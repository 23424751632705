import {
  DailyCapacityPerWeek,
  DailyTeamMemberProjectCapacity,
  ProjectsByTeamMember,
  TeamMemberProjects,
  TeamMemberKey,
} from 'utils/types/dailyTeamsCapacity';
import {
  addIdToArrayIfNotExists,
  updateDailyCapacityPerWeek,
} from '../helpers';

type TeamMemberProjectsWithoutLinks = Omit<TeamMemberProjects, 'links'>;
type ProjectsByTeamMemberWihoutLinks =
  ProjectsByTeamMember<TeamMemberProjectsWithoutLinks>;

export const getProjectsIdsFromTeamMember: (
  teamId: string,
  memberId: string,
  projectsByTeamMember: ProjectsByTeamMemberWihoutLinks
) => string[] = (teamId, memberId, projectsByTeamMember) => {
  if (
    !(teamId in projectsByTeamMember) ||
    !(memberId in projectsByTeamMember[teamId])
  ) {
    return [];
  }
  return projectsByTeamMember[teamId][memberId]?.projectsIds || [];
};

const getAllTeamProjectsIds: (
  teamId: string,
  projectsByTeamMember: ProjectsByTeamMemberWihoutLinks
) => string[] = (teamId, projectsByTeamMember) => {
  if (!(teamId in projectsByTeamMember)) {
    return [];
  }
  return projectsByTeamMember[teamId]?.allProjectsIds || [];
};

export const generateTeamMemberKey: (
  teamId: string,
  memberId: string
) => TeamMemberKey = (teamId, memberId) => `team-${teamId}__member-${memberId}`;

export const getProjectsIdsFromTeamMemberToFetch: (
  teamMemberProjectsIds: string[],
  projectsIds: string[]
) => string[] = (teamMemberProjectsIds, projectsIds) => {
  if (teamMemberProjectsIds.length === 0) {
    return projectsIds;
  }
  return projectsIds.filter(
    (projectId) => !teamMemberProjectsIds.includes(projectId)
  );
};

export const getProjectsIdsFromTeamMemberToRemove: (
  teamMemberProjectsIds: string[],
  projectsIds: string[]
) => string[] = (teamMemberProjectsIds, projectsIds) => {
  return teamMemberProjectsIds.filter(
    (projectId) => !projectsIds.includes(projectId)
  );
};

type AddProjectToTeamMemberResponseType =
  ProjectsByTeamMemberWihoutLinks[keyof ProjectsByTeamMemberWihoutLinks];
export const addProjectToTeamMember: (
  teamId: string,
  memberId: string,
  projectId: string,
  projectsByTeamMember: ProjectsByTeamMemberWihoutLinks
) => AddProjectToTeamMemberResponseType = (
  teamId,
  memberId,
  projectId,
  projectsByTeamMember
) => {
  let teamMemberProjects = {} as AddProjectToTeamMemberResponseType;
  if (teamId in projectsByTeamMember) {
    teamMemberProjects = { ...projectsByTeamMember[teamId] };
  }
  teamMemberProjects[memberId] = {
    projectsIds: addIdToArrayIfNotExists(
      getProjectsIdsFromTeamMember(teamId, memberId, projectsByTeamMember),
      projectId
    ),
  };
  teamMemberProjects.allProjectsIds = addIdToArrayIfNotExists(
    getAllTeamProjectsIds(teamId, projectsByTeamMember),
    projectId
  );
  return teamMemberProjects;
};

export const removeProjectsFromTeamMember: (
  teamId: string,
  memberId: string,
  projectsIds: string[],
  projectsByTeamMember: ProjectsByTeamMemberWihoutLinks
) => ProjectsByTeamMemberWihoutLinks = (
  teamId,
  memberId,
  projectsIds,
  projectsByTeamMember
) => {
  let updatedProjectsByTeamMember = { ...projectsByTeamMember };
  if (
    !(teamId in projectsByTeamMember) ||
    !(memberId in projectsByTeamMember[teamId])
  ) {
    return updatedProjectsByTeamMember;
  }
  const memberProjectsIds = getProjectsIdsFromTeamMember(
    teamId,
    memberId,
    projectsByTeamMember
  );
  updatedProjectsByTeamMember[teamId][memberId] = {
    projectsIds: memberProjectsIds.filter((id) => !projectsIds.includes(id)),
  };
  if (updatedProjectsByTeamMember[teamId][memberId].projectsIds.length === 0) {
    delete updatedProjectsByTeamMember[teamId][memberId];
  }
  if (Object.keys(updatedProjectsByTeamMember[teamId]).length === 1) {
    const { [teamId]: teamToRemove, ...restOfTeams } = projectsByTeamMember;
    updatedProjectsByTeamMember = restOfTeams;
  }
  return updatedProjectsByTeamMember;
};

export const removeTeamIdFromProjects: (
  teamId: string,
  memberId: string,
  projectsIds: string[],
  projectsById: DailyTeamMemberProjectCapacity
) => {
  projectsIdsToRemove: string[];
  updatedProjectsById: DailyTeamMemberProjectCapacity;
} = (teamId, memberId, projectsIds, projectsById) => {
  const projectsIdsToRemove = [];
  const updatedProjectsById = { ...projectsById };
  const teamMemberKey = generateTeamMemberKey(teamId, memberId);
  for (const projectId of projectsIds) {
    if (projectId in updatedProjectsById) {
      const dailyCapacities = {
        ...updatedProjectsById[projectId].dailyCapacities,
      };
      if (teamMemberKey in dailyCapacities) {
        delete dailyCapacities[teamMemberKey];
        updatedProjectsById[projectId].dailyCapacities = dailyCapacities;
      }
      if (Object.keys(dailyCapacities).length === 0) {
        projectsIdsToRemove.push(projectId);
        delete updatedProjectsById[projectId];
      }
    }
  }
  return {
    projectsIdsToRemove,
    updatedProjectsById,
  };
};

type AddDailyCapacityToProjectResponseTye =
  DailyTeamMemberProjectCapacity[keyof DailyTeamMemberProjectCapacity];
export const addDailyCapacityToProject: (
  teamId: string,
  memberId: string,
  projectId: string,
  dailyCapacity: DailyCapacityPerWeek,
  projectsById: DailyTeamMemberProjectCapacity
) => AddDailyCapacityToProjectResponseTye = (
  teamId,
  memberId,
  projectId,
  dailyCapacity,
  projectsById
) => {
  let currentDailyProjectCapacitiesPerWeek = {} as DailyCapacityPerWeek;
  const teamMemberKey = generateTeamMemberKey(teamId, memberId);
  if (projectId in projectsById) {
    const dailyProjectCapacitiesByTeamMember = {
      ...projectsById[projectId].dailyCapacities,
    };
    currentDailyProjectCapacitiesPerWeek = {
      ...dailyProjectCapacitiesByTeamMember[teamMemberKey],
    };
  }
  const updatedDailyProjectCapacitiesPerWeek = updateDailyCapacityPerWeek(
    { ...currentDailyProjectCapacitiesPerWeek },
    dailyCapacity
  );
  return {
    dailyCapacities: {
      [teamMemberKey]: updatedDailyProjectCapacitiesPerWeek,
    },
  };
};
