import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { SLICE_STATUS } from 'utils/constants';
import { Status } from 'utils/customTypes';
import { AdditionalBenefit } from 'utils/types/program';
import { RootState } from 'state/store';
import additionalBenefitAPI from './additionalBenefitAPI';

interface MonetaryBenefitsState {
  status: Status;
  value: AdditionalBenefit;
}

/* ============================= INITIAL STATE ============================== */
const initialState: MonetaryBenefitsState = {
  status: SLICE_STATUS.IDLE,
  value: {} as AdditionalBenefit,
};

/* ============================== REDUX THUNK =============================== */
export const fetchAdditionalBenefit = createAsyncThunk(
  'additionalBenefit/FETCH_ADDITIONAL_BENEFIT',
  async ({
    programId,
    categoryId,
    benefitId,
  }: {
    programId: string;
    categoryId: string;
    benefitId: string;
  }) => {
    const response = await additionalBenefitAPI.getAdditionalBenefit(
      programId,
      categoryId,
      benefitId
    );
    return response;
  }
);

export const deleteAdditionalBenefit = createAsyncThunk(
  'additionalBenefit/DELETE_ADDITIONAL_BENEFIT',
  async ({
    programId,
    categoryId,
    benefitId,
  }: {
    programId: string;
    categoryId: string;
    benefitId: string;
  }) => {
    const response = await additionalBenefitAPI.deleteAdditionalBenefit(
      programId,
      categoryId,
      benefitId
    );
    return response;
  }
);

export const updateAdditionalBenefit = createAsyncThunk(
  'additionalBenefit/UPDATE_ADDITIONAL_BENEFIT',
  async ({
    programId,
    categoryId,
    benefitId,
    data,
  }: {
    programId: string;
    categoryId: string;
    benefitId: string;
    data: AdditionalBenefit;
  }) => {
    const response = await additionalBenefitAPI.updateAdditionalBenefit(
      programId,
      categoryId,
      benefitId,
      data
    );
    return response;
  }
);

/* ================================ ACTIONS ================================= */
export const resetAdditionalBenefit = createAction(
  'additionalBenefit/RESET_ADDITIONAL_BENEFIT'
);

/* ================================= REDUCER ================================ */
const additionalBenefitSlice = createSlice({
  name: 'additionalBenefit',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdditionalBenefit.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchAdditionalBenefit.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        state.value = action.payload;
      })
      .addCase(fetchAdditionalBenefit.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(deleteAdditionalBenefit.fulfilled, (state) => {
        state.value = {};
      })
      .addCase(deleteAdditionalBenefit.rejected, (state) => {
        throw new Error();
      })
      .addCase(updateAdditionalBenefit.fulfilled, (state, action) => {
        state.value = action.payload;
      })
      .addCase(updateAdditionalBenefit.rejected, (state) => {
        throw new Error();
      });
  },
});

/* =============================== SELECTORS ================================ */
export const selectAdditionalBenefit = (state: RootState) =>
  state.additionalBenefit.value;

export const selectAdditionalBenefitStatus = (state: RootState) =>
  state.additionalBenefit.status;

export default additionalBenefitSlice.reducer;
