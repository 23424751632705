import { Modal, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

const LeavingUnsavedRequestModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
  onClose,
  isCreating,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
  onClose: () => void;
  isCreating: boolean;
}) => {
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  const handleClose = () => {
    onClose();
    setIsOpen(false);
  };

  return (
    <Modal
      data-testid='leaving-unsaved-request__modal'
      actionButtons={[
        {
          children: intl.get(
            'REQUESTS_LIST_PAGE.MODAL.LEAVING_CREAT_NEW_REQUEST.SUBMIT_BUTTON'
          ),
          variant: 'primary',
          color: 'danger',
          onClick: handleConfirm,
        },
        {
          children: intl.get(
            'REQUESTS_LIST_PAGE.MODAL.DELETE_REQUEST.CANCEL_BUTTON'
          ),
          variant: 'secondary',
          onClick: handleClose,
        },
      ]}
      closeModal={handleClose}
      isOpen={isOpen}
      title={intl.get(
        'REQUESTS_LIST_PAGE.MODAL.LEAVING_CREAT_NEW_REQUEST.TITLE'
      )}
      titleIcon={{
        name: 'arrow-back-outline',
      }}
      size='medium'
      aria-label={intl.get(
        'REQUESTS_LIST_PAGE.MODAL.LEAVING_CREAT_NEW_REQUEST.TITLE'
      )}
    >
      <Typography className='mb-5'>
        {isCreating
          ? intl.get(
              'REQUESTS_LIST_PAGE.MODAL.LEAVING_CREAT_NEW_REQUEST.CALL_TO_ACTION'
            )
          : intl.get(
              'REQUESTS_LIST_PAGE.MODAL.LEAVING_CREAT_NEW_REQUEST.CALL_TO_ACTION_FOR_EDITING'
            )}
      </Typography>
    </Modal>
  );
};

export default LeavingUnsavedRequestModal;
