import TaskModal from 'Organisms/TaskModal/TaskModal';
import { useMemo } from 'react';
import { taskModalDefaultValues } from 'Pages/ProjectPage/tabs/Tasks/helpers/constants';
import { TaskFormFields } from 'utils/types/task';
import { TaskTemplate } from 'utils/types/taskTemplate';
import { Task } from 'utils/customTypes';

interface EditTaskTemplateModalProps {
  isModalOpen: boolean;
  onCloseModal: () => void;
  taskTemplateData: TaskTemplate;
  setNewAndUpdatedTemplates: React.Dispatch<React.SetStateAction<Set<string>>>;
  setTemplates: React.Dispatch<React.SetStateAction<TaskTemplate[]>>;
}

const EditTaskTemplateModal: React.FC<EditTaskTemplateModalProps> = ({
  isModalOpen,
  onCloseModal,
  taskTemplateData,
  setNewAndUpdatedTemplates,
  setTemplates,
}) => {
  const initialTaskData = useMemo<Task>(
    () => ({
      ...taskModalDefaultValues,
      ...taskTemplateData,
      estimate_hours: taskTemplateData.estimated_hours || '0',
    }),
    [taskTemplateData]
  );

  const confirmEditingTask = (newTaskData: TaskFormFields) => {
    const templateEdited = taskTemplateData;

    setTemplates((prevTemplates) => {
      const editedIndex = prevTemplates.findIndex(
        (template) => template.id === templateEdited.id
      );
      if (editedIndex === -1) return prevTemplates;

      return [
        ...prevTemplates.slice(0, editedIndex),
        {
          ...templateEdited,
          name: newTaskData.name,
          description: newTaskData.description,
          estimated_hours: newTaskData.estimate_hours || '0',
          type: newTaskData.type,
        },
        ...prevTemplates.slice(editedIndex + 1),
      ];
    });

    setNewAndUpdatedTemplates((prevTemplatesIds) =>
      prevTemplatesIds.add(templateEdited.id)
    );
  };

  return (
    <TaskModal
      isTaskTemplate
      isOpen={isModalOpen}
      setIsOpen={onCloseModal}
      taskData={initialTaskData}
      onSaveTask={confirmEditingTask}
    />
  );
};

export default EditTaskTemplateModal;
