import React from 'react';
import intl from 'react-intl-universal';
import {
  FormItem,
  UsersPicker,
  Datepicker,
  NumericInput,
} from '@getsynapse/design-system';
import { TASKS_TABLE_FILTERS } from 'utils/constants';
import {
  ProjectTasksTableTab,
  UserAvatars,
  Option,
  rangeDateType,
} from 'utils/customTypes';
import { RangeFilter, TaskFilters, TaskFiltersKey } from 'utils/types/filters';
import { isArrayOfOptions, isDateRangeObject } from 'utils/typeGuards';
import { getInitialValueForDropDown } from 'utils/functions';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';
import useTasksFilters from './hooks/useTasksFilters';

const DATE_FILTERS = [
  TASKS_TABLE_FILTERS.START_DATE,
  TASKS_TABLE_FILTERS.DUE_DATE,
  TASKS_TABLE_FILTERS.COMPLETION_DATE,
];

const FiltersForm: React.FC<{
  filters: TaskFilters;
  taskTable: ProjectTasksTableTab;
  onUpdateFilters: (newFilters: TaskFilters) => void;
}> = ({ filters, taskTable, onUpdateFilters }) => {
  const { getFilterOptionsByKey } = useTasksFilters(taskTable);
  const statusOptions = getFilterOptionsByKey(TASKS_TABLE_FILTERS.STATUS);
  const typeOptions = getFilterOptionsByKey(TASKS_TABLE_FILTERS.TYPE);
  const assigneeUsers = getFilterOptionsByKey(
    TASKS_TABLE_FILTERS.ASSIGNEE_USERS
  ) as UserAvatars[];

  const getInitialAssigneeUsers = (filterKey: TaskFiltersKey) => {
    const assigneeUserIds = (filters[filterKey] as string[]) || [];
    return assigneeUsers.filter((user: UserAvatars) =>
      assigneeUserIds.includes(user.value)
    );
  };

  const getRangeInititalValue = (
    filterKey: TaskFiltersKey,
    filterRange: 'from' | 'to'
  ) => {
    const filter = (filters[filterKey] as RangeFilter) || {};
    const value = filter[filterRange] || '';
    if (DATE_FILTERS.includes(filterKey)) {
      return value ? new Date(value) : null;
    }
    return value;
  };

  const isDateRangeFilterEmpty = (filter: RangeFilter) =>
    filter.from === null && filter.to === null;

  const isRangeFilterEmpty = (filter: RangeFilter) =>
    filter.from === '' && filter.to === '';

  const updateFilters = (
    filterKey: TaskFiltersKey,
    value: Option[] | rangeDateType | string,
    rangeFilterKey: 'from' | 'to' | null = null
  ) => {
    let filtersCopy = { ...filters } as TaskFilters;
    if (isArrayOfOptions(value)) {
      if (value.length === 0) {
        delete filtersCopy[filterKey];
      } else {
        filtersCopy[filterKey] = value.map((option) => option.value);
      }
    } else if (isDateRangeObject(value)) {
      const filterValue = value as rangeDateType;
      filtersCopy[filterKey] = {
        ...filtersCopy[filterKey],
        [rangeFilterKey as string]: filterValue.startDate
          ? filterValue.startDate.toString()
          : null,
      };
      if (isDateRangeFilterEmpty(filtersCopy[filterKey] as RangeFilter)) {
        delete filtersCopy[filterKey];
      }
    } else if (typeof value === 'string') {
      filtersCopy[filterKey] = {
        ...filtersCopy[filterKey],
        [rangeFilterKey as string]: value,
      };
      if (isRangeFilterEmpty(filtersCopy[filterKey] as RangeFilter)) {
        delete filtersCopy[filterKey];
      }
    }
    onUpdateFilters(filtersCopy);
  };

  return (
    <div className='mt-8 flex flex-col space-y-5'>
      <FormItem label={intl.get('TASKS.TASK_DETAIL_PAGE.STATUS')}>
        <MultiSelectDropdown
          options={statusOptions}
          values={getInitialValueForDropDown(
            statusOptions,
            filters.status as string[]
          )}
          placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
          onChange={(options: Option[]) =>
            updateFilters(TASKS_TABLE_FILTERS.STATUS, options)
          }
          triggerProps={{
            'aria-label': intl.get('TASKS.TASK_DETAIL_PAGE.STATUS'),
            'data-cy': 'tasks-filters__status-picker',
          }}
          listProps={{ 'data-cy': 'tasks-filters__status__options-list' }}
        />
      </FormItem>
      <FormItem label={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_TYPE')}>
        <MultiSelectDropdown
          options={typeOptions}
          values={getInitialValueForDropDown(
            typeOptions,
            filters.type as string[]
          )}
          onChange={(options: Option[]) =>
            updateFilters(TASKS_TABLE_FILTERS.TYPE, options)
          }
          placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
          triggerProps={{
            'aria-label': intl.get('TASKS.TASK_DETAIL_PAGE.TASK_TYPE'),
            'data-cy': 'tasks-filters__type-picker',
          }}
          listProps={{ 'data-cy': 'tasks-filters__type__options-list' }}
        />
      </FormItem>
      <FormItem label={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_ASSIGNEE')}>
        <UsersPicker
          triggerText={intl.get('FILTER_GENERAL.PLACEHOLDER')}
          deactivatedUserText={intl.get('DEACTIVATED')}
          usersList={assigneeUsers}
          selectedUsersList={getInitialAssigneeUsers(
            TASKS_TABLE_FILTERS.ASSIGNEE_USERS
          )}
          onChange={(users) =>
            updateFilters(TASKS_TABLE_FILTERS.ASSIGNEE_USERS, users)
          }
          triggerProps={{
            'aria-label': intl.get('TASKS.TASK_DETAIL_PAGE.TASK_ASSIGNEE'),
            'data-cy': 'tasks-filters__assignee-picker',
          }}
          popperProps={{ 'data-cy': 'tasks-filters__assignee__options-list' }}
          allowSelectDeactivated
        />
      </FormItem>
      <FormItem label={intl.get('TASKS.TASK_DETAIL_PAGE.START_DATE_LABEL')}>
        <div className='w-full flex space-x-4'>
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.FROM')}
            startDate={getRangeInititalValue(
              TASKS_TABLE_FILTERS.START_DATE,
              'from'
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(TASKS_TABLE_FILTERS.START_DATE, date, 'from')
            }
            inputProps={{
              'aria-label': `${intl.get(
                'TASKS.TASK_DETAIL_PAGE.START_DATE_LABEL'
              )}__from-range`,
              'data-cy': 'tasks-filters__start-date__from-range',
            }}
          />
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.TO')}
            startDate={getRangeInititalValue(
              TASKS_TABLE_FILTERS.START_DATE,
              'to'
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(TASKS_TABLE_FILTERS.START_DATE, date, 'to')
            }
            inputProps={{
              'aria-label': `${intl.get(
                'TASKS.TASK_DETAIL_PAGE.START_DATE_LABEL'
              )}__to-range`,
              'data-cy': 'tasks-filters__start-date__to-range',
            }}
          />
        </div>
      </FormItem>
      <FormItem label={intl.get('TASKS.TASK_DETAIL_PAGE.DUE_DATE_LABEL')}>
        <div className='w-full flex space-x-4'>
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.FROM')}
            startDate={getRangeInititalValue(
              TASKS_TABLE_FILTERS.DUE_DATE,
              'from'
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(TASKS_TABLE_FILTERS.DUE_DATE, date, 'from')
            }
            inputProps={{
              'aria-label': `${intl.get(
                'TASKS.TASK_DETAIL_PAGE.DUE_DATE_LABEL'
              )}__from-range`,
              'data-cy': 'tasks-filters__due-date__from-range',
            }}
          />
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.TO')}
            startDate={getRangeInititalValue(
              TASKS_TABLE_FILTERS.DUE_DATE,
              'to'
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(TASKS_TABLE_FILTERS.DUE_DATE, date, 'to')
            }
            inputProps={{
              'aria-label': `${intl.get(
                'TASKS.TASK_DETAIL_PAGE.DUE_DATE_LABEL'
              )}__to-range`,
              'data-cy': 'tasks-filters__due-date__to-range',
            }}
          />
        </div>
      </FormItem>
      <FormItem
        label={intl.get('TASKS.TASK_DETAIL_PAGE.ACTUAL_COMPLETION_DATE')}
      >
        <div className='w-full flex space-x-4'>
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.FROM')}
            startDate={getRangeInititalValue(
              TASKS_TABLE_FILTERS.COMPLETION_DATE,
              'from'
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(TASKS_TABLE_FILTERS.COMPLETION_DATE, date, 'from')
            }
            inputProps={{
              'aria-label': `${intl.get(
                'TASKS.TASK_DETAIL_PAGE.ACTUAL_COMPLETION_DATE'
              )}__from-range`,
              'data-cy': 'tasks-filters__actual-completion-date__from-range',
            }}
          />
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.TO')}
            startDate={getRangeInititalValue(
              TASKS_TABLE_FILTERS.COMPLETION_DATE,
              'to'
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(TASKS_TABLE_FILTERS.COMPLETION_DATE, date, 'to')
            }
            inputProps={{
              'aria-label': `${intl.get(
                'TASKS.TASK_DETAIL_PAGE.ACTUAL_COMPLETION_DATE'
              )}__to-range`,
              'data-cy': 'tasks-filters__actual-completion-date__to-range',
            }}
          />
        </div>
      </FormItem>
      <FormItem label={intl.get('TASKS.TASK_DETAIL_PAGE.ESTIMATED_TIME_LABEL')}>
        <div className='w-full flex space-x-4'>
          <NumericInput
            placeholder={intl.get('FILTER_GENERAL.FROM')}
            containerClassName='w-full'
            value={getRangeInititalValue(
              TASKS_TABLE_FILTERS.ESTIMATED_HOURS,
              'from'
            )}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              updateFilters(
                TASKS_TABLE_FILTERS.ESTIMATED_HOURS,
                event.target.value,
                'from'
              )
            }
            aria-label={`${intl.get(
              'TASKS.TASK_DETAIL_PAGE.ESTIMATED_TIME_LABEL'
            )}__from-range`}
            data-cy='tasks-filters__estimated-hours__from-range'
          />
          <NumericInput
            placeholder={intl.get('FILTER_GENERAL.TO')}
            containerClassName='w-full'
            value={getRangeInititalValue(
              TASKS_TABLE_FILTERS.ESTIMATED_HOURS,
              'to'
            )}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              updateFilters(
                TASKS_TABLE_FILTERS.ESTIMATED_HOURS,
                event.target.value,
                'to'
              )
            }
            aria-label={`${intl.get(
              'TASKS.TASK_DETAIL_PAGE.ESTIMATED_TIME_LABEL'
            )}__to-range`}
            data-cy='tasks-filters__estimated-hours__to-range'
          />
        </div>
      </FormItem>
      <FormItem label={intl.get('TASKS.TASK_DETAIL_PAGE.ADD_ACTUAL_HOURS')}>
        <div className='w-full flex space-x-4'>
          <NumericInput
            placeholder={intl.get('FILTER_GENERAL.FROM')}
            containerClassName='w-full'
            value={getRangeInititalValue(
              TASKS_TABLE_FILTERS.ACTUAL_HOURS,
              'from'
            )}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              updateFilters(
                TASKS_TABLE_FILTERS.ACTUAL_HOURS,
                event.target.value,
                'from'
              )
            }
            aria-label={`${intl.get(
              'TASKS.TASK_DETAIL_PAGE.ADD_ACTUAL_HOURS'
            )}__from-range`}
            data-cy='tasks-filters__actual-hours__from-range'
          />
          <NumericInput
            placeholder={intl.get('FILTER_GENERAL.TO')}
            containerClassName='w-full'
            value={getRangeInititalValue(
              TASKS_TABLE_FILTERS.ACTUAL_HOURS,
              'to'
            )}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              updateFilters(
                TASKS_TABLE_FILTERS.ACTUAL_HOURS,
                event.target.value,
                'to'
              )
            }
            aria-label={`${intl.get(
              'TASKS.TASK_DETAIL_PAGE.ADD_ACTUAL_HOURS'
            )}__to-range`}
            data-cy='tasks-filters__actual-hours__to-range'
          />
        </div>
      </FormItem>
    </div>
  );
};

export default FiltersForm;
