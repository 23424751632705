import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { useParams, useHistory } from 'react-router-dom';
import { Modal, Typography } from '@getsynapse/design-system';
import { showNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { deleteROIObjective } from 'state/ROICategory/ROICategoySlice';
import { PATHS } from 'utils/constants';

export interface DeleteROIObjectiveModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const DeleteROIObjectiveModal = ({
  isOpen,
  onClose,
}: DeleteROIObjectiveModalProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { programId, categoryId, objectiveId } = useParams<{
    programId: string;
    categoryId: string;
    objectiveId: string;
  }>();

  const handleDelete = async () => {
    try {
      await dispatch(
        deleteROIObjective({ programId, categoryId, objectiveId })
      );
      dispatch(
        showNotification({
          notificationVariant: 'success',
          notificationTitle: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.DELETE_OBJECTIVE.NOTIFICATION.SUCCESS.TITLE'
          ),
          autoHide: false,
        })
      );
      onClose();
      history.push(
        `${PATHS.PROGRAM_PAGE}/${programId}${PATHS.ROI_CATEGORY_PAGE}/${categoryId}`
      );
    } catch (error) {
      dispatch(
        showNotification({
          notificationVariant: 'error',
          notificationTitle: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.DELETE_OBJECTIVE.NOTIFICATION.ERROR.TITLE'
          ),
          notificationMessage: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.DELETE_OBJECTIVE.NOTIFICATION.ERROR.MESSAGE'
          ),
        })
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={onClose}
      aria-label='delete-roi-objective_modal'
      data-testid='delete-roi-objective-modal'
      title={intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.DELETE_OBJECTIVE.MODAL.TITLE'
      )}
      titleIcon={{
        name: 'trash',
        className: 'text-error-dark',
      }}
      size='medium'
      actionButtons={[
        {
          children: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.DELETE_OBJECTIVE.MODAL.CONFIRM_BUTTON'
          ),
          variant: 'primary',
          color: 'danger',
          onClick: handleDelete,
          'data-testid': 'delete-roi-objective-button',
        },
        {
          children: intl.get('CANCEL'),
          variant: 'tertiary',
          onClick: onClose,
          'data-testid': 'cancel-delete-roi-objective-button',
        },
      ]}
    >
      <Typography>
        {intl.get('ROI_CATEGORY.OBJECTIVE_PAGE.ROI_OBJECTIVE_MODAL_TEXT')}
      </Typography>
    </Modal>
  );
};

export default DeleteROIObjectiveModal;
