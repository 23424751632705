import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import moment from 'moment';
import {
  Table,
  Typography,
  Tag,
  tailwindOverride,
  TableOperationHeader,
  Avatar,
  Tooltip,
  IconButton,
} from '@getsynapse/design-system';
import {
  DATE,
  TEMPLATE_SORTING,
  PATHS,
  PROJECT_TEMPLATE_STATUS_VALUES,
} from 'utils/constants';
import { ProjectTemplate } from 'utils/types/templates';
import {
  setSearchParam,
  setTemplatesOrder,
  deleteProjectTemplate,
} from 'state/ProjectTemplates/projectTemplatesSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import cognotaAvatar from 'assets/images/cognota-avatar.svg';
import UserAvatar from 'Atoms/UserAvatar';
import DeleteTemplateModal from './DeleteTemplate/DeleteTemplateModal';
import { Link } from 'react-router-dom';
import { isUserStatusDisabled } from 'utils/functions';

interface TemplateOwnerCellProps {
  template: ProjectTemplate;
}

const TemplateOwnerCell = ({ template }: TemplateOwnerCellProps) => {
  const hasOwner: boolean = !!template.owner;

  if (template.system) {
    return (
      <div className='flex items-start'>
        <Avatar
          iconSrc={cognotaAvatar}
          iconProps={{ className: 'text-lg' }}
          bgColorClass='bg-secondary'
        />
        <div className='pl-2'>
          <Typography variant='body2' className='text-primary'>
            {intl.get('SETTINGS_PAGE.PROJECTS_PAGE.SYSTEM')}
          </Typography>
        </div>
      </div>
    );
  } else if (hasOwner) {
    return (
      <div
        className={tailwindOverride('flex items-center', {
          'text-neutral-light': isUserStatusDisabled(template.owner?.status),
        })}
      >
        <UserAvatar
          size='small'
          user={{
            avatar_url: template.owner?.avatar_url,
            data: {
              firstName: get(template, 'owner.data.firstName') || '-',
              lastName: get(template, 'owner.data.lastName') || '',
            },
            status: template.owner?.status,
          }}
          className='mr-2.5'
        />
        {`${get(template, 'owner.data.firstName') || '-'} ${
          get(template, 'owner.data.lastName') || ''
        }`}
      </div>
    );
  } else {
    return <div>-</div>;
  }
};

type TemplatesTableProps = {
  data: ProjectTemplate[];
};

const TemplatesTable = ({ data }: TemplatesTableProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchParamValue, setSearchParamValue] = useState('');
  const [templateIdToDelete, setTemplateIdToDelete] = useState<string | null>(
    null
  );
  const tagMaping = {
    [PROJECT_TEMPLATE_STATUS_VALUES.DRAFT]: {
      background: 'bg-tertiary-lightest',
      text: 'text-tertiary-darker',
    },
    [PROJECT_TEMPLATE_STATUS_VALUES.PUBLISHED]: {
      background: 'bg-success-lightest',
      text: 'text-success-darker',
    },
    [PROJECT_TEMPLATE_STATUS_VALUES.DISABLED]: {
      background: 'bg-neutral-lighter-two',
      text: 'text-neutral',
    },
  };

  useEffect(() => {
    dispatch(setSearchParam(''));
  }, [dispatch]);

  const handleSearch = (value: string) => {
    setSearchParamValue(value);
    dispatch(setSearchParam(value));
  };

  const handleSort = (orderByParam: string[], order: 'desc' | 'asc') => {
    dispatch(setTemplatesOrder({ order, orderBy: orderByParam }));
  };

  const handleDeleteTemplate = async () => {
    await dispatch(deleteProjectTemplate({ templateId: templateIdToDelete! }));
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get(
          'SETTINGS_PAGE.TEMPLATE_PAGE.SUCCESS_MESSAGE',
          { action: intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.ACTIONS.DELETE') }
        ),
      })
    );
  };

  return (
    <div className='pt-4'>
      <DeleteTemplateModal
        shouldOpenModal={templateIdToDelete !== null}
        onCancel={() => setTemplateIdToDelete(null)}
        onConfirm={handleDeleteTemplate}
      />
      <TableOperationHeader
        className='border-0 rounded-b-none bg-neutral-white'
        inputValue={searchParamValue}
        setInputValue={handleSearch}
      />
      <div className='max-h-templates overflow-auto'>
        <Table
          className='w-full mb-5'
          canSelectRows={false}
          isSelectRowCellSticky
          data-cy='projects-settings__templates-table'
          data={{
            headData: {
              stickyHeader: true,
              handleSort,
              headCells: [
                {
                  content: '',
                  className: 'w-11',
                },
                {
                  columnName: TEMPLATE_SORTING.NAME,
                  content: intl.get('SETTINGS_PAGE.PROJECTS_PAGE.TITLE'),
                  className: 'font-semibold w-3/12 py-3',
                },
                {
                  columnName: TEMPLATE_SORTING.DESCRIPTION,
                  content: intl.get('SETTINGS_PAGE.PROJECTS_PAGE.DESCRIPTION'),
                  className: 'font-semibold w-4/12 py-3',
                },
                {
                  columnName: TEMPLATE_SORTING.OWNER,
                  content: intl.get('SETTINGS_PAGE.PROJECTS_PAGE.OWNER'),
                  className: 'font-semibold  w-3/12 py-3',
                },
                {
                  columnName: TEMPLATE_SORTING.UPDATED_AT,
                  content: intl.get('SETTINGS_PAGE.PROJECTS_PAGE.DATE'),
                  className: 'font-semibold w-1/12 py-3',
                },
                {
                  columnName: TEMPLATE_SORTING.STATUS,
                  content: intl.get('SETTINGS_PAGE.PROJECTS_PAGE.STATUS'),
                  className: 'font-semibold w-1/12 py-3',
                },
                {
                  content: <div></div>,
                  className: 'h-10 bg-primary-lightest z-2',
                },
              ],
            },
            rows: data.map((template) => {
              const isTemplatePublished =
                template.status === PROJECT_TEMPLATE_STATUS_VALUES.PUBLISHED;
              return {
                id: template.id,
                onClick: () =>
                  history.push(`${PATHS.TEMPLATE_PAGE}/${template.id}`),
                className: 'cursor-pointer',
                'data-cy': `projects-settings__templates-table__row-${template.id}`,
                cells: [
                  {
                    content: '',
                    className: 'w-11',
                  },
                  {
                    content: (
                      <div className='truncate w-72'>
                        <Link
                          to={`${PATHS.TEMPLATE_PAGE}/${template.id}`}
                          title={template.name}
                        >
                          {template.name}
                        </Link>
                      </div>
                    ),
                    className: 'w-3/12',
                  },
                  {
                    content: (
                      <Tooltip
                        timeout={0}
                        showPopper
                        usePortal
                        position='topRight'
                        contentProps={{
                          className: 'px-3 py-2 text-sm z-50 absolute',
                        }}
                        openMode='hover1'
                        trigger={
                          <div className='truncate w-84'>
                            {template.description}
                          </div>
                        }
                      >
                        {template.description}
                      </Tooltip>
                    ),
                    className: 'truncate w-4/12',
                  },
                  {
                    content: <TemplateOwnerCell template={template} />,
                    className: 'truncate w-3/12',
                  },
                  {
                    content: moment(template.updatedAt).format(
                      DATE.SHORT_FORMAT
                    ),
                    className: 'truncate w-1/12',
                  },
                  {
                    content: (
                      <Tag
                        className={tailwindOverride(
                          'group py-0.5 border-transparent',
                          'text-xs border',
                          tagMaping[template.status].background
                        )}
                        textClassName={tagMaping[template.status].text}
                        label={intl.get(
                          `SETTINGS_PAGE.PROJECTS_PAGE.${template.status}`
                        )}
                      />
                    ),
                    className: 'truncate w-1/12',
                  },
                  {
                    content: (
                      <div className='h-full text-center w-12'>
                        <div className='ml-2 hidden group-hover:flex'>
                          {!template.system && (
                            <Tooltip
                              openMode='hover1'
                              timeout={0}
                              showPopper
                              position='topLeft'
                              contentProps={{
                                className: 'px-3 py-2 text-sm z-50 absolute',
                              }}
                              usePortal
                              trigger={
                                <IconButton
                                  name='trash'
                                  aria-label={intl.get(
                                    'SETTINGS_PAGE.TEMPLATE_PAGE.DELETE_TEMPLATE'
                                  )}
                                  iconClassname='pointer-events-none'
                                  className={tailwindOverride(
                                    'text-base text-neutral-dark ml-2 p-1 rounded',
                                    'hover:bg-neutral-lightest hover:shadow-allocation-table',
                                    {
                                      'cursor-not-allowed': isTemplatePublished,
                                    }
                                  )}
                                  disabled={isTemplatePublished}
                                  data-cy={`projects-settings__templates-table__delete-${template.id}`}
                                  onClick={(
                                    event: React.MouseEvent<HTMLButtonElement>
                                  ) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    setTemplateIdToDelete(template.id!);
                                  }}
                                />
                              }
                            >
                              {isTemplatePublished
                                ? intl.get(
                                    'SETTINGS_PAGE.TEMPLATE_PAGE.DELETE_TEMPLATE_TOOLTIP'
                                  )
                                : intl.get(
                                    'SETTINGS_PAGE.TEMPLATE_PAGE.DELETE_TEMPLATE'
                                  )}
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    ),
                    className: 'w-12',
                  },
                ],
              };
            }),
          }}
        />
      </div>
    </div>
  );
};

export default TemplatesTable;
