import React from 'react';
import intl from 'react-intl-universal';
import {
  FormLabel,
  Typography,
  tailwindOverride,
} from '@getsynapse/design-system';
import { PartialHoursItem as PartialHoursItemType } from 'types/store/manageTimeOff';
import PartialHoursItem from './components/PartialHoursItem/PartialHoursItem';

interface PartialHoursAmountProps {
  partialHoursItems: PartialHoursItemType[];
  totalHours: number;
  dailyCapacity: number;
  updatePartialHours: (day: string, hours: number) => void;
  updateValidityForNumericInput: (index: number, isValid: boolean) => void;
}

const PartialHoursAmount: React.FC<PartialHoursAmountProps> = ({
  partialHoursItems,
  totalHours,
  dailyCapacity,
  updatePartialHours,
  updateValidityForNumericInput,
}) => {
  return (
    <div className='mt-6' id='partial-hours-amount'>
      <div className='flex flex-1 justify-between'>
        <FormLabel required>{intl.get('MANAGE_TIME_OFF.AMOUNT')}</FormLabel>
        <Typography variant='label' className='tex-primary-darker'>
          {intl.get('MANAGE_TIME_OFF.DAILY_CAPACITY', {
            capacity: dailyCapacity,
          })}
        </Typography>
      </div>
      <div
        className={tailwindOverride(
          'max-h-partial-time-off-days overflow-y-auto',
          'border rounded border-neutral-lighter'
        )}
      >
        <div className={tailwindOverride('flex flex-col space-y-4 p-4')}>
          {partialHoursItems.map((partialHoursItem, itemIndex) => (
            <PartialHoursItem
              getIsNumericInputValid={(isNumericInputValid) => {
                updateValidityForNumericInput(itemIndex, isNumericInputValid);
              }}
              key={`partial_hours_item-${partialHoursItem.day}`}
              {...partialHoursItem}
              updatePartialHours={updatePartialHours}
            />
          ))}
        </div>
        <div
          className={tailwindOverride(
            'flex flex-1 justify-between items-center',
            'h-12 px-4 bg-primary-lightest'
          )}
          id='partial-hours-total-sum'
        >
          <Typography variant='body' weight='medium' className='text-primary'>
            {intl.get('MANAGE_TIME_OFF.TOTAL')}
          </Typography>
          <div className='flex'>
            <Typography
              variant='body'
              weight='medium'
              className='text-primary mr-3'
            >
              {totalHours}
            </Typography>
            <Typography variant='body' weight='medium' className='text-primary'>
              {intl.get('MANAGE_TIME_OFF.HOURS')}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartialHoursAmount;
