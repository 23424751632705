import has from 'lodash/has';
import cloneDeep from 'lodash/cloneDeep';
import {
  ProjectParticipantAllocation,
  ProjectCollaboratorsAllocations,
  ResourceAllocationSections,
  WeekTimeOffAndHolidays,
  ProjectParticipantRole,
} from 'utils/customTypes';
import {
  PROJECT_OWNER,
  RESOURCE_ALLOCATION_TABLE_SECTIONS,
} from 'utils/constants';

export const generateResourceAllocationSections: () => ResourceAllocationSections =
  () =>
    Object.keys(RESOURCE_ALLOCATION_TABLE_SECTIONS).reduce((acc: {}, cur) => {
      const resp = acc as ResourceAllocationSections;
      return {
        ...resp,
        [cur]: {
          id: cur,
          users: {},
        },
      };
    }, {});

export const populateCollaboratorsSection: (
  originalSections: ResourceAllocationSections,
  collaboratorsAllocations: ProjectCollaboratorsAllocations[]
) => ResourceAllocationSections = (
  originalSections,
  collaboratorsAllocations
) => {
  const sections = cloneDeep(originalSections);
  for (const collaboratorsAllocation of collaboratorsAllocations) {
    sections[RESOURCE_ALLOCATION_TABLE_SECTIONS.COLLABORATORS].users = {
      ...sections[RESOURCE_ALLOCATION_TABLE_SECTIONS.COLLABORATORS].users,
      [collaboratorsAllocation.userId]: {
        data: collaboratorsAllocation.collaborator,
        roles: [
          {
            role: collaboratorsAllocation.job_role,
            estimation: {
              start_date: collaboratorsAllocation.start_date,
              end_date: collaboratorsAllocation.end_date,
              estimated_hours: collaboratorsAllocation.estimated_hours,
              userId: collaboratorsAllocation.userId,
              projectLearnOpId: collaboratorsAllocation.projectLearnOpId,
            },
          },
        ],
        assignedProjectTasks: collaboratorsAllocation.assignedProjectTasks,
      },
    };
  }
  return sections;
};

const populateSectionWithParticipantData = (
  sections: ResourceAllocationSections,
  sectionId: string,
  participantData: ProjectParticipantAllocation
) => {
  const {
    roles,
    data,
    assignedProjectTasks,
    holidaysAndTimeOffs = [],
  } = participantData;
  const timeOffs = holidaysAndTimeOffs.filter(
    (timeOff: WeekTimeOffAndHolidays) =>
      timeOff.weeklyTimeOffForUser > 0 || timeOff.holidaysWithinWeek.length > 0
  );
  for (const role of roles) {
    if (!has(sections[sectionId].users, data.id!)) {
      sections[sectionId].users = {
        ...sections[sectionId].users,
        [data.id!]: {
          data,
          roles: [
            {
              role: role.role,
              participationId: role.participationId,
              allocations: role.allocations,
            },
          ],
          assignedProjectTasks,
          holidaysAndTimeOffs: timeOffs,
        },
      };
    } else {
      sections[sectionId].users[data.id!] = {
        ...sections[sectionId].users[data.id!],
        roles: [
          {
            role: role.role,
            participationId: role.participationId,
            allocations: role.allocations,
          },
          ...sections[sectionId].users[data.id!].roles,
        ],
      };
    }
  }
  return sections;
};

export const populateOwnersAndMembersSections: (
  originalSections: ResourceAllocationSections,
  participants: ProjectParticipantAllocation[]
) => ResourceAllocationSections = (originalSections, participants) => {
  let sections = cloneDeep(originalSections);
  for (const participant of participants) {
    const { roles } = participant;
    const isParticipantAProjectOwner = roles.some(
      (role: ProjectParticipantRole) => role.role === PROJECT_OWNER
    );
    if (isParticipantAProjectOwner) {
      sections = populateSectionWithParticipantData(
        sections,
        RESOURCE_ALLOCATION_TABLE_SECTIONS.OWNERS,
        participant
      );
    } else {
      sections = populateSectionWithParticipantData(
        sections,
        RESOURCE_ALLOCATION_TABLE_SECTIONS.MEMBERS,
        participant
      );
    }
  }
  return sections;
};
