import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import classnames from 'classnames';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import intl from 'react-intl-universal';

import {
  Button,
  Dropdown,
  FormItem,
  IconButton,
  Typography,
  Datepicker,
} from '@getsynapse/design-system';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';

import { selectBussinessTeams } from 'state/Organization/organizationSlice';
import { setActiveFilters, getFilters } from 'state/Insights/capacitySlice';
import {
  INSIGHT_CAPACITY_FILTER_PANEL_DATE_OPTIONS as DATE_OPTIONS,
  INSIGHTS_DATE_FILTER_NAME,
  INSIGHTS_CAPACITY_FILTERS,
} from 'utils/constants';
import { InsightsFilters, InsightsFiltersKey } from 'utils/types/filters';
import { rangeDateType } from 'utils/customTypes';
import { getInitialValueForDropDown } from 'utils/functions';

import filterPanelIcon from 'assets/icons/filter-icon-insight.svg';
import collapseIcon from 'assets/icons/collapse.svg';
import expandIcon from 'assets/icons/expand.svg';
import isEqual from 'lodash/isEqual';

export const FILTER_FIELDS = {
  START_DATE: 'startDate',
  END_DATE: 'endDate',
};

type Option = {
  label: string;
  value: string;
};

type Props = {
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  isExpanded: boolean;
};

const FilterSections: React.FC<Props> = ({ setIsExpanded, isExpanded }) => {
  const dispatch = useDispatch();
  const filters = useSelector(getFilters);
  const bussinessTeams = useSelector(selectBussinessTeams);
  const [applyButtonEnabled, setApplyButtonEnabled] = useState(false);
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(
    window.localStorage.getItem(INSIGHTS_DATE_FILTER_NAME.CAPACITY) ===
      'CUSTOM' || false
  );
  const [appliedFilters, setAppliedFilters] = useState<InsightsFilters>({
    startDate: '',
    endDate: '',
    businessUnitIds: [],
  });
  const [selectedBusinessUnitOptions, setSelectedBusinessUnitOptions] =
    useState<Option[]>([]);

  useEffect(() => {
    if (filters?.startDate && filters?.endDate) {
      setAppliedFilters(filters as InsightsFilters);
    }
  }, [filters]);

  const dateOptions = useMemo(
    () =>
      Object.values(DATE_OPTIONS).map((option) => ({
        value: option.key,
        label: intl.get(
          `INSIGHTS_PAGE_TABS.CAPACITY_FILTER.DATE.${option.key}`
        ),
      })),
    []
  );

  const businessUnitsOptions = useMemo(() => {
    return orderBy(bussinessTeams, (businessUnit) => {
      const businessLabel =
        get(businessUnit, 'label') || get(businessUnit, 'title') || '';
      return businessLabel.toLocaleLowerCase();
    }).map((businessUnit) => ({
      label: get(businessUnit, 'label') || get(businessUnit, 'title'),
      value: get(businessUnit, 'value') || get(businessUnit, 'id'),
    }));
  }, [bussinessTeams]) as Option[];

  const defaultDateOption = useMemo(() => {
    const savedDateSelection = window.localStorage.getItem(
      INSIGHTS_DATE_FILTER_NAME.CAPACITY
    );

    const fallBackOption = DATE_OPTIONS.LAST_12_MONTHS;
    const defaultDate = get(
      DATE_OPTIONS,
      savedDateSelection ? savedDateSelection : fallBackOption.key,
      fallBackOption
    );

    return {
      label: intl.get(
        `INSIGHTS_PAGE_TABS.CAPACITY_FILTER.DATE.${defaultDate.key}`
      ),
      value: defaultDate.key,
    };
  }, []);

  const [startFromDateOption, setStartFromDateOption] =
    useState<Option>(defaultDateOption);

  const handleChangeDateFilter = (selectedItem: Option) => {
    setShowCustomDatePicker(selectedItem.value === DATE_OPTIONS.CUSTOM.key);
    setStartFromDateOption(selectedItem);

    if (selectedItem.value !== DATE_OPTIONS.CUSTOM.key) {
      const dateValue = get(
        DATE_OPTIONS,
        `[${startFromDateOption.value}].numberOfDays`,
        ''
      );

      const newStartDate = moment().subtract(dateValue, 'days').format();
      const newEndDate = moment().format();
      setAppliedFilters(() => {
        return {
          startDate: newStartDate,
          endDate: newEndDate,
        };
      });
      setApplyButtonEnabled(true);
    } else {
      setShowCustomDatePicker(true);
      setAppliedFilters(() => {
        return {
          startDate: '',
          endDate: '',
        };
      });
    }
  };

  const resetDateFilter = async () => {
    await setStartFromDateOption(defaultDateOption);
    if (defaultDateOption.label === 'Custom') {
      setShowCustomDatePicker(true);
      if (filters?.startDate && filters?.endDate) {
        setAppliedFilters(filters as InsightsFilters);
      }
    } else {
      setShowCustomDatePicker(false);
    }
  };

  const resetFilters = async () => {
    await resetDateFilter();
    await setSelectedBusinessUnitOptions(
      getInitialValueForDropDown(
        businessUnitsOptions,
        filters &&
          (filters[INSIGHTS_CAPACITY_FILTERS.BUSINESS_TEAMS] as string[])
      )
    );
  };

  const handleApplyFilters = () => {
    window.localStorage.setItem(
      INSIGHTS_DATE_FILTER_NAME.CAPACITY,
      startFromDateOption.value
    );
    let newStartDate = '';
    let newEndDate = '';

    if (startFromDateOption.value === 'CUSTOM') {
      newStartDate = moment(appliedFilters.startDate).format();
      newEndDate = moment(appliedFilters.endDate).format();
    } else {
      const dateValue = get(
        DATE_OPTIONS,
        `[${startFromDateOption.value}].numberOfDays`,
        ''
      );

      newStartDate = moment().subtract(dateValue, 'days').format();
      newEndDate = moment().format();
    }

    window.localStorage.setItem(
      INSIGHTS_DATE_FILTER_NAME.CAPACITY_DATE_FILTER_DATE_RANGE.START_DATE,
      newStartDate
    );
    window.localStorage.setItem(
      INSIGHTS_DATE_FILTER_NAME.CAPACITY_DATE_FILTER_DATE_RANGE.END_DATE,
      newEndDate
    );

    dispatch(
      setActiveFilters({
        ...appliedFilters,
        startDate: newStartDate,
        endDate: newEndDate,
      })
    );
    setApplyButtonEnabled(false);
  };

  const updateFilters = (
    filterKey: InsightsFiltersKey,
    value: Option[] | rangeDateType | boolean
  ) => {
    let filtersCopy = { ...appliedFilters } as InsightsFilters;
    if (filterKey === INSIGHTS_CAPACITY_FILTERS.INSIGHTS_TIMEFRAME) {
      const filterValue = value as rangeDateType;
      const startDate = filterValue.startDate;
      const endDate = filterValue.endDate;
      filtersCopy['startDate'] = startDate;
      filtersCopy['endDate'] = endDate;
    } else {
      const optionValue = value as Option[];
      if (optionValue.length === 0) {
        delete filtersCopy[filterKey];
      } else {
        filtersCopy[filterKey] = optionValue.map(
          (option) => option.value
        ) as string[];
      }
    }

    if (filterKey === INSIGHTS_CAPACITY_FILTERS.BUSINESS_TEAMS) {
      const optionValue = value as Option[];
      setSelectedBusinessUnitOptions(optionValue);
    }
    !isEqual(filters, filtersCopy)
      ? setApplyButtonEnabled(true)
      : setApplyButtonEnabled(false);
    setAppliedFilters(filtersCopy);
  };

  return (
    <div className='flex flex-col h-full'>
      <div className='flex justify-between py-4 px-5'>
        <Typography
          variant='h6'
          weight='medium'
          className={classnames(!isExpanded && 'hidden')}
        >
          {intl.get('INSIGHTS_PAGE_TABS.FILTER.TITLE')}
        </Typography>

        <div
          className={classnames(!isExpanded ? '-ml-2' : 'pr-12 flex space-x-4')}
        >
          <IconButton
            src={filterPanelIcon}
            className={classnames(!isExpanded ? 'hidden' : 'text-2xl')}
            onClick={() => resetFilters()}
          />

          {isExpanded ? (
            <IconButton
              className=''
              src={collapseIcon}
              onClick={() => setIsExpanded(false)}
            />
          ) : (
            <IconButton
              className=''
              src={expandIcon}
              onClick={() => setIsExpanded(true)}
            />
          )}
        </div>
      </div>

      <div
        className={classnames(
          !isExpanded ? 'hidden' : 'w-full overflow-y-auto',
          'flex-1'
        )}
      >
        <div className='px-5'>
          <FormItem
            label={intl.get('INSIGHTS_PAGE.CAPACITY.TIME_FRAME')}
            component='div'
            className='pt-2 pb-6'
            data-testid='insight-capacity-time-frame-filter'
          >
            <Dropdown
              options={dateOptions}
              values={[startFromDateOption]}
              onChange={handleChangeDateFilter}
            />
          </FormItem>
        </div>
        {showCustomDatePicker && (
          <FormItem data-testid='capacity_time-frame-filter-custom'>
            <div className='px-5'>
              <Datepicker
                startDate={
                  appliedFilters.startDate && new Date(appliedFilters.startDate)
                }
                endDate={
                  appliedFilters.endDate && new Date(appliedFilters.endDate)
                }
                minDate={new Date(moment().subtract(365, 'days').format())}
                maxDate={new Date(moment().format())}
                className='w-full pb-5'
                startDateLabel={intl.get('INSIGHTS_PAGE.CAPACITY.START_DATE')}
                startPlaceHolder={intl.get('INSIGHTS_PAGE.CAPACITY.START_DATE')}
                endDateLabel={intl.get('INSIGHTS_PAGE.CAPACITY.END_DATE')}
                endPlaceHolder={intl.get('INSIGHTS_PAGE.CAPACITY.END_DATE')}
                canSelectRange
                onPickDate={(date: rangeDateType) =>
                  updateFilters(
                    INSIGHTS_CAPACITY_FILTERS.INSIGHTS_TIMEFRAME,
                    date
                  )
                }
              />
            </div>
          </FormItem>
        )}
        <div className='px-5'>
          <FormItem label={intl.get('INSIGHTS_PAGE.CAPACITY.BUSINESS_UNITS')}>
            <MultiSelectDropdown
              options={businessUnitsOptions}
              values={selectedBusinessUnitOptions}
              onChange={(option: Option[]) => {
                updateFilters(INSIGHTS_CAPACITY_FILTERS.BUSINESS_TEAMS, option);
              }}
              placeholder={intl.get(
                'INSIGHTS_PAGE.CAPACITY.BUSINESS_UNIT_PLACEHOLDER'
              )}
              triggerProps={{
                'aria-label': intl.get('INSIGHTS_PAGE.CAPACITY.BUSINESS_UNITS'),
                'data-testid': 'capacity_filters__business-teams',
              }}
              listProps={{
                'data-testid': 'capacity_filters__business-teams__options-list',
              }}
            />
          </FormItem>
        </div>
      </div>
      <div
        className={classnames(
          !isExpanded
            ? 'hidden'
            : 'w-full mt-auto border-t border-primary-lighter-two flex py-2 z-5'
        )}
      >
        <div className='flex mt-auto ml-auto mr-5'>
          <Button
            variant='tertiary'
            className='mr-4'
            onClick={() => resetFilters()}
            data-cy='insight-capacity-reset-button'
          >
            {intl.get('INSIGHTS_PAGE_TABS.FILTER.RESET')}
          </Button>
          <Button
            disabled={!applyButtonEnabled}
            onClick={() => handleApplyFilters()}
            data-cy='insight-capacity-apply-button'
          >
            {intl.get('INSIGHTS_PAGE_TABS.FILTER.APPLY')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterSections;
