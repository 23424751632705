import React, { createContext, useContext, useMemo } from 'react';
import { Request, UpdateReqData, RequestQuestion } from 'utils/customTypes';
import { calculateRequestPermissions } from '../helpers/permissions';
import { selectIsActiveRequestAForm } from 'state/ActiveRequest/activeRequestSlice';
import { useSelector } from 'react-redux';
import { selectUser } from 'state/User/userSlice';

export const RequestContext = createContext<{
  areBasicAndRequestDisabled: boolean;
  isAdditionalDisabled: boolean;
  isLDDisabled: boolean;
  isBusinessUnitDisabled: boolean;
  requestHasUpdate: boolean;
  isForm: boolean;
  shouldUseUserBusinessTeamAsDefault: boolean;
  isOwnerOrAdmin: boolean;
}>({
  areBasicAndRequestDisabled: true,
  isAdditionalDisabled: true,
  isLDDisabled: true,
  isBusinessUnitDisabled: true,
  requestHasUpdate: false,
  isForm: false,
  shouldUseUserBusinessTeamAsDefault: false,
  isOwnerOrAdmin: false,
});

export const RequestProvider = ({
  children,
  requestData,
  updatedReqData,
  requestQuestionsData,
}: {
  children: React.ReactNode;
  requestData: Request;
  updatedReqData: UpdateReqData;
  requestQuestionsData: RequestQuestion[];
}) => {
  const isForm = useSelector(selectIsActiveRequestAForm);
  const user = useSelector(selectUser);
  const {
    isOwnerOrAdmin,
    areBasicAndRequestDisabled,
    isAdditionalDisabled,
    isLDDisabled,
    isBusinessUnitDisabled,
    requestHasUpdate,
    shouldUseUserBusinessTeamAsDefault,
  }: any = useMemo(
    () =>
      calculateRequestPermissions(
        requestData,
        isForm,
        user,
        updatedReqData,
        requestQuestionsData
      ),
    [isForm, requestData, requestQuestionsData, updatedReqData, user]
  );

  return (
    <RequestContext.Provider
      value={{
        isOwnerOrAdmin,
        areBasicAndRequestDisabled,
        isAdditionalDisabled,
        isLDDisabled,
        isBusinessUnitDisabled,
        requestHasUpdate,
        isForm,
        shouldUseUserBusinessTeamAsDefault,
      }}
    >
      {children}
    </RequestContext.Provider>
  );
};

export const useRequestContext = () => {
  const context = useContext(RequestContext);
  if (context === undefined) {
    throw new Error('Something wrong with the request context');
  }
  return context;
};
