import { PickerFileMetadata } from 'filestack-js';
import { getImageFileStackConfig } from 'utils/filestack';
import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { PortalBackground } from 'utils/customTypes';
import {
  Typography,
  FileUpload,
  IconButton,
  Radio,
} from '@getsynapse/design-system';
import removeIcon from 'assets/icons/trash-bin-small.svg';

const UploadBackground = ({
  onChange,
  portalImageSetting,
}: {
  onChange: (property: 'portalBackground', value: PortalBackground) => void;
  portalImageSetting: PortalBackground;
}) => {
  const fileStackConfig = getImageFileStackConfig();

  return (
    <div className='mt-4 flex flex-col'>
      <Typography variant='body2' weight='medium'>
        {intl.get('INTAKE_TAB.UPLOAD_BACKGROUND')}
      </Typography>
      <div className='mt-2 flex'>
        <Radio
          name='useImage'
          label={
            <div className='ml-2 w-full h-full'>
              {isEmpty(portalImageSetting.image) ? (
                <div className='mt-2'>
                  <FileUpload
                    className='flex-1'
                    config={fileStackConfig}
                    displayUploadedFiles={false}
                    onFileUploadHandle={(files: PickerFileMetadata[]) => {
                      onChange('portalBackground', {
                        ...portalImageSetting,
                        image: files[0],
                        useDefault: false,
                      });
                    }}
                    customHelperText={
                      <span className='font-semibold text-xs'>
                        {intl.get('INTAKE_TAB.IMAGE_SIZE_HELPER')}
                      </span>
                    }
                    customErrorMessages={{
                      'file-too-large': intl.get('INTAKE_TAB.10MB_FILE_ERROR'),
                    }}
                    hidePickerWhenUploading
                    uploadInstructions={
                      <>
                        {intl.get('INTAKE_TAB.DRAG_HERE')}{' '}
                        <span className='text-purple-darker'>
                          {intl.get('INTAKE_TAB.CHOOSE')}
                        </span>{' '}
                        {intl.get('INTAKE_TAB.FROM_DEVICE')}
                      </>
                    }
                    data-cy='portal-image-picker'
                  />
                </div>
              ) : (
                <div className='h-full flex items-center'>
                  <span
                    className='text-purple-darker text-sm'
                    data-cy='portal-image-picker-name'
                  >
                    {portalImageSetting.image?.filename}
                  </span>
                  <IconButton
                    className='ml-4 cursor-pointer'
                    iconClassname='text-primary-dark text-base'
                    data-cy='portal-image-picker-delete'
                    src={removeIcon}
                    onClick={() =>
                      onChange('portalBackground', {
                        ...portalImageSetting,
                        image: undefined,
                        useDefault: true,
                      })
                    }
                  />
                </div>
              )}
            </div>
          }
          value='useDefault'
          inputClassName={isEmpty(portalImageSetting.image) ? 'mt-6' : ''}
          inputProps={{
            checked: !get(portalImageSetting, 'useDefault', true),
            onChange: () => {
              onChange('portalBackground', {
                ...portalImageSetting,
                useDefault: false,
              });
            },
          }}
        />
      </div>
      <div className='mt-4 flex items-center'>
        <Radio
          name='useDefault'
          label={
            <span className='ml-2 text-sm mt-0.5'>
              {intl.get('INTAKE_TAB.USE_DEFAULT')}
            </span>
          }
          value='useDefault'
          inputProps={{
            checked: get(portalImageSetting, 'useDefault', true),
            onChange: () => {
              onChange('portalBackground', {
                ...portalImageSetting,
                useDefault: true,
              });
            },
          }}
        />
      </div>
    </div>
  );
};

export default UploadBackground;
