import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'state/store';
import {
  fetchTeamMemberProjectsList,
  selectTeamMemberIsExpanded,
} from 'state/Capacities/TeamMemberProjectsList/teamMemberProjectsListSlice';
import useScrollHandlerContext from '../ScrollHandler/useScrollHandlerContext';
import ProjectsList from './components/ProjectsList/ProjectsList';

const TeamMemberProjects: React.FC<{
  teamId: string;
  memberId: string;
  bgColor: string;
}> = ({ teamId, memberId, bgColor }) => {
  const { removeTeamMemberProjects } = useScrollHandlerContext();
  const dispatch = useDispatch();
  const prevTeamMemberExpandedStatus = useRef<boolean>(false);
  const isTeamMemberExpanded = useSelector((state: RootState) =>
    selectTeamMemberIsExpanded(state, teamId, memberId)
  );
  useEffect(() => {
    if (prevTeamMemberExpandedStatus.current !== isTeamMemberExpanded) {
      prevTeamMemberExpandedStatus.current = isTeamMemberExpanded;
      if (isTeamMemberExpanded) {
        dispatch(fetchTeamMemberProjectsList({ teamId, memberId }));
      }
      if (!isTeamMemberExpanded) {
        removeTeamMemberProjects(teamId, memberId);
      }
    }
  }, [
    isTeamMemberExpanded,
    teamId,
    memberId,
    removeTeamMemberProjects,
    dispatch,
  ]);

  return (
    <div
      id={`team-${teamId}__member-${memberId}__projects-list`}
      aria-labelledby={`team-${teamId}__member-${memberId}__row`}
      hidden={!isTeamMemberExpanded}
      className={bgColor}
    >
      <ProjectsList teamId={teamId} memberId={memberId} />
    </div>
  );
};

export default TeamMemberProjects;
