import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import BasicInfoLoader from '../../Components/SkeletonLoaders/BasicInfoLoader';
import { ApprovedRequestAnalytic } from 'utils/customTypes';
import { INSIGHTS_PAGE_COLORS } from 'utils/constants';

const ApprovedRequestsSkeletonLoader = () => {
  const skeletonColor = INSIGHTS_PAGE_COLORS.SKELETON_LOADER_COLOR;

  const mockApprovedRequests: ApprovedRequestAnalytic[] = [
    {
      businessTeamTitle: 'Fin',
      requestCount: 20,
    },
    {
      businessTeamTitle: 'HR',
      requestCount: 10,
    },
    {
      businessTeamTitle: 'IT',
      requestCount: 21,
    },
    {
      businessTeamTitle: 'Mkt',
      requestCount: 4,
    },
    {
      businessTeamTitle: 'Sales',
      requestCount: 22,
    },
  ];

  return (
    <div className='flex w-full h-full'>
      <div className='w-1/2'>
        <BasicInfoLoader />
      </div>

      <div className='w-1/2 h-full flex flex-col pt-8 animate-pulse'>
        <ResponsiveContainer width='85%' height='90%'>
          <BarChart
            data={mockApprovedRequests}
            margin={{
              top: 25,
            }}
          >
            <XAxis
              tickLine={false}
              tick={false}
              dataKey='businessUnitName'
              padding={{ left: 15 }}
              stroke={skeletonColor}
            />
            <YAxis
              tickLine={false}
              tick={false}
              stroke={skeletonColor}
              domain={[0, 'dataMax + 10']}
            />
            <Bar dataKey='requestsTotal' fill={skeletonColor} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ApprovedRequestsSkeletonLoader;
