import React from 'react';
import { useInView } from 'react-intersection-observer';
import debounce from 'lodash/debounce';
import { tailwindOverride } from '@getsynapse/design-system';
import { VIEW_SETTING_VALUES } from 'utils/constants/capacityView';
import useScrollHandlerContext from 'Pages/TeamsPage/views/TeamsCapacity/components/CapacityTable/components/ScrollHandler/useScrollHandlerContext';
import NoDailyCapacity from 'Pages/TeamsPage/views/TeamsCapacity/components/DailyCapacity/components/DaysCarousel/components/NoDailyCapacity/NoDailyCapacity';
import NoWeeklyCapacity from 'Pages/TeamsPage/views/TeamsCapacity/components/BetaWeeklyCapacity/components/NoWeeklyCapacity/NoWeeklyCapacity';

const LastMemberRow: React.FC<{
  teamId: string;
  onLastMemberRowInView: (inView: boolean) => void;
  numberOfMembers: number;
}> = ({ teamId, numberOfMembers, onLastMemberRowInView }) => {
  const { rootId, capacityView } = useScrollHandlerContext();
  let bgColor = 'bg-neutral-white';
  if (numberOfMembers % 2 === 0) {
    bgColor = 'bg-neutral-lightest-two';
  }

  const debouncenOnChangeHandler = debounce(
    (inView: boolean) => onLastMemberRowInView(inView),
    500
  );
  const { ref: elementRef } = useInView({
    root: rootId,
    threshold: 0.5,
    rootMargin: '-80px 0px 0px',
    onChange: debouncenOnChangeHandler,
  });

  return (
    <div
      ref={elementRef}
      className={tailwindOverride(
        'w-full h-26 flex',
        'member-row last-member-row',
        bgColor
      )}
    >
      <div className={tailwindOverride('w-2/6 h-full flex')}>
        <div className='w-10 h-full' />
        <div
          className={tailwindOverride(
            'h-full',
            'flex flex-1 items-center',
            'animate-pulse gap-x-2'
          )}
        >
          <div className='w-6 h-6 rounded-full bg-primary-lighter' />
          <div className='flex flex-1 flex-col gap-y-2'>
            <div className='w-2/5 h-4 rounded-sm bg-primary-lighter' />
            <div className='w-3/5 h-4 rounded-sm bg-primary-lighter' />
          </div>
        </div>
        <div
          className={tailwindOverride(
            'w-1/3 h-full',
            'flex flex-col justify-center',
            'animate-pulse gap-y-2'
          )}
        >
          <div className='w-3/5 h-4 rounded-sm bg-primary-lighter' />
          <div className='w-3/5 h-4 rounded-sm bg-primary-lighter' />
        </div>
      </div>
      <div className={tailwindOverride('w-4/6 h-full flex')}>
        {capacityView === VIEW_SETTING_VALUES.DAILY && (
          <NoDailyCapacity id={`${teamId}__last-member-row`} isLoading />
        )}
        {(capacityView === VIEW_SETTING_VALUES.BETA_WEEKLY ||
          capacityView === VIEW_SETTING_VALUES.WEEKLY) && (
          <NoWeeklyCapacity id={`${teamId}__last-member-row`} isLoading />
        )}
      </div>
    </div>
  );
};

export default LastMemberRow;
