import {
  getAllocationSummaryStyles,
  noCapacityAndAssignedHoursAllocationSummaryStyle,
  noCapacityWithAssignedHoursAllocationSummaryStyle,
  numberFormatter,
  calculateCapacityPercentage,
} from 'Pages/TeamsPage/views/TeamsCapacity/components/DailyCapacity/helpers';
import { AllocationSummaryStyles } from 'utils/types/dailyTeamsCapacity';
import { VIEW_SETTING_VALUES } from 'utils/constants/capacityView';
import { CapacityView } from 'utils/types/capacityView';

export interface UseAllocationSummaryPillProps {
  assignedHours: number;
  defaultCapacity: number;
  timeOff?: number;
  capacityView?: CapacityView;
  level?: 'team' | 'member';
}

interface UseAllocationSummaryPillReturn {
  styles: AllocationSummaryStyles;
  label: string;
}

const useAllocationSummaryPill: (
  props: UseAllocationSummaryPillProps
) => UseAllocationSummaryPillReturn = ({
  assignedHours,
  defaultCapacity,
  timeOff = 0,
  capacityView = VIEW_SETTING_VALUES.DAILY,
  level = 'team',
}) => {
  let capacityPercentage = calculateCapacityPercentage(
    assignedHours,
    defaultCapacity,
    timeOff || 0
  );
  let styles = getAllocationSummaryStyles(capacityPercentage);
  let label = '';

  if (level === 'member') {
    if (defaultCapacity === 0 && assignedHours === 0 && timeOff === 0) {
      styles = noCapacityAndAssignedHoursAllocationSummaryStyle;
      capacityPercentage = 100;
    }

    if (defaultCapacity === 0 && (assignedHours > 0 || timeOff)) {
      styles = noCapacityWithAssignedHoursAllocationSummaryStyle;
      capacityPercentage = 100;
    }
  }

  if (capacityView === VIEW_SETTING_VALUES.DAILY) {
    label = `${numberFormatter.format(assignedHours)}h`;
  }

  if (capacityView === VIEW_SETTING_VALUES.WEEKLY) {
    label = `${capacityPercentage}%`;
  }

  return {
    styles,
    label,
  };
};

export default useAllocationSummaryPill;
