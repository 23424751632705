import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { tailwindOverride, Typography } from '@getsynapse/design-system';
import {
  fetchTaskContent,
  selectIsFetchingLinkedTaskContent,
} from 'state/LinkedTaskContent/linkedTaskContentSlice';
import LinkedContentCardsList from './components/LinkedContentCardsList';

interface LinkedContentProps {
  taskId: string;
}

const LinkedContent: FC<LinkedContentProps> = ({ taskId }) => {
  const dispatch = useDispatch();
  const isFetchingLinkedTaskContent = useSelector(
    selectIsFetchingLinkedTaskContent
  );

  useEffect(() => {
    dispatch(fetchTaskContent(taskId));
  }, [taskId, dispatch]);

  if (isFetchingLinkedTaskContent) {
    return <div>Loading...</div>;
  }

  return (
    <div className={tailwindOverride('w-full py-8', 'flex flex-col gap-y-6')}>
      <div className={tailwindOverride('w-full flex justify-between')}>
        <div className='flex flex-col gap-y-2'>
          <Typography variant='h5' className='text-primary'>
            {intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.TITLE')}
          </Typography>
          <Typography variant='label' className='text-neutral'>
            {intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.SUBTITLE')}
          </Typography>
        </div>
      </div>
      <LinkedContentCardsList />
    </div>
  );
};

export default LinkedContent;
