import { FC, ComponentProps } from 'react';
import intl from 'react-intl-universal';
import { TableHeadCell, tailwindOverride } from '@getsynapse/design-system';
import { TIME_OFFS_TABLE_HEADERS } from 'utils/constants/manageTimeOff';

export interface TableHeaderProps {
  sortBy: string;
  order: string;
  handleSort: (sortBy: string, order: string) => void;
}

const TableHeader: FC<TableHeaderProps> = ({ sortBy, order, handleSort }) => {
  const headCells: ComponentProps<typeof TableHeadCell>[] = Object.entries(
    TIME_OFFS_TABLE_HEADERS
  ).map(([key, value]) => {
    const isActive = sortBy === value;
    return {
      columnName: value,
      content: intl.get(`MANAGE_TIME_OFF.TABLE.COLUMNS.${key}`),
      className: 'px-4 py-3',
      style: { width: '30%' },
      isActive,
      isDesc: isActive && order === 'desc',
      isAsc: isActive && order === 'asc',
      'aria-label': intl
        .get(`MANAGE_TIME_OFF.TABLE.COLUMNS.${key}`)
        .toLocaleLowerCase(),
    };
  });

  return (
    <thead
      className={tailwindOverride(
        'h-10 bg-primary-lightest',
        'text-primary text-caption font-semibold',
        'sticky top-0 z-1'
      )}
    >
      <tr>
        {headCells.map((headCell) => (
          <TableHeadCell
            key={headCell.columnName}
            {...headCell}
            handleSort={(columnName: string) => {
              const newOrder =
                headCell.isActive && headCell.isAsc ? 'desc' : 'asc';
              handleSort(columnName, newOrder);
            }}
            className={tailwindOverride(
              'text-left h-full shadow-table-column last:shadow-none font-semibold',
              'sticky top-0 z-1 bg-primary-lightest',
              { 'bg-secondary-lightest': headCell.isActive }
            )}
          />
        ))}
        <TableHeadCell
          content=''
          className={tailwindOverride(
            'w-24 px-4 py-3',
            'sticky top-0 z-1 bg-primary-lightest'
          )}
        />
      </tr>
    </thead>
  );
};

export default TableHeader;
