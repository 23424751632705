import {
  FormEvent,
  ChangeEvent,
  useState,
  useCallback,
  useEffect,
} from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import { some, get, set } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import Auth from '@aws-amplify/auth';
import {
  Button,
  Checkbox,
  Dropdown,
  FormItem,
  TextField,
  Typography,
  useLink,
} from '@getsynapse/design-system';
import {
  addBusinessUser,
  addUserAndOrganization,
} from 'state/UsersManagement/usersManagementSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import {
  getUserBasicInfo,
  selectUserBasicInfo,
  selfRegistration,
} from 'state/User/userSlice';
import {
  COUNTRIES,
  PATHS,
  USER_TYPES,
  EMPLOYMENT_TYPE,
  USER_ROLES,
  USER_STATUS,
  EMAIL_REGEX,
} from 'utils/constants';
import { useMemo } from 'react';
import { Option, User } from 'utils/customTypes';
import PasswordTooltip from 'Atoms/PasswordTooltip/PasswordTooltip';

const RegisterPage = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId } = useParams<{ userId: string }>();
  const linkStyles = useLink();
  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const userSelector = useSelector(selectUserBasicInfo);

  const email = query.get('email');

  const accountId = query.get('accountId');

  const token = query.get('token');

  const countryOptions = useMemo(
    () =>
      COUNTRIES.map((country) => ({
        value: country,
        label: intl.get(`COUNTRIES.${country}`),
      })),
    []
  );
  useEffect(() => {
    if (userId) {
      dispatch(getUserBasicInfo(userId));
    }
  }, [userId, dispatch]);

  const [userData, setUserData] = useState({
    email: email || '',
    firstName: '',
    lastName: '',
    company: '',
    jobTitle: '',
    country: '',
    password: '',
    confirmPassword: '',
    agreeToTerms: false,
  });

  const [data, setData] = useState({
    emailError: false,
    passwordError: false,
    registrationError: '',
    isSubmitting: false,
  });

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  useEffect(() => {
    if (userSelector.id) {
      if (
        userSelector.status !== USER_STATUS.INVITED &&
        userSelector.status !== USER_STATUS.INVITED_DISABLED &&
        !data.isSubmitting
      ) {
        history.push(PATHS.LOGIN);
      }
      setUserData((prevState) => ({
        ...prevState,
        email: userSelector.email!,
      }));
    }
  }, [userSelector, history, data.isSubmitting]);

  const isDisabled = useMemo(() => {
    if (some(userData, isEmpty)) {
      if (accountId) {
        if (
          userData.email.trim() &&
          userData.password.trim() &&
          userData.confirmPassword.trim() &&
          userData.password === userData.confirmPassword &&
          isEmailValid
        ) {
          return userData.agreeToTerms ? false : true;
        } else {
          return true;
        }
      } else {
        if (
          userData.email.trim() &&
          userData.firstName.trim() &&
          userData.lastName.trim() &&
          (userData.company.trim() || userSelector.id) &&
          userData.jobTitle.trim() &&
          userData.country.trim() &&
          userData.password.trim() &&
          userData.confirmPassword.trim() &&
          userData.password === userData.confirmPassword &&
          isPasswordValid &&
          isEmailValid
        ) {
          return userData.agreeToTerms ? false : true;
        } else {
          return true;
        }
      }
    }
    return false;
  }, [accountId, userData, userSelector.id, isPasswordValid, isEmailValid]);

  const createOrgAndUser = async () => {
    const organization = {
      account_name: userData.company,
      account_owner: userData.email,
      token_flag: 'invalid',
    };
    if (token) {
      set(organization, 'token', token);
    }
    const user = await dispatch(
      addUserAndOrganization({
        data: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          jobTitle: userData.jobTitle,
          employmentType: EMPLOYMENT_TYPE[0],
        },
        country_iso_3166_1_alpha_2_code: userData.country,
        type: USER_TYPES.L_D as User['type'],
        default_capacity: 40,
        role: USER_ROLES.ADMIN,
        status: 'registered',
        organization,
      })
    );
    return get(user, 'payload', {});
  };

  const cognitoSignUp = async () => {
    try {
      await Auth.signUp({
        username: userData.email!.toLowerCase().trim(),
        password: userData.password,
        attributes: {
          given_name: userData.firstName,
          family_name: userData.lastName,
        },
      });
      return true;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const createUser = async (organizationId: string) => {
    const userInfo = {
      data: {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
      },
      type: USER_TYPES.BUSINESS as User['type'],
      role: USER_ROLES.USER,
      organization_id: organizationId,
      status: 'registered',
    };
    const newUser = await dispatch(addBusinessUser(userInfo));
    return get(newUser, 'payload', {});
  };

  const createUserInDBandCognito = async () => {
    const user = accountId
      ? await createUser(accountId)
      : await createOrgAndUser();
    setData((prev) => ({
      ...prev,
      registrationError: '',
      isSubmitting: true,
    }));
    return user;
  };

  const updateUserAndCreateInCognito = async () => {
    const updateFields = {
      data: {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        jobTitle: userData.jobTitle,
      },
      country_iso_3166_1_alpha_2_code: userData.country,
      status: USER_STATUS.REGISTERED,
    };
    const user = await dispatch(selfRegistration({ userId, updateFields }));

    setData((prev) => ({
      ...prev,
      registrationError: '',
      isSubmitting: true,
    }));
    return get(user, 'payload', {});
  };

  const validateEmail = () => {
    if (userData.email) {
      const isEmailValid = EMAIL_REGEX.test(userData.email);
      setIsEmailValid(isEmailValid);
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      const user = userSelector.id
        ? await updateUserAndCreateInCognito()
        : await createUserInDBandCognito();
      const errorCode = get(user, 'code') || get(user, 'error');
      if (errorCode) {
        const message =
          errorCode === 404
            ? intl.get('TRIAL_REGISTRATION_PAGE.INVALID_TOKEN_ERROR')
            : errorCode === 500
            ? intl.get('TRIAL_REGISTRATION_PAGE.INVALID_ACCOUNT_ERROR')
            : intl.get('TRIAL_REGISTRATION_PAGE.USERNAME_EXISTS_ERROR');
        dispatch(
          showNotificationBanner({
            notificationVariant: 'error',
            notificationText: message,
            timeout: 7000,
          })
        );
        return;
      }
      await cognitoSignUp();
      history.push(`${PATHS.ALMOST_THERE}/${userData.email!}`);
    } catch (error) {
      const message = intl.get('TRIAL_REGISTRATION_PAGE.USERNAME_EXISTS_ERROR');
      dispatch(
        showNotificationBanner({
          notificationVariant: 'error',
          notificationText: message,
          timeout: 7000,
        })
      );
    }
  };

  const onUserDataChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setUserData((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    },
    []
  );

  const setConfirmPassword = (event: ChangeEvent<HTMLInputElement>) => {
    const value = get(event, 'target.value') || '';

    setUserData((prev) => ({
      ...prev,
      confirmPassword: value,
    }));

    if (userData.password.trim() && value.trim()) {
      if (userData.password !== value) {
        setData((prev) => ({ ...prev, passwordError: true }));
        return;
      }
    }
    setData((prev) => ({ ...prev, passwordError: false }));
    return;
  };

  const setCountry = (event: ChangeEvent<HTMLInputElement>) => {
    const option = countryOptions.filter(
      (country) =>
        country.label.toLowerCase() === event?.target.value.trim().toLowerCase()
    );
    if (option.length) {
      setUserData((prev) => ({ ...prev, country: option[0].value }));
    } else {
      setUserData((prev) => ({ ...prev, country: '' }));
    }
  };

  return (
    <div>
      <Typography variant='h2' weight='string' className='text-center'>
        {intl.get('TRIAL_REGISTRATION_PAGE.HEADING')}
      </Typography>

      <form className='mt-4 flex flex-col items-center' onSubmit={handleSubmit}>
        <div className='grid grid-cols-2 gap-4 w-full'>
          <FormItem
            label={intl.get('TRIAL_REGISTRATION_PAGE.INPUT.EMAIL.LABEL')}
            labelProps={{
              required: true,
              state: isEmailValid ? 'default' : 'error',
            }}
            component='div'
            className='col-span-2'
            helpText={
              !isEmailValid && intl.get('LOGIN_PAGE.INPUT.INVALID_HELP_TEXT')
            }
            helpTextProps={{
              state: isEmailValid ? 'default' : 'error',
            }}
          >
            <TextField
              variant='text'
              placeholder={intl.get(
                'TRIAL_REGISTRATION_PAGE.INPUT.EMAIL.PLACEHOLDER'
              )}
              name='email'
              value={userData.email}
              onChange={onUserDataChange}
              disabled={userSelector.id ? true : false}
              onBlur={validateEmail}
            />
          </FormItem>
          {!accountId && (
            <>
              <FormItem
                label={intl.get(
                  'TRIAL_REGISTRATION_PAGE.INPUT.FIRST_NAME.LABEL'
                )}
                labelProps={{ required: true }}
                component='div'
              >
                <TextField
                  variant='text'
                  placeholder={intl.get(
                    'TRIAL_REGISTRATION_PAGE.INPUT.FIRST_NAME.PLACEHOLDER'
                  )}
                  name='firstName'
                  value={userData.firstName}
                  onChange={onUserDataChange}
                />
              </FormItem>

              <FormItem
                label={intl.get(
                  'TRIAL_REGISTRATION_PAGE.INPUT.LAST_NAME.LABEL'
                )}
                labelProps={{ required: true }}
                component='div'
              >
                <TextField
                  variant='text'
                  placeholder={intl.get(
                    'TRIAL_REGISTRATION_PAGE.INPUT.LAST_NAME.PLACEHOLDER'
                  )}
                  name='lastName'
                  value={userData.lastName}
                  onChange={onUserDataChange}
                />
              </FormItem>

              {!userSelector.id && (
                <FormItem
                  label={intl.get(
                    'TRIAL_REGISTRATION_PAGE.INPUT.COMPANY.LABEL'
                  )}
                  labelProps={{ required: true }}
                  component='div'
                >
                  <TextField
                    variant='text'
                    placeholder={intl.get(
                      'TRIAL_REGISTRATION_PAGE.INPUT.COMPANY.PLACEHOLDER'
                    )}
                    name='company'
                    value={userData.company}
                    onChange={onUserDataChange}
                  />
                </FormItem>
              )}

              <FormItem
                label={intl.get(
                  'TRIAL_REGISTRATION_PAGE.INPUT.JOB_TITLE.LABEL'
                )}
                labelProps={{ required: true }}
                component='div'
              >
                <TextField
                  variant='text'
                  placeholder={intl.get(
                    'TRIAL_REGISTRATION_PAGE.INPUT.JOB_TITLE.PLACEHOLDER'
                  )}
                  name='jobTitle'
                  value={userData.jobTitle}
                  onChange={onUserDataChange}
                />
              </FormItem>

              <FormItem
                label={intl.get('TRIAL_REGISTRATION_PAGE.INPUT.COUNTRY.LABEL')}
                labelProps={{ required: true }}
                component='div'
                className={classnames({ 'col-span-2': !userSelector.id })}
              >
                <Dropdown
                  options={countryOptions}
                  placeholder={intl.get(
                    'TRIAL_REGISTRATION_PAGE.INPUT.COUNTRY.PLACEHOLDER'
                  )}
                  values={[
                    {
                      label: intl.get(`COUNTRIES.${userData.country}`),
                      value: userData.country,
                    },
                  ]}
                  filterable
                  triggerProps={{
                    onChange: setCountry,
                    className: 'h-6',
                  }}
                  onChange={(option: Option) =>
                    setUserData((prev) => ({ ...prev, country: option.value }))
                  }
                />
              </FormItem>
            </>
          )}
          <FormItem
            label={intl.get('TRIAL_REGISTRATION_PAGE.INPUT.PASSWORD.LABEL')}
            labelProps={{
              required: true,
              state: data.passwordError ? 'error' : 'default',
            }}
            name='password'
            helpText={
              data.passwordError &&
              intl.get('LOGIN_PAGE.INPUT.PASSWORD_NOT_MATCH')
            }
            helpTextProps={{
              state: data.passwordError ? 'error' : 'default',
            }}
            component='div'
            className={classnames({ 'col-span-2': accountId })}
          >
            <PasswordTooltip
              trigger={
                <TextField
                  variant='password'
                  placeholder={intl.get(
                    'TRIAL_REGISTRATION_PAGE.INPUT.PASSWORD.PLACEHOLDER'
                  )}
                  value={userData.password}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setUserData((prev) => ({
                      ...prev,
                      password: event.target.value,
                    }));
                    setData((prev) => ({ ...prev, passwordError: false }));
                  }}
                  divProps={{ className: 'relative z-0' }}
                />
              }
              className='z-10 -top-2 ml-57.5'
              password={userData.password}
              setIsPasswordValid={setIsPasswordValid}
            />
          </FormItem>

          <FormItem
            label={intl.get(
              'TRIAL_REGISTRATION_PAGE.INPUT.CONFIRM_PASSWORD.LABEL'
            )}
            labelProps={{
              required: true,
              state: data.passwordError ? 'error' : 'default',
            }}
            helpText={
              data.passwordError &&
              intl.get('LOGIN_PAGE.INPUT.PASSWORD_NOT_MATCH')
            }
            helpTextProps={{
              state: data.passwordError ? 'error' : 'default',
            }}
            component='div'
            className={classnames({ 'col-span-2': accountId })}
          >
            <TextField
              variant='password'
              name='passwordConfirm'
              placeholder={intl.get(
                'TRIAL_REGISTRATION_PAGE.INPUT.CONFIRM_PASSWORD.PLACEHOLDER'
              )}
              value={userData.confirmPassword}
              onChange={setConfirmPassword}
              divProps={{ className: 'relative z-0' }}
            />
          </FormItem>
        </div>

        <div className='mt-4 mb-9 flex justify-between w-full text-body2'>
          <Checkbox
            label={intl.getHTML('TRIAL_REGISTRATION_PAGE.TERMS_CHECK', {
              linkClass: `class='no-underline ${linkStyles}'`,
            })}
            checked={userData.agreeToTerms}
            onChange={(event) =>
              setUserData((prev) => ({
                ...prev,
                agreeToTerms: event.target.checked,
              }))
            }
          />
          <Link
            to={PATHS.LOGIN}
            className={classnames('no-underline', linkStyles)}
          >
            {intl.get('TRIAL_REGISTRATION_PAGE.LOGIN_BUTTON')}
          </Link>
        </div>

        <Button
          variant='primary'
          type='submit'
          className={classnames('w-74 justify-center', {
            'cursor-not-allowed': isDisabled,
          })}
          disabled={isDisabled}
        >
          {intl.get('TRIAL_REGISTRATION_PAGE.REGISTER_BUTTON')}
        </Button>
      </form>
    </div>
  );
};

export default RegisterPage;
