const ROICostsSkeletonLoader = () => (
  <div
    className='flex-grow overflow-y-auto bg-neutral-white'
    data-testid='roi-costs_skeleton_loader'
  >
    <div className='h-full flex flex-col'>
      <div className='h-14 flex items-center px-6'>
        <div className='h-6 w-1/2 bg-neutral-lighter-two animate-pulse' />
      </div>
      <div className='mb-6 mx-6 rounded-lg border border-neutral-lighter-two flex-grow'>
        <div className='border-b border-neutral-lighter-two h-12 px-6 flex items-center'>
          <div className='h-4 w-2/12 bg-neutral-lighter-two animate-pulse' />
        </div>
        <div className='w-2/3 pt-6 pl-8'>
          <div className='h-4 w-1/2 bg-neutral-lighter-two animate-pulse' />
          <div className='h-4 w-11/12 bg-neutral-lighter-two animate-pulse mt-3' />
          <div className='h-20 w-full bg-neutral-lighter-two animate-pulse mt-8' />
          <div className='grid grid-cols-3 gap-x-5 mt-9'>
            <div>
              <div className='h-4 w-3/4 bg-neutral-lighter-two animate-pulse' />
              <div className='h-10 w-full bg-neutral-lighter-two animate-pulse mt-4' />
            </div>
            <div>
              <div className='h-4 w-1/4 bg-neutral-lighter-two animate-pulse' />
              <div className='h-10 w-full bg-neutral-lighter-two animate-pulse mt-4' />
            </div>
            <div>
              <div className='h-4 w-3/4 bg-neutral-lighter-two animate-pulse' />
              <div className='h-10 w-full bg-neutral-lighter-two animate-pulse mt-4' />
            </div>
          </div>
          <div className='mt-12 h-5 w-1/6 bg-neutral-lighter-two animate-pulse' />
        </div>
      </div>
    </div>
  </div>
);

export default ROICostsSkeletonLoader;
