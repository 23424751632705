import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { selectDailyMemberCapacity } from 'state/DailyTeamsCapacity/TeamMembersCapacity/teamMembersCapacitySlice';
import { DailyCapacityProps } from 'utils/types/dailyTeamsCapacity';
import NoDailyCapacity from '../../DaysCarousel/components/NoDailyCapacity/NoDailyCapacity';
import DaysCarousel from '../../DaysCarousel/DaysCarousel';
import DailyMemberCapacity from '../DailyMemberCapacity/DailyMemberCapacity';

const DailyMemberCapacities: React.FC<{
  memberId: string;
}> = ({ memberId }) => {
  const dailyCapacity = useSelector((state: RootState) =>
    selectDailyMemberCapacity(state, memberId)
  );
  if (Object.keys(dailyCapacity).length === 0) {
    return (
      <div className='w-4/6 h-full flex'>
        <NoDailyCapacity id={`member-${memberId}`} isLoading />
      </div>
    );
  }
  return (
    <div className='w-4/6 h-full flex'>
      <DaysCarousel<DailyCapacityProps>
        id={`member-${memberId}`}
        component={DailyMemberCapacity}
        items={dailyCapacity}
      />
    </div>
  );
};

export default DailyMemberCapacities;
