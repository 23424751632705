import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppliedFilters } from '@getsynapse/design-system';
import { TimeOffEntryFilters } from 'types/store/manageTimeOff';
import { selectTimeOffTypesByCode } from 'state/ManageTimeOff/TimeOffTypes/timeOffTypesSlice';

interface AppliedFiltersTagsProps {
  savedFilters: TimeOffEntryFilters;
  onUpdateFilters: (filters: TimeOffEntryFilters) => void;
}

interface FilterTag {
  label: string;
  key: string;
}

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});

const AppliedFiltersTags: FC<AppliedFiltersTagsProps> = ({
  savedFilters,
  onUpdateFilters,
}) => {
  const timeOffTypes = useSelector(selectTimeOffTypesByCode);

  const formatDate: (dateString: string) => string = (dateString) => {
    const date = new Date(dateString.replace(/-/g, '/'));
    return dateFormatter.format(date);
  };

  const appliefFilters = useMemo(() => {
    const tags: FilterTag[] = [];
    if (savedFilters.startDate && savedFilters.endDate) {
      tags.push({
        label: `${formatDate(savedFilters.startDate)} - ${formatDate(
          savedFilters.endDate
        )}`,
        key: 'dateRange',
      });
    }

    if (savedFilters.timeOffType) {
      tags.push({
        label: timeOffTypes[savedFilters.timeOffType].name,
        key: 'timeOffType',
      });
    }
    return tags;
  }, [savedFilters, timeOffTypes]);

  const handleRemoveFilter = (filterTag: FilterTag) => {
    const updatedFilters = { ...savedFilters };
    if (filterTag.key === 'dateRange') {
      updatedFilters.startDate = '';
      updatedFilters.endDate = '';
    }

    if (filterTag.key === 'timeOffType') {
      updatedFilters.timeOffType = '';
    }

    onUpdateFilters(updatedFilters);
  };

  const handleClearAll = () => onUpdateFilters({});

  return (
    <AppliedFilters
      filters={appliefFilters}
      onRemoveFilter={handleRemoveFilter}
      onClearAll={handleClearAll}
    />
  );
};

export default AppliedFiltersTags;
