import { useEffect } from 'react';
import VendorsTable from './VendorsTable';
import { useSelector, useDispatch } from 'react-redux';
import {
  getVendors,
  selectOrderedVendors,
  setVendorsOrder,
  setSearchParam,
} from 'state/Vendors/vendorsSlice';

const VendorsPage: React.FC<{ isVisible: boolean }> = ({ isVisible }) => {
  const dispatch = useDispatch();
  const vendors = useSelector(selectOrderedVendors);

  useEffect(() => {
    dispatch(
      setVendorsOrder({
        order: 'asc',
        orderBy: 'vendorName',
      })
    );
    dispatch(setSearchParam({ searchParam: '' }));
    dispatch(getVendors());
  }, [dispatch]);

  useEffect(() => {
    if (!isVisible) {
      dispatch(
        setVendorsOrder({
          order: 'asc',
          orderBy: 'vendorName',
        })
      );
      dispatch(setSearchParam({ searchParam: '' }));
    }
  }, [isVisible, dispatch]);

  const handleSort = (orderByParam: string, order: 'desc' | 'asc') => {
    dispatch(setVendorsOrder({ order, orderBy: orderByParam }));
  };

  const handleSearch = (searchParam: string) =>
    dispatch(setSearchParam({ searchParam }));

  return (
    <VendorsTable
      data={vendors}
      handleSort={handleSort}
      handleSearch={handleSearch}
      isVisible={isVisible}
    />
  );
};

export default VendorsPage;
