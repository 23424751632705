import intl from 'react-intl-universal';
import { IconButton, Tooltip } from '@getsynapse/design-system';

interface ProjectSideDrawerToggleProps {
  projectId: string;
  onOpenProjectPanel: (projectId: string) => void;
}

const ProjectSideDrawerToggle: React.FC<ProjectSideDrawerToggleProps> = ({
  projectId,
  onOpenProjectPanel,
}) => {
  return (
    <Tooltip
      openMode='hover1'
      timeout={0}
      showPopper
      position='topCenter'
      contentProps={{
        className: 'px-3 py-2 text-sm z-50',
      }}
      usePortal
      trigger={
        <IconButton
          name='pencil-outline'
          iconClassname='pointer-events-none'
          className='text-base text-neutral-dark p-1 hover:bg-neutral-lightest hover:shadow-allocation-table rounded'
          data-cy={`quick-edit-button-${projectId}`}
          onClick={() => onOpenProjectPanel(projectId)}
        />
      }
    >
      {intl.get('SIDE_PANEL.QUICK_EDIT')}
    </Tooltip>
  );
};

export default ProjectSideDrawerToggle;
