import React from 'react';
import { tailwindOverride } from '@getsynapse/design-system';
import { ProjectCapacity } from 'utils/types/teamsCapacity';
import ProjectData from './ProjectData/ProjectData';
import Capacity from './ProjectCapacity/ProjectCapacity';

const ProjectRow: React.FC<{
  teamId: string;
  memberId: string;
  projectCapacity: ProjectCapacity;
  className?: string;
}> = ({ teamId, memberId, projectCapacity, className }) => {
  return (
    <div className={tailwindOverride('flex flex-1')}>
      <div className={tailwindOverride('w-2/6 h-26', className)}>
        <ProjectData
          teamId={teamId}
          memberId={memberId}
          data={projectCapacity.data}
        />
      </div>
      <div className='w-4/6 h-26 relative'>
        <Capacity
          projectId={projectCapacity.data.id}
          projectStatus={projectCapacity.data.status}
          allocations={projectCapacity.allocations}
          teamId={teamId}
          memberId={memberId}
          className={className}
          hasTasks={projectCapacity.data.hasTasks}
        />
      </div>
    </div>
  );
};

export default ProjectRow;
