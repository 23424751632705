import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchTimeOffTypes } from 'state/ManageTimeOff/TimeOffTypes/timeOffTypesSlice';
import { fetchUpcomingTimeOffEntries } from 'state/ManageTimeOff/TimeOffEntries/upcoming/upcomingTimeOffEntriesSlice';
import { fetchPastTimeOffEntries } from 'state/ManageTimeOff/TimeOffEntries/past/pastTimeOffEntriesSlice';
import useSnackbarNotification from 'Hooks/useSnackbarNotification';
import TimeOffPageTitle from './components/TimeOffPageTitle/TimeOffPageTitle';
import TimeOffTabs from './components/TimeOffTabs/TimeOffTabs';
import TimeOffEntrySidePanel from './components/TimeOffEntrySidePanel/TimeOffEntrySidePanel';
import DeleteTimeOffConfimationModal from './components/DeleteTimeOffConfimationModal/DeleteTimeOffConfimationModal';

const TimeOffListPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTimeOffTypes());
  }, [dispatch]);

  const {
    isNotificationVisible,
    snackbarProps,
    showNotification,
    SnackbarNotification,
  } = useSnackbarNotification();

  const handleOnDeleteTimeOffEntry = (
    timeOffEntryId: string,
    userId: string
  ) => {
    dispatch(fetchUpcomingTimeOffEntries({ userId, includeTotalCount: true }));
    dispatch(fetchPastTimeOffEntries({ userId, includeTotalCount: true }));
  };

  return (
    <div className='flex flex-col h-full'>
      {isNotificationVisible && snackbarProps && (
        <SnackbarNotification {...snackbarProps} />
      )}
      <DeleteTimeOffConfimationModal
        showSnackbarNotification={showNotification}
        onDeleteTimeOffEntry={handleOnDeleteTimeOffEntry}
      />
      <TimeOffEntrySidePanel />
      <TimeOffPageTitle />
      <TimeOffTabs />
    </div>
  );
};

export default TimeOffListPage;
