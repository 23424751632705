import { useState, useMemo } from 'react';
import {
  Legend,
  PieChart,
  Cell,
  Pie,
  Sector,
  ResponsiveContainer,
} from 'recharts';
import intl from 'react-intl-universal';
import { generateNativeTextElement } from './Elements';
import {
  ACTIVE_COLORS,
  IN_ACTIVE_COLORS,
  inActiveColor,
  chartCenterCountStyle,
  chartCenterTextStyle,
  SECTOR_INACTIVE_COLOR,
  CENTER_COUNT_COLOR,
  CENTER_TEXT_COLOR,
  PATH_CIRCLE_COLOR,
  DATA_STATE_NO_PROJECTS_LINKED,
} from './constants';

const PieChartComponent = ({ projectDetails }: { projectDetails: any }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [sectorClicked, setSectorClicked] = useState(false);
  const [sectorHovered, setSectorHovered] = useState(false);
  const [renderBlankState, setBlankState] = useState(false);
  const [sectorColors, setSectorColors] = useState(ACTIVE_COLORS);

  const totalNumberOfProjects = projectDetails.totalNumberOfProjects;

  useMemo(() => {
    if (totalNumberOfProjects === 0) {
      setBlankState(true);
      setSectorColors(IN_ACTIVE_COLORS);
    } else {
      setBlankState(false);
      setSectorColors(ACTIVE_COLORS);
    }
  }, [totalNumberOfProjects]);

  const data = useMemo(() => {
    return totalNumberOfProjects === 0
      ? DATA_STATE_NO_PROJECTS_LINKED
      : [
          {
            name: 'New',
            value: projectDetails.totalNumberOfNewProjects,
          },
          {
            name: 'In Queue',
            value: projectDetails.totalNumberOfProjectsInQueue,
          },
          {
            name: 'On Hold',
            value: projectDetails.totalNumberOfProjectsOnHold,
          },
          {
            name: 'In Progress',
            value: projectDetails.totalNumberOfProjectsInProgress,
          },
          {
            name: 'Completed',
            value: projectDetails.totalNumberOfProjectsCompleted,
          },
        ];
  }, [
    projectDetails.totalNumberOfNewProjects,
    projectDetails.totalNumberOfProjectsCompleted,
    projectDetails.totalNumberOfProjectsInProgress,
    projectDetails.totalNumberOfProjectsInQueue,
    projectDetails.totalNumberOfProjectsOnHold,
    totalNumberOfProjects,
  ]);

  const renderActiveShape = (props: {
    cx?: any;
    cy?: any;
    midAngle?: any;
    innerRadius?: any;
    outerRadius?: any;
    startAngle?: any;
    endAngle?: any;
    fill?: any;
    percent?: any;
    value?: any;
    showArc?: any;
  }) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 4) * cos;
    const sy = cy + (outerRadius + 4) * sin;
    const mx = cx + (outerRadius + 12) * cos;
    const my = cy + (outerRadius + 12) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    const chartCenterCountFill = sectorHovered
      ? SECTOR_INACTIVE_COLOR
      : CENTER_COUNT_COLOR;

    const chartCenterTextFill =
      sectorHovered || renderBlankState
        ? SECTOR_INACTIVE_COLOR
        : CENTER_TEXT_COLOR;

    const chartCenterTextValue =
      totalNumberOfProjects === 1
        ? intl.get('PROGRAM_PAGE.INSIGHTS.PIE_CHART_PROJECT')
        : intl.get('PROGRAM_PAGE.INSIGHTS.PIE_CHART_PROJECTS');
    const chartCenterElements = !renderBlankState ? (
      <>
        {generateNativeTextElement(
          cx,
          cy,
          'middle',
          chartCenterCountFill,
          totalNumberOfProjects,
          chartCenterCountStyle,
          2
        )}
        {generateNativeTextElement(
          cx,
          cy,
          'middle',
          chartCenterTextFill,
          chartCenterTextValue,
          chartCenterTextStyle,
          18
        )}
      </>
    ) : (
      <>
        {generateNativeTextElement(
          cx,
          cy,
          'middle',
          chartCenterTextFill,
          intl.get('PROGRAM_PAGE.INSIGHTS.PIE_CHART_NO'),
          chartCenterTextStyle,
          -10
        )}
        {generateNativeTextElement(
          cx,
          cy,
          'middle',
          chartCenterTextFill,
          intl.get('PROGRAM_PAGE.INSIGHTS.PIE_CHART_LINKED'),
          chartCenterTextStyle,
          4
        )}
        {generateNativeTextElement(
          cx,
          cy,
          'middle',
          chartCenterTextFill,
          intl.get('PROGRAM_PAGE.INSIGHTS.PIE_CHART_PROJECTS'),
          chartCenterTextStyle,
          18
        )}
      </>
    );

    const chartArcElements = !renderBlankState ? (
      <>
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 2}
          outerRadius={outerRadius + 4}
          fill={fill}
          style={{ outline: 'none' }}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}`}
          stroke={PATH_CIRCLE_COLOR}
          fill='none'
        />
        <circle
          cx={mx}
          cy={my}
          r={2}
          fill={PATH_CIRCLE_COLOR}
          stroke={PATH_CIRCLE_COLOR}
        />
        <text
          x={ex - (cos >= 0 ? 1 : -1) * 16}
          y={sin >= 0 ? ey + 1 * 10 : ey - 1 * 8}
          textAnchor={textAnchor}
          fill={CENTER_TEXT_COLOR}
          fontWeight={700}
          fontSize='1em'
        >
          {`${value}/${totalNumberOfProjects}`}
        </text>
      </>
    ) : null;
    return (
      <g style={{ outline: 'none' }}>
        {chartCenterElements}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          style={{ outline: 'none' }}
        />
        {chartArcElements}
      </g>
    );
  };

  const handleSectorClick = (event: any, index: number) => {
    setSectorClicked(true);
    setActiveIndex(index);
    setSectorHovered(true);
  };
  const handleMouseEnter = (event: any, index: number) => {
    setActiveIndex(index);
  };
  const handleMouseLeave = (event: any, index: number) => {
    setSectorClicked(false);
    setSectorHovered(false);
  };
  const renderColorfulLegendText = (value: string, entry: any) => {
    return (
      <span
        style={{
          color: renderBlankState
            ? SECTOR_INACTIVE_COLOR
            : sectorClicked && data[activeIndex].name !== entry.value
            ? SECTOR_INACTIVE_COLOR
            : CENTER_COUNT_COLOR,
          lineHeight: '1rem',
          fontSize: '0.75rem',
          fontWeight: 400,
          paddingRight: '0.25rem',
        }}
      >
        {value}
      </span>
    );
  };

  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={data}
          activeIndex={activeIndex}
          activeShape={(projectDetails) => renderActiveShape(projectDetails)}
          cx={120}
          cy='55%'
          innerRadius={33}
          outerRadius={55}
          paddingAngle={1}
          dataKey='value'
          onMouseEnter={(_, index) => handleMouseEnter(_, index)}
          onMouseLeave={(_, index) => handleMouseLeave(_, index)}
          onClick={(_, index) => handleSectorClick(_, index)}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              style={{
                outline: 'none',
              }}
              fill={
                sectorClicked
                  ? activeIndex === index % sectorColors.length
                    ? sectorColors[index % sectorColors.length]
                    : inActiveColor
                  : sectorColors[index % sectorColors.length]
              }
            />
          ))}
        </Pie>
        <Legend
          style={{
            position: 'absolute',
            bottom: '0',
            left: '0',
            right: '0',
            margin: 'auto',
            outline: 'none',
          }}
          align='right'
          iconSize={8}
          iconType='circle'
          layout='vertical'
          verticalAlign='middle'
          formatter={renderColorfulLegendText}
          height={120}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
