import { useState, Fragment } from 'react';
import intl from 'react-intl-universal';
import set from 'lodash/set';
import { Button } from '@getsynapse/design-system';
import TaskModal from 'Organisms/TaskModal/TaskModal';
import { taskModalDefaultValues } from './helpers/constants';
import { useDispatch } from 'react-redux';
import { createNewTask } from 'state/ProjectTasks/projectTaskSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { TaskFormFields } from 'utils/types/task';

const CreateTaskModal: React.FC<{
  renderText: string;
  componentType?: string;
}> = ({ renderText, componentType }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCreateTask = async (newTaskData: TaskFormFields) => {
    if (!newTaskData.start_date) {
      set(newTaskData, 'start_date', null);
    }
    if (!newTaskData.due_date) {
      set(newTaskData, 'due_date', null);
    }
    await dispatch(createNewTask(newTaskData));
    await dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get('TASKS.NOTIFICATIONS.TASK_CREATION_SUCCESS'),
      })
    );
  };

  return (
    <Fragment>
      <Button
        onClick={() => setIsModalOpen(true)}
        className='px-4 py-2'
        data-cy={`${componentType}-add-task-button`}
        size='small'
      >
        {renderText}
      </Button>

      <TaskModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        taskData={taskModalDefaultValues}
        onSaveTask={onCreateTask}
      />
    </Fragment>
  );
};

export default CreateTaskModal;
