import { ChangeEvent } from 'react';
import { Toggle } from '@getsynapse/design-system';
import { intakeQuestionWrapper } from 'utils/customTypes';
import get from 'lodash/get';

const ToggleQuestion = ({
  question,
  handler,
  disabled,
}: intakeQuestionWrapper) => {
  return (
    <Toggle
      id={`toggle-${question.id}`}
      onText={get(question, 'data.onText')}
      offText={get(question, 'data.offText')}
      checked={question.data.value}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        handler(question, event.target.checked, 'data.value')
      }
      disabled={disabled}
    />
  );
};

export default ToggleQuestion;
