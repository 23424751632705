import {
  FormItem,
  TextField,
  Button,
  IconButton,
} from '@getsynapse/design-system';
import { DesiredBehavioursData } from 'utils/types/program';
import intl from 'react-intl-universal';

const DesiredBehavioursGroup = ({
  onUpdateQuestion,
  currentValue,
  readOnly,
}: {
  onUpdateQuestion: (value: DesiredBehavioursData) => void;
  currentValue: DesiredBehavioursData;
  readOnly: boolean;
}) => {
  const onUpdate = (index: number, value: string) => {
    const newValues = [...currentValue];
    newValues[index] = {
      ...newValues[index],
      behaviour_text: value,
    };
    onUpdateQuestion(newValues);
  };

  const onDelete = (index: number) => {
    const newValues = [...currentValue];
    newValues.splice(index, 1);
    onUpdateQuestion(newValues);
  };

  const onAdd = () => {
    const newValues = [...currentValue];
    newValues.push({
      behaviour_text: '',
    });
    onUpdateQuestion(newValues);
  };

  return (
    <FormItem
      label={intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.FIELDS.DESIRED_BEHAVIOURS'
      )}
      className='w-full col-span-2'
      labelProps={{
        className: 'text-base',
        'data-testid': 'desired-behaviours-form-label',
      }}
    >
      {currentValue.map((behaviour, index) => {
        return (
          <div className='flex items-center' key={index}>
            <TextField
              readOnly={readOnly}
              value={behaviour.behaviour_text}
              placeholder={intl.get(
                `PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.FIELDS.DESIRED_BEHAVIOURS_PLACEHOLDER`
              )}
              variant='text'
              length='medium'
              data-testid={`desired-behaviour-${index}`}
              className='w-full mt-2'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onUpdate(index, e.target.value);
              }}
            />
            {!readOnly && (
              <IconButton
                name='trash'
                readOnly={readOnly}
                onClick={() => onDelete(index)}
                className='text-primary-dark text-lg h-6 ml-4 mt-2'
              />
            )}
          </div>
        );
      })}
      {!readOnly && (
        <Button
          onClick={onAdd}
          readOnly={readOnly}
          iconName='add-circle'
          variant='tertiary'
          className='mt-4'
          data-testid='add-behaviour-button'
        >
          {intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.FIELDS.ADD_BEHAVIOUR'
          )}
        </Button>
      )}
    </FormItem>
  );
};

export default DesiredBehavioursGroup;
