import { Icon } from '@getsynapse/design-system';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';

const LinkContent = ({
  iconSrc,
  iconName,
  iconClassName,
  ariaLabel,
  isExpanded,
  linkText,
}: {
  iconSrc: string;
  iconName: string;
  iconClassName: string;
  ariaLabel: string;
  isExpanded: boolean;
  linkText: string;
}) => {
  return (
    <>
      <Icon
        src={iconSrc}
        name={iconName}
        className={iconClassName}
        aria-label={ariaLabel}
      />
      <span
        className={classnames(
          'ml-2',
          'transition-all',
          'duration-75 ease-in',
          'delay-150 motion-safe',
          'inline',
          {
            hidden: !isExpanded,
          }
        )}
      >
        {linkText}
      </span>
    </>
  );
};

export type LinkWrapperType = {
  linkPath: string;
  iconSrc?: string;
  linkText: string;
  iconName?: string;
  isExpanded: boolean;
  ariaLabel: string;
  external?: boolean;
  linkBorderColor?: string;
};

const RenderLinks = ({
  linkPath,
  iconSrc = '',
  linkText,
  iconName = '',
  isExpanded,
  ariaLabel,
  external = false,
  linkBorderColor = '',
}: LinkWrapperType) => {
  const iconClassName = 'text-neutral-white text-2xl';

  const linkStyle = {
    color: 'white',
    backgroundColor: 'rgba(255,255,255,0.15)',
  };

  if (external) {
    return (
      <a
        href={linkPath}
        rel='noreferrer'
        className={classnames(
          'border-l-2',
          'border-transparent',
          'flex',
          'h-10',
          'items-center',
          'text-center',
          'text-neutral-white',
          'hover:bg-primary-darker',
          'hover:bg-opacity-50',
          'focus:bg-primary-darker',
          'focus:bg-opacity-50',
          'focus:outline-none',
          'rounded',
          'text-label',
          'px-2'
        )}
      >
        <LinkContent
          iconSrc={iconSrc}
          iconName={iconName}
          iconClassName={iconClassName}
          ariaLabel={ariaLabel}
          isExpanded={isExpanded}
          linkText={linkText}
        />
      </a>
    );
  }

  return (
    <NavLink
      exact
      to={linkPath}
      activeStyle={linkStyle}
      className={(isActive) =>
        classnames(
          'border-l-2',
          'flex',
          'h-10',
          'items-center',
          'text-center',
          'text-neutral-white',
          'hover:bg-primary-darker',
          'hover:bg-opacity-50',
          'focus:bg-primary-darker',
          'focus:bg-opacity-50',
          'focus:outline-none',
          'rounded',
          'text-label',
          'px-2',
          {
            [linkBorderColor]: isActive,
            'border-transparent': !isActive,
          }
        )
      }
    >
      <LinkContent
        iconSrc={iconSrc}
        iconName={iconName}
        iconClassName={iconClassName}
        ariaLabel={ariaLabel}
        isExpanded={isExpanded}
        linkText={linkText}
      />
    </NavLink>
  );
};

export default RenderLinks;
