import { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { SidePanel } from '@getsynapse/design-system';
import useSidePanel from 'Hooks/useSidePanel';
import { SLICE_STATUS } from 'utils/constants';
import {
  selectTaskIdToEdit,
  setTaskIdToEdit,
} from 'state/ProjectTasksList/actions/actionsSlice';
import {
  fetchTaskDetail,
  selectCanEdit,
  selectTaskProjectId,
  selectTaskStatus,
  selectFetchTaskDetailStatus,
} from 'state/TaskDetail/taskSlice';
import HeaderElement from './HeaderElement/HeaderElement';
import SidePanelContent from './SidePanelContent/SidePanelContent';

const TaskDetailSidePanel = () => {
  const dispatch = useDispatch();
  const fetchTaskDetailStatus = useSelector(selectFetchTaskDetailStatus);
  const taskIdToEdit = useSelector(selectTaskIdToEdit);
  const canEditTask = useSelector(selectCanEdit);
  const taskStatus = useSelector(selectTaskStatus);
  const projectId = useSelector(selectTaskProjectId);
  const closePanelCallback = useCallback(() => {
    dispatch(setTaskIdToEdit(null));
  }, [dispatch]);

  const { openPanel, isPanelOpen, onClose, closePanel } =
    useSidePanel(closePanelCallback);

  useEffect(() => {
    if (taskIdToEdit) {
      dispatch(fetchTaskDetail({ taskId: taskIdToEdit }));
      openPanel();
    }
  }, [taskIdToEdit, dispatch, openPanel]);

  const footerButtons = useMemo(
    () => [
      {
        children: intl.get('UPDATE'),
        disabled: !canEditTask,
        onClick: () => {},
      },
      {
        children: intl.get('CANCEL'),
        variant: 'tertiary',
        onClick: closePanel,
      },
    ],
    [closePanel, canEditTask]
  );

  return (
    <SidePanel
      isOpen={isPanelOpen}
      onClose={onClose}
      footerButtons={footerButtons}
      isLoading={fetchTaskDetailStatus === SLICE_STATUS.LOADING}
      headerElement={
        <HeaderElement
          taskId={taskIdToEdit}
          projectId={projectId}
          status={taskStatus}
          onClose={onClose}
        />
      }
    >
      {taskIdToEdit && <SidePanelContent />}
    </SidePanel>
  );
};

export default TaskDetailSidePanel;
