import intl from 'react-intl-universal';
import { Icon, Toggle, Tooltip, Typography } from '@getsynapse/design-system';
import { useCallback, useMemo } from 'react';
import tooltipInfo from 'assets/icons/tooltip-info.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserDraftPublicSetting,
  updatePublicDraftSettings,
} from 'state/UserSetting/userSettingsSlice';
import { Request } from 'utils/customTypes';
import { QUESTIONNAIRE_TYPE } from 'utils/constants';

const DraftPublicSettingsOption = ({
  requestData,
}: {
  requestData: Request;
}) => {
  const dispatch = useDispatch();
  const userDraftPublicSetting = useSelector(selectUserDraftPublicSetting);

  const { isDraftPublicUserSetting, requestId } = useMemo(() => {
    if (requestData.type === QUESTIONNAIRE_TYPE.FORM) {
      return {
        isDraftPublicUserSetting: userDraftPublicSetting,
      };
    }

    return {
      requestId: requestData.id,
      isDraftPublicUserSetting: requestData.draft_public_setting,
    };
  }, [
    userDraftPublicSetting,
    requestData.type,
    requestData.id,
    requestData.draft_public_setting,
  ]);

  const handleEnableDisable = useCallback(async () => {
    await dispatch(updatePublicDraftSettings(requestId));
  }, [dispatch, requestId]);

  return (
    <div
      className='flex flex-none items-center px-4'
      data-testid='draft-public-settings-option'
      data-cy='public-draft-setting'
    >
      <Tooltip
        position='botomCenter'
        ariaId='public-draft-tooltip'
        openMode='hover1'
        contentProps={{ className: 'z-200000' }}
        trigger={<Icon name='info' src={tooltipInfo} className='mt-1.5 mr-4' />}
      >
        {intl.get('REQUEST_PAGE.TOP_BAR.PUBLIC_DRAFT.TOOLTIP')}
      </Tooltip>
      <Typography variant='label'>
        {intl.get('REQUEST_PAGE.TOP_BAR.PUBLIC_DRAFT.TITLE')}
      </Typography>
      <Toggle
        isSmall
        id='public-draft-setting'
        inputProps={{
          'data-cy': 'public-draft-setting-toggle',
        }}
        className='ml-4 mt-0.5'
        labelProps={{ className: 'mb-5' }}
        checked={isDraftPublicUserSetting}
        onChange={handleEnableDisable}
      />
    </div>
  );
};

export default DraftPublicSettingsOption;
