import { ReactElement, useRef } from 'react';
import { Typography, Icon, Button, Tooltip } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

type CardProps = {
  iconSrc: string;
  title: string;
  emptyStateImg: string;
  emptyStateText: string;
  content: ReactElement | null;
  viewAllTooltipText: string;
  viewAllOnClick: () => void;
  showViewAllButton: boolean;
  dataCy: string;
};

const Card = ({
  iconSrc,
  title,
  emptyStateImg,
  emptyStateText,
  content,
  viewAllTooltipText,
  viewAllOnClick,
  showViewAllButton,
  dataCy,
}: CardProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <div className='border border-neutral-lighter-two rounded shadow-header h-120 2xl:h-152 px-4 pt-4 pb-8 2xl:px-6 2xl:pt-6'>
      <div className='flex items-center justify-between	mb-6'>
        <div className='flex items-center'>
          <Icon src={iconSrc} className='mr-2 w-6 h-6' />
          <Typography variant='h5'>{title}</Typography>
        </div>
        {showViewAllButton && (
          <Tooltip
            timeout='0'
            openMode='hover1'
            position='bottomLeft'
            contentProps={{ className: 'z-10 px-3 py-1' }}
            trigger={
              <Button
                variant='tertiary'
                ref={buttonRef}
                onClick={() => {
                  viewAllOnClick();
                  buttonRef?.current?.blur();
                }}
                data-cy={`${dataCy}-view-button`}
              >
                {intl.get('MY_WORKSPACE.VIEW_ALL')}
              </Button>
            }
            hasMaxWidth={false}
          >
            {viewAllTooltipText}
          </Tooltip>
        )}
      </div>
      {content ? (
        content
      ) : (
        <div className='flex items-center justify-center flex-col h-full'>
          <img src={emptyStateImg} alt='' data-cy={`${dataCy}-img`} />
          <Typography className='mt-4'>{emptyStateText}</Typography>
        </div>
      )}
    </div>
  );
};

export default Card;
