import { Modal, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

interface InReviewModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onMoveInReview: () => void;
}

const InReviewModal: React.FC<InReviewModalProps> = ({
  isOpen,
  onCancel,
  onMoveInReview,
}) => {
  return (
    <Modal
      aria-label='in-review-request-modal'
      data-testid='in-review-request-modal'
      size='medium'
      isOpen={isOpen}
      closeModal={onCancel}
      title={intl.get('REQUEST_PAGE.TOP_BAR.IN_REVIEW_MODAL.TITLE')}
      actionButtons={[
        {
          children: intl.get('CONFIRM'),
          variant: 'primary',
          onClick: onMoveInReview,
          'data-cy': 'request-in-review-button',
        },
        {
          children: intl.get('CANCEL'),
          variant: 'secondary',
          onClick: onCancel,
          'data-cy': 'request-in-review-cancel-button',
        },
      ]}
    >
      <Typography className='mb-4'>
        {intl.get('REQUEST_PAGE.TOP_BAR.IN_REVIEW_MODAL.CONFIRMATION')}
      </Typography>

      <Typography>
        {intl.get('REQUEST_PAGE.TOP_BAR.IN_REVIEW_MODAL.NOTIFICATION')}
      </Typography>
    </Modal>
  );
};

export default InReviewModal;
