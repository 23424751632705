import { FC } from 'react';
import intl from 'react-intl-universal';
import { TaskWithAssigneesAndProject } from 'types/store/tasksList';
import { CentralizedTasksTableTab } from 'utils/types/centralizedTasks';
import RenderNoRecords from 'Pages/RequestsListPage/components/NoRecords';
import TaskRow from '../TaskRow/TaskRow';
import CreateTaskModal from '../../CreateTaskModal/CreateTaskModal';
import LastTaskRow from '../TaskRow/LastTaskRow/LastTaskRow';
import emptyTasksTable from 'assets/icons/empty-tasks.svg';

interface TableBodyProps {
  tasks: TaskWithAssigneesAndProject[];
  canFetchMoreTasks: boolean;
  fetchMoreTasks: () => void;
  tableName: CentralizedTasksTableTab;
}

const TableBody: FC<TableBodyProps> = ({
  tasks,
  canFetchMoreTasks,
  fetchMoreTasks,
  tableName,
}) => {
  const isListEmpty = tasks.length === 0;
  return (
    <tbody>
      {tasks.map((task: TaskWithAssigneesAndProject, index: number) => {
        const isOdd = index % 2 !== 0;
        return (
          <TaskRow
            key={task.id}
            task={task}
            bgColor={isOdd ? 'bg-neutral-lightest-two' : 'bg-neutral-white'}
          />
        );
      })}
      {!isListEmpty && canFetchMoreTasks && (
        <LastTaskRow
          isOdd={tasks.length % 2 !== 0}
          tableName={tableName}
          onLastRowInView={fetchMoreTasks}
        />
      )}
      {isListEmpty && (
        <tr>
          <td colSpan={10}>
            <RenderNoRecords
              dataCy='tasks-list-empty-table'
              imageSrc={emptyTasksTable}
              imageClassName='-ml-4'
              className='h-empty-table-body w-full'
              caption={intl.get('TASKS_LIST_PAGE.TABLE.EMPTY')}
              labelClassName='mt-0'
            >
              <div className='flex-initial self-center mt-2'>
                <CreateTaskModal />
              </div>
            </RenderNoRecords>
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default TableBody;
