import {
  NewScheduleCreationParams,
  EventData,
} from 'utils/types/learningSchedule';

export const serializeCreateEvent = (
  data: EventData
): NewScheduleCreationParams => {
  return {
    learningEvent: {
      learningEventTypeId: data.EventType,
      title: data.Subject,
    },
    schedule: {
      scheduleFacilitatorIds: data.Facilitators || [],
      scheduleProjectIds: data.ProjectId,
      startDateTime: data.StartTime,
      endDateTime: data.EndTime,
      isAllDay: data.IsAllDay,
      recurrencePattern: {
        recurrenceRule: data.RecurrenceRule || '',
      },
    },
  };
};
