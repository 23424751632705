import classnames from 'classnames';
import { Card } from 'Pages/InsightsPage/Components/Card';
import FilterSections from './FiltersSections';
import { useState } from 'react';

const FiltersPanel = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Card
      className={classnames(
        'h-full text-base flex flex-col relative overflow-y-auto',
        `${isExpanded ? 'w-80 min-w-80' : 'w-12 min-w-12'}`
      )}
    >
      <FilterSections isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
    </Card>
  );
};

export default FiltersPanel;
