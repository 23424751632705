import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import {
  Typography,
  Button,
  Icon,
  tailwindOverride,
} from '@getsynapse/design-system';
import isEqual from 'lodash/isEqual';
import projectTemplatesAPI from 'state/ProjectTemplates/projectTemplatesAPI';
import { FieldTemplateType } from 'utils/types/fields';
import useModal from 'Hooks/useModal';
import FieldTemplateCard from './FieldTemplateCard';
import templateFieldIcon from 'assets/icons/template-field.svg';
import orderBy from 'lodash/orderBy';

const AddTemplateFieldsModal: React.FC<{
  onUpdateFields: (
    fieldTemplatesToAdd: FieldTemplateType[],
    fieldTemplatesIdsToRemove: string[]
  ) => void;
  previouslySelectedFieldTemplatesIds?: string[];
}> = ({ onUpdateFields, previouslySelectedFieldTemplatesIds = [] }) => {
  const { Modal, modalProps, openModal, closeModal } = useModal();
  const [fieldTemplates, setFieldTemplates] = useState<FieldTemplateType[]>([]);
  const [selectedFieldTemplatesIds, setSelectedFieldTemplatesIds] = useState<
    string[]
  >([]);

  useEffect(() => {
    let isMounted = true;
    const fetchFieldTemplates = async () => {
      const fields = await projectTemplatesAPI.fetchFieldTemplates();
      if (isMounted && fields.length > 0) {
        const orderedFields = orderBy(
          fields,
          [(field) => field.name.toLowerCase()],
          'asc'
        );
        setFieldTemplates(orderedFields);
      }
    };
    if (fieldTemplates?.length === 0) {
      fetchFieldTemplates();
    }
    return () => {
      isMounted = false;
    };
  }, [fieldTemplates]);

  useEffect(() => {
    if (modalProps.isOpen) {
      setSelectedFieldTemplatesIds(previouslySelectedFieldTemplatesIds);
    }
  }, [previouslySelectedFieldTemplatesIds, modalProps.isOpen]);

  const canSave = !isEqual(
    selectedFieldTemplatesIds,
    previouslySelectedFieldTemplatesIds
  );

  const handleSave = () => {
    const fieldTemplatesIdsToRemove =
      previouslySelectedFieldTemplatesIds.filter(
        (value: string) => !selectedFieldTemplatesIds.includes(value)
      );
    const fieldTemplatesToAdd = fieldTemplates.filter(
      (fieldTemplate: FieldTemplateType) =>
        !previouslySelectedFieldTemplatesIds.includes(fieldTemplate.id!) &&
        selectedFieldTemplatesIds.includes(fieldTemplate.id!)
    );
    onUpdateFields(fieldTemplatesToAdd, fieldTemplatesIdsToRemove);
    closeModal();
  };

  const handleSelectFieldTemplate = (fieldTemplateId: string) => {
    setSelectedFieldTemplatesIds((prevState) => {
      let newState = [...prevState];
      const fieldTemplateIndex = prevState.findIndex(
        (value: string) => value === fieldTemplateId
      );
      if (fieldTemplateIndex > -1) {
        newState = [
          ...prevState.slice(0, fieldTemplateIndex),
          ...prevState.slice(fieldTemplateIndex + 1),
        ];
      } else {
        newState = newState.concat(fieldTemplateId);
      }
      return newState;
    });
  };

  return (
    <>
      <Modal
        {...modalProps}
        data-cy='fields-for-template-modal'
        size='large'
        title={intl.get(
          'SETTINGS_PAGE.TEMPLATE_PAGE.ADD_MORE_FIELDS_MODAL.TITLE'
        )}
        titleIcon={{
          src: templateFieldIcon,
        }}
        aria-label={intl.get(
          'SETTINGS_PAGE.TEMPLATE_PAGE.ADD_MORE_FIELDS_MODAL.TITLE'
        )}
        childrenClassName='max-h-140 overflow-y-auto'
        actionButtons={[
          {
            children: intl.get('SAVE'),
            variant: 'primary',
            onClick: handleSave,
            disabled: !canSave,
            'data-cy': 'fields-for-template-modal__save-button',
          },
          {
            children: intl.get('CANCEL'),
            variant: 'tertiary',
            onClick: () => closeModal(),
          },
        ]}
      >
        <Typography variant='body' className='text-primary'>
          {intl.get(
            'SETTINGS_PAGE.TEMPLATE_PAGE.ADD_MORE_FIELDS_MODAL.DESCRIPTION'
          )}
        </Typography>
        <div className={tailwindOverride('grid grid-cols-2 gap-2.5 mt-4')}>
          {fieldTemplates &&
            fieldTemplates.map((fieldTemplate) => (
              <FieldTemplateCard
                key={fieldTemplate.id}
                fieldTemplate={fieldTemplate}
                isSelected={selectedFieldTemplatesIds.includes(
                  fieldTemplate.id!
                )}
                onSelectFieldTemplate={handleSelectFieldTemplate}
              />
            ))}
        </div>
      </Modal>
      <Button
        variant='tertiary'
        className={tailwindOverride('mx-px my-px', {
          'mt-8': previouslySelectedFieldTemplatesIds.length > 0,
        })}
        onClick={() => openModal(modalProps)}
        data-cy='add-template-field'
      >
        <Icon name='add-circle' className='mr-2 text-2xl' />
        <Typography variant='label' className='text-primary cursor-pointer'>
          {intl.get(
            'SETTINGS_PAGE.TEMPLATE_PAGE.BUILD_SECTION.ADD_MORE_FIELDS'
          )}
        </Typography>
      </Button>
    </>
  );
};

export default AddTemplateFieldsModal;
