import { Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

type CategoryPromptsProps = {
  categoryPrompts: string[];
  programDescription: string;
};

const CategoryPrompts = ({
  categoryPrompts,
  programDescription,
}: CategoryPromptsProps) => {
  return (
    <div className='w-5/12'>
      <Typography
        variant='body'
        weight='medium'
        className='pb-2'
        data-testid='create-objective-modal_prompts-label'
      >
        {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.ADD_OBJECTIVE.PROMPTS')}
      </Typography>
      <ul
        className='list-disc ml-4'
        data-testid='create-objective-modal_prompts-list'
      >
        {categoryPrompts!.map((prompt) => (
          <li key={prompt} className='text-base'>
            {prompt}
          </li>
        ))}
      </ul>

      <Typography variant='body' weight='medium' className='pb-2 pt-4'>
        {intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.ADD_OBJECTIVE.PROGRAM_DESCRIPTION'
        )}
      </Typography>
      <Typography
        variant='body'
        data-testid='create-objective-modal_category-description'
      >
        {programDescription}
      </Typography>
    </div>
  );
};

export default CategoryPrompts;
