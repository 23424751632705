import { Modal, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  submitChanges: () => void;
};

const DeleteCollectionMethodModal = ({
  isOpen,
  setIsOpen,
  submitChanges,
}: Props) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      data-testid='delete-collection-method-modal'
      isOpen={isOpen}
      title={intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.DELETE_COLLECTION_METHOD_MODAL.TITLE'
      )}
      titleIcon={{
        name: 'alert-circle',
        className: 'text-warning-darker',
      }}
      aria-label={intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.DELETE_COLLECTION_METHOD_MODAL.TITLE'
      )}
      closeModal={closeModal}
      size='medium'
      actionButtons={[
        {
          children: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.DELETE_COLLECTION_METHOD_MODAL.CONFIRM_BUTTON'
          ),
          variant: 'primary',
          color: 'warning',
          onClick: () => {
            submitChanges();
            closeModal();
          },
          'data-testid': 'delete-collection-method-modal-confirm-button',
        },
        {
          children: intl.get('CANCEL'),
          variant: 'tertiary',
          onClick: closeModal,
          'data-testid': 'delete-collection-method-modal-cancel-button',
        },
      ]}
    >
      <Typography
        className='mb-6'
        data-testid='delete-collection-methods-modal-text'
      >
        {intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.DELETE_COLLECTION_METHOD_MODAL.BODY_TEXT'
        )}
      </Typography>
    </Modal>
  );
};

export default DeleteCollectionMethodModal;
