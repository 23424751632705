import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { Team } from 'utils/types/dailyTeamsCapacity';
import teamsListAPI from './teamsListAPI';

interface TeamsListState {
  allIds: string[];
  byId: Team;
  links: {
    next: string | null;
  };
  status: Status;
}

/* ============================= INITIAL STATE ============================== */
const initialState: TeamsListState = {
  allIds: [],
  byId: {},
  links: {
    next: null,
  },
  status: SLICE_STATUS.IDLE,
};

/* ============================== ACTIONS =============================== */
export const toggleExpandTeam = createAction<{
  teamId: string;
  expanded: boolean;
}>('capacities/teamsList/TOGGLE_EXPAND_TEAM');
export const resetCapacitiesState = createAction('capacities/RESET_STATE');

/* ============================== REDUX THUNK =============================== */
export const fetchTeamsList = createAsyncThunk(
  'capacities/teamsList/FETCH_TEAMS_LIST',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      let url = state.capacities.teamsList.links.next;
      if (state.capacities.teamsList.allIds.length === 0) {
        url = 'v2/teams/learning?limit=15';
      }
      if (!url) {
        return state.capacities.teamsList;
      }
      const response = await teamsListAPI.fetchTeamsList(url);
      const teamsById: Team = {};
      for (const team of response.teams) {
        teamsById[team.id] = {
          name: team.name,
          expanded: false,
        };
      }
      return {
        byId: { ...state.capacities.teamsList.byId, ...teamsById },
        allIds: state.capacities.teamsList.allIds.concat(
          Object.keys(teamsById)
        ),
        links: response.links,
      };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

/* =============================== SELECTORS ================================ */
export const selectAllTeamsIds = (state: RootState) =>
  state.capacities.teamsList.allIds;
export const selectNumberOfTeams = (state: RootState) =>
  state.capacities.teamsList.allIds.length;
export const selectTeamById = (state: RootState, teamId: string) =>
  state.capacities.teamsList.byId[teamId as keyof Team];
export const selectTeamsListStateStatus = (state: RootState) =>
  state.capacities.teamsList.status;
export const selectFetchMoreTeamsLink = (state: RootState) =>
  state.capacities.teamsList.links.next;
export const selectTeamExpandedStatus = (state: RootState, teamId: string) => {
  if (teamId in state.capacities.teamsList.byId) {
    return state.capacities.teamsList.byId[teamId].expanded;
  }
  return false;
};

/* ================================= REDUCER ================================ */
const teamsListSlice = createSlice({
  name: 'teamsList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamsList.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchTeamsList.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        state.allIds = action.payload?.allIds || [];
        state.byId = action.payload?.byId || {};
        state.links = action.payload?.links || { next: null };
      })
      .addCase(fetchTeamsList.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(toggleExpandTeam, (state, action) => {
        const { teamId, expanded } = action.payload;
        if (teamId in state.byId) {
          state.byId[teamId].expanded = expanded;
        }
      })
      .addCase(resetCapacitiesState, (state) => {
        state.allIds = initialState.allIds;
        state.byId = initialState.byId;
        state.links = initialState.links;
        state.status = initialState.status;
      });
  },
});

export default teamsListSlice.reducer;
