import intl from 'react-intl-universal';
import {
  Typography,
  Modal,
  FormItem,
  NumericInput,
  InlineMessage,
} from '@getsynapse/design-system';

import usersIcon from 'assets/icons/users.svg';
import Divider from 'Atoms/Divider';
import { BusinessUser, FormOption } from 'utils/customTypes';
import EmploymentTypePicker from 'Molecules/EmploymentTypePicker/EmploymentTypePicker';
import { useDispatch, useSelector } from 'react-redux';
import { selectLearningTeams } from 'state/LearningTeams/learningTeamsSlice';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { get, isEmpty } from 'lodash';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';
import {
  assignUserToLearningTeam,
  getSelectedUser,
  resetUpgradeResult,
  selectAvailableLicenses,
  selectUpgradeResult,
  selectUpgradeStatus,
  upgradeUser,
} from 'state/UsersManagement/usersManagementSlice';
import { showNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';

const ChangeUserTypeModal = ({
  isOpen,
  closeModal,
  user,
}: {
  isOpen?: boolean;
  closeModal: () => void;
  user: BusinessUser;
}) => {
  const errors: any = {};
  const dispatch = useDispatch();
  const learningTeams = useSelector(selectLearningTeams);
  const upgradeResult = useSelector(selectUpgradeResult);
  const upgradeStatus = useSelector(selectUpgradeStatus);
  const [selectedLearningTeams, setSelectedLearningTeams] = useState<string[]>(
    []
  );
  const [capacity, setCapacity] = useState<number>(0);
  const [employmentType, setEmploymentType] = useState<string>();
  const learningTeamOptions: FormOption[] = useMemo(() => {
    const teamsManaged = !isEmpty(get(user, 'teamsManaged'))
      ? get(user, 'teamsManaged').map((team: { id: string }) => team.id)
      : [];
    const teams = learningTeams
      .filter((team) => !teamsManaged.includes(team.id))
      .map((team) => ({ value: team.id, label: team.name }));

    return teams;
  }, [user, learningTeams]);
  const availableLicenses = useSelector(selectAvailableLicenses);

  useEffect(() => {
    if (upgradeResult === 'failed') {
      dispatch(
        showNotification({
          notificationVariant: 'error',
          notificationTitle: '',
          notificationMessage: intl.get(
            'SETTINGS_PAGE.USER_PAGE.CHANGE_USER_TYPE_MODAL.ERROR_NOTIFICATION'
          ),
        })
      );
    } else if (upgradeResult === 'success') {
      dispatch(
        showNotification({
          notificationVariant: 'success',
          notificationTitle: '',
          notificationMessage: intl.get(
            'SETTINGS_PAGE.USER_PAGE.CHANGE_USER_TYPE_MODAL.SUCCESS_NOTIFICATION'
          ),
        })
      );
      dispatch(getSelectedUser(user.id));
      closeModal();
    }
    dispatch(resetUpgradeResult());
  }, [upgradeResult, dispatch, closeModal, user.id]);

  const checkInput = (event: KeyboardEvent) => {
    const integerRegex = /\d/;
    if (!integerRegex.test(event.code)) {
      event.preventDefault();
    }
  };

  const handleSubmit = async () => {
    await dispatch(
      upgradeUser({
        userId: user.id,
        organizationId: user.organization_id!,
        employmentType: employmentType!,
        defaultCapacity: capacity!,
      })
    );
    await dispatch(
      assignUserToLearningTeam({
        userId: user.id,
        teamsIds: selectedLearningTeams,
      })
    );
  };

  return (
    <Modal
      title={intl.get('SETTINGS_PAGE.USER_PAGE.CHANGE_USER_TYPE_MODAL.TITLE')}
      closeModal={closeModal}
      childrenClassName=''
      isOpen={isOpen}
      titleIcon={{ src: usersIcon }}
      size='large'
      aria-label={intl.get(
        'SETTINGS_PAGE.USER_PAGE.CHANGE_USER_TYPE_MODAL.TITLE'
      )}
      actionButtons={[
        {
          children: intl.get(
            'SETTINGS_PAGE.USER_PAGE.CHANGE_USER_TYPE_MODAL.BUTTONS.CONFIRM'
          ),
          variant: 'primary',
          disabled:
            upgradeStatus !== 'idle' ||
            selectedLearningTeams.length === 0 ||
            !capacity ||
            !employmentType,
          onClick: handleSubmit,
          'data-cy': 'Change',
        },
        {
          children: intl.get(
            'SETTINGS_PAGE.USER_PAGE.CHANGE_USER_TYPE_MODAL.BUTTONS.CANCEL'
          ),
          variant: 'tertiary',
          onClick: closeModal,
          'data-cy': 'cancel',
        },
      ]}
    >
      <div className='flex flex-col gap-6'>
        <div className='flex flex-col gap-6'>
          <Typography>
            {intl.get(
              'SETTINGS_PAGE.USER_PAGE.CHANGE_USER_TYPE_MODAL.BODY.CHANGE_DESCRIPTION'
            )}
          </Typography>
          {availableLicenses > 0 && (
            <Typography weight='medium'>
              {intl.get(
                'SETTINGS_PAGE.USER_PAGE.CHANGE_USER_TYPE_MODAL.BODY.LICENSES_REMAINING',
                { licenses: availableLicenses }
              )}
            </Typography>
          )}

          {availableLicenses <= 0 && (
            <InlineMessage
              isOpen
              variant='warning'
              message={intl.get(
                'SETTINGS_PAGE.USER_PAGE.CHANGE_USER_TYPE_MODAL.BODY.NO_LICENSES'
              )}
            ></InlineMessage>
          )}
        </div>
        <Divider className='m-0' />
        <div className='flex flex-col gap-6'>
          <Typography>
            {intl.get(
              'SETTINGS_PAGE.USER_PAGE.CHANGE_USER_TYPE_MODAL.BODY.PLEASE_FILL'
            )}
          </Typography>
          <Typography>
            {intl.get(
              'SETTINGS_PAGE.USER_PAGE.CHANGE_USER_TYPE_MODAL.BODY.FORM.SECTION_TITLE_JOB'
            )}
          </Typography>
          <div className='flex flex-col gap-4'>
            <div className='flex flex-row justify-between flex-grow gap-6'>
              <EmploymentTypePicker
                className='flex-grow'
                onChange={(option) => {
                  setEmploymentType((option as FormOption).value);
                }}
                required
              />
              <FormItem
                className='flex-grow'
                label={intl.get('SETTINGS_PAGE.USER_PAGE.JOB_INFORMATION.TEAM')}
                data-cy='label__team'
                component='div'
                labelProps={{ required: true }}
              >
                <MultiSelectDropdown
                  options={learningTeamOptions}
                  onChange={(options: FormOption[]) => {
                    const values = options.map((item) => {
                      return item.value;
                    });
                    setSelectedLearningTeams(values);
                  }}
                  triggerProps={{
                    className: 'h-6 items-center',
                    'data-cy': 'field__team',
                    placeholder: intl.get(
                      'SETTINGS_PAGE.USER_PAGE.JOB_INFORMATION.TEAM_PLACEHOLDER'
                    ),
                  }}
                />
              </FormItem>
            </div>

            <Typography>
              {intl.get(
                'SETTINGS_PAGE.USER_PAGE.CHANGE_USER_TYPE_MODAL.BODY.FORM.SECTION_TITLE_CAPACITY'
              )}
            </Typography>
            <FormItem
              className='flex-initial w-1/2 pr-6'
              component='div'
              label={intl.get(
                'SETTINGS_PAGE.USER_PAGE.CAPACITY_MANAGEMENT.WEEKLY_CAPACITY'
              )}
              labelProps={{
                required: true,
                state: errors.capacity ? 'error' : 'default',
              }}
              helpText={
                errors.capacity &&
                intl.get('SETTINGS_PAGE.USER_PAGE.ERRORS.MISSING_INFORMATION')
              }
              helpTextProps={{ state: errors.capacity ? 'error' : 'default' }}
            >
              <div className='flex'>
                <NumericInput
                  step='1'
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setCapacity(parseInt(event.target.value, 10));
                  }}
                  defaultValue={0}
                  containerClassName='w-full'
                  placeholder={intl.get(
                    'SETTINGS_PAGE.USER_PAGE.CAPACITY_MANAGEMENT.WEEKLY_CAPACITY_PLACEHOLDER'
                  )}
                  state={errors.capacity ? 'error' : 'default'}
                  data-cy='weekly-capacity_input'
                  min='0'
                  onKeyPress={checkInput}
                />
              </div>
            </FormItem>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeUserTypeModal;
