import { tailwindOverride } from '@getsynapse/design-system';
import SkeletonLoading from 'Organisms/CapacityAllocationTable/components/SkeletonLoading';

const ColorDescriptionLoader: React.FC<{ className?: string }> = ({
  className,
}) => (
  <div
    className={tailwindOverride(
      'px-2 py-1',
      'flex space-x-2',
      'box-border animate-pulse',
      className
    )}
  >
    <div className='w-2 h-2 rounded-full bg-neutral-lighter' />
    <div className='flex flex-1 h-full bg-neutral-lighter rounded-sm' />
  </div>
);

const Loader = () => {
  return (
    <div
      className={tailwindOverride(
        'w-full h-auto',
        'rounded border border-neutral-lighter-two',
        'relative'
      )}
    >
      <div
        className={tailwindOverride(
          'w-full px-4 py-3 bg-neutral-white',
          'flex justify-end space-x-2'
        )}
      >
        <ColorDescriptionLoader className='w-20' />
        <ColorDescriptionLoader className='w-28' />
        <ColorDescriptionLoader className='w-36' />
      </div>
      <SkeletonLoading />
    </div>
  );
};

export default Loader;
