import React, { useMemo, useEffect } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography,
  FormItem,
  TextField,
  TextArea,
  Avatar,
  UsersPicker,
  GlobalBanner,
} from '@getsynapse/design-system';
import get from 'lodash/get';
import { getUserInitials, getUserDisplayName } from 'utils/functions';
import { AvatarUser, User, UserAvatars } from 'utils/customTypes';
import { ProjectTemplate } from 'utils/types/templates';
import {
  selectLDUsersForDropdown,
  getLDUsers,
} from 'state/UsersManagement/usersManagementSlice';
import cognotaAvatar from 'assets/images/cognota-avatar.svg';

const TemplateOwner: React.FC<{
  system: boolean;
  owner_id?: string;
  ldUsers: UserAvatars[];
  onUpdateTemplateOwner: (userId: string) => void;
}> = ({ system, owner_id, ldUsers, onUpdateTemplateOwner }) => {
  const owner = useMemo(
    () => ldUsers.filter((ldUser) => ldUser.value === owner_id),
    [ldUsers, owner_id]
  );
  return system ? (
    <TemplateCreator system />
  ) : (
    <UsersPicker
      usersList={ldUsers}
      selectedUsersList={owner}
      onChange={(users: UserAvatars[]) =>
        onUpdateTemplateOwner(get(users, '[0].value', null))
      }
      triggerProps={{ 'data-cy': 'template-owner-field' }}
      maxLimit={1}
    />
  );
};

const TemplateCreator: React.FC<{ creator?: User; system: boolean }> = ({
  creator = null,
  system = false,
}) => {
  const templateCreator: Partial<AvatarUser> = {};
  if (creator) {
    templateCreator.avatar_url = creator.avatar_url;
    templateCreator.data = {
      firstName: creator.data.firstName,
      lastName: creator.data.lastName,
    };
  }

  if (system) {
    templateCreator.avatar_url = cognotaAvatar;
    templateCreator.data = {
      firstName: intl.get('SETTINGS_PAGE.PROJECTS_PAGE.SYSTEM'),
      lastName: '',
    };
  }

  if (!creator && !system) {
    return <Typography weight='strong'>-</Typography>;
  }

  return (
    <div className='flex items-start'>
      <Avatar
        iconSrc={templateCreator.avatar_url}
        iconProps={{ className: 'text-lg' }}
        initial={getUserInitials(creator as User)}
        bgColorClass='bg-secondary'
      />
      <div className='ml-2'>
        <Typography
          variant='body'
          className='text-primary font-semibold'
          data-cy='template-creator-field'
        >
          {getUserDisplayName(templateCreator as User)}
        </Typography>
      </div>
    </div>
  );
};

const Overview: React.FC<{
  templateData: ProjectTemplate;
  updateTemplateData: (key: keyof ProjectTemplate, value: any) => void;
}> = ({ templateData, updateTemplateData }) => {
  const dispatch = useDispatch();
  const ldUsers = useSelector(selectLDUsersForDropdown);
  const isUpdating = templateData.id ? true : false;
  const canUpdate = isUpdating ? !templateData.system : true;
  const displayBanner = isUpdating && !templateData.system;

  useEffect(() => {
    if (ldUsers && ldUsers.length === 0) {
      dispatch(getLDUsers());
    }
  }, [ldUsers, dispatch]);

  const handleUpdateTemplateOwner = (userId: string) => {
    updateTemplateData('owner_id', userId);
  };

  return (
    <div className='mt-4'>
      <Typography variant='body2' className='text-neutral-dark'>
        {intl.get(
          'SETTINGS_PAGE.TEMPLATE_PAGE.OVERVIEW_SECTION.SECTION_DESCRIPTION'
        )}
      </Typography>
      {displayBanner && (
        <GlobalBanner
          variant='warning'
          className='mt-2.5'
          data-cy='teamplate-overview__update-warning'
        >
          {() => (
            <Typography variant='body2' className='text-neutral-darker'>
              {intl.getHTML('SETTINGS_PAGE.TEMPLATE_PAGE.UPDATE_WARNING')}
            </Typography>
          )}
        </GlobalBanner>
      )}
      <div className='grid grid-cols-2 mt-8 gap-y-6 gap-x-10%'>
        <FormItem
          label={intl.get(
            'SETTINGS_PAGE.TEMPLATE_PAGE.OVERVIEW_SECTION.TITLE_FIELD_LABEL'
          )}
          labelProps={{ required: true }}
        >
          <TextField
            aria-label={intl.get(
              'SETTINGS_PAGE.TEMPLATE_PAGE.OVERVIEW_SECTION.TITLE_FIELD_LABEL'
            )}
            value={templateData.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              updateTemplateData('name', event.target.value)
            }
            readOnly={!canUpdate}
            data-cy='template-title-field'
          />
        </FormItem>
        <FormItem
          label={intl.get(
            'SETTINGS_PAGE.TEMPLATE_PAGE.OVERVIEW_SECTION.TEMPLATE_CREATOR_FIELD_LABEL'
          )}
          aria-label={intl.get(
            'SETTINGS_PAGE.TEMPLATE_PAGE.OVERVIEW_SECTION.TEMPLATE_CREATOR_FIELD_LABEL'
          )}
        >
          <TemplateCreator
            creator={templateData.creator}
            system={templateData.system || false}
          />
        </FormItem>
        <FormItem
          label={intl.get(
            'SETTINGS_PAGE.TEMPLATE_PAGE.OVERVIEW_SECTION.DESCRIPTION_FIELD_LABEL'
          )}
          labelProps={{ required: true }}
        >
          <TextArea
            textAreaProps={{
              'aria-label': intl.get(
                'SETTINGS_PAGE.TEMPLATE_PAGE.OVERVIEW_SECTION.DESCRIPTION_FIELD_LABEL'
              ),
              'data-cy': 'template-description-field',
            }}
            value={templateData.description || ''}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              updateTemplateData('description', event.target.value)
            }
            readOnly={!canUpdate}
          />
        </FormItem>
        <FormItem
          label={intl.get(
            'SETTINGS_PAGE.TEMPLATE_PAGE.OVERVIEW_SECTION.TEMPLATE_OWNER_FIELD_LABEL'
          )}
          aria-label={intl.get(
            'SETTINGS_PAGE.TEMPLATE_PAGE.OVERVIEW_SECTION.TEMPLATE_OWNER_FIELD_LABEL'
          )}
        >
          <TemplateOwner
            ldUsers={ldUsers}
            owner_id={templateData.owner_id}
            system={templateData.system || false}
            onUpdateTemplateOwner={handleUpdateTemplateOwner}
          />
        </FormItem>
      </div>
    </div>
  );
};

export default Overview;
