import React from 'react';
import intl from 'react-intl-universal';
import { Tag, Tooltip, tailwindOverride } from '@getsynapse/design-system';

const TeamManagerPill = () => {
  return (
    <React.Fragment>
      <div className='sm:hidden md:hidden lg:hidden xl:block'>
        <Tag
          label={intl.get('TEAMS_CAPACITY.TEAM_MANAGER')}
          className='bg-secondary-lighter mb-2'
          textClassName='text-secondary-darker'
        />
      </div>
      <div className='sm:block md:block lg:block xl:hidden'>
        <Tooltip
          showPopper
          openMode='hover2'
          timeout={0}
          usePortal
          className='w-full'
          contentProps={{ className: 'py-1 px-2' }}
          trigger={
            <div className='mb-2'>
              <Tag
                className={tailwindOverride('bg-secondary-lighter w-full py-2')}
                label=''
              />
            </div>
          }
        >
          {intl.get('TEAMS_CAPACITY.TEAM_MANAGER')}
        </Tooltip>
      </div>
    </React.Fragment>
  );
};

export default TeamManagerPill;
