import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { ProjectTemplate } from 'utils/types/templates';

export type fetchTemplatesParams = {
  status?: 'draft' | 'published' | 'disabled';
};

type NewProjectTemplate = Pick<
  ProjectTemplate,
  | 'name'
  | 'description'
  | 'owner_id'
  | 'access'
  | 'status'
  | 'resource_type'
  | 'hidden'
>;

class ProjectTemplatesAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchProjectTemplates = async ({ status }: fetchTemplatesParams) => {
    const { data } = await this.instance.get(
      'template/retrieveProjectTemplates',
      { params: { status } }
    );
    return data.data;
  };

  addNewProjectTemplate = async (newTemplate: NewProjectTemplate) => {
    const { data } = await this.instance.post(
      'template/createProjectTemplate',
      newTemplate
    );
    return data.data;
  };

  fetchFieldTemplates = async () => {
    const { data } = await this.instance.get(
      'customFields/retrieveFieldTemplates'
    );
    return data.data;
  };

  deleteProjectTemplate: (templateId: string) => Promise<any> = async (
    templateId: string
  ) => {
    const { data } = await this.instance.delete(
      `/template/deleteProjectTemplate/${templateId}`
    );
    return data.data;
  };
}

const projectTemplatesAPI = new ProjectTemplatesAPI();

export default projectTemplatesAPI;
