import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CountAndRatio from '../../Components/CountAndRatio';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { SLICE_STATUS } from 'utils/constants';
import ErrorScreen from '../../Components/ErrorScreen';
import {
  getInReviewRequestsState,
  fetchInReviewRequests,
  getActiveFiltersQueryParams,
} from 'state/Insights/InsightsSlice';
import InReviewRequestsSkeletonLoader from './InReviewRequestsSkeletonLoader';
import { formatBarChartDataForInReviewRequests } from './helpers';
import BarChartForInReviewRequests from './BarChartForInReviewRequests';
import { Typography } from '@getsynapse/design-system';

const InReviewRequests = () => {
  const inReviewRequestsState = useSelector(getInReviewRequestsState);
  const activeFilters = useSelector(getActiveFiltersQueryParams);

  const inReviewRequestsBarChartData = formatBarChartDataForInReviewRequests(
    inReviewRequestsState.values
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInReviewRequests(activeFilters));
  }, [dispatch, activeFilters]);

  return (
    <div
      className={classnames(
        'border rounded border-neutral-lighter-two space-y-6',
        'flex flex-col items-between rounded-lg shadow-allocation-table h-full w-full px-4 py-3'
      )}
    >
      {inReviewRequestsState.status === SLICE_STATUS.LOADING ? (
        <InReviewRequestsSkeletonLoader />
      ) : inReviewRequestsState.status === SLICE_STATUS.FAILED ? (
        <ErrorScreen />
      ) : (
        <>
          <div className='w-full flex justify-between pr-8'>
            <CountAndRatio
              count={inReviewRequestsState.requestsTotal}
              countMessage={intl.get(
                'INSIGHTS_PAGE_TABS.IN_REVIEW_REQUESTS.TITLE'
              )}
            />
          </div>
          <div className='flex flex-1 flex-col'>
            {inReviewRequestsState.requestsTotal === 0 ? (
              <Typography
                variant='caption'
                className='text-neutral-lighter mt-10'
              >
                {intl.get('INSIGHTS_PAGE_TABS.NO_REQUESTS')}
              </Typography>
            ) : (
              <>
                <span className='text-xs font-semibold'>
                  {intl.get(
                    'INSIGHTS_PAGE_TABS.IN_REVIEW_REQUESTS.DAYS_IN_REVIEW'
                  )}
                </span>
                <div className='flex-1 w-full pl-4 mt-2'>
                  <BarChartForInReviewRequests
                    data={inReviewRequestsBarChartData}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default InReviewRequests;
