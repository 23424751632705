import intl from 'react-intl-universal';
import classnames from 'classnames';

const BusinessTeamsTableHead = () => {
  const headCellsClassNames = 'py-3 font-semibold';

  return [
    {
      'data-testid': 'team-name',
      columnName: 'title',
      content: intl.get('ORG_SETTINGS_PAGE.LEARNING_TEAMS.TABLE.TEAM_NAME'),
      className: classnames(headCellsClassNames, 'w-2/5'),
    },
    {
      'data-testid': 'team-description',
      columnName: 'description',
      content: intl.get('ORG_SETTINGS_PAGE.BUSINESS_TEAMS.TABLE.DESCRIPTION'),
      className: classnames(headCellsClassNames, 'w-2/5'),
    },
    {
      'data-testid': 'team-create-date',
      columnName: 'createdAt',
      content: intl.get('ORG_SETTINGS_PAGE.BUSINESS_TEAMS.TABLE.CREATION_DATE'),
      className: classnames(headCellsClassNames, 'w-1/5'),
    },
  ];
};

export default BusinessTeamsTableHead;
