import { Modal, Typography } from '@getsynapse/design-system';
import { ModalProps } from '@getsynapse/design-system/dist/Organisms/Modal/Modal';
import intl from 'react-intl-universal';

export type DeleteCommentConfirmationModalProps = ModalProps & {
  onConfirm: (commentId: string) => void;
  onCancel: () => void;
};

export const DeleteCommentConfirmationModal = ({
  onConfirm,
  onCancel,
  ...props
}: DeleteCommentConfirmationModalProps) => {
  return (
    <Modal
      {...props}
      aria-label={intl.get('COMMENT.DELETE_COMMENT.TITLE')}
      titleIcon={{
        name: 'trash',
      }}
      title={intl.get('COMMENT.DELETE_COMMENT.TITLE')}
      size='medium'
      actionButtons={[
        {
          children: intl.get('COMMENT.DELETE_COMMENT.DELETE_BUTTON'),
          onClick: onConfirm,
          'data-cy': 'confirm-modal_confirm-button',
          variant: 'primary',
          color: 'danger',
        },
        {
          children: intl.get('CANCEL'),
          variant: 'tertiary',
          onClick: onCancel,
          'data-cy': 'confirm-modal_cancel-button',
        },
      ]}
    >
      <Typography className='mb-6'>
        {intl.get('COMMENT.DELETE_COMMENT.BODY')}
      </Typography>
    </Modal>
  );
};
