import Skelton from 'Atoms/Skelton/Skelton';
import PieChartLoader from '../../Components/SkeletonLoaders/PieChartLoader';

const ByPrioritySkeletonLoader = () => (
  <div className='h-full w-full flex'>
    <div className='w-1/2 h-full flex flex-col justify-between'>
      <div>
        <Skelton className='h-4 w-10' />
        <Skelton className='h-4 w-10 mt-2' />
      </div>
      <div className='pb-10'>
        <Skelton className='h-4 w-20' />
        <Skelton className='h-4 w-20 mt-2' />
        <Skelton className='h-4 w-20 mt-2' />
      </div>
    </div>
    <PieChartLoader />
  </div>
);

export default ByPrioritySkeletonLoader;
