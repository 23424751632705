import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import intl from 'react-intl-universal';
import { TableOperationHeader } from '@getsynapse/design-system';
import { CentralizedTasksTableTab } from 'utils/types/centralizedTasks';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';
import TasksFiltersSidePanel from './TasksFiltersSidePanel/TasksFiltersSidePanel';
import AppliedFiltersTags from './TasksFiltersSidePanel/TasksAppliedFiltersTags';
import { useDispatch, useSelector } from 'react-redux';
import {
  createFilterSetting,
  selectFiltersSettingsByType,
  updateFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import { SLICE_STATUS } from 'utils/constants';
import { TaskFilters } from 'utils/types/filters';
import { RawFilters } from 'utils/filters';
import { selectTasksSearch, exportTasksStatus } from 'state/Tasks/taskSlice';
import MoreActionsPopup, {
  OptionProps,
} from 'Organisms/TableHeaderActionButtons/MoreActionsPopup';
import config from 'config/Config';
import ExportIcon from 'assets/icons/export.svg';

export interface TasksTableHeaderProps {
  onExport: (callback: () => void) => void;
  exportEnabled?: Boolean;
  taskTable: CentralizedTasksTableTab;
  onUpdateFilters: (filters: RawFilters) => void;
  onSearch: (value: string) => void;
  tabName?: string;
}

const TasksTableHeader: React.FC<TasksTableHeaderProps> = ({
  onExport,
  exportEnabled = true,
  taskTable,
  onUpdateFilters,
  onSearch,
  tabName = 'fromTeamTasks',
}) => {
  const dispatch = useDispatch();
  const exportStatus = useSelector(exportTasksStatus);
  const filtersSettings = useSelector(selectFiltersSettingsByType(taskTable));

  const [shouldDisplayFilterComponent, setShouldDisplayFilterComponent] =
    useState(false);

  const toggleFilterComponent = () =>
    setShouldDisplayFilterComponent((prev) => !prev);

  const appliedFilters = useMemo(
    () => (filtersSettings ? filtersSettings.settings : {}),
    [filtersSettings]
  );

  const handleUpdateFilters = (filters: TaskFilters) => {
    if (filtersSettings && filtersSettings.id) {
      dispatch(
        updateFilterSetting({
          id: filtersSettings.id,
          updateFields: { filter_settings: filters },
        })
      );
    } else {
      dispatch(
        createFilterSetting({
          filter_type: taskTable,
          filter_settings: filters,
        })
      );
    }
    onUpdateFilters(filters);
  };

  const searchValue = useSelector(selectTasksSearch(taskTable));
  const firstRender = useRef<boolean>(true);
  const [value, setValue] = useState<string>();
  const handleSearch = useCallback(
    (value: string) => {
      setValue(value);
      onSearch(value);
    },
    [onSearch]
  );

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      if (searchValue !== value) {
        setValue(searchValue);
      }
    }
  }, [value, searchValue, handleSearch]);

  const moreActionsList: OptionProps[] = [
    {
      label: intl.get('TASKS_LIST_PAGE.EXPORT_TASKS'),
      selectOption: onExport,
      disabled: !exportEnabled,
      renderLink: `${config.get(
        'backendURL'
      )}task_export/exportOrgTasksAsCsv?tabName=${tabName}`,
      iconSrc: ExportIcon,
      tabName: tabName,
      dataCy: 'table-header__export-button',
      loading: exportStatus === SLICE_STATUS.LOADING,
    },
  ];

  return (
    <div data-cy='centralized-tasks-table-header'>
      <TasksFiltersSidePanel
        open={shouldDisplayFilterComponent}
        onClose={toggleFilterComponent}
        filters={appliedFilters}
        taskTable={taskTable}
        onUpdateFilters={handleUpdateFilters}
      />

      <TableOperationHeader
        className='border-b-0'
        setInputValue={handleSearch}
        inputValue={value ?? ''}
        actionButtons={
          <>
            <FilterButton
              onToggleFilter={toggleFilterComponent}
              aria-pressed={shouldDisplayFilterComponent}
              data-cy='taskTable__filter-button'
            />
            <MoreActionsPopup options={moreActionsList} />
          </>
        }
      />
      <AppliedFiltersTags
        filters={appliedFilters}
        onUpdateFilters={handleUpdateFilters}
        onDisplayAllFilters={toggleFilterComponent}
      />
    </div>
  );
};

export default TasksTableHeader;
