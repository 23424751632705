import {
  createAsyncThunk,
  createSlice,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { SLICE_STATUS, INSIGHTS_DATE_FILTER_NAME } from 'utils/constants';
import ProjectsAPI from './ProjectsAPI';
import { getInsightsDefaultDateFilter } from './helpers';
import {
  InsightsProjectActiveFilter,
  BusinessUnitFilterData,
  PriorityFilterData,
  ProcessFilterData,
} from 'utils/customTypes';

interface GeneralInsights {
  status?: string;
  total: number;
  new: number;
  canceled: number;
  completed: number;
  completedWithinTimeline: number;
}

interface PriorityValues {
  projectPriority: string;
  projectCount: number;
}

interface ProjectsByPriority {
  status?: string;
  values: PriorityValues[];
}

interface ProjectsByHealth {
  status?: string;
  total: number;
  onTrack: number;
  atRisk: number;
  offTrack: number;
}

interface Process {
  title: string;
  total: number;
  stages: {
    title: string;
    value: number;
  }[];
}

interface ProjectsByProcess {
  status?: string;
  processes: {
    labels: string[];
    data: Process[];
  };
}

type ProjectStatusList =
  | 'new'
  | 'in_planning'
  | 'in_progress'
  | 'completed'
  | 'canceled'
  | 'on_hold'
  | 'closed';

export interface ProjectsInsightsStatus {
  title: ProjectStatusList;
  value: number;
}

interface ProjectsByStatus {
  status?: string;
  total?: number;
  data: ProjectsInsightsStatus[];
}

interface TaskStatus {
  title: 'New' | 'In progress' | 'On hold' | 'Completed';
  value: number;
}

interface TasksByStatus {
  status?: string;
  total: number;
  data: TaskStatus[];
}

interface ProjectFilters {
  status?: string;
  businessUnitFilterValues: BusinessUnitFilterData[];
  projectPriorityFilterValues: PriorityFilterData[];
  processNameFilterValues: ProcessFilterData[];
}

interface ProjectInsightsState {
  status?: string;
  generalInsights: GeneralInsights;
  projectsByPriority: ProjectsByPriority;
  projectsByHealth: ProjectsByHealth;
  projectsByProcess: ProjectsByProcess;
  projectsByStatus: ProjectsByStatus;
  tasksByStatus: TasksByStatus;
  projectFilters: ProjectFilters;
  activeFilters: InsightsProjectActiveFilter;
}

/* ============================= INITIAL STATE ============================== */

const initialState: ProjectInsightsState = {
  generalInsights: {
    status: SLICE_STATUS.IDLE,
    total: 0,
    new: 0,
    canceled: 0,
    completed: 0,
    completedWithinTimeline: 0,
  },
  projectsByPriority: {
    status: SLICE_STATUS.IDLE,
    values: [],
  },
  projectsByHealth: {
    status: SLICE_STATUS.IDLE,
    total: 0,
    onTrack: 0,
    atRisk: 0,
    offTrack: 0,
  },
  projectsByProcess: {
    status: SLICE_STATUS.IDLE,
    processes: {
      labels: [],
      data: [],
    },
  },
  projectsByStatus: {
    status: SLICE_STATUS.IDLE,
    data: [],
    total: 0,
  },
  tasksByStatus: {
    status: SLICE_STATUS.IDLE,
    data: [],
    total: 0,
  },
  projectFilters: {
    status: SLICE_STATUS.IDLE,
    businessUnitFilterValues: [],
    projectPriorityFilterValues: [],
    processNameFilterValues: [],
  },
  activeFilters: {
    startDate: getInsightsDefaultDateFilter(INSIGHTS_DATE_FILTER_NAME.PROJECT),
    businessUnitIds: [],
    priorities: [],
    processNames: [],
  },
};

/* ============================== REDUX THUNK =============================== */
export const fetchGeneralInsights = createAsyncThunk(
  'projectsInsights/FETCH_GENERAL_INSIGHTS',
  async (activeFilters: InsightsProjectActiveFilter) => {
    const { data } = await ProjectsAPI.fetchProjectAggregates(activeFilters);
    return data;
  }
);

export const fetchProjectsByPriority = createAsyncThunk(
  'projectsInsights/FETCH_PROJECTS_BY_PRIORITY',
  async (activeFilters: InsightsProjectActiveFilter) => {
    const { data } = await ProjectsAPI.fetchProjectsByPriority(activeFilters);
    return data;
  }
);

export const fetchProjectsByHealth = createAsyncThunk(
  'projectsInsights/FETCH_PROJECTS_BY_HEALTH',
  async (activeFilters: InsightsProjectActiveFilter) => {
    const { data } = await ProjectsAPI.fetchProjectsByHealth(activeFilters);
    return data;
  }
);

export const fetchProjectsByProcess = createAsyncThunk(
  'projectsInsights/FETCH_PROJECTS_BY_PROCESS',
  async (activeFilters: InsightsProjectActiveFilter) => {
    const { data } = await ProjectsAPI.fetchProjectsByProcess(activeFilters);
    return data;
  }
);
export const fetchProjectsByStatus = createAsyncThunk(
  'projectsInsights/FETCH_PROJECTS_BY_STATUS',

  async (activeFilters: InsightsProjectActiveFilter) => {
    const { data } = await ProjectsAPI.fetchProjectsByStatus(activeFilters);
    return data;
  }
);
export const fetchTasksByStatus = createAsyncThunk(
  'projectsInsights/FETCH_TASKS_BY_STATUS',
  async (activeFilters: InsightsProjectActiveFilter) => {
    const { data } = await ProjectsAPI.fetchProjectTasksByStatus(activeFilters);
    return data;
  }
);

export const fetchFilters = createAsyncThunk(
  'projectsInsights/FETCH_FILTERS',
  async () => {
    const { data } = await ProjectsAPI.fetchFilters();
    return data;
  }
);

export const setActiveFilters = createAction<InsightsProjectActiveFilter>(
  'projectsInsights/SET_ACTIVE_FILTERS'
);

/* ================================= REDUCER ================================ */
const projectsSlice = createSlice({
  name: 'insights',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGeneralInsights.pending, (state) => {
        state.generalInsights.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchGeneralInsights.rejected, (state) => {
        state.generalInsights.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchGeneralInsights.fulfilled, (state, action) => {
        state.generalInsights.status = SLICE_STATUS.IDLE;
        state.generalInsights = action.payload.projectAggregates;
      })
      .addCase(fetchProjectsByPriority.pending, (state) => {
        state.projectsByPriority.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchProjectsByPriority.rejected, (state) => {
        state.projectsByPriority.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchProjectsByPriority.fulfilled, (state, action) => {
        const values = action.payload.projectsByPriority.map((item: any) => {
          return {
            projectPriority: item.projectPriority,
            projectCount: Number(item.projectCount),
            ratio: Number(item.ratio),
          };
        });
        state.projectsByPriority.values = values;
        state.projectsByPriority.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchProjectsByHealth.pending, (state) => {
        state.projectsByHealth.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchProjectsByHealth.rejected, (state) => {
        state.projectsByHealth.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchProjectsByHealth.fulfilled, (state, action) => {
        state.projectsByHealth.status = SLICE_STATUS.IDLE;
        state.projectsByHealth.total =
          Number(action.payload.projectsInProgess) || 0;
        action.payload.projectsByHealth.forEach((item: any) => {
          if (item.projectHealth === 'On track') {
            state.projectsByHealth.onTrack = Number(item.count) || 0;
          }
          if (item.projectHealth === 'At risk') {
            state.projectsByHealth.atRisk = Number(item.count) || 0;
          }
          if (item.projectHealth === 'Off track') {
            state.projectsByHealth.offTrack = Number(item.count) || 0;
          }
        });
      })
      .addCase(fetchProjectsByProcess.pending, (state) => {
        state.projectsByProcess.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchProjectsByProcess.rejected, (state) => {
        state.projectsByProcess.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchProjectsByProcess.fulfilled, (state, action) => {
        state.projectsByProcess.status = SLICE_STATUS.IDLE;
        state.projectsByProcess.processes.labels =
          action.payload.projectProcesses || [];
        state.projectsByProcess.processes.data =
          action.payload.projectsByProcess.map((item: any) => {
            const total = item.byStage.reduce(
              (
                acc: number,
                curr: { stageName: string; projectCount: number }
              ) => acc + Number(curr.projectCount) || 0,
              0
            );
            return {
              title: item.processName,
              total,
              stages: item.byStage.map((stage: any) => ({
                title: stage.stageName,
                value: stage.projectCount,
              })),
            };
          });
      })
      .addCase(fetchProjectsByStatus.pending, (state) => {
        state.projectsByStatus.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchProjectsByStatus.rejected, (state) => {
        state.projectsByStatus.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchProjectsByStatus.fulfilled, (state, action) => {
        state.projectsByStatus = action.payload.projectsByStatus.reduce(
          (acc: any, curr: any) => {
            return {
              total: acc.total + Number(curr.projectCount) || 0,
              data: acc.data.concat({
                title: curr.projectStatus,
                value: Number(curr.projectCount) || 0,
              }),
              status: SLICE_STATUS.IDLE,
            };
          },
          {
            total: 0,
            data: [],
            status: SLICE_STATUS.IDLE,
          }
        );
      })
      .addCase(fetchTasksByStatus.pending, (state) => {
        state.tasksByStatus.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchTasksByStatus.rejected, (state) => {
        state.tasksByStatus.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchTasksByStatus.fulfilled, (state, action) => {
        state.tasksByStatus = action.payload.tasksByStatus.reduce(
          (acc: any, curr: any) => {
            return {
              total: acc.total + Number(curr.taskCount) || 0,
              data: acc.data.concat({
                title: curr.taskStatus,
                value: Number(curr.taskCount) || 0,
              }),
              status: SLICE_STATUS.IDLE,
            };
          },
          {
            total: 0,
            data: [],
            status: SLICE_STATUS.IDLE,
          }
        );
      })
      .addCase(fetchFilters.pending, (state) => {
        state.projectFilters.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchFilters.rejected, (state) => {
        state.projectFilters.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchFilters.fulfilled, (state, action) => {
        state.projectFilters.status = SLICE_STATUS.IDLE;
        state.projectFilters = action.payload;
      })
      .addCase(setActiveFilters, (state, action) => {
        state.activeFilters = action.payload;
      });
  },
});

/* =============================== SELECTORS ================================ */

export const projectsInsights = (state: RootState) => state.projectsInsights;

export const getGeneralInsights = createSelector(
  projectsInsights,
  (state) => state.generalInsights
);

export const getprojectsByPriority = createSelector(
  projectsInsights,
  (state) => state.projectsByPriority
);

export const getProjectsByHealth = createSelector(
  projectsInsights,
  (state) => state.projectsByHealth
);

export const getProjectsByProcess = createSelector(
  projectsInsights,
  (state) => state.projectsByProcess
);

export const getProjectsByStatus = createSelector(
  projectsInsights,
  (state) => state.projectsByStatus
);

export const getTasksByStatus = createSelector(
  projectsInsights,
  (state) => state.tasksByStatus
);

export const getFilters = createSelector(
  projectsInsights,
  (state) => state.projectFilters
);

export const getActiveFiltersQueryParams = createSelector(
  projectsInsights,
  (state) => {
    return state.activeFilters;
  }
);

export default projectsSlice.reducer;
