import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import useModal from 'Hooks/useModal';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { NewProject, objKeyAsString } from 'utils/customTypes';
import { PROJECT_PRIVACY, PATHS, SLICE_STATUS } from 'utils/constants';
import {
  getCurrentProjectData,
  getCurrentProjectId,
  resetProject,
} from 'state/Project/projectSlice';
import {
  duplicateProject,
  selectProjectStatus,
} from 'state/Projects/projectsSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { getLDUsers } from 'state/UsersManagement/usersManagementSlice';
import { getOrganizationProcesses } from 'state/Processes/processesSlice';
import ProjectCreationForm from '../ProjectCreationForm';

const requiredFields = [
  'title',
  'owners',
  'startDate',
  'targetCompletionDate',
  'privacy',
] as const;

const DuplicateProjectModal: React.FC<{
  isOpen: boolean;
  onCloseModal: () => void;
}> = ({ isOpen = false, onCloseModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { Modal, modalProps, openModal, closeModal } = useModal();
  const originalProjectData = useSelector(getCurrentProjectData);
  const originalProjectId = useSelector(getCurrentProjectId);
  const projectSliceStatus = useSelector(selectProjectStatus);
  const [learningTeamIds, setLearningTeamIds] = useState<string[]>([]);
  const [currentSavedProjectData, setCurrentSavedProjectData] =
    useState<NewProject>(originalProjectData);

  useEffect(() => {
    if (isOpen && !modalProps.isOpen) {
      openModal(modalProps);
    }
  }, [openModal, modalProps, isOpen]);

  useEffect(() => {
    if (originalProjectData) {
      setCurrentSavedProjectData({
        ...originalProjectData,
        title: `${originalProjectData.title} - Copy`,
        privacy: '',
        startDate: null,
        targetCompletionDate: null,
      });
    }
  }, [originalProjectData, isOpen]);

  useEffect(() => {
    dispatch(getLDUsers());
    dispatch(getOrganizationProcesses());
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    dispatch(resetProject);
    onCloseModal();
    closeModal();
  }, [onCloseModal, dispatch, closeModal]);

  const handleUpdateProjectData = (newValue: objKeyAsString) => {
    setCurrentSavedProjectData((prevState: NewProject) => ({
      ...prevState,
      ...newValue,
    }));
  };

  const handleSave = useCallback(
    async (redirectToProject: boolean = false) => {
      const {
        title,
        owners,
        startDate,
        targetCompletionDate,
        category_id,
        process_id,
        privacy,
      } = currentSavedProjectData;
      const duplicatedProject = await dispatch(
        duplicateProject({
          projectId: originalProjectId,
          data: {
            title,
            owners,
            startDate,
            targetCompletionDate,
            category_id,
            process_id,
            privacy,
            learningTeamIds,
          },
        })
      );
      dispatch(
        showNotificationBanner({
          notificationVariant: 'success',
          notificationText: intl.get(
            'REQUEST_PAGE.TOP_BAR.CREATE_PROJECT_MODAL.SUCCESS_MESSAGE',
            {
              projectTitle: currentSavedProjectData.title,
            }
          ),
        })
      );
      const duplicateProjectId = get(duplicatedProject, 'payload.id');
      if (duplicateProjectId && redirectToProject) {
        history.push(`${PATHS.PROJECT_PAGE}/${duplicateProjectId}`);
      } else {
        handleCloseModal();
      }
    },
    [
      currentSavedProjectData,
      learningTeamIds,
      handleCloseModal,
      dispatch,
      history,
      originalProjectId,
    ]
  );

  const checkEmpty = useCallback((value: any) => {
    if (Array.isArray(value)) {
      return isEmpty(value);
    }
    return value == null || value === '';
  }, []);

  const canSave = useMemo(() => {
    if (isEmpty(currentSavedProjectData)) {
      return false;
    }

    if (
      currentSavedProjectData.privacy === PROJECT_PRIVACY.TEAM &&
      isEmpty(learningTeamIds)
    ) {
      return false;
    }

    const missingRequiredFields = requiredFields.some((field) =>
      checkEmpty(currentSavedProjectData[field])
    );
    if (missingRequiredFields) {
      return false;
    }

    return true;
  }, [currentSavedProjectData, learningTeamIds, checkEmpty]);

  const actionButtons = useMemo(
    () => [
      {
        children: intl.get('SAVE_OPEN'),
        variant: 'primary',
        className: 'h-10',
        disabled: !canSave,
        loading: projectSliceStatus === SLICE_STATUS.LOADING,
        onClick: () => handleSave(true),
      },
      {
        children: intl.get('SAVE_NOW'),
        variant: 'secondary',
        className: 'h-10',
        disabled: !canSave,
        loading: projectSliceStatus === SLICE_STATUS.LOADING,
        onClick: () => handleSave(),
      },
      {
        children: intl.get('CANCEL'),
        variant: 'tertiary',
        onClick: handleCloseModal,
        className: 'h-10',
      },
    ],
    [canSave, projectSliceStatus, handleCloseModal, handleSave]
  );

  return (
    <Modal
      {...modalProps}
      aria-label={intl.get('DUPLICATE_PROJECT_MODAL.TITLE')}
      title={intl.get('DUPLICATE_PROJECT_MODAL.TITLE')}
      size='large'
      actionButtons={actionButtons}
      closeModal={handleCloseModal}
    >
      <ProjectCreationForm
        description={intl.get('DUPLICATE_PROJECT_MODAL.DESCRIPTION')}
        setLearningTeamIds={setLearningTeamIds}
        data={currentSavedProjectData}
        updateValue={handleUpdateProjectData}
      />
    </Modal>
  );
};

export default DuplicateProjectModal;
