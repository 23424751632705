import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getApprovedRequestsAnalytics,
  fetchApprovedRequests,
  getActiveFiltersQueryParams,
} from 'state/Insights/InsightsSlice';
import BarChartForApprovedRequests from './BarChartForApprovedRequests';
import CountAndRatio from '../../Components/CountAndRatio';
import intl from 'react-intl-universal';
import { SLICE_STATUS } from 'utils/constants';
import ErrorScreen from '../../Components/ErrorScreen';
import ApprovedRequestsSkeletonLoader from './ApprovedRequestsSkeletonLoader';
import { Typography } from '@getsynapse/design-system';
import { Card } from 'Pages/InsightsPage/Components/Card';

const ApprovedRequests = () => {
  const approvedRequestsAnalytics = useSelector(getApprovedRequestsAnalytics);
  const dispatch = useDispatch();
  const activeFilters = useSelector(getActiveFiltersQueryParams);

  useEffect(() => {
    dispatch(fetchApprovedRequests(activeFilters));
  }, [dispatch, activeFilters]);

  return (
    <Card className='w-3/5 px-4 pt-3 h-full flex flex-col'>
      {approvedRequestsAnalytics.status === SLICE_STATUS.LOADING ? (
        <ApprovedRequestsSkeletonLoader />
      ) : approvedRequestsAnalytics.status === SLICE_STATUS.FAILED ? (
        <ErrorScreen />
      ) : (
        <>
          <div className='flex flex-col'>
            <CountAndRatio
              count={approvedRequestsAnalytics.requestsTotal}
              countMessage={intl.get(
                'INSIGHTS_PAGE_TABS.APPROVED_REQUESTS.TITLE'
              )}
              ratioMessage={intl.get(
                'INSIGHTS_PAGE_TABS.APPROVED_REQUESTS.APPROVAL_RATIO',
                {
                  ratio: Math.round(
                    approvedRequestsAnalytics.approvalRatio * 100
                  ),
                }
              )}
            />
          </div>

          <p className='text-xs font-semibold mt-4'>
            {intl.get('INSIGHTS_PAGE_TABS.APPROVED_REQUESTS.CHART_LABEL')}
          </p>

          {approvedRequestsAnalytics.requestsTotal === 0 ? (
            <div className='flex grow items-center py-4 justify-center h-48'>
              <Typography variant='caption' className='text-neutral-lighter'>
                {intl.get('INSIGHTS_PAGE_TABS.NO_REQUESTS')}
              </Typography>
            </div>
          ) : (
            <div className='h-full pb-4 flex items-end justify-center'>
              <BarChartForApprovedRequests
                data={approvedRequestsAnalytics.values}
              />
            </div>
          )}
        </>
      )}
    </Card>
  );
};

export default ApprovedRequests;
