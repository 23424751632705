import { useState, useCallback, useEffect } from 'react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import TeamsCapacity from '../../views/TeamsCapacity/TeamsCapacity';
import CapacityPage from '../../components/CapacityPage/CapacityPage';

const CapacityTab = () => {
  const ldClient = useLDClient();
  const { tasksAssignmentsBasedResourcesCapacity } = useFlags();
  const [shouldRender, setShouldRender] = useState<boolean>(false);

  const init = useCallback(async () => {
    try {
      await ldClient?.waitForInitialization();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error initializing LaunchDarkly client', error);
    } finally {
      setShouldRender(true);
    }
  }, [ldClient]);

  useEffect(() => {
    init();
  }, [init]);

  if (!shouldRender) {
    return null;
  }

  if (tasksAssignmentsBasedResourcesCapacity) {
    return <TeamsCapacity />;
  }

  return <CapacityPage />;
};

export default CapacityTab;
