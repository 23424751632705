import { REQUEST_STATUS } from 'utils/constants';

const REQUEST_STATUS_STROKE_COLOR_MAP = {
  [REQUEST_STATUS.APPROVED]: '#FFC107',
  [REQUEST_STATUS.CANCELED]: '#4CAF50',
};

export const getLineStrokeColor = (status: string) => {
  return REQUEST_STATUS_STROKE_COLOR_MAP[status];
};

export const X_Y_AXIS_STYLE = {
  fontSize: '0.75em',
  fontWeight: '400',
  lineHeight: '1em',
  color: '#000000',
};
