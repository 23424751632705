import intl from 'react-intl-universal';
import { Tag } from '@getsynapse/design-system';
import { useMemo } from 'react';
import classnames from 'classnames';
import intangibleIcon from 'assets/icons/intangible-benefits-strategy.svg';
import monetaryIcon from 'assets/icons/monetary-icon.svg';

type Props = {
  isBenefitMonetary: boolean | null;
  annualBenefit?: number | null;
};

const AdditionalBenefitTag = ({ isBenefitMonetary, annualBenefit }: Props) => {
  const tagProperties = useMemo(() => {
    let tagLabel;
    let backgroundClassName;
    let textClassName;
    let leadingIcon;

    if (isBenefitMonetary === true) {
      if (!annualBenefit) {
        tagLabel = intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.TAGS.MONETARY'
        );
        backgroundClassName = 'bg-secondary-lightest';
        textClassName = 'text-secondary-darker';
        leadingIcon = { src: monetaryIcon };
      } else {
        tagLabel = `${intl.get('DOLLAR_SIGN')}${annualBenefit?.toLocaleString(
          'en-US'
        )}`;
        backgroundClassName = 'bg-neutral-lighter-two';
        textClassName = 'text-neutral-dark';
      }
    } else if (isBenefitMonetary === false) {
      tagLabel = intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.TAGS.INTANGIBLE'
      );
      backgroundClassName = 'bg-secondary-lightest';
      textClassName = 'text-secondary-darker';
      leadingIcon = { src: intangibleIcon };
    } else {
      tagLabel = intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.TAGS.NO_INFORMATION'
      );
      backgroundClassName = 'bg-neutral-lighter-two';
      textClassName = 'text-neutral';
    }

    return { tagLabel, backgroundClassName, textClassName, leadingIcon };
  }, [isBenefitMonetary, annualBenefit]);

  return (
    <Tag
      label={tagProperties.tagLabel}
      className={classnames(
        'py-1 px-2 my-0',
        tagProperties.backgroundClassName
      )}
      textClassName={classnames('font-semibold', tagProperties.textClassName)}
      leadingIcon={tagProperties.leadingIcon}
    />
  );
};

export default AdditionalBenefitTag;
