import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import { useParams } from 'react-router-dom';
import { Typography, tailwindOverride } from '@getsynapse/design-system';
import { trackEvent } from 'Services/pendo';
import { Objective } from 'utils/types/program';
import { PATHS, PENDO_EVENTS } from 'utils/constants';
import ResultsTag from './ResultsTag';

type Props = {
  objective: Objective;
};

const CategoryObjectiveCard = ({ objective }: Props) => {
  const { programId, categoryId } = useParams<{
    programId: string;
    categoryId: string;
  }>();

  const onManageObjective = () => {
    trackEvent(PENDO_EVENTS.CTA_MANAGE_OBJECTIVE, {});
  };

  return (
    <Link
      to={`${PATHS.PROGRAM_PAGE}/${programId}${PATHS.STRATEGY_PAGE}/${categoryId}${PATHS.CATEGORY_OBJECTIVE_PAGE}/${objective.id}`}
      data-testid={`category-objective-${objective.id}_card`}
      key={objective.id}
      className={tailwindOverride(
        'group py-5 px-6 relative',
        'border rounded border-primary-lighter-two',
        'transition duration-100 ease-linear',
        'shadow-header',
        'hover:border-secondary-light',
        'hover:shadow-lifted',
        'flex flex-wrap'
      )}
      onClick={onManageObjective}
    >
      <Typography
        data-testid={`category-objective-${objective.id}_number`}
        weight='medium'
        className='leading-6 pb-1 w-full'
      >
        {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE', {
          number: objective?.position,
        })}
      </Typography>
      <Typography
        variant='body2'
        title={objective.name}
        data-testid={`category-objective-${objective.id}_name`}
        className='w-full leading-6 line-clamp-1'
      >
        {objective.name}
      </Typography>
      <div
        className={tailwindOverride(
          'sm:flex-col md:flex-col',
          'lg:flex-row',
          'w-full pt-3 flex items-start'
        )}
      >
        <div className='flex'>
          {objective.isMet === null &&
            (objective.annualBenefit === null ||
              objective.annualBenefit === undefined) && (
              <ResultsTag type='noResults' objectiveId={objective.id} />
            )}
          {objective.isMet && (
            <ResultsTag
              type='objectiveMet'
              objectiveId={objective.id}
              className='mr-0.5'
            />
          )}
          {objective.isMet === false && (
            <ResultsTag
              type='objectiveNotMet'
              objectiveId={objective.id}
              className='mr-0.5'
            />
          )}
          {objective.annualBenefit !== null &&
            objective.annualBenefit !== undefined && (
              <ResultsTag
                type='monetaryBenefits'
                objectiveResult={objective.annualBenefit}
                objectiveId={objective.id}
              />
            )}
        </div>
        <Typography
          data-testid={`category-objective-${objective.id}_manage-objective`}
          variant='body2'
          title={intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.MANAGE_OBJECTIVE'
          )}
          className={tailwindOverride(
            'block ml-auto text-sm font-medium',
            'text-purple-dark',
            'group-hover:text-purple-darker',
            'text-center leading-5'
          )}
          onClick={onManageObjective}
        >
          {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.MANAGE_OBJECTIVE')}
        </Typography>
      </div>
    </Link>
  );
};

export default CategoryObjectiveCard;
