import React from 'react';
import {
  useElevation,
  tailwindOverride,
  Typography,
  Icon,
} from '@getsynapse/design-system';
import { UserViewSettings, Option } from 'utils/customTypes';
import { CapacityView } from 'utils/types/capacityView';

interface ViewPickerOption extends Option {
  label: string;
  value: CapacityView;
  option: UserViewSettings;
}

const ViewPickerTrigger: React.FC<{
  onToggleDropdown: () => void;
  selectedOptions: ViewPickerOption[];
  disabled: boolean;
  isDropdownExpanded: boolean;
}> = ({
  onToggleDropdown,
  selectedOptions,
  disabled,
  isDropdownExpanded,
  ...props
}) => {
  const hoverElevation = useElevation(1);
  return (
    <div
      aria-label='capacity-view-picker'
      onClick={!disabled ? onToggleDropdown : () => {}}
      className={tailwindOverride(
        'h-8 min-h-8 min-w-22',
        'py-1 pl-2 pr-5',
        'flex items-center justify-center',
        'gap-x-2 rounded border relative',
        {
          'border-neutral-lighter text-neutral-light': disabled,
          'bg-neutral-lightest hover:border-neutral-lighter': disabled,
          'focus:ring-0 active:ring-0 focus-visible:ring-0': disabled,
          'focus-visible:outline-none cursor-not-allowed': disabled,
          'ring-primary border-primary': isDropdownExpanded,
        },
        {
          'border-neutral-light hover:border-neutral-dark focus:border-primary active:ring-primary text-neutral-black':
            !disabled && !isDropdownExpanded,
          [`hover:${hoverElevation} focus:border-primary`]: !disabled,
          'focus-visible:outline-none focus:ring-primary': !disabled,
          'focus-visible:ring-primary bg-neutral-white': !disabled,
        }
      )}
      {...props}
    >
      <Typography variant='body'>{selectedOptions[0].label}</Typography>
      <Icon
        name={isDropdownExpanded ? 'caret-up-outline' : 'caret-down-outline'}
        className={tailwindOverride(
          'text-neutral-dark text-sm',
          'absolute top-0 right-0',
          'mt-2 mr-1.5'
        )}
      />
    </div>
  );
};

export default ViewPickerTrigger;
