import React, { useState, useEffect } from 'react';
import { SidePanelFilter, Icon } from '@getsynapse/design-system';
import { ProjectFilters } from 'utils/types/filters';
import FiltersForm from './FiltersForm';
import CustomFieldsFilters from './CustomFieldsFilters';
import intl from 'react-intl-universal';
import { fetchFieldsUsedInProjects } from 'state/CustomFields/customFieldsSlice';
import { useDispatch } from 'react-redux';

export type Props = {
  open: boolean;
  onUpdateFilters: (filters: ProjectFilters) => void;
  onClose: () => void;
  filters?: ProjectFilters;
  isBoardView?: boolean;
};

const ProjectsFiltersSidePanel: React.FC<Props> = ({
  open,
  filters = {},
  onUpdateFilters,
  onClose,
  isBoardView = false,
}) => {
  const dispatch = useDispatch();
  const [appliedFilters, setAppliedFilters] = useState<ProjectFilters>(
    {} as ProjectFilters
  );
  const [canApplyFilters, setCanApplyFilters] = useState<boolean>(false);
  const [showCustomFilters, setShowCustomFilters] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    if (filters) {
      setAppliedFilters(filters as ProjectFilters);
    }
  }, [filters]);

  useEffect(() => {
    dispatch(fetchFieldsUsedInProjects());
  }, [dispatch]);

  const handleUpdateFilters = (
    updatedFilters: ProjectFilters,
    isClosing?: boolean
  ) => {
    setAppliedFilters(updatedFilters);
    if (!isClosing) {
      setCanApplyFilters(true);
    }
  };

  const handleApplyFilters = () => {
    onUpdateFilters(appliedFilters);
    setCanApplyFilters(false);
  };

  const handleClearAllFilters = () => {
    onUpdateFilters({} as ProjectFilters);
    setAppliedFilters({} as ProjectFilters);
    setCanApplyFilters(false);
  };

  return (
    <SidePanelFilter
      open={open}
      onClose={() => {
        onClose();
        setShowCustomFilters(false);
        setShowAnimation(false);
        handleUpdateFilters(filters as ProjectFilters, true);
      }}
      appliedFiltersCount={Object.keys(appliedFilters).length}
      canApplyFilters={canApplyFilters}
      onClearAllFilters={handleClearAllFilters}
      onApplyFilters={handleApplyFilters}
      data-testid='projects-filters-side-panel'
      title={`${showCustomFilters ? '' : 'Filters'}`}
    >
      {!showCustomFilters ? (
        <FiltersForm
          filters={appliedFilters as ProjectFilters}
          onUpdateFilters={handleUpdateFilters}
          isBoardView={isBoardView}
          setShowCustomFilters={setShowCustomFilters}
          showAnimation={showAnimation}
        />
      ) : (
        <div className='animate-sidePanel'>
          <button
            className='text-neutral-dark hover:text-neutral-black focus-visible:text-neutral-black active:text-neutral-black py-1 focus:outline-none text-sm leading-6 focus:ring-0'
            onClick={() => {
              setShowCustomFilters(false);
              setShowAnimation(true);
            }}
            data-testid='back-to-filters-button'
          >
            <div className='flex items-center'>
              <Icon name='arrow-back' className='mr-2 text-xl' />
              {intl.get('BACK')}
            </div>
          </button>
          <CustomFieldsFilters
            filters={appliedFilters as ProjectFilters}
            onUpdateFilters={handleUpdateFilters}
          />
        </div>
      )}
    </SidePanelFilter>
  );
};

export default ProjectsFiltersSidePanel;
