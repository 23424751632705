import { combineReducers } from '@reduxjs/toolkit';

import teamsCapacity from './TeamsCapacity/teamsCapacitySlice';
import days from './Days/daysSlice';

import teamMembersCapacity from './TeamMembersCapacity/teamMembersCapacitySlice';
import teamMemberProjectsCapacity from './TeamMemberProjectsCapacity/teamMemberProjectsCapacitySlice';

export default combineReducers({
  teamsCapacity,
  days,
  teamMembersCapacity,
  teamMemberProjectsCapacity,
});
