import React from 'react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import { Icon, Typography, tailwindOverride } from '@getsynapse/design-system';
import { PATHS, TEAMS_PAGE_TABS } from 'utils/constants';

const NoCapacity: React.FC<{ userId: string }> = ({ userId, ...props }) => {
  return (
    <div
      className={tailwindOverride(
        'w-full h-6',
        'px-2 mt-4',
        'bg-transparent',
        'absolute top-0 left-0 z-10'
      )}
    >
      <Link
        to={`${PATHS.TEAMS}/${TEAMS_PAGE_TABS.TEAMS}?user=${userId}`}
        className={tailwindOverride(
          'w-full h-6',
          'flex flex-grow flex-shrink-0 flex items-center',
          'rounded-sm px-2',
          'bg-primary-lighter-two',
          'border border-primary-lighter'
        )}
        style={{
          boxShadow: `0px 2px 2px 0px #12151B0D, 2px 0px 0px 0px rgb(var(--color-primary-dark)) inset`,
        }}
        role='link'
        {...props}
      >
        <Icon
          name='alert-circle'
          className='text-primary-dark text-lg mr-2'
          aria-hidden='true'
        />
        <Typography
          variant='caption'
          weight='medium'
          className='text-primary-darker'
        >
          {intl.get('TEAMS_CAPACITY.ASSIGN_CAPACITY')}
        </Typography>
      </Link>
    </div>
  );
};

export default NoCapacity;
