import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTeamsList,
  selectTeamsListStateStatus,
} from 'state/Capacities/TeamsList/teamsListSlice';
import { SLICE_STATUS } from 'utils/constants';
import { Status } from 'utils/customTypes';
import SkeletonLoader from '../WeeklyCapacity/components/CapacityTable/components/SkeletonLoader';
import CapacityTable from '../CapacityTable/CapacityTable';
import { initWeeksArray } from 'state/WeeklyTeamCapacity/Weeks/weeksSlice';
import { toDateString } from 'state/WeeklyTeamCapacity/Weeks/helpers';
import { resetWeeklyCapacityState } from 'state/WeeklyTeamCapacity/TeamsCapacity/teamsCapacitySlice';
import useWeeklyCapacity from './hooks/useWeeklyCapacity/useWeeklyCapacity';
import useCapacityViewState from '../../hooks/useCapacityViewState/useCapacityViewState';

const BetaWeeklyCapacity = () => {
  const dispatch = useDispatch();
  const previousStatus = useRef<Status>(SLICE_STATUS.IDLE);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const status = useSelector(selectTeamsListStateStatus);
  const weeklyCapacityCallbacks = useWeeklyCapacity();
  const capacityViewStartDate = useCapacityViewState('WEEKLY');

  useEffect(() => {
    dispatch(fetchTeamsList());
    return () => {
      dispatch(resetWeeklyCapacityState());
    };
  }, [dispatch]);

  useEffect(() => {
    let weeklyCapacityStartDate = new Date();
    if (capacityViewStartDate) {
      weeklyCapacityStartDate = new Date(
        capacityViewStartDate.replace(/-/g, '/')
      );
    }
    dispatch(
      initWeeksArray({ startDate: toDateString(weeklyCapacityStartDate) })
    );
  }, [dispatch, capacityViewStartDate]);

  useEffect(() => {
    if (previousStatus.current !== status) {
      previousStatus.current = status;
      if (status === SLICE_STATUS.IDLE && isLoading) {
        setIsLoading(false);
      }
    }
  }, [status, isLoading]);

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return <CapacityTable scrollHandlerCallbacks={weeklyCapacityCallbacks} />;
};

export default BetaWeeklyCapacity;
