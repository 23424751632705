import {
  Datepicker,
  FormItem,
  tailwindOverride,
} from '@getsynapse/design-system';
import { TASK_STATUS } from 'utils/constants';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import {
  selectTaskCompletionDate,
  selectTaskStartDate,
  selectTaskStatus,
} from 'state/TaskDetail/taskSlice';

interface TaskActualCompletionDateFieldProps {
  isOnSidepanel?: string;
  isViewOnly?: boolean;
  isReadOnly?: boolean;
}

const TaskActualCompletionDateField = ({
  isOnSidepanel,
  isReadOnly,
  isViewOnly,
}: TaskActualCompletionDateFieldProps) => {
  const startDate = useSelector(selectTaskStartDate);
  const taskStatus = useSelector(selectTaskStatus);
  const taskCompletionDate = useSelector(selectTaskCompletionDate);

  const handleDatePick = (date: string) => {};

  if (taskStatus !== TASK_STATUS.COMPLETED) {
    return null;
  }

  return (
    <FormItem
      label={intl.get('TASKS.TASK_DETAIL_PAGE.ACTUAL_COMPLETION_DATE')}
      labelProps={{ required: true }}
      component='div'
      className={tailwindOverride({ 'mt-4': isOnSidepanel }, 'ml-4 w-full')}
      // helpText={
      //   requiredFieldsErrors?.completion_date &&
      //   intl.get('TASKS.TASK_DETAIL_PAGE.MISSING_INFO_ERROR')
      // }
      // helpTextProps={{
      //   state: requiredFieldsErrors?.completion_date ? 'error' : 'default',
      // }}
    >
      <Datepicker
        disabled={isViewOnly || isReadOnly}
        className='w-full'
        startDate={taskCompletionDate}
        onPickDate={handleDatePick}
        minDate={startDate}
      />
    </FormItem>
  );
};

export default TaskActualCompletionDateField;
