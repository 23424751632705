import React, { forwardRef } from 'react';
import intl from 'react-intl-universal';
import {
  Icon,
  Tooltip,
  Typography,
  tailwindOverride,
} from '@getsynapse/design-system';
import dayOffIcon from 'assets/icons/day-off.svg';

export const TooltipTrigger = forwardRef<HTMLDivElement, { label: string }>(
  ({ label, ...otherProps }, ref) => (
    <div
      className={tailwindOverride(
        'w-full h-6 px-2 rounded-sm',
        'flex justify-center items-center cursor-pointer',
        'border border-primary-light',
        'bg-primary-lighter gap-x-1'
      )}
      ref={ref}
      style={{
        boxShadow:
          '0px 2px 2px 0px #12151B0D, 2px 0px 0px 0px rgb(var(--color-primary-dark)) inset',
      }}
      {...otherProps}
    >
      <Icon
        src={dayOffIcon}
        className='text-primary-darker text-xl'
        aria-label='time-off-icon'
      />
      <Typography
        className='text-primary-darker'
        variant='label'
        weight='medium'
      >
        {label}
      </Typography>
    </div>
  )
);

const TimeOffAllocation: React.FC<{
  timeOff: number;
  'data-testid'?: string;
  'data-cy'?: string;
}> = ({ timeOff, ...otherProps }) => {
  const timeLabel = `${timeOff}${intl.get(
    'PEOPLE.RESOURCE_ALLOCATION.UPDATE_WEEKLY_ALLOCATION_MODAL.HOUR_LABEL'
  )}`;
  return (
    <Tooltip
      className='flex w-full py-1'
      trigger={<TooltipTrigger label={timeLabel} />}
      ariaId={otherProps['data-testid'] || 'time-off-allocation'}
      showPopper
      usePortal
      openMode='hover2'
      timeout={0}
      position='topCenter'
      contentProps={{
        className: tailwindOverride(
          'bg-neutral-white',
          'rounded-lg p-4',
          'w-max absolute',
          'text-xs',
          'shadow-tooltip',
          'border border-purple-lighter'
        ),
      }}
      {...otherProps}
    >
      <div className='flex space-x-4'>
        <Typography
          variant='caption'
          className='text-purple-darker font-semibold'
        >
          {intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.TIME_OFF_TOOLTIP')}
        </Typography>
        <Typography variant='label' className='text-purple-darker'>
          {`${timeOff}${intl.get(
            'PEOPLE.RESOURCE_ALLOCATION.UPDATE_WEEKLY_ALLOCATION_MODAL.HOUR_LABEL'
          )}`}
        </Typography>
      </div>
    </Tooltip>
  );
};

export default TimeOffAllocation;
