import { Snackbar, tailwindOverride } from '@getsynapse/design-system';
import { Alert } from '@reach/alert';
import React, { useCallback, useState } from 'react';

type variant = 'information' | 'success' | 'error' | 'warning';

interface SnackbarProps {
  title: string;
  message?: string;
  variant?: variant;
  hasActionButton?: boolean;
  actionButtonText?: string;
  actionButtonCallback?: () => void;
  actionButtonPosition?: 'right' | 'bottom';
  hasCloseButton?: boolean;
  autoHideDuration?: string | number;
  onCloseCallback?: () => void;
  className?: string;
  cta?: React.ReactNode;
}

const SnackbarNotification: React.FC<SnackbarProps> = (props) => (
  <Alert className='fixed bottom-12 left-6 z-50' type='assertive'>
    <Snackbar data-cy='snackbar-notification' {...props} />
  </Alert>
);

interface SnackBarNotificationParams {
  notificationVariant?: variant;
  notificationTitle: string;
  notificationMessage?: string;
  timeout?: number;
  autoHide?: boolean;
  hasActionButton?: boolean;
  actionButtonText?: string;
  actionButtonCallback?: () => void;
  actionButtonPosition?: 'right' | 'bottom';
}

interface SnackbarNotificationReturn {
  snackbarProps?: SnackbarProps;
  hideNotification: () => void;
  showNotification: (params: SnackBarNotificationParams) => void;
  isNotificationVisible: boolean;
  SnackbarNotification: React.FC<SnackbarProps>;
}

const useSnackbarNotification = (): SnackbarNotificationReturn => {
  const [isNotificationVisible, setIsNotificationVisible] =
    useState<boolean>(false);
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>();

  const hideNotification = useCallback(
    () => setIsNotificationVisible(false),
    []
  );

  const showNotification = useCallback(
    ({
      actionButtonCallback,
      actionButtonPosition = 'bottom',
      actionButtonText,
      autoHide = true,
      hasActionButton = false,
      notificationMessage,
      notificationTitle,
      notificationVariant = 'success',
      timeout,
    }: SnackBarNotificationParams) => {
      setSnackbarProps({
        variant: notificationVariant,
        title: notificationTitle,
        message: notificationMessage,
        hasCloseButton: !autoHide,
        autoHideDuration: autoHide ? timeout || 4000 : 0,
        onCloseCallback: hideNotification,
        hasActionButton,
        actionButtonText,
        actionButtonPosition,
        actionButtonCallback,
        className: tailwindOverride(
          'relative bg-neutral-white',
          'animate-snackarNotification'
        ),
      });
      setIsNotificationVisible(true);
    },
    [hideNotification]
  );

  return {
    snackbarProps,
    hideNotification,
    showNotification,
    isNotificationVisible,
    SnackbarNotification,
  };
};

export default useSnackbarNotification;
