export const EventTypesOptions = [
  {
    value: '1',
    code: 'ilt',
    label: 'Instructor Led Training',
  },
  {
    value: '2',
    code: 'vilt',
    label: 'Virtual Instructor Led Training',
  },
  {
    value: '3',
    code: 'hybrid',
    label: 'Blended / Hybrid',
  },
];
