import { useEffect, useMemo, useState } from 'react';
import { useParams, Prompt, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Location } from 'history';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import { Button } from '@getsynapse/design-system';

import {
  selectProgramWithFilteredProjects,
  getProgramData,
  updateProgram,
  updateProgramOwners,
  updateProgramProjects,
  selectProgramStatus,
} from 'state/Program/programSlice';
import { isUserOrganizationAdmin, selectUserId } from 'state/User/userSlice';
import {
  getLearningTeams,
  selectLearningTeamStatus,
  selectLearningTeamIsFetched,
} from 'state/LearningTeams/learningTeamsSlice';
import Loader from 'Molecules/Loader/Loader';
import UnsavedChangesModal from 'Organisms/UnsavedChangesModal/UnsavedChangesModal';
import { PATHS, PROGRAMS_STATUS, SLICE_STATUS } from 'utils/constants';
import { Program } from 'utils/customTypes';
import ProgramLeftPanel from './ProgramLeftPanel';
import ProgramContent from './ProgramContent';

import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import PageFooter from 'Molecules/PageFooter/PageFooter';

type OverviewProps = {
  leavingToLocation: string;
  setLeavingToLocation: React.Dispatch<React.SetStateAction<string>>;
  isLeavingWarningModalOpen: boolean;
  setIsLeavingWarningModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleBlockedNavigation: (location: Location) => boolean;
  confirmedNavigation: boolean;
  setConfirmedNavigation: React.Dispatch<React.SetStateAction<boolean>>;
};

const Overview = ({
  leavingToLocation,
  setLeavingToLocation,
  isLeavingWarningModalOpen,
  setIsLeavingWarningModalOpen,
  handleBlockedNavigation,
  confirmedNavigation,
  setConfirmedNavigation,
}: OverviewProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { programId } = useParams<{ programId: string }>();
  const program: Program = useSelector(selectProgramWithFilteredProjects);
  const originalProgram = useSelector(getProgramData);
  const currentUserId = useSelector(selectUserId);
  const isUserAdmin = useSelector(isUserOrganizationAdmin);
  const programStatus = useSelector(selectProgramStatus);

  const userHasWriteAccess = useMemo(() => {
    if (!program.id || program.status === PROGRAMS_STATUS.CLOSED) {
      return false;
    }
    if (isUserAdmin) {
      return true;
    }
    return program.programOwners.some((owner) => owner.id === currentUserId);
  }, [
    isUserAdmin,
    program.programOwners,
    program.id,
    currentUserId,
    program.status,
  ]);

  const ldTeamStatus = useSelector(selectLearningTeamStatus);
  const ldTeamsFetched = useSelector(selectLearningTeamIsFetched);
  const [updatedProgram, setUpdatedProgram] = useState<Partial<Program>>(
    {} as Program
  );

  const ownersChanges: boolean = useMemo(() => {
    if (!isEmpty(updatedProgram.programOwners)) {
      const originalOwnersIds = originalProgram.programOwners.map(
        (owner) => owner.id
      );
      if (
        JSON.stringify(updatedProgram.programOwners) !==
        JSON.stringify(originalOwnersIds)
      ) {
        return true;
      }
    }
    return false;
  }, [updatedProgram.programOwners, originalProgram.programOwners]);

  const updatedProjectsIds = useMemo(
    () => originalProgram.updatedProjects?.map((project) => project.id),
    [originalProgram.updatedProjects]
  );

  const projectChanges: boolean = useMemo(() => {
    const originalProjectsIds = originalProgram.programProjects.map(
      (project) => project.id
    );
    if (
      JSON.stringify(updatedProjectsIds) !== JSON.stringify(originalProjectsIds)
    ) {
      return true;
    }
    return false;
  }, [updatedProjectsIds, originalProgram.programProjects]);

  const programChanges = useMemo(() => {
    if (
      updatedProgram.title &&
      originalProgram.title !== updatedProgram.title
    ) {
      return true;
    }
    if (
      updatedProgram.description &&
      originalProgram.description !== updatedProgram.description
    ) {
      return true;
    }

    if (
      updatedProgram.status &&
      originalProgram.status !== updatedProgram.status
    ) {
      return true;
    }
    if (
      updatedProgram.delivery_type &&
      originalProgram.delivery_type !== updatedProgram.delivery_type
    ) {
      return true;
    }
    return false;
  }, [
    originalProgram,
    updatedProgram.title,
    updatedProgram.description,
    updatedProgram.status,
    updatedProgram.delivery_type,
  ]);

  const changesDetected: boolean = useMemo(
    () => programChanges || ownersChanges || projectChanges,
    [ownersChanges, projectChanges, programChanges]
  );

  useEffect(() => {
    if (!ldTeamsFetched && ldTeamStatus !== SLICE_STATUS.LOADING) {
      dispatch(getLearningTeams());
    }
  }, [ldTeamStatus, ldTeamsFetched, dispatch]);

  useEffect(() => {
    if (confirmedNavigation && leavingToLocation) {
      history.push(leavingToLocation, {
        from: `${PATHS.PROGRAM_PAGE}/${programId}`,
      });
      setConfirmedNavigation(false);
      setLeavingToLocation('');
    }
  }, [
    confirmedNavigation,
    history,
    leavingToLocation,
    programId,
    setConfirmedNavigation,
    setLeavingToLocation,
  ]);

  useEffect(() => {
    const handleLeavingSiteWithUnsavedChanges = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    if (changesDetected) {
      window.addEventListener(
        'beforeunload',
        handleLeavingSiteWithUnsavedChanges
      );
      return () => {
        window.removeEventListener(
          'beforeunload',
          handleLeavingSiteWithUnsavedChanges
        );
      };
    }
  }, [changesDetected]);

  const handleFieldChange = (path: string, value: any) => {
    const newData = {};
    set(newData, path, value);
    setUpdatedProgram((prevState) => ({
      ...prevState,
      ...newData,
    }));
  };

  const updateProgramData = async () => {
    let success = true;

    if (ownersChanges) {
      const updateOwnersResult = await dispatch(
        updateProgramOwners({
          programId,
          ownersIds: get(updatedProgram, 'programOwners') as string[],
        })
      );
      success =
        success && get(updateOwnersResult, 'payload.programOwners', false);
      delete updatedProgram.programOwners;
    }
    if (projectChanges) {
      const updateProjectsResult = await dispatch(
        updateProgramProjects({
          programId,
          projectIds: updatedProjectsIds!,
        })
      );
      success =
        success && get(updateProjectsResult, 'payload.programProjects', false);
    }
    if (programChanges) {
      const updateProgramResult = await dispatch(
        updateProgram({ programId, updateFields: updatedProgram })
      );
      success =
        success && get(updateProgramResult, 'payload.updatedProgram', false);
    }

    if (success) {
      dispatch(
        showNotificationBanner({
          notificationVariant: 'success',
          notificationText: intl.get('PROGRAM_PAGE.UPDATE_SUCCESS'),
        })
      );
      setUpdatedProgram({} as Program);
    } else {
      dispatch(
        showNotificationBanner({
          notificationVariant: 'error',
          notificationText: intl.get('PROGRAM_PAGE.UPDATE_ERROR'),
        })
      );
    }
  };

  const cancelUpdateProgram = () => {
    setConfirmedNavigation(true);
    history.push(`${PATHS.PROGRAMS_LIST_PAGE}`);
  };

  return programStatus !== SLICE_STATUS.LOADING && get(program, 'id') ? (
    <>
      <Prompt when={changesDetected} message={handleBlockedNavigation} />
      <UnsavedChangesModal
        isOpen={isLeavingWarningModalOpen}
        setIsOpen={setIsLeavingWarningModalOpen}
        onConfirm={() => setConfirmedNavigation(true)}
      />
      <div className='max-h-details-without-bar h-screen flex'>
        <ProgramLeftPanel
          program={program}
          userHasWriteAccess={userHasWriteAccess}
          handleFieldChange={handleFieldChange}
        />
        <ProgramContent
          program={program}
          userHasWriteAccess={userHasWriteAccess}
          changesDetected={changesDetected}
        />
      </div>

      <PageFooter>
        <Button
          variant='tertiary'
          onClick={cancelUpdateProgram}
          data-cy='program-cancel-button'
        >
          {intl.get('CANCEL')}
        </Button>
        <Button
          onClick={updateProgramData}
          disabled={!changesDetected}
          data-cy='program-update-button'
        >
          {intl.get('PROGRAM_PAGE.UPDATE')}
        </Button>
      </PageFooter>
    </>
  ) : (
    <Loader />
  );
};

export default Overview;
