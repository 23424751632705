import { QuestionAnswer, EvaluationPlanItem } from 'utils/types/program';

export const formatDataForQuestionUpdate = (
  methodId: string,
  value: QuestionAnswer,
  data: EvaluationPlanItem[]
) => {
  const updateOrAddAndswerToMethod = (
    currentAnswers: QuestionAnswer[],
    newAnswer: QuestionAnswer
  ) => {
    const updatedAnswers = [...currentAnswers];
    const index = updatedAnswers.findIndex(
      (answer) => answer.question_id === newAnswer.question_id
    );
    if (index !== -1) {
      updatedAnswers.splice(index, 1, newAnswer);
    } else {
      updatedAnswers.push(newAnswer);
    }

    return updatedAnswers;
  };

  return data.map((methodData) => {
    if (methodData.data_collection_method_id === methodId) {
      return {
        ...methodData,
        collection_method_selected_options: updateOrAddAndswerToMethod(
          methodData.collection_method_selected_options,
          value
        ),
      };
    }
    return methodData;
  });
};
