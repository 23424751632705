import { useState, useEffect, useMemo, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Tabs } from '@getsynapse/design-system';
import BasicDetails from './BasicDetailsPage';
import RequestDetails from './RequestDetailsPage';
import AdditonalDetails from './AdditionalDetailsPage';
import LDOnlyPage from './LDOnlyPage';
import TopBar from './components/TopBar/TopBar';
import {
  getPropertiesComments,
  selectPropertiesComments,
} from 'state/RequestComments/requestCommentsSlice';
import { selectUserId, selectUserType } from 'state/User/userSlice';
import { REQUEST_STATUS, USER_TYPES } from 'utils/constants';
import {
  Request,
  RequestPageTabs,
  RequestQuestion,
  RequestRequiredErrors,
  UpdateReqData,
} from 'utils/customTypes';
import PendingCancellationBanner from './components/PendingCancellationBanner';
import { useRequestContext } from './context/RequestContext';
import ApprovedRequestBanner from './components/ApprovedRequest/ApprovedRequestBanner';
import CancelledRequestBanner from './components/CancelledRequest/CancelledRequestBanner';
import DeclinedRequestBanner from './components/DeclinedRequest/DeclinedRequestBanner';
import WaitlistedRequestBanner from './components/WaitlistedRequest/WaitlistedRequestBanner';
import Details from 'Molecules/DetailsPage/DetailsPage';

type Props = {
  activeTabIndex: RequestPageTabs;
  setActiveTabIndex: (index: number) => void;
  requestData: Request;
  requestQuestionsData: RequestQuestion[];
  handleLeavingPageWithUnsavedChanges: (path: string) => void;
  updatedReqData?: UpdateReqData;
  setUpdatedReqData?: React.Dispatch<React.SetStateAction<UpdateReqData>>;
  errors?: RequestRequiredErrors;
  setOwners?: React.Dispatch<React.SetStateAction<string[] | null>>;
  setBusinessReviewers?: React.Dispatch<React.SetStateAction<string[] | null>>;
  setLDReviewers?: React.Dispatch<React.SetStateAction<string[] | null>>;
  questionIdParam?: string;
  propertyNameParam?: string;
  requestTitleRef?: React.MutableRefObject<null>;
  isLDReviewer?: boolean;
};

const DetailsPage = ({
  activeTabIndex,
  setActiveTabIndex,
  requestData,
  requestQuestionsData,
  handleLeavingPageWithUnsavedChanges = () => {},
  requestTitleRef,
  updatedReqData,
  setUpdatedReqData = () => {},
  errors = {},
  setOwners = () => {},
  questionIdParam = '',
  propertyNameParam = '',
  setBusinessReviewers = () => {},
  setLDReviewers = () => {},
  isLDReviewer = false,
}: Props) => {
  const dispatch = useDispatch();
  const [showComment, setShowComment] = useState(false);
  const propertiesComments = useSelector(selectPropertiesComments);
  const requestId = requestData.id!;
  const userId = useSelector(selectUserId);
  const userType = useSelector(selectUserType);
  const [questionId, setQuestionId] = useState(questionIdParam);
  const [propertyName, setPropertyName] = useState(propertyNameParam);

  useEffect(() => {
    dispatch(getPropertiesComments(requestId));
  }, [dispatch, requestId]);

  useEffect(() => {
    if (questionIdParam || propertyNameParam) {
      setQuestionId(questionIdParam);
      setPropertyName(propertyNameParam);
      setShowComment(true);
    } else {
      setQuestionId('');
      setPropertyName('');
      setShowComment(false);
    }
  }, [questionIdParam, propertyNameParam]);

  const showPendingCancellationBanner =
    requestData.status === REQUEST_STATUS.PENDING_CANCELLATION &&
    requestData.owners?.some((owner) => owner.id === userId);

  const {
    isOwnerOrAdmin,
    areBasicAndRequestDisabled,
    isAdditionalDisabled,
    isLDDisabled,
  } = useRequestContext();

  const tabs = useMemo(() => {
    const availableTabs = [
      {
        label: intl.get('REQUEST_PAGE.TABS.BASIC'),
        content: (
          <Fragment>
            {showPendingCancellationBanner && (
              <PendingCancellationBanner requestData={requestData} />
            )}
            {requestData.status === REQUEST_STATUS.APPROVED && (
              <ApprovedRequestBanner requestData={requestData} />
            )}
            {requestData.status === REQUEST_STATUS.CANCELED && (
              <CancelledRequestBanner requestData={requestData} />
            )}
            {requestData.status === REQUEST_STATUS.REJECTED && (
              <DeclinedRequestBanner requestData={requestData} />
            )}
            {requestData.status === REQUEST_STATUS.WAITLISTED && (
              <WaitlistedRequestBanner requestData={requestData} />
            )}
            <BasicDetails
              requestData={requestData}
              requestQuestionsData={requestQuestionsData}
              showComment={showComment}
              propertiesComments={propertiesComments}
              setUpdatedReqData={setUpdatedReqData}
              updatedReqData={updatedReqData}
              errors={errors}
              setOwners={setOwners}
              setBusinessReviewers={setBusinessReviewers}
              setLDReviewers={setLDReviewers}
              questionIdParam={questionId}
              propertyNameParam={propertyName}
              requestTitleRef={requestTitleRef}
              isLDReviewer={isLDReviewer}
            />
          </Fragment>
        ),
      },
      {
        label: intl.get('REQUEST_PAGE.TABS.REQUEST'),
        content: (
          <Fragment>
            {showPendingCancellationBanner && (
              <PendingCancellationBanner requestData={requestData} />
            )}
            {requestData.status === REQUEST_STATUS.APPROVED && (
              <ApprovedRequestBanner requestData={requestData} />
            )}
            {requestData.status === REQUEST_STATUS.CANCELED && (
              <CancelledRequestBanner requestData={requestData} />
            )}
            {requestData.status === REQUEST_STATUS.REJECTED && (
              <DeclinedRequestBanner requestData={requestData} />
            )}
            {requestData.status === REQUEST_STATUS.WAITLISTED && (
              <WaitlistedRequestBanner requestData={requestData} />
            )}
            <RequestDetails
              requestData={requestData}
              requestQuestionsData={requestQuestionsData}
              showComment={showComment}
              propertiesComments={propertiesComments}
              setUpdatedReqData={setUpdatedReqData}
              disableFields={areBasicAndRequestDisabled}
              questionIdParam={questionIdParam}
              propertyNameParam={propertyNameParam}
            />
          </Fragment>
        ),
      },
    ];
    if (requestData.status !== REQUEST_STATUS.DRAFT) {
      availableTabs.push({
        label: intl.get('REQUEST_PAGE.TABS.ADDITIONAL'),
        content: (
          <Fragment>
            {showPendingCancellationBanner && (
              <PendingCancellationBanner requestData={requestData} />
            )}
            {requestData.status === REQUEST_STATUS.APPROVED && (
              <ApprovedRequestBanner requestData={requestData} />
            )}
            {requestData.status === REQUEST_STATUS.CANCELED && (
              <CancelledRequestBanner requestData={requestData} />
            )}
            {requestData.status === REQUEST_STATUS.REJECTED && (
              <DeclinedRequestBanner requestData={requestData} />
            )}
            {requestData.status === REQUEST_STATUS.WAITLISTED && (
              <WaitlistedRequestBanner requestData={requestData} />
            )}
            <AdditonalDetails
              requestData={requestData}
              requestQuestionsData={requestQuestionsData}
              showComment={showComment}
              setUpdatedReqData={setUpdatedReqData}
              disableFields={isAdditionalDisabled}
              questionIdParam={questionIdParam}
            />
          </Fragment>
        ),
      });

      if (userType === USER_TYPES.L_D) {
        availableTabs.push({
          label: intl.get('REQUEST_PAGE.TABS.L_D'),
          content: (
            <Fragment>
              {showPendingCancellationBanner && (
                <PendingCancellationBanner requestData={requestData} />
              )}
              {requestData.status === REQUEST_STATUS.APPROVED && (
                <ApprovedRequestBanner requestData={requestData} />
              )}
              {requestData.status === REQUEST_STATUS.CANCELED && (
                <CancelledRequestBanner requestData={requestData} />
              )}
              {requestData.status === REQUEST_STATUS.REJECTED && (
                <DeclinedRequestBanner requestData={requestData} />
              )}
              {requestData.status === REQUEST_STATUS.WAITLISTED && (
                <WaitlistedRequestBanner requestData={requestData} />
              )}
              <LDOnlyPage
                requestData={requestData}
                requestQuestionsData={requestQuestionsData}
                setUpdatedReqData={setUpdatedReqData}
                showComment={showComment}
                disableFields={isLDDisabled}
                propertiesComments={propertiesComments}
                errors={errors}
                questionIdParam={questionIdParam}
                propertyNameParam={propertyNameParam}
              />
            </Fragment>
          ),
        });
      }
    }

    return availableTabs;
  }, [
    showPendingCancellationBanner,
    requestData,
    requestQuestionsData,
    showComment,
    propertiesComments,
    setUpdatedReqData,
    updatedReqData,
    areBasicAndRequestDisabled,
    errors,
    setOwners,
    setBusinessReviewers,
    setLDReviewers,
    questionId,
    propertyName,
    questionIdParam,
    propertyNameParam,
    isAdditionalDisabled,
    userType,
    isLDDisabled,
    requestTitleRef,
    isLDReviewer,
  ]);

  const toggleComment = () => {
    setShowComment((prevShowComments) => !prevShowComments);
    setQuestionId('');
    setPropertyName('');
  };

  return (
    <div className='w-full'>
      <Details
        className='min-h-full'
        bodyClassName='overflow-y-hidden'
        topBar={
          <TopBar
            toggleComment={toggleComment}
            showComment={showComment}
            requestData={requestData}
            canEditProperties={isOwnerOrAdmin}
            handleLeavingPageWithUnsavedChanges={
              handleLeavingPageWithUnsavedChanges
            }
          />
        }
        content={
          <div data-cy='details-tabs'>
            <Tabs
              tabListProps={{ className: 'top-20 bg-neutral-white z-5' }}
              tabPanelsProps={{
                className: 'max-h-request-details overflow-y-auto',
              }}
              data={tabs}
              index={activeTabIndex}
              onChange={setActiveTabIndex}
            />
          </div>
        }
      />
    </div>
  );
};

export default DetailsPage;
