import { Modal } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import { Fragment } from 'react';
import { TaskBundle } from 'utils/customTypes';
import {
  PATHS,
  SETTINGS_ATTRIBUTES,
  SETTINGS_SECTIONS,
  SETTINGS_TABS,
} from 'utils/constants';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { deleteTaskBundle } from 'state/TasksBundle/tasksBundleSlice';

const DeleteTaskBundleModal = ({
  taskBundle,
  isOpen,
  setIsOpen,
}: {
  taskBundle: TaskBundle;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const taskBundleId = get(taskBundle, 'id', '');

  const handleDeleteTaskBundle = async () => {
    await dispatch(deleteTaskBundle(taskBundleId));
    history.push(
      `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.PROJECTS}&attribute=${SETTINGS_ATTRIBUTES.TASK_BUNDLES}`
    );
    dispatch(
      showSnackbarNotification({
        notificationVariant: 'success',
        notificationTitle: intl.get(
          'SETTINGS_PAGE.TASK_BUNDLE_PAGE.DELETE_BUNDLE.SUCCESS_MESSAGE'
        ),
        autoHide: true,
      })
    );
  };

  return (
    <Modal
      data-cy='delete-task-bundle_modal'
      title={intl.get('SETTINGS_PAGE.TASK_BUNDLE_PAGE.DELETE_BUNDLE.TITLE')}
      closeModal={() => setIsOpen(false)}
      isOpen={isOpen}
      aria-label={intl.get(
        'SETTINGS_PAGE.TASK_BUNDLE_PAGE.DELETE_BUNDLE.TITLE'
      )}
      size='medium'
      titleIcon={{ name: 'trash', className: 'text-error-dark' }}
      actionButtons={[
        {
          children: intl.get(
            'SETTINGS_PAGE.TASK_BUNDLE_PAGE.DELETE_BUNDLE.CONFIRM'
          ),

          onClick: handleDeleteTaskBundle,
          'data-cy': 'delete-task-bundle_button',
        },
        {
          children: intl.get(
            'SETTINGS_PAGE.TASK_BUNDLE_PAGE.DELETE_BUNDLE.CANCEL'
          ),
          variant: 'tertiary',
          onClick: () => setIsOpen(false),
          'data-cy': 'cancel-delete-task-bundle_button',
        },
      ]}
    >
      <Fragment>
        <Typography variant='body' className='mb-6 text-neutral-black'>
          {intl.get(
            'SETTINGS_PAGE.TASK_BUNDLE_PAGE.DELETE_BUNDLE.BODY_FIRST_LINE'
          )}
        </Typography>
        <Typography variant='body' className='text-neutral-black'>
          {intl.get(
            'SETTINGS_PAGE.TASK_BUNDLE_PAGE.DELETE_BUNDLE.BODY_SECOND_LINE'
          )}
        </Typography>
      </Fragment>
    </Modal>
  );
};

export default DeleteTaskBundleModal;
