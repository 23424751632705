import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Button, Icon, Typography } from '@getsynapse/design-system';
import ArchiveProjectModal from 'Organisms/ArchiveProjectModal/ArchiveProjectModal';
import UnarchiveIcon from 'assets/icons/unarchive.svg';

const UnarchiveProjectButton: React.FC<{
  afterUnarchiveProject: () => void;
}> = ({ afterUnarchiveProject }) => {
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const toggleDisplayModal = () => setDisplayModal((prevState) => !prevState);
  return (
    <>
      <ArchiveProjectModal
        afterArchiveProject={afterUnarchiveProject}
        onCloseModal={toggleDisplayModal}
        shouldDisplayModal={displayModal}
      />
      <Button
        className='bg-transparent'
        size='small'
        variant='tertiary'
        onClick={toggleDisplayModal}
        data-cy='unarchive-project-trigger-button'
      >
        <Icon src={UnarchiveIcon} className='text-primary text-xl mr-2' />
        <Typography variant='label' className='text-primary cursor-pointer'>
          {intl.get('UNARCHIVE')}
        </Typography>
      </Button>
    </>
  );
};

export default UnarchiveProjectButton;
