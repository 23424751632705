import Skelton from 'Atoms/Skelton/Skelton';

const SidePanelSkeletonLoader = () => (
  <div className='h-full w-full'>
    <div className='border-b border-primary-lighter h-12 pt-3 px-6'>
      <div className='flex h-6'>
        <Skelton className='h-4 w-28 mt-1' />
        <div className='w-24 flex justify-end ml-auto items-center'>
          <Skelton className='h-4 w-4' />
          <div className='border-l h-full w-0 border-primary-lighter mx-3'></div>
          <Skelton className='h-4 w-4' />
        </div>
      </div>
    </div>
    <div className='px-6 flex flex-col'>
      <Skelton className='h-6 w-96 mt-6' />
      <Skelton className='h-4 w-28 mt-12' />
      <Skelton className='h-6 w-28 mt-6' />
      <Skelton className='h-4 w-28 mt-8' />
      <Skelton className='h-4 w-full mt-8' />
      <Skelton className='h-4 w-full mt-4' />
      <Skelton className='h-4 w-full mt-4' />
      <Skelton className='h-4 w-3/4 mt-4' />
      <Skelton className='h-4 w-28 mt-16' />
      <Skelton className='h-4 w-full mt-8' />
      <Skelton className='h-4 w-28 mt-12' />
      <Skelton className='h-4 w-full mt-8' />
      <Skelton className='h-4 w-28 mt-12' />
      <Skelton className='h-4 w-full mt-4' />
      <Skelton className='h-4 w-full mt-4' />
    </div>
  </div>
);

export default SidePanelSkeletonLoader;
