import React from 'react';
import intl from 'react-intl-universal';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  tailwindOverride,
  Icon,
  IconButton,
  Toggle,
  Typography,
} from '@getsynapse/design-system';

const FieldTemplateCard: React.FC<{
  fieldTemplateId: string;
  required?: boolean;
  canUpdate?: boolean;
  isSortEnabled?: boolean;
  onRemoveFieldTemplate: (fieldTemplateId: string) => void;
  onUpdateRequiredStatus: (checked: boolean) => void;
}> = ({
  children,
  fieldTemplateId,
  required = true,
  canUpdate = true,
  isSortEnabled = false,
  onUpdateRequiredStatus,
  onRemoveFieldTemplate,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: fieldTemplateId });
  const originalTransform = CSS.Transform.toString(transform);
  const modifiedTransform = originalTransform
    ? originalTransform.substring(0, originalTransform.indexOf('scaleY')) +
      ' scaleY(1)'
    : undefined;
  const style = {
    transform: modifiedTransform,
    transition,
  };
  return (
    <div
      ref={isSortEnabled ? setNodeRef : null}
      style={isSortEnabled ? style : undefined}
      className={tailwindOverride(
        'rounded border border-neutral-lighter-two',
        'pr-6 pb-4',
        'relative self-start',
        {
          'z-200000': isDragging,
          'pl-2': isSortEnabled,
          'pl-6': !isSortEnabled,
        }
      )}
      data-cy={`field-template-${fieldTemplateId}`}
    >
      {canUpdate && (
        <IconButton
          name='remove-circle'
          className={tailwindOverride(
            'text-xl text-error-dark',
            'absolute top-0 right-0 mt-2.5 mr-6'
          )}
          onClick={() => onRemoveFieldTemplate(fieldTemplateId)}
          aria-label='remove field template'
          data-cy={`field-template-${fieldTemplateId}__remove-button`}
        />
      )}
      <div className='flex mt-7 flex-1'>
        {isSortEnabled && (
          <Icon
            name='reorder-four'
            className='text-2xl mr-5 mt-6 cursor-move'
            {...listeners}
            {...attributes}
          />
        )}
        <div className='w-full'>
          {children}
          {canUpdate && (
            <div className='mt-2 flex flex-1 items-center'>
              <Toggle
                id={fieldTemplateId}
                isSmall
                checked={required}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onUpdateRequiredStatus(event.target.checked)
                }
                inputProps={{
                  'aria-label': intl.get(
                    'SETTINGS_PAGE.TEMPLATE_PAGE.BUILD_SECTION.REQUIRED'
                  ),
                  'data-cy': `field-template-${fieldTemplateId}__required-toggle`,
                }}
              />
              <Typography variant='body2' className='text-primary ml-px'>
                {intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.BUILD_SECTION.REQUIRED')}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FieldTemplateCard;
