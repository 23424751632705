import { PROJECT_STATUS, PROJECT_USER_ACTIONS } from 'utils/constants';
import { useUserPermissionsContext } from 'Pages/ProjectPage/context/UserPermissionsContext';

const useGetProjectReadOnly = (status?: string, isArchived?: boolean) => {
  const { canUser } = useUserPermissionsContext();

  const isReadOnly =
    [PROJECT_STATUS.CLOSED].includes(status) ||
    !canUser(PROJECT_USER_ACTIONS.UPDATE_PROJECT) ||
    isArchived ||
    false;

  return isReadOnly;
};
export default useGetProjectReadOnly;
