import { PROGRAM_NUMBER_FORMAT, PROJECT_NUMBER_FORMAT } from './constants';
import { IdentifierFormat } from './customTypes';

export const formatProjectNumber = (projectNumber?: number | null) =>
  formatIdentifier(PROJECT_NUMBER_FORMAT, projectNumber);

export const formatProgramNumber = (programNumber?: number | null) =>
  formatIdentifier(PROGRAM_NUMBER_FORMAT, programNumber);

const formatIdentifier = (
  format: IdentifierFormat,
  identifier?: number | null
) => {
  if (!identifier) {
    return '';
  }
  const numberLength = identifier.toString().length;
  if (numberLength < 7) {
    return format.FULL.slice(0, format.FULL.length - numberLength) + identifier;
  } else {
    return format.BASE + identifier;
  }
};
