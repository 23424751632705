import { api } from './api';
import axios from 'axios';

export interface TaskType {
  id: string;
  name: string;
  code: string;
}

export interface TaskTypesResponse {
  taskTypes: TaskType[];
}

export const fetchTaskTypeList = async () => {
  const url = `/v2/task-types`;
  const response = await api.get<TaskTypesResponse>(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};
