import React from 'react';
import { PROJECT_CONTENT_TYPE } from 'utils/constants';
import { getThumbnailBackgroundImage } from '../ContentTable/utils';

type Keys = keyof typeof PROJECT_CONTENT_TYPE;
type contentType = typeof PROJECT_CONTENT_TYPE[Keys];
const Thumbnail: React.FC<{
  cloudFrontUrl?: string;
  backupUrl?: string;
  contentType: contentType;
  mimeType: string;
  dataCy?: string;
}> = ({
  cloudFrontUrl = '',
  backupUrl = '',
  contentType,
  mimeType,
  dataCy,
}) => {
  let backgroundImageUrl = getThumbnailBackgroundImage(
    contentType,
    mimeType,
    cloudFrontUrl,
    backupUrl
  );
  return (
    <div className='w-full py-1 px-2' data-cy={dataCy}>
      <div
        className='w-full h-10'
        style={{
          backgroundImage: `url("${backgroundImageUrl}")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    </div>
  );
};

export default Thumbnail;
