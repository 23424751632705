import React from 'react';
import get from 'lodash/get';
import { Typography, tailwindOverride } from '@getsynapse/design-system';
import { Week } from 'Organisms/CapacityAllocationTable/helpers';
import { CAPACITY_TABLE_NUMBER_OF_WEEKS } from 'utils/constants';

const WeeksCarousel: React.FC<{
  weeks: Week[];
  currentIndex: number;
}> = ({ currentIndex, weeks }) => {
  const firstWeekVisible = currentIndex;
  const lastWeekVisible = currentIndex + CAPACITY_TABLE_NUMBER_OF_WEEKS - 1;
  return (
    <div
      className='flex h-full'
      style={{
        transition: 'transform 0.5s ease-in-out',
        transform: `translateX(-${
          currentIndex * (100 / CAPACITY_TABLE_NUMBER_OF_WEEKS)
        }%)`,
      }}
      aria-label='weeks carousel'
    >
      {weeks.map((week: Week, index: number) => {
        const isCurrentWeek = get(week, 'isCurrentWeek', false);
        return (
          <div
            key={index}
            className={tailwindOverride(
              'w-1/6 h-full relative px-1',
              'flex items-center justify-center',
              'flex-shrink-0 flex-grow',
              {
                'text-primary bg-secondary-lightest border-l border-r border-secondary-lighter':
                  isCurrentWeek,
              }
            )}
            role='group'
            aria-label={`week ${index + 1} of ${weeks.length}`}
            aria-hidden={index < firstWeekVisible || index > lastWeekVisible}
          >
            {isCurrentWeek && (
              <div
                className={tailwindOverride(
                  'w-full h-px',
                  'bg-secondary-lighter',
                  'absolute top-0 left-0'
                )}
              />
            )}
            {week !== null && (
              <Typography
                variant='caption'
                className={tailwindOverride('font-semibold', {
                  'text-secondary-darker': isCurrentWeek,
                })}
              >
                {week.label}
              </Typography>
            )}
            {isCurrentWeek && (
              <div
                className={tailwindOverride(
                  'w-full h-0.5',
                  'bg-secondary-dark',
                  'absolute bottom-0 left-0'
                )}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export const Loader = () => {
  return (
    <div
      className={tailwindOverride(
        'w-full h-full flex',
        'absolute top-0 left-0',
        'pl-6 pr-16',
        'bg-primary-lightest',
        'z-10'
      )}
      aria-label='weeks carousel loader'
    >
      {Array.from(Array(CAPACITY_TABLE_NUMBER_OF_WEEKS)).map(
        (_, index: number) => (
          <div
            key={`loader-colum-${index}`}
            className={tailwindOverride(
              'w-1/6 h-full',
              'flex items-center justify-center',
              'flex-shrink-0 flex-grow',
              'animate-pulse'
            )}
          >
            <div className='w-3/5 bg-primary-lighter h-4 rounded-sm' />
          </div>
        )
      )}
    </div>
  );
};

export default WeeksCarousel;
