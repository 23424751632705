import { isEmpty, get, isEqual } from 'lodash';
import {
  Request,
  UpdateReqData,
  RequestQuestion,
  User,
  CurrentUser,
} from 'utils/customTypes';
import { USER_TYPES, USER_ROLES, REQUEST_STATUS } from 'utils/constants';

export const calculateRequestPermissions = (
  requestData: Request,
  isForm: boolean,
  user: CurrentUser,
  updatedReqData?: UpdateReqData,
  requestQuestionsData?: RequestQuestion[]
) => {
  let isOwner = false;
  if (!isEmpty(requestData.owners)) {
    isOwner = requestData.owners!.some((owner) => owner.id === user.id);
  }

  const isRequester = requestData.requester_id === user.id;

  const originalBusinessReviewerIds = requestData.reviewers
    ?.filter((reviewer: User) => reviewer.type === USER_TYPES.BUSINESS)
    .map((reviewer: User) => reviewer.id);
  const isBusinessReviewer = originalBusinessReviewerIds?.includes(user.id);

  const originalLDReviewerIds = requestData.reviewers
    ?.filter((reviewer: User) => reviewer.type === USER_TYPES.L_D)
    .map((reviewer: User) => reviewer.id);
  const isLDReviewer = originalLDReviewerIds?.includes(user.id);

  const isAdmin = user.role === USER_ROLES.ADMIN;

  const isDraftOrSubmitted =
    requestData.status === REQUEST_STATUS.DRAFT ||
    requestData.status === REQUEST_STATUS.SUBMITTED;

  const isApprovedOrDeclined =
    requestData.status === REQUEST_STATUS.APPROVED ||
    requestData.status === REQUEST_STATUS.REJECTED;

  const canEditWaitlist =
    requestData.status === REQUEST_STATUS.WAITLISTED &&
    (user.type === USER_TYPES.L_D || user.type === USER_TYPES.BUSINESS) &&
    (isOwner || isRequester || isLDReviewer || isAdmin);

  const canEditApprovedOrDeclined =
    isApprovedOrDeclined && (isOwner || isAdmin);

  let areBasicAndRequestDisabled = true;
  if (
    (!isForm &&
      (isOwner ||
        isRequester ||
        isBusinessReviewer ||
        isLDReviewer ||
        isAdmin) &&
      isDraftOrSubmitted) ||
    canEditWaitlist ||
    canEditApprovedOrDeclined ||
    isForm
  ) {
    areBasicAndRequestDisabled = false;
  }

  const isCancelStage =
    requestData.status === REQUEST_STATUS.CANCELED ||
    requestData.status === REQUEST_STATUS.PENDING_CANCELLATION;

  const isDeclined = requestData.status === REQUEST_STATUS.REJECTED;

  let isAdditionalDisabled = true;
  if (
    (!isForm &&
      (isOwner ||
        isRequester ||
        isBusinessReviewer ||
        isLDReviewer ||
        isAdmin) &&
      !isCancelStage &&
      !isDeclined) ||
    isForm
  ) {
    isAdditionalDisabled = false;
  }

  let isLDDisabled = true;
  if (
    (!isForm &&
      (isOwner || isLDReviewer || isAdmin) &&
      !isCancelStage &&
      !isDeclined) ||
    isForm
  ) {
    isLDDisabled = false;
  }

  let isBusinessUnitDisabled = true;
  if (
    (((user.type === USER_TYPES.BUSINESS && !get(user, 'businessTeam')) ||
      user.type === USER_TYPES.L_D) &&
      isDraftOrSubmitted &&
      !areBasicAndRequestDisabled &&
      !isLDReviewer) ||
    (canEditWaitlist && !isLDReviewer)
  ) {
    isBusinessUnitDisabled = false;
  }

  const questionsWithoutLd = requestQuestionsData?.filter(
    (que) => que.section !== 'ldDetails'
  );
  const filteredRequestQuestions =
    user.type === USER_TYPES.L_D && requestData.status !== REQUEST_STATUS.DRAFT
      ? requestQuestionsData
      : questionsWithoutLd;

  let requestHasUpdate = false;
  if (!isEmpty(updatedReqData?.requestAttributes)) {
    const updatedAttributes = updatedReqData?.requestAttributes;
    for (const property in updatedAttributes) {
      if (property === 'data') {
        if (
          (!isEmpty(updatedAttributes.data?.additionalInformation) ||
            !isEmpty(requestData.data?.additionalInformation)) &&
          updatedAttributes.data?.additionalInformation !==
            requestData.data?.additionalInformation
        ) {
          requestHasUpdate = true;
          break;
        }
      } else if (
        (!isEmpty(get(updatedAttributes, property)) ||
          !isEmpty(get(requestData, property))) &&
        !isEqual(get(updatedAttributes, property), get(requestData, property))
      ) {
        requestHasUpdate = true;
        break;
      }
    }
  } else if (filteredRequestQuestions?.length) {
    for (let i = 0; i < filteredRequestQuestions!.length; i++) {
      const updatedQuestion = updatedReqData?.requestQuestions
        ? updatedReqData?.requestQuestions[filteredRequestQuestions![i].id]
        : null;
      const originalQuestionData = get(filteredRequestQuestions, `[${i}].data`);
      if (
        !isEmpty(updatedQuestion) &&
        (updatedQuestion?.data.value !== originalQuestionData.value ||
          updatedQuestion?.data.label !== originalQuestionData.label)
      ) {
        requestHasUpdate = true;
      }
    }
  }

  let shouldUseUserBusinessTeamAsDefault = false;
  if (
    user.type === USER_TYPES.BUSINESS &&
    get(user, 'businessTeam') &&
    isDraftOrSubmitted
  ) {
    if (isForm) {
      shouldUseUserBusinessTeamAsDefault = true;
    } else {
      if (!get(requestData, 'businessTeams')) {
        shouldUseUserBusinessTeamAsDefault = true;
      }
    }
  }
  return {
    isOwnerOrAdmin: isOwner || isAdmin,
    areBasicAndRequestDisabled,
    isAdditionalDisabled,
    isLDDisabled,
    isBusinessUnitDisabled,
    requestHasUpdate,
    shouldUseUserBusinessTeamAsDefault,
  };
};
