import intl from 'react-intl-universal';
import { Input, Checkbox, TextArea } from '@getsynapse/design-system';
import { debounce, get } from 'lodash';
import { QuestionsProps } from '../Question';
import { useEffect } from 'react';

export const TextField = ({
  questionData,
  changeQuestionData,
}: QuestionsProps) => {
  const isMultiline = get(questionData, 'data.multiline');

  const updatePlaceholder = debounce((value) => {
    changeQuestionData('data.placeholder', value, questionData.id);
  }, 500);

  const updateMultiline = (checked: boolean) => {
    changeQuestionData('data.multiline', checked, questionData.id);
  };

  useEffect(() => {
    if (isMultiline === undefined) {
      changeQuestionData('data.multiline', false, questionData.id);
    }
  }, [isMultiline, questionData.id, changeQuestionData]);

  return (
    <div>
      {isMultiline && questionData.type === 'customTextArea' ? (
        <TextArea
          textAreaProps={{
            defaultValue: get(questionData, 'data.placeholder'),
            placeholder: intl.get(
              'SETTINGS_PAGE.FORMS.DESIGN.CUSTOM_QUESTIONS.PLACEHOLDER'
            ),
            className: 'text-neutral-light focus:text-neutral-black',
          }}
          onChange={(event) => updatePlaceholder(event.target.value)}
        />
      ) : (
        <Input
          defaultValue={get(questionData, 'data.placeholder')}
          placeholder={intl.get(
            'SETTINGS_PAGE.FORMS.DESIGN.CUSTOM_QUESTIONS.PLACEHOLDER'
          )}
          className='text-neutral-light focus:text-neutral-black'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            updatePlaceholder(event.target.value)
          }
        />
      )}
      {questionData.type === 'customTextArea' && (
        <Checkbox
          className='mt-2'
          checked={get(questionData, 'data.multiline')}
          label={intl.get(
            'SETTINGS_PAGE.FORMS.DESIGN.CUSTOM_QUESTIONS.TEXT.MULTILINE'
          )}
          onChange={(event) => updateMultiline(event.target.checked)}
        />
      )}
    </div>
  );
};

export default TextField;
