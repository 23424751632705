import { useCallback, useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import {
  calculatePartialHoursTotalSum,
  generatePartialHoursForDateRange,
  generatePartialHoursItemsArray,
} from '../../utils/partialHours';
import { NewTimeOffEntry, PartialHoursItem } from 'types/store/manageTimeOff';
import { createTimeOffEntry } from 'state/ManageTimeOff/TimeOffEntries/all/allTimeOffEntriesSlice';
import { fetchUpcomingTimeOffEntries } from 'state/ManageTimeOff/TimeOffEntries/upcoming/upcomingTimeOffEntriesSlice';
import { fetchPastTimeOffEntries } from 'state/ManageTimeOff/TimeOffEntries/past/pastTimeOffEntriesSlice';

interface UseAddTimeOffResponse {
  timeOffEntry: NewTimeOffEntry;
  updateTimeOffEntry: (
    key: keyof Omit<NewTimeOffEntry, 'id' | 'days'>,
    value: string
  ) => void;
  partialHoursItems: PartialHoursItem[];
  totalHours: number;
  updateTimeOffEntryDays: (day: string, hours: number) => void;
  updateValidityForNumericInput: (index: number, isValid: boolean) => void;
  updateDateRangeValidity: (isValid: boolean) => void;
}

const useAddTimeOff: (
  userId: string | undefined,
  shouldSaveChanges: boolean,
  dailyCapacity: number,
  onSaveChanges: (callback: () => void) => void,
  setCanSaveChanges: (canSaveChanges: boolean) => void,
  showNotification: (params: any) => void
) => UseAddTimeOffResponse = (
  userId,
  shouldSaveChanges,
  dailyCapacity,
  onSaveChanges,
  setCanSaveChanges,
  showNotification
) => {
  const dispatch = useDispatch();
  const [timeOffEntry, setTimeOffEntry] = useState<NewTimeOffEntry>({
    timeOffTypeId: '',
    startDate: '',
    endDate: '',
    days: {},
  });
  const [validityForAllNumericInputs, setValidityForAllNumericInputs] =
    useState<boolean[]>([]);
  const [isDateRangeValid, setIsDateRangeValid] = useState<boolean>(true);

  const updateValidityForNumericInput = useCallback(
    (index: number, isValid: boolean) => {
      setValidityForAllNumericInputs((prev) => {
        if (index >= prev.length) {
          return [...prev, isValid];
        }

        const newValidity = [...prev];
        newValidity[index] = isValid;
        return newValidity;
      });
    },
    []
  );

  const updateTimeOffEntry = useCallback(
    (key: keyof Omit<NewTimeOffEntry, 'id' | 'days'>, value: string) => {
      setTimeOffEntry((prev) => ({
        ...prev,
        [key]: value,
      }));
    },
    []
  );

  const addTimeOffEntry = useCallback(async () => {
    try {
      await dispatch(
        createTimeOffEntry({
          userId: userId as string,
          ...timeOffEntry,
        })
      );
      showNotification({
        notificationMessage: intl.get('MANAGE_TIME_OFF.ADD_TIME_OFF_SUCCES'),
      });
      dispatch(
        fetchUpcomingTimeOffEntries({
          userId: userId as string,
          includeTotalCount: true,
        })
      );
      dispatch(
        fetchPastTimeOffEntries({
          userId: userId as string,
          includeTotalCount: true,
        })
      );
    } catch (error) {
      showNotification({
        notificationVariant: 'error',
        notificationTitle: intl.get('MANAGE_TIME_OFF.SAVE_CHANGES_ERROR_TITLE'),
        notificationMessage: intl.get(
          'MANAGE_TIME_OFF.SAVE_CHANGES_ERROR_MESSAGE'
        ),
      });
    }
  }, [userId, timeOffEntry, dispatch, showNotification]);

  const updateTimeOffEntryDays = useCallback((day: string, hours: number) => {
    setTimeOffEntry((prev) => ({
      ...prev,
      days: {
        ...prev.days,
        [day]: hours,
      },
    }));
  }, []);

  const setTimeOffEntryDays = useCallback(
    (startDate, endDate) => {
      const rangeStart = new Date(startDate);
      const rangeEnd = new Date(endDate);
      const partialHours = generatePartialHoursForDateRange(
        rangeStart,
        rangeEnd,
        dailyCapacity
      );
      setTimeOffEntry((prev) => ({
        ...prev,
        days: partialHours,
      }));
    },
    [dailyCapacity]
  );

  const areAllNumericInputsValid = useMemo(
    () => validityForAllNumericInputs.every((valid) => valid),
    [validityForAllNumericInputs]
  );

  const partialHoursItems = useMemo(
    () => generatePartialHoursItemsArray(timeOffEntry.days),
    [timeOffEntry.days]
  );
  const totalHours = useMemo(
    () => calculatePartialHoursTotalSum(timeOffEntry.days),
    [timeOffEntry.days]
  );

  useEffect(() => {
    if (timeOffEntry.startDate && timeOffEntry.endDate) {
      setTimeOffEntryDays(timeOffEntry.startDate, timeOffEntry.endDate);
    }
  }, [timeOffEntry.startDate, timeOffEntry.endDate, setTimeOffEntryDays]);

  useEffect(() => {
    if (
      timeOffEntry.startDate !== '' &&
      timeOffEntry.endDate !== '' &&
      timeOffEntry.timeOffTypeId !== '' &&
      areAllNumericInputsValid &&
      isDateRangeValid
    ) {
      setCanSaveChanges(true);
    } else {
      setCanSaveChanges(false);
    }
  }, [
    timeOffEntry.startDate,
    timeOffEntry.endDate,
    timeOffEntry.timeOffTypeId,
    setCanSaveChanges,
    areAllNumericInputsValid,
    isDateRangeValid,
  ]);

  useEffect(() => {
    if (shouldSaveChanges && userId) {
      onSaveChanges(addTimeOffEntry);
    }
  }, [shouldSaveChanges, userId, addTimeOffEntry, onSaveChanges]);

  return useMemo(
    () => ({
      timeOffEntry,
      updateTimeOffEntry,
      partialHoursItems,
      totalHours,
      updateTimeOffEntryDays,
      updateValidityForNumericInput,
      updateDateRangeValidity: setIsDateRangeValid,
    }),
    [
      timeOffEntry,
      updateTimeOffEntry,
      partialHoursItems,
      totalHours,
      updateTimeOffEntryDays,
      updateValidityForNumericInput,
    ]
  );
};

export default useAddTimeOff;
