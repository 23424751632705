import { PROJECT_PARTICIPANT_ROLE, PROJECT_OWNER } from 'utils/constants';
import intl from 'react-intl-universal';

export const getProjectRole = (projectRole: string) => {
  if (projectRole === PROJECT_OWNER) {
    return intl.get(
      `PEOPLE.RESOURCE_ALLOCATION.PARTICIPANT_PROJECT_ROLE.PROJECT_OWNER`
    );
  }
  const targetRole = Object.entries(PROJECT_PARTICIPANT_ROLE).find(
    (entry) => entry[1] === projectRole
  );
  if (targetRole) {
    return intl.get(
      `PEOPLE.RESOURCE_ALLOCATION.PARTICIPANT_PROJECT_ROLE.${targetRole[0]}`
    );
  }
  return '';
};
