import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import intl from 'react-intl-universal';
import moment from 'moment';
import classnames from 'classnames';
import { Table, Typography } from '@getsynapse/design-system';
import { DATE, PATHS, USER_TYPES } from 'utils/constants';
import {
  getBusinessTeams,
  selectOrderedBusinessTeams,
  setBusinessTeamsOrder,
  setSearchParam,
} from 'state/BusinessTeams/businessTeamsSlice';
import { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import BusinessTeamsTableHead from './BusinessTeamsTableHead';
import TableHeader from '../TableHeader/TableHeader';

const BusinessTeams: React.FC<{ isVisible: boolean }> = ({
  isVisible = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const businessTeams = useSelector(selectOrderedBusinessTeams);

  useEffect(() => {
    dispatch(setSearchParam({ searchParam: '' }));
    dispatch(
      setBusinessTeamsOrder({
        order: 'asc',
        orderBy: 'title',
      })
    );
    dispatch(getBusinessTeams());
  }, [dispatch]);

  useEffect(() => {
    if (!isVisible) {
      dispatch(setSearchParam({ searchParam: '' }));
      dispatch(
        setBusinessTeamsOrder({
          order: 'asc',
          orderBy: 'title',
        })
      );
    }
  }, [isVisible, dispatch]);

  const handleSort = (orderByParam: string, order: 'desc' | 'asc') => {
    dispatch(setBusinessTeamsOrder({ order, orderBy: orderByParam }));
  };

  const handleSearch = (searchParam: string) =>
    dispatch(setSearchParam({ searchParam }));

  return (
    <>
      <TableHeader
        onSearch={handleSearch}
        shouldClearInput={!isVisible}
        tableIdentifier='business-teams-table'
      />
      <Table
        data-testid='business-teams-table'
        className='max-h-table w-full relative mb-5'
        canSelectRows={false}
        bodyProps={{
          className: classnames('max-h-table-body overflow-y-auto', {
            block: businessTeams.length > 0,
          }),
        }}
        data={{
          headData: {
            className: 'w-full block h-auto min-h-10',
            rowProps: {
              className: 'table w-full table-fixed h-auto min-h-10',
            },
            handleSort,
            headCells: BusinessTeamsTableHead(),
          },
          rows:
            businessTeams.length > 0
              ? businessTeams.map((team) => {
                  return {
                    id: team.id,
                    'data-testid': `business-team_row-${team.id}`,
                    className: 'cursor-pointer table w-full table-fixed',
                    onClick: () =>
                      history.push(
                        `${PATHS.EDIT_TEAM}/${USER_TYPES.BUSINESS}/${team.id}`
                      ),
                    cells: [
                      {
                        content: (
                          <Link
                            to={`${PATHS.EDIT_TEAM}/${USER_TYPES.BUSINESS}/${team.id}`}
                          >
                            {get(team, 'title', '-')}
                          </Link>
                        ),
                        className: 'w-2/5 truncate',
                      },
                      {
                        content: (
                          <Typography
                            title={get(team, 'description') || '-'}
                            variant='body2'
                            className='leading-4 truncate'
                          >
                            {get(team, 'description') || '-'}
                          </Typography>
                        ),
                        className: 'w-2/5',
                      },
                      {
                        content: team.createdAt
                          ? moment(team.createdAt).format(
                              DATE.MONTH_DATE_YEAR_FORMAT
                            )
                          : '-',
                        className: 'w-1/5',
                      },
                    ],
                  };
                })
              : [
                  {
                    'data-testid': 'teams_table-empty',
                    id: '0',
                    cells: [
                      {
                        colSpan: '3',
                        content: (
                          <div className='flex justify-center'>
                            <Typography
                              variant='body2'
                              className='text-neutral'
                            >
                              {intl.get(
                                'ORG_SETTINGS_PAGE.LEARNING_TEAMS.TABLE.NO_TEAM_ADDED'
                              )}
                            </Typography>
                          </div>
                        ),
                      },
                    ],
                  },
                ],
        }}
      />
    </>
  );
};

export default BusinessTeams;
