const EvaluationPlanSkeletonLoader = () => {
  const skeletonClassnames = 'bg-neutral-lighter-two animate-pulse';
  const divsToRepeat = (
    <div className='w-full mr-5'>
      <div className={`h-4 w-1/2 mt-7 ${skeletonClassnames}`} />
      <div className={`h-10 w-full mt-3 mb-6 ${skeletonClassnames}`} />
    </div>
  );

  return (
    <>
      <div className='w-1/2'>{divsToRepeat}</div>
      <div className='border-t border-neutral-lighter-two animate-pulse mb-8' />
      <div className={`h-4 w-1/12 mt-6 ${skeletonClassnames}`} />
      <div className='flex w-full mb-7'>
        {Array(2)
          .fill('')
          .map(() => divsToRepeat)}
      </div>
      <div className='w-1/2'>{divsToRepeat}</div>
    </>
  );
};

export default EvaluationPlanSkeletonLoader;
