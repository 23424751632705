import {
  InReviewRequestAnalytic,
  InReviewRequestBarChartData,
} from 'utils/customTypes';
import intl from 'react-intl-universal';

export const formatBarChartDataForInReviewRequests = (
  data: InReviewRequestAnalytic[]
) => {
  const initialStateOfBarChartData = {
    LESS_THAN_THREE_DAYS: 0,
    FOUR_TO_EIGHT_DAYS: 0,
    NINE_TO_FOURTEEN_DAYS: 0,
    MORE_THAN_FIFTEEN_DAYS: 0,
  };

  data.forEach((request) => {
    const { daysInReview } = request;
    if (daysInReview <= 3) {
      initialStateOfBarChartData.LESS_THAN_THREE_DAYS += 1;
    } else if (daysInReview > 3 && daysInReview <= 8) {
      initialStateOfBarChartData.FOUR_TO_EIGHT_DAYS += 1;
    } else if (daysInReview > 8 && daysInReview <= 14) {
      initialStateOfBarChartData.NINE_TO_FOURTEEN_DAYS += 1;
    } else if (daysInReview > 14) {
      initialStateOfBarChartData.MORE_THAN_FIFTEEN_DAYS += 1;
    }
  });
  const formatedChartData = [] as InReviewRequestBarChartData[];
  Object.entries(initialStateOfBarChartData).forEach(([key, value]) => {
    formatedChartData.push({
      name: intl.get(
        `INSIGHTS_PAGE_TABS.IN_REVIEW_REQUESTS.${key.toUpperCase()}`
      ),
      count: value,
    });
  });

  return formatedChartData;
};
