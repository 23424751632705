import React from 'react';
import { useSelector } from 'react-redux';
import { CapacityPerWeek } from 'types/store/weeklyTeamCapacity';
import { RootState } from 'state/store';
import { selectWeeklyCapacityByMemberId } from 'state/WeeklyTeamCapacity/TeamMembersCapacity/teamMembersCapacitySlice';
import NoWeeklyCapacity from '../../NoWeeklyCapacity/NoWeeklyCapacity';
import WeeksGrid from '../../WeeksGrid/WeeksGrid';
import TeamMembersWeeklyCapacity from '../TeamMembersWeeklyCapacity/TeamMembersWeeklyCapacity';

interface TeamMembersWeeklyCapacitiesProps {
  teamId: string;
  memberId: string;
}
const TeamMembersWeeklyCapacities: React.FC<
  TeamMembersWeeklyCapacitiesProps
> = ({ teamId, memberId }) => {
  const weeklyMemberCapacity = useSelector((state: RootState) =>
    selectWeeklyCapacityByMemberId(state, memberId)
  );

  if (Object.keys(weeklyMemberCapacity).length === 0) {
    return (
      <div className='w-4/6 h-full flex'>
        <NoWeeklyCapacity id={`team-${teamId}__member-${memberId}`} isLoading />
      </div>
    );
  }

  return (
    <div className='w-4/6 h-full flex'>
      <WeeksGrid<CapacityPerWeek>
        id={`team-${teamId}__member-${memberId}`}
        component={TeamMembersWeeklyCapacity}
        items={weeklyMemberCapacity}
      />
    </div>
  );
};

export default TeamMembersWeeklyCapacities;
