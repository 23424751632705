import React from 'react';
import { tailwindOverride } from '@getsynapse/design-system';
import { CapacityPerWeek } from 'types/store/weeklyTeamCapacity';
import AllocationSummary from '../../../../CapacityTable/components/AllocationSummary/AllocationSummary';
import AllocationSummaryTooltip from '../../../../CapacityTable/components/AllocationSummaryTooltip/AllocationSummaryTooltip';
import CapacityBreakdown from '../../../../CapacityTable/components/CapacityBreakdown/CapacityBreakdown';
import MemberTimeoff from '../../../../CapacityTable/components/TeamMembers/components/MemberTimeOff/MemberTimeOff';

const TeamMembersWeeklyCapacity: React.FC<CapacityPerWeek> = ({
  assigned,
  timeOff,
  capacity,
}) => {
  return (
    <div
      className={tailwindOverride(
        'w-full h-14 ',
        'pt-4 px-2',
        'flex flex-col gap-y-2.5'
      )}
    >
      <AllocationSummaryTooltip
        trigger={
          <div>
            <AllocationSummary
              assignedHours={parseFloat(assigned)}
              defaultCapacity={parseFloat(capacity)}
              timeOff={parseFloat(timeOff)}
              capacityView='WEEKLY'
              level='member'
            />
          </div>
        }
      >
        <CapacityBreakdown
          assignedHours={parseFloat(assigned)}
          defaultCapacity={parseFloat(capacity)}
          timeOff={parseFloat(timeOff)}
          capacityView='WEEKLY'
          level='member'
        />
      </AllocationSummaryTooltip>
      {parseFloat(timeOff) > 0 && (
        <MemberTimeoff timeOff={parseFloat(timeOff)} />
      )}
    </div>
  );
};

export default TeamMembersWeeklyCapacity;
