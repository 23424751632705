const CategorySkeleton = () => {
  return (
    <div className='flex flex-row w-full space-x-4'>
      <div className='p-6 border border-neutral-lighter-two rounded-lg h-insights w-2/3'>
        <div className='animate-pulse'>
          <div className='bg-neutral-lighter-two w-2/6 h-4 rounded' />
          <div className='bg-neutral-lighter-two w-5/6 h-4 mt-4 rounded' />

          <div className='bg-neutral-lighter-two w-3/6 h-4 mt-4 rounded' />
          <div className='bg-neutral-lighter-two w-4/6 h-4 mt-4 rounded' />

          <div className='bg-neutral-lighter-two w-6/6 h-28 mt-4 rounded' />
        </div>
      </div>
      <div className='p-6 border border-neutral-lighter-two rounded-lg h-insights w-1/3'>
        <div className='animate-pulse'>
          <div className='bg-neutral-lighter-two w-1/2 h-4 rounded' />
          <div className='bg-neutral-lighter-two w-2/2 h-4 rounded mt-4' />
          <div className='bg-neutral-lighter-two w-2/2 h-4 rounded mt-2' />
          <div className='bg-neutral-lighter-two w-2/2 h-4 rounded mt-2' />
          <div className='bg-neutral-lighter-two w-1/2 h-4 rounded mt-2' />
        </div>
      </div>
    </div>
  );
};

export default CategorySkeleton;
