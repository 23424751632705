import React from 'react';
import { ProjectLevelAllocation } from 'utils/types/tasksAssignmentsResourcesCapacity';
import { ParticipantTypes } from 'utils/constants';
import { Column } from '../../../Timeline/Timeline';
import TimeOffAllocation from '../TimeOffAllocation/TimeOffAllocation';
import AllocatedHoursAndAvailability from '../AllocatedHoursAndAvailability/AllocatedHoursAndAvailability';
import AllocatedHours from '../AllocatedHoursAndAvailability/AllocatedHours';

const Allocation: React.FC<{
  allocation: ProjectLevelAllocation;
  participantType: ParticipantTypes;
  isCurrentWeek: boolean;
  'data-testid'?: string;
  'data-cy'?: string;
}> = ({
  allocation,
  isCurrentWeek = false,
  participantType,
  ...otherProps
}) => {
  const displayAvailability =
    participantType !== ParticipantTypes.BUSINESS &&
    participantType !== ParticipantTypes.EXTERNAL;
  const hasTimeOff = allocation.timeOff > 0;
  return (
    <Column isCurrentWeek={isCurrentWeek} className='space-y-3' {...otherProps}>
      {displayAvailability ? (
        <AllocatedHoursAndAvailability allocation={allocation} />
      ) : (
        <AllocatedHours allocation={allocation} />
      )}
      {hasTimeOff && <TimeOffAllocation timeOff={allocation.timeOff} />}
    </Column>
  );
};

export default Allocation;
