import React, { useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { RootState } from 'state/store';
import {
  selectMemberIdsByTeam,
  selectNextTeamMembersListLink,
  fetchTeamMembersList,
} from 'state/Capacities/TeamMembersList/teamMembersListSlice';
import useRowsInView from '../../../../hooks/useRowsInView/useRowsInView';
import useScrollHandlerContext from '../../../ScrollHandler/useScrollHandlerContext';
import MemberRowAccordion from '../MemberRowAccordion/MemberRowAccordion';
import LastMemberRow from '../LastMemberRow/LastMemberRow';

const MembersList: React.FC<{
  teamId: string;
  isExpanded: boolean;
}> = ({ teamId, isExpanded }) => {
  const { fetchTeamMembersCapacity, removeTeamMembersCapacity } =
    useScrollHandlerContext();
  const dispatch = useDispatch();
  const firstRender = useRef<boolean>(true);
  const membersIds = useSelector((state: RootState) =>
    selectMemberIdsByTeam(state, teamId)
  );
  const canFetchMoreMembers = useSelector((state: RootState) =>
    selectNextTeamMembersListLink(state, teamId)
  );

  useEffect(() => {
    if (!isExpanded && !firstRender.current) {
      firstRender.current = true;
    }
  }, [isExpanded]);

  useEffect(() => {
    if (!isExpanded && membersIds.length > 0) {
      removeTeamMembersCapacity(teamId, membersIds);
    }
  }, [isExpanded, membersIds, teamId, removeTeamMembersCapacity]);

  const onVisibleMembersChangeHandler = useCallback(
    (membersIds: string[]) => fetchTeamMembersCapacity(teamId, membersIds),
    [teamId, fetchTeamMembersCapacity]
  );

  const handleFetchMoreTeamMembers = useCallback(() => {
    dispatch(fetchTeamMembersList(teamId));
  }, [dispatch, teamId]);

  const debouncedFetchMoreTeamMembersHandler = debounce(
    (shouldFetchMoreMembers: boolean) => {
      if (shouldFetchMoreMembers) {
        handleFetchMoreTeamMembers();
      }
    },
    500
  );

  const { updateRowInView } = useRowsInView(onVisibleMembersChangeHandler);

  return (
    <React.Fragment>
      {membersIds.map((memberId: string, index: number) => {
        let bgColor = 'bg-neutral-white';
        if (index % 2 === 0) {
          bgColor = 'bg-neutral-lightest-two';
        }
        return (
          <MemberRowAccordion
            key={`team-${teamId}__member-${memberId}`}
            teamId={teamId}
            memberId={memberId}
            bgColor={bgColor}
            updateRowInView={updateRowInView}
          />
        );
      })}
      {canFetchMoreMembers && (
        <LastMemberRow
          teamId={teamId}
          numberOfMembers={membersIds.length}
          onLastMemberRowInView={debouncedFetchMoreTeamMembersHandler}
        />
      )}
    </React.Fragment>
  );
};

export default MembersList;
