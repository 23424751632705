import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { PATHS, USER_TYPES } from 'utils/constants';
import { RequestPageTabs } from 'utils/customTypes';
import { selectUserType } from 'state/User/userSlice';
import { useSelector } from 'react-redux';
import BasicDetailsButtons from './BasicDetailsButtons';
import RequestDetailsButtons from './RequestDetailsButtons';
import AditionalDetailsButtons from './AditionalDetailsButtons';
import LDOnlyButtons from './LDOnlyButtons';

export const FooterButtons = ({
  activeTabIndex,
  setActiveTabIndex,
  onSubmit,
  onUpdate,
  requestDataStatus,
  hasErrors,
  onCancel,
  validRequiredFields,
  changesDetected,
}: {
  activeTabIndex: RequestPageTabs;
  setActiveTabIndex: (tabIndex: RequestPageTabs) => void;
  onSubmit: () => Promise<void>;
  onUpdate: () => Promise<void>;
  requestDataStatus: string;
  hasErrors: boolean;
  onCancel?: () => void;
  validRequiredFields: boolean;
  changesDetected: boolean;
}) => {
  const history = useHistory();
  const userType = useSelector(selectUserType);
  const isLDUser = useMemo(() => userType === USER_TYPES.L_D, [userType]);

  const onClickHandler = (value?: RequestPageTabs) => {
    if (value || value === 0) {
      setActiveTabIndex(value);
    }
  };

  const handleClickCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      history.push(PATHS.REQUESTS_LIST_PAGE);
    }
  };

  switch (activeTabIndex) {
    case 0:
      return (
        <BasicDetailsButtons
          requestStatus={requestDataStatus}
          onSubmit={onSubmit}
          onCancel={handleClickCancel}
          onUpdate={onUpdate}
          hasErrors={hasErrors}
          validRequiredFields={validRequiredFields}
          changesDetected={changesDetected}
        />
      );
    case 1:
      return (
        <RequestDetailsButtons
          requestStatus={requestDataStatus}
          onCancel={handleClickCancel}
          onSubmit={onSubmit}
          onUpdate={onUpdate}
          hasErrors={hasErrors}
          validRequiredFields={validRequiredFields}
          changesDetected={changesDetected}
        />
      );
    case 2:
      return (
        <AditionalDetailsButtons
          onClickHandler={onClickHandler}
          onCancel={handleClickCancel}
          onUpdate={onUpdate}
          hasErrors={hasErrors}
          isLDUser={isLDUser}
        />
      );
    case 3:
      return (
        <LDOnlyButtons
          onUpdate={onUpdate}
          onCancel={handleClickCancel}
          hasErrors={hasErrors}
        />
      );
    default:
      return null;
  }
};

export default FooterButtons;
