import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import notificationSettingsApi from './notificationSettingsAPI';
import { NotificationSetting } from 'utils/types/notification-settings';

interface NotificationsState {
  notificationSettings: NotificationSetting[];
  status: Status;
}

/* ============================= INITIAL STATE ============================== */
const initialState: NotificationsState = {
  notificationSettings: [],
  status: SLICE_STATUS.IDLE,
};

/* ============================== REDUX THUNK =============================== */
export const getNotificationSettings = createAsyncThunk(
  'notifications/GET_NOTIFICATION_SETTINGS',
  async (): Promise<NotificationSetting[]> => {
    return notificationSettingsApi.fetchNotificationSettings();
  }
);

export const updateNotificationSetting = createAsyncThunk(
  'notifications/UPDATE_NOTIFICATION_SETTINGS',
  async (opts: {
    userId: string;
    category: string;
    trigger: string;
    groupId: string;
    value: boolean;
  }) => {
    const { userId, trigger, groupId, value } = opts;
    const data = await notificationSettingsApi.updateNotificationSettings(
      userId,
      trigger,
      groupId,
      value
    );

    return data;
  }
);

/* ================================= REDUCER ================================ */
const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    RESET_STATUS: (state) => {
      state.status = SLICE_STATUS.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationSettings.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getNotificationSettings.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(getNotificationSettings.fulfilled, (state, action) => {
        state.notificationSettings = action.payload;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(updateNotificationSetting.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(updateNotificationSetting.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(updateNotificationSetting.fulfilled, (state) => {
        state.status = SLICE_STATUS.SUCCESS;
      });
  },
});

/* ================================ ACTIONS ================================= */
export const { RESET_STATUS: resetStatus } = notificationsSlice.actions;

/* =============================== SELECTORS ================================ */
export const selectNotificationSettings = createSelector(
  [(state: RootState) => state],
  (state: RootState) => {
    return state.notificationSettings.notificationSettings;
  }
);
export const selectNotificationSettingsStatus = createSelector(
  [(state: RootState) => state],
  (state: RootState) => {
    return state.notificationSettings.status;
  }
);
export default notificationsSlice.reducer;
