import {
  DataValueDescription,
  SmallGrayText,
} from 'Pages/InsightsPage/Components/DataValueDescription';
import { ByProcessBarChart } from 'Pages/InsightsPage/Projects/ByProcess/ByProcessBarChart';
import { Dropdown } from '@getsynapse/design-system';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProjectsByProcess,
  getProjectsByProcess,
  getActiveFiltersQueryParams,
} from 'state/Insights/ProjectsSlice';
import { Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import HorizontalBarChartLoader from 'Pages/InsightsPage/Components/SkeletonLoaders/HorizontalBarChartLoader';
import { SLICE_STATUS } from 'utils/constants';
import ErrorScreen from '../../Components/ErrorScreen';

export const ByProcess = () => {
  const dispatch = useDispatch();
  const activeFilters = useSelector(getActiveFiltersQueryParams);
  useEffect(() => {
    dispatch(fetchProjectsByProcess(activeFilters));
  }, [dispatch, activeFilters]);

  const { processes, status } = useSelector(getProjectsByProcess);

  const [selectedProcess, setSelectedProcess] = useState<{
    label: string;
    value: string;
  }>({
    label: '',
    value: '',
  });

  const onChange = (selectedProcess: { label: string; value: string }) => {
    setSelectedProcess(selectedProcess);
  };

  useEffect(() => {
    setSelectedProcess({
      label: processes.labels[0],
      value: processes.labels[0],
    });
  }, [processes.labels]);

  const selectedProcessData = useMemo(() => {
    const selected = processes.data.find(
      (process) => process.title === selectedProcess.label
    );

    return {
      stages: selected?.stages,
      total: selected?.total,
    };
  }, [processes.data, selectedProcess.label]);

  return status === SLICE_STATUS.LOADING ? (
    <HorizontalBarChartLoader />
  ) : status === SLICE_STATUS.FAILED ? (
    <ErrorScreen />
  ) : (
    <div className='flex flex-col h-full w-full'>
      <SmallGrayText className='mb-1'>
        {intl.get('PROJECTS_INSIGHTS_TABS.BY_PROCESS.TITLE')}
      </SmallGrayText>

      <div className='flex justify-between'>
        {selectedProcessData.total && (
          <DataValueDescription
            title={intl
              .get('PROJECTS_INSIGHTS_TABS.BY_PROCESS.TOTAL')
              .replace('*', selectedProcess.label)}
            value={selectedProcessData.total}
          />
        )}

        <div>
          <Dropdown
            className='w-28 mt-2'
            onChange={onChange}
            options={processes.labels.map((label) => {
              return {
                label: label,
                value: label,
              };
            })}
            triggerProps={{
              size: 'sm',
            }}
            values={[selectedProcess]}
          />
        </div>
      </div>

      <div className='h-full pt-4 w-full flex align-bottom'>
        {selectedProcessData.total === 0 ? (
          <div className='flex flex-col h-full px-6 justify-center items-center'>
            <Typography variant='caption' className='text-neutral-lighter'>
              {intl.get('PROJECTS_INSIGHTS_TABS.NO_PROJECTS')}
            </Typography>
          </div>
        ) : (
          <ByProcessBarChart data={selectedProcessData.stages} />
        )}
      </div>
    </div>
  );
};
