import { PickerFileMetadata } from 'filestack-js';
import { getImageFileStackConfig } from 'utils/filestack';
import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import {
  FormItem,
  Typography,
  FileUpload,
  IconButton,
} from '@getsynapse/design-system';
import removeIcon from 'assets/icons/trash-bin-small.svg';

const UploadLogo = ({
  onChange,
  logoFile,
}: {
  onChange: (
    property: 'portalLogo',
    value: PickerFileMetadata | undefined
  ) => void;
  logoFile?: PickerFileMetadata;
}) => {
  const fileStackConfig = getImageFileStackConfig();

  return (
    <FormItem className='mt-4 flex flex-col'>
      <Typography variant='body2' weight='medium'>
        {intl.get('INTAKE_TAB.UPLOAD_LOGO')}
      </Typography>
      {isEmpty(logoFile) ? (
        <FileUpload
          className='mt-1 flex-1'
          config={fileStackConfig}
          displayUploadedFiles={false}
          onFileUploadHandle={(files: PickerFileMetadata[]) =>
            onChange('portalLogo', files[0])
          }
          hidePickerWhenUploading
          customErrorMessages={{
            'file-too-large': intl.get('INTAKE_TAB.10MB_FILE_ERROR'),
          }}
          uploadInstructions={
            <>
              {intl.get('INTAKE_TAB.DRAG_HERE')}{' '}
              <span className='text-purple-darker'>
                {intl.get('INTAKE_TAB.CHOOSE')}
              </span>{' '}
              {intl.get('INTAKE_TAB.FROM_DEVICE')}
            </>
          }
          data-cy='portal-logo-picker'
        />
      ) : (
        <div className='flex items-center mt-1'>
          <span
            className='text-purple-darker text-sm'
            data-cy='portal-logo-picker-name'
          >
            {logoFile?.filename}
          </span>
          <IconButton
            className='ml-4 cursor-pointer'
            iconClassname='text-primary-dark text-base'
            data-cy='portal-logo-picker-delete'
            src={removeIcon}
            onClick={() => {
              onChange('portalLogo', undefined);
            }}
          />
        </div>
      )}
    </FormItem>
  );
};

export default UploadLogo;
