import { useState } from 'react';
import intl from 'react-intl-universal';
import MoreActions from 'Organisms/MoreActions/MoreActions';
import DeleteTask from '../DeleteTask/DeleteTask';
import { MoreActionsOption } from 'utils/customTypes';
import { TASKS_MORE_ACTIONS, PROJECT_STATUS } from 'utils/constants';
import { useSelector } from 'react-redux';
import { getCurrentProjectData } from 'state/Project/projectSlice';

const TaskActions: React.FC<{
  deleteTaskCallback: () => void;
}> = ({ deleteTaskCallback }) => {
  const [displayDeleteTaskModal, setDisplayDeleteTaskModal] = useState(false);

  const handleSelectOption = (option: MoreActionsOption) => {
    if (option.selectOption) {
      option.selectOption();
    }

    if (option.value === TASKS_MORE_ACTIONS.DELETE) {
      setDisplayDeleteTaskModal(true);
    }
  };

  const projectData = useSelector(getCurrentProjectData);

  const isReadOnly = [PROJECT_STATUS.CLOSED, PROJECT_STATUS.CANCELED].includes(
    projectData.status
  );

  return (
    <>
      <DeleteTask
        confirmDeleteTask={deleteTaskCallback}
        shouldDisplayModal={displayDeleteTaskModal}
        onCloseModal={() => setDisplayDeleteTaskModal(false)}
      />
      <MoreActions
        options={[
          {
            value: TASKS_MORE_ACTIONS.DELETE,
            label: intl.get(
              'TASKS.TASK_DETAIL_PAGE.MORE_ACTIONS_DROPDOWN.DELETE'
            ),
            iconName: 'trash',
            iconClassName: 'text-error-darker group-hover:text-error-dark',
            dataCy: 'delete-task-button',
            disabled: isReadOnly,
          },
        ]}
        onSelectOption={handleSelectOption}
      />
    </>
  );
};

export default TaskActions;
