import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { RootState } from 'state/store';
import {
  selectTimeOffEntry,
  updateTimeOffEntry as updateTimeOffEntryThunk,
} from 'state/ManageTimeOff/TimeOffEntries/all/allTimeOffEntriesSlice';
import {
  fetchTimeOffEntryDays,
  selectTimeOffDays,
} from 'state/ManageTimeOff/TimeOffDays/timeOffDaysSlice';
import { fetchUpcomingTimeOffEntries } from 'state/ManageTimeOff/TimeOffEntries/upcoming/upcomingTimeOffEntriesSlice';
import { fetchPastTimeOffEntries } from 'state/ManageTimeOff/TimeOffEntries/past/pastTimeOffEntriesSlice';
import {
  TimeOffEntryFormState,
  PartialHours,
  PartialHoursItem,
} from 'types/store/manageTimeOff';
import { EditTimeOffEntryParams } from 'api/manageTimeOff';
import {
  calculatePartialHoursTotalSum,
  generatePartialHoursItemsArray,
  dateFormatter,
  generatePartialHoursForDateRange,
  getPartialHoursDiff,
} from 'Pages/TimeOffListPage/utils/partialHours';

interface UseEditTimeOffState extends Omit<TimeOffEntryFormState, 'hours'> {
  id: string;
  days: PartialHours;
}

export interface UseEditTimeOffParams {
  userId?: string;
  timeOffEntryId: string;
  dailyCapacity: number;
  setCanSaveChanges: (canSaveChanges: boolean) => void;
  shouldSaveChanges: boolean;
  onSaveChanges: (callback: () => void) => void;
  showNotification: (params: any) => void;
}

interface UseEditTimeOffResponse {
  updatedTimeOffEntry: UseEditTimeOffState;
  updateTimeOffEntry: (
    key: keyof Omit<UseEditTimeOffState, 'id' | 'days'>,
    value: string
  ) => void;
  updateTimeOffEntryDays: (day: string, hours: number) => void;
  partialHoursItems: PartialHoursItem[];
  totalHours: number;
  updateValidityForNumericInput: (index: number, isValid: boolean) => void;
  updateDateRangeValidity: (isValid: boolean) => void;
}

const useEditTimeOff = ({
  userId,
  timeOffEntryId,
  dailyCapacity,
  setCanSaveChanges,
  shouldSaveChanges,
  onSaveChanges,
  showNotification,
}: UseEditTimeOffParams): UseEditTimeOffResponse => {
  const dispatch = useDispatch();
  const [updatedTimeOffEntry, setUpdatedTimeOffEntry] =
    useState<UseEditTimeOffState>({} as UseEditTimeOffState);
  const [validityForAllNumericInputs, setValidityForAllNumericInputs] =
    useState<boolean[]>([]);
  const [isDateRangeValid, setIsDateRangeValid] = useState<boolean>(true);

  const prevTimeOffEntry = useRef<UseEditTimeOffState | null>(null);
  const firstRender = useRef(true);
  const prevTimeOffDateRange = useRef<Pick<
    UseEditTimeOffState,
    'startDate' | 'endDate'
  > | null>(null);
  const originalTimeOffEntry = useSelector((state: RootState) =>
    selectTimeOffEntry(state, timeOffEntryId)
  );
  const originalTimeOffEntryDays = useSelector(selectTimeOffDays);

  const updateTimeOffEntry = useCallback(
    (key: keyof Omit<UseEditTimeOffState, 'days'>, value: string) => {
      setUpdatedTimeOffEntry((prev: UseEditTimeOffState) => ({
        ...prev,
        [key]: value,
      }));
    },
    []
  );

  const updateTimeOffEntryDays = useCallback((day: string, hours: number) => {
    setUpdatedTimeOffEntry((prev: UseEditTimeOffState) => ({
      ...prev,
      days: {
        ...prev.days,
        [day]: hours,
      },
    }));
  }, []);

  const areDatesDifferent = useCallback(
    (prevDateString: string, newDateString: string) => {
      const prevDate = new Date(prevDateString);
      const newDate = new Date(newDateString);
      return dateFormatter.format(prevDate) !== dateFormatter.format(newDate);
    },
    []
  );

  const getTimeOffDiff = useCallback(
    (
      prevTimeOffEntry: UseEditTimeOffState,
      newTimeOffEntry: UseEditTimeOffState
    ) => {
      let diff: EditTimeOffEntryParams['timeOffEntry'] = {};
      if (prevTimeOffEntry.timeOffTypeId !== newTimeOffEntry.timeOffTypeId) {
        diff.timeOffTypeId = newTimeOffEntry.timeOffTypeId;
      }
      if (
        areDatesDifferent(prevTimeOffEntry.startDate, newTimeOffEntry.startDate)
      ) {
        diff.startDate = newTimeOffEntry.startDate;
      }
      if (
        areDatesDifferent(prevTimeOffEntry.endDate, newTimeOffEntry.endDate)
      ) {
        diff.endDate = newTimeOffEntry.endDate;
      }
      let diffDays = getPartialHoursDiff(
        prevTimeOffEntry.days,
        newTimeOffEntry.days
      );
      if (Object.keys(diffDays).length > 0) {
        diff.days = diffDays;
      }
      return diff;
    },
    [areDatesDifferent]
  );

  const setTimeOffDays = useCallback(
    (startDate: Date, endDate: Date) => {
      const newPartialHours = generatePartialHoursForDateRange(
        startDate,
        endDate,
        dailyCapacity,
        updatedTimeOffEntry.days
      );
      setUpdatedTimeOffEntry((prev: UseEditTimeOffState) => ({
        ...prev,
        days: newPartialHours,
      }));
    },
    [dailyCapacity, updatedTimeOffEntry.days]
  );

  const updateValidityForNumericInput = useCallback(
    (index: number, isValid: boolean) => {
      setValidityForAllNumericInputs((prev) => {
        if (index >= prev.length) {
          return [...prev, isValid];
        }

        const newValidity = [...prev];
        newValidity[index] = isValid;
        return newValidity;
      });
    },
    []
  );

  const partialHoursItems = useMemo(() => {
    if (updatedTimeOffEntry.days) {
      return generatePartialHoursItemsArray(updatedTimeOffEntry.days);
    }
    return [];
  }, [updatedTimeOffEntry.days]);

  const totalHours = useMemo(() => {
    if (updatedTimeOffEntry.days) {
      return calculatePartialHoursTotalSum(updatedTimeOffEntry.days);
    }
    return 0;
  }, [updatedTimeOffEntry.days]);

  const timeOffDiffToSubmit = useMemo(() => {
    if (
      !firstRender.current &&
      prevTimeOffEntry.current &&
      Object.keys(updatedTimeOffEntry).length > 0
    ) {
      return getTimeOffDiff(prevTimeOffEntry.current, updatedTimeOffEntry);
    }
    return {};
  }, [updatedTimeOffEntry, getTimeOffDiff]);

  const areAllNumericInputsValid = useMemo(
    () => validityForAllNumericInputs.every((valid) => valid),
    [validityForAllNumericInputs]
  );

  const editTimeOff = useCallback(async () => {
    try {
      await dispatch(
        updateTimeOffEntryThunk({
          userId: userId as string,
          id: timeOffEntryId,
          timeOffEntry: timeOffDiffToSubmit,
        })
      );
      showNotification({
        notificationMessage: intl.get('MANAGE_TIME_OFF.UPDATE_TIME_OFF_SUCCES'),
      });
      dispatch(fetchUpcomingTimeOffEntries({ userId: userId as string }));
      dispatch(fetchPastTimeOffEntries({ userId: userId as string }));
    } catch (error) {
      showNotification({
        notificationVariant: 'error',
        notificationTitle: intl.get('MANAGE_TIME_OFF.SAVE_CHANGES_ERROR_TITLE'),
        notificationMessage: intl.get(
          'MANAGE_TIME_OFF.SAVE_CHANGES_ERROR_MESSAGE'
        ),
      });
    }
  }, [dispatch, timeOffDiffToSubmit, userId, timeOffEntryId, showNotification]);

  useEffect(() => {
    if (shouldSaveChanges && userId) {
      onSaveChanges(editTimeOff);
    }
  }, [editTimeOff, shouldSaveChanges, userId, onSaveChanges]);

  useEffect(() => {
    if (userId && timeOffEntryId) {
      dispatch(
        fetchTimeOffEntryDays({
          userId,
          entryId: timeOffEntryId,
        })
      );
    }
  }, [dispatch, userId, timeOffEntryId]);

  useEffect(() => {
    if (
      originalTimeOffEntry &&
      Object.keys(originalTimeOffEntryDays).length > 0
    ) {
      firstRender.current = false;
      const timeOffEntryToUpdate = {
        id: originalTimeOffEntry.id,
        timeOffTypeId: originalTimeOffEntry.timeOffTypeId,
        startDate: new Date(
          originalTimeOffEntry.startDate.replace(/-/g, '/')
        ).toISOString(),
        endDate: new Date(
          originalTimeOffEntry.endDate.replace(/-/g, '/')
        ).toISOString(),
        days: originalTimeOffEntryDays,
      };
      prevTimeOffEntry.current = timeOffEntryToUpdate;
      prevTimeOffDateRange.current = {
        startDate: dateFormatter.format(
          new Date(timeOffEntryToUpdate.startDate)
        ),
        endDate: dateFormatter.format(new Date(timeOffEntryToUpdate.endDate)),
      };
      setUpdatedTimeOffEntry(timeOffEntryToUpdate);
    }
  }, [originalTimeOffEntry, originalTimeOffEntryDays]);

  useEffect(() => {
    if (
      !firstRender.current &&
      updatedTimeOffEntry.startDate &&
      updatedTimeOffEntry.endDate &&
      prevTimeOffDateRange.current
    ) {
      if (
        areDatesDifferent(
          prevTimeOffDateRange.current.startDate,
          updatedTimeOffEntry.startDate
        ) ||
        areDatesDifferent(
          prevTimeOffDateRange.current.endDate,
          updatedTimeOffEntry.endDate
        )
      ) {
        prevTimeOffDateRange.current.startDate = updatedTimeOffEntry.startDate;
        prevTimeOffDateRange.current.endDate = updatedTimeOffEntry.endDate;
        setTimeOffDays(
          new Date(updatedTimeOffEntry.startDate),
          new Date(updatedTimeOffEntry.endDate)
        );
      }
    }
  }, [
    updatedTimeOffEntry.startDate,
    updatedTimeOffEntry.endDate,
    setTimeOffDays,
    areDatesDifferent,
  ]);

  useEffect(() => {
    if (!firstRender.current) {
      setCanSaveChanges(
        areAllNumericInputsValid &&
          isDateRangeValid &&
          Object.keys(timeOffDiffToSubmit).length > 0
      );
    }
  }, [
    areAllNumericInputsValid,
    setCanSaveChanges,
    timeOffDiffToSubmit,
    isDateRangeValid,
  ]);

  return useMemo(
    () => ({
      updatedTimeOffEntry,
      updateTimeOffEntry,
      updateTimeOffEntryDays,
      partialHoursItems,
      totalHours,
      updateValidityForNumericInput,
      updateDateRangeValidity: setIsDateRangeValid,
    }),
    [
      updatedTimeOffEntry,
      updateTimeOffEntry,
      updateTimeOffEntryDays,
      partialHoursItems,
      totalHours,
      updateValidityForNumericInput,
    ]
  );
};

export default useEditTimeOff;
