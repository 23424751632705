import { createContext } from 'react';
import { CapacityView } from 'utils/types/capacityView';

export type ScrollHandlerContextType = {
  rootId: Element | null;
  capacityView: CapacityView;
  setRootId: (rootId: Element | null) => void;
  fetchTeamsCapacity: (teamIds: string[]) => void;
  fetchTeamMembersCapacity: (teamId: string, memberIds: string[]) => void;
  fetchTeamMembeProjectsCapacity: (
    teamId: string,
    memberId: string,
    projectIds: string[]
  ) => void;
  removeTeamMembersCapacity: (teamId: string, memberIds: string[]) => void;
  removeTeamMemberProjects: (teamId: string, memberId: string) => void;
};

export const ScrollHandlerContext = createContext<ScrollHandlerContextType>(
  {} as ScrollHandlerContextType
);
