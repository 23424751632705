import { Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import ErrorScreen from '../../Components/ErrorScreen';
import BasicInfoLoader from '../../Components/SkeletonLoaders/BasicInfoLoader';
import { SLICE_STATUS } from 'utils/constants';

const TotalProjects = ({
  totalProjects,
  status,
}: {
  totalProjects: number;
  status?: string;
}) => {
  return status === SLICE_STATUS.LOADING ? (
    <BasicInfoLoader hideSubLine className='mt-2' />
  ) : status === SLICE_STATUS.FAILED ? (
    <ErrorScreen />
  ) : (
    <div className='h-full flex justify-center space-x-2 items-center'>
      <span className='flex text-5xl font-bold'>{totalProjects}</span>
      <Typography
        variant='caption'
        weight='medium'
        className='flex flex-wrap py-2 w-4'
      >
        {intl.get('INSIGHTS_PAGE.PROJECTS.TOTAL_PROJECTS')}
      </Typography>
    </div>
  );
};

export default TotalProjects;
