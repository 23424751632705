import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'state/store';
import { fetchUpcomingTimeOffEntries as fetchUpcomingTimeOffEntriesAPI } from 'api/manageTimeOff';
import { SLICE_STATUS } from 'utils/constants';
import { TIME_OFFS_TABLE_HEADERS } from 'utils/constants/manageTimeOff';
import { Status } from 'utils/customTypes';
import {
  TimeOffEntry,
  TimeOffEntriesTableSorting,
} from 'types/store/manageTimeOff';
import {
  addTimeOffEntries,
  selectAllTimeOffEntriesById,
  removeTimeOffEntry,
} from '../all/allTimeOffEntriesSlice';
import { getAfterQueryParamFromNextLink } from '../helpers';
import { TimeOffEntryFilters } from 'types/store/manageTimeOff';

export type UpcomingTimeOffEntriesStateStatus = Status | 'rejected';

interface FetchUpcomingTimeOffEntriesParams {
  userId: string;
  includeTotalCount?: boolean;
  fetchNext?: boolean;
}

interface UpcomingTimeOffEntriesState {
  ids: string[];
  status: UpcomingTimeOffEntriesStateStatus;
  links: {
    next: string | null;
  };
  filters: TimeOffEntryFilters;
  sorting: TimeOffEntriesTableSorting;
  totalCount?: number;
}

const initialState: UpcomingTimeOffEntriesState = {
  ids: [],
  status: 'idle',
  links: {
    next: null,
  },
  filters: {} as TimeOffEntryFilters,
  sorting: {
    sortBy: TIME_OFFS_TABLE_HEADERS.DATES,
    order: 'asc',
  },
  totalCount: 0,
};

export const fetchUpcomingTimeOffEntries = createAsyncThunk(
  'manageTimeOff/upcomingTimeOffEntries/fetchUpcomingTimeOffEntries',
  async (
    {
      userId,
      includeTotalCount = false,
      fetchNext = false,
    }: FetchUpcomingTimeOffEntriesParams,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;
      let afterQueryParam = '';
      if (fetchNext) {
        const nextLink = state.manageTimeOff.upcomingTimeOffEntries.links.next;
        if (nextLink) {
          afterQueryParam = getAfterQueryParamFromNextLink(nextLink);
        }
      }
      const filters = state.manageTimeOff.upcomingTimeOffEntries.filters;
      const sorting = state.manageTimeOff.upcomingTimeOffEntries.sorting;
      const timeOffEntries = await fetchUpcomingTimeOffEntriesAPI({
        userId,
        includeTotalCount,
        after: afterQueryParam,
        ...filters,
        ...sorting,
      });
      const allIds: string[] = [];
      const byId = timeOffEntries.entries.reduce(
        (acc: { [key: string]: TimeOffEntry }, entry: TimeOffEntry) => {
          allIds.push(entry.id);
          acc[entry.id] = entry;
          return acc;
        },
        {}
      );
      dispatch(addTimeOffEntries(byId));
      return {
        ids: allIds,
        links: timeOffEntries.links,
        totalCount: timeOffEntries?.totalCount || 0,
        fetchNext,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 403) {
          return rejectWithValue('rejected');
        }
      }
      return rejectWithValue(SLICE_STATUS.FAILED);
    }
  }
);

export const setFilters = createAction(
  'manageTimeOff/upcomingTimeOffEntries/setFilters',
  (filters: TimeOffEntryFilters) => {
    return { payload: { filters } };
  }
);

const upcomingTimeOffEntriesSlice = createSlice({
  name: 'manageTimeOff/upcomingTimeOffEntries',
  initialState,
  reducers: {
    resetUpcomingTimeOffEntries: () => initialState,
    setSorting: (
      state,
      action: { type: string; payload: TimeOffEntriesTableSorting }
    ) => {
      return {
        ...state,
        sorting: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUpcomingTimeOffEntries.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchUpcomingTimeOffEntries.fulfilled, (state, action) => {
        if (action.payload) {
          let updatedIds = action.payload.ids;
          if (action.payload.fetchNext) {
            updatedIds = Array.from(new Set([...state.ids, ...updatedIds]));
          }
          state.status = SLICE_STATUS.IDLE;
          state.ids = updatedIds;
          state.links = action.payload.links;
          if (action.payload.totalCount) {
            state.totalCount = action.payload.totalCount;
          }
        }
      })
      .addCase(fetchUpcomingTimeOffEntries.rejected, (state, action) => {
        state.status = action.payload as UpcomingTimeOffEntriesStateStatus;
      })
      .addCase(removeTimeOffEntry.fulfilled, (state, action) => {
        if (action.payload.id) {
          state.ids = state.ids.filter((id) => id !== action.payload.id);
        }
      })
      .addCase(setFilters, (state, action) => {
        state.filters = action.payload.filters;
      });
  },
});

export const selectFetchUpcomingTimeOffEntriesStatus = (state: RootState) =>
  state.manageTimeOff.upcomingTimeOffEntries.status;
export const selectUpcomingTimeOffEntriesIds = (state: RootState) =>
  state.manageTimeOff.upcomingTimeOffEntries.ids;
export const selectUpcomingTimeOffEntries = createSelector(
  [selectAllTimeOffEntriesById, selectUpcomingTimeOffEntriesIds],
  (entries: { [id: string]: TimeOffEntry }, ids: string[]) =>
    ids.map((id) => entries[id])
);
export const selectFilters = (state: RootState) =>
  state.manageTimeOff.upcomingTimeOffEntries.filters;
export const selectUpcomingTimeOffEntriesSorting = (state: RootState) =>
  state.manageTimeOff.upcomingTimeOffEntries.sorting;
export const selectCanFetchMoreUpcomingTimeOffEntries = (state: RootState) =>
  !!state.manageTimeOff.upcomingTimeOffEntries.links.next;
export const selectTotalUpcomingTimeOffEntries = (state: RootState) =>
  state.manageTimeOff.upcomingTimeOffEntries.totalCount;

export const { resetUpcomingTimeOffEntries, setSorting } =
  upcomingTimeOffEntriesSlice.actions;

export default upcomingTimeOffEntriesSlice.reducer;
