import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Typography } from '@getsynapse/design-system';
import importIcon from 'assets/icons/import.svg';
import {
  attachTaskBundleToProject,
  fetchTaskBundles,
  selectAllTaskBundles,
} from 'state/TasksBundle/tasksBundleSlice';
import { Option } from 'utils/customTypes';
import intl from 'react-intl-universal';
import { useParams } from 'react-router-dom';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { fetchFilterSettingByType } from 'state/Settings/Filters/FiltersSlice';
import { SETTINGS_FILTERS } from 'utils/constants';
import {
  fetchTeamTasks,
  fetchUserTasks,
} from 'state/ProjectTasks/projectTaskSlice';
import TaskBundlePicker from 'Molecules/TaskBundlePicker/TaskBundlePicker';
import { useElementWidth } from 'Hooks/useElementWidth';
import { fetchProject } from 'state/Project/projectSlice';

interface ImportTasksModalProps {
  showModal: boolean;
  onClose: () => void;
}

const ImportTasksModal: React.FC<ImportTasksModalProps> = ({
  showModal,
  onClose,
}) => {
  const dispatch = useDispatch();
  const containerElement = useRef<HTMLDivElement>(null);
  const taskBundleWidth = useElementWidth(containerElement);

  const taskBundles = useSelector(selectAllTaskBundles);

  const [selectedTaskBundle, setSelectedTaskBundle] = useState<Option>();
  const [isLoading, setIsLoading] = useState(false);

  const onCloseModal = () => {
    setSelectedTaskBundle(undefined);
    onClose();
  };

  useEffect(() => {
    if (showModal) {
      dispatch(fetchTaskBundles());
    }
  }, [dispatch, showModal]);

  const { projectId } = useParams<{ projectId: string }>();

  const onImportTasksFromBundle = async () => {
    setIsLoading(true);

    await dispatch(
      attachTaskBundleToProject({
        projectId,
        taskBundleId: selectedTaskBundle?.value,
      })
    );

    setIsLoading(false);
    onClose();

    await Promise.all([
      dispatch(
        showNotificationBanner({
          notificationVariant: 'success',
          notificationText: intl.get(
            'TASKS.IMPORT_TASKS_MODAL.SUCCESS_MESSAGE'
          ),
        })
      ),
      dispatch(fetchProject(projectId)),
      dispatch(fetchUserTasks(projectId)),
      dispatch(fetchTeamTasks(projectId)),
      dispatch(
        fetchFilterSettingByType(SETTINGS_FILTERS.PROJECT_TEAM_TASKS_TABLE)
      ),
      dispatch(
        fetchFilterSettingByType(SETTINGS_FILTERS.MY_PROJECT_TASKS_TABLE)
      ),
    ]);
  };

  return (
    <Modal
      title={intl.get('TASKS.IMPORT_TASKS_MODAL.TITLE')}
      titleIcon={{ src: importIcon }}
      isOpen={showModal}
      closeModal={onCloseModal}
      aria-label={intl.get('TASKS.IMPORT_TASKS_MODAL.TITLE')}
      data-cy='import-tasks-modal'
      size='medium'
    >
      <Typography>
        {intl.get('TASKS.IMPORT_TASKS_MODAL.DESCRIPTION')}
      </Typography>

      <div ref={containerElement}>
        <TaskBundlePicker
          required
          taskBundles={taskBundles}
          onSelectTaskBundle={setSelectedTaskBundle}
          width={taskBundleWidth}
        />
      </div>

      <div className='flex space-x-3 mt-8'>
        <Button
          loading={isLoading}
          onClick={onImportTasksFromBundle}
          disabled={!selectedTaskBundle}
          className=' h-10'
          data-cy='import-tasks-modal-import_button'
        >
          {intl.get('IMPORT')}
        </Button>

        <Button
          onClick={onCloseModal}
          disabled={isLoading}
          className=' h-10'
          variant='tertiary'
        >
          {intl.get('CANCEL')}
        </Button>
      </div>
    </Modal>
  );
};

export default ImportTasksModal;
