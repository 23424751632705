import { useCallback, useState } from 'react';

const useSidePanel = (onCloseCallback = () => {}) => {
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [showUnsavedBanner, setShowUnsavedBanner] = useState<boolean>(false);
  const [showUnsavedChangesAnimation, setShowUnsavedChangesAnimation] =
    useState<boolean>(false);

  const resetState = useCallback(() => {
    setHasUnsavedChanges(false);
    setShowUnsavedBanner(false);
    setShowUnsavedChangesAnimation(false);
  }, []);

  const openPanel = useCallback(() => {
    resetState();
    setIsPanelOpen(true);
  }, [resetState]);

  const closePanel = useCallback(() => {
    setIsPanelOpen(false);
    resetState();
    onCloseCallback();
  }, [onCloseCallback, resetState]);

  const onClose = useCallback(() => {
    if (!hasUnsavedChanges) {
      closePanel();
    } else {
      setShowUnsavedBanner(true);
      setShowUnsavedChangesAnimation(true);
      setTimeout(() => {
        setShowUnsavedChangesAnimation(false);
      }, 500);
    }
  }, [closePanel, hasUnsavedChanges]);

  return {
    openPanel,
    isPanelOpen,
    closePanel,
    onClose,
    resetState,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    showUnsavedBanner,
    showUnsavedChangesAnimation,
  };
};

export default useSidePanel;
