import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchTeamTasks,
  selectTeamTasksLoading,
  selectTeamTasks,
  selectCanFetchtMoreTasks,
  selectTotalCount,
} from 'state/ProjectTasksList/teamTasks/teamTasksSlice';
import { fetchProjectProgress } from 'state/Project/progress/progressSlice';
import { TASKS_TABLE_TABS } from 'utils/constants';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';
import TasksTable from '../TasksTable/TasksTable';

const TeamTasks = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectTeamTasksLoading);
  const teamTasks = useSelector(selectTeamTasks);
  const canFetchMoreTasks = useSelector(selectCanFetchtMoreTasks);
  const totalCount = useSelector(selectTotalCount);
  const { projectId } = useParams<{ projectId: string }>();

  useEffect(() => {
    if (projectId) {
      dispatch(fetchTeamTasks({ projectId, includeTotalCount: true }));
      dispatch(fetchProjectProgress({ projectId }));
    }
  }, [dispatch, projectId]);

  const handleFetchMoreTasks = useCallback(() => {
    dispatch(
      fetchTeamTasks({
        projectId,
        fetchNext: true,
      })
    );
  }, [dispatch, projectId]);

  if (isLoading) {
    return (
      <SkeletonLoader
        data-testid={`${TASKS_TABLE_TABS.TEAM_TASKS}__skeleton-loader`}
      />
    );
  }

  return (
    <TasksTable
      tableName={TASKS_TABLE_TABS.TEAM_TASKS}
      tasks={teamTasks}
      canFetchMoreTasks={canFetchMoreTasks}
      fetchMoreTasks={handleFetchMoreTasks}
      totalCount={totalCount}
    />
  );
};

export default TeamTasks;
