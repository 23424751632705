import { useState, useRef } from 'react';
import { TabPanel, TabPanels, Tabs } from '@reach/tabs';
import intl from 'react-intl-universal';
import { Tab, TabList, Button } from '@getsynapse/design-system';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import Events from './components/Events/Events';
import { EventsScheduleRef } from './components/Events/components/EventsSchedule/EventsSchedule';

const LearningSchedule = () => {
  const SCHEDULE_TABS_MAPPING: { [key: string]: number } = {
    events: 0,
  };

  const queryParams = new URLSearchParams(window.location.search);
  const activeTab = queryParams.get('activeTab');
  const [currentTabIndex, setCurrentTabIndex] = useState(
    activeTab ? SCHEDULE_TABS_MAPPING[activeTab] : 0
  );
  const eventsScheduleRef = useRef<EventsScheduleRef>(null);

  const onAddingEvent = () => {
    eventsScheduleRef?.current?.onAddingEvent();
  };

  return (
    <>
      <PageTitle titleComponent={intl.get('SCHEDULE.TITLE')} />
      <Tabs
        index={currentTabIndex}
        onChange={(index) => {
          setCurrentTabIndex(index);
          window.history.replaceState(
            {},
            '',
            `${window.location.pathname}?activeTab=${
              Object.keys(SCHEDULE_TABS_MAPPING)[index]
            }`
          );
        }}
        className='mx-6'
      >
        {({ selectedIndex }) => (
          <>
            <div className='flex justify-between'>
              <TabList className='w-64 mb-4'>
                <Tab index={0}>{intl.get('SCHEDULE.TABS.EVENTS')}</Tab>
              </TabList>
              {selectedIndex === 0 && (
                <Button
                  className='h-8'
                  data-testid='add-event_button'
                  onClick={onAddingEvent}
                >
                  {intl.get('SCHEDULE.ADD_EVENT_BUTTON')}
                </Button>
              )}
            </div>

            <TabPanels>
              <TabPanel key={0}>
                <Events ref={eventsScheduleRef} />
              </TabPanel>
            </TabPanels>
          </>
        )}
      </Tabs>
    </>
  );
};

export default LearningSchedule;
