import { useRef, useCallback } from 'react';
import debounce from 'lodash/debounce';

const useRowsInView: (onRowsInView: (rowsInView: string[]) => void) => {
  updateRowInView: (rowId: string, inView: boolean) => void;
} = (onRowsInView) => {
  const rowsInViewById = useRef<Map<string, boolean>>(
    new Map<string, boolean>()
  );

  const handleRowsInView = useCallback(() => {
    const rowsInView = Array.from(rowsInViewById.current?.entries() ?? [])
      .filter(([, inView]) => inView)
      .map(([rowId]) => rowId);
    onRowsInView(rowsInView);
  }, [onRowsInView]);

  const debouncedHandleRowsInView = debounce(handleRowsInView, 500);

  const updateRowInView = useCallback(
    (rowId: string, inView: boolean) => {
      rowsInViewById.current?.set(rowId, inView);
      debouncedHandleRowsInView();
    },
    [debouncedHandleRowsInView]
  );

  return {
    updateRowInView,
  };
};

export default useRowsInView;
