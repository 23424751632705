export const ACTIVE_COLORS = [
  '#45598C',
  '#F6BD30',
  '#8B2C56',
  '#3389D3',
  '#4B6B0A',
];
export const IN_ACTIVE_COLORS = [
  '#E1E9EC',
  '#E1E9EC',
  '#E1E9EC',
  '#E1E9EC',
  '#E1E9EC',
];
export const inActiveColor = '#E1E9EC';
export const SECTOR_INACTIVE_COLOR = '#C1CFD6';
export const CENTER_COUNT_COLOR = '#0E1212';
export const CENTER_TEXT_COLOR = '#506268';
export const PATH_CIRCLE_COLOR = '#A0B0B5';
export const chartCenterCountStyle = {
  fontWeight: 500,
  fontSize: '1.5em',
  lineHeight: '1.7em',
};

export const chartCenterTextStyle = {
  fontWeight: 400,
  fontSize: '0.875em',
  lineHeight: '1em',
};

export const DATA_STATE_NO_PROJECTS_LINKED = [
  {
    name: 'New',
    value: 1,
  },
  {
    name: 'In Queue',
    value: 1,
  },
  {
    name: 'On Hold',
    value: 1,
  },
  {
    name: 'In Progress',
    value: 1,
  },
  {
    name: 'Completed',
    value: 1,
  },
];
