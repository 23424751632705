import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {
  createAsyncThunk,
  createSlice,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import userAPI from './userAPI';
import { RootState } from 'state/store';
import { Status, CurrentUser, BusinessUser } from 'utils/customTypes';
import { SLICE_STATUS, USER_TYPES, USER_ROLES } from 'utils/constants';

interface UserState {
  value: CurrentUser;
  status: Status;
  nonRegisteredUser: CurrentUser;
}

/* ============================= INITIAL STATE ============================== */
const initialState: UserState = {
  value: {},
  status: 'idle',
  nonRegisteredUser: {},
};

/* ============================== REDUX THUNK =============================== */
export const getUser = createAsyncThunk(
  'user/GET_USER',
  async (email: string) => {
    const response = await userAPI.getUser(email);
    if (isEmpty(response.data.user)) {
      throw new Error('User not in DB');
    }
    const data = {
      id: get(response, 'data.user.id'),
      email: get(response, 'data.user.data.email'),
      full_name: `${get(response, 'data.user.data.firstName')} ${get(
        response,
        'data.user.data.lastName'
      )}`,
      type: get(response, 'data.user.type'),
      organization_id: get(response, 'data.user.organization_id'),
      role: get(response, 'data.user.role'),
      avatar_url: get(response, 'data.user.avatar_url'),
      firstName: get(response, 'data.user.data.firstName'),
      lastName: get(response, 'data.user.data.lastName'),
      status: get(response, 'data.user.status'),
      teamsManaged: get(response, 'data.user.teamsManaged') || [],
      associatedTeamsCount: get(response, 'data.user.associatedTeamsCount', 0),
      collaboratedProjects: get(
        response,
        'data.user.collaboratedProjects',
        get(response, 'data.user.collboratedProjects')
      ),
      v2ParticipatedProjects:
        get(response, 'data.user.v2_participatedProjects', []) || [],
      businessTeam: get(response, 'data.user.businessTeam_id') || '',
      jobTitle: get(response, 'data.user.data.jobTitle'),
      dashboardIds: get(response, 'data.user.dashboardIds'),
      firstUser: get(response, 'data.user.data.firstUser', false),
      createdAt: get(response, 'data.user.createdAt'),
      defaultCapacity: get(response, 'data.user.default_capacity'),
    };
    return data;
  }
);

export const getUserBasicInfo = createAsyncThunk(
  'user/GET_USER_BASIC_INFO',
  async (userId: string) => {
    const response = await userAPI.getUserBasicInfo(userId);
    if (isEmpty(response.data.basicInfo)) {
      throw new Error('User not in DB');
    }
    return response.data.basicInfo;
  }
);

export const setRegistered = createAsyncThunk(
  'user/SET_USER_AS_REGISTERED',
  async (userId: string) => {
    const response = await userAPI.setRegistered(userId);
    if (response.code !== 200) {
      throw new Error('User is not updated');
    }
    return response.data;
  }
);

export const selfRegistration = createAsyncThunk(
  'user/SELF_REGISTRATION',
  async ({
    userId,
    updateFields,
  }: {
    userId: string;
    updateFields: Partial<BusinessUser>;
  }) => {
    const response = await userAPI.userSelfRegistration(userId, updateFields);
    if (response.success) {
      return response.data.user;
    }
    return response;
  }
);

export const updateLoginData = createAsyncThunk(
  'user/UPDATE_LOGIN_DATA',
  async () => {
    const { data } = await userAPI.updateLoginData();
    return data.user;
  }
);

/* ================================= REDUCER ================================ */
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    RESET_USER: (state) => {
      state.value = initialState.value;
      state.status = initialState.status;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.value = {};
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.value = action.payload;
        if (!isEmpty(action.payload)) {
          state.status = SLICE_STATUS.IDLE;
        } else {
          state.status = SLICE_STATUS.FAILED;
        }
      })
      .addCase(getUserBasicInfo.fulfilled, (state, action) => {
        state.nonRegisteredUser = action.payload;
      })
      .addCase(setCurrentUser, (state, action) => {
        state.value = action.payload;
      });
  },
});

/* ================================ ACTIONS ================================= */
export const { RESET_USER: resetUser } = userSlice.actions;

export const setCurrentUser = createAction<CurrentUser>(
  'usersManagement/SET_CURRENT_USER'
);

/* =============================== SELECTORS ================================ */
export const selectUserSliceStatus = (state: RootState) =>
  state.currentUser.status;

export const selectUser = (state: RootState) => state.currentUser.value;

export const selectUserId = (state: RootState) => state.currentUser.value.id;

export const selectUserEmail = (state: RootState) =>
  state.currentUser.value.email;

export const selectOrganizationId = (state: RootState) =>
  state.currentUser.value.organization_id;

export const selectUserType = (state: RootState) =>
  state.currentUser.value.type;

export const selectUserName = (state: RootState) =>
  state.currentUser.value.full_name;

export const selectUserRole = (state: RootState) =>
  state.currentUser.value.role;

export const selectManagedTeams = (state: RootState) =>
  state.currentUser.value.teamsManaged;

export const selectAssociatedTeamsCount = (state: RootState) =>
  state.currentUser.value.associatedTeamsCount;

export const selectCollaboratedProjects = (state: RootState) =>
  state.currentUser.value.collaboratedProjects;

export const selectParticipatedProjects = (state: RootState) =>
  state.currentUser.value.v2ParticipatedProjects;

export const selectIsUserLd = createSelector(
  selectUserType,
  (userType) => userType === USER_TYPES.L_D
);

export const selectIsFirstUser = (state: RootState) =>
  state.currentUser.value.firstUser;

export const isUserOrganizationAdmin = createSelector(
  [selectUserType, selectUserRole],
  (userType, userRole) =>
    userType === USER_TYPES.L_D && userRole === USER_ROLES.ADMIN
);

export const selectUserBasicInfo = (state: RootState) =>
  state.currentUser.nonRegisteredUser;

export const selectUserDashboardIds = (state: RootState) =>
  state.currentUser.value.dashboardIds;

export const selectUserDefaultCapacity = (state: RootState) =>
  state.currentUser.value.defaultCapacity || 40;

export const selectUserDailyCapacity = createSelector(
  selectUserDefaultCapacity,
  (defaultCapacity) => defaultCapacity / 5
);

export default userSlice.reducer;
