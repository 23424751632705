import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { selectDailyProjectCapacity } from 'state/DailyTeamsCapacity/TeamMemberProjectsCapacity/teamMemberProjectsCapacitySlice';
import { DailyProjectCapacityProps } from 'utils/types/dailyTeamsCapacity';
import NoDailyCapacity from '../../DaysCarousel/components/NoDailyCapacity/NoDailyCapacity';
import DailyProjectCapacity from '../DailyProjectCapacity/DailyProjectCapacity';
import DaysCarousel from '../../DaysCarousel/DaysCarousel';

const DailyProjectCapacities: React.FC<{
  teamId: string;
  memberId: string;
  projectId: string;
}> = ({ teamId, memberId, projectId }) => {
  const dailyCapacity = useSelector((state: RootState) =>
    selectDailyProjectCapacity(state, teamId, memberId, projectId)
  );
  const id = `team-${teamId}__member-${memberId}__project-${projectId}`;
  if (Object.keys(dailyCapacity).length === 0) {
    return (
      <div className='w-4/6 h-full flex'>
        <NoDailyCapacity id={id} isLoading />
      </div>
    );
  }
  return (
    <div className='w-4/6 h-full flex'>
      <DaysCarousel<DailyProjectCapacityProps>
        id={id}
        component={DailyProjectCapacity}
        items={dailyCapacity}
      />
    </div>
  );
};

export default DailyProjectCapacities;
