import { useState, FC } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Auth from '@aws-amplify/auth';

import { Icon, IconButton, Typography, Tag } from '@getsynapse/design-system';
import { closeSidekick } from 'state/Sidekick/sidekickSlice';
import config from 'config/Config';

const Header: FC<{ tagLabel: string }> = ({ tagLabel }) => {
  const dispatch = useDispatch();

  return (
    <div className='flex justify-between py-4 px-6 border-b border-neutral-lighter-two font-semibold'>
      <div className='flex items-center'>
        <Icon name='planet-outline' className='mr-2' />

        <Typography variant='body' weight='medium'>
          {intl.get('SIDEKICK')}
        </Typography>

        <Tag
          label={tagLabel}
          className='bg-tertiary-lightest group-hover:border-tertiary py-px px-1.5 ml-2'
          textClassName='text-tertiary-dark text-xs font-semibold'
        />
      </div>

      <IconButton
        onClick={() => {
          dispatch(closeSidekick());
        }}
        name='close'
        className='text-primary-dark rounded text-lg p-1 hover:bg-neutral-lightest hover:shadow-allocation-table'
      />
    </div>
  );
};

const Body: FC<{
  baseURl: string;
}> = ({ baseURl }) => {
  const [token, setToken] = useState();
  const sidekickHost = baseURl + '?token=' + token;

  Auth.currentAuthenticatedUser().then((user) => {
    setToken(user.signInUserSession.idToken.jwtToken);
  });

  if (token) {
    return (
      <iframe
        title='Cognota Sidekick'
        src={sidekickHost}
        className='w-full h-full'
      />
    );
  } else {
    return <></>;
  }
};

const Sidekick = () => {
  const { sidekickGa, sidekickEarlyAccess } = useFlags();
  const dispatch = useDispatch();

  let baseUrl = '';
  let tagLabel = '';
  if (sidekickGa || sidekickEarlyAccess) {
    baseUrl = config.get('betaSidekickURL');
    tagLabel = intl.get('BETA');
  }

  return (
    <>
      <div className='top-0 left-0 absolute w-screen h-screen z-50 flex'>
        <div
          className='w-1/3 h-screen bg-neutral-black opacity-30'
          onClick={() => {
            dispatch(closeSidekick());
          }}
        />

        <div className='flex flex-col w-2/3 h-screen bg-neutral-white'>
          <Header tagLabel={tagLabel} />
          <Body baseURl={baseUrl} />
        </div>
      </div>
    </>
  );
};

export default Sidekick;
