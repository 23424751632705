import { useEffect, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import { useParams, useHistory, Prompt } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isEmpty from 'lodash/isEmpty';
import { Button, Typography } from '@getsynapse/design-system';
import { trackEvent } from 'Services/pendo';
import { RootState } from 'state/store';
import { Program } from 'utils/customTypes';
import {
  ProgramCategory,
  Objective,
  MeasurementScale,
  MonetaryBenefitsType,
} from 'utils/types/program';
import { PENDO_EVENTS, SLICE_STATUS } from 'utils/constants';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { getProgramData, fetchProgram } from 'state/Program/programSlice';
import {
  getCategory,
  selectCategory,
  selectCategoryStatus,
  selectObjectiveAndIndex,
  updateObjective,
} from 'state/StrategyCategory/strategyCategorySlice';
import {
  fetchObjectiveMeasurement,
  selectObjectiveMeasurement,
  createObjectiveMeasurement,
  updateObjectiveMeasurement,
  resetObjectiveMeasurement,
  selectObjectiveMeasurementStatus,
} from 'state/ObjectiveMeasurementSlice/objectiveMeasurementSlice';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import ObjectiveTopBar from './components/ObjectiveTopBar';
import LegacyObjectiveData from './legacyComponents/ObjectiveData';
import ObjectiveData from './components/ObjectiveData';
import commingSoon from 'assets/images/cogno-rocket.svg';
import PageFooter from 'Molecules/PageFooter/PageFooter';
import ObjectiveMeasurement from './components/ObjectiveMeasurement/ObjectiveMeasurement';
import {
  fetchMeasurementScales,
  selectMeasurementScalesStatus,
} from 'state/MeasurementScales/measurementScalesSlice';
import Divider from 'Atoms/Divider';
import MonetryBenefits from './components/MonetaryBenefits/MonetaryBenefits';
import { STRATEGY_CATEGORIES } from 'utils/constants/program';
import {
  fetchMonetaryBenefits,
  resetMonetaryBenefits,
  selectMonetaryBenefits,
  createMonetaryBenefits,
  updateMonetaryBenefits,
  selectMonetaryBenefitsStatus,
} from 'state/MonetaryBenefits/monetaryBenefitsSlice';
import isNumber from 'lodash/isNumber';
import UnsavedChangesModal from 'Organisms/UnsavedChangesModal/UnsavedChangesModal';
import { Location } from 'history';
import ObjectiveSkeletonLoader from './components/ObjectiveSkeletonLoader';

const CategoryObjectivePage = () => {
  const { programId, categoryId, objectiveId } = useParams<{
    programId: string;
    categoryId: string;
    objectiveId: string;
  }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    enableProgramStrategyMeasurement = false,
    enableProgramStrategyBeta121 = false,
  } = useFlags();
  const program: Program = useSelector(getProgramData);
  const category: ProgramCategory = useSelector(selectCategory);
  const categoryStatus = useSelector(selectCategoryStatus);
  const { objective } = useSelector((state: RootState) =>
    selectObjectiveAndIndex(state, objectiveId)
  );

  const objectiveMeasurement = useSelector(selectObjectiveMeasurement);
  const objectiveMeasurementStatus = useSelector(
    selectObjectiveMeasurementStatus
  );
  const monetaryBenefits = useSelector(selectMonetaryBenefits);
  const monetaryBenefitsStatus = useSelector(selectMonetaryBenefitsStatus);
  const measurementScalesStatus = useSelector(selectMeasurementScalesStatus);

  const [objectiveData, setObjectiveData] = useState<Objective>();
  const [measurementData, setMeasurementData] = useState<{
    objectiveMeasurementScaleId: string;
    score: number | null;
  }>();
  const [monetaryBenefitsData, setMonetaryBenefitsData] =
    useState<MonetaryBenefitsType>({});
  const [confirmedNavigation, setConfirmedNavigation] =
    useState<boolean>(false);
  const [isLeavingWarningModalOpen, setIsLeavingWarningModalOpen] =
    useState<boolean>(false);
  const [leavingToLocation, setLeavingToLocation] = useState<string>('');
  const [areNumericFieldsValid, setAreNumericFieldsValid] =
    useState<boolean>(true);
  const [isMeasurementScaleValid, setIsMeasurementScaleValid] = useState(true);

  const isImapctCategory = category?.name === STRATEGY_CATEGORIES.IMPACT;

  const isDataLoading =
    categoryStatus === SLICE_STATUS.LOADING ||
    monetaryBenefitsStatus === SLICE_STATUS.LOADING ||
    objectiveMeasurementStatus === SLICE_STATUS.LOADING ||
    measurementScalesStatus === SLICE_STATUS.LOADING;

  useEffect(() => {
    if (confirmedNavigation && leavingToLocation) {
      history.push(leavingToLocation);
      setConfirmedNavigation(false);
      setLeavingToLocation('');
    }
  }, [confirmedNavigation, history, leavingToLocation]);

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        dispatch(fetchProgram(programId)),
        dispatch(getCategory({ programId, categoryId })),
        dispatch(fetchMeasurementScales()),
        dispatch(
          fetchObjectiveMeasurement({ programId, categoryId, objectiveId })
        ),
        isImapctCategory &&
          dispatch(
            fetchMonetaryBenefits({ programId, categoryId, objectiveId })
          ),
      ]);
    };
    init();

    return () => {
      dispatch(resetObjectiveMeasurement());
      if (isImapctCategory) {
        dispatch(resetMonetaryBenefits());
      }
    };
  }, [categoryId, dispatch, objectiveId, programId, isImapctCategory]);

  useEffect(() => {
    if (objective) {
      setObjectiveData(objective);
    }
  }, [objective]);

  useEffect(() => {
    if (monetaryBenefits) {
      setMonetaryBenefitsData(monetaryBenefits);
    }
  }, [monetaryBenefits]);

  const onChangeMeasurement = (
    scale: MeasurementScale,
    score: number | null
  ) => {
    setMeasurementData({
      objectiveMeasurementScaleId: scale.id,
      score,
    });
  };

  const hasChangedObjective: boolean = useMemo(
    () =>
      !isEmpty(objectiveData) &&
      (objectiveData?.name !== objective?.name ||
        objectiveData?.isMet !== objective?.isMet ||
        objectiveData?.notMetReason !== objective?.notMetReason),
    [objective?.isMet, objective?.name, objective?.notMetReason, objectiveData]
  );

  const hasChangedMeasurement: boolean = useMemo(
    () =>
      !isEmpty(measurementData) &&
      (measurementData!.objectiveMeasurementScaleId !==
        objectiveMeasurement?.objectiveMeasurementScaleId ||
        ![
          objectiveMeasurement?.percentageScore,
          objectiveMeasurement?.fivePointScore,
          objectiveMeasurement?.tenPointScore,
        ].includes(measurementData!.score)),
    [
      objectiveMeasurement?.fivePointScore,
      objectiveMeasurement?.objectiveMeasurementScaleId,
      objectiveMeasurement?.percentageScore,
      objectiveMeasurement?.tenPointScore,
      measurementData,
    ]
  );

  const hasMonetaryBenefitsChanged: boolean = useMemo(() => {
    if (isImapctCategory) {
      if (
        isEmpty(monetaryBenefits) &&
        Object.values(monetaryBenefitsData).every((prop) => prop === null)
      ) {
        return false;
      }
      return (
        !isEmpty(monetaryBenefitsData) &&
        (monetaryBenefitsData?.unitOfMeasure !==
          monetaryBenefits?.unitOfMeasure ||
          monetaryBenefitsData?.valueOfOneUnitOfMeasure !==
            monetaryBenefits?.valueOfOneUnitOfMeasure ||
          monetaryBenefitsData?.changeInPerformancePerMonth !==
            monetaryBenefits?.changeInPerformancePerMonth)
      );
    }
    return false;
  }, [monetaryBenefitsData, monetaryBenefits, isImapctCategory]);

  const canSave =
    objectiveData?.name !== '' &&
    (hasChangedObjective ||
      hasChangedMeasurement ||
      hasMonetaryBenefitsChanged);

  const onCancel = () => {
    history.goBack();
  };

  const onSave = async () => {
    if (canSave) {
      try {
        const changes = [];
        if (hasChangedObjective) {
          const hasNameChanged = objectiveData?.name !== objective?.name;
          const hasMetChanged = objectiveData?.isMet !== objective?.isMet;
          const hasNotMetReasonChanged =
            objectiveData?.notMetReason !== objective?.notMetReason;
          let notMetReason = hasNotMetReasonChanged
            ? objectiveData?.notMetReason
            : undefined;
          if (hasMetChanged) {
            notMetReason =
              hasMetChanged && objectiveData?.isMet ? '' : notMetReason;
          }
          changes.push(
            dispatch(
              updateObjective({
                programId,
                objectiveId,
                name: hasNameChanged ? objectiveData?.name : undefined,
                is_met: hasMetChanged ? objectiveData?.isMet : undefined,
                not_met_reason: notMetReason,
              })
            )
          );
        }
        if (hasChangedMeasurement) {
          if (measurementData) {
            if (objectiveMeasurement) {
              changes.push(
                dispatch(
                  updateObjectiveMeasurement({
                    programId,
                    categoryId,
                    objectiveId,
                    measurementId: objectiveMeasurement.id,
                    objectiveMeasurement: measurementData,
                  })
                )
              );
            } else {
              changes.push(
                dispatch(
                  createObjectiveMeasurement({
                    programId,
                    categoryId,
                    objectiveId,
                    objectiveMeasurement: measurementData,
                  })
                )
              );
            }
          }
        }
        if (hasMonetaryBenefitsChanged) {
          const monetaryBenefitsPayload = {
            unitOfMeasure: monetaryBenefitsData?.unitOfMeasure
              ? monetaryBenefitsData.unitOfMeasure
              : null,
            valueOfOneUnitOfMeasure: isNumber(
              monetaryBenefitsData?.valueOfOneUnitOfMeasure
            )
              ? monetaryBenefitsData?.valueOfOneUnitOfMeasure
              : null,
            changeInPerformancePerMonth: isNumber(
              monetaryBenefitsData?.changeInPerformancePerMonth
            )
              ? monetaryBenefitsData?.changeInPerformancePerMonth
              : null,
          };

          if (!isEmpty(monetaryBenefits)) {
            changes.push(
              dispatch(
                updateMonetaryBenefits({
                  programId,
                  categoryId,
                  objectiveId,
                  monetaryBenefitsId: monetaryBenefits?.id!,
                  monetaryBenefits: monetaryBenefitsPayload,
                })
              )
            );
          } else {
            changes.push(
              dispatch(
                createMonetaryBenefits({
                  programId,
                  categoryId,
                  objectiveId,
                  monetaryBenefits: monetaryBenefitsPayload,
                })
              )
            );
          }
        }
        await Promise.all(changes);

        dispatch(
          showSnackbarNotification({
            autoHide: false,
            notificationVariant: 'success',
            notificationTitle: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.SUCCESS_TITLE'
            ),
          })
        );
        trackEvent(PENDO_EVENTS.SAVE_OBJECTIVE, {
          id: objectiveData?.id,
          name: objectiveData?.name,
          is_met: objectiveData?.isMet,
        });

        history.goBack();
      } catch (err) {
        dispatch(
          showSnackbarNotification({
            autoHide: false,
            notificationVariant: 'error',
            notificationTitle: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.UPDATE_ERROR_TITLE'
            ),
            notificationMessage: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.ERROR.MESSAGE'
            ),
          })
        );
      }
    }
  };

  const handleBlockedNavigation = (location: Location) => {
    if (!confirmedNavigation && objective) {
      setLeavingToLocation(`${location.pathname}${location.search}`);
      setIsLeavingWarningModalOpen(true);
      return false;
    }
    return true;
  };

  return (
    <div className='h-full flex flex-col'>
      <Prompt when={canSave} message={handleBlockedNavigation} />
      <UnsavedChangesModal
        isOpen={isLeavingWarningModalOpen}
        setIsOpen={setIsLeavingWarningModalOpen}
        onConfirm={() => setConfirmedNavigation(true)}
      />
      {isDataLoading && <ObjectiveSkeletonLoader />}
      {program.id && category.id && !isDataLoading && (
        <>
          <PageTitle
            className='w-full'
            titleComponent={intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.CATEGORY_TITLE',
              {
                program: program.title,
                category: category.name,
              }
            )}
          />
          <main
            id='objective-details-container'
            className='mb-6 mx-6 rounded-lg border border-neutral-lighter-two flex-grow'
          >
            <ObjectiveTopBar
              categoryName={category?.name}
              objectiveResult={objectiveData?.isMet}
              objectiveId={objective?.id}
            />
            {enableProgramStrategyMeasurement && objective && (
              <div className='overflow-y-auto max-h-details-with-bar pb-10'>
                <ObjectiveData
                  objective={objective}
                  setObjectiveData={setObjectiveData}
                />
                <Divider className='mt-0 mb-8' />
                {isImapctCategory && enableProgramStrategyBeta121 && (
                  <>
                    <MonetryBenefits
                      monetaryBenefitsData={monetaryBenefitsData!}
                      setMonetaryBenefitsData={setMonetaryBenefitsData}
                      setAreNumericFieldsValid={setAreNumericFieldsValid}
                    />
                    <Divider className='my-8' />
                  </>
                )}

                <ObjectiveMeasurement
                  objective={objective}
                  setObjectiveData={setObjectiveData}
                  objectiveMeasurement={objectiveMeasurement}
                  onChangeMeasurement={onChangeMeasurement}
                  hideMeasurementScale={isImapctCategory}
                  setIsMeasurementScaleValid={setIsMeasurementScaleValid}
                />
              </div>
            )}
            {!enableProgramStrategyMeasurement && (
              <>
                <LegacyObjectiveData
                  objective={objective}
                  setObjectiveData={setObjectiveData}
                />
                <div className='h-99 flex justify-center items-center'>
                  <img src={commingSoon} alt='rocket taking off' />
                  <div className='pl-8'>
                    <Typography variant='h5'>
                      {intl.get(
                        'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.EVALUATION_DETAILS'
                      )}
                    </Typography>
                    <Typography variant='body' weight='medium'>
                      {intl.get(
                        'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.COMMING_SOON'
                      )}
                    </Typography>
                  </div>
                </div>
              </>
            )}
          </main>
          <PageFooter>
            <Button
              variant='tertiary'
              onClick={onCancel}
              data-testid='category-objective_cancel'
            >
              {intl.get('CANCEL')}
            </Button>
            <Button
              data-testid='category-objective_save'
              onClick={onSave}
              disabled={
                !canSave || !areNumericFieldsValid || !isMeasurementScaleValid
              }
            >
              {intl.get('SAVE')}
            </Button>
          </PageFooter>
        </>
      )}
    </div>
  );
};

export default CategoryObjectivePage;
