import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  PATHS,
  SETTINGS_ATTRIBUTES,
  SETTINGS_SECTIONS,
  SETTINGS_TABS,
  UPDATE_PROJECT_TABS,
} from 'utils/constants';
import { fetchProgram, getProgramTitle } from 'state/Program/programSlice';
import { selectRequestsPortalName } from 'state/Organization/organizationSlice';

interface ProjectPageParams {
  projectId: string;
}

interface TaskPageParams extends ProjectPageParams {
  taskId: string;
}

interface ProgramPageParams {
  programId: string;
  activeTab?: string;
}

interface CategoryPageParams extends ProgramPageParams {
  categoryId: string;
}

interface CategoryObjectivePageParams extends CategoryPageParams {
  objectiveId: string;
}

interface AdditionalBenefitPageParams extends CategoryPageParams {
  benefitId: string;
}

interface TeamPageParams {
  teamId: string;
}

interface EditTaskBundlePageParams {
  taskBundleId: string;
}

interface TemplatePageParams {
  templateId: string;
}

type BackPage =
  | {
      url: string;
      name: string;
    }
  | undefined;

const useNavigation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { enableProgramStrategy = false } = useFlags();

  const fromUrl: string = get(location, 'state.from', '');

  const categoryObjectivePage = useRouteMatch<CategoryObjectivePageParams>({
    path: `${PATHS.PROGRAM_PAGE}/:programId/:activeTab?/:categoryId${PATHS.CATEGORY_OBJECTIVE_PAGE}/:objectiveId`,
  });
  const additionalBenefitPage = useRouteMatch<AdditionalBenefitPageParams>({
    path: `${PATHS.PROGRAM_PAGE}/:programId${PATHS.STRATEGY_PAGE}/:categoryId${PATHS.ADDITIONAL_BENEFIT_PAGE}/:benefitId`,
  });
  const categoryPage = useRouteMatch<CategoryPageParams>({
    path: `${PATHS.PROGRAM_PAGE}/:programId/:activeTab?/:categoryId`,
    exact: true,
  });
  const customFieldPage = useRouteMatch(
    `${PATHS.SETTINGS}${PATHS.CUSTOM_FIELDS_PAGE}`
  );
  const editBusinessTeamPage = useRouteMatch<TeamPageParams>(
    `${PATHS.EDIT_TEAM}/business/:teamId`
  );
  const editLearningTeamPage = useRouteMatch<TeamPageParams>(
    `${PATHS.EDIT_TEAM}/ld/:teamId`
  );
  const editTaskBundlePage = useRouteMatch<EditTaskBundlePageParams>(
    PATHS.EDIT_TASK_BUNDLE_PAGE
  );
  const formPage = useRouteMatch(`${PATHS.SETTINGS}${PATHS.FORM_PAGE}`);
  const newCustomFieldPage = useRouteMatch(PATHS.NEW_CUSTOM_FIELD_PAGE);
  const newProjectPage = useRouteMatch<TemplatePageParams>(
    `${PATHS.NEW_PROJECT_PAGE}/:templateId`
  );
  const newTaskBundlePage = useRouteMatch(PATHS.NEW_TASK_BUNDLE_PAGE);
  const programPage = useRouteMatch<ProgramPageParams>({
    path: enableProgramStrategy
      ? `${PATHS.PROGRAM_PAGE}/:programId/:activeTab?`
      : `${PATHS.PROGRAM_PAGE}/:programId`,
    exact: true,
  });
  const projectPage = useRouteMatch<ProjectPageParams>(
    `${PATHS.PROJECT_PAGE}/:projectId`
  );
  const requestPage = useRouteMatch(PATHS.REQUEST_PAGE);
  const singleTaskPage = useRouteMatch<TaskPageParams>(
    `${PATHS.PROJECT_PAGE}/:projectId/tasks/:taskId`
  );
  const processPage = useRouteMatch(`${PATHS.PROCESS_PAGE}/:processId`);
  const templatePage = useRouteMatch<TemplatePageParams>(
    `${PATHS.TEMPLATE_PAGE}/:templateId`
  );
  const userPage = useRouteMatch(PATHS.USER_PAGE);
  const vendorPage = useRouteMatch(PATHS.VENDOR_PAGE);
  const roiObjectivePage = useRouteMatch<CategoryObjectivePageParams>({
    path: `${PATHS.PROGRAM_PAGE}/:programId${PATHS.ROI_CATEGORY_PAGE}/:categoryId${PATHS.ROI_OBJECTIVE_PAGE}/:objectiveId`,
  });

  const roiCostsPage = useRouteMatch<CategoryPageParams>({
    path: `${PATHS.PROGRAM_PAGE}/:programId${PATHS.ROI_CATEGORY_PAGE}/:categoryId${PATHS.ROI_COSTS_PAGE}`,
  });

  const programTitle = useSelector(getProgramTitle);
  const portalName = useSelector(selectRequestsPortalName);

  useEffect(() => {
    const programId = fromUrl.split('/')[2];

    if (
      projectPage?.isExact &&
      fromUrl.includes(`${PATHS.PROGRAM_PAGE}/`) &&
      !isEmpty(programId)
    ) {
      dispatch(fetchProgram(programId));
    }
  }, [dispatch, fromUrl, projectPage?.isExact]);

  const backPage = useMemo<BackPage>(() => {
    if (projectPage || newProjectPage) {
      if (
        (singleTaskPage?.isExact || projectPage?.isExact) &&
        fromUrl?.includes(PATHS.TASKS_LIST_PAGE)
      ) {
        return {
          url: `${PATHS.TASKS_LIST_PAGE}`,
          name: intl.get('TASKS_LIST_PAGE.BACK_LINK'),
        };
      } else if (singleTaskPage && singleTaskPage.isExact) {
        return {
          url: `${PATHS.PROJECT_PAGE}/${singleTaskPage.params.projectId}?tab=${UPDATE_PROJECT_TABS.TASKS}`,
          name: intl.get('TASKS.TASK_DETAIL_PAGE.SINGULAR_TITLE'),
        };
      } else if (fromUrl && fromUrl.includes(PATHS.PROGRAM_PAGE)) {
        return {
          url: fromUrl,
          name: programTitle,
        };
      } else if (fromUrl && fromUrl === PATHS.TEAMS) {
        return {
          url: PATHS.TEAMS,
          name: intl.get('TEAMS.CAPACITY'),
        };
      } else {
        return {
          url: PATHS.PROJECTS_LIST_PAGE,
          name: intl.get('NEW_PROJECT_PAGE.ALL_PROJECTS_LINK'),
        };
      }
    } else if (requestPage) {
      return {
        url: PATHS.REQUESTS_LIST_PAGE,
        name: portalName,
      };
    } else if (editLearningTeamPage) {
      return {
        url: `${PATHS.SETTINGS}/${SETTINGS_TABS.TEAMS}?section=${SETTINGS_SECTIONS.LD_TEAM}`,
        name: intl.get('ENTITIES.LEARNING_TEAM', { num: 2 }),
      };
    } else if (editBusinessTeamPage) {
      return {
        url: `${PATHS.SETTINGS}/${SETTINGS_TABS.TEAMS}?section=${SETTINGS_SECTIONS.BUSINESS_TEAM}`,
        name: intl.get('ENTITIES.BUSINESS_TEAM', { num: 2 }),
      };
    } else if (vendorPage) {
      return {
        url: `${PATHS.SETTINGS}/${SETTINGS_TABS.TEAMS}?section=${SETTINGS_SECTIONS.VENDORS}`,
        name: intl.get('ENTITIES.VENDOR', { num: 2 }),
      };
    } else if (userPage) {
      return {
        url: `${PATHS.SETTINGS}/${SETTINGS_TABS.USERS}`,
        name: intl.get('ENTITIES.USER', { num: 2 }),
      };
    } else if (formPage) {
      return {
        url: `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.INTAKE}&attribute=${SETTINGS_ATTRIBUTES.REQUEST_FORM}`,
        name: intl.get('ENTITIES.REQUEST_FORM', { num: 2 }),
      };
    } else if (customFieldPage || newCustomFieldPage) {
      return {
        url: `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.FIELDS}`,
        name: intl.get('ENTITIES.FIELDS', { num: 2 }),
      };
    } else if (templatePage) {
      return {
        url: `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.PROJECTS}&attribute=${SETTINGS_ATTRIBUTES.TEMPLATES}`,
        name: intl.get('ENTITIES.TEMPLATE', { num: 2 }),
      };
    } else if (processPage) {
      return {
        url: `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.PROJECTS}&attribute=${SETTINGS_ATTRIBUTES.PROCESS}`,
        name: intl.get('ENTITIES.PROCESS', { num: 2 }),
      };
    } else if (programPage) {
      return {
        url: PATHS.PROGRAMS_LIST_PAGE,
        name: intl.get('ENTITIES.PROGRAMS'),
      };
    } else if (newTaskBundlePage || editTaskBundlePage) {
      return {
        url: `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.PROJECTS}&attribute=${SETTINGS_ATTRIBUTES.TASK_BUNDLES}`,
        name: intl.get('ENTITIES.TASK_BUNDLE', { num: 2 }),
      };
    } else if (enableProgramStrategy && categoryPage) {
      return {
        url: `${PATHS.PROGRAM_PAGE}/${get(categoryPage, 'params.programId')}${
          PATHS.STRATEGY_PAGE
        }`,
        name: intl.get('PROGRAM_PAGE.STRATEGY_PAGE.PROGRAM_STRATEGY'),
      };
    } else if (enableProgramStrategy && categoryObjectivePage) {
      const { programId, categoryId } = categoryObjectivePage.params;
      return {
        url: `${PATHS.PROGRAM_PAGE}/${programId}${PATHS.STRATEGY_PAGE}/${categoryId}`,
        name: intl.get('PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.PROGRAM_CATEGORY'),
      };
    } else if (enableProgramStrategy && additionalBenefitPage) {
      const { programId, categoryId } = additionalBenefitPage.params;
      return {
        url: `${PATHS.PROGRAM_PAGE}/${programId}${PATHS.STRATEGY_PAGE}/${categoryId}`,
        name: intl.get('PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.PROGRAM_CATEGORY'),
      };
    } else if (roiObjectivePage) {
      const { programId, categoryId } = roiObjectivePage?.params!;
      return {
        url: `${PATHS.PROGRAM_PAGE}/${programId}${PATHS.ROI_CATEGORY_PAGE}/${categoryId}`,
        name: intl.get('PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.PROGRAM_CATEGORY'),
      };
    } else if (roiCostsPage) {
      const { programId, categoryId } = roiCostsPage?.params!;
      return {
        url: `${PATHS.PROGRAM_PAGE}/${programId}${PATHS.ROI_CATEGORY_PAGE}/${categoryId}`,
        name: intl.get('PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.PROGRAM_CATEGORY'),
      };
    }
  }, [
    projectPage,
    newProjectPage,
    requestPage,
    editLearningTeamPage,
    editBusinessTeamPage,
    vendorPage,
    userPage,
    formPage,
    customFieldPage,
    newCustomFieldPage,
    templatePage,
    programPage,
    newTaskBundlePage,
    editTaskBundlePage,
    enableProgramStrategy,
    categoryPage,
    categoryObjectivePage,
    singleTaskPage,
    fromUrl,
    programTitle,
    portalName,
    additionalBenefitPage,
    roiObjectivePage,
    roiCostsPage,
    processPage,
  ]);

  const goToBackPage: () => void = useCallback(() => {
    if (backPage?.url) {
      if (fromUrl) {
        history.push(backPage.url, {
          from: fromUrl,
        });
      } else {
        history.push(backPage.url);
      }
    }
  }, [backPage?.url, fromUrl, history]);

  return {
    backPage,
    goToBackPage,
  };
};

export default useNavigation;
