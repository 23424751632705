const skeletonDiv = (index: number) => (
  <div className='flex mb-4' key={`resources-utilization-skeleton-${index}`}>
    <div className='w-full bg-neutral-lighter mr-9 h-3' />
    <div className='w-full bg-neutral-lighter h-7' />
  </div>
);

const ChartSkeletonLoader = () => (
  <>
    <div className='bg-neutral-lighter animate-pulse w-40 h-4 mb-2'></div>
    <div className='bg-neutral-lighter animate-pulse w-40 h-4 mb-8'></div>
    {Array(8)
      .fill('')
      .map((_, index) => skeletonDiv(index))}
  </>
);

export default ChartSkeletonLoader;
