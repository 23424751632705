import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import intl from 'react-intl-universal';
import { isEmpty, get } from 'lodash';
import { Button, Typography } from '@getsynapse/design-system';
import { Program } from 'utils/customTypes';
import { PENDO_EVENTS, SLICE_STATUS, ROI_METHODOLOGIES } from 'utils/constants';
import StrategyExamplesCard from 'Organisms/StrategyExamplesPanel/StrategyExamplesCard';
import { STRATEGY_CATEGORIES } from 'utils/constants/program';
import { trackEvent } from 'Services/pendo';
import { getProgramData } from 'state/Program/programSlice';
import {
  getCategory,
  selectCategory,
  selectCategoryStatus,
  resetProgramCategory,
  getEvaluationPlan,
  getCollectionMethodsAndOptions,
  getCategoryObjectiveTemplates,
} from 'state/StrategyCategory/strategyCategorySlice';
import CategoryEmptyState from 'Pages/StrategyCategoryPage/components/CategoryEmptyState';
import AdditionalBenefits from 'Pages/StrategyCategoryPage/components/AdditionalBenefits/AdditionalBenefits';
import CategorySkeleton from './components/CategorySkeleton';
import CategoryObjectiveCard from './components/CategoryObjectiveCard';
import Evaluation from './components/Evaluation/Evaluation';
import AddCategoryObjectiveModal from './components/AddCategoryObjectiveModal';
import Evidence from './components/Evidence/Evidence';
import AddAdditionalBenefitModal from './components/AddAdditionalBenefitModal/AddAdditionalBenefitModal';
import MonetaryBenefitsTotalCard from './components/MonetaryBenefitsTotalCard/MonetaryBenefitsTotalCard';
import StrategyCategoryTemplate from 'Templates/StrategyCategoryTemplate/StrategyCategoryTemplate';

const StrategyCategoryPage = () => {
  const { programId, categoryId } = useParams<{
    programId: string;
    categoryId: string;
  }>();
  const dispatch = useDispatch();
  const {
    enableEvaluationPlanAndEvidence = false,
    enableProgramStrategyBeta122 = false,
  } = useFlags();

  const program: Program = useSelector(getProgramData);
  const currentCategory = useSelector(selectCategory);
  const categoryStatus = useSelector(selectCategoryStatus);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAdditionalBenefitModalOpen, setIsAdditionalBenefitModalOpen] =
    useState(false);

  const annualTotal = useMemo(() => {
    let benefitVal = currentCategory?.objectives.reduce((acc, objective) => {
      return acc + (objective.annualBenefit || 0);
    }, 0);
    if (currentCategory?.additionalBenefits) {
      benefitVal += currentCategory?.additionalBenefits.reduce(
        (acc, benefit) => {
          return (
            acc +
            (benefit.isMonetary && benefit.annualBenefit
              ? benefit.annualBenefit
              : 0)
          );
        },
        0
      );
    }
    return benefitVal;
  }, [currentCategory?.objectives, currentCategory?.additionalBenefits]);

  const isImapctCategory = currentCategory?.name === STRATEGY_CATEGORIES.IMPACT;

  useEffect(() => {
    if (programId && categoryId) {
      dispatch(getCategory({ programId, categoryId }));
      if (!isEmpty(currentCategory?.name)) {
        batch(() => {
          dispatch(getEvaluationPlan({ programId, categoryId }));
          dispatch(getCollectionMethodsAndOptions({ categoryId }));
        });
      }
    }
  }, [currentCategory?.name, categoryId, dispatch, programId]);

  useEffect(() => {
    if (!isEmpty(currentCategory?.name)) {
      dispatch(
        getCategoryObjectiveTemplates({
          programId,
          categoryId: currentCategory.id,
        })
      );
    }
  }, [currentCategory?.name, currentCategory?.id, dispatch, programId]);

  useEffect(() => {
    return () => {
      dispatch(resetProgramCategory());
    };
  }, [dispatch]);

  const onOpenCreateObjectivesModal = () => {
    setIsModalOpen(true);
    trackEvent(PENDO_EVENTS.CTA_OPEN_CREATE_OBJECTIVE_MODAL, {
      category: currentCategory?.name,
    });
  };

  const hasObjectives = currentCategory?.objectives.length > 0;

  const addAdditionalBenefits = () => {
    setIsAdditionalBenefitModalOpen(true);
    trackEvent(PENDO_EVENTS.CTA_OPEN_ADDITIONAL_BENEFIT_MODAL, {
      category: currentCategory?.name,
    });
  };

  const shouldShowMonetaryBenefitsTotalCard =
    currentCategory?.objectives?.length > 0 ||
    (currentCategory?.additionalBenefits?.length ?? 0) > 0;

  return (
    <StrategyCategoryTemplate
      categoryName={currentCategory?.name}
      loadingComponent={<CategorySkeleton />}
      categoryId={categoryId}
      isLoading={categoryStatus === SLICE_STATUS.LOADING}
    >
      <AddCategoryObjectiveModal
        setIsOpen={setIsModalOpen}
        isOpen={isModalOpen}
        category={currentCategory}
        programId={programId}
        programDescription={program.description!}
      />
      <AddAdditionalBenefitModal
        setIsOpen={setIsAdditionalBenefitModalOpen}
        isOpen={isAdditionalBenefitModalOpen}
        category={currentCategory}
        programId={programId}
        programDescription={program.description!}
      />
      <div className='w-full flex px-6 gap-8 border-b border-neutral-lighter-two'>
        <div className='w-2/3 min-h-details-with-bar pb-10'>
          <Typography
            data-testid='category-objectives_section-title'
            variant='h5'
            className='pb-2 leading-7'
          >
            {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.OBJECTIVES_SETTING')}
          </Typography>

          <div className='flex w-full justify-between space-x-8 items-start'>
            <Typography
              className='text-neutral-darker'
              data-testid='category__instructions'
              variant='body2'
            >
              {currentCategory.explanation ||
                intl.get(
                  `PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.CATEGORY_INSTRUCTIONS.${currentCategory.name.toLocaleUpperCase()}`
                )}
            </Typography>
            <Button
              className='whitespace-nowrap'
              data-testid='category__add-objectives'
              onClick={onOpenCreateObjectivesModal}
            >
              {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.ADD_OBJECTIVE')}
            </Button>
          </div>

          {currentCategory?.objectives.length > 0 ? (
            <div
              className='pt-6 space-y-2 pb-10'
              data-testid='category-objectives_section'
            >
              {currentCategory?.objectives.map((objective) => (
                <CategoryObjectiveCard
                  key={objective.id}
                  objective={objective}
                />
              ))}
            </div>
          ) : (
            <CategoryEmptyState
              setIsOpen={setIsModalOpen}
              caption={intl.get(
                'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.GET_STARTED_ADD_OBJECTIVES'
              )}
            />
          )}

          {isImapctCategory && enableProgramStrategyBeta122 && (
            <AdditionalBenefits
              handleClick={addAdditionalBenefits}
              benefits={currentCategory.additionalBenefits || []}
            />
          )}
          {isImapctCategory && shouldShowMonetaryBenefitsTotalCard && (
            <MonetaryBenefitsTotalCard annualTotal={annualTotal} />
          )}
        </div>
        <StrategyExamplesCard
          className='w-90 h-filtered-stage min-h-142 flex-none ml-auto'
          programId={programId}
          links={get(
            ROI_METHODOLOGIES,
            currentCategory.name?.toUpperCase(),
            []
          )}
        />
      </div>
      {enableEvaluationPlanAndEvidence && (
        <div className='flex'>
          <Evaluation
            hasObjectives={hasObjectives}
            categoryName={currentCategory.name || ''}
          />
          <Evidence readOnly={!hasObjectives} />
        </div>
      )}
    </StrategyCategoryTemplate>
  );
};

export default StrategyCategoryPage;
