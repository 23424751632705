import React from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import get from 'lodash/get';
import {
  Typography,
  Button,
  tailwindOverride,
} from '@getsynapse/design-system';
import { selectUserId } from 'state/User/userSlice';
import { Owner, ProjectParticipantRole } from 'utils/customTypes';
import {
  PROJECT_PARTICIPANT_TYPE,
  RESOURCE_ALLOCATION_TABLE_SECTIONS,
} from 'utils/constants';
import { getProjectRole } from '../../../helpers';
import { useUserPermissionsContext } from 'Pages/ProjectPage/context/UserPermissionsContext';
import UserAvatar from 'Atoms/UserAvatar';
import ActionsMenu from './ActionsMenu';
import { isUserStatusDisabled } from 'utils/functions';

export const AllocatedUserDetails: React.FC<{
  user: Owner;
  hasAssignments?: boolean;
  sectionId: string;
  params: {
    toggleSection?: (event: React.MouseEvent<HTMLElement>) => void;
    isOpen?: boolean;
  };
}> = ({ user, hasAssignments = false, sectionId, params }) => {
  const currentUserId = useSelector(selectUserId);
  const { hasUserPermissionsLevelOf } = useUserPermissionsContext();
  const isMembersSection =
    sectionId === RESOURCE_ALLOCATION_TABLE_SECTIONS.MEMBERS;
  const displayActionsMenu =
    hasUserPermissionsLevelOf(PROJECT_PARTICIPANT_TYPE.OWNER) ||
    (isMembersSection &&
      hasUserPermissionsLevelOf(PROJECT_PARTICIPANT_TYPE.MEMBER) &&
      currentUserId === user.id);

  const getOwnerName = () => {
    const displayName = `${get(user, 'data.firstName', '')} ${get(
      user,
      'data.lastName',
      ''
    )}`;

    if (!displayName.trim()) {
      return get(user, 'data.email', '');
    }
    return displayName;
  };

  return (
    <div className='flex p-2' data-cy='participant-details'>
      <UserAvatar
        user={{
          avatar_url: user.avatar_url,
          data: {
            firstName: user.data?.firstName || '',
            lastName: user.data?.lastName || '',
            email: user.data?.email || '',
          },
          status: user.status,
        }}
      />
      <div className='flex flex-1 flex-col pl-2 relative'>
        <Typography
          variant='label'
          className={tailwindOverride('text-primary font-semibold', {
            'text-neutral-light': isUserStatusDisabled(user.status),
          })}
        >
          {getOwnerName()}
        </Typography>
        <Typography variant='label' className='text-neutral mb-2'>
          {user.data?.email}
        </Typography>
        <Button
          variant='tertiary'
          disabled={!hasAssignments}
          className={classnames('text-sm self-start px-1', {
            'text-neutral-light': !hasAssignments,
            'text-primary': hasAssignments,
          })}
          onClick={params?.toggleSection}
          size='small'
        >
          {params?.isOpen
            ? intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.HIDE_TASKS')
            : intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.VIEW_TASKS')}
        </Button>
        {displayActionsMenu && (
          <ActionsMenu user={user} sectionId={sectionId} />
        )}
      </div>
    </div>
  );
};

export const AllocatedUserRoles: React.FC<{
  roles: ProjectParticipantRole[];
}> = ({ roles }) => {
  return (
    <div className='flex flex-col h-full px-2' data-cy='participant-roles'>
      {roles.map(({ role }, index: number) => (
        <div className='flex items-start py-2' key={index}>
          <Typography variant='caption' className='text-primary h-6'>
            {getProjectRole(role)}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export const AllocatesUserHours: React.FC<{
  roles: ProjectParticipantRole[];
}> = ({ roles }) => {
  return (
    <div
      className='flex flex-col h-full px-2'
      data-cy='participant-total-allocation'
    >
      {roles.map((role: ProjectParticipantRole, index: number) => {
        let totalAllocation = 0;
        if (role.estimation) {
          totalAllocation = parseFloat(role.estimation.estimated_hours) || 0;
        } else if (role.allocations) {
          for (const allocation of role.allocations) {
            totalAllocation += allocation.allocatedHours;
          }
        }

        return (
          <div className='flex items-start py-2' key={index}>
            <Typography variant='caption' className='text-primary h-6'>
              {totalAllocation}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};
