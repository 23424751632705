import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  initWeeksAndDaysArray,
  setNumberOfDays,
} from 'state/DailyTeamsCapacity/Days/daysSlice';
import { resetDailyCapacityState } from 'state/DailyTeamsCapacity/TeamsCapacity/teamsCapacitySlice';
import {
  fetchTeamsList,
  selectTeamsListStateStatus,
} from 'state/Capacities/TeamsList/teamsListSlice';
import { SLICE_STATUS } from 'utils/constants';
import { Status } from 'utils/customTypes';
import useScreenSize from './hooks/useScreenSize/useScreenSize';
import useDailyCapacity from './hooks/useDailyCapacity/useDailyCapacity';
import useCapacityViewState from '../../hooks/useCapacityViewState/useCapacityViewState';
import {
  getNumberOfDaysBasedOnScreenSize,
  getStartAndEndDateRange,
} from './helpers/index';
import CapacityTable from '../CapacityTable/CapacityTable';
import SkeletonLoader from './components/SkeletonLoader/SkeletonLoader';

const DailyCapacity = () => {
  const dispatch = useDispatch();
  const previousStatus = useRef<Status>(SLICE_STATUS.IDLE);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const status = useSelector(selectTeamsListStateStatus);
  const { width: screenSize } = useScreenSize();
  const dailyCapacityCallbacks = useDailyCapacity();
  const capacityVieStartDate = useCapacityViewState('DAILY');
  const numberOfDays = getNumberOfDaysBasedOnScreenSize(screenSize);

  useEffect(() => {
    dispatch(fetchTeamsList());
    return () => {
      dispatch(resetDailyCapacityState());
    };
  }, [dispatch]);

  useEffect(() => {
    const dateRange = getStartAndEndDateRange(capacityVieStartDate);
    dispatch(
      initWeeksAndDaysArray({
        startDate: dateRange[0],
        endDate: dateRange[1],
      })
    );
  }, [dispatch, capacityVieStartDate]);

  useEffect(() => {
    dispatch(setNumberOfDays(numberOfDays));
  }, [numberOfDays, dispatch]);

  useEffect(() => {
    if (previousStatus.current !== status) {
      previousStatus.current = status;
      if (status === SLICE_STATUS.IDLE && isLoading) {
        setIsLoading(false);
      }
    }
  }, [status, isLoading]);

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return <CapacityTable scrollHandlerCallbacks={dailyCapacityCallbacks} />;
};

export default DailyCapacity;
