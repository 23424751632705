import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { moveProjectToAnotherStage } from 'state/Projects/projectsSlice';
import { ProcessStage, Project } from 'utils/customTypes';
import {
  NEW_AND_IN_QUEUE_PROJECTS_STAGE,
  PROJECT_STATUS,
} from 'utils/constants';
import Stage from './Stage';

const StagesList: React.FC<{
  stages: { newAndInQueueProjectsStage: Project[]; stagesList: ProcessStage[] };
  emptyComponent?: ReactNode;
  areFiltersApplied?: Boolean;
}> = ({ stages, emptyComponent, areFiltersApplied = false }) => {
  const { newAndInQueueProjectsStage, stagesList } = stages;
  const noResultsFound =
    newAndInQueueProjectsStage.length === 0 &&
    stagesList.every((stage: ProcessStage) => stage.cards.length === 0);
  const dispatch = useDispatch();

  const handleDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;
    if (
      destination?.droppableId &&
      destination.droppableId !== source.droppableId
    ) {
      const { droppableId: sourceStageId } = source;
      const { droppableId: destinationStageId } = destination;
      let newStageId: string | null = destinationStageId;
      let newStatus = null;
      if (destinationStageId === NEW_AND_IN_QUEUE_PROJECTS_STAGE.ID) {
        newStageId = null;
        newStatus = PROJECT_STATUS.IN_PLANNING;
      }
      if (sourceStageId === NEW_AND_IN_QUEUE_PROJECTS_STAGE.ID) {
        newStatus = PROJECT_STATUS.IN_PROGRESS;
      }
      dispatch(
        moveProjectToAnotherStage({
          projectId: draggableId,
          newStageId,
          newStatus,
        })
      );
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div
        id='stages-list'
        data-cy='stages-list'
        className={classnames('flex flex-grow overflow-x-auto relative', {
          'h-stage mt-8': !noResultsFound && !areFiltersApplied,
          'h-empty-table-body mt-8': noResultsFound,
          'h-filtered-stage mt-4': !noResultsFound && areFiltersApplied,
        })}
      >
        {noResultsFound && emptyComponent}

        <Stage
          id={NEW_AND_IN_QUEUE_PROJECTS_STAGE.ID}
          name={intl.get(
            'PROJECTS_LIST_PAGE.BOARD.NEW_AND_IN_QUEUE_PROJECTS_STAGE'
          )}
          color={NEW_AND_IN_QUEUE_PROJECTS_STAGE.BG_COLOR}
          textColor={NEW_AND_IN_QUEUE_PROJECTS_STAGE.TEXT_COLOR}
          colorOnDrag={NEW_AND_IN_QUEUE_PROJECTS_STAGE.ON_DRAG_COLOR}
          borderColorOnDrag={
            NEW_AND_IN_QUEUE_PROJECTS_STAGE.ON_DRAG_BORDER_COLOR
          }
          cards={newAndInQueueProjectsStage}
          isBoardEmpty={noResultsFound}
        />

        <div
          className={classnames(
            'w-px flex flex-shrink-0 bg-neutral-lighter-two ml-2 mr-6',
            {
              'h-stage': !noResultsFound && !areFiltersApplied,
              'h-empty-table-body': noResultsFound,
              'h-filtered-stage': !noResultsFound && areFiltersApplied,
            }
          )}
        />

        {stagesList.map((stage: ProcessStage) => (
          <Stage
            key={stage.id}
            id={stage.id}
            name={stage.name}
            color={stage.color}
            textColor={stage.textColor}
            colorOnDrag={stage.colorOnDrag}
            borderColorOnDrag={stage.borderColorOnDrag}
            cards={stage.cards}
            isBoardEmpty={noResultsFound}
          />
        ))}
      </div>
    </DragDropContext>
  );
};

export default StagesList;
