import { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get, capitalize } from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import {
  Table,
  AvatarGroup,
  Typography,
  Button,
  Tooltip,
  IconButton,
  tailwindOverride,
} from '@getsynapse/design-system';
import {
  displayNotification,
  setNotificationText,
  setNotificationTimeout,
  setNotificationVariant,
} from 'state/InlineNotification/inlineNotificationSlice';
import { deleteRequest } from 'state/Requests/requestSlice';
import {
  selectUserType,
  selectUser,
  selectOrganizationId,
} from 'state/User/userSlice';
import { PATHS, DATE, USER_TYPES, SIDE_PANEL_TYPES } from 'utils/constants';
import {
  AvatarUser,
  ModalProps,
  Owner,
  Request,
  TableExportOptions,
  RequestsTable as RequestsTableType,
} from 'utils/customTypes';
import { formatRequestIdentifier } from 'Pages/helpers';
import searchNoReturns from 'assets/images/no-requests-found.svg';
import filterNoResults from 'assets/images/empty-filtered-requests.svg';
import NoRequestsSvg from 'assets/icons/empty-requests.svg';
import RequestsTableHead from './RequestsTableHead';
import UserAvatar from 'Atoms/UserAvatar';
import RenderNoRecords from '../components/NoRecords';
import LinkedProjectsTooltip from './LinkedProjectsTooltip';
import { openPanel } from 'state/SidePanel/sidePanelSlice';
import { calculateRequestPermissions } from 'Pages/RequestPage/helpers/permissions';
import { isUserStatusDisabled } from 'utils/functions';
import { RequestStatus } from 'utils/types/request';
import RequestStatusTag from 'Atoms/RequestStatusTag/RequestStatusTag';

type RequestsTableProps = {
  requestsTable: RequestsTableType;
  data: Request[];
  totalRequests: number;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setModalProps: React.Dispatch<React.SetStateAction<ModalProps>>;
  setSelectedRequests: React.Dispatch<React.SetStateAction<string[]>>;
  selectedRequests: string[];
  handleSort: (orderByParam: string, order: 'desc' | 'asc') => void;
  isSearch?: boolean;
  areFiltersApplied?: boolean;
  onSelectExportOption: (exportOption: TableExportOptions | null) => void;
  showFilter: boolean;
};

const RequestsTable = ({
  requestsTable,
  data,
  setModalOpen,
  setModalProps,
  setSelectedRequests,
  selectedRequests,
  handleSort,
  isSearch = false,
  areFiltersApplied = false,
  totalRequests,
  onSelectExportOption,
  showFilter = false,
}: RequestsTableProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [requests, setRequests] = useState(data);
  const userType = useSelector(selectUserType);
  const currentUser = useSelector(selectUser);
  const isLDUser = userType === USER_TYPES.L_D;
  const organizationId = useSelector(selectOrganizationId);

  useEffect(() => setRequests(data), [data]);

  const openModal = (request: Request) => {
    setModalOpen(true);
    const props = {
      title: intl.get('REQUESTS_LIST_PAGE.MODAL.DELETE_REQUEST.TITLE'),
      children: intl.get('REQUESTS_LIST_PAGE.MODAL.DELETE_REQUEST.BODY', {
        requestNo: formatRequestIdentifier(request.requestIdentifier!),
      }),
      closeModal: () => setModalOpen(false),
      size: 'medium',
      actionButtons: [
        {
          children: intl.get(
            'REQUESTS_LIST_PAGE.MODAL.DELETE_REQUEST.DELETE_BUTTON'
          ),
          color: 'danger',
          onClick: () => deleteSelectedRequest(request),
        },
        {
          children: intl.get(
            'REQUESTS_LIST_PAGE.MODAL.DELETE_REQUEST.CANCEL_BUTTON'
          ),
          variant: 'tertiary',
          onClick: () => setModalOpen(false),
        },
      ],
    };
    setModalProps(props);
  };

  const deleteSelectedRequest = (request: Request) => {
    setModalOpen(false);
    dispatch(
      setNotificationText(
        intl.get('REQUESTS_LIST_PAGE.NOTIFICATIONS.DELETE_REQUEST', {
          requestNo: formatRequestIdentifier(request.requestIdentifier!),
        })
      )
    );
    dispatch(setNotificationTimeout(4000));
    dispatch(setNotificationVariant('success'));
    dispatch(displayNotification());
    if (request.id) {
      dispatch(deleteRequest(request.id));
    }
  };

  const isRequestTableEmpty = requests.length === 0;

  const areBasicAndLDDisabled = (request: Request) => {
    const { areBasicAndRequestDisabled, isLDDisabled } =
      calculateRequestPermissions(request, false, currentUser);
    return areBasicAndRequestDisabled && isLDDisabled;
  };

  return (
    <div
      className={classnames('w-full rounded-b border-t-0', {
        'max-h-small-table': !showFilter,
        'max-h-filtered-table': showFilter,
        'overflow-auto border border-neutral-lighter-two': !isRequestTableEmpty,
        'overflow-hidden': isRequestTableEmpty,
      })}
    >
      <Table
        className={classnames('relative w-full', {
          'max-w-full border-0 ': !isRequestTableEmpty,
        })}
        selectedRowsIds={selectedRequests}
        isSelectRowCellSticky={true}
        onSelectRows={(rowsIds: string[]) => setSelectedRequests(rowsIds)}
        data={{
          headData: {
            stickyHeader: true,
            onSelectExportOption,
            handleSort,
            headCells: RequestsTableHead({
              isLDUser,
              isRequestTableEmpty,
            }),
          },
          rows: requests.map((request: Request, index: number) => {
            const showViewIcon = areBasicAndLDDisabled(request);
            const submissonDate = request.submittedAt
              ? moment(request.submittedAt).format(DATE.SHORT_FORMAT)
              : intl.get('REQUESTS_LIST_PAGE.NO_DATE');
            const decisionDate = request.decision_date
              ? moment(request.decision_date).format(DATE.SHORT_FORMAT)
              : intl.get('REQUESTS_LIST_PAGE.NO_DATE');
            const showName = request.owners && request.owners.length > 1;
            const ownersAvatars = request.owners
              ? request.owners.map((owner: Owner) => {
                  const firstName = get(owner, 'data.firstName', ' ');
                  const lastName = get(owner, 'data.lastName', ' ');
                  return {
                    imageSrc: get(owner, 'avatar_url'),
                    initial: firstName?.charAt(0) + lastName?.charAt(0),
                    name: `${showName ? firstName + ' ' + lastName : ''}`,
                    disabled: isUserStatusDisabled(owner.status),
                  };
                })
              : [];
            const isOdd = index % 2;
            const isSelected = selectedRequests.includes(request.id!);
            const rowsClasses = classnames('border-transparent', {
              'sticky z-1': !isRequestTableEmpty,
              'bg-neutral-white': !isOdd && !isSelected,
              'bg-neutral-lightest-two': isOdd && !isSelected,
              'bg-secondary-lightest border-secondary-darker': isSelected,
            });
            const businessTeams = get(request, 'businessTeams') || [];
            const businessList = businessTeams
              .map((team: Request['businessTeam']) => team?.title)
              .join(', ');

            return {
              id: request.id,
              className: 'group cursor-pointer',
              onClick: () =>
                history.push(
                  `${PATHS.REQUEST_PAGE}/${request.id}?accountId=${organizationId}`
                ),
              cells: [
                {
                  content: (
                    <div
                      className={classnames({ 'w-32': !isRequestTableEmpty })}
                    >
                      {formatRequestIdentifier(request.requestIdentifier!)}
                    </div>
                  ),
                  className: classnames(rowsClasses, {
                    'w-32 left-14': !isRequestTableEmpty,
                  }),
                },
                {
                  content: (
                    <div
                      className={classnames('truncate', {
                        'w-64': !isRequestTableEmpty,
                      })}
                    >
                      <Link
                        to={`${PATHS.REQUEST_PAGE}/${request.id}`}
                        title={request.title}
                        data-cy={`request__title-${request.id}`}
                      >
                        {request.title}
                      </Link>
                    </div>
                  ),
                  className: classnames(rowsClasses, {
                    'w-64 left-54': !isRequestTableEmpty,
                  }),
                },
                {
                  content: (
                    <div
                      className={classnames({ 'w-36': !isRequestTableEmpty })}
                    >
                      <RequestStatusTag
                        status={request.status! as RequestStatus}
                      />
                    </div>
                  ),
                  className: classnames(rowsClasses, {
                    'w-36 left-126': !isRequestTableEmpty,
                  }),
                },
                {
                  content: request.requester && (
                    <div className='flex items-center'>
                      <UserAvatar user={request.requester as AvatarUser} />
                      <span
                        className={tailwindOverride('ml-2.5', {
                          'text-neutral-light': isUserStatusDisabled(
                            request.requester.status
                          ),
                        })}
                      >
                        {`${get(
                          request.requester,
                          'data.firstName',
                          '-'
                        )} ${get(request.requester, 'data.lastName')}`}
                      </span>
                    </div>
                  ),
                  className: `font-semibold`,
                },
                ...(isLDUser
                  ? [
                      {
                        content:
                          request.owners && request.owners.length === 1 ? (
                            <div className='flex items-center'>
                              <AvatarGroup
                                avatars={ownersAvatars}
                                deactivatedText={intl.get('DEACTIVATED')}
                              />
                              <span className='ml-2.5'>
                                {`${get(
                                  request,
                                  'owners[0].data.firstName',
                                  ''
                                )} ${get(
                                  request,
                                  'owners[0].data.lastName',
                                  ''
                                )}`}
                              </span>
                            </div>
                          ) : (
                            <AvatarGroup avatars={ownersAvatars} />
                          ),
                        className: `font-semibold`,
                      },
                    ]
                  : []),
                {
                  content:
                    request.requestProjects &&
                    request.requestProjects?.length! ? (
                      <LinkedProjectsTooltip
                        numberOfProjects={get(request, 'linkedProject')}
                        projects={request.requestProjects!}
                      />
                    ) : (
                      intl.get(
                        'REQUESTS_LIST_PAGE.TABLE.LINKED_PROJECTS.NO_LINKED_PROJETS'
                      )
                    ),
                },
                ...(isLDUser
                  ? [
                      {
                        content: (
                          <Typography
                            title={businessList}
                            variant='body2'
                            className='w-40 overflow-ellipsis overflow-hidden'
                          >
                            {businessList}
                          </Typography>
                        ),
                      },
                      {
                        content: capitalize(request.ldPriority),
                      },
                    ]
                  : []),
                {
                  content: (
                    <div className='w-44 truncate'>
                      {get(request, 'form.title')}
                    </div>
                  ),
                },
                {
                  content: moment(request.createdAt).format(DATE.SHORT_FORMAT),
                },
                {
                  content: submissonDate,
                },
                {
                  content: decisionDate,
                },
                {
                  content: moment(request.updatedAt).format(DATE.SHORT_FORMAT),
                },
                {
                  content: (
                    <div
                      className='opacity-0 group-hover:opacity-100 flex justify-center'
                      onClick={(event: React.MouseEvent<HTMLInputElement>) =>
                        event.stopPropagation()
                      }
                    >
                      {
                        <Tooltip
                          openMode='hover1'
                          timeout={0}
                          showPopper
                          position='topCenter'
                          contentProps={{
                            className: 'px-3 py-2 text-sm z-50',
                          }}
                          usePortal
                          trigger={
                            <IconButton
                              name={`${
                                showViewIcon ? 'eye' : 'pencil-outline'
                              }`}
                              iconClassname='pointer-events-none'
                              className='text-base text-neutral-dark p-1 hover:bg-neutral-lightest hover:shadow-allocation-table rounded'
                              data-cy={`quick-edit-button-${request.id}`}
                              onClick={() =>
                                dispatch(
                                  openPanel({
                                    resourceType: SIDE_PANEL_TYPES.REQUEST,
                                    resourceId: request.id!,
                                  })
                                )
                              }
                            />
                          }
                        >
                          {showViewIcon
                            ? intl.get('SIDE_PANEL.QUICK_VIEW')
                            : intl.get('SIDE_PANEL.QUICK_EDIT')}
                        </Tooltip>
                      }
                      {request.status === 'draft' && (
                        <Tooltip
                          openMode='hover1'
                          timeout={0}
                          showPopper
                          position='topCenter'
                          contentProps={{
                            className: 'px-3 py-2 text-sm z-50',
                          }}
                          usePortal
                          trigger={
                            <IconButton
                              name='trash'
                              iconClassname='pointer-events-none'
                              data-cy={`request-delete-button-${request.id}`}
                              className='text-base ml-2 text-neutral-dark p-1 hover:bg-neutral-lightest hover:shadow-allocation-table rounded'
                              onClick={() => openModal(request)}
                            />
                          }
                        >
                          {intl.get('REQUESTS_LIST_PAGE.TABLE.ACTIONS.DELETE')}
                        </Tooltip>
                      )}
                    </div>
                  ),
                  className: classnames(rowsClasses, {
                    'w-10 right-0 sticky': !isRequestTableEmpty,
                  }),
                },
              ],
            };
          }),
          total: totalRequests,
        }}
        data-cy={`${requestsTable}-table`}
        emptyComponent={
          !isSearch && !areFiltersApplied ? (
            <RenderNoRecords
              imageSrc={NoRequestsSvg}
              caption={intl.get('REQUESTS_LIST_PAGE.CREATE_MANAGE_REQUESTS')}
              className='h-empty-table-body'
            >
              <Link to={PATHS.REQUEST_PAGE}>
                <Button className='mt-2 mx-auto'>
                  {intl.get('REQUESTS_LIST_PAGE.GET_STARTED')}
                </Button>
              </Link>
            </RenderNoRecords>
          ) : (
            <RenderNoRecords
              dataCy='no-request-found'
              imageSrc={isSearch ? searchNoReturns : filterNoResults}
              caption={intl.get('REQUESTS_LIST_PAGE.NO_RECORDS')}
              className={
                isSearch ? 'h-empty-table-body' : 'h-empty-filtered-table-body'
              }
              imageClassName={isSearch ? '-ml-4' : ''}
            />
          )
        }
      />
    </div>
  );
};

export default RequestsTable;
