import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { PROJECTS_TABLE_TABS } from 'utils/constants';
import {
  selectUserProjects,
  selectAllUserProjects,
  selectIfUnfilteredUserProjectsIsEmpty,
  selectFetchUserProjectsStatus,
} from 'state/Projects/projectsSlice';
import {
  ProjectsTableTab,
  Pagination as PaginationType,
} from 'utils/customTypes';
import TableHeaderFilters from '../TableHeaderFilters';
import MyProjectsTable from './MyProjectsTable';
import Pagination from 'Organisms/Pagination/ClientPagination';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';

const MyProjects: React.FC<{
  onAddProject: () => void;
  onDuplicateProject: (projectId: string) => void;
  onArchiveProject: (projectId: string) => void;
  onUpdatePagination: (
    pagination: PaginationType,
    table: ProjectsTableTab
  ) => void;
}> = ({
  onAddProject,
  onDuplicateProject,
  onArchiveProject,
  onUpdatePagination,
}) => {
  const userProjectsList = useSelector(selectUserProjects);
  const originalUserProjectsList = useSelector(selectAllUserProjects);
  const fetchProjectsStatus = useSelector(selectFetchUserProjectsStatus);
  const isUnfilteredUserProjectsEmpty = useSelector(
    selectIfUnfilteredUserProjectsIsEmpty
  );

  const handleUpdatePagination = useCallback(
    (pagination) => {
      const table: ProjectsTableTab = PROJECTS_TABLE_TABS.MY_PROJECTS;
      onUpdatePagination(pagination, table);
    },
    [onUpdatePagination]
  );

  if (fetchProjectsStatus === 'loading') {
    return <SkeletonLoader data-cy='skeleton-loader' />;
  }

  return (
    <div className='mt-4'>
      <TableHeaderFilters
        table={PROJECTS_TABLE_TABS.MY_PROJECTS}
        exportEnabled={!isUnfilteredUserProjectsEmpty}
        filterButtonEnabled={!isEmpty(originalUserProjectsList)}
      />
      <MyProjectsTable
        totalProjects={userProjectsList.total}
        projectsList={userProjectsList.data}
        addNewProject={onAddProject}
        onDuplicateProject={onDuplicateProject}
        onArchiveProject={onArchiveProject}
      />
      <Pagination
        total={userProjectsList.total}
        onChange={handleUpdatePagination}
        className='z-10 max-w-full'
      />
    </div>
  );
};

export default MyProjects;
