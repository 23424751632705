import { tailwindOverride } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { REQUESTS_TABLE_SORTABLE_COLUMNS } from 'utils/constants';

type RequestsTableHeadProps = {
  isLDUser: boolean;
  isRequestTableEmpty: boolean;
};

const RequestsTableHead = ({
  isLDUser,
  isRequestTableEmpty,
}: RequestsTableHeadProps) => {
  return [
    {
      'data-cy': 'header__request-number',
      content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.REQUEST_NO'),
      columnName: REQUESTS_TABLE_SORTABLE_COLUMNS.REQUEST_NUMBER,
      className: tailwindOverride({
        'w-32 left-14 z-2': !isRequestTableEmpty,
      }),
    },
    {
      'data-cy': 'header__request-title',
      content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.REQUEST_TITLE'),
      columnName: REQUESTS_TABLE_SORTABLE_COLUMNS.TITLE,
      className: tailwindOverride({
        'w-64 left-54 z-2': !isRequestTableEmpty,
      }),
    },
    {
      'data-cy': 'header__request-status',
      content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.STATUS'),
      columnName: REQUESTS_TABLE_SORTABLE_COLUMNS.STATUS,
      className: tailwindOverride({
        'w-36 left-126 z-2': !isRequestTableEmpty,
      }),
    },
    {
      'data-cy': 'header__requester-name',
      content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.REQUESTER_NAME'),
      columnName: REQUESTS_TABLE_SORTABLE_COLUMNS.REQUESTER_NAME,
    },
    ...(isLDUser
      ? [
          {
            'data-cy': 'header__owners',
            content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.OWNER_NAME'),
            columnName: REQUESTS_TABLE_SORTABLE_COLUMNS.OWNERS,
          },
        ]
      : []),
    {
      content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.LINKED_PROJECTS'),
      columnName: REQUESTS_TABLE_SORTABLE_COLUMNS.PROJECTS,
      className: tailwindOverride({
        'sticky top-0 z-1': !isRequestTableEmpty,
      }),
    },
    ...(isLDUser
      ? [
          {
            content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.BUSINESS_UNIT'),
            columnName: REQUESTS_TABLE_SORTABLE_COLUMNS.BUSINESS_UNIT,
          },
          {
            content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.PRIORITY'),
            columnName: REQUESTS_TABLE_SORTABLE_COLUMNS.LD_PRIORITY,
          },
        ]
      : []),
    {
      content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.FORM'),
      columnName: REQUESTS_TABLE_SORTABLE_COLUMNS.FORM_TITLE,
    },
    {
      content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.CREATION_DATE'),
      columnName: REQUESTS_TABLE_SORTABLE_COLUMNS.CREATED_AT,
    },
    {
      'data-cy': 'header__submission-date',
      content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.SUBMISSION_DATE'),
      columnName: REQUESTS_TABLE_SORTABLE_COLUMNS.SUBMITTED_AT,
    },
    {
      content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.DECISION_DATE'),
      columnName: REQUESTS_TABLE_SORTABLE_COLUMNS.DECISION_DATE,
    },
    {
      'data-cy': 'header__update-date',
      content: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.UPDATE_DATE'),
      columnName: REQUESTS_TABLE_SORTABLE_COLUMNS.UPDATED_AT,
    },
    ...(!isRequestTableEmpty
      ? [
          {
            content: <div className='w-12'></div>,
            className: 'w-10 sticky right-0 z-2 h-10',
          },
        ]
      : []),
  ];
};

export default RequestsTableHead;
