import { SKELETON_COLOR } from './constants';
import { ResponsiveContainer, PieChart, Pie } from 'recharts';

const ChartSkeletonLoader = () => {
  const resourceTypeAllocation = [
    {
      resourceType: 'business',
      allocation: 300,
    },
    {
      resourceType: 'external',
      allocation: 300,
    },
    {
      resourceType: 'ld',
      allocation: 300,
    },
  ];

  return (
    <>
      <div className='bg-neutral-lighter animate-pulse w-40 h-4 mb-2'></div>
      <div className='bg-neutral-lighter animate-pulse w-40 h-4'></div>
      <ResponsiveContainer width='100%' height='100%'>
        <PieChart>
          <Pie
            dataKey='allocation'
            startAngle={180}
            endAngle={0}
            cy='65%'
            data={resourceTypeAllocation}
            innerRadius='30%'
            paddingAngle={0.5}
            fill={SKELETON_COLOR}
          />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default ChartSkeletonLoader;
