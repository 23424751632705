import { FC, useMemo } from 'react';
import intl from 'react-intl-universal';
import { tailwindOverride } from '@getsynapse/design-system';
import { TimeOffEntry, TimeOffEntryFilters } from 'types/store/manageTimeOff';
import AddTimeOffButton from '../../AddTimeOffButton/AddTimeOffButton';
import EmptyTimeOffPageIndicator from '../EmptyTimeOffPageIndicator/EmptyTimeOffPageIndicator';
import TimeOffRow from '../TimeOffRow/TimeOffRow';
import LastTimeOffRow from '../TimeOffRow/components/LastTimeOffRow/LastTimeOffRow';
import { isEmpty } from 'lodash';
import TimeOffFilterNoResultIndicator from '../../TimeOffFilters/components/TimeOffFilterNoResultIndicator/TimeOffFilterNoResultIndicator';

export interface TableBodyProps {
  timeOffEntries: TimeOffEntry[];
  fetchMoreTimeOffEntries: () => void;
  canFetchMore?: boolean;
  rootId: Element | null;
  savedFilters: TimeOffEntryFilters;
}

const TableBody: FC<TableBodyProps> = ({
  timeOffEntries,
  fetchMoreTimeOffEntries,
  canFetchMore = false,
  rootId,
  savedFilters,
}) => {
  const areFiltersEmpty = useMemo(() => {
    if (isEmpty(savedFilters)) {
      return true;
    }

    return Object.values(savedFilters).every((filter) => {
      return filter === '';
    });
  }, [savedFilters]);

  return (
    <tbody>
      {timeOffEntries.map((timeOffEntry: TimeOffEntry, index: number) => (
        <TimeOffRow
          key={timeOffEntry.id}
          timeOffEntry={timeOffEntry}
          className={tailwindOverride({
            'bg-neutral-lightest-two': index % 2 > 0,
            'bg-neutral-white': index % 2 === 0,
          })}
        />
      ))}
      {canFetchMore && (
        <LastTimeOffRow
          fetchMoreTimeOffEntries={fetchMoreTimeOffEntries}
          rootId={rootId}
          className={
            timeOffEntries.length % 2 === 0
              ? 'bg-neutral-lightest-two'
              : 'bg-neutral-white'
          }
        />
      )}
      {timeOffEntries.length === 0 && areFiltersEmpty && (
        <tr>
          <td colSpan={4}>
            <EmptyTimeOffPageIndicator
              caption={intl.get('MANAGE_TIME_OFF.TABLE.EMPTY_CAPTION')}
              labelClassName='mt-2'
              imageClassName='-ml-0.5'
            >
              <div className='flex justify-center mt-2'>
                <AddTimeOffButton />
              </div>
            </EmptyTimeOffPageIndicator>
          </td>
        </tr>
      )}
      {timeOffEntries.length === 0 && !areFiltersEmpty && (
        <tr>
          <td colSpan={4}>
            <TimeOffFilterNoResultIndicator
              caption={intl.get(
                'MANAGE_TIME_OFF.TABLE.EMPTY_FILTER_RESULT_CAPTION'
              )}
              labelClassName='mt-2'
              imageClassName='-ml-0.5'
            />
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default TableBody;
