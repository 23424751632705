import { Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

export const getEmptyTableRow = (searchParamValue: string) => [
  {
    id: 'empty-task',
    className: 'cursor-auto pointer-events-none w-full table-fixed',
    cells: [
      {
        content: '',
        className: 'w-5/12',
      },
      {
        content: (
          <Typography variant='body2' className='leading-4 text-primary-light'>
            {searchParamValue
              ? intl.get(
                  'SETTINGS_PAGE.TASK_BUNDLE_PAGE.BUILD_SECTION.NO_RESULTS_FOUND'
                )
              : intl.get(
                  'SETTINGS_PAGE.TASK_BUNDLE_PAGE.BUILD_SECTION.NO_TASKS_ADDED'
                )}
          </Typography>
        ),
        className: 'w-7/12',
      },
      {
        content: '',
        className: 'w-16 right-0 sticky z-1 border-transparent',
      },
    ],
  },
];
