import { GlobalBanner, Button, Typography } from '@getsynapse/design-system';
import { useState } from 'react';
import intl from 'react-intl-universal';
import ApprovedRequestInfoModal from './ApprovedRequestInfoModal';
import { Request } from 'utils/customTypes';
import moment from 'moment';
import { DATE } from 'utils/constants';
import classNames from 'classnames';

type Props = {
  requestData: Request;
  sidePanel?: boolean;
  dataTestId?: string;
};

const ApprovedRequestBanner = ({
  requestData,
  sidePanel,
  dataTestId,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const approvalDate = moment(requestData.decision_date).format(
    DATE.SHORT_FORMAT
  );

  return (
    <>
      <ApprovedRequestInfoModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        comments={requestData.data?.approval?.comments!}
        approvalDate={approvalDate}
      />
      <GlobalBanner
        variant='success'
        hasCloseIcon
        className={classNames(`${sidePanel ? 'mb-4' : 'mt-2'}`)}
        iconProps={{
          'data-testid': dataTestId,
        }}
      >
        {() => {
          return (
            <div className='flex justify-between items-start'>
              <div>
                <Typography
                  variant='body'
                  className='text-success-darkest leading-6'
                  data-testid='approved-request-banner-line'
                >
                  {intl.getHTML(
                    'REQUEST_PAGE.APPROVED_REQUEST_BANNER.LINE_ONE',
                    { approvalDate: approvalDate }
                  )}
                </Typography>
                {!sidePanel && (
                  <Typography
                    variant='body'
                    className='text-success-darkest leading-6'
                    data-testid='approved-request-banner-line-2'
                  >
                    {intl.get('REQUEST_PAGE.APPROVED_REQUEST_BANNER.LINE_TWO')}
                  </Typography>
                )}
              </div>
              {!sidePanel && (
                <Button
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                  color='positive'
                  data-testid='view-comments-button'
                >
                  {intl.get('REQUEST_PAGE.APPROVED_REQUEST_BANNER.BUTTON')}
                </Button>
              )}
            </div>
          );
        }}
      </GlobalBanner>
    </>
  );
};

export default ApprovedRequestBanner;
