import { FC, useMemo, Fragment } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Typography, FormItem, Dropdown } from '@getsynapse/design-system';
import { selectTimeOffTypes } from 'state/ManageTimeOff/TimeOffTypes/timeOffTypesSlice';
import { TimeOffEntryFormState, TimeOffType } from 'types/store/manageTimeOff';
import { rangeDateType } from 'utils/customTypes';
import DateRangeField from './components/DateRangeField/DateRangeField';

interface TimeOffTypeOption {
  label: string;
  value: string;
}

interface TimeOffEntryFormProps {
  title: string;
  timeOffEntry: TimeOffEntryFormState | null;
  updateTimeOffEntry: (
    key: keyof Omit<TimeOffEntryFormState, 'id'>,
    value: any
  ) => void;
  updateDateRangeValidity: (isValid: boolean) => void;
}

const TimeOffEntryForm: FC<TimeOffEntryFormProps> = ({
  title,
  timeOffEntry,
  updateTimeOffEntry,
  updateDateRangeValidity,
}) => {
  const timeOffTypesList = useSelector(selectTimeOffTypes);

  const timeOffTypesDropdownOptions = useMemo<TimeOffTypeOption[]>(
    () =>
      timeOffTypesList.map((timeOffType: TimeOffType) => ({
        label: timeOffType.name,
        value: timeOffType.id,
      })),
    [timeOffTypesList]
  );

  const selectedTimeOffType = useMemo(() => {
    if (!timeOffEntry) {
      return [];
    }

    const timeOffType = timeOffTypesDropdownOptions.find(
      (option) => option.value === timeOffEntry.timeOffTypeId
    );

    return timeOffType ? [timeOffType] : [];
  }, [timeOffEntry, timeOffTypesDropdownOptions]);

  return (
    <Fragment>
      <Typography variant='h4' className='text-primary mb-8' weight='medium'>
        {title}
      </Typography>
      <DateRangeField
        startDate={timeOffEntry?.startDate}
        endDate={timeOffEntry?.endDate}
        updateDateRange={(dateRange: rangeDateType) => {
          updateTimeOffEntry('startDate', dateRange.startDate);
          updateTimeOffEntry('endDate', dateRange.endDate);
        }}
        updateDateRangeValidity={updateDateRangeValidity}
      />
      <FormItem
        className='mt-4'
        label={intl.get('USER_TIME_OFF_PAGE.TABLE.COLUMNS.TYPE')}
        labelProps={{ required: true }}
      >
        <Dropdown
          options={timeOffTypesDropdownOptions}
          values={selectedTimeOffType}
          onChange={(option: TimeOffTypeOption) =>
            updateTimeOffEntry('timeOffTypeId', option.value)
          }
          placeholder={intl.get('USER_TIME_OFF_PAGE.FORM.TYPE_PLACEHOLDER')}
        />
      </FormItem>
    </Fragment>
  );
};

export default TimeOffEntryForm;
