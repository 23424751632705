import intl from 'react-intl-universal';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import has from 'lodash/has';
import {
  filter,
  rangeDate,
  Project,
  ProjectOwner,
  ProjectProcessStage,
  objKeyAsString,
  ProcessStage,
  NewProject,
  ProjectCategory,
  ProjectProcess,
  SortingType,
  Option,
} from 'utils/customTypes';
import {
  CustomFieldsFilters,
  ProjectFilters,
  ProjectFiltersKey,
  RangeFilter,
} from 'utils/types/filters';
import { isDateRangeFilter } from 'utils/typeGuards';
import {
  TABLE_FILTERS_OPERATORS,
  PROJECT_HEALTH,
  COLUMN_OPTION_TYPES,
  STAGE_COLORS,
  STAGE_TEXT_COLOR,
  STAGE_ON_DRAG_COLORS,
  STAGE_ON_DRAG_BORDER_COLORS,
  PROJECT_STATUS,
  NEW_PROJECT_NONREQUIRED_ENUM_FIELDS,
  PROJECTS_TABLE_SORTABLE_COLUMNS,
  PROJECTS_TABLE_FILTERS,
  CUSTOM_FIELDS_FILTERS_TYPES,
  ALLOWED_DATE_FIELD_DISPLAY_VALUES,
} from 'utils/constants';
import { getValueById } from 'Pages/ProjectsListPage/helpers/tableColumnsValues';
import { DateValue, FieldValueType } from 'utils/types/fields';
import { areIntervalsOverlapping } from 'date-fns';
import { ProjectTemplateField } from 'utils/types/templates';
import type { LearningTeam } from 'utils/types/learningTeam';
import type { BusinessTeam } from 'utils/types/businessTeams';
import { formatProjectNumber } from 'utils/formatters';
import {
  naturalSort,
  ownerSortKeyGenerator,
  sortItemsByKey,
  businessTeamSortKeyGenerator,
} from '../../utils/naturalSorting';

export const formatFilters = (filters: filter[]) =>
  filters.map((filter) => {
    if (filter.type === COLUMN_OPTION_TYPES.DATE) {
      const dateRange = filter.value as rangeDate;
      const startDate = new Date(dateRange.startDate).toDateString();
      const endDate = dateRange.endDate
        ? new Date(dateRange.endDate).toDateString()
        : startDate;
      return {
        ...filter,
        value: {
          startDate,
          endDate,
        },
      };
    } else {
      return filter;
    }
  });

export const checkColumnMatchDateFilter = (
  columnData: any,
  filter: Partial<filter>
) => {
  const requestDate = moment(new Date(columnData));
  const rangeDate = filter.value as rangeDate;
  const startDate = new Date(rangeDate.startDate);
  const endDate = new Date(rangeDate.endDate);
  switch (filter.operator) {
    case TABLE_FILTERS_OPERATORS.EQUAL:
      return requestDate.isSame(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.GREATER:
      return requestDate.isAfter(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.GREATER_OR_EQUAL:
      return requestDate.isSameOrAfter(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.LESS:
      return requestDate.isBefore(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.LESS_OR_EQUAL:
      return requestDate.isSameOrBefore(startDate, 'days');
    case TABLE_FILTERS_OPERATORS.BETWEEN:
      return requestDate.isBetween(startDate, endDate, 'days', '[]');
    default:
      return false;
  }
};

const checkProjectMatchDateFilter = (
  filterValue: RangeFilter,
  projectValue: string
) => {
  let projectMatchDateFilter = false;
  const projectDate = moment(new Date(projectValue));
  const fromRange = filterValue.from && new Date(filterValue.from);
  const toRange = filterValue.to && new Date(filterValue.to);
  if (fromRange) {
    projectMatchDateFilter = projectDate.isSameOrAfter(fromRange, 'days');
  }
  if (fromRange && toRange) {
    projectMatchDateFilter =
      projectMatchDateFilter && projectDate.isSameOrBefore(toRange, 'days');
  }
  if (!fromRange && toRange) {
    projectMatchDateFilter = projectDate.isSameOrBefore(toRange, 'days');
  }
  return projectMatchDateFilter;
};

const checkColumnMatchDateRangeFilter = (
  columnData: { projectStartDate: string; projectEndDate: string },
  filter: Partial<CustomFieldsFilters>
) => {
  const rangeDate = filter.value as RangeFilter;
  const startDate = new Date(rangeDate.from);
  const endDate = new Date(rangeDate.to);
  switch (filter.operator) {
    case TABLE_FILTERS_OPERATORS.EQUAL:
      return (
        moment(columnData.projectStartDate).isSame(startDate, 'days') &&
        moment(columnData.projectEndDate).isSame(endDate, 'days')
      );
    case TABLE_FILTERS_OPERATORS.BETWEEN:
      return (
        moment(columnData.projectStartDate).isBetween(
          startDate,
          endDate,
          'days',
          '[]'
        ) &&
        moment(columnData.projectEndDate).isBetween(
          startDate,
          endDate,
          'days',
          '[]'
        )
      );
    case TABLE_FILTERS_OPERATORS.OVERLAPS:
      return areIntervalsOverlapping(
        { start: startDate, end: endDate },
        {
          start: new Date(columnData.projectStartDate),
          end: new Date(columnData.projectEndDate),
        }
      );
    default:
      return false;
  }
};

export const checkColumnMatchNumberFilter = (
  fieldValue: number,
  filter: Partial<CustomFieldsFilters>
) => {
  const filterValue = filter.value as number;
  switch (filter.operator) {
    case TABLE_FILTERS_OPERATORS.EQUAL:
      return fieldValue === filterValue;
    case TABLE_FILTERS_OPERATORS.GREATER:
      return fieldValue > filterValue;
    case TABLE_FILTERS_OPERATORS.GREATER_OR_EQUAL:
      return fieldValue >= filterValue;
    case TABLE_FILTERS_OPERATORS.LESS:
      return fieldValue < filterValue;
    case TABLE_FILTERS_OPERATORS.LESS_OR_EQUAL:
      return fieldValue <= filterValue;
    default:
      return false;
  }
};

export const checkColumnMatchTextFilter = (
  fieldValue: string,
  filter: Partial<CustomFieldsFilters>
) => {
  const filterValue = filter.value?.toString();
  const lowerCaseFieldValue = fieldValue?.toLowerCase();
  switch (filter.operator) {
    case TABLE_FILTERS_OPERATORS.CONTAINS:
      return lowerCaseFieldValue?.includes(filterValue!.toLocaleLowerCase());
    case TABLE_FILTERS_OPERATORS.DOESNT_CONTAIN:
      return !lowerCaseFieldValue?.includes(filterValue!.toLocaleLowerCase());
    default:
      return false;
  }
};

export const checkColumnMatchMultiOptionsFilter = (
  fieldValue: FieldValueType | { val: FieldValueType[] },
  filter: Partial<CustomFieldsFilters>
) => {
  const filterValues = filter.value as Option[];
  const isFieldValueAnArray = Array.isArray(fieldValue);
  switch (filter.operator) {
    case TABLE_FILTERS_OPERATORS.EQUAL:
      if (isFieldValueAnArray) {
        return fieldValue.some((value) =>
          filterValues?.some((filterValue) => value.val === filterValue.value)
        );
      }
      return filterValues.some(
        (filterValue) => fieldValue === filterValue.value
      );
    case TABLE_FILTERS_OPERATORS.NOT_EQUAL:
      if (isFieldValueAnArray && !fieldValue.length) {
        return true;
      } else if (isFieldValueAnArray) {
        return fieldValue.every((value) =>
          filterValues.every((filterValue) => value.val !== filterValue.value)
        );
      }
      return filterValues.every(
        (filterValue) => filterValue.value !== fieldValue
      );
    default:
      return false;
  }
};

const checkCustomFieldFilter = (
  filterValue: Partial<CustomFieldsFilters>,
  project: Project
) => {
  const { operator, value, fieldId, type } = filterValue;
  const customProperties = get(project, 'customProperties', []);
  const customProperty = customProperties?.find(
    (property: ProjectTemplateField) => property.field_template_id === fieldId
  );
  const projectFieldValue = get(customProperty, 'value.val');
  switch (type) {
    case CUSTOM_FIELDS_FILTERS_TYPES.DATE:
      if (customProperty) {
        const filter = {
          operator,
          value: {
            startDate: (value as RangeFilter)?.from,
            endDate: (value as RangeFilter)?.to,
          },
        };
        return checkColumnMatchDateFilter(projectFieldValue, filter);
      }
      return false;
    case CUSTOM_FIELDS_FILTERS_TYPES.DATE_RANGE:
      if (
        customProperty &&
        projectFieldValue &&
        Array.isArray(projectFieldValue)
      ) {
        const projectStartDate = projectFieldValue.find(
          (date: DateValue) =>
            date.display === ALLOWED_DATE_FIELD_DISPLAY_VALUES.START_DATE
        );
        const projectEndDate = projectFieldValue.find(
          (date: DateValue) =>
            date.display === ALLOWED_DATE_FIELD_DISPLAY_VALUES.END_DATE
        );
        if (projectStartDate && projectEndDate) {
          const filter = {
            operator,
            value: {
              from: (value as RangeFilter)?.from,
              to: (value as RangeFilter)?.to,
            },
          };
          return checkColumnMatchDateRangeFilter(
            {
              projectStartDate: projectStartDate.val,
              projectEndDate: projectEndDate.val,
            },
            filter
          );
        }
        return false;
      }
      return false;
    case CUSTOM_FIELDS_FILTERS_TYPES.NUMBER:
      if (!customProperty && operator === TABLE_FILTERS_OPERATORS.NOT_EQUAL) {
        return true;
      } else if (customProperty) {
        const filter = {
          operator,
          value: value,
        };
        return checkColumnMatchNumberFilter(projectFieldValue, filter);
      }
      return false;
    case CUSTOM_FIELDS_FILTERS_TYPES.TEXT:
      if (
        !customProperty &&
        operator === TABLE_FILTERS_OPERATORS.DOESNT_CONTAIN
      ) {
        return true;
      } else if (customProperty && customProperty !== null) {
        const filter = {
          operator,
          value: value,
        };
        return checkColumnMatchTextFilter(projectFieldValue, filter);
      }
      return false;
    case CUSTOM_FIELDS_FILTERS_TYPES.MULTI_OPTIONS:
      if (!customProperty && operator === TABLE_FILTERS_OPERATORS.NOT_EQUAL) {
        return true;
      } else if (customProperty) {
        const filter = {
          operator,
          value,
        };
        return checkColumnMatchMultiOptionsFilter(projectFieldValue, filter);
      }
      return false;
  }
  return true;
};

const checkProjectMatchAllFilters = (
  project: Project,
  filters: ProjectFilters
) => {
  let projectMatchAllFilters = true;
  for (const filter of Object.keys(filters)) {
    const filterValue = filters[filter as ProjectFiltersKey];
    const projectValue = project[filter as keyof Project];
    if (
      (!isEmpty(filterValue) && !isEmpty(projectValue)) ||
      typeof filterValue === 'boolean'
    ) {
      let projectMatchFilter = false;
      if (isDateRangeFilter(filterValue)) {
        projectMatchFilter = checkProjectMatchDateFilter(
          filterValue,
          projectValue
        );
      } else if (filter === PROJECTS_TABLE_FILTERS.OWNERS) {
        projectMatchFilter = projectValue.some((owner: ProjectOwner) =>
          (filterValue as string[]).includes(owner.project_owners.userId!)
        );
      } else if (filter === PROJECTS_TABLE_FILTERS.BUSINESS_TEAMS) {
        projectMatchFilter = projectValue.some((team: BusinessTeam) =>
          (filterValue as string[]).includes(team.id)
        );
      } else if (filter === PROJECTS_TABLE_FILTERS.TEAMS) {
        projectMatchFilter = projectValue.some((team: LearningTeam) =>
          (filterValue as string[]).includes(team.id)
        );
      } else if (filter === PROJECTS_TABLE_FILTERS.ARCHIVED) {
        if (projectValue === undefined) {
          projectMatchFilter = !filterValue;
        } else {
          projectMatchFilter = projectValue === filterValue;
        }
      } else {
        projectMatchFilter = (filterValue as string[]).includes(projectValue);
      }
      projectMatchAllFilters = projectMatchAllFilters && projectMatchFilter;
      if (!projectMatchAllFilters) {
        return projectMatchAllFilters;
      }
    } else if (has(filterValue, 'fieldId')) {
      const projectMatchFilter = checkCustomFieldFilter(
        filterValue as Partial<CustomFieldsFilters>,
        project
      );
      projectMatchAllFilters = projectMatchAllFilters && projectMatchFilter!;
    } else {
      projectMatchAllFilters = false;
      return projectMatchAllFilters;
    }
  }
  return projectMatchAllFilters;
};

export const filterProjects: (
  projects: Project[],
  search: string,
  filters?: ProjectFilters,
  userId?: string
) => Project[] = (projects, search, filters) => {
  let filteredProjectsList = projects;
  const filterArchivedDefault = {
    ...filters,
    [PROJECTS_TABLE_FILTERS.ARCHIVED]:
      get(filters, PROJECTS_TABLE_FILTERS.ARCHIVED) || false,
  } as ProjectFilters;
  const filtersActive =
    filterArchivedDefault && Object.keys(filterArchivedDefault).length > 0;
  const searchActive = search;
  if (filtersActive || searchActive) {
    filteredProjectsList = projects.filter((project: Project) => {
      let projectMatchSearch = false;
      let projectMatchFilters = false;
      if (searchActive) {
        const projectNumber = formatProjectNumber(project.projectNumber);
        const projectNumberRegex = /P?\d{6}/;
        const projectNumberMatchSearch = projectNumberRegex.exec(search);
        if (projectNumberMatchSearch) {
          let formattedSearch = search.toUpperCase();
          if (formattedSearch[0] !== 'P') {
            formattedSearch = `P${search}`;
          }
          projectMatchSearch = projectNumber === formattedSearch;
        }
        if (!projectMatchSearch) {
          projectMatchSearch =
            project.title
              ?.toLocaleLowerCase()
              .includes(search.toLocaleLowerCase()) ||
            projectNumber.includes(search);
        }
      }
      if (searchActive && !projectMatchSearch) {
        return projectMatchSearch;
      }
      if (filtersActive) {
        projectMatchFilters = checkProjectMatchAllFilters(
          project,
          filterArchivedDefault
        );
      }
      if (searchActive && filtersActive) {
        return projectMatchSearch && projectMatchFilters;
      } else if (searchActive && !filtersActive) {
        return projectMatchSearch;
      } else if (!searchActive && filtersActive) {
        return projectMatchFilters;
      } else {
        return false;
      }
    });
  }
  return filteredProjectsList;
};

export const groupProjectsByStage: (
  stages: ProjectProcessStage[],
  projects: Project[]
) => { newAndInQueueProjectsStage: Project[]; stagesList: ProcessStage[] } = (
  stages,
  projects
) => {
  const stagesList: objKeyAsString = {};
  let index = 0;
  const newAndInQueueProjectsArray = [];
  for (const stage of stages) {
    stagesList[stage.id] = {
      id: stage.id,
      name: stage.stageName,
      color: STAGE_COLORS[index],
      textColor: STAGE_TEXT_COLOR[index],
      colorOnDrag: STAGE_ON_DRAG_COLORS[index],
      borderColorOnDrag: STAGE_ON_DRAG_BORDER_COLORS[index],
      cards: [],
    };
    index = (index + 1) % STAGE_COLORS.length;
  }
  for (const project of projects) {
    if (
      [PROJECT_STATUS.NEW, PROJECT_STATUS.IN_PLANNING].includes(project.status)
    ) {
      newAndInQueueProjectsArray.push(project);
      continue;
    }
    if (project.stage_id && stagesList[project?.stage_id]) {
      stagesList[project?.stage_id].cards =
        stagesList[project?.stage_id].cards.concat(project);
    }
  }
  return {
    newAndInQueueProjectsStage: newAndInQueueProjectsArray,
    stagesList: Object.values(stagesList),
  };
};

export const filterOutInactiveProjects = (projects: Project[]) =>
  projects.filter((project) => {
    const inActiveStatus = [
      PROJECT_STATUS.COMPLETED,
      PROJECT_STATUS.CANCELED,
      PROJECT_STATUS.CLOSED,
    ];
    return !inActiveStatus.includes(project.status);
  });

export const processNewProjectData = (project: NewProject) => {
  delete project.health;
  delete project.stage_id;
  delete project.is_archived;
  delete project.isProjectPartOfUserPrograms;
  NEW_PROJECT_NONREQUIRED_ENUM_FIELDS.forEach((field) => {
    if (!project[field]) {
      delete project[field];
    }
  });
  return project;
};

export const formatProjectsList = (
  projects: Project[],
  processes: ProjectProcess[],
  categories: ProjectCategory[]
) =>
  projects.map((project: Project) => {
    let matchedStages: ProjectProcessStage[] = [];
    const matchedProcess = processes.find(
      (process: ProjectProcess) => process.id === project.process_id
    );
    if (matchedProcess) {
      matchedStages = matchedProcess.projectStages;
    }
    return {
      ...project,
      category: getValueById(categories, 'categoryName', project.category_id),
      process: matchedProcess ? matchedProcess.processName : '',
      stage:
        matchedStages.length > 0
          ? getValueById(matchedStages, 'stageName', project.stage_id!)
          : '',
    };
  });

export const orderProjectsBy = (
  projects: Project[],
  column: string,
  order: SortingType
) => {
  const healthMapping: objKeyAsString = {
    [PROJECT_HEALTH.OFF_TRACK]: intl.get(
      'PROJECT_DETAIL.HEALTH_OPTIONS.OFF_TRACK'
    ),
    [PROJECT_HEALTH.ON_TRACK]: intl.get(
      'PROJECT_DETAIL.HEALTH_OPTIONS.ON_TRACK'
    ),
    [PROJECT_HEALTH.AT_RISK]: intl.get('PROJECT_DETAIL.HEALTH_OPTIONS.AT_RISK'),
  };

  switch (column) {
    case PROJECTS_TABLE_SORTABLE_COLUMNS.PROJECT_OWNER:
      const sortedProjectsByOwners = sortItemsByKey(
        projects,
        ownerSortKeyGenerator,
        order
      );

      return sortedProjectsByOwners;
    case PROJECTS_TABLE_SORTABLE_COLUMNS.BUSINESS_UNIT:
      const sortedProjectsByBusinessTeams = sortItemsByKey(
        projects,
        businessTeamSortKeyGenerator,
        order
      );

      return sortedProjectsByBusinessTeams;
    case PROJECTS_TABLE_SORTABLE_COLUMNS.ACTUAL_COMPLETION_DATE:
    case PROJECTS_TABLE_SORTABLE_COLUMNS.TARGET_COMPLETION_DATE:
    case PROJECTS_TABLE_SORTABLE_COLUMNS.START_DATE:
      return orderBy(projects, [column], [order]);
    case PROJECTS_TABLE_SORTABLE_COLUMNS.PROJECT_NUMBER:
      return naturalSort([...projects], column as keyof Project, order);
    case PROJECTS_TABLE_SORTABLE_COLUMNS.HEALTH:
      return orderBy(
        projects,
        [
          (project: Project) =>
            project.status === PROJECT_STATUS.IN_PROGRESS ||
            project.status === PROJECT_STATUS.COMPLETED
              ? healthMapping[project.health]
              : '',
        ],
        [order]
      );
    case PROJECTS_TABLE_SORTABLE_COLUMNS.TITLE:
      return naturalSort([...projects], column as keyof Project, order);
    default:
      return orderBy(projects, [column.toLowerCase()], [order]);
  }
};
