import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import { TableOperationHeader } from '@getsynapse/design-system';
import {
  exportRequestsByFormStatus,
  exportRequestsStatus,
  searchByTitle,
  setFilters,
} from 'state/Requests/requestSlice';
import { selectUserType } from 'state/User/userSlice';
import { RequestsTable } from 'utils/customTypes';
import { SLICE_STATUS, USER_TYPES } from 'utils/constants';
import { RequestFilters } from 'utils/types/filters';
import useImportRequests from '../hooks/useImportRequests';
import MoreActionsPopup, {
  OptionProps,
} from 'Organisms/TableHeaderActionButtons/MoreActionsPopup';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';
import ExportIcon from 'assets/icons/export.svg';
import RequestsSidePanelFilters from './RequestsSidePanelFilters/RequestsSidePanelFilters';
import AppliedFiltersTags from './RequestsSidePanelFilters/AppliedFiltersTags';
import ImportRequestModal from './ImportRequestModal/ImportRequestModal';
import ImportIcon from 'assets/icons/import.svg';

const RequestsTableHeader: React.FC<{
  requestsTable: RequestsTable;
  onExportHandler: (callback: () => void) => void;
  onExportByFormHandler: (callback: () => void) => void;
  exportEnabled?: Boolean;
  appliedFilters: RequestFilters;
  tableSearchParam: string;
  saveFiltersSettings: (filters: RequestFilters) => void;
}> = ({
  requestsTable,
  onExportByFormHandler,
  onExportHandler,
  exportEnabled = false,
  appliedFilters,
  tableSearchParam,
  saveFiltersSettings,
}) => {
  const dispatch = useDispatch();
  const userType = useSelector(selectUserType);
  const exportStatus = useSelector(exportRequestsStatus);
  const exportByFormStatus = useSelector(exportRequestsByFormStatus);

  const isLDUser = useMemo(() => userType === USER_TYPES.L_D, [userType]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const importButtonProps = useImportRequests();
  const [showImportModal, setShowImportModal] = useState<boolean>(false);

  useEffect(() => {
    if (tableSearchParam !== searchValue) {
      setSearchValue(tableSearchParam);
    }
  }, [tableSearchParam, searchValue]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    dispatch(searchByTitle({ searchParam: value, table: requestsTable }));
  };

  const toggleFilters = () => setShowFilters((prev) => !prev);

  const importActionItem = [
    { label: intl.get('IMPORT'), isSectionTitle: true },
    {
      label: intl.get('REQUESTS_LIST_PAGE.TABLE.IMPORT.IMPORT'),
      iconSrc: ImportIcon,
      selectOption: () => setShowImportModal(true),
      dataCy: 'table-header__import-button',
      disabled:
        isEmpty(importButtonProps.currentUser) ||
        isEmpty(importButtonProps.settings),
      importButtonProps: importButtonProps,
    },
  ];

  const tableActionsList: Partial<OptionProps>[] = [
    ...(isLDUser && importButtonProps.isReady ? importActionItem : []),

    { label: intl.get('EXPORT'), isSectionTitle: true },
    {
      label: intl.get('REQUESTS_LIST_PAGE.EXPORT'),
      selectOption: onExportHandler,
      disabled: !exportEnabled,
      tooltip: {
        text: exportEnabled
          ? intl.get('REQUESTS_LIST_PAGE.EXPORT_TOOLTIP')
          : '',
      },
      iconSrc: ExportIcon,
      dataCy: 'table-header__export-button',
      loading: exportStatus === SLICE_STATUS.LOADING,
    },
    {
      label: intl.get('REQUESTS_LIST_PAGE.EXPORT_BY_FORM'),
      selectOption: onExportByFormHandler,
      disabled: !exportEnabled,
      tooltip: {
        text: exportEnabled
          ? intl.get('REQUESTS_LIST_PAGE.EXPORT_TOOLTIP')
          : '',
      },
      iconSrc: ExportIcon,
      dataCy: 'table-header__export-by-form-button',
      loading: exportByFormStatus === SLICE_STATUS.LOADING,
    },
  ];

  const handleUpdateFilters = (filters: RequestFilters) => {
    dispatch(setFilters(filters, requestsTable));
    saveFiltersSettings(filters);
  };

  return (
    <div className='p-px'>
      <RequestsSidePanelFilters
        filters={appliedFilters}
        onClose={toggleFilters}
        open={showFilters}
        onUpdateFilters={handleUpdateFilters}
      />
      <ImportRequestModal
        showModal={showImportModal}
        onClose={() => setShowImportModal(false)}
      />
      <TableOperationHeader
        inputValue={searchValue}
        setInputValue={handleSearch}
        className='border-b-0'
        actionButtons={
          <>
            <FilterButton
              aria-pressed={showFilters}
              onToggleFilter={toggleFilters}
              data-cy={`${requestsTable}-table__filter-button`}
            />
            <MoreActionsPopup options={tableActionsList} />
          </>
        }
      />
      <AppliedFiltersTags
        filters={appliedFilters}
        onDisplayAllFilters={toggleFilters}
        onUpdateFilters={handleUpdateFilters}
      />
    </div>
  );
};

export default RequestsTableHeader;
