import React from 'react';
import { Button, GlobalBanner, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { PROJECT_CANCEL_REASONS, PROJECT_USER_ACTIONS } from 'utils/constants';
import { useSelector } from 'react-redux';
import { isUserOrganizationAdmin } from 'state/User/userSlice';
import { useUserPermissionsContext } from 'Pages/ProjectPage/context/UserPermissionsContext';

type CancelledStatusBannerProps = {
  handleOnClick?: () => void;
  handleOnReactivateClick?: (prevState: boolean) => void;
  reason?: string | null;
  sidePanel?: boolean;
};

export function CancelledStatusBanner(props: CancelledStatusBannerProps) {
  const {
    handleOnClick,
    handleOnReactivateClick,
    reason,
    sidePanel = false,
  } = props;

  const { canUser } = useUserPermissionsContext();

  const userHasReactivatePermission = canUser(
    PROJECT_USER_ACTIONS.REACTIVATE_PROJECT
  );
  const isUserAdmin = useSelector(isUserOrganizationAdmin);
  const canReactivate = userHasReactivatePermission || isUserAdmin;

  return (
    <GlobalBanner
      variant='information'
      className={`${sidePanel ? 'mb-4' : 'mt-4'}`}
      data-cy='cancelled-project-banner'
    >
      {() => {
        const cancelReasonKey = Object.keys(PROJECT_CANCEL_REASONS).find(
          (key) => PROJECT_CANCEL_REASONS[key] === reason
        );

        const displayReason = intl
          .get(`PROJECT_DETAIL.CANCEL_PROJECT.REASONS.${cancelReasonKey}`)
          .defaultMessage(reason || '');

        const reasonBody = intl
          .get('PROJECT_DETAIL.CANCEL_PROJECT.REASON', {
            reason: displayReason,
          })
          .defaultMessage(displayReason);

        return (
          <div className='flex justify-between'>
            <div>
              <Typography variant='h6'>
                {intl.get('PROJECT_DETAIL.CANCEL_PROJECT.BANNER_TITLE')}
              </Typography>
              <Typography data-cy='banner-cancellation-reason'>
                {reasonBody}
              </Typography>
            </div>
            <div className='flex items-center'>
              {handleOnClick && (
                <Button
                  variant='secondary'
                  onClick={handleOnClick}
                  data-cy='cancelled-banner-button'
                  className='mr-4'
                >
                  {intl
                    .get('PROJECT_DETAIL.CLOSE_PROJECT.VIEW_DETAILS_BUTTON')
                    .defaultMessage('View Details')}
                </Button>
              )}
              {canReactivate && (
                <Button
                  variant='secondary'
                  onClick={handleOnReactivateClick}
                  data-cy='reactivate-banner-button'
                >
                  {intl
                    .get('PROJECT_DETAIL.REACTIVATE_PROJECT.REACTIVATE_BUTTON')
                    .defaultMessage('Reactivate')}
                </Button>
              )}
            </div>
          </div>
        );
      }}
    </GlobalBanner>
  );
}
