import { FC } from 'react';
import { useInView } from 'react-intersection-observer';
import debounce from 'lodash/debounce';

export interface LastTimeOffRowProps {
  fetchMoreTimeOffEntries: () => void;
  rootId: Element | null;
  className?: string;
}

const LastTimeOffRow: FC<LastTimeOffRowProps> = ({
  fetchMoreTimeOffEntries,
  rootId,
  className = '',
}) => {
  const debouncenOnChangeHandler = debounce((inView: boolean) => {
    if (inView) {
      fetchMoreTimeOffEntries();
    }
  }, 500);

  const { ref: elementRef } = useInView({
    root: rootId,
    threshold: 0.5,
    rootMargin: '-80px 0px 0px',
    onChange: debouncenOnChangeHandler,
  });

  return (
    <tr ref={elementRef} aria-label='last-time-off-row' className={className}>
      <td className='p-4' style={{ width: '30%' }}>
        <div className='w-3/5 h-4 rounded bg-neutral-lighter-two' />
      </td>
      <td className='p-4' style={{ width: '30%' }}>
        <div className='w-3/5 h-4 rounded bg-neutral-lighter-two' />
      </td>
      <td className='p-4' style={{ width: '30%' }}>
        <div className='w-1/4 h-4 rounded bg-neutral-lighter-two' />
      </td>
      <td className='w-24 p-4'>
        <div className='flex flex-1 justify-end gap-x-4'>
          <div className='w-4 h-4 rounded bg-neutral-lighter-two' />
          <div className='w-4 h-4 rounded bg-neutral-lighter-two' />
        </div>
      </td>
    </tr>
  );
};

export default LastTimeOffRow;
