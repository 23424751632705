import { ReactNode, MouseEvent } from 'react';
import intl from 'react-intl-universal';
import moment, { Moment } from 'moment';
import {
  ParticipantAllocation,
  ProjectParticipantRole,
  Owner,
  objKeyAsString,
  NewProject,
} from 'utils/customTypes';
import { PROJECT_PARTICIPANT_ROLE, PROJECT_OWNER, DATE } from 'utils/constants';
import { formatProjectNumber } from 'utils/formatters';

export interface Column {
  id?: string;
  className?: string;
  content?: ReactNode | string;
}

export interface Allocation {
  allocationId?: string;
  weekStart: Date | string;
  weekEnd: Date | string;
  content: ReactNode;
}

export interface Estimation {
  startDate: Date | string;
  endDate: Date | string;
  content: {
    bgColor: string;
    textColor: string;
    onClickCallback: (id: string) => void;
    label: string;
    userId: string;
  };
}

export interface Assignment {
  columns: Column[];
  startDate: Date | string;
  endDate: Date | string;
  content: {
    bgColor: string;
    borderColor: string;
    textColor: string;
    name: string;
    time?: string;
  };
}

export interface AllocatedUserColumn {
  className?: string;
  children: (params?: {
    toggleSection?: (event: MouseEvent<HTMLElement>) => void;
    isOpen?: boolean;
  }) => ReactNode | string;
}

export interface AllocatedUserRole {
  participationId?: string;
  allocations?: Allocation[];
  estimation?: Estimation;
}

export interface AllocatedUser {
  id: string;
  columns: AllocatedUserColumn[];
  roles: AllocatedUserRole[];
  assignments: Assignment[];
  timeOffs?: Allocation[];
}

export interface Section {
  id: string;
  label: string;
  users: AllocatedUser[];
  emptyMessage: string;
}
export interface Week {
  label: string;
  startWeekDate: Date;
  endWeekDate: Date;
  isCurrentWeek: boolean;
}

export const getWeek: (date: Moment) => Week = (date) => {
  const currentWeekStart = moment().startOf('isoWeek');
  const currentWeekEnd = moment().endOf('isoWeek');

  const monday = date.clone().startOf('isoWeek');
  const friday = monday.clone().weekday(5);
  const sunday = date.clone().endOf('isoWeek');
  const weekRange = `${monday.format('MMM D')} - ${friday.format('MMM D')}`;
  return {
    label: weekRange,
    startWeekDate: monday.toDate(),
    endWeekDate: sunday.toDate(),
    isCurrentWeek:
      currentWeekStart.isSame(monday) && currentWeekEnd.isSame(sunday),
  };
};

export const getWeeksArray: (
  startDate: string | Date,
  endDate: string | Date,
  numberOfWeeks: number
) => Week[] = (startDate, endDate, numberOfWeeks) => {
  const weeksArray = [];
  const start = moment(new Date(startDate));
  const end = moment(new Date(endDate));
  let pivotWeek = start;

  if (start.isSame(end, 'day')) {
    for (let index = 0; index < numberOfWeeks; index++) {
      const week = getWeek(pivotWeek);
      weeksArray.push(week);
      pivotWeek = pivotWeek.add(7, 'days');
    }
    return weeksArray;
  }

  while (moment(pivotWeek).isBefore(end)) {
    const week = getWeek(pivotWeek);
    weeksArray.push(week);
    pivotWeek = pivotWeek.add(7, 'days');
  }
  if (weeksArray.length % numberOfWeeks !== 0) {
    while (weeksArray.length % numberOfWeeks !== 0) {
      const week = getWeek(pivotWeek);
      weeksArray.push(week);
      pivotWeek = pivotWeek.add(7, 'days');
    }
  }
  return weeksArray;
};

export const confirmAllocationBetweenWeek = (
  week: Week,
  allocation: Omit<Allocation, 'content'>
) => {
  const { startWeekDate, endWeekDate } = week;
  const { weekEnd, weekStart } = allocation;
  return (
    moment(new Date(weekStart)).isSameOrAfter(startWeekDate) &&
    moment(new Date(weekEnd)).isSameOrBefore(endWeekDate)
  );
};

export const confirmAllocationBetweenWeeks = (
  weeks: Week[],
  allocation: Omit<Allocation, 'content'>
) => {
  const { weekStart, weekEnd } = allocation;
  const firstWeek = weeks[0];
  const lastWeek = weeks[weeks.length - 1];
  return (
    firstWeek &&
    firstWeek.startWeekDate &&
    moment(new Date(weekStart)).isSameOrAfter(firstWeek.startWeekDate) &&
    lastWeek &&
    lastWeek.endWeekDate &&
    moment(new Date(weekEnd)).isSameOrBefore(lastWeek.endWeekDate)
  );
};

export const confirmAllocationOutOfWeeksRange = (
  weeks: Week[],
  allocation: Omit<Allocation, 'content'>
) => {
  const { weekStart } = allocation;
  const lastWeek = weeks[weeks.length - 1];
  return (
    lastWeek &&
    lastWeek.endWeekDate &&
    moment(new Date(weekStart)).isAfter(lastWeek.endWeekDate)
  );
};

export const generateGridColumnsArray = (
  currentWeeksSlideArray: Week[],
  startDate: Date | string,
  endDate: Date | string
) => {
  const estimationStartWeek = moment(startDate).startOf('isoWeek');
  const estimationEndWeek = moment(endDate).endOf('isoWeek');
  let gridColumnsArray: boolean[] = Array.from(
    Array.of(currentWeeksSlideArray.length),
    () => false
  );
  const isEstimationOutOfWeeksArray = confirmAllocationOutOfWeeksRange(
    currentWeeksSlideArray,
    {
      weekStart: estimationStartWeek.clone().toDate(),
      weekEnd: estimationEndWeek.clone().toDate(),
    }
  );
  if (isEstimationOutOfWeeksArray) {
    return gridColumnsArray;
  } else {
    for (let i = 0; i < currentWeeksSlideArray.length; i++) {
      const week = currentWeeksSlideArray[i];
      if (moment(estimationStartWeek).isAfter(week.endWeekDate)) {
        gridColumnsArray[i] = false;
        continue;
      }
      const isEstimationBetweenWeek = confirmAllocationBetweenWeek(week, {
        weekStart: estimationStartWeek.clone().toDate(),
        weekEnd: estimationEndWeek.clone().toDate(),
      });
      if (isEstimationBetweenWeek) {
        gridColumnsArray[i] = true;
        break;
      }

      if (moment(estimationEndWeek).isSameOrAfter(week.endWeekDate)) {
        gridColumnsArray[i] = true;
        let pivot = i + 1;
        while (
          pivot < currentWeeksSlideArray.length &&
          moment(estimationEndWeek).isSameOrAfter(
            currentWeeksSlideArray[pivot].endWeekDate
          )
        ) {
          gridColumnsArray[pivot] = true;
          pivot += 1;
        }
        i = pivot;
        break;
      }
    }
  }
  return gridColumnsArray;
};

export const recalculateTotalAllocatedHours = (
  startDate: Date | string,
  endDate: Date | string,
  allocationsArray: ParticipantAllocation[]
) => {
  const firstAllocation = allocationsArray[0];
  const lastAllocation = allocationsArray[allocationsArray.length - 1];
  const startWeek = moment(startDate).startOf('isoWeek');
  const endWeek = moment(endDate).endOf('isoWeek');

  if (moment(startWeek).isAfter(lastAllocation.weekEnd)) {
    return 0;
  }

  if (moment(endWeek).isBefore(firstAllocation.weekStart)) {
    return 0;
  }

  let totalAllocations = 0;
  for (let allocation of allocationsArray) {
    if (moment(startWeek).isAfter(allocation.weekEnd)) {
      continue;
    }

    if (moment(allocation.weekEnd).isAfter(endWeek)) {
      break;
    }

    totalAllocations += allocation.allocatedHours;
  }

  return totalAllocations;
};

export const getProjectRole = (projectRole: string) => {
  if (projectRole === PROJECT_OWNER) {
    return intl.get(
      `PEOPLE.RESOURCE_ALLOCATION.PARTICIPANT_PROJECT_ROLE.PROJECT_OWNER`
    );
  }
  const targetRole = Object.entries(PROJECT_PARTICIPANT_ROLE).find(
    (entry) => entry[1] === projectRole
  );
  if (targetRole) {
    return intl.get(
      `PEOPLE.RESOURCE_ALLOCATION.PARTICIPANT_PROJECT_ROLE.${targetRole[0]}`
    );
  }
  return '';
};

export const formatUsersDataForCsvExport = (
  usersArray: string[],
  sections: objKeyAsString
) => {
  return usersArray.map((userId: string) => {
    let user: {
      data: Owner;
      roles: ProjectParticipantRole[];
      participantType: string;
    } = { data: {}, roles: [], participantType: '' };
    for (const section of Object.values(sections)) {
      if (section.users[userId]) {
        user = {
          data: section.users[userId].data,
          roles: section.users[userId].roles,
          participantType: section.id,
        };
      }
    }
    return user;
  });
};

export const generateCsvFileName = (projectData: NewProject) => {
  const prefix = intl.get('PEOPLE.TABS.RESOURCE_ALLOCATION').replace(' ', '_');
  const projectNumber = formatProjectNumber(
    parseInt(projectData.projectNumber!)
  );
  const title = projectData.title.trim().replace(' ', '_');
  const date = moment().format('YYYYMMDD');

  return `${prefix}_${projectNumber}_${title}_${date}.csv`;
};

export const generateCsvHeaders = (weeksSlideArray: Week[]) => {
  const headers: string[] = [
    intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.HEADERS.PARTICIPANT_TYPE'),
    intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.HEADERS.NAME'),
    intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.HEADERS.PROJECT_ROLE'),
  ];
  for (const week of weeksSlideArray) {
    headers.push(week.label);
  }
  headers.push(
    intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.HEADERS.PARTICIPANT_TOTAL')
  );
  return headers;
};

export const generateCsvData = (
  weeksSlideArray: Week[],
  users: {
    data: Owner;
    roles: ProjectParticipantRole[];
    participantType: string;
  }[] = []
) => {
  const csvData: objKeyAsString[] = [];
  const weeklyTotalRow: objKeyAsString = {
    [intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.HEADERS.PARTICIPANT_TYPE')]: '',
    [intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.HEADERS.NAME')]: '',
    [intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.HEADERS.PROJECT_ROLE')]:
      intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.HEADERS.WEEKLY_TOTAL'),
  };
  for (const week of weeksSlideArray) {
    weeklyTotalRow[week.label] = 0;
  }

  for (const user of users) {
    for (const role of user.roles) {
      const csvRow: objKeyAsString = {};
      csvRow[
        intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.HEADERS.PARTICIPANT_TYPE')
      ] = intl.get(`PEOPLE.RESOURCE_ALLOCATION.TABLE.${user.participantType}`, {
        num: 0,
      });
      csvRow[
        intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.HEADERS.NAME')
      ] = `${user.data.data?.firstName} ${user.data.data?.lastName}`;
      csvRow[
        intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.HEADERS.PROJECT_ROLE')
      ] = getProjectRole(role.role);
      let start = 0;
      let totalParticipant = 0;
      if (role.allocations && !role.estimation) {
        for (const allocation of role.allocations) {
          totalParticipant += allocation.allocatedHours;
          for (let i = start; i < weeksSlideArray.length; i++) {
            const week = weeksSlideArray[i];
            const isAllocationBetweenCurrentWeek = confirmAllocationBetweenWeek(
              week,
              {
                weekStart: new Date(allocation.weekStart),
                weekEnd: new Date(allocation.weekEnd),
              }
            );
            if (isAllocationBetweenCurrentWeek) {
              start = i + 1;
              csvRow[week.label] = allocation.allocatedHours;
              weeklyTotalRow[week.label] =
                weeklyTotalRow[week.label] + allocation.allocatedHours;
              break;
            } else {
              csvRow[week.label] = '';
            }
          }
        }
        if (start < weeksSlideArray.length) {
          for (let j = start; j < weeksSlideArray.length; j++) {
            csvRow[weeksSlideArray[j].label] = '';
          }
        }
      } else {
        totalParticipant = parseFloat(role.estimation!.estimated_hours);
        for (let i = start; i < weeksSlideArray.length; i++) {
          const estimationStartWeek = moment(
            role.estimation?.start_date
          ).startOf('isoWeek');
          const week = weeksSlideArray[i];
          if (
            moment(estimationStartWeek).isSameOrAfter(week.startWeekDate) &&
            moment(estimationStartWeek).isSameOrBefore(week.endWeekDate)
          ) {
            csvRow[week.label] = `${role.estimation?.estimated_hours} (${moment(
              role.estimation?.start_date
            ).format(DATE.SHORT_FORMAT)} - ${moment(
              role.estimation?.end_date
            ).format(DATE.SHORT_FORMAT)})`;
          } else {
            csvRow[week.label] = '';
          }
        }
      }
      csvRow[
        intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.HEADERS.PARTICIPANT_TOTAL')
      ] = totalParticipant;
      csvData.push(csvRow);
    }
  }
  csvData.push(weeklyTotalRow);
  return csvData;
};
