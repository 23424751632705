import React, { MouseEvent } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { selectLearningTeams } from 'state/LearningTeams/learningTeamsSlice';
import { selectUserById } from 'state/UsersManagement/usersManagementSlice';
import { getUserAccessOnProject } from '../../helpers/getUserParticipantType';
import {
  Table,
  Button,
  Icon,
  Tooltip,
  OverflowMenu,
  OverflowMenuItem,
  Typography,
  tailwindOverride,
} from '@getsynapse/design-system';
import { Project } from 'utils/customTypes';
import {
  setSortingOrders,
  mySearch,
  myProjectsTableFilters,
} from 'state/Projects/projectsSlice';
import { setProjectId } from 'state/Project/projectSlice';
import { isUserOrganizationAdmin, selectIsUserLd } from 'state/User/userSlice';
import {
  PROJECTS_TABLE_SORTABLE_COLUMNS,
  PROJECTS_TABLE_TABS,
  PROJECT_STATUS,
  DATE,
  PROJECT_PARTICIPANT_TYPE,
  PROJECTS_LIST_ACTIONS,
  PATHS,
} from 'utils/constants';
import { SortingType } from 'utils/customTypes';
import {
  getStatusColumn,
  getOwnerColumn,
  getProjectBusinessUnitColumn,
  getTeamsColumn,
  getPriorityLabel,
} from '../../helpers/tableColumnsValues';
import {
  isProjectDueDatePast,
  isNotStartedProjectStartDatePast,
} from '../../helpers/helpers';
import emptyProjectsTable from 'assets/icons/empty-projects.svg';
import emptyFilteredProjects from 'assets/images/empty-filtered-projects-table.svg';
import noProjectsFound from 'assets/images/no-projects-found-table.svg';
import informationIcon from 'assets/icons/information.svg';
import HealthLabel from 'Molecules/HealthLabel';
import RenderNoRecords from 'Pages/RequestsListPage/components/NoRecords';
import UnarchiveIcon from 'assets/icons/unarchive.svg';
import ProjectSideDrawerToggle from '../ProjectSideDrawerToggle';
import { Link, useHistory } from 'react-router-dom';
import { formatProjectNumber } from 'utils/formatters';

const MyProjectsTable: React.FC<{
  projectsList: Project[];
  addNewProject?: () => void;
  onDuplicateProject: (projectId: string) => void;
  onArchiveProject: (projectId: string) => void;
  totalProjects?: number;
}> = ({
  projectsList,
  addNewProject,
  onDuplicateProject,
  onArchiveProject,
  totalProjects = 0,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isProjectsListEmpty = projectsList.length === 0;
  const searchValue = useSelector(mySearch);
  const filterValues = useSelector(myProjectsTableFilters);
  const isLDUser = useSelector(selectIsUserLd);
  const userData = useSelector(selectUserById);
  const ldTeams = useSelector(selectLearningTeams);
  const isAdmin = useSelector(isUserOrganizationAdmin);

  const handleSort = (orderByParam: string, order: SortingType) => {
    dispatch(
      setSortingOrders(
        { order, orderBy: orderByParam },
        PROJECTS_TABLE_TABS.MY_PROJECTS
      )
    );
  };

  const onOpenProjectPanel = async (projectId: string) => {
    await dispatch(setProjectId(projectId));
  };

  const goToProjectPage = (projectId: string) =>
    history.push(`${PATHS.PROJECT_PAGE}/${projectId}`, {
      from: history.location.pathname,
    });

  const getActionsHeader = () => [
    {
      content: <div></div>,
      className: classnames('bg-primary-lightest h-10', {
        'w-12': !isProjectsListEmpty && isLDUser,
        'w-6': !isProjectsListEmpty && !isLDUser,
        'right-0 z-2': !isProjectsListEmpty,
      }),
    },
  ];

  const getActionsRow = (
    isReadOnly: boolean,
    isProjectOwner: boolean,
    project: Project,
    stickyCellsStyles: (className: string) => string
  ) => {
    const overflowMenuItems: {
      label: string;
      iconName?: string;
      iconSrc?: string;
      onSelect: () => void;
      action: typeof PROJECTS_LIST_ACTIONS[keyof typeof PROJECTS_LIST_ACTIONS];
    }[] = [
      {
        label: intl.get('DUPLICATE_PROJECT_MODAL.TITLE'),
        iconName: 'copy-outline',
        onSelect: () => onDuplicateProject(project.id),
        action: PROJECTS_LIST_ACTIONS.DUPLICATE,
      },
    ];

    const canUserArchiveOrUnarchiveProject =
      project.isProjectPartOfUserPrograms || isAdmin || isProjectOwner;

    if (!project.is_archived && canUserArchiveOrUnarchiveProject) {
      overflowMenuItems.push({
        label: intl.get('ARCHIVE_PROJECT_MODAL.TITLE', {
          action: intl.get('ARCHIVE'),
        }),
        iconName: 'archive',
        onSelect: () => onArchiveProject(project.id),
        action: PROJECTS_LIST_ACTIONS.ARCHIVE,
      });
    }

    if (project.is_archived && canUserArchiveOrUnarchiveProject) {
      overflowMenuItems.push({
        label: intl.get('ARCHIVE_PROJECT_MODAL.TITLE', {
          action: intl.get('UNARCHIVE'),
        }),
        iconSrc: UnarchiveIcon,
        onSelect: () => onArchiveProject(project.id),
        action: PROJECTS_LIST_ACTIONS.UNARCHIVE,
      });
    }

    return [
      {
        content: (
          <div
            className={classnames(
              'h-full text-center relative flex space-x-2',
              {
                'w-12': !isProjectsListEmpty && isLDUser,
                'w-6': !isProjectsListEmpty && !isLDUser,
                'w-full': isProjectsListEmpty,
              }
            )}
            onClick={(event: MouseEvent<HTMLInputElement>) =>
              event.stopPropagation()
            }
          >
            <ProjectSideDrawerToggle
              projectId={project.id}
              onOpenProjectPanel={onOpenProjectPanel}
            />
            {isLDUser && (
              <OverflowMenu
                menuListProps={{
                  'data-cy': `project-${project.id}__actions-popover`,
                }}
                menuButtonProps={{
                  'data-cy': `project-${project.id}__actions-popover-trigger`,
                }}
              >
                {overflowMenuItems.map((item) => (
                  <OverflowMenuItem
                    key={item.label}
                    className='flex items-center space-x-2'
                    onSelect={item.onSelect}
                    data-cy={`project-${project.id}__actions-popover__${item.action}`}
                  >
                    <Icon
                      name={item.iconName || ''}
                      src={item.iconSrc || ''}
                      className={tailwindOverride(
                        'fill-current text-primary',
                        'group-hover:fill-current group-hover:text-secondary-darker',
                        'text-lg',
                        {
                          'text-xl': item.iconSrc,
                        }
                      )}
                    />
                    <Typography
                      variant='label'
                      className={tailwindOverride(
                        'group-hover:text-secondary-darker',
                        'cursor-pointer'
                      )}
                    >
                      {item.label}
                    </Typography>
                  </OverflowMenuItem>
                ))}
              </OverflowMenu>
            )}
          </div>
        ),
        className: stickyCellsStyles(
          classnames({
            'w-12': !isProjectsListEmpty && isLDUser,
            'w-6': !isProjectsListEmpty && !isLDUser,
            'right-0': !isProjectsListEmpty,
          })
        ),
      },
    ];
  };

  return (
    <div
      className={classnames('w-full rounded-b', {
        'overflow-auto border border-neutral-lighter-two': !isProjectsListEmpty,
        'overflow-hidden': isProjectsListEmpty,
        'max-h-no-filtered-table-body': isEmpty(filterValues),
        'max-h-filtered-table-body': !isEmpty(filterValues),
      })}
      id='my-projects-table'
    >
      <Table
        className={classnames('relative', {
          'max-w-full border-0': !isProjectsListEmpty,
          'w-full': isProjectsListEmpty,
        })}
        canSelectRows={false}
        data={{
          headData: {
            stickyHeader: true,
            handleSort,
            headCells: [
              {
                'data-cy': 'm-header__project-number',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.PROJECT_NUMBER,
                content: intl.get(
                  'PROJECTS_LIST_PAGE.TABLE.HEAD.PROJECT_NUMBER'
                ),
                className: classnames('h-10', {
                  'w-32 left-0 z-2': !isProjectsListEmpty,
                }),
              },
              {
                'data-cy': 'm-header__project-name',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.TITLE,
                content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.PROJECT_NAME'),
                className: classnames('h-10', {
                  'w-72 left-40 z-2': !isProjectsListEmpty,
                }),
              },
              {
                'data-cy': 'm-header__status',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.STATUS,
                content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.STATUS'),
                className: classnames('h-10', {
                  'w-28 left-120 z-2': !isProjectsListEmpty,
                }),
              },
              {
                'data-cy': 'm-header__priority',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.PRIORITY,
                content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.PRIORITY'),
                className: classnames({ 'w-32': !isProjectsListEmpty }),
              },
              {
                'data-cy': 'm-header__owner',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.PROJECT_OWNER,
                content: intl.get(
                  'PROJECTS_LIST_PAGE.TABLE.HEAD.PROJECT_OWNER'
                ),
                className: classnames({ 'w-44': !isProjectsListEmpty }),
              },
              {
                'data-cy': 'm-header__health',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.HEALTH,
                content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.HEALTH'),
                className: classnames({ 'w-32': !isProjectsListEmpty }),
              },
              {
                'data-cy': 'm-header__business-unit',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.BUSINESS_UNIT,
                content: intl.get(
                  'PROJECTS_LIST_PAGE.TABLE.HEAD.BUSINESS_UNIT'
                ),
                className: classnames({ 'w-56': !isProjectsListEmpty }),
              },
              {
                'data-cy': 'm-header__teams',
                content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.TEAMS'),
                className: classnames('bg-primary-lightest', {
                  'w-40': !isProjectsListEmpty,
                }),
              },
              {
                'data-cy': 'm-header__category',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.PROJECT_CATEGORY,
                content: intl.get(
                  'PROJECTS_LIST_PAGE.TABLE.HEAD.PROJECT_CATEGORY'
                ),
                className: classnames({ 'w-48': !isProjectsListEmpty }),
              },
              {
                'data-cy': 't-header__start-date',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.START_DATE,
                content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.START_DATE'),
                className: classnames({ 'w-32': !isProjectsListEmpty }),
              },
              {
                'data-cy': 't-header__target-completion-date',
                columnName:
                  PROJECTS_TABLE_SORTABLE_COLUMNS.TARGET_COMPLETION_DATE,
                content: intl.get(
                  'PROJECTS_LIST_PAGE.TABLE.HEAD.TARGET_COMPLETION_DATE'
                ),
                className: classnames({ 'w-36': !isProjectsListEmpty }),
              },
              {
                'data-cy': 't-header__actual-completion-date',
                columnName:
                  PROJECTS_TABLE_SORTABLE_COLUMNS.ACTUAL_COMPLETION_DATE,
                content: intl.get(
                  'PROJECTS_LIST_PAGE.TABLE.HEAD.ACTUAL_COMPLETION_DATE'
                ),
                className: classnames({ 'w-40': !isProjectsListEmpty }),
              },
              ...getActionsHeader(),
            ],
          },
          rows: projectsList.map((project: Project, index: number) => {
            const { isReadOnly, permissionLevel } = getUserAccessOnProject(
              project,
              userData,
              ldTeams
            );
            const isOdd = index % 2;
            const isInProgressOrCompleted =
              project.status === PROJECT_STATUS.IN_PROGRESS ||
              project.status === PROJECT_STATUS.COMPLETED;

            const projectDueDateIsPast = isProjectDueDatePast(
              project.status,
              project.targetCompletionDate
            );

            const notStartedProjectStartDateIsPast =
              isNotStartedProjectStartDatePast(
                project.status,
                project.startDate
              );

            const stickyCellsStyles = (className: string) =>
              classnames(
                'border-transparent',
                {
                  'sticky z-1': !isProjectsListEmpty,
                  'bg-neutral-white': !isOdd,
                  'bg-neutral-lightest-two': isOdd,
                },
                className
              );

            return {
              'data-cy': `project-${project.id}`,
              id: project.id,
              className: 'group cursor-pointer',
              onClick: () => goToProjectPage(project.id),
              cells: [
                {
                  content: (
                    <div
                      className={classnames({ 'w-32': !isProjectsListEmpty })}
                    >
                      {formatProjectNumber(project.projectNumber)}
                    </div>
                  ),
                  className: stickyCellsStyles(
                    classnames({ 'w-32 left-0': !isProjectsListEmpty })
                  ),
                },
                {
                  content: (
                    <div
                      className={classnames('truncate', {
                        'w-72': !isProjectsListEmpty,
                        'w-full': isProjectsListEmpty,
                      })}
                    >
                      <Link
                        to={{
                          pathname: `${PATHS.PROJECT_PAGE}/${project.id}`,
                          state: {
                            from: history.location.pathname,
                          },
                        }}
                        title={project.title}
                      >
                        {project.title}
                      </Link>
                    </div>
                  ),
                  className: stickyCellsStyles(
                    classnames({ 'w-72 left-40': !isProjectsListEmpty })
                  ),
                },
                {
                  content: (
                    <div
                      className={classnames({ 'w-28': !isProjectsListEmpty })}
                    >
                      {getStatusColumn(project.status)}
                    </div>
                  ),
                  className: stickyCellsStyles(
                    classnames({ 'w-28 left-120': !isProjectsListEmpty })
                  ),
                },
                {
                  content: getPriorityLabel(project.priority),
                  className: classnames({ 'w-36': !isProjectsListEmpty }),
                },
                {
                  content: project.owners && getOwnerColumn(project.owners),
                  className: classnames({ 'w-40': !isProjectsListEmpty }),
                },
                {
                  content: isInProgressOrCompleted && (
                    <HealthLabel health={project?.health} />
                  ),
                  className: classnames({ 'w-36': !isProjectsListEmpty }),
                },
                {
                  content: (
                    <div className='w-56 truncate'>
                      {getProjectBusinessUnitColumn(
                        project.businessTeams || []
                      )}
                    </div>
                  ),
                  className: classnames({
                    'w-56': !isProjectsListEmpty,
                  }),
                },
                {
                  content: (
                    <div className='w-40 truncate'>
                      {getTeamsColumn(project.privacy!, project.ldteams || [])}
                    </div>
                  ),
                  className: classnames({
                    'w-40': !isProjectsListEmpty,
                  }),
                },
                {
                  content: project.category,
                },
                {
                  content: project.startDate && (
                    <div
                      className={
                        notStartedProjectStartDateIsPast
                          ? 'text-warning-darker flex items-center'
                          : ''
                      }
                    >
                      {moment(new Date(project.startDate)).format(
                        DATE.SHORT_FORMAT
                      )}
                      {notStartedProjectStartDateIsPast && (
                        <Tooltip
                          openMode='hover1'
                          position='topCenter'
                          className='ml-auto'
                          timeout={0}
                          showPopper
                          trigger={
                            <span>
                              <Icon src={informationIcon} />
                            </span>
                          }
                          contentProps={{
                            className:
                              'bg-warning-lightest text-warning-darker rounded text-sm w-60 whitespace-normal z-50',
                          }}
                        >
                          {intl.getHTML(`PROJECTS_LIST_PAGE.PAST_START_DATE`)}
                        </Tooltip>
                      )}
                    </div>
                  ),
                  className: classnames({ 'w-32': !isProjectsListEmpty }),
                },
                {
                  content: project.targetCompletionDate && (
                    <span
                      className={
                        projectDueDateIsPast
                          ? 'bg-error-lighter text-error-dark'
                          : ''
                      }
                    >
                      {moment(new Date(project.targetCompletionDate)).format(
                        DATE.SHORT_FORMAT
                      )}
                    </span>
                  ),
                  className: classnames({ 'w-32': !isProjectsListEmpty }),
                },
                {
                  content:
                    project?.actualDate &&
                    moment(new Date(project.actualDate)).format(
                      DATE.SHORT_FORMAT
                    ),
                  className: classnames({ 'w-32': !isProjectsListEmpty }),
                },
                ...getActionsRow(
                  isReadOnly,
                  permissionLevel === PROJECT_PARTICIPANT_TYPE.OWNER,
                  project,
                  stickyCellsStyles
                ),
              ],
            };
          }),
          total: totalProjects,
        }}
        data-cy='projects-table'
        emptyComponent={
          !isEmpty(searchValue) || !isEmpty(filterValues) ? (
            <RenderNoRecords
              imageSrc={
                !isEmpty(searchValue) ? noProjectsFound : emptyFilteredProjects
              }
              className={
                !isEmpty(searchValue)
                  ? 'h-empty-table-body'
                  : 'h-empty-filtered-table-body'
              }
              caption={intl.get('REQUESTS_LIST_PAGE.NO_RECORDS')}
            />
          ) : (
            <RenderNoRecords
              className='h-empty-table-body'
              caption={intl.get('PROJECTS_LIST_PAGE.TABLE.EMPTY')}
              imageSrc={emptyProjectsTable}
              imageClassName='-ml-4'
              labelClassName='mt-0'
            >
              <Button className='mt-2 mx-auto' onClick={addNewProject}>
                {intl.get('PROJECTS_LIST_PAGE.TABLE.GET_STARTED')}
              </Button>
            </RenderNoRecords>
          )
        }
      />
    </div>
  );
};

export default MyProjectsTable;
