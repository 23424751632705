import { Typography } from '@getsynapse/design-system';
import { formatStatusTitle } from 'Pages/InsightsPage/Projects/ByStatus/ByStatusBarChart';

interface TooltipRowMap {
  [key: string]: string;
}

const TOOLTIP_ROW_MAP: TooltipRowMap = {
  Bar: 'fill',
  Line: 'stroke',
};

const TooltipRow = ({ data, chartType }: { data: any; chartType: string }) => (
  <>
    {data.map((item: any, index: number) => (
      <div key={`${item.name}-${index}`} className='flex flex-row mb-auto'>
        <div
          className='border border-transparent rounded-sm w-4 h-4'
          style={{ background: item[TOOLTIP_ROW_MAP[chartType]] }}
        />
        <Typography variant='label' className='text-neutral-dark ml-2'>
          {`${item.name} : ${item.value}`}
        </Typography>
      </div>
    ))}
  </>
);

export const ProjectsCustomTooltip = ({
  active,
  payload,
  label,
  chartType,
}: {
  active?: any;
  payload?: any;
  label?: string;
  chartType: string;
}) => {
  if (active && payload && payload.length) {
    return (
      <div className='flex flex-col bg-neutral-white px-2 py-2 border border-neutral-lighter-two shadow-allocation-table rounded-sm'>
        <div className='flex flex-row mb-2'>
          <Typography
            variant='label'
            className='text-neutral-dark font-semibold'
          >
            {formatStatusTitle(label)}
          </Typography>
        </div>

        <TooltipRow data={payload} chartType={chartType} />
      </div>
    );
  }

  return null;
};
