import intl from 'react-intl-universal';
import get from 'lodash/get';
import set from 'lodash/set';
import TaskModal from 'Organisms/TaskModal/TaskModal';
import { useDispatch } from 'react-redux';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { duplicateTask } from 'state/ProjectTasks/projectTaskSlice';
import { Task } from 'utils/customTypes';
import { TaskFormFields } from 'utils/types/task';

interface DuplicateTaskModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  taskData: Task;
}

const DuplicateTaskModal: React.FC<DuplicateTaskModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  taskData,
}) => {
  const dispatch = useDispatch();

  const onDuplicateTask = async (newTaskData: TaskFormFields) => {
    if (!newTaskData.start_date) {
      set(newTaskData, 'start_date', null);
    }
    if (!newTaskData.due_date) {
      set(newTaskData, 'due_date', null);
    }
    const duplicatedTaskData = await dispatch(
      duplicateTask({
        taskId: taskData.id,
        newTaskData: newTaskData,
      })
    );
    const duplicatedTaskId = get(duplicatedTaskData, 'payload.id', '');
    if (duplicatedTaskId != null) {
      await dispatch(
        showNotificationBanner({
          notificationVariant: 'success',
          notificationText: intl.get(
            'TASKS.NOTIFICATIONS.TASK_DUPLICATION_SUCCESS'
          ),
        })
      );
    }
  };

  return (
    <TaskModal
      isOpen={isModalOpen}
      setIsOpen={setIsModalOpen}
      taskData={taskData}
      onSaveTask={onDuplicateTask}
    />
  );
};

export default DuplicateTaskModal;
