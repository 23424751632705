import { useCallback } from 'react';
import { Tabs } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import FormSummary from './FormSummary';
import FormDesign from './FormDesign/FormDesign';
import {
  Form,
  UserAvatars,
  FormOption,
  RequestQuestion,
} from 'utils/customTypes';

type FormDetailsProps = {
  formObj: Form;
  handleChangeField: (
    value: string | string[] | boolean,
    pathToUpdate: string
  ) => void;
  ldUsers: UserAvatars[];
  onTabChange?: (currentIndex: number) => void;
  currentTab?: number;
  formQuestions: RequestQuestion[];
  setFormQuestions: React.Dispatch<React.SetStateAction<RequestQuestion[]>>;
  errors?: {
    title: boolean;
    description: boolean;
    requestType: boolean;
    businessTeams: boolean;
    owners: boolean;
  };
  businessTeamsOptions: FormOption[];
};

const FormDetailsTabs = ({
  formObj,
  handleChangeField,
  ldUsers,
  onTabChange = () => {},
  currentTab = 0,
  formQuestions,
  setFormQuestions,
  errors,
  businessTeamsOptions,
}: FormDetailsProps) => {
  const handleTabChange = useCallback(
    (index: number) => {
      onTabChange(index);
    },
    [onTabChange]
  );

  return (
    <Tabs
      tabListProps={{ className: 'w-full flex-grow' }}
      tabPanelsProps={{
        className: 'max-h-page-body pb-10 overflow-y-auto',
      }}
      data={[
        {
          label: intl.get('SETTINGS_PAGE.FORMS.SUMMARY.TITLE'),
          content: (
            <FormSummary
              formObj={formObj}
              handleChangeField={handleChangeField}
              ldUsers={ldUsers}
              errors={errors}
              businessTeamsOptions={businessTeamsOptions}
            />
          ),
        },
        {
          label: intl.get('SETTINGS_PAGE.FORMS.DESIGN.TITLE'),
          content: (
            <FormDesign
              formQuestions={formQuestions}
              setFormQuestions={setFormQuestions}
            />
          ),
        },
      ]}
      index={currentTab}
      onChange={handleTabChange}
      type='tab'
    />
  );
};

export default FormDetailsTabs;
