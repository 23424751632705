import React from 'react';
import { useInView } from 'react-intersection-observer';
import debounce from 'lodash/debounce';
import { tailwindOverride } from '@getsynapse/design-system';
import { VIEW_SETTING_VALUES } from 'utils/constants/capacityView';
import useScrollHandlerContext from 'Pages/TeamsPage/views/TeamsCapacity/components/CapacityTable/components/ScrollHandler/useScrollHandlerContext';
import NoDailyCapacity from 'Pages/TeamsPage/views/TeamsCapacity/components/DailyCapacity/components/DaysCarousel/components/NoDailyCapacity/NoDailyCapacity';
import NoWeeklyCapacity from 'Pages/TeamsPage/views/TeamsCapacity/components/BetaWeeklyCapacity/components/NoWeeklyCapacity/NoWeeklyCapacity';

const LastProjectRow: React.FC<{
  teamId: string;
  memberId: string;
  onLastProjectRowInView: (inView: boolean) => void;
}> = ({ teamId, memberId, onLastProjectRowInView }) => {
  const { rootId, capacityView } = useScrollHandlerContext();
  const debouncedOnChangeHandler = debounce(
    (inView: boolean) => onLastProjectRowInView(inView),
    500
  );
  const { ref: elementRef } = useInView({
    root: rootId,
    threshold: 0.5,
    rootMargin: '-80px 0px 0px',
    onChange: debouncedOnChangeHandler,
  });
  return (
    <div
      ref={elementRef}
      className='w-full h-26 flex'
      aria-label={`team-${teamId}__member-${memberId}__last-project`}
    >
      <div className='w-2/6 h-full flex'>
        <div className='w-10 h-full' />
        <div
          className={tailwindOverride(
            'w-2/3 h-full',
            'py-5 pl-10 pr-2',
            'flex flex-col gap-y-1',
            'animate-pulse'
          )}
        >
          <div className='w-1/2 h-4 rounded-sm bg-neutral-lighter-two' />
          <div className='w-3/4 h-4 rounded-sm bg-neutral-lighter-two' />
          <div className='w-2/3 h-4 rounded-sm bg-neutral-lighter-two' />
        </div>
        <div
          className={tailwindOverride(
            'w-1/3 h-full',
            'px-2 pt-5 pb-4',
            'flex flex-col gap-y-2',
            'animate-pulse'
          )}
        >
          <div className='w-3/4 h-4 rounded-sm bg-neutral-lighter-two' />
          <div className='w-2/3 h-4 rounded-sm bg-neutral-lighter-two' />
        </div>
      </div>
      <div className='w-4/6 h-full flex'>
        {capacityView === VIEW_SETTING_VALUES.DAILY && (
          <NoDailyCapacity
            id={`team-${teamId}__member-${memberId}__last-project`}
            isLoading
          />
        )}
        {(capacityView === VIEW_SETTING_VALUES.BETA_WEEKLY ||
          capacityView === VIEW_SETTING_VALUES.WEEKLY) && (
          <NoWeeklyCapacity
            id={`team-${teamId}__member-${memberId}__last-project`}
            isLoading
          />
        )}
      </div>
    </div>
  );
};

export default LastProjectRow;
