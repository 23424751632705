import React from 'react';
import intl from 'react-intl-universal';
import { FormItem, UsersPicker, Datepicker } from '@getsynapse/design-system';
import { REQUEST_TABLE_FILTERS } from 'utils/constants';
import { UserAvatars, Option, rangeDateType } from 'utils/customTypes';
import {
  getInitialDatePickerValue,
  getInitialUsersPickerValue,
  getInitialValueForDropDown,
} from 'utils/functions';
import {
  RangeFilter,
  RequestFilters,
  RequestFiltersKey,
} from 'utils/types/filters';
import { isArrayOfOptions, isDateRangeObject } from 'utils/typeGuards';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';
import useRequestFilters from 'Pages/RequestsListPage/hooks/useRequestFilters';

const FiltersForm: React.FC<{
  filters: RequestFilters;
  onUpdateFilters: (newFilters: RequestFilters) => void;
}> = ({ filters, onUpdateFilters }) => {
  const { getFilterOptionsByKey } = useRequestFilters();
  const statusOptions = getFilterOptionsByKey(REQUEST_TABLE_FILTERS.STATUS);
  const requesterOptions = getFilterOptionsByKey(
    REQUEST_TABLE_FILTERS.REQUESTER
  ) as UserAvatars[];
  const ownerOptions = getFilterOptionsByKey(
    REQUEST_TABLE_FILTERS.OWNERS
  ) as UserAvatars[];
  const projectOptions = getFilterOptionsByKey(REQUEST_TABLE_FILTERS.PROJECTS);
  const businessUnitOptions = getFilterOptionsByKey(
    REQUEST_TABLE_FILTERS.BUSINESS_TEAMS
  );
  const priorityOptions = getFilterOptionsByKey(REQUEST_TABLE_FILTERS.PRIORITY);
  const formOptions = getFilterOptionsByKey(REQUEST_TABLE_FILTERS.FORM);
  const linkedProjectOptions = getFilterOptionsByKey(
    REQUEST_TABLE_FILTERS.LINKED_PROJECTS
  );

  const isDateRangeFilterEmpty = (filter: RangeFilter) =>
    filter.from === null && filter.to === null;

  const updateFilters = (
    filterKey: RequestFiltersKey,
    value: Option[] | rangeDateType | string,
    rangeFilterKey: 'from' | 'to' | null = null
  ) => {
    let filtersCopy = { ...filters } as RequestFilters;
    if (isArrayOfOptions(value)) {
      if (value.length === 0) {
        delete filtersCopy[filterKey];
      } else {
        filtersCopy[filterKey] = value.map((option) => option.value);
      }
    } else if (isDateRangeObject(value)) {
      const filterValue = value as rangeDateType;
      filtersCopy[filterKey] = {
        ...(filtersCopy[filterKey] as RangeFilter),
        [rangeFilterKey as string]: filterValue.startDate
          ? filterValue.startDate.toString()
          : null,
      };
      if (isDateRangeFilterEmpty(filtersCopy[filterKey] as RangeFilter)) {
        delete filtersCopy[filterKey];
      }
    }
    onUpdateFilters(filtersCopy);
  };

  return (
    <div className='mt-8 mb-12 flex flex-col space-y-5'>
      <FormItem label={intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.STATUS')}>
        <MultiSelectDropdown
          options={statusOptions}
          values={getInitialValueForDropDown(
            statusOptions,
            filters[REQUEST_TABLE_FILTERS.STATUS] as string[]
          )}
          onChange={(options: Option[]) =>
            updateFilters(REQUEST_TABLE_FILTERS.STATUS, options)
          }
          triggerProps={{
            'aria-label': intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.STATUS'),
            'data-cy': 'requests-filters__status-picker',
          }}
          listProps={{ 'data-cy': 'requests-filters__status__options-list' }}
        />
      </FormItem>
      <FormItem
        label={intl.get('REQUEST_PAGE.BASIC_DETAILS.FIELDS.REQUEST_CREATOR')}
      >
        <UsersPicker
          usersList={requesterOptions}
          onChange={(users) =>
            updateFilters(REQUEST_TABLE_FILTERS.REQUESTER, users)
          }
          selectedUsersList={getInitialUsersPickerValue(
            requesterOptions,
            filters[REQUEST_TABLE_FILTERS.REQUESTER] as string[]
          )}
          triggerText={intl.get(
            'REQUEST_PAGE.BASIC_DETAILS.FIELDS.REQUEST_CREATOR_PLACEHOLDER'
          )}
          triggerProps={{
            'aria-label': intl.get(
              'REQUEST_PAGE.BASIC_DETAILS.FIELDS.REQUEST_CREATOR'
            ),
            'data-cy': 'requests-filters__requester-picker',
          }}
          popperProps={{
            'data-cy': 'requests-filters__requester__options-list',
          }}
          allowSelectDeactivated
        />
      </FormItem>
      <FormItem
        label={intl.get('REQUEST_PAGE.BASIC_DETAILS.FIELDS.REQUEST_OWNER')}
      >
        <UsersPicker
          usersList={ownerOptions}
          onChange={(users) =>
            updateFilters(REQUEST_TABLE_FILTERS.OWNERS, users)
          }
          selectedUsersList={getInitialUsersPickerValue(
            ownerOptions,
            filters[REQUEST_TABLE_FILTERS.OWNERS] as string[]
          )}
          triggerText={intl.get(
            'REQUEST_PAGE.BASIC_DETAILS.FIELDS.REQUEST_OWNER_PLACEHOLDER'
          )}
          triggerProps={{
            'aria-label': intl.get(
              'REQUEST_PAGE.BASIC_DETAILS.FIELDS.REQUEST_OWNER'
            ),
            'data-cy': 'requests-filters__owner-picker',
          }}
          popperProps={{
            'data-cy': 'requests-filters__owner__options-list',
          }}
          allowSelectDeactivated
        />
      </FormItem>
      <FormItem
        label={intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.LINKED_PROJECTS')}
      >
        <MultiSelectDropdown
          options={projectOptions}
          filterable
          values={getInitialValueForDropDown(
            projectOptions,
            filters[REQUEST_TABLE_FILTERS.PROJECTS] as string[]
          )}
          onChange={(options: Option[]) =>
            updateFilters(REQUEST_TABLE_FILTERS.PROJECTS, options)
          }
          triggerProps={{
            'aria-label': intl.get(
              'REQUESTS_LIST_PAGE.TABLE.HEAD.LINKED_PROJECTS'
            ),
            'data-cy': 'requests-filters__projects-picker',
          }}
          listProps={{ 'data-cy': 'requests-filters__projects__options-list' }}
        />
      </FormItem>
      <FormItem label={intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.BUSINESS_UNIT')}>
        <MultiSelectDropdown
          options={businessUnitOptions}
          values={getInitialValueForDropDown(
            businessUnitOptions,
            filters[REQUEST_TABLE_FILTERS.BUSINESS_TEAMS] as string[]
          )}
          onChange={(options: Option[]) =>
            updateFilters(REQUEST_TABLE_FILTERS.BUSINESS_TEAMS, options)
          }
          triggerProps={{
            'aria-label': intl.get(
              'REQUESTS_LIST_PAGE.TABLE.HEAD.BUSINESS_UNIT'
            ),
            'data-cy': 'requests-filters__business-teams-picker',
          }}
          listProps={{
            'data-cy': 'requests-filters__business-teams__options-list',
          }}
        />
      </FormItem>
      <FormItem label={intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.PRIORITY')}>
        <MultiSelectDropdown
          options={priorityOptions}
          values={getInitialValueForDropDown(
            priorityOptions,
            filters[REQUEST_TABLE_FILTERS.PRIORITY] as string[]
          )}
          onChange={(options: Option[]) =>
            updateFilters(REQUEST_TABLE_FILTERS.PRIORITY, options)
          }
          triggerProps={{
            'aria-label': intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.PRIORITY'),
            'data-cy': 'requests-filters__priority-picker',
          }}
          listProps={{
            'data-cy': 'requests-filters__priority__options-list',
          }}
        />
      </FormItem>
      <FormItem label={intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.FORM')}>
        <MultiSelectDropdown
          options={formOptions}
          values={getInitialValueForDropDown(
            formOptions,
            filters[REQUEST_TABLE_FILTERS.FORM] as string[]
          )}
          onChange={(options: Option[]) =>
            updateFilters(REQUEST_TABLE_FILTERS.FORM, options)
          }
          triggerProps={{
            'aria-label': intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.FORM'),
            'data-cy': 'requests-filters__forms-picker',
          }}
          listProps={{
            'data-cy': 'requests-filters__forms__options-list',
          }}
        />
      </FormItem>
      <FormItem
        label={intl.get(
          'REQUESTS_LIST_PAGE.TABLE.LINKED_PROJECTS.PICKER_LABEL'
        )}
      >
        <MultiSelectDropdown
          options={linkedProjectOptions}
          values={getInitialValueForDropDown(
            linkedProjectOptions,
            filters[REQUEST_TABLE_FILTERS.LINKED_PROJECTS] as string[]
          )}
          onChange={(options: Option[]) =>
            updateFilters(REQUEST_TABLE_FILTERS.LINKED_PROJECTS, options)
          }
          triggerProps={{
            'aria-label': intl.get(
              'REQUESTS_LIST_PAGE.TABLE.LINKED_PROJECTS.PICKER_LABEL'
            ),
            'data-cy': 'requests-filters__linked-projects-picker',
          }}
          listProps={{
            'data-cy': 'requests-filters__linked-projects__options-list',
          }}
        />
      </FormItem>
      <FormItem label={intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.CREATION_DATE')}>
        <div className='w-full flex space-x-4'>
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.FROM')}
            startDate={getInitialDatePickerValue(
              'from',
              filters[REQUEST_TABLE_FILTERS.CREATED_AT] as RangeFilter
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(REQUEST_TABLE_FILTERS.CREATED_AT, date, 'from')
            }
            inputProps={{
              'aria-label': `${intl.get(
                'REQUESTS_LIST_PAGE.TABLE.HEAD.CREATION_DATE'
              )}__from-range`,
              'data-cy': 'requests-filters__creation-date__from-range',
            }}
          />
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.TO')}
            startDate={getInitialDatePickerValue(
              'to',
              filters[REQUEST_TABLE_FILTERS.CREATED_AT] as RangeFilter
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(REQUEST_TABLE_FILTERS.CREATED_AT, date, 'to')
            }
            inputProps={{
              'aria-label': `${intl.get(
                'REQUESTS_LIST_PAGE.TABLE.HEAD.CREATION_DATE'
              )}__to-range`,
              'data-cy': 'requests-filters__creation-date__to-range',
            }}
          />
        </div>
      </FormItem>
      <FormItem
        label={intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.SUBMISSION_DATE')}
      >
        <div className='w-full flex space-x-4'>
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.FROM')}
            startDate={getInitialDatePickerValue(
              'from',
              filters[REQUEST_TABLE_FILTERS.SUBMITTED_AT] as RangeFilter
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(REQUEST_TABLE_FILTERS.SUBMITTED_AT, date, 'from')
            }
            inputProps={{
              'aria-label': `${intl.get(
                'REQUESTS_LIST_PAGE.TABLE.HEAD.SUBMISSION_DATE'
              )}__from-range`,
              'data-cy': 'requests-filters__submission-date__from-range',
            }}
          />
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.TO')}
            startDate={getInitialDatePickerValue(
              'to',
              filters[REQUEST_TABLE_FILTERS.SUBMITTED_AT] as RangeFilter
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(REQUEST_TABLE_FILTERS.SUBMITTED_AT, date, 'to')
            }
            inputProps={{
              'aria-label': `${intl.get(
                'REQUESTS_LIST_PAGE.TABLE.HEAD.SUBMISSION_DATE'
              )}__to-range`,
              'data-cy': 'requests-filters__submission-date__to-range',
            }}
          />
        </div>
      </FormItem>
      <FormItem label={intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.DECISION_DATE')}>
        <div className='w-full flex space-x-4'>
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.FROM')}
            startDate={getInitialDatePickerValue(
              'from',
              filters[REQUEST_TABLE_FILTERS.DECISION_DATE] as RangeFilter
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(REQUEST_TABLE_FILTERS.DECISION_DATE, date, 'from')
            }
            inputProps={{
              'aria-label': `${intl.get(
                'REQUESTS_LIST_PAGE.TABLE.HEAD.DECISION_DATE'
              )}__from-range`,
              'data-cy': 'requests-filters__decision-date__from-range',
            }}
          />
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.TO')}
            startDate={getInitialDatePickerValue(
              'to',
              filters[REQUEST_TABLE_FILTERS.DECISION_DATE] as RangeFilter
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(REQUEST_TABLE_FILTERS.DECISION_DATE, date, 'to')
            }
            inputProps={{
              'aria-label': `${intl.get(
                'REQUESTS_LIST_PAGE.TABLE.HEAD.DECISION_DATE'
              )}__to-range`,
              'data-cy': 'requests-filters__decision-date__to-range',
            }}
          />
        </div>
      </FormItem>
      <FormItem label={intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.UPDATE_DATE')}>
        <div className='w-full flex space-x-4'>
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.FROM')}
            startDate={getInitialDatePickerValue(
              'from',
              filters[REQUEST_TABLE_FILTERS.UPDATED_AT] as RangeFilter
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(REQUEST_TABLE_FILTERS.UPDATED_AT, date, 'from')
            }
            inputProps={{
              'aria-label': `${intl.get(
                'REQUESTS_LIST_PAGE.TABLE.HEAD.UPDATE_DATE'
              )}__from-range`,
              'data-cy': 'requests-filters__update-date__from-range',
            }}
          />
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.TO')}
            startDate={getInitialDatePickerValue(
              'to',
              filters[REQUEST_TABLE_FILTERS.UPDATED_AT] as RangeFilter
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(REQUEST_TABLE_FILTERS.UPDATED_AT, date, 'to')
            }
            inputProps={{
              'aria-label': `${intl.get(
                'REQUESTS_LIST_PAGE.TABLE.HEAD.UPDATE_DATE'
              )}__to-range`,
              'data-cy': 'requests-filters__update-date__to-range',
            }}
          />
        </div>
      </FormItem>
    </div>
  );
};

export default FiltersForm;
