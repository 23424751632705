import {
  Button,
  Dropdown,
  Tag,
  FormLabel,
  Icon,
  IconButton,
} from '@getsynapse/design-system';
import { formatRequestIdentifier } from 'Pages/helpers';
import { useEffect, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRequestsForDropdown,
  selectRequestsForDropdown,
} from 'state/Requests/requestSlice';
import { DropdownRequest, Option } from 'utils/customTypes';

type Props = {
  linkedRequests: DropdownRequest[];
  updateLinkedRequests: (
    requests: DropdownRequest[],
    isAdding: boolean
  ) => void;
  onFinish: () => void;
};

const RequestsDropdown = ({
  linkedRequests,
  updateLinkedRequests,
  onFinish,
}: Props) => {
  const dispatch = useDispatch();

  const requestsSelector = useSelector(selectRequestsForDropdown);

  const [selectedRequests, setSelectedRequests] = useState<Option[]>([]);

  const requestsOptions = useMemo(() => {
    if (!requestsSelector) return [];
    return requestsSelector
      ?.filter(
        (request) =>
          !linkedRequests?.some(
            (linkedRequest) => linkedRequest.id === request.id
          )
      )
      .map((request) => ({
        label: `${formatRequestIdentifier(request.requestIdentifier!)} - ${
          request.title
        }`,
        value: request.id,
      }));
  }, [requestsSelector, linkedRequests]);

  useEffect(() => {
    dispatch(getRequestsForDropdown());
  }, [dispatch]);

  const clearSelectedRequests = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setSelectedRequests([]);
  };

  const AddRequests = () => {
    const newAddedRequests = requestsSelector.filter((request) =>
      selectedRequests.some(
        (selectedRequest) => selectedRequest.value === request.id
      )
    );
    updateLinkedRequests(newAddedRequests, true);
    setSelectedRequests([]);
    onFinish();
  };

  return (
    <>
      <FormLabel className='mb-2' htmlFor='requests_dropdown'>
        {intl.get('INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.REQUESTS_LABEL')}
      </FormLabel>
      <div className='flex items-start'>
        <Dropdown
          id='requests_dropdown'
          triggerProps={{
            'data-testid': 'requests_dropdown_trigger',
          }}
          listProps={{
            'data-testid': 'requests_dropdown_list',
          }}
          options={requestsOptions}
          values={selectedRequests}
          filterable
          placeholder={intl.get(
            'INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.REQUESTS_PLACEHOLDER'
          )}
          renderSelectedOptionTag={({ label, value, ...restOfProps }) => (
            <Tag
              {...restOfProps}
              key={label}
              label={label.split('-').slice(1).join('-')}
            />
          )}
          renderTriggerIcon={() => (
            <IconButton
              name='close-circle'
              className='text-neutral-dark'
              onClick={clearSelectedRequests}
              disabled={selectedRequests.length === 0}
              description={intl.get(
                'INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.CLEAR_ALL'
              )}
            />
          )}
          onChange={(option) => setSelectedRequests(option)}
          multiple
          triggerLeadingIcon={
            <Icon name='search' className='text-neutral w-6 h-6' />
          }
        />
        {linkedRequests?.length > 0 && (
          <Button
            variant='tertiary'
            className='ml-2 relative top-2'
            onClick={onFinish}
          >
            {intl.get('CANCEL')}
          </Button>
        )}
        <Button
          variant='tertiary'
          className='ml-2 relative top-2'
          disabled={selectedRequests.length === 0}
          onClick={AddRequests}
          data-testid='link-request_button'
        >
          {intl.get('INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.LINK_REQUESTS')}
        </Button>
      </div>
    </>
  );
};

export default RequestsDropdown;
