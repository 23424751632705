import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import {
  NewProject,
  TaskDetailType,
  UpdateReqData,
  TimeOffData,
} from 'utils/customTypes';

interface sidePanelState {
  type: string;
  isOpen: boolean;
  resourceId: string;
  sidePanelUpdatedData:
    | NewProject
    | UpdateReqData
    | TaskDetailType
    | TimeOffData
    | null;
  isViewOnlyMode: boolean;
  fromPage?: string;
}

/* ============================= INITIAL STATE ============================== */

const initialState: sidePanelState = {
  type: '',
  isOpen: false,
  resourceId: '',
  sidePanelUpdatedData: null,
  isViewOnlyMode: false,
  fromPage: '',
};

/* ============================== REDUX THUNK =============================== */

export const openPanel = createAsyncThunk(
  'sidePanel/OPEN_PANEL',
  async ({
    resourceType,
    resourceId,
    isViewOnlyMode = false,
    fromPage,
  }: {
    resourceType: string;
    resourceId: string;
    isViewOnlyMode?: boolean;
    fromPage?: string;
  }) => {
    return {
      resourceType,
      resourceId,
      isViewOnlyMode,
      fromPage,
    };
  }
);

/* ================================= ACTIONS ================================ */

export const setSidePanelUpdatedData = createAction(
  'sidePanel/SET_SIDE_PANEL_UPDATED_DATA',
  (updateData) => {
    return { payload: { updateData } };
  }
);

export const resetSidePanelUpdatedData = createAction(
  'project/RESET_SIDE_PANEL_UPDATED_DATA'
);

export const closePanel = createAction('sidePanel/CLOSE_PANEL');
/* ================================= REDUCER ================================ */

const sidePanelSlice = createSlice({
  name: 'sidePanel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(openPanel.fulfilled, (state, action) => {
        state.isOpen = true;
        state.type = action.payload.resourceType;
        state.resourceId = action.payload.resourceId;
        state.isViewOnlyMode = action.payload.isViewOnlyMode;
        state.fromPage = action.payload.fromPage;
      })
      .addCase(closePanel, (state, action) => {
        state.isOpen = false;
        state.type = '';
        state.resourceId = '';
      })
      .addCase(setSidePanelUpdatedData, (state, action) => {
        state.sidePanelUpdatedData = action.payload.updateData;
      })
      .addCase(resetSidePanelUpdatedData, (state) => {
        state.sidePanelUpdatedData = null;
      });
  },
});

/* =============================== SELECTORS ================================ */
export const selectSidePanelData = (state: RootState) => {
  return {
    isOpen: state.sidePanel.isOpen,
    type: state.sidePanel.type,
    resourceId: state.sidePanel.resourceId,
    isViewOnlyMode: state.sidePanel.isViewOnlyMode,
    fromPage: state.sidePanel.fromPage,
  };
};

export const selectSidePanelUpdatedData = (state: RootState) =>
  state.sidePanel.sidePanelUpdatedData;

export default sidePanelSlice.reducer;
