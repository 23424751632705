import {
  Modal,
  Typography,
  Button,
  Tab,
  TabList,
  Radio,
  Dropdown,
  TextField,
} from '@getsynapse/design-system';
import { DELETE_PROGRAM_OPTIONS } from 'utils/constants';
import intl from 'react-intl-universal';
import { TabPanel, TabPanels, Tabs } from '@reach/tabs';
import React, { useEffect, useMemo, useState } from 'react';
import { Program, Option, DeleteProgramFlag } from 'utils/customTypes';
import get from 'lodash/get';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { selectAllPrograms, fetchPrograms } from 'state/Programs/programsSlice';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  program: Program;
  deleteProgram: (flag: DeleteProgramFlag, newProgramId?: string) => void;
};

const DeleteProgramModal = ({
  isOpen,
  setIsOpen,
  program,
  deleteProgram,
}: Props) => {
  const dispatch = useDispatch();

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [checked, setChecked] = useState<string>(
    DELETE_PROGRAM_OPTIONS.UNLINK_PROJECTS
  );
  const [newProgram, setNewProgram] = useState<{
    id: string;
    title: string;
  } | null>(null);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] =
    useState<boolean>(true);

  const allPrograms = useSelector(selectAllPrograms);

  const programsOptions = useMemo(
    () =>
      allPrograms
        .filter((p) => p.id !== program.id)
        .map((p) => ({
          label: p.title,
          value: p.id,
        })),
    [allPrograms, program.id]
  );
  const hasProjectsLinkedToOtherPrograms = useMemo(
    () =>
      program.programProjects.some((project) => {
        const linkedPrograms = get(project, 'projectPrograms', []);
        return linkedPrograms.length > 1;
      }),
    [program.programProjects]
  );

  useEffect(() => {
    dispatch(fetchPrograms());
  }, [dispatch]);

  useEffect(() => {
    if (checked !== DELETE_PROGRAM_OPTIONS.MOVE_PROJECTS && newProgram) {
      setNewProgram(null);
    }
  }, [checked, newProgram]);

  const closeModal = () => {
    setChecked(DELETE_PROGRAM_OPTIONS.UNLINK_PROJECTS);
    setActiveIndex(0);
    setIsOpen(false);
    setIsDeleteButtonDisabled(true);
  };

  const handleDeleteConfirmation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    if (checked === DELETE_PROGRAM_OPTIONS.MOVE_PROJECTS) {
      if (program.title === value && isDeleteButtonDisabled && newProgram) {
        setIsDeleteButtonDisabled(false);
      } else if (
        (program.title !== value || !newProgram) &&
        !isDeleteButtonDisabled
      ) {
        setIsDeleteButtonDisabled(true);
      }
    } else {
      if (program.title === value && isDeleteButtonDisabled) {
        setIsDeleteButtonDisabled(false);
      } else if (program.title !== value && !isDeleteButtonDisabled) {
        setIsDeleteButtonDisabled(true);
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={intl.get('PROGRAM_PAGE.DELETE_PROGRAM_MODAL.TITLE')}
      titleIcon={{ name: 'trash' }}
      aria-label={intl.get('PROGRAM_PAGE.DELETE_PROGRAM_MODAL.TITLE')}
      closeModal={closeModal}
      size='large'
      actionButtons={[]}
      childrenClassName='max-h-157.5'
      data-cy='delete-program-modal'
    >
      <Tabs index={activeIndex} onChange={(index) => setActiveIndex(index)}>
        <TabList>
          <Tab index={0}>
            {intl.get('PROGRAM_PAGE.DELETE_PROGRAM_MODAL.STEP_ONE.TAB')}
          </Tab>
          <Tab index={1}>
            {intl.get('PROGRAM_PAGE.DELETE_PROGRAM_MODAL.STEP_TWO.TAB')}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel key={0}>
            <Typography className='mb-6 mt-4'>
              {intl.get(
                'PROGRAM_PAGE.DELETE_PROGRAM_MODAL.STEP_ONE.DESCRIPTION'
              )}
            </Typography>
            <Radio
              name='delete-options'
              label={intl.get(
                'PROGRAM_PAGE.DELETE_PROGRAM_MODAL.STEP_ONE.DELETE_OPTIONS.UNLINK'
              )}
              value={DELETE_PROGRAM_OPTIONS.UNLINK_PROJECTS}
              inputProps={{
                checked: checked === DELETE_PROGRAM_OPTIONS.UNLINK_PROJECTS,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                  setChecked(event.target.value),
              }}
              className='mb-4'
              data-cy='unlink-projects-radio'
            />
            <Radio
              name='delete-options'
              label={intl.get(
                'PROGRAM_PAGE.DELETE_PROGRAM_MODAL.STEP_ONE.DELETE_OPTIONS.MOVE'
              )}
              value={DELETE_PROGRAM_OPTIONS.MOVE_PROJECTS}
              inputProps={{
                checked: checked === DELETE_PROGRAM_OPTIONS.MOVE_PROJECTS,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                  setChecked(event.target.value),
              }}
              className='mb-4'
              disabled={programsOptions.length === 0}
              data-cy='move-projects-radio'
            />
            <Radio
              name='delete-options'
              label={intl.get(
                'PROGRAM_PAGE.DELETE_PROGRAM_MODAL.STEP_ONE.DELETE_OPTIONS.DELETE'
              )}
              value={DELETE_PROGRAM_OPTIONS.DELETE_PROJECTS}
              inputProps={{
                checked: checked === DELETE_PROGRAM_OPTIONS.DELETE_PROJECTS,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                  setChecked(event.target.value),
              }}
              disabled={hasProjectsLinkedToOtherPrograms}
              data-cy='delete-projects-radio'
            />
            <Typography className='mt-6 mb-4'>
              {intl.get(
                `PROGRAM_PAGE.DELETE_PROGRAM_MODAL.STEP_ONE.${
                  checked === DELETE_PROGRAM_OPTIONS.DELETE_PROJECTS
                    ? 'DELETED'
                    : checked === DELETE_PROGRAM_OPTIONS.UNLINK_PROJECTS
                    ? 'UNLINKED'
                    : 'MOVED'
                }_PROJECTS_TEXT`,
                {
                  numberOfProjects: program.programProjects.length,
                }
              )}
            </Typography>
            {checked === DELETE_PROGRAM_OPTIONS.DELETE_PROJECTS ||
            checked === DELETE_PROGRAM_OPTIONS.UNLINK_PROJECTS ? (
              <ul
                className='border p-2 border-primary-lighter rounded overflow-y-auto max-h-43'
                data-cy='projects-list'
              >
                {program.programProjects.map((project, index) => (
                  <li
                    key={project.id}
                    className={classNames('p-2', {
                      'border-t border-primary-lighter': index > 0,
                    })}
                  >
                    {project.title}
                  </li>
                ))}
              </ul>
            ) : (
              <Dropdown
                options={programsOptions}
                onChange={(option: Option) => {
                  setNewProgram({ id: option.value, title: option.label });
                }}
                triggerProps={{
                  placeholder: intl.get(
                    'PROGRAM_PAGE.DELETE_PROGRAM_MODAL.STEP_ONE.PROGRAMS_DROPDOWN_PLACEHOLDER'
                  ),
                  'data-cy': 'select-program-dropdown',
                }}
              />
            )}
            <div className='flex mt-8'>
              <Button
                className='mr-4'
                onClick={() => setActiveIndex(1)}
                disabled={
                  checked === DELETE_PROGRAM_OPTIONS.MOVE_PROJECTS &&
                  !newProgram
                }
                data-cy='continue-button'
              >
                {intl.get('CONTINUE')}
              </Button>
              <Button
                variant='secondary'
                onClick={closeModal}
                data-cy='setp-one-cancel-buuton'
              >
                {intl.get('CANCEL')}
              </Button>
            </div>
          </TabPanel>
          <TabPanel key={1}>
            <Typography className='my-4'>
              {intl.get(
                'PROGRAM_PAGE.DELETE_PROGRAM_MODAL.STEP_TWO.DESCRIPTION'
              )}
            </Typography>
            <TextField
              placeholder={intl.get(
                'PROGRAM_PAGE.DELETE_PROGRAM_MODAL.STEP_TWO.PROGRAM_NAME_PLACEHOLDER'
              )}
              onChange={handleDeleteConfirmation}
              data-cy='confirm-program-name-input'
            />
            <Typography className='mt-4'>
              {intl.getHTML(
                `PROGRAM_PAGE.DELETE_PROGRAM_MODAL.STEP_TWO.${
                  checked === DELETE_PROGRAM_OPTIONS.DELETE_PROJECTS
                    ? 'DELETED'
                    : checked === DELETE_PROGRAM_OPTIONS.UNLINK_PROJECTS
                    ? 'UNLINKED'
                    : 'MOVED'
                }_PROJECTS_TEXT`,
                {
                  numberOfProjects: program.programProjects.length,
                  newProgramTitle: newProgram?.title,
                }
              )}
            </Typography>
            <div className='flex mt-8'>
              <Button
                className='mr-4'
                onClick={() => deleteProgram(checked, newProgram?.id)}
                disabled={isDeleteButtonDisabled}
                data-cy='confirm-delete-button'
              >
                {intl.get(
                  'PROGRAM_PAGE.DELETE_PROGRAM_MODAL.STEP_TWO.DELETE_BUTTON'
                )}
              </Button>
              <Button
                variant='secondary'
                onClick={closeModal}
                data-cy='step-two-cancel-button'
              >
                {intl.get('CANCEL')}
              </Button>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Modal>
  );
};

export default DeleteProgramModal;
