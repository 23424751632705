import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import debounce from 'lodash/debounce';
import { tailwindOverride } from '@getsynapse/design-system';
import { RootState } from 'state/store';
import { selectCurrentViewSettingValue } from 'state/ViewSettings/viewSettingsSlice';
import { selectTeamById } from 'state/Capacities/TeamsList/teamsListSlice';
import {
  VIEW_SETTING,
  VIEW_SETTING_VALUES,
} from 'utils/constants/capacityView';
import useScrollHandlerContext from 'Pages/TeamsPage/views/TeamsCapacity/components/CapacityTable/components/ScrollHandler/useScrollHandlerContext';
import TeamData from '../TeamData/TeamData';
import TeamDailyCapacity from 'Pages/TeamsPage/views/TeamsCapacity/components/DailyCapacity/components/Teams/TeamDailyCapacities/TeamDailyCapacities';
import TeamWeeklyCapacities from 'Pages/TeamsPage/views/TeamsCapacity/components/BetaWeeklyCapacity/components/Teams/TeamWeeklyCapacities/TeamWeeklyCapacities';

const TeamRow: React.FC<{
  teamId: string;
  updateTeamRowInView: (teamId: string, inView: boolean) => void;
}> = ({ teamId, updateTeamRowInView }) => {
  const { rootId } = useScrollHandlerContext();
  const team = useSelector((state: RootState) => selectTeamById(state, teamId));
  const savedTeamsCapacityView = useSelector(
    selectCurrentViewSettingValue(VIEW_SETTING)
  );
  const rootMargin = useMemo(() => {
    if (savedTeamsCapacityView.value !== VIEW_SETTING_VALUES.DAILY) {
      return '-40px 0px 0px';
    }
    return '-80px 0px 0px';
  }, [savedTeamsCapacityView.value]);
  const debouncedOnChangeHandler = debounce(
    (inView: boolean) => updateTeamRowInView(teamId, inView),
    500
  );
  const { ref: elementRef } = useInView({
    root: rootId,
    threshold: 0.5,
    rootMargin,
    onChange: debouncedOnChangeHandler,
  });
  if (!team) {
    return null;
  }
  const isTeamExpanded = team.expanded || false;

  return (
    <div
      ref={elementRef}
      className={tailwindOverride(
        'w-full h-14 flex bg-neutral-white cursor-pointer',
        'team-row'
      )}
      style={{
        boxShadow:
          '0px -1px 0px 0px rgb(var(--color-neutral-lighter-two)) inset',
      }}
      id={`team-${teamId}__row`}
      data-team-id={teamId}
      aria-expanded={isTeamExpanded}
      aria-controls={`team-${teamId}__members-list`}
    >
      <TeamData
        teamId={teamId}
        teamName={team.name}
        isExpanded={isTeamExpanded}
      />
      {savedTeamsCapacityView.value === VIEW_SETTING_VALUES.DAILY && (
        <TeamDailyCapacity id={teamId} />
      )}
      {(savedTeamsCapacityView.value === VIEW_SETTING_VALUES.BETA_WEEKLY ||
        savedTeamsCapacityView.value === VIEW_SETTING_VALUES.WEEKLY) && (
        <TeamWeeklyCapacities id={teamId} />
      )}
    </div>
  );
};

export default TeamRow;
