import { useMemo, useCallback, FC, useEffect } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { AppliedFilters, tailwindOverride } from '@getsynapse/design-system';
import { CentralizedTasksTableTab } from 'utils/types/centralizedTasks';
import { TaskListFilters } from 'types/store/tasksList';
import {
  fetchAvailablePrograms,
  fetchAvailableProjects,
  searchUsers,
  selectSearchAssigneesResult,
  selectSearchProgramResult,
  selectSearchProjectResult,
} from 'state/TasksList/Search/searchSlice';

interface AppliedFiltersTagsProps {
  filters: TaskListFilters;
  onUpdateFilters: (filters: TaskListFilters) => void;
  tableName: CentralizedTasksTableTab;
}

interface RangeFilterParams {
  from?: string;
  to?: string;
  key: string;
}

interface AppliedFiltersTag {
  key: keyof TaskListFilters;
  label: string;
  value: string;
}

const AppliedFiltersTags: FC<AppliedFiltersTagsProps> = ({
  filters,
  onUpdateFilters,
  tableName,
}) => {
  const taskAssignees = useSelector(selectSearchAssigneesResult);
  const taskProjects = useSelector(selectSearchProjectResult);
  const taskPrograms = useSelector(selectSearchProgramResult);

  const dateFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }),
    []
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (filters.user && filters.user.length > 0) {
      dispatch(searchUsers({ userIds: filters.user }));
    }
  }, [filters.user, dispatch]);

  useEffect(() => {
    if (filters.project && filters.project.length > 0) {
      dispatch(fetchAvailableProjects({ projectIds: filters.project }));
    }
  }, [filters.project, dispatch]);

  useEffect(() => {
    if (filters.program && filters.program.length > 0) {
      dispatch(fetchAvailablePrograms({ programIds: filters.program }));
    }
  }, [filters.program, dispatch]);

  const getAssigneesTagFilter = useCallback(
    (ids: string[]) => {
      if (Object.keys(taskAssignees).length === 0) {
        return [];
      }
      return ids.map<AppliedFiltersTag>((id) => ({
        key: 'user',
        value: id,
        label: taskAssignees[id]?.name || 'N/A',
      }));
    },
    [taskAssignees]
  );

  const getProjectsTagFilter = useCallback(
    (ids: string[]) => {
      if (Object.keys(taskProjects).length === 0) {
        return [];
      }
      return ids.map<AppliedFiltersTag>((id) => ({
        key: 'project',
        value: id,
        label: taskProjects[id]?.name || 'N/A',
      }));
    },
    [taskProjects]
  );

  const getProgramsTagFilter = useCallback(
    (ids: string[]) => {
      if (Object.keys(taskPrograms).length === 0) {
        return [];
      }
      return ids.map<AppliedFiltersTag>((id) => ({
        key: 'program',
        value: id,
        label: taskPrograms[id]?.name || 'N/A',
      }));
    },
    [taskPrograms]
  );

  const getStatusTagFilter = useCallback((status: string[]) => {
    return status.map<AppliedFiltersTag>((id) => ({
      key: 'status',
      value: id,
      label: intl.get(
        `TASKS.TASK_DETAIL_PAGE.STATUS_OPTIONS.${id.toUpperCase()}`
      ),
    }));
  }, []);

  const getTypeTagFilter = useCallback((types: string[]) => {
    return types.map<AppliedFiltersTag>((id) => ({
      key: 'type',
      value: id,
      label: intl.get(`TASKS.TASK_TYPES.${id.toUpperCase()}`),
    }));
  }, []);

  const getDateRangeTagFilter = useCallback(
    ({ from, to, key }: RangeFilterParams) => {
      let label = '';

      if (from) {
        label = dateFormatter.format(new Date(from));
      }

      if (from && to) {
        label += ` - ${dateFormatter.format(new Date(to))}`;
      }

      if (!from && to) {
        label = dateFormatter.format(new Date(to));
      }

      return {
        key,
        value: '',
        label,
      };
    },
    [dateFormatter]
  );

  const getHoursRangeTagFilter = useCallback(
    ({ from, to, key }: RangeFilterParams) => {
      let label = '';
      if (from) {
        label = `${from}`;
      }
      if (from && to) {
        label += ` - ${to}`;
      }
      if (!from && to) {
        label = `${to}`;
      }
      if (label.length > 0) {
        label += ' hrs';
      }
      return {
        key,
        value: '',
        label,
      };
    },
    []
  );

  const appliedFilters = useMemo(() => {
    const tags: AppliedFiltersTag[] = [];
    if (filters.disabled) {
      tags.push({
        key: 'disabled',
        value: '',
        label: intl.get(
          `TASKS.TASK_DETAIL_PAGE.ADDITIONAL_FLAGS_LABELS.DISABLED`
        ),
      });
    }

    if (filters.user && filters.user.length > 0) {
      const assignees = getAssigneesTagFilter(filters.user);
      tags.push(...assignees);
    }

    if (filters.project && filters.project.length > 0) {
      const projects = getProjectsTagFilter(filters.project);
      tags.push(...projects);
    }

    if (filters.program) {
      const programs = getProgramsTagFilter(filters.program);
      tags.push(...programs);
    }

    if (filters.status && filters.status.length > 0) {
      const status = getStatusTagFilter(filters.status);
      tags.push(...status);
    }

    if (filters.type && filters.type.length > 0) {
      const types = getTypeTagFilter(filters.type);
      tags.push(...types);
    }

    if (filters.startDateFrom || filters.startDateTo) {
      const dateRange = getDateRangeTagFilter({
        from: filters.startDateFrom,
        to: filters.startDateTo,
        key: 'startDateFrom',
      }) as AppliedFiltersTag;
      tags.push(dateRange);
    }

    if (filters.dueDateFrom || filters.dueDateTo) {
      const dateRange = getDateRangeTagFilter({
        from: filters.dueDateFrom,
        to: filters.dueDateTo,
        key: 'dueDateFrom',
      }) as AppliedFiltersTag;
      tags.push(dateRange);
    }

    if (filters.completionDateFrom || filters.completionDateTo) {
      const dateRange = getDateRangeTagFilter({
        from: filters.completionDateFrom,
        to: filters.completionDateTo,
        key: 'completionDateFrom',
      }) as AppliedFiltersTag;
      tags.push(dateRange);
    }

    if (filters.estimateHoursFrom || filters.estimateHoursTo) {
      const hoursRange = getHoursRangeTagFilter({
        from: filters.estimateHoursFrom,
        to: filters.estimateHoursTo,
        key: 'estimateHoursFrom',
      }) as AppliedFiltersTag;
      tags.push(hoursRange);
    }

    if (filters.actualHoursFrom || filters.actualHoursTo) {
      const hoursRange = getHoursRangeTagFilter({
        from: filters.actualHoursFrom,
        to: filters.actualHoursTo,
        key: 'actualHoursFrom',
      }) as AppliedFiltersTag;
      tags.push(hoursRange);
    }

    return tags;
  }, [
    filters,
    getAssigneesTagFilter,
    getProjectsTagFilter,
    getProgramsTagFilter,
    getStatusTagFilter,
    getTypeTagFilter,
    getDateRangeTagFilter,
    getHoursRangeTagFilter,
  ]);

  const handleRemoveFilter = (appliedFilter: AppliedFiltersTag) => {
    const filterToRemove = filters[appliedFilter.key];
    let updatedFilters = { ...filters };
    if (Array.isArray(filterToRemove)) {
      updatedFilters = {
        ...filters,
        [appliedFilter.key]: filterToRemove.filter(
          (value) => value !== appliedFilter.value
        ),
      };
    }

    if (appliedFilter.key === 'disabled') {
      delete updatedFilters.disabled;
    }

    if (appliedFilter.key === 'startDateFrom') {
      delete updatedFilters.startDateFrom;
      delete updatedFilters.startDateTo;
    }

    if (appliedFilter.key === 'dueDateFrom') {
      delete updatedFilters.dueDateFrom;
      delete updatedFilters.dueDateTo;
    }

    if (appliedFilter.key === 'completionDateFrom') {
      delete updatedFilters.completionDateFrom;
      delete updatedFilters.completionDateTo;
    }

    if (appliedFilter.key === 'estimateHoursFrom') {
      delete updatedFilters.estimateHoursFrom;
      delete updatedFilters.estimateHoursTo;
    }

    if (appliedFilter.key === 'actualHoursFrom') {
      delete updatedFilters.actualHoursFrom;
      delete updatedFilters.actualHoursTo;
    }

    onUpdateFilters(updatedFilters);
  };

  return (
    <AppliedFilters<AppliedFiltersTag>
      filters={appliedFilters}
      onRemoveFilter={handleRemoveFilter}
      onClearAll={() => onUpdateFilters({})}
      className={tailwindOverride('border-primary-lighter-two z-20')}
      aria-label={`${tableName}__applied-filters`}
    />
  );
};

export default AppliedFiltersTags;
