import classNames from 'classnames';

const ChartSkeletonLoader = () => {
  const topDivs = (
    <div>
      <div className='bg-neutral-lighter animate-pulse w-40 h-4 mb-2'></div>
      <div className='bg-neutral-lighter animate-pulse w-40 h-4'></div>
    </div>
  );

  const renderBottomDivs = (widthClassname: string) => (
    <div className='flex mb-3.5 mr-9'>
      <div className='bg-neutral-lighter animate-pulse w-1/3 h-3.5 mb-2 mr-9'></div>
      <div
        className={classNames(
          'bg-neutral-lighter animate-pulse h-4',
          widthClassname
        )}
      ></div>
    </div>
  );

  return (
    <div data-testid='utilization-by-team-skeleton-loader' className='px-4'>
      <div className='flex justify-between mb-14'>
        {topDivs}
        {topDivs}
      </div>
      {renderBottomDivs('w-1/2')}
      {renderBottomDivs('w-1/5')}
      {renderBottomDivs('w-2/5')}
      {renderBottomDivs('w-3/5')}
      {renderBottomDivs('w-1/6')}
      {renderBottomDivs('w-2/6')}
      {renderBottomDivs('w-1/12')}
      {renderBottomDivs('w-4/12')}
      {renderBottomDivs('w-7/12')}
    </div>
  );
};

export default ChartSkeletonLoader;
