import { FC } from 'react';
import {
  Typography,
  Button,
  Avatar,
  Icon,
  tailwindOverride,
} from '@getsynapse/design-system';
import { Submitter } from 'types/store/actualHours';

interface AccordionTriggerProps {
  submitter: Submitter;
  totalHours: number;
  isExpanded: boolean;
  toggleIsExpanded: () => void;
}

const AccordionTrigger: FC<AccordionTriggerProps> = ({
  submitter,
  totalHours,
  isExpanded,
  toggleIsExpanded,
}) => {
  const splittedName = submitter.name.split(' ');
  const initials = `${splittedName[0].charAt(0)}${splittedName[1].charAt(0)}`;

  return (
    <Typography variant='h3'>
      <Button
        variant='tertiary'
        id={`submitter-${submitter.id}__accordion-trigger`}
        aria-label={`submitter-${submitter.id}__accordion-trigger`}
        aria-expanded={isExpanded}
        aria-controls={`submitter-${submitter.id}__accordion-content`}
        onClick={toggleIsExpanded}
        className={tailwindOverride(
          'flex items-center justify-between',
          'w-full p-0'
        )}
      >
        <div className='flex gap-x-2 items-center'>
          <div
            style={{
              transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
            }}
          >
            <Icon name='chevron-forward' className='color-neutral text-base' />
          </div>
          <Avatar size='small' name={submitter.name} initial={initials} />
          <Typography variant='body'>{submitter.name}</Typography>
        </div>
        <Typography variant='body' className='mr-14' weight='medium'>
          {`${totalHours}h`}
        </Typography>
      </Button>
    </Typography>
  );
};

export default AccordionTrigger;
