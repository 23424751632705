import { Modal, Typography, FormItem } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

type ApprovedRequestInfoModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  comments: string;
  approvalDate: string;
};

const ApprovedRequestInfoModal = ({
  isOpen,
  setIsOpen,
  comments,
  approvalDate,
}: ApprovedRequestInfoModalProps) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      aria-label='approve-request-info-modal'
      closeModal={handleClose}
      isOpen={isOpen}
      title={intl.get('REQUEST_PAGE.APPROVED_REQUEST_INFO_MODAL.TITLE')}
      size='medium'
      data-testid='approve-request-info-modal'
    >
      <FormItem
        label={intl.get('REQUEST_PAGE.APPROVED_REQUEST_INFO_MODAL.COMMENTS')}
      >
        <Typography variant='body2'>
          {comments
            ? comments
            : intl.get('REQUEST_PAGE.APPROVED_REQUEST_INFO_MODAL.NO_COMMENTS')}
        </Typography>
      </FormItem>
      <FormItem
        className='mt-6'
        label={intl.get(
          'REQUEST_PAGE.APPROVED_REQUEST_INFO_MODAL.APPROVAL_DATE'
        )}
      >
        <Typography variant='body2'>{approvalDate}</Typography>
      </FormItem>
    </Modal>
  );
};

export default ApprovedRequestInfoModal;
