import { useElevation, Typography } from '@getsynapse/design-system';
import externalLandingImg from 'assets/images/external-landing.svg';
import intl from 'react-intl-universal';

const ExternalUsersLandingPage = () => {
  const elevationClass = useElevation(1);

  return (
    <div
      className={`h-full flex flex-col items-center justify-center bg-neutral-white rounded mx-6 mb-10 ${elevationClass}`}
    >
      <img src={externalLandingImg} alt='' />
      <Typography variant='h3' className='mt-9 mb-2'>
        {intl.get('LANDING.DESIGN')}
      </Typography>
      <Typography>
        {intl.get('LANDING.EXTERNAL_USERS.BODY_LINE_ONE')}
      </Typography>
      <Typography>
        {intl.get('LANDING.EXTERNAL_USERS.BODY_LINE_TWO')}
      </Typography>
    </div>
  );
};

export default ExternalUsersLandingPage;
