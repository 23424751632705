import intl from 'react-intl-universal';
import { Tag } from '@getsynapse/design-system';
import { useMemo } from 'react';
import classnames from 'classnames';
import partyingFace from 'assets/icons/partying-face.svg';
import confusedFace from 'assets/icons/confused-face.svg';

type Props = {
  objectiveResult?: number;
  objectiveId: string;
  type: 'noResults' | 'objectiveMet' | 'objectiveNotMet' | 'monetaryBenefits';
  className?: string;
};

const getTagProperties = (type: Props['type'], objectiveResult?: number) => {
  let tagLabel;
  let backgroundClassName;
  let textClassName;
  let leadingIcon;

  switch (type) {
    case 'noResults':
      tagLabel = intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE_RESULT.NO_RESULTS'
      );
      backgroundClassName = 'bg-neutral-lighter-two';
      textClassName = 'text-neutral-dark';
      leadingIcon = undefined;
      break;
    case 'objectiveMet':
      tagLabel = intl.getHTML(
        'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE_RESULT.MET'
      );
      backgroundClassName = 'bg-secondary-lightest';
      textClassName = 'text-secondary-darker';
      leadingIcon = { src: partyingFace };
      break;
    case 'objectiveNotMet':
      tagLabel = intl.getHTML(
        'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE_RESULT.NOT_MET'
      );
      backgroundClassName = 'bg-tertiary-lightest';
      textClassName = 'text-tertiary-dark';
      leadingIcon = {
        src: confusedFace,
      };
      break;
    case 'monetaryBenefits':
      tagLabel = `${intl.get('DOLLAR_SIGN')}${objectiveResult?.toLocaleString(
        'en-US'
      )}`;
      backgroundClassName = 'bg-neutral-lighter-two';
      textClassName = 'text-neutral-dark';
      leadingIcon = undefined;
      break;
    default:
      tagLabel = '';
      backgroundClassName = '';
      textClassName = '';
      break;
  }

  return { tagLabel, backgroundClassName, textClassName, leadingIcon };
};

const ResultsTag = ({
  objectiveResult,
  objectiveId,
  type,
  className,
}: Props) => {
  const { tagLabel, backgroundClassName, textClassName, leadingIcon } = useMemo(
    () => getTagProperties(type, objectiveResult),
    [type, objectiveResult]
  );

  return (
    <Tag
      data-testid={`category-objective-${objectiveId}-${type}_result-tag`}
      label={tagLabel}
      className={classnames('py-1 px-2 my-0', backgroundClassName, className)}
      textClassName={classnames('font-semibold', textClassName)}
      leadingIcon={leadingIcon}
    />
  );
};

export default ResultsTag;
