import React from 'react';
import { Table, Typography } from '@getsynapse/design-system';
import { ResourceSummaryItem } from 'utils/customTypes';
import { getProjectRole } from '../../../helpers';
import intl from 'react-intl-universal';

const ResourceSummaryTable: React.FC<{ data: any }> = ({ data }) => (
  <div className='mt-6'>
    <Typography variant='h4'>
      {intl.get('PEOPLE.RESOURCE_SUMMARY.RESOURCE_SUMMARY_TABLE.TITLE')}
    </Typography>
    <Typography variant='body2' className='text-neutral-dark mt-1'>
      {intl.get('PEOPLE.RESOURCE_SUMMARY.RESOURCE_SUMMARY_TABLE.SUB_TITLE')}
    </Typography>
    <Table
      className='mt-4'
      numberOfHeadRows={2}
      data-cy='resource-summary-table'
      style={{ borderSpacing: 0 }}
      canSelectRows={false}
      bodyProps={{
        className: 'max-h-resource-summary overflow-y-auto block',
      }}
      data={{
        headData: [
          {
            rowProps: {
              className: 'table w-full table-fixed',
            },
            headCells: [
              {
                content: '',
              },
              {
                content: intl.get(
                  'PEOPLE.RESOURCE_SUMMARY.RESOURCE_SUMMARY_TABLE.MEMBERS_SECTION'
                ),
                className: 'text-center',
                colSpan: 3,
              },
              {
                content: intl.get(
                  'PEOPLE.RESOURCE_SUMMARY.RESOURCE_SUMMARY_TABLE.COLLABORATORS_SECTION'
                ),
                className: 'text-center',
                colSpan: 2,
              },
            ],
            className: 'sticky top-0 z-1',
          },
          {
            rowProps: {
              className: 'table w-full table-fixed',
            },
            headCells: [
              {
                content: intl.get(
                  'PEOPLE.RESOURCE_SUMMARY.RESOURCE_SUMMARY_TABLE.PROJECT_ROLE_COLUMN'
                ),
              },
              {
                content: intl.get(
                  'PEOPLE.RESOURCE_SUMMARY.RESOURCE_SUMMARY_TABLE.NO_OF_RESOURCE_COLUMN'
                ),
                className: 'text-right',
              },
              {
                content: intl.get(
                  'PEOPLE.RESOURCE_SUMMARY.RESOURCE_SUMMARY_TABLE.TOTAL_HOURS_COLUMN'
                ),
                className: 'text-right',
              },
              {
                content: intl.get(
                  'PEOPLE.RESOURCE_SUMMARY.RESOURCE_SUMMARY_TABLE.TOTAL_ACTUAL_HOURS'
                ),
                className: 'text-right',
              },
              {
                content: intl.get(
                  'PEOPLE.RESOURCE_SUMMARY.RESOURCE_SUMMARY_TABLE.NO_OF_RESOURCE_COLUMN'
                ),
                className: 'text-right',
              },
              {
                content: intl.get(
                  'PEOPLE.RESOURCE_SUMMARY.RESOURCE_SUMMARY_TABLE.TOTAL_HOURS_COLUMN'
                ),
                className: 'text-right',
              },
            ],
            className: 'sticky top-10 z-1',
          },
        ],
        rows: [
          ...data.items.map((summaryItem: ResourceSummaryItem) => {
            return {
              id: summaryItem.projectRole,
              className: 'table w-full table-fixed',
              cells: [
                { content: getProjectRole(summaryItem.projectRole) },
                {
                  content: summaryItem.numberOfResource,
                  className: 'text-right',
                },
                {
                  content: `${intl.get(
                    'PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION_TIME',
                    { allocation: summaryItem.totalHours }
                  )}`,
                  className: 'text-right',
                },
                {
                  content: `${intl.get(
                    'PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION_TIME',
                    { allocation: summaryItem.totalActualHours }
                  )}`,
                  className: 'text-right',
                },
                {
                  content: summaryItem.collaboratorsResourceNumber,
                  className: 'text-right',
                },
                {
                  content: `${intl.get(
                    'PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION_TIME',
                    { allocation: summaryItem.collaboratorsAllocatedHours }
                  )}`,
                  className: 'text-right',
                },
              ],
            };
          }),
          {
            id: 'summary-toal',
            className:
              'sticky bottom-0 bg-secondary-lightest-two hover:bg-secondary-lightest-two table w-full table-fixed',
            cells: [
              {
                content: intl.get(
                  'PEOPLE.RESOURCE_SUMMARY.RESOURCE_SUMMARY_TABLE.TOTAL'
                ),
                className:
                  'font-bold group-hover:border-neutral-lightest group-hover:shadow-cancel',
              },
              {
                content: data.total.totalNumberOfResource,
                className:
                  'font-semibold group-hover:border-neutral-lightest text-right group-hover:shadow-cancel',
              },
              {
                content: intl.get(
                  'PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION_TIME',
                  { allocation: data.total.totalHours }
                ),
                className:
                  'font-semibold group-hover:border-neutral-lightest text-right group-hover:shadow-cancel',
              },
              {
                content: intl.get(
                  'PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION_TIME',
                  { allocation: data.total.totalActualHours }
                ),
                className:
                  'font-semibold group-hover:border-neutral-lightest text-right group-hover:shadow-cancel',
              },
              {
                content: data.total.totalCollaboratorsResourceNumber,
                className:
                  'font-semibold group-hover:border-neutral-lightest text-right group-hover:shadow-cancel',
              },
              {
                content: intl.get(
                  'PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION_TIME',
                  {
                    allocation: data.total.totalCollaboratorsAllocatedHours,
                  }
                ),
                className:
                  'font-semibold group-hover:border-neutral-lightest text-right group-hover:shadow-cancel',
              },
            ],
          },
        ],
      }}
    />
  </div>
);

export default ResourceSummaryTable;
