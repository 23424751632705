import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FieldHookCallback, FlatfileResults } from '@flatfile/react';
import { IDictionary } from '@flatfile/adapter';
import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import isFinite from 'lodash/isFinite';
import toInteger from 'lodash/toInteger';
import { selectUser } from 'state/User/userSlice';
import { SelectOrganisationId } from 'state/Organization/organizationSlice';
import {
  selectBusinessTeamsForDropdown,
  getBusinessTeams,
} from 'state/BusinessTeams/businessTeamsSlice';
import {
  allUsers,
  bulkCreateUsers,
} from 'state/UsersManagement/usersManagementSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { EMAIL_REGEX, USER_IMPORT_ATTRIBUTES } from 'utils/constants';
import {
  flatfileBusinessUserSettings,
  cleanImportedUsers,
} from '../flatfileHelperUser';

const useImportBusinessUsers = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  const organizationId = useSelector(SelectOrganisationId);
  const savedUsers = useSelector(allUsers);
  const businessTeams = useSelector(selectBusinessTeamsForDropdown);

  useEffect(() => {
    dispatch(getBusinessTeams());
  }, [dispatch]);

  const hooks: IDictionary<FieldHookCallback> = {
    [USER_IMPORT_ATTRIBUTES.EMAIL]: (values) => {
      return values.map(([item, index]) => {
        const isEmailValid = isEmpty(item)
          ? false
          : EMAIL_REGEX.test(`${item}`);
        if (!isEmailValid) {
          return [
            {
              value: '',
              info: [
                {
                  message: intl.get(
                    'USERS_PAGE.TABLE.IMPORT.IMPORT_NOT_NUMBER'
                  ),
                  level: 'error',
                },
              ],
            },
            index,
          ];
        } else {
          const hasEmail = savedUsers.findIndex(
            (user) => user.data.email === item
          );
          if (hasEmail === -1) {
            return [{ value: item! }, index];
          }

          return [
            {
              value: '',
              info: [
                {
                  message: intl.get('USERS_PAGE.TABLE.IMPORT.REPEATED_EMAIL'),
                  level: 'error',
                },
              ],
            },
            index,
          ];
        }
      });
    },
    [USER_IMPORT_ATTRIBUTES.IS_ADMIN]: (values) => {
      return values.map(([item, index]) => [
        { value: item ? item : false },
        index,
      ]);
    },
    [USER_IMPORT_ATTRIBUTES.DEFAULT_CAPACITY]: (values) => {
      return values.map(([item, index]) => {
        if (!isEmpty(item)) {
          if (isFinite(+item!)) {
            return [{ value: toInteger(item) }, index];
          }
        }
        return [
          {
            value: 0,
            info: [
              {
                message: intl.get('USERS_PAGE.TABLE.IMPORT.IMPORT_NOT_NUMBER'),
                level: 'warning',
              },
            ],
          },
          index,
        ];
      });
    },
    [USER_IMPORT_ATTRIBUTES.HOURLY_RATE]: (values) => {
      return values.map(([item, index]) => {
        if (!isEmpty(item)) {
          if (isFinite(+item!)) {
            return [{ value: item! }, index];
          }
        }
        return [
          {
            value: 0,
            info: [
              {
                message: intl.get('USERS_PAGE.TABLE.IMPORT.IMPORT_NOT_NUMBER'),
                level: 'warning',
              },
            ],
          },
          index,
        ];
      });
    },
  };

  const cleanUploadedDataAndCreate = async (result: FlatfileResults) => {
    const formattedUsers = await cleanImportedUsers(
      result,
      organizationId,
      'business'
    );
    dispatch(bulkCreateUsers(formattedUsers));
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get('USERS_PAGE.TABLE.IMPORT.SUCCESS'),
      })
    );
  };

  const settings = useMemo(
    () => flatfileBusinessUserSettings(businessTeams),
    [businessTeams]
  );

  const isDataReady = !isEmpty(businessTeams);

  return {
    currentUser,
    settings,
    fieldHooks: hooks,
    isDataReady,
    onData: cleanUploadedDataAndCreate,
  };
};

export default useImportBusinessUsers;
