import { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updatePagination,
  selectProgramsPaginationData,
  selectProgramsSliceStatus,
  setProgramsSearch,
  setFilters,
} from 'state/Programs/programsSlice';
import ProgramsTableHeader from './ProgramsTableHeader';
import ProgramsTable from './ProgramsTable';
import Pagination from 'Organisms/Pagination/ClientPagination';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';
import { ProgramFilters } from 'utils/types/filters';
import {
  createFilterSetting,
  fetchFilterSettingByType,
  selectFiltersSettingsByType,
  updateFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import { SETTINGS_FILTERS } from 'utils/constants';

const ProgramsList = ({ onAddProgram }: { onAddProgram: () => void }) => {
  const dispatch = useDispatch();
  const pagination = useSelector(selectProgramsPaginationData);
  const fetchProgramsStatus = useSelector(selectProgramsSliceStatus);
  const filtersSettings = useSelector(
    selectFiltersSettingsByType(SETTINGS_FILTERS.PROGRAMS_TABLE)
  );
  const savedFilters = useMemo<ProgramFilters>(
    () => (filtersSettings ? filtersSettings.settings : {}),
    [filtersSettings]
  );

  useEffect(() => {
    if (savedFilters) {
      dispatch(setFilters(savedFilters));
    }
  }, [savedFilters, dispatch]);

  useEffect(() => {
    dispatch(fetchFilterSettingByType(SETTINGS_FILTERS.PROGRAMS_TABLE));
    dispatch(setProgramsSearch(''));
  }, [dispatch]);

  const handleSaveFiltersSettings = (updatedFilters: ProgramFilters) => {
    if (filtersSettings && filtersSettings.id) {
      dispatch(
        updateFilterSetting({
          id: filtersSettings.id,
          updateFields: { filter_settings: updatedFilters },
        })
      );
    } else {
      dispatch(
        createFilterSetting({
          filter_type: SETTINGS_FILTERS.PROGRAMS_TABLE,
          filter_settings: updatedFilters,
        })
      );
    }
  };
  const handleUpdatePagination = useCallback(
    (pagination) => {
      dispatch(updatePagination(pagination));
    },
    [dispatch]
  );

  if (fetchProgramsStatus === 'loading') {
    return <SkeletonLoader data-cy='programs-table-skeleton-loader' />;
  }

  return (
    <div className='mt-4 px-6 max-h-programs-table overflow-y-auto'>
      <ProgramsTableHeader
        savedFilters={savedFilters}
        saveFilters={handleSaveFiltersSettings}
      />
      <ProgramsTable
        onAddProgram={onAddProgram}
        areFiltersApplied={Object.keys(savedFilters).length > 0}
      />
      <Pagination total={pagination.count} onChange={handleUpdatePagination} />
    </div>
  );
};

export default ProgramsList;
