import Skelton from 'Atoms/Skelton/Skelton';
import { tailwindOverride } from '@getsynapse/design-system';

const BasicInfoLoader = ({
  className = '',
  hideSubLine = false,
}: {
  className?: string;
  hideSubLine?: boolean;
}) => (
  <div className={tailwindOverride('h-full w-full flex-col p-4', className)}>
    <div className='w-full flex items-center'>
      <Skelton className='h-10 w-10' />
      <div className='flex flex-col ml-2'>
        <Skelton className='h-4 w-20' />
        <Skelton className='h-4 w-20 mt-1' />
      </div>
    </div>
    {!hideSubLine && (
      <div className='w-full'>
        <Skelton className='h-2 w-2/3 mt-2' />
      </div>
    )}
  </div>
);

export default BasicInfoLoader;
