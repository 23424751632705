import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { Status, UserViewSettings } from 'utils/customTypes';
import viewSettingsAPI from './viewSettingsAPI';
import { SLICE_STATUS } from 'utils/constants';
import { RootState } from 'state/store';

interface ViewSettingsState {
  viewSettings: { [key: string]: UserViewSettings[] };
  userSettings: { [key: string]: UserViewSettings };
  status: Status;
}

/* ============================= INITIAL STATE ============================== */
const initialState: ViewSettingsState = {
  viewSettings: {},
  userSettings: {},
  status: SLICE_STATUS.IDLE,
};

/* ============================== REDUX THUNK =============================== */
export const getViewSettingValues = createAsyncThunk(
  'viewSettings/GET_VIEW_SETTINGS',
  async (keys: string[]) => {
    const allValues = await Promise.all(
      keys.map(async (key) => {
        const response = await viewSettingsAPI.getViewSettingValues(key);
        if (isEmpty(response.data)) {
          throw new Error('Setting does not exist');
        }
        return { key, data: response.data };
      })
    );
    return allValues;
  }
);

export const getUserSettingValues = createAsyncThunk(
  'viewSettings/GET_USER_VIEW_SETTINGS',
  async () => {
    const response = await viewSettingsAPI.getUserViewSettings();
    return response;
  }
);

export const createUpdateUserViewSetting = createAsyncThunk(
  'viewSettings/UPDATE_PUBLIC_DRAFT_SETTINGS',
  async (opts: {
    view_settings_id: string;
    view_settings_values_id?: string;
    value?: string;
  }) => {
    const response = await viewSettingsAPI.createUpdateUserViewSetting(
      opts.view_settings_id,
      opts.view_settings_values_id,
      opts.value
    );
    return response;
  }
);

/* ================================= REDUCER ================================ */
const viewSettingsSlice = createSlice({
  name: 'viewSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getViewSettingValues.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getViewSettingValues.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        action.payload.forEach((payload) => {
          state.viewSettings[payload.key] = payload.data;
        });
      })
      .addCase(getViewSettingValues.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
        state.viewSettings = initialState.viewSettings;
      })
      .addCase(getUserSettingValues.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getUserSettingValues.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        action.payload.forEach((setting: UserViewSettings) => {
          state.userSettings[setting.setting_key] = setting;
        });
      })
      .addCase(getUserSettingValues.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
        state.userSettings = initialState.userSettings;
      })
      .addCase(createUpdateUserViewSetting.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(createUpdateUserViewSetting.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        state.userSettings[action.payload.setting_key] = action.payload;
      });
  },
});

/* =============================== SELECTORS ================================ */
export const selectUserViewSettingValues =
  (key: string) => (state: RootState) =>
    state.viewSettings.userSettings[key];

export const selectViewSettingValues = (key: string) => (state: RootState) =>
  state.viewSettings.viewSettings[key];

export const selectCurrentViewSettingValue =
  (key: string) => (state: RootState) => {
    const userSetting = selectUserViewSettingValues(key)(state);
    return (
      userSetting ||
      selectViewSettingValues(key)(state)?.find((setting) => setting.is_default)
    );
  };

export default viewSettingsSlice.reducer;
