//import { CapacityPerWeek } from 'types/store/weeklyTeamCapacity';
import { api } from './api';
import axios from 'axios';
import { get } from 'lodash';

export interface WeeklyTeamMemberProjectCapacityApiResponse {
  userId: string;
  projectId: string;
  weeklyCapacities: any;
  links: {
    next: string;
    prev: string;
  };
}

export interface FetchWeeklyTeamMemberProjectCapacityParams {
  teamId: string;
  memberId: string;
  projectId: string;
  startDate: string;
  endDate?: string;
}

export const fetchWeeklyCapacityForTeamMemberProject = async ({
  teamId,
  memberId,
  projectId,
  startDate,
  endDate,
}: FetchWeeklyTeamMemberProjectCapacityParams): Promise<WeeklyTeamMemberProjectCapacityApiResponse> => {
  const url = `/v2/teams/${teamId}/members/${memberId}/projects/${projectId}/capacities/weekly`;

  const response = await api.get<WeeklyTeamMemberProjectCapacityApiResponse>(
    url,
    {
      params: {
        startDate,
        endDate,
      },
    }
  );
  if (axios.isAxiosError(response)) {
    const errorMessage = get(response, 'response.data.message', '');
    throw errorMessage;
  }
  return response.data;
};
