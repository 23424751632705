import { useState, useCallback } from 'react';
import { ParticipantTypes } from 'utils/constants';

const useCapacityTable = () => {
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>(
    []
  );
  const [openSections, setOpenSections] = useState<string[]>([
    ParticipantTypes.OWNERS,
  ]);

  const isSectionOpen = useCallback(
    (sectionId: string) => openSections.includes(sectionId),
    [openSections]
  );

  const toggleSection = useCallback(
    (sectionId: string) => {
      if (isSectionOpen(sectionId)) {
        setOpenSections(openSections.filter((id) => id !== sectionId));
      } else {
        setOpenSections([...openSections, sectionId]);
      }
    },
    [openSections, isSectionOpen]
  );

  const toggleParticipant = useCallback(
    (id: string) => {
      if (selectedParticipants.includes(id)) {
        setSelectedParticipants(selectedParticipants.filter((i) => i !== id));
      } else {
        setSelectedParticipants([...selectedParticipants, id]);
      }
    },
    [selectedParticipants]
  );

  return { isSectionOpen, toggleSection, toggleParticipant };
};

export default useCapacityTable;
