import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, tailwindOverride } from '@getsynapse/design-system';
import {
  selectPagination,
  fetchCapacity,
  capacityStatus,
  updatePaginationIndex,
} from 'state/TasksAssignmentsResourcesCapacity/tasksAssignmentsResourcesCapacitySlice';
import useWeeksCarousel from './hooks/useWeeksCarousel';
import WeeksCarousel, { Loader } from './WeeksCarousel/WeeksCarousel';

const WeekHeaders: React.FC<{ projectId: string }> = ({ projectId }) => {
  const dispatch = useDispatch();
  const capacityTableStatus = useSelector(capacityStatus);
  const { from, to, index } = useSelector(selectPagination);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const updatePagination = useCallback(
    (index: number) => {
      dispatch(updatePaginationIndex(index));
    },
    [dispatch]
  );

  const refetchCapacity = useCallback(
    (startDate: string, endDate: string) => {
      dispatch(
        fetchCapacity({
          projectId,
          query: { tableStartDate: startDate, tableEndDate: endDate },
        })
      );
    },
    [dispatch, projectId]
  );

  const { weeks, currentIndex, handleNext, handlePrevious } = useWeeksCarousel(
    from,
    to,
    index,
    updatePagination,
    refetchCapacity
  );

  useEffect(() => {
    if (capacityTableStatus === 'loading' && !isFetching) {
      setIsFetching(true);
    }

    if (capacityTableStatus === 'idle' && isFetching) {
      setTimeout(() => setIsFetching(false), 1000);
    }
  }, [isFetching, capacityTableStatus]);

  return (
    <div className='flex flex-1 h-full relative'>
      <div
        className={tailwindOverride(
          'w-6 h-full',
          'flex justify-center items-center'
        )}
      >
        <IconButton
          name='chevron-back'
          className='text-primary text-lg'
          onClick={handlePrevious}
          aria-label='previous week'
          data-cy='weeks-carousel__back-button'
        />
      </div>
      <div className={tailwindOverride('w-full h-full overflow-hidden')}>
        {isFetching && <Loader />}
        <WeeksCarousel currentIndex={currentIndex} weeks={weeks} />
      </div>
      {/* right arrow */}
      <div
        className={tailwindOverride(
          'w-6 h-full',
          'flex justify-center items-center'
        )}
      >
        <IconButton
          name='chevron-forward'
          className='text-primary text-lg'
          onClick={handleNext}
          aria-label='next week'
          data-cy='weeks-carousel__next-button'
        />
      </div>
      {/** actions menu */}
      <div className={tailwindOverride('w-12 px-2')} />
    </div>
  );
};

export default WeekHeaders;
