import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import { SidePanel } from '@getsynapse/design-system';
import { getLearningTeams } from 'state/LearningTeams/learningTeamsSlice';
import {
  getCurrentProjectData,
  getProjectSliceStatus,
  resetProject,
  getProjectId,
  fetchProject,
  updateProject,
  setProjectId,
} from 'state/Project/projectSlice';
import {
  getAllUsers,
  getLDUsers,
} from 'state/UsersManagement/usersManagementSlice';
import ProjectSidePanelHeader from './ProjectSidePanelHeader';
import { UserPermissionsProvider } from 'Pages/ProjectPage/context/UserPermissionsContext';
import ProjectSidePanelContent from './ProjectSidePanelContent';
import useHasUserAccess from 'Pages/ProjectPage/hooks/useHasUserAccess';
import { NewProject } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import useSidePanel from 'Hooks/useSidePanel';
import { getUpdatedProjectData } from 'Pages/ProjectPage/helpers/updatedProjectData';
import { getProgramId } from 'state/Program/programSlice';
import { fetchProjects } from 'state/Projects/projectsSlice';
import { fetchProjectsForProgram } from 'state/Program/programSlice';
import { updateLinkedProject } from 'state/Program/programSlice';
import { fetchUserProjects } from 'state/Projects/projectsSlice';
import { showNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { fetchTeamTasks } from 'state/ProjectTasks/projectTaskSlice';

interface ProjectSidePanelProps {
  onClosePanel?: () => void;
  onSave?: () => void;
}

const ProjectSidePanel: React.FC<ProjectSidePanelProps> = ({
  onClosePanel = () => {},
  onSave = () => {},
}) => {
  const dispatch = useDispatch();
  const projectId = useSelector(getProjectId);
  const closePanelCallback = useCallback(() => {
    setIsUpdating(false);
    onClosePanel();
    dispatch(resetProject());
  }, [dispatch, onClosePanel]);
  const {
    openPanel,
    isPanelOpen,
    closePanel,
    onClose,
    resetState,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    showUnsavedBanner,
    showUnsavedChangesAnimation,
  } = useSidePanel(closePanelCallback);

  const { permissionsLevel } = useHasUserAccess();
  const [disabled, setDisabled] = useState<boolean>(true);

  const programId = useSelector(getProgramId);
  const projectData = useSelector(getCurrentProjectData);
  const projectSliceStatus = useSelector(getProjectSliceStatus);
  const isLoading = useMemo(
    () => projectSliceStatus === SLICE_STATUS.LOADING,
    [projectSliceStatus]
  );
  const [updatedData, setUpdatedData] = useState<NewProject>();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    if (projectId) {
      openPanel();
      Promise.all([
        dispatch(fetchProject(projectId)),
        dispatch(getAllUsers()),
        dispatch(getLDUsers()),
        dispatch(getLearningTeams()),
        dispatch(fetchTeamTasks(projectId)),
      ]);
    }
  }, [dispatch, openPanel, projectId, resetState]);

  useEffect(() => {
    return () => {
      Promise.all([dispatch(resetProject()), dispatch(setProjectId(''))]);
    };
  }, [dispatch]);

  useEffect(() => {
    if (isPanelOpen && !projectId) {
      closePanel();
    }
  }, [closePanel, projectId, isPanelOpen]);

  const onUpdate = useCallback(async () => {
    setIsUpdating(true);
    const { updatedProjectData } = getUpdatedProjectData(
      updatedData as NewProject,
      projectData
    );
    if (updatedProjectData.id) {
      delete updatedProjectData.id;
    }
    const project = await dispatch(
      updateProject({
        projectId: projectId!,
        data: updatedProjectData,
      })
    );
    onSave();
    if (!programId) {
      await Promise.all([
        dispatch(fetchProjects()),
        dispatch(fetchUserProjects()),
      ]);
    } else {
      const updatedProject = get(project, 'payload');
      if (updatedProject?.id) {
        dispatch(fetchProjectsForProgram(programId));
        await dispatch(
          updateLinkedProject({
            project: updatedProject,
          })
        );
      }
    }
    dispatch(
      showNotification({
        notificationVariant: 'success',
        notificationTitle: intl.get('UPDATE_PROJECT_PAGE.UPDATED'),
      })
    );
    closePanel();
  }, [
    closePanel,
    dispatch,
    programId,
    projectData,
    projectId,
    updatedData,
    onSave,
  ]);

  const footerButtons = useMemo(
    () => [
      {
        children: intl.get('SIDE_PANEL.UPDATE_BUTTON'),
        disabled: disabled,
        onClick: onUpdate,
        loading: isUpdating,
      },
      {
        children: intl.get('SIDE_PANEL.CANCEL_BUTTON'),
        variant: 'tertiary',
        onClick: closePanel,
      },
    ],
    [disabled, onUpdate, isUpdating, closePanel]
  );

  useEffect(() => {
    if (projectData.id && !updatedData) {
      setUpdatedData(projectData);
    } else if (!projectData.id) {
      setUpdatedData(undefined);
    }
  }, [projectData, updatedData]);

  return (
    <SidePanel
      id='project-side-panel'
      onClose={onClose}
      isOpen={isPanelOpen}
      isLoading={isLoading}
      displayBackdrop={hasUnsavedChanges}
      headerElement={
        updatedData && (
          <ProjectSidePanelHeader
            onClose={onClose}
            projectId={projectId}
            projectNumber={parseInt(get(projectData, 'projectNumber', ''))}
            projectStatus={get(projectData, 'status', '')}
            updatedData={updatedData}
          />
        )
      }
      footerButtons={footerButtons}
      backdropProps={{
        onClick: onClose,
        className: 'bg-transparent',
      }}
    >
      {updatedData && (
        <UserPermissionsProvider userPermissionsLevel={permissionsLevel}>
          <ProjectSidePanelContent
            projectData={projectData}
            setShouldDisableUpdate={setDisabled}
            shouldDisableUpdate={disabled}
            unsavedChanges={showUnsavedBanner}
            showUnsavedChangesAnimation={showUnsavedChangesAnimation}
            currentSavedProject={updatedData}
            setCurrentSavedProject={
              setUpdatedData as Dispatch<SetStateAction<NewProject>>
            }
            setUnsavedChanges={setHasUnsavedChanges}
          />
        </UserPermissionsProvider>
      )}
    </SidePanel>
  );
};

export default ProjectSidePanel;
