import { Modal, Typography } from '@getsynapse/design-system';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { showNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { deleteObjective } from 'state/StrategyCategory/strategyCategorySlice';
import { STRATEGY_CATEGORIES } from 'utils/constants/program';

export interface DeleteObjectiveModalProps {
  categoryName: string;
  afterDelete: (objectiveId?: string) => void;
  isOpen: boolean;
  objectiveId: string;
  onClose: () => void;
  programId: string;
  categoryId: string;
}

const DeleteObjectiveModal: React.FC<DeleteObjectiveModalProps> = ({
  categoryName,
  afterDelete,
  isOpen,
  objectiveId,
  onClose,
  programId,
  categoryId,
}) => {
  const dispatch = useDispatch();

  const getModalMessage = () => {
    if (categoryName === STRATEGY_CATEGORIES.IMPACT) {
      return intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.DELETE_OBJECTIVE.MODAL.IMPACE_LEVEL_MESSAGE'
      );
    }
    return intl.get(
      'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.DELETE_OBJECTIVE.MODAL.MESSAGE'
    );
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteObjective({ programId, objectiveId, categoryId }));
      dispatch(
        showNotification({
          notificationVariant: 'success',
          notificationTitle: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.DELETE_OBJECTIVE.NOTIFICATION.SUCCESS.TITLE'
          ),
          autoHide: false,
        })
      );
    } catch (error) {
      dispatch(
        showNotification({
          notificationVariant: 'error',
          notificationTitle: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.DELETE_OBJECTIVE.NOTIFICATION.ERROR.TITLE'
          ),
          notificationMessage: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.DELETE_OBJECTIVE.NOTIFICATION.ERROR.MESSAGE'
          ),
        })
      );
    } finally {
      afterDelete(objectiveId);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={onClose}
      aria-label='delete-objective-modal'
      data-testid='delete-objective_modal'
      title={intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.DELETE_OBJECTIVE.MODAL.TITLE'
      )}
      titleIcon={{
        name: 'trash',
        className: 'text-error-dark',
      }}
      size='medium'
      actionButtons={[
        {
          children: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.DELETE_OBJECTIVE.MODAL.CONFIRM_BUTTON'
          ),
          variant: 'primary',
          color: 'danger',
          onClick: handleDelete,
          'data-testid': 'delete-objective_confirm',
        },
        {
          children: intl.get('CANCEL'),
          variant: 'tertiary',
          onClick: onClose,
          'data-testid': 'delete-objective_cancel',
        },
      ]}
    >
      <Typography data-testid='delete-objective-message'>
        {getModalMessage()}
      </Typography>
    </Modal>
  );
};

export default DeleteObjectiveModal;
