import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import { TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { Tab, TabList } from '@getsynapse/design-system';
import { SETTINGS_ATTRIBUTES } from 'utils/constants';
import General from './components/General/General';
import FormsPage from './components/Forms/FormsPage';
import { useState } from 'react';

const IntakeSettings = () => {
  const indexParam = new URLSearchParams(window.location.search).get(
    'attribute'
  );
  const attrMapping = {
    [SETTINGS_ATTRIBUTES.GENERAL]: 0,
    [SETTINGS_ATTRIBUTES.REQUEST_FORM]: 1,
  };
  const [activeIndex, setActiveIndex] = useState(
    indexParam ? attrMapping[indexParam] : 0
  );

  return (
    <Tabs
      index={activeIndex}
      onChange={(index: number) => {
        setActiveIndex(index);
        window.history.replaceState(
          {},
          '',
          `${window.location.pathname}?attribute=${
            Object.keys(attrMapping)[index]
          }`
        );
      }}
    >
      <div className='shadow-header px-4'>
        <Typography variant='h4'>{intl.get('INTAKE')}</Typography>

        <Typography variant='caption' className='text-neutral'>
          {intl.get('INTAKE_TAB.CAPTION')}
        </Typography>

        <TabList type='subHeader' className='mb-0'>
          <Tab index={0} type='subHeader' data-testid='general'>
            {intl.get('GENERAL')}
          </Tab>

          <Tab
            index={1}
            type='subHeader'
            data-testid='request-forms'
            data-cy='request-forms'
          >
            {intl.get('ENTITIES.REQUEST_FORM', { num: 2 })}
          </Tab>
        </TabList>
      </div>

      <TabPanels>
        <TabPanel key={0}>
          <General />
        </TabPanel>

        <TabPanel key={1}>
          <FormsPage />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default IntakeSettings;
