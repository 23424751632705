import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import useModal from 'Hooks/useModal';
import { Typography } from '@getsynapse/design-system';
import kanbanCardPlus from 'assets/icons/kanban-card-plus.svg';

const UpdateTemplateConfirmationModal: React.FC<{
  shouldDisplayModal?: boolean;
  onConfirm: () => void;
  onClose: () => void;
}> = ({ shouldDisplayModal = false, onConfirm, onClose }) => {
  const { Modal, modalProps, openModal, closeModal } = useModal();

  useEffect(() => {
    if (shouldDisplayModal && !modalProps.isOpen) {
      openModal(modalProps);
    }
  }, [modalProps, openModal, shouldDisplayModal]);

  const handleClose = () => {
    onClose();
    closeModal();
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <Modal
      {...modalProps}
      size='medium'
      title={intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.UPDATE_TEMPLATE')}
      titleIcon={{
        src: kanbanCardPlus,
      }}
      aria-label={intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.UPDATE_TEMPLATE')}
      data-cy='update-template-confirmation-modal'
      actionButtons={[
        {
          children: intl.get(
            'SETTINGS_PAGE.TEMPLATE_PAGE.UPDATE_CONFIRMATION_MODAL.CONFIRM'
          ),
          variant: 'primary',
          onClick: handleConfirm,
          'data-cy': 'update-template-confirmation-modal__confirm-button',
        },
        {
          children: intl.get('CANCEL'),
          variant: 'tertiary',
          onClick: handleClose,
        },
      ]}
      closeModal={handleClose}
    >
      <Typography variant='body2' className='text-primary'>
        {intl.getHTML(
          'SETTINGS_PAGE.TEMPLATE_PAGE.UPDATE_CONFIRMATION_MODAL.DESCRIPTION'
        )}
      </Typography>
    </Modal>
  );
};

export default UpdateTemplateConfirmationModal;
