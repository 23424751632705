import React, { useMemo } from 'react';
import { Dropdown, FormItem } from '@getsynapse/design-system';
import { useSelector } from 'react-redux';
import { selectTimeOffTypes } from 'state/ManageTimeOff/TimeOffTypes/timeOffTypesSlice';
import intl from 'react-intl-universal';
import { TimeOffEntryFilters } from 'types/store/manageTimeOff';
import { rangeDateType } from 'utils/customTypes';
import { TimeOffType } from 'types/store/manageTimeOff';
import moment from 'moment';
import FilterDateRangeField from './components/FilterDateRangeField/FilterDateRangeField';

interface TimeOffTypeOption {
  label: string;
  value: string;
}

interface FiltersFormProps {
  appliedFilters: TimeOffEntryFilters;
  onUpdateFilters: (newFilters: TimeOffEntryFilters) => void;
  setCanApplyFilters: (canApplyFilters: boolean) => void;
  minFilterDate?: string;
  maxFilterDate?: string;
}

const FiltersForm: React.FC<FiltersFormProps> = ({
  appliedFilters,
  onUpdateFilters,
  setCanApplyFilters,
  minFilterDate,
  maxFilterDate,
}) => {
  const timeOffTypesList = useSelector(selectTimeOffTypes);

  const timeOffTypesDropdownOptions = useMemo<TimeOffTypeOption[]>(
    () =>
      timeOffTypesList.map((timeOffType: TimeOffType) => ({
        label: timeOffType.name,
        value: timeOffType.code,
      })),
    [timeOffTypesList]
  );

  const selectedTimeOffType = useMemo(() => {
    if (!appliedFilters.timeOffType) {
      return [];
    }

    const timeOffType = timeOffTypesDropdownOptions.find(
      (option) => option.value === appliedFilters.timeOffType
    );

    return timeOffType ? [timeOffType] : [];
  }, [appliedFilters.timeOffType, timeOffTypesDropdownOptions]);

  const updateDateRange = (date: rangeDateType) => {
    onUpdateFilters({
      ...appliedFilters,
      startDate: moment(date.startDate).format('YYYY-MM-DD'),
      endDate: moment(date.endDate).format('YYYY-MM-DD'),
    });
  };

  const updateDateRangeValidity = (isValid: boolean) => {
    if (appliedFilters.startDate || appliedFilters.endDate) {
      setCanApplyFilters(isValid);
    }
  };

  const updateTimeOffType = (timeOffType: string) => {
    onUpdateFilters({
      ...appliedFilters,
      timeOffType,
    });
  };

  return (
    <div className='mt-8 mb-12 flex flex-col space-y-5'>
      <FormItem>
        <FilterDateRangeField
          startDate={
            appliedFilters?.startDate
              ? moment(appliedFilters.startDate).format('YYYY-MM-DD HH:mm:ss')
              : ''
          }
          endDate={
            appliedFilters?.endDate
              ? moment(appliedFilters.endDate).format('YYYY-MM-DD HH:mm:ss')
              : ''
          }
          updateDateRange={updateDateRange}
          updateDateRangeValidity={updateDateRangeValidity}
          minDate={minFilterDate}
          maxDate={maxFilterDate}
        />
      </FormItem>

      <FormItem label={intl.get('MANAGE_TIME_OFF.TABLE.COLUMNS.TIME_OFF_TYPE')}>
        <Dropdown
          options={timeOffTypesDropdownOptions}
          values={selectedTimeOffType}
          onChange={(option: TimeOffTypeOption) =>
            updateTimeOffType(option.value)
          }
          triggerProps={{
            'aria-label': intl.get(
              'MANAGE_TIME_OFF.TABLE.COLUMNS.TIME_OFF_TYPE'
            ),
            'data-testid': 'time_off-filters__time_off-picker',
          }}
          listProps={{
            'data-testid': 'time_off-filters__time_off__options-list',
          }}
        />
      </FormItem>
    </div>
  );
};

export default FiltersForm;
