import { IconButton, Tooltip } from '@getsynapse/design-system';
import { useHistory, useLocation } from 'react-router-dom';
import {
  NewProject,
  TaskDetailType,
  UpdateReqData,
  TimeOff,
} from 'utils/customTypes';
import { SIDE_PANEL_TYPES, PATHS } from 'utils/constants';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import { formatRequestIdentifier } from 'Pages/helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSidePanelUpdatedData,
  selectSidePanelData,
} from 'state/SidePanel/sidePanelSlice';
import RequestStatusTag from 'Atoms/RequestStatusTag/RequestStatusTag';
import { RequestStatus } from 'utils/types/request';

const PanelHeader = ({
  type,
  data,
  onClosePanel,
  updatedData,
}: {
  type: string;
  data: NewProject;
  onClosePanel: (force: boolean) => void;
  updatedData: NewProject | UpdateReqData | TaskDetailType | TimeOff;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const sidePanel = useSelector(selectSidePanelData);
  const { state } = useLocation();
  const fromPage = get(state, 'from', '');

  const getHeader = () => {
    switch (type) {
      case SIDE_PANEL_TYPES.REQUEST:
        const formattedNumber = formatRequestIdentifier(data.requestIdentifier);
        return (
          <div className='w-full flex text-sm items-center'>
            <div className='font-semibold pr-1'>
              {intl.get('SIDE_PANEL.ID')}
            </div>
            {': '}
            {formattedNumber}
            <div className='ml-2'>
              <RequestStatusTag status={data.status! as RequestStatus} />
            </div>
          </div>
        );
      case SIDE_PANEL_TYPES.TIME_OFF:
        return <div className='flex-1'></div>;
      default:
        return '';
    }
  };

  const getFullPageLink = () => {
    switch (type) {
      case SIDE_PANEL_TYPES.REQUEST:
        return `${PATHS.REQUEST_PAGE}/${data.id}?fromSidePanel=true`;
      default:
        return '';
    }
  };

  return (
    <div className='border-b border-primary-lighter h-12 py-3 px-4'>
      <div className='flex'>
        {getHeader()}
        <div className='w-24 flex justify-end'>
          {type !== SIDE_PANEL_TYPES.TIME_OFF && (
            <Tooltip
              openMode='hover1'
              timeout={0}
              position='botomCenter'
              contentProps={{ className: 'px-3 py-2 text-sm z-50' }}
              trigger={
                <IconButton
                  name='resize'
                  iconClassname='pointer-events-none'
                  className='text-lg p-1 rounded text-primary-dark hover:bg-neutral-lightest hover:shadow-allocation-table'
                  onClick={() => {
                    history.push(getFullPageLink(), {
                      from: fromPage ? fromPage : sidePanel.fromPage,
                    });
                    dispatch(setSidePanelUpdatedData(updatedData));
                    onClosePanel(true);
                  }}
                />
              }
            >
              {intl.get('SIDE_PANEL.FULL_PAGE')}
            </Tooltip>
          )}

          <div className='border-l h-full w-0 border-primary-lighter mx-3'></div>
          <IconButton
            name='close'
            className='text-primary-dark rounded text-lg p-1 hover:bg-neutral-lightest hover:shadow-allocation-table'
            onClick={() => onClosePanel(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default PanelHeader;
