import {
  Button,
  Datepicker,
  Dropdown,
  FormItem,
  NumericInput,
  TextField,
  Typography,
  Tooltip,
  Icon,
  tailwindOverride,
} from '@getsynapse/design-system';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import { selectFieldsUsedInProjects } from 'state/CustomFields/customFieldsSlice';
import { Option, rangeDateType } from 'utils/customTypes';
import { FieldTemplateType } from 'utils/types/fields';
import {
  ALLOWED_FIELD_TYPES,
  ALLOWED_FIELD_VALUE_SELECTORS,
  NUMBER_COMPARATOR,
  DATE_COMPARATOR,
  DROPDOWN_COMPARATOR,
  DATE_RANGE_COMPARATOR,
  STRING_COMPARATOR,
  TABLE_FILTERS_OPERATORS,
  DATE,
  CUSTOM_FIELDS_FILTERS_TYPES,
} from 'utils/constants';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { ProjectFilters, RangeFilter } from 'utils/types/filters';
import AppliedFiltersTags from './AppliedFiltersTags';

type Props = {
  filters?: ProjectFilters;
  onUpdateFilters: (newFilters: ProjectFilters) => void;
};

const CustomFieldsFilters = ({ filters, onUpdateFilters }: Props) => {
  const fields = useSelector(selectFieldsUsedInProjects);

  const [selectedField, setSelectedField] = useState<FieldTemplateType>(
    {} as FieldTemplateType
  );
  const [selectedOperator, setSelectedOperator] = useState('');
  const [selectedValues, setSelectedValues] = useState<
    Option[] | string | number | RangeFilter | undefined
  >(undefined);
  const [updatedProjectsFilters, setUpdatedProjectsFilters] =
    useState<ProjectFilters>(filters!);

  useEffect(() => {
    setUpdatedProjectsFilters(filters!);
  }, [filters]);

  const filterType = useMemo(() => {
    if (selectedField.type === ALLOWED_FIELD_TYPES.DATE) {
      return CUSTOM_FIELDS_FILTERS_TYPES.DATE;
    } else if (selectedField.type === ALLOWED_FIELD_TYPES.DATERANGE) {
      return CUSTOM_FIELDS_FILTERS_TYPES.DATE_RANGE;
    } else if (
      selectedField.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.FREEFORM &&
      (selectedField.type === ALLOWED_FIELD_TYPES.INT ||
        selectedField.type === ALLOWED_FIELD_TYPES.FLOAT ||
        selectedField.type === ALLOWED_FIELD_TYPES.NUMERIC)
    ) {
      return CUSTOM_FIELDS_FILTERS_TYPES.NUMBER;
    } else if (
      selectedField.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.FREEFORM &&
      (selectedField.type === ALLOWED_FIELD_TYPES.SINGLE_LINE ||
        selectedField.type === ALLOWED_FIELD_TYPES.MULTILINE)
    ) {
      return CUSTOM_FIELDS_FILTERS_TYPES.TEXT;
    }
    return CUSTOM_FIELDS_FILTERS_TYPES.MULTI_OPTIONS;
  }, [selectedField.type, selectedField.value_selector]);

  const fieldsOptions = useMemo(
    () =>
      fields.map((field) => ({
        label: field.name,
        value: field.id,
      })),
    [fields]
  );

  const operators = useMemo(() => {
    switch (filterType) {
      case CUSTOM_FIELDS_FILTERS_TYPES.MULTI_OPTIONS:
        return DROPDOWN_COMPARATOR.map((operator) => ({
          label: intl.get(`OPERATORS.${operator}`),
          value: operator,
        }));
      case CUSTOM_FIELDS_FILTERS_TYPES.DATE:
        return DATE_COMPARATOR.map((operator) => ({
          label: intl.get(`OPERATORS.${operator}`),
          value: operator,
        }));
      case CUSTOM_FIELDS_FILTERS_TYPES.DATE_RANGE:
        return DATE_RANGE_COMPARATOR.map((operator) => ({
          label: intl.get(`OPERATORS.${operator}`),
          value: operator,
        }));
      case CUSTOM_FIELDS_FILTERS_TYPES.NUMBER:
        return NUMBER_COMPARATOR.map((operator) => ({
          label: intl.get(`OPERATORS.${operator}`),
          value: operator,
        }));
      case CUSTOM_FIELDS_FILTERS_TYPES.TEXT:
        return STRING_COMPARATOR.map((operator) => ({
          label: intl.get(`OPERATORS.${operator}`),
          value: operator,
        }));
    }
  }, [filterType]);

  const valueOptions = useMemo(
    () =>
      selectedField.allowed_values?.length
        ? selectedField?.allowed_values!.map((value) => ({
            label: value.display?.toString() || value.val?.toString(),
            value: value.val,
          }))
        : [],
    [selectedField.allowed_values]
  );

  const renderValueComponent = useCallback(() => {
    switch (filterType) {
      case CUSTOM_FIELDS_FILTERS_TYPES.MULTI_OPTIONS:
        return (
          <MultiSelectDropdown
            multiple
            options={valueOptions}
            values={selectedValues as Option[]}
            onChange={(options: Option[]) =>
              options.length
                ? setSelectedValues(options)
                : setSelectedValues(undefined)
            }
            placeholder={intl.get('CUSTOM_FIELDS_FILTERS.VALUE_PLACEHOLDER')}
            triggerProps={{
              'data-testid': 'custom-filters-value-dropdown',
            }}
          />
        );
      case CUSTOM_FIELDS_FILTERS_TYPES.DATE:
      case CUSTOM_FIELDS_FILTERS_TYPES.DATE_RANGE:
        if (
          selectedField.type === ALLOWED_FIELD_TYPES.DATE &&
          selectedOperator !== TABLE_FILTERS_OPERATORS.BETWEEN
        ) {
          return (
            <Datepicker
              onPickDate={(date: rangeDateType) =>
                date.startDate
                  ? setSelectedValues({
                      from: moment(date.startDate).format(DATE.DATE_STAMP),
                    } as RangeFilter)
                  : setSelectedValues(undefined)
              }
              data-testid='custom-filters-date-input'
              className='w-full'
              startPlaceHolder={intl.get(
                'CUSTOM_FIELDS_FILTERS.DATE_PLACEHOLDER'
              )}
            />
          );
        } else {
          return (
            <Datepicker
              className='w-full'
              canSelectRange
              startDateLabel={intl.get('FILTER_GENERAL.FROM')}
              endDateLabel={intl.get('FILTER_GENERAL.TO')}
              onPickDate={(date: rangeDateType) =>
                date.startDate && date.endDate
                  ? setSelectedValues({
                      from: date.startDate ? date.startDate.toString() : '',
                      to: date.endDate ? date.endDate.toString() : '',
                    })
                  : setSelectedValues(undefined)
              }
              startPlaceHolder={intl.get(
                'CUSTOM_FIELDS_FILTERS.DATE_FROM_PLACEHOLDER'
              )}
              endPlaceHolder={intl.get(
                'CUSTOM_FIELDS_FILTERS.DATE_END_PLACEHOLDER'
              )}
              data-testid='custom-filters-date-range-input'
            />
          );
        }
      case CUSTOM_FIELDS_FILTERS_TYPES.NUMBER:
        return (
          <NumericInput
            placeholder={intl.get(
              'CUSTOM_FIELDS_FILTERS.INPUT_VALUE_PLACEHOLDER'
            )}
            value={selectedValues as string}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (!isEmpty(event.target.value)) {
                setSelectedValues(Number(event.target.value));
              } else {
                setSelectedValues(undefined);
              }
            }}
            data-testid='custom-filters-number-input'
          />
        );
      case CUSTOM_FIELDS_FILTERS_TYPES.TEXT:
        return (
          <TextField
            placeholder={intl.get(
              'CUSTOM_FIELDS_FILTERS.INPUT_VALUE_PLACEHOLDER'
            )}
            value={(selectedValues as string) || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSelectedValues(event.target.value)
            }
            data-testid='custom-filters-text-input'
          />
        );
    }
  }, [
    valueOptions,
    selectedField.type,
    filterType,
    selectedOperator,
    selectedValues,
  ]);

  const renderOpeartorDropdown = useCallback(
    () => (
      <Dropdown
        options={operators!}
        onChange={(option) => setSelectedOperator(option.value)}
        values={
          selectedOperator
            ? [
                {
                  label: intl.get(`OPERATORS.${selectedOperator}`),
                  value: selectedOperator,
                },
              ]
            : []
        }
        renderTrigger={({
          onToggleDropdown,
          selectedOptions,
          isDropdownExpanded,
        }) => {
          return (
            <div
              className='relative bg-neutral-white h-auto w-full'
              data-testid='custom-filters-operator-dropdown'
              onClick={onToggleDropdown}
            >
              <div className='h-auto'>
                <div
                  className='h-auto rounded border flex justify-between relative py-2 pl-4 pr-8 min-h-10 text-base border-primary-lighter hover:border-neutral-lighter active:primary-lighter text-neutral-black hover:shadow-skim focus:border-primary focus-visible:outline-none focus:ring-primary focus-visible:ring-primary bg-neutral-white w-full'
                  role='button'
                  tabIndex={0}
                  data-value='false'
                  aria-disabled='false'
                >
                  <div className='w-full inline-flex items-center flex-wrap'>
                    <div
                      className={classNames(
                        `truncate w-full ${
                          selectedOptions.length
                            ? ' text-neutral-black '
                            : 'text-neutral'
                        }`
                      )}
                    >
                      {selectedOptions[0]?.value ===
                      TABLE_FILTERS_OPERATORS.OVERLAPS ? (
                        <Tooltip
                          className='w-full'
                          showPopper
                          usePortal
                          openMode='hover1'
                          timeout={0}
                          trigger={
                            <div className='w-full flex justify-between items-center'>
                              <div>{selectedOptions[0]?.label}</div>
                              <Icon
                                name='information-circle'
                                className='text-purple text-xl'
                              />
                            </div>
                          }
                          position='topLeft'
                          contentProps={{
                            className:
                              'z-200000 bg-neutral-white text-purple-darker border-purple-lighter border',
                          }}
                        >
                          {intl.get('CUSTOM_FIELDS_FILTERS.OVERLAPS_TOOLTIP')}
                        </Tooltip>
                      ) : (
                        selectedOptions[0]?.label ||
                        intl.get('CUSTOM_FIELDS_FILTERS.OPERATOR_PLACEHOLDER')
                      )}
                    </div>
                  </div>
                  <div className='absolute top-0 right-0 flex items-center mt-3 mr-2.5'>
                    <Icon
                      name={
                        isDropdownExpanded
                          ? 'caret-up-outline'
                          : 'caret-down-outline'
                      }
                      className='text-neutral-dark'
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }}
        renderOption={(
          option: Option,
          selected: boolean,
          selectOption,
          { className, ...otherProps }
        ) => {
          const isOverlapsOption =
            option.value === TABLE_FILTERS_OPERATORS.OVERLAPS;

          const listItem = (
            <li
              {...otherProps}
              onClick={selectOption}
              tabIndex={selected ? 0 : -1}
              className={tailwindOverride(
                'font-body text-base truncate group px-4 py-2 focus-visible:border-0 focus-visible:ring-0 focus-visible:outline-none cursor-pointer text-neutral-black hover:text-neutral-dark hover:shadow-list-item-hover hover:bg-neutral-lighter-two focus-visible:bg-neutral-lighter-two focus-visible:text-neutral-dark focus-visible:shadow-list-item-hover',
                {
                  'text-neutral-white bg-primary hover:text-neutral-white hover:bg-primary focus-visible:bg-primary focus-visible:text-neutral-white':
                    selected,
                }
              )}
            >
              {isOverlapsOption ? (
                <div className='flex justify-between items-center'>
                  <div>{option.label}</div>
                  <Icon
                    name='information-circle'
                    className='text-purple text-xl'
                  />
                </div>
              ) : (
                option.label
              )}
            </li>
          );

          return isOverlapsOption ? (
            <Tooltip
              className='w-full'
              showPopper
              usePortal
              openMode='hover1'
              timeout={0}
              trigger={listItem}
              position='topLeft'
              contentProps={{
                className:
                  'z-200000 bg-neutral-white text-purple-darker border-purple-lighter border',
              }}
            >
              {intl.get('CUSTOM_FIELDS_FILTERS.OVERLAPS_TOOLTIP')}
            </Tooltip>
          ) : (
            listItem
          );
        }}
      />
    ),
    [operators, selectedOperator]
  );

  const changeField = (option: Option) => {
    const field = fields.find((f) => f.id === option.value);
    setSelectedOperator('');
    setSelectedValues(undefined);
    setSelectedField(field!);
  };

  const addToFilter = () => {
    const newKey = uuidv4();
    const newValue = {
      operator: selectedOperator,
      value: selectedValues,
      fieldId: selectedField.id!,
      type: filterType,
    };
    const newUpdatedFilters = {
      ...updatedProjectsFilters,
      [newKey! as string]: newValue,
    };
    setUpdatedProjectsFilters(newUpdatedFilters);
    setSelectedOperator('');
    setSelectedValues(undefined);
    setSelectedField({} as FieldTemplateType);
    onUpdateFilters(newUpdatedFilters);
  };

  return (
    <div data-testid='custom-fields-filters-panel'>
      <Typography variant='h4' weight='medium'>
        {intl.get('FILTER_GENERAL.FIELDS_FILTERS')}
      </Typography>
      <AppliedFiltersTags
        filters={filters!}
        onUpdateFilters={onUpdateFilters}
        onDisplayAllFilters={() => {}}
        customFieldsFilters
      />
      <FormItem
        label={intl.get('ENTITIES.FIELDS', { num: 1 })}
        className='mt-8'
      >
        <Dropdown
          options={fieldsOptions}
          onChange={changeField}
          triggerProps={{
            placeholder: intl.get('CUSTOM_FIELDS_FILTERS.FIELD_PLACEHOLDER'),
            'data-testid': 'custom-filters-field-dropdown',
          }}
          values={
            selectedField.id
              ? [
                  {
                    label: selectedField.name,
                    value: selectedField.id,
                  },
                ]
              : []
          }
        />
      </FormItem>
      <FormItem
        label={intl.get('CUSTOM_FIELDS_FILTERS.OPERATOR_LABEL')}
        className='mt-6'
      >
        {renderOpeartorDropdown()}
      </FormItem>
      <FormItem
        label={
          selectedOperator === TABLE_FILTERS_OPERATORS.BETWEEN ||
          selectedField.type === ALLOWED_FIELD_TYPES.DATERANGE
            ? ''
            : intl.get('CUSTOM_FIELDS_FILTERS.VALUE_LABEL')
        }
        className='mt-6 mb-4'
      >
        {renderValueComponent()}
      </FormItem>
      <Button
        variant='tertiary'
        iconName='add-circle'
        onClick={addToFilter}
        disabled={
          !selectedField || !selectedOperator || selectedValues === undefined
        }
        data-testid='custom-filters-add-button'
      >
        {intl.get('ADD')}
      </Button>
    </div>
  );
};

export default CustomFieldsFilters;
