import { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAllTeamsIds,
  selectFetchMoreTeamsLink,
} from 'state/Capacities/TeamsList/teamsListSlice';
import { selectUserRole } from 'state/User/userSlice';
import { setProjectId } from 'state/Project/projectSlice';
import { USER_ROLES } from 'utils/constants';
import { ScrollHandlerContextType } from './components/ScrollHandler/ScrollHandlerContext';
import ScrollHandler from './components/ScrollHandler/ScrollHandler';
import TableHeader from './components/TableHeader/TableHeader';
import TeamsList from './components/TeamsList/TeamsList';
import ProjectSidePanel from 'Organisms/ProjectSidePanel/ProjectSidePanel';
import EmptyTeamsList from './components/TeamsList/components/EmptyTeamsList/EmptyTeamsList';

type CapacityTableProps<T> = React.PropsWithChildren<{
  scrollHandlerCallbacks: T;
}>;

const CapacityTable = <T extends ScrollHandlerContextType>({
  scrollHandlerCallbacks,
}: CapacityTableProps<T>) => {
  const dispatch = useDispatch();
  const teamsIds = useSelector(selectAllTeamsIds);
  const fetchMoreTeamsLink = useSelector(selectFetchMoreTeamsLink);
  const userRole = useSelector(selectUserRole);
  const isUserAdmin = userRole === USER_ROLES.ADMIN;

  return (
    <Fragment>
      <ProjectSidePanel onClosePanel={() => dispatch(setProjectId(''))} />
      <ScrollHandler callbacks={scrollHandlerCallbacks}>
        <table
          className='w-full border-collapse table-fixed'
          cellPadding={0}
          cellSpacing={0}
          data-name='teams-capacity-table'
          data-cy='teams-capacity-table'
        >
          <TableHeader />
          {teamsIds.length > 0 && (
            <TeamsList
              teamsIds={teamsIds}
              canFetchMoreTeams={!!fetchMoreTeamsLink}
            />
          )}
          {teamsIds.length === 0 && (
            <EmptyTeamsList isUserAdmin={isUserAdmin} />
          )}
        </table>
      </ScrollHandler>
    </Fragment>
  );
};

export default CapacityTable;
