import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { Status, UserSettings } from 'utils/customTypes';
import { UserSettingsAPI } from './userSettingsAPI';
import { SLICE_STATUS } from 'utils/constants';
import { RootState } from 'state/store';

const userSettingsAPI = new UserSettingsAPI();

export interface UserSettingState {
  value: Partial<UserSettings>;
  status: Status;
}

/* ============================= INITIAL STATE ============================== */
const initialState: UserSettingState = {
  value: {
    draft_public_setting: false,
  },
  status: SLICE_STATUS.IDLE,
};

/* ============================== REDUX THUNK =============================== */
export const getUserSettings = createAsyncThunk(
  'userSettings/GET_USER_SETTINGS',
  async () => {
    const response = await userSettingsAPI.getUserSettings();
    if (isEmpty(response.data)) {
      throw new Error('User not in DB');
    }
    return response.data;
  }
);

export const updatePublicDraftSettings = createAsyncThunk(
  'userSettings/UPDATE_PUBLIC_DRAFT_SETTINGS',
  async (requestId?: string) => {
    const response = await userSettingsAPI.updatePublicDraftSettings(requestId);
    return response.data.updatedSettings;
  }
);

/* ================================= REDUCER ================================ */
const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserSettings.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getUserSettings.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        state.value = action.payload;
      })
      .addCase(getUserSettings.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
        state.value = initialState.value;
      })
      .addCase(updatePublicDraftSettings.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        state.value = action.payload;
      });
  },
});

/* =============================== SELECTORS ================================ */
export const selectUserDraftPublicSetting = (state: RootState) =>
  state.userSettings.value.draft_public_setting;

export default userSettingsSlice.reducer;
