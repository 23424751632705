import { useState, FC, useEffect, useRef, useMemo } from 'react';
import intl from 'react-intl-universal';
import {
  FormItem,
  Datepicker,
  FormHelperText,
} from '@getsynapse/design-system';
import { rangeDateType } from 'utils/customTypes';
import moment from 'moment';

export interface DateRangeFieldProps {
  startDate: string | undefined;
  endDate: string | undefined;
  updateDateRange: (dateRange: rangeDateType) => void;
  updateDateRangeValidity: (isValid: boolean) => void;
  minDate?: string;
  maxDate?: string;
}

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
});

const DateRangeField: FC<DateRangeFieldProps> = ({
  startDate,
  endDate,
  updateDateRange,
  updateDateRangeValidity,
  minDate,
  maxDate,
}) => {
  const [dateRange, setDateRange] = useState<[Date, Date]>();
  const maxRangeEndDate = useRef<Date>();
  const prevDateRange = useRef<[Date, Date]>();

  const isDateRangeValid = useMemo(() => {
    if (dateRange && maxRangeEndDate.current) {
      return dateRange[1].getTime() <= maxRangeEndDate.current.getTime();
    }
    return true;
  }, [dateRange]);

  const didDateRangeChange = useMemo(() => {
    if (dateRange) {
      if (prevDateRange.current) {
        return (
          dateRange[0].getTime() !== prevDateRange.current[0].getTime() ||
          dateRange[1].getTime() !== prevDateRange.current[1].getTime()
        );
      }
      return true;
    }
    return false;
  }, [dateRange]);

  useEffect(() => {
    if (startDate && endDate) {
      prevDateRange.current = [new Date(startDate), new Date(endDate)];
      setDateRange([new Date(startDate), new Date(endDate)]);
    }

    return () => {
      maxRangeEndDate.current = undefined;
      setDateRange(undefined);
    };
  }, [startDate, endDate]);

  useEffect(() => {
    if (isDateRangeValid && didDateRangeChange && dateRange) {
      prevDateRange.current = [dateRange[0], dateRange[1]];
      updateDateRange({
        startDate: dateRange?.[0].toISOString(),
        endDate: dateRange?.[1].toISOString(),
      });
    } else {
      updateDateRangeValidity(isDateRangeValid);
    }
  }, [
    isDateRangeValid,
    dateRange,
    updateDateRange,
    didDateRangeChange,
    updateDateRangeValidity,
  ]);

  const getMaxRangeEndDate = (startDate: Date): Date => {
    const maxEndDate = new Date(startDate);
    maxEndDate.setMonth(startDate.getMonth() + 18);
    return maxEndDate;
  };

  const setDateRangeToMaxEndDate = () => {
    setDateRange((prev) => [prev![0], maxRangeEndDate.current!]);
  };

  const handleUpdateDateRange = (dateRange: rangeDateType) => {
    if (dateRange.startDate && dateRange.endDate) {
      const startDate = new Date(dateRange.startDate);
      const endDate = new Date(dateRange.endDate);
      maxRangeEndDate.current = getMaxRangeEndDate(startDate);
      setDateRange([startDate, endDate]);
    }
  };

  return (
    <FormItem>
      <Datepicker
        className='w-full'
        minDate={minDate ? moment(minDate).startOf('day').toDate() : undefined}
        maxDate={maxDate ? moment(maxDate).startOf('day').toDate() : undefined}
        canSelectRange
        startDateLabel={intl.get('USER_TIME_OFF_PAGE.TABLE.COLUMNS.START_DATE')}
        startDateLabelProps={{
          required: true,
        }}
        startDate={dateRange?.[0]}
        endDateLabel={intl.get('USER_TIME_OFF_PAGE.TABLE.COLUMNS.END_DATE')}
        endDateLabelProps={{
          required: true,
        }}
        endDate={dateRange?.[1]}
        size='large'
        onPickDate={handleUpdateDateRange}
      />
      {!isDateRangeValid && (
        <FormHelperText state='error'>
          {intl.get('MANAGE_TIME_OFF.INVALID_DATE_RANGE_ERROR')}
        </FormHelperText>
      )}
      {!isDateRangeValid && maxRangeEndDate.current && (
        <FormHelperText className='pl-6'>
          {intl.get('MANAGE_TIME_OFF.UPDATE_INVALID_DATE_RANGE')}&nbsp;
          <u className='cursor-pointer' onClick={setDateRangeToMaxEndDate}>
            {dateFormatter.format(maxRangeEndDate.current)}
          </u>
        </FormHelperText>
      )}
    </FormItem>
  );
};

export default DateRangeField;
