import { Modal, Typography, FormItem } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

type WaitlistedInfoModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  comments: string;
  waitlistDate: string | undefined | null;
  conditions?: string[];
  details?: string;
};

const WaitlistedInfoModal = ({
  isOpen,
  setIsOpen,
  comments,
  waitlistDate,
  conditions = [],
  details = '',
}: WaitlistedInfoModalProps) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  const requestConditions = conditions.map((condition) =>
    intl.get(
      `REQUEST_PAGE.TOP_BAR.WAITLIST_MODAL.WAITLIST_CONDITIONS.${condition}`
    )
  );

  return (
    <Modal
      aria-label='waitlisted-request-info-modal'
      closeModal={handleClose}
      isOpen={isOpen}
      title={intl.get('REQUEST_PAGE.TOP_BAR.WAITLIST_MODAL.WAITLIST_REQUEST')}
      size='medium'
      childrenClassName=''
      data-testid='waitlist-info-modal'
    >
      <FormItem
        className='mb-4'
        label={intl.get(
          'REQUEST_PAGE.TOP_BAR.WAITLIST_MODAL.CONDITION_APPROVAL'
        )}
        labelProps={{ required: true }}
      >
        <Typography variant='body2'>{requestConditions.join(', ')}</Typography>
      </FormItem>

      {details && (
        <FormItem
          label={intl.get(
            'REQUEST_PAGE.DECLINED_REQUEST_INFO_MODAL.ADDITIONAL_DETAILS'
          )}
          className='mt-4'
        >
          <Typography variant='body2'>{details}</Typography>
        </FormItem>
      )}

      <FormItem
        label={intl.get('REQUEST_PAGE.DECLINED_REQUEST_INFO_MODAL.COMMENTS')}
        className='mt-6'
      >
        <Typography variant='body2'>
          {comments
            ? comments
            : intl.get('REQUEST_PAGE.DECLINED_REQUEST_INFO_MODAL.NO_COMMENTS')}
        </Typography>
      </FormItem>
      <FormItem
        className='mt-6'
        label={intl.get('REQUEST_PAGE.TOP_BAR.WAITLIST_MODAL.WAITLIST_DATE')}
      >
        <Typography variant='body2' data-testid='waitlist-date'>
          {waitlistDate}
        </Typography>
      </FormItem>
    </Modal>
  );
};

export default WaitlistedInfoModal;
