import { FC } from 'react';
import intl from 'react-intl-universal';
import {
  tailwindOverride,
  Typography,
  useElevation,
} from '@getsynapse/design-system';

interface TableFooterProps {
  total: number;
}

const TableFooter: FC<TableFooterProps> = ({ total }) => {
  const skim = useElevation(1);
  return (
    <div
      className={tailwindOverride(
        'w-full px-6 py-2',
        'flex flex-1 items-center',
        'absolute bottom-0 left-0 z-10',
        'bg-neutral-white',
        skim
      )}
    >
      <Typography variant='body'>
        {intl.get('MANAGE_TIME_OFF.NUMBER_OF_RESULTS', { number: total })}
      </Typography>
    </div>
  );
};

export default TableFooter;
