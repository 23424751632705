import { api } from './api';
import axios from 'axios';
import {
  LinkedTaskContent,
  LinkedTaskContentUploader,
} from 'types/store/linkedTaskContent';

interface FetchLinkedTaskContentParams {
  taskId: string;
}

export interface FetchLinkedTaskContentResponse {
  linkedContent: LinkedTaskContent[];
  uploaders: LinkedTaskContentUploader[];
}

export const fetchLinkedTaskContent = async ({
  taskId,
}: FetchLinkedTaskContentParams) => {
  const url = `/v2/tasks/${taskId}/linked-content`;
  const response = await api.get<FetchLinkedTaskContentResponse>(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};
