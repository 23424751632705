import React, { ChangeEvent, useMemo } from 'react';
import {
  FormItem,
  Dropdown,
  TextField,
  NumericInput,
} from '@getsynapse/design-system';
import {
  MethodQuestionWithPresetValues,
  QuestionAnswer,
  DesiredBehavioursData,
} from 'utils/types/program';
import { OptionWithKey } from 'utils/customTypes';
import intl from 'react-intl-universal';
import DesiredBehavioursGroup from './DesiredBehavioursGroup';
import { METHOD_QUESTIONS } from 'utils/constants/program';

const CollectionPropertyQuestion = ({
  questionName,
  readOnly,
  questionWithOptions,
  currentAnswer,
  onUpdateField,
  onUpdateOtherTextOfDataSource,
}: {
  questionName: string;
  readOnly: boolean;
  onUpdateOtherTextOfDataSource: (value: string, questionId: string) => void;
  questionWithOptions?: MethodQuestionWithPresetValues;
  currentAnswer?: QuestionAnswer;
  onUpdateField: (
    value: string | number | OptionWithKey[] | DesiredBehavioursData,
    questionId: string
  ) => void;
}) => {
  const dropdownOptions = useMemo(() => {
    return questionWithOptions?.options || [];
  }, [questionWithOptions]);

  const selectedOptions = useMemo(
    () =>
      dropdownOptions.filter((option) => {
        return currentAnswer?.questions_answers?.find(
          (answer) => answer.answer_preset_id === option.key
        );
      }),
    [dropdownOptions, currentAnswer]
  );

  const shouldShowOtherField =
    questionName === METHOD_QUESTIONS.DATA_SOURCE &&
    selectedOptions.some((option) => {
      return option.value === 'other';
    });

  if (!questionWithOptions) return null;
  switch (questionName) {
    case METHOD_QUESTIONS.DATA_SOURCE:
    case METHOD_QUESTIONS.COLLECTION_TIMING:
    case METHOD_QUESTIONS.METHOD:
      return (
        <div className='flex flex-col'>
          <FormItem
            label={intl.get(
              `PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.FIELDS.${questionName.toUpperCase()}`
            )}
            className='w-full'
            labelProps={{ className: 'text-base' }}
          >
            <Dropdown
              readOnly={readOnly}
              multiple
              values={selectedOptions}
              placeholder={intl.get(
                `PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.FIELDS.${questionName.toUpperCase()}_PLACEHOLDER`
              )}
              onChange={(options: OptionWithKey[]) => {
                onUpdateField(options, questionWithOptions.question_id);
              }}
              options={dropdownOptions}
              triggerProps={{
                className: 'py-0 items-center',
                'data-testid': `evaluation-plan__collection-${questionName}`,
              }}
            />
          </FormItem>
          {shouldShowOtherField && (
            <FormItem
              label={intl.get(
                'PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.FIELDS.OTHER'
              )}
              className='w-full mt-6'
              labelProps={{ className: 'text-base' }}
            >
              <TextField
                readOnly={readOnly}
                value={
                  currentAnswer?.questions_answers?.find(
                    (answer) => answer.answer_name === 'other'
                  )?.data_source_other_text
                }
                placeholder={intl.get(
                  'PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.FIELDS.OTHER_PLACEHOLDER'
                )}
                data-testid='evaluation-plan__other-text-field'
                variant='text'
                length='medium'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onUpdateOtherTextOfDataSource(
                    e.target.value,
                    questionWithOptions.question_id
                  )
                }
              />
            </FormItem>
          )}
        </div>
      );
    case METHOD_QUESTIONS.NUMBER_OF_RESPONDENTS:
    case METHOD_QUESTIONS.NUMBER_OBSERVED:
    case METHOD_QUESTIONS.NUMBER_OF_OBSERVERS:
      const numberValue = currentAnswer?.questions_answers?.[0]?.[questionName];
      return (
        <FormItem
          label={intl.get(
            `PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.FIELDS.${questionName.toUpperCase()}`
          )}
          className='w-full'
          labelProps={{ className: 'text-base' }}
        >
          <NumericInput
            readOnly={readOnly}
            value={numberValue || numberValue === 0 ? numberValue : ''}
            placeholder={intl.get(
              `PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.FIELDS.${questionName.toUpperCase()}_PLACEHOLDER`
            )}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              onUpdateField(
                event.target.value,
                questionWithOptions.question_id
              );
            }}
            data-testid={`evaluation-plan__collection-${questionName}`}
            allowFloatValue={false}
            allowNegativeValue={false}
            min={0}
          />
        </FormItem>
      );
    case METHOD_QUESTIONS.DESIRED_BEHAVIOURS:
      const currentValue =
        currentAnswer?.questions_answers?.[0]?.[questionName];
      return (
        <DesiredBehavioursGroup
          currentValue={currentValue ? currentValue : []}
          readOnly={readOnly}
          onUpdateQuestion={(value: DesiredBehavioursData) =>
            onUpdateField(value, questionWithOptions.question_id)
          }
        />
      );
    default:
      return null;
  }
};

export default CollectionPropertyQuestion;
