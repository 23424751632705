export const IN_COLOR_MAP = ['#E1E9EC', '#E1E9EC', '#E1E9EC', '#E1E9EC'];
export const inActiveColor = '#E1E9EC';
export const SECTOR_INACTIVE_COLOR = '#C1CFD6';
export const CENTER_COUNT_COLOR = '#0E1212';
export const CENTER_TEXT_COLOR = '#506268';
export const PATH_CIRCLE_COLOR = '#A0B0B5';
export const OTHERS_TEXT = 'Others';
export const DATA_STATE_NO_PROJECTS_LINKED = [
  {
    name: 'New',
    value: 1,
  },
  {
    name: 'In Queue',
    value: 1,
  },
  {
    name: 'On Hold',
    value: 1,
  },
  {
    name: 'In Progress',
    value: 1,
  },
  {
    name: 'Completed',
    value: 1,
  },
];
