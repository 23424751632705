import { Icon, tailwindOverride, Tooltip } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { Option } from '../../../utils/customTypes';

type ProjectStatusOptionParams = {
  disabled: boolean;
  selected: boolean;
  otherProps: Record<string, any>;
  selectOption: () => void;
  option: Option;
};

export function ProjectStatusOption(props: ProjectStatusOptionParams) {
  const { disabled, selected, otherProps, selectOption, option } = props;

  const disabledClass = disabled
    ? 'text-neutral-lighter cursor-not-allowed'
    : '';

  const listItem = (
    <li
      {...otherProps}
      onClick={() => {
        if (!disabled) {
          selectOption();
        }
      }}
      tabIndex={selected ? 0 : -1}
      className={tailwindOverride(
        'font-body text-base truncate group px-4 py-2 focus-visible:border-0 focus-visible:ring-0 focus-visible:outline-none',
        {
          'cursor-pointer text-neutral-black hover:text-neutral-dark hover:shadow-list-item-hover hover:bg-neutral-lighter-two focus-visible:bg-neutral-lighter-two focus-visible:text-neutral-dark focus-visible:shadow-list-item-hover':
            !disabled,
          'text-neutral-white bg-primary hover:text-neutral-white hover:bg-primary focus-visible:bg-primary focus-visible:text-neutral-white':
            selected && !disabled,
        },
        disabledClass
      )}
    >
      {option.label}
    </li>
  );

  const tooltip = (
    <>
      <Tooltip
        className='w-full'
        showPopper
        usePortal
        openMode='hover1'
        timeout={0}
        trigger={listItem}
        position='bottomRight'
        contentProps={{
          className: 'z-200000 text-neutral-darker bg-neutral-white flex',
        }}
      >
        <Icon
          name='information-circle-outline'
          className='text-2xl text-neutral-darker'
        />
        <span className='ml-2'>
          {intl
            .get('PROJECT_DETAIL.COMPLETION_DISABLED')
            .defaultMessage(
              'Please complete all tasks before completing this project.'
            )}
        </span>
      </Tooltip>
    </>
  );

  return disabledClass ? tooltip : listItem;
}
