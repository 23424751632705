import Thumbnail from '../../Thumbnail/Thumbnail';
import classnames from 'classnames';
import get from 'lodash/get';
import { IconButton, Typography } from '@getsynapse/design-system';
import { ProjectContent } from 'utils/customTypes';
import { getTruncatedFileNameAndExtension } from '../../ContentTable/utils';

const CarouselContentItem = ({
  content,
  index,
  onRemoveFile,
}: {
  content: Partial<ProjectContent>;
  index: number;
  onRemoveFile: (index: number) => void;
}) => {
  const truncateFileName = (name: string) => {
    if (name.length < 15) {
      return name;
    }
    const { name: fileName, extension } = getTruncatedFileNameAndExtension(
      name,
      15
    );
    return `${fileName}${extension}`;
  };

  return (
    <div
      key={index}
      data-cy={`content-carousel-item-${index}`}
      className={classnames(
        'flex snap-start gap-2 shrink-0 max-w-66 h-12 shadow-allocation-table rounded-sm items-center z-2'
      )}
    >
      <div className='min-w-19 pl-1'>
        <Thumbnail
          backupUrl={get(content, 'data.url', '')}
          contentType={content.content_type!}
          mimeType={get(content, 'data.mimetype', '')}
          dataCy={`content-carousel-thumbnail-${index}`}
        />
      </div>
      <Typography
        variant='body2'
        className='min-w-23 text-primary-dark whitespace-nowrap'
      >
        {truncateFileName(get(content, 'data.filename', ''))}
      </Typography>
      <IconButton
        name='trash'
        className='w-5 ml-auto mr-2 text-base text-primary-dark'
        onClick={() => onRemoveFile(index)}
        data-cy={`content-carousel-item-delete-${index}`}
      />
    </div>
  );
};

export default CarouselContentItem;
