import React from 'react';
import classnames from 'classnames';
import intl from 'react-intl-universal';
import { Button, Tooltip, Icon } from '@getsynapse/design-system';
import informationIcon from 'assets/icons/information.svg';

export const EditAssignmentActionButtons: React.FC<{
  doneCallback: () => void;
  cancelCallback: () => void;
  confirmButtonProps?: object;
  cancelButtonProps?: object;
}> = ({
  doneCallback,
  cancelCallback,
  confirmButtonProps,
  cancelButtonProps,
}) => {
  return (
    <React.Fragment>
      <Tooltip
        timeout={0}
        position='topCenter'
        contentProps={{
          className: classnames(
            'z-10 py-1 px-3 bg-neutral-darker min-w-12',
            'text-center shadow-tooltip'
          ),
        }}
        openMode='hover2'
        showPopper
        trigger={
          <Button
            variant='tertiary'
            className='p-1.5'
            onClick={doneCallback}
            {...confirmButtonProps}
          >
            <Icon
              name='checkmark-circle'
              className='text-success-darker text-lg'
            />
          </Button>
        }
      >
        {intl.get('DONE')}
      </Tooltip>
      <Tooltip
        timeout={0}
        position='topCenter'
        contentProps={{
          className: classnames(
            'z-10 py-1 px-3 bg-neutral-darker min-w-12',
            'text-center shadow-tooltip'
          ),
        }}
        showPopper
        openMode='hover2'
        trigger={
          <Button
            variant='tertiary'
            className='p-1.5'
            onClick={cancelCallback}
            {...cancelButtonProps}
          >
            <Icon name='close-circle' className='text-neutral-dark text-lg' />
          </Button>
        }
      >
        {intl.get('CANCEL')}
      </Tooltip>
    </React.Fragment>
  );
};

const RemoveProjectOwnerRoleButton: React.FC<{
  removeButtonProps?: object;
}> = ({ removeButtonProps }) => (
  <Tooltip
    timeout={0}
    position='topLeft'
    contentProps={{
      className: classnames(
        'z-10 w-54 p-4 bg-neutral-darker',
        'shadow-tooltip',
        'whitespace-normal'
      ),
    }}
    showPopper
    openMode='hover2'
    trigger={
      <Button
        variant='tertiary'
        className='p-1.5'
        disabled
        {...removeButtonProps}
      >
        <Icon
          name='trash'
          className='text-neutral-lighter text-lg cursor-not-allowed'
        />
      </Button>
    }
  >
    {intl.get(
      'PEOPLE.RESOURCE_ALLOCATION.TABLE.REMOVE_PROJECT_OWNER_ROLE_WARNING'
    )}
  </Tooltip>
);

const RemoveParticipantRoleButton: React.FC<{
  isRemovingStartedRole: boolean;
  removeCallback: () => void;
  removeButtonProps?: object;
}> = ({ isRemovingStartedRole, removeCallback, removeButtonProps }) => (
  <Tooltip
    trigger={
      <Button
        variant='tertiary'
        className='p-1.5'
        onClick={removeCallback}
        {...removeButtonProps}
      >
        <Icon name='trash' className='text-neutral-dark text-lg' />
      </Button>
    }
    openMode='hover2'
    timeout='10'
    ariaId='required-allocation-changes'
    position='bottomLeft'
    showPopper
    contentProps={{
      className: classnames(
        'bg-warning-lighter',
        'text-warning-dark',
        'max-w-max z-50 absolute',
        'whitespace-normal'
      ),
    }}
  >
    {isRemovingStartedRole ? (
      <div className='max-w-64 rounded-sm flex'>
        <Icon src={informationIcon} className='font-normal text-xl w-16' />
        <span className='ml-2'>
          {intl.get(
            'PEOPLE.RESOURCE_ALLOCATION.TABLE.REMOVE_STARTED_ROLE_WARNING_TOOLTIP'
          )}
        </span>
      </div>
    ) : (
      <div className='w-full rounded-sm flex items-center'>
        <Icon src={informationIcon} className='font-normal text-xl' />
        <span className='ml-2'>
          {intl.get(
            'PEOPLE.RESOURCE_ALLOCATION.TABLE.REMOVE_USER_WARNING_TOOLTIP'
          )}
        </span>
      </div>
    )}
  </Tooltip>
);

export const AssignmentActionButtons: React.FC<{
  editCallback: () => void;
  removeCallback: () => void;
  isAssignmentRoleProjectOwner: boolean;
  isLastAssignment: boolean;
  isAssignmentStarted: boolean;
  isAssignmentNewlyAdded: boolean;
  shouldDisableEditButton: boolean;
  editButtonProps?: object;
  removeButtonProps?: object;
}> = ({
  editCallback,
  removeCallback,
  isAssignmentRoleProjectOwner,
  isLastAssignment,
  isAssignmentStarted,
  isAssignmentNewlyAdded,
  shouldDisableEditButton,
  editButtonProps,
  removeButtonProps,
}) => {
  const displayWarningTooltip =
    isLastAssignment || (isAssignmentStarted && !isAssignmentNewlyAdded);
  const isRemovingStartedRole =
    isAssignmentStarted && !isAssignmentNewlyAdded && !isLastAssignment;
  return (
    <React.Fragment>
      <Tooltip
        timeout={0}
        position='topCenter'
        contentProps={{
          className: classnames(
            'z-10 py-1 px-3 bg-neutral-darker min-w-12',
            'text-center shadow-tooltip'
          ),
        }}
        openMode='hover2'
        showPopper
        trigger={
          <Button
            variant='tertiary'
            className='p-1.5'
            disabled={shouldDisableEditButton}
            onClick={editCallback}
            {...editButtonProps}
          >
            <Icon
              name='pencil-outline'
              className={classnames('text-lg', {
                'text-neutral-dark': !shouldDisableEditButton,
                'text-neutral-lighter cursor-not-allowed':
                  shouldDisableEditButton,
              })}
            />
          </Button>
        }
      >
        {intl.get('EDIT')}
      </Tooltip>
      {isAssignmentRoleProjectOwner && (
        <RemoveProjectOwnerRoleButton removeButtonProps={removeButtonProps} />
      )}

      {!isAssignmentRoleProjectOwner && displayWarningTooltip && (
        <RemoveParticipantRoleButton
          removeCallback={removeCallback}
          isRemovingStartedRole={isRemovingStartedRole}
          removeButtonProps={removeButtonProps}
        />
      )}

      {!isAssignmentRoleProjectOwner && !displayWarningTooltip && (
        <Tooltip
          timeout={0}
          position='topCenter'
          contentProps={{
            className: classnames(
              'z-10 py-1 px-3 bg-neutral-darker min-w-12',
              'text-center shadow-tooltip'
            ),
          }}
          openMode='hover2'
          showPopper
          trigger={
            <Button
              variant='tertiary'
              className='p-1.5'
              onClick={removeCallback}
              {...removeButtonProps}
            >
              <Icon name='trash' className='text-neutral-dark text-lg' />
            </Button>
          }
        >
          {intl.get('REMOVE')}
        </Tooltip>
      )}
    </React.Fragment>
  );
};
