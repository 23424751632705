import { tailwindOverride } from '@getsynapse/design-system';
import { HTMLAttributes, ReactNode } from 'react';

export const Card = ({
  children,
  ...otherProps
}: {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>) => {
  const { className, ...otherAttributes } = otherProps;

  return (
    <div
      className={tailwindOverride(
        className,
        'border border-neutral-lighter-two rounded-lg shadow-allocation-table'
      )}
      {...otherAttributes}
    >
      {children}
    </div>
  );
};
