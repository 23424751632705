import { Typography } from '@getsynapse/design-system';
import { NotificationSetting as NotificationSettingType } from 'utils/types/notification-settings';
import intl from 'react-intl-universal';
import { NotificationSetting } from './NotificationSetting';

export interface NotificationSettingGroupProps {
  setting: NotificationSettingType;
  onToggle: (
    category: string,
    trigger: string,
    groupId: string,
    value: boolean
  ) => void;
}

export function NotificationSettingGroup({
  setting,
  onToggle,
}: NotificationSettingGroupProps) {
  return (
    <div className='w-full flex flex-col justify-start' key={setting.category}>
      <Typography variant='h6' className='mb-2'>
        {intl.get(`NOTIFICATIONS_SETTINGS.${setting.category}.TITLE`) ||
          setting.category}
      </Typography>

      {setting.groupSettings.map((group, i) => (
        <NotificationSetting
          category={setting.category}
          key={group.id}
          groupId={group.id}
          name={group.name}
          value={group.emailEnabled}
          onToggle={onToggle}
        />
      ))}
    </div>
  );
}
