import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { TimeOff } from 'utils/customTypes';
class TimeOffAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  createTimeOff: (newTimeOff: TimeOff) => Promise<any> = async (newTimeOff) => {
    const { data } = await this.instance.post(`user/timeOff`, newTimeOff);
    return data;
  };

  fetchTimeOffInfo = async (timeOffId: string) => {
    const { data } = await this.instance.get(`user/timeOff/${timeOffId}`);
    return data;
  };

  fetchUserTimeOffs = async (userId: string) => {
    const { data } = await this.instance.get(`user/userTimeOff/${userId}`);
    return data;
  };

  updateTimeOff: (
    timeOffId: string,
    updateFields: Pick<TimeOff, 'startDate' | 'endDate' | 'timeOffType'>
  ) => Promise<any> = async (timeoffId, updateFields) => {
    const { data } = await this.instance.put(`user/timeOff/${timeoffId}`, {
      updateFields,
    });
    return data;
  };

  deleteTimeOff: (timeOffId: string) => Promise<any> = async (timeoffId) => {
    const { data } = await this.instance.delete(`user/timeOff/${timeoffId}`);
    return data;
  };
}

const timeOffAPI = new TimeOffAPI();

export default timeOffAPI;
