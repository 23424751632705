import intl from 'react-intl-universal';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import {
  COLUMN_OPTION_TYPES,
  PROJECTS_TABLE_FILTER_OPTIONS,
  TABLE_FILTERS_OPERATORS,
} from 'utils/constants';
import {
  TimeOffData,
  filter,
  SortingOrderType,
  FormOption,
  Owner,
} from 'utils/customTypes';
import { TimeOff } from '../../utils/customTypes';
import { checkColumnMatchDateFilter } from 'state/Projects/helpers';
import { BusinessTeam } from 'utils/types/businessTeams';
import { LearningTeam } from 'utils/types/learningTeam';

const checkColumnContainsValue = (
  column: string | undefined,
  columnData: any,
  value: string,
  filterTye: string = COLUMN_OPTION_TYPES.TEXT,
  shouldContain: boolean = true
) => {
  let formattedValue = value.toLocaleLowerCase();
  let formattedColumn = columnData;

  if (filterTye === COLUMN_OPTION_TYPES.OPTIONS) {
    let arrayToValidate = columnData;
    if (column === PROJECTS_TABLE_FILTER_OPTIONS.BUSINESS_UNIT.value) {
      arrayToValidate = columnData.map((team: BusinessTeam) => team.id);
    }
    if (column === PROJECTS_TABLE_FILTER_OPTIONS.TEAMS.value) {
      arrayToValidate = columnData.map((team: LearningTeam) => team.id);
    }
    return shouldContain
      ? arrayToValidate.includes(value)
      : !arrayToValidate.includes(value);
  }

  if (column === 'status') {
    formattedColumn = intl.get(
      `PROJECT_DETAIL.STATUS_OPTIONS.${columnData.toUpperCase()}`
    );
  }

  if (column === 'priority') {
    formattedColumn = intl.get(
      `PROJECT_DETAIL.PRIORITY_OPTIONS.${columnData.toUpperCase()}`
    );
  }

  if (column === 'owners') {
    if (shouldContain) {
      return columnData.some((owner: Owner) => {
        const fullname =
          `${owner.data?.firstName} ${owner.data?.lastName}`.toLocaleLowerCase();
        return fullname.includes(formattedValue);
      });
    } else {
      return columnData.every((owner: Owner) => {
        const fullname =
          `${owner.data?.firstName} ${owner.data?.lastName}`.toLocaleLowerCase();
        return !fullname.includes(formattedValue);
      });
    }
  }

  if (column === 'projectNumber') {
    return shouldContain
      ? (formattedColumn + '').indexOf(formattedValue) > -1
      : (formattedColumn + '').indexOf(formattedValue) === -1;
  }

  return formattedColumn
    ? shouldContain
      ? formattedColumn.toLowerCase().includes(formattedValue)
      : !formattedColumn.toLowerCase().includes(formattedValue)
    : false;
};

const checkColumnMatchFilter = (columnData: any, filter: filter) => {
  const value =
    filter.type === COLUMN_OPTION_TYPES.OPTIONS
      ? (filter.value as FormOption).value
      : (filter.value as string);
  if (!value) {
    return false;
  }

  switch (filter.operator) {
    case TABLE_FILTERS_OPERATORS.CONTAINS:
      return checkColumnContainsValue(
        filter.column,
        columnData,
        value,
        filter.type
      );
    case TABLE_FILTERS_OPERATORS.DOESNT_CONTAIN:
      return checkColumnContainsValue(
        filter.column,
        columnData,
        value,
        filter.type,
        false
      );
    case TABLE_FILTERS_OPERATORS.EQUAL:
      return isNaN(columnData) || typeof columnData === 'boolean'
        ? columnData === value
        : columnData === +value;
    case TABLE_FILTERS_OPERATORS.NOT_EQUAL:
      return isNaN(columnData) || typeof columnData === 'boolean'
        ? columnData !== value
        : columnData !== +value;
    case TABLE_FILTERS_OPERATORS.GREATER:
      return columnData > +value;
    case TABLE_FILTERS_OPERATORS.GREATER_OR_EQUAL:
      return columnData >= +value;
    case TABLE_FILTERS_OPERATORS.LESS:
      return columnData < +value;
    case TABLE_FILTERS_OPERATORS.LESS_OR_EQUAL:
      return columnData <= +value;
    case TABLE_FILTERS_OPERATORS.BETWEEN:
      return false;
    default:
      return false;
  }
};

const allFiltersMatched = (timeOff: TimeOffData, filters: filter[]) => {
  let allFiltersMatched = false;
  for (const filter of filters) {
    if (filter.column) {
      let columnMatchFilter = false;
      const columnData = timeOff[filter.column];
      if (columnData) {
        if (filter.type === COLUMN_OPTION_TYPES.DATE) {
          const formattedColumnData = moment(columnData)
            .utc()
            .endOf('day')
            .toDate()
            .toString();
          columnMatchFilter = checkColumnMatchDateFilter(
            formattedColumnData,
            filter
          );
        } else {
          columnMatchFilter = checkColumnMatchFilter(columnData, filter);
        }
      }
      if (filter.logic !== undefined) {
        allFiltersMatched =
          filter.logic === 'AND'
            ? allFiltersMatched && columnMatchFilter
            : allFiltersMatched || columnMatchFilter;
      } else {
        allFiltersMatched = columnMatchFilter;
      }
    }
  }
  return allFiltersMatched;
};

export const sortTimeOffsBy = (
  timeOffs: TimeOffData[],
  sorting: SortingOrderType
) => {
  let column = sorting.orderBy || 'start_date';
  const { order, orderBy: sortBy } = sorting;
  if (sortBy === 'dates') {
    column = 'start_date';
  }
  return orderBy(timeOffs, [column], [order]);
};

export const filterTimeOffs = (
  timeOffs: TimeOffData[],
  filters: filter[],
  searchParam: string
) => {
  let filteredTimeOffs = timeOffs;
  const filtersActive = filters.length > 0;
  const searchActive = searchParam !== '';
  if (filtersActive || searchActive) {
    filteredTimeOffs = timeOffs.filter((timeOff: TimeOffData) => {
      let searchMatch = false;
      let filtersMatch = false;
      if (filtersActive) {
        filtersMatch = allFiltersMatched(timeOff, filters);
      }
      if (searchActive && timeOff.time_off_type) {
        const timeOffTypeLabel = intl.get(
          `TEAMS.TIME_OFF.TIME_OFF_TYPES.${timeOff.time_off_type}`
        );
        searchMatch =
          timeOffTypeLabel
            .toLocaleLowerCase()
            .includes(searchParam.toLocaleLowerCase()) || false;
      }
      if (searchActive && filtersActive) {
        return searchMatch && filtersMatch;
      } else if (searchActive && !filtersActive) {
        return searchMatch;
      } else if (!searchActive && filtersActive) {
        return filtersMatch;
      } else {
        return false;
      }
    });
  }
  return filteredTimeOffs;
};

export const convertTimeOffToFormData: (timeOff: TimeOffData) => TimeOff = (
  timeOff: TimeOffData
) => ({
  id: timeOff.id,
  startDate: moment(timeOff.start_date)
    .utc()
    .startOf('day')
    .format('YYYY-MM-DD HH:mm:ss'),
  endDate: moment(timeOff.end_date)
    .utc()
    .endOf('day')
    .format('YYYY-MM-DD HH:mm:ss'),
  timeOffType: timeOff.time_off_type,
  userId: timeOff.user_id,
});
