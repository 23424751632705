import { Typography, tailwindOverride } from '@getsynapse/design-system';
import { HTMLAttributes, ReactNode } from 'react';

export const SmallGrayText = ({
  children,
  ...otherProps
}: { children: ReactNode } & HTMLAttributes<HTMLDivElement>) => {
  const { className, ...otherAttributes } = otherProps;

  return (
    <Typography
      variant='caption'
      className={tailwindOverride(className, 'font-semibold text-neutral')}
      {...otherAttributes}
    >
      {children}
    </Typography>
  );
};

export const DataValueDescription = ({
  value,
  title,
}: {
  value: number;
  title: string;
}) => {
  return (
    <div className='flex items-center'>
      <p className='flex font-bold text-5xl short:text-4xl'>{value}</p>

      <div className='flex flex-col ml-1 w-18'>
        <Typography variant='label' className='ml-1 font-semibold'>
          {title}
        </Typography>
      </div>
    </div>
  );
};
