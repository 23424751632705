import { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import { Tabs } from '@getsynapse/design-system';
import { TASKS_TABLE_TABS, UPDATE_PROJECT_TABS } from 'utils/constants';
import TeamTasks from '../TeamTasks/TeamTasks';
import UserTasks from '../UserTasks/UserTasks';

const TableTabs = () => {
  const history = useHistory();
  const tabsMapping = useMemo<Record<string, number>>(
    () => ({
      [TASKS_TABLE_TABS.TEAM_TASKS]: 0,
      [TASKS_TABLE_TABS.MY_TASKS]: 1,
    }),
    []
  );
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  useEffect(() => {
    const search = history.location.search;
    const queryParams = new URLSearchParams(search);
    const activeTab = queryParams.get('subTab');
    const activeTabIndex = activeTab ? tabsMapping[activeTab] : 0;
    if (currentTabIndex !== activeTabIndex) {
      setCurrentTabIndex(activeTabIndex);
    }
  }, [history, currentTabIndex, tabsMapping]);

  const handleChangeTab = (index: number) => {
    setCurrentTabIndex(index);
    const activeTab = Object.keys(tabsMapping)[index];
    const pathName = history.location.pathname;
    history.replace(
      `${pathName}?subTab=${activeTab}&tab=${UPDATE_PROJECT_TABS.TASKS}`
    );
  };

  return (
    <div className='px-6 flex-grow'>
      <Tabs
        index={currentTabIndex}
        onChange={handleChangeTab}
        tabListProps={{
          className: 'mb-4 w-58',
        }}
        type='tab'
        data={[
          {
            label: intl.get('TASKS.TEAM_TASKS'),
            content: currentTabIndex === 0 && <TeamTasks />,
          },
          {
            label: intl.get('TASKS.MY_TASKS'),
            content: currentTabIndex === 1 && <UserTasks />,
          },
        ]}
      />
    </div>
  );
};

export default TableTabs;
