import { tailwindOverride, Typography } from '@getsynapse/design-system';
import { initDateFormatter } from '../../../../helpers';

const WeekDaysHeader: React.FC<{
  weekStart: string;
  weekDays: string[];
  numberOfDays: number;
}> = ({ weekStart, weekDays, numberOfDays }) => {
  const dateFormatter = initDateFormatter();
  const startOfWeek = new Date(weekStart.replace(/-/g, '/'));
  const currentDate = dateFormatter.format(new Date());
  const weekEnd = new Date(
    new Date(startOfWeek).setDate(startOfWeek.getDate() + 4)
  );
  const displaySecondMonth = startOfWeek.getMonth() !== weekEnd.getMonth();
  const shortDateFormatter = initDateFormatter('en-GB', {
    weekday: 'short',
    day: 'numeric',
  });
  const monthLabelFormatter = initDateFormatter('en-US', { month: 'short' });

  return (
    <div
      className={tailwindOverride('h-full', 'flex-shrink-0 flex-grow')}
      style={{
        width: `${(100 / numberOfDays) * weekDays.length}%`,
      }}
    >
      <div
        className={tailwindOverride(
          'px-4 h-10',
          'flex justify-center items-center',
          'border border-t-0 border-r-0 border-primary-lighter-two'
        )}
        aria-label={`week-days-header__week-${weekStart}`}
      >
        {weekDays.length >= 2 && (
          <Typography
            variant='caption'
            weight='medium'
            className='text-primary'
          >
            {monthLabelFormatter.format(startOfWeek)}
            {displaySecondMonth && ` - ${monthLabelFormatter.format(weekEnd)}`}
          </Typography>
        )}
      </div>
      <div className='flex w-full'>
        {weekDays.map((day) => {
          const weekDay = new Date(day.replace(/-/g, '/'));
          const isCurrentDay = currentDate === dateFormatter.format(weekDay);
          return (
            <div
              key={day}
              className={tailwindOverride(
                'flex-1 h-10 relative',
                'flex justify-center items-center',
                'flex-shrink-0 flex-grow',
                {
                  'bg-secondary-lightest border-t border-l border-r border-secondary-lighter':
                    isCurrentDay,
                  'border-l border-primary-lighter-two': !isCurrentDay,
                }
              )}
              aria-label={`week-days-header__day-${day}`}
            >
              <Typography
                variant='caption'
                weight='medium'
                className='text-primary'
              >
                {shortDateFormatter.format(weekDay)}
              </Typography>
              {isCurrentDay && (
                <div
                  className={tailwindOverride(
                    'w-full h-0.5',
                    'bg-secondary-darker',
                    'absolute bottom-0 left-0'
                  )}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WeekDaysHeader;
