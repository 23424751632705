import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { MeasurementScale } from 'utils/types/program';

class MeasurementScalesAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchMeasurementScales = async () => {
    const { data } = await this.instance.get('v2/measurement-scale');
    return data as MeasurementScale[];
  };
}

export default new MeasurementScalesAPI();
