import { api } from './api';
import axios from 'axios';

export interface SearchProjectParams {
  name?: string;
  projectIds?: string[];
}

export interface AvailableProject {
  id: string;
  name: string;
  displayId: string;
}

export interface FetchAvailableProjectResponse {
  availableProjects: AvailableProject[];
}

export const searchProjects = async ({
  name,
  projectIds,
}: SearchProjectParams) => {
  let url = `/v2/available-projects/`;
  const urlParams = new URLSearchParams();
  if (name) {
    urlParams.append('name', name);
  }

  if (projectIds) {
    projectIds.forEach((id) => urlParams.append('projectIds[]', id));
  }

  url = `${url}?${urlParams.toString()}`;

  const response = await api.get<FetchAvailableProjectResponse>(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};
