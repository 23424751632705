import CustomField from 'Molecules/CustomField/CustomField';
import { useState } from 'react';
import { tailwindOverride } from '@getsynapse/design-system';
import { CustomFieldType, FieldType } from 'utils/types/fields';
import { objKeyAsString } from 'utils/customTypes';

interface CustomFieldsProps {
  customFields: CustomFieldType[];
  disabled: boolean;
  onChange?: (updatedFields: Record<string, FieldType['value']>) => void;
  requiredFieldsErrors?: objKeyAsString;
  className?: string;
}

const CustomFields: React.FC<CustomFieldsProps> = ({
  customFields,
  disabled,
  onChange = () => {},
  requiredFieldsErrors,
  className = 'gap-x-10%',
}) => {
  const [updatedFields, setUpdatedFields] = useState<
    Record<string, FieldType['value']>
  >({});

  const onUpdate: (
    newValue: FieldType['value'],
    fieldTemplateName: string,
    fieldId?: string
  ) => void = (newValue, fieldTemplateName, fieldId) => {
    const newUpdatedFields: Record<string, FieldType['value']> = {
      ...updatedFields,
      [fieldTemplateName]: newValue,
    };
    setUpdatedFields(newUpdatedFields);
    onChange(newUpdatedFields);
  };

  return (
    <div className={tailwindOverride('grid grid-cols-2 gap-y-6', className)}>
      {customFields.map((customField) => (
        <CustomField
          key={customField.field_template.id}
          field={customField.field}
          fieldTemplate={customField.field_template}
          onUpdate={onUpdate}
          disabled={disabled}
          required={customField.required}
          displayError={
            requiredFieldsErrors
              ? requiredFieldsErrors[customField.field_template.name]
              : false
          }
        />
      ))}
    </div>
  );
};

export default CustomFields;
