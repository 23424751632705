import intl from 'react-intl-universal';

const LearningTeamsTableHead = () => {
  const headCellsClassNames = 'py-3 font-semibold';

  return [
    {
      'data-testid': 'team-name',
      columnName: 'name',
      content: intl.get('ORG_SETTINGS_PAGE.LEARNING_TEAMS.TABLE.TEAM_NAME'),
      className: headCellsClassNames,
    },
    {
      'data-testid': 'team-manager',
      columnName: 'teamManagers',
      content: intl.get('ORG_SETTINGS_PAGE.LEARNING_TEAMS.TABLE.TEAM_MANAGER'),
      className: headCellsClassNames,
    },
    {
      'data-testid': 'team-reports-to',
      columnName: 'parentTeam.name',
      content: intl.get('ORG_SETTINGS_PAGE.LEARNING_TEAMS.TABLE.REPORTS_TO'),
      className: headCellsClassNames,
    },
    {
      'data-testid': 'team-create-date',
      columnName: 'createdAt',
      content: intl.get('ORG_SETTINGS_PAGE.LEARNING_TEAMS.TABLE.CREATION_DATE'),
      className: headCellsClassNames,
    },
  ];
};

export default LearningTeamsTableHead;
