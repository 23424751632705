import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import intl from 'react-intl-universal';
import { Button } from '@getsynapse/design-system';
import TaskModal from 'Organisms/TaskModal/TaskModal';
import { taskModalDefaultValues } from 'Pages/ProjectPage/tabs/Tasks/helpers/constants';
import { TaskFormFields } from 'utils/types/task';
import { TaskTemplate } from 'utils/types/taskTemplate';

interface CreateTaskTemplateModalProps {
  setNewAndUpdatedTemplates: React.Dispatch<React.SetStateAction<Set<string>>>;
  setTemplates: React.Dispatch<React.SetStateAction<TaskTemplate[]>>;
}

const CreateTaskTemplateModal: React.FC<CreateTaskTemplateModalProps> = ({
  setNewAndUpdatedTemplates,
  setTemplates,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const confirmAddingNewTask = (newTaskData: TaskFormFields) => {
    const newId = uuidv4();
    setTemplates((prevTemplates) => [
      ...prevTemplates,
      {
        id: newId,
        name: newTaskData.name,
        description: newTaskData.description,
        estimated_hours: newTaskData.estimate_hours,
        type: newTaskData.type,
      },
    ]);
    setNewAndUpdatedTemplates((prevTemplates) => prevTemplates.add(newId));
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        className='px-4 py-2'
        data-testid='add-task-template-button'
        size='small'
      >
        {intl.get('TASKS.ADD_TASK_BUTTON_TITLE')}
      </Button>

      <TaskModal
        isTaskTemplate
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        taskData={taskModalDefaultValues}
        onSaveTask={confirmAddingNewTask}
      />
    </>
  );
};

export default CreateTaskTemplateModal;
