import orderBy from 'lodash/orderBy';
// import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { PROGRAM_FIELDS } from 'utils/constants';
import { Program, SortingType, User } from 'utils/customTypes';
import { ProgramFilters, ProgramFiltersKey } from 'utils/types/filters';
import { PROGRAM_TABLE_FILTERS } from 'utils/constants/program';
import { isDateRangeFilter } from 'utils/typeGuards';
import { checkEntityMatchesDateFilter } from 'utils/filters';
import { formatProgramNumber } from 'utils/formatters';
import {
  naturalSort,
  programOwnerSortKeyGenerator,
  sortItemsByKey,
} from '../../utils/naturalSorting';

export const orderProgramsBy = (
  programs: Program[],
  column: string,
  order: SortingType
) => {
  switch (column) {
    case PROGRAM_FIELDS.OWNERS:
      const sortedByOwners = sortItemsByKey(
        programs,
        programOwnerSortKeyGenerator,
        order
      );

      return sortedByOwners;
    case PROGRAM_FIELDS.TITLE:
      return naturalSort([...programs], column as keyof Program, order);
    default:
      return orderBy(programs, [column], [order]);
  }
};

const programMatchAllFilters: (
  program: Program,
  filters: ProgramFilters
) => boolean = (program, filters) => {
  let allFiltersMatched = true;
  for (const filter of Object.keys(filters)) {
    const filterValue = filters[filter as ProgramFiltersKey];
    const programValue = program[filter as keyof Program];
    if (!isEmpty(filterValue)) {
      let programMatchesCurrentFilter = false;

      switch (filter) {
        case PROGRAM_TABLE_FILTERS.CREATION:
          if (isDateRangeFilter(filterValue)) {
            programMatchesCurrentFilter = checkEntityMatchesDateFilter(
              programValue as string,
              filterValue
            );
          }
          break;
        case PROGRAM_TABLE_FILTERS.OWNERS:
          programMatchesCurrentFilter = (programValue as User[]).some((owner) =>
            (filterValue as string[]).includes(owner.id)
          );
          break;
        default:
          programMatchesCurrentFilter = (filterValue as string[]).includes(
            programValue as string
          );
          break;
      }

      allFiltersMatched = allFiltersMatched && programMatchesCurrentFilter;
      if (!allFiltersMatched) {
        return allFiltersMatched;
      }
    } else {
      allFiltersMatched = false;
      return allFiltersMatched;
    }
  }
  return allFiltersMatched;
};

const checkProgramMatchesSearch: (
  program: Program,
  search: string
) => boolean = (program, search) => {
  let programMatchesSearch = false;
  if (program.program_number !== null) {
    programMatchesSearch = formatProgramNumber(program.program_number)
      ?.toLocaleLowerCase()
      .includes(search.toLocaleLowerCase());
  }
  if (!programMatchesSearch) {
    const title = program.title.toLocaleLowerCase();
    const description = program.description?.toLocaleLowerCase() || '';
    const formattedSearch = search.toLocaleLowerCase();
    return (
      title.includes(formattedSearch) || description.includes(formattedSearch)
    );
  }
  return programMatchesSearch;
};

export const filterPrograms: (
  programs: Program[],
  search: string,
  filters: ProgramFilters
) => Program[] = (programs, search, filters) => {
  let filteredPrograms = programs;
  const filtersActive = Object.keys(filters).length > 0;
  const searchActive = search;
  if (filtersActive || searchActive) {
    filteredPrograms = programs.filter((program: Program) => {
      let programMatchSearch = false;
      let programMatchFilters = false;
      if (searchActive) {
        programMatchSearch = checkProgramMatchesSearch(program, search);
      }
      if (filtersActive) {
        programMatchFilters = programMatchAllFilters(program, filters);
      }
      if (searchActive && filtersActive) {
        return programMatchSearch && programMatchFilters;
      } else if (searchActive && !filtersActive) {
        return programMatchSearch;
      } else if (!searchActive && filtersActive) {
        return programMatchFilters;
      } else {
        return false;
      }
    });
  }
  return filteredPrograms;
};
