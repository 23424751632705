import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { Task, TaskAssignedUser } from 'utils/customTypes';
import { TASKS_TABLE_FILTERS, TASK_STATUS } from 'utils/constants';
import { RangeFilter, TaskFilters, TaskFiltersKey } from 'utils/types/filters';
import { isDateRangeFilter } from 'utils/typeGuards';
import { CentralizedTask } from 'utils/types/centralizedTasks';

const taskIncludesAssigneeUser: (
  filterValue: string[],
  taskValue: TaskAssignedUser[]
) => boolean = (filterValue, taskValue) =>
  taskValue.some((user: TaskAssignedUser) => filterValue.includes(user.id));

const taskMatchDateFilter: (
  filterValue: RangeFilter,
  taskValue: string
) => boolean = (filterValue, taskValue) => {
  let dateFilterMatched = false;
  const taskDate = moment(new Date(taskValue));
  const fromRange = filterValue.from && new Date(filterValue.from);
  const toRange = filterValue.to && new Date(filterValue.to);
  if (fromRange) {
    dateFilterMatched = taskDate.isSameOrAfter(fromRange, 'days');
  }
  if (fromRange && toRange) {
    dateFilterMatched =
      dateFilterMatched && taskDate.isSameOrBefore(toRange, 'days');
  }
  if (!fromRange && toRange) {
    dateFilterMatched = taskDate.isSameOrBefore(toRange, 'days');
  }
  return dateFilterMatched;
};

const taskMatchRangeFilter: (
  filterValue: RangeFilter,
  taskValue: string
) => boolean = (filterValue, taskValue) => {
  let rangeFilterMatched = false;
  const formattedTaskValue = parseInt(taskValue);
  const fromRange = filterValue.from && parseInt(filterValue.from);
  const toRange = filterValue.to && parseInt(filterValue.to);
  if (fromRange) {
    rangeFilterMatched = formattedTaskValue >= fromRange;
  }
  if (fromRange && toRange) {
    rangeFilterMatched = rangeFilterMatched && formattedTaskValue <= toRange;
  }
  if (!fromRange && toRange) {
    rangeFilterMatched = formattedTaskValue <= toRange;
  }
  return rangeFilterMatched;
};

const taskMatchAllFilters: (task: Task, filters: TaskFilters) => boolean = (
  task,
  filters
) => {
  let allFiltersMatched = true;
  const DATE_FILTERS = [
    TASKS_TABLE_FILTERS.START_DATE,
    TASKS_TABLE_FILTERS.DUE_DATE,
    TASKS_TABLE_FILTERS.COMPLETION_DATE,
  ];
  const RANGE_FILTERS = [
    TASKS_TABLE_FILTERS.ESTIMATED_HOURS,
    TASKS_TABLE_FILTERS.ACTUAL_HOURS,
  ];
  for (const filter of Object.keys(filters)) {
    const filterValue = filters[filter as TaskFiltersKey];
    const taskValue = task[filter as keyof Task];
    if (!isEmpty(taskValue) && !isEmpty(filterValue)) {
      let filterMatch = false;
      if (isDateRangeFilter(filterValue)) {
        if (DATE_FILTERS.includes(filter)) {
          filterMatch = taskMatchDateFilter(filterValue, taskValue);
        } else if (RANGE_FILTERS.includes(filter)) {
          filterMatch = taskMatchRangeFilter(filterValue, taskValue);
        }
      } else if (filter === TASKS_TABLE_FILTERS.ASSIGNEE_USERS) {
        filterMatch = taskIncludesAssigneeUser(filterValue, taskValue);
      } else if (filter === TASKS_TABLE_FILTERS.TYPE) {
        const formattedTaskValue = (taskValue as string)
          .toUpperCase()
          .replace(/ /g, '_');
        filterMatch = filterValue.includes(formattedTaskValue);
      } else {
        filterMatch = filterValue.includes(taskValue);
      }
      allFiltersMatched = allFiltersMatched && filterMatch;
      if (!allFiltersMatched) {
        return allFiltersMatched;
      }
    } else {
      allFiltersMatched = false;
      return allFiltersMatched;
    }
  }
  return allFiltersMatched;
};

export const getTasksAssigneesList: (
  tasks: Task[] | CentralizedTask[]
) => TaskAssignedUser[] = (tasks) => {
  const assignees: TaskAssignedUser[] = [];
  const selectedUsersIds: string[] = [];
  for (const task of tasks) {
    const assignedUsers = task.assignedUsers || [];
    for (const user of assignedUsers) {
      if (!selectedUsersIds.includes(user.id)) {
        selectedUsersIds.push(user.id);
        assignees.push(user);
      }
    }
  }
  return assignees;
};

export const filterTasks: (
  tasks: Task[],
  filters: TaskFilters,
  search: string
) => Task[] = (tasks, filters, search) => {
  const filtersActive = Object.keys(filters).length > 0;
  const searchActive = search;

  let filteredTasks = tasks;

  if (filtersActive || searchActive) {
    filteredTasks = tasks.filter((task: Task) => {
      let taskMatchSearch = false;
      let taskMatchFilters = false;

      if (filtersActive) {
        taskMatchFilters = taskMatchAllFilters(task, filters);
      }

      if (searchActive) {
        taskMatchSearch =
          task.name?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
          task.description
            ?.toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()) ||
          false;
      }
      if (searchActive && filtersActive) {
        return taskMatchSearch && taskMatchFilters;
      } else if (searchActive && !filtersActive) {
        return taskMatchSearch;
      } else if (!searchActive && filtersActive) {
        return taskMatchFilters;
      } else {
        return false;
      }
    });
  }
  return filteredTasks;
};

export const sortTasks = (arrayToSort: Task[], orderToFollow: string[]) => {
  const arrayToUpdate = [...arrayToSort];

  arrayToUpdate.sort(function (a, b) {
    const A = a['id'],
      B = b['id'];

    if (orderToFollow.indexOf(A) > orderToFollow.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return arrayToUpdate;
};

export const getTasksEstimatedAndActualHours: (tasks: Task[]) => {
  estimatedHours: number;
  actualHours: number;
} = (tasks) => {
  let estimatedHours = 0,
    actualHours = 0;
  const availableTasks = tasks.filter((task: Task) => !task.disabled);
  for (let task of availableTasks) {
    if (task.estimate_hours) {
      estimatedHours += parseFloat(task.estimate_hours);
    }
    if (
      task.actual_hours &&
      [TASK_STATUS.COMPLETED, TASK_STATUS.IN_PROGRESS].includes(task.status)
    ) {
      actualHours += parseFloat(task.actual_hours);
    }
  }
  return { estimatedHours, actualHours };
};

export const getTasksCompletionProgress: (tasks: Task[]) => {
  available: number;
  completed: number;
} = (tasks) => {
  const available = tasks.filter((task: Task) => !task.disabled);
  const completed = available.filter(
    (task: Task) => task.status === TASK_STATUS.COMPLETED
  ).length;
  return { available: available.length, completed };
};
