import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import classNames from 'classnames';
import {
  fetchDeclinedRequests,
  getDeclinedRequestsAnalytics,
  getActiveFiltersQueryParams,
} from 'state/Insights/InsightsSlice';
import DeclinedRequestsSkeletonLoader from './DeclinedRequestsSkeletonLoader';
import CountAndRatio from '../../Components/CountAndRatio';
import ErrorScreen from '../../Components/ErrorScreen';
import { SLICE_STATUS } from 'utils/constants';
import PieChartComponent from './PieChart';
import intl from 'react-intl-universal';
import Legend from './Legend';
import { OTHERS_TEXT } from './constants';
import { Typography } from '@getsynapse/design-system';

const DeclinedRequests = () => {
  const dispatch = useDispatch();
  const declinedRequestsAnalytics = useSelector(getDeclinedRequestsAnalytics);
  const activeFilters = useSelector(getActiveFiltersQueryParams);

  useEffect(() => {
    dispatch(fetchDeclinedRequests(activeFilters));
  }, [dispatch, activeFilters]);

  const ratio = Math.round(declinedRequestsAnalytics.declinedRatio * 100);
  const topThreeReasons = declinedRequestsAnalytics.declinationByReason;
  const otherReasons = declinedRequestsAnalytics.otherDeclinationByReason;
  const reasonsToDisplay =
    otherReasons.length > 0
      ? [
          ...topThreeReasons,
          {
            declinationReason: OTHERS_TEXT,
            requestCount: declinedRequestsAnalytics.totalOthersCount,
          },
        ]
      : topThreeReasons;

  return (
    <div className='w-2/5 border border-neutral-lighter-two rounded-lg shadow-allocation-table flex px-4 pt-3 pb-9'>
      {declinedRequestsAnalytics.status === SLICE_STATUS.LOADING ? (
        <DeclinedRequestsSkeletonLoader />
      ) : declinedRequestsAnalytics.status === SLICE_STATUS.FAILED ? (
        <ErrorScreen />
      ) : (
        <div
          className={classNames('w-full h-full flex', {
            'flex-col': declinedRequestsAnalytics.declinedRequests === 0,
          })}
        >
          <div
            className={classNames(
              'flex flex-col',
              {
                'w-1/2 h-full': declinedRequestsAnalytics.declinedRequests > 0,
              },
              {
                'w-full h-1/2':
                  declinedRequestsAnalytics.declinedRequests === 0,
              }
            )}
          >
            <div className='flex flex-row'>
              <div className='w-2/3'>
                <CountAndRatio
                  count={declinedRequestsAnalytics.declinedRequests}
                  ratioMessage={`${ratio} ${intl.get(
                    'INSIGHTS_PAGE_TABS.DECLINED_REQUESTS.DECLINE_RATIO'
                  )}`}
                  countMessage={intl.get(
                    'INSIGHTS_PAGE_TABS.DECLINED_REQUESTS.TITLE'
                  )}
                />
              </div>
            </div>

            {declinedRequestsAnalytics.declinedRequests !== 0 && (
              <div className='w-full mb-4 flex flex-col justify-end h-1/3 mt-auto pl-2'>
                <Legend
                  topThreeReasons={
                    declinedRequestsAnalytics.declinationByReason
                  }
                  otherReasons={otherReasons}
                />
              </div>
            )}
          </div>

          {declinedRequestsAnalytics.declinedRequests !== 0 && (
            <div className='w-full flex h-full pt-32'>
              <PieChartComponent
                requestDetails={declinedRequestsAnalytics}
                reasons={reasonsToDisplay}
              />
            </div>
          )}

          {declinedRequestsAnalytics.declinedRequests === 0 && (
            <div className='flex grow flex-col h-full w-full items-center py-4'>
              <Typography variant='caption' className='text-neutral-lighter'>
                {intl.get('INSIGHTS_PAGE_TABS.NO_REQUESTS')}
              </Typography>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DeclinedRequests;
