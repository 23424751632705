import Alert from '@reach/alert';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, tailwindOverride } from '@getsynapse/design-system';
import {
  getSnackBarNotificationData,
  hideNotification,
} from 'state/SnackbarNotification/SnackbarNotificationSlice';

const SnackbarNotification = () => {
  const dispatch = useDispatch();
  const {
    notificationTitle,
    notificationMessage,
    notificationVariant,
    autoHide,
    timeout,
  } = useSelector(getSnackBarNotificationData);
  return (
    <Alert className='fixed bottom-12 left-6 z-50' type='assertive'>
      <Snackbar
        variant={notificationVariant}
        title={notificationTitle}
        message={notificationMessage}
        hasCloseButton={!autoHide}
        autoHideDuration={timeout}
        onCloseCallback={() => dispatch(hideNotification())}
        data-cy='snackbar-notification'
        className={tailwindOverride(
          'relative bg-neutral-white',
          'animate-snackarNotification'
        )}
      />
    </Alert>
  );
};

export default SnackbarNotification;
