export const CHART_DARK_GREEN = '#327569';
export const CHART_YELLOW = '#F6BD30';
export const CHART_PLUM = '#9B3A66';
export const CHART_PURPLE = '#45598C';
export const CHART_ORANGE = '#E46136';
export const CHART_BLUE = '#3389D3';
export const CHART_DARK_YELLOW = '#D57F0A';
export const CHART_DARK_RED = '#C33035';

export const CHART_COLOR_PALETTE = [
  CHART_DARK_GREEN,
  CHART_YELLOW,
  CHART_PLUM,
  CHART_PURPLE,
  CHART_ORANGE,
  CHART_BLUE,
  CHART_DARK_YELLOW,
  CHART_DARK_RED,
];
