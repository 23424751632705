import React, { useMemo } from 'react';
import intl from 'react-intl-universal';
import {
  tailwindOverride,
  Typography,
  useElevation,
  useLink,
} from '@getsynapse/design-system';
import { useRouteMatch, useParams } from 'react-router-dom';
import usePortalSettings from 'Hooks/usePortalSettings';
import { PATHS } from 'utils/constants';
import AuthImage from 'assets/images/auth-bg.png';
import PasswordBg from 'assets/images/password-bg.svg';
import Logo from 'assets/icons/logo.svg';
import LogoDark from 'assets/icons/logo-dark.svg';
import BusinessBGImage from 'assets/images/business-login.png';

const AuthPage: React.FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const {
    isFetching,
    portalBackground,
    shouldUseDefaultPortalBackground,
    portalLogo,
  } = usePortalSettings(organizationId);

  const isPasswordPage = !!useRouteMatch({
    path: [
      PATHS.FORGOT_PASSWORD,
      PATHS.CHANGE_PASSWORD,
      `${PATHS.PASSWORD_SETUP}/:userId`,
    ],
  });

  const styles = useMemo<{
    bgColor: string;
    bgUrl: string;
    bgSize: string;
  }>(() => {
    if (
      organizationId &&
      !shouldUseDefaultPortalBackground &&
      portalBackground
    ) {
      return {
        bgColor: '',
        bgUrl: portalBackground,
        bgSize: 'cover',
      };
    }

    if (
      organizationId &&
      (shouldUseDefaultPortalBackground || !portalBackground)
    ) {
      return {
        bgColor: '',
        bgUrl: BusinessBGImage,
        bgSize: 'cover',
      };
    }

    if (isPasswordPage) {
      return {
        bgColor: 'bg-neutral-lighter',
        bgUrl: PasswordBg,
        bgSize: 'contain',
      };
    }

    return {
      bgColor: 'bg-neutral-black',
      bgUrl: AuthImage,
      bgSize: 'contain',
    };
  }, [
    isPasswordPage,
    organizationId,
    shouldUseDefaultPortalBackground,
    portalBackground,
  ]);

  const externalLink = useLink(true);
  const mainElevation = useElevation(2);
  const logoImageURL = organizationId
    ? portalLogo
      ? portalLogo
      : null
    : isPasswordPage
    ? LogoDark
    : Logo;

  return (
    <div className='flex h-screen'>
      <div
        className={tailwindOverride('absolute top-8 left-8', {
          'mt-8': !portalLogo,
        })}
      >
        {logoImageURL && (
          <img
            data-cy='portal-logo'
            src={logoImageURL}
            alt='login portal'
            width={130}
            height={130}
          />
        )}
      </div>

      <div
        className={tailwindOverride('w-1/2 flex items-center', styles.bgColor)}
      >
        <div
          className='w-full h-full bg-contain bg-left bg-no-repeat'
          style={{
            backgroundImage:
              isFetching && organizationId ? undefined : `url(${styles.bgUrl})`,
            backgroundPosition: 'top center',
            backgroundSize: styles.bgSize,
          }}
          data-cy='portal-image-background'
        />
      </div>

      <div className='w-1/2 relative flex flex-col justify-between items-center'>
        <main
          className={tailwindOverride(
            'w-full bg-neutral-white rounded flex justify-center h-5/6 items-center my-auto',
            mainElevation,
            className
          )}
          style={{ transform: 'translateX(-5%)' }}
        >
          {children}
        </main>

        <Typography variant='caption' className='pb-4'>
          {intl.getHTML('FOOTER', {
            year: new Date().getFullYear(),
            externalLink: `class='${externalLink}'`,
          })}
        </Typography>
      </div>
    </div>
  );
};

export default AuthPage;
