import { useEffect, useMemo, useCallback, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import {
  selectTimeOffEntryIdToDelete,
  setTimeOffEntryIdToDelete,
  removeTimeOffEntry,
} from 'state/ManageTimeOff/TimeOffEntries/all/allTimeOffEntriesSlice';
import useGetUserId from 'Pages/TimeOffListPage/hooks/useGetUserId';
import useModal from 'Hooks/useModal';

export interface DeleteTimeOffConfimationModalProps {
  showSnackbarNotification: (snackbarProps: any) => void;
  onDeleteTimeOffEntry: (timeOffEntryId: string, userId: string) => void;
}

const DeleteTimeOffConfimationModal: FC<DeleteTimeOffConfimationModalProps> = ({
  showSnackbarNotification,
  onDeleteTimeOffEntry,
}) => {
  const dispatch = useDispatch();
  const timeOffEntryIdToDelete = useSelector(selectTimeOffEntryIdToDelete);
  const userId = useGetUserId();
  const { Modal, modalProps, openModal, closeModal } = useModal();

  useEffect(() => {
    if (timeOffEntryIdToDelete && !modalProps.isOpen) {
      openModal({
        ...modalProps,
        isOpen: true,
      });
    }
  }, [modalProps, openModal, timeOffEntryIdToDelete]);

  const handleCloseModal = useCallback(() => {
    dispatch(setTimeOffEntryIdToDelete(null));
    closeModal();
  }, [dispatch, closeModal]);

  const handleDeleteTimeOffEntry = useCallback(async () => {
    try {
      await dispatch(
        removeTimeOffEntry({
          userId: userId!,
          timeOffEntryId: timeOffEntryIdToDelete!,
        })
      );
      onDeleteTimeOffEntry(timeOffEntryIdToDelete!, userId!);
      showSnackbarNotification({
        notificationMessage: intl.get(
          'MANAGE_TIME_OFF.DELETE_TIME_OFF_SUCCESS'
        ),
      });
    } catch (error) {
      showSnackbarNotification({
        notificationVariant: 'error',
        notificationTitle: intl.get('MANAGE_TIME_OFF.SAVE_CHANGES_ERROR_TITLE'),
        notificationMessage: intl.get(
          'MANAGE_TIME_OFF.SAVE_CHANGES_ERROR_MESSAGE'
        ),
      });
    } finally {
      handleCloseModal();
    }
  }, [
    dispatch,
    handleCloseModal,
    userId,
    timeOffEntryIdToDelete,
    showSnackbarNotification,
    onDeleteTimeOffEntry,
  ]);

  const actionButtons = useMemo(
    () => [
      {
        children: intl.get('MANAGE_TIME_OFF.DELETE_TIME_OFF_BUTTON'),
        color: 'danger',
        onClick: handleDeleteTimeOffEntry,
      },
      {
        children: intl.get('CANCEL'),
        variant: 'tertiary',
        onClick: handleCloseModal,
      },
    ],
    [handleCloseModal, handleDeleteTimeOffEntry]
  );

  return (
    <Modal
      {...modalProps}
      title={intl.get('MANAGE_TIME_OFF.DELET_TIME_OFF_TITLE')}
      titleIcon={{
        name: 'trash',
        className: 'text-neutral-dark text-sm',
      }}
      size='medium'
      aria-label={intl.get('MANAGE_TIME_OFF.DELET_TIME_OFF_TITLE')}
      actionButtons={actionButtons}
      closeModal={handleCloseModal}
      childrenClassName='flex flex-col space-y-6'
    >
      <Typography variant='body'>
        {intl.get('MANAGE_TIME_OFF.DELETE_TIME_OFF_MESSAGE')}
      </Typography>
      <Typography variant='body'>
        {intl.get('MANAGE_TIME_OFF.DELETE_TIME_OFF_CONFIRMATION')}
      </Typography>
    </Modal>
  );
};

export default DeleteTimeOffConfimationModal;
