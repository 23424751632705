import { FC } from 'react';
import intl from 'react-intl-universal';
import {
  TableRow,
  tailwindOverride,
  Checkbox,
  Typography,
} from '@getsynapse/design-system';
import { TaskWithAssignees } from 'types/store/projectTasksList';
import { getTaskType } from 'utils/tasks';
import { TASK_STATUS, TASK_TYPE_CATEGORY } from 'utils/constants';
import {
  getDueAndActualDateColumn,
  getStatusColumn,
} from 'Pages/ProjectsListPage/helpers/tableColumnsValues';
import AssigneesColumn from 'Pages/TasksListPage/components/v2/TasksListTable/TaskRow/AssigneesColumn/AssigneesColumn';
import DisableColumnIcon from '../DisableColumnIcon/DisableColumnIcon';
import ReorderColumn from '../ReorderColumn/ReorderColumn';
import ActionButtonsColumn from '../ActionButtonsColumn/ActionButtonsColumn';

interface TaskRowProps {
  task: TaskWithAssignees;
  bgColor?: string;
  showDisabledColumn?: boolean;
  showReorderColumn?: boolean;
}

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});

const TaskRow: FC<TaskRowProps> = ({
  task,
  bgColor = 'bg-neutral-white',
  showDisabledColumn = false,
  showReorderColumn = false,
}) => {
  const columnStyles = tailwindOverride(
    'border-b border-t border-r-0 border-transparent',
    'px-4 py-3'
  );

  const stickyColumnStyles = tailwindOverride(
    columnStyles,
    bgColor,
    'sticky z-1'
  );

  const formatDate = (dateString: string): string => {
    return dateFormatter.format(new Date(dateString));
  };

  return (
    <TableRow
      id={task.id}
      aria-label={`task-row-${task.id}`}
      className={tailwindOverride('group cursor-pointer relative', bgColor)}
      cells={[
        {
          content: (
            <div className='w-6'>
              <Checkbox
                onChange={() => {}}
                label=''
                aria-label={`task-row-${task.id}-checkbox`}
              />
            </div>
          ),
          className: tailwindOverride(stickyColumnStyles, 'w-6 left-0'),
        },
        {
          content: (
            <div className='w-14'>
              <ReorderColumn taskId={task.id} onReorderRow={() => {}} />
            </div>
          ),
          className: tailwindOverride(stickyColumnStyles, 'w-14 left-14', {
            hidden: !showReorderColumn,
          }),
        },
        {
          content: (
            <div className='w-12 flex items-center justify-center'>
              <DisableColumnIcon
                taskId={task.id}
                isTaskDisabled={task.disabled || false}
                isTaskCompleted={task.status === TASK_STATUS.COMPLETED}
              />
            </div>
          ),
          className: tailwindOverride(stickyColumnStyles, 'w-12 p-0', {
            hidden: !showDisabledColumn,
            'left-36': showReorderColumn,
            'left-14': !showReorderColumn,
          }),
        },
        {
          content: <div className='w-32'>{task.displayId}</div>,
          className: tailwindOverride(stickyColumnStyles, 'w-32', {
            'left-26': !showReorderColumn && showDisabledColumn,
            'left-48': showReorderColumn && showDisabledColumn,
            'left-14': !showReorderColumn && !showDisabledColumn,
            'left-36': showReorderColumn && !showDisabledColumn,
          }),
        },
        {
          content: <div className='w-full truncate'>{task.name}</div>,
          className: tailwindOverride(stickyColumnStyles, 'w-1/3', {
            'left-66': !showReorderColumn && showDisabledColumn,
            'left-88': showReorderColumn && showDisabledColumn,
            'left-54': !showReorderColumn && !showDisabledColumn,
            'left-76': showReorderColumn && !showDisabledColumn,
          }),
        },
        {
          content: <div className='w-20'>{getStatusColumn(task.status)}</div>,
          className: tailwindOverride(columnStyles, 'w-20'),
        },
        {
          content: (
            <div className='truncate w-44'>
              <AssigneesColumn assignees={task.assignees} />
            </div>
          ),
          className: tailwindOverride(columnStyles, 'w-44'),
        },
        {
          content: (
            <div className='w-31'>
              {task?.type
                ? getTaskType(task.type, TASK_TYPE_CATEGORY.TABLE)
                : intl.get('TASKS.TABLE.NO_TASK_TYPE')}
            </div>
          ),
          className: tailwindOverride(columnStyles, 'w-31'),
        },
        {
          content: (
            <div
              className={tailwindOverride('w-38', {
                'text-neutral-dark': !task.startDate,
              })}
            >
              {task.startDate
                ? formatDate(task.startDate)
                : intl.get('TASKS.TABLE.NO_START_DATE')}
            </div>
          ),
          className: tailwindOverride(columnStyles, 'w-38'),
        },
        {
          content: (
            <div className='w-60'>
              {getDueAndActualDateColumn({
                status: task.status,
                due_date: task.endDate,
                completion_date: task.completedDate,
                id: task.id,
              })}
            </div>
          ),
          className: tailwindOverride(columnStyles, 'w-60'),
        },
        {
          content: (
            <div className='w-34'>
              <Typography variant='label' className='text-primary'>
                {task.estimateHours
                  ? `${parseFloat(task.estimateHours)}h`
                  : '0h'}
                {task.actualHours ? ` / ${parseFloat(task.actualHours)}h` : ''}
              </Typography>
            </div>
          ),
          className: tailwindOverride(columnStyles, 'w-34'),
        },
        {
          content: (
            <div
              className='w-12'
              onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                event.stopPropagation();
              }}
            >
              <div className='hidden group-hover:flex'>
                <ActionButtonsColumn
                  taskId={task.id}
                  displayDuplicateTaskModal={() => {}}
                />
              </div>
            </div>
          ),
          className: tailwindOverride(stickyColumnStyles, 'w-12 right-0'),
        },
      ]}
    />
  );
};

export default TaskRow;
