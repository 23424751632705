import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { SLICE_STATUS } from 'utils/constants';
import { Status } from 'utils/customTypes';
import { convertPartialsHoursToMinutes } from 'Pages/TimeOffListPage/utils/partialHours';
import { TimeOffEntry } from 'types/store/manageTimeOff';
import {
  AddTimeOffEntryParams,
  addTimeOffEntry,
  EditTimeOffEntryParams,
  editTimeOffEntry,
  deleteTimeOffEntry,
} from 'api/manageTimeOff';

interface AllTimeOffEntriesState {
  timeOffEntryIdToDelete: string | null;
  byId: { [id: string]: TimeOffEntry };
  status: Status;
}

const initialState: AllTimeOffEntriesState = {
  timeOffEntryIdToDelete: null,
  byId: {},
  status: SLICE_STATUS.IDLE,
};

const dateFormatter = new Intl.DateTimeFormat('fr-CA');

export const createTimeOffEntry = createAsyncThunk(
  'manageTimeOff/allTimeOffEntries/createTimeOffEntry',
  async (
    { userId, timeOffTypeId, startDate, endDate, days }: AddTimeOffEntryParams,
    { rejectWithValue }
  ) => {
    try {
      const newTimeOffEntry = await addTimeOffEntry({
        userId,
        timeOffTypeId,
        startDate: dateFormatter.format(new Date(startDate)),
        endDate: dateFormatter.format(new Date(endDate)),
        days: convertPartialsHoursToMinutes(days),
      });
      return {
        id: newTimeOffEntry.timeOffEntry.id,
        timeOffTypeId: newTimeOffEntry.timeOffEntry.timeOffTypeId,
        startDate: newTimeOffEntry.timeOffEntry.startDate,
        endDate: newTimeOffEntry.timeOffEntry.endDate,
        hours: Object.entries(newTimeOffEntry.timeOffEntry.days)
          .reduce((acc, [_, day]) => acc + day.hours, 0)
          .toString(),
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTimeOffEntry = createAsyncThunk(
  'manageTimeOff/allTimeOffEntries/updateTimeOffEntry',
  async (
    { id, userId, timeOffEntry }: EditTimeOffEntryParams,
    { rejectWithValue }
  ) => {
    try {
      const timeOffEntryToUpdate = { ...timeOffEntry };

      if (timeOffEntryToUpdate.startDate) {
        timeOffEntryToUpdate.startDate = dateFormatter.format(
          new Date(timeOffEntryToUpdate.startDate)
        );
      }

      if (timeOffEntryToUpdate.endDate) {
        timeOffEntryToUpdate.endDate = dateFormatter.format(
          new Date(timeOffEntryToUpdate.endDate)
        );
      }

      if (timeOffEntryToUpdate.days) {
        timeOffEntryToUpdate.days = convertPartialsHoursToMinutes(
          timeOffEntryToUpdate.days
        );
      }

      const updatedTimeOffEntry = await editTimeOffEntry({
        userId,
        id,
        timeOffEntry: timeOffEntryToUpdate,
      });

      return updatedTimeOffEntry.timeOffEntry;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeTimeOffEntry = createAsyncThunk(
  'manageTimeOff/allTimeOffEntries/removeTimeOffEntry',
  async (
    { userId, timeOffEntryId }: { userId: string; timeOffEntryId: string },
    { rejectWithValue }
  ) => {
    try {
      await deleteTimeOffEntry({ userId, timeOffEntryId });
      return { id: timeOffEntryId };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const allTimeOffEntriesSlice = createSlice({
  name: 'manageTimeOff/allTimeOffEntries',
  initialState,
  reducers: {
    addTimeOffEntries: (state, action) => {
      state.byId = {
        ...state.byId,
        ...action.payload,
      };
    },
    setTimeOffEntryIdToDelete: (
      state,
      action: { type: string; payload: string | null }
    ) => {
      state.timeOffEntryIdToDelete = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTimeOffEntry.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(createTimeOffEntry.fulfilled, (state, action) => {
        const { byId } = state;
        if (action.payload) {
          state.byId = {
            ...byId,
            [action.payload.id]: action.payload,
          };
        }
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(createTimeOffEntry.rejected, (state, action) => {
        state.status = SLICE_STATUS.FAILED;
        throw new Error(action.payload as string);
      })
      .addCase(updateTimeOffEntry.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(updateTimeOffEntry.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        if (action.payload) {
          const updatedTimeOffEntry = action.payload;
          const timeOffEntryToUpdate = {
            ...state.byId[updatedTimeOffEntry.id],
          };
          if (updatedTimeOffEntry.timeOffTypeId) {
            timeOffEntryToUpdate.timeOffTypeId =
              updatedTimeOffEntry.timeOffTypeId;
          }
          if (updatedTimeOffEntry.startDate) {
            timeOffEntryToUpdate.startDate = updatedTimeOffEntry.startDate;
          }
          if (updatedTimeOffEntry.endDate) {
            timeOffEntryToUpdate.endDate = updatedTimeOffEntry.endDate;
          }
          state.byId = {
            ...state.byId,
            [updatedTimeOffEntry.id]: {
              ...timeOffEntryToUpdate,
            },
          };
        }
      })
      .addCase(updateTimeOffEntry.rejected, (state, action) => {
        state.status = SLICE_STATUS.FAILED;
        throw new Error(action.payload as string);
      })
      .addCase(removeTimeOffEntry.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(removeTimeOffEntry.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        const newById = { ...state.byId };
        delete newById[action.payload.id];
        state.byId = { ...newById };
      })
      .addCase(removeTimeOffEntry.rejected, (state, action) => {
        state.status = SLICE_STATUS.FAILED;
        throw new Error(action.payload as string);
      });
  },
});

export const { addTimeOffEntries, setTimeOffEntryIdToDelete } =
  allTimeOffEntriesSlice.actions;

export const selectCreateAllTimeOffEntryStatus = (state: RootState) =>
  state.manageTimeOff.allTimeOffEntries.status;

export const selectAllTimeOffEntriesById = (state: RootState) =>
  state.manageTimeOff.allTimeOffEntries.byId;

export const selectTimeOffEntryIdToDelete = (state: RootState) =>
  state.manageTimeOff.allTimeOffEntries.timeOffEntryIdToDelete;

export const selectAllTimeOffEntries = createSelector(
  [selectAllTimeOffEntriesById],
  (entries: AllTimeOffEntriesState['byId']) => Object.values(entries)
);

export const selectTimeOffEntry = createSelector(
  [selectAllTimeOffEntriesById, (_: RootState, id: string) => id],
  (entries: AllTimeOffEntriesState['byId'], id: string) => entries[id]
);

export default allTimeOffEntriesSlice.reducer;
