import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { FiltersPanelSkeletonLoader } from './FiltersPanelSkeletonLoader';
import { Card } from 'Pages/InsightsPage/Components/Card';
import FilterSections from './components/FilterSections';
import { SLICE_STATUS } from 'utils/constants';
import { useEffect, useState } from 'react';
import { fetchFilters, getFilters } from 'state/Insights/InsightsSlice';

const FiltersPanel = () => {
  const dispatch = useDispatch();
  const filters = useSelector(getFilters);
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    dispatch(fetchFilters());
  }, [dispatch]);

  const status = filters.status || SLICE_STATUS.IDLE;
  return (
    <Card
      className={classnames(
        'h-full text-base flex flex-col relative overflow-y-auto',
        `${isExpanded ? 'w-80 min-w-80' : 'w-12 min-w-12'}`
      )}
    >
      {status === SLICE_STATUS.LOADING ? (
        <FiltersPanelSkeletonLoader />
      ) : (
        <FilterSections
          status={status}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          filters={filters}
        />
      )}
    </Card>
  );
};

export default FiltersPanel;
