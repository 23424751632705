import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import orderBy from 'lodash/orderBy';
import { PROGRAMS_DELIVERY_TYPE, PROGRAMS_STATUS } from 'utils/constants';
import { Option } from 'utils/customTypes';
import { selectLDUsersForDropdown } from 'state/UsersManagement/usersManagementSlice';
import { ProgramFiltersKey } from 'utils/types/filters';
import { PROGRAM_TABLE_FILTERS } from 'utils/constants/program';

const useProgramFilters = () => {
  const statusOptions = useMemo<Option[]>(() => {
    const options = Object.keys(PROGRAMS_STATUS).map((key) => ({
      value: PROGRAMS_STATUS[key as keyof typeof PROGRAMS_STATUS],
      label: intl.get(`PROGRAMS_LIST_PAGE.STATUS.${key}`),
    }));
    return orderBy(options, 'label');
  }, []);

  const deliveryOptions = useMemo<Option[]>(() => {
    const options = Object.keys(PROGRAMS_DELIVERY_TYPE).map((key) => ({
      value: PROGRAMS_DELIVERY_TYPE[key as keyof typeof PROGRAMS_DELIVERY_TYPE],
      label: intl.get(`PROGRAMS_LIST_PAGE.DELIVERY_TYPES.${key}`),
    }));
    return orderBy(options, 'label');
  }, []);

  const ownerOptions = useSelector(selectLDUsersForDropdown);

  const getOptionsbyKey = (key: ProgramFiltersKey) => {
    switch (key) {
      case PROGRAM_TABLE_FILTERS.STATUS:
        return statusOptions;
      case PROGRAM_TABLE_FILTERS.DELIVERY:
        return deliveryOptions;
      case PROGRAM_TABLE_FILTERS.OWNERS:
        return ownerOptions;
      default:
        return [];
    }
  };

  return {
    statusOptions,
    deliveryOptions,
    ownerOptions,
    getOptionsbyKey,
  };
};

export default useProgramFilters;
