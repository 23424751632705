import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { selectSubmitterTimeEntries } from 'state/ActualHours/actualHoursSlice';
import AccordionTrigger from './AccordionTrigger';
import TimeEntry from '../TimeEntries/TimeEntry';

interface SubmitterAccordionProps {
  submitterId: string;
}

const SubmitterAccordion: FC<SubmitterAccordionProps> = ({ submitterId }) => {
  const submitterTimeEntries = useSelector((state: RootState) =>
    selectSubmitterTimeEntries(state, submitterId)
  );
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <div className='flex flex-col gap-y-2'>
      <AccordionTrigger
        submitter={submitterTimeEntries.submitter}
        isExpanded={isExpanded}
        toggleIsExpanded={() => setIsExpanded(!isExpanded)}
        totalHours={submitterTimeEntries.totalHours}
      />
      <div
        id={`submitter-${submitterId}__accordion-content`}
        role='region'
        aria-labelledby={`submitter-${submitterId}__accordion-trigger`}
        className='flex flex-col gap-y-2 px-12'
      >
        {isExpanded &&
          submitterTimeEntries.actualHours.map((timeEntry) => {
            return (
              <TimeEntry
                key={timeEntry.id}
                hours={timeEntry}
                onDeleteHours={() => {}}
              />
            );
          })}
      </div>
    </div>
  );
};

export default SubmitterAccordion;
