import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  IconButton,
  Typography,
  tailwindOverride,
} from '@getsynapse/design-system';
import { RootState } from 'state/store';
import {
  selectWeeksCarouselPagination,
  selectWeeksCarouselItems,
  setWeeksCarouselPagination,
  fetchMoreWeeklyCapacity,
} from 'state/TeamsCapacity/teamsCapacitySlice';
import { useWeeksCarousel } from '../hooks/useWeeksCarousel';
import { WeeksCarouselPagination } from 'utils/types/teamsCapacity';
import { TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS } from 'utils/constants';
import { Week } from 'Organisms/CapacityAllocationTable/helpers';

const WeeksCarousel = () => {
  const dispatch = useDispatch();
  const { startDate, endDate, index } = useSelector<
    RootState,
    WeeksCarouselPagination
  >(selectWeeksCarouselPagination);
  const weeksCaourselItems = useSelector<RootState, Week[]>(
    selectWeeksCarouselItems
  );
  const handleUpdatePaginationIndex = useCallback(
    (index: number) => dispatch(setWeeksCarouselPagination({ index })),
    [dispatch]
  );
  const fetchWeeklyCapacity = useCallback(
    (newPagination: WeeksCarouselPagination) => {
      dispatch(fetchMoreWeeklyCapacity(newPagination));
    },
    [dispatch]
  );
  const { handleNext, handlePrevious, currentIndex } = useWeeksCarousel(
    startDate,
    endDate,
    index,
    weeksCaourselItems.length,
    handleUpdatePaginationIndex,
    fetchWeeklyCapacity
  );
  const firstWeekVisible = currentIndex;
  const lastWeekVisible =
    currentIndex + TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS - 1;
  return (
    <div className='w-full flex h-full'>
      <div
        className={tailwindOverride(
          'w-6 h-full',
          'flex justify-center items-center'
        )}
      >
        <IconButton
          name='chevron-back'
          onClick={handlePrevious}
          className='text-primary text-lg'
          aria-label='teams-capacity__weeks-carousel-previous'
          data-cy='teams-capacity__weeks-carousel-previous'
        />
      </div>
      <div className='w-full h-full overflow-hidden'>
        <div
          className='flex h-full'
          aria-label='teams-capacity__weeks-carousel'
          data-cy='teams-capacity__weeks-carousel'
          style={{
            transition: 'transform 0.5s ease-in-out',
            transform: `translateX(-${
              currentIndex * (100 / TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS)
            }%)`,
          }}
        >
          {weeksCaourselItems.map((week: Week, index: number) => {
            const isCurrentWeek = week.isCurrentWeek || false;
            return (
              <div
                key={index}
                className={tailwindOverride(
                  'h-full relative',
                  'flex items-center justify-center',
                  'flex-shrink-0 flex-grow',
                  {
                    'text-primary bg-secondary-lightest border-l border-r border-secondary-lighter':
                      isCurrentWeek,
                  }
                )}
                style={{
                  width: `calc(100% / ${TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS})`,
                }}
                role='group'
                aria-label={`week ${index + 1} of ${weeksCaourselItems.length}`}
                aria-hidden={
                  index < firstWeekVisible || index > lastWeekVisible
                }
              >
                {isCurrentWeek && (
                  <div
                    className={tailwindOverride(
                      'w-full h-px',
                      'bg-secondary-lighter',
                      'absolute top-0 left-0'
                    )}
                  />
                )}
                {week !== null && (
                  <Typography
                    variant='caption'
                    className={tailwindOverride('font-semibold', {
                      'text-secondary-darker': isCurrentWeek,
                    })}
                  >
                    {week.label}
                  </Typography>
                )}
                {isCurrentWeek && (
                  <div
                    className={tailwindOverride(
                      'w-full h-0.5',
                      'bg-secondary-dark',
                      'absolute bottom-0 left-0'
                    )}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={tailwindOverride(
          'w-6 h-full',
          'flex justify-center items-center'
        )}
      >
        <IconButton
          name='chevron-forward'
          onClick={handleNext}
          className='text-primary text-lg'
          aria-label='teams-capacity__weeks-carousel-next'
          data-cy='teams-capacity__weeks-carousel-next'
        />
      </div>
    </div>
  );
};

export default WeeksCarousel;
