import { FormItem, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import React, { useMemo } from 'react';
import { PROJECT_STATUS } from 'utils/constants';
import ProjectStatusPicker from '../../../NewProjectPage/components/ProjectStatusPicker';
import { Option, ProjectStatus } from 'utils/customTypes';

type ReactiveModalContentProps = {
  onStatusSelection: (_: any, status: string) => void;
  projectStatus: ProjectStatus;
  projectTitle: string;
  projectId: string;
};

export function ReactivateModalContent(props: ReactiveModalContentProps) {
  const {
    onStatusSelection,
    projectStatus,
    projectId,
    projectTitle,
  }: ReactiveModalContentProps = props;

  const getInitialValueForDropDown = (
    options: Option[],
    values: string[] | string | undefined
  ) => {
    if (!values) {
      return [];
    }
    return options.filter(
      (option) => values === option.value || values.includes(option.value)
    );
  };

  const statusOptions = useMemo(() => {
    const { CLOSED, CANCELED, COMPLETED, ...remainingStatus } = PROJECT_STATUS;
    const statusList = { ...remainingStatus };
    return Object.keys(statusList).map((key) => ({
      label: intl.get(`PROJECT_DETAIL.STATUS_OPTIONS.${key}`),
      value: PROJECT_STATUS[key],
    }));
  }, []);

  const bodyText = intl
    .getHTML('PROJECT_DETAIL.REACTIVATE_PROJECT.BODY', { projectTitle })
    .defaultMessage(
      `To reactivate project ${projectTitle}, please select a new status for this project.`
    );

  return (
    <>
      <Typography variant='body' className='mt-6'>
        {bodyText}
      </Typography>
      <FormItem className='mt-6'>
        <ProjectStatusPicker
          hasError={false}
          fromSidePanel={false}
          isReadOnly={false}
          handleFormFieldChange={onStatusSelection}
          values={getInitialValueForDropDown(
            statusOptions,
            projectStatus.toString()
          )}
          options={statusOptions}
          projectId={projectId}
        />
      </FormItem>
    </>
  );
}
