import { Icon, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import classNames from 'classnames';
import roiTotalCost from 'assets/icons/roi-total-cost.svg';

type Props = {
  totalCost: number | null;
};

const TotalProgramCost = ({ totalCost }: Props) => (
  <div
    className='rounded border border-secondary-light mt-6 py-4 px-6 flex justify-between items-center'
    data-testid='roi-total-cost-banner'
  >
    <div className='flex items-center'>
      <Icon src={roiTotalCost} className='h-10 w-10' />
      <Typography weight='medium' className='mx-4'>
        {intl.get('ROI_CATEGORY.PROGRAM_COSTS.BANNER')}
      </Typography>
    </div>
    <Typography
      data-testid='roi-total-cost'
      className={classNames({
        'text-neutral': totalCost === null,
      })}
    >
      {totalCost === null
        ? intl.get('ROI_CATEGORY.ROI_CALCULATOR.NO_VALUE_PROVIDED')
        : `${intl.get('DOLLAR_SIGN')}${totalCost?.toLocaleString('en-US')}`}
    </Typography>
  </div>
);

export default TotalProgramCost;
