import {
  IdentifierFormat,
  objKeyAsString,
  ProjectPermissionsLevel,
} from 'utils/customTypes';

export const PATHS = {
  ALMOST_THERE: '/almost-there',
  LOGIN: '/login',
  LOGOUT_AND_LOGIN: '/login?logout=true',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  ROOT: '/',
  REQUEST_PAGE: '/request',
  SSO_AUTHENTICATION_PAGE: '/sso',
  SSO_AUTHENTICATION_CALLBACK: '/callback',
  REQUESTS_LIST_PAGE: '/requests-list',
  INSIGHTS: '/insights',
  DESIGN: '/design',
  TEAMS: '/teams',
  TEAMS_SETTINGS: '/settings/teams',
  PROJECTS_LIST_PAGE: '/projects-list',
  NEW_PROJECT_PAGE: '/new-project',
  PROJECT_PAGE: '/project',
  SETTINGS: '/settings',
  EDIT_TEAM: '/settings/team',
  USER_PAGE: '/settings/user',
  PROCESS_PAGE: '/settings/process',
  TEMPLATE_PAGE: '/settings/template',
  NEW_TEMPLATE_PAGE: '/settings/template/new',
  NEW_TASK_BUNDLE_PAGE: '/settings/task-bundle/new',
  EDIT_TASK_BUNDLE_PAGE: '/settings/task-bundle/:taskBundleId',
  CLONE_TASK_BUNDLE_PAGE: '/settings/task-bundle/:taskBundleId/clone',
  FORM_PAGE: '/form',
  VENDOR_PAGE: '/settings/vendor',
  DEACTIVATED_ACCOUNT: '/deactivated',
  PASSWORD_SETUP: '/password-setup',
  VERIFY_USER: '/verify',
  REGISTER: '/signup',
  UPGRADE: '/upgrade',
  COMPLETE_REGISTRATION: '/complete-registration',
  PROFILE: '/profile',
  NOTIFICATIONS: '/notifications',
  TIME_OFF: '/time-off',
  MANAGE_TIME_OFF: '/manage-time-off',
  TASKS: '/tasks',
  TASKS_LIST_PAGE: '/tasks-list',
  ELEMENTS: 'elements',
  PROGRAMS_LIST_PAGE: '/programs-list',
  PROGRAM_PAGE: '/program',
  STRATEGY_PAGE: '/strategy',
  CUSTOM_FIELDS_PAGE: '/fields',
  NEW_CUSTOM_FIELD_PAGE: '/settings/new-field',
  CONFIGURATIONS_PAGE: '/settings/configurations',
  CATEGORY_OBJECTIVE_PAGE: '/objectives',
  ADDITIONAL_BENEFIT_PAGE: '/additional-benefit',
  INPUT_CATEGORY_PAGE: '/input-category',
  ROI_CATEGORY_PAGE: '/roi-category',
  ROI_OBJECTIVE_PAGE: '/roi-objective',
  ROI_COSTS_PAGE: '/roi-costs',
  LEARNING_SCHEDULE_PAGE: '/learning-schedule',
};

export const INSIGHTS_TABS = {
  INTAKE: 'intake',
  PROJECTS: 'projects',
  CAPACITY: 'capacity',
};

export const INSIGHTS_INTAKE_REQUESTS_BY_CHART = {
  STATUS: 'status',
  BUSINESS_UNIT: 'Business Unit',
  TYPE: 'type',
  FORM: 'form',
};

export const INSIGHTS_INTAKE_REQUESTS_BY_CHART_FILTERS = [
  'status',
  'businessUnit',
  'type',
  'form',
];

export const SETTINGS_TABS = {
  ACCOUNT: 'account',
  USERS: 'users',
  TEAMS: 'teams',
  CONFIGURATIONS: 'configurations',
};

export const TABLE_TABS_MAPPING: { [key: string]: number } = {
  teamItems: 0,
  myItems: 1,
};

export const DETAILS_PAGE_TABS_MAPPING: { [key: string]: number } = {
  overview: 0,
  build: 1,
};

export const SETTINGS_SECTIONS = {
  INTAKE: 'intake',
  PROJECTS: 'projects',
  FIELDS: 'fields',
  LD_TEAM: 'ldTeams',
  BUSINESS_TEAM: 'businessTeams',
  VENDORS: 'vendors',
};

export const SETTINGS_ATTRIBUTES = {
  GENERAL: 'general',
  REQUEST_FORM: 'requestForms',
  PROCESS: 'process',
  TEMPLATES: 'templates',
  TASK_BUNDLES: 'taskBundles',
};

export const AUTH_CONSTANTS = {
  SCOPE: ['email', 'openid', 'aws.cognito.signin.user.admin'],
  RESPONSE_TYPE: 'code',
};

export const REQUEST_TYPE = [
  'CONTENT_DEVELOPMENT',
  'LEARNING_DELIVERY',
  'LEARNING_ADMIN',
  'CONSULTING',
  'ASSESSMENT_EVALUATION',
  'EMPLOYEE_SERVICE',
  'COACHING_MENTORING',
  'EVENTS',
  'OTHERS',
] as const;

export const DATE = {
  SHORT_FORMAT: 'MMM D, yyyy',
  EXPORT_CSV_FORMAT: 'YYYYMMDD',
  MONTH_DATE_YEAR_FORMAT: 'll',
  TASK_TABLE_FORMAT: 'MMM D, yyyy',
  TASK_DETAIL_FORMAT: 'MMM DD, yyyy',
  FULL_MONTH_YEAR_FORMAT: 'LL',
  LONG_FORMAT: 'MMMM D, yyyy',
  DATE_STAMP: 'YYYY-MM-DD',
};

export const USER_TYPES: Record<string, string> = {
  BUSINESS: 'business',
  EXTERNAL: 'external',
  L_D: 'ld',
  FACILITATOR: 'facilitator',
};

export const USER_ROLES = {
  ADMIN: 'admin',
  USER: 'user',
} as const;

export const TABLE_FILTERS_OPERATORS = {
  CONTAINS: 'CONTAINS',
  DOESNT_CONTAIN: 'DOESNT_CONTAIN',
  EQUAL: 'EQUAL',
  NOT_EQUAL: 'NOT_EQUAL',
  GREATER: 'GREATER',
  GREATER_OR_EQUAL: 'GREATER_OR_EQUAL',
  LESS: 'LESS',
  LESS_OR_EQUAL: 'LESS_OR_EQUAL',
  BETWEEN: 'BETWEEN',
  NULL: 'NULL',
  NOT_NULL: 'NOT_NULL',
  OVERLAPS: 'OVERLAPS',
};

const OPTIONAL_COMPARATOR = [
  TABLE_FILTERS_OPERATORS.EQUAL,
  TABLE_FILTERS_OPERATORS.NOT_NULL,
  TABLE_FILTERS_OPERATORS.NULL,
];

export const DATE_COMPARATOR = [
  TABLE_FILTERS_OPERATORS.EQUAL,
  TABLE_FILTERS_OPERATORS.GREATER,
  TABLE_FILTERS_OPERATORS.GREATER_OR_EQUAL,
  TABLE_FILTERS_OPERATORS.LESS,
  TABLE_FILTERS_OPERATORS.LESS_OR_EQUAL,
  TABLE_FILTERS_OPERATORS.BETWEEN,
];

export const DATE_RANGE_COMPARATOR = [
  TABLE_FILTERS_OPERATORS.EQUAL,
  TABLE_FILTERS_OPERATORS.BETWEEN,
  TABLE_FILTERS_OPERATORS.OVERLAPS,
];

export const DROPDOWN_COMPARATOR = [
  TABLE_FILTERS_OPERATORS.EQUAL,
  TABLE_FILTERS_OPERATORS.NOT_EQUAL,
];

export const STRING_COMPARATOR = [
  TABLE_FILTERS_OPERATORS.CONTAINS,
  TABLE_FILTERS_OPERATORS.DOESNT_CONTAIN,
];

export const NUMBER_COMPARATOR = [
  TABLE_FILTERS_OPERATORS.EQUAL,
  TABLE_FILTERS_OPERATORS.NOT_EQUAL,
  TABLE_FILTERS_OPERATORS.GREATER,
  TABLE_FILTERS_OPERATORS.GREATER_OR_EQUAL,
  TABLE_FILTERS_OPERATORS.LESS,
  TABLE_FILTERS_OPERATORS.LESS_OR_EQUAL,
];

export const COLUMN_OPTION_TYPES = {
  TEXT: 'text',
  OPTIONS: 'options',
  NUMBER: 'number',
  DATE: 'date',
};

export const REQUEST_COLUMNS = [
  {
    value: 'requestIdentifier',
    operators: [
      TABLE_FILTERS_OPERATORS.CONTAINS,
      TABLE_FILTERS_OPERATORS.DOESNT_CONTAIN,
      TABLE_FILTERS_OPERATORS.GREATER,
      TABLE_FILTERS_OPERATORS.GREATER_OR_EQUAL,
      TABLE_FILTERS_OPERATORS.LESS,
      TABLE_FILTERS_OPERATORS.LESS_OR_EQUAL,
    ],
  },
  {
    value: 'requester',
    operators: STRING_COMPARATOR,
  },
  { value: 'title', operators: STRING_COMPARATOR },
  {
    value: 'submittedAt',
    operators: DATE_COMPARATOR,
    type: COLUMN_OPTION_TYPES.DATE,
  },
  { value: 'formTitle', operators: STRING_COMPARATOR },
  {
    value: 'createdAt',
    operators: DATE_COMPARATOR,
    type: COLUMN_OPTION_TYPES.DATE,
  },
  { value: 'status', operators: STRING_COMPARATOR },
  {
    value: 'requestProjects',
    type: COLUMN_OPTION_TYPES.OPTIONS,
    operators: OPTIONAL_COMPARATOR,
  },
];

export const REQUEST_TABLE_FILTERS = {
  STATUS: 'status',
  REQUESTER: 'requester_id',
  OWNERS: 'owners',
  PROJECTS: 'requestProjects',
  BUSINESS_TEAMS: 'businessTeams',
  PRIORITY: 'ldPriority',
  FORM: 'form_id',
  LINKED_PROJECTS: 'linkedProjects',
  CREATED_AT: 'createdAt',
  SUBMITTED_AT: 'submittedAt',
  DECISION_DATE: 'decision_date',
  UPDATED_AT: 'updatedAt',
};

export const LD_USER_PERMISSIONS = [
  {
    value: 'decision_date',
    operators: DATE_COMPARATOR,
    type: COLUMN_OPTION_TYPES.DATE,
  },
  {
    value: 'owners',
    operators: STRING_COMPARATOR,
  },
  { value: 'businessTeams', operators: STRING_COMPARATOR },
  { value: 'ldPriority', operators: STRING_COMPARATOR },
  {
    value: 'updatedAt',
    operators: DATE_COMPARATOR,
    type: COLUMN_OPTION_TYPES.DATE,
  },
];

export const BUSINESS_USER_PERMISSIONS = [
  {
    value: 'updatedAt',
    operators: DATE_COMPARATOR,
    type: COLUMN_OPTION_TYPES.DATE,
  },
];

export const REQUEST_SECTIONS = {
  BASIC_DETAILS: 'basicDetail',
  REQUEST_DETAILS: 'requestDetails',
  ADDITIONAL_DETAILS: 'additionalDetails',
  LD_DETAILS: 'ldDetails',
} as const;

export const REQUEST_PROPERTIES = {
  ADDITONAL_INFORMATION: 'additionalInformation',
  EFFORT: 'effort',
  COST: 'cost',
  LD_PRIORITY: 'ldPriority',
  REQUEST_IDENTIFIER: 'requestIdentifier',
  BUSINESS_UNIT: 'businessUnit',
  REQUEST_TITLE: 'requestTitle',
  REQUEST_OWNER: 'requestOwner',
  REQUEST_CREATOR: 'request_creator_id',
  REQUESTER: 'requester_id',
  REQUEST_DESC: 'requestDescription',
  FORM_TITLE: 'formTitle',
  REQUEST_BUSINESS_REVIEWER: 'businessReviewer',
  REQUEST_LD_REVIEWER: 'ldReviewer',
};

export const REQUEST_STATUS = {
  SUBMITTED: 'submitted',
  DRAFT: 'draft',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PENDING_CANCELLATION: 'pending_cancellation',
  CANCELED: 'canceled',
  WAITLISTED: 'waitlisted',
};

export const REQUEST_LD_PRIORITY = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
  UNASSIGNED: 'unassigned',
} as const;

export const REQUEST_LINKED_PROJECTS_OPTIONS = {
  NONE: 'none',
  ONE: 'one',
  MANY: 'many',
} as const;

export const REQUEST_USER_LEVELS = {
  REQUESTER: 'requester',
  OWNER: 'owner',
  TEAM_MEMBER: 'teamMember',
};

export const REQUEST_REQUIRED_FIELDS = {
  TITLE: 'title',
  LD_PRIORITY: 'ldPriority',
} as const;

export const REQUESTS_TABLE = {
  MY_REQUESTS: 'myRequests',
  TEAM_REQUESTS: 'teamRequests',
} as const;

export const NEW_PROJECT_FORM_FIELDS = {
  TITLE: 'title',
  BUSINESS_UNIT: 'businessUnitId',
  CATEGORY: 'category_id',
  DESCRIPTION: 'description',
  START_DATE: 'startDate',
  TARGET_COMPLETION_DATE: 'targetCompletionDate',
  TARGET_LAUNCH_DATE: 'targetLaunchDate',
  ACTUAL_COMPLETION_DATE: 'actualDate',
  PROCESS: 'process_id',
  STATUS: 'status',
  PROGRAMS: 'programs',
  STAGE: 'stage_id',
  PRIVACY: 'privacy',
  PRIORITY: 'priority',
  PROJECT_OWNERS: 'owners',
  RESOURCING_TYPE: 'resourcing_type',
  ESTIMATED_EFFORT: 'estimatedEffort',
  BUDGET_SOURCE: 'budget_source',
  ESTIMATED_COST: 'estimated_cost',
  ALLOCATED_BUDGET: 'allocated_budget',
  HEALTH: 'health',
  PROJECT_REQUESTS: 'projectRequests',
  VENDORS: 'vendors',
  TEAMS: 'learningTeams',
  BUSINESS_TEAMS: 'businessTeams',
  TEMPLATE: 'project_template_id',
  IS_ARCHIVED: 'is_archived',
  TASK_BUNDLE: 'task_bundle_id',
};

export const PROJECT_PRIVACY: objKeyAsString = {
  PRIVATE: 'private',
  TEAM: 'team',
  PUBLIC: 'public',
};

export const PROJECT_HEALTH: objKeyAsString = {
  ON_TRACK: 'green',
  OFF_TRACK: 'red',
  AT_RISK: 'orange',
};

export const PROJECT_RESOURCING_TYPE: objKeyAsString = {
  INTERNAL: 'internal',
  VENDOR: 'vendor',
  MIXED: 'mixed',
};

export const PROJECT_BUDGET_SOURCE: objKeyAsString = {
  LD: 'ld_budget',
  BUSINESS: 'business_budget',
  SPECIAL: 'special',
  MIXED: 'mixed',
  OTHERS: 'others',
};

export const PROJECT_PRIORITY: objKeyAsString = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};

export const PROJECT_STATUS: objKeyAsString = {
  NEW: 'new',
  IN_PLANNING: 'in_planning',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  ON_HOLD: 'on_hold',
  CLOSED: 'closed',
};

export const UPDATE_PROJECT_TABS: objKeyAsString = {
  OVERVIEW: 'overview',
  TASKS: 'tasks',
  PEOPLE: 'people',
  BUDGET: 'budget',
  CONTENT: 'content',
};

export const PROJECT_CANCEL_REASONS: objKeyAsString = {
  BUSINESS_ASK: 'Business ask',
  PRIORITY_CHANGE: 'Priority change',
  LACK_OF_RESOURCE: 'Lack of resource',
  TIMELINE_CONFLICT: 'Timeline conflict',
  TECHNICAL_ISSUE: 'Technical issue',
  BUDGET_ISSUE: 'Budget issue',
  PROCUREMENT_ISSUE: 'Procurement issue',
  VENDOR_ISSUE: 'Vendor issue',
  OTHER: 'Other',
};

export const PROJECT_ONHOLD_REASONS: objKeyAsString = {
  BUSINESS_PRIORITY_CHANGE: 'Priority change',
  PROJECT_SCOPE_CHANGE: 'Project scope change',
  SME: 'SME not available',
  LACK_OF_BUSINESS_RESOURCE: 'Lack of resource',
  LD_PRIORITY_CHANGE: 'L&D priority change',
  LACK_OF_LD_RESOURCE: 'Lack of L&D resource',
  TIMELINE_CONFLICT: 'Timeline conflict',
  PROCUREMENT_ISSUE: 'Procurement issue',
  TECHNICAL_ISSUE: 'Technical issue',
  VENDOR_ISSUE: 'Vendor issue',
  OTHER: 'Other',
};

export const REQUEST_ACTIONS: objKeyAsString = {
  DOWNLOAD_REQUEST: 'DOWNLOAD_REQUEST',
  CANCEL_REQUEST: 'CANCEL_REQUEST',
  CREATE_PROJECT: 'CREATE_PROJECT',
  LINK_PROJECT: 'LINK_PROJECT',
};

export const REASONS_OF_CANCELLATION = [
  'PRIORITY_CHANGE',
  'BUSINESS_TIMELINE',
  'LACK_OF_RESOURCE',
  'LACK_OF_BUDGET',
  'PROCUREMENT_ISSUE',
  'VENDOR_ISSUE',
  'OTHER',
] as const;

export const REASONS_OF_DECLINATION = [
  'NOT_ALIGNED',
  'DUPLICATE_REQUEST',
  'SERVICE_NOT_AVAILABLE',
  'EXTERNAL_VENDOR_RECOMMENDED',
  'RESOURCE_AVAILABILITY',
  'BUDGET_AVAILABILITY',
  'TIMELINE_CONFLICT',
  'TECHNOLOGY_CONSTRAINT',
  'VENDOR_AVAILABILITY',
  'OTHER',
] as const;

export const PROJECTS_TABLE_COLUMNS = {
  PROJECT_NUMBER: 'PROJECT_NUMBER',
  PROJECT_NAME: 'PROJECT_NAME',
  STATUS: 'STATUS',
  BUSINESS_UNIT: 'BUSINESS_UNIT',
  PROJECT_CATEGORY: 'PROJECT_CATEGORY',
  PRIORITY: 'PRIORITY',
  PROJECT_OWNER: 'PROJECT_OWNER',
  START_DATE: 'START_DATE',
  TARGET_COMPLETION_DATE: 'TARGET_COMPLETION_DATE',
  ACTUAL_COMPLETION_DATE: 'ACTUAL_COMPLETION_DATE',
  RESOURCING_TYPE: 'RESOURCING_TYPE',
  PROCESS_STAGE: 'PROCESS_STAGE',
  PROJECT_STAGE: 'PROJECT_STAGE',
  HEALTH: 'HEALTH',
  PARTICIPANT_TYPE: 'PARTICIPANT_TYPE',
  TEAMS: 'TEAMS',
};

export const NEW_PROJECT_NONREQUIRED_ENUM_FIELDS = [
  'priority',
  'resourcing_type',
  'budget_source',
];

export const TEAM_PROJECTS_TABLE_COLUMNS = [
  PROJECTS_TABLE_COLUMNS.PROJECT_NUMBER,
  PROJECTS_TABLE_COLUMNS.PROJECT_NAME,
  PROJECTS_TABLE_COLUMNS.STATUS,
  PROJECTS_TABLE_COLUMNS.PRIORITY,
  PROJECTS_TABLE_COLUMNS.PROJECT_OWNER,
  PROJECTS_TABLE_COLUMNS.HEALTH,
  PROJECTS_TABLE_COLUMNS.BUSINESS_UNIT,
  PROJECTS_TABLE_COLUMNS.PROJECT_CATEGORY,
  PROJECTS_TABLE_COLUMNS.START_DATE,
  PROJECTS_TABLE_COLUMNS.TARGET_COMPLETION_DATE,
  PROJECTS_TABLE_COLUMNS.ACTUAL_COMPLETION_DATE,
  PROJECTS_TABLE_COLUMNS.RESOURCING_TYPE,
  PROJECTS_TABLE_COLUMNS.PROCESS_STAGE,
  PROJECTS_TABLE_COLUMNS.PROJECT_STAGE,
  PROJECTS_TABLE_COLUMNS.TEAMS,
];

export const USER_PROJECTS_TABLE_COLUMNS = [
  PROJECTS_TABLE_COLUMNS.PROJECT_NUMBER,
  PROJECTS_TABLE_COLUMNS.PROJECT_NAME,
  PROJECTS_TABLE_COLUMNS.STATUS,
  PROJECTS_TABLE_COLUMNS.PRIORITY,
  PROJECTS_TABLE_COLUMNS.PROJECT_OWNER,
  PROJECTS_TABLE_COLUMNS.HEALTH,
  PROJECTS_TABLE_COLUMNS.BUSINESS_UNIT,
  PROJECTS_TABLE_COLUMNS.PROJECT_CATEGORY,
  PROJECTS_TABLE_COLUMNS.START_DATE,
  PROJECTS_TABLE_COLUMNS.TARGET_COMPLETION_DATE,
  PROJECTS_TABLE_COLUMNS.ACTUAL_COMPLETION_DATE,
  PROJECTS_TABLE_COLUMNS.PARTICIPANT_TYPE,
];

export const PROJECTS_TABLE_FILTER_OPTIONS = {
  PROJECT_NUMBER: {
    value: 'projectNumber',
    operators: [
      TABLE_FILTERS_OPERATORS.CONTAINS,
      TABLE_FILTERS_OPERATORS.DOESNT_CONTAIN,
      TABLE_FILTERS_OPERATORS.EQUAL,
      TABLE_FILTERS_OPERATORS.GREATER,
      TABLE_FILTERS_OPERATORS.GREATER_OR_EQUAL,
      TABLE_FILTERS_OPERATORS.LESS,
      TABLE_FILTERS_OPERATORS.LESS_OR_EQUAL,
    ],
    type: COLUMN_OPTION_TYPES.TEXT,
  },
  PROJECT_NAME: {
    value: 'title',
    operators: STRING_COMPARATOR,
    type: COLUMN_OPTION_TYPES.TEXT,
  },
  BUSINESS_UNIT: {
    value: 'businessTeams',
    type: COLUMN_OPTION_TYPES.OPTIONS,
    operators: STRING_COMPARATOR,
  },
  PROJECT_CATEGORY: {
    value: 'category_id',
    type: COLUMN_OPTION_TYPES.OPTIONS,
    operators: [
      TABLE_FILTERS_OPERATORS.EQUAL,
      TABLE_FILTERS_OPERATORS.NOT_EQUAL,
    ],
  },
  PRIORITY: {
    value: 'priority',
    operators: STRING_COMPARATOR,
    type: COLUMN_OPTION_TYPES.TEXT,
  },
  PROJECT_OWNER: {
    value: 'owners',
    operators: STRING_COMPARATOR,
    type: COLUMN_OPTION_TYPES.TEXT,
  },
  START_DATE: {
    value: 'startDate',
    operators: DATE_COMPARATOR,
    type: COLUMN_OPTION_TYPES.DATE,
  },
  TARGET_COMPLETION_DATE: {
    value: 'targetCompletionDate',
    operators: DATE_COMPARATOR,
    type: COLUMN_OPTION_TYPES.DATE,
  },
  ACTUAL_COMPLETION_DATE: {
    value: 'actualDate',
    operators: DATE_COMPARATOR,
    type: COLUMN_OPTION_TYPES.DATE,
  },
  PROCESS_STAGE: {
    value: 'process_id',
    type: COLUMN_OPTION_TYPES.OPTIONS,
    operators: [
      TABLE_FILTERS_OPERATORS.EQUAL,
      TABLE_FILTERS_OPERATORS.NOT_EQUAL,
    ],
  },
  PROJECT_STAGE: {
    value: 'stage_id',
    type: COLUMN_OPTION_TYPES.OPTIONS,
    operators: [
      TABLE_FILTERS_OPERATORS.EQUAL,
      TABLE_FILTERS_OPERATORS.NOT_EQUAL,
    ],
  },
  HEALTH: {
    value: 'health',
    operators: STRING_COMPARATOR,
    type: COLUMN_OPTION_TYPES.TEXT,
  },
  STATUS: {
    value: 'status',
    operators: STRING_COMPARATOR,
    type: COLUMN_OPTION_TYPES.TEXT,
  },
  RESOURCING_TYPE: {
    value: 'resourcing_type',
    operators: STRING_COMPARATOR,
    type: COLUMN_OPTION_TYPES.TEXT,
  },
  PARTICIPANT_TYPE: {
    value: 'participant_type',
    type: COLUMN_OPTION_TYPES.OPTIONS,
    operators: [
      TABLE_FILTERS_OPERATORS.EQUAL,
      TABLE_FILTERS_OPERATORS.NOT_EQUAL,
    ],
  },
  TEAMS: {
    value: 'ldteams',
    type: COLUMN_OPTION_TYPES.OPTIONS,
    operators: STRING_COMPARATOR,
  },
} as const;

export const PROJECTS_TABLE_FILTERS = {
  STATUS: 'status',
  PRIORITY: 'priority',
  OWNERS: 'owners',
  HEALTH: 'health',
  BUSINESS_TEAMS: 'businessTeams',
  PRIVACY: 'privacy',
  TEAMS: 'ldteams',
  CATEGORY: 'category_id',
  START_DATE: 'startDate',
  TARGET_COMPLETION_DATE: 'targetCompletionDate',
  ACTUAL_COMPLETION_DATE: 'actualDate',
  RESOURCING_TYPE: 'resourcing_type',
  BUDGET_SOURCE: 'budget_source',
  PROCESS: 'process_id',
  STAGE: 'stage_id',
  ARCHIVED: 'is_archived',
} as const;

export const INSIGHTS_CAPACITY_FILTERS = {
  INSIGHTS_TIMEFRAME: 'insightsTimeframe',
  BUSINESS_TEAMS: 'businessUnitIds',
} as const;

export const PROJECTS_TABLE_SORTABLE_COLUMNS = {
  PROJECT_NUMBER: 'projectNumber',
  TITLE: 'title',
  STATUS: 'status',
  PRIORITY: 'priority',
  PROJECT_OWNER: 'owners',
  HEALTH: 'health',
  BUSINESS_UNIT: 'businessTeams',
  PROJECT_CATEGORY: 'category',
  START_DATE: 'startDate',
  TARGET_COMPLETION_DATE: 'targetCompletionDate',
  ACTUAL_COMPLETION_DATE: 'actualDate',
  PROCESS_STAGE: 'process',
} as const;

export const MEMBER_VALUES = {
  TEAM_MEMBERS: 'ldTeamMembers',
  JOB_TITLE: 'data.jobTitle',
  EMPLOYMENT_TYPE: 'data.employmentType',
  COUNTRY: 'country_iso_3166_1_alpha_2_code',
  STATE: 'data.province',
  HOURLY_RATE: 'data.rateHour',
  TYPE: 'type',
  TEAM: 'team',
  STATUS: 'status',
};

export const TEAM_MEMBER_COLUMNS = [
  {
    labelKey: 'TEAM_MEMBERS',
    value: MEMBER_VALUES.TEAM_MEMBERS,
    operators: STRING_COMPARATOR,
    type: COLUMN_OPTION_TYPES.TEXT,
  },
  {
    labelKey: 'JOB_TITLE',
    value: MEMBER_VALUES.JOB_TITLE,
    operators: STRING_COMPARATOR,
    type: COLUMN_OPTION_TYPES.TEXT,
  },
  {
    labelKey: 'EMPLOYMENT_TYPE',
    value: MEMBER_VALUES.EMPLOYMENT_TYPE,
    operators: [
      TABLE_FILTERS_OPERATORS.EQUAL,
      TABLE_FILTERS_OPERATORS.NOT_EQUAL,
    ],
    type: COLUMN_OPTION_TYPES.OPTIONS,
  },
  {
    labelKey: 'COUNTRY',
    value: MEMBER_VALUES.COUNTRY,
    operators: [
      TABLE_FILTERS_OPERATORS.EQUAL,
      TABLE_FILTERS_OPERATORS.NOT_EQUAL,
    ],
    type: COLUMN_OPTION_TYPES.OPTIONS,
  },
  {
    labelKey: 'HOURLY_RATE',
    value: MEMBER_VALUES.HOURLY_RATE,
    operators: [
      TABLE_FILTERS_OPERATORS.EQUAL,
      TABLE_FILTERS_OPERATORS.NOT_EQUAL,
      TABLE_FILTERS_OPERATORS.GREATER,
      TABLE_FILTERS_OPERATORS.GREATER_OR_EQUAL,
      TABLE_FILTERS_OPERATORS.LESS,
      TABLE_FILTERS_OPERATORS.LESS_OR_EQUAL,
    ],
    type: COLUMN_OPTION_TYPES.NUMBER,
  },
];

export const PROJECTS_BOARD_FILTER_OPTIONS: objKeyAsString = {
  PROJECT_NAME: {
    value: 'title',
    operators: STRING_COMPARATOR,
    type: 'text',
  },
  BUSINESS_UNIT: {
    value: 'businessUnit',
    operators: STRING_COMPARATOR,
    type: 'text',
  },
  STATUS: {
    value: 'status',
    operators: STRING_COMPARATOR,
    type: 'text',
  },
  PRIORITY: {
    value: 'priority',
    operators: STRING_COMPARATOR,
    type: 'text',
  },
  HEALTH: {
    value: 'health',
    operators: STRING_COMPARATOR,
    type: 'text',
  },
  TARGET_COMPLETION_DATE: {
    value: 'targetCompletionDate',
    operators: DATE_COMPARATOR,
    type: 'date',
  },
  PROJECT_OWNER: {
    value: 'owners',
    operators: STRING_COMPARATOR,
    type: 'text',
  },
} as const;

export const PROJECTS_TABLE_TABS = {
  TEAM_PROJECTS: 'teamProjectsTable',
  MY_PROJECTS: 'myProjectsTable',
} as const;

export const PROJECT_USER_ROLE = {
  OWNER: 'Owner',
  MEMBER: 'Member',
  COLLABORATOR: 'Collaborator',
};

export const SLICE_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  UPDATING: 'updating',
  SUCCESS: 'success',
  FAILED: 'failed',
} as const;

export const NOTIFICATION_STATUS = {
  NEW: 'new',
  UNREAD: 'unread',
  READ: 'read',
} as const;

export const PROJECT_FILES_PICKER = {
  ATTACH_FILE: 'ATTACH_FILE',
  LINK_FILE: 'LINK_FILE',
};

export const PROJECT_LINK_FILE_TYPE = 'link';

export const FILE_SIZE_UNITS = [
  'B',
  'KB',
  'MB',
  'GB',
  'TB',
  'PB',
  'EB',
  'ZB',
  'YB',
];

export const UPDATE_MEMBER_OPTIONS = [
  'UPDATE_INFO',
  'MANAGE_TIME_OFF',
] as const;

export const EMPLOYMENT_TYPE = [
  'FULL_TIME',
  'PART_TIME',
  'INTERN',
  'CONTRACTOR',
] as const;

export const PROJECT_MORE_ACTIONS = {
  ARCHIVE: 'ARCHIVE',
  CANCEL: 'CANCEL',
  REACTIVATE: 'REACTIVATE',
  DELETE: 'DELETE',
  CLOSE: 'CLOSE',
};

export const NOTIFICATION_TYPE = {
  REQUEST_STATUS_UPDATED: 'request-status-updated',
  REQUEST_ATTRIBUTES_UPDATED: 'request-attributes-updated',
  REQUEST_QUESTIONS_UPDATED: 'request-questions-updated',
  ASSIGN_NEW_OWNER: 'request-owner-assigned',
  UNASSIGN_OWNER: 'request-owner-unassigned',
  QUESTION_COMMENTED: 'question-commented',
  QUESTION_COMMENT_MENTION: 'question-comment-mention',
  REQUEST_PROPERTY_COMMENTED: 'request-property-commented',
  REQUEST_PROPERTY_COMMENT_MENTION: 'request-property-comment-mention',
  REQUEST_BUSINESS_REVIEWER: 'request-business-reviewer-assigned',
  TASK_USER_ASSIGNED: 'task-user-assigned',
  TASK_USER_UNASSIGNED: 'task-user-unassigned',
  TASK_USER_ASSIGNED_OWNER_VIEW: 'task-user-assigned-owner-view',
  TASK_USER_UNASSIGNED_OWNER_VIEW: 'task-user-unassigned-owner-view',
  TASK_ACTUAL_HOURS_UPDATED: 'task-actual-hours-updated',
  TASK_UPDATED: 'task-updated',
  TASK_STATUS_UPDATED: 'task-status-updated',
  TASK_DISABLED: 'task-disabled',
  TASK_DELETED: 'task-deleted',
  TASK_DUE_DATE: 'task-due-date',
  TASK_COMMENT_MENTION: 'task-comment-mention',
  PROJECT_OWNER_ASSIGNED: 'project-owner-assigned',
  PROJECT_OWNER_UNASSIGNED: 'project-owner-unassigned',
  PROJECT_STATUS_UPDATED: 'project-status-updated',
  PROJECT_UPDATED: 'project-updated',
  PROJECT_COMMENTED: 'project-commented',
  PROJECT_COMMENT_MENTION: 'project-comment-mention',
  NEW_PROJECT_PARTICIPANT: 'new-project-participant',
  PROJECT_PARTICIPANT_REMOVED: 'project-participant-removed',
  PROJECT_ASSIGNMENT_UPDATED: 'project-assignment-updated',
  PROJECT_ASSIGNMENT_REMOVED: 'project-assignment-removed',
  PROJECT_IS_ARCHIVED_UPDATED: 'project-is-archived-updated',
  PROJECT_START_DATE: 'project-start-date',
  PROJECT_DUE_DATE: 'project-target-completion-date',
  BUSINESS_USER_NEW_REGISTRATION: 'new-user-registration',
  TIME_OFF_CREATED: 'time-off-self-create',
  TIME_OFF_UPDATED: 'time-off-self-update',
  TIME_OFF_REMOVED: 'time-off-self-delete',
  PROGRAM_UPDATED: 'program-updated',
  PROJECT_LINKED_TO_PROGRAM: 'project-linked-to-program',
  PROJECT_UNLINKED_FROM_PROGRAM: 'project-unlinked-from-program',
  PROGRAM_CLOSED: 'program-closed',
  PROGRAM_DELETED: 'program-deleted',
  TASK_BUNDLE_UPDATED: 'task-bundle-updated',
  TASK_BUNDLE_DELETED: 'task-bundle-deleted',
  V2_PROJECT_PARTICIPANT_ADDED: 'v2-project-participant-added',
  V2_PROJECT_PARTICIPANT_REMOVED: 'v2-project-participant-removed',
} as const;

export const PROJECTS_LIST_VIEW_MODE = {
  TABLE: 'TABLE',
  BOARD: 'BOARD',
};

export const PROJECTS_BOARD_TABS = {
  TEAM_BOARD: 'teamProjectsBoard',
  MY_BOARD: 'myProjectsBoard',
} as const;

export const NEW_AND_IN_QUEUE_PROJECTS_STAGE = {
  ID: 'newAndInQueueProjectsStage',
  BG_COLOR: 'bg-neutral-lighter',
  TEXT_COLOR: 'text-neutral-darker',
  ON_DRAG_COLOR: 'bg-neutral-lighter-two',
  ON_DRAG_BORDER_COLOR: 'bg-neutral-light',
};

export const STAGE_COLORS = [
  'bg-secondary-lighter',
  'bg-tertiary-lighter',
  'bg-purple-lighter',
  'bg-fire-lighter',
];

export const STAGE_TEXT_COLOR = [
  'text-secondary-darker',
  'text-tertiary-darker',
  'text-purple-darker',
  'text-fire-darker',
];

export const STAGE_ON_DRAG_COLORS = [
  'bg-secondary-lightest',
  'bg-tertiary-lightest',
  'bg-purple-lightest',
  'bg-fire-lightest',
];

export const STAGE_ON_DRAG_BORDER_COLORS = [
  'border-secondary-light',
  'border-tertiary-light',
  'border-purple-light',
  'border-fire-light',
];

export const QUESTIONNAIRE_TYPE = {
  REQUEST: 'request',
  FORM: 'form',
} as const;

export const SKILLS = [
  'ACCOUNT_MANAGEMENT',
  'ANIMATION',
  'ASSESSMENT_CREATION',
  'AUDIO_EDITING',
  'BRAND_DEVELOPMENT',
  'BUSINESS_ANALYSIS',
  'BUSINESS_DEVELOPMENT',
  'BUSINESS_INTELLIGENCE',
  'BUSINESS_RELATION_MANAGEMENT',
  'CASCADING_STYLE_SHEETS',
  'CHANGE_MANAGEMENT',
  'COACHING',
  'COMMUNICATION',
  'CONSULTING',
  'CONTENT_MANAGEMENT_SYSTEM',
  'CONTENT_WRITING_EDITING',
  'CONTRACT_MANAGEMENT',
  'COORDINATION',
  'CURRICULUM_DESIGN_DEVELOP',
  'DATA_ANALYSIS',
  'DATABASE',
  'DIGITAL_MARKETING',
  'E_LEARNING',
  'EVALUATION',
  'EVENT_MANAGEMENT',
  'GOVERNANCE',
  'GRAPHIC_DESIGN',
  'INTAKE_MANAGEMENT',
  'FACILITATION',
  'FINANCIAL_ANALYSIS',
  'FINANCIAL_MANAGEMENT',
  'HTML5',
  'HUMAN_RESOURCE_MANAGEMENT_SYSTEM',
  'INFORMATION_ARCHITECTURE',
  'INSTRUCTIONAL_DESIGN',
  'INTAKE_MANAGEMENT',
  'JAVASCRIPT',
  'LEADERSHIP',
  'LEARNING_MANAGEMENT_SYSTEM',
  'MARKETING',
  'MEASUREMENT',
  'MOBILE_APP_DESIGN',
  'MOBILE_APP_DEVELOPMENT',
  'PEOPLE_ANALYTICS',
  'PERFORMANCE_MANAGEMENT',
  'PHOTOGRAPHY',
  'PLANNING',
  'PROCESS_IMPROVEMENT',
  'PRODUCT_MANAGEMENT',
  'PROGRAMMING',
  'PROJECT_MANAGEMENT',
  'PROOFREADING',
  'QUALITY_ASSURANCE',
  'REPORTING',
  'RESEARCH',
  'REQUIREMENT_GATHERING',
  'REQUIREMENT_ANALYSIS',
  'RESOURCE_MANAGEMENT',
  'SEARCH_ENGINE_OPTIMIZATION',
  'SOCIAL_MEDIA',
  'SOCIAL_MEDIA_MARKETING',
  'SOFTWARE_ADOBE_PHOTOSHOP',
  'SOFTWARE_ADOBE_ILLUSTRATOR',
  'SOFTWARE_ADOBE_CAPTIVATE',
  'SOFTWARE_ARTICULATE',
  'SOFTWARE_CAMTASIA',
  'SOFTWARE_EASYGENERATOR',
  'SOFTWARE_ELUCIDAT',
  'SOFTWARE_ISPRING_SUITE',
  'SOFTWARE_LECTORA',
  'SOFTWARE_MICROSOFT_WORD',
  'SOFTWARE_MICROSOFT_POWERPOINT',
  'SOFTWARE_MICROSOFT_EXCEL',
  'SOFTWARE - SHAREPOINT',
  'SURVEY_DESIGN',
  'TALENT_MANAGEMENT',
  'TEAM_MANAGEMENT',
  'TRAINING_DELIVERY',
  'UX_DESIGN',
  'UX_RESEARCH',
  'USABILITY TESTING',
  'VENDOR_MANAGEMENT',
  'VIDEO_EDITING',
  'VIRTUAL_REALITY',
  'WEB_DESIGN',
  'WEB_DEVELOPMENT',
] as const;

export const JOB_FUNCTIONS = [
  'BUSINESS_RELATIONSHIP_MANAGEMENT',
  'COACHING',
  'CONTENT_WRITING',
  'COMMUNICATION',
  'CONSULTING',
  'COORDINATION',
  'DATA_REPORTING_ANALYSIS',
  'FACILITATION',
  'FINANCIAL_MANAGEMENT',
  'GOVERNANCE',
  'GRAPHIC_DESIGN',
  'INSTRUCTIONAL_DESIGN',
  'INTAKE_MANAGEMENT',
  'LEARNING_DEVELOPMENT',
  'LEARNING_PLATFORM_ADMINISTRATION',
  'LEARNING_STRATEGY',
  'LEARNING_SUPPORT',
  'LEARNING_TECHNOLOGY',
  'LEADERSHIP_DEVELOPMENT',
  'MARKETING',
  'MEASUREMENT',
  'PLANNING',
  'PORTFOLIO_MANAGEMENT',
  'PROGRAM_MANAGEMENT',
  'PROJECT_MANAGER',
  'QUALITY_ASSURANCE',
  'REPORTING',
  'RESOURCE_MANAGEMENT',
  'SUBJECT_MATTER_EXPERT',
  'TALENT_DEVELOPMENT',
  'TEAM_MANAGEMENT',
  'TRANSLATION',
  'WEB_DEVELOPMENT',
  'VENDOR_MANAGEMENT',
  'VIDEO_PRODUCTION',
  'OTHERS',
] as const;

export const LANGUAGES = [
  'aa',
  'ab',
  'ae',
  'af',
  'ak',
  'am',
  'an',
  'ar',
  'as',
  'av',
  'ay',
  'az',
  'ba',
  'be',
  'bg',
  'bh',
  'bi',
  'bm',
  'bn',
  'bo',
  'br',
  'bs',
  'ca',
  'ce',
  'ch',
  'co',
  'cr',
  'cs',
  'cu',
  'cv',
  'cy',
  'da',
  'de',
  'dv',
  'dz',
  'ee',
  'el',
  'en',
  'eo',
  'es',
  'et',
  'eu',
  'fa',
  'ff',
  'fi',
  'fj',
  'fo',
  'fr',
  'fy',
  'ga',
  'gd',
  'gl',
  'gn',
  'gu',
  'gv',
  'ha',
  'he',
  'hi',
  'ho',
  'hr',
  'ht',
  'hu',
  'hy',
  'hz',
  'ia',
  'id',
  'ie',
  'ig',
  'ii',
  'ik',
  'io',
  'is',
  'it',
  'iu',
  'ja',
  'jv',
  'ka',
  'kg',
  'ki',
  'kj',
  'kk',
  'kl',
  'km',
  'kn',
  'ko',
  'kr',
  'ks',
  'ku',
  'kv',
  'kw',
  'ky',
  'la',
  'lb',
  'lg',
  'li',
  'ln',
  'lo',
  'lt',
  'lu',
  'lv',
  'mg',
  'mh',
  'mi',
  'mk',
  'ml',
  'mn',
  'mr',
  'ms',
  'mt',
  'my',
  'na',
  'nb',
  'nd',
  'ne',
  'ng',
  'nl',
  'nn',
  'no',
  'nr',
  'nv',
  'ny',
  'oc',
  'oj',
  'om',
  'or',
  'os',
  'pa',
  'pi',
  'pl',
  'ps',
  'pt',
  'qu',
  'rm',
  'rn',
  'ro',
  'ru',
  'rw',
  'sa',
  'sc',
  'sd',
  'se',
  'sg',
  'si',
  'sk',
  'sl',
  'sm',
  'sn',
  'so',
  'sq',
  'sr',
  'ss',
  'st',
  'su',
  'sv',
  'sw',
  'ta',
  'te',
  'tg',
  'th',
  'ti',
  'tk',
  'tl',
  'tn',
  'to',
  'tr',
  'ts',
  'tt',
  'tw',
  'ty',
  'ug',
  'uk',
  'ur',
  'uz',
  've',
  'vi',
  'vo',
  'wa',
  'wo',
  'xh',
  'yi',
  'yo',
  'za',
  'zh',
  'zu',
  'UNDEFINED',
];

export const USER_STATUS: Record<string, string> = {
  REGISTERED: 'registered',
  INVITED: 'invited',
  REGISTERED_DISABLED: 'disabled_registered',
  INVITED_DISABLED: 'disabled_invited',
};

export const COUNTRIES = [
  'AF',
  'AX',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BQ',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'BN',
  'BG',
  'BF',
  'BI',
  'KH',
  'CM',
  'CA',
  'IC',
  'CV',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CG',
  'CD',
  'CK',
  'CR',
  'CI',
  'HR',
  'CU',
  'CW',
  'CY',
  'CZ',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GW',
  'GY',
  'HT',
  'HM',
  'VA',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IM',
  'IL',
  'IT',
  'JM',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KR',
  'KP',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MK',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'ME',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'RE',
  'RO',
  'RU',
  'RW',
  'BL',
  'SH',
  'KN',
  'LC',
  'MF',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SX',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'SS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SZ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'GB',
  'US',
  'UM',
  'UY',
  'UZ',
  'VU',
  'VE',
  'VN',
  'VG',
  'VI',
  'WF',
  'EH',
  'YE',
  'XK',
  'ZM',
  'ZW',
];

export const UPDATE_MEMBER_FORM = 'update_member_form';

export const TIME_OFF_TYPE = {
  vacation: 'vacation',
  sick_day_off: 'sick_day_off',
  parental_leave: 'parental_leave',
  paid_time_off: 'paid_time_off',
  custom_holiday: 'custom_holiday',
  others: 'others',
} as const;

export const BOARD_SORTING_OPTIONS: objKeyAsString = {
  NEWEST: {
    order: 'desc',
    orderBy: 'targetCompletionDate',
  },
  OLDEST: {
    order: 'asc',
    orderBy: 'targetCompletionDate',
  },
  TITLE_DESC: {
    order: 'desc',
    orderBy: 'title',
  },
  TITLE_ASC: {
    order: 'asc',
    orderBy: 'title',
  },
};

export const TASK_TYPES = {
  PLANNING: 'Planning',
  RESEARCH: 'Research',
  ANALYSIS: 'Analysis',
  INSTRUCTIONAL_DESIGN: 'Instructional Design',
  CONTENT_CREATION: 'Content Creation',
  CONTENT_UPDATE: 'Content Update',
  GRAPHIC_DESIGN: 'Graphic Design',
  MEDIA_PRODUCTION: 'Media Production',
  ASSESSMENT_CREATION: 'Assessment Creation',
  FILE_UPLOAD: 'File Upload',
  DEVELOPMENT: 'Development',
  IMPLEMENTATION: 'Implementation',
  EVALUATION: 'Evaluation',
  QA: 'QA',
  ERROR_CORRECTION: 'Error Correction',
  REVIEW: 'Review',
  APPROVAL: 'Approval',
  PUBLISH: 'Publish',
  PROJECT_MANAGEMENT: 'Project Management',
  PROGRAM_MANAGEMENT: 'Program Management',
  SCHEDULING: 'Scheduling/Registration',
  DELIVERY: 'Delivery/Facilitation',
  LMS_DEPLOYMENT: 'LMS Deployment',
  LMS_SUPPORT: 'LMS Admin & Support',
  COMMUNICATION: 'Communication',
  DESIGN: 'Design',
  EXTERNAL_REVIEW: 'External Review',
  INITIATING: 'Initiating',
  EXECUTION: 'Execution',
  MONITORING: 'Monitoring',
  CLOSING: 'Closing',
  OTHERS: 'Others',
};

export const TASK_ADDITONAL_FLAGS: objKeyAsString = {
  DISABLED: 'disabled',
};

export const TASK_STATUS: objKeyAsString = {
  NEW: 'new',
  ON_HOLD: 'on_hold',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
};

export const TASKS_TABLE_TABS = {
  TEAM_TASKS: 'teamTasksTable',
  MY_TASKS: 'myTasksTable',
} as const;

export const TASK_FIELDS = {
  NAME: 'name',
  START_DATE: 'start_date',
  DUE_DATE: 'due_date',
  TASK_TYPE: 'type',
  ASSIGNEE_ADD: 'assignedUserIds',
  ASSIGNEE_UPDATE: 'assignedUsers',
  DESCRIPTION: 'description',
  ESTIMATED_HOURS: 'estimate_hours',
  STATUS: 'status',
  DISABLED: 'disabled',
  ACTUAL_HOURS: 'actual_hours',
  COMPLETION_DATE: 'completion_date',
};

export const TASKS_TABLE_COLUMN_REFERENCE = {
  DISABLED: 'DISABLED',
  NAME: 'NAME',
  ASSIGNEE_UPDATE: 'ASSIGNEE_UPDATE',
  TASK_TYPE: 'TASK_TYPE',
  START_DATE: 'START_DATE',
  DUE_DATE: 'DUE_DATE',
  STATUS: 'STATUS',
};

export const TASKS_TABLE_COLUMNS = [
  TASKS_TABLE_COLUMN_REFERENCE.DISABLED,
  TASKS_TABLE_COLUMN_REFERENCE.NAME,
  TASKS_TABLE_COLUMN_REFERENCE.ASSIGNEE_UPDATE,
  TASKS_TABLE_COLUMN_REFERENCE.TASK_TYPE,
  TASKS_TABLE_COLUMN_REFERENCE.START_DATE,
  TASKS_TABLE_COLUMN_REFERENCE.DUE_DATE,
  TASKS_TABLE_COLUMN_REFERENCE.STATUS,
];

export const TASKS_TABLE_FILTER: objKeyAsString = {
  NAME: {
    value: TASK_FIELDS.NAME,
    operators: STRING_COMPARATOR,
    type: COLUMN_OPTION_TYPES.TEXT,
  },
  TASK_TYPE: {
    value: TASK_FIELDS.TASK_TYPE,
    operators: [
      TABLE_FILTERS_OPERATORS.EQUAL,
      TABLE_FILTERS_OPERATORS.NOT_EQUAL,
    ],
    type: COLUMN_OPTION_TYPES.OPTIONS,
  },
  STATUS: {
    value: TASK_FIELDS.STATUS,
    operators: [
      TABLE_FILTERS_OPERATORS.EQUAL,
      TABLE_FILTERS_OPERATORS.NOT_EQUAL,
    ],
    type: COLUMN_OPTION_TYPES.OPTIONS,
  },
  ASSIGNEE_UPDATE: {
    value: TASK_FIELDS.ASSIGNEE_UPDATE,
    operators: [
      TABLE_FILTERS_OPERATORS.EQUAL,
      TABLE_FILTERS_OPERATORS.NOT_EQUAL,
    ],
    type: COLUMN_OPTION_TYPES.OPTIONS,
  },
  START_DATE: {
    value: TASK_FIELDS.START_DATE,
    operators: DATE_COMPARATOR,
    type: COLUMN_OPTION_TYPES.DATE,
  },
  DUE_DATE: {
    value: TASK_FIELDS.DUE_DATE,
    operators: DATE_COMPARATOR,
    type: COLUMN_OPTION_TYPES.DATE,
  },
  ACTUAL_DATE: {
    value: TASK_FIELDS.COMPLETION_DATE,
    operators: DATE_COMPARATOR,
    type: COLUMN_OPTION_TYPES.DATE,
  },
  ESTIMATED_HOURS: {
    value: TASK_FIELDS.ESTIMATED_HOURS,
    operators: NUMBER_COMPARATOR,
    type: COLUMN_OPTION_TYPES.NUMBER,
  },
  ACTUAL_HOURS: {
    value: TASK_FIELDS.ACTUAL_HOURS,
    operators: NUMBER_COMPARATOR,
    type: COLUMN_OPTION_TYPES.NUMBER,
  },
  DISABLED: {
    value: TASK_FIELDS.DISABLED,
    operators: [TABLE_FILTERS_OPERATORS.EQUAL],
    type: COLUMN_OPTION_TYPES.OPTIONS,
  },
};

export const TASKS_TABLE_FILTERS = {
  STATUS: 'status',
  TYPE: 'type',
  ASSIGNEE_USERS: 'assignedUsers',
  START_DATE: 'start_date',
  DUE_DATE: 'due_date',
  COMPLETION_DATE: 'completion_date',
  ESTIMATED_HOURS: 'estimate_hours',
  ACTUAL_HOURS: 'actual_hours',
};

export const TASKS_MORE_ACTIONS = {
  DELETE: 'DELETE',
};

export const TEAM_ACTIONS = {
  DELETE: 'DELETE',
};

export const USER_ACTIONS = {
  NOTIFY: 'NOTIFY',
};

export const PROCESS_ACTIONS = {
  DELETE: 'DELETE',
};

export const PROJECT_PROCESS_FIELDS = {
  PROCESS_NAME: 'processName',
  DESCRIPTION: 'description',
  ORGANIZATION_ID: 'organization_id',
  CREATED_BY: 'createdBy',
  STAGES: 'projectStages',
  DATA: 'data',
};

export const PROJECT_STAGE_FIELDS: objKeyAsString = {
  STAGE_NAME: 'stageName',
  DESCRIPTION: 'description',
  PROCESS_ID: 'process_id',
  MAX_COMPLETON_TIME: 'maxCompletionTime',
  MIN_COMPLETION_TIME: 'minCompletionTime',
  DATA: 'data',
};

export const FORMS_FILTER_OPTIONS = {
  TITLE: 'title',
  REQUEST_TYPE: 'request_type',
  FORM_CREATOR: 'formCreator.data.firstName',
  CREATION_DATE: 'createdAt',
  LAST_UPDATE: 'updatedAt',
  STATUS: 'data.published',
};

export const CREATE_TEAM_FORM = 'create_team_form';

export const FORM_OPTIONS = {
  DELETE: 'DELETE',
  LINK: 'LINK',
} as const;

export const FORMS_TABLE_OPTIONS = ['DUPLICATE', 'DELETE'] as const;

export const PROJECT_PEOPLE_TABS: objKeyAsString = {
  RESOURCE_PLAN: 'resourcePlan',
  RESOURCE_ALLOCATION: 'resourceAllocation',
  RESOURCE_SUMMARY: 'resourceSummary',
};

export const PROJECT_PARTICIPANT_TYPE: objKeyAsString = {
  NOT_PARTICIPANT: 'not-participant',
  OWNER: 'owner',
  MEMBER: 'member',
  COLLABORATOR: 'collaborator',
};

export const NEW_PROJECT_PARTICIPANT_FIELDS: objKeyAsString = {
  USER_ID: 'userId',
  PROJECT_ID: 'projectLearnOpId',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  JOB_ROLE: 'job_role',
  TYPE: 'type',
  HOURS_ASSIGNED: 'hoursAssigned',
  ESTIMATED_HOURS: 'estimatedHours',
  ACTUAL_HOURS: 'actualHours',
};

export const PROJECT_PARTICIPANT_ROLE = {
  INSTRUCTIONAL_DESIGNER: 'Instructional Designer',
  LEARNING_DEVELOPER: 'Learning Developer',
  MEDIA_DEVELOPER: 'Media Developer',
  CONTENT_WRITER: 'Content Writer',
  PRODUCER: 'Producer',
  QA: 'QA',
  TRAINER: 'Trainer',
  LEARNING_CONSULTANT: 'Learning Consultant',
  REPORTING_ANALYST: 'Reporting Analyst',
  TRAINING_COORDINATOR: 'Training Coordinator',
  LMS_ADMIN: 'LMS Admin/Support',
  PROJECT_MANAGER: 'Project Manager',
  PROGRAM_MANAGER: 'Program Manager',
  RESOURCE_MANAGER: 'Resource Manager',
  COMMUNICATION_SPECIALIST: 'Communication Specialist',
  EXECTIVE_SPOSNOR: 'Executive Sponsor',
  SME: 'SME',
  REVIEWER: 'Reviewer',
  OTHERS: 'Others',
};

export const BUDGET_DETAILS_FIELDS = {
  ALLOCATED_BUDGET: 'allocated_budget',
  NOTES: 'notes',
  COST_TO_DATE: 'cost_to_date',
};

export const RESOURCE_ALLOCATION_TABLE_SECTIONS = {
  OWNERS: 'OWNERS',
  MEMBERS: 'MEMBERS',
  COLLABORATORS: 'COLLABORATORS',
};

export const PROJECT_OWNER = 'Project Owner';

export const LICENSE_TIER = {
  TRIAL: 'trial',
  REGULAR: 'regular',
} as const;

export const LICENSE_TIER_FOR_PENDO_DISPLAY = {
  TRIAL: 'trial',
  REGULAR: 'paid',
} as const;

export const PROJECT_NUMBER_FORMAT: IdentifierFormat = {
  BASE: 'P',
  FULL: 'P000000',
};

export const PROGRAM_NUMBER_FORMAT: IdentifierFormat = {
  BASE: 'PM',
  FULL: 'PM000000',
};

export const SUPPORT_POPUP_OPTIONS = {
  LEARNOPS_CERTIFICATION: 'LEARNOPS_CERTIFICATION',
  CHAT: 'CHAT',
  LEARNING: 'LEARNING',
  SHOW: 'SHOW',
  LEARNOPS_COMMUNITY: 'LEARNOPS_COMMUNITY',
};

export const CURRENCIES = ['USD', 'CAD', 'EUR', 'GBP', 'AUD', 'NZD'];

export const CURRENCY_SYMBOLS = {
  USD: '$',
  CAD: '$',
  EUR: '€',
  GBP: '£',
  AUD: '$',
  NZD: '$',
};

export const PENDO_EVENTS = {
  PROJECT_CREATED: 'Project created',
  USER_LOGGED_IN: 'User logged in',
  REQUEST_SUBMITTED: 'Request submitted',
  REQUEST_APPROVED: 'Request approved',
  REQUEST_DECLINED: 'Request declined',
  REQUEST_FORM_ADDED: 'Request form added',
  PROCESS_ADDED: 'Process added',
  CTA_MANAGE_OBJECTIVE: 'Clicked on manage objective',
  SAVE_OBJECTIVE: 'Saved objective',
  CREATE_OBJECTIVE: 'Created objective',
  CTA_OPEN_CREATE_OBJECTIVE_MODAL: "Clicked on 'Add Objective' button",
  CTA_SAVE_OBJECTIVES: "Clicked on 'Yes, Add Objective(s)' button",
  CTA_SAVE_ADDITIONAL_BENEFITS: "Clicked on 'Yes, Add Benefit(s)' button",
  CREATE_ADDITIONAL_BENEFITS: 'Created additional benefits',
  CTA_OPEN_ADDITIONAL_BENEFIT_MODAL: "Clicked on 'Add Benefit' button",
  CTA_MANAGE_BENEFIT: 'Clicked on manage benefit(Additional Benefits)',
  SAVE_ADDITIONAL_BENEFIT: 'Saved additional benefit',
  PROGRAM_ROI_MANAGE_COSTS_CLICK: 'Clicked on program roi manage costs',
  PROGRAM_ROI_ADD_NEW_COST: 'Clicked on program roi add new cost',
  PROGRAM_ROI_SAVE_COST: 'Clicked on Save in program roi cost page',
  PROGRAM_COST_REMOVE_ICON_CLICK: 'Clicked on program cost remove icon',
  ROI_CALCULATOR_VIEW_CALCULATION: 'Clicked on ROI calculator view calculation',
  ROI_BOOTCAMP_LINK_CLICKED: 'Clicked on the “Claim Your Free Boot Camp” link',
};

export const PROJECT_USER_ACTIONS = {
  VIEW_COMMENTS: 'view-comments',
  ADD_COMMENT: 'add-comment',
  CANCEL_PROJECTS: 'cancel-projects',
  CLOSE_PROJECTS: 'close-projects',
  PUT_PROJECT_ON_HOLD: 'put-project-on-hold',
  UPDATE_PROJECT: 'update-project',
  ADD_TASK: 'add-task',
  REORDER_TASKS: 'reorder-tasks',
  DUPLICATE_TASK: 'duplicate-task',
  UPDATE_TASK: 'update-task',
  DELETE_TASK: 'delete-task',
  ADD_FILE: 'add-file',
  DELETE_FILE: 'delete-file',
  UPDATE_BUDGET_PLAN: 'update-budget-plan',
  UPDATE_BUDGET_DETAILS: 'update-budget-details',
  UPDATE_RESOURCE_PLAN: 'update-resource-plan',
  ADD_PARTICIPANT: 'add-participant',
  UPDATE_RESOURCE_ASSIGNMENT: 'update-resource-assignment',
  ADJUST_RESOURCE_ALLOCATION: 'adjust-resource-allocation',
  IMPORT_TASKS: 'import-tasks',
  ARCHIVE_PROJECTS: 'archive-projects',
  REACTIVATE_PROJECT: 'reactivate-project',
};

export const PROJECT_PERMISSIONS_BY_LEVEL: Record<
  ProjectPermissionsLevel,
  string[]
> = {
  owner: [
    PROJECT_USER_ACTIONS.VIEW_COMMENTS,
    PROJECT_USER_ACTIONS.ADD_COMMENT,
    PROJECT_USER_ACTIONS.CANCEL_PROJECTS,
    PROJECT_USER_ACTIONS.CLOSE_PROJECTS,
    PROJECT_USER_ACTIONS.UPDATE_PROJECT,
    PROJECT_USER_ACTIONS.PUT_PROJECT_ON_HOLD,
    PROJECT_USER_ACTIONS.ADD_TASK,
    PROJECT_USER_ACTIONS.REORDER_TASKS,
    PROJECT_USER_ACTIONS.DUPLICATE_TASK,
    PROJECT_USER_ACTIONS.UPDATE_TASK,
    PROJECT_USER_ACTIONS.DELETE_TASK,
    PROJECT_USER_ACTIONS.ADD_FILE,
    PROJECT_USER_ACTIONS.DELETE_FILE,
    PROJECT_USER_ACTIONS.UPDATE_BUDGET_DETAILS,
    PROJECT_USER_ACTIONS.UPDATE_BUDGET_PLAN,
    PROJECT_USER_ACTIONS.ADD_PARTICIPANT,
    PROJECT_USER_ACTIONS.ADJUST_RESOURCE_ALLOCATION,
    PROJECT_USER_ACTIONS.UPDATE_RESOURCE_ASSIGNMENT,
    PROJECT_USER_ACTIONS.UPDATE_RESOURCE_PLAN,
    PROJECT_USER_ACTIONS.IMPORT_TASKS,
    PROJECT_USER_ACTIONS.ARCHIVE_PROJECTS,
    PROJECT_USER_ACTIONS.REACTIVATE_PROJECT,
  ],
  member: [
    PROJECT_USER_ACTIONS.VIEW_COMMENTS,
    PROJECT_USER_ACTIONS.ADD_COMMENT,
    PROJECT_USER_ACTIONS.ADD_TASK,
    PROJECT_USER_ACTIONS.REORDER_TASKS,
    PROJECT_USER_ACTIONS.DUPLICATE_TASK,
    PROJECT_USER_ACTIONS.UPDATE_TASK,
    PROJECT_USER_ACTIONS.DELETE_TASK,
    PROJECT_USER_ACTIONS.ADD_FILE,
    PROJECT_USER_ACTIONS.DELETE_FILE,
    PROJECT_USER_ACTIONS.ADJUST_RESOURCE_ALLOCATION,
    PROJECT_USER_ACTIONS.UPDATE_RESOURCE_ASSIGNMENT,
  ],
  collaborator: [
    PROJECT_USER_ACTIONS.UPDATE_TASK,
    PROJECT_USER_ACTIONS.ADD_FILE,
    PROJECT_USER_ACTIONS.DELETE_FILE,
    PROJECT_USER_ACTIONS.VIEW_COMMENTS,
    PROJECT_USER_ACTIONS.ADD_COMMENT,
  ],
  'not-participant': [PROJECT_USER_ACTIONS.VIEW_COMMENTS],
};

export const PROJECT_CONTENT_TYPE = {
  FILE: 'file',
  WEB_LINK: 'web_link',
  DESIGN: 'design',
  DESIGN_FILE: 'design_file',
} as const;

export const PROJECT_CONTENT_MODAL_TAB = {
  FILE: 'file',
  WEB_LINK: 'weblink',
  DESIGN: 'design',
} as const;

export const PROJECT_CONTENT_TABLE_SORTING = {
  NAME: 'name',
  ADDED_BY: 'uploadedBy',
  ADDED_ON: 'uploadDate',
} as const;

export const LONG_INPUTS_LENGTH = 255;
export const SHORT_INPUTS_LENGTH = 80;

export const PROJECT_IMPORT_ATTRIBUTES = {
  TITLE: 'title',
  OWNERS: 'owners',
  DESCRIPTION: 'description',
  BUSINESS_TEAMS: 'businessTeams',
  CATEGORY: 'category_id',
  START_DATE: 'startDate',
  TARGET_COMPLETION_DATE: 'targetCompletionDate',
  PRIORITY: 'priority',
  PRIVACY: 'privacy',
  PROCESS: 'process_id',
  STAGE: 'stage_id',
  STATUS: 'status',
  HEALTH: 'health',
  LEARNING_TEAMS: 'learningTeams',
} as const;

export const PROJECT_IMPORT_DEFAULT_VALUES = {
  PRIORITY: 'low',
  PRIVACY: 'public',
  STATUS: 'new',
  HEALTH: 'green',
} as const;

export const REQUEST_IMPORT_ATTRIBUTES = {
  REQUEST_TYPE: 'request_type',
  CREATOR: 'requestCreator',
  BASE_FORM: 'baseFormId',
  TITLE: 'title',
  DESCRIPTION: 'description',
  OWNERS: 'owners',
  BUSINESS_TEAM: 'businessTeams',
  EFFORT: 'effort',
  COST: 'cost',
  LD_PRIORITY: 'ldPriority',
  STATUS: 'status',
  SUBMITTED_AT: 'submittedAt',
  DECISION_DATE: 'decision_date',
  CREATION_DATE: 'createdAt',
};

export const WAITLIST_CONDITIONS = [
  'STRATEGY_PRIORITY',
  'RESOURCE',
  'BUDGET',
  'TIMELINE',
  'IT',
  'PROCUREMENT',
  'VENDOR',
  'OTHERS',
];

export const USER_IMPORT_ATTRIBUTES = {
  EMAIL: 'data.email',
  IS_ADMIN: 'role',
  FIRST_NAME: 'data.firstName',
  LAST_NAME: 'data.lastName',
  COUNTRY: 'country_iso_3166_1_alpha_2_code',
  STATE: 'data.province',
  JOB_TITLE: 'data.jobTitle',
  EMPLOYMENT_TYPE: 'data.employmentType',
  TEAM: 'registeredLearningTeams',
  BUSINESS_TEAM: 'businessTeams.id',
  DEFAULT_CAPACITY: 'default_capacity',
  JOB_FUNCTION: 'data.jobFunctions',
  LANGUAGES: 'data.languages',
  HOURLY_RATE: 'data.rateHour',
};

export const CAROUSEL_DIRECTION = {
  PREV: 'prev',
  NEXT: 'next',
};

export const PROFILE_CONTEXT_MENU = ['PROFILE', 'NOTIFICATIONS', 'TIME_OFF'];

export const TABLE_EXPORT_OPTIONS = {
  ALL: 'all',
  CURRENT_PAGE: 'current-page',
};

export const EXPORT_USERS_COLUMNS = {
  USER_NAME: 'USER_NAME',
  USER_EMAIL: 'USER_EMAIL',
  ROLE: 'ROLE',
  USER_TYPE: 'USER_TYPE',
  TEAM: 'TEAM',
  JOB_TITLE: 'JOB_TITLE',
  EMPLOYMENT_TYPE: 'EMPLOYMENT_TYPE',
  LOCATION: 'LOCATION',
  HOURLY_RATE: 'HOURLY_RATE',
  ACCOUNT_STATUS: 'ACCOUNT_STATUS',
};

export const LARGE_SCREEN_WIDTH = 1536;

export const NUMBER_OF_ROWS_IN_SMALL_SCREENS = 6;

export const NUMBER_OF_ROWS_IN_LARGE_SCREENS = 8;

export const NUMBER_OF_MENTIONS_IN_SMALL_SCREENS = 3;

export const NUMBER_OF_MENTIONS_IN_LARGE_SCREENS = 4;

export const MENTIONS_TYPES = {
  DESIGN: 'designMention',
  REQUEST_PROPERTY: 'requestPropertyMention',
  RQUEST_QUESTION: 'requestQuestionMention',
  PROJECT: 'projectMention',
  TASK: 'taskMention',
} as const;

export const REQUESTS_TABLE_SORTABLE_COLUMNS = {
  REQUEST_NUMBER: 'requestIdentifier',
  TITLE: 'title',
  STATUS: 'status',
  REQUESTER_NAME: 'requester.data.firstName',
  OWNERS: 'owners[0].data.firstName',
  PROJECTS: 'linkedProject',
  BUSINESS_UNIT: 'businessTeams[0].title',
  LD_PRIORITY: 'ldPriority',
  FORM_TITLE: 'formTitle',
  CREATED_AT: 'createdAt',
  SUBMITTED_AT: 'submittedAt',
  DECISION_DATE: 'decision_date',
  UPDATED_AT: 'updatedAt',
} as const;

export const SIDE_PANEL_TYPES = {
  REQUEST: 'request',
  TIME_OFF: 'timeOff',
};

export const ALLOWED_FIELD_TYPES = {
  BOOLEAN: 'boolean',
  DATE: 'date',
  DATERANGE: 'daterange',
  LABEL: 'label',
  MULTILINE: 'multiline text',
  NUMERIC: 'numeric',
  SINGLE_LINE: 'single-line text',
  INT: 'int',
  FLOAT: 'float',
} as const;

export const ALLOWED_FIELD_VALUE_SELECTORS = {
  CHECKBOX: 'checkbox',
  DATE_PICKER: 'date_picker',
  DROPDOWN: 'dropdown',
  FREEFORM: 'freeform',
  RADIO: 'radio',
} as const;

export const FIELD_CONSOLIDATED_TYPES = {
  CHECKBOX: 'checkbox',
  DATE_PICKER: 'date_picker',
  DROPDOWN: 'dropdown',
  NUMERICAL_FIELD: 'numerical_field',
  RADIO: 'radio',
  TEXT_FIELD: 'text_field',
  TOGGLE: 'toggle',
};

export const PROJECT_TEMPLATE_ACCESS_VALUES = {
  USER: 'user',
  TEAM: 'team',
  ORGANIZATION: 'org',
  EVERYONE: 'everyone',
} as const;

export const PROJECT_TEMPLATE_STATUS_VALUES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  DISABLED: 'disabled',
} as const;

export const PROJECT_TEMPLATE_RESOURCE_TYPE_VALUES = {
  INTERNAL: 'internal',
  VENDOR: 'vendor',
  MIXED: 'mixed',
} as const;

export const ALLOWED_DATE_FIELD_DISPLAY_VALUES = {
  START_DATE: 'start-date',
  END_DATE: 'end-date',
} as const;

export const TEMPLATE_SORTING = {
  NAME: ['name'],
  DESCRIPTION: ['description'],
  OWNER: ['owner.data.firstName', 'owner.data.lastName'],
  UPDATED_AT: ['updatedAt'],
  STATUS: ['status'],
};

export const CUSTOM_FIELDS = {
  TITLE: 'name',
  TYPE: 'type',
  DESCRIPTION: 'description',
  CREATOR: 'creator',
  LAST_UPDATED: 'updatedAt',
  ENABLED: 'enabled',
  DISABLED: 'disabled',
  ALLOWED_VALUES: 'allowed_values',
  VALUE_SELECTOR: 'value_selector',
  COLLECTION: 'collection',
  DEFAULT_VALUE: 'default_value',
};

export const CUSTOM_FIELDS_FILTERS = {
  TYPE: 'type',
} as const;

export const PROGRAM_FIELDS = {
  PROGRAM_NUMBER: 'program_number',
  TITLE: 'title',
  STATUS: 'status',
  DESCRIPTION: 'description',
  OWNERS: 'programOwners',
  DELIVERY: 'delivery_type',
  CREATION: 'createdAt',
};

export const PROGRAMS_DELIVERY_TYPE = {
  ONLINE: 'online',
  IN_PERSON: 'in_person',
  BLENDED: 'blended',
  OTHER: 'other',
} as const;

export const PROGRAMS_STATUS = {
  NOT_STARTED: 'not_started',
  IN_PROGRESS: 'in_progress',
  CLOSED: 'closed',
};

export const PROGRAMS_COLUMNS = [
  {
    labelKey: 'PROGRAM_NUMBER',
    value: PROGRAM_FIELDS.PROGRAM_NUMBER,
    operators: [
      TABLE_FILTERS_OPERATORS.CONTAINS,
      TABLE_FILTERS_OPERATORS.DOESNT_CONTAIN,
      TABLE_FILTERS_OPERATORS.EQUAL,
      TABLE_FILTERS_OPERATORS.GREATER,
      TABLE_FILTERS_OPERATORS.GREATER_OR_EQUAL,
      TABLE_FILTERS_OPERATORS.LESS,
      TABLE_FILTERS_OPERATORS.LESS_OR_EQUAL,
    ],
    type: COLUMN_OPTION_TYPES.TEXT,
  },
  {
    labelKey: 'TITLE',
    value: PROGRAM_FIELDS.TITLE,
    operators: STRING_COMPARATOR,
    type: COLUMN_OPTION_TYPES.TEXT,
  },
  {
    labelKey: 'STATUS',
    value: PROGRAM_FIELDS.STATUS,
    type: COLUMN_OPTION_TYPES.OPTIONS,
    operators: [
      TABLE_FILTERS_OPERATORS.EQUAL,
      TABLE_FILTERS_OPERATORS.NOT_EQUAL,
    ],
  },
  {
    labelKey: 'OWNERS',
    value: PROGRAM_FIELDS.OWNERS,
    operators: STRING_COMPARATOR,
    type: COLUMN_OPTION_TYPES.TEXT,
  },
  {
    labelKey: 'DELIVERY_TYPE',
    value: PROGRAM_FIELDS.DELIVERY,
    type: COLUMN_OPTION_TYPES.OPTIONS,
    operators: [
      TABLE_FILTERS_OPERATORS.EQUAL,
      TABLE_FILTERS_OPERATORS.NOT_EQUAL,
    ],
  },
  {
    labelKey: 'CREATION',
    value: PROGRAM_FIELDS.CREATION,
    operators: DATE_COMPARATOR,
    type: COLUMN_OPTION_TYPES.DATE,
  },
];

export const PROGRAM_PROJECT_FIELDS = {
  TITLE: 'title',
  STATUS: 'status',
  PRIORITY: 'priority',
  PROGRESS: 'progress',
  START_DATE: 'startDate',
  TARGET_COMPLETION_DATE: 'targetCompletionDate',
  ACTUAL_COMPLETION_DATE: 'actualDate',
};

export const HELPER_CENTER_LINK = 'https://intercom.help/cognota';
export const TIME_OFF_TABLE_COLUMNS = {
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  TYPE: 'time_off_type',
};

export const TEAMS_PAGE_TABS = {
  CAPACITY: 'capacity',
  TEAMS: 'team',
};

export const EMAIL_REGEX = /^([\w-+.]+@([\w-]+\.)+[\w-]{2,10})?$/;

export const PROJECT_TEMPLATE_ERRORS = {
  VERSION_MISMATCH: 'project template version mismatch',
};

export const SETTINGS_FILTERS = {
  TEAM_PROJECTS_TABLE: 'teamProjectsTable',
  MY_PROJECTS_TABLE: 'myProjectsTable',
  TEAM_PROJECTS_BOARD: 'teamProjectsBoard',
  MY_PROJECTS_BOARD: 'myProjectsBoard',
  PROJECT_TEAM_TASKS_TABLE: 'teamTasksTable',
  MY_PROJECT_TASKS_TABLE: 'myTasksTable',
  MY_REQUESTS_TABLE: 'myRequests',
  TEAM_REQUESTS_TABLE: 'teamRequests',
  FIELDS_TABLE: 'fieldsTable',
  CENTRALIZED_TEAM_TASKS_TABLE: 'centralizedTeamTasksTable',
  MY_CENTRALIZED_TASKS_TABLE: 'myCentralizedTasksTable',
  PROGRAMS_TABLE: 'programsTable',
  USERS_TABLE: 'usersTable',
  PROGRAM_PROJECTS_TABLE: 'programProjectsTable',
  UPCOMING_TIME_OFFS_TABLE: 'upcomingTimeOffsTable',
  PAST_TIME_OFFS_TABLE: 'pastTimeOffsTable',
} as const;

export const CHANGED_TASK_TYPE = {
  LMS: 'LMS',
  DESIGN: 'Design',
  OTHER: 'Other',
};

export const TASK_TYPE_CATEGORY = {
  TABLE: 'table',
  DETAIL: 'detail',
};

export enum ParticipantTypes {
  OWNERS = 'owners',
  LEARNING = 'learningUsers',
  BUSINESS = 'businessUsers',
  EXTERNAL = 'externalUsers',
  FACILITATOR = 'facilitatorUsers',
}

export const CAPACITY_TABLE_NUMBER_OF_WEEKS = 6;
export const TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS = 7;
export const TEAMS_CAPACITY_TABLE_NUMBER_OF_TEAMS = 5;

export const DELETE_PROGRAM_OPTIONS = {
  NO_PROJRCTS: 'NO_PROJECTS',
  MOVE_PROJECTS: 'MOVE_PROJECTS',
  UNLINK_PROJECTS: 'UNLINK_PROJECTS',
  DELETE_PROJECTS: 'DELETE_PROJECTS',
};

export const INSIGHTS_PAGE_COLORS = {
  STROKE: '#DADDE2',
  SKELETON_LOADER_COLOR: '#E1E9EC',
  APPROVED_REQUESTS: {
    BAR_CHART_MAIN_COLOR: '#9B3A66',
    BAR_CHART_AXIES_STYLE: {
      fontSize: '0.75em',
      fontWeight: '400',
      fill: '#000',
      lineHeight: '1em',
    },
  },
  IN_REVIEW_REQUESTS: {
    BAR_CHART_MAIN_COLOR: '#327569',
    BAR_CHART_AXIES_STYLE: {
      fontSize: '0.75em',
      fontWeight: '400',
      fill: '#000',
      lineHeight: '1em',
    },
  },
  PROJECTS: {
    BY_PROCESS: {
      BAR_CHART_MAIN_COLOR: '#327569',
      BAR_CHART_AXIES_STYLE: {
        fontSize: '0.75em',
        fontWeight: '400',
        fill: '#0E1212',
        lineHeight: '1em',
      },
    },
    BY_STATUS: {
      BAR_CHART_MAIN_COLOR: '#8B2C56',
    },
    TASKS_BY_STATUS: {
      BAR_CHART_MAIN_COLOR: '#45598C',
    },
  },
};

export const INSIGHT_REQUEST_FILTER_PANEL_DATE_OPTIONS = {
  LAST_1_MONTH: {
    key: 'LAST_1_MONTH',
    numberOfDays: 30,
  },
  LAST_2_MONTHS: {
    key: 'LAST_2_MONTHS',
    numberOfDays: 60,
  },
  LAST_3_MONTHS: {
    key: 'LAST_3_MONTHS',
    numberOfDays: 90,
  },
  LAST_4_MONTHS: {
    key: 'LAST_4_MONTHS',
    numberOfDays: 120,
  },
  LAST_5_MONTHS: {
    key: 'LAST_5_MONTHS',
    numberOfDays: 150,
  },
  LAST_6_MONTHS: {
    key: 'LAST_6_MONTHS',
    numberOfDays: 180,
  },
  ALL: {
    key: 'ALL',
    numberOfDays: 0,
  },
};

export const INSIGHT_CAPACITY_FILTER_PANEL_DATE_OPTIONS = {
  LAST_WEEK: {
    key: 'LAST_WEEK',
    numberOfDays: 7,
  },
  LAST_MONTH: {
    key: 'LAST_MONTH',
    numberOfDays: 30,
  },
  LAST_3_MONTHS: {
    key: 'LAST_3_MONTHS',
    numberOfDays: 90,
  },
  LAST_6_MONTHS: {
    key: 'LAST_6_MONTHS',
    numberOfDays: 180,
  },
  LAST_12_MONTHS: {
    key: 'LAST_12_MONTHS',
    numberOfDays: 365,
  },
  CUSTOM: {
    key: 'CUSTOM',
    numberOfDays: 0,
  },
};

export const INSIGHTS_DATE_FILTER_NAME = {
  INTAKE: 'intakeDateFilter',
  PROJECT: 'projectDateFilter',
  CAPACITY: 'capacityDateFilter',
  CAPACITY_DATE_FILTER_DATE_RANGE: {
    START_DATE: 'capacityStartDateFilter',
    END_DATE: 'capacityEndDateFilter',
  },
};

export const INSIGHT_PROJECT_FILTER_PANEL_PRIORITY_OPTIONS = [
  'HIGH',
  'MEDIUM',
  'LOW',
];

export const PROJECTS_LIST_ACTIONS = {
  DUPLICATE: 'duplicate',
  ARCHIVE: 'archive',
  UNARCHIVE: 'unarchive',
} as const;

export const FIELD_TYPES_DISPLAY_NAME_MAP = {
  [ALLOWED_FIELD_TYPES.MULTILINE]: 'Multiline Text Field',
  [ALLOWED_FIELD_TYPES.SINGLE_LINE]: 'Text field',
};

export const TASK_BUNDLE_FIELDS = {
  TITLE: 'name',
  DESCRIPTION: 'description',
  CREATOR: 'taskBundleCreator',
  CREATION: 'createdAt',
};

export const TASK_BUNDLE_ACTIONS = {
  DELETE: 'DELETE',
};

export const TASK_BUNDLE_SORTING = {
  NAME: ['name'],
  DESCRIPTION: ['description'],
  ESTIMATED_HOURS: ['totalEstimatedHours'],
  CREATOR: [
    'taskBundleCreator.data.firstName',
    'taskBundleCreator.data.lastName',
  ],
  CREATED_AT: ['createdAt'],
};

export const CUSTOM_FIELDS_FILTERS_TYPES = {
  DATE: 'date',
  DATE_RANGE: 'date_range',
  NUMBER: 'number',
  TEXT: 'text',
  MULTI_OPTIONS: 'multi_options',
};

export const FILESTACK_FILE_TYPES = {
  JPG: 'image/jpeg',
  PNG: 'image/png',
  MP4: 'video/mp4',
  MPEG: 'video/mpeg',
  MP3: 'audio/mpeg',
};

export const NUMERICAL_FIELD_MAX_VALUE = 99999999.99;

export const ADDITIONAL_BENEFITS_PROMPTS = ['OTHER', 'SCOPE'];

// when adding new fiels, make sure the keys for the new file are properly mapped in backend endpoint for signing roi files
export const ROI_METHODOLOGIES = {
  MAIN: ['application_guide', 'roi_brocheur', 'roi_at_a_glance'],
  INPUT: ['level_0_building_the_brief'],
  REACTION: ['level_1_reaction_evaluation_methodology'],
  LEARNING: ['level_2_learning_evaluation_methodology'],
  APPLICATION: ['level_3_application_evaluation_methodology'],
  IMPACT: [
    'level_4_impact_calculating_monetary_value',
    'level_4_impact_evaluation_methodology',
  ],
  ROI: [
    'level_5_roi_calculating_program_costs',
    'level_5_roi_evaluation_methodology',
  ],
};

export const ROI_INSTITUTE_BOOTCAMP_LINK =
  'https://www.roiinstituteacademy.com/offers/MAtphF2S/checkout?coupon_code=COGNOTABC';
