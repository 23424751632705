import { FC, HTMLProps } from 'react';
import config from 'config/Config';
import {
  File,
  Design,
  WebLink,
  ContentType,
} from 'types/store/linkedTaskContent';

interface DownloadableWrapperProps {
  name: string;
  contentTye: ContentType;
  contentData: File | Design | WebLink;
}

const DownloadableWrapper: FC<DownloadableWrapperProps> = ({
  name,
  contentTye,
  contentData,
  children,
}) => {
  let props: HTMLProps<HTMLAnchorElement> = {};

  switch (contentTye) {
    case 'file':
    case 'design_file':
      props = {
        href: (contentData as File).cloudFrontURL || (contentData as File).url,
        download: (contentData as File).filename,
      };
      break;
    case 'web_link':
      props = {
        href: (contentData as WebLink).url,
      };
      break;
    case 'design':
      props = {
        href: `${config.get('designURL')}#/elements/${
          (contentData as Design).id
        }/briefElements`,
      };
      break;
    default:
      break;
  }

  return (
    <a aria-label={name} target='_blank' rel='noreferrer' {...props}>
      {children}
    </a>
  );
};

export default DownloadableWrapper;
