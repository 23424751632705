import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { ProgramInputCategoryData } from 'utils/types/program';
import { RootState } from 'state/store';
import programInputAPI from './ProgramInputCategoyAPI';

interface programInputCategoryState {
  status: Status;
  value: ProgramInputCategoryData;
}

/* ============================= INITIAL STATE ============================== */

const initialState: programInputCategoryState = {
  value: {} as ProgramInputCategoryData,
  status: SLICE_STATUS.IDLE,
};

/* ============================= ACTIONS ============================== */
export const resetProgramInput = createAction(
  'programInput/RESET_PROGRAM_INPUT'
);

/* ============================== REDUX THUNK =============================== */
export const fetchProgramInput = createAsyncThunk(
  'programInput/FETCH_PROGRAM_INPUT',
  async ({
    programId,
    categoryId,
  }: {
    programId: string;
    categoryId: string;
  }) => {
    const response = await programInputAPI.fetchProgramInput(
      programId,
      categoryId
    );
    if (response.status !== 200) {
      throw new Error('Error fetching program INPUT category');
    }
    return response.data;
  }
);

export const createProgramInput = createAsyncThunk(
  'programInput/CREATE_PROGRAM_INPUT',
  async ({
    programId,
    categoryId,
    inputUpdatedData,
  }: {
    programId: string;
    categoryId: string;
    inputUpdatedData: ProgramInputCategoryData;
  }) => {
    const response = await programInputAPI.createProgramInput(
      programId,
      categoryId,
      inputUpdatedData
    );
    return response;
  }
);

export const updateProgramInput = createAsyncThunk(
  'programInput/UPDATE_PROGRAM_INPUT',
  async ({
    programId,
    categoryId,
    inputId,
    inputUpdatedData,
  }: {
    programId: string;
    categoryId: string;
    inputId: string;
    inputUpdatedData: ProgramInputCategoryData;
  }) => {
    const response = await programInputAPI.updateProgramInput(
      programId,
      categoryId,
      inputId,
      inputUpdatedData
    );
    return response;
  }
);

export const linkRequests = createAsyncThunk(
  'programInput/LINK_REQUESTS',
  async ({
    programId,
    categoryId,
    inputId,
    requestIds,
  }: {
    programId: string;
    categoryId: string;
    inputId: string;
    requestIds: string[];
  }) => {
    const response = await programInputAPI.linkRequests(
      programId,
      categoryId,
      inputId,
      requestIds
    );
    return response.data;
  }
);

export const unlinkRequests = createAsyncThunk(
  'programInput/UN_LINK_REQUESTS',
  async ({
    programId,
    categoryId,
    inputId,
    inputRequestIds,
  }: {
    programId: string;
    categoryId: string;
    inputId: string;
    inputRequestIds: string[];
  }) => {
    const response = await programInputAPI.unlinkRequests(
      programId,
      categoryId,
      inputId,
      inputRequestIds
    );
    return response?.data;
  }
);

export const linkBusinessUnits = createAsyncThunk(
  'programInput/LINK_BUSINESS_UNITS',
  async ({
    programId,
    categoryId,
    inputId,
    businessUnitIds,
  }: {
    programId: string;
    categoryId: string;
    inputId: string;
    businessUnitIds: string[];
  }) => {
    const response = await programInputAPI.linkBusinessUnits(
      programId,
      categoryId,
      inputId,
      businessUnitIds
    );
    return response.data;
  }
);

export const unlinkBusinessUnits = createAsyncThunk(
  'programInput/UN_LINK_BUSINESS_UNITS',
  async ({
    programId,
    categoryId,
    inputId,
    inputBusinessUnitIds,
  }: {
    programId: string;
    categoryId: string;
    inputId: string;
    inputBusinessUnitIds: string[];
  }) => {
    const response = await programInputAPI.unlinkBusinessUnits(
      programId,
      categoryId,
      inputId,
      inputBusinessUnitIds
    );
    return response?.data;
  }
);

/* ================================= REDUCER ================================ */
const programInputSlice = createSlice({
  name: 'programInput',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProgramInput.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchProgramInput.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchProgramInput.fulfilled, (state, action) => {
        state.value = action.payload;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(resetProgramInput, (state) => {
        state.value = {} as ProgramInputCategoryData;
      })
      .addCase(createProgramInput.fulfilled, (state, action) => {
        state.value = {
          ...action.payload,
          requests: state.value.requests,
          businessUnits: state.value.businessUnits,
        };
      })
      .addCase(createProgramInput.rejected, () => {
        throw new Error();
      })
      .addCase(updateProgramInput.fulfilled, (state, action) => {
        state.value = {
          ...action.payload,
          requests: state.value.requests,
          businessUnits: state.value.businessUnits,
        };
      })
      .addCase(updateProgramInput.rejected, () => {
        throw new Error();
      })
      .addCase(linkRequests.fulfilled, (state, action) => {
        state.value.requests = action.payload.concat(
          state.value.requests ?? []
        );
      })
      .addCase(linkRequests.rejected, () => {
        throw new Error();
      })
      .addCase(unlinkRequests.fulfilled, (state, action) => {
        state.value.requests = state.value.requests?.filter(
          (request) => !action.payload!.includes(request.inputRequestId!)
        );
      })
      .addCase(unlinkRequests.rejected, () => {
        throw new Error();
      })
      .addCase(linkBusinessUnits.fulfilled, (state, action) => {
        state.value.businessUnits = action.payload.concat(
          state.value.businessUnits ?? []
        );
      })
      .addCase(linkBusinessUnits.rejected, () => {
        throw new Error();
      })
      .addCase(unlinkBusinessUnits.fulfilled, (state, action) => {
        state.value.businessUnits = state.value.businessUnits?.filter(
          (businessUnit) =>
            !action.payload.includes(businessUnit.inputBusinessUnitId)
        );
      })
      .addCase(unlinkBusinessUnits.rejected, () => {
        throw new Error();
      });
  },
});

/* =============================== SELECTORS ================================ */

export const getProgramInput = (state: RootState): ProgramInputCategoryData =>
  state.programInput.value;

export const getProgramInputStatus = (state: RootState): Status =>
  state.programInput.status;

export default programInputSlice.reducer;
