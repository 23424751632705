import { useSelector } from 'react-redux';
import Card from '../Card';
import emptyMentionsImg from 'assets/images/empty-mentions-img.svg';
import mentionsIcon from 'assets/icons/mentions-icon.svg';
import intl from 'react-intl-universal';
import { useState } from 'react';
import {
  selectRecentMentionsForCard,
  selectRecentMentionsStatus,
} from 'state/MyWorkspace/myWorkspaceSlice';
import MentionsList from './MentionsList';
import MentionsModal from './MentionsModal';
import { SLICE_STATUS } from 'utils/constants';
import MentionsLoader from './MentionsLoader';

const MentionsCard = () => {
  const mentionsSelector = useSelector(selectRecentMentionsForCard);
  const mentionsStatus = useSelector(selectRecentMentionsStatus);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <MentionsModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        icon={mentionsIcon}
      />
      <Card
        title={intl.get('MY_WORKSPACE.RECENT_MENTIONS.TITLE')}
        iconSrc={mentionsIcon}
        emptyStateImg={emptyMentionsImg}
        emptyStateText={intl.get(
          'MY_WORKSPACE.RECENT_MENTIONS.EMPTY_STATE_TEXT'
        )}
        content={
          mentionsStatus === SLICE_STATUS.LOADING ? (
            <MentionsLoader />
          ) : mentionsSelector.length ||
            mentionsStatus === SLICE_STATUS.FAILED ? (
            <MentionsList data={mentionsSelector} cardList />
          ) : null
        }
        showViewAllButton={mentionsSelector.length ? true : false}
        viewAllTooltipText={intl.get(
          'MY_WORKSPACE.RECENT_MENTIONS.TOOLTIP_TEXT'
        )}
        viewAllOnClick={() => setIsModalOpen(true)}
        dataCy='recent-mentions'
      />
    </>
  );
};

export default MentionsCard;
