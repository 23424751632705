import EvaluationPlan from './components/EvaluationPlan';
import { Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

const Evaluation = ({
  hasObjectives,
  categoryName,
}: {
  hasObjectives: boolean;
  categoryName: string;
}) => {
  return (
    <div className='w-2/3 h-full px-6 pt-10'>
      <Typography variant='h5' className='pb-2 leading-7'>
        {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.TITLE')}
      </Typography>
      <Typography className='text-neutral-darker' variant='body2'>
        {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.SECTION_DESCRIPTION')}
      </Typography>
      <EvaluationPlan
        hasObjectives={hasObjectives}
        categoryName={categoryName}
      />
    </div>
  );
};

export default Evaluation;
