import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { PickerFileMetadata } from 'filestack-js';
import { Typography, FileUpload } from '@getsynapse/design-system';
import { getFileStackConfig } from '../../../../AddContentModal/components/projectContentFilestackConfig';
import {
  ProjectFile,
  ProjectContent,
  ProjectContentType,
} from 'utils/customTypes';
import CarouselContentItem from '../../../../AddContentModal/components/CarouselContentItem';

const ReplaceFileContent: React.FC<{
  originalFile: ProjectFile;
  replaceFile: (fileData: ProjectFile) => void;
  contentType: Omit<ProjectContentType, 'web_link | design'>;
}> = ({ replaceFile, originalFile, contentType }) => {
  const fileStackConfig = getFileStackConfig();
  const [uploadedFile, setUploadedFile] = useState<ProjectFile | null>(null);
  const onUploadFile = (newFiles: PickerFileMetadata[]) => {
    if (newFiles.length > 0) {
      setUploadedFile(newFiles[0]);
      replaceFile(newFiles[0]);
    }
  };
  const onRemoveFile = () => {
    setUploadedFile(null);
    replaceFile(originalFile);
  };
  return (
    <div>
      <Typography
        variant='body2'
        weight='medium'
        className='text-neutral-black'
      >
        {uploadedFile
          ? intl.get(
              'PROJECT_DETAIL.FILES_TAB.REPLACE_CONTENT_MODAL.REPLACE_FILE'
            )
          : intl.get(
              'PROJECT_DETAIL.FILES_TAB.REPLACE_CONTENT_MODAL.UPLOAD_FILE'
            )}
      </Typography>
      {uploadedFile ? (
        <div className='p-2'>
          <CarouselContentItem
            content={
              {
                data: uploadedFile,
                content_type: contentType,
              } as Partial<ProjectContent>
            }
            index={0}
            onRemoveFile={onRemoveFile}
          />
        </div>
      ) : (
        <FileUpload
          className='flex-1 mt-1'
          config={{ ...fileStackConfig, maxFiles: 1 }}
          displayUploadedFiles={false}
          onFileUploadHandle={onUploadFile}
          customErrorMessages={{
            'file-too-large': intl.get('PROJECT_DETAIL.FILES_TAB.FILE_1GB'),
          }}
          uploadInstructions={
            <>
              {intl.get('INTAKE_TAB.DRAG_HERE')}{' '}
              <span className='text-purple-darker'>
                {intl.get('INTAKE_TAB.CHOOSE')}
              </span>{' '}
              {intl.get('PROJECT_DETAIL.FILES_TAB.FILE_LIMIT')}
            </>
          }
          hidePickerWhenUploading
          data-cy='replace-file__upload-picker'
        />
      )}
    </div>
  );
};

export default ReplaceFileContent;
