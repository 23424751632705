import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';

import { RootState } from 'state/store';
import { fetchWeeklyCapacityForTeam } from 'api';
import { generateFetchPrevOrNextWeekCapacityURL, removeTeams } from './helpers';
import { selectDatesRange, selectWeeks } from '../Weeks/weeksSlice';
import { DateSegment, getStartOfTheWeek, toDateString } from '../Weeks/helpers';

// 1:1 mapping but can change later when necessary
export type CapacityPerWeekEntryState = {
  startDate: string; // Date in str form
  endDate: string; // Date in str form
  assigned: string;
  timeOff: string;
  capacity: string;
};

export type CapacityPerWeekState = {
  [week: string]: CapacityPerWeekEntryState | undefined;
};

export type WeeklyTeamCapacityState = {
  [teamId: string]: {
    weeklyCapacities: CapacityPerWeekState;
  };
};

interface TeamsCapacityState {
  byId: WeeklyTeamCapacityState;
  visibleTeams: string[];
  status: Status;
}

export interface FetchMoreDailyTeamsCapacityParams {
  startDate: string;
  endDate: string;
  link: 'prev' | 'next';
}

export interface FetchMoreWeeklyTeamsCapacityParams {
  startDate: string;
  endDate: string;
  link: 'prev' | 'next';
}

/* ============================= INITIAL STATE ============================== */
const initialState: TeamsCapacityState = {
  byId: {},
  visibleTeams: [],
  status: SLICE_STATUS.IDLE,
};

/* ============================== ACTIONS =============================== */
const setVisibleTeams = createAction<string[]>(
  'weeklyTeamCapacities/SET_VISIBLE_TEAMS'
);
export const setTeamCapacityStatus = createAction<Status>(
  'weeklyTeamCapacities/SET_TEAM_CAPACITY_STATUS'
);
export const resetWeeklyCapacityState = createAction(
  'weeklyTeamCapacities/RESET_STATE'
);
const setWeeklyTeamCapacities = createAction<{
  teamId: string;
  weeklyCapacities: CapacityPerWeekState;
}>('weeklyTeamCapacities/SET_TEAM_WEEEKLY_CAPACITIES');

const removeWeekFromWeeklyTeamCapacities = createAction<{
  teamId: string;
  week: string;
}>('weeklyTeamCapacities/REMOVE_DAY_FROM_TEAM_WEEEKLY_CAPACITIES');

/* ============================== REDUX THUNK =============================== */
export const fetchWeeklyTeamsCapacity = createAsyncThunk(
  'weeklyTeamCapacities/FETCH_WEEEKLY_TEAMS_CAPACITY',
  async (
    params: { teamIds: string[] },
    { rejectWithValue, getState, dispatch }
  ) => {
    const state = getState() as RootState;
    const { startDate, endDate } = selectDatesRange(state);

    const currentTeamIds = state.weeklyTeamsCapacity.teamsCapacity.visibleTeams;

    try {
      const teamsToFetch = params.teamIds.filter(
        (teamId) => !currentTeamIds.includes(teamId)
      );
      dispatch(setVisibleTeams(params.teamIds));
      let requests = [];
      let counter = 0;
      for (const teamId of teamsToFetch) {
        requests.push(
          fetchWeeklyCapacityForTeam({ teamId, startDate, endDate }).then(
            (res) => {
              const weeklyCapacities = res.weeklyCapacities;
              if (weeklyCapacities) {
                dispatch(
                  setWeeklyTeamCapacities({
                    teamId: teamId,
                    weeklyCapacities,
                  })
                );
              }
              return res.links;
            }
          )
        );

        if (counter % 5 === 0 && counter !== 0) {
          await Promise.all(requests);
        }
      }
      const teamIdsToRemove = currentTeamIds.filter(
        (teamId) => !params.teamIds.includes(teamId)
      );
      return teamIdsToRemove;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const fetchMoreWeeklyTeamsCapacity = createAsyncThunk(
  'weeklyTeamCapacities/FETCH_MORE_WEEKLY_TEAMS_CAPACITY',
  async (
    params: FetchMoreWeeklyTeamsCapacityParams,
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const state = getState() as RootState;
      const { link, startDate, endDate } = params;
      const visibleTeams = state.weeklyTeamsCapacity.teamsCapacity.visibleTeams;

      for (const teamId of visibleTeams) {
        let startDateParam = '';
        if (link === 'next') {
          startDateParam = generateFetchPrevOrNextWeekCapacityURL(
            endDate,
            link
          );
        } else {
          startDateParam = generateFetchPrevOrNextWeekCapacityURL(
            startDate,
            link
          );
        }

        fetchWeeklyCapacityForTeam({
          teamId,
          startDate: startDateParam,
        })
          .then((res) => {
            const weeklyCapacities = res.weeklyCapacities;
            if (weeklyCapacities) {
              dispatch(
                setWeeklyTeamCapacities({
                  teamId,
                  weeklyCapacities,
                })
              );
            }
            return res;
          })
          .then(() => {
            let week = '';
            if (link === 'prev') {
              week = endDate;
            } else {
              week = startDate;
            }
            dispatch(
              removeWeekFromWeeklyTeamCapacities({
                teamId,
                week,
              })
            );
          });
      }
      return link;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

/* ================================= REDUCER ================================ */
const weeklyTeamsCapacitySlice = createSlice({
  name: 'weeklyTeamsCapacity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWeeklyTeamsCapacity.pending, (state) => {
      state.status = SLICE_STATUS.LOADING;
    });
    builder.addCase(fetchWeeklyTeamsCapacity.fulfilled, (state, action) => {
      state.status = SLICE_STATUS.IDLE;
      const teamIdsToRemove = action.payload;
      if (teamIdsToRemove && teamIdsToRemove.length > 0) {
        state.byId = removeTeams(state.byId, teamIdsToRemove);
      }
    });
    builder.addCase(fetchWeeklyTeamsCapacity.rejected, (state) => {
      state.status = SLICE_STATUS.IDLE;
    });
    builder.addCase(setVisibleTeams, (state, action) => {
      state.visibleTeams = action.payload;
    });
    builder.addCase(setTeamCapacityStatus, (state, action) => {
      state.status = action.payload;
    });
    builder.addCase(setWeeklyTeamCapacities, (state, action) => {
      const { teamId, weeklyCapacities } = action.payload;
      let currentTeamDailyCapacities = {};
      if (state.byId[teamId]?.weeklyCapacities) {
        currentTeamDailyCapacities = { ...state.byId[teamId].weeklyCapacities };
      }
      currentTeamDailyCapacities = {
        ...currentTeamDailyCapacities,
        ...weeklyCapacities,
      };
      state.byId = {
        ...state.byId,
        [teamId]: {
          ...state.byId[teamId],
          weeklyCapacities: {
            ...currentTeamDailyCapacities,
          },
        },
      };
    });
    builder.addCase(removeWeekFromWeeklyTeamCapacities, (state, action) => {
      const { teamId, week } = action.payload;
      const weeklyCapacities = { ...state.byId[teamId].weeklyCapacities };
      const startOfWeek = getStartOfTheWeek(new Date(week.replace('-', '/')));
      delete weeklyCapacities[toDateString(startOfWeek)];

      state.byId[teamId].weeklyCapacities = weeklyCapacities;
    });
    builder.addCase(resetWeeklyCapacityState, (state) => {
      state.byId = initialState.byId;
      state.visibleTeams = initialState.visibleTeams;
    });
  },
});

/* =============================== SELECTORS ================================ */
export const selectTeamsCapacityStatus = (state: RootState) =>
  state.weeklyTeamsCapacity.teamsCapacity.status;

export const selectCapacityByTeamId = (state: RootState, teamId: string) =>
  state.weeklyTeamsCapacity.teamsCapacity.byId[teamId]?.weeklyCapacities;

export const selectWeeklyCapacityByTeamId = createSelector(
  [
    (state: RootState, teamId: string) => selectCapacityByTeamId(state, teamId),
    selectWeeks,
  ],
  (teamCapacity: CapacityPerWeekState, weeks: DateSegment[]) => {
    if (!teamCapacity || !weeks) {
      return {};
    }
    return weeks.reduce<{
      [week: string]: CapacityPerWeekEntryState | undefined;
    }>((acc, current: DateSegment) => {
      const [startDate] = current;
      const entry = teamCapacity[startDate];
      acc[startDate] = entry;
      return acc;
    }, {});
  }
);

export const selectVisibleTeams = (state: RootState) =>
  state.dailyTeamsCapacity.teamsCapacity.visibleTeams;

export default weeklyTeamsCapacitySlice.reducer;
