import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { ProgramInputCategoryData } from 'utils/types/program';

class ProgramInputAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchProgramInput = async (programId: string, categoryId: string) => {
    const res = await this.instance.get(
      `v2/programs/${programId}/categories/${categoryId}/input`
    );
    return res;
  };

  createProgramInput = async (
    programId: string,
    categoryId: string,
    inputUpdatedData: ProgramInputCategoryData
  ) => {
    const { data: response } = await this.instance.post(
      `v2/programs/${programId}/categories/${categoryId}/input`,
      inputUpdatedData
    );
    return response;
  };

  updateProgramInput = async (
    programId: string,
    categoryId: string,
    inputId: string,
    inputUpdatedData: ProgramInputCategoryData
  ) => {
    const { data: response } = await this.instance.put(
      `v2/programs/${programId}/categories/${categoryId}/input/${inputId}`,
      inputUpdatedData
    );
    return response;
  };

  linkRequests = async (
    programId: string,
    categoryId: string,
    inputId: string,
    requestIds: string[]
  ) => {
    const response = await this.instance.post(
      `v2/programs/${programId}/categories/${categoryId}/input/${inputId}/request`,
      { requestIds }
    );
    return response;
  };

  unlinkRequests = async (
    programId: string,
    categoryId: string,
    inputId: string,
    inputRequestIds: string[]
  ) => {
    const response = await this.instance.delete(
      `v2/programs/${programId}/categories/${categoryId}/input/${inputId}/request?link_ids=${inputRequestIds.join(
        ','
      )}`
    );
    return response;
  };

  linkBusinessUnits = async (
    programId: string,
    categoryId: string,
    inputId: string,
    businessTeamIds: string[]
  ) => {
    const response = await this.instance.post(
      `v2/programs/${programId}/categories/${categoryId}/input/${inputId}/business_team`,
      { businessTeamIds }
    );
    return response;
  };

  unlinkBusinessUnits = async (
    programId: string,
    categoryId: string,
    inputId: string,
    inputBusinessUnitIds: string[]
  ) => {
    const response = await this.instance.delete(
      `v2/programs/${programId}/categories/${categoryId}/input/${inputId}/business_team?link_ids=${inputBusinessUnitIds.join(
        ','
      )}`
    );
    return response;
  };
}

const programInputAPI = new ProgramInputAPI();

export default programInputAPI;
