import { useMemo, useState } from 'react';
import { GlobalBanner, Button, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import moment from 'moment';
import { DATE } from 'utils/constants';
import { Request } from 'utils/customTypes';
import WaitlistedInfoModal from './WaitlistedInfoModal';
import classNames from 'classnames';

type Props = {
  requestData: Request;
  sidePanel?: boolean;
  dataTestId?: string;
};

const WaitlistedRequestBanner = ({
  requestData,
  sidePanel,
  dataTestId,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const waitlistDate = moment(requestData.decision_date).format(
    DATE.SHORT_FORMAT
  );

  const conditions = useMemo(() => {
    const requestConditions =
      get(requestData, 'data.waitlisted.conditions') || [];
    return requestConditions
      .map((condition: string) =>
        intl.get(
          `REQUEST_PAGE.TOP_BAR.WAITLIST_MODAL.WAITLIST_CONDITIONS.${condition}`
        )
      )
      .join(', ');
  }, [requestData]);

  return (
    <>
      <WaitlistedInfoModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        comments={get(requestData, 'data.waitlisted.comments')}
        waitlistDate={waitlistDate}
        conditions={get(requestData, 'data.waitlisted.conditions')}
        details={get(requestData, 'data.waitlisted.details')}
      />
      <GlobalBanner
        variant='warning'
        hasCloseIcon
        className={classNames(`${sidePanel ? 'mb-4' : 'mt-2'}`)}
        iconProps={{
          'data-testid': dataTestId,
        }}
      >
        {() => {
          return (
            <div className='flex justify-between items-start'>
              <div>
                <Typography
                  variant='body'
                  className='text-warning-darker leading-6'
                >
                  {intl.getHTML(
                    'REQUEST_PAGE.WAITLISTED_REQUEST_BANNER.LINE_ONE',
                    { date: waitlistDate }
                  )}
                </Typography>
                <Typography
                  variant='body'
                  className='text-warning-darker leading-6'
                >
                  {intl.get('REQUEST_PAGE.WAITLISTED_REQUEST_BANNER.LINE_TWO', {
                    conditions: conditions,
                  })}
                </Typography>
              </div>
              {!sidePanel && (
                <Button onClick={() => setIsModalOpen(true)} color='warning'>
                  {intl.get('REQUEST_PAGE.DECLINED_REQUEST_BANNER.BUTTON')}
                </Button>
              )}
            </div>
          );
        }}
      </GlobalBanner>
    </>
  );
};

export default WaitlistedRequestBanner;
