import React from 'react';
import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import debounce from 'lodash/debounce';
import { RootState } from 'state/store';
import { selectTeamMemberProject } from 'state/Capacities/TeamMemberProjectsList/teamMemberProjectsListSlice';
import { selectCurrentViewSettingValue } from 'state/ViewSettings/viewSettingsSlice';
import {
  VIEW_SETTING,
  VIEW_SETTING_VALUES,
} from 'utils/constants/capacityView';
import useScrollHandlerContext from 'Pages/TeamsPage/views/TeamsCapacity/components/CapacityTable/components/ScrollHandler/useScrollHandlerContext';
import ProjectData from '../ProjectData/ProjectData';
import DailyProjectCapacities from 'Pages/TeamsPage/views/TeamsCapacity/components/DailyCapacity/components/TeamMemberProjects/DailyProjectCapacities/DailyProjectCapacities';
import WeeklyProjectCapacities from 'Pages/TeamsPage/views/TeamsCapacity/components/BetaWeeklyCapacity/components/TeamMemberProjects/TeamMemberProjectsWeeklyCapacities/TeamMemberProjectsWeeklyCapacities';

const ProjectRow: React.FC<{
  teamId: string;
  memberId: string;
  projectId: string;
  updateProjectIsInView: (projectId: string, inView: boolean) => void;
}> = ({ teamId, memberId, projectId, updateProjectIsInView }) => {
  const project = useSelector((state: RootState) =>
    selectTeamMemberProject(state, projectId)
  );
  const savedTeamsCapacityView = useSelector(
    selectCurrentViewSettingValue(VIEW_SETTING)
  );
  const { rootId } = useScrollHandlerContext();
  const debouncedOnChangeHandler = debounce(
    (inView: boolean) => updateProjectIsInView(projectId, inView),
    1000
  );
  const { ref: elementRef } = useInView({
    root: rootId,
    threshold: 0.5,
    rootMargin: '-80px 0px 0px',
    onChange: debouncedOnChangeHandler,
  });
  if (!project) {
    return null;
  }
  const customKey = `team-${teamId}__member-${memberId}__project-${projectId}`;
  return (
    <div
      ref={elementRef}
      aria-label={`${customKey}-row`}
      className='w-full h-26 flex'
    >
      <ProjectData
        customKey={customKey}
        projectId={projectId}
        projectData={project}
      />
      {savedTeamsCapacityView.value === VIEW_SETTING_VALUES.DAILY && (
        <DailyProjectCapacities
          teamId={teamId}
          memberId={memberId}
          projectId={projectId}
        />
      )}
      {(savedTeamsCapacityView.value === VIEW_SETTING_VALUES.BETA_WEEKLY ||
        savedTeamsCapacityView.value === VIEW_SETTING_VALUES.WEEKLY) && (
        <WeeklyProjectCapacities
          teamId={teamId}
          memberId={memberId}
          projectId={projectId}
        />
      )}
    </div>
  );
};

export default ProjectRow;
