import { useEffect, useRef, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import {
  fetchPastTimeOffEntries,
  selectPastTimeOffEntries,
  selectFetchPastTimeOffEntriesStatus,
  PastTimeOffEntriesStateStatus,
  resetPastTimeOffEntries,
  setSorting,
  selectPastTimeOffEntriesSorting,
  setFilters,
  selectCanFetchMorePastTimeOffEntries,
  selectTotalPastTimeOffEntries,
} from 'state/ManageTimeOff/TimeOffEntries/past/pastTimeOffEntriesSlice';
import { showNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import useGetUserId from '../../hooks/useGetUserId';
import { SLICE_STATUS, PATHS, SETTINGS_FILTERS } from 'utils/constants';
import { TIME_OFF_TABLES } from 'utils/constants/manageTimeOff';
import SkeletonLoader from '../TimeOffTableList/SkeletonLoader/SkeletonLoader';
import TimeOffTableList from '../TimeOffTableList/TimeOffTableList';
import { TimeOffEntryFilters } from 'types/store/manageTimeOff';
import {
  createFilterSetting,
  fetchFilterSettingByType,
  selectFiltersSettingsByType,
  updateFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import moment from 'moment';

const PastTimeOffList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useGetUserId();
  const [isFetching, setIsFetching] = useState(true);
  const [hasFilterLoaded, setHasFilterLoaded] = useState(false);
  const status = useRef<PastTimeOffEntriesStateStatus>(SLICE_STATUS.IDLE);
  const fetchPastTimeOffEntriesStatus = useSelector(
    selectFetchPastTimeOffEntriesStatus
  );
  const pastTimeOffEntries = useSelector(selectPastTimeOffEntries);
  const sorting = useSelector(selectPastTimeOffEntriesSorting);
  const canFetchMore = useSelector(selectCanFetchMorePastTimeOffEntries);
  const totalCount = useSelector(selectTotalPastTimeOffEntries);
  const [showFilterComponent, setShowFilterComponent] = useState(false);
  const filtersSettings = useSelector(
    selectFiltersSettingsByType(SETTINGS_FILTERS.PAST_TIME_OFFS_TABLE)
  );
  const savedFilters = useMemo<TimeOffEntryFilters>(
    () => (filtersSettings ? filtersSettings.settings : {}),
    [filtersSettings]
  );

  useEffect(() => {
    const fetchFilter = async () => {
      await dispatch(
        fetchFilterSettingByType(SETTINGS_FILTERS.PAST_TIME_OFFS_TABLE)
      );
      setHasFilterLoaded(true);
    };
    fetchFilter();
  }, [dispatch]);

  const handleSaveFiltersSettings = (updatedFilters: TimeOffEntryFilters) => {
    if (filtersSettings && filtersSettings.id) {
      dispatch(
        updateFilterSetting({
          id: filtersSettings.id,
          updateFields: { filter_settings: updatedFilters },
        })
      );
    } else {
      dispatch(
        createFilterSetting({
          filter_type: SETTINGS_FILTERS.PAST_TIME_OFFS_TABLE,
          filter_settings: updatedFilters,
        })
      );
    }
  };

  const handleToggleFilterComponent = () => {
    setShowFilterComponent((prevState) => !prevState);
  };

  useEffect(() => {
    if (userId && hasFilterLoaded) {
      dispatch(setFilters(savedFilters));
      dispatch(fetchPastTimeOffEntries({ userId, includeTotalCount: true }));
    }
    return () => {
      dispatch(resetPastTimeOffEntries());
    };
  }, [dispatch, userId, savedFilters, hasFilterLoaded]);

  useEffect(() => {
    if (fetchPastTimeOffEntriesStatus !== status.current) {
      status.current = fetchPastTimeOffEntriesStatus;
      if (fetchPastTimeOffEntriesStatus === 'rejected') {
        dispatch(
          showNotification({
            notificationVariant: 'error',
            notificationTitle: intl.get(
              'MANAGE_TIME_OFF.NO_PERMISSION_ERROR_TITLE'
            ),
            notificationMessage: intl.get(
              'MANAGE_TIME_OFF.NO_PERMISSION_ERROR_MESSAGE'
            ),
          })
        );
        history.replace(PATHS.ROOT);
      }
      if (fetchPastTimeOffEntriesStatus !== SLICE_STATUS.LOADING) {
        setIsFetching(false);
      }
    }
  }, [fetchPastTimeOffEntriesStatus, history, dispatch]);

  const onHandleSort = (sortBy: string, order: string) => {
    dispatch(setSorting({ sortBy, order }));
    dispatch(fetchPastTimeOffEntries({ userId: userId! }));
  };

  const handleFetchMoreTimeOffEntries = () => {
    dispatch(fetchPastTimeOffEntries({ userId: userId!, fetchNext: true }));
  };

  if (isFetching) {
    return (
      <SkeletonLoader id={`${TIME_OFF_TABLES.HISTORY}__skeleton-loader`} />
    );
  }

  return (
    <TimeOffTableList
      id={TIME_OFF_TABLES.HISTORY}
      timeOffEntries={pastTimeOffEntries}
      handleSort={onHandleSort}
      sorting={sorting}
      savedFilters={savedFilters}
      onUpdateFilters={handleSaveFiltersSettings}
      onCloseFilterPanel={handleToggleFilterComponent}
      isFilterPanelOpen={showFilterComponent}
      toggleFilterPanel={handleToggleFilterComponent}
      maxFilterDate={moment().format('YYYY-MM-DD')}
      canFetchMore={canFetchMore}
      fetchMoreTimeOffEntries={handleFetchMoreTimeOffEntries}
      totalCount={totalCount}
    />
  );
};

export default PastTimeOffList;
