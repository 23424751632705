import intl from 'react-intl-universal';
import { RequestPageTabs } from 'utils/customTypes';
import NavigationButton from './NavigationButton';
import ActionButton from './ActionButton';
import { useRequestContext } from 'Pages/RequestPage/context/RequestContext';

export const AditionalDetailsButtons = ({
  onClickHandler,
  onCancel,
  onUpdate,
  hasErrors,
  isLDUser,
}: {
  onClickHandler: (value?: RequestPageTabs) => void;
  onCancel: () => void;
  onUpdate: () => Promise<void>;
  hasErrors: boolean;
  isLDUser: boolean;
}) => {
  const { areBasicAndRequestDisabled, isAdditionalDisabled, isLDDisabled } =
    useRequestContext();
  const disableSaveButtons =
    areBasicAndRequestDisabled && isAdditionalDisabled && isLDDisabled;

  return (
    <div className='flex space-x-4 h-7.5 w-full justify-end'>
      <NavigationButton
        onClickHandler={onCancel}
        variant='tertiary'
        label={intl.get('REQUEST_PAGE.REQUEST_DETAILS.BUTTON.CANCEL')}
      />

      <ActionButton
        action={onUpdate}
        variant={isLDUser ? 'secondary' : 'primary'}
        hasErrors={hasErrors}
        testId='request_update-button'
        disabled={disableSaveButtons || hasErrors}
      >
        {intl.get('REQUEST_PAGE.REQUEST_DETAILS.BUTTON.UPDATE_EXIT')}
      </ActionButton>
    </div>
  );
};

export default AditionalDetailsButtons;
