import { FC } from 'react';
import { IconButton, tailwindOverride } from '@getsynapse/design-system';

interface ReorderColumnProps {
  taskId: string;
  disableUpArrow?: boolean;
  disableDownArrow?: boolean;
  onReorderRow: (direction: 'up' | 'down', taskId: string) => void;
}
const ReorderColumn: FC<ReorderColumnProps> = ({
  taskId,
  onReorderRow,
  disableDownArrow = false,
  disableUpArrow = false,
}) => {
  return (
    <div
      className='flex gap-x-2'
      aria-label={`reorder-task-${taskId}__arrow-buttons`}
    >
      <IconButton
        name='arrow-up'
        aria-label='move-task-up-arrow-button'
        disabled={disableUpArrow}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          onReorderRow('up', taskId);
        }}
        className={tailwindOverride('p-1 my-auto text-lg rounded-sm', {
          'text-neutral-lighter': disableUpArrow,
          'text-neutral-dark hover:text-primary hover:bg-neutral-lightest':
            !disableUpArrow,
        })}
      />
      <IconButton
        name='arrow-down'
        disabled={disableDownArrow}
        aria-label='move-task-down-arrow-button'
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          onReorderRow('down', taskId);
        }}
        className={tailwindOverride('p-1 my-auto text-lg rounded-sm', {
          'text-neutral-lighter': disableDownArrow,
          'text-neutral-dark hover:text-primary hover:bg-neutral-lightest':
            !disableDownArrow,
        })}
      />
    </div>
  );
};

export default ReorderColumn;
