import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Table,
  IconButton,
  tailwindOverride,
  Typography,
  Tooltip,
} from '@getsynapse/design-system';
import {
  selectTimeOffsTable,
  setSorting,
  deleteUserTimeOff,
} from 'state/TimeOff/TimeOffSlice';
import { openPanel } from 'state/SidePanel/sidePanelSlice';
import {
  displayNotification,
  setNotificationText,
  setNotificationVariant,
  setNotificationTimeout,
} from 'state/InlineNotification/inlineNotificationSlice';
import { DATE, SIDE_PANEL_TYPES } from 'utils/constants';
import { TimeOffData, SortingType } from 'utils/customTypes';
import TableHeaderFilters from './TableHeaderFilters';

const TimeOffTable = () => {
  const dispatch = useDispatch();
  const timeOffs = useSelector(selectTimeOffsTable);
  const isTableEmpty = timeOffs.data.length === 0;
  const handleSort = (orderByParam: string, order: SortingType) => {
    dispatch(setSorting({ order, orderBy: orderByParam }));
  };
  const handleRemoveTimeOff = async (id: string) => {
    await dispatch(deleteUserTimeOff(id));
    dispatch(setNotificationVariant('success'));
    dispatch(setNotificationTimeout(4000));
    dispatch(
      setNotificationText(
        intl.get('USER_TIME_OFF_PAGE.SIDE_PANEL.SUCCESS_MESSAGE', {
          action: intl.get('USER_TIME_OFF_PAGE.SIDE_PANEL.ACTIONS.REMOVE'),
        })
      )
    );
    dispatch(displayNotification());
  };
  return (
    <>
      <TableHeaderFilters />
      <Table
        canSelectRows={false}
        className='w-full'
        data-cy='time-offs-table'
        bodyProps={{
          className: tailwindOverride('max-h-table-body overflow-y-auto', {
            block: !isTableEmpty,
          }),
        }}
        data={{
          headData: {
            stickyHeader: true,
            handleSort,
            rowProps: {
              className: 'table w-full table-fixed h-auto min-h-10',
            },
            headCells: [
              {
                columnName: 'dates',
                content: intl.get('USER_TIME_OFF_PAGE.TABLE.COLUMNS.DATES'),
                className: 'w-1/3',
              },
              {
                columnName: 'time_off_type',
                content: intl.get('USER_TIME_OFF_PAGE.TABLE.COLUMNS.TYPE'),
              },
              {
                content: '',
                className: 'w-24',
              },
            ],
          },
          rows: timeOffs.data.map((timeOff: TimeOffData) => {
            const startDate = moment(new Date(timeOff.start_date))
              .utc()
              .format(DATE.TASK_TABLE_FORMAT);
            const endDate = moment(new Date(timeOff.end_date))
              .utc()
              .format(DATE.TASK_TABLE_FORMAT);
            return {
              id: timeOff.id,
              'data-testid': `time-off-row-${timeOff.id}`,
              className:
                'group cursor-pointer relative table w-full table-fixed',
              cells: [
                {
                  content: (
                    <Typography
                      variant='label'
                      className='text-primary'
                    >{`${startDate} - ${endDate}`}</Typography>
                  ),
                  className: 'w-1/3',
                },
                {
                  content: intl.get(
                    `TEAMS.TIME_OFF.TIME_OFF_TYPES.${timeOff.time_off_type}`
                  ),
                },
                {
                  content: (
                    <div className='flex items-center justify-end'>
                      <Tooltip
                        openMode='hover1'
                        timeout={0}
                        showPopper
                        position='topCenter'
                        contentProps={{
                          className: 'px-3 py-2 text-sm z-50 absolute',
                        }}
                        usePortal
                        trigger={
                          <IconButton
                            name='pencil-outline'
                            iconClassname='pointer-events-none'
                            className={tailwindOverride(
                              'text-base text-neutral-dark p-1 rounded',
                              'hover:bg-neutral-lightest hover:shadow-allocation-table'
                            )}
                            data-cy='edit-time-off-button'
                            aria-label={intl.get('EDIT')}
                            onClick={() =>
                              dispatch(
                                openPanel({
                                  resourceType: SIDE_PANEL_TYPES.TIME_OFF,
                                  resourceId: timeOff.id!,
                                  isViewOnlyMode: false,
                                })
                              )
                            }
                          />
                        }
                      >
                        {intl.get('EDIT')}
                      </Tooltip>
                      <Tooltip
                        openMode='hover1'
                        timeout={0}
                        showPopper
                        position='topCenter'
                        contentProps={{
                          className: 'px-3 py-2 text-sm z-50 absolute',
                        }}
                        usePortal
                        trigger={
                          <IconButton
                            name='trash'
                            iconClassname='pointer-events-none'
                            aria-label={intl.get('REMOVE')}
                            data-cy='remove-time-off-button'
                            className={tailwindOverride(
                              'text-base text-neutral-dark p-1 ml-2 rounded',
                              'hover:bg-neutral-lightest hover:shadow-allocation-table'
                            )}
                            onClick={() => handleRemoveTimeOff(timeOff.id!)}
                          />
                        }
                      >
                        {intl.get('REMOVE')}
                      </Tooltip>
                    </div>
                  ),
                  className: 'w-24',
                },
              ],
            };
          }),
        }}
        emptyComponent={
          <div className='w-full flex items-center justify-center p-4'>
            <Typography variant='label' className='text-neutral-dark'>
              {intl.get('USER_TIME_OFF_PAGE.TABLE.EMPTY')}
            </Typography>
          </div>
        }
      />
    </>
  );
};

export default TimeOffTable;
