import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectUserId } from 'state/User/userSlice';

const useGetUserId = () => {
  const { userId = undefined } = useParams<{ userId: string }>();
  const currentUserId = useSelector(selectUserId);

  if (userId) {
    return userId;
  }

  return currentUserId;
};

export default useGetUserId;
