import React from 'react';
import { DailyCapacityProps } from 'utils/types/dailyTeamsCapacity';
import AllocationSummary from '../../../../CapacityTable/components/AllocationSummary/AllocationSummary';
import AllocationSummaryTooltip from '../../../../CapacityTable/components/AllocationSummaryTooltip/AllocationSummaryTooltip';
import CapacityBreakdown from '../../../../CapacityTable/components/CapacityBreakdown/CapacityBreakdown';

const TeamDailyCapacity: React.FC<DailyCapacityProps> = ({
  assignedHours,
  dailyCapacity,
  timeOff,
}) => {
  return (
    <div className='w-full h-14 flex items-center px-2'>
      <AllocationSummaryTooltip
        trigger={
          <div>
            <AllocationSummary
              assignedHours={assignedHours}
              defaultCapacity={dailyCapacity}
              timeOff={timeOff}
            />
          </div>
        }
      >
        <CapacityBreakdown
          assignedHours={assignedHours}
          defaultCapacity={dailyCapacity}
          timeOff={timeOff}
        />
      </AllocationSummaryTooltip>
    </div>
  );
};

export default TeamDailyCapacity;
