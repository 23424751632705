import {
  createAsyncThunk,
  createSlice,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import moment from 'moment';
import TimeOffAPI from './timeOffApi';
import { RootState } from 'state/store';
import { getCapacity } from 'state/Capacity/capacitySlice';
import { formatFilters } from 'state/Projects/helpers';
import {
  Status,
  TimeOff,
  TimeOffData,
  filter,
  SortingType,
} from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import {
  filterTimeOffs,
  sortTimeOffsBy,
  convertTimeOffToFormData,
} from './helpers';
import orderBy from 'lodash/orderBy';

interface timeOffState {
  timeOffs: TimeOffData[];
  status: Status;
  timeOffIdToUpdate: string | null;
  table: {
    filters: filter[];
    pagination: {
      limit: number;
      offset: number;
    };
    sorting: {
      orderBy: string;
      order: SortingType;
    };
    searchParam: string;
  };
}

/* ============================= INITIAL STATE ============================== */
const initialState: timeOffState = {
  timeOffs: [],
  status: 'idle',
  timeOffIdToUpdate: null,
  table: {
    filters: [],
    pagination: {
      limit: 15,
      offset: 0,
    },
    sorting: {
      order: 'asc',
      orderBy: '',
    },
    searchParam: '',
  },
};

/* ================================ ACTIONS ================================= */
export const setFilters = createAction(
  'timeOff/SET_FILTERS',
  (filters: filter[]) => {
    const formatedFilters = formatFilters(filters);
    return { payload: { formatedFilters } };
  }
);

export const setSearchParam = createAction(
  'timeOff/SET_SEARCH_PARAM',
  (searchParam: string) => {
    return { payload: { searchParam } };
  }
);

export const setPagination = createAction(
  'timeOff/SET_PAGINATION',
  (pagination) => {
    return { payload: { pagination } };
  }
);

export const setSorting = createAction('timeOff/SET_SORTING', (sorting) => {
  return { payload: { sorting } };
});

export const setTimeOffIdToUpdate = createAction(
  'timeOff/SET_TIME_OFF_ID_TO_UPDATE',
  (timeOffId: string | null) => {
    return { payload: { timeOffId } };
  }
);

/* ============================== REDUX THUNK =============================== */
export const userCreateTimeOff = createAsyncThunk(
  'timeOff/CREATE_TIMEOFF',
  async (newTimeOff: TimeOff, { dispatch, getState }) => {
    const timeOff = { ...newTimeOff };
    if (!newTimeOff.userId) {
      const state = getState() as RootState;
      timeOff.userId = state.currentUser.value.id;
    }
    const { data, code } = await TimeOffAPI.createTimeOff(timeOff);
    if (code !== 200) {
      throw new Error('Could not create time off');
    }
    dispatch(getCapacity());
    return data;
  }
);

export const getUserTimeOffs = createAsyncThunk(
  'timeOff/GET_USER_TIME_OFFS',
  async (userId: string) => {
    const { data, code } = await TimeOffAPI.fetchUserTimeOffs(userId);
    if (code !== 200) {
      throw new Error('Could not fetch time off');
    }
    return data;
  }
);

export const updateUserTimeOff = createAsyncThunk(
  'timeOff/UPDATE_TIME_OFF',
  async (timeOff: TimeOff, { dispatch }) => {
    const { id, timeOffType, startDate, endDate } = timeOff;
    const { data, code } = await TimeOffAPI.updateTimeOff(id!, {
      timeOffType,
      startDate: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
    });
    if (code !== 200) {
      throw new Error('Could not update time off');
    }
    dispatch(getCapacity());
    return data;
  }
);

export const deleteUserTimeOff = createAsyncThunk(
  'timeOff/DELETE_USER_TIME_OFF',
  async (timeOffId: string, { dispatch }) => {
    const { code } = await TimeOffAPI.deleteTimeOff(timeOffId);
    if (code !== 200) {
      throw new Error('Could not delete time off data');
    }
    dispatch(getCapacity());
    return timeOffId;
  }
);

/* ================================= REDUCER ================================ */
const timeOffSlice = createSlice({
  name: 'timeOff',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userCreateTimeOff.pending, (state, action) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(userCreateTimeOff.rejected, (state, action) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(userCreateTimeOff.fulfilled, (state, action) => {
        state.timeOffs = [...state.timeOffs, action.payload];
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(getUserTimeOffs.pending, (state, action) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getUserTimeOffs.rejected, (state, action) => {
        state.timeOffs = [];
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(getUserTimeOffs.fulfilled, (state, action) => {
        state.timeOffs = action.payload;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(updateUserTimeOff.pending, (state, action) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(updateUserTimeOff.rejected, (state, action) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(updateUserTimeOff.fulfilled, (state, action) => {
        const updatedTimeOff = action.payload as TimeOffData;
        state.timeOffs = state.timeOffs.map((timeOff: TimeOffData) => {
          if (timeOff.id === updatedTimeOff.id) {
            return updatedTimeOff;
          } else {
            return timeOff;
          }
        });
      })
      .addCase(deleteUserTimeOff.pending, (state, action) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(deleteUserTimeOff.rejected, (state, action) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(deleteUserTimeOff.fulfilled, (state, action) => {
        state.timeOffs = state.timeOffs.filter(
          (timeOff) => timeOff.id !== action.payload
        );
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(setFilters, (state, action) => {
        state.table.filters = action.payload.formatedFilters;
      })
      .addCase(setSearchParam, (state, action) => {
        state.table.searchParam = action.payload.searchParam;
      })
      .addCase(setPagination, (state, action) => {
        state.table.pagination = action.payload.pagination;
      })
      .addCase(setSorting, (state, action) => {
        state.table.sorting = action.payload.sorting;
      })
      .addCase(setTimeOffIdToUpdate, (state, action) => {
        state.timeOffIdToUpdate = action.payload.timeOffId;
      });
  },
});

/* =============================== SELECTORS ================================ */
export const selectTimeOffs = (state: RootState) => state.timeOff.timeOffs;

export const selectTimeOffOrderedByStartDate = (state: RootState) =>
  orderBy(state.timeOff.timeOffs, 'start_date');

export const selectTimeOffDeletionStatus = (state: RootState) =>
  state.timeOff.status;
export default timeOffSlice.reducer;

export const selectFilters = (state: RootState) => state.timeOff.table.filters;

export const selectTimeOffsTable = createSelector(
  [(state: RootState) => state.timeOff.table, selectTimeOffs],
  (tableData, timeOffs) => {
    const { filters, searchParam, sorting, pagination } = tableData;
    const filteredTimeOffs = filterTimeOffs(timeOffs, filters, searchParam);
    const orderedTimeOffs = sortTimeOffsBy(filteredTimeOffs, sorting);
    return {
      data: orderedTimeOffs.slice(pagination.offset, pagination.limit),
      total: filteredTimeOffs.length,
    };
  }
);

export const selectTimeOffById = createSelector(
  [
    (state: RootState) => state.timeOff.timeOffs,
    (state: RootState) => state.timeOff.timeOffIdToUpdate,
  ],
  (timeOffs: TimeOffData[], timeOffId: string | null) => {
    let response = {} as TimeOff;
    if (timeOffId !== null) {
      const selectedTimeOff = timeOffs.find(
        (timeOff) => timeOff.id === timeOffId
      );
      if (selectedTimeOff) {
        response = convertTimeOffToFormData(selectedTimeOff);
      }
    }
    return response;
  }
);
