import FiltersPanel from 'Pages/InsightsPage/Projects/FiltersPanel/FiltersPanel';
import TotalProjects from './TopSection/TotalProjectsCard';
import ProjectStatusCard from './TopSection/ProjectStatusCard';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  fetchGeneralInsights,
  getGeneralInsights,
  getActiveFiltersQueryParams,
} from 'state/Insights/ProjectsSlice';
import { Card } from 'Pages/InsightsPage/Components/Card';
import ByPriority from 'Pages/InsightsPage/Projects/ByPriority/ByPriority';
import ByHealth from './ByHealth/ByHealth';
import { ByProcess } from 'Pages/InsightsPage/Projects/ByProcess/ByProcess';
import { ByStatus } from 'Pages/InsightsPage/Projects/ByStatus/ByStatus';
import { TasksByStatus } from 'Pages/InsightsPage/Projects/TasksByStatus/TasksByStatus';

const Projects = () => {
  const dispatch = useDispatch();
  const generalProjectsInsightsData = useSelector(getGeneralInsights);

  const activeFilters = useSelector(getActiveFiltersQueryParams);
  useEffect(() => {
    dispatch(fetchGeneralInsights(activeFilters));
  }, [dispatch, activeFilters]);

  return (
    <>
      <div className='flex h-insights w-full relative space-x-2'>
        <FiltersPanel />
        <div className='flex flex-col w-full'>
          <div
            style={{
              height: '100%',
              maxHeight: '12%',
            }}
            className='flex gap-2 w-full'
          >
            <Card className='w-full max-w-46'>
              <TotalProjects
                totalProjects={generalProjectsInsightsData.total}
                status={generalProjectsInsightsData.status}
              />
            </Card>
            <Card className='w-full'>
              <ProjectStatusCard
                generalProjectsInsightsData={generalProjectsInsightsData}
              />
            </Card>
          </div>

          <div
            style={{
              height: '100%',
              maxHeight: '35%',
            }}
            className='mt-3 w-full flex gap-2'
          >
            <Card className='w-1/2 px-4 pt-3 relative'>
              <ByPriority />
            </Card>

            <Card className='flex w-1/2'>
              <ByHealth />
            </Card>
          </div>

          <div className='h-full min-h-120 mt-2 w-full flex gap-2'>
            <Card className='w-2/5 pt-2.5 pl-3 pr-5 pb-5 h-full'>
              <ByProcess />
            </Card>

            <Card className='w-30-percent px-4 pt-4 pb-5'>
              <ByStatus />
            </Card>

            <Card className='w-35-percent px-2.5 pt-3 pb-5'>
              <TasksByStatus />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
