import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { USER_TYPES } from 'utils/constants';
import { USER_TABLE_FILTERS } from 'utils/constants/user';
import {
  AllUsersType,
  BusinessUser,
  LDUser,
  SortingType,
} from 'utils/customTypes';
import { UserFilters, UserFiltersKey } from 'utils/types/filters';

export const orderUsersBy = (
  users: AllUsersType[],
  column: string[],
  order: SortingType
) => {
  return orderBy(
    users,
    (user) => {
      return column.map((orderRoute) => {
        const label = `${get(user, orderRoute) || ''}`.trim();
        return label ? label.toLocaleLowerCase() : '';
      });
    },
    [order, order]
  );
};

const userMatchedSearch: (user: AllUsersType, search: string) => boolean = (
  user,
  search
) => {
  const name = `${user.data.firstName || ''} ${user.data.lastName || ''}`
    .trim()
    .toLocaleLowerCase();

  return name.includes(search) || user.data.email.includes(search);
};

const checkUserTeamFilter: (
  user: AllUsersType,
  filterValue: string[]
) => boolean = (user, filterValue) => {
  if (user.type === USER_TYPES.BUSINESS) {
    const team = (user as BusinessUser).businessTeam_id;
    return Boolean(team && filterValue.includes(team));
  } else if (user.type === USER_TYPES.L_D) {
    const teams = (user as LDUser).registeredLearningTeams.map(
      (team) => team.id
    );
    return filterValue.some((team) => teams.includes(team));
  } else {
    return false;
  }
};

const checkJobTitleFilter: (
  user: AllUsersType,
  filterValue: string[]
) => boolean = (user, filterValue) => {
  const jobFilter: string = filterValue[0].trim().toLocaleLowerCase();
  const jobTitle: string = user.data.jobTitle?.trim().toLocaleLowerCase() || '';

  return Boolean(jobTitle.includes(jobFilter));
};

const userMatchedAllFilters: (
  user: AllUsersType,
  filters: UserFilters
) => boolean = (user, filters) => {
  let matchedAllFilters = true;

  for (const filter of Object.keys(filters)) {
    const filterValue = filters[filter as UserFiltersKey];

    if (!isEmpty(filterValue)) {
      let userMatchesCurrentFilter: boolean = false;

      switch (filter) {
        case USER_TABLE_FILTERS.TEAM:
          userMatchesCurrentFilter = checkUserTeamFilter(
            user,
            filterValue as string[]
          );
          break;
        case USER_TABLE_FILTERS.JOB_TITLE:
          userMatchesCurrentFilter = checkJobTitleFilter(
            user,
            filterValue as string[]
          );
          break;
        default:
          const userValue = get(user, filter as keyof AllUsersType);
          userMatchesCurrentFilter = (filterValue as string[]).includes(
            userValue as string
          );
          break;
      }

      matchedAllFilters = matchedAllFilters && userMatchesCurrentFilter;
      if (!matchedAllFilters) {
        return matchedAllFilters;
      }
    } else {
      matchedAllFilters = false;
      return matchedAllFilters;
    }
  }

  return matchedAllFilters;
};

export const filterUsers: (
  users: AllUsersType[],
  search: string,
  filters: UserFilters
) => AllUsersType[] = (users, search, filters) => {
  let filteredUsers = users;
  const filtersActive = Object.keys(filters).length > 0;

  if (filtersActive || search) {
    filteredUsers = users.filter((user) => {
      let userMatchesSearch: boolean = false;
      let userMatchesFilters: boolean = false;

      if (search) {
        userMatchesSearch = userMatchedSearch(user, search);
      }

      if (filtersActive) {
        userMatchesFilters = userMatchedAllFilters(user, filters);
      }

      if (search && filtersActive) {
        return userMatchesFilters && userMatchesSearch;
      } else if (search) {
        return userMatchesSearch;
      } else if (filtersActive) {
        return userMatchesFilters;
      } else {
        return false;
      }
    });
  }

  return filteredUsers;
};
