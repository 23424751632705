import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import BasicInfoLoader from '../../Components/SkeletonLoaders/BasicInfoLoader';
import { INSIGHTS_PAGE_COLORS } from 'utils/constants';

const InReviewRequestsSkeletonLoader = () => {
  const skeletonColor = INSIGHTS_PAGE_COLORS.SKELETON_LOADER_COLOR;

  const mockInReviewRequestsData = [
    {
      name: '1 - 3 days',
      count: 5,
    },
    {
      name: '4 - 8 days',
      count: 2,
    },
    {
      name: '9 - 14 days',
      count: 7,
    },
    {
      name: '+15 days',
      count: 3,
    },
  ];
  return (
    <div className='flex flex-col w-full h-full'>
      <BasicInfoLoader />
      <div className='w-full h-full flex flex-col animate-pulse'>
        <ResponsiveContainer width='85%' height='80%'>
          <BarChart data={mockInReviewRequestsData} layout='vertical'>
            <XAxis
              tickLine={false}
              tick={false}
              type='number'
              domain={[0, 'dataMax + 10']}
              stroke={skeletonColor}
            />
            <YAxis
              tickLine={false}
              tick={false}
              stroke={skeletonColor}
              dataKey='name'
              type='category'
            />
            <Bar dataKey='count' maxBarSize={40} fill={skeletonColor} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default InReviewRequestsSkeletonLoader;
