import { LineChart, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { getLineElements } from 'Pages/InsightsPage/Intake/RequestsBy/Elements';
import { SKELETON_COLOR } from './constants';

const ChartSkeletonLoader = () => {
  const mockDCAData = [
    {
      name: new Date('2021-01-01'),
      Availability: 15,
      Demand: 10,
      Capacity: 20,
    },
    {
      name: new Date('2021-01-02'),
      Availability: 6,
      Demand: 5,
      Capacity: 10,
    },
    {
      name: new Date('2021-01-03'),
      Availability: 10,
      Demand: 20,
      Capacity: 30,
    },
  ];

  return (
    <div data-testid='chart-skeleton-loader'>
      <div className='bg-neutral-lighter animate-pulse w-40 h-4 mb-2'></div>
      <div className='bg-neutral-lighter animate-pulse w-40 h-4'></div>
      <ResponsiveContainer
        className='pt-6 animate-pulse min-h-10'
        width='95%'
        height='77%'
      >
        <LineChart margin={{ top: 8 }} data={mockDCAData}>
          <XAxis
            tickLine={false}
            tick={false}
            dataKey='name'
            padding={{ left: 40, right: 40 }}
            stroke={SKELETON_COLOR}
          />
          <YAxis
            tickLine={false}
            tick={false}
            stroke={SKELETON_COLOR}
            padding={{ top: 15 }}
          />
          {getLineElements(['Availability', 'Capacity', 'Demand'], false, true)}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartSkeletonLoader;
