import { useState, useEffect, useMemo } from 'react';
import NoObjetivesEvaluationState from '../components/NoObjetivesEvaluationState';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Prompt } from 'react-router-dom';
import { Location } from 'history';
import {
  FormItem,
  Dropdown,
  Button,
  Notification,
  Typography,
} from '@getsynapse/design-system';
import {
  EvaluationPlanItem,
  QuestionAnswer,
  NewCollectionMethod,
  UpdatedCollectionMethod,
} from 'utils/types/program';
import {
  selectEvaluationPlan,
  selectCollectionMethodsAndOptions,
  selectEvaluationSectionStatus,
  selectCollectionMethodsAndOptionsStatus,
  bulkCreateCollectionMethodsWithAnswers,
  updateCollectionMethodQuestionsAnswers,
  getEvaluationPlan,
  deleteCollectionMethodWithAnswers,
} from 'state/StrategyCategory/strategyCategorySlice';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import get from 'lodash/get';
import CollectionMethodPropertiesGroup from './CollectionMethodPropertiesGroup';
import {
  formatCollectionMethodsOptions,
  formatDataForQuestionUpdate,
  formatDataForOtherFieldUpdate,
  formatCollectionsToBeCreated,
  formatCollectionsToBeUpdated,
  getItemsToCreateUpdateDelete,
  getDefaultDataForNewMethod,
} from './helpers';
import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { OptionWithKey } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import EvaluationPlanSkeletonLoader from './EvaluationPlanSkeletonLoader';
import DeleteCollectionMethodModal from './DeleteCollectionMethodModal';
import LeavingUnsavedEvaluationModal from './LeavingUnsavedEvaluationModal';

const EvaluationPlan = ({
  hasObjectives,
  categoryName,
}: {
  hasObjectives: boolean;
  categoryName: string;
}) => {
  const dispatch = useDispatch();
  const evaluationPlanData = useSelector(selectEvaluationPlan);
  const evaluationPlanStatus = useSelector(selectEvaluationSectionStatus);
  const collectionMethodsAndOptions = useSelector(
    selectCollectionMethodsAndOptions
  );
  const collectionMethodsAndOptionsStatus = useSelector(
    selectCollectionMethodsAndOptionsStatus
  );

  const { programId, categoryId } = useParams<{
    programId: string;
    categoryId: string;
  }>();
  const { methodsOptions, methodQuestionsAndOptions } = useMemo(() => {
    return formatCollectionMethodsOptions(collectionMethodsAndOptions);
  }, [collectionMethodsAndOptions]);
  const [hasChanges, setHasChanges] = useState(false);
  const [selectedCollectionMethods, setSelectedCollectionMethods] = useState<
    OptionWithKey[]
  >([]);
  const history = useHistory();
  const [currentEvaluationPlanData, setCurrentEvaluationPlanData] =
    useState<EvaluationPlanItem[]>(evaluationPlanData);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [hasDataToBeRemoved, setHasDataToBeRemoved] = useState<boolean>(false);

  const [collectionsToBeCreated, setCollectionsToBeCreated] = useState<
    NewCollectionMethod[]
  >([]);

  const [collectionsToBeUpdated, setCollectionsToBeUpdated] = useState<
    UpdatedCollectionMethod[]
  >([]);

  const [collectionsToBeRemoved, setCollectionsToBeRemoved] = useState<
    string[]
  >([]);

  const [confirmedNavigation, setConfirmedNavigation] =
    useState<boolean>(false);
  const [leavingToLocation, setLeavingToLocation] = useState<string>('');
  const [isLeavingWarningModalOpen, setIsLeavingWarningModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    setCurrentEvaluationPlanData(evaluationPlanData);
  }, [evaluationPlanData]);

  useEffect(() => {
    setHasChanges(!isEqual(evaluationPlanData, currentEvaluationPlanData));
  }, [currentEvaluationPlanData, evaluationPlanData]);

  const onMethodsChange = (options: OptionWithKey[]) => {
    const currentMethodList = currentEvaluationPlanData.map(
      (method) => method.data_collection_method_id
    );
    const newMethodsList = options.map((option) => option.key);

    const methodsToRemove = currentMethodList.filter(
      (methodKey) => !newMethodsList.includes(methodKey)
    );
    const methodsToAdd = newMethodsList.filter(
      (methodKey) => !currentMethodList.includes(methodKey)
    );

    const newEvaluationPlanData = [...currentEvaluationPlanData];

    methodsToRemove.forEach((methodToRemove) => {
      const index = newEvaluationPlanData.findIndex(
        (method) => method.data_collection_method_id === methodToRemove
      );
      newEvaluationPlanData.splice(index, 1);
    });

    methodsToAdd.forEach((methodToAdd) => {
      const newMethod = getDefaultDataForNewMethod(
        methodsOptions,
        methodToAdd,
        methodQuestionsAndOptions,
        evaluationPlanData
      );
      newEvaluationPlanData.push(newMethod);
    });
    setCurrentEvaluationPlanData(newEvaluationPlanData);
  };

  const onQuestionUpdate = (methodId: string, value: QuestionAnswer) => {
    setCurrentEvaluationPlanData((prevData) =>
      formatDataForQuestionUpdate(methodId, value, prevData)
    );
  };

  const onUpdateOtherTextOfDataSource = (
    methodId: string,
    questionId: string,
    value: string | null
  ) => {
    setCurrentEvaluationPlanData((prevData) =>
      formatDataForOtherFieldUpdate(prevData, methodId, questionId, value)
    );
  };

  const submitChanges = async (
    collectionsToBeCreated: NewCollectionMethod[],
    collectionsToBeUpdated: UpdatedCollectionMethod[],
    collectionsToBeRemoved: string[]
  ) => {
    let areAllOpertaionsSuccessfull = true;

    if (collectionsToBeCreated.length) {
      const createResult = await dispatch(
        bulkCreateCollectionMethodsWithAnswers({
          programId,
          categoryId,
          dataCollectionMethods: collectionsToBeCreated,
        })
      );
      const createHasErrors =
        get(createResult, 'meta.rejectedWithValue') !== undefined;
      areAllOpertaionsSuccessfull =
        areAllOpertaionsSuccessfull && !createHasErrors;
      setCollectionsToBeCreated([]);
    }

    if (collectionsToBeUpdated.length) {
      const updateResults = await Promise.all(
        collectionsToBeUpdated.map(
          async (method) =>
            await dispatch(
              updateCollectionMethodQuestionsAnswers({
                programId,
                categoryId,
                collectionMethodAnswerId:
                  method.data_collection_method_answer_id,
                updatedQuestions: method.data,
              })
            )
        )
      );

      const updateHasErrors = updateResults.some(
        (updateResult) =>
          get(updateResult, 'meta.rejectedWithValue') !== undefined
      );

      areAllOpertaionsSuccessfull =
        areAllOpertaionsSuccessfull && !updateHasErrors;
      setCollectionsToBeUpdated([]);
    }

    if (collectionsToBeRemoved.length) {
      const deleteResults = await Promise.all(
        collectionsToBeRemoved.map(
          async (collectionId) =>
            await dispatch(
              deleteCollectionMethodWithAnswers({
                programId,
                categoryId,
                dataCollectionMethodId: collectionId,
              })
            )
        )
      );

      const deleteHasErrors = deleteResults.some(
        (deleteResult) =>
          get(deleteResult, 'meta.rejectedWithValue') !== undefined
      );

      areAllOpertaionsSuccessfull =
        areAllOpertaionsSuccessfull && !deleteHasErrors;
      setCollectionsToBeRemoved([]);
      if (hasDataToBeRemoved) {
        setHasDataToBeRemoved(false);
      }
    }

    if (areAllOpertaionsSuccessfull) {
      dispatch(getEvaluationPlan({ programId, categoryId }));
      dispatch(
        showSnackbarNotification({
          notificationVariant: 'success',
          notificationTitle: intl.get(
            'STRATEGY_DATA_COLLECTION.NOTIFICATION.SUCCESS_TITLE',
            { categoryName }
          ),
        })
      );
    } else {
      dispatch(
        showSnackbarNotification({
          notificationVariant: 'error',
          notificationTitle: intl.get(
            'STRATEGY_DATA_COLLECTION.NOTIFICATION.ERROR.TITLE'
          ),
          notificationMessage: intl.get(
            'STRATEGY_DATA_COLLECTION.NOTIFICATION.ERROR.MESSAGE'
          ),
        })
      );
    }
  };

  const formatDataAndSubmit = async () => {
    let formattedCollectionsToBeCreated: NewCollectionMethod[] = [];
    let formattedCollectionsToBeUpdated: UpdatedCollectionMethod[] = [];
    let collectionsToBeRemovedIds: string[] = [];

    const {
      collectionMethodToBeCreated,
      collectionMethodToBeUpdated,
      collectionMethodsToBeRemoved,
    } = getItemsToCreateUpdateDelete(
      evaluationPlanData,
      currentEvaluationPlanData
    );

    if (!isEmpty(collectionMethodToBeCreated)) {
      formattedCollectionsToBeCreated = formatCollectionsToBeCreated(
        currentEvaluationPlanData,
        collectionMethodToBeCreated
      );
      setCollectionsToBeCreated(formattedCollectionsToBeCreated);
    }

    if (!isEmpty(collectionMethodToBeUpdated)) {
      formattedCollectionsToBeUpdated = formatCollectionsToBeUpdated(
        currentEvaluationPlanData,
        collectionMethodToBeUpdated,
        evaluationPlanData
      );
      setCollectionsToBeUpdated(formattedCollectionsToBeUpdated);
    }

    let collectionsHaveDataToBeRemoved = false;

    if (!isEmpty(collectionMethodsToBeRemoved)) {
      const collectionsToBeRemovedWithData = evaluationPlanData.filter(
        (method) =>
          collectionMethodsToBeRemoved.includes(
            method.data_collection_method_id
          )
      );
      collectionsToBeRemovedIds = collectionsToBeRemovedWithData.map(
        (method) => method.id!
      );

      setCollectionsToBeRemoved(collectionsToBeRemovedIds);
      collectionsHaveDataToBeRemoved = collectionsToBeRemovedWithData.some(
        (method) => method.collection_method_selected_options.length
      );
      if (collectionsHaveDataToBeRemoved) {
        setHasDataToBeRemoved(collectionsHaveDataToBeRemoved);
      }
    }

    if (!collectionsHaveDataToBeRemoved) {
      submitChanges(
        formattedCollectionsToBeCreated,
        formattedCollectionsToBeUpdated,
        collectionsToBeRemovedIds
      );
    }
  };

  const isOriginalDataCollectionDataEmpty = isEmpty(evaluationPlanData);

  useEffect(() => {
    if (hasDataToBeRemoved && collectionsToBeRemoved.length) {
      setIsDeleteModalOpen(true);
    }
  }, [hasDataToBeRemoved, collectionsToBeRemoved]);

  useEffect(() => {
    const savedCollectionMethods = currentEvaluationPlanData.map(
      (collectionMethodAnswer) =>
        collectionMethodAnswer.data_collection_method_id
    );
    setSelectedCollectionMethods(
      methodsOptions.filter((option) =>
        savedCollectionMethods.includes(option.key)
      )
    );
  }, [currentEvaluationPlanData, methodsOptions]);

  const discardChanges = () => {
    setCurrentEvaluationPlanData(evaluationPlanData);
  };

  const isPlanSectionReadOnly = !hasObjectives;

  const handleBlockedNavigation = (location: Location) => {
    if (!confirmedNavigation) {
      setLeavingToLocation(location.pathname);
      setIsLeavingWarningModalOpen(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (confirmedNavigation && leavingToLocation) {
      history.push(leavingToLocation);
      setConfirmedNavigation(false);
      setLeavingToLocation('');
    }
  }, [confirmedNavigation, leavingToLocation, setConfirmedNavigation, history]);

  if (!hasObjectives && isOriginalDataCollectionDataEmpty) {
    return <NoObjetivesEvaluationState />;
  }

  if (
    evaluationPlanStatus === SLICE_STATUS.LOADING ||
    collectionMethodsAndOptionsStatus === SLICE_STATUS.LOADING
  ) {
    return <EvaluationPlanSkeletonLoader />;
  }

  return (
    <div className='w-full flex flex-col'>
      <DeleteCollectionMethodModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        submitChanges={() =>
          submitChanges(
            collectionsToBeCreated,
            collectionsToBeUpdated,
            collectionsToBeRemoved
          )
        }
      />
      <Prompt when={hasChanges} message={handleBlockedNavigation} />
      <LeavingUnsavedEvaluationModal
        isOpen={isLeavingWarningModalOpen}
        setIsOpen={setIsLeavingWarningModalOpen}
        onConfirm={() => setConfirmedNavigation(true)}
      />
      {!hasObjectives && (
        <Notification className='w-full mt-4' variant='warning'>
          {() => (
            <div className='flex flex-col'>
              <Typography variant='h6' className='pb-2 leading-7 font-semibold'>
                {intl.get(
                  'PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.NO_OBJECTIVES_TITLE'
                )}
              </Typography>
              <Typography className='text-neutral-darker' variant='h6'>
                {intl.get(
                  'PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.NO_OBJECTIVES_DESCRIPTION'
                )}
              </Typography>
            </div>
          )}
        </Notification>
      )}
      <FormItem
        label={intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.FIELDS.COLLECTION_METHOD'
        )}
        className='mt-6 w-1/2 mb-10'
        labelProps={{ className: 'text-base' }}
      >
        <Dropdown
          multiple
          readOnly={isPlanSectionReadOnly}
          placeholder={intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.FIELDS.COLLECTION_METHOD_PLACEHOLDER'
          )}
          values={selectedCollectionMethods}
          onChange={(options: OptionWithKey[]) => {
            onMethodsChange(options);
          }}
          options={methodsOptions}
          triggerProps={{
            className: 'py-0 items-center',
            'data-testid': 'evaluation-plan__data-collection-method',
          }}
        />
      </FormItem>
      {selectedCollectionMethods.map((method, index) => (
        <CollectionMethodPropertiesGroup
          method={method.label}
          questionsData={get(methodQuestionsAndOptions, method.key, [])}
          questionsAnswers={
            currentEvaluationPlanData.find(
              (answer) => answer.data_collection_method_id === method.key
            )?.collection_method_selected_options || []
          }
          key={index}
          readOnly={isPlanSectionReadOnly}
          onUpdateQuestion={(value: QuestionAnswer) => {
            onQuestionUpdate(method.key, value);
          }}
          onUpdateOtherTextOfDataSource={(value: string, questionId: string) =>
            onUpdateOtherTextOfDataSource(method.key, questionId, value)
          }
        />
      ))}
      {!isPlanSectionReadOnly && (
        <div className='flex border-t w-full border-neutral-lighter-two pt-6'>
          <Button
            className='w-24'
            variant='primary'
            onClick={formatDataAndSubmit}
            disabled={!hasChanges}
            data-testid='evaluation-plan__save-button'
          >
            {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.SAVE_PLAN')}
          </Button>
          <Button
            className='w-36 ml-4'
            variant='tertiary'
            onClick={discardChanges}
            disabled={!hasChanges}
            data-testid='evaluation-plan__discard-button'
          >
            {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.DICARD_CHANGES')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default EvaluationPlan;
