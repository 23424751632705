import {
  NewCollectionMethodAnswer,
  EvaluationPlanItem,
} from 'utils/types/program';

export const formatCollectionsToBeCreated = (
  data: EvaluationPlanItem[],
  collectionMethodToBeCreated: string[]
) => {
  return data
    .filter((method) =>
      collectionMethodToBeCreated.includes(method.data_collection_method_id)
    )
    .map((method) => {
      const data = [] as NewCollectionMethodAnswer[];
      method.collection_method_selected_options.forEach((answers) => {
        answers.questions_answers.forEach((answer) => {
          data.push({
            answer_id: answer.answer_preset_id,
            number_of_respondents: answer.number_of_respondents,
            data_source_other_text: answer.data_source_other_text,
            number_of_observers: answer.number_of_observers,
            number_observed: answer.number_observed,
            question_id: answers.question_id,
            desired_behaviours: answer.desired_behaviours,
          });
        });
      });

      return {
        data_collection_method_id: method.data_collection_method_id,
        data: data,
      };
    });
};
