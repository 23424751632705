import React from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import {
  Icon,
  Typography,
  OverflowMenu,
  OverflowMenuItem,
} from '@getsynapse/design-system';
import { setParticipantIdToRemove } from 'state/TasksAssignmentsResourcesCapacity/tasksAssignmentsResourcesCapacitySlice';

const ActionsMenu: React.FC<{
  userId: string;
}> = ({ userId }) => {
  const dispatch = useDispatch();
  const handleSelectOption = () => {
    dispatch(setParticipantIdToRemove(userId));
  };
  return (
    <OverflowMenu
      menuButtonProps={{
        'aria-label': `remove-participant-${userId}__trigger-button`,
        'data-cy': `remove-participant-${userId}__trigger-button`,
      }}
    >
      <OverflowMenuItem
        onSelect={() => handleSelectOption()}
        aria-label={`remove-participant-${userId}-button`}
        data-cy={`remove-participant-${userId}-button`}
        className='active:bg-neutral-lighter-two'
      >
        <div className='w-full h-full flex item-center'>
          <Icon name='trash' className='mr-2 text-lg text-error-dark' />
          <Typography variant='label' className='text-primary cursor-pointer'>
            {intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.REMOVE_PARTICIPANT')}
          </Typography>
        </div>
      </OverflowMenuItem>
    </OverflowMenu>
  );
};

export default ActionsMenu;
