import {
  Table,
  Typography,
  Icon,
  TableOperationHeader,
  Tag,
  tailwindOverride,
} from '@getsynapse/design-system';
import importantIcon from 'assets/icons/important.svg';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import FilterFacilitatorsPopup from './FilterFacilitatorsPopup/FilterFacilitatorsPopup';
import { Option } from 'utils/customTypes';
import classNames from 'classnames';
import {
  fetchFacilitators,
  selectFacilitatorsForDropdown,
} from 'state/Schedule/ScheduleSlice';
import debounce from 'lodash/debounce';

export const AddFacilitatorsSection = ({
  updateFacilitatorsIds,
}: {
  updateFacilitatorsIds: (rowsIds: string[]) => void;
}) => {
  const dispatch = useDispatch();

  const facilitators = useSelector(selectFacilitatorsForDropdown);

  const [appliedFilters, setAppliedFilters] = useState<{
    skills: Option[];
    languages: Option[];
    countries: Option[];
  }>({ skills: [], languages: [], countries: [] });
  const [selectedFacilitators, setSelectedFacilitators] = useState<string[]>(
    []
  );
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    dispatch(fetchFacilitators());
  }, [dispatch]);

  const updateAppliedFilters = useCallback(
    (
      selectedOptions: Option[] | null,
      type?: 'skills' | 'languages' | 'countries'
    ) => {
      const oldFilters = { ...appliedFilters };
      if (selectedOptions === null && type === undefined) {
        oldFilters.skills = [];
        oldFilters.languages = [];
        oldFilters.countries = [];
      } else if (type) {
        oldFilters[type] = selectedOptions!;
      }
      setAppliedFilters(oldFilters);
      const filters = {
        skills: oldFilters.skills.map((skill) => skill.value),
        languages: oldFilters.languages.map((language) => language.value),
        countries: oldFilters.countries.map((country) => country.value),
      };
      dispatch(fetchFacilitators({ search: searchValue, filters }));
    },
    [appliedFilters, dispatch, searchValue]
  );

  const debounceSearch = useMemo(
    () =>
      debounce((value: string) => {
        const filters = {
          skills: appliedFilters.skills.map((skill) => skill.value),
          languages: appliedFilters.languages.map((language) => language.value),
          countries: appliedFilters.countries.map((country) => country.value),
        };
        dispatch(fetchFacilitators({ search: value, filters }));
      }, 300),
    [appliedFilters, dispatch]
  );

  const handleSearch = useCallback(
    (value: string) => {
      setSearchValue(value);
      debounceSearch(value);
    },
    [debounceSearch]
  );

  return (
    <div id='fac-table' data-testid='fac-table'>
      <TableOperationHeader
        className='border-b-0'
        actionButtons={
          <FilterFacilitatorsPopup
            updateAppliedFilters={updateAppliedFilters}
            appliedFilters={appliedFilters}
            disabled={facilitators.length === 0}
          />
        }
        searchInputContainerClassName='w-48-percent'
        inputValue={searchValue}
        setInputValue={handleSearch}
      />
      <div
        className={classNames('w-full rounded-b', {
          'overflow-x-auto border border-neutral-lighter-two h-60':
            facilitators.length > 0,
        })}
      >
        <Table
          data-cy='facilitator-section'
          className={classNames('w-full', {
            'border-0': facilitators.length > 0,
          })}
          emptyComponent={
            <div className='flex justify-center items-center h-60 w-full'>
              <Icon src={importantIcon} className='h-6 w-6 mr-2' />
              <Typography>
                {intl.get(
                  'SCHEDULE.EVENTS.FACILITATORS_SECTION.NO_FACILITATORS'
                )}
              </Typography>
            </div>
          }
          selectedRowsIds={selectedFacilitators}
          isSelectRowCellSticky={true}
          numberOfHeadRows={0}
          data={{
            headData: [],
            rows: facilitators.map((data, index) => {
              const isOdd = index % 2;
              const isSelected = selectedFacilitators.includes(data.id!);
              return {
                id: data.id,
                cells: [
                  {
                    content: (
                      <div className='flex flex-col'>
                        <Typography variant='body2' className='truncate'>
                          {data.name}
                        </Typography>
                        <Typography
                          variant='body2'
                          className='text-neutral-light truncate'
                        >
                          {data.jobTitle}
                        </Typography>
                      </div>
                    ),
                    className: tailwindOverride(
                      'w-1/2 px-2 py-2 sticky left-14',
                      {
                        'bg-neutral-white': !isOdd && !isSelected,
                        'bg-neutral-lightest-two': isOdd && !isSelected,
                        'bg-secondary-lightest border-secondary-darker':
                          isSelected,
                      }
                    ),
                  },
                  {
                    content: (
                      <div className='flex flex-col'>
                        <Typography
                          variant='body2'
                          className='text-neutral-black truncate'
                        >
                          {data.province || ''}
                        </Typography>
                        <Typography
                          variant='body2'
                          className='text-neutral-light truncate'
                        >
                          {intl.get(`COUNTRIES.${data.country}`)}
                        </Typography>
                      </div>
                    ),
                    className: 'px-2 py-2 w-1/2',
                  },
                  ...(appliedFilters.skills.length > 0
                    ? [
                        {
                          content: (
                            <Tag
                              className='text-xs border border-transparent py-0.5 bg-purple-lightest group-hover:border-purple-dark'
                              textClassName='text-purple-darker overflow-auto max-w-full font-semibold'
                              label={data.matchedSkills
                                .map((skill: string) =>
                                  intl.get(`TEAMS.SKILLS.${skill}`)
                                )
                                .join(' - ')}
                            />
                          ),
                        },
                      ]
                    : []),
                  ...(appliedFilters.languages.length > 0
                    ? [
                        {
                          content: (
                            <Tag
                              className='text-xs border border-transparent py-0.5 bg-purple-lightest group-hover:border-purple-dark'
                              textClassName='text-purple-darker overflow-auto max-w-full font-semibold'
                              label={data.matchedLanguages
                                .map((language: string) =>
                                  intl.get(
                                    `LANGUAGES.${language.toLocaleLowerCase()}`
                                  )
                                )
                                .join(' - ')}
                            />
                          ),
                        },
                      ]
                    : []),
                ],
              };
            }),
          }}
          onSelectRows={(rowsIds: string[]) => {
            updateFacilitatorsIds(rowsIds);
            setSelectedFacilitators(rowsIds);
          }}
        />
      </div>
    </div>
  );
};

export default AddFacilitatorsSection;
