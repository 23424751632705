import { dtApi as api } from './api';
import axios from 'axios';
import { TaskAssignee, TaskProject } from './taskTypes';

export interface Task {
  id: string;
  name: string;
  displayId: string;
  organizationId: string;
  project: TaskProject;
  description: string;
  disabled: boolean;
  status: string;
  type: string;
  startDate: string;
  endDate: string;
  completedDate?: string;
  estimateHours: string;
  actualHours: string;
  assignees: TaskAssignee[];
}

export interface TaskDetailResponse {
  task: Task;
  permissions?: {
    canEdit?: boolean;
    canDelete?: boolean;
  };
}

export interface FetchTaskDetailParams {
  taskId: string;
}

export const fetchTask = async ({ taskId }: FetchTaskDetailParams) => {
  const url = `/v2/tasks/${taskId}`;
  const response = await api.get<TaskDetailResponse>(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};
