import { Modal, Typography } from '@getsynapse/design-system';
import { ModalProps } from '@getsynapse/design-system/dist/Organisms/Modal/Modal';
import intl from 'react-intl-universal';

type DeleteRequestConfirmationModalProps = {
  request: string;
  onConfirm: () => void;
} & ModalProps;

const DeleteRequestConfirmationModal = ({
  request,
  isOpen,
  onClose,
  onConfirm,
}: DeleteRequestConfirmationModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      actionButtons={[
        {
          children: intl.get(
            'REQUEST_PAGE.TOP_BAR.DELETE_REQUEST_MODAL.DELETE_BUTTON'
          ),
          variant: 'primary',
          color: 'danger',
          onClick: onConfirm,
          'data-cy': 'request-delete-button',
        },
        {
          children: intl.get(
            'REQUEST_PAGE.TOP_BAR.DELETE_REQUEST_MODAL.CANCEL_BUTTON'
          ),
          variant: 'secondary',
          onClick: onClose,
        },
      ]}
      title={intl.get('REQUEST_PAGE.TOP_BAR.DELETE_REQUEST_MODAL.TITLE')}
      closeModal={onClose}
      size='medium'
    >
      <div className='flex flex-col gap-6'>
        <Typography>
          {intl.getHTML('REQUEST_PAGE.TOP_BAR.DELETE_REQUEST_MODAL.WARNING')}
        </Typography>
        <Typography>
          {intl.getHTML(
            'REQUEST_PAGE.TOP_BAR.DELETE_REQUEST_MODAL.CONFIRMATION',
            {
              request,
            }
          )}
        </Typography>
      </div>
    </Modal>
  );
};

export default DeleteRequestConfirmationModal;
