import {
  Avatar,
  Tag,
  Typography,
  tailwindOverride,
} from '@getsynapse/design-system';
import { get } from 'lodash';
import intl from 'react-intl-universal';
import { SuggestionDataItem } from 'react-mentions';

export type UserDataSuggestion = {
  avatar?: { imageSrc: string; initial: string; name: string };
  disabled?: boolean;
} & SuggestionDataItem;

export type CustomSuggestionProps = {
  suggestion: UserDataSuggestion;
  search: string;
  highlightedDisplay: React.ReactNode;
  index: number;
  focused: boolean;
};

export const CustomSuggestion = ({
  suggestion,
  focused,
}: CustomSuggestionProps) => {
  return (
    <div
      className={tailwindOverride(
        'w-full flex items-center justify-between px-4 py-2',
        {
          'bg-neutral-black': focused,
        }
      )}
    >
      <div className='flex items-center'>
        <Avatar
          imageSrc={get(suggestion, 'avatar.imageSrc')}
          initial={get(suggestion, 'avatar.initial')}
          size='small'
          disabled={suggestion.disabled}
        />
        <Typography
          variant='body2'
          className={tailwindOverride('ml-2', {
            'text-neutral-black': !focused,
            'text-neutral-white': focused,
            'text-neutral-light': suggestion.disabled,
          })}
        >
          {suggestion.display}
        </Typography>
      </div>
      {suggestion.disabled && (
        <Tag
          label={intl.get('DEACTIVATED')}
          className='bg-neutral-lighter-two ml-2'
          textClassName='text-neutral-dark'
        />
      )}
    </div>
  );
};
