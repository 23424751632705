import { Table, Button } from '@getsynapse/design-system';
import moment from 'moment';
import intl from 'react-intl-universal';
import { getOwnersCell, getProgramStatusPill } from './helpers';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Program, SortingType } from 'utils/customTypes';
import { DATE, PROGRAM_FIELDS, PATHS, SLICE_STATUS } from 'utils/constants';
import {
  selectPaginatedPrograms,
  setProgramsSortingOptions,
  programsSearch,
  selectProgramsSliceStatus,
} from 'state/Programs/programsSlice';
import emptyProgramsTable from 'assets/images/programs-table-empty.svg';
import noSearchResults from 'assets/images/no-projects-found-table.svg';
import noFilterResults from 'assets/images/empty-filtered-projects-table.svg';
import isEmpty from 'lodash/isEmpty';
import TableEmptyErrorState from 'Molecules/TableEmptyErrorState/TableEmptyErrorState';
import RenderNoRecords from 'Pages/RequestsListPage/components/NoRecords';
import { formatProgramNumber } from 'utils/formatters';

interface ProgramsTableProps {
  onAddProgram: () => void;
  areFiltersApplied?: boolean;
}

const ProgramsTable: React.FC<ProgramsTableProps> = ({
  onAddProgram,
  areFiltersApplied = false,
}) => {
  const dispatch = useDispatch();
  const programs = useSelector(selectPaginatedPrograms);
  const sliceStatus = useSelector(selectProgramsSliceStatus);
  const searchValue = useSelector(programsSearch);

  const handleSort = (orderByParam: string, order: SortingType) => {
    dispatch(setProgramsSortingOptions(orderByParam, order));
  };
  const history = useHistory();

  return (
    <div className='max-h-programs-table-content overflow-auto w-full'>
      <Table
        className='w-full mb-5 relative'
        canSelectRows={false}
        isSelectRowCellSticky
        data-cy='programs-table-body'
        data={{
          headData: {
            stickyHeader: true,
            handleSort,
            rowProps: {
              className: 'w-full table-fixed h-auto min-h-10 z-2',
            },
            headCells: [
              {
                columnName: PROGRAM_FIELDS.PROGRAM_NUMBER,
                content: intl.get(
                  'PROGRAMS_LIST_PAGE.TABLE.COLUMNS.PROGRAM_NUMBER'
                ),
                className: 'font-semibold w-20 py-3',
                'data-cy': 'header__program-number',
              },
              {
                columnName: PROGRAM_FIELDS.TITLE,
                content: intl.get('PROGRAMS_LIST_PAGE.TABLE.COLUMNS.TITLE'),
                className: 'font-semibold max-w-94 py-3',
                'data-cy': 'header__program-title',
              },
              {
                columnName: PROGRAM_FIELDS.STATUS,
                content: intl.get('PROGRAMS_LIST_PAGE.TABLE.COLUMNS.STATUS'),
                className: 'font-semibold w-1/12 py-3',
                'data-cy': 'header__program-status',
              },
              {
                columnName: PROGRAM_FIELDS.OWNERS,
                content: intl.get('PROGRAMS_LIST_PAGE.TABLE.COLUMNS.OWNERS'),
                className: 'font-semibold w-1/6 py-3',
                'data-cy': 'header__program-owners',
              },
              {
                columnName: PROGRAM_FIELDS.DELIVERY,
                content: intl.get(
                  'PROGRAMS_LIST_PAGE.TABLE.COLUMNS.DELIVERY_TYPE'
                ),
                className: 'font-semibold w-1/6 py-3',
                'data-cy': 'header__program-delivery',
              },
              {
                columnName: PROGRAM_FIELDS.CREATION,
                content: intl.get('PROGRAMS_LIST_PAGE.TABLE.COLUMNS.CREATION'),
                className: 'font-semibold w-1/12 py-3',
                'data-cy': 'header__program-creation',
              },
            ],
          },
          rows: programs.map((program: Program) => {
            return {
              id: program.id,
              onClick: () =>
                history.push(`${PATHS.PROGRAM_PAGE}/${program.id}`),
              className: 'cursor-pointer w-full table-fixed',
              cells: [
                {
                  content: formatProgramNumber(program.program_number),
                  className: 'w-20',
                },
                {
                  content: program.title,
                  className: 'max-w-94 truncate',
                },
                {
                  content: getProgramStatusPill(program.status),
                  className: 'truncate w-1/12',
                },
                {
                  content: getOwnersCell(program.programOwners),
                  className: 'truncate w-1/6',
                },
                {
                  content: program.delivery_type
                    ? intl.get(
                        `PROGRAMS_LIST_PAGE.DELIVERY_TYPES.${program.delivery_type?.toUpperCase()}`
                      )
                    : '-',
                  className: 'truncate w-1/6',
                },
                {
                  content: moment(new Date(program.createdAt)).format(
                    DATE.SHORT_FORMAT
                  ),
                  className: 'truncate w-1/12',
                },
              ],
            };
          }),
        }}
        emptyComponent={
          sliceStatus === SLICE_STATUS.FAILED ? (
            <TableEmptyErrorState />
          ) : !isEmpty(searchValue) || !areFiltersApplied ? (
            <RenderNoRecords
              imageSrc={
                !isEmpty(searchValue) ? noSearchResults : noFilterResults
              }
              className='h-empty-table-body'
              caption={intl.get('PROGRAMS_LIST_PAGE.TABLE.NO_RECORDS')}
            />
          ) : (
            <RenderNoRecords
              imageSrc={emptyProgramsTable}
              className='h-empty-table-body'
              caption={intl.get('PROGRAMS_LIST_PAGE.TABLE.EMPTY')}
              labelClassName='mt-0'
            >
              <Button className='mt-2 mx-auto' onClick={onAddProgram}>
                {intl.get('PROGRAMS_LIST_PAGE.TABLE.GET_STARTED')}
              </Button>
            </RenderNoRecords>
          )
        }
      />
    </div>
  );
};

export default ProgramsTable;
