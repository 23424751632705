import axios, { AxiosInstance } from 'axios';
import get from 'lodash/get';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { DailyCapacityPerWeek } from 'utils/types/dailyTeamsCapacity';

export interface FetchDailyTeamMembersCapacityApiResponse {
  id: string;
  dailyCapacities: {
    weeks: DailyCapacityPerWeek;
  };
}

class TeamMemberProjectsCapacityAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }
  fetchDailyTeamMemberProjectCapacity: (
    url: string
  ) => Promise<FetchDailyTeamMembersCapacityApiResponse> = async (url) => {
    const response =
      await this.instance.get<FetchDailyTeamMembersCapacityApiResponse>(url);
    if (axios.isAxiosError(response)) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
    return response.data;
  };
}

const teamMemberProjectsCapacityAPI = new TeamMemberProjectsCapacityAPI();
export default teamMemberProjectsCapacityAPI;
