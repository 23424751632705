import { IconButton } from '@getsynapse/design-system';
import classnames from 'classnames';
import usePagination from 'Hooks/usePagination';
import BackToFirstPage from 'assets/icons/back-to-first-page.svg';
import ForwardToLastPage from 'assets/icons/forward-to-last-page.svg';

type PageSliderProps = {
  total: number;
  pages: number;
  offset: number;
  limit: number;
  setOffset: (value: number) => void;
};

const PageSlider = ({
  total,
  pages,
  offset,
  limit,
  setOffset,
}: PageSliderProps) => {
  const paginationRange = usePagination({
    totalCount: total,
    currentPage: offset,
    pageSize: limit,
  });

  const handlePageChange = (isForward: boolean) => {
    if (isForward) {
      if (offset < pages) {
        setOffset(offset + 1);
      }
    } else {
      if (offset > 1) {
        setOffset(offset - 1);
      }
    }
  };

  return (
    <div className='flex justify-center mx-6'>
      <IconButton
        className='w-4 h-4 mr-4'
        src={BackToFirstPage}
        disabled={offset <= 1}
        onClick={() => setOffset(1)}
      />
      <IconButton
        className='w-4 h-4'
        name='chevron-back-outline'
        onClick={() => handlePageChange(false)}
        disabled={offset <= 1}
      />

      <div className='w-24 flex justify-center'>
        {paginationRange && paginationRange.length > 0 ? (
          paginationRange.map((item, key) => {
            const isPageNumber = Number.isInteger(item);
            return (
              <span
                key={key}
                className={classnames('pr-1', {
                  'text-secondary-dark underline font-bold': item === offset,
                  'text-neutral': item !== offset,
                  'cursor-pointer': isPageNumber,
                })}
                onClick={() => {
                  if (isPageNumber) {
                    setOffset(item);
                  }
                }}
              >
                {item}
              </span>
            );
          })
        ) : (
          <span>1</span>
        )}
      </div>

      <IconButton
        className='w-4 h-4'
        name='chevron-forward-outline'
        onClick={() => handlePageChange(true)}
        disabled={offset >= pages}
        data-cy='pagination__next-page-button'
      />
      <IconButton
        className='w-4 h-4 ml-4'
        src={ForwardToLastPage}
        disabled={offset >= pages}
        onClick={() => setOffset(pages)}
      />
    </div>
  );
};

export default PageSlider;
