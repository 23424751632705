import { useMemo, useState } from 'react';
import { Tabs, useElevation, Button } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import classNames from 'classnames';

import Loader from 'Molecules/Loader/Loader';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import UnsavedChangesPrompt from 'Organisms/UnsavedChangesPrompt/UnsavedChangesPrompt';
import BundleOverview from 'Pages/SettingsPage/Configurations/ProjectSettings/components/TaskBundlePage/components/BundleOverview';
import BuildBundle from 'Pages/SettingsPage/Configurations/ProjectSettings/components/TaskBundlePage/components/BuildBundle';
import DeleteTaskBundle from '../DeleteTaskBundle/DeleteTaskBundle';

import { DETAILS_PAGE_TABS_MAPPING } from 'utils/constants';
import useNavigation from 'Hooks/useNavigation';
import { useTaskBundleOperations } from 'Hooks/TaskBundles';

const TaskBundlePage = () => {
  const {
    activeBundleStatus,
    localTaskBundle,
    localTaskTemplates,
    updateLocalTaskBundle,
    setLocalTaskTemplates,
    canSave,
    hasChanges,
    onSaveBundle,
    setModifiedTemplatesIds,
    isEditing,
    isCreating,
    isCloning,
  } = useTaskBundleOperations();
  const { goToBackPage } = useNavigation();

  const queryParams = new URLSearchParams(window.location.search);
  const activeTab = queryParams.get('activeTab');

  const footerElevation = useElevation(1);

  const [tabIndex, setTabIndex] = useState(
    activeTab ? DETAILS_PAGE_TABS_MAPPING[activeTab] : 0
  );

  const pageTitle: string = useMemo<string>(() => {
    if (isCreating) {
      return intl.get('SETTINGS_PAGE.TASK_BUNDLE_PAGE.ADD_TASK_BUNDLE');
    } else if (isCloning) {
      return intl.get('SETTINGS_PAGE.TASK_BUNDLE_PAGE.DUPLICATE_TASK_BUNDLE');
    } else {
      return localTaskBundle.name;
    }
  }, [isCloning, isCreating, localTaskBundle.name]);

  const tabData = useMemo(
    () => [
      {
        label: intl.get('SETTINGS_PAGE.TASK_BUNDLE_PAGE.BUNDLE_OVERVIEW_TAB'),
        content: (
          <BundleOverview
            data={localTaskBundle}
            isEditing={isEditing}
            handleFieldChange={updateLocalTaskBundle}
          />
        ),
      },
      {
        label: intl.get('SETTINGS_PAGE.TASK_BUNDLE_PAGE.BUNDLE_BUILD_TAB'),
        content: (
          <BuildBundle
            templates={localTaskTemplates}
            setTemplates={setLocalTaskTemplates}
            isEditing={isEditing}
            setNewAndUpdatedTemplates={setModifiedTemplatesIds}
          />
        ),
      },
    ],
    [
      isEditing,
      localTaskBundle,
      localTaskTemplates,
      setLocalTaskTemplates,
      setModifiedTemplatesIds,
      updateLocalTaskBundle,
    ]
  );

  return (
    <>
      {activeBundleStatus === 'loading' ? (
        <Loader />
      ) : (
        <div className='h-full flex flex-col shadow-skim'>
          <UnsavedChangesPrompt hasChanges={hasChanges} />
          <PageTitle titleComponent={pageTitle} />
          <DetailsPage
            topBar={isEditing && <DeleteTaskBundle data={localTaskBundle} />}
            bodyClassName='overflow-y-visible'
            content={
              <Tabs
                data={tabData}
                index={tabIndex}
                onChange={(index: number) => {
                  setTabIndex(index);
                  window.history.replaceState(
                    {},
                    '',
                    `${window.location.pathname}?activeTab=${
                      Object.keys(DETAILS_PAGE_TABS_MAPPING)[index]
                    }`
                  );
                }}
              />
            }
          />
          <div
            className={classNames(
              'w-full flex justify-end py-2 px-12',
              footerElevation
            )}
          >
            <Button variant='tertiary' className='mr-4' onClick={goToBackPage}>
              {intl.get('CANCEL')}
            </Button>
            <Button
              disabled={!canSave}
              className={classNames({ 'cursor-not-allowed': !canSave })}
              onClick={onSaveBundle}
              data-cy='save-task-bundle_button'
              data-testid='build-bundle-submit-button'
            >
              {isEditing
                ? intl.get('SETTINGS_PAGE.TASK_BUNDLE_PAGE.UPDATE')
                : intl.get('SAVE')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default TaskBundlePage;
