import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import {
  TeamMemberProjectsById,
  ProjectsByTeamMember,
  TeamMemberProjects,
} from 'utils/types/dailyTeamsCapacity';
import { addIdsToArrayIfNotExists } from '../helpers';

const emptyTeamMember: TeamMemberProjects = {
  projectsIds: [],
  links: { next: null },
};

export const toggleMemberFromProjectsByTeamMember: (
  byTeamMember: ProjectsByTeamMember<TeamMemberProjects>,
  teamId: string,
  memberId: string
) => ProjectsByTeamMember<TeamMemberProjects> = (
  byTeamMember,
  teamId,
  memberId
) => {
  const updatedByTeamMember = cloneDeep(byTeamMember);
  if (!(teamId in updatedByTeamMember)) {
    set(updatedByTeamMember, teamId, {
      [memberId]: emptyTeamMember,
      allProjectsIds: [],
    });
  } else {
    if (memberId in updatedByTeamMember[teamId]) {
      delete updatedByTeamMember[teamId][memberId];
    } else {
      set(updatedByTeamMember, teamId, {
        ...updatedByTeamMember[teamId],
        [memberId]: emptyTeamMember,
      });
    }
  }
  if (Object.keys(updatedByTeamMember[teamId]).length === 1) {
    delete updatedByTeamMember[teamId];
  }
  return updatedByTeamMember;
};

export const removeTeamFromProjectsById: (
  projectsById: TeamMemberProjectsById,
  projectsIds: string[],
  teamMemberId: string
) => {
  projectsIdsToRemove: string[];
  updatedProjectsById: TeamMemberProjectsById;
} = (projectsById, projectsIds, teamMemberId) => {
  const projectsIdsToRemove = [];
  const updatedProjectsById = cloneDeep(projectsById);
  for (const projectId of projectsIds) {
    if (projectId in updatedProjectsById) {
      const teamMembersIds = updatedProjectsById[projectId].teamMembersIds;
      if (teamMembersIds.length === 1) {
        projectsIdsToRemove.push(projectId);
        delete updatedProjectsById[projectId];
      } else {
        updatedProjectsById[projectId].teamMembersIds = teamMembersIds.filter(
          (id) => id !== teamMemberId
        );
      }
    }
  }
  return { projectsIdsToRemove, updatedProjectsById };
};

export const addProjectsToTeamMember: (
  byTeamMember: ProjectsByTeamMember<TeamMemberProjects>,
  teamId: string,
  memberId: string,
  projectsIds: string[],
  links: { next: string | null }
) => ProjectsByTeamMember<TeamMemberProjects> = (
  byTeamMember,
  teamId,
  memberId,
  projectsIds,
  links
) => {
  const updatedByTeamMember = cloneDeep(byTeamMember);
  const teamMemberProjects: TeamMemberProjects = {
    projectsIds: addIdsToArrayIfNotExists(
      projectsIds,
      updatedByTeamMember[teamId]?.[memberId]?.projectsIds || []
    ),
    links,
  };
  const allProjectsIds = addIdsToArrayIfNotExists(
    projectsIds,
    updatedByTeamMember[teamId]?.allProjectsIds || []
  );
  set(updatedByTeamMember, teamId, {
    ...updatedByTeamMember[teamId],
    [memberId]: teamMemberProjects,
    allProjectsIds,
  });
  return updatedByTeamMember;
};
