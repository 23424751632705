import React from 'react';
import TeamRow from '../TeamRow/TeamRow';
import TeamMembers from '../../../TeamMembers/TeamMembers';

const TeamRowAccordion: React.FC<{
  teamId: string;
  updateTeamRowInView: (teamId: string, inView: boolean) => void;
}> = ({ teamId, updateTeamRowInView }) => {
  return (
    <React.Fragment>
      <TeamRow teamId={teamId} updateTeamRowInView={updateTeamRowInView} />
      <TeamMembers teamId={teamId} />
    </React.Fragment>
  );
};

export default TeamRowAccordion;
