import React, { useState } from 'react';
import { tailwindOverride } from '@getsynapse/design-system';
import { ParticipantCapacity } from 'utils/types/tasksAssignmentsResourcesCapacity';
import { ParticipantTypes } from 'utils/constants';
import UserDetails from './UserDetails/UserDetails';
import AllocationsTimeline from './AllocationsTimeline/AllocationsTimeline';
import AssignedTasks from '../AssignedTasks/AssignedTasks';

const Participant: React.FC<{
  participantType: ParticipantTypes;
  capacity: ParticipantCapacity[string];
  className: string;
}> = ({ participantType, capacity, className }) => {
  const [openAssignedTasksList, setOpenAssignedTasksList] =
    useState<boolean>(false);

  const toggleAssignedTasksList = () =>
    setOpenAssignedTasksList((prev) => !prev);

  return (
    <>
      <div
        className={tailwindOverride('flex flex-1 h-full', className)}
        data-cy={`participant-${capacity.data.id}`}
      >
        <UserDetails
          isOwner={participantType === ParticipantTypes.OWNERS}
          user={capacity.data}
          toggleTaksSection={toggleAssignedTasksList}
          isTasksSectionOpen={openAssignedTasksList}
          hasTasksAssigned={capacity.tasks.length > 0}
        />
        <div className='w-3/5'>
          <AllocationsTimeline
            participantType={participantType}
            allocations={capacity.allocations}
            userId={capacity.data.id}
            className={className}
          />
        </div>
      </div>
      <div
        className={tailwindOverride(
          'overflow-hidden transition-height duration-300 easy',
          {
            'h-auto': openAssignedTasksList,
            'h-0': !openAssignedTasksList,
          }
        )}
      >
        <AssignedTasks
          tasks={capacity.tasks}
          userId={capacity.data.id}
          weeksArray={Object.keys(capacity.allocations)}
          className={className}
        />
      </div>
    </>
  );
};

export default Participant;
