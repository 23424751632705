import intl from 'react-intl-universal';
import { Typography, Tag } from '@getsynapse/design-system';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProgramStrategy,
  getProgramStrategy,
} from 'state/ProgramStrategy/programStrategySlice';
import StrategyCategoryCard from './components/StrategyCategoryCard';
import StrategyExamplesCard from 'Organisms/StrategyExamplesPanel/StrategyExamplesCard';
import { ROI_METHODOLOGIES } from 'utils/constants';
import { useEffect } from 'react';

type Props = {
  programId: string;
};

const ProgramStrategyPage = ({ programId }: Props) => {
  const dispatch = useDispatch();
  const programStrategy = useSelector(getProgramStrategy);

  useEffect(() => {
    dispatch(fetchProgramStrategy(programId));
  }, [dispatch, programId]);

  return (
    <div className='flex gap-4'>
      <div className='py-6 mb-6 pr-6 pl-6 border rounded-lg border-primary-lighter-two min-h-strategy flex-auto'>
        <div className='flex items-center space-x-2.5'>
          <Typography className='text-lg' data-testid='strategy-page__name'>
            {programStrategy.name
              ? programStrategy.name
              : intl.get('PROGRAM_PAGE.STRATEGY_PAGE.PROGRAM_STRATEGY')}
          </Typography>
          <Tag
            label={intl.get('PROGRAM_PAGE.STRATEGY_PAGE.BETA')}
            className='h-auto bg-purple-lightest py-0.5 px-2'
            textClassName='text-purple-darker leading-none font-semibold'
            data-testid='strategy-page__beta-tag'
          />
        </div>
        <Typography
          className='text-sm pt-2'
          data-testid='strategy-page__description'
        >
          {programStrategy.description}
        </Typography>

        <div className='mt-4 grid gap-4 grid-cols-2 w-full'>
          {programStrategy.categories.map((category) => (
            <StrategyCategoryCard
              key={category.id}
              category={category}
              programId={programId}
            />
          ))}
        </div>
      </div>
      <StrategyExamplesCard
        className='w-90 min-h-strategy flex-none'
        programId={programId}
        links={ROI_METHODOLOGIES.MAIN}
        showROIBootcamp
      />
    </div>
  );
};

export default ProgramStrategyPage;
