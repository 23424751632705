import {
  createAsyncThunk,
  createSlice,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import VendorsAPI from './vendorsAPI';
import { ProjectVendor, Status, SortingType } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import orderBy from 'lodash/orderBy';

interface Vendors {
  searchParam: string;
  vendors: ProjectVendor[];
  vendor?: ProjectVendor;
  status: Status;
  vendorsSorting: {
    orderBy: string;
    order: SortingType;
  };
}
/* ============================= INITIAL STATE ============================== */
const initialState: Vendors = {
  searchParam: '',
  vendors: [],
  vendor: undefined,
  status: SLICE_STATUS.IDLE,
  vendorsSorting: {
    order: 'asc',
    orderBy: '',
  },
};

/* ============================== REDUX THUNK =============================== */
export const getVendors = createAsyncThunk('vendors/GET_VENDORS', async () => {
  const response = await VendorsAPI.fetchVendors();
  return response.data.vendors;
});

export const addVendor = createAsyncThunk(
  'vendors/ADD_VENDOR',
  async (vendorData: Partial<ProjectVendor>) => {
    const response = await VendorsAPI.createVendor(vendorData);
    return response.data.vendor;
  }
);

export const getVendor = createAsyncThunk(
  'vendors/GET_VENDOR',
  async (vendorId: string) => {
    const { data } = await VendorsAPI.fetchVendor(vendorId);
    return data.vendor;
  }
);

export const changeVendor = createAsyncThunk(
  'vendors/EDIT_VENDOR',
  async (updateData: { vendorId: string; newData: Partial<ProjectVendor> }) => {
    const { data } = await VendorsAPI.editVendor(
      updateData.vendorId,
      updateData.newData
    );
    return data.vendor;
  }
);

/* ================================= REDUCER ================================ */
const vendorsSlice = createSlice({
  name: 'vendors',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVendors.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getVendors.fulfilled, (state, action) => {
        state.vendors = action.payload;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(getVendors.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(addVendor.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(addVendor.fulfilled, (state, action) => {
        state.vendors = [...state.vendors, action.payload];
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(addVendor.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(getVendor.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getVendor.fulfilled, (state, action) => {
        state.vendor = action.payload;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(getVendor.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(changeVendor.fulfilled, (state, action) => {
        state.vendor = action.payload;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(changeVendor.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(setVendorsOrder, (state, action) => {
        state.vendorsSorting.order = action.payload.order;
        state.vendorsSorting.orderBy = action.payload.orderBy;
      })
      .addCase(setSearchParam, (state, action) => {
        state.searchParam = action.payload.searchParam;
      });
  },
});

/* ================================ ACTIONS ================================= */
export const setVendorsOrder = createAction<{
  order: SortingType;
  orderBy: string;
}>('vendors/SET_VENDORS_ORDER');

export const setSearchParam = createAction<{ searchParam: string }>(
  'businessTeams/SET_SEARCH_PARAM'
);
/* =============================== SELECTORS ================================ */

export const selectVendors = (state: RootState) => state.vendors.vendors;
export const selectVendor = (state: RootState) => state.vendors.vendor;
export const selectStatus = (state: RootState) => state.vendors.status;
export const vendorsSorting = (state: RootState) =>
  state.vendors.vendorsSorting;
const vendorsSearchParam = (state: RootState) => state.vendors.searchParam;
export const selectOrderedVendors = createSelector(
  [selectVendors, vendorsSorting, vendorsSearchParam],
  (vendors, sorting, searchParam: string) => {
    let filteredVendors = vendors;
    if (searchParam) {
      filteredVendors = vendors.filter((vendor: ProjectVendor) => {
        const clearedVendorName = vendor.vendorName.trim().toLowerCase();
        const clearedSearchParam = searchParam.trim().toLowerCase();
        return clearedVendorName.includes(clearedSearchParam);
      });
    }
    return orderBy(filteredVendors, sorting.orderBy, [
      sorting.order,
      sorting.order,
    ]);
  }
);

export default vendorsSlice.reducer;
