import { useMemo, FC } from 'react';
import intl from 'react-intl-universal';
import orderBy from 'lodash/orderBy';
import { TASK_STATUS } from 'utils/constants';
import { Option } from 'utils/customTypes';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';

interface StatusFilterFieldProps {
  status: string[];
  onChange: (status: string[]) => void;
}

const StatusFilterField: FC<StatusFilterFieldProps> = ({
  status,
  onChange,
}) => {
  const statusOptions = useMemo(() => {
    const options = Object.keys(TASK_STATUS).map((key) => ({
      value: TASK_STATUS[key],
      label: intl.get(`TASKS.TASK_DETAIL_PAGE.STATUS_OPTIONS.${key}`),
    }));
    return orderBy(options, 'label');
  }, []);

  const selectedStatusOptions = statusOptions.filter((option: Option) =>
    status.includes(option.value)
  );

  return (
    <MultiSelectDropdown
      options={statusOptions}
      values={selectedStatusOptions}
      placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
      onChange={(options: Option[]) => {
        const newStatus = options.map((option: Option) => option.value);
        onChange(newStatus);
      }}
      triggerProps={{
        'aria-label': intl.get('TASK.STATUS'),
      }}
    />
  );
};

export default StatusFilterField;
