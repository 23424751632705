import { Checkbox } from '@getsynapse/design-system';
import { checkboxOption, intakeQuestionWrapper } from 'utils/customTypes';
import { useState, useEffect } from 'react';

const CheckboxQuestion = ({
  question,
  handler,
  disabled,
}: intakeQuestionWrapper) => {
  useEffect(() => {
    const newOptionsData = question.data.value.items.filter(
      (data: checkboxOption) => data.item
    );
    setOptions((prev) => {
      const hasChanges = prev.find(
        (option, index) => option.checked !== newOptionsData[index].checked
      );
      if (hasChanges) {
        return newOptionsData;
      }
      return prev;
    });
  }, [question]);

  const [options, setOptions] = useState<checkboxOption[]>(
    question.data.value.items.filter((data: checkboxOption) => data.item)
  );

  return (
    <div>
      {options.map((option: checkboxOption, index: number) => {
        return (
          <Checkbox
            key={option.index}
            label={option.item}
            className='pr-10'
            onChange={(event) => {
              const selectedValue = event.target.checked;
              setOptions((prev) => [
                ...prev.slice(0, index),
                { ...prev[index], checked: selectedValue },
                ...prev.slice(index + 1),
              ]);
              handler(
                question,
                selectedValue,
                `data.value.items[${index}].checked`
              );
            }}
            checked={options[index].checked}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

export default CheckboxQuestion;
