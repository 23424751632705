import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { UsersPicker } from '@getsynapse/design-system';
import {
  searchUsers,
  selectSearchAssignees,
  selectSearchAssigneesResult,
} from 'state/TasksList/Search/searchSlice';
import { debounce } from 'lodash';
import { TaskAssignee } from 'types/store/tasks';
import { UserOption } from '@getsynapse/design-system/dist/Molecules/UsersPicker/UsersPicker';

interface AssigneeFilterFieldProps {
  user: string[];
  onChange: (user: string[]) => void;
}

function getInitial(user: TaskAssignee) {
  return (
    user.name
      ?.split(' ')
      .map((name) => name[0])
      .join('') || 'NA'
  );
}

const AssigneeFilterField: FC<AssigneeFilterFieldProps> = ({
  user,
  onChange,
}) => {
  const users = useSelector(selectSearchAssignees);
  const userMap = useSelector(selectSearchAssigneesResult);

  const usersList =
    users?.map((u) => {
      const initial = getInitial(u);

      return {
        value: u.id,
        label: u.name,
        avatar: {
          imageSrc: u.avatarUrl,
          initial,
        },
      };
    }) || [];
  const onFilterChange = debounce(
    (assigneeName: string) => dispatch(searchUsers({ assigneeName })),
    500
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(searchUsers({}));
  }, [dispatch]);

  let selectedUsersList = useMemo(
    () =>
      user.map((u) => {
        const mappedUser = userMap[u] || {};

        const selectedOption: UserOption = {
          value: u,
          label: mappedUser.name || 'N/A',
          avatar: {
            imageSrc: mappedUser.avatarUrl,
            initial: getInitial(mappedUser),
          },
        };

        return selectedOption;
      }),
    [userMap, user]
  );

  return (
    <UsersPicker
      triggerText={intl.get('FILTER_GENERAL.PLACEHOLDER')}
      usersList={usersList}
      selectedUsersList={selectedUsersList}
      onFilterChange={onFilterChange}
      onChange={(selectedUsers: UserOption[]) => {
        const user = selectedUsers.map((user) => user.value);
        onChange(user);
      }}
      triggerProps={{
        'aria-label': intl.get('TASK.ASSIGNEE'),
      }}
    />
  );
};

export default AssigneeFilterField;
