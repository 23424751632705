import { Button, Tooltip, Typography, Icon } from '@getsynapse/design-system';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import {
  selectSidePanelData,
  setSidePanelUpdatedData,
} from 'state/SidePanel/sidePanelSlice';
import { PATHS } from 'utils/constants';
import {
  NewProject,
  TaskDetailType,
  UpdateReqData,
  TimeOff,
} from 'utils/customTypes';
import get from 'lodash/get';
import intl from 'react-intl-universal';

const PanelFooter = ({
  onUpdate,
  onClosePanel,
  disabled,
  onSubmitRequest,
  showSubmitButton,
  shouldDisableSubmitRequest,
  isRequestDetailFieldsValid,
  isAdding = false,
  updatedData,
}: {
  onUpdate: () => void;
  onClosePanel: (force: boolean) => void;
  disabled: boolean;
  onSubmitRequest?: () => void;
  showSubmitButton: boolean;
  shouldDisableSubmitRequest: boolean;
  isRequestDetailFieldsValid: boolean;
  isAdding?: boolean;
  updatedData: NewProject | UpdateReqData | TaskDetailType | TimeOff;
}) => {
  const sidePanel = useSelector(selectSidePanelData);
  const { state } = useLocation();
  const fromPage = get(state, 'from', '');
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div className='flex h-12 py-3 border-t border-primary-lighter absolute bottom-0 w-full items-center justify-end px-10'>
      <Button
        className='h-8'
        onClick={onUpdate}
        disabled={disabled}
        data-cy='quick-edit-update-button'
      >
        {isAdding ? intl.get('ADD') : intl.get('SIDE_PANEL.UPDATE_BUTTON')}
      </Button>
      {showSubmitButton &&
        (!isRequestDetailFieldsValid ? (
          <Tooltip
            trigger={
              <Button
                className='h-8 ml-4'
                onClick={onSubmitRequest}
                disabled={
                  shouldDisableSubmitRequest || !isRequestDetailFieldsValid
                }
              >
                {intl.get('SIDE_PANEL.SUBMIT_BUTTON')}
              </Button>
            }
            hasMaxWidth={false}
            contentProps={{
              className: 'p-4 text-sm z-50 flex bg-neutral-white',
            }}
            showPopper
            openMode='hover2'
            timeout={10}
            position='topLeft'
          >
            <Typography className='text-purple-darker w-56' variant='body2'>
              {intl.get('SIDE_PANEL.REQUEST_MISSING_FIELDS')}
            </Typography>
            <div
              className={classnames(
                'flex p-2 ml-2 h-8 text-purple-darker round justify-center items-center',
                'hover:bg-neutral-lightest hover:shadow-allocation-table cursor-pointer'
              )}
              onClick={() => {
                history.push(
                  `${PATHS.REQUEST_PAGE}/${sidePanel.resourceId}?fromSidePanel=true`,
                  {
                    from: fromPage ? fromPage : sidePanel.fromPage,
                  }
                );
                dispatch(setSidePanelUpdatedData(updatedData));
                onClosePanel(true);
              }}
            >
              <Icon
                name='resize'
                className='text-lg p-1 rounded text-primary-dark'
              />

              <Typography className='text-sm ml-2' variant='body2'>
                {intl.get('SIDE_PANEL.FULL_PAGE')}
              </Typography>
            </div>
          </Tooltip>
        ) : (
          <Button
            className='h-8 ml-4'
            onClick={onSubmitRequest}
            disabled={shouldDisableSubmitRequest || !isRequestDetailFieldsValid}
          >
            {intl.get('SIDE_PANEL.SUBMIT_BUTTON')}
          </Button>
        ))}
      <Button
        className='h-8 ml-4'
        variant='tertiary'
        onClick={() => onClosePanel(true)}
      >
        {intl.get('SIDE_PANEL.CANCEL_BUTTON')}
      </Button>
    </div>
  );
};

export default PanelFooter;
