import React from 'react';
import { tailwindOverride, Typography } from '@getsynapse/design-system';

const getStyleMap: (value: number) => {
  bg: string;
  text: string;
  border: string;
  leftBorder: string;
} = (value) => {
  const percentage = Math.round(value * 100);
  const style = {
    bg: 'bg-success-lightest',
    text: 'text-success-darkest',
    border: 'border-success-lighter',
    leftBorder: 'rgb(var(--color-success-dark))',
  };
  if (percentage >= 60 && percentage < 80) {
    style.bg = 'bg-warning-lightest-two';
    style.text = 'text-warning-darker';
    style.border = 'border-warning-lighter';
    style.leftBorder = 'rgb(var(--color-warning-darker))';
  } else if (percentage >= 80 && percentage < 100) {
    style.bg = 'bg-fire-lightest';
    style.text = 'text-fire-darker';
    style.border = 'border-fire-light';
    style.leftBorder = 'rgb(var(--color-fire-dark))';
  } else if (percentage >= 100) {
    style.bg = 'bg-error-lightest-two';
    style.text = 'text-error-darker';
    style.border = 'border-error-lightest';
    style.leftBorder = 'rgb(var(--color-error-dark))';
  }
  return style;
};

const CapacityPercentage: React.FC<{
  percentage: number;
}> = ({ percentage }) => {
  const style = getStyleMap(percentage);
  return (
    <div
      className={tailwindOverride(
        'w-full h-6 relative',
        'flex items-center justify-center',
        'cursor-pointer',
        'rounded',
        'border',
        style.border,
        style.bg
      )}
      style={{
        boxShadow: `0px 2px 2px 0px #12151B0D, 2px 0px 0px 0px ${style.leftBorder} inset`,
      }}
    >
      <Typography
        variant='label'
        weight='strong'
        className={tailwindOverride('cursor-pointer', style.text)}
      >
        {Intl.NumberFormat('en-US', { style: 'percent' }).format(percentage)}
      </Typography>
    </div>
  );
};

export default CapacityPercentage;
