import { WeeksAndDaysArray } from 'utils/types/dailyTeamsCapacity';

export const initDateFormatter: (
  locales?: string,
  options?: Intl.DateTimeFormatOptions
) => Intl.DateTimeFormat = (locales = 'en-US', options) =>
  new Intl.DateTimeFormat(locales, options);

export const getMondayOfTheWeek = (d: Date): Date => {
  const date = new Date(d);
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
};

export const getPrevWeekDay = (date: Date): Date => {
  const prevWeekDay = new Date(date.getTime());
  if (prevWeekDay.getDay() === 1) {
    prevWeekDay.setDate(prevWeekDay.getDate() - 3);
  } else if (prevWeekDay.getDay() === 0) {
    prevWeekDay.setDate(prevWeekDay.getDate() - 2);
  } else {
    prevWeekDay.setDate(prevWeekDay.getDate() - 1);
  }
  return prevWeekDay;
};

export const getNextWeekDay = (date: Date): Date => {
  const nextWeekDay = new Date(date.getTime());
  if (nextWeekDay.getDay() === 5) {
    nextWeekDay.setDate(nextWeekDay.getDate() + 3);
  } else if (nextWeekDay.getDay() === 6) {
    nextWeekDay.setDate(nextWeekDay.getDate() + 2);
  } else {
    nextWeekDay.setDate(nextWeekDay.getDate() + 1);
  }
  return nextWeekDay;
};

export const getNumberOfDaysBasedOnScreenSize: (
  screenSize: number
) => number = (screenSize) => {
  if (screenSize < 1280) {
    return 6;
  }
  if (screenSize < 1680) {
    return 8;
  }
  return 12;
};

export const getStartAndEndDateRange = (
  startDate?: string
): [string, string] => {
  const defaultDateFormatter = initDateFormatter('fr-CA');
  let start = new Date();
  if (startDate) {
    start = getMondayOfTheWeek(new Date(startDate.replace(/-/g, '/')));
  }
  let pivotDate = new Date(start.getTime());
  let daysCount = 11;
  while (daysCount > 0) {
    pivotDate = getNextWeekDay(pivotDate);
    daysCount -= 1;
  }
  return [
    defaultDateFormatter.format(start),
    defaultDateFormatter.format(pivotDate),
  ];
};

export const getWeeksAndDays: (
  startDate: string,
  endDate: string,
  numberOfDays?: number
) => WeeksAndDaysArray = (startDate, endDate, numberOfDays = 12) => {
  const weekAndDays: WeeksAndDaysArray = {};
  if (!startDate || !endDate) {
    return weekAndDays;
  }
  const dateFormatter = initDateFormatter('fr-CA');
  const start = new Date(startDate.replace(/-/g, '/'));
  const end = new Date(endDate.replace(/-/g, '/'));
  let pivotDate = new Date(start.getTime());
  let daysCount = numberOfDays;
  while (daysCount > 0) {
    const monday = getMondayOfTheWeek(pivotDate);
    const week = dateFormatter.format(monday);
    weekAndDays[week] = [];
    while (pivotDate.getDay() <= 5) {
      weekAndDays[week].push(dateFormatter.format(pivotDate));
      daysCount -= 1;
      if (daysCount === 0) {
        break;
      }
      if (pivotDate.getTime() === end.getTime()) {
        break;
      }
      pivotDate.setDate(pivotDate.getDate() + 1);
    }
    pivotDate = getNextWeekDay(pivotDate);
  }
  return weekAndDays;
};
