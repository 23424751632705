import { useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchWeeklyTeamsCapacity } from 'state/WeeklyTeamCapacity/TeamsCapacity/teamsCapacitySlice';
import {
  fetchWeeklyTeamMembersCapacity,
  removeTeamMembersCapacity,
} from 'state/WeeklyTeamCapacity/TeamMembersCapacity/teamMembersCapacitySlice';
import {
  fetchWeeklyTeamMemberProjectsCapacity,
  removeTeamMemberProjects,
} from 'state/WeeklyTeamCapacity/TeamMembersProjectCapacity/teamMemberProjectsCapacitySlice';
import { VIEW_SETTING_VALUES } from 'utils/constants/capacityView';
import { ScrollHandlerContextType } from '../../../CapacityTable/components/ScrollHandler/ScrollHandlerContext';

const useWeeklyCapacity: () => ScrollHandlerContextType = () => {
  const [rootId, setRootId] = useState<Element | null>(null);
  const dispatch = useDispatch();

  const fetchTeamsCapacity = useCallback(
    (teamIds: string[]) => dispatch(fetchWeeklyTeamsCapacity({ teamIds })),
    [dispatch]
  );

  const fetchTeamMembersCapacity = useCallback(
    (teamId: string, membersIds: string[]) =>
      dispatch(fetchWeeklyTeamMembersCapacity({ teamId, membersIds })),
    [dispatch]
  );

  const removeTeamMembersCapacityHandler = useCallback(
    (teamId: string, memberIds: string[]) =>
      dispatch(removeTeamMembersCapacity({ teamId, memberIds })),
    [dispatch]
  );

  const fetchTeamMembeProjectsCapacity = useCallback(
    (teamId: string, memberId: string, projectsIds: string[]) =>
      dispatch(
        fetchWeeklyTeamMemberProjectsCapacity({
          teamId,
          memberId,
          projectsIds,
        })
      ),
    [dispatch]
  );

  const removeTeamMemberProjectsHandler = useCallback(
    (teamId: string, memberId: string) =>
      dispatch(removeTeamMemberProjects({ teamId, memberId })),
    [dispatch]
  );

  return useMemo(
    () => ({
      rootId,
      setRootId,
      capacityView: VIEW_SETTING_VALUES.BETA_WEEKLY,
      fetchTeamsCapacity,
      fetchTeamMembersCapacity,
      fetchTeamMembeProjectsCapacity,
      removeTeamMembersCapacity: removeTeamMembersCapacityHandler,
      removeTeamMemberProjects: removeTeamMemberProjectsHandler,
    }),
    [
      rootId,
      setRootId,
      fetchTeamsCapacity,
      fetchTeamMembeProjectsCapacity,
      fetchTeamMembersCapacity,
      removeTeamMembersCapacityHandler,
      removeTeamMemberProjectsHandler,
    ]
  );
};

export default useWeeklyCapacity;
