import { Modal, Typography, FormItem } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

type CancelledRequestInfoModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reason: string;
  details?: string;
  ldComments?: string;
  cancellationDate: string;
};

const CancelledRequestInfoModal = ({
  isOpen,
  setIsOpen,
  reason,
  details,
  ldComments,
  cancellationDate,
}: CancelledRequestInfoModalProps) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      aria-label='cancelled-request-info-modal'
      closeModal={handleClose}
      isOpen={isOpen}
      title={intl.get('REQUEST_PAGE.CANCELLED_REQUEST_INFO_MODAL.TITLE')}
      size='medium'
      childrenClassName=''
    >
      <FormItem
        label={intl.get('REQUEST_PAGE.CANCELLED_REQUEST_INFO_MODAL.REASON')}
        labelProps={{ required: true }}
      >
        <Typography variant='body2'>{reason}</Typography>
      </FormItem>
      {details && (
        <FormItem
          label={intl.get(
            'REQUEST_PAGE.CANCELLED_REQUEST_INFO_MODAL.ADDITIONAL_INFO'
          )}
          className='mt-4'
        >
          <Typography variant='body2'>{details}</Typography>
        </FormItem>
      )}
      <Typography variant='h5' className='mt-4'>
        {intl.get('REQUEST_PAGE.CANCELLED_REQUEST_INFO_MODAL.INTERNAL_SECTION')}
      </Typography>
      <FormItem
        label={intl.get('REQUEST_PAGE.CANCELLED_REQUEST_INFO_MODAL.COMMENTS')}
        className='mt-4'
      >
        <Typography variant='body2'>
          {ldComments
            ? ldComments
            : intl.get('REQUEST_PAGE.CANCELLED_REQUEST_INFO_MODAL.NO_COMMENTS')}
        </Typography>
      </FormItem>
      <FormItem
        className='mt-6'
        label={intl.get(
          'REQUEST_PAGE.CANCELLED_REQUEST_INFO_MODAL.CANCELLATION_DATE'
        )}
      >
        <Typography variant='body2'>{cancellationDate}</Typography>
      </FormItem>
    </Modal>
  );
};

export default CancelledRequestInfoModal;
