import { Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

import NoTasksIcon from 'assets/images/empty-task-bundles-tasks.svg';

type EmptyTasksTableProps = {
  children?: React.ReactNode;
};

const EmptyTasksTable = ({ children }: EmptyTasksTableProps) => {
  return (
    <div
      data-cy='empty-table'
      data-testid='empty-table'
      className='bg-neutral-white flex justify-center items-center border-t-0 h-empty-table-body'
    >
      <div className='flex flex-col gap-2'>
        <img
          className='self-center -ml-8'
          src={NoTasksIcon}
          alt='No tasks found'
        />

        <span className='self-center'>
          <Typography>
            {intl.get(
              'SETTINGS_PAGE.TASK_BUNDLE_PAGE.BUILD_SECTION.GET_STARTED'
            )}
          </Typography>
        </span>

        <span className='self-center'>{children}</span>
      </div>
    </div>
  );
};

export default EmptyTasksTable;
