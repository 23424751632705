import {
  PROJECT_PRIVACY,
  PROJECT_STATUS,
  NEW_PROJECT_FORM_FIELDS,
} from 'utils/constants';
import { NewProject, objKeyAsString } from 'utils/customTypes';
import { isEqual, isEmpty, transform } from 'lodash';

export const getDifference = (newObject: object, baseObject: object) => {
  return transform(newObject, (result: objKeyAsString, value, key) => {
    if (!isEqual(value, baseObject[key])) {
      result[key] = newObject[key];
    }
  });
};

export const removeUnnecessaryStatusData = (
  newProject: objKeyAsString,
  baseProject: NewProject
): objKeyAsString => {
  let updatedProject: objKeyAsString = { ...newProject };
  if (
    newProject.status !== PROJECT_STATUS.ON_HOLD &&
    !isEmpty(baseProject.hold_reason)
  ) {
    updatedProject = { ...updatedProject, hold_reason: '' };
  }

  if (
    newProject.status !== PROJECT_STATUS.COMPLETED &&
    newProject.status !== PROJECT_STATUS.CLOSED &&
    !isEmpty(baseProject.actualDate)
  ) {
    updatedProject = { ...updatedProject, actualDate: null };
  }

  if (
    newProject.status !== PROJECT_STATUS.CANCELED &&
    !isEmpty(baseProject.cancel_reason)
  ) {
    updatedProject = { ...updatedProject, cancel_reason: '' };
  }
  return updatedProject;
};

export const getUpdatedProjectData = (
  newProject: NewProject,
  baseProject: NewProject
) => {
  const { id, customProperties, ...restProjectData } = newProject;
  const { id: baseProjectId, ...restBaseProject } = baseProject;
  let updatedProject = { ...restProjectData };
  let originalProject = { ...restBaseProject };
  if (newProject.status !== originalProject.status) {
    updatedProject = {
      ...updatedProject,
      ...removeUnnecessaryStatusData(updatedProject, originalProject),
    };
  }
  if (
    updatedProject.privacy === PROJECT_PRIVACY.PRIVATE &&
    baseProject.privacy === PROJECT_PRIVACY.PRIVATE
  ) {
    delete updatedProject[NEW_PROJECT_FORM_FIELDS.PROGRAMS];
  }

  const updatedProjectData = getDifference(updatedProject, baseProject);
  return { updatedProjectData };
};
