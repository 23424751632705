import {
  useState,
  FormEventHandler,
  ChangeEvent,
  useMemo,
  useEffect,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import Auth from '@aws-amplify/auth';
import { Button, TextField, Typography } from '@getsynapse/design-system';
import { PATHS } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import {
  selectUserBasicInfo,
  getUserBasicInfo,
  setRegistered,
} from 'state/User/userSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import PasswordTooltip from 'Atoms/PasswordTooltip/PasswordTooltip';

const PasswordSetup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const [data, setData] = useState({
    password: '',
    passwordConfirm: '',
    error: '',
    isSubmitting: false,
  });

  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const userSelector = useSelector(selectUserBasicInfo);

  useEffect(() => {
    if (userId) {
      dispatch(getUserBasicInfo(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (!userSelector) {
      history.push(PATHS.LOGIN);
    }
  }, [userSelector, history]);

  const strength = useMemo(() => {
    if (data.password) {
      const length = data.password.length;
      if (length >= 16) {
        return intl.get('CHANGE_PASSWORD_PAGE.PASSWORD_LEVEL.STRONG');
      } else if (length >= 12) {
        return intl.get('CHANGE_PASSWORD_PAGE.PASSWORD_LEVEL.MEDIUM');
      } else {
        return intl.get('CHANGE_PASSWORD_PAGE.PASSWORD_LEVEL.WEAK');
      }
    } else return '';
  }, [data.password]);

  const passwordMatch = useMemo(() => {
    return data.password && data.password === data.passwordConfirm;
  }, [data]);

  const confirmProps = useMemo((): {
    state?: 'success' | 'error';
    helpText?: string;
  } | void => {
    if (passwordMatch) {
      return {
        state: 'success',
        helpText: intl.get('CHANGE_PASSWORD_PAGE.INPUT.MATCHED'),
      };
    } else if (data.passwordConfirm) {
      return {
        state: 'error',
        helpText: intl.get('CHANGE_PASSWORD_PAGE.INPUT.NO_MATCH'),
      };
    }
  }, [passwordMatch, data.passwordConfirm]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    setData({ ...data, isSubmitting: true });
    const userInfo = {
      username: userSelector.email!.toLowerCase().trim(),
      password: data.password,
      attributes: {
        given_name: userSelector.firstName,
        family_name: userSelector.lastName,
      },
    };
    try {
      await Auth.signUp(userInfo);
      dispatch(setRegistered(userId));
      history.push(`${PATHS.ALMOST_THERE}/${userSelector.email!}`);
    } catch (error: any) {
      const message = get(error, 'message') || '';
      dispatch(
        showNotificationBanner({
          notificationVariant: 'error',
          notificationText: message,
          timeout: 7000,
        })
      );
      if (message) {
        setData({ ...data, error: message, isSubmitting: false });
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      error: '',
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className='max-w-xs'>
      <Typography
        variant='h2'
        className='mb-6 flex items-center justify-center'
      >
        {intl.get('PASSWORD_SETUP_PAGE.TITLE')}
      </Typography>

      <Typography className='mb-6'>
        {intl.getHTML('PASSWORD_SETUP_PAGE.PROMPT', {
          email: userSelector?.email,
        })}
      </Typography>

      <form onSubmit={handleSubmit}>
        <PasswordTooltip
          trigger={
            <TextField
              name='password'
              className='mb-8'
              label={intl.get('PASSWORD_SETUP_PAGE.INPUT.PASSWORD')}
              placeholder={intl.get(
                'CHANGE_PASSWORD_PAGE.INPUT.PASSWORD_PLACEHOLDER'
              )}
              onChange={handleChange}
              variant='password'
              value={data.password}
              state={data.error ? 'error' : undefined}
              helpText={
                strength && data.error
                  ? intl.get('CHANGE_PASSWORD_PAGE.INPUT.INCORRECT_FORMAT')
                  : strength && (
                      <Typography
                        className='text-neutral-black'
                        variant='caption'
                      >
                        <strong className='font-semibold'>
                          {intl.get(
                            'CHANGE_PASSWORD_PAGE.PASSWORD_LEVEL.STRENGTH'
                          )}
                        </strong>
                        {strength}
                      </Typography>
                    )
              }
            />
          }
          className='top-15 z-20'
          password={data.password}
          setIsPasswordValid={setIsPasswordValid}
        />

        <TextField
          name='passwordConfirm'
          label={intl.get('CHANGE_PASSWORD_PAGE.INPUT.CONFIRM')}
          placeholder={intl.get(
            'CHANGE_PASSWORD_PAGE.INPUT.CONFIRM_PLACEHOLDER'
          )}
          className='mb-10'
          onChange={handleChange}
          variant='password'
          value={data.passwordConfirm}
          {...confirmProps}
        />

        <Button
          type='submit'
          className='w-full justify-center'
          disabled={!passwordMatch || !isPasswordValid}
          loading={data.isSubmitting}
        >
          {intl.get('PASSWORD_SETUP_PAGE.BUTTON')}
        </Button>
      </form>
    </div>
  );
};

export default PasswordSetup;
