import { Typography, tailwindOverride } from '@getsynapse/design-system';
import React from 'react';
import { PROJECT_STATUS } from 'utils/constants';
import { formatter } from 'utils/functions';

type ProjectStatus = typeof PROJECT_STATUS[keyof typeof PROJECT_STATUS];

export const projectStatusColorsMap: Record<
  ProjectStatus,
  {
    border: string;
    text: string;
    boxShadow: string;
  }
> = {
  [PROJECT_STATUS.NEW]: {
    border: 'border-purple-light',
    text: 'text-purple-darker',
    boxShadow: 'rgb(var(--color-purple-dark))',
  },
  [PROJECT_STATUS.IN_PLANNING]: {
    border: 'border-warning-light',
    text: 'text-warning-darker',
    boxShadow: 'rgb(var(--color-warning-darker))',
  },
  [PROJECT_STATUS.IN_PROGRESS]: {
    border: 'border-teal-lighter',
    text: 'text-teal-dark',
    boxShadow: 'rgb(var(--color-teal-default))',
  },
  [PROJECT_STATUS.COMPLETED]: {
    border: 'border-success-light',
    text: 'text-success-darker',
    boxShadow: 'rgb(var(--color-success-dark))',
  },
  [PROJECT_STATUS.ON_HOLD]: {
    border: 'border-tertiary-lighter',
    text: 'text-tertiary-dark',
    boxShadow: 'rgb(var(--color-tertiary-default))',
  },
  [PROJECT_STATUS.CLOSED]: {
    border: 'border-secondary-light',
    text: 'text-secondary-darker',
    boxShadow: 'rgb(var(--color-secondary-dark))',
  },
};

const WeeklyCapacity: React.FC<{
  projectStatus: ProjectStatus;
  allocation: number;
}> = ({ projectStatus, allocation }) => {
  const style = projectStatusColorsMap[projectStatus];

  if (!style) {
    return null;
  }

  return (
    <div
      className={tailwindOverride(
        'flex flex-1 items-center justify-center',
        'h-6 border rounded-sm',
        'bg-neutral-white',
        style.border
      )}
      style={{
        boxShadow: `0px 2px 2px 0px #12151B0D, 2px 0px 0px 0px ${style.boxShadow} inset`,
      }}
    >
      <Typography
        variant='caption'
        weight='medium'
        className={style.text}
      >{`${formatter.format(allocation)}h`}</Typography>
    </div>
  );
};

export default WeeklyCapacity;
