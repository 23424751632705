import { Typography } from '@getsynapse/design-system';
import {
  MethodQuestionWithPresetValues,
  QuestionAnswer,
  DesiredBehavioursData,
} from 'utils/types/program';
import { OptionWithKey } from 'utils/customTypes';
import {
  EVALUATION_COLLECTION_QUESTIONS_ORDER,
  METHOD_QUESTIONS,
  DEFAULT_EMPTY_COLLECTION_METHOD_ANSWER,
} from 'utils/constants/program';
import CollectionPropertyQuestion from './CollectionPropertyQuestion';
import isEmpty from 'lodash/isEmpty';

const CollectionMethodPropertiesGroup = ({
  method,
  readOnly,
  questionsData,
  questionsAnswers,
  onUpdateQuestion,
  onUpdateOtherTextOfDataSource,
}: {
  method: string;
  readOnly: boolean;
  questionsData: MethodQuestionWithPresetValues[];
  questionsAnswers: QuestionAnswer[];
  onUpdateQuestion: (value: QuestionAnswer) => void;
  onUpdateOtherTextOfDataSource: (value: string, questionId: string) => void;
}) => {
  const isArrayOfOptionWithKey = <T extends OptionWithKey>(
    arr: any[]
  ): arr is T[] => {
    if (!Array.isArray(arr)) return false;
    if (arr.length === 0) return true;

    const firstItem = arr[0];
    if (!firstItem) return false;

    const optionKeys = Object.keys(firstItem) as Array<keyof OptionWithKey>;

    return arr.every(
      (item) =>
        typeof item === 'object' &&
        optionKeys.every(
          (key) => key in item && typeof item[key] === typeof firstItem[key]
        )
    );
  };

  const onUpdateField = (
    value: string | number | OptionWithKey[] | DesiredBehavioursData,
    questionId: string,
    questionName: string,
    currentAnswer?: QuestionAnswer
  ) => {
    let answers = {} as QuestionAnswer;
    let answer_id = '' as string | undefined;
    switch (questionName) {
      case METHOD_QUESTIONS.DATA_SOURCE:
      case METHOD_QUESTIONS.COLLECTION_TIMING:
      case METHOD_QUESTIONS.METHOD:
        if (Array.isArray(value) && isArrayOfOptionWithKey(value)) {
          answers = {
            question_id: questionId,
            question_name: questionName,
            questions_answers: value.map((option) => {
              const addedOption = currentAnswer?.questions_answers?.find(
                (answer) => answer.answer_preset_id === option.key
              );
              if (addedOption) {
                return addedOption;
              } else {
                return {
                  ...DEFAULT_EMPTY_COLLECTION_METHOD_ANSWER,
                  answer_id: currentAnswer?.questions_answers?.find(
                    (answer) => answer.answer_preset_id === option.key
                  )?.answer_id,
                  answer_preset_id: option.key,
                  answer_name: option.value,
                };
              }
            }),
          };
        }
        break;
      case METHOD_QUESTIONS.NUMBER_OF_RESPONDENTS:
      case METHOD_QUESTIONS.NUMBER_OBSERVED:
      case METHOD_QUESTIONS.NUMBER_OF_OBSERVERS:
        answer_id = currentAnswer?.questions_answers?.[0]?.answer_id;
        if (typeof value === 'string') {
          const parsedValue = parseInt(value);
          answers = {
            question_id: questionId,
            question_name: questionName,
            questions_answers: [
              {
                ...DEFAULT_EMPTY_COLLECTION_METHOD_ANSWER,
                answer_id,
                [questionName]: isNaN(parsedValue) ? null : parsedValue,
              },
            ],
          };
        }
        break;
      case METHOD_QUESTIONS.DESIRED_BEHAVIOURS:
        answer_id = currentAnswer?.questions_answers?.[0]?.answer_id;
        answers = {
          question_id: questionId,
          question_name: questionName,
          questions_answers: [
            {
              ...DEFAULT_EMPTY_COLLECTION_METHOD_ANSWER,
              answer_id,
              [questionName]: value as DesiredBehavioursData,
            },
          ],
        };
        break;
      default:
        break;
    }
    if (!isEmpty(answers)) {
      onUpdateQuestion(answers);
    }
  };

  return (
    <div className='border-t w-full border-neutral-lighter-two flex flex-col'>
      <Typography
        variant='h5'
        className='pb-2 leading-7 mt-6'
        data-testid='evaluation-plan__method-section-name'
      >
        {method}
      </Typography>
      <div className='w-full grid grid-cols-2 gap-x-10 gap-y-6 mt-4 pb-8'>
        {EVALUATION_COLLECTION_QUESTIONS_ORDER.map((questionName, index) => {
          const questionWithOptions = questionsData.find(
            (question) => question.question_name === questionName
          );
          const currentAnswer = questionWithOptions
            ? questionsAnswers.find(
                (answer) =>
                  answer.question_id === questionWithOptions?.question_id
              )
            : undefined;

          return (
            <CollectionPropertyQuestion
              key={index}
              questionName={questionName}
              readOnly={readOnly}
              questionWithOptions={questionWithOptions}
              currentAnswer={currentAnswer}
              onUpdateField={(value, questionId) => {
                onUpdateField(value, questionId, questionName, currentAnswer);
              }}
              onUpdateOtherTextOfDataSource={onUpdateOtherTextOfDataSource}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CollectionMethodPropertiesGroup;
