import { FC } from 'react';
import intl from 'react-intl-universal';
import { Icon, Tooltip, tailwindOverride } from '@getsynapse/design-system';
import disabledTask from 'assets/icons/disabled.svg';
import completedTask from 'assets/icons/success-checkmark.svg';

interface DisableColumnIconProps {
  taskId: string;
  isTaskDisabled: boolean;
  isTaskCompleted: boolean;
}

const DisableColumnIcon: FC<DisableColumnIconProps> = ({
  taskId,
  isTaskCompleted,
  isTaskDisabled,
}) => {
  if (isTaskDisabled) {
    return (
      <Tooltip
        trigger={
          <div>
            <Icon
              src={disabledTask}
              className='h-5 w-5'
              aria-label={`task-${taskId}__disabled-icon`}
            />
          </div>
        }
        openMode='hover2'
        ariaId='task-disabled'
        position='topCenter'
        timeout={0}
        showPopper
        contentProps={{
          className: tailwindOverride(
            'bg-neutral-dark',
            'text-neutral-white',
            'rounded',
            'font-body px-3.5 py-2 z-10'
          ),
        }}
      >
        <span>{intl.get('TASKS.TABLE.INACTIVE')}</span>
      </Tooltip>
    );
  }

  if (isTaskCompleted) {
    return (
      <Icon
        src={completedTask}
        className='h-5 w-5'
        aria-label={`task-${taskId}__completed-icon`}
      />
    );
  }

  return null;
};

export default DisableColumnIcon;
