import {
  createAsyncThunk,
  createSlice,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import {
  ApprovedRequestAnalytic,
  DeclinedRequestAnalytic,
  InReviewRequestAnalytic,
  RequestsBy,
  BusinessUnitFilterData,
  FormFilterData,
  OwnerFilterData,
  TypeFilterData,
  InsightsIntakeActiveFilter,
} from 'utils/customTypes';
import {
  requestsByDataFormatter,
  getInsightsDefaultDateFilter,
} from './helpers';
import { SLICE_STATUS, INSIGHTS_DATE_FILTER_NAME } from 'utils/constants';
import InsightsAPI from './InsightsAPI';
import { orderBy } from 'lodash';

interface ResponseRatio {
  status?: string;
  responseRatio?: number;
  ratioCompareToLastTerm?: number;
  avgResponseTime?: number;
}

interface ReceivedRequests {
  status?: string;
  requestsRecieved: number;
  ratioCompareToLastTerm?: number;
  mostCommonRequestForm: {
    formTitle: string;
    percent: number;
  };
  mostRequestingBusinessUnit: {
    businessTeamTitle: string;
    percent: number;
  };
}

interface ApprovedRequests {
  status?: string;
  requestsTotal: number;
  approvalRatio: number;
  ratioCompareToLastTerm: number;
  values: ApprovedRequestAnalytic[];
}

interface DeclinedRequests {
  status?: string;
  declinedRequests: number;
  declinedRatio: number;
  ratioCompareToLastTerm?: number;
  declinationByReason: DeclinedRequestAnalytic[];
  otherDeclinationByReason: DeclinedRequestAnalytic[];
  totalOthersCount?: number;
}

interface InReviewRequests {
  status?: string;
  requestsTotal: number;
  totalRequestsWaitingCancellation: number;
  values: InReviewRequestAnalytic[];
}

interface IntakeFilters {
  status?: string;
  businessUnitFilterValues: BusinessUnitFilterData[];
  formFilterValues: FormFilterData[];
  ownerFilterValues: OwnerFilterData[];
  typeFilterValues: TypeFilterData[];
}

interface InsightsState {
  responseRatio: ResponseRatio;
  receivedRequests: ReceivedRequests;
  approvedRequests: ApprovedRequests;
  declinedRequests: DeclinedRequests;
  requestsBy: RequestsBy;
  intakeFilters: IntakeFilters;
  filter: {
    filters: any;
    status?: string;
  };
  inReviewRequests: InReviewRequests;
  activeFilters: InsightsIntakeActiveFilter;
}

/* ============================= INITIAL STATE ============================== */

const initialState: InsightsState = {
  responseRatio: {
    status: SLICE_STATUS.IDLE,
    responseRatio: 0,
    ratioCompareToLastTerm: 8,
    avgResponseTime: 2,
  },
  receivedRequests: {
    status: SLICE_STATUS.IDLE,
    requestsRecieved: 0,
    ratioCompareToLastTerm: 0,
    mostCommonRequestForm: {
      formTitle: '',
      percent: 0,
    },
    mostRequestingBusinessUnit: {
      businessTeamTitle: '',
      percent: 0,
    },
  },
  approvedRequests: {
    status: SLICE_STATUS.IDLE,
    requestsTotal: 0,
    approvalRatio: 0,
    ratioCompareToLastTerm: 0,
    values: [],
  },
  declinedRequests: {
    status: SLICE_STATUS.IDLE,
    declinedRequests: 0,
    declinedRatio: 0,
    ratioCompareToLastTerm: 8,
    declinationByReason: [],
    otherDeclinationByReason: [],
    totalOthersCount: 0,
  },
  requestsBy: {
    status: SLICE_STATUS.IDLE,
    values: [],
    seriesNames: [],
  },
  intakeFilters: {
    status: SLICE_STATUS.IDLE,
    businessUnitFilterValues: [],
    formFilterValues: [],
    ownerFilterValues: [],
    typeFilterValues: [],
  },
  inReviewRequests: {
    status: SLICE_STATUS.IDLE,
    requestsTotal: 0,
    totalRequestsWaitingCancellation: 0,
    values: [],
  },
  filter: {
    filters: {},
  },
  activeFilters: {
    startDate: getInsightsDefaultDateFilter(INSIGHTS_DATE_FILTER_NAME.INTAKE),
    ownerIds: [],
    formIds: [],
    businessUnitIds: [],
    requestTypes: [],
  },
};

/* ============================== REDUX THUNK =============================== */
export const fetchResponseRatio = createAsyncThunk(
  'intake/FETCH_RESPONSE_RATIO',
  async (activeFilters: InsightsIntakeActiveFilter) => {
    const { data } = await InsightsAPI.fetchResponseRatios(activeFilters);
    return data;
  }
);

export const fetchDeclinedRequests = createAsyncThunk(
  'intake/FETCH_DECLINED_REQUESTS',
  async (activeFilters: InsightsIntakeActiveFilter) => {
    const { data } = await InsightsAPI.fetchDeclinedRequests(activeFilters);
    return data;
  }
);

export const fetchReceivedRequests = createAsyncThunk(
  'intake/FETCH_RECEIVED_REQUESTS',
  async (activeFilters: InsightsIntakeActiveFilter) => {
    const { data } = await InsightsAPI.fetchReceivedRequests(activeFilters);
    return data;
  }
);

export const fetchApprovedRequests = createAsyncThunk(
  'intake/FETCH_APPROVED_REQUESTS',
  async (activeFilters: InsightsIntakeActiveFilter) => {
    const { data } = await InsightsAPI.fetchApprovedRequests(activeFilters);
    return data;
  }
);

export const fetchInReviewRequests = createAsyncThunk(
  'intake/FETCH_IN_REVIEW_REQUESTS',
  async (activeFilters: InsightsIntakeActiveFilter) => {
    const { data } = await InsightsAPI.fetchInReviewRequests(activeFilters);
    return data;
  }
);

export const fetchAggregatesBy = createAsyncThunk(
  'intake/FETCH_AGGREGATES_BY',
  async ({
    filter,
    activeFilters,
  }: {
    filter: string;
    activeFilters: InsightsIntakeActiveFilter;
  }) => {
    const { data } = await InsightsAPI.fetchAggregatesBy(filter, activeFilters);
    return { data, isStatusPage: filter === 'status' };
  }
);

export const fetchFilters = createAsyncThunk(
  'intake/FETCH_FILTERS',
  async () => {
    const response = await InsightsAPI.fetchFilters();

    return response;
  }
);

export const setActiveFilters = createAction<InsightsIntakeActiveFilter>(
  'intake/SET_ACTIVE_FILTERS'
);

/* ================================= REDUCER ================================ */
const insightsSlice = createSlice({
  name: 'insights',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchResponseRatio.pending, (state) => {
        state.responseRatio.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchResponseRatio.rejected, (state) => {
        state.responseRatio.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchResponseRatio.fulfilled, (state, action) => {
        state.responseRatio.status = SLICE_STATUS.IDLE;
        state.responseRatio = action.payload;
      })
      .addCase(fetchReceivedRequests.pending, (state) => {
        state.receivedRequests.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchReceivedRequests.rejected, (state) => {
        state.receivedRequests.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchReceivedRequests.fulfilled, (state, action) => {
        state.receivedRequests = action.payload;
        state.receivedRequests.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchApprovedRequests.pending, (state) => {
        state.approvedRequests.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchApprovedRequests.rejected, (state) => {
        state.approvedRequests.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchApprovedRequests.fulfilled, (state, action) => {
        const {
          approvedRequests,
          approvalRatio,
          approvedRequestsByBusinessTeam,
        } = action.payload;

        const requestsByBusinessTeam = approvedRequestsByBusinessTeam.map(
          (item: {
            businessTeamTitle: string;
            requestCount: string;
            businessTeamId?: string;
          }) => {
            return {
              ...item,
              requestCount: parseInt(item.requestCount) || 0,
            };
          }
        );

        const sortedRequestsByBusinessTeam = orderBy(
          requestsByBusinessTeam,
          ['requestCount'],
          ['desc']
        ).slice(0, 4);

        state.approvedRequests = {
          status: SLICE_STATUS.IDLE,
          requestsTotal: approvedRequests ? parseInt(approvedRequests) : 0,
          approvalRatio: approvalRatio || 0,
          ratioCompareToLastTerm: 0,
          values: sortedRequestsByBusinessTeam || [],
        };
      })
      .addCase(fetchInReviewRequests.pending, (state) => {
        state.inReviewRequests.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchInReviewRequests.rejected, (state) => {
        state.inReviewRequests.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchInReviewRequests.fulfilled, (state, action) => {
        const {
          requestsInReview,
          requestsTimeInReview,
          requestsPendingCancellation,
        } = action.payload;

        state.inReviewRequests = {
          ...state.inReviewRequests,
          requestsTotal: requestsInReview || 0,
          totalRequestsWaitingCancellation: requestsPendingCancellation || 0,
          values: requestsTimeInReview,
          status: SLICE_STATUS.IDLE,
        };
      })
      .addCase(fetchAggregatesBy.pending, (state) => {
        state.requestsBy.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchAggregatesBy.rejected, (state) => {
        state.requestsBy.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchAggregatesBy.fulfilled, (state, action) => {
        const { data, isStatusPage } = action.payload;
        const { seriesNames, requestsBy } = requestsByDataFormatter(
          data,
          isStatusPage
        );
        state.requestsBy = {
          status: SLICE_STATUS.IDLE,
          values: requestsBy,
          seriesNames: seriesNames,
        };
      })
      .addCase(fetchDeclinedRequests.pending, (state) => {
        state.declinedRequests.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchDeclinedRequests.rejected, (state) => {
        state.declinedRequests.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchDeclinedRequests.fulfilled, (state, action) => {
        const formattedReasons = action.payload.declinationByReason.map(
          (reason: { declinationReason: string; requestCount: string }) => ({
            ...reason,
            requestCount: Number(reason.requestCount),
          })
        );
        const reOrderedBasedOnCount = formattedReasons.sort(
          (
            a: { declinationReason: string; requestCount: number },
            b: { declinationReason: string; requestCount: number }
          ) => b.requestCount - a.requestCount
        );
        const topThreeReasons = reOrderedBasedOnCount.slice(0, 3);
        const otherReasons = reOrderedBasedOnCount.slice(3);
        let totalOtherRequestCount = 0;
        if (otherReasons.length > 0) {
          const otherReasonsCount = otherReasons.reduce(
            (
              acc: number,
              reason: { declinationReason: string; requestCount: number }
            ) => acc + reason.requestCount,
            0
          );
          totalOtherRequestCount = otherReasonsCount;
        }
        state.declinedRequests = {
          status: SLICE_STATUS.IDLE,
          declinedRequests: Number(action.payload.declinedRequests),
          declinedRatio: Number(action.payload.declinedRatio),
          declinationByReason: topThreeReasons,
          otherDeclinationByReason: otherReasons.length > 0 ? otherReasons : [],
          totalOthersCount: totalOtherRequestCount,
        };
      })
      .addCase(fetchFilters.pending, (state) => {
        state.intakeFilters.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchFilters.rejected, (state) => {
        state.intakeFilters.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchFilters.fulfilled, (state, action) => {
        state.intakeFilters = action.payload;
        state.intakeFilters.status = SLICE_STATUS.IDLE;
      })
      .addCase(setActiveFilters, (state, action) => {
        state.activeFilters = action.payload;
      });
  },
});

/* =============================== SELECTORS ================================ */

export const selectInsights = (state: RootState) => state.intakeInsights;

export const getResponseRatio = createSelector(
  selectInsights,
  (state) => state.responseRatio
);

export const getApprovedRequestsAnalytics = createSelector(
  selectInsights,
  (state) => state.approvedRequests
);

export const getDeclinedRequestsAnalytics = createSelector(
  selectInsights,
  (state) => state.declinedRequests
);

export const getReceivedRequests = createSelector(
  selectInsights,
  (state) => state.receivedRequests
);

export const getRequestsBy = createSelector(
  selectInsights,
  (state) => state.requestsBy
);

export const getInReviewRequestsState = createSelector(
  selectInsights,
  (state) => state.inReviewRequests
);

export const getFilters = createSelector(
  selectInsights,
  (state) => state.intakeFilters
);

export const getActiveFiltersQueryParams = createSelector(
  selectInsights,
  (state) => {
    return state.activeFilters;
  }
);

export default insightsSlice.reducer;
