import {
  getMondayOfTheWeek,
  getNextWeekDay,
  getPrevWeekDay,
  initDateFormatter,
} from 'Pages/TeamsPage/views/TeamsCapacity/components/DailyCapacity/helpers';
import { WeeksAndDaysArray } from 'utils/types/dailyTeamsCapacity';

const defaultDateFormatter = initDateFormatter('fr-CA');

export const addItemToTheStart: (
  startDate: Date,
  weeksAndDaysArray: WeeksAndDaysArray
) => {
  newStartDate: string;
  updatedWeeksAndDaysArray: WeeksAndDaysArray;
} = (startDate, weeksAndDaysArray) => {
  const prevWeekDay = getPrevWeekDay(startDate);
  let updatedWeeksAndDaysArray = { ...weeksAndDaysArray };
  const newStartDate = defaultDateFormatter.format(prevWeekDay);
  const firstWeek = Object.keys(updatedWeeksAndDaysArray)[0];
  if (updatedWeeksAndDaysArray[firstWeek].length < 5) {
    updatedWeeksAndDaysArray[firstWeek] = [
      newStartDate,
      ...updatedWeeksAndDaysArray[firstWeek],
    ];
  } else {
    const startOfWeek = getMondayOfTheWeek(prevWeekDay);
    const newWeek = defaultDateFormatter.format(startOfWeek);
    updatedWeeksAndDaysArray = {
      [newWeek]: [newStartDate],
      ...updatedWeeksAndDaysArray,
    };
  }
  return {
    newStartDate,
    updatedWeeksAndDaysArray,
  };
};

export const addItemToTheEnd: (
  endDate: Date,
  weeksAndDaysArray: WeeksAndDaysArray
) => {
  newEndDate: string;
  updatedWeeksAndDaysArray: WeeksAndDaysArray;
} = (endDate, weeksAndDaysArray) => {
  let updatedWeeksAndDaysArray = { ...weeksAndDaysArray };
  const nextWeekDay = getNextWeekDay(endDate);
  const newEndDate = defaultDateFormatter.format(nextWeekDay);
  const lastWeek = Object.keys(updatedWeeksAndDaysArray).at(-1)!;
  if (updatedWeeksAndDaysArray[lastWeek].length < 5) {
    updatedWeeksAndDaysArray[lastWeek] =
      updatedWeeksAndDaysArray[lastWeek].concat(newEndDate);
  } else {
    const startOfWeek = getMondayOfTheWeek(nextWeekDay);
    const newWeek = defaultDateFormatter.format(startOfWeek);
    updatedWeeksAndDaysArray = {
      ...updatedWeeksAndDaysArray,
      [newWeek]: [newEndDate],
    };
  }
  return {
    newEndDate,
    updatedWeeksAndDaysArray,
  };
};

export const removeItemFromTheStart: (
  startDate: Date,
  weeksAndDaysArray: WeeksAndDaysArray
) => {
  newStartDate: string;
  updatedWeeksAndDaysArray: WeeksAndDaysArray;
} = (startDate, weeksAndDaysArray) => {
  let updatedWeeksAndDaysArray = { ...weeksAndDaysArray };
  const prevWeekDay = getNextWeekDay(startDate);
  const newStartDate = defaultDateFormatter.format(prevWeekDay);
  const firstWeek = Object.keys(updatedWeeksAndDaysArray)[0];
  if (updatedWeeksAndDaysArray[firstWeek].length > 1) {
    updatedWeeksAndDaysArray[firstWeek] =
      updatedWeeksAndDaysArray[firstWeek].slice(1);
  } else {
    delete updatedWeeksAndDaysArray[firstWeek];
  }
  return {
    newStartDate,
    updatedWeeksAndDaysArray,
  };
};

export const removeItemFromTheEnd: (
  endDate: Date,
  weeksAndDaysArray: WeeksAndDaysArray
) => {
  newEndDate: string;
  updatedWeeksAndDaysArray: WeeksAndDaysArray;
} = (endDate, weeksAndDaysArray) => {
  let updatedWeeksAndDaysArray = { ...weeksAndDaysArray };
  const nextWeekDay = getPrevWeekDay(endDate);
  const newEndDate = defaultDateFormatter.format(nextWeekDay);
  const lastWeek = Object.keys(updatedWeeksAndDaysArray).at(-1)!;
  if (updatedWeeksAndDaysArray[lastWeek].length > 1) {
    updatedWeeksAndDaysArray[lastWeek] = updatedWeeksAndDaysArray[
      lastWeek
    ].slice(0, -1);
  } else {
    delete updatedWeeksAndDaysArray[lastWeek];
  }
  return {
    newEndDate,
    updatedWeeksAndDaysArray,
  };
};
