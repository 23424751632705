import { Modal, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import updateModalIcon from 'assets/icons/update-field-modal.svg';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  confirmUpdate: () => void;
};

const UpdateWarningModal = ({ isOpen, setIsOpen, confirmUpdate }: Props) => (
  <Modal
    isOpen={isOpen}
    title={intl.get('SETTINGS_PAGE.FIELDS_PAGE.UPDATE_WARNING_MODAL.TITLE')}
    titleIcon={{ src: updateModalIcon }}
    aria-label={intl.get(
      'SETTINGS_PAGE.FIELDS_PAGE.UPDATE_WARNING_MODAL.TITLE'
    )}
    data-cy='update-warning__confirmation-modal'
    closeModal={() => setIsOpen(false)}
    size='medium'
    actionButtons={[
      {
        children: intl.get(
          'SETTINGS_PAGE.FIELDS_PAGE.UPDATE_WARNING_MODAL.PROCEED_BUTTON'
        ),
        variant: 'primary',
        onClick: confirmUpdate,
        'data-cy': 'update-warning__proceed-button',
      },
      {
        children: intl.get('CANCEL'),
        variant: 'tertiary',
        onClick: () => setIsOpen(false),
      },
    ]}
  >
    <Typography>
      {intl.getHTML('SETTINGS_PAGE.FIELDS_PAGE.UPDATE_WARNING_MODAL.BODY')}
    </Typography>
  </Modal>
);

export default UpdateWarningModal;
