import { useState, useMemo } from 'react';
import {
  Legend,
  PieChart,
  Cell,
  Pie,
  Sector,
  ResponsiveContainer,
} from 'recharts';
import intl from 'react-intl-universal';
import { generateNativeTextElement } from './Elements';
import {
  ACTIVE_COLORS,
  INACTIVE_COLORS,
  inActiveColor,
  chartCenterCountStyle,
  chartCenterTextStyle,
  SECTOR_INACTIVE_COLOR,
  CENTER_COUNT_COLOR,
  CENTER_TEXT_COLOR,
  DATA_STATE_NO_TASKS,
} from './constants';

const PieChartComponent = ({
  taskDetails,
  showLegend = false,
}: {
  taskDetails: any;
  showLegend: boolean;
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [sectorClicked, setSectorClicked] = useState(false);
  const [sectorHovered, setSectorHovered] = useState(false);
  const [renderBlankState, setBlankState] = useState(false);
  const [sectorColors, setSectorColors] = useState(ACTIVE_COLORS);

  const totalNumberOfTasks = taskDetails.total;

  useMemo(() => {
    if (totalNumberOfTasks === 0) {
      setBlankState(true);
      setSectorColors(INACTIVE_COLORS);
    } else {
      setBlankState(false);
      setSectorColors(ACTIVE_COLORS);
    }
  }, [totalNumberOfTasks]);

  const data = useMemo(() => {
    return totalNumberOfTasks === 0
      ? DATA_STATE_NO_TASKS(intl)
      : [
          {
            name: intl.get(`TASKS.TASK_DETAIL_PAGE.STATUS_OPTIONS.NEW`),
            value: taskDetails.new,
          },
          {
            name: intl.get(`TASKS.TASK_DETAIL_PAGE.STATUS_OPTIONS.IN_PROGRESS`),
            value: taskDetails.inProgress,
          },
          {
            name: intl.get(`TASKS.TASK_DETAIL_PAGE.STATUS_OPTIONS.COMPLETED`),
            value: taskDetails.completed,
          },
          {
            name: intl.get(`TASKS.TASK_DETAIL_PAGE.STATUS_OPTIONS.ON_HOLD`),
            value: taskDetails.onHold,
          },
        ];
  }, [
    taskDetails.new,
    taskDetails.completed,
    taskDetails.inProgress,
    taskDetails.onHold,
    totalNumberOfTasks,
  ]);

  const renderActiveShape = (props: {
    cx?: any;
    cy?: any;
    midAngle?: any;
    innerRadius?: any;
    outerRadius?: any;
    startAngle?: any;
    endAngle?: any;
    fill?: any;
    percent?: any;
    value?: any;
    showArc?: any;
    name?: any;
  }) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      value,
      name,
    } = props;

    const chartCenterCountFill = sectorHovered
      ? SECTOR_INACTIVE_COLOR
      : CENTER_COUNT_COLOR;

    const chartCenterTextFill =
      sectorHovered || renderBlankState
        ? SECTOR_INACTIVE_COLOR
        : CENTER_TEXT_COLOR;

    const chartCenterElements = !renderBlankState ? (
      <>
        {generateNativeTextElement(
          cx,
          cy,
          'middle',
          chartCenterCountFill,
          value + '/' + totalNumberOfTasks,
          chartCenterCountStyle,
          0
        )}
        {generateNativeTextElement(
          cx,
          cy,
          'middle',
          chartCenterTextFill,
          name,
          chartCenterTextStyle,
          22
        )}
      </>
    ) : (
      <>
        {generateNativeTextElement(
          cx,
          cy,
          'middle',
          chartCenterTextFill,
          '',
          chartCenterTextStyle,
          -10
        )}
        {generateNativeTextElement(
          cx,
          cy,
          'middle',
          chartCenterTextFill,
          '00',
          { ...chartCenterTextStyle, fontWeight: 500, fontSize: '1.25em' },
          4
        )}
        {generateNativeTextElement(
          cx,
          cy,
          'middle',
          chartCenterTextFill,
          intl.get('PROJECT_DETAIL.INSIGHTS.BY_TASKS.TASKS'),
          chartCenterTextStyle,
          18
        )}
      </>
    );

    const chartArcElements = !renderBlankState ? (
      <>
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 2}
          outerRadius={outerRadius + 4}
          fill={fill}
          style={{ outline: 'none' }}
        />
      </>
    ) : null;
    return (
      <g style={{ outline: 'none' }}>
        {chartCenterElements}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          style={{ outline: 'none' }}
        />
        {chartArcElements}
      </g>
    );
  };

  const handleSectorClick = (event: any, index: number) => {
    setSectorClicked(true);
    setActiveIndex(index);
    setSectorHovered(true);
  };
  const handleMouseEnter = (event: any, index: number) => {
    setActiveIndex(index);
  };
  const handleMouseLeave = (event: any, index: number) => {
    setSectorClicked(false);
    setSectorHovered(false);
  };
  const renderColorfulLegendText = (value: string, entry: any) => {
    return (
      <span
        style={{
          color: renderBlankState
            ? SECTOR_INACTIVE_COLOR
            : sectorClicked && data[activeIndex].name !== entry.value
            ? SECTOR_INACTIVE_COLOR
            : CENTER_COUNT_COLOR,
          lineHeight: '1rem',
          fontSize: '0.75rem',
          fontWeight: 400,
          paddingRight: '0.25rem',
        }}
      >
        {value}
      </span>
    );
  };

  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={data}
          activeIndex={activeIndex}
          activeShape={(taskDetails) => renderActiveShape(taskDetails)}
          cy='50%'
          cx='50%'
          innerRadius={60}
          outerRadius={75}
          paddingAngle={1}
          dataKey='value'
          onMouseEnter={(_, index) => handleMouseEnter(_, index)}
          onMouseLeave={(_, index) => handleMouseLeave(_, index)}
          onClick={(_, index) => handleSectorClick(_, index)}
        >
          {data.map((entry, index: any) => (
            <Cell
              key={`cell-${index}`}
              style={{
                outline: 'none',
              }}
              fill={
                sectorClicked
                  ? activeIndex === index % sectorColors.length
                    ? sectorColors[index % sectorColors.length]
                    : inActiveColor
                  : sectorColors[index % sectorColors.length]
              }
            />
          ))}
        </Pie>
        {showLegend && (
          <Legend
            style={{
              bottom: '0',
              left: '0',
              right: '0',
              margin: 'auto',
              outline: 'none',
            }}
            align='left'
            iconSize={8}
            iconType='circle'
            layout='horizontal'
            verticalAlign='bottom'
            formatter={renderColorfulLegendText}
          />
        )}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
