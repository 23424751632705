import { api } from './api';
import axios from 'axios';

export interface Project {
  id: string;
  title: string;
  isArchived: boolean;
  status: string;
}

export interface ProjectsListResponse {
  projects: Project[];
}

export const fetchProjectsList = async () => {
  const url = `/v2/projects`;
  const response = await api.get<ProjectsListResponse>(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};
