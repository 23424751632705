import React from 'react';
import { tailwindOverride } from '@getsynapse/design-system';
import { ParticipantCapacity } from 'utils/types/tasksAssignmentsResourcesCapacity';
import { ParticipantTypes } from 'utils/constants';
import Toggle from './components/Toggle';
import EmptySection from './components/EmptySection';
import Participant from '../Participant/Participant';

const Section: React.FC<{
  participantType: ParticipantTypes;
  participantCapacity: ParticipantCapacity;
  isSectionOpen?: boolean;
  toggleSection: (sectionId: string) => void;
}> = ({
  participantType,
  participantCapacity,
  isSectionOpen = false,
  toggleSection,
}) => {
  const participants = Object.values(participantCapacity);

  return (
    <>
      <tr className='h-10'>
        <td colSpan={2}>
          <Toggle
            participantType={participantType}
            onToggleSection={() => toggleSection(participantType)}
            isSectionOpen={isSectionOpen}
          />
        </td>
      </tr>
      <tr>
        <td colSpan={2}>
          <div
            className={tailwindOverride(
              'overflow-hidden',
              'transition-height duration-300 easy',
              { 'h-auto': isSectionOpen, 'h-0': !isSectionOpen }
            )}
          >
            {participants && participants.length > 0 ? (
              participants.map(
                (participant: ParticipantCapacity[string], index: number) => {
                  const isOdd = index % 2;
                  return (
                    <Participant
                      participantType={participantType}
                      key={participant.data.id}
                      capacity={participant}
                      className={
                        isOdd ? 'bg-neutral-white' : 'bg-neutral-lightest-two'
                      }
                    />
                  );
                }
              )
            ) : (
              <EmptySection participantType={participantType} />
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default Section;
