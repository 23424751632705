import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';

class ProgramStrategyAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchProgramStrategy = async (programId: string) => {
    const { data } = await this.instance.get(
      `program/${programId}/retrieveStrategyForProgram`
    );
    return data;
  };

  fetchSignedROIFileUrl = async (programId: string, fileName: string) => {
    const { data } = await this.instance.get(
      `v2/programs/${programId}/roi_instruction/${fileName}`
    );
    return data;
  };
}

const programStrategyAPI = new ProgramStrategyAPI();

export default programStrategyAPI;
