import { Facilitator } from 'utils/types/learningSchedule';
import { api } from './api';
import axios from 'axios';

export const fetchFacilitatorsList = async (search?: string, filters?: any) => {
  let url = '/v2/facilitation-management/facilitators';

  const queryParams: string[] = [];
  if (search) {
    queryParams.push(
      `name=${search}`,
      `jobTitle=${search}`,
      `province=${search}`
    );
  }

  if (filters) {
    if (filters.languages && filters.languages.length > 0) {
      queryParams.push(`languages=${filters.languages.join(',')}`);
    }
    if (filters.skills && filters.skills.length > 0) {
      queryParams.push(`skills=${filters.skills.join(',')}`);
    }
    if (filters.countries && filters.countries.length > 0) {
      queryParams.push(`countries=${filters.countries.join(',')}`);
    }
  }

  if (queryParams.length > 0) {
    url += `?${queryParams.join('&')}`;
  }

  const response = await api.get<Facilitator[]>(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};
