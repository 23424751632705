import { Modal, Typography, FormItem } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

type DeclinedRequestInfoModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  comments: string;
  declineDate: string;
  reason: string;
  details?: string;
};

const DeclinedRequestInfoModal = ({
  isOpen,
  setIsOpen,
  comments,
  declineDate,
  reason,
  details,
}: DeclinedRequestInfoModalProps) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      aria-label='declined-request-info-modal'
      closeModal={handleClose}
      isOpen={isOpen}
      title={intl.get('REQUEST_PAGE.DECLINED_REQUEST_INFO_MODAL.TITLE')}
      size='medium'
      childrenClassName=''
      data-testid='declined-request-info-modal'
    >
      <FormItem
        label={intl.get('REQUEST_PAGE.DECLINED_REQUEST_INFO_MODAL.REASON')}
        labelProps={{ required: true }}
      >
        <Typography variant='body2' data-testid='declined-reason-text'>
          {reason}
        </Typography>
      </FormItem>
      {details && (
        <FormItem
          label={intl.get(
            'REQUEST_PAGE.DECLINED_REQUEST_INFO_MODAL.ADDITIONAL_DETAILS'
          )}
          className='mt-4'
        >
          <Typography variant='body2' data-testid='declined-details-text'>
            {details}
          </Typography>
        </FormItem>
      )}
      <FormItem
        label={intl.get('REQUEST_PAGE.DECLINED_REQUEST_INFO_MODAL.COMMENTS')}
        className='mt-4'
      >
        <Typography variant='body2' data-testid='declined-comments-text'>
          {comments
            ? comments
            : intl.get('REQUEST_PAGE.DECLINED_REQUEST_INFO_MODAL.NO_COMMENTS')}
        </Typography>
      </FormItem>
      <FormItem
        className='mt-6'
        label={intl.get(
          'REQUEST_PAGE.DECLINED_REQUEST_INFO_MODAL.DECLINE_DATE'
        )}
      >
        <Typography variant='body2' data-testid='declined-date-text'>
          {declineDate}
        </Typography>
      </FormItem>
    </Modal>
  );
};

export default DeclinedRequestInfoModal;
