import intl from 'react-intl-universal';
import { getFileStackConfig } from './filestackConfig';
import { PickerFileMetadata } from 'filestack-js';
import { FileUpload } from '@getsynapse/design-system';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  createCategoryEvaluationEvidence,
  deleteCategoryEvaluationEvidence,
} from 'state/StrategyCategory/strategyCategorySlice';
import ReplaceEvidenceModal from './ReplaceEvidenceModal';
import { useState } from 'react';
import get from 'lodash/get';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { EvaluationEvidenceFilePostData } from 'utils/types/program';

const FileUploadComponent = () => {
  const { programId, categoryId } = useParams<{
    programId: string;
    categoryId: string;
  }>();
  const dispatch = useDispatch();
  const fileStackConfig = getFileStackConfig();
  const [isReplaceModalOpen, setIsReplaceModalOpen] = useState(false);
  const [existingEvidenceId, setExistingEvidenceId] = useState<string>('');
  const [fileData, setFileData] = useState<EvaluationEvidenceFilePostData>();

  const checkCreateEvidenceResponse = (
    createEvidenceResponse: any,
    replaceFlag: boolean
  ) => {
    const hasError = get(createEvidenceResponse, 'error');
    const hasFileNameExistError =
      hasError &&
      get(createEvidenceResponse, 'payload.response.status') === 409;
    if (hasFileNameExistError) {
      const existingEvidenceIdWithSameFileName = get(
        createEvidenceResponse,
        'payload.response.data.evidenceId'
      );
      setExistingEvidenceId(existingEvidenceIdWithSameFileName);
      setIsReplaceModalOpen(true);
    } else if (hasError) {
      dispatch(
        showSnackbarNotification({
          autoHide: false,
          notificationVariant: 'error',
          notificationTitle: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.EVIDENCE.UPLOAD_ERROR_MESSAGE_TITLE'
          ),
          notificationMessage: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.EVIDENCE.UPLOAD_ERROR_MESSAGE_BODY'
          ),
        })
      );
    } else {
      dispatch(
        showSnackbarNotification({
          autoHide: false,
          notificationVariant: 'success',
          notificationTitle: replaceFlag
            ? intl.get(
                'PROGRAM_PAGE.STRATEGY_PAGE.EVIDENCE.REPLACE_SUCCESS_MESSAGE'
              )
            : intl.get(
                'PROGRAM_PAGE.STRATEGY_PAGE.EVIDENCE.UPLOAD_SUCCESS_MESSAGE'
              ),
        })
      );
    }
  };

  const uploadFile = async (file: Array<PickerFileMetadata>) => {
    const uploadedFileData = {
      fileType: file[0].mimetype,
      fileKey: file[0].key!,
      fileHandle: file[0].handle,
      fileProviderUrl: file[0].url,
      fileCdnUrl: file[0].url,
      fileName: file[0].filename,
      metadata: file[0],
      providerName: 'filestack',
    };
    setFileData(uploadedFileData);
    const createEvidenceResponse = await dispatch(
      createCategoryEvaluationEvidence({
        programId,
        categoryId,
        fileData: uploadedFileData,
      })
    );
    checkCreateEvidenceResponse(createEvidenceResponse, false);
  };

  const replaceFile = async () => {
    const deleteResponse = await dispatch(
      deleteCategoryEvaluationEvidence({
        programId,
        categoryId,
        evidenceId: existingEvidenceId,
      })
    );
    if (get(deleteResponse, 'payload', undefined)) {
      const createEvidenceResponse = await dispatch(
        createCategoryEvaluationEvidence({
          programId,
          categoryId,
          fileData: fileData!,
        })
      );
      checkCreateEvidenceResponse(createEvidenceResponse, true);
    } else {
      dispatch(
        showSnackbarNotification({
          autoHide: false,
          notificationVariant: 'error',
          notificationTitle: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.EVIDENCE.UPLOAD_ERROR_MESSAGE_TITLE'
          ),
          notificationMessage: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.EVIDENCE.UPLOAD_ERROR_MESSAGE_BODY'
          ),
        })
      );
    }
  };

  return (
    <>
      <ReplaceEvidenceModal
        fileName={fileData?.fileName!}
        isOpen={isReplaceModalOpen}
        setIsOpen={setIsReplaceModalOpen}
        replaceFile={replaceFile}
      />
      <FileUpload
        className='mt-1'
        config={fileStackConfig}
        displayUploadedFiles={false}
        onFileUploadHandle={uploadFile}
        customErrorMessages={{
          'file-too-large': intl.get('PROJECT_DETAIL.FILES_TAB.FILE_1GB'),
        }}
        uploadInstructions={
          <>
            {intl.get('INTAKE_TAB.DRAG_HERE')}{' '}
            <span className='text-purple-darker'>
              {intl.get('INTAKE_TAB.CHOOSE')}
            </span>{' '}
            file.
          </>
        }
        hidePickerWhenUploading
        data-testid='evaluation-evidence-upload-picker'
      />
    </>
  );
};

export default FileUploadComponent;
