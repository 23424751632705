import React, { useEffect, useCallback } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Button } from '@getsynapse/design-system';
import {
  getUserTimeOffs,
  selectTimeOffsTable,
  setPagination,
} from 'state/TimeOff/TimeOffSlice';
import { selectUserId } from 'state/User/userSlice';
import { openPanel } from 'state/SidePanel/sidePanelSlice';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import TimeOffTable from './components/TimeOffTable';
import Pagination from 'Organisms/Pagination/ClientPagination';
import { SIDE_PANEL_TYPES } from 'utils/constants';

const MyTimeOff = () => {
  const currentUserId = useSelector(selectUserId);
  const timeOffs = useSelector(selectTimeOffsTable);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUserId) {
      dispatch(getUserTimeOffs(currentUserId));
    }
  }, [currentUserId, dispatch]);

  const handleUpdatePagination = useCallback(
    (pagination) => {
      dispatch(setPagination(pagination));
    },
    [dispatch]
  );

  return (
    <div className='w-full h-full flex flex-col'>
      <PageTitle titleComponent={intl.get('USER_TIME_OFF_PAGE.TITLE')} />
      <DetailsPage
        content={
          <div className='flex flex-col'>
            <div className='flex justify-between mb-4 items-center'>
              <Typography variant='label' className='text-neutral'>
                {intl.get('USER_TIME_OFF_PAGE.SUBTITLE')}
              </Typography>
              <Button
                onClick={() =>
                  dispatch(
                    openPanel({
                      resourceType: SIDE_PANEL_TYPES.TIME_OFF,
                      resourceId: 'new',
                      isViewOnlyMode: false,
                    })
                  )
                }
                data-cy='add-time-off-button'
              >
                {intl.get('USER_TIME_OFF_PAGE.ADD_TIME_OFF')}
              </Button>
            </div>
            <TimeOffTable />
          </div>
        }
      />
      <div className='absolute bottom-0 w-full'>
        <Pagination
          total={timeOffs.total}
          onChange={handleUpdatePagination}
          className='z-10 max-w-full'
        />
      </div>
    </div>
  );
};

export default MyTimeOff;
