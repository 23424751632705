import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { DeleteProgramFlag, Program } from 'utils/customTypes';
import get from 'lodash/get';

class ProgramsAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(`${config.get('backendURL')}program`);
  }

  createProgram: (newProgramData: Program) => Promise<any> = async (
    newProgramData
  ) => {
    const response = await this.instance.post('/createProgram', newProgramData);
    if (response.data) {
      return response.data;
    }
    return get(response, 'response.data');
  };

  fetchTeamPrograms: () => Promise<any> = async () => {
    const { data } = await this.instance.get('/retrieveAllPrograms');
    return data;
  };

  deleteProgram: (
    programId: string,
    flag: DeleteProgramFlag,
    newProgramId: string
  ) => Promise<any> = async (programId, flag, newProgramId) => {
    const { data } = await this.instance.post(`/deleteProgram/${programId}`, {
      flag,
      newProgramId,
    });
    return data;
  };
}

const programsAPI = new ProgramsAPI();

export default programsAPI;
