import React from 'react';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import {
  Button,
  Avatar,
  Typography,
  tailwindOverride,
  Tooltip,
} from '@getsynapse/design-system';
import { UserAvatars } from 'utils/customTypes';

const TriggerButton: React.FC<{
  disabled?: boolean;
  currentUser: UserAvatars;
  assignToMeCallback: () => void;
  className?: string;
  style?: Partial<CSSStyleDeclaration>;
}> = ({
  disabled = false,
  currentUser,
  assignToMeCallback,
  className,
  ...otherProps
}) => {
  return (
    <Button
      variant='secondary'
      className={tailwindOverride('group', className, {
        'cursor-not-allowed': disabled,
      })}
      disabled={disabled}
      onClick={assignToMeCallback}
      {...otherProps}
    >
      <Avatar
        initial={get(currentUser, 'avatar.initial')}
        imageSrc={get(currentUser, 'avatar.imageSrc')}
        className={tailwindOverride({
          'group-hover:shadow-lifted group-hover:border-neutral-white':
            !disabled,
          'cursor-not-allowed': disabled,
        })}
      />
      <Typography
        variant='body2'
        className={tailwindOverride('text-primary ml-2', {
          'group-hover:text-neutral-white': !disabled,
        })}
      >
        {intl.get('PROJECT_DETAIL.ASSIGN_TO_ME')}
      </Typography>
    </Button>
  );
};

const AssignToMeButton: React.FC<{
  disabled: boolean;
  currentUser: UserAvatars;
  assignToMeCallback: () => void;
  isUserAdminOrManager?: boolean;
  dynamicButtonMargin?: number;
}> = ({
  disabled,
  currentUser,
  assignToMeCallback,
  isUserAdminOrManager = false,
  dynamicButtonMargin = 0,
}) => {
  return disabled ? (
    <div
      className='absolute top-0 left-0 mt-px'
      style={{ marginLeft: `${dynamicButtonMargin}px` }}
    >
      <Tooltip
        position='topRight'
        showPopper
        openMode='hover2'
        timeout={0}
        ariaId='assign-to-me-button'
        hasMaxWidth={false}
        trigger={
          <div>
            <TriggerButton
              disabled
              currentUser={currentUser}
              assignToMeCallback={assignToMeCallback}
            />
          </div>
        }
      >
        {intl.getHTML('PROJECT_DETAIL.USER_CANNOT_ASSIGN_ITSELF_AS_OWNER', {
          num: isUserAdminOrManager ? 1 : 0,
        })}
      </Tooltip>
    </div>
  ) : (
    <TriggerButton
      currentUser={currentUser}
      assignToMeCallback={assignToMeCallback}
      className='absolute top-0 left-0 mt-px'
      style={{ marginLeft: `${dynamicButtonMargin}px` }}
    />
  );
};

export default AssignToMeButton;
