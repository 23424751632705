import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import orderBy from 'lodash/orderBy';
import { Option } from 'utils/customTypes';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';
import {
  fetchAvailablePrograms,
  selectSearchPrograms,
} from 'state/TasksList/Search/searchSlice';
import { debounce } from 'lodash';

interface ProgramFilterFieldProps {
  program: string[];
  onChange: (program: string[]) => void;
}

const ProgramFilterField: FC<ProgramFilterFieldProps> = ({
  program,
  onChange,
}) => {
  const dispatch = useDispatch();

  const onFilterChange = debounce((name: string) => {
    dispatch(fetchAvailablePrograms({ name }));
  }, 500);

  useEffect(() => {
    dispatch(fetchAvailablePrograms({}));
  }, [dispatch]);

  const programsList = useSelector(selectSearchPrograms);
  const programOptions = useMemo<Option[]>(() => {
    const options = programsList?.map((program) => ({
      value: program.id,
      label: `${program.displayId} - ${program.name}`,
    }));
    return orderBy(options, 'label');
  }, [programsList]);

  const selectedProgramOptions = programOptions.filter((option: Option) =>
    program.includes(option.value)
  );

  return (
    <MultiSelectDropdown
      options={programOptions}
      filterable
      values={selectedProgramOptions}
      onFilterChange={onFilterChange}
      onChange={(options: Option[]) => {
        const program = options.map((option: Option) => option.value);
        onChange(program);
      }}
      placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
      triggerProps={{
        'aria-label': intl.get('ENTITIES.PROGRAM', { num: 1 }),
      }}
    />
  );
};

export default ProgramFilterField;
