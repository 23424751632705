import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  fetchProjectsByHealth,
  getProjectsByHealth,
  getActiveFiltersQueryParams,
} from 'state/Insights/ProjectsSlice';
import { Typography } from '@getsynapse/design-system';
import BarChartByHealthProjects from './BarChartByHealthProjects';
import { DataValueDescription } from 'Pages/InsightsPage/Components/DataValueDescription';
import HorizontalBarChartLoader from 'Pages/InsightsPage/Components/SkeletonLoaders/HorizontalBarChartLoader';
import { SLICE_STATUS } from 'utils/constants';
import ErrorScreen from '../../Components/ErrorScreen';

const ByHealth = () => {
  const dispatch = useDispatch();
  const activeFilters = useSelector(getActiveFiltersQueryParams);

  useEffect(() => {
    dispatch(fetchProjectsByHealth(activeFilters));
  }, [dispatch, activeFilters]);

  const ByHealthProjectsState = useSelector(getProjectsByHealth);

  const data = [
    {
      name: 'On track',
      count: ByHealthProjectsState.onTrack,
    },
    {
      name: 'Off track',
      count: ByHealthProjectsState.offTrack,
    },
    {
      name: 'At risk',
      count: ByHealthProjectsState.atRisk,
    },
  ];
  return ByHealthProjectsState.status === SLICE_STATUS.LOADING ? (
    <HorizontalBarChartLoader />
  ) : ByHealthProjectsState.status === SLICE_STATUS.FAILED ? (
    <ErrorScreen />
  ) : (
    <>
      <div className='flex flex-col mt-2.5 ml-3 w-full'>
        <Typography
          variant='caption'
          className='font-semibold flex justify-start text-neutral'
        >
          {intl.get('PROJECTS_INSIGHTS_TABS.BY_HEALTH_PROJECTS.TITLE')}
        </Typography>

        <div className='flex mt-0.5'>
          <DataValueDescription
            title={intl.get('PROJECTS_INSIGHTS_TABS.BY_HEALTH_PROJECTS.LABEL')}
            value={ByHealthProjectsState.total}
          />
        </div>

        <div className='w-full h-full mt-6 justify-center flex'>
          {ByHealthProjectsState.total === 0 ? (
            <Typography variant='caption' className='text-neutral-lighter'>
              {intl.get('PROJECTS_INSIGHTS_TABS.NO_PROJECTS')}
            </Typography>
          ) : (
            <BarChartByHealthProjects data={data} />
          )}
        </div>
      </div>
    </>
  );
};

export default ByHealth;
