import React from 'react';
import CapacityBreakdownTooltip from './components/CapacityBreakdownTooltip';
import CapacityPercentage from './components/CapacityPercentage';

const WeeklyCapacity: React.FC<{
  assignedHours: number;
  weeklyCapacity: number;
  weeklyTimeOff: number;
}> = ({ assignedHours, weeklyTimeOff, weeklyCapacity }) => {
  const totalAssignedHours = assignedHours + weeklyTimeOff;
  const percentage =
    totalAssignedHours > 0 ? totalAssignedHours / weeklyCapacity : 0;
  return (
    <CapacityBreakdownTooltip
      trigger={
        <div>
          <CapacityPercentage percentage={percentage} />
        </div>
      }
      weeklyCapacity={weeklyCapacity}
      assignedHours={totalAssignedHours}
      availableHours={weeklyCapacity - totalAssignedHours}
    />
  );
};

export default WeeklyCapacity;
