import { ReactElement } from 'react';
import { Modal } from '@getsynapse/design-system';

type MyWorspaceModalProps = {
  content: ReactElement;
  title: string;
  icon: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dataCy: string;
};

const MyWorspaceModal = ({
  content,
  title,
  icon,
  isOpen,
  setIsOpen,
  dataCy,
}: MyWorspaceModalProps) => (
  <Modal
    aria-label={title}
    data-cy={dataCy}
    closeModal={() => setIsOpen(false)}
    isOpen={isOpen}
    title={title}
    titleIcon={{ src: icon }}
    size='large'
    childrenClassName='h-124'
  >
    {content}
  </Modal>
);

export default MyWorspaceModal;
