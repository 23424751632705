import moment from 'moment';
import { PROJECT_STATUS } from 'utils/constants';

const inactiveProjectStatus = [
  PROJECT_STATUS.COMPLETED,
  PROJECT_STATUS.CANCELED,
  PROJECT_STATUS.CLOSED,
];

const notStartedProjectStatusArray = [
  PROJECT_STATUS.NEW,
  PROJECT_STATUS.IN_PLANNING,
];

export const isProjectDueDatePast = (
  status: string,
  projectDueDate: string | undefined
) => {
  if (inactiveProjectStatus.includes(status)) {
    return false;
  }
  if (moment(projectDueDate).isBefore(moment(), 'day')) {
    return true;
  }
  return false;
};

export const isNotStartedProjectStartDatePast = (
  status: string,
  projectStartDate: string | undefined
) => {
  if (notStartedProjectStatusArray.includes(status)) {
    if (moment(projectStartDate).isBefore(moment(), 'day')) {
      return true;
    }
    return false;
  }
  return false;
};
