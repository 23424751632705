import { Fragment } from 'react';
import { Typography, CheckboxGroup } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { StringOption } from 'utils/customTypes';

type TemplateObjectiveCheckboxGroupProps = {
  categoryName: string;
  selectedObjectiveTemplates: Map<
    string,
    StringOption & {
      checked: boolean;
      templateId: string;
    }
  >;
  setSelectedObjectiveTemplates: (
    value: React.SetStateAction<
      Map<
        string,
        StringOption & {
          checked: boolean;
          templateId: string;
        }
      >
    >
  ) => void;
};

const TemplateObjectiveCheckboxGroup = ({
  categoryName,
  selectedObjectiveTemplates,
  setSelectedObjectiveTemplates,
}: TemplateObjectiveCheckboxGroupProps) => {
  const updateTemplateObjectives = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const selectedValue = selectedObjectiveTemplates.get(event.target.value);

    setSelectedObjectiveTemplates((prev) =>
      new Map(prev).set(value, {
        ...selectedValue!,
        checked: !selectedValue!.checked,
      })
    );
  };

  return (
    <Fragment>
      <Typography
        variant='body2'
        className='pb-6'
        data-testid='create-objective-modal_subtitle'
      >
        {intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.ADD_OBJECTIVE.SUBTITLE_REACTION'
        )}
      </Typography>
      <CheckboxGroup
        name={`objective-templates-${categoryName}`}
        onElementChange={updateTemplateObjectives}
        options={Array.from(selectedObjectiveTemplates.values())}
        className='px-4 space-y-2 pb-6'
        checkboxContainerTestId='create-objective-modal_template-objectives'
      />
    </Fragment>
  );
};

export default TemplateObjectiveCheckboxGroup;
