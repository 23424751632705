import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import intl from 'react-intl-universal';
import { Program } from 'utils/customTypes';
import { PATHS } from 'utils/constants';
import { getProgramData, fetchProgram } from 'state/Program/programSlice';
import {
  fetchProgramStrategy,
  selectCategoryIds,
} from 'state/ProgramStrategy/programStrategySlice';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import CategoryNavigator from './components/CategoryNavigator';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import classNames from 'classnames';

type Props = {
  categoryName: string;
  categoryId: string;
  loadingComponent?: React.ReactNode;
  children: React.ReactNode;
  isLoading: boolean;
};

const StrategyCategoryTemplate = ({
  categoryName,
  categoryId,
  loadingComponent,
  children,
  isLoading,
}: Props) => {
  const history = useHistory();
  const { programId } = useParams<{
    programId: string;
  }>();
  const dispatch = useDispatch();
  const {
    enableProgramStrategyBeta12 = false,
    enableProgramStrategyBeta14 = false,
    enableProgramStrategy15Roi = false,
  } = useFlags();

  const program: Program = useSelector(getProgramData);
  const categoryIds = useSelector(
    selectCategoryIds(
      enableProgramStrategyBeta12,
      enableProgramStrategyBeta14,
      enableProgramStrategy15Roi
    )
  );

  useEffect(() => {
    if (programId) {
      if (!program.id) {
        dispatch(fetchProgram(programId));
      }
      if (categoryIds.length === 0) {
        dispatch(fetchProgramStrategy(programId));
      }
    }
  }, [dispatch, programId, program.id, categoryIds]);

  const onNextCategory = (step: number) => {
    const currentCategoryIndex = categoryIds.indexOf(categoryId || '');
    if (currentCategoryIndex !== -1) {
      const index = currentCategoryIndex + step;
      const categoryLength = categoryIds.length;
      const newCategoryId =
        categoryIds[
          ((index % categoryLength) + categoryLength) % categoryLength
        ];
      if (
        (currentCategoryIndex === 5 && step === 1) ||
        (currentCategoryIndex === 1 && step === -1)
      ) {
        history.push(
          `${PATHS.PROGRAM_PAGE}/${programId}${PATHS.INPUT_CATEGORY_PAGE}/${newCategoryId}`
        );
      } else if (
        (currentCategoryIndex === 4 && step === 1) ||
        (currentCategoryIndex === 0 && step === -1)
      ) {
        history.push(
          `${PATHS.PROGRAM_PAGE}/${programId}${PATHS.ROI_CATEGORY_PAGE}/${newCategoryId}`
        );
      } else {
        history.push(
          `${PATHS.PROGRAM_PAGE}/${programId}${PATHS.STRATEGY_PAGE}/${newCategoryId}`
        );
      }
    }
  };

  return (
    <div className='h-full pb-6 flex flex-col'>
      <PageTitle
        data-testid='program__title'
        titleClassName={classNames({
          'w-full': !program.title || !categoryName,
        })}
        titleComponent={
          categoryName && program.title ? (
            intl.get('PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.CATEGORY_TITLE', {
              program: program.title,
              category: categoryName,
            })
          ) : (
            <div className='h-6 w-1/2 bg-neutral-lighter-two animate-pulse' />
          )
        }
      />
      <DetailsPage
        className='border-b rounded-b-lg'
        topBarClassName='pl-6'
        topBar={
          <CategoryNavigator
            currentCategoryName={categoryName}
            onNext={onNextCategory}
            isLoading={isLoading || !categoryName}
          />
        }
        bodyClassName='flex pt-6 px-0 pb-4'
        content={
          !isLoading && categoryName ? (
            <div className='w-full flex flex-col h-full'>{children}</div>
          ) : (
            loadingComponent
          )
        }
      />
    </div>
  );
};

export default StrategyCategoryTemplate;
