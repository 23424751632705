import { mentionsLimit } from 'state/MyWorkspace/myWorkspaceSlice';
import { useSelector } from 'react-redux';
import Skelton from 'Atoms/Skelton/Skelton';
import { useMemo } from 'react';

const MentionsLoader = () => {
  const limit = useSelector(mentionsLimit);

  const list = useMemo(() => {
    const listItems = [];
    for (let i = 0; i < limit; i++) {
      listItems.push(
        <div
          key={i}
          className={`mb-1 block pb-5 pl-8 pr-4 ${i !== 0 ? 'pt-3' : 'pt-1'}`}
        >
          <div className='flex mb-1'>
            <Skelton className='w-6 h-6 mr-2' />
            <Skelton className='h-4' />
          </div>
          <Skelton className='w-11/12 ml-8 mb-6 h-4' />
          <Skelton className='ml-8 w-11/12' />
        </div>
      );
    }
    return listItems;
  }, [limit]);

  return <>{list}</>;
};

export default MentionsLoader;
