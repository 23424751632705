import { FC } from 'react';
import { Typography } from '@getsynapse/design-system';

interface SkeletonLoaderProps {
  title: string;
  id: string;
}

const SkeletonLoader: FC<SkeletonLoaderProps> = ({ title, id }) => {
  return (
    <div
      className='flex flex-col space-y-6'
      role='status'
      aria-live='polite'
      aria-busy='true'
      aria-label={id}
    >
      <Typography variant='h4' className='text-primary' weight='medium'>
        {title}
      </Typography>
      <div className='flex flex-1 space-x-4'>
        <div className='flex flex-1 flex-col space-y-2'>
          <div className='w-1/4 h-4 rounded-sm bg-primary-lighter-two' />
          <div className='h-20 rounded-sm bg-primary-lighter-two' />
        </div>
        <div className='flex flex-1 flex-col space-y-2'>
          <div className='w-1/4 h-4 rounded-sm bg-primary-lighter-two' />
          <div className='h-20 rounded-sm bg-primary-lighter-two' />
        </div>
      </div>

      <div className='flex flex-1 flex-col space-y-2'>
        <div className='w-1/4 h-4 rounded-sm bg-primary-lighter-two' />
        <div className='h-20 rounded-sm bg-primary-lighter-two' />
      </div>

      <div className='flex flex-1 flex-col space-y-2'>
        <div className='w-1/4 h-4 rounded-sm bg-primary-lighter-two' />
        <div className='h-20 rounded-sm bg-primary-lighter-two' />
      </div>
    </div>
  );
};

export default SkeletonLoader;
