import { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import StrategyCategoryTemplate from 'Templates/StrategyCategoryTemplate/StrategyCategoryTemplate';
import StrategyExamplesCard from 'Organisms/StrategyExamplesPanel/StrategyExamplesCard';
import { STRATEGY_CATEGORIES } from 'utils/constants/program';
import {
  getProgramInput,
  getProgramInputStatus,
  fetchProgramInput,
  resetProgramInput,
  createProgramInput,
  updateProgramInput,
  linkRequests,
  unlinkRequests,
  linkBusinessUnits,
  unlinkBusinessUnits,
} from 'state/ProgramInputCategoy/ProgramInputCategoySlice';
import { useParams, useHistory, Prompt } from 'react-router-dom';
import PageFooter from 'Molecules/PageFooter/PageFooter';
import { Button } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { DropdownRequest } from 'utils/customTypes';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import BusinessRequestSection, {
  BusinessRequestSectionRef,
} from './components/BusinessRequestSection/BusinessRequestSection';
import ObjectivesDetailsSection from './components/ObjectivesDetailsSection/ObjectivesDetailsSection';
import LearnerDetailSection from './components/LearnerDetailSection/LearnerDetailSection';
import { SLICE_STATUS, ROI_METHODOLOGIES } from 'utils/constants';
import InputFieldsPageSkeleton from './InputFieldsPageSkeleton';
import { ProgramInputCategoryData } from 'utils/types/program';
import UnsavedChangesModal from 'Organisms/UnsavedChangesModal/UnsavedChangesModal';
import { Location } from 'history';

const InputCategoryPage = () => {
  const dispatch = useDispatch();
  const { programId, categoryId } = useParams<{
    programId: string;
    categoryId: string;
  }>();
  const history = useHistory();
  const businessRequestSectionRef = useRef<BusinessRequestSectionRef>(null);

  const programInput = useSelector(getProgramInput);
  const programInputStatus = useSelector(getProgramInputStatus);

  const [linkedRequests, setLinkedRequests] = useState<DropdownRequest[]>([]);
  const [linkedBusinessUnits, setLinkedBusinessUnits] = useState<string[]>([]);
  const [inputUpdatedData, setInputUpdatedData] =
    useState<ProgramInputCategoryData>({} as ProgramInputCategoryData);
  const [isNumericInputValid, setIsNumericInputValid] = useState(true);
  const [confirmedNavigation, setConfirmedNavigation] =
    useState<boolean>(false);
  const [isLeavingWarningModalOpen, setIsLeavingWarningModalOpen] =
    useState<boolean>(false);
  const [leavingToLocation, setLeavingToLocation] = useState<string>('');

  const hasRequestChanges = useMemo(() => {
    const linkedRequestsIds =
      linkedRequests?.map((request) => request.id).sort() ?? [];
    const programInputRequestsIds =
      programInput?.requests?.map((request) => request.id).sort() ?? [];
    return (
      JSON.stringify(linkedRequestsIds) !==
      JSON.stringify(programInputRequestsIds)
    );
  }, [linkedRequests, programInput]);

  const hasBusinessUnitsChanges = useMemo(() => {
    const sortedCurrLinkedBUs = [...linkedBusinessUnits].sort() ?? [];
    const sortedOriginalLinkedBUs =
      programInput?.businessUnits?.map((bu) => bu.businessUnitId).sort() ?? [];

    return (
      JSON.stringify(sortedCurrLinkedBUs) !==
      JSON.stringify(sortedOriginalLinkedBUs)
    );
  }, [linkedBusinessUnits, programInput.businessUnits]);

  const hasInputChanges = useMemo(() => {
    const { requests, businessUnits, ...inputFields } = programInput;
    return !isEqual(inputFields, inputUpdatedData);
  }, [inputUpdatedData, programInput]);

  const hasChanges =
    hasRequestChanges || hasInputChanges || hasBusinessUnitsChanges;

  useEffect(() => {
    if (programId && categoryId) {
      dispatch(fetchProgramInput({ programId, categoryId }));
    }

    return () => {
      setInputUpdatedData({} as ProgramInputCategoryData);
      dispatch(resetProgramInput());
    };
  }, [dispatch, programId, categoryId]);

  useEffect(() => {
    setLinkedRequests(programInput?.requests!);
  }, [programInput.requests]);

  useEffect(() => {
    setLinkedBusinessUnits(
      programInput?.businessUnits?.map((bu) => bu.businessUnitId) ?? []
    );
  }, [programInput.businessUnits]);

  useEffect(() => {
    const { requests, businessUnits, ...inputFields } = programInput;
    setInputUpdatedData(inputFields);
  }, [programInput]);

  useEffect(() => {
    if (confirmedNavigation && leavingToLocation) {
      history.push(leavingToLocation);
      setConfirmedNavigation(false);
      setLeavingToLocation('');
    }
  }, [confirmedNavigation, history, leavingToLocation]);

  const updateLinkedRequests = (
    requests: DropdownRequest[],
    isAdding: boolean
  ) => {
    if (isAdding) {
      const sortedRequests = orderBy(requests, ['requestIdentifier'], ['asc']);
      setLinkedRequests((prevRequests) => [
        ...sortedRequests,
        ...(prevRequests ?? []),
      ]);
    } else {
      const removedRequests = linkedRequests.filter(
        (request) => !requests.some((req) => req.id === request.id)
      );
      setLinkedRequests(removedRequests);
    }
  };

  const updateLinkedBusinessUnits = (businessUnitsIds: string[]) =>
    setLinkedBusinessUnits(businessUnitsIds);

  const updateInputField = (
    value: string | number,
    property: keyof ProgramInputCategoryData
  ) => {
    const originalValue = programInput[property];

    if (value === '') {
      if (
        originalValue === null ||
        (property === 'numberOfLearners' && isNumber(originalValue))
      ) {
        setInputUpdatedData((prevData) => ({
          ...prevData,
          [property]: null,
        }));
        return;
      } else if (originalValue === undefined) {
        setInputUpdatedData((prevData) => {
          const updatedData = { ...prevData };
          delete updatedData[property];
          return updatedData;
        });
        return;
      }
    }
    setInputUpdatedData((prevData) => ({
      ...prevData,
      [property]: value,
    }));
  };

  const showErrorNotification = () => {
    dispatch(
      showSnackbarNotification({
        autoHide: false,
        notificationVariant: 'error',
        notificationTitle: intl.get(
          'STRATEGY_DATA_COLLECTION.NOTIFICATION.ERROR.TITLE'
        ),
        notificationMessage: intl.get(
          'STRATEGY_DATA_COLLECTION.NOTIFICATION.ERROR.MESSAGE'
        ),
      })
    );
  };

  const createInputData = async () => {
    try {
      const response = await dispatch(
        createProgramInput({ programId, categoryId, inputUpdatedData })
      );
      return get(response, 'payload.id');
    } catch (error) {
      showErrorNotification();
      return;
    }
  };

  const saveInputData = async () => {
    let inputId = programInput.id;
    const isNewInput = !inputId;
    if (
      !inputId &&
      isNumericInputValid &&
      (hasRequestChanges || hasBusinessUnitsChanges || hasInputChanges)
    ) {
      inputId = await createInputData();
    }
    if (
      inputId &&
      (hasRequestChanges || hasBusinessUnitsChanges || hasInputChanges) &&
      isNumericInputValid
    ) {
      const changes = [];
      try {
        if (hasRequestChanges) {
          const newRequests = linkedRequests.filter(
            (request) =>
              !programInput?.requests?.some((req) => req.id === request.id)
          );
          if (newRequests.length) {
            changes.push(
              dispatch(
                linkRequests({
                  programId,
                  categoryId,
                  inputId: inputId!,
                  requestIds: newRequests.map((req) => req.id),
                })
              )
            );
          }

          const removedRequests = programInput?.requests?.filter(
            (request) => !linkedRequests.some((req) => req.id === request.id)
          );

          if (removedRequests?.length) {
            changes.push(
              dispatch(
                unlinkRequests({
                  programId,
                  categoryId,
                  inputId: inputId!,
                  inputRequestIds: removedRequests.map(
                    (req) => req.inputRequestId!
                  ),
                })
              )
            );
            if (removedRequests.length === programInput.requests?.length) {
              businessRequestSectionRef.current?.showRequestsDropdownAndHideTable();
            }
          }
        }
        if (hasBusinessUnitsChanges) {
          const newBusinessUnits = linkedBusinessUnits.filter(
            (bu) =>
              !programInput?.businessUnits?.some(
                (linkedBU) => linkedBU.businessUnitId === bu
              )
          );
          if (newBusinessUnits.length) {
            changes.push(
              dispatch(
                linkBusinessUnits({
                  programId,
                  categoryId,
                  inputId: inputId!,
                  businessUnitIds: newBusinessUnits,
                })
              )
            );
          }

          const removedBusinessUnits = programInput?.businessUnits?.filter(
            (linkedBU) => !linkedBusinessUnits.includes(linkedBU.businessUnitId)
          );

          if (removedBusinessUnits?.length) {
            changes.push(
              dispatch(
                unlinkBusinessUnits({
                  programId,
                  categoryId,
                  inputId: inputId!,
                  inputBusinessUnitIds: removedBusinessUnits.map(
                    (bu) => bu.inputBusinessUnitId
                  ),
                })
              )
            );
          }
        }

        if (!isNewInput && hasInputChanges) {
          const { id, ...dataWithoutId } = inputUpdatedData;
          changes.push(
            dispatch(
              updateProgramInput({
                programId,
                categoryId,
                inputId,
                inputUpdatedData: dataWithoutId,
              })
            )
          );
        }
        await Promise.all(changes);
        dispatch(
          showSnackbarNotification({
            autoHide: false,
            notificationVariant: 'success',
            notificationTitle: intl.get(
              'INPUT_CATEGORY.SAVE_SUCCESS_NOTIFICATION'
            ),
          })
        );
      } catch (error) {
        showErrorNotification();
      }
    }
  };

  const changeIsNumericInputValid = (isValid: boolean) => {
    setIsNumericInputValid(isValid);
  };

  const handleBlockedNavigation = (location: Location) => {
    if (!confirmedNavigation) {
      setLeavingToLocation(`${location.pathname}${location.search}`);
      setIsLeavingWarningModalOpen(true);
      return false;
    }
    return true;
  };

  return (
    <StrategyCategoryTemplate
      categoryId={categoryId}
      loadingComponent={<InputFieldsPageSkeleton />}
      categoryName={STRATEGY_CATEGORIES.INPUT}
      isLoading={
        programInputStatus === SLICE_STATUS.LOADING ||
        programInputStatus === SLICE_STATUS.FAILED
      }
    >
      <Prompt
        when={hasChanges && isNumericInputValid}
        message={handleBlockedNavigation}
      />
      <UnsavedChangesModal
        isOpen={isLeavingWarningModalOpen}
        setIsOpen={setIsLeavingWarningModalOpen}
        onConfirm={() => setConfirmedNavigation(true)}
      />
      <div className='px-8 flex gap-8'>
        <div className='w-2/3 pb-8'>
          <BusinessRequestSection
            linkedRequests={linkedRequests}
            linkedBusinessUnits={linkedBusinessUnits}
            updateLinkedBusinessUnits={updateLinkedBusinessUnits}
            updateLinkedRequests={updateLinkedRequests}
            completionDate={programInput?.completionDate ?? ''}
            ref={businessRequestSectionRef}
            updateInputField={updateInputField}
          />
          <ObjectivesDetailsSection
            programInput={inputUpdatedData}
            updateInputField={updateInputField}
          />
          <LearnerDetailSection
            programInput={inputUpdatedData}
            updateInputField={updateInputField}
            changeIsNumericInputValid={changeIsNumericInputValid}
          />
        </div>
        <StrategyExamplesCard
          className='w-90 h-filtered-stage min-h-142 flex-none ml-auto'
          programId={programId}
          links={ROI_METHODOLOGIES.INPUT}
        />
      </div>
      <PageFooter>
        <Button variant='tertiary' onClick={() => history.goBack()}>
          {intl.get('CANCEL')}
        </Button>
        <Button
          onClick={saveInputData}
          disabled={!hasChanges || !isNumericInputValid}
          data-testid='input-save_button'
        >
          {intl.get('SAVE')}
        </Button>
      </PageFooter>
    </StrategyCategoryTemplate>
  );
};

export default InputCategoryPage;
