import { IconButton, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import chevronLeft from 'assets/icons/chevron-left.svg';
import chevronRight from 'assets/icons/chevron-right.svg';
import { STRATEGY_CATEGORIES } from 'utils/constants/program';

type Props = {
  currentCategoryName: string;
  onNext: (step: number) => void;
  isLoading: boolean;
};

const CategoryNavigator = ({
  currentCategoryName,
  onNext,
  isLoading,
}: Props) => {
  const getTitle = () => {
    if (
      currentCategoryName.toLocaleLowerCase() ===
      STRATEGY_CATEGORIES.INPUT.toLocaleLowerCase()
    ) {
      return intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.INPUT_CATEGORY_NAVIGATOR'
      );
    } else if (
      currentCategoryName.toLocaleLowerCase() ===
      STRATEGY_CATEGORIES.ROI.toLocaleLowerCase()
    ) {
      return intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.ROI_CATEGORY_NAVIGATOR'
      );
    } else {
      return intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.CATEGORY_NAVIGATOR',
        {
          category: currentCategoryName,
        }
      );
    }
  };

  return (
    <div className='flex items-center space-x-3 h-full'>
      <div className='flex space-x-1'>
        <IconButton
          data-testid='category__navigator__left'
          onClick={() => onNext(-1)}
          src={chevronLeft}
          className='text-base bg-neutral-lighter-two p-1 rounded-full'
          description='Previous category'
        />
        <IconButton
          data-testid='category__navigator__right'
          onClick={() => onNext(1)}
          src={chevronRight}
          className='text-base bg-neutral-lighter-two p-1 rounded-full'
          description='Next category'
        />
      </div>
      {isLoading && (
        <div className='h-4 w-2/12 bg-neutral-lighter-two animate-pulse' />
      )}
      {!isLoading && (
        <Typography
          data-testid='category__name'
          weight='medium'
          variant='label'
        >
          {getTitle()}
        </Typography>
      )}
    </div>
  );
};

export default CategoryNavigator;
