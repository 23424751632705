import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { ROICategoryType } from 'utils/types/program';
import { RootState } from 'state/store';
import roiCategoryAPI from './ROICategoyAPI';

interface ROICategoryState {
  status: Status;
  value: ROICategoryType;
}

/* ============================= INITIAL STATE ============================== */

const initialState: ROICategoryState = {
  value: {} as ROICategoryType,
  status: SLICE_STATUS.IDLE,
};

/* ============================= ACTIONS ============================== */
export const resetROICategory = createAction('roiCategory/RESET_ROI_CATEGORY');

/* ============================== REDUX THUNK =============================== */
export const fetchROICategory = createAsyncThunk(
  'roiCategory/FETCH_ROI_CATEGORY',
  async ({
    programId,
    categoryId,
  }: {
    programId: string;
    categoryId: string;
  }) => {
    const response = await roiCategoryAPI.fetchROICategory(
      programId,
      categoryId
    );
    return response.data;
  }
);

export const createROIObjective = createAsyncThunk(
  'roiCategory/CREATE_ROI_OBJECTIVE',
  async ({
    programId,
    categoryId,
    roi,
  }: {
    programId: string;
    categoryId: string;
    roi: number;
  }) => {
    const response = await roiCategoryAPI.createROIObjective(
      programId,
      categoryId,
      roi
    );
    return response;
  }
);

export const updateROIObjective = createAsyncThunk(
  'roiCategory/UPDATE_ROI_OBJECTIVE',
  async ({
    programId,
    categoryId,
    roiId,
    roi,
  }: {
    programId: string;
    categoryId: string;
    roiId: string;
    roi: number;
  }) => {
    const response = await roiCategoryAPI.updateROIObjective(
      programId,
      categoryId,
      roiId,
      roi
    );
    return response;
  }
);

export const deleteROIObjective = createAsyncThunk(
  'roiCategory/DELETE_ROI_OBJECTIVE',
  async ({
    programId,
    categoryId,
    objectiveId,
  }: {
    programId: string;
    categoryId: string;
    objectiveId: string;
  }) => {
    const response = await roiCategoryAPI.deleteROIObjective(
      programId,
      categoryId,
      objectiveId
    );
    return response;
  }
);

/* ================================= REDUCER ================================ */
const roiCategorySlice = createSlice({
  name: 'roiCategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchROICategory.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchROICategory.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
        throw new Error();
      })
      .addCase(fetchROICategory.fulfilled, (state, action) => {
        state.value = action.payload;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(resetROICategory, (state) => {
        state.value = {} as ROICategoryType;
      })
      .addCase(createROIObjective.fulfilled, (state, action) => {
        state.value.roi = action.payload;
        state.value.roiCalculatorData.returnOnInvestmentObjectivePercentage =
          action.payload.roi;
      })
      .addCase(createROIObjective.rejected, () => {
        throw new Error();
      })
      .addCase(updateROIObjective.fulfilled, (state, action) => {
        state.value.roi = action.payload;
        state.value.roiCalculatorData.returnOnInvestmentObjectivePercentage =
          action.payload.roi;
      })
      .addCase(updateROIObjective.rejected, () => {
        throw new Error();
      })
      .addCase(deleteROIObjective.fulfilled, (state) => {
        state.value.roi = {};
      })
      .addCase(deleteROIObjective.rejected, () => {
        throw new Error();
      });
  },
});

/* =============================== SELECTORS ================================ */

export const selectROICategory = (state: RootState) => state.roiCategory.value;

export const selectROIObjective = (state: RootState) =>
  state.roiCategory.value.roi;

export const selectROICategoryStatus = (state: RootState) =>
  state.roiCategory.status;

export const selectROICalculations = (state: RootState) =>
  state.roiCategory.value.roiCalculatorData;

export default roiCategorySlice.reducer;
