import { FC, useMemo } from 'react';
import intl from 'react-intl-universal';
import orderBy from 'lodash/orderBy';
import snakeCase from 'lodash/snakeCase';
import { TASK_TYPES } from 'utils/constants';
import { Option } from 'utils/customTypes';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';

interface TypeFilterFieldProps {
  type: string[];
  onChange: (type: string[]) => void;
}

const TypeFilterField: FC<TypeFilterFieldProps> = ({ type, onChange }) => {
  const typeOptions = useMemo(() => {
    const options = Object.keys(TASK_TYPES).map((key) => ({
      value: snakeCase(intl.get(`TASKS.TASK_TYPES.${key}`)),
      label: intl.get(`TASKS.TASK_TYPES.${key}`),
    }));
    return orderBy(options, 'label');
  }, []);

  const selectedTypeOptions = typeOptions.filter((option: Option) =>
    type.includes(option.value)
  );

  return (
    <MultiSelectDropdown
      options={typeOptions}
      values={selectedTypeOptions}
      onChange={(options: Option[]) => {
        const type = options.map((option: Option) => option.value);
        onChange(type);
      }}
      placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
      triggerProps={{
        'aria-label': intl.get('TASK.TYPE'),
      }}
    />
  );
};

export default TypeFilterField;
