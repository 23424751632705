import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { ProjectWeblink } from 'utils/customTypes';
import { TextField } from '@getsynapse/design-system';

const ReplaceWebLinkContent: React.FC<{
  contentData: Partial<ProjectWeblink>;
  replaceContenData: (data: Partial<ProjectWeblink>) => void;
}> = ({ contentData, replaceContenData }) => {
  const [weblinkName, setWeblinkName] = useState(contentData.display);
  const [weblink, setWeblink] = useState(contentData.url);

  useEffect(() => {
    if (weblink && weblinkName) {
      if (contentData.display !== weblinkName || contentData.url !== weblink) {
        replaceContenData({
          display: weblinkName,
          url: weblink,
        });
      }
    }
  }, [weblinkName, weblink, replaceContenData, contentData]);

  return (
    <div className='flex space-x-4'>
      <TextField
        label={intl.get('PROJECT_DETAIL.FILES_TAB.TEXT_TO_DISPLAY')}
        labelProps={{
          required: true,
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setWeblinkName(e.target.value)
        }
        value={weblinkName}
        placeholder={intl.get('PROJECT_DETAIL.FILES_TAB.WEBLINK_PLACEHOLDER')}
        className='w-full'
        data-cy='replace-web-link__name-field'
      />
      <TextField
        label={intl.get('PROJECT_DETAIL.FILES_TAB.WEBLINK')}
        labelProps={{
          required: true,
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setWeblink(e.target.value)
        }
        value={weblink}
        placeholder={intl.get(
          'PROJECT_DETAIL.FILES_TAB.WEBLINK_URL_PLACEHOLDER'
        )}
        className='w-full'
        data-cy='replace-web-link__url-field'
      />
    </div>
  );
};

export default ReplaceWebLinkContent;
