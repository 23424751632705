export const addIdsToArrayIfNotExists: (
  idsToAdd: string[],
  idsArray: string[]
) => string[] = (idsToAdd, idsArray) => {
  if (idsArray.length === 0) {
    return idsToAdd;
  }
  const idsSet = new Set(idsArray);
  idsToAdd.forEach((id) => idsSet.add(id));
  return Array.from(idsSet);
};

export const addIdToArrayIfNotExists: (
  idsArray: string[],
  newId: string
) => string[] = (idsArray, newId) => {
  if (idsArray.includes(newId)) {
    return idsArray;
  }
  return idsArray.concat(newId);
};
