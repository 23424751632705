import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'state/store';
import { SLICE_STATUS } from 'utils/constants';
import { Status } from 'utils/customTypes';
import { TIME_OFFS_TABLE_HEADERS } from 'utils/constants/manageTimeOff';
import {
  TimeOffEntry,
  TimeOffEntriesTableSorting,
} from 'types/store/manageTimeOff';
import { fetchPastTimeOffEntries as fetchPastTimeOffEntriesAPI } from 'api/manageTimeOff';
import {
  addTimeOffEntries,
  selectAllTimeOffEntriesById,
  removeTimeOffEntry,
} from '../all/allTimeOffEntriesSlice';
import { getAfterQueryParamFromNextLink } from '../helpers';
import { TimeOffEntryFilters } from 'types/store/manageTimeOff';

export type PastTimeOffEntriesStateStatus = Status | 'rejected';

interface FetchPastTimeOffEntriesParams {
  userId: string;
  includeTotalCount?: boolean;
  fetchNext?: boolean;
}

interface PastTimeOffEntriesState {
  ids: string[];
  status: PastTimeOffEntriesStateStatus;
  links: {
    next: string | null;
  };
  filters: TimeOffEntryFilters;
  sorting: TimeOffEntriesTableSorting;
  totalCount?: number;
}

const initialState: PastTimeOffEntriesState = {
  ids: [],
  status: SLICE_STATUS.IDLE,
  links: {
    next: null,
  },
  filters: {} as TimeOffEntryFilters,
  sorting: {
    sortBy: TIME_OFFS_TABLE_HEADERS.DATES,
    order: 'desc',
  },
  totalCount: 0,
};

export const fetchPastTimeOffEntries = createAsyncThunk(
  'manageTimeOff/pastTimeOffEntries/fetchPastTimeOffEntries',
  async (
    {
      userId,
      includeTotalCount = false,
      fetchNext = false,
    }: FetchPastTimeOffEntriesParams,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;
      let afterQueryParam = '';
      if (fetchNext) {
        const nextLink = state.manageTimeOff.pastTimeOffEntries.links.next;
        if (nextLink) {
          afterQueryParam = getAfterQueryParamFromNextLink(nextLink);
        }
      }
      const filters = state.manageTimeOff.pastTimeOffEntries.filters;
      const sorting = state.manageTimeOff.pastTimeOffEntries.sorting;
      const timeOffEntries = await fetchPastTimeOffEntriesAPI({
        userId,
        includeTotalCount,
        after: afterQueryParam,
        ...filters,
        ...sorting,
      });
      const allIds: string[] = [];
      const byId = timeOffEntries.entries.reduce(
        (acc: { [key: string]: TimeOffEntry }, entry: TimeOffEntry) => {
          allIds.push(entry.id);
          acc[entry.id] = entry;
          return acc;
        },
        {}
      );
      dispatch(addTimeOffEntries(byId));
      return {
        ids: allIds,
        links: timeOffEntries.links,
        totalCount: timeOffEntries?.totalCount || 0,
        fetchNext,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 403) {
          return rejectWithValue('rejected');
        }
      }
      return rejectWithValue(SLICE_STATUS.FAILED);
    }
  }
);

export const setFilters = createAction(
  'manageTimeOff/pastTimeOffEntries/SET_FILTERS',
  (filters: TimeOffEntryFilters) => {
    return { payload: { filters } };
  }
);

const pastTimeOffEntriesSlice = createSlice({
  name: 'manageTimeOff/pastTimeOffEntries',
  initialState,
  reducers: {
    resetPastTimeOffEntries: () => initialState,
    setSorting: (
      state,
      action: { type: string; payload: TimeOffEntriesTableSorting }
    ) => {
      return {
        ...state,
        sorting: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPastTimeOffEntries.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchPastTimeOffEntries.fulfilled, (state, action) => {
        if (action.payload) {
          let updatedIds = action.payload.ids;
          if (action.payload.fetchNext) {
            updatedIds = Array.from(new Set([...state.ids, ...updatedIds]));
          }
          state.status = SLICE_STATUS.IDLE;
          state.ids = updatedIds;
          state.links = action.payload.links;
          if (action.payload.totalCount) {
            state.totalCount = action.payload.totalCount;
          }
        }
      })
      .addCase(fetchPastTimeOffEntries.rejected, (state, action) => {
        state.status = action.payload as PastTimeOffEntriesStateStatus;
      })
      .addCase(removeTimeOffEntry.fulfilled, (state, action) => {
        if (action.payload.id) {
          state.ids = state.ids.filter((id) => id !== action.payload.id);
        }
      })
      .addCase(setFilters, (state, action) => {
        state.filters = action.payload.filters;
      });
  },
});

export const selectFetchPastTimeOffEntriesStatus = (state: RootState) =>
  state.manageTimeOff.pastTimeOffEntries.status;
export const selectPastTimeOffEntriesIds = (state: RootState) =>
  state.manageTimeOff.pastTimeOffEntries.ids;
export const selectPastTimeOffEntries = createSelector(
  [selectAllTimeOffEntriesById, selectPastTimeOffEntriesIds],
  (entries: { [id: string]: TimeOffEntry }, ids: string[]) =>
    ids.map((id) => entries[id])
);
export const selectFilters = (state: RootState) =>
  state.manageTimeOff.pastTimeOffEntries.filters;
export const selectPastTimeOffEntriesSorting = (state: RootState) =>
  state.manageTimeOff.pastTimeOffEntries.sorting;
export const selectCanFetchMorePastTimeOffEntries = (state: RootState) =>
  !!state.manageTimeOff.pastTimeOffEntries.links.next;
export const selectTotalPastTimeOffEntries = (state: RootState) =>
  state.manageTimeOff.pastTimeOffEntries.totalCount;

export const { resetPastTimeOffEntries, setSorting } =
  pastTimeOffEntriesSlice.actions;

export default pastTimeOffEntriesSlice.reducer;
