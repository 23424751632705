import BasicInfoLoader from '../../Components/SkeletonLoaders/BasicInfoLoader';
import Skelton from 'Atoms/Skelton/Skelton';

const StatusCardSkeletonLoader = () => (
  <div className='flex justify-center items-center h-full pt-2'>
    <BasicInfoLoader hideSubLine />
    <BasicInfoLoader hideSubLine />
    <BasicInfoLoader hideSubLine />
    <div className='flex flex-col mr-10'>
      <Skelton className='h-2 w-40' />
      <Skelton className='h-2 w-40 mt-2' />
    </div>
  </div>
);

export default StatusCardSkeletonLoader;
