import { Project } from 'utils/customTypes';
import {
  Tooltip,
  Typography,
  useElevation,
  Table,
} from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { getStatusColumn } from '../../ProjectsListPage/helpers/tableColumnsValues';

const LinkedProjectsTooltip = ({
  projects,
  numberOfProjects,
}: {
  projects: Project[];
  numberOfProjects: string;
}) => (
  <Tooltip
    openMode='hover2'
    position='bottomLeft'
    timeout={100}
    hasMaxWidth={false}
    contentProps={{
      className: `w-100 bg-neutral-white text-neutral-black p-6 z-10 ${useElevation(
        3
      )}`,
    }}
    showPopper
    className='w-full h-full'
    trigger={
      <div className='w-full h-full flex items-center'>{numberOfProjects}</div>
    }
  >
    <div
      onClick={(event: React.MouseEvent<HTMLElement>) =>
        event.stopPropagation()
      }
    >
      <Typography variant='h6' className='mb-4'>
        {intl.get('REQUESTS_LIST_PAGE.TABLE.LINKED_PROJECTS.TITLE')}
      </Typography>
      <div className='max-h-60 overflow-y-auto w-88'>
        <Table
          canSelectRows={false}
          className='w-full'
          numberOfHeadRows={0}
          data={{
            headData: [],
            rows: projects.map((project, index) => ({
              cells: [
                {
                  content: <div className='w-52 truncate'>{project.title}</div>,
                  className:
                    'group-hover:shadow-cancel group-hover:border-transparent',
                },
                {
                  content: getStatusColumn(
                    project.status,
                    false,
                    'group-hover:border-transparent'
                  ),
                  'data-testid': `project-status-${index}`,
                  className:
                    'group-hover:shadow-cancel group-hover:border-transparent',
                },
              ],
            })),
          }}
        />
      </div>
    </div>
  </Tooltip>
);

export default LinkedProjectsTooltip;
