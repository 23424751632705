import axios, { AxiosInstance } from 'axios';
import get from 'lodash/get';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { NewProject } from 'utils/customTypes';

class ProjectsAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchTeamProjects: () => Promise<any> = async () => {
    const { data } = await this.instance.get('project/myTeamProjects');
    return data;
  };

  fetchUserProjects: () => Promise<any> = async () => {
    const { data } = await this.instance.get('project/myProjects');
    return data;
  };

  createProject: (newProjectData: NewProject) => Promise<any> = async (
    newProjectData
  ) => {
    const { data } = await this.instance.post(`project`, {
      learningTeams: [],
      collaborators: [],
      participants: [],
      ...newProjectData,
    });
    return data;
  };

  bulkCreateProjects: (newProjects: NewProject[]) => Promise<any> = async (
    newProjects
  ) => {
    const { data } = await this.instance.post(`project/bulkCreate`, {
      newProjects,
    });
    return data;
  };

  deleteProject = async (projectId: string) => {
    await this.instance.delete(`project/${projectId}/`);
  };

  duplicateProject: (projectId: string, data: NewProject) => Promise<any> =
    async (projectId, data) => {
      const {
        title,
        owners,
        category_id,
        process_id,
        learningTeamIds,
        ...otherData
      } = data;
      const body = {
        ...otherData,
        projectName: title,
        ownerIds: owners,
        processId: process_id,
        categoryId: category_id,
        ldTeamIds: learningTeamIds,
      };
      const response = await this.instance.post(`/project/${projectId}/clone`, {
        ...body,
      });
      return response.data.data;
    };

  getProjectsIdsWithoutTasks: () => Promise<string[]> = async () => {
    const response = await this.instance.get<{
      code: number;
      data: {
        idsForProjectsWithoutTasks: string[];
      };
      success: boolean;
    }>(`/project/tasks/retrieveIdsForProjectsWithoutTasks`);
    if (response.status === 200 && response.data) {
      return get(response, 'data.data.idsForProjectsWithoutTasks', []);
    }
    if (axios.isAxiosError(response)) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
  };
}

const projectsAPI = new ProjectsAPI();

export default projectsAPI;
