export const MOST_UTILIZED_TOP_COLOR = { r: 0x67, g: 0x21, b: 0x40 };

export const MOST_UTILIZED_TOP_COLOR_RGB = { r: 103, g: 33, b: 64 };

export const MOST_UTILIZED_BOTTOM_COLOR = { r: 0xe6, g: 0xc3, b: 0xd4 };

export const LEAST_UTILIZED_TOP_COLOR = { r: 0x1e, g: 0x43, b: 0x3d };

export const LEAST_UTILIZED_TOP_COLOR_RGB = { r: 30, g: 67, b: 61 };

export const LEAST_UTILIZED_BOTTOM_COLOR = { r: 0xb1, g: 0xd8, b: 0xd0 };
