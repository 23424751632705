import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import isEqual from 'lodash/isEqual';

import { TaskBundle } from 'utils/customTypes';
import { TaskTemplate } from 'utils/types/taskTemplate';

type UseTaskBundleEdit = (
  initialBasicData?: TaskBundle,
  initialTaskTemplates?: TaskTemplate[]
) => {
  localTaskBundle: TaskBundle;
  localTaskTemplates: TaskTemplate[];
  updateLocalTaskBundle: (field: string, value: string) => void;
  setLocalTaskBundle: Dispatch<SetStateAction<TaskBundle>>;
  setLocalTaskTemplates: Dispatch<SetStateAction<TaskTemplate[]>>;
  isBundleOverviewValid: boolean;
  overViewChanged: boolean;
  templatesChanged: boolean;
};

const useTaskBundleEdit: UseTaskBundleEdit = (
  initialBasicData = {} as TaskBundle,
  initialTaskTemplates = []
) => {
  const [localTaskBundle, setLocalTaskBundle] = useState(initialBasicData);
  const [localTaskTemplates, setLocalTaskTemplates] =
    useState<TaskTemplate[]>(initialTaskTemplates);

  const updateLocalTaskBundle = useCallback((field: string, value: string) => {
    setLocalTaskBundle((prev) => ({ ...prev, [field]: value }));
  }, []);

  useEffect(() => {
    if (initialBasicData.id) {
      setLocalTaskBundle(initialBasicData);
    }
  }, [initialBasicData]);

  useEffect(() => {
    if (initialTaskTemplates.length) {
      setLocalTaskTemplates(initialTaskTemplates);
    }
  }, [initialTaskTemplates]);

  const isBundleOverviewValid = useMemo<boolean>(
    () => Boolean(localTaskBundle.name && localTaskBundle.description),
    [localTaskBundle.description, localTaskBundle.name]
  );
  const overViewChanged = useMemo<boolean>(
    () => !isEqual(localTaskBundle, initialBasicData),
    [localTaskBundle, initialBasicData]
  );
  const templatesChanged = useMemo<boolean>(
    () => !isEqual(localTaskTemplates, initialTaskTemplates),
    [localTaskTemplates, initialTaskTemplates]
  );

  return {
    localTaskBundle,
    localTaskTemplates,
    updateLocalTaskBundle,
    setLocalTaskBundle,
    setLocalTaskTemplates,
    isBundleOverviewValid,
    overViewChanged,
    templatesChanged,
  };
};

export default useTaskBundleEdit;
