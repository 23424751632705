import { Modal, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

const UnsavedChangesModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
}) => {
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      actionButtons={[
        {
          children: intl.get('UNSAVED_CHANGES.DISCARD_BUTTON'),
          onClick: handleConfirm,
        },
        {
          children: intl.get('CANCEL'),
          variant: 'secondary',
          onClick: handleClose,
        },
      ]}
      closeModal={handleClose}
      isOpen={isOpen}
      title={intl.get('UNSAVED_CHANGES.TITLE')}
      titleIcon={{
        name: 'arrow-back-outline',
      }}
      size='medium'
      aria-label={intl.get('UNSAVED_CHANGES.TITLE')}
    >
      <Typography>{intl.get('UNSAVED_CHANGES.MESSAGE')}</Typography>
      <Typography className='mt-4'>
        {intl.get('UNSAVED_CHANGES.CONFIRM_MESSAGE')}
      </Typography>
    </Modal>
  );
};

export default UnsavedChangesModal;
