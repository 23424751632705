import React, { useEffect } from 'react';
import { Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import useModal from 'Hooks/useModal';

const DeleteFileConfirmationModal: React.FC<{
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title?: string;
  subTitle?: string;
  confirmLabel?: string;
  cancelLabel?: string;
}> = ({
  isOpen,
  onConfirm,
  onCancel,
  title,
  subTitle,
  confirmLabel,
  cancelLabel,
}) => {
  const { Modal, modalProps, openModal, closeModal } = useModal();

  const handleCancel = () => {
    closeModal();
    onCancel();
  };

  const handleConfirm = () => {
    closeModal();
    onConfirm();
  };

  useEffect(() => {
    if (isOpen && !modalProps.isOpen) {
      openModal(modalProps);
    }
  }, [isOpen, modalProps, openModal]);

  return (
    <Modal
      {...modalProps}
      data-cy='delete-file__confirmation-modal'
      title={title || intl.get('DELETE_FILE')}
      titleIcon={{
        name: 'trash',
        className: 'text-error-dark',
      }}
      aria-label={title || intl.get('DELETE_FILE')}
      size='medium'
      closeModal={handleCancel}
      actionButtons={[
        {
          children: confirmLabel || intl.get('DELETE_FILE_CONFIRM'),
          variant: 'primary',
          color: 'danger',
          onClick: handleConfirm,
          'data-cy': 'delete-file__confirm-button',
        },
        {
          children: cancelLabel || intl.get('CANCEL'),
          variant: 'tertiary',
          'data-cy': 'delete-file__cancel-button',
          onClick: handleCancel,
        },
      ]}
    >
      <Typography variant='body' className='text-primary'>
        {subTitle || intl.get('DELETE_FILE_CONFIRMATION')}
      </Typography>
    </Modal>
  );
};

export default DeleteFileConfirmationModal;
