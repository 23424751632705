import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';

export interface CreateObjectiveMeasurementBody {
  programObjectiveId: string;
  objectiveMeasurementScaleId: string;
  percentageScore?: number | null;
  fivePointScore?: number | null;
  tenPointScore?: number | null;
}

export interface UpdateObjectiveMeasurementBody {
  id: string;
  objectiveMeasurementScaleId?: string;
  percentageScore?: number | null;
  fivePointScore?: number | null;
  tenPointScore?: number | null;
}

class ObjectiveMeasurementAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchObjectiveMeasurement = async (
    programId: string,
    categoryId: string,
    objectiveId: string
  ) => {
    const { data } = await this.instance.get(
      `v2/programs/${programId}/categories/${categoryId}/objectives/${objectiveId}/measurements`
    );
    return data || undefined;
  };

  createObjectiveMeasurement = async (
    programId: string,
    categoryId: string,
    objectiveId: string,
    objectiveMeasurement: CreateObjectiveMeasurementBody
  ) => {
    const { data } = await this.instance.post(
      `v2/programs/${programId}/categories/${categoryId}/objectives/${objectiveId}/measurements`,
      objectiveMeasurement
    );

    return data;
  };

  updateObjectiveMeasurement = async (
    programId: string,
    categoryId: string,
    objectiveId: string,
    measurementId: string,
    objectiveMeasurement: UpdateObjectiveMeasurementBody
  ) => {
    const { data } = await this.instance.put(
      `v2/programs/${programId}/categories/${categoryId}/objectives/${objectiveId}/measurements/${measurementId}`,
      objectiveMeasurement
    );

    return data;
  };
}

export default new ObjectiveMeasurementAPI();
