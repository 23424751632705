import intl from 'react-intl-universal';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import { FormLabel, Toggle } from '@getsynapse/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import TaskDetailForm from './TaskDetailForm/TaskDetailForm';
import TaskCommentsPanel from './Comments/TaskCommentsPanel';
import { useEffect, useState } from 'react';
import TaskDetailFooter from './TaskDetailFooter/TaskDetailFooter';
import { fetchTaskDetail, selectTaskDetail } from 'state/TaskDetail/taskSlice';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'Molecules/Loader/Loader';
import LinkedContent from './LinkedContent/LinkedContent';

const TasksDetailPage = () => {
  const { taskComments: taskCommentsFlag = false } = useFlags();
  const { taskId } = useParams<{
    projectId: string;
    taskId: string;
  }>();

  const [isUpdating, setIsUpdating] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTaskDetail({ taskId }));
  }, [taskId, dispatch]);

  const handleSave = () => {
    setIsUpdating(true);
  };
  const handleCancel = () => {
    setIsUpdating(false);
  };
  // const userType = useSelector(selectUserType);
  const task = useSelector(selectTaskDetail);

  if (!task) {
    return <Loader />;
  }

  return (
    <div className='flex flex-col h-full'>
      <PageTitle titleComponent={`${task.project.title} / ${task.name}`} />
      <DetailsPage
        topBar={
          <div className='z-5 flex ml-4 mt-1'>
            <div className='w-1/2'>
              <FormLabel>{intl.get('TASKS.TASK_DETAIL_PAGE.ID')}</FormLabel>
              <span className='ml-1'>{task.displayId}</span>
            </div>
            <div className='w-1/2 justify-end ml-auto flex mr-4'>
              <Toggle
                offText={intl.get('TASKS.TASK_DETAIL_PAGE.ENABLE_TASK')}
              />
            </div>
          </div>
        }
        content={
          <div
            className='bg-neutral-white flex-grow overflow-y-auto flex flex-row '
            data-cy='task-form-body'
          >
            <div className='w-full'>
              <TaskDetailForm task={task} />
              <LinkedContent taskId={taskId} />
            </div>
          </div>
        }
        comments={taskCommentsFlag && <TaskCommentsPanel />}
      />
      <TaskDetailFooter
        taskDisabled={task.disabled}
        isUpdating={isUpdating}
        onCancel={handleCancel}
        onSave={handleSave}
      />
    </div>
  );
};

export default TasksDetailPage;
