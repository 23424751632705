import MoreActions from 'Organisms/MoreActions/MoreActions';
import { useMemo, useState } from 'react';
import { MoreActionsOption, TaskBundle } from 'utils/customTypes';
import intl from 'react-intl-universal';
import { TASK_BUNDLE_ACTIONS } from 'utils/constants';
import DeleteTaskBundleModal from './DeleteTaskBundleModal';

const DeleteTaskBundle = ({ data }: { data: TaskBundle }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const options: MoreActionsOption[] = useMemo(() => {
    return [
      {
        dataCy: 'delete-task-bundle_option',
        iconName: 'trash',
        iconClassName: 'text-primary-dark',
        label: intl.get('SETTINGS_PAGE.TASK_BUNDLE_PAGE.DELETE_BUNDLE.DELETE'),
        value: TASK_BUNDLE_ACTIONS.DELETE,
      },
    ];
  }, []);

  const onSelectOption = (option: MoreActionsOption) => {
    switch (option.value) {
      case TASK_BUNDLE_ACTIONS.DELETE:
        setIsModalOpen(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <DeleteTaskBundleModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        taskBundle={data}
      />

      <div className='relative z-5'>
        <div className='flex items-center justify-end'>
          <MoreActions options={options} onSelectOption={onSelectOption} />
        </div>
      </div>
    </>
  );
};

export default DeleteTaskBundle;
