import { FC, useRef, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { DebouncedFunc, debounce } from 'lodash';
import { FormItem, TextField } from '@getsynapse/design-system';
import { LONG_INPUTS_LENGTH } from 'utils/constants';

interface TaskNameProps {
  name: string;
  onNameChange: (name: string) => void;
  isViewOnly?: boolean;
  isReadOnly?: boolean;
}

const TaskName: FC<TaskNameProps> = ({
  name,
  onNameChange,
  isReadOnly = false,
  isViewOnly = false,
}) => {
  const [newNameVale, setNewNameVale] = useState<string>(name);
  const debouncedOnChangeHandler =
    useRef<DebouncedFunc<(name: string) => void>>();

  useEffect(() => {
    debouncedOnChangeHandler.current = debounce(
      (name: string) => onNameChange(name),
      500
    );
  }, [onNameChange]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewNameVale(event.target.value);
    if (debouncedOnChangeHandler.current) {
      debouncedOnChangeHandler.current(event.target.value);
    }
  };

  return (
    <FormItem>
      <TextField
        label={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_TITLE_LABEL')}
        labelProps={{ required: true }}
        value={newNameVale}
        variant='text'
        displayCharactersCount
        maxLength={LONG_INPUTS_LENGTH}
        placeholder={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_TITLE_PLACEHOLDER')}
        disabled={isReadOnly || isViewOnly}
        onChange={onChangeHandler}
        aria-label='task-name-field'
      />
    </FormItem>
  );
};

export default TaskName;
