import get from 'lodash/get';
import moment from 'moment';
import organizationAPI from 'state/Organization/organizationAPI';
import { objKeyAsString, CurrentUser } from '../utils/customTypes';

declare const window: any;

type Visitor = {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  registrationDate?: string;
  type?: string;
  admin?: boolean;
  firstUser?: boolean;
  accountType?: string;
};

type Account = {
  id?: string;
  name?: string;
  creationDate?: string;
  type?: string;
  trialEndDate?: string;
  numberOfLicenses?: string;
};

export const initPendo: (
  userEmail: string,
  organizationId: string,
  licenseType: string,
  currentUser: CurrentUser
) => void = async (userEmail, organizationId, licenseType, currentUser) => {
  if (!window.pendo || !userEmail || !organizationId) {
    return;
  }

  let visitor: Visitor = {};

  if (userEmail) {
    visitor.id = userEmail;
    visitor.email = userEmail;
    visitor.firstName = get(currentUser, 'firstName');
    visitor.lastName = get(currentUser, 'lastName');
    visitor.jobTitle = get(currentUser, 'jobTitle');
    visitor.registrationDate = moment(get(currentUser, 'createdAt')).format(
      'YYYY-MM-DDTHH:mm:ss.SSSZ'
    );
    visitor.type = get(currentUser, 'type');
    visitor.admin = get(currentUser, 'role') === 'admin';
    visitor.firstUser = get(currentUser, 'firstUser') || false;
    visitor.accountType = licenseType;
  }

  let account: Account = {
    id: organizationId,
  };

  if (organizationId) {
    const response = await organizationAPI.fetchOrganization(organizationId);
    account.name =
      get(response, 'account_name') || get(response, 'company_name') || '';
    account.creationDate = moment(get(response, 'createdAt')).format(
      'YYYY-MM-DDTHH:mm:ss.SSSZ'
    );
    account.numberOfLicenses = get(response, 'license_number') || 0;
    account.trialEndDate = get(response, 'trial_end_date') || '';
    account.type = licenseType;
  }

  window.pendo.initialize({
    visitor,
    account,
  });
};

export const trackEvent = (eventName: string, eventData: objKeyAsString) => {
  if (window.pendo && window.pendo.isReady && window.pendo.isReady()) {
    return window.pendo.track(eventName, eventData);
  }

  setTimeout(() => trackEvent(eventName, eventData), 500);
};
