import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dropdown, tailwindOverride } from '@getsynapse/design-system';
import { Option, UserViewSettings } from 'utils/customTypes';
import { CapacityView } from 'utils/types/capacityView';
import {
  VIEW_SETTING,
  VIEW_SETTING_VALUES,
} from 'utils/constants/capacityView';
import {
  selectViewSettingValues,
  createUpdateUserViewSetting,
} from 'state/ViewSettings/viewSettingsSlice';
import ViewPickerTrigger from './components/ViewPickerTrigger';

interface ViewPickerOption extends Option {
  label: string;
  value: CapacityView;
  option: UserViewSettings;
}

const ViewPicker: React.FC<{
  capacityView: CapacityView;
}> = ({ capacityView }) => {
  const dispatch = useDispatch();
  const [view, setView] = useState<Omit<ViewPickerOption, 'option'>>({
    label: intl.get(`TEAMS_CAPACITY.VIEW_PICKER.${capacityView}`),
    value: capacityView,
  });
  const prevCapacityView = useRef<CapacityView>(capacityView);
  const { teamsCapacityViewByWeek = false } = useFlags();
  const viewSettingValues = useSelector(selectViewSettingValues(VIEW_SETTING));
  const pickerOptions = useMemo(() => {
    if (!viewSettingValues || viewSettingValues.length === 0) {
      return [];
    }
    let options = viewSettingValues;
    if (teamsCapacityViewByWeek) {
      options = options.filter(
        (viewSettingValue) =>
          viewSettingValue.value !== VIEW_SETTING_VALUES.WEEKLY
      );
    }
    if (!teamsCapacityViewByWeek) {
      options = options.filter(
        (viewSettingValue) =>
          viewSettingValue.value !== VIEW_SETTING_VALUES.BETA_WEEKLY
      );
    }
    return options.map((viewSettingValue: UserViewSettings) => ({
      label: intl.get(`TEAMS_CAPACITY.VIEW_PICKER.${viewSettingValue.value}`),
      value: viewSettingValue.value,
      option: viewSettingValue,
    }));
  }, [viewSettingValues, teamsCapacityViewByWeek]);
  const handleViewChange = (selected: ViewPickerOption) => {
    const { label, value, option } = selected;
    prevCapacityView.current = capacityView;
    setView({ label, value });
    dispatch(
      createUpdateUserViewSetting({
        view_settings_id: option.view_settings_id,
        view_settings_values_id: option.view_settings_values_id,
      })
    );
  };
  useEffect(() => {
    if (capacityView !== prevCapacityView.current) {
      prevCapacityView.current = capacityView;
      setView({
        label: intl.get(`TEAMS_CAPACITY.VIEW_PICKER.${capacityView}`),
        value: capacityView,
      });
    }
  }, [capacityView]);

  return (
    <div className='absolute top-0 right-0 mr-6'>
      <Dropdown
        options={pickerOptions}
        values={[view]}
        onChange={handleViewChange}
        popperDiffWidth
        listProps={{ className: 'w-30' }}
        renderTrigger={({
          onToggleDropdown,
          selectedOptions,
          disabled,
          isDropdownExpanded,
          props,
        }) => (
          <ViewPickerTrigger
            onToggleDropdown={onToggleDropdown}
            selectedOptions={selectedOptions as ViewPickerOption[]}
            disabled={disabled}
            isDropdownExpanded={isDropdownExpanded}
            {...props}
          />
        )}
        renderOption={(
          option: Option,
          isSelected: boolean,
          selectOption,
          { className, ...otherProps }
        ) => (
          <li
            key={option.value}
            onClick={selectOption}
            className={tailwindOverride(className, 'h-8 py-1', {
              'bg-primary text-neutral-white': isSelected,
            })}
            {...otherProps}
          >
            {option.label}
          </li>
        )}
      />
    </div>
  );
};

export default ViewPicker;
