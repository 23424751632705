import intl from 'react-intl-universal';
import { tailwindOverride } from '@getsynapse/design-system';
import { useSelector } from 'react-redux';
import { selectCurrentViewSettingValue } from 'state/ViewSettings/viewSettingsSlice';
import {
  VIEW_SETTING,
  VIEW_SETTING_VALUES,
} from 'utils/constants/capacityView';
import HeaderDaysCarousel from '../../../DailyCapacity/components/HeaderDaysCarousel/HeaderDaysCarousel';
import HeaderWeeksCarousel from '../../../BetaWeeklyCapacity/components/HeaderWeeksCarousel/HeaderWeeksCarousel';

const TableHeader = () => {
  const savedTeamsCapacityView = useSelector(
    selectCurrentViewSettingValue(VIEW_SETTING)
  );
  return (
    <thead
      className={tailwindOverride(
        `bg-primary-lightest font-semibold text-primary text-xs`,
        'sticky top-0 z-5',
        {
          'h-20': savedTeamsCapacityView.value === VIEW_SETTING_VALUES.DAILY,
          'h-10': savedTeamsCapacityView.value !== VIEW_SETTING_VALUES.WEEKLY,
        }
      )}
    >
      <tr>
        <th className='w-2/6'>
          <div
            className={tailwindOverride('flex w-full relative', {
              'h-20':
                savedTeamsCapacityView.value === VIEW_SETTING_VALUES.DAILY,
              'h-10':
                savedTeamsCapacityView.value !== VIEW_SETTING_VALUES.WEEKLY,
            })}
          >
            <div className='w-10' />
            <div
              className={tailwindOverride(
                'w-3/5 h-full px-4 py-3',
                'flex items-start justify-start'
              )}
            >
              {intl.get('TEAMS_CAPACITY.TEAM_NAME_COLUMN')}
            </div>
            <div
              className={tailwindOverride(
                'w-2/5 h-full flex',
                'items-start justify-start px-4 py-3'
              )}
            >
              {intl.get('ORG_SETTINGS_PAGE.LEARNING_TEAMS.ROLES', {
                op: 2,
              })}
            </div>
          </div>
        </th>
        <th className='w-4/6 h-full relative'>
          {savedTeamsCapacityView.value === VIEW_SETTING_VALUES.DAILY && (
            <HeaderDaysCarousel />
          )}
          {(savedTeamsCapacityView.value === VIEW_SETTING_VALUES.BETA_WEEKLY ||
            savedTeamsCapacityView.value === VIEW_SETTING_VALUES.WEEKLY) && (
            <HeaderWeeksCarousel />
          )}
        </th>
      </tr>
    </thead>
  );
};

export default TableHeader;
