import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import { Button, Icon, Typography } from '@getsynapse/design-system';
import removeIcon from 'assets/icons/trash-bin-small.svg';
import ResultsTag from 'Pages/StrategyCategoryPage/components/ResultsTag';
import DeleteObjectiveModal from '../DeleteObjectiveModal/DeleteObjectiveModal';
import { PATHS } from 'utils/constants';

type Props = {
  categoryName?: string;
  objectiveResult?: boolean | null;
  objectiveId?: string;
};
const ObjectiveTopBar = ({
  categoryName = '',
  objectiveResult,
  objectiveId = '',
}: Props) => {
  const { programId, categoryId } = useParams<{
    programId: string;
    categoryId: string;
  }>();
  const history = useHistory();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const afterDelete = () => {
    setIsDeleteModalOpen(false);
    history.replace(
      `${PATHS.PROGRAM_PAGE}/${programId}/strategy/${categoryId}`
    );
  };

  const getTagType = () => {
    if (objectiveResult === true) {
      return 'objectiveMet';
    } else if (objectiveResult === false) {
      return 'objectiveNotMet';
    } else {
      return 'noResults';
    }
  };

  return (
    <div className='flex w-full items-center py-2 pr-2 pl-6 justify-between border-b border-neutral-lighter-two'>
      <DeleteObjectiveModal
        categoryName={categoryName}
        afterDelete={afterDelete}
        isOpen={isDeleteModalOpen}
        objectiveId={objectiveId}
        onClose={() => setIsDeleteModalOpen(false)}
        programId={programId}
        categoryId={categoryId}
      />
      <div className='flex space-x-2'>
        <Typography
          data-testid='category-objective-page_manage'
          variant='body2'
          className='font-semibold'
        >
          {intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE_PAGE_SUBTITLE',
            {
              category: categoryName,
            }
          )}
        </Typography>

        <ResultsTag type={getTagType()} objectiveId={objectiveId} />
      </div>

      <Button
        data-testid='category-objective_delete'
        variant='tertiary'
        onClick={() => setIsDeleteModalOpen(true)}
      >
        <Icon src={removeIcon} className='text-base pr-2' />
        {`${intl.get('DELETE')} ${intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE',
          { number: '' }
        )}`}
      </Button>
    </div>
  );
};

export default ObjectiveTopBar;
