import { ChangeEvent } from 'react';
import intl from 'react-intl-universal';
import {
  Dropdown,
  FormItem,
  TextField,
  Typography,
} from '@getsynapse/design-system';
import { ScheduleProjectOption } from 'utils/types/learningSchedule';
import { EventTypesOptions } from 'Pages/LearningSchedule/components/constants';
import { Option } from 'utils/customTypes';
import ProjectsDropdown from './ProjectsDropdown/ProjectsDropdown';

type DetailsSectionProps = {
  changeEventData: (value: any, prop: string) => void;
  projectIds: string[];
  projects: ScheduleProjectOption[];
};

const DetailsSection = ({
  changeEventData,
  projectIds,
  projects,
}: DetailsSectionProps) => {
  const setProjectsIds = (options: Option[]) => {
    changeEventData(
      options.map((option) => option.value),
      'projectIds'
    );
  };

  return (
    <div data-testid='add-event-modal-detail'>
      <Typography variant='body2' className='mt-2'>
        {intl.get('SCHEDULE.EVENTS.DETAILS_SECTION.DETAILS_TITLE')}
      </Typography>
      <FormItem
        label={intl.get('SCHEDULE.EVENTS.DETAILS_SECTION.TITLE_LABEL')}
        className='mt-4'
        labelProps={{
          required: true,
        }}
      >
        <TextField
          placeholder={intl.get(
            'SCHEDULE.EVENTS.DETAILS_SECTION.TITLE_PLACEHOLDER'
          )}
          data-testid='event_title__textfield'
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            changeEventData(event.target.value, 'eventTitle');
          }}
        />
      </FormItem>
      <FormItem
        label={intl.get('SCHEDULE.EVENTS.DETAILS_SECTION.TYPE_LABEL')}
        className='mt-4'
        labelProps={{ required: true }}
      >
        <Dropdown
          options={EventTypesOptions}
          onChange={(option: Option) => {
            changeEventData(option.value, 'learningEventTypeId');
          }}
          triggerProps={{ 'data-testid': 'event_types__trigger' }}
          placeholder={intl.get(
            'SCHEDULE.EVENTS.DETAILS_SECTION.TYPE_PLACEHOLDER'
          )}
        />
      </FormItem>
      <FormItem
        label={intl.get('SCHEDULE.EVENTS.DETAILS_SECTION.PROJECT_LABEL')}
        className='mt-4'
        labelProps={{ required: true }}
      >
        <ProjectsDropdown
          onChange={setProjectsIds}
          value={projectIds}
          projects={projects}
        />
      </FormItem>
      <Typography variant='body2' className='mt-12 pb-4'>
        {intl.get('SCHEDULE.EVENTS.EVENT_SCHEDULE_SECTION.SCHEDULE_TITLE')}
      </Typography>
    </div>
  );
};

export default DetailsSection;
