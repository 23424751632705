import moment from 'moment';
import { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS } from 'utils/constants';
import { WeeksCarouselPagination } from 'utils/types/teamsCapacity';

export const useWeeksCarousel = (
  startDate: string | null,
  endDate: string | null,
  index: number,
  numberOfWeeks: number,
  updatePaginationIndex: (index: number) => void,
  fetchWeeklyCapacity: (newPagination: WeeksCarouselPagination) => void
) => {
  const [currentIndex, setCurrentIndex] = useState(
    TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS
  );
  const prevIndex = useRef<number>(TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS);

  const handleNext = useCallback(() => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
    updatePaginationIndex(currentIndex + 1);
  }, [currentIndex, updatePaginationIndex]);

  const handlePrevious = useCallback(() => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
    updatePaginationIndex(currentIndex - 1);
  }, [currentIndex, updatePaginationIndex]);

  const fetchWeeklyCapacityIfRequired = useCallback(
    (direction: 'previous' | 'next') => {
      if (!endDate || !startDate) {
        return;
      }
      let newStartDate = startDate,
        newEndDate = endDate,
        newIndex = currentIndex;
      if (
        direction === 'next' &&
        currentIndex + TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS === numberOfWeeks
      ) {
        newStartDate = moment(new Date(endDate.replace(/-/g, '/')))
          .startOf('isoWeek')
          .format('YYYY-MM-DD');
        newEndDate = moment(new Date(endDate.replace(/-/g, '/')))
          .add(TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS, 'weeks')
          .format('YYYY-MM-DD');
      }

      if (direction === 'previous' && currentIndex === 0) {
        newStartDate = moment(new Date(startDate.replace(/-/g, '/')))
          .subtract(TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS, 'weeks')
          .format('YYYY-MM-DD');
        newEndDate = moment(new Date(startDate.replace(/-/g, '/')))
          .subtract(1, 'days')
          .format('YYYY-MM-DD');
        newIndex = TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS;
      }

      if (startDate !== newStartDate || endDate !== newEndDate) {
        fetchWeeklyCapacity({
          startDate: newStartDate,
          endDate: newEndDate,
          index: newIndex,
        });
      }
    },
    [currentIndex, startDate, endDate, fetchWeeklyCapacity, numberOfWeeks]
  );

  useEffect(() => {
    if (currentIndex !== prevIndex.current) {
      const direction = currentIndex > prevIndex.current ? 'next' : 'previous';
      prevIndex.current = currentIndex;
      fetchWeeklyCapacityIfRequired(direction);
    }
  }, [currentIndex, fetchWeeklyCapacityIfRequired]);

  useEffect(() => {
    if (index !== prevIndex.current) {
      setCurrentIndex(index);
      prevIndex.current = index;
    }
  }, [index]);

  return useMemo(
    () => ({
      currentIndex,
      handleNext,
      handlePrevious,
    }),
    [currentIndex, handleNext, handlePrevious]
  );
};
