import {
  getStatusColumn,
  getStatusLabel,
} from 'Pages/ProjectsListPage/helpers/tableColumnsValues';
import intl from 'react-intl-universal';
import arrowIcon from 'assets/icons/arrow-long-right-grey.svg';
import { Icon } from '@getsynapse/design-system';

const ProjectStatusNotification: React.FC<{
  projectName?: string;
  currentStatus?: string;
  previousStatus?: string;
  triggererName?: string;
  unread?: boolean;
}> = ({
  projectName,
  triggererName,
  currentStatus,
  previousStatus,
  unread = false,
}) => {
  const projectStatus = getStatusLabel(currentStatus);

  return (
    <div>
      {intl.getHTML('APP_NOTIFICATIONS.PROJECT_STATUS_UPDATED', {
        userName: triggererName,
        projectName: projectName,
        projectStatus,
      })}

      <div className='flex mt-4 gap-2 items-center'>
        <div>{getStatusColumn(previousStatus, unread)}</div>
        <div>
          <Icon src={arrowIcon} />
        </div>
        <div>{getStatusColumn(currentStatus, unread)}</div>
      </div>
    </div>
  );
};

export default ProjectStatusNotification;
