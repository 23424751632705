import {
  SortingType,
  Status,
  FieldsFiltersQueryParams,
  PageSizeType,
  filter,
} from 'utils/customTypes';
import customFieldsAPI from './customFieldsAPI';
import { RootState } from 'state/store';
import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { SLICE_STATUS } from 'utils/constants';
import { get } from 'lodash';
import { FieldTemplateType, FieldDefaultValue } from 'utils/types/fields';
import { orderBy } from 'lodash';

interface CustomFieldsState {
  customFields: FieldTemplateType[];
  currentField: FieldTemplateType | null;
  fieldsUsedInProjects: FieldTemplateType[];
  filters?: filter[];
  pagination: {
    limit: PageSizeType;
    offset: number;
  };
  searchParam: string;
  status: Status;
  totalFields: number;
}

/* ============================= INITIAL STATE ============================== */
const initialState: CustomFieldsState = {
  customFields: [],
  fieldsUsedInProjects: [],
  currentField: null,
  pagination: {
    limit: 15,
    offset: 0,
  },
  searchParam: '',
  status: SLICE_STATUS.IDLE,
  totalFields: 0,
};

const fieldsAPI = customFieldsAPI;

/* ============================== REDUX THUNK =============================== */

export const fetchCustomFields = createAsyncThunk(
  'customFields/FETCH_CUSTOM_FIELDS',
  async (params: {
    searchText: string;
    limit: number;
    offset: number;
    filters?: FieldsFiltersQueryParams[];
  }) => {
    const response = await fieldsAPI.fetchCustomFields(params);
    return response;
  }
);

export const fetchCustomField = createAsyncThunk(
  'customFields/FETCH_CUSTOM_FIELD',
  async (fieldId: string) => {
    const response = await fieldsAPI.fetchCustomField(fieldId);
    return response;
  }
);

export const fetchFieldsUsedInProjects = createAsyncThunk(
  'customFields/FETCH_FIELDS_USED_IN_PROJECTS',
  async () => {
    const response = await fieldsAPI.fetchAllFieldsUsedInProjects();
    return response;
  }
);

export const deleteCustomField = createAsyncThunk(
  'customFields/DELETE_CUSTOM_FIELD',
  async (fieldId: string) => {
    const response = await fieldsAPI.deleteCustomField(fieldId);
    return response;
  }
);

export const createCustomField = createAsyncThunk(
  'customFields/CREATE_CUSTOM_FIELD',
  async (data: FieldTemplateType) => {
    const response = await fieldsAPI.createCustomField(data);
    return response;
  }
);

export const updateCustomField = createAsyncThunk(
  'customFields/UPDATE_CUSTOM_FIELD',
  async ({
    fieldId,
    updateFields,
    defaultValues,
  }: {
    fieldId: string;
    updateFields: FieldTemplateType;
    defaultValues: FieldDefaultValue[];
  }) => {
    const response = await fieldsAPI.updateCustomField(
      fieldId,
      updateFields,
      defaultValues
    );
    if (response.success) {
      return response.data;
    }
    return response;
  }
);

/* ============================== ACTIONS =============================== */

export const setCustomFieldsSortingOptions = createAction(
  'customFields/SET_ORDERS',
  (column: string, order: SortingType) => {
    return { payload: { order: order, orderBy: column } };
  }
);

export const setCustomFieldsSearch = createAction(
  'customFields/SET_SEARCH',
  (search: string) => {
    return { payload: search };
  }
);

export const updatePagination = createAction(
  'customFields/UPDATE_PAGINATION',
  (pagination) => {
    return { payload: pagination };
  }
);

export const setCustomFieldsFilters = createAction<filter[]>(
  'customFields/SET_CUSTOM_FIELDS_FILTERS'
);

export const resetCustomField = createAction('customFields/RESET_CUSTOM_FIELD');

export const resetCustomFields = createAction(
  'customFields/RESET_CUSTOM_FIELDS'
);

/* ================================= REDUCER ================================ */
const customFieldsSlice = createSlice({
  name: 'customFields',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomFields.fulfilled, (state, action) => {
        state.customFields = [...get(action, 'payload.data.rows', [])];
        state.totalFields = get(action, 'payload.data.count', 0);
        const apiCallSucceeded = get(action, 'payload.success', false);
        if (apiCallSucceeded) {
          state.status = SLICE_STATUS.IDLE;
        } else {
          state.status = SLICE_STATUS.FAILED;
        }
      })
      .addCase(fetchCustomFields.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchCustomFields.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchCustomField.fulfilled, (state, action) => {
        state.currentField = action.payload.data;
        const apiCallSucceeded = get(action, 'payload.success', false);
        if (apiCallSucceeded) {
          state.status = SLICE_STATUS.IDLE;
        } else {
          state.status = SLICE_STATUS.FAILED;
        }
      })
      .addCase(fetchFieldsUsedInProjects.fulfilled, (state, action) => {
        state.fieldsUsedInProjects = get(action, 'payload.data', []);
      })
      .addCase(fetchCustomField.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchCustomField.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(setCustomFieldsSearch, (state, action) => {
        state.searchParam = action.payload;
      })
      .addCase(updatePagination, (state, action) => {
        state.pagination = action.payload;
      })
      .addCase(deleteCustomField.fulfilled, (state, action) => {
        state.customFields = state.customFields.filter(
          (field) => field.id !== action.payload.id
        );
      })
      .addCase(updateCustomField.fulfilled, (state, action) => {
        if (get(action, 'payload.updatedCustomField', null)) {
          state.currentField = {
            ...state.currentField,
            ...action.payload.updatedCustomField,
          };
        }
      })
      .addCase(resetCustomField, (state) => {
        state.currentField = null;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(resetCustomFields, (state) => {
        state.customFields = [];
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(createCustomField.fulfilled, (state, action) => {
        state.customFields = state.customFields.concat(action.payload);
      });
  },
});

/* =============================== SELECTORS ================================ */

export const selectAllFields = (state: RootState) =>
  state.customFields.customFields;
export const selectFieldsSliceStatus = (state: RootState) =>
  state.customFields.status;
export const selectCurrentCustomField = (state: RootState) =>
  state.customFields.currentField;
export const selectFieldsTableSearchText = (state: RootState) =>
  state.customFields.searchParam;
export const selectFieldsTablePagination = (state: RootState) =>
  state.customFields.pagination;
export const selectTotalCount = (state: RootState) =>
  state.customFields.totalFields;
export const selectFieldsUsedInProjects = (state: RootState) =>
  orderBy(
    state.customFields.fieldsUsedInProjects,
    [(field) => field.name.toLowerCase()],
    'asc'
  );

export default customFieldsSlice.reducer;
