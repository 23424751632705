import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  FormItem,
  FormLabel,
  Typography,
  UsersPicker,
} from '@getsynapse/design-system';
import { selectAllUsersForDropdown } from 'state/UsersManagement/usersManagementSlice';
import { getProjectOwners } from 'state/Project/projectSlice';
import { selectParticipantIds } from 'state/TasksAssignmentsResourcesCapacity/tasksAssignmentsResourcesCapacitySlice';
import { UserAvatars } from 'utils/customTypes';
import { NewParticipant } from 'utils/types/tasksAssignmentsResourcesCapacity';
import UserAvatar from 'Atoms/UserAvatar';

const ParticipantPicker: React.FC<{
  isUpdating: boolean;
  currentParticipant?: NewParticipant;
  handleChange: (key: keyof NewParticipant, value: string | string[]) => void;
}> = ({ isUpdating, currentParticipant, handleChange }) => {
  const participantIds = useSelector(selectParticipantIds);
  const allAvailableUsers = useSelector(selectAllUsersForDropdown);
  const projectOwners = useSelector(getProjectOwners);

  const usersList = useMemo(
    () =>
      allAvailableUsers.filter(
        (user) =>
          user.value !== currentParticipant?.user_id &&
          !participantIds.includes(user.value) &&
          !projectOwners.includes(user.value)
      ),
    [allAvailableUsers, currentParticipant, participantIds, projectOwners]
  );

  const selectedUsers = useMemo(
    () =>
      allAvailableUsers.filter(
        (user) => user.value === currentParticipant?.user_id
      ),
    [currentParticipant, allAvailableUsers]
  );

  return isUpdating ? (
    <div className='h-full flex flex-col'>
      <FormLabel required>
        {intl.get(
          'PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.PROJECT_PARTICIPANT_FIELD_LABEL'
        )}
      </FormLabel>
      <div className='flex items-center flex-grow'>
        <UserAvatar
          user={{
            avatar_url: selectedUsers[0]?.avatar?.imageSrc,
            data: {
              firstName: selectedUsers[0]?.label.split(' ')[0],
              lastName: selectedUsers[0]?.label.split(' ')[1],
            },
          }}
          className='mr-2'
        />
        <Typography variant='label' className='text-primary'>
          {selectedUsers[0]?.label}
        </Typography>
      </div>
    </div>
  ) : (
    <FormItem
      label={intl.get(
        'PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.PROJECT_PARTICIPANT_FIELD_LABEL'
      )}
      labelProps={{ required: true }}
    >
      <UsersPicker
        usersList={usersList}
        multiple={false}
        selectedUsersList={selectedUsers}
        onChange={(user: UserAvatars) => handleChange('user_id', user.value)}
        triggerText={intl.get(
          'PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.PROJECT_PARTICIPANT_FIELD_PLACEHOLDER'
        )}
        triggerProps={{
          'aria-label': intl.get(
            'PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.PROJECT_PARTICIPANT_FIELD_LABEL'
          ),
          'data-cy': 'participant-user-picker',
        }}
      />
    </FormItem>
  );
};

export default ParticipantPicker;
