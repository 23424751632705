import {
  Dropdown,
  FormItem,
  Modal,
  TextArea,
  TextField,
  Typography,
  UsersPicker,
} from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import addProgramIcon from 'assets/icons/add-program.svg';
import { useMemo, useState, useEffect } from 'react';
import { Program, Option, UserOption } from 'utils/customTypes';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { PROGRAMS_DELIVERY_TYPE, PATHS } from 'utils/constants';
import {
  getLDUsers,
  selectLDUsersForDropdown,
} from 'state/UsersManagement/usersManagementSlice';
import { selectUserId } from 'state/User/userSlice';
import { createNewProgram } from 'state/Programs/programsSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { useHistory } from 'react-router-dom';

type AddProgramModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddProgramModal = ({ isOpen, setIsOpen }: AddProgramModalProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [programData, setProgramData] = useState<Program>({} as Program);
  const ldUsers = useSelector(selectLDUsersForDropdown);
  const currentUserId = useSelector(selectUserId);

  const deliveryTypeOptions = [
    {
      label: intl.get(
        `PROGRAMS_LIST_PAGE.DELIVERY_TYPES.${PROGRAMS_DELIVERY_TYPE.ONLINE.toUpperCase()}`
      ),
      value: PROGRAMS_DELIVERY_TYPE.ONLINE,
    },
    {
      label: intl.get(
        `PROGRAMS_LIST_PAGE.DELIVERY_TYPES.${PROGRAMS_DELIVERY_TYPE.IN_PERSON.toUpperCase()}`
      ),
      value: PROGRAMS_DELIVERY_TYPE.IN_PERSON,
    },
    {
      label: intl.get(
        `PROGRAMS_LIST_PAGE.DELIVERY_TYPES.${PROGRAMS_DELIVERY_TYPE.BLENDED.toUpperCase()}`
      ),
      value: PROGRAMS_DELIVERY_TYPE.BLENDED,
    },
    {
      label: intl.get(
        `PROGRAMS_LIST_PAGE.DELIVERY_TYPES.${PROGRAMS_DELIVERY_TYPE.OTHER.toUpperCase()}`
      ),
      value: PROGRAMS_DELIVERY_TYPE.OTHER,
    },
  ];

  useEffect(() => {
    dispatch(getLDUsers());
  }, [dispatch]);

  const onFieldChange = (prop: string, value: string | string[]) => {
    setProgramData((prevState) => ({
      ...prevState,
      [prop]: value,
    }));
  };

  const errors = useMemo(
    () => !programData.title || isEmpty(get(programData, 'owners', [])),
    [programData]
  );

  const addProgram = async () => {
    setIsOpen(false);

    const data = await dispatch(createNewProgram(programData));
    const createdProgram = get(data, 'payload.createdProgram', null);
    if (isEmpty(createdProgram)) {
      dispatch(
        showNotificationBanner({
          notificationVariant: 'error',
          notificationText: intl.get('ADD_PROGRAM_MODAL.ERROR'),
          timeout: 7000,
        })
      );
    } else {
      const programId = createdProgram.id;
      history.push(`${PATHS.PROGRAM_PAGE}/${programId}`);
    }
    setProgramData({} as Program);
  };

  const ownersList = useMemo(
    () =>
      ldUsers.filter((ldUser) =>
        get(programData, 'owners', [])?.includes(ldUser.value)
      ),
    [ldUsers, programData]
  );

  const changeOwners = (owners: UserOption[]) => {
    const ownersIds: string[] = owners.map((owner) => owner.value);
    onFieldChange('owners', ownersIds);
  };

  useEffect(() => {
    if (currentUserId) {
      onFieldChange('owners', [currentUserId]);
    }
  }, [currentUserId]);

  const cancelHandler = () => {
    setProgramData({} as Program);
    setIsOpen(false);
  };

  return (
    <Modal
      aria-label={intl.get('ADD_PROGRAM_MODAL.MODAL_TITLE')}
      data-testid='add-program_modal'
      closeModal={() => setIsOpen(false)}
      title={intl.get('ADD_PROGRAM_MODAL.MODAL_TITLE')}
      titleIcon={{
        src: addProgramIcon,
      }}
      size='large'
      isOpen={isOpen}
      actionButtons={[
        {
          children: intl.get('ADD'),
          variant: 'primary',
          onClick: addProgram,
          disabled: errors,
          'data-testid': 'save-program_button',
        },
        {
          children: intl.get('CANCEL'),
          variant: 'tertiary',
          onClick: cancelHandler,
        },
      ]}
    >
      <>
        <Typography>{intl.get('ADD_PROGRAM_MODAL.CAPTION')}</Typography>
        <div className='grid grid-cols-2 gap-x-10 gap-y-4 mt-4'>
          <FormItem
            label={intl.get('ADD_PROGRAM_MODAL.TITLE')}
            labelProps={{ required: true }}
          >
            <TextField
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onFieldChange('title', event.target.value)
              }
              value={programData.title}
              data-cy='program-title_input'
              data-testid='program-title_input'
              placeholder={intl.get('ADD_PROGRAM_MODAL.TITLE_PLACEHOLDER')}
            />
          </FormItem>
          <FormItem
            label={intl.get('ADD_PROGRAM_MODAL.DESCRIPTION')}
            className='row-span-2'
          >
            <TextArea
              textAreaProps={{
                className: 'max-h-32',
                placeholder: intl.get(
                  'ADD_PROGRAM_MODAL.DESCRIPTION_PLACEHOLDER'
                ),
                'data-cy': 'program-description_input',
              }}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                onFieldChange('description', event.target.value)
              }
            />
          </FormItem>
          <FormItem label={intl.get('ADD_PROGRAM_MODAL.DELIVERY_TYPE')}>
            <Dropdown
              options={deliveryTypeOptions}
              onChange={(option: Option) =>
                onFieldChange('delivery_type', option.value)
              }
              triggerProps={{
                placeholder: intl.get(
                  'ADD_PROGRAM_MODAL.DELIVERY_TYPE_PLACEHOLDER'
                ),
                'data-cy': 'delivery-type_dropdown',
              }}
              listProps={{
                'data-cy': 'delivery-type_list',
              }}
            />
          </FormItem>
          <FormItem
            label={intl.get('ADD_PROGRAM_MODAL.OWNERS')}
            labelProps={{ required: true }}
          >
            <UsersPicker
              usersList={ldUsers}
              maxLimit={2}
              selectedUsersList={ownersList}
              onChange={changeOwners}
              triggerProps={{ 'data-testid': 'program-owners_picker' }}
            />
          </FormItem>
        </div>
      </>
    </Modal>
  );
};

export default AddProgramModal;
