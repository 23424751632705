import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';

class TaskAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchTeamTasks = async (params?: {}) => {
    const { data } = await this.instance.get('task/retrieveTeamTasks', {
      params,
    });
    return data;
  };

  fetchUserTasks = async (params?: {}) => {
    const { data } = await this.instance.get('task/retrieveUserTasks', {
      params,
    });
    return data;
  };
}

const taskAPI = new TaskAPI();

export default taskAPI;
