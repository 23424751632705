import { Typography, tailwindOverride } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

export const EmptySuggestion = () => {
  return (
    <div
      className={tailwindOverride(
        'w-full flex items-center justify-between px-4 py-2 shadow-raised left-0 top-0'
      )}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className='flex items-center'>
        <Typography variant='body2' className={'ml-2 text-neutral-black'}>
          {intl.get('TASKS.TASK_DETAIL_PAGE.COMMENTS.NO_SUGGESTIONS')}
        </Typography>
      </div>
    </div>
  );
};
