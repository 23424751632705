import { PROJECT_STATUS } from 'utils/constants';

const DEFAULT_PROGRESS_BAR_MAX_VALUE = 1;

export const decideMaxValueBasedOnProjectStatus = (
  projectStatus: string,
  completedTasksCount: number,
  incompletedTasksCount: number
) => {
  return projectStatus === PROJECT_STATUS.COMPLETED &&
    completedTasksCount === 0 &&
    incompletedTasksCount === 0
    ? DEFAULT_PROGRESS_BAR_MAX_VALUE
    : completedTasksCount + incompletedTasksCount;
};

export const decideCurrentProgressValueBasedOnProjectStatus = (
  projectStatus: string,
  completedTasksCount: number
) => {
  return projectStatus === PROJECT_STATUS.COMPLETED && completedTasksCount === 0
    ? DEFAULT_PROGRESS_BAR_MAX_VALUE
    : completedTasksCount;
};
