import React, {
  useState,
  useEffect,
  ChangeEvent,
  ReactNode,
  useCallback,
} from 'react';
import { CheckboxGroup } from '@getsynapse/design-system';
import {
  CheckboxFieldTemplateType,
  CheckboxFieldType,
  SelectValue,
} from 'utils/types/fields';

interface CheckboxFieldProps {
  field?: CheckboxFieldType;
  fieldTemplate: CheckboxFieldTemplateType;
  onChange: (newValue: SelectValue | { val: SelectValue[] }) => void;
  disabled?: boolean;
}

interface CheckboxItem {
  label: ReactNode | string;
  checked?: boolean;
  value: string | number;
}

const setInitialValues: (
  allowedValues: CheckboxFieldProps['fieldTemplate']['allowed_values'],
  defaultValue: CheckboxFieldProps['fieldTemplate']['default_value'],
  field: CheckboxFieldProps['field']
) => [CheckboxItem[], Record<string | number, SelectValue>] = (
  allowedValues,
  defaultValue,
  field
) => {
  let initialValues: SelectValue[] = [];
  if (field) {
    initialValues = field.value.val || [];
  } else if (defaultValue) {
    initialValues = defaultValue.val;
  }
  const initialValuesHash: Record<CheckboxItem['value'], boolean> = {};
  initialValues.forEach((value) => (initialValuesHash[value.val!] = true));

  const newOptions: CheckboxItem[] = [];
  const newOptionsHash: Record<string | number, SelectValue> = {};
  allowedValues.forEach((option) => {
    newOptionsHash[option.val!] = option;
    newOptions.push({
      label: option.display || option.val!.toString(),
      checked: Boolean(initialValuesHash[option.val!]),
      value: option.val!,
    });
  });

  return [newOptions, newOptionsHash];
};

const setNewValues: (
  optionsHash: Record<string | number, SelectValue>,
  options: CheckboxItem[],
  value: string
) => [CheckboxItem[], SelectValue[] | null] = (optionsHash, options, value) => {
  const newValue: SelectValue[] = [];
  const newOptions: CheckboxItem[] = [];

  options.forEach((option) => {
    newOptions.push({
      ...option,
      checked: option.value === value ? !option.checked : option.checked,
    });

    if (option.value === value && !option.checked) {
      newValue.push(optionsHash[option.value]);
    } else if (option.value !== value && option.checked) {
      newValue.push(optionsHash[option.value]);
    }
  });

  return [newOptions, newValue.length > 0 ? newValue : null];
};

const CheckboxField: React.FC<CheckboxFieldProps> = ({
  field,
  fieldTemplate,
  onChange,
  disabled = false,
}) => {
  const [options, setOptions] = useState<CheckboxItem[]>([]);
  const [optionsHash, setOptionsHash] = useState<
    Record<string | number, SelectValue>
  >({});

  useEffect(() => {
    const [newOptions, newOptionsHash] = setInitialValues(
      fieldTemplate.allowed_values,
      fieldTemplate.default_value,
      field
    );
    setOptions(newOptions);
    setOptionsHash(newOptionsHash);
  }, [field, fieldTemplate.allowed_values, fieldTemplate.default_value]);

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      const [newOptions, newValue] = setNewValues(optionsHash, options, value);
      setOptions(newOptions);
      onChange(newValue ? { val: newValue } : { val: [] });
    },
    [optionsHash, onChange, options]
  );

  return (
    <div>
      <CheckboxGroup
        options={options}
        disabled={disabled}
        onElementChange={onChangeHandler}
      />
    </div>
  );
};

export default CheckboxField;
