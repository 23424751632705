import {
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  LabelList,
  TooltipProps,
} from 'recharts';
import { Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import {
  selectOverallAllocationRate,
  selectBusinessTeamsDemand,
  selectBusinessTeamsDemandStatus,
} from 'state/Insights/capacitySlice';
import { SLICE_STATUS } from 'utils/constants';
import ChartSkeletonLoader from '../UtliztionByTeam/ChartSkeletonLoader';
import styles from '../UtliztionByTeam/UtilizatonByTeamChart.module.css';
import { useState, useMemo } from 'react';
import legend from 'assets/images/demand-by-bu-legend.svg';
import classNames from 'classnames';
import truncate from 'lodash/truncate';
import {
  X_Y_AXIS_STYLE,
  AXIS_COLOR,
  BAR_HOVER_COLOR,
  DEMAND_BAR_COLOR,
  ALLOCATION_BAR_COLOR,
  X_AXIS_LINE_COLOR,
  DATA_LABELS_STYLE,
} from './constants';
import NoData from '../components/NoData';
import LoadingError from '../components/LoadingError';
import { wordWrap } from 'Pages/helpers';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';

const AllocationVsDemandByBUChart = () => {
  const tooltipClassnames =
    'px-2 py-0.5 bg-neutral-white border border-purple-lighter text-neutral-dark text-sm leading-6 z-50';

  const chartTitle = (
    <div className='w-full'>
      <p className='text-3.5 leading-2 text-neutral-black font-semibold'>
        {intl.get('CAPACITY_INSIGHTS.BUSINESS_UNITS_DEMAND.TITLE')}
      </p>
      <Typography variant='caption' className='text-neutral-dark'>
        {intl.get('CAPACITY_INSIGHTS.BUSINESS_UNITS_DEMAND.CAPTION')}
      </Typography>
    </div>
  );

  const businessUnitsDemand = useSelector(selectBusinessTeamsDemand);
  const businessUnitsDemandStatus = useSelector(
    selectBusinessTeamsDemandStatus
  );
  const overallAllocation = useSelector(selectOverallAllocationRate);

  const [yAxisTooltipCoordinates, setYAxisTooltipCoordinates] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [yAxisTooltipValue, setyAxisTooltipValue] = useState<string>('');

  const chartHeight = useMemo(() => {
    let height;
    if (businessUnitsDemand && businessUnitsDemand!.length > 7) {
      height = businessUnitsDemand!.length * 45;
    } else {
      height = '100%';
    }
    return height;
  }, [businessUnitsDemand]);

  const renderChartTooltip = (props: TooltipProps<ValueType, NameType>) => {
    const { active, payload } = props;

    return (
      active &&
      payload?.length && (
        <div className='bg-neutral-white rounded border border-purple-lighter p-2 shadow-raised'>
          <div className='flex items-center'>
            <div className='w-3.5	h-3.5 rounded-sm bg-secondary-light mr-2'></div>
            <Typography className='text-purple-darker' variant='body2'>
              {`${intl.get(
                'CAPACITY_INSIGHTS.BUSINESS_UNITS_DEMAND.DEMAND'
              )} : ${payload[0].payload.demand}h`}
            </Typography>
          </div>
          <div className='flex items-center'>
            <div className='w-3.5	h-3.5 rounded-sm bg-secondary-darker mr-2'></div>
            <Typography className='text-purple-darker' variant='body2'>
              {`${intl.get(
                'CAPACITY_INSIGHTS.BUSINESS_UNITS_DEMAND.ALLOCATION'
              )} : ${payload[0].payload.allocation}h`}
            </Typography>
          </div>
          <Typography className='text-purple-darker' variant='body2'>
            {`${intl.get(
              'CAPACITY_INSIGHTS.BUSINESS_UNITS_DEMAND.ALLOCATION_RATE'
            )} : ${payload[0].payload.allocationRate}%`}
          </Typography>
        </div>
      )
    );
  };

  const renderBars = (
    props: {
      x: number;
      y: number;
      width: number;
      height: number;
      fill: string;
      allocationRate: number;
    },
    flag: number
  ) => {
    const { x, y, width, height, fill, allocationRate } = props;
    const roundedCornersPath = `M${x},${y}L ${x + width - 2},${y}A 2,2,0,0,1,
        ${x + width},${y + 2}L ${x + width},${y + height - 2}A 2,2,0,0,1,
        ${x + width - 2},${y + height}L ${x},${y + height}Z`;
    const normalCornersPath = `M${x},${y}L ${x + width},${y}L ${x + width},${
      y + height
    }L ${x},${y + height}Z`;
    let d;
    if (allocationRate === 100) {
      d = roundedCornersPath;
    } else {
      d = flag === 0 ? roundedCornersPath : normalCornersPath;
    }

    return (
      <g className='recharts-layer recharts-bar-rectangle' role='img'>
        <path
          fill={fill}
          width={width}
          height={height}
          x={x}
          y={y}
          className='recharts-rectangle'
          d={d}
        />
      </g>
    );
  };

  const renderYAxisTicks = (props: {
    x: number;
    y: number;
    payload: { value: string };
  }) => {
    const { x, y, payload } = props;
    const tickValue = payload.value;
    let valueFirstLine = '',
      valueSecondLine = '';
    const isTextLong = tickValue.length >= 25;
    const doesTextNeedTrucation = tickValue.length >= 46;
    if (isTextLong) {
      const valueAfterTruncation = doesTextNeedTrucation
        ? truncate(tickValue, {
            length: 46,
          })
        : tickValue;
      const textWithLineBreaks = wordWrap(valueAfterTruncation, 25);
      valueFirstLine = textWithLineBreaks.substring(
        0,
        textWithLineBreaks.indexOf('\n')
      );
      valueSecondLine = valueAfterTruncation.substring(
        textWithLineBreaks.indexOf('\n')
      );
    }

    return (
      <g className='recharts-layer recharts-cartesian-axis-tick'>
        <text
          width='150'
          orientation='left'
          height='262'
          stroke='none'
          fill={AXIS_COLOR}
          className='recharts-text recharts-cartesian-axis-tick-value'
          x={x}
          y={y}
          textAnchor='end'
          style={X_Y_AXIS_STYLE}
          onMouseEnter={(event) => {
            if (doesTextNeedTrucation) {
              setYAxisTooltipCoordinates({
                x: event.clientX,
                y: event.clientY,
              });
              setyAxisTooltipValue(tickValue);
            }
          }}
          onMouseLeave={() => {
            if (doesTextNeedTrucation) {
              setYAxisTooltipCoordinates(null);
            }
          }}
        >
          {isTextLong ? (
            <>
              <tspan x={x} dy='-0.145em'>
                {valueFirstLine}
              </tspan>
              <tspan x={x} dy='1em'>
                {valueSecondLine}
              </tspan>
            </>
          ) : (
            tickValue
          )}
        </text>
      </g>
    );
  };

  return (
    <div
      className='flex flex-col w-full p-6'
      data-testid='bu-allocation-demand_chart'
    >
      {businessUnitsDemandStatus === SLICE_STATUS.LOADING ? (
        <ChartSkeletonLoader />
      ) : (
        <>
          {businessUnitsDemand?.length ? (
            <>
              <div className='flex justify-between'>
                {chartTitle}
                <div>
                  <Typography variant='body2' className='leading-4 text-right'>
                    {intl.get(
                      'CAPACITY_INSIGHTS.BUSINESS_UNITS_DEMAND.OVERALL_ALLOCATION'
                    )}
                  </Typography>
                  <p className='text-xl font-semibold text-right'>{`${overallAllocation}%`}</p>
                </div>
              </div>
              <div
                className={classNames(
                  'overflow-y-auto overflow-x-hidden h-full flex mt-18 flex-col',
                  styles.scrollbar
                )}
              >
                {yAxisTooltipCoordinates && (
                  <div
                    className={classNames(
                      tooltipClassnames,
                      'rounded fixed shadow-raised max-w-60 z-1 pointer-events-none	'
                    )}
                    style={{
                      left: yAxisTooltipCoordinates?.x,
                      top: yAxisTooltipCoordinates?.y,
                    }}
                  >
                    {yAxisTooltipValue}
                  </div>
                )}
                <ResponsiveContainer
                  width='100%'
                  height={chartHeight}
                  id='bu-allocation-demand_bar_chart'
                >
                  <BarChart
                    layout='vertical'
                    data={businessUnitsDemand}
                    margin={{
                      right: 16,
                      left: 16,
                    }}
                  >
                    <XAxis
                      tickLine={false}
                      style={X_Y_AXIS_STYLE}
                      type='number'
                      tickCount={5}
                      padding={{ right: 32, left: 8 }}
                      label={{
                        value: intl.get(
                          'CAPACITY_INSIGHTS.BUSINESS_UNITS_DEMAND.HOURS'
                        ),
                        position: 'insideBottomRight',
                        offset: -0.1,
                        style: X_Y_AXIS_STYLE,
                        dx: -16,
                        fill: AXIS_COLOR,
                      }}
                      tick={{ stroke: 'none', fill: AXIS_COLOR }}
                      stroke={X_AXIS_LINE_COLOR}
                    />
                    <YAxis
                      tickLine={false}
                      style={X_Y_AXIS_STYLE}
                      dataKey='teamName'
                      type='category'
                      axisLine={false}
                      width={150}
                      tick={renderYAxisTicks}
                      yAxisId={0}
                    />
                    <YAxis
                      dataKey='teamName'
                      type='category'
                      hide
                      yAxisId={1}
                    />
                    <ChartTooltip
                      content={renderChartTooltip}
                      cursor={{ fill: BAR_HOVER_COLOR }}
                    />
                    <Bar
                      dataKey='demand'
                      fill={DEMAND_BAR_COLOR}
                      barSize={16}
                      yAxisId={1}
                      shape={(props) => renderBars(props, 0)}
                    >
                      <LabelList
                        dataKey='demand'
                        position='right'
                        style={DATA_LABELS_STYLE}
                        formatter={(value: number) => `${value}h`}
                      />
                    </Bar>
                    <Bar
                      dataKey='allocation'
                      fill={ALLOCATION_BAR_COLOR}
                      barSize={16}
                      yAxisId={0}
                      shape={(props) => renderBars(props, 1)}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className='flex justify-center w-full mt-4'>
                <img
                  src={legend}
                  className='w-40 h-4'
                  alt={intl.get(
                    'CAPACITY_INSIGHTS.BUSINESS_UNITS_DEMAND.CHART_LEGEND'
                  )}
                  data-testid='bu-allocation-demand_chart-legend'
                />
              </div>
            </>
          ) : businessUnitsDemand ? (
            <>
              {chartTitle}
              <NoData />
            </>
          ) : (
            <>
              {chartTitle}
              <LoadingError />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AllocationVsDemandByBUChart;
