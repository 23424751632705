import intl from 'react-intl-universal';
import get from 'lodash/get';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import {
  Tag,
  AvatarGroup,
  Tooltip,
  tailwindOverride,
  Typography,
} from '@getsynapse/design-system';
import {
  PROJECT_STATUS,
  PROJECT_BUDGET_SOURCE,
  PROJECT_PRIVACY,
  TASK_STATUS,
  DATE,
} from 'utils/constants';
import {
  Owner,
  Project,
  ProjectOwner,
  ProjectCollaborator,
  ProjectParticipant,
  AvatarSize,
  Task,
} from 'utils/customTypes';
import UserAvatar from 'Atoms/UserAvatar';
import moment from 'moment';
import { isTaskDueDatePast } from 'Pages/ProjectPage/tabs/Tasks/helpers/helpers';
import type { LearningTeam } from 'utils/types/learningTeam';
import type { BusinessTeam } from 'utils/types/businessTeams';
import { isUserStatusDisabled } from 'utils/functions';

type keys = keyof typeof PROJECT_STATUS;
export type ProjectStatus = typeof PROJECT_STATUS[keys];

export const getStatusStyle: (
  status: ProjectStatus,
  selected?: boolean
) => { className: string; textClassName: string } = (
  status,
  selected = false
) => {
  switch (status) {
    case PROJECT_STATUS.NEW:
      return {
        className: classnames(
          'bg-purple-lightest group-hover:border-purple-dark',
          { 'border-purple-dark': selected }
        ),
        textClassName: 'text-purple-darker',
      };
    case PROJECT_STATUS.IN_PLANNING:
      return {
        className: classnames(
          'bg-warning-lightest group-hover:border-warning-light',
          { 'border-warning-light': selected }
        ),
        textClassName: 'text-warning-darker',
      };
    case PROJECT_STATUS.IN_PROGRESS:
      return {
        className: classnames('bg-teal-lightest group-hover:border-teal', {
          'border-teal': selected,
        }),
        textClassName: 'text-teal-dark',
      };
    case PROJECT_STATUS.COMPLETED:
      return {
        className: classnames(
          'bg-success-lightest group-hover:border-success-dark',
          { 'border-success-dark': selected }
        ),
        textClassName: 'text-success-darker',
      };
    case PROJECT_STATUS.ON_HOLD:
      return {
        className: classnames(
          'bg-tertiary-lightest group-hover:border-tertiary',
          { 'border-tertiary': selected }
        ),
        textClassName: 'text-tertiary-darker',
      };
    case PROJECT_STATUS.CANCELED:
      return {
        className: classnames('bg-error-lightest group-hover:border-error', {
          'border-error': selected,
        }),
        textClassName: 'text-error-darker',
      };
    case PROJECT_STATUS.CLOSED:
      return {
        className: classnames(
          'bg-secondary-lightest group-hover:border-secondary',
          { 'border-secondary': selected }
        ),
        textClassName: 'text-secondary-darker',
      };
    default:
      return { className: '', textClassName: '' };
  }
};
export const getStatusPillStyle: (status: ProjectStatus) => string = (
  status
) => {
  switch (status) {
    case PROJECT_STATUS.NEW:
      return 'bg-purple-dark';
    case PROJECT_STATUS.IN_PROGRESS:
      return 'bg-teal';
    case PROJECT_STATUS.IN_PLANNING:
      return 'bg-warning-light';
    case PROJECT_STATUS.COMPLETED:
      return 'bg-success-darker';
    case PROJECT_STATUS.ON_HOLD:
      return 'bg-tertiary-light';
    case PROJECT_STATUS.CLOSED:
      return 'bg-secondary-dark';
    default:
      return '';
  }
};

export const getStatusLabel: (status: ProjectStatus) => string = (status) => {
  const key = `${status}`.toUpperCase();
  return intl.get(`PROJECT_DETAIL.STATUS_OPTIONS.${key}`).defaultMessage('');
};

export const getStatusColumn = (
  status: ProjectStatus,
  selected?: boolean,
  className?: string
) => {
  if (!status) {
    return null;
  }
  const tagStyle = getStatusStyle(status, selected);
  const tagLabel = getStatusLabel(status);
  return (
    <Tag
      className={tailwindOverride(
        `text-xs border border-transparent py-0.5 ${tagStyle.className} ${className}`
      )}
      textClassName={tagStyle.textClassName}
      label={tagLabel}
    />
  );
};

export const getStatusPill = (status: ProjectStatus) => {
  const className = getStatusPillStyle(status);
  const tagLabel = getStatusLabel(status);
  return (
    <Tooltip
      showPopper
      openMode='hover1'
      timeout={0}
      usePortal
      contentProps={{ className: 'z-200000 py-1 px-3' }}
      trigger={
        <div>
          <Tag
            className={tailwindOverride(
              `border cursor-pointer border-transparent w-7 h-2 ${className}`
            )}
            label=''
          />
        </div>
      }
    >
      {tagLabel}
    </Tooltip>
  );
};

export const getResourcingTypeLabel = (type: string | undefined) => {
  return type
    ? intl.get(`PROJECT_DETAIL.RESOURCING_TYPE_OPTIONS.${type.toUpperCase()}`)
    : '';
};

export const getBudgetSourceLabel = (type: string | undefined) => {
  if (!type) {
    return '';
  }
  const budgetKey = Object.keys(PROJECT_BUDGET_SOURCE).find(
    (key) => PROJECT_BUDGET_SOURCE[key] === type
  );
  return budgetKey
    ? intl.get(`PROJECT_DETAIL.BUDGET_SOURCE_OPTIONS.${budgetKey}`)
    : '';
};

export const getOwnerColumn = (
  owners: Owner[],
  size: AvatarSize = 'small',
  hideName: boolean = false
) => {
  if (owners.length === 1 && !hideName) {
    return (
      <div className='flex items-center'>
        <UserAvatar
          size={size}
          user={{
            avatar_url: owners[0].avatar_url,
            status: owners[0].status,
            data: {
              firstName: get(owners[0], 'data.firstName'),
              lastName: get(owners[0], 'data.lastName'),
            },
          }}
          className='mr-2.5'
        />
        {`${get(owners, '0.data.firstName')} ${get(owners, '0.data.lastName')}`}
      </div>
    );
  } else {
    return (
      <AvatarGroup
        avatars={owners.map((owner) => {
          const firstName = get(owner, 'data.firstName');
          const lastName = get(owner, 'data.lastName');
          return {
            size: 'medium',
            imageSrc: owner.avatar_url,
            initial: get(firstName, '[0]'),
            name: `${firstName} ${lastName}`,
            disabled: isUserStatusDisabled(owner.status),
          };
        })}
        deactivatedText={intl.get('DEACTIVATED')}
      />
    );
  }
};

export const getProjectRoleColumn = (
  userId: string | undefined,
  project: Project
) => {
  if (!userId || !project) {
    return '';
  }

  const isOwner = project.owners
    ? project.owners.some(
        (owner: ProjectOwner) => owner.project_owners.userId === userId
      )
    : false;

  if (isOwner) {
    return intl.get('PROJECT_DETAIL.USER_ROLE.OWNER');
  }

  const isCollaborator = project.collaborators
    ? project.collaborators.some(
        (collaborator: ProjectCollaborator) =>
          collaborator.project_collaborators.userId === userId
      )
    : false;

  if (isCollaborator) {
    return intl.get('PROJECT_DETAIL.USER_ROLE.COLLABORATOR');
  }

  const isMember = project.participants
    ? project.participants.some(
        (participant: ProjectParticipant) =>
          participant.project_participants.userId === userId
      )
    : false;

  if (isMember) {
    return intl.get('PROJECT_DETAIL.USER_ROLE.MEMBER');
  }
};

export const getValueById = (list: any, name: string, id: string) => {
  if (isEmpty(list) || !id) {
    return '';
  }
  const selectedValue = list.find((option: any) => option.id === id);
  if (selectedValue) {
    return selectedValue[name];
  } else {
    return '';
  }
};

export const getProjectBusinessUnitColumn = (businessTeams: BusinessTeam[]) => {
  const businessTeamsNames = businessTeams
    .map((businessTeam: BusinessTeam) => businessTeam.title)
    .join(', ')
    .trim();
  return (
    <Tooltip
      openMode='hover1'
      position='topCenter'
      contentProps={{
        className: classnames(
          'bg-neutral-darker shadow-tooltip',
          'rounded-lg px-4 py-3',
          'absolute',
          'w-max whitespace-normal',
          'text-body',
          'z-50'
        ),
      }}
      timeout={0}
      showPopper
      className={`w-full truncate`}
      trigger={<span>{businessTeamsNames}</span>}
    >
      {businessTeamsNames}
    </Tooltip>
  );
};

export const getTeamsColumn = (
  projectPrivacy: string,
  learningTeams: LearningTeam[]
) => {
  const ldTeams = learningTeams
    .map((learningTeam: LearningTeam) => learningTeam.name)
    .join(', ')
    .trim();
  switch (projectPrivacy) {
    case PROJECT_PRIVACY.PRIVATE:
      return intl.get('PROJECT_DETAIL.PRIVACY_OPTIONS.PRIVATE');
    case PROJECT_PRIVACY.TEAM:
      return (
        <Tooltip
          openMode='hover1'
          position='topCenter'
          contentProps={{
            className: classnames(
              'bg-neutral-darker shadow-tooltip',
              'rounded-lg px-4 py-3',
              'absolute',
              'w-max whitespace-normal',
              'text-body',
              'z-50'
            ),
          }}
          timeout={0}
          showPopper
          className={`w-full truncate`}
          trigger={<span>{ldTeams}</span>}
        >
          {ldTeams}
        </Tooltip>
      );
    default:
      return intl.get('PROJECT_DETAIL.PRIVACY_OPTIONS.PUBLIC');
  }
};

export const getPriorityLabel = (priority: string) => {
  if (!priority) {
    return intl.get('PROJECT_DETAIL.PRIORITY_OPTIONS.NONE');
  }
  return intl.get(`PROJECT_DETAIL.PRIORITY_OPTIONS.${priority.toUpperCase()}`);
};

export const getDueAndActualDateColumn = ({
  status,
  due_date,
  completion_date,
  id,
}: Partial<Task>) => {
  const isTaskCompleted = status === TASK_STATUS.COMPLETED;
  const isTaskPastDueDate = isTaskDueDatePast(status, due_date);
  const dueDate = due_date
    ? moment(new Date(due_date)).format(DATE.TASK_TABLE_FORMAT)
    : '';
  const actualDate =
    completion_date &&
    moment(new Date(completion_date)).format(DATE.TASK_TABLE_FORMAT);
  return (
    <Typography
      variant='label'
      className={classnames('text-primary', {
        'bg-error-lightest text-error-darker': isTaskPastDueDate,
        'text-neutral-dark': !dueDate,
      })}
      data-testid={`task-${id}__due-n-actual-date`}
    >
      {`${!dueDate && !actualDate ? intl.get('TASKS.TABLE.NO_DATE') : dueDate}${
        isTaskCompleted && actualDate ? ` / ${actualDate}` : ''
      }`}
    </Typography>
  );
};
