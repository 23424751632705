import { useCallback, useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SidePanel } from '@getsynapse/design-system';
import get from 'lodash/get';
import intl from 'react-intl-universal';
import useSidePanel from 'Hooks/useSidePanel';
import useSnackbarNotification from 'Hooks/useSnackbarNotification';
import {
  selectIsSidePanelOpen,
  closeSidePanel,
} from 'state/ManageTimeOff/SidePanel/sidePanelSlice';
import { selectTimeOffEntryId } from 'state/ManageTimeOff/TimeOffDays/timeOffDaysSlice';
import userAPI from 'state/User/userAPI';
import { selectUserId, selectUserDailyCapacity } from 'state/User/userSlice';
import useGetUserId from 'Pages/TimeOffListPage/hooks/useGetUserId';
import EditTimeOff from '../EditTimeOff/EditTimeOff';
import AddTimeOff from '../AddTimeOff/AddTimeOff';

const TimeOffEntrySidePanel = () => {
  const dispatch = useDispatch();
  const targetUserId = useGetUserId();
  const [dailyCapacity, setDailyCapacity] = useState<number>(0);
  const [shouldSaveChanges, setShouldSaveChanges] = useState<boolean>(false);
  const [canSaveChanges, setCanSaveChanges] = useState<boolean>(false);
  const isSidePanelOpen = useSelector(selectIsSidePanelOpen);
  const timeOffEntryToEdit = useSelector(selectTimeOffEntryId);
  const currentUserId = useSelector(selectUserId);
  const currentUserDailyCapacity = useSelector(selectUserDailyCapacity);

  const closePanelCallback = useCallback(() => {
    dispatch(closeSidePanel());
  }, [dispatch]);

  const fetchUserDailyCapacity = useCallback(async (userId: string) => {
    const userData = await userAPI.getUserById(userId);
    const defaultCapacity = get(userData, 'data.user.default_capacity');
    const dailyCapacity = defaultCapacity / 5;
    setDailyCapacity(dailyCapacity);
  }, []);

  const { openPanel, isPanelOpen, closePanel, onClose } =
    useSidePanel(closePanelCallback);

  const {
    isNotificationVisible,
    snackbarProps,
    showNotification,
    SnackbarNotification,
  } = useSnackbarNotification();

  useEffect(() => {
    if (isSidePanelOpen) {
      openPanel();
    }
  }, [openPanel, isSidePanelOpen]);

  useEffect(() => {
    if (targetUserId && currentUserId && targetUserId !== currentUserId) {
      fetchUserDailyCapacity(targetUserId);
    } else {
      setDailyCapacity(currentUserDailyCapacity);
    }
  }, [
    targetUserId,
    currentUserDailyCapacity,
    currentUserId,
    fetchUserDailyCapacity,
  ]);

  const saveButtonLabel = useMemo(() => {
    let label = intl.get('ADD');
    if (timeOffEntryToEdit) {
      label = intl.get('UPDATE');
    }
    return label;
  }, [timeOffEntryToEdit]);

  const onSaveChanges = useCallback(
    (callback) => {
      callback();
      setShouldSaveChanges(false);
      setCanSaveChanges(false);
      closePanel();
    },
    [closePanel]
  );

  const footerButtons = useMemo(
    () => [
      {
        children: saveButtonLabel,
        disabled: !canSaveChanges,
        onClick: () => setShouldSaveChanges(true),
      },
      {
        children: intl.get('CANCEL'),
        variant: 'tertiary',
        onClick: closePanel,
      },
    ],
    [closePanel, saveButtonLabel, canSaveChanges]
  );

  return (
    <>
      <SidePanel
        isOpen={isPanelOpen}
        onClose={onClose}
        footerButtons={footerButtons}
      >
        {timeOffEntryToEdit && (
          <EditTimeOff
            timeOffEntryId={timeOffEntryToEdit}
            dailyCapacity={dailyCapacity}
            setCanSaveChanges={setCanSaveChanges}
            shouldSaveChanges={shouldSaveChanges}
            onSaveChanges={onSaveChanges}
            showNotification={showNotification}
          />
        )}
        {!timeOffEntryToEdit && (
          <AddTimeOff
            onSaveChanges={onSaveChanges}
            shouldSaveChanges={shouldSaveChanges}
            setCanSaveChanges={setCanSaveChanges}
            showNotification={showNotification}
            dailyCapacity={dailyCapacity}
          />
        )}
      </SidePanel>
      {isNotificationVisible && snackbarProps && (
        <SnackbarNotification {...snackbarProps} />
      )}
    </>
  );
};

export default TimeOffEntrySidePanel;
