import { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { IconButton, tailwindOverride } from '@getsynapse/design-system';
import {
  selectWeeksAndDays,
  selectNumberOfDays,
  selectDatesRange,
  moveToNextDay,
  moveToPreviousDay,
} from 'state/DailyTeamsCapacity/Days/daysSlice';
import { fetchMoreDailyTeamsCapacity } from 'state/DailyTeamsCapacity/TeamsCapacity/teamsCapacitySlice';
import { fetchMoreDailyTeamMembersCapacity } from 'state/DailyTeamsCapacity/TeamMembersCapacity/teamMembersCapacitySlice';
import { fetchMoreDailyTeamMembeProjectsCapacity } from 'state/DailyTeamsCapacity/TeamMemberProjectsCapacity/teamMemberProjectsCapacitySlice';
import { FetchMoreDailyCapacityParams } from 'utils/types/dailyTeamsCapacity';
import WeekDaysHeader from './components/WeekDaysHeader/WeekDaysHeader';

const HeaderDaysCarousel = () => {
  const dispatch = useDispatch();
  const promises = useRef<Array<FetchMoreDailyCapacityParams>>([]);
  const fetching = useRef<boolean>(false);
  const weekAndDays = useSelector(selectWeeksAndDays);
  const numberOfDays = useSelector(selectNumberOfDays);
  const { startDate, endDate } = useSelector(selectDatesRange);

  const handleFetchMoreDailyCapacity = (
    params: FetchMoreDailyCapacityParams
  ) => {
    dispatch(fetchMoreDailyTeamsCapacity(params));
    dispatch(fetchMoreDailyTeamMembersCapacity(params));
    dispatch(fetchMoreDailyTeamMembeProjectsCapacity(params));
  };

  const fetchMoreDailyCapacity = async () => {
    if (promises.current.length > 0 && !fetching.current) {
      fetching.current = true;
      for (const promise of promises.current) {
        handleFetchMoreDailyCapacity(promise);
      }
      promises.current = [];
      fetching.current = false;
    }
  };

  const debouncedFetchMoreDailyCapacity = debounce(fetchMoreDailyCapacity, 500);

  const handlePrevious = () => {
    dispatch(moveToPreviousDay());
    promises.current.push({ startDate, endDate, link: 'prevDay' });
    debouncedFetchMoreDailyCapacity();
  };

  const handleNext = async () => {
    dispatch(moveToNextDay());
    promises.current.push({ startDate, endDate, link: 'nextDay' });
    debouncedFetchMoreDailyCapacity();
  };

  return (
    <div
      className='w-full max-w-full flex h-20 flex-grow-0'
      aria-label='daily-teams-capacity__days-carousel'
    >
      <div
        className={tailwindOverride('w-6 h-full py-3', 'flex justify-center')}
      >
        <IconButton
          name='chevron-back'
          onClick={handlePrevious}
          className='text-primary text-lg'
          aria-label='daily-teams-capacity__days-carousel__previous-button'
          data-cy='daily-teams-capacity__days-carousel__previous-button'
        />
      </div>
      <div className={tailwindOverride('w-full  h-full flex')}>
        {Object.keys(weekAndDays).map((week, index) => {
          return (
            <WeekDaysHeader
              key={`week-${index}`}
              weekStart={week}
              weekDays={weekAndDays[week]}
              numberOfDays={numberOfDays}
            />
          );
        })}
      </div>
      <div
        className={tailwindOverride('w-6 h-full py-2', 'flex justify-center')}
      >
        <IconButton
          name='chevron-forward'
          onClick={handleNext}
          className='text-primary text-lg'
          aria-label='daily-teams-capacity__days-carousel__next-button'
          data-cy='daily-teams-capacity__days-carousel__next-button'
        />
      </div>
    </div>
  );
};

export default HeaderDaysCarousel;
