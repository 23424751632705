const ROICategorySkeleton = () => (
  <div data-testid='roi-skeleton-loader' className='px-8 pt-2 w-2/3'>
    <div className='animate-pulse w-1/4 bg-neutral-lighter-two h-4 mb-3' />
    <div className='animate-pulse w-3/4 bg-neutral-lighter-two h-4 mb-7' />
    <div className='animate-pulse w-full bg-neutral-lighter-two h-32 mb-14' />
    <div className='animate-pulse w-1/4 bg-neutral-lighter-two h-4 mb-3' />
    <div className='flex mb-2'>
      <div className='animate-pulse w-3/4 bg-neutral-lighter-two h-4 mr-10' />
      <div className='animate-pulse w-1/5 bg-neutral-lighter-two h-4' />
    </div>
    <div className='animate-pulse w-2/3 bg-neutral-lighter-two h-4 mb-7' />
    <div className='animate-pulse w-full bg-neutral-lighter-two h-20 mb-14' />
    <div className='animate-pulse w-1/4 bg-neutral-lighter-two h-4 mb-3' />
    <div className='animate-pulse w-4/5 bg-neutral-lighter-two h-4 mb-7' />
    <div className='animate-pulse w-full bg-neutral-lighter-two h-72' />
  </div>
);

export default ROICategorySkeleton;
