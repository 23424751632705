import React from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import get from 'lodash/get';
import { Typography } from '@getsynapse/design-system';
import { useHistory, useLocation } from 'react-router-dom';

const TabLink: React.FC<{
  tabKey: string;
  isActive: boolean;
  setCurrentTab: (tabKey: string) => void;
  fromSidePanel: boolean;
}> = ({ tabKey, isActive, setCurrentTab, fromSidePanel }) => {
  const { pathname, state } = useLocation();
  const history = useHistory();
  const fromPage = get(state, 'from', '');

  const handleChangeTab = () => {
    setCurrentTab(tabKey);
    if (fromPage) {
      history.push(
        `${pathname}?tab=${tabKey}${
          fromSidePanel ? '&fromSidePanel=true' : ''
        }`,
        { from: fromPage }
      );
    } else {
      history.push(
        `${pathname}?tab=${tabKey}${fromSidePanel ? '&fromSidePanel=true' : ''}`
      );
    }
  };

  return (
    <div
      data-cy={`tab-${tabKey}`}
      className={classnames(
        'cursor-pointer ml-6 border-b-2',
        'hover:border-primary-darker',
        {
          'border-primary': isActive,
          'border-transparent': !isActive,
        }
      )}
      onClick={handleChangeTab}
    >
      <Typography
        variant='body2'
        className={classnames(
          'capitalize',
          'hover:text-primary-darker hover:border-primary-darker hover:font-semibold',
          {
            'text-primary-dark font-normal': !isActive,
            'text-primary font-semibold': isActive,
          }
        )}
      >
        {intl.get(`UPDATE_PROJECT_PAGE_TABS.${tabKey.toUpperCase()}`)}
      </Typography>
    </div>
  );
};

export default TabLink;
