import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { tailwindOverride } from '@getsynapse/design-system';
import { fetchTeamsCapacityStatus } from 'state/TeamsCapacity/teamsCapacitySlice';
import WeeksCarousel from './WeeksCarousel';
import { WeeksCarouselLoader } from './SkeletonLoader';

const TableHeader: React.FC<{ numberOfTeams: number }> = ({
  numberOfTeams,
}) => {
  const capacityTableStatus = useSelector(fetchTeamsCapacityStatus);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  useEffect(() => {
    let isMounted = true;
    if (capacityTableStatus === 'loading' && !isFetching && isMounted) {
      setIsFetching(true);
    }
    if (capacityTableStatus === 'idle' && isFetching && isMounted) {
      setTimeout(() => {
        if (isMounted) {
          setIsFetching(false);
        }
      }, 1000);
    }
    return () => {
      isMounted = false;
    };
  }, [isFetching, capacityTableStatus]);
  return (
    <thead
      className={tailwindOverride(
        'h-10 bg-primary-lightest font-semibold text-primary text-xs',
        'sticky top-0 z-5'
      )}
    >
      <tr>
        <th className='w-2/6'>
          <div className={tailwindOverride('flex w-full h-full relative')}>
            <div className='w-10' />
            <div
              className={tailwindOverride(
                'w-3/5 h-full px-4',
                'flex items-center'
              )}
            >
              {intl.get('TEAMS_CAPACITY.TEAMS_AND_MEMBERS_COLUMN_TITLE', {
                numberOfTeams,
              })}
            </div>
            <div
              className={tailwindOverride(
                'w-2/5 h-full flex',
                'items-center px-4'
              )}
            >
              {intl.get('ORG_SETTINGS_PAGE.LEARNING_TEAMS.ROLES', {
                op: 2,
              })}
            </div>
          </div>
        </th>
        <th className='w-4/6 h-full relative'>
          {isFetching && (
            <WeeksCarouselLoader
              className={tailwindOverride(
                'w-full',
                'absolute top-0 left-0',
                'bg-primary-lightest',
                'z-10'
              )}
            />
          )}
          <WeeksCarousel />
        </th>
      </tr>
    </thead>
  );
};

export default TableHeader;
