import { FC } from 'react';
import intl from 'react-intl-universal';
import { Typography, tailwindOverride } from '@getsynapse/design-system';
import { LinkedTaskContentUploader } from 'types/store/linkedTaskContent';
import { isUserStatusDisabled } from 'utils/functions';

interface ContentUploaderProps {
  uploader: LinkedTaskContentUploader;
  createdAt: string;
  className?: string;
}

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});

const ContentUploader: FC<ContentUploaderProps> = ({
  uploader,
  createdAt,
  className = '',
}) => {
  const userIsDisabled = isUserStatusDisabled(uploader.status);

  const formatDate = (dateString: string): string => {
    return dateFormatter.format(new Date(dateString));
  };

  return (
    <Typography variant='label' className={tailwindOverride(className)}>
      {intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.TOOLTIP_CONTENT.ADDED_BY')}

      <span
        className={tailwindOverride('mx-1', {
          'text-neutral-light': userIsDisabled,
        })}
      >
        {uploader.name}
        {userIsDisabled && ` (${intl.get('DEACTIVATED')})`}
      </span>

      {intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.TOOLTIP_CONTENT.ON_DATE', {
        date: formatDate(createdAt),
      })}
    </Typography>
  );
};

export default ContentUploader;
