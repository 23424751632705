import { IconButton, Typography } from '@getsynapse/design-system';

const FilterTags = ({ selectedFilterCategoryValue, removeTag }: any) => {
  return (
    <div className='flex flex-grow flex-wrap'>
      {selectedFilterCategoryValue.map((data: any) => (
        <div className='flex flex-row items-center py-1 px-2 rounded-full outline-none group cursor-pointer focus:shadow-skim group bg-secondary-lightest hover:bg-secondary-darker mr-1 mb-1 last:mr-0'>
          <Typography
            variant='h4'
            weight='medium'
            className='font-body lg:text-caption max-w-35 max-h-4 truncate overflow-hidden text-xs font-semibold group-hover:text-secondary-lighter text-secondary-dark'
          >
            {data.label}
          </Typography>
          <IconButton
            name='close-circle'
            className='ml-1.5 flex items-center text-lg font-semibold group-hover:text-secondary-lighter text-secondary-dark max-w-max'
            onClick={() => removeTag(data.id, false)}
          />
        </div>
      ))}
    </div>
  );
};

export default FilterTags;
