import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Icon, Typography, tailwindOverride } from '@getsynapse/design-system';
import { toggleExpandTeam } from 'state/Capacities/TeamsList/teamsListSlice';

const TeamData: React.FC<{
  teamId: string;
  teamName: string;
  isExpanded: boolean;
}> = ({ teamId, teamName, isExpanded = false }) => {
  const dispatch = useDispatch();
  const prevExpandedStatus = useRef<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleExpanded = () => {
    dispatch(toggleExpandTeam({ teamId, expanded: !expanded }));
    prevExpandedStatus.current = !expanded;
    setExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (isExpanded !== prevExpandedStatus.current) {
      prevExpandedStatus.current = isExpanded;
      setExpanded(isExpanded);
    }
  }, [isExpanded]);

  return (
    <div
      className={tailwindOverride('w-2/6 h-full flex')}
      onClick={toggleExpanded}
    >
      <div
        className={tailwindOverride(
          'w-10 h-full px-2',
          'flex items-center justify-end'
        )}
      >
        <Icon
          name={expanded ? 'chevron-up' : 'chevron-down'}
          className='text-primary-dark text-lg'
        />
      </div>
      <div
        className={tailwindOverride(
          'h-full flex flex-1 items-center justify-start',
          'px-4'
        )}
      >
        <Typography
          variant='caption'
          weight='medium'
          className='text-primary-darker line-clamp-2'
          title={teamName}
        >
          {teamName}
        </Typography>
      </div>
    </div>
  );
};

export default TeamData;
