import { FC, useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { SidePanelFilter } from '@getsynapse/design-system';
import { TaskListFilters } from 'types/store/tasksList';
import { fetchProjects } from 'state/Projects/projectsSlice';
import { fetchPrograms } from 'state/Programs/programsSlice';
import { getAllUsers } from 'state/UsersManagement/usersManagementSlice';
import FiltersForm from './FiltersForm/FiltersForm';

interface TasksFiltersSidePanelProps {
  open: boolean;
  onClose: () => void;
  filters: TaskListFilters;
  onUpdateFilters: (filters: TaskListFilters) => void;
  hideAssigneeFilter?: boolean;
}

const TasksFiltersSidePanel: FC<TasksFiltersSidePanelProps> = ({
  open,
  onClose,
  filters,
  onUpdateFilters,
  hideAssigneeFilter = false,
}) => {
  const dispatch = useDispatch();
  const appliedFiltersCount = Object.keys(filters).length || 0;
  const firstRender = useRef(true);
  const [canApplyFilters, setCanApplyFilters] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<TaskListFilters>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (filters) {
      setAppliedFilters(filters);
    }
  }, [filters]);

  const init = useCallback(async () => {
    await Promise.all([
      dispatch(fetchProjects()),
      dispatch(fetchPrograms()),
      dispatch(getAllUsers()),
    ]);
    setIsLoading(false);
  }, [dispatch]);

  useEffect(() => {
    if (firstRender.current && open) {
      firstRender.current = false;
      init();
    }
  }, [init, open]);

  const handleClearAllFilters = () => {
    onUpdateFilters({});
    setAppliedFilters({});
    setCanApplyFilters(false);
  };

  const handleUpdateFilters = (updatedFilters: TaskListFilters) => {
    setAppliedFilters(updatedFilters);
    setCanApplyFilters(true);
  };

  const handleApplyFilters = () => {
    onUpdateFilters(appliedFilters);
    setCanApplyFilters(false);
  };

  return (
    <SidePanelFilter
      open={open}
      onClose={onClose}
      appliedFiltersCount={appliedFiltersCount}
      canApplyFilters={canApplyFilters}
      onClearAllFilters={handleClearAllFilters}
      onApplyFilters={handleApplyFilters}
    >
      {isLoading ? (
        'Loading...'
      ) : (
        <FiltersForm
          filters={appliedFilters}
          onUpdateFilters={handleUpdateFilters}
          hideAssigneeFilter={hideAssigneeFilter}
        />
      )}
    </SidePanelFilter>
  );
};

export default TasksFiltersSidePanel;
