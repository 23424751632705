import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  selectInReviewRequestsForModal,
  setRequestsInReviewOrderForModal,
} from 'state/MyWorkspace/myWorkspaceSlice';
import RequestsTable from './RequestsTable';
import MyWorspaceModal from '../MyWorkspaceModal';

type RequestsModalProps = {
  icon: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const RequestsModal = ({ icon, isOpen, setIsOpen }: RequestsModalProps) => {
  const dispatch = useDispatch();
  const requestsSelector = useSelector(selectInReviewRequestsForModal);

  const handleSort = (orderByParam: string[], order: 'desc' | 'asc') => {
    dispatch(
      setRequestsInReviewOrderForModal({ order, orderBy: orderByParam })
    );
  };

  return (
    <MyWorspaceModal
      title={intl.get('MY_WORKSPACE.IN_REVIEW_REQUESTS.TITLE')}
      icon={icon}
      content={
        <RequestsTable
          data={requestsSelector}
          handleSort={handleSort}
          cardTable={false}
        />
      }
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dataCy='requests-in-review-modal'
    />
  );
};

export default RequestsModal;
