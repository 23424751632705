import { api } from './api';
import axios from 'axios';

export interface FetchProjectProgressParams {
  projectId: string;
  userId?: string;
}

export interface FetchProjectProgressResponse {
  progress: {
    totalTasks: string;
    completedTasks: string;
    estimateHours: string;
    actualHours: string;
  };
}

export const fetchProjectProgress = async (
  params: FetchProjectProgressParams
) => {
  let url = `/v2/projects/${params.projectId}/progress`;
  if (params.userId) {
    url = `${url}?userId=${params.userId}`;
  }
  const response = await api.get<FetchProjectProgressResponse>(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};
