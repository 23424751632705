import { Card } from 'Pages/InsightsPage/Components/Card';
import DemandCapAvailabilityChart from './DemandCapAvailability/DemandCapAvailabilityChart';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAggregates,
  fetchBusinessTeamsDemand,
  fetchDCATrend,
  fetchDemandByProject,
  fetchResourceTypeAllocation,
  fetchTeamsUtilization,
  fetchTaskTypeEstimation,
  fetchResourceUtilization,
} from 'state/Insights/capacitySlice';
import { INSIGHTS_DATE_FILTER_NAME } from 'utils/constants';
import AggregatesNumbers from './AggregatesNumbers/AggregatesNumbers';
import UtlizationByTeamChart from './UtliztionByTeam/UtilizationByTeamChart';
import DemandByProjectChart from './DemandByProjectCategory/DemandByProjectCategoryChart';
import AllocationByResourceTypeChart from './AllocationByResourceType/AllocationByResourceTypeChart';
import AllocationVsDemandByBUChart from './AllocationVsDemandByBU/AllocationVsDemandByBUChart';
import FiltersPanel from './Filters/FiltersPanel';
import { getFilters, setActiveFilters } from 'state/Insights/capacitySlice';
import moment from 'moment';
import EstimatedVsActualByTaskTypeChart from './EstimatedVsActualByTaskType/EstimatedVsActualByTaskTypeChart';
import UtilizationByResourcesChart from './UtilizationByResources/UtilizationByResourcesChart';

const Capacity = () => {
  const dispatch = useDispatch();
  const [isFilterReady, setIsFilterReady] = useState(false);
  const filters = useSelector(getFilters);
  const getLocalStorageDateObject = (key: string, defaultValue: any) => {
    const storedDate = window.localStorage.getItem(key);

    return storedDate ? moment(storedDate).format() : defaultValue;
  };

  useEffect(() => {
    const setFilters = async () => {
      await dispatch(
        setActiveFilters({
          startDate: getLocalStorageDateObject(
            INSIGHTS_DATE_FILTER_NAME.CAPACITY_DATE_FILTER_DATE_RANGE
              .START_DATE,
            ''
          ),
          endDate: getLocalStorageDateObject(
            INSIGHTS_DATE_FILTER_NAME.CAPACITY_DATE_FILTER_DATE_RANGE.END_DATE,
            ''
          ),
          businessUnitIds: filters && filters.businessUnitIds,
        })
      );
    };
    if (!isFilterReady) {
      setFilters();
    }
    setIsFilterReady(true);
  }, [dispatch, filters, isFilterReady]);

  useEffect(() => {
    if (isFilterReady) {
      dispatch(fetchAggregates(filters));
      dispatch(fetchDCATrend(filters));
      dispatch(fetchTeamsUtilization(filters));
      dispatch(fetchDemandByProject(filters));
      dispatch(fetchBusinessTeamsDemand(filters));
      dispatch(fetchResourceTypeAllocation(filters));
      dispatch(fetchTaskTypeEstimation(filters));
      dispatch(fetchResourceUtilization({ filters, sort: 'desc' }));
      dispatch(fetchResourceUtilization({ filters, sort: 'asc' }));
    }
  }, [dispatch, filters, isFilterReady]);

  return (
    <div className='flex h-insights w-full relative space-x-2'>
      <FiltersPanel />
      <div
        data-testid='capacity-insights-page'
        className='w-full space-y-2 overflow-y-auto h-insights'
      >
        <AggregatesNumbers />
        <Card className='flex w-full h-80'>
          <DemandCapAvailabilityChart />
        </Card>
        <div className='flex w-full h-124'>
          <Card className='w-half-width-minus-0.25 mr-2 flex'>
            <UtlizationByTeamChart />
          </Card>
          <Card className='w-half-width-minus-0.25 flex'>
            <AllocationVsDemandByBUChart />
          </Card>
        </div>
        <div className='flex w-full h-124'>
          <Card className='w-half-width-minus-0.25 mr-2 flex'>
            <AllocationByResourceTypeChart />
          </Card>
          <Card className='w-half-width-minus-0.25 flex'>
            <DemandByProjectChart />
          </Card>
        </div>
        <div className='flex w-full h-124'>
          <Card className='w-half-width-minus-0.25 mr-2 flex'>
            <EstimatedVsActualByTaskTypeChart />
          </Card>
          <Card className='w-half-width-minus-0.25 flex'>
            <UtilizationByResourcesChart />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Capacity;
