import React from 'react';
import {
  useElevation,
  tailwindOverride,
  Typography,
  IconButton,
} from '@getsynapse/design-system';
import { getTruncatedFileNameAndExtension } from 'Pages/ProjectPage/tabs/Content/components/ContentTable/utils';
import Thumbnail from './Thumbnail';
import { MAX_FILE_NAME_LENGTH } from './constants';
import { EvaluationEvidenceItem } from 'utils/types/program';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';

const Card: React.FC<{
  file: EvaluationEvidenceItem;
  removeFile: (fileId: string) => void;
  readOnly: boolean;
}> = ({ file, removeFile, readOnly }) => {
  const dispatch = useDispatch();
  const shadowElevation = useElevation(1);
  let displayName: string;
  let displayExtension: string | undefined = '';

  if (file.fileName.length > MAX_FILE_NAME_LENGTH) {
    let { name, extension } = getTruncatedFileNameAndExtension(
      file.fileName,
      MAX_FILE_NAME_LENGTH
    );
    displayName = name;
    displayExtension = extension;
  } else {
    displayName = file.fileName;
    displayExtension = file.fileType;
  }

  const checkFileUrlExpiry = (event: any) => {
    const url = new URL(file.fileUrl!);
    const urlParams = new URLSearchParams(url.search);
    const expires = urlParams.get('Expires');
    const currentTimestamp = new Date();
    const expiresTimestamp = new Date(Number(expires));
    if (currentTimestamp > expiresTimestamp) {
      dispatch(
        showSnackbarNotification({
          autoHide: false,
          notificationVariant: 'error',
          notificationTitle: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.EVIDENCE.DOWNLOAD_ERROR_TITLE'
          ),
          notificationMessage: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.EVIDENCE.DOWNLOAD_ERROR_MESSAGE'
          ),
        })
      );
      event.preventDefault();
    }
  };

  return (
    <div
      key={file.id}
      data-testid={`file-thumbnail-${file.id}`}
      className={tailwindOverride(
        'p-1 pr-2 w-full h-12 border-r border-l border-neutral-lighter-two bg-neutral-white flex items-center mb-2 justify-between',
        'rounded-sm group',
        shadowElevation
      )}
    >
      <div className='flex items-center'>
        <Thumbnail
          evidenceId={file.id!}
          contentType={file.fileType}
          fileUrl={file.fileUrl!}
        />
        {file.fileName && !(file.fileName.length > MAX_FILE_NAME_LENGTH) ? (
          <Typography
            variant='label'
            className='py-2 px-4 text-primary truncate'
            weight='normal'
            data-testid={`file-name-${file.id}`}
          >
            {displayName}
          </Typography>
        ) : (
          <>
            <Typography
              variant='label'
              className='py-2 pl-4 text-primary truncate'
              weight='normal'
              data-testid={`file-name-${file.id}`}
            >
              {displayName}
            </Typography>
            <Typography
              variant='label'
              className='text-primary pr-4'
              weight='normal'
              data-testid={`file-name-extension-${file.id}`}
            >
              {displayExtension}
            </Typography>
          </>
        )}
      </div>
      <div className='flex items-center'>
        <a
          onClick={checkFileUrlExpiry}
          href={file.fileUrl}
          target='_blank'
          rel='noreferrer'
          download
          data-cy={`file-download-link-${file.id}`}
        >
          <IconButton
            name='cloud-download'
            hasASize={false}
            className='text-neutral p-1'
            data-cy={`file-download-${file.id}`}
          />
        </a>
        {!readOnly && (
          <IconButton
            name='trash'
            className='ml-1 text-neutral p-1'
            hasASize={false}
            onClick={() => removeFile(file.id!)}
            data-cy={`file-delete-${file.id}`}
          />
        )}
      </div>
    </div>
  );
};

export default Card;
