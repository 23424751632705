import { Dropdown, FormItem } from '@getsynapse/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ComponentProps, useMemo } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { selectAllProjects } from 'state/Projects/projectsSlice';
import { selectUser } from 'state/User/userSlice';
import { Option } from 'utils/customTypes';
import { canAddTaskToProject } from 'utils/permissionChecker';
import {
  selectLdTeamAndSubTeams,
  selectLdTeamMemberIds,
} from 'state/LearningTeams/learningTeamsSlice';

interface ProjectPickerProps {
  dropdownProps: Partial<ComponentProps<typeof Dropdown>>;
}

const ProjectPicker: React.FC<ProjectPickerProps> = ({ dropdownProps }) => {
  const { tasksAssignmentsBasedResourcesCapacity = false } = useFlags();
  const projects = useSelector(selectAllProjects);
  const user = useSelector(selectUser);
  const userManagedTeams = useSelector(selectLdTeamAndSubTeams);
  const teamMemberIds = useSelector(selectLdTeamMemberIds);

  const projectOptions = useMemo<Option[]>(() => {
    return projects
      .filter((project) =>
        canAddTaskToProject(
          project,
          user,
          userManagedTeams,
          teamMemberIds,
          tasksAssignmentsBasedResourcesCapacity
        )
      )
      .map((project) => ({
        label: project.title,
        value: project.id,
      }))
      .sort((optionA, optionB) => optionA.label.localeCompare(optionB.label));
  }, [
    projects,
    tasksAssignmentsBasedResourcesCapacity,
    teamMemberIds,
    user,
    userManagedTeams,
  ]);

  return (
    <FormItem
      label={intl.get('ENTITIES.PROJECT', { num: 1 })}
      labelProps={{ required: true }}
    >
      <Dropdown
        onChange={dropdownProps.onChange || (() => {})}
        options={projectOptions}
        triggerProps={{
          'data-cy': 'project-selector',
          'data-testid': 'project-selector',
          placeholder: intl.get('TASKS.ADD_TASK_MODAL.PROJECT_PLACEHOLDER'),
        }}
      />
    </FormItem>
  );
};

export default ProjectPicker;
