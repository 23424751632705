import intl from 'react-intl-universal';
import NavigationButton from './NavigationButton';
import ActionButton from './ActionButton';
import { useRequestContext } from 'Pages/RequestPage/context/RequestContext';

export const LDOnlyButton = ({
  onUpdate,
  onCancel,
  hasErrors,
}: {
  onUpdate: () => Promise<void>;
  onCancel: () => void;
  hasErrors: boolean;
}) => {
  const { isAdditionalDisabled, isLDDisabled } = useRequestContext();
  const disableSaveButtons = isAdditionalDisabled && isLDDisabled;
  return (
    <div className='flex space-x-4 h-7.5 w-full justify-end'>
      <NavigationButton
        onClickHandler={onCancel}
        variant='tertiary'
        label={intl.get('CANCEL')}
      />

      <ActionButton
        action={onUpdate}
        variant='primary'
        hasErrors={hasErrors}
        testId='request_update-button'
        disabled={disableSaveButtons || hasErrors}
      >
        {intl.get('REQUEST_PAGE.REQUEST_DETAILS.BUTTON.UPDATE_REQUEST')}
      </ActionButton>
    </div>
  );
};

export default LDOnlyButton;
