import { useCallback, useMemo } from 'react';
import intl from 'react-intl-universal';
import orderBy from 'lodash/orderBy';
import { TASK_ADDITONAL_FLAGS, TASK_STATUS, TASK_TYPES } from 'utils/constants';
import { RangeFilter, CentralizedTasksFiltersKey } from 'utils/types/filters';
import { Option, ProgramLinkedProject } from 'utils/customTypes';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectAllUsersForDropdown } from 'state/UsersManagement/usersManagementSlice';
import { selectAllPrograms } from 'state/Programs/programsSlice';
import { selectAllProjects } from 'state/Projects/projectsSlice';
import { formatProgramNumber, formatProjectNumber } from 'utils/formatters';

function useTasksFilters(selectedPrograms: string[]) {
  const usersList = useSelector(selectAllUsersForDropdown);
  const programsList = useSelector(selectAllPrograms);
  const projectsList = useSelector(selectAllProjects);

  const additonalFlagOptions = useMemo(() => {
    const options = Object.keys(TASK_ADDITONAL_FLAGS).map((key) => ({
      value: TASK_ADDITONAL_FLAGS[key],
      label: intl.get(`TASKS.TASK_DETAIL_PAGE.ADDITIONAL_FLAGS_LABELS.${key}`),
    }));
    return orderBy(options, 'label');
  }, []);

  const statusOptions = useMemo(() => {
    const options = Object.keys(TASK_STATUS).map((key) => ({
      value: TASK_STATUS[key],
      label: intl.get(`TASKS.TASK_DETAIL_PAGE.STATUS_OPTIONS.${key}`),
    }));
    return orderBy(options, 'label');
  }, []);

  const typeOptions = useMemo(() => {
    const options = Object.keys(TASK_TYPES).map((key) => ({
      value: intl.get(`TASKS.TASK_TYPES.${key}`),
      label: intl.get(`TASKS.TASK_TYPES.${key}`),
    }));
    return orderBy(options, 'label');
  }, []);

  const programOptions = useMemo<Option[]>(() => {
    const options = programsList.map((program) => ({
      value: program.id,
      label: `${formatProgramNumber(program.program_number)} - ${
        program.title
      }`,
    }));
    return orderBy(options, 'label');
  }, [programsList]);

  const projectOptions = useMemo(() => {
    if (selectedPrograms.length > 0) {
      const projects = programsList
        .filter((program) => selectedPrograms.includes(program.id))
        .map((program) => program.programProjects)
        .flat()
        .reduce((filteredProjects, project) => {
          const foundProject = filteredProjects.find(
            (filteredProject) => filteredProject.id === project.id
          );
          if (!foundProject) {
            return filteredProjects.concat([project]);
          } else {
            return filteredProjects;
          }
        }, [] as ProgramLinkedProject[])
        .map((project) => ({
          value: project.id,
          label: project.title,
        }));

      return orderBy(projects, 'label');
    } else {
      const options = projectsList.map((project) => ({
        value: project.id,
        label: `${formatProjectNumber(project.projectNumber)} - ${
          project.title
        }`,
      }));
      return orderBy(options, 'label');
    }
  }, [programsList, projectsList, selectedPrograms]);

  const tasksFiltersOptions: Record<
    CentralizedTasksFiltersKey,
    Option[] | RangeFilter
  > = useMemo(
    () => ({
      additionalFlags: additonalFlagOptions,
      status: statusOptions,
      type: typeOptions,
      assignedUsers: usersList,
      start_date: { from: '', to: '' },
      due_date: { from: '', to: '' },
      completion_date: { from: '', to: '' },
      estimate_hours: { from: '', to: '' },
      actual_hours: { from: '', to: '' },
      program: programOptions,
      project: projectOptions,
    }),
    [
      statusOptions,
      typeOptions,
      usersList,
      programOptions,
      projectOptions,
      additonalFlagOptions,
    ]
  );

  const getDateFilterLabel = useCallback(
    (value: RangeFilter, dateFormat: string) => {
      let label = '';
      if (value.from) {
        label = `${moment(new Date(value.from)).format(dateFormat)}`;
      }
      if (value.from && value.to) {
        label = `${label} - ${moment(new Date(value.to)).format(dateFormat)}`;
      } else if (!value.from && value.to) {
        label = `${moment(new Date(value.to)).format(dateFormat)}`;
      }
      return label;
    },
    []
  );

  const getHoursRangeFilterLabel = useCallback((value: RangeFilter) => {
    let label = '';
    if (value.from) {
      label = `${value.from}`;
    }
    if (value.from && value.to) {
      label = `${label} - ${value.to}`;
    }
    if (!value.from && value.to) {
      label = `${value.to}`;
    }
    return label.length > 0 ? `${label} hrs` : ``;
  }, []);

  const getFilterOptionLabelByKeyAndValue = useCallback(
    (key: CentralizedTasksFiltersKey, value: string) => {
      const options = tasksFiltersOptions[key] as Option[];
      const option = options.find((option: Option) => option.value === value);
      return option ? option.label : '';
    },
    [tasksFiltersOptions]
  );

  const getFilterOptionsByKey = useCallback(
    (key: CentralizedTasksFiltersKey) => tasksFiltersOptions[key] as Option[],
    [tasksFiltersOptions]
  );

  return {
    getFilterOptionsByKey,
    getDateFilterLabel,
    getHoursRangeFilterLabel,
    getFilterOptionLabelByKeyAndValue,
  };
}

export default useTasksFilters;
