import {
  useState,
  FormEventHandler,
  ChangeEvent,
  useMemo,
  useEffect,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import Auth from '@aws-amplify/auth';
import {
  Button,
  TextField,
  Typography,
  Tooltip,
  IconButton,
} from '@getsynapse/design-system';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { PATHS } from 'utils/constants';
import PasswordTooltip from 'Atoms/PasswordTooltip/PasswordTooltip';

const ChangePassword = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    password: '',
    passwordConfirm: '',
    error: '',
    isSubmitting: false,
  });

  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const username = query.get('username');
  const code = query.get('code');

  useEffect(() => {
    if (query && (!username || !code)) {
      history.push(PATHS.LOGIN);
    }
  }, [query, username, code, history]);

  const strength = useMemo(() => {
    if (data.password) {
      const length = data.password.length;
      if (length >= 16) {
        return intl.get('CHANGE_PASSWORD_PAGE.PASSWORD_LEVEL.STRONG');
      } else if (length >= 12) {
        return intl.get('CHANGE_PASSWORD_PAGE.PASSWORD_LEVEL.MEDIUM');
      } else {
        return intl.get('CHANGE_PASSWORD_PAGE.PASSWORD_LEVEL.WEAK');
      }
    } else return '';
  }, [data.password]);

  const passwordMatch = useMemo(() => {
    return data.password && data.password === data.passwordConfirm;
  }, [data]);

  const confirmProps = useMemo((): {
    state?: 'success' | 'error';
    helpText?: string;
  } | void => {
    if (passwordMatch) {
      return {
        state: 'success',
        helpText: intl.get('CHANGE_PASSWORD_PAGE.INPUT.MATCHED'),
      };
    } else if (data.passwordConfirm) {
      return {
        state: 'error',
        helpText: intl.get('CHANGE_PASSWORD_PAGE.INPUT.NO_MATCH'),
      };
    }
  }, [passwordMatch, data.passwordConfirm]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    setData({ ...data, isSubmitting: true });
    if (username && code && data.password) {
      try {
        await Auth.forgotPasswordSubmit(username, code, data.password);
        history.push(PATHS.LOGIN);
        setData({ ...data, isSubmitting: false });
      } catch (error: any) {
        dispatch(
          showNotificationBanner({
            notificationVariant: 'error',
            notificationText: get(error, 'message') || '',
            timeout: 7000,
          })
        );
        if (error.message) {
          setData({ ...data, error: error.message, isSubmitting: false });
        }
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      error: '',
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className='max-w-xs'>
      <Typography
        variant='h2'
        className='mb-6 flex items-center justify-center'
      >
        {intl.get('CHANGE_PASSWORD_PAGE.TITLE')}
        <Tooltip
          className='pl-2'
          contentProps={{
            className:
              'bg-warning-lighter text-warning-dark text-xs rounded px-4 py-2 w-56 absolute z-40',
          }}
          trigger={
            <IconButton
              description='trigger'
              name='information-circle'
              iconClassname='text-xl text-warning-dark'
            />
          }
          position='bottomRight'
        >
          {intl.get('CHANGE_PASSWORD_PAGE.INFO.TITLE')}
          <ul className='list-disc pl-5'>
            {intl.getHTML('CHANGE_PASSWORD_PAGE.INFO.CONTENT')}
          </ul>
        </Tooltip>
      </Typography>

      <Typography className='mb-6'>
        {intl.get('CHANGE_PASSWORD_PAGE.PROMPT')}
      </Typography>

      <form onSubmit={handleSubmit}>
        <PasswordTooltip
          trigger={
            <TextField
              name='password'
              className='mb-8'
              label={intl.get('CHANGE_PASSWORD_PAGE.INPUT.PASSWORD')}
              placeholder={intl.get(
                'CHANGE_PASSWORD_PAGE.INPUT.PASSWORD_PLACEHOLDER'
              )}
              onChange={handleChange}
              variant='password'
              value={data.password}
              state={data.error ? 'error' : undefined}
              helpText={
                strength && data.error
                  ? intl.get('CHANGE_PASSWORD_PAGE.INPUT.INCORRECT_FORMAT')
                  : strength && (
                      <Typography
                        className='text-neutral-black'
                        variant='caption'
                      >
                        <strong className='font-semibold'>
                          {intl.get(
                            'CHANGE_PASSWORD_PAGE.PASSWORD_LEVEL.STRENGTH'
                          )}
                        </strong>
                        {strength}
                      </Typography>
                    )
              }
            />
          }
          className='top-15 z-20'
          password={data.password}
          setIsPasswordValid={setIsPasswordValid}
        />

        <TextField
          name='passwordConfirm'
          label={intl.get('CHANGE_PASSWORD_PAGE.INPUT.CONFIRM')}
          placeholder={intl.get(
            'CHANGE_PASSWORD_PAGE.INPUT.CONFIRM_PLACEHOLDER'
          )}
          className='mb-10'
          onChange={handleChange}
          variant='password'
          value={data.passwordConfirm}
          {...confirmProps}
        />

        <Button
          type='submit'
          className='w-full justify-center'
          disabled={!passwordMatch || !isPasswordValid}
          loading={data.isSubmitting}
        >
          {intl.get('CHANGE_PASSWORD_PAGE.BUTTON')}
        </Button>
      </form>
    </div>
  );
};

export default ChangePassword;
