export const AXIS_COLOR = '#506268';

export const X_Y_AXIS_STYLE = {
  fontSize: '0.75rem',
  fontWeight: '400',
  lineHeight: '1rem',
  color: AXIS_COLOR,
};

export const BAR_HOVER_COLOR = '#F6F8F9';

export const DEMAND_BAR_COLOR = '#55AC9B';

export const ALLOCATION_BAR_COLOR = '#2F5E55';

export const X_AXIS_LINE_COLOR = '#C1CFD6';

export const DATA_LABELS_STYLE = {
  fontSize: '0.75rem',
  fontWeight: '600',
  lineHeight: '1rem',
  color: '#0E1212',
  fill: '#0E1212',
};
