import { Icon, Typography } from '@getsynapse/design-system';

type TeamMemberHeaderProps = {
  name: string;
  collapsedSection: string[];
  onToggleSection: (event: React.MouseEvent<HTMLDivElement>) => void;
  teamId?: string;
};

const TableSubHeaderContent = ({
  name,
  collapsedSection,
  onToggleSection,
  teamId,
}: TeamMemberHeaderProps) => {
  return (
    <div className='w-full h-full relative'>
      <div
        className='absolute -left-17 top-0 bottom-0 flex items-center justify-center w-12 h-full'
        style={{
          transform: `${
            teamId && collapsedSection.includes(teamId)
              ? 'rotate(180deg)'
              : 'rotate(0deg)'
          }`,
          transition: 'all 0.2s linear',
        }}
        onClick={onToggleSection}
      >
        <Icon name='chevron-up' className='text-lg text-neutral-dark' />
      </div>
      <div>
        <Typography variant='body2' weight='medium'>
          {name}
        </Typography>
      </div>
    </div>
  );
};

export default TableSubHeaderContent;
