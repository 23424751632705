import { Typography, Button } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import emptyForms from 'assets/images/empty-forms.svg';

const EmptyForms = () => (
  <div className='flex flex-col justify-center items-center h-empty-forms-table-body'>
    <div className='flex flex-col'>
      <img
        src={emptyForms}
        alt={intl.get('SETTINGS_PAGE.FORMS.NO_FORMS')}
        className='-ml-6'
      />
      <Typography
        variant='body'
        className='mt-6 text-neutral-black w-full text-center'
      >
        {intl.get('SETTINGS_PAGE.FORMS.CREATE_FORMS')}
      </Typography>
      <Button className='mt-3 mx-auto' onClick={() => {}}>
        {intl.get('PROJECTS_LIST_PAGE.TABLE.GET_STARTED')}
      </Button>
    </div>
  </div>
);

export default EmptyForms;
