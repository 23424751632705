import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { SLICE_STATUS } from 'utils/constants';
import { Status } from 'utils/customTypes';
import { MeasurementScale } from 'utils/types/program';
import measurementScalesAPI from './measurementScalesAPI';
import { RootState } from 'state/store';

interface MeasurementScalesState {
  status: Status;
  value: MeasurementScale[];
}

/* ============================= INITIAL STATE ============================== */
const initialState: MeasurementScalesState = {
  status: SLICE_STATUS.IDLE,
  value: [],
};

/* ============================== REDUX THUNK =============================== */
export const fetchMeasurementScales = createAsyncThunk(
  'measurementScales/fetchMeasurementScales',
  async () => {
    const response = await measurementScalesAPI.fetchMeasurementScales();
    return response;
  }
);

/* ================================= REDUCER ================================ */
const measurementScalesSlice = createSlice({
  name: 'measurementScales',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMeasurementScales.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchMeasurementScales.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        state.value = action.payload;
      })
      .addCase(fetchMeasurementScales.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      });
  },
});

/* =============================== SELECTORS ================================ */
export const selectMeasurementScales = (state: RootState) =>
  state.measurementScales.value;

export const selectMeasurementScalesStatus = (state: RootState) =>
  state.measurementScales.status;

export const selectMeasurementScalebyId = createSelector(
  [selectMeasurementScales, (_: RootState, scaleId: string) => scaleId],
  (scales, scaleId) => {
    return scales.find((scale) => scale.id === scaleId);
  }
);

export default measurementScalesSlice.reducer;
