import React from 'react';
import intl from 'react-intl-universal';
import {
  Typography,
  tailwindOverride,
  Icon,
  Tooltip,
} from '@getsynapse/design-system';
import dayOffIcon from 'assets/icons/day-off.svg';

const Trigger: React.FC<{ timeOff: number }> = ({ timeOff }) => {
  return (
    <div
      className={tailwindOverride(
        'w-full h-6 relative mt-3',
        'flex items-center justify-center',
        'rounded',
        'border border-neutral-lighter',
        'bg-neutral-lighter-two',
        'cursor-pointer'
      )}
      style={{
        boxShadow: `0px 2px 2px 0px #12151B0D, 2px 0px 0px 0px rgb(var(--color-neutral-dark)) inset`,
      }}
    >
      <Icon
        src={dayOffIcon}
        className='text-neutral-darker mr-2 text-lg'
        aria-label='time-off-icon'
      />
      <Typography
        variant='label'
        weight='strong'
        className='cursor-pointer text-neutral-darker'
      >{`${timeOff}h`}</Typography>
    </div>
  );
};

const WeeklyTimeOff: React.FC<{ timeOff: number }> = ({ timeOff }) => {
  return (
    <Tooltip
      trigger={
        <div>
          <Trigger timeOff={timeOff} />
        </div>
      }
      openMode='click'
      timeout={0}
      position='topCenter'
      contentProps={{
        className: tailwindOverride(
          'px-4 py-3 z-50 w-52',
          'border border-purple-lighter',
          'bg-neutral-white'
        ),
      }}
      showPopper
      usePortal
      className='w-full'
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <div className='flex flex-1 justify-between'>
        <Typography
          variant='caption'
          weight='medium'
          className='text-purple-darker'
        >
          {intl.get('TEAMS_CAPACITY.WEEKLY_TIME_OFF')}
        </Typography>
        <Typography
          variant='label'
          className='text-purple-darker'
        >{`${timeOff}h`}</Typography>
      </div>
    </Tooltip>
  );
};

export default WeeklyTimeOff;
