import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/store';
import {
  fetchTeamMembersList,
  removeTeamMembers,
} from 'state/Capacities/TeamMembersList/teamMembersListSlice';
import { selectTeamExpandedStatus } from 'state/Capacities/TeamsList/teamsListSlice';
import MembersList from './components/MembersList/MembersList';

const TeamMembersList: React.FC<{
  teamId: string;
}> = ({ teamId }) => {
  const dispatch = useDispatch();
  const shouldFetchTeamMembers = useRef<boolean>(true);
  const isTeamExpanded = useSelector((state: RootState) =>
    selectTeamExpandedStatus(state, teamId)
  );

  useEffect(() => {
    if (shouldFetchTeamMembers.current && isTeamExpanded) {
      shouldFetchTeamMembers.current = false;
      dispatch(fetchTeamMembersList(teamId));
    }
  }, [teamId, isTeamExpanded, dispatch]);

  useEffect(() => {
    if (!isTeamExpanded && !shouldFetchTeamMembers.current) {
      shouldFetchTeamMembers.current = true;
      dispatch(removeTeamMembers({ teamId }));
    }
  }, [teamId, isTeamExpanded, dispatch]);

  return (
    <div
      id={`team-${teamId}__members-list`}
      aria-labelledby={`team-${teamId}__row`}
      hidden={!isTeamExpanded}
    >
      <MembersList teamId={teamId} isExpanded={isTeamExpanded} />
    </div>
  );
};

export default TeamMembersList;
