import {
  createAsyncThunk,
  createSlice,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { ROICostsType, CostDataType } from 'utils/types/program';
import { RootState } from 'state/store';
import roiCostsAPI from './ROICostsAPI';

interface ROICostsState {
  status: Status;
  value: ROICostsType;
}

/* ============================= INITIAL STATE ============================== */

const initialState: ROICostsState = {
  value: {} as ROICostsType,
  status: SLICE_STATUS.IDLE,
};

/* ============================= ACTIONS ============================== */
export const resetROICosts = createAction('roiCosts/RESET_ROI_Costs');

/* ============================== REDUX THUNK =============================== */
export const fetchROICosts = createAsyncThunk(
  'roiCosts/FETCH_ROI_COSTS',
  async ({
    programId,
    categoryId,
  }: {
    programId: string;
    categoryId: string;
  }) => {
    const response = await roiCostsAPI.fetchROICosts(programId, categoryId);
    return response.data;
  }
);

export const createROICosts = createAsyncThunk(
  'roiCosts/CREATE_ROI_COSTS',
  async ({
    programId,
    categoryId,
    costs,
  }: {
    programId: string;
    categoryId: string;
    costs: Partial<CostDataType>[];
  }) => {
    const response = await roiCostsAPI.createROICosts(
      programId,
      categoryId,
      costs
    );
    return response.data;
  }
);

export const updateROICost = createAsyncThunk(
  'roiCosts/UPDATE_ROI_COSTS',
  async ({
    programId,
    categoryId,
    costId,
    cost,
  }: {
    programId: string;
    categoryId: string;
    costId: string;
    cost: Partial<CostDataType>;
  }) => {
    const response = await roiCostsAPI.updateROICost(
      programId,
      categoryId,
      costId,
      cost
    );
    return response.data;
  }
);

export const deleteROICost = createAsyncThunk(
  'roiCosts/DELETE_ROI_COSTS',
  async ({
    programId,
    categoryId,
    costId,
  }: {
    programId: string;
    categoryId: string;
    costId: string;
  }) => {
    const response = await roiCostsAPI.deleteROICost(
      programId,
      categoryId,
      costId
    );
    if (response.status === 204) {
      return costId;
    }
  }
);

/* ================================= REDUCER ================================ */
const roiCostsSlice = createSlice({
  name: 'roiCosts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchROICosts.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchROICosts.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
        throw new Error();
      })
      .addCase(fetchROICosts.fulfilled, (state, action) => {
        state.value = action.payload;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(resetROICosts, (state) => {
        state.value = {} as ROICostsType;
      })
      .addCase(createROICosts.fulfilled, (state, action) => {
        state.value = action.payload;
      })
      .addCase(createROICosts.rejected, () => {
        throw new Error();
      })
      .addCase(updateROICost.fulfilled, (state, action) => {
        const updatedCost = action.payload;
        state.value = {
          ...state.value,
          costData: state.value.costData.map((cost) => {
            if (cost.id === updatedCost.id) {
              return updatedCost;
            }
            return cost;
          }),
        };
      })
      .addCase(updateROICost.rejected, () => {
        throw new Error();
      })
      .addCase(deleteROICost.fulfilled, (state, action) => {
        const deletedCostId = action.payload;
        state.value = {
          ...state.value,
          costData: state.value.costData.filter(
            (cost) => cost.id !== deletedCostId
          ),
        };
      })
      .addCase(deleteROICost.rejected, () => {
        throw new Error();
      });
  },
});

/* =============================== SELECTORS ================================ */

export const selectTotalProgramCosts = (state: RootState) =>
  state.roiCosts.value.totalProgramCost;

export const selectROICostsData = (state: RootState) =>
  state.roiCosts.value.costData;

export const selectROIOtherCosts = createSelector(
  [selectROICostsData],
  (costs) => (costs?.length > 1 ? costs.slice(1) : [])
);

export const selectROIResourceCost = createSelector(
  [selectROICostsData],
  (costs) => (costs?.length > 0 ? costs[0] : ({} as CostDataType))
);

export const selectROICostsStatus = (state: RootState) => state.roiCosts.status;

export default roiCostsSlice.reducer;
