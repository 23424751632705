import React from 'react';
import { useSelector } from 'react-redux';
import { selectWeeklyCapacityByTeamId } from 'state/WeeklyTeamCapacity/TeamsCapacity/teamsCapacitySlice';
import { RootState } from 'state/store';
import { CapacityPerWeek } from 'types/store/weeklyTeamCapacity';
import NoWeeklyCapacity from '../../NoWeeklyCapacity/NoWeeklyCapacity';
import WeeksGrid from '../../WeeksGrid/WeeksGrid';
import TeamWeeklyCapacity from '../TeamWeeklyCapacity/TeamWeeklyCapacity';

interface TeamWeeklyCapacitiesProps {
  id: string;
}
const TeamWeeklyCapacities: React.FC<TeamWeeklyCapacitiesProps> = ({ id }) => {
  const weeklyTeamCapacity = useSelector((state: RootState) =>
    selectWeeklyCapacityByTeamId(state, id)
  );

  if (Object.keys(weeklyTeamCapacity).length === 0) {
    return (
      <div className='w-4/6 h-full flex'>
        <NoWeeklyCapacity id={`team-${id}`} isLoading />
      </div>
    );
  }

  return (
    <div className='w-4/6 h-full flex'>
      <WeeksGrid<CapacityPerWeek>
        id={`team-${id}`}
        component={TeamWeeklyCapacity}
        items={weeklyTeamCapacity}
      />
    </div>
  );
};

export default TeamWeeklyCapacities;
