import { Dropdown } from '@getsynapse/design-system';
import {
  intakeQuestionWrapper,
  dropdownOption,
  Option,
} from 'utils/customTypes';
import get from 'lodash/get';
import { useMemo } from 'react';

const DropdownQuestion = ({
  question,
  handler,
  disabled,
}: intakeQuestionWrapper) => {
  const isMultiple = get(question, 'data.multiselect');
  const optionsData = useMemo(
    () =>
      question.data.value.items.map((data: dropdownOption) => {
        if (data.display) {
          return {
            label: data.display,
            value: data.value,
          };
        }
        return null;
      }),
    [question.data.value.items]
  );
  const dropdownValues = useMemo(() => {
    if (!isMultiple && question.data.value.value) {
      return [
        {
          label: question.data.value.value.display,
          value: question.data.value.value.value,
        },
      ];
    } else if (isMultiple && question.data.value.value?.length) {
      return question.data.value.value.map((value: dropdownOption) => ({
        label: value.display,
        value: value.value,
      }));
    } else {
      return [];
    }
  }, [isMultiple, question.data.value.value]);

  return (
    <>
      {optionsData.length && (
        <Dropdown
          options={optionsData}
          disabled={disabled}
          multiple={isMultiple}
          className=''
          onChange={(options: Option[]) =>
            handler(
              question,
              !isMultiple
                ? {
                    value: get(options, 'value'),
                    display: get(options, 'label'),
                  }
                : options.map((option) => ({
                    value: option.value,
                    display: option.label,
                  })),
              'data.value.value'
            )
          }
          values={dropdownValues}
          triggerProps={{
            placeholder: get(question, 'data.placeholder'),
            className: 'h-10',
          }}
        />
      )}
    </>
  );
};

export default DropdownQuestion;
