import classnames from 'classnames';
import intl from 'react-intl-universal';
import { Typography, Icon, Tooltip } from '@getsynapse/design-system';
import informationIcon from 'assets/icons/tooltip-info.svg';
import businessLoginImage from 'assets/images/business-login.png';
import loginFormImage from 'assets/images/login-form-image.png';

const PortalPreview = ({
  logoUrl,
  backgroundUrl,
  useDefaultImage,
  currentPortalName,
}: {
  logoUrl: string;
  backgroundUrl: string;
  useDefaultImage: boolean;
  currentPortalName?: string;
}) => {
  const getBackgroundImage = () => {
    if (!useDefaultImage && backgroundUrl) {
      return backgroundUrl;
    }
    return businessLoginImage;
  };

  return (
    <div className='w-1/2 flex flex-col min-w-532px items-center'>
      <div>
        <div className='flex'>
          <Typography variant='body2' weight='medium'>
            {intl.get('INTAKE_TAB.PREVIEW')}
          </Typography>

          <Tooltip
            trigger={
              <Icon src={informationIcon} className='font-normal ml-2 mt-1' />
            }
            openMode='hover1'
            timeout={1}
            position='bottomRight'
            contentProps={{
              className: classnames(
                'bg-neutral-darker',
                'rounded px-4 py-3.5 z-10',
                'min-w-100 absolute',
                'font-normal'
              ),
            }}
          >
            <span>{intl.get('INTAKE_TAB.PREVIEW_TOOLTIP')}</span>
          </Tooltip>
        </div>
        <div className='w-532px h-300px flex relative'>
          {logoUrl && (
            <img
              className='absolute left-3 top-3'
              src={logoUrl}
              width={55}
              height={40}
              alt='logo'
            ></img>
          )}
          <div
            className=' w-1/2 h-full bg-cover bg-left bg-no-repeat'
            style={{
              backgroundImage: `url(${getBackgroundImage()})`,
              backgroundPosition: 'top center',
            }}
          />
          <div className='h-full w-1/2 bg-neutral-lightest'>
            <div
              style={{ backgroundImage: `url(${loginFormImage})` }}
              className='absolute w-66 h-62 top-5 right-6 bg-cover rounded'
            />
            <span
              className='w-34 h-7 absolute top-13 right-22 text-xs font-bold text-center'
              data-cy='preview-portal-title'
            >
              {intl.get('LOGIN_PAGE.WELCOME_TO')} {currentPortalName}
            </span>
            <span className='text-6 absolute bottom-1 right-20'>
              {intl.get('INTAKE_TAB.COPYRIGHT')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortalPreview;
