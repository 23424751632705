import { useRequestContext } from 'Pages/RequestPage/context/RequestContext';
import intl from 'react-intl-universal';
import { REQUEST_STATUS } from 'utils/constants';
import ActionButton from './ActionButton';
import NavigationButton from './NavigationButton';

const BasicDetailsButtons = ({
  requestStatus,
  onCancel,
  onUpdate,
  onSubmit,
  hasErrors,
  validRequiredFields,
  changesDetected,
}: {
  requestStatus: string;
  onCancel: () => void;
  onUpdate: () => Promise<void>;
  onSubmit: () => Promise<void>;
  hasErrors: boolean;
  validRequiredFields: boolean;
  changesDetected: boolean;
}) => {
  const {
    areBasicAndRequestDisabled,
    isAdditionalDisabled,
    isLDDisabled,
    isForm,
  } = useRequestContext();

  const disableSaveButtons =
    areBasicAndRequestDisabled &&
    isAdditionalDisabled &&
    isLDDisabled &&
    !isForm;

  const disableSubmitButton =
    areBasicAndRequestDisabled && isAdditionalDisabled && isLDDisabled;

  return (
    <div className='flex space-x-4 h-7.5 w-full justify-end'>
      <NavigationButton
        onClickHandler={onCancel}
        variant='tertiary'
        label={intl.get('REQUEST_PAGE.REQUEST_DETAILS.BUTTON.CANCEL')}
      />
      <ActionButton
        action={onUpdate}
        variant='secondary'
        hasErrors={hasErrors}
        testId='request_update-button'
        disabled={
          disableSaveButtons ||
          (hasErrors && requestStatus !== REQUEST_STATUS.DRAFT) ||
          (!changesDetected && requestStatus === REQUEST_STATUS.DRAFT)
        }
      >
        {requestStatus === REQUEST_STATUS.DRAFT
          ? intl.get('REQUEST_PAGE.REQUEST_DETAILS.BUTTON.SAVE_EXIT')
          : intl.get('REQUEST_PAGE.REQUEST_DETAILS.BUTTON.UPDATE_EXIT')}
      </ActionButton>
      {requestStatus === REQUEST_STATUS.DRAFT && (
        <ActionButton
          testId='request_submit-button'
          action={onSubmit}
          variant='primary'
          hasErrors={hasErrors}
          disabled={disableSubmitButton || !validRequiredFields}
        >
          {intl.get('REQUEST_PAGE.REQUEST_DETAILS.BUTTON.SUBMIT')}
        </ActionButton>
      )}
    </div>
  );
};

export default BasicDetailsButtons;
