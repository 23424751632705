import axios, { AxiosInstance } from 'axios';
import get from 'lodash/get';
import config from 'config/Config';
import { initAxios } from 'utils/axios';

interface TeamsListResponse {
  teams: {
    id: string;
    name: string;
  }[];
  links: {
    next: string | null;
  };
}

class TeamsListAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchTeamsList: (url: string) => Promise<TeamsListResponse> = async (url) => {
    const response = await this.instance.get<TeamsListResponse>(url);
    if (axios.isAxiosError(response)) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
    return response.data;
  };
}

const teamsListAPI = new TeamsListAPI();
export default teamsListAPI;
