import { useMemo } from 'react';
import {
  Dropdown,
  Icon,
  tailwindOverride,
  Typography,
} from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import UserAvatar from 'Atoms/UserAvatar';
import { CurrentUser } from 'utils/customTypes';
import { PATHS, PROFILE_CONTEXT_MENU } from 'utils/constants';
import avatarIcon from 'assets/icons/avatar.svg';
import calendarIcon from 'assets/icons/calendar.svg';
import mailIcon from 'assets/icons/mail.svg';

type ProfileDropdownProps = {
  user: CurrentUser;
};

type customOption = {
  name: string;
  icon: string;
  userId?: string;
  key?: string;
};

const AvatarTrigger = ({ user }: ProfileDropdownProps) => {
  return (
    <>
      <UserAvatar
        size='small'
        user={{
          avatar_url: user.avatar_url,
          data: {
            firstName: user.firstName!,
            lastName: user.lastName!,
          },
        }}
      />
      <Typography variant='body2' className='text-neutral-black'>
        {user.full_name}
      </Typography>
    </>
  );
};

function getDropdownOptions(user: CurrentUser, timeOffRoute: string) {
  return [
    {
      name: intl.get(`PROFILE_MENU.${PROFILE_CONTEXT_MENU[0]}`),
      icon: avatarIcon,
      userId: user.id || '',
      key: PROFILE_CONTEXT_MENU[0],
      route: PATHS.PROFILE,
    },
    {
      name: intl.get(`PROFILE_MENU.${PROFILE_CONTEXT_MENU[1]}`),
      icon: mailIcon,
      key: PROFILE_CONTEXT_MENU[1],
      route: PATHS.NOTIFICATIONS,
    },
    {
      name: intl.get(`PROFILE_MENU.${PROFILE_CONTEXT_MENU[2]}`),
      icon: calendarIcon,
      key: PROFILE_CONTEXT_MENU[2],
      route: timeOffRoute,
    },
  ];
}

function getDropdownOptionsWithoutNotifications(
  user: CurrentUser,
  timeOffRoute: string
) {
  return [
    {
      name: intl.get(`PROFILE_MENU.${PROFILE_CONTEXT_MENU[0]}`),
      icon: avatarIcon,
      userId: user.id || '',
      key: PROFILE_CONTEXT_MENU[0],
      route: PATHS.PROFILE,
    },
    {
      name: intl.get(`PROFILE_MENU.${PROFILE_CONTEXT_MENU[2]}`),
      icon: calendarIcon,
      key: PROFILE_CONTEXT_MENU[2],
      route: timeOffRoute,
    },
  ];
}

const ProfileDropdown = ({ user }: ProfileDropdownProps) => {
  const history = useHistory();
  const { timeOff20 = false, userEmailNotificationSettings = false } =
    useFlags();
  const options = useMemo(() => {
    let timeOffRoute = PATHS.TIME_OFF;
    if (timeOff20) {
      timeOffRoute = PATHS.MANAGE_TIME_OFF;
    }
    if (user.id) {
      return userEmailNotificationSettings
        ? getDropdownOptions(user, timeOffRoute)
        : getDropdownOptionsWithoutNotifications(user, timeOffRoute);
    } else {
      return [];
    }
  }, [timeOff20, user, userEmailNotificationSettings]);

  return (
    <div>
      <Dropdown
        options={options}
        onChange={(option) => history.push(option.route)}
        renderTrigger={({ onToggleDropdown }) => (
          <div
            onClick={onToggleDropdown}
            className={tailwindOverride(
              'flex space-x-2 items-center cursor-pointer rounded-sm',
              'hover:bg-neutral-lightest ring-4 ring-transparent',
              'hover:ring-neutral-lightest'
            )}
            data-cy='profile-dropdown_trigger'
          >
            <AvatarTrigger user={user} />
          </div>
        )}
        popperDiffWidth
        listProps={{ className: 'w-44 z-10' }}
        renderOption={(
          option: customOption,
          isSelected: boolean,
          selectOption: () => void,
          { className, ...otherProps }
        ) => (
          <li
            {...otherProps}
            tabIndex={isSelected ? 0 : -1}
            className={tailwindOverride('flex w-full group', className, {
              'hover:bg-neutral-lighter-two': isSelected,
              'hover:shadow-list-item-hover': isSelected,
              'focus-visible:bg-neutral-lighter-two': isSelected,
              'hover:text-neutral-dark': isSelected,
            })}
            onClick={selectOption}
            data-cy={`profile-dropdown_option-${option.key}`}
          >
            <div className='flex items-center'>
              <Icon src={option.icon} className='text-lg' />
              <span className='ml-2.5 text-primary text-sm leading-6'>
                {option.name}
              </span>
            </div>
          </li>
        )}
      />
    </div>
  );
};

export default ProfileDropdown;
