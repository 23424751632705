import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { CostDataType } from 'utils/types/program';

class ROICostsAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(`${config.get('backendURL')}v2/programs/`);
  }

  fetchROICosts = async (programId: string, categoryId: string) => {
    const res = await this.instance.get(
      `${programId}/categories/${categoryId}/roi_cost`
    );
    return res;
  };

  createROICosts = async (
    programId: string,
    categoryId: string,
    costs: Partial<CostDataType>[]
  ) => {
    const res = await this.instance.post(
      `${programId}/categories/${categoryId}/roi_cost`,
      costs
    );
    return res;
  };

  updateROICost = async (
    programId: string,
    categoryId: string,
    costId: string,
    cost: Partial<CostDataType>
  ) => {
    const res = await this.instance.patch(
      `${programId}/categories/${categoryId}/roi_cost/${costId}`,
      cost
    );
    return res;
  };

  deleteROICost = async (
    programId: string,
    categoryId: string,
    costId: string
  ) => {
    const res = await this.instance.delete(
      `${programId}/categories/${categoryId}/roi_cost/${costId}`
    );
    return res;
  };
}

export default new ROICostsAPI();
