import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  selectTeamProjects,
  selectAllProjects,
  selectIfUnfilteredTeamProjectsIsEmpty,
  selectProjectStatus,
} from 'state/Projects/projectsSlice';
import { PROJECTS_TABLE_TABS } from 'utils/constants';
import {
  ProjectsTableTab,
  Pagination as PaginationType,
} from 'utils/customTypes';
import TableHeaderFilters from '../TableHeaderFilters';
import TeamProjectsTable from './TeamProjectsTable';
import Pagination from 'Organisms/Pagination/ClientPagination';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';

const TeamProjects: React.FC<{
  onAddProject: () => void;
  onDuplicateProject: (projectId: string) => void;
  onArchiveProject: (projectId: string) => void;
  onUpdatePagination: (
    pagination: PaginationType,
    table: ProjectsTableTab
  ) => void;
}> = ({
  onAddProject,
  onDuplicateProject,
  onArchiveProject,
  onUpdatePagination,
}) => {
  const teamProjectsList = useSelector(selectTeamProjects);
  const allProjectsList = useSelector(selectAllProjects);
  const fetchProjectsStatus = useSelector(selectProjectStatus);
  const isUnfilteredTeamProjectsEmpty = useSelector(
    selectIfUnfilteredTeamProjectsIsEmpty
  );

  const handleUpdatePagination = useCallback(
    (pagination) => {
      const table: ProjectsTableTab = PROJECTS_TABLE_TABS.TEAM_PROJECTS;
      onUpdatePagination(pagination, table);
    },
    [onUpdatePagination]
  );

  if (fetchProjectsStatus === 'loading') {
    return <SkeletonLoader data-cy='skeleton-loader' />;
  }

  return (
    <div className='mt-4'>
      <TableHeaderFilters
        table={PROJECTS_TABLE_TABS.TEAM_PROJECTS}
        exportEnabled={!isUnfilteredTeamProjectsEmpty}
        filterButtonEnabled={!isEmpty(allProjectsList)}
      />
      <TeamProjectsTable
        projectsList={teamProjectsList.data}
        totalProjects={teamProjectsList.total}
        addNewProject={onAddProject}
        onDuplicateProject={onDuplicateProject}
        onArchiveProject={onArchiveProject}
      />
      <Pagination
        total={teamProjectsList.total}
        onChange={handleUpdatePagination}
        className='z-10 max-w-full'
      />
    </div>
  );
};

export default TeamProjects;
