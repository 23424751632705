import { Typography } from '@getsynapse/design-system';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { SelectOrganisationId } from 'state/Organization/organizationSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import config from 'config/Config';

const ShareableLink = () => {
  const dispatch = useDispatch();
  const businessLoginBaseUrl = config.get('businessLoginBaseUrl');
  const organizationId = useSelector(SelectOrganisationId);
  const portalUrl = businessLoginBaseUrl + organizationId;
  const copyToClipboard = () => {
    navigator.clipboard.writeText(portalUrl);
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get('INTAKE_TAB.LINK_COPIED'),
      })
    );
  };
  return (
    <div className='w-full mt-4 flex flex-col' data-cy='shareable-portal-link'>
      <Typography variant='body2' weight='medium'>
        {intl.get('INTAKE_TAB.PORTAL_LINK')}
      </Typography>
      <div className='w-full mt-1 h-10 border border-primary-lighter rounded flex items-center'>
        <div className='h-full w-full truncate bg-primary-lightest px-2 rounded text-primary flex flex-col justify-center'>
          <span
            className='truncate max-w-full'
            title={portalUrl}
            data-cy='requests-portal-url'
          >
            {portalUrl}
          </span>
        </div>
        <button
          className='w-16 h-full border-l border-primary-lighter cursor-pointer flex flex-col justify-center items-center text-primary text-sm'
          onClick={copyToClipboard}
        >
          {intl.get('INTAKE_TAB.COPY_LINK')}
        </button>
      </div>
    </div>
  );
};

export default ShareableLink;
