import React, { useState, SetStateAction, Dispatch, useEffect } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { PickerFileMetadata } from 'filestack-js';
import get from 'lodash/get';
import {
  Dropdown,
  FormItem,
  FileUpload,
  Tag,
  Checkbox,
  Typography,
} from '@getsynapse/design-system';
import { selectDesigns } from 'state/Designs/designsSlice';
import { getProjectLinkedDesigns } from 'state/Project/projectSlice';
import { Design, ProjectContent } from 'utils/customTypes';
import { PROJECT_CONTENT_TYPE } from 'utils/constants';
import { getFileStackConfig } from './projectContentFilestackConfig';
import FilesCarousel from './FilesCarousel';
import designIcon from 'assets/icons/design-icon.svg';

const AddDesignSection: React.FC<{
  setFiles: Dispatch<SetStateAction<ProjectContent[]>>;
}> = ({ setFiles }) => {
  const projectLinkedDesigns = useSelector(getProjectLinkedDesigns);
  const designsList = useSelector(selectDesigns);
  const fileStackConfig = getFileStackConfig();
  const [selectedDesigns, setSelectedDesigns] = useState<Design[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<ProjectContent[]>([]);

  useEffect(() => {
    setFiles(uploadedFiles);
  }, [uploadedFiles, setFiles]);

  const handleSelectDesigns = (desingsArray: Design[]) => {
    const newDesings = desingsArray.map((design: Design) => ({
      data: {},
      content_type: PROJECT_CONTENT_TYPE.DESIGN,
      linkedTasksIds: [],
      linkedDesignId: design.id,
    }));
    setSelectedDesigns(desingsArray);
    setFiles(newDesings);
  };

  const handleUploadFiles = (files: PickerFileMetadata[]) => {
    const newFiles = files.map((file: PickerFileMetadata) => ({
      data: { ...file },
      content_type: PROJECT_CONTENT_TYPE.DESIGN_FILE,
      linkedTasksIds: [],
    }));
    setUploadedFiles((prevFiles) => prevFiles.concat(newFiles));
  };

  const onRemoveFile = (index: number) => {
    setUploadedFiles((prevFiles) => [
      ...prevFiles.slice(0, index),
      ...prevFiles.slice(index + 1),
    ]);
  };

  const availableDesigns = designsList.filter(
    (design: Design) => !projectLinkedDesigns.includes(design.id)
  );

  return (
    <div className='mt-8'>
      <FormItem label={intl.get('PROJECT_DETAIL.FILES_TAB.SELECT_DESIGN')}>
        <Dropdown
          multiple
          options={availableDesigns}
          getOptionLabel={(design: Design) => design.title}
          onChange={handleSelectDesigns}
          disabled={uploadedFiles.length > 0}
          placeholder={intl.get('PROJECT_DETAIL.FILES_TAB.SELECT_DESIGN')}
          filterable
          filterOptions={(options: Design[] | any[], filterValue: string) =>
            options.filter((option: Design) => {
              const title = (option as Design).title
                ? (option as Design).title.toLowerCase()
                : '';
              return title.includes(filterValue.toLocaleLowerCase());
            })
          }
          triggerProps={{
            className: 'pb-1',
            'data-cy': 'add-design-section__trigger',
          }}
          listProps={{ 'data-cy': 'add-design-section__designs-list' }}
          renderOption={(
            option: Design,
            isSelected: boolean,
            selectOption,
            { className, ...otherProps }
          ) => (
            <li
              {...otherProps}
              className={classnames('group', className, {
                'hover:bg-primary focus-visible:bg-primary': isSelected,
              })}
            >
              <Checkbox
                checked={isSelected}
                onChange={selectOption}
                label={
                  <div className='flex w-full items-center'>
                    <Typography
                      variant='body'
                      className={classnames('text-neutral-black truncate', {
                        'group-hover:text-neutral-white': isSelected,
                        'max-w-3/4': 'ownerData' in option,
                      })}
                    >
                      {option.title}
                    </Typography>
                    {'ownerData' in option && (
                      <Tag
                        label={`${intl.get(
                          'PROJECT_DETAIL.FILES_TAB.DESIGN_OWNER_TAG'
                        )}: ${get(option, 'ownerData.firstName')} ${get(
                          option,
                          'ownerData.lastName'
                        )}`}
                        className='bg-neutral-lighter-two ml-2 group-hover:bg-neutral-dark'
                        textClassName={classnames(
                          'text-xs text-neutral-darker font-semibold',
                          'group-hover:text-neutral-white'
                        )}
                      />
                    )}
                  </div>
                }
                inputProps={{
                  className: `${
                    isSelected &&
                    'group-hover:border-neutral-white group-focus-visible:border-neutral-white'
                  }`,
                }}
                className={`${
                  isSelected &&
                  'group-hover:text-neutral-white group-focus-visible:text-neutral-white'
                }`}
              />
            </li>
          )}
          renderSelectedOptionTag={(
            { textClassName, label, ...restOfProps },
            selectedOption: any
          ) => {
            const designFile = selectedOption as Design;
            return (
              <Tag
                {...restOfProps}
                textClassName={classnames(
                  'text-xs text-secondary-darker font-semibold',
                  'group-hover:text-secondary-lighter',
                  textClassName
                )}
                leadingIcon={{
                  src: designIcon,
                  className:
                    'text-base fill-current text-secondary-dark group-hover:text-secondary-lighter',
                }}
                className='group bg-secondary-lightest hover:bg-secondary-darker mr-1 mb-1 last:mr-0'
                label={designFile.title}
                tailingIcon={{ className: 'text-base' }}
                data-cy='add-design-section__selected-design'
              />
            );
          }}
        />
      </FormItem>
      <FormItem
        label={intl.get('PROJECT_DETAIL.FILES_TAB.UPLOAD_DESIGN_FILE')}
        className='mt-6'
        component='div'
      >
        {uploadedFiles.length > 0 && (
          <FilesCarousel files={uploadedFiles} onRemoveFile={onRemoveFile} />
        )}
        <FileUpload
          className='mt-1 flex-1'
          config={fileStackConfig}
          displayUploadedFiles={false}
          onFileUploadHandle={handleUploadFiles}
          disabled={selectedDesigns.length > 0}
          customErrorMessages={{
            'file-too-large': intl.get('PROJECT_DETAIL.FILES_TAB.FILE_1GB'),
          }}
          uploadInstructions={
            <>
              {intl.get('INTAKE_TAB.DRAG_HERE')}{' '}
              <span className='text-purple-darker'>
                {intl.get('INTAKE_TAB.CHOOSE')}
              </span>{' '}
              {intl.get('PROJECT_DETAIL.FILES_TAB.FILE_LIMIT')}
            </>
          }
          hidePickerWhenUploading
          data-cy='add-design-section__file-uploader'
        />
      </FormItem>
    </div>
  );
};

export default AddDesignSection;
