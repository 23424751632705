import React, { useCallback, useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import useModal from 'Hooks/useModal';
import { ReactivateModalContent } from './ReactivateModalContent';
import { NewProject, ProjectStatus } from 'utils/customTypes';
import { PROJECT_STATUS } from 'utils/constants';

type ReactivateProjectProps = {
  shouldDisplayModal: boolean;
  closeModalCallback: () => void;
  handleReactivateProjectClick: (status: ProjectStatus) => void;
  projectData: NewProject;
  projectId: string;
};

function ReactivateProjectModal(props: ReactivateProjectProps) {
  const {
    shouldDisplayModal,
    closeModalCallback,
    handleReactivateProjectClick,
    projectData,
    projectId,
  } = props;
  const { Modal, modalProps, openModal, closeModal, updateModal } = useModal();
  const [canReactivateProject, setCanReactivateProject] = useState(false);

  const selectedStatus = useRef<ProjectStatus>(null);

  const onCloseModal = useCallback(() => {
    closeModal();
    closeModalCallback();
  }, [closeModalCallback, closeModal]);

  const handleSubmit = useCallback(() => {
    handleReactivateProjectClick(selectedStatus.current);
    onCloseModal();
  }, [onCloseModal, handleReactivateProjectClick]);

  const getActionButtons = useCallback(() => {
    return [
      {
        children: intl
          .get('PROJECT_DETAIL.REACTIVATE_PROJECT.SUBMIT')
          .defaultMessage('Reactivate Project'),
        variant: 'primary',
        color: 'danger',
        disabled: !canReactivateProject,
        'data-cy': 'confirm-button',
        onClick: handleSubmit,
      },
      {
        children: intl.get('PROJECT_DETAIL.REACTIVATE_PROJECT.CANCEL'),
        variant: 'secondary',
        'data-cy': 'cancel-button',
        onClick: onCloseModal,
      },
    ];
  }, [canReactivateProject, handleSubmit, onCloseModal]);

  useEffect(() => {
    updateModal({
      actionButtons: getActionButtons(),
    });
  }, [getActionButtons, updateModal]);

  const displayModal = useCallback(() => {
    openModal({
      title: intl.get('PROJECT_DETAIL.REACTIVATE_PROJECT.TITLE'),
      size: 'medium',
      children: (
        <ReactivateModalContent
          onStatusSelection={handleStatusSelection}
          projectId={projectId}
          projectTitle={projectData.title}
          projectStatus={projectData.status}
        />
      ),
      actionButtons: getActionButtons(),
    });
  }, [
    getActionButtons,
    openModal,
    projectId,
    projectData.status,
    projectData.title,
  ]);

  useEffect(() => {
    if (shouldDisplayModal && !modalProps.isOpen) {
      displayModal();
    }
  }, [shouldDisplayModal, modalProps.isOpen, displayModal]);

  function handleStatusSelection(_: any, status: string) {
    selectedStatus.current = Object.values(PROJECT_STATUS).find(
      (value) => value === status
    );

    setCanReactivateProject(selectedStatus.current !== PROJECT_STATUS.CANCELED);
  }

  return (
    <Modal
      {...modalProps}
      aria-label='reactivate project confirmation'
      data-cy='reactivate-project-modal'
      closeModal={onCloseModal}
    />
  );
}

export default ReactivateProjectModal;
