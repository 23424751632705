import { EvaluationPlanItem } from 'utils/types/program';

export const formatDataForOtherFieldUpdate = (
  data: EvaluationPlanItem[],
  methodId: string,
  questionId: string,
  value: string | null
) => {
  return data.map((methodData) => {
    if (methodData.data_collection_method_id === methodId) {
      return {
        ...methodData,
        collection_method_selected_options:
          methodData.collection_method_selected_options.map((answer) => {
            if (answer.question_id === questionId) {
              return {
                ...answer,
                questions_answers: answer.questions_answers.map(
                  (questionAnswer) => {
                    if (questionAnswer.answer_name === 'other') {
                      return {
                        ...questionAnswer,
                        data_source_other_text: value,
                      };
                    }
                    return questionAnswer;
                  }
                ),
              };
            }
            return answer;
          }),
      };
    }
    return methodData;
  });
};
