import { combineReducers } from '@reduxjs/toolkit';

import teamTasksSlice from './teamTasks/teamTasksSlice';
import userTasksSlice from './userTasks/userTasksSlice';
import actionsSlice from './actions/actionsSlice';

export default combineReducers({
  teamTasks: teamTasksSlice,
  userTasks: userTasksSlice,
  actions: actionsSlice,
});
