import { ChangeEvent, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import { Typography } from '@getsynapse/design-system';
import {
  MeasurementScale,
  Objective,
  ObjectiveMeasurementType,
} from 'utils/types/program';
import ObjectiveMetInput from './components/ObjectiveMetInput';
import ObjectiveMeasurementScale from './components/ObjectiveMeasurementScale';

interface Props {
  objective: Objective;
  setObjectiveData: React.Dispatch<React.SetStateAction<Objective | undefined>>;
  objectiveMeasurement?: ObjectiveMeasurementType;
  onChangeMeasurement: (scale: MeasurementScale, score: number | null) => void;
  hideMeasurementScale?: boolean;
  setIsMeasurementScaleValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const ObjectiveMeasurement = ({
  objective,
  setObjectiveData,
  objectiveMeasurement,
  onChangeMeasurement,
  hideMeasurementScale = false,
  setIsMeasurementScaleValid,
}: Props) => {
  const [selectedOption, setSelectedOption] = useState<'' | 'true' | 'false'>(
    ''
  );

  const isObjectiveMet: '' | 'true' | 'false' = useMemo(() => {
    if (selectedOption) {
      return selectedOption;
    } else if (objective.isMet === null) {
      return '';
    } else {
      return objective.isMet.toString() as 'true' | 'false';
    }
  }, [objective.isMet, selectedOption]);
  const measurementScaleId: string | undefined = useMemo(() => {
    if (objectiveMeasurement) {
      return objectiveMeasurement.objectiveMeasurementScaleId;
    }
  }, [objectiveMeasurement]);
  const measurementScore: number | undefined = useMemo(() => {
    if (objectiveMeasurement) {
      return (
        objectiveMeasurement.percentageScore ??
        objectiveMeasurement.fivePointScore ??
        objectiveMeasurement.tenPointScore ??
        undefined
      );
    }
  }, [objectiveMeasurement]);

  const objectiveMetOptions: { label: string; value: 'true' | 'false' }[] = [
    {
      label: intl.get('PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.YES_MET'),
      value: 'true',
    },
    {
      label: intl.get('PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.NOT_MET'),
      value: 'false',
    },
  ];

  const onSetObjectiveMet = (event: ChangeEvent<HTMLInputElement>) => {
    const optionId = objectiveMetOptions.findIndex(
      (option) => option.value === event.target.value
    );
    if (optionId !== -1) {
      const isTrue = objectiveMetOptions[optionId].value === 'true';
      setSelectedOption(objectiveMetOptions[optionId].value);
      setObjectiveData((prev) => ({ ...prev, isMet: isTrue } as Objective));
    }
  };

  const onSetObjectiveNotMetReason = (reason: string) => {
    setObjectiveData(
      (prev) => ({ ...prev, notMetReason: reason } as Objective)
    );
  };

  return (
    <div className='px-8'>
      <Typography variant='h5' className='leading-7'>
        {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE_MEASUREMENT')}
      </Typography>
      <Typography variant='body2' className='text-neutral-darker'>
        {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.REPORT_RESULTS')}
      </Typography>

      <ObjectiveMetInput
        isObjectiveMet={isObjectiveMet}
        objectiveMetOptions={objectiveMetOptions}
        onSetObjectiveMet={onSetObjectiveMet}
        notMetReason={objective.notMetReason}
        setObjectiveNotMetReason={onSetObjectiveNotMetReason}
      />

      {!isEmpty(isObjectiveMet) && !hideMeasurementScale && (
        <ObjectiveMeasurementScale
          storedScaleId={measurementScaleId}
          storedScore={measurementScore}
          onChangeMeasurement={onChangeMeasurement}
          setIsMeasurementScaleValid={setIsMeasurementScaleValid}
        />
      )}
    </div>
  );
};

export default ObjectiveMeasurement;
