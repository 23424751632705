import { formatter } from '../Weeks/helpers';
import { WeeklyTeamCapacityState } from './teamsCapacitySlice';

export function getPrevWeek(dayDate: Date) {
  const prevDate = new Date(dayDate);
  prevDate.setDate(dayDate.getDate() - 7);
  return prevDate;
}

export function getNextWeek(dayDate: Date) {
  const prevDate = new Date(dayDate);
  prevDate.setDate(dayDate.getDate() + 7);
  return prevDate;
}

export const generateFetchPrevOrNextWeekCapacityURL = (
  week: string,
  type: 'prev' | 'next'
) => {
  const dayDate = new Date(week.replace(/-/g, '/'));
  let newWeek = dayDate;
  if (type === 'prev') {
    newWeek = getPrevWeek(dayDate);
  } else {
    newWeek = getNextWeek(dayDate);
  }
  return formatter.format(newWeek);
};

export const removeTeams = (
  weeklyCapacity: WeeklyTeamCapacityState,
  teamIds: string[]
) => {
  const newWeeklyCapacity = { ...weeklyCapacity };
  for (const teamId of teamIds) {
    if (teamId in newWeeklyCapacity) {
      delete newWeeklyCapacity[teamId];
    }
  }
  return newWeeklyCapacity;
};
