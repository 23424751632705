import {
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  LabelList,
  Cell,
  TooltipProps,
} from 'recharts';
import { Typography, IconButton, Tooltip } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import {
  selectTeamUtilizationStatus,
  selectTeamsUtilization,
  selectOverallUtilization,
} from 'state/Insights/capacitySlice';
import { SLICE_STATUS } from 'utils/constants';
import ChartSkeletonLoader from './ChartSkeletonLoader';
import styles from './UtilizatonByTeamChart.module.css';
import { useState, useEffect, useMemo } from 'react';
import legend from 'assets/images/team-utilization-chart-legend.svg';
import orderBy from 'lodash/orderBy';
import classNames from 'classnames';
import { SortingType } from 'utils/customTypes';
import truncate from 'lodash/truncate';
import {
  X_Y_AXIS_STYLE,
  AXIS_COLOR,
  BAR_HOVER_COLOR,
  CAPACITY_BAR_COLOR,
  ALLOCATION_BAR_COLOR,
  X_AXIS_LINE_COLOR,
  ALLOCATION_BAR_COLOR_WITH_OPACITY,
  DATA_LABELS_STYLE,
} from './constants';
import { TeamUtilizationType } from 'state/Insights/types.d';
import NoData from '../components/NoData';
import LoadingError from '../components/LoadingError';
import { wordWrap } from 'Pages/helpers';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';

const UtilizationByTeamChart = () => {
  const tooltipClassnames =
    'px-2 py-0.5 bg-neutral-white border border-purple-lighter text-neutral-dark text-sm leading-6 z-50';

  const chartTitle = (
    <div>
      <p className='text-3.5 leading-2 text-neutral-black font-semibold'>
        {intl.get('CAPACITY_INSIGHTS.UTILIZATION_BY_TEAM.TITLE')}
      </p>
      <Typography variant='caption' className='text-neutral-dark'>
        {intl.get('CAPACITY_INSIGHTS.UTILIZATION_BY_TEAM.CAPTION')}
      </Typography>
    </div>
  );

  const teamsUtilization = useSelector(selectTeamsUtilization);
  const utlizationByTeamStatus = useSelector(selectTeamUtilizationStatus);
  const overallUtilization = useSelector(selectOverallUtilization);

  const [chartData, setChartData] = useState<Array<TeamUtilizationType>>(
    teamsUtilization!
  );
  const [activeSorting, setActiveSorting] = useState('desc');
  const [yAxisTooltipCoordinates, setYAxisTooltipCoordinates] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [yAxisTooltipValue, setyAxisTooltipValue] = useState<string>('');

  const chartHeight = useMemo(() => {
    let height;
    if (chartData && chartData!.length > 7) {
      height = chartData!.length * 45;
    } else {
      height = '100%';
    }
    return height;
  }, [chartData]);

  useEffect(() => {
    setChartData(teamsUtilization!);
  }, [teamsUtilization]);

  const renderChartTooltip = (props: TooltipProps<ValueType, NameType>) => {
    const { active, payload } = props;

    return (
      active &&
      payload?.length && (
        <div className='bg-neutral-white rounded border border-purple-lighter p-2 shadow-raised'>
          <div className='flex items-center'>
            <div className='w-3.5	h-3.5 rounded-sm bg-purple-darker mr-2'></div>
            <Typography className='text-purple-darker' variant='body2'>
              {`${intl.get(
                'CAPACITY_INSIGHTS.UTILIZATION_BY_TEAM.ALLOCATION'
              )} : ${payload[0].payload.allocation}h`}
            </Typography>
          </div>
          <div className='flex items-center'>
            <div className='w-3.5	h-3.5 rounded-sm bg-purple-light mr-2'></div>
            <Typography className='text-purple-darker' variant='body2'>
              {`${intl.get(
                'CAPACITY_INSIGHTS.UTILIZATION_BY_TEAM.CAPACITY'
              )} : ${payload[0].payload.capacity}h`}
            </Typography>
          </div>
        </div>
      )
    );
  };

  const sortData = (sortType: SortingType) => {
    if (activeSorting !== sortType) {
      setActiveSorting(sortType);
      const sortedData = orderBy(chartData, ['utilization'], [sortType]);
      setChartData(sortedData);
    }
  };

  const renderCustomizedBarLabels = (
    props: {
      x?: string | number;
      y?: string | number;
      width?: string | number;
      height?: string | number;
      value?: string | number;
    },
    flag: number
  ) => {
    const { x, y, width, height, value } = props;

    return (
      ((flag === 0 && Number(value) < 100) ||
        (flag === 1 && Number(value) > 100)) && (
        <text
          x={Number(x) + Number(width) + 4}
          y={Number(y) + Number(height) / 2 + 4}
          style={DATA_LABELS_STYLE}
        >
          {`${value}%`}
        </text>
      )
    );
  };

  const renderBars = (
    props: {
      x: number;
      y: number;
      width: number;
      height: number;
      fill: string;
      utilization: number;
    },
    flag: number
  ) => {
    const { x, y, width, height, fill, utilization } = props;
    const roundedCornersPath = `M${x},${y}L ${x + width - 2},${y}A 2,2,0,0,1,
        ${x + width},${y + 2}L ${x + width},${y + height - 2}A 2,2,0,0,1,
        ${x + width - 2},${y + height}L ${x},${y + height}Z`;
    const normalCornersPath = `M${x},${y}L ${x + width},${y}L ${x + width},${
      y + height
    }L ${x},${y + height}Z`;
    let d;
    if (utilization < 100) {
      d = flag === 0 ? roundedCornersPath : normalCornersPath;
    } else {
      d = flag === 0 ? normalCornersPath : roundedCornersPath;
    }

    return (
      <g className='recharts-layer recharts-bar-rectangle' role='img'>
        <path
          fill={fill}
          width={width}
          height={height}
          x={x}
          y={y}
          className='recharts-rectangle'
          d={d}
        />
      </g>
    );
  };

  const renderYAxisTicks = (props: {
    x: number;
    y: number;
    payload: {
      value: string;
    };
  }) => {
    const { x, y, payload } = props;
    const tickValue = payload.value;
    let valueFirstLine = '',
      valueSecondLine = '';
    const isTextLong = tickValue.length >= 25;
    const doesTextNeedTrucation = tickValue.length >= 46;
    if (isTextLong) {
      const valueAfterTruncation = doesTextNeedTrucation
        ? truncate(tickValue, {
            length: 46,
          })
        : tickValue;
      const textWithLineBreaks = wordWrap(valueAfterTruncation, 25);
      valueFirstLine = textWithLineBreaks.substring(
        0,
        textWithLineBreaks.indexOf('\n')
      );
      valueSecondLine = valueAfterTruncation.substring(
        textWithLineBreaks.indexOf('\n')
      );
    }

    return (
      <g className='recharts-layer recharts-cartesian-axis-tick'>
        <text
          width='150'
          orientation='left'
          height='262'
          stroke='none'
          fill={AXIS_COLOR}
          className='recharts-text recharts-cartesian-axis-tick-value'
          x={x}
          y={y}
          textAnchor='end'
          style={X_Y_AXIS_STYLE}
          onMouseEnter={(event) => {
            if (doesTextNeedTrucation) {
              setYAxisTooltipCoordinates({
                x: event.clientX,
                y: event.clientY,
              });
              setyAxisTooltipValue(tickValue);
            }
          }}
          onMouseLeave={() => {
            if (doesTextNeedTrucation) {
              setYAxisTooltipCoordinates(null);
            }
          }}
        >
          {isTextLong ? (
            <>
              <tspan x={x} dy='-0.145em'>
                {valueFirstLine}
              </tspan>
              <tspan x={x} dy='1em'>
                {valueSecondLine}
              </tspan>
            </>
          ) : (
            tickValue
          )}
        </text>
      </g>
    );
  };

  return (
    <div
      className='flex flex-col w-full p-6'
      data-testid='utilization-by-team_chart'
    >
      {utlizationByTeamStatus === SLICE_STATUS.LOADING ? (
        <ChartSkeletonLoader />
      ) : (
        <>
          {chartData?.length ? (
            <>
              <div className='flex items-center justify-between'>
                {chartTitle}
                <div>
                  <Typography variant='body2' className='leading-4 text-right'>
                    {intl.get(
                      'CAPACITY_INSIGHTS.UTILIZATION_BY_TEAM.OVERALL_UTLIZATION'
                    )}
                  </Typography>
                  <p
                    className='text-xl font-semibold	text-right'
                    data-testid='overall-utilization'
                  >{`${overallUtilization}%`}</p>
                </div>
              </div>
              <div className='my-4 flex flex-col items-end -mr-2.5 mb-4'>
                <Tooltip
                  trigger={
                    <IconButton
                      data-testid='sort-asc'
                      name='caret-up'
                      hasASize={false}
                      className='hover:text-neutral-darker'
                      iconClassname={classNames(
                        'w-6 h-5 pointer-events-none',
                        `${
                          activeSorting === 'asc' && chartData.length !== 1
                            ? 'text-neutral-darker'
                            : 'text-neutral-lighter'
                        }`
                      )}
                      disabled={chartData.length === 1}
                      onClick={() => sortData('asc')}
                    />
                  }
                  position='bottomLeft'
                  timeout={0}
                  contentProps={{
                    className: tooltipClassnames,
                  }}
                  openMode='hover1'
                >
                  {intl.get(
                    'CAPACITY_INSIGHTS.UTILIZATION_BY_TEAM.SORT_ASC_TOOLTIP'
                  )}
                </Tooltip>
                <Tooltip
                  trigger={
                    <IconButton
                      data-testid='sort-desc'
                      name='caret-down'
                      hasASize={false}
                      className='relative -top-2 hover:text-neutral-darker'
                      iconClassname={classNames(
                        'w-6 h-5 pointer-events-none',
                        `${
                          activeSorting === 'desc'
                            ? 'text-neutral-darker'
                            : 'text-neutral-lighter'
                        }`
                      )}
                      disabled={chartData.length === 1}
                      onClick={() => sortData('desc')}
                    />
                  }
                  position='bottomLeft'
                  timeout={0}
                  contentProps={{
                    className: tooltipClassnames,
                  }}
                  openMode='hover1'
                >
                  {intl.get(
                    'CAPACITY_INSIGHTS.UTILIZATION_BY_TEAM.SORT_DESC_TOOLTIP'
                  )}
                </Tooltip>
              </div>
              <div
                className={classNames(
                  'overflow-y-auto overflow-x-hidden h-full flex flex-col',
                  styles.scrollbar
                )}
              >
                {yAxisTooltipCoordinates && (
                  <div
                    className={classNames(
                      tooltipClassnames,
                      'rounded fixed shadow-raised max-w-60 z-1 pointer-events-none	'
                    )}
                    style={{
                      left: yAxisTooltipCoordinates?.x,
                      top: yAxisTooltipCoordinates?.y,
                    }}
                  >
                    {yAxisTooltipValue}
                  </div>
                )}
                <ResponsiveContainer
                  width='100%'
                  height={chartHeight}
                  id='teams-utilization_bar_chart'
                >
                  <BarChart
                    layout='vertical'
                    data={chartData}
                    margin={{
                      right: 16,
                      left: 16,
                    }}
                  >
                    <XAxis
                      tickLine={false}
                      style={X_Y_AXIS_STYLE}
                      type='number'
                      tickCount={5}
                      padding={{ right: 32, left: 8 }}
                      label={{
                        value: intl.get(
                          'CAPACITY_INSIGHTS.UTILIZATION_BY_TEAM.HOURS'
                        ),
                        position: 'insideBottomRight',
                        offset: -0.1,
                        style: X_Y_AXIS_STYLE,
                        dx: -16,
                        fill: AXIS_COLOR,
                      }}
                      tick={{ stroke: 'none', fill: AXIS_COLOR }}
                      stroke={X_AXIS_LINE_COLOR}
                    />
                    <YAxis
                      tickLine={false}
                      style={X_Y_AXIS_STYLE}
                      dataKey='teamName'
                      type='category'
                      axisLine={false}
                      width={150}
                      tick={renderYAxisTicks}
                      yAxisId={0}
                    />
                    <YAxis
                      dataKey='teamName'
                      type='category'
                      hide
                      yAxisId={1}
                    />
                    <ChartTooltip
                      content={renderChartTooltip}
                      cursor={{ fill: BAR_HOVER_COLOR }}
                    />
                    <Bar
                      dataKey='capacity'
                      fill={CAPACITY_BAR_COLOR}
                      barSize={16}
                      yAxisId={1}
                      shape={(props) => renderBars(props, 0)}
                    >
                      <LabelList
                        dataKey='utilization'
                        content={(props) => renderCustomizedBarLabels(props, 0)}
                      />
                    </Bar>
                    <Bar
                      dataKey='allocation'
                      fill={ALLOCATION_BAR_COLOR}
                      barSize={16}
                      yAxisId={0}
                      shape={(props) => renderBars(props, 1)}
                    >
                      {chartData.map(
                        (entry: TeamUtilizationType, index: number) => {
                          const fill =
                            entry.capacity < entry.allocation &&
                            entry.capacity !== 0
                              ? ALLOCATION_BAR_COLOR_WITH_OPACITY
                              : ALLOCATION_BAR_COLOR;
                          return <Cell key={`cell-${index}`} fill={fill} />;
                        }
                      )}
                      <LabelList
                        dataKey='utilization'
                        content={(props) => renderCustomizedBarLabels(props, 1)}
                      />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className='flex justify-center w-full mt-4'>
                <img
                  src={legend}
                  className='w-40 h-4'
                  alt={intl.get(
                    'CAPACITY_INSIGHTS.UTILIZATION_BY_TEAM.CHART_LEGEND'
                  )}
                  data-testid='utilization-by-team_chart-legend'
                />
              </div>
            </>
          ) : chartData ? (
            <>
              {chartTitle}
              <NoData />
            </>
          ) : (
            <>
              {chartTitle}
              <LoadingError />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default UtilizationByTeamChart;
