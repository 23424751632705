import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { fetchTimeOffDays } from 'api/manageTimeOff';
import { closeSidePanel } from '../SidePanel/sidePanelSlice';
import { PartialHours } from 'types/store/manageTimeOff';

interface TimeOffDaysState {
  entryId: string;
  days: PartialHours;
  links: {
    next: string | null;
  };
  status: Status;
}

interface FetchTimeOffEntryDaysParams {
  userId: string;
  entryId: string;
}

const initialState: TimeOffDaysState = {
  entryId: '',
  days: {},
  links: {
    next: null,
  },
  status: SLICE_STATUS.IDLE,
};

export const fetchTimeOffEntryDays = createAsyncThunk(
  'manageTimeOff/timeOffDays/fetchTimeOffEntryDays',
  async (
    { userId, entryId }: FetchTimeOffEntryDaysParams,
    { rejectWithValue }
  ) => {
    try {
      const response = await fetchTimeOffDays({
        entryId,
        userId,
        query: 'limit=550',
      });
      const days = Object.entries(response.timeOff).reduce(
        (acc: PartialHours, current) => {
          const [key, value] = current;
          acc[key] = parseFloat(value.hours);
          return acc;
        },
        {}
      );
      return {
        days,
        links: response.links,
      };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const timeOffDaysSlice = createSlice({
  name: 'manageTimeOff/timeOffDays',
  initialState,
  reducers: {
    setEntryId: (state, action) => {
      state.entryId = action.payload;
    },
    resetDays: (state) => {
      state.days = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimeOffEntryDays.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchTimeOffEntryDays.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = SLICE_STATUS.IDLE;
          state.days = {
            ...state.days,
            ...action.payload.days,
          };
          state.links = action.payload.links;
        }
      })
      .addCase(fetchTimeOffEntryDays.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(closeSidePanel, (state) => {
        if (state.entryId) {
          state.entryId = '';
        }
        if (Object.keys(state.days).length > 0) {
          state.days = {};
        }
      });
  },
});

export const { setEntryId, resetDays } = timeOffDaysSlice.actions;

export const selectFetchTimeOffEntryDaysStatus = (state: RootState) =>
  state.manageTimeOff.timeOffDays.status;
export const selectCanFetchMoreTimeOffDays = (state: RootState) =>
  !!state.manageTimeOff.timeOffDays.links.next;
export const selectTimeOffDays = (state: RootState) =>
  state.manageTimeOff.timeOffDays.days;
export const selectTimeOffEntryId = (state: RootState) =>
  state.manageTimeOff.timeOffDays.entryId;
export const selectTimeOffDay = createSelector(
  [selectTimeOffDays, (_: RootState, day: string) => day],
  (days: TimeOffDaysState['days'], day) => days[day]
);

export default timeOffDaysSlice.reducer;
