import { ComponentProps, useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import { Dropdown, FormItem } from '@getsynapse/design-system';
import { ProjectCategory, SortingType } from 'utils/customTypes';
import { getInitialValueForDropDown } from 'utils/functions';

type Props = {
  formItemProps: ComponentProps<typeof FormItem>;
  categories: ProjectCategory[];
  sortOrder?: SortingType;
  dropdownProps: Partial<ComponentProps<typeof Dropdown>>;
  currentValue: string;
};

const ProjectCategoryPicker = ({
  formItemProps,
  dropdownProps,
  currentValue,
  sortOrder = 'asc',
  categories = [],
}: Props) => {
  const formatOptions = useMemo(() => {
    return orderBy(
      categories,
      (category) => {
        const label = get(category, 'categoryName') || '';
        return label.toLocaleLowerCase();
      },
      sortOrder
    ).map((category) => ({
      label: get(category, 'categoryName'),
      value: get(category, 'id'),
    }));
  }, [categories, sortOrder]);

  const {
    onChange = () => {},
    multiple,
    ...otherDropdownProps
  } = dropdownProps;
  return (
    <FormItem {...formItemProps}>
      <Dropdown
        multiple={multiple}
        onChange={onChange}
        options={formatOptions}
        values={getInitialValueForDropDown(formatOptions, currentValue)}
        {...otherDropdownProps}
      />
    </FormItem>
  );
};

export default ProjectCategoryPicker;
