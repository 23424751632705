import { Button, useElevation } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

interface TaskDetailFooterProps {
  onCancel: () => void;
  onSave: () => void;
  isUpdating?: boolean;
  taskDisabled: boolean;
}

const TaskDetailFooter = ({
  onCancel,
  onSave,
  isUpdating = false,
  taskDisabled,
}: TaskDetailFooterProps) => {
  const footerElevation = useElevation(1);

  return (
    <div
      className={`w-full bg-neutral-white py-2 flex justify-end z-5 ${footerElevation}`}
    >
      <div className='flex space-x-4 mr-12'>
        <Button
          variant='secondary'
          onClick={onCancel}
          data-cy='task-cancel-button'
        >
          {intl.get('TASKS.TASK_DETAIL_PAGE.CANCEL_UPDATES_BUTTON')}
        </Button>

        <Button
          disabled={isUpdating || taskDisabled}
          onClick={onSave}
          data-cy='task-save-button'
        >
          {intl.get('TASKS.TASK_DETAIL_PAGE.SAVE_UPDATES_BUTTON')}
        </Button>
      </div>
    </div>
  );
};

export default TaskDetailFooter;
