import { AxiosInstance } from 'axios';
import get from 'lodash/get';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { objKeyAsString } from 'utils/customTypes';
import { NewFilterSettings } from './FiltersSlice';

class FilterSettingsApi {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  retrieveFilterSettingByType = async (filterType: string) => {
    const response = await this.instance.get(
      `filterSettings/retrieveFilterSetting/${filterType}`
    );
    if (response.data && response.status === 200) {
      return response.data.data;
    }
    const isAxiosError = get(response, 'isAxiosError', false);
    if (isAxiosError) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
  };

  createFilterSetting = async (newFilterSettings: NewFilterSettings) => {
    const response = await this.instance.post(
      'filterSettings/createFilterSetting',
      {
        ...newFilterSettings,
      }
    );
    if (response.data && response.status === 200) {
      return response.data.data;
    }
    const isAxiosError = get(response, 'isAxiosError', false);
    if (isAxiosError) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
  };

  updateFilterSetting = async (
    filterId: string,
    updateFields: { filter_settings: objKeyAsString }
  ) => {
    const response = await this.instance.put(
      `filterSettings/updateFilterSetting/${filterId}`,
      {
        updateFields,
      }
    );
    if (response.data && response.status === 200) {
      return response.data.data;
    }
    const isAxiosError = get(response, 'isAxiosError', false);
    if (isAxiosError) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
  };
}

const filterSettingsApi = new FilterSettingsApi();

export default filterSettingsApi;
