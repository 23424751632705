import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { isEmpty } from 'lodash';
import { tailwindOverride } from '@getsynapse/design-system';
import {
  closePanel,
  selectSidePanelData,
  resetSidePanelUpdatedData,
} from 'state/SidePanel/sidePanelSlice';
import { getLearningTeams } from 'state/LearningTeams/learningTeamsSlice';
import {
  displayNotification,
  setNotificationText,
  setNotificationTimeout,
  setNotificationVariant,
} from 'state/InlineNotification/inlineNotificationSlice';
import {
  getRequest,
  getRequestQuestions,
  resetActiveRequest,
  selectActiveRequest,
  updateOwners,
  updateReviewers,
} from 'state/ActiveRequest/activeRequestSlice';
import {
  editRequest,
  updateRequestQuestions,
} from 'state/ActiveRequest/activeRequestSlice';
import {
  selectTimeOffById,
  setTimeOffIdToUpdate,
  userCreateTimeOff,
  updateUserTimeOff,
} from 'state/TimeOff/TimeOffSlice';
import { getUserRequests, getAllRequests } from 'state/Requests/requestSlice';
import { SIDE_PANEL_TYPES, REQUEST_STATUS, USER_TYPES } from 'utils/constants';
import {
  NewProject,
  TaskDetailType,
  UpdateReqData,
  User,
  TimeOff,
} from 'utils/customTypes';
import { formatRequestIdentifier } from 'Pages/helpers';
import PanelHeader from './components/PanelHeader';
import PanelContent from './components/PanelContent';
import PanelFooter from './components/PanelFooter';
import SidePanelSkeletonLoader from './components/SidePanelSkeletonLoader';

const SidePanel = () => {
  const dispatch = useDispatch();
  const [isDataReady, setIsDataReady] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [showUnsavedBanner, setShowUnsavedBanner] = useState<boolean>(false);
  const [showUnsavedChangesAnimation, setShowUnsavedChangesAnimation] =
    useState<boolean>(false);
  const [shouldDisableUpdate, setShouldDisableUpdate] = useState<boolean>(true);
  const [shouldDisableSubmitRequest, setShouldDisableSubmitRequest] =
    useState<boolean>(true);
  const [isRequestDetailFieldsValid, setIsRequestDetailFieldsValid] =
    useState<boolean>(false);
  const sidePanel = useSelector(selectSidePanelData);
  const requestSelector = useSelector(selectActiveRequest);
  const timeOffSelector = useSelector(selectTimeOffById);
  const [updatedData, setUpdatedData] = useState<
    NewProject | UpdateReqData | TaskDetailType | TimeOff | null
  >(null);
  const shouldOpenPanel =
    sidePanel.isOpen && sidePanel.type && sidePanel.resourceId;

  const onClosePanel = (force: boolean) => {
    if (force) {
      resetData();
      dispatch(closePanel());
    } else {
      if (!unsavedChanges) {
        resetData();
        dispatch(closePanel());
      } else {
        setShowUnsavedBanner(true);
        setShowUnsavedChangesAnimation(true);
        setTimeout(() => {
          setShowUnsavedChangesAnimation(false);
        }, 500);
      }
    }
  };

  useEffect(() => {
    if (!unsavedChanges && showUnsavedBanner) {
      setShowUnsavedBanner(false);
    }
  }, [unsavedChanges, setShowUnsavedBanner, showUnsavedBanner]);

  const onUpdate = async (submitFlag?: boolean) => {
    dispatch(setNotificationVariant('success'));
    dispatch(setNotificationTimeout(4000));
    let notificationText = '';
    switch (sidePanel.type) {
      case SIDE_PANEL_TYPES.REQUEST:
        const formattedRequestId = formatRequestIdentifier(
          requestSelector.requestIdentifier!
        );
        notificationText = submitFlag
          ? intl.get('REQUEST_PAGE.NOTIFICATIONS.REQUEST_SUBMIT', {
              requestNo: formattedRequestId,
            })
          : intl.get('REQUEST_PAGE.NOTIFICATIONS.MODIFICATION_SUCCESS', {
              requestNo: formattedRequestId,
            });
        if (submitFlag) {
          await dispatch(
            editRequest({
              request: {
                ...requestSelector,
                ...updatedData?.requestAttributes,
              },
              updateData: {
                status: REQUEST_STATUS.SUBMITTED,
              },
            })
          );
        } else if (!isEmpty(updatedData?.requestAttributes)) {
          await dispatch(
            editRequest({
              request: {
                ...requestSelector,
                ...updatedData?.requestAttributes,
              },
              updateData: {},
            })
          );
        }
        if (!isEmpty(updatedData?.requestQuestions)) {
          await dispatch(
            updateRequestQuestions({
              requestId: sidePanel.resourceId,
              updateData: updatedData?.requestQuestions,
            })
          );
        }
        if (updatedData?.requestAttributes?.updatedOwners) {
          await dispatch(
            updateOwners({
              requestId: sidePanel.resourceId,
              ownersIds: updatedData?.requestAttributes.updatedOwners,
            })
          );
        }
        if (
          updatedData?.requestAttributes?.businessReviewers &&
          updatedData?.requestAttributes?.ldReviewers
        ) {
          await dispatch(
            updateReviewers({
              requestId: sidePanel.resourceId,
              reviewersId:
                updatedData?.requestAttributes.businessReviewers.concat(
                  updatedData?.requestAttributes.ldReviewers
                ),
            })
          );
        } else if (updatedData?.requestAttributes?.businessReviewers) {
          const originalLDReviewerIds = requestSelector.reviewers
            ?.filter((reviewer: User) => reviewer.type === USER_TYPES.L_D)
            .map((reviewer: User) => reviewer.id);
          await dispatch(
            updateReviewers({
              requestId: sidePanel.resourceId,
              reviewersId:
                updatedData?.requestAttributes.businessReviewers.concat(
                  originalLDReviewerIds
                ),
            })
          );
        } else if (updatedData?.requestAttributes?.ldReviewers) {
          const originalBusinessReviewerIds = requestSelector.reviewers
            ?.filter((reviewer: User) => reviewer.type === USER_TYPES.BUSINESS)
            .map((reviewer: User) => reviewer.id);
          await dispatch(
            updateReviewers({
              requestId: sidePanel.resourceId,
              reviewersId: updatedData?.requestAttributes.ldReviewers.concat(
                originalBusinessReviewerIds
              ),
            })
          );
        }
        await dispatch(getAllRequests());
        await dispatch(getUserRequests());
        break;
      case SIDE_PANEL_TYPES.TIME_OFF:
        const timeOffId = sidePanel.resourceId;
        let action = '';
        if (timeOffId === 'new') {
          action = intl.get('USER_TIME_OFF_PAGE.SIDE_PANEL.ACTIONS.ADD');
          await dispatch(userCreateTimeOff(updatedData as TimeOff));
        } else {
          action = intl.get('USER_TIME_OFF_PAGE.SIDE_PANEL.ACTIONS.UPDATE');
          await dispatch(updateUserTimeOff(updatedData as TimeOff));
        }
        notificationText = intl.get(
          'USER_TIME_OFF_PAGE.SIDE_PANEL.SUCCESS_MESSAGE',
          { action }
        );
        break;
    }
    onClosePanel(true);
    dispatch(setNotificationText(notificationText));
    dispatch(displayNotification());
    resetSidePanelUpdatedData();
  };

  const data: any = useMemo(() => {
    switch (sidePanel.type) {
      case SIDE_PANEL_TYPES.REQUEST:
        if (!isDataReady && requestSelector.id) {
          setUpdatedData(null);
          setIsDataReady(true);
        }
        return requestSelector;
      case SIDE_PANEL_TYPES.TIME_OFF:
        if (!isDataReady) {
          let timeOff = {} as TimeOff;
          if (timeOffSelector?.id) {
            timeOff = { ...timeOffSelector } as TimeOff;
          }
          setUpdatedData(timeOff);
          setIsDataReady(true);
        }
        return timeOffSelector;
      default:
        return null;
    }
  }, [sidePanel.type, isDataReady, requestSelector, timeOffSelector]);

  const resetData = useCallback(() => {
    setShouldDisableUpdate(true);
    setShouldDisableSubmitRequest(true);
    setIsRequestDetailFieldsValid(false);
    setShowUnsavedBanner(false);
    setUnsavedChanges(false);
    setIsDataReady(false);
    switch (sidePanel.type) {
      case SIDE_PANEL_TYPES.REQUEST:
        dispatch(resetActiveRequest());
        break;
      case SIDE_PANEL_TYPES.TIME_OFF:
        dispatch(setTimeOffIdToUpdate(null));
    }
  }, [dispatch, sidePanel.type]);

  const getData = useCallback(async () => {
    switch (sidePanel.type) {
      case SIDE_PANEL_TYPES.REQUEST:
        dispatch(getRequest(sidePanel.resourceId));
        dispatch(getRequestQuestions(sidePanel.resourceId));
        dispatch(getLearningTeams());
        break;
      case SIDE_PANEL_TYPES.TIME_OFF:
        dispatch(
          setTimeOffIdToUpdate(
            sidePanel.resourceId !== 'new' ? sidePanel.resourceId : null
          )
        );
        break;
      default:
        break;
    }
  }, [dispatch, sidePanel.resourceId, sidePanel.type]);

  useEffect(() => {
    if (sidePanel.isOpen && sidePanel.resourceId) {
      getData();
    }

    return () => {
      resetData();
    };
  }, [sidePanel.isOpen, sidePanel.resourceId, getData, resetData]);

  const showSubmitButton = useMemo(
    () =>
      sidePanel.type === SIDE_PANEL_TYPES.REQUEST &&
      requestSelector.status === REQUEST_STATUS.DRAFT,
    [requestSelector.status, sidePanel.type]
  );

  const isAdding =
    sidePanel.type === SIDE_PANEL_TYPES.TIME_OFF &&
    sidePanel.resourceId === 'new';

  return sidePanel.isOpen ? (
    <>
      <div
        className={tailwindOverride(
          'absolute w-screen h-screen bg-neutral-black opacity-30 hidden z-50',
          `${shouldOpenPanel && 'block'}`
        )}
      />
      <div
        className={tailwindOverride(
          'transition-width',
          'duration-150 ease-in',
          'h-screen w-0 absolute right-0 bottom-0 z-50 bg-neutral-white',
          `${
            shouldOpenPanel &&
            'w-140 border border-neutral-lighter shadow-allocation-table'
          }`
        )}
        data-testid={`side-panel-${sidePanel.type}`}
      >
        {isDataReady ? (
          <div className={`${!shouldOpenPanel && 'hidden'}`}>
            <PanelHeader
              type={sidePanel.type}
              data={data}
              onClosePanel={onClosePanel}
              updatedData={updatedData!}
            />
            <PanelContent
              type={sidePanel.type}
              data={data}
              setShouldDisableUpdate={setShouldDisableUpdate}
              shouldDisableUpdate={shouldDisableUpdate}
              unsavedChanges={showUnsavedBanner}
              showUnsavedChangesAnimation={showUnsavedChangesAnimation}
              updatedData={updatedData!}
              setUpdatedData={setUpdatedData}
              setUnsavedChanges={setUnsavedChanges}
              setShouldDisableSubmitRequest={setShouldDisableSubmitRequest}
              setIsRequestDetailFieldsValid={setIsRequestDetailFieldsValid}
              isDataReady={isDataReady}
            />
            <PanelFooter
              onUpdate={() => onUpdate(false)}
              onClosePanel={onClosePanel}
              disabled={shouldDisableUpdate}
              showSubmitButton={showSubmitButton}
              onSubmitRequest={() => onUpdate(true)}
              shouldDisableSubmitRequest={shouldDisableSubmitRequest}
              isRequestDetailFieldsValid={isRequestDetailFieldsValid}
              isAdding={isAdding}
              updatedData={updatedData!}
            />
          </div>
        ) : (
          <SidePanelSkeletonLoader />
        )}
      </div>
    </>
  ) : null;
};

export default SidePanel;
