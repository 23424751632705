import { useMemo } from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import { selectBusinessTeamsForDropdown } from 'state/BusinessTeams/businessTeamsSlice';
import { selectLearningTeamsForDropdown } from 'state/LearningTeams/learningTeamsSlice';
import { COUNTRIES, EMPLOYMENT_TYPE, USER_STATUS } from 'utils/constants';
import { USER_TABLE_FILTERS } from 'utils/constants/user';
import { Option } from 'utils/customTypes';
import { UserFilters, UserFiltersKey } from 'utils/types/filters';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getUserTypeOptionsForDropdown } from '../AddUserModalHelper';

const userStatus = [
  USER_STATUS.REGISTERED,
  USER_STATUS.INVITED,
  USER_STATUS.REGISTERED_DISABLED,
  'pending',
];

const sortAlphabetically = (a: Option, b: Option) =>
  a.label.localeCompare(b.label, undefined, {
    numeric: true,
    sensitivity: 'base',
  });

const useUserFilters = (appliedFilters: UserFilters) => {
  const learningTeams = useSelector(selectLearningTeamsForDropdown);
  const businessTeams = useSelector(selectBusinessTeamsForDropdown);
  const { facilitatorManagement = false } = useFlags();

  const employmentTypeOptions = useMemo<Option[]>(() => {
    const options = EMPLOYMENT_TYPE.map((type) => ({
      value: type,
      label: intl.get(`TEAMS.EMPLOYMENT_TYPE.${type}`),
    })).sort(sortAlphabetically);

    return options;
  }, []);

  const countryOptions = useMemo<Option[]>(() => {
    const options = COUNTRIES.map((country) => ({
      value: country,
      label: intl.get(`COUNTRIES.${country.toLocaleUpperCase()}`),
    })).sort(sortAlphabetically);

    return options;
  }, []);

  const userTypeOptions = useMemo<Option[]>(() => {
    const options = getUserTypeOptionsForDropdown(facilitatorManagement).sort(
      sortAlphabetically
    );

    return options;
  }, [facilitatorManagement]);

  const teamOptions = useMemo<Option[]>(() => {
    const options = [...learningTeams, ...businessTeams].map((team) => ({
      value: team.value,
      label: team.label,
    }));

    return options;
  }, [businessTeams, learningTeams]);

  const statusOptions = useMemo<Option[]>(() => {
    const options = userStatus
      .map((status) => ({
        value: status,
        label: intl.get(
          `${
            status !== 'pending'
              ? 'USERS_PAGE.TABLE.USER_STATUS'
              : 'USERS_PAGE.TABLE.USER_PENDING'
          }.${status.toUpperCase()}`
        ),
      }))
      .sort(sortAlphabetically);

    return options;
  }, []);

  const jobTitleOptions = useMemo<Option[]>(() => {
    const jobTitleFilter = appliedFilters[
      USER_TABLE_FILTERS.JOB_TITLE
    ] as string[];
    if (jobTitleFilter && jobTitleFilter.length > 0) {
      return jobTitleFilter.map((jobTitle) => ({
        value: jobTitle,
        label: jobTitle,
      }));
    } else {
      return [];
    }
  }, [appliedFilters]);

  const getOptionsbyKey: (key: UserFiltersKey) => Option[] = (key) => {
    switch (key) {
      case USER_TABLE_FILTERS.EMPLOYMENT_TYPE:
        return employmentTypeOptions;
      case USER_TABLE_FILTERS.COUNTRY:
        return countryOptions;
      case USER_TABLE_FILTERS.TYPE:
        return userTypeOptions;
      case USER_TABLE_FILTERS.TEAM:
        return teamOptions;
      case USER_TABLE_FILTERS.STATUS:
        return statusOptions;
      case USER_TABLE_FILTERS.JOB_TITLE:
        return jobTitleOptions;
      default:
        return [];
    }
  };

  return {
    employmentTypeOptions,
    countryOptions,
    userTypeOptions,
    teamOptions,
    statusOptions,
    getOptionsbyKey,
  };
};

export default useUserFilters;
