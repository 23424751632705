import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import { INSIGHTS_PAGE_COLORS } from 'utils/constants';
import { tailwindOverride } from '@getsynapse/design-system';

const PieChartLoader = ({ className = '' }: { className?: string }) => {
  const data = [
    {
      declinedRequestReason: 'Not Aligned',
      requestsTotal: 20,
    },
    {
      declinedRequestReason: "Can't meet timeline",
      requestsTotal: 10,
    },
    {
      declinedRequestReason: 'Lack of resources',
      requestsTotal: 21,
    },
    {
      declinedRequestReason: 'Inadequate Request',
      requestsTotal: 4,
    },
  ];

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill='none'
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill='#333'
        >{`PV ${value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill='#999'
        >
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  return (
    <div className={tailwindOverride('h-full w-1/2 animate-pulse', className)}>
      <ResponsiveContainer width='100%' height='100%'>
        <PieChart>
          <Pie
            activeShape={renderActiveShape}
            data={data}
            innerRadius={40}
            outerRadius={76}
            cx='45%'
            fill={INSIGHTS_PAGE_COLORS.SKELETON_LOADER_COLOR}
            dataKey='requestsTotal'
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieChartLoader;
