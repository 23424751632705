import React from 'react';
import intl from 'react-intl-universal';
import { Button, tailwindOverride } from '@getsynapse/design-system';
import FilterIcon from 'assets/icons/filter.svg';

type ExportButtonProps = {
  onToggleFilter: () => void;
} & React.ComponentProps<typeof Button>;

const FilterButton: React.FC<ExportButtonProps> = ({
  onToggleFilter,
  className,
  ...buttonProps
}) => {
  return (
    <Button
      data-testid='table-header__filter-button'
      size='small'
      variant='tertiary'
      onClick={onToggleFilter}
      iconSrc={FilterIcon}
      iconPosition='left'
      className={tailwindOverride(className, 'border', {
        'cursor-not-allowed': buttonProps.disabled,
        'bg-neutral-lightest': buttonProps['aria-pressed'],
      })}
      iconName={tailwindOverride('fill-current text-primary', {
        'text-neutral-lightest': buttonProps.disabled,
      })}
      {...buttonProps}
    >
      {intl.get('TABLE_HEADER_ACTIONS.FILTER')}
    </Button>
  );
};

export default FilterButton;
