import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import { useParams } from 'react-router-dom';
import { Typography, tailwindOverride } from '@getsynapse/design-system';
import { trackEvent } from 'Services/pendo';
import { AdditionalBenefit } from 'utils/types/program';
import AdditionalBenefitTag from 'Pages/AdditionalBenefitPage/components/AdditionalBenefitTag';
import { PATHS, PENDO_EVENTS } from 'utils/constants';

type Props = {
  benefit: AdditionalBenefit;
  index: number;
};

const AdditionalBenefitCard = ({ benefit, index }: Props) => {
  const { programId, categoryId } = useParams<{
    programId: string;
    categoryId: string;
  }>();

  const onManageBenefit = () => {
    trackEvent(PENDO_EVENTS.CTA_MANAGE_BENEFIT, {});
  };

  return (
    <Link
      to={`${PATHS.PROGRAM_PAGE}/${programId}${PATHS.STRATEGY_PAGE}/${categoryId}${PATHS.ADDITIONAL_BENEFIT_PAGE}/${benefit.id}`}
      data-testid={`additional_benefit-${benefit.id}_card`}
      key={benefit.id}
      className={tailwindOverride(
        'group py-5 px-6 relative',
        'border rounded-lg border-primary-lighter-two',
        'transition duration-100 ease-linear',
        'shadow-header',
        'hover:border-secondary-light',
        'hover:shadow-lifted',
        'flex flex-wrap'
      )}
      onClick={onManageBenefit}
    >
      <Typography
        data-testid={`additional_benefit-${benefit.id}_number`}
        weight='medium'
        className='leading-6 pb-1 w-full'
      >
        {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFITS.BENEFIT', {
          number: index + 1,
        })}
      </Typography>
      <Typography
        variant='body2'
        title={benefit.benefitText}
        data-testid={`additional_benefit-${benefit.id}_name`}
        className='w-full leading-6 line-clamp-1'
      >
        {benefit.benefitText}
      </Typography>
      <div
        className={tailwindOverride(
          'sm:flex-col md:flex-col',
          'lg:flex-row',
          'w-full pt-3 flex items-start'
        )}
      >
        <AdditionalBenefitTag
          isBenefitMonetary={benefit.isMonetary ?? null}
          annualBenefit={benefit.annualBenefit ?? null}
        />
        <Typography
          data-testid={`additional_benefit-${benefit.id}_manage-benefit`}
          variant='body2'
          title={intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFITS.MANAGE_BENEFIT'
          )}
          className={tailwindOverride(
            'block ml-auto text-sm font-medium',
            'text-purple-dark',
            'group-hover:text-purple-darker',
            'text-center leading-5'
          )}
          onClick={onManageBenefit}
        >
          {intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFITS.MANAGE_BENEFIT'
          )}
        </Typography>
      </div>
    </Link>
  );
};

export default AdditionalBenefitCard;
