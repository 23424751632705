import intl from 'react-intl-universal';
import { CommentsPanel, Comment } from '@getsynapse/design-system';

import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  createTaskComment,
  deleteTaskComment,
  getSingleTaskId,
  getTaskComments,
  updateTaskComment,
} from 'state/SingleTask/singleTaskSlice';
import { useAppThunkDispatch } from 'state/store';
import { MentionItem } from 'react-mentions';
import MentionableCommentForm from 'Organisms/Comments/Mentions/MentionableCommentForm';
import { selectUser } from 'state/User/userSlice';
import { Comment as CommentType } from 'utils/types/comments';
import { DeleteCommentConfirmationModal } from 'Organisms/Comments/DeleteCommentConfirmationModal/DeleteCommentConfirmationModal';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';

const TaskCommentsPanel = () => {
  const taskId = useSelector(getSingleTaskId);
  const taskComments = useSelector(getTaskComments);
  const user = useSelector(selectUser);

  const dispatch = useAppThunkDispatch();

  const [isCommentsExpanded, setIsCommentsExpanded] = useState<boolean>(false);
  const [commentToDelete, setCommentToDelete] = useState<CommentType | null>(
    null
  );
  const [commentToEdit, setCommentToEdit] = useState<CommentType | null>(null);

  const notifyResult = useCallback(
    (result, successMessage: string) => {
      if (result.meta.requestStatus === 'rejected') {
        dispatch(
          showSnackbarNotification({
            notificationVariant: 'error',
            notificationTitle: intl.get('ERROR_SNACKBAR_TITLE'),
            notificationMessage: intl.get('COMMENT.COMMENT_ERROR'),
          })
        );
      } else {
        dispatch(
          showSnackbarNotification({
            notificationVariant: 'success',
            notificationTitle: intl.get('SUCCESS_SNACKBAR_TITLE'),
            notificationMessage: intl.get(successMessage),
          })
        );
      }
    },
    [dispatch]
  );

  const handleAddComment = async (content: string, mentions: MentionItem[]) => {
    const result = await dispatch(
      createTaskComment({
        taskId,
        content,
        mentions: mentions.map((mention) => mention.id),
      })
    );
    notifyResult(result, 'COMMENT.COMMENT_CREATED');
  };

  const handleEditComment = async (
    content: string,
    mentions: MentionItem[]
  ) => {
    if (commentToEdit) {
      const result = await dispatch(
        updateTaskComment({
          commentId: commentToEdit.id,
          taskId,
          content,
          mentions: mentions.map((mention) => mention.id || String(mention)),
        })
      );
      notifyResult(result, 'COMMENT.COMMENT_UPDATED');
      setCommentToEdit(null);
    }
  };
  const handleDeleteComment = async () => {
    if (commentToDelete) {
      const result = await dispatch(
        deleteTaskComment({ commentId: commentToDelete.id })
      );
      notifyResult(result, 'COMMENT.COMMENT_DELETED');
      setCommentToDelete(null);
    }
  };

  const handleClickDeleteComment = async (comment: CommentType) => {
    setCommentToDelete(comment);
  };
  const handleClickEditComment = async (comment: CommentType) => {
    setCommentToEdit(comment);
  };

  return (
    <>
      <CommentsPanel
        commentsList={taskComments}
        isExpanded={isCommentsExpanded}
        onToggle={() => setIsCommentsExpanded(!isCommentsExpanded)}
        canAddComment
        addCommentControl={
          <MentionableCommentForm
            placeholder={intl.get(
              'TASKS.TASK_DETAIL_PAGE.COMMENTS.ADD_COMMENT'
            )}
            onSubmitComment={handleAddComment}
          />
        }
        renderComment={(comment: CommentType) => (
          <Comment
            comment={comment}
            isMutable={user.id === comment.author.id}
            onClickEdit={handleClickEditComment}
            onClickDelete={handleClickDeleteComment}
            isEditing={commentToEdit?.id === comment.id}
            editControl={
              <MentionableCommentForm
                placeholder={intl.get(
                  'TASKS.TASK_DETAIL_PAGE.COMMENTS.ADD_COMMENT'
                )}
                onSubmitComment={handleEditComment}
                onCancel={() => setCommentToEdit(null)}
                defaultContent={comment.content}
                defaultMentions={comment.mentions}
              />
            }
          />
        )}
        labels={{
          emptyState: intl.get('TASKS.TASK_DETAIL_PAGE.COMMENTS.NO_COMMENTS'),
          title: intl.get('TASKS.TASK_DETAIL_PAGE.COMMENTS.TITLE', {
            num: taskComments.length,
          }),
          viewTooltip: intl.get(
            'TASKS.TASK_DETAIL_PAGE.COMMENTS.VIEW_COMMENTS'
          ),
        }}
      />
      <DeleteCommentConfirmationModal
        isOpen={commentToDelete !== null}
        onCancel={() => setCommentToDelete(null)}
        onConfirm={() => handleDeleteComment()}
      />
    </>
  );
};

export default TaskCommentsPanel;
