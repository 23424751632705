import { useEffect, useMemo, useState } from 'react';
import { useParams, Prompt, useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@getsynapse/design-system';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import {
  fetchCustomField,
  resetCustomField,
  selectCurrentCustomField,
  updateCustomField,
  selectFieldsSliceStatus,
} from 'state/CustomFields/customFieldsSlice';
import FooterButtons from './components/FooterButtons';
import DeleteCustomFieldModal from './components/DeleteCustomFieldModal';
import FieldsOverview from './components/FieldOverview';
import {
  FieldTemplateType,
  FieldType,
  FieldValueType,
  FieldDefaultValue,
} from 'utils/types/fields';
import { isEqual, get, isEmpty } from 'lodash';
import { checkIfFieldIsValid, removeUnchangedData } from './helpers/helper';
import UpdateWarningModal from './components/UpdateWarningModal';
import projectTemplatesAPI from 'state/ProjectTemplates/projectTemplatesAPI';
import {
  ALLOWED_FIELD_TYPES,
  ALLOWED_FIELD_VALUE_SELECTORS,
  SLICE_STATUS,
} from 'utils/constants';
import DefaultValuesModal from './components/DefaultValuesModal';
import UnsavedChangesModal from 'Organisms/UnsavedChangesModal/UnsavedChangesModal';
import { Location } from 'history';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import Loader from 'Molecules/Loader/Loader';

const CustomFieldPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { fieldId } = useParams<{ fieldId: string }>();
  const currentField = useSelector(selectCurrentCustomField);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDefaultValueModalOpen, setIsDefaultValueModalOpen] = useState(false);
  const [updatedField, setUpdatedField] = useState<FieldTemplateType>(
    currentField!
  );
  const [cleanData, setCleanData] = useState<FieldTemplateType>(currentField!);
  const [disableSaveBuuton, setDisableSaveButton] = useState(true);
  const [titleError, setTitleError] = useState(false);
  const [defaultValues, setDefaultValues] = useState<FieldDefaultValue[]>([]);
  const [confirmedNavigation, setConfirmedNavigation] =
    useState<boolean>(false);
  const [leavingToLocation, setLeavingToLocation] = useState<string>('');
  const [isLeavingWarningModalOpen, setIsLeavingWarningModalOpen] =
    useState<boolean>(false);

  const sliceStatus = useSelector(selectFieldsSliceStatus);

  const hasOptions =
    (currentField?.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.DROPDOWN ||
      currentField?.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.RADIO ||
      currentField?.value_selector ===
        ALLOWED_FIELD_VALUE_SELECTORS.CHECKBOX) &&
    currentField?.type !== ALLOWED_FIELD_TYPES.BOOLEAN;

  const changesDetected = useMemo(
    () => !isEqual(updatedField, currentField),
    [currentField, updatedField]
  );

  useEffect(() => {
    const handleLeavingSiteWithUnsavedChanges = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    if (changesDetected) {
      window.addEventListener(
        'beforeunload',
        handleLeavingSiteWithUnsavedChanges
      );
      return () => {
        window.removeEventListener(
          'beforeunload',
          handleLeavingSiteWithUnsavedChanges
        );
      };
    }
  }, [changesDetected]);

  useEffect(() => {
    if (confirmedNavigation && leavingToLocation) {
      history.push(leavingToLocation);
      setConfirmedNavigation(false);
      setLeavingToLocation('');
    }
  }, [confirmedNavigation, history, leavingToLocation]);

  useEffect(() => {
    if (updatedField?.id) {
      if (changesDetected && checkIfFieldIsValid(updatedField)) {
        setDisableSaveButton(false);
      } else {
        setDisableSaveButton(true);
      }
    }
  }, [changesDetected, updatedField]);

  useEffect(() => {
    if (fieldId) {
      dispatch(fetchCustomField(fieldId));
    }
    return () => {
      dispatch(resetCustomField());
    };
  }, [fieldId, dispatch]);

  useEffect(() => {
    setUpdatedField(currentField!);
  }, [currentField]);

  const checkRemovedOptions = (data: FieldTemplateType) => {
    if (data.allowed_values && hasOptions) {
      const projectFields = get(currentField, 'projectFields', []);
      const removedOptions: FieldValueType[] = [];
      projectFields.forEach((project: FieldType) => {
        const isProjectValueEmpty = isEmpty(project.value.val);
        if (typeof project.value.val === 'object' && !isProjectValueEmpty) {
          project.value.val?.forEach((item: FieldValueType) => {
            const isOptionExist = data.allowed_values?.some(
              (value) => value.val === item.val
            );
            if (!isOptionExist) {
              removedOptions.push(item);
            }
          });
        } else if (
          typeof project.value.val !== 'object' &&
          project.value.val.toString()
        ) {
          const isOptionExist = data.allowed_values?.some(
            (value) => value.val === project.value.val
          );
          if (!isOptionExist) {
            removedOptions.push(project.value as FieldValueType);
          }
        }
      });
      //remove duplicats from removedOptions
      const uniqueRemovedOptions = removedOptions.filter(
        (item: FieldValueType, index: number) =>
          removedOptions.findIndex(
            (value: FieldValueType) => value.val === item.val
          ) === index
      );
      return uniqueRemovedOptions;
    }
    return [];
  };
  const updateField = async () => {
    if (isDefaultValueModalOpen) {
      setIsDefaultValueModalOpen(false);
    }
    const updatedFieldResult = await dispatch(
      updateCustomField({ fieldId, updateFields: cleanData, defaultValues })
    );

    const success = get(
      updatedFieldResult,
      'payload.updatedCustomField',
      false
    );

    if (success) {
      dispatch(
        showSnackbarNotification({
          notificationVariant: 'success',
          notificationTitle: intl.get('SUCCESS_SNACKBAR_TITLE'),
          notificationMessage: intl.get(
            'SETTINGS_PAGE.FIELDS_PAGE.DETAILS.UPDATE_SUCCESS',
            { title: updatedField.name }
          ),
        })
      );
    } else {
      dispatch(
        showSnackbarNotification({
          notificationVariant: 'error',
          notificationTitle: '',
          notificationMessage: intl.get(
            'SETTINGS_PAGE.FIELDS_PAGE.DETAILS.UPDATE_ERROR'
          ),
        })
      );
    }
  };

  const confirmUpdate = async () => {
    setIsUpdateModalOpen(false);
    const removedValues = checkRemovedOptions(cleanData);
    const defaultOptions = removedValues.map((value) => ({
      oldValue: value.val,
      newValue: '',
    }));
    setDefaultValues(defaultOptions);
    if (removedValues.length) {
      setIsDefaultValueModalOpen(true);
    } else {
      updateField();
    }
  };

  const onSave = async () => {
    const data = removeUnchangedData(currentField!, updatedField);
    setCleanData(data);
    if (data.name) {
      const fields = await projectTemplatesAPI.fetchFieldTemplates();
      const isTitleExist = fields.some(
        (field: FieldTemplateType) =>
          field.name === data.name && field.id !== updatedField.id
      );
      if (isTitleExist) {
        setDisableSaveButton(true);
        setTitleError(true);
        return;
      }
      setIsUpdateModalOpen(true);
    } else if (!!Object.keys(data).length) {
      //check if there is other property than name
      setIsUpdateModalOpen(true);
    }
  };

  const handleBlockedNavigation = (location: Location) => {
    if (!confirmedNavigation) {
      setLeavingToLocation(`${location.pathname}${location.search}`);
      setIsLeavingWarningModalOpen(true);
      return false;
    }
    return true;
  };

  return (
    <div className='h-full flex flex-col'>
      <Prompt when={changesDetected} message={handleBlockedNavigation} />
      <UnsavedChangesModal
        isOpen={isLeavingWarningModalOpen}
        setIsOpen={setIsLeavingWarningModalOpen}
        onConfirm={() => setConfirmedNavigation(true)}
      />
      <DeleteCustomFieldModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        fieldId={fieldId}
      />
      <UpdateWarningModal
        isOpen={isUpdateModalOpen}
        setIsOpen={() => setIsUpdateModalOpen(false)}
        confirmUpdate={confirmUpdate}
      />
      <DefaultValuesModal
        isOpen={isDefaultValueModalOpen}
        setIsOpen={() => setIsDefaultValueModalOpen(false)}
        data={updatedField}
        updateField={updateField}
        setDefaultValues={setDefaultValues}
        defaultValues={defaultValues}
      />
      <PageTitle
        titleComponent={
          currentField?.name || intl.get('SETTINGS_PAGE.FIELDS_PAGE.PAGE_TITLE')
        }
        dataCy='custom-field-title'
      />
      <div className='mx-6 rounded-t-lg border-l border-r border-t border-neutral-lighter-two flex-grow'>
        <div className='h-12 border-b p-2 border-neutral-lighter-two flex items-center justify-end'>
          <Button
            variant='tertiary'
            iconName='trash'
            data-cy='custom-field-delete-button'
            onClick={() => setIsDeleteModalOpen(true)}
          >
            {intl.get('SETTINGS_PAGE.FIELDS_PAGE.DETAILS.DELETE_FIELD')}
          </Button>
        </div>
        {updatedField?.id && sliceStatus !== SLICE_STATUS.LOADING ? (
          <FieldsOverview
            data={updatedField}
            setData={setUpdatedField}
            onUpdatePage
            titleError={titleError}
            setTitleError={setTitleError}
          />
        ) : (
          <Loader />
        )}
      </div>
      <FooterButtons
        disableSaveButton={disableSaveBuuton}
        onSave={onSave}
        onUpdatePage
      />
    </div>
  );
};

export default CustomFieldPage;
