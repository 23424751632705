import { useState, ChangeEvent, useMemo } from 'react';
import {
  Modal,
  Typography,
  TextArea,
  FormItem,
  TextField,
} from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';
import { WAITLIST_CONDITIONS } from 'utils/constants';
import { Option } from 'utils/customTypes';

type WaitlistModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onWaitlist: ({
    conditions,
    comments,
    details,
  }: {
    conditions: string[];
    comments?: string | undefined;
    details?: string;
  }) => void;
};

const WaitlistModal = ({
  isOpen,
  onCancel,
  onWaitlist,
}: WaitlistModalProps) => {
  const [data, setData] = useState<{
    conditions: string[];
    comments?: string;
    details?: string;
    error: boolean;
  }>({
    conditions: [],
    comments: '',
    details: '',
    error: false,
  });

  const conditions = WAITLIST_CONDITIONS.map((condition) => ({
    value: condition,
    label: intl.get(
      `REQUEST_PAGE.TOP_BAR.WAITLIST_MODAL.WAITLIST_CONDITIONS.${condition}`
    ),
  }));

  const hasOthers = useMemo(
    () => data.conditions.findIndex((condition) => condition === 'OTHERS'),
    [data.conditions]
  );

  const setComments = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setData((prev) => ({ ...prev, comments: event.target.value }));
  };

  const setConditions = (conditions: Option[]) => {
    const newValues = conditions.map((condition) => condition.value);
    setData((prev) => ({ ...prev, conditions: newValues }));
  };

  const setDetails = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setData((prev) => ({
      ...prev,
      details: event.target.value,
      error: false,
    }));
  };

  const handleClose = () => {
    onCancel();
    setData({ conditions: [], comments: '', details: '', error: false });
  };

  const handleWaitlistRequest = () => {
    onWaitlist({
      ...data,
      details: data.details?.trim(),
      comments: data.comments?.trim(),
    });
    handleClose();
  };

  const validateData = () => {
    let isValid = !isEmpty(data.conditions);
    isValid = hasOthers !== -1 ? isValid && data.details !== '' : isValid;
    if (isValid) {
      handleWaitlistRequest();
    } else {
      setData((prev) => ({ ...prev, error: true }));
    }
  };

  return (
    <Modal
      childrenClassName=''
      aria-label='waitlist-request-modal'
      data-cy='waitlist-request-modal'
      actionButtons={[
        {
          children: intl.get(
            'REQUEST_PAGE.TOP_BAR.WAITLIST_MODAL.WAITLIST_REQUEST'
          ),
          variant: 'primary',
          onClick: validateData,
          'data-cy': 'request-waitlist-button',
          disabled: isEmpty(data.conditions),
        },
        {
          children: intl.get(
            'REQUESTS_LIST_PAGE.MODAL.DELETE_REQUEST.CANCEL_BUTTON'
          ),
          'data-cy': 'request-waitlist-cancel-button',
          variant: 'secondary',
          onClick: handleClose,
        },
      ]}
      closeModal={handleClose}
      isOpen={isOpen}
      title={intl.get('REQUEST_PAGE.TOP_BAR.WAITLIST_MODAL.WAITLIST_REQUEST')}
      size='medium'
    >
      <Typography className='mb-4'>
        {intl.get('REQUEST_PAGE.TOP_BAR.WAITLIST_MODAL.WAITLIST_MEANS')}
      </Typography>

      <Typography className='mb-4'>
        {intl.get('REQUEST_PAGE.TOP_BAR.WAITLIST_MODAL.PLEASE')}
      </Typography>

      <FormItem
        className='mb-4'
        label={intl.get(
          'REQUEST_PAGE.TOP_BAR.WAITLIST_MODAL.CONDITION_APPROVAL'
        )}
        labelProps={{ required: true }}
      >
        <MultiSelectDropdown
          options={conditions}
          values={[]}
          onChange={setConditions}
          multiple
          placeholder={intl.get(
            'REQUEST_PAGE.TOP_BAR.WAITLIST_MODAL.SELECT_CONDITIONS'
          )}
          triggerProps={{
            'data-cy': 'field_waitlist-conditions',
          }}
        />
      </FormItem>

      {hasOthers !== -1 && (
        <FormItem
          label={intl.get('REQUEST_PAGE.TOP_BAR.WAITLIST_MODAL.SPECIFY_OTHERS')}
          className='my-4'
          labelProps={{
            required: true,
            state: data.error ? 'error' : 'default',
          }}
        >
          <TextField
            variant='text'
            length='medium'
            placeholder={intl.get(
              'REQUEST_PAGE.TOP_BAR.WAITLIST_MODAL.MENTION_REASON'
            )}
            onChange={setDetails}
            value={data.details}
            className='focus:ring-0'
            data-cy='other-specification-input__waitlist'
            state={data.error ? 'error' : 'default'}
            helpText={
              data.error
                ? intl.get('REQUEST_PAGE.BASIC_DETAILS.ERRORS.MISSING_INPUT')
                : ''
            }
          />
        </FormItem>
      )}

      <TextArea
        label={intl.get('REQUESTS_LIST_PAGE.MODAL.APPROVE_REQUEST.COMMENTS')}
        textAreaProps={{
          placeholder: intl.get(
            'REQUESTS_LIST_PAGE.MODAL.APPROVE_REQUEST.DETAILS'
          ),
          className: 'h-24',
          'data-cy': 'field_waitlist-comments',
        }}
        value={data.comments}
        onChange={setComments}
      />
    </Modal>
  );
};

export default WaitlistModal;
