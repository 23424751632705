import React, { useEffect, useMemo, useCallback } from 'react';
import intl from 'react-intl-universal';
import { useDispatch } from 'react-redux';
import useModal from 'Hooks/useModal';
import { addNewProjectProcess } from 'state/Processes/processesSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import ProcessForm from './ProcessForm';
import useProjectProcessForm from '../hooks/useProjectProcessForm';

const ProcessModal: React.FC<{
  isModalOpen: boolean;
  onCloseModalHandle: () => void;
}> = ({ isModalOpen, onCloseModalHandle }) => {
  const dispatch = useDispatch();
  const { Modal, openModal, modalProps, closeModal } = useModal();
  const {
    projectProcess,
    canSubmitForm,
    updateProjectProcess,
    addNewProjectProcessStage,
    removeProjectProcessStage,
    updateProjectProcessStage,
    processStagesWithRepeatedNames,
    setProjectProcess,
    defaultProcessState,
  } = useProjectProcessForm(null);

  const handleCloseModal = useCallback(() => {
    onCloseModalHandle();
    closeModal();
  }, [closeModal, onCloseModalHandle]);

  const handleSave = useCallback(async () => {
    await dispatch(addNewProjectProcess(projectProcess));
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get(
          'SETTINGS_PAGE.ADD_PROCESS_MODAL.PROCESS_ADDED_SUCCESSFULLY_MESSAGE'
        ),
      })
    );
    handleCloseModal();
    setProjectProcess(defaultProcessState);
  }, [
    dispatch,
    projectProcess,
    handleCloseModal,
    setProjectProcess,
    defaultProcessState,
  ]);

  const actionButtons = useMemo(
    () => [
      {
        children: intl.get('NEW_PROJECT_PAGE.SAVE_BUTTON'),
        variant: 'primary',
        'data-cy': 'process-form__confirm-button',
        disabled: !canSubmitForm,
        onClick: handleSave,
      },
      {
        children: intl.get('NEW_PROJECT_PAGE.CANCEL_BUTTON'),
        variant: 'tertiary',
        onClick: handleCloseModal,
        'data-cy': 'process-form__cancel-button',
      },
    ],
    [canSubmitForm, handleCloseModal, handleSave]
  );

  useEffect(() => {
    if (isModalOpen && !modalProps.isOpen) {
      openModal(modalProps);
    }
  }, [isModalOpen, modalProps, openModal]);

  useEffect(() => {
    if (!isModalOpen && !modalProps.isOpen) {
      setProjectProcess(defaultProcessState);
    }
  }, [
    defaultProcessState,
    isModalOpen,
    modalProps,
    openModal,
    setProjectProcess,
  ]);

  return (
    <Modal
      {...modalProps}
      aria-label={intl.get('SETTINGS_PAGE.ADD_PROCESS_MODAL.TITLE')}
      closeModal={handleCloseModal}
      title={intl.get('SETTINGS_PAGE.ADD_PROCESS_MODAL.TITLE')}
      size='large'
      actionButtons={actionButtons}
      childrenClassName='max-h-136'
      data-cy='process-form__container'
    >
      <ProcessForm
        process={projectProcess}
        updateProcess={updateProjectProcess}
        updateStage={updateProjectProcessStage}
        addStage={addNewProjectProcessStage}
        removeStage={removeProjectProcessStage}
        stagesErrors={processStagesWithRepeatedNames || []}
        unremovableStages={[]}
      />
    </Modal>
  );
};

export default ProcessModal;
