import { useEffect, useMemo, useState, ChangeEvent, useCallback } from 'react';
import { useParams, Prompt, useHistory } from 'react-router-dom';
import { Location } from 'history';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { trackEvent } from 'Services/pendo';
import {
  Button,
  Typography,
  FormItem,
  Input,
  NumericInput,
  Tooltip,
  IconButton,
  Modal,
} from '@getsynapse/design-system';
import { getProgramData, fetchProgram } from 'state/Program/programSlice';
import {
  fetchROICosts,
  resetROICosts,
  selectROICostsStatus,
  selectROIOtherCosts,
  selectROIResourceCost,
  createROICosts,
  updateROICost,
  deleteROICost,
} from 'state/ROICosts/ROICostsSlice';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import PageFooter from 'Molecules/PageFooter/PageFooter';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import UnsavedChangesModal from 'Organisms/UnsavedChangesModal/UnsavedChangesModal';
import {
  ROI_COSTS_PRIMARY_FIELDS,
  STRATEGY_CATEGORIES,
} from 'utils/constants/program';
import TotalProgramCost from 'Pages/ROICategoryPage/components/TotalProgramCost/TotalProgramCost';
import ProcessStageDropdown from './components/ProcessStageDropdown/ProcessStageDropdown';
import CostComponent from './components/CostComponent';
import { SLICE_STATUS, PENDO_EVENTS } from 'utils/constants';
import ROICostsSkeletonLoader from './components/ROIOCostsSkeletonLoader';
import { CostDataType } from 'utils/types/program';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';

const ROICostsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enableProgramStrategy15Roi = false } = useFlags();

  const { programId, categoryId } = useParams<{
    programId: string;
    categoryId: string;
  }>();

  const [costs, setCosts] = useState<CostDataType[]>([]);
  const [resourceCost, setResourceCost] = useState<CostDataType>(
    {} as CostDataType
  );
  const [isResourceCostValueValid, setIsResourceCostValid] = useState(true);
  const [areOtherCostsValid, setAreOtherCostsValid] = useState<
    | {
        id: string;
        isValid: boolean;
      }[]
    | null
  >(null);
  const [confirmedNavigation, setConfirmedNavigation] =
    useState<boolean>(false);
  const [isLeavingWarningModalOpen, setIsLeavingWarningModalOpen] =
    useState<boolean>(false);
  const [leavingToLocation, setLeavingToLocation] = useState<string>('');
  const [areAllActionsCompleted, setAreAllActionsCompleted] =
    useState<boolean>(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [costToDelete, setCostToDelete] = useState<string>('');

  const programSelector = useSelector(getProgramData);
  const roiOtherCostsSelector = useSelector(selectROIOtherCosts);
  const roiResourceCostSelector = useSelector(selectROIResourceCost);
  const roiCostsStatusSelector = useSelector(selectROICostsStatus);

  const isDataReady =
    roiCostsStatusSelector === SLICE_STATUS.IDLE &&
    programSelector.id &&
    enableProgramStrategy15Roi;

  const totalProgramCost = useMemo(() => {
    if (
      !isNumber(resourceCost.cost) &&
      costs.every((cost) => !isNumber(cost.cost))
    ) {
      return null;
    }
    return (
      costs.reduce((acc, cost) => {
        return acc + (cost.cost || 0);
      }, 0) + (resourceCost.cost || 0)
    );
  }, [costs, resourceCost.cost]);

  const hasResourceCostChanged = useMemo(() => {
    let updatedResourceCost: Partial<CostDataType> = { ...resourceCost };

    if (
      updatedResourceCost.cost === null &&
      roiResourceCostSelector.cost === undefined
    ) {
      delete updatedResourceCost.cost;
    }
    if (
      !resourceCost?.costStageId &&
      roiResourceCostSelector.costStageId === undefined
    ) {
      updatedResourceCost = pick(updatedResourceCost, [
        'id',
        'description',
        'cost',
      ]);
    }

    return !isEqual(roiResourceCostSelector, updatedResourceCost);
  }, [resourceCost, roiResourceCostSelector]);

  const hasOherCostsChanged = useMemo(
    () => !isEqual(roiOtherCostsSelector, costs),
    [costs, roiOtherCostsSelector]
  );

  const hasChanges = hasResourceCostChanged || hasOherCostsChanged;

  const newAddedCosts = useMemo(
    () => costs.filter((cost) => cost.id?.includes('new-')),
    [costs]
  );

  const existingCosts = useMemo(
    () => costs.filter((cost) => !cost.id?.includes('new-')),
    [costs]
  );

  const canSave = useMemo(() => {
    const allExistingCostsValid = existingCosts.every((cost) => {
      const costValidity = areOtherCostsValid?.find(
        (item) => item.id === cost.id
      );
      const isValid = costValidity?.isValid ?? true;
      return cost.description && isValid;
    });

    const otherCostsChanged = !isEqual(roiOtherCostsSelector, existingCosts);

    const anyNewCostHasDescription = newAddedCosts?.some(
      (cost) => cost?.description
    );

    const validNewCostsWithDescription = areOtherCostsValid?.filter((item) =>
      newAddedCosts.some((cost) => cost.id === item.id && cost.description)
    );

    const allNewCostsValid =
      validNewCostsWithDescription?.every((item) => item.isValid) ?? true;

    return (
      (anyNewCostHasDescription ||
        hasResourceCostChanged ||
        otherCostsChanged) &&
      isResourceCostValueValid &&
      allNewCostsValid &&
      allExistingCostsValid
    );
  }, [
    areOtherCostsValid,
    newAddedCosts,
    existingCosts,
    roiOtherCostsSelector,
    hasResourceCostChanged,
    isResourceCostValueValid,
  ]);

  useEffect(() => {
    if (confirmedNavigation && leavingToLocation) {
      history.push(leavingToLocation);
      setConfirmedNavigation(false);
      setLeavingToLocation('');
    }
  }, [confirmedNavigation, history, leavingToLocation]);

  useEffect(() => {
    async function fetchProgramData() {
      if (programId && !programSelector.id) {
        await dispatch(fetchProgram(programId));
      }
    }

    fetchProgramData();
  }, [dispatch, programId, programSelector.id]);

  useEffect(() => {
    async function fetchCostsData() {
      if (programId && categoryId) {
        await dispatch(fetchROICosts({ programId, categoryId }));
      }
    }

    fetchCostsData();

    return () => {
      dispatch(resetROICosts());
    };
  }, [dispatch, programId, categoryId]);

  const resetData = useCallback(() => {
    setResourceCost(roiResourceCostSelector);
    setCosts(roiOtherCostsSelector);
  }, [roiOtherCostsSelector, roiResourceCostSelector]);

  useEffect(() => {
    if (areAllActionsCompleted) {
      resetData();
    }
  }, [resetData, areAllActionsCompleted]);

  const addCost = () => {
    const newCost = {
      id: `new-${costs.length + 1}`,
      description: '',
      cost: null,
      costStageId: null,
      costStageName: null,
      processName: null,
      projectStageIsDeleted: false,
      projectProcessIsEnabled: true,
    };
    setCosts((prevCosts) => [...prevCosts, newCost]);
    trackEvent(PENDO_EVENTS.PROGRAM_ROI_ADD_NEW_COST, {
      programId: programId,
    });
  };

  const handleCostChange = (
    id: string,
    value: string | number | Partial<CostDataType> | null,
    prop: keyof CostDataType
  ) => {
    setCosts((previousCosts) =>
      previousCosts.map((cost) => {
        if (cost.id === id) {
          const updatedCost = {
            ...cost,
            [prop]: value,
          };

          if (prop === 'costStageId') {
            return {
              ...updatedCost,
              ...(value as Partial<CostDataType>),
              projectStageIsDeleted: false,
              projectProcessIsEnabled: true,
            };
          }

          return updatedCost;
        }
        return cost;
      })
    );
  };

  const saveData = async () => {
    if (!canSave) return;

    setAreAllActionsCompleted(false);
    const newCosts: Partial<CostDataType>[] = [];
    const changedExistingCosts: Partial<CostDataType>[] = [];
    const dispatchedActions = [];

    const extractChangedProps = (
      original: Partial<CostDataType>,
      updated: Partial<CostDataType>
    ) => {
      const changedProps: any = {};
      Object.keys(updated).forEach((key) => {
        if (
          updated[key as keyof CostDataType] !==
          original[key as keyof CostDataType]
        ) {
          changedProps[key as keyof CostDataType] =
            updated[key as keyof CostDataType];
        }
      });
      return !isEmpty(changedProps)
        ? { id: updated.id, ...changedProps }
        : undefined;
    };

    const relevantChangedResourceCost = pick(
      resourceCost,
      ROI_COSTS_PRIMARY_FIELDS
    );

    if (!roiResourceCostSelector.id) {
      relevantChangedResourceCost.description = intl.get(
        'ROI_CATEGORY.COSTS_PAGE.RESOURCE_COSTS'
      );
      relevantChangedResourceCost.costStageId =
        resourceCost.costStageId || null;
      relevantChangedResourceCost.cost = resourceCost.cost || null;
      newCosts.push(relevantChangedResourceCost);
    } else {
      const relevantOriginalResourceCost = pick(
        roiResourceCostSelector,
        ROI_COSTS_PRIMARY_FIELDS
      );
      const changedProps = extractChangedProps(
        relevantOriginalResourceCost,
        relevantChangedResourceCost
      );
      if (changedProps) {
        changedExistingCosts.push(changedProps);
      }
    }

    const newAddedCostsWithDescription = newAddedCosts.filter(
      (cost) => cost.description
    );
    if (newAddedCostsWithDescription.length > 0) {
      const relevantNewCosts = newAddedCostsWithDescription.map((cost) =>
        pick(cost, ROI_COSTS_PRIMARY_FIELDS.slice(1))
      );
      newCosts.push(...relevantNewCosts);
    }

    if (newCosts.length > 0) {
      dispatchedActions.push(
        dispatch(createROICosts({ programId, categoryId, costs: newCosts }))
      );
    }

    const relevantOriginalCosts = existingCosts.map((cost) => ({
      original: pick(
        roiOtherCostsSelector.find((original) => original.id === cost.id)!,
        ROI_COSTS_PRIMARY_FIELDS
      ),
      updated: pick(cost, ROI_COSTS_PRIMARY_FIELDS),
    }));

    relevantOriginalCosts.forEach(({ original, updated }) => {
      const diffProps = extractChangedProps(original, updated);
      if (diffProps) {
        changedExistingCosts.push(diffProps);
      }
    });

    changedExistingCosts.forEach((costData) => {
      const { id, ...cost } = costData;
      dispatchedActions.push(
        dispatch(updateROICost({ programId, categoryId, costId: id!, cost }))
      );
    });

    const removedCostsIds = roiOtherCostsSelector.reduce(
      (acc: string[], cost) => {
        if (!costs.some((updatedCost) => updatedCost.id === cost.id)) {
          acc.push(cost.id!);
        }
        return acc;
      },
      []
    );
    removedCostsIds.forEach((costId) => {
      dispatchedActions.push(
        dispatch(deleteROICost({ programId, categoryId, costId }))
      );
    });

    if (dispatchedActions.length > 0) {
      try {
        await Promise.all(dispatchedActions);
        setAreAllActionsCompleted(true);
        dispatch(
          showSnackbarNotification({
            autoHide: false,
            notificationVariant: 'success',
            notificationTitle: intl.get(
              'ROI_CATEGORY.COSTS_PAGE.SUCCESS_MESSAGE'
            ),
          })
        );
        trackEvent(PENDO_EVENTS.PROGRAM_ROI_SAVE_COST, {
          programId: programId,
          numberOfNewCosts: newCosts.length,
          numberOfUpdatedCosts: changedExistingCosts.length,
          numberOfDeletedCosts: removedCostsIds.length,
        });
      } catch (error) {
        dispatch(
          showSnackbarNotification({
            autoHide: false,
            notificationVariant: 'error',
            notificationTitle: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.UPDATE_ERROR_TITLE'
            ),
            notificationMessage: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.ERROR.MESSAGE'
            ),
          })
        );
      }
    }
  };

  const changeAreOtherCostsValid = (id: string, isValid: boolean) => {
    setAreOtherCostsValid((prevState) => {
      if (!prevState) {
        return [{ id, isValid }];
      }

      const index = prevState.findIndex((item) => item.id === id);

      if (index !== -1) {
        return prevState.map((item, idx) =>
          idx === index ? { ...item, isValid } : item
        );
      }

      return [...prevState, { id, isValid }];
    });
  };

  const handleBlockedNavigation = (location: Location) => {
    if (!confirmedNavigation) {
      setLeavingToLocation(`${location.pathname}${location.search}`);
      setIsLeavingWarningModalOpen(true);
      return false;
    }
    return true;
  };

  const removeCost = () => {
    setCosts((prevCosts) =>
      prevCosts.filter((cost) => cost.id !== costToDelete)
    );
    setCostToDelete('');
    setIsDeleteModalOpen(false);
  };

  const showDeleteModal = (id: string) => {
    setIsDeleteModalOpen(true);
    setCostToDelete(id);
  };

  return (
    <div className='h-full flex flex-col'>
      <Prompt when={hasChanges} message={handleBlockedNavigation} />
      <UnsavedChangesModal
        isOpen={isLeavingWarningModalOpen}
        setIsOpen={setIsLeavingWarningModalOpen}
        onConfirm={() => setConfirmedNavigation(true)}
      />
      <Modal
        isOpen={isDeleteModalOpen}
        closeModal={() => setIsDeleteModalOpen(false)}
        aria-label='delete-cost_modal'
        data-testid='delete-cost_modal'
        title={intl.get('ROI_CATEGORY.COSTS_PAGE.DELETE_MODAL_TITLE')}
        titleIcon={{
          name: 'trash',
          className: 'text-error-dark',
        }}
        size='small'
        childrenClassName='-mt-4'
        actionButtons={[
          {
            children: intl.get('YES_DELETE'),
            variant: 'primary',
            color: 'danger',
            onClick: removeCost,
            'data-testid': 'delete-cost-confirm_button',
          },
          {
            children: intl.get('CANCEL'),
            variant: 'tertiary',
            onClick: () => setIsDeleteModalOpen(false),
            'data-testid': 'delete-cost-cancel_button',
          },
        ]}
      />

      {!isDataReady && <ROICostsSkeletonLoader />}
      {isDataReady && (
        <>
          <PageTitle
            className='w-full'
            titleComponent={intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.CATEGORY_TITLE',
              {
                program: programSelector.title,
                category: STRATEGY_CATEGORIES.ROI,
              }
            )}
          />
          <DetailsPage
            topBar={
              <div className='flex items-center pl-4 h-full'>
                <Typography variant='body2' weight='medium'>
                  {intl.get('ROI_CATEGORY.PROGRAM_COSTS.TITLE')}
                </Typography>
              </div>
            }
            content={
              <div className='w-2/3 pt-2 pl-2'>
                <Typography variant='h5' className='mb-1'>
                  {intl.get('ROI_CATEGORY.COSTS_PAGE.HEADING_TITLE')}
                </Typography>
                <Typography variant='body2' className=''>
                  {intl.get('ROI_CATEGORY.COSTS_PAGE.HEADING_SUBTITLE')}
                </Typography>
                <TotalProgramCost totalCost={totalProgramCost} />
                <div className='flex mt-8 w-full mb-5 px-1'>
                  <FormItem
                    label={intl.get('ROI_CATEGORY.COSTS_PAGE.COST_DESCRIPTION')}
                    className='w-cost-description mr-5'
                  >
                    <Input
                      value={intl.get('ROI_CATEGORY.COSTS_PAGE.RESOURCE_COSTS')}
                      data-testid='cost-description_input'
                      readOnly
                      id='cost-description_input'
                      tailingIcon={
                        <Tooltip
                          openMode='hover1'
                          timeout={0}
                          trigger={
                            <IconButton
                              data-testid='cost-description_tooltip'
                              name='information-circle'
                              description={intl.get(
                                'ROI_CATEGORY.COSTS_PAGE.RESOURCE_COSTS_INFORMATION_ICON'
                              )}
                              className='text-primary-dark'
                            />
                          }
                          showPopper={true}
                          contentProps={{ className: 'text-sm w-60' }}
                        >
                          {intl.get(
                            'ROI_CATEGORY.COSTS_PAGE.RESOURCE_COSTS_TOOLTIP_LINE_1'
                          )}
                          <span className='font-semibold'>
                            {intl.get(
                              'ROI_CATEGORY.COSTS_PAGE.RESOURCE_COSTS_TOOLTIP_LINE_2'
                            )}
                          </span>
                        </Tooltip>
                      }
                    />
                  </FormItem>
                  <FormItem
                    label={intl.get('ROI_CATEGORY.COSTS_PAGE.COST')}
                    className='w-cost mr-5'
                  >
                    <div className='flex'>
                      <Typography className='mr-1 mt-2'>
                        {intl.get('DOLLAR_SIGN')}
                      </Typography>
                      <NumericInput
                        getIsInputValid={(isValid) =>
                          setIsResourceCostValid(isValid)
                        }
                        containerClassName='w-full'
                        placeholder={intl.get(
                          'ROI_CATEGORY.COSTS_PAGE.COST_PLACEHOLDER'
                        )}
                        allowNegativeValue={false}
                        maxAllowedDecimalPlaces={2}
                        max={99999999.99}
                        data-testid='resource-cost-numeric_input'
                        id='resource-cost-numeric_input'
                        value={resourceCost?.cost ?? ''}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          setResourceCost((prevCost) => ({
                            ...prevCost!,
                            cost:
                              event.target.value !== ''
                                ? Number(event.target.value)
                                : null,
                          }))
                        }
                      />
                    </div>
                  </FormItem>
                  <FormItem
                    label={intl.get('ROI_CATEGORY.COSTS_PAGE.PROCESS_STAGE')}
                    className='w-stage mr-4'
                  >
                    <ProcessStageDropdown
                      costData={resourceCost}
                      onChange={(stage) => {
                        setResourceCost((prevCost) => ({
                          ...prevCost!,
                          costStageId: stage.costStageId!,
                          costStageName: stage.costStageName!,
                          processName: stage.processName!,
                          projectStageIsDeleted: false,
                          projectProcessIsEnabled: true,
                        }));
                      }}
                    />
                  </FormItem>
                  <div className='w-8' />
                </div>
                {costs?.length > 0 &&
                  costs.map((cost) => (
                    <CostComponent
                      key={cost?.id}
                      costData={cost!}
                      handleCostChange={handleCostChange}
                      changeAreOtherCostsValid={changeAreOtherCostsValid}
                      showDeleteModal={showDeleteModal}
                    />
                  ))}
                <Button
                  iconName='add-circle'
                  variant='tertiary'
                  className='mt-5'
                  onClick={addCost}
                  data-testid='add-cost_button'
                >
                  {intl.get('ROI_CATEGORY.COSTS_PAGE.ADD_COST_BUTTON')}
                </Button>
              </div>
            }
          />
          <PageFooter>
            <Button
              variant='tertiary'
              onClick={resetData}
              disabled={!hasChanges}
              data-testid='discard-changes_button'
            >
              {intl.get('DISCARD_CHANGES')}
            </Button>
            <Button
              onClick={saveData}
              disabled={!canSave}
              data-testid='save-cost_button'
            >
              {intl.get('SAVE')}
            </Button>
          </PageFooter>
        </>
      )}
    </div>
  );
};

export default ROICostsPage;
