import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import { PROJECT_CONTENT_TYPE } from 'utils/constants';
import { ProjectContentType } from 'utils/customTypes';
import { getContentTypeLabel } from '../utils';
import useModal from 'Hooks/useModal';

const ConfirmationModal: React.FC<{
  contentType: ProjectContentType;
  shouldDisplay: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}> = ({ contentType, shouldDisplay, onConfirm, onCancel }) => {
  const { Modal, modalProps, openModal, closeModal } = useModal();
  let actionLabel = intl.get('DELETE');
  const contentTypeCasting = contentType as ProjectContentType;
  if (
    contentTypeCasting === PROJECT_CONTENT_TYPE.WEB_LINK ||
    contentTypeCasting === PROJECT_CONTENT_TYPE.DESIGN
  ) {
    actionLabel = intl.get('REMOVE');
  }
  let contentTypeLabel = getContentTypeLabel(contentType);
  if (contentTypeCasting === PROJECT_CONTENT_TYPE.DESIGN_FILE) {
    contentTypeLabel = intl.get(
      'PROJECT_DETAIL.FILES_TAB.TABLE.CONTENT_TYPE.FILE'
    );
  }
  const modalTitle = intl.get(
    'PROJECT_DETAIL.FILES_TAB.TABLE.ACTIONS_MENU.DELETE',
    {
      action: actionLabel,
    }
  );

  const handleCancel = () => {
    closeModal();
    onCancel();
  };

  const handleConfirm = () => {
    closeModal();
    onConfirm();
  };

  useEffect(() => {
    if (shouldDisplay && !modalProps.isOpen) {
      openModal(modalProps);
    }
  }, [shouldDisplay, modalProps, openModal]);

  return (
    <Modal
      {...modalProps}
      data-cy='remove-content__confirmation-modal'
      title={modalTitle}
      aria-label={modalTitle}
      size='medium'
      closeModal={handleCancel}
      actionButtons={[
        {
          children: intl.get(
            'PROJECT_DETAIL.FILES_TAB.CONFIRMATION_MODAL.CONFIRM',
            { action: actionLabel, content: contentTypeLabel }
          ),
          variant: 'primary',
          color: 'danger',
          onClick: handleConfirm,
          'data-cy': 'remove-content__confirm-button',
        },
        {
          children: intl.get('PROJECT_DETAIL.DELETE_PROJECT.CANCEL'),
          variant: 'tertiary',
          onClick: handleCancel,
        },
      ]}
    >
      {(contentTypeCasting === PROJECT_CONTENT_TYPE.FILE ||
        contentTypeCasting === PROJECT_CONTENT_TYPE.DESIGN_FILE) && (
        <Typography variant='body' className='text-primary mb-8'>
          {intl.get('PROJECT_DETAIL.FILES_TAB.CONFIRMATION_MODAL.HEADER')}
        </Typography>
      )}
      <Typography variant='body' className='text-primary'>
        {intl.get('PROJECT_DETAIL.FILES_TAB.CONFIRMATION_MODAL.BODY', {
          action: actionLabel.toLowerCase(),
          content: contentTypeLabel.toLowerCase(),
        })}
      </Typography>
    </Modal>
  );
};

export default ConfirmationModal;
