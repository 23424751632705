import { useEffect, useState, useMemo, useRef } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Prompt } from 'react-router-dom';
import { get, isEmpty, isEqual } from 'lodash';
import {
  Button,
  useElevation,
  Toggle,
  Typography,
} from '@getsynapse/design-system';
import {
  requiredFields,
  requiredFieldsErrorsMap,
  taskFields,
} from './helpers/constants';
import { isTaskDueDatePast, getActualHoursData } from './helpers/helpers';
import { getAllUsers } from 'state/UsersManagement/usersManagementSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import {
  objKeyAsString,
  TaskDetailType,
  ProjectContent,
  TaskActualHours,
} from 'utils/customTypes';
import {
  TASK_STATUS,
  PROJECT_PARTICIPANT_TYPE,
  PROJECT_PERMISSIONS_BY_LEVEL,
  PROJECT_USER_ACTIONS,
  PROJECT_STATUS,
  TASK_FIELDS,
} from 'utils/constants';
import {
  getSingleTaskData,
  updateTask,
  updateTaskEnablement,
  isCurrentUserAssignedToTask,
  setTaskDetail,
  updateLinkedContent,
  getLinkedTaskContentFilesIds,
  fetchTaskAndActualHours,
  updateTaskActualHours,
  fetchTaskComments,
  resetTaskComments,
} from 'state/SingleTask/singleTaskSlice';
import {
  fetchProject,
  getCurrentProjectData,
  fetchProjectFiles,
  getProjectContentFiles,
} from 'state/Project/projectSlice';
import { deleteTask } from 'state/ProjectTasks/projectTaskSlice';
import { selectUserId } from 'state/User/userSlice';
import { getDifference } from 'Pages/ProjectPage/helpers/updatedProjectData';
import { OnHoldStatusBanner } from 'Pages/ProjectPage/components/Banners/Banners';
import useHasUserAccess from 'Pages/ProjectPage/hooks/useHasUserAccess';
import TaskActions from './components/TaskActions/TaskActions';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import pastDueDateIcon from 'assets/icons/past-due.svg';
import TaskDetails from './components/TaskDetails';
import ContentSection from './components/ContentSection/ContentSection';
import LinkedContentFiles from './components/ContentSection/LinkedContentFiles';
import TaskCommentsPanel from './components/Comments/TaskCommentsPanel';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import { selectSidePanelUpdatedData } from 'state/SidePanel/sidePanelSlice';
import Loader from 'Molecules/Loader/Loader';
import { isUserOrganizationAdmin } from 'state/User/userSlice';
import UnsavedChangesModal from 'Organisms/UnsavedChangesModal/UnsavedChangesModal';
import useNavigation from 'Hooks/useNavigation';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const Banner: React.FC<{
  originalTaskData: TaskDetailType;
  currentTaskData: TaskDetailType;
  sidePanel?: boolean;
}> = ({ originalTaskData, currentTaskData, sidePanel = false }) => {
  const isTaskPastDueDate = useMemo(() => {
    return originalTaskData
      ? isTaskDueDatePast(originalTaskData.status, originalTaskData.due_date)
      : false;
  }, [originalTaskData]);

  if (currentTaskData.disabled) {
    return (
      <OnHoldStatusBanner
        sidePanel={sidePanel}
        message={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_DISABLED_WARNING')}
      />
    );
  } else if (currentTaskData.status === TASK_STATUS.ON_HOLD) {
    return (
      <OnHoldStatusBanner
        sidePanel={sidePanel}
        message={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_ON_HOLD_WARNING')}
      />
    );
  } else if (isTaskPastDueDate) {
    return (
      <OnHoldStatusBanner
        sidePanel={sidePanel}
        iconProps={{
          src: pastDueDateIcon,
        }}
        message={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_PAST_DUE_WARNING')}
      />
    );
  } else {
    return null;
  }
};

const TaskPage = () => {
  const { projectId, taskId } = useParams<{
    projectId: string;
    taskId: string;
  }>();
  const firstRender = useRef<boolean>(true);
  const { permissionsLevel } = useHasUserAccess();
  const availableUserActions = useMemo(
    () => PROJECT_PERMISSIONS_BY_LEVEL[permissionsLevel],
    [permissionsLevel]
  );
  const isUserAssignedToTask = useSelector(isCurrentUserAssignedToTask);
  const currentUserId = useSelector(selectUserId);
  const linkedTaskContentFilesIds = useSelector(getLinkedTaskContentFilesIds);
  const projectContentFiles = useSelector(getProjectContentFiles);
  const isUserAdmin = useSelector(isUserOrganizationAdmin);
  const [linkedContentFiles, setLinkedContentFiles] =
    useState<ProjectContent[]>();
  const footerElevation = useElevation(1);
  const dispatch = useDispatch();
  const fetchedTaskData = useSelector(getSingleTaskData);
  const isCurrentUserTaskCreator = useMemo<boolean>(
    () =>
      typeof currentUserId !== 'undefined' &&
      fetchedTaskData.created_by !== null &&
      currentUserId === fetchedTaskData.created_by,
    [fetchedTaskData, currentUserId]
  );
  const [taskData, setTaskData] = useState<TaskDetailType>(fetchedTaskData);
  const [isUpdating, setIsUpdating] = useState(false);
  const projectData = useSelector(getCurrentProjectData);
  const [requiredFieldsErrors, setRequiredFieldsErrors] =
    useState<objKeyAsString>(requiredFieldsErrorsMap);
  const [disableSave, setDisableSave] = useState(true);
  const [dataToCompare, setDataToCompare] = useState<TaskDetailType>();
  const [confirmedNavigation, setConfirmedNavigation] =
    useState<boolean>(false);
  const [wasSaved, setWasSaved] = useState<boolean>(false);
  const [isLeavingWarningModalOpen, setIsLeavingWarningModalOpen] =
    useState<boolean>(false);
  const changesDetected = useMemo(
    () => !isEqual(dataToCompare, taskData),
    [dataToCompare, taskData]
  );
  const sidePanelTaskData = useSelector(selectSidePanelUpdatedData);
  const fromSidePanel = new URLSearchParams(window.location.search).get(
    'fromSidePanel'
  )
    ? true
    : false;
  const { goToBackPage } = useNavigation();

  const { taskComments: taskCommentsFlag = false } = useFlags();

  useEffect(() => {
    dispatch(fetchProject(projectId));
    dispatch(fetchProjectFiles(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    dispatch(fetchTaskAndActualHours(taskId));
    dispatch(getAllUsers());
    dispatch(fetchTaskComments(taskId));
    return () => {
      dispatch(setTaskDetail(taskFields));
      dispatch(resetTaskComments);
    };
  }, [dispatch, taskId]);

  useEffect(() => {
    const { assignedUsers, ...remainingTaskData } = fetchedTaskData;
    const ownersIds =
      assignedUsers?.map((owner: any) => owner.id || owner) || [];

    if (firstRender.current) {
      if (fromSidePanel && sidePanelTaskData) {
        setTaskData(sidePanelTaskData as TaskDetailType);
      } else {
        setTaskData({ ...remainingTaskData, assignedUsers: ownersIds });
      }
      setDataToCompare({ ...remainingTaskData, assignedUsers: ownersIds });
    }
  }, [fetchedTaskData, sidePanelTaskData, fromSidePanel]);

  useEffect(() => {
    if (
      !fromSidePanel &&
      firstRender.current &&
      taskData.id &&
      taskData.taskActualHours
    ) {
      firstRender.current = false;
    }
  }, [fromSidePanel, taskData]);

  useEffect(() => {
    if (
      firstRender.current &&
      fromSidePanel &&
      dataToCompare &&
      dataToCompare.id
    ) {
      firstRender.current = false;
    }
  }, [dataToCompare, fromSidePanel]);

  useEffect(() => {
    if (!isEqual(taskData, taskFields)) {
      if (changesDetected && disableSave) {
        setDisableSave(false);
      }
      if (!changesDetected && !disableSave) {
        setDisableSave(true);
      }
    }
  }, [taskData, disableSave, dataToCompare, changesDetected]);

  useEffect(() => {
    const linkedFiles = projectContentFiles.filter(
      (projectContentFile: ProjectContent) =>
        linkedTaskContentFilesIds?.includes(projectContentFile.id)
    );
    setLinkedContentFiles(linkedFiles);
  }, [projectContentFiles, linkedTaskContentFilesIds]);

  useEffect(() => {
    if (confirmedNavigation) {
      setConfirmedNavigation(false);
      goToBackPage();
    }
  }, [confirmedNavigation, goToBackPage]);

  useEffect(() => {
    const handleLeavingSiteWithUnsavedChanges = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    if (changesDetected) {
      window.addEventListener(
        'beforeunload',
        handleLeavingSiteWithUnsavedChanges
      );
      return () => {
        window.removeEventListener(
          'beforeunload',
          handleLeavingSiteWithUnsavedChanges
        );
      };
    }
    return () => {};
  }, [changesDetected, wasSaved]);

  const saveTaskData = (
    item: string,
    value: string | string[] | TaskActualHours[]
  ) => {
    const dataToUpdate: objKeyAsString = {
      [item]: value,
    };
    if (
      item === TASK_FIELDS.STATUS &&
      value !== TASK_STATUS.COMPLETED &&
      taskData.status === TASK_STATUS.COMPLETED &&
      taskData.completion_date
    ) {
      dataToUpdate[TASK_FIELDS.COMPLETION_DATE] = null;
    }
    if (
      item === TASK_FIELDS.STATUS &&
      value === TASK_STATUS.COMPLETED &&
      taskData.status !== TASK_STATUS.COMPLETED &&
      !taskData.completion_date
    ) {
      dataToUpdate[TASK_FIELDS.COMPLETION_DATE] = new Date();
    }
    setTaskData((prevData: TaskDetailType) => ({
      ...prevData,
      ...dataToUpdate,
    }));
  };

  const handleCancel = () => {
    goToBackPage();
  };

  const handleDisableTask = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTaskData((prev) => ({ ...prev, disabled: !event.target.checked }));
    setDataToCompare(
      (prev) => ({ ...prev, disabled: !event.target.checked } as TaskDetailType)
    );
    dispatch(
      updateTaskEnablement({
        taskId,
        disabled: !event.target.checked,
      })
    );
  };

  const handleUpdateTask = async () => {
    setIsUpdating(true);
    const updatedTaskData: Partial<TaskDetailType> = getDifference(
      taskData,
      dataToCompare!
    );
    setDataToCompare((prev) => ({
      ...prev,
      ...taskData,
    }));
    const taskActualHours = get(updatedTaskData, 'taskActualHours');
    if (taskActualHours) {
      delete updatedTaskData.taskActualHours;
    }
    await dispatch(
      updateTask({
        taskId,
        data: { ...updatedTaskData },
      })
    );
    if (taskActualHours) {
      const { newActualHours, removedActualHours } = getActualHoursData(
        fetchedTaskData.taskActualHours!,
        taskData?.taskActualHours!
      );
      await dispatch(
        updateTaskActualHours({ taskId, newActualHours, removedActualHours })
      );
    }
    setIsUpdating(false);
  };

  const handleSave = async () => {
    setWasSaved(true);
    let canSave = true;
    let errorsMap: objKeyAsString = { ...requiredFieldsErrorsMap };
    const fieldsToValidate = [...requiredFields];
    const dateFields: string[] = [];
    const shouldValidateActualCompletionField =
      taskData.status === TASK_STATUS.COMPLETED;
    const hasAssignedUsers = taskData.assignedUsers?.length > 0;
    if (shouldValidateActualCompletionField) {
      fieldsToValidate.push(TASK_FIELDS.COMPLETION_DATE);
      dateFields.push(TASK_FIELDS.COMPLETION_DATE);
      errorsMap[TASK_FIELDS.COMPLETION_DATE] = false;
    }
    if (hasAssignedUsers) {
      fieldsToValidate.push(TASK_FIELDS.START_DATE);
      fieldsToValidate.push(TASK_FIELDS.DUE_DATE);
      dateFields.push(TASK_FIELDS.START_DATE);
      dateFields.push(TASK_FIELDS.DUE_DATE);
      errorsMap[TASK_FIELDS.START_DATE] = false;
      errorsMap[TASK_FIELDS.DUE_DATE] = false;
    }
    fieldsToValidate.forEach((field) => {
      if (!dateFields.includes(field) && isEmpty(taskData[field])) {
        errorsMap[field] = true;
      } else {
        if (!(taskData[field] instanceof Date) && isEmpty(taskData[field])) {
          errorsMap[field] = true;
        }
      }
    });
    canSave = !Object.values(errorsMap).some((val: boolean) => val);
    setRequiredFieldsErrors(errorsMap);
    setDisableSave(true);
    if (!canSave) {
      dispatch(
        showNotificationBanner({
          notificationVariant: 'error',
          notificationText: intl.get(
            'TASKS.TASK_DETAIL_PAGE.TASK_UPDATE_ERROR'
          ),
        })
      );
    } else {
      await handleUpdateTask();
      dispatch(
        showNotificationBanner({
          notificationVariant: 'success',
          notificationText: intl
            .get('TASKS.TASK_DETAIL_PAGE.UPDATE_SUCCESSFUL')
            .replace('Task', taskData?.name),
        })
      );
      goToBackPage();
    }
  };

  const canUpdateTask = useMemo<boolean>(
    () =>
      (permissionsLevel &&
        (permissionsLevel === PROJECT_PARTICIPANT_TYPE.OWNER ||
          permissionsLevel === PROJECT_PARTICIPANT_TYPE.MEMBER)) ||
      (availableUserActions.includes(PROJECT_USER_ACTIONS.UPDATE_TASK) &&
        (isUserAssignedToTask || isCurrentUserTaskCreator)),
    [
      permissionsLevel,
      availableUserActions,
      isUserAssignedToTask,
      isCurrentUserTaskCreator,
    ]
  );

  const canRemoveTask = useMemo<boolean>(
    () =>
      (permissionsLevel &&
        (permissionsLevel === PROJECT_PARTICIPANT_TYPE.OWNER ||
          permissionsLevel === PROJECT_PARTICIPANT_TYPE.MEMBER)) ||
      (availableUserActions.includes(PROJECT_USER_ACTIONS.DELETE_TASK) &&
        isUserAssignedToTask),
    [permissionsLevel, availableUserActions, isUserAssignedToTask]
  );

  const canDeleteActualHours = useMemo<boolean>(
    () =>
      (permissionsLevel === PROJECT_PARTICIPANT_TYPE.OWNER ||
        permissionsLevel === PROJECT_PARTICIPANT_TYPE.MEMBER ||
        isUserAdmin) &&
      !(
        fetchedTaskData.status === TASK_STATUS.COMPLETED &&
        taskData.status === TASK_STATUS.COMPLETED
      ) &&
      taskData.status !== TASK_STATUS.ON_HOLD &&
      !taskData.disabled,
    [permissionsLevel, isUserAdmin, fetchedTaskData, taskData]
  );

  const isViewOnlyMode =
    taskData.status === TASK_STATUS.ON_HOLD ||
    taskData.disabled ||
    !canUpdateTask;

  const handleUnlinkFile = async (fileId: string) => {
    const updatedLinkedContent =
      linkedContentFiles?.filter(
        (linkedContentFile: ProjectContent) => linkedContentFile.id !== fileId
      ) || [];
    await dispatch(
      updateLinkedContent({ taskId, linkedContentFiles: updatedLinkedContent })
    );
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get(
          'TASKS.TASK_DETAIL_PAGE.CONTENT.CONTENT_UNLINK_MESSAGE'
        ),
      })
    );
  };

  const handleDeleteTask = async () => {
    await dispatch(deleteTask(taskId));
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl
          .get('TASKS.NOTIFICATIONS.TASK_DELETION_SUCCESS')
          .replace('Task', taskData?.name),
      })
    );
    goToBackPage();
  };

  const isReadOnly = [PROJECT_STATUS.CLOSED, PROJECT_STATUS.CANCELED].includes(
    projectData.status
  );

  const handleBlockedNavigation = () => {
    if (!confirmedNavigation) {
      setIsLeavingWarningModalOpen(true);
      return false;
    }
    return true;
  };
  return (
    <div className='h-full flex flex-col'>
      <Prompt
        when={changesDetected && !wasSaved}
        message={handleBlockedNavigation}
      />
      <UnsavedChangesModal
        isOpen={isLeavingWarningModalOpen}
        setIsOpen={setIsLeavingWarningModalOpen}
        onConfirm={() => setConfirmedNavigation(true)}
      />
      <PageTitle
        titleComponent={`${get(projectData, 'title', '')} / ${taskData?.name}`}
        dataCy='task-title'
      />
      {taskData.id && taskData.taskActualHours ? (
        <DetailsPage
          topBar={
            <div className='z-5'>
              <div className='w-full min-h-8 flex justify-end items-center'>
                {canUpdateTask && taskData.status !== TASK_STATUS.COMPLETED && (
                  <Toggle
                    offText={intl.get('TASKS.TASK_DETAIL_PAGE.ENABLE_TASK')}
                    onChange={handleDisableTask}
                    onText={intl.get('TASKS.TASK_DETAIL_PAGE.ENABLE_TASK')}
                    className='mr-1 my-auto'
                    checked={!taskData.disabled}
                    toggleTextPosition='left'
                    inputProps={{
                      'data-testid': 'task-toggle-enablement',
                    }}
                    disabled={!canUpdateTask || isReadOnly}
                  />
                )}

                {canRemoveTask && (
                  <TaskActions deleteTaskCallback={handleDeleteTask} />
                )}
              </div>
            </div>
          }
          content={
            <div
              className='bg-neutral-white flex-grow overflow-y-auto flex flex-row '
              data-cy='task-form-body'
            >
              <div className='w-full'>
                <Banner
                  originalTaskData={fetchedTaskData}
                  currentTaskData={taskData}
                />
                <TaskDetails
                  requiredFieldsErrors={requiredFieldsErrors}
                  setData={saveTaskData}
                  data={taskData}
                  isViewOnly={isViewOnlyMode}
                  canUpdateTask={canUpdateTask}
                  canDeleteActualHours={canDeleteActualHours}
                />

                <ContentSection
                  canUpdateContent={
                    canUpdateTask && !isViewOnlyMode && !projectData.is_archived
                  }
                >
                  {linkedContentFiles && linkedContentFiles?.length > 0 ? (
                    <LinkedContentFiles
                      files={linkedContentFiles || []}
                      canUpdateContent={canUpdateTask && !isViewOnlyMode}
                      onUnlinkFile={handleUnlinkFile}
                    />
                  ) : (
                    <div className='mt-4'>
                      <Typography
                        variant='label'
                        className='text-neutral-black'
                      >
                        {intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.NO_CONTENT')}
                      </Typography>
                    </div>
                  )}
                </ContentSection>
              </div>
            </div>
          }
          comments={taskCommentsFlag && <TaskCommentsPanel />}
        />
      ) : (
        <Loader />
      )}
      <div
        className={`w-full bg-neutral-white py-2 flex justify-end z-5 ${footerElevation}`}
      >
        <div className='flex space-x-4 mr-12'>
          <Button
            variant='secondary'
            onClick={handleCancel}
            data-cy='task-cancel-button'
          >
            {intl.get('TASKS.TASK_DETAIL_PAGE.CANCEL_UPDATES_BUTTON')}
          </Button>

          <Button
            disabled={isUpdating || disableSave || taskData.disabled}
            onClick={handleSave}
            data-cy='task-save-button'
          >
            {intl.get('TASKS.TASK_DETAIL_PAGE.SAVE_UPDATES_BUTTON')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TaskPage;
