import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import StrategyCategoryTemplate from 'Templates/StrategyCategoryTemplate/StrategyCategoryTemplate';
import StrategyExamplesCard from 'Organisms/StrategyExamplesPanel/StrategyExamplesCard';
import { STRATEGY_CATEGORIES } from 'utils/constants/program';
import ROICategorySkeleton from './components/ROICategorySkeleton';
import ObjectiveSetting from './components/ObjectiveSetting/ObjectiveSetting';
import {
  fetchROICategory,
  selectROICategoryStatus,
  selectROICategory,
  resetROICategory,
} from 'state/ROICategory/ROICategoySlice';
import classNames from 'classnames';
import intl from 'react-intl-universal';
import { Icon, Typography } from '@getsynapse/design-system';
import { trackEvent } from 'Services/pendo';
import TotalProgramCost from './components/TotalProgramCost/TotalProgramCost';
import ROICalculator from './components/ObjectiveSetting/ROICalculator/ROICalculator';
import {
  SLICE_STATUS,
  PATHS,
  PENDO_EVENTS,
  ROI_METHODOLOGIES,
} from 'utils/constants';

const ROICategoryPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { categoryId, programId } = useParams<{
    categoryId: string;
    programId: string;
  }>();

  const roiCategorySelector = useSelector(selectROICategory);
  const roiStatusSelector = useSelector(selectROICategoryStatus);

  useEffect(() => {
    if (categoryId && programId) {
      dispatch(fetchROICategory({ categoryId, programId }));
    }
    return () => {
      dispatch(resetROICategory());
    };
  }, [dispatch, programId, categoryId]);

  return (
    <StrategyCategoryTemplate
      categoryId={categoryId}
      loadingComponent={<ROICategorySkeleton />}
      categoryName={STRATEGY_CATEGORIES.ROI}
      isLoading={
        roiStatusSelector === SLICE_STATUS.LOADING || !roiCategorySelector.name
      }
    >
      <div className='px-8 flex gap-8'>
        <div className='w-2/3'>
          <ObjectiveSetting roiData={roiCategorySelector} />
          <div className='mt-12'>
            <Typography variant='h5' className='pb-1'>
              {intl.get('ROI_CATEGORY.PROGRAM_COSTS.TITLE')}
            </Typography>
            <div className='flex gap-8 items-start'>
              <Typography className='text-neutral-darker' variant='body2'>
                {intl.get('ROI_CATEGORY.PROGRAM_COSTS.SUBTITLE')}
              </Typography>
              <button
                className={classNames(
                  'font-semibold text-purple-dark hover:text-purple-darker focus-visible:text-purple-darker',
                  'active:text-purple-darker focus:outline-none text-sm leading-6 focus:ring-0'
                )}
                onClick={() => {
                  trackEvent(PENDO_EVENTS.PROGRAM_ROI_MANAGE_COSTS_CLICK, {
                    programId: programId,
                  });
                  history.push(`${location.pathname}${PATHS.ROI_COSTS_PAGE}`);
                }}
                data-testid='manage-costs-button'
              >
                <div className='flex items-center w-30'>
                  {intl.get('ROI_CATEGORY.PROGRAM_COSTS.MANAGE_COSTS')}
                  <Icon name='arrow-forward' className='ml-2 text-xl' />
                </div>
              </button>
            </div>
            <TotalProgramCost
              totalCost={roiCategorySelector.totalProgramCost}
            />
          </div>
          <ROICalculator />
        </div>
        <StrategyExamplesCard
          className='w-90 h-filtered-stage min-h-142 flex-none ml-auto'
          programId={programId}
          links={ROI_METHODOLOGIES.ROI}
        />
      </div>
    </StrategyCategoryTemplate>
  );
};

export default ROICategoryPage;
