import { Dispatch, SetStateAction } from 'react';
import classnames from 'classnames';
import intl from 'react-intl-universal';
import { IconButton, tailwindOverride } from '@getsynapse/design-system';
import logo from 'assets/images/cognota-logo.svg';
import menu from 'assets/icons/menu.svg';

const SideHeader = ({
  isExpanded,
  setIsExpanded,
}: {
  isExpanded: boolean;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <div
      className={classnames('flex', 'mb-9', 'items-center', {
        'justify-between': isExpanded,
        'justify-center': !isExpanded,
      })}
    >
      <div
        className={tailwindOverride(
          'h-6 overflow-hidden',
          'transition-width duration-500 ease-in-out',
          {
            'w-20.5': isExpanded,
            'w-3 cursor-pointer': !isExpanded,
          }
        )}
        style={{ backgroundImage: `url("${logo}")` }}
        data-testid='logo-section'
        onClick={!isExpanded ? () => setIsExpanded((prev) => !prev) : undefined}
      />
      <IconButton
        description={intl.get('COLLAPSE_EXPAND_ICON_DESC')}
        src={menu}
        className={tailwindOverride(
          'transition-display duration-300 ease-in-out',
          {
            'inline-flex': isExpanded,
            hidden: !isExpanded,
          }
        )}
        iconClassname={tailwindOverride(
          'items-center	justify-center bg-primary-darker bg-opacity-50 rounded-sm h-4 w-4 text-lg'
        )}
        hasASize={false}
        onClick={() => setIsExpanded((prev) => !prev)}
        data-cy='expand-collapse_sidebar'
      />
    </div>
  );
};

export default SideHeader;
