import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';
import { Week, getWeek } from 'Organisms/CapacityAllocationTable/helpers';
import { CAPACITY_TABLE_NUMBER_OF_WEEKS } from 'utils/constants';

const useWeeksCarousel = (
  startDate: string | null,
  endDate: string | null,
  index: number,
  updatePaginationIndex: (index: number) => void,
  fetchCapacity: (startDate: string, endDate: string) => void
) => {
  const [currentIndex, setCurrentIndex] = useState<number>(12);
  const prevIndex = useRef<number>(12);
  const [weeks, setWeeks] = useState<Week[]>([]);

  const generateWeeksArray = (from: string, to: string) => {
    const weeksArray = [];
    const start = moment(new Date(from.replace(/-/g, '/')));
    const end = moment(new Date(to.replace(/-/g, '/')));
    let pivotWeek = start;
    while (moment(pivotWeek).isSameOrBefore(end)) {
      const week = getWeek(pivotWeek);
      weeksArray.push(week);
      pivotWeek = pivotWeek.add(7, 'days');
    }
    return weeksArray;
  };

  const addWeeksAtEnd = useCallback((startDate: string, endDate: string) => {
    const formattedStartDate = moment(new Date(startDate.replace(/-/g, '/')))
      .startOf('isoWeek')
      .format('YYYY-MM-DD');
    const formattedEndDate = moment(new Date(endDate.replace(/-/g, '/')))
      .subtract(1, 'weeks')
      .startOf('isoWeek')
      .format('YYYY-MM-DD');
    const columns = generateWeeksArray(formattedStartDate, formattedEndDate);
    setWeeks((prev) => [...prev, ...columns]);
  }, []);

  const addWeeksAtBeginning = useCallback(
    (startDate: string, endDate: string) => {
      const formattedStartDate = moment(new Date(startDate.replace(/-/g, '/')))
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      const formattedEndDate = moment(new Date(endDate.replace(/-/g, '/')))
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      const columns = generateWeeksArray(formattedStartDate, formattedEndDate);
      const nextIndex = currentIndex + columns.length;
      setWeeks((prev) => [...columns, ...prev]);
      setCurrentIndex(nextIndex);
      prevIndex.current = nextIndex;
    },
    [currentIndex, prevIndex]
  );

  const fetchCapacityIfRequired = useCallback(() => {
    if (!endDate || !startDate) {
      return;
    }
    let tableStartDate = startDate;
    let tableEndDate = endDate;
    let whereToAdd = '';
    if (currentIndex + CAPACITY_TABLE_NUMBER_OF_WEEKS >= weeks.length) {
      tableStartDate = moment(new Date(endDate.replace(/-/g, '/')))
        .add(1, 'weeks')
        .format('YYYY-MM-DD');
      tableEndDate = moment(new Date(endDate.replace(/-/g, '/')))
        .add(13, 'weeks')
        .format('YYYY-MM-DD');
      whereToAdd = 'end';
    }
    if (currentIndex - CAPACITY_TABLE_NUMBER_OF_WEEKS <= 0) {
      tableStartDate = moment(new Date(startDate.replace(/-/g, '/')))
        .subtract(12, 'weeks')
        .format('YYYY-MM-DD');
      tableEndDate = moment(new Date(startDate.replace(/-/g, '/')))
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
      whereToAdd = 'start';
    }
    if (tableStartDate !== startDate && tableEndDate !== endDate) {
      fetchCapacity(tableStartDate, tableEndDate);
      if (whereToAdd === 'end') {
        addWeeksAtEnd(tableStartDate, tableEndDate);
      } else {
        addWeeksAtBeginning(tableStartDate, tableEndDate);
      }
    }
  }, [
    currentIndex,
    startDate,
    endDate,
    weeks,
    addWeeksAtBeginning,
    addWeeksAtEnd,
    fetchCapacity,
  ]);

  const handleNext = useCallback(() => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
    updatePaginationIndex(currentIndex + 1);
  }, [currentIndex, updatePaginationIndex]);

  const handlePrevious = useCallback(() => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
    updatePaginationIndex(currentIndex - 1);
  }, [currentIndex, updatePaginationIndex]);

  useEffect(() => {
    if (startDate !== null && endDate !== null && weeks.length === 0) {
      const columns = generateWeeksArray(startDate, endDate);
      setWeeks(columns);
    }
  }, [weeks, startDate, endDate]);

  useEffect(() => {
    if (currentIndex !== prevIndex.current) {
      prevIndex.current = currentIndex;
      fetchCapacityIfRequired();
    }
  }, [currentIndex, fetchCapacityIfRequired, prevIndex]);

  useEffect(() => {
    if (index !== currentIndex && startDate !== null && endDate !== null) {
      prevIndex.current = index;
      const columns = generateWeeksArray(startDate, endDate);
      setWeeks(columns);
      setCurrentIndex(index);
    }
  }, [index, currentIndex, startDate, endDate]);

  return useMemo(
    () => ({
      currentIndex,
      weeks,
      handleNext,
      handlePrevious,
    }),
    [currentIndex, weeks, handleNext, handlePrevious]
  );
};

export default useWeeksCarousel;
