import React from 'react';
import intl from 'react-intl-universal';
import debounce from 'lodash/debounce';
import {
  Typography,
  NumericInput,
  tailwindOverride,
} from '@getsynapse/design-system';
import { PartialHoursItem as PartialHoursItemType } from 'types/store/manageTimeOff';

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
});

interface PartialHoursItemProps extends PartialHoursItemType {
  updatePartialHours: (day: string, hours: number) => void;
  getIsNumericInputValid: (isNumericInputValid: boolean) => void;
}

const PartialHoursItem: React.FC<PartialHoursItemProps> = ({
  day,
  hours,
  isWeekDay,
  updatePartialHours,
  getIsNumericInputValid,
}) => {
  const onChangeHandler = debounce(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      updatePartialHours(day, Number(e.target.value)),
    500
  );

  return (
    <div
      className={tailwindOverride(
        'w-full flex flex-1',
        'items-center justify-between'
      )}
    >
      <Typography variant='body' weight='medium' className='text-primary'>
        {dateFormatter.format(new Date(day.replace(/-/g, '/')))}
      </Typography>
      <div className='flex items-center'>
        <NumericInput
          aria-label={`partial-hours-field-for-${day}`}
          min={0}
          defaultValue={hours}
          containerClassName='w-24'
          disabled={!isWeekDay}
          onChange={onChangeHandler}
          allowNegativeValue={false}
          allowFloatValue={true}
          maxAllowedDecimalPlaces={2}
          step='any'
          getIsInputValid={(isValid: boolean) => {
            getIsNumericInputValid(isValid);
          }}
        />
        <Typography
          variant='body'
          weight='medium'
          className='text-primary ml-2'
        >
          {intl.get('MANAGE_TIME_OFF.HOURS')}
        </Typography>
      </div>
    </div>
  );
};

export default PartialHoursItem;
