import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  selectDueProjectsForModal,
  setDueProjectsOrderForModal,
} from 'state/MyWorkspace/myWorkspaceSlice';
import ProjectsTable from './ProjectsTable';
import MyWorspaceModal from '../MyWorkspaceModal';

type ProjectsModalProps = {
  icon: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const ProjectsModal = ({ icon, isOpen, setIsOpen }: ProjectsModalProps) => {
  const dispatch = useDispatch();
  const projectsSelector = useSelector(selectDueProjectsForModal);

  const handleSort = (orderByParam: string[], order: 'desc' | 'asc') => {
    dispatch(setDueProjectsOrderForModal({ order, orderBy: orderByParam }));
  };

  return (
    <MyWorspaceModal
      title={intl.get('MY_WORKSPACE.DUE_PROJECTS.MODAL_TITLE')}
      icon={icon}
      content={
        <ProjectsTable
          data={projectsSelector}
          handleSort={handleSort}
          cardTable={false}
        />
      }
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dataCy='due-projects-modal'
    />
  );
};

export default ProjectsModal;
