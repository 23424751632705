import {
  LargeLabel,
  FormItem,
  Typography,
  TextField,
  NumericInput,
} from '@getsynapse/design-system';
import { ProgramInputCategoryData } from 'utils/types/program';
import intl from 'react-intl-universal';

const LearnerDetailSection = ({
  programInput,
  changeIsNumericInputValid,
  updateInputField,
}: {
  programInput: ProgramInputCategoryData;
  changeIsNumericInputValid: (value: boolean) => void;
  updateInputField: (
    value: string | number,
    property: keyof ProgramInputCategoryData
  ) => void;
}) => {
  const changeNumberOfLearners = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let numberOfLearners: string | number = event.target.value;
    if (numberOfLearners !== '') {
      numberOfLearners = Number(event.target.value);
    }
    updateInputField(numberOfLearners, 'numberOfLearners');
  };

  return (
    <div className='border-t w-full border-neutral-lighter-two mt-10'>
      <Typography variant='h5' className='mt-8'>
        {intl.get('INPUT_CATEGORY.LEARNER_DETAIL_SECTION.SECTION_TITLE')}
      </Typography>
      <Typography variant='body2' className='text-neutral-darker mb-4 mt-1'>
        {intl.get('INPUT_CATEGORY.OBJECTIVES_DETAIL_SECTION.SECTION_SUBTITLE')}
      </Typography>
      <div className='w-full flex gap-10'>
        <FormItem
          className='w-1/2'
          label={
            <LargeLabel
              label={intl.get(
                'INPUT_CATEGORY.LEARNER_DETAIL_SECTION.FIELDS.AUDIENCE.TITLE'
              )}
              subtitle={intl.get(
                'INPUT_CATEGORY.LEARNER_DETAIL_SECTION.FIELDS.AUDIENCE.SUBTITLE'
              )}
              subtitleProps={{ className: 'text-neutral-darker' }}
              labelProps={{ className: 'text-base mb-0.5' }}
            />
          }
          labelProps={{ className: 'mb-0' }}
        >
          <TextField
            placeholder={intl.get(
              'INPUT_CATEGORY.LEARNER_DETAIL_SECTION.FIELDS.AUDIENCE.PLACEHOLDER'
            )}
            data-testid='audience-input'
            value={programInput.audience ?? ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              updateInputField(event.target.value, 'audience')
            }
          />
        </FormItem>
        <FormItem
          className='w-1/2'
          label={
            <LargeLabel
              label={intl.get(
                'INPUT_CATEGORY.LEARNER_DETAIL_SECTION.FIELDS.NO_OF_LEARNERS.TITLE'
              )}
              subtitle={intl.get(
                'INPUT_CATEGORY.LEARNER_DETAIL_SECTION.FIELDS.NO_OF_LEARNERS.SUBTITLE'
              )}
              subtitleProps={{ className: 'text-neutral-darker' }}
              labelProps={{ className: 'text-base mb-0.5' }}
            />
          }
          labelProps={{ className: 'mb-0' }}
        >
          <NumericInput
            placeholder={intl.get(
              'INPUT_CATEGORY.LEARNER_DETAIL_SECTION.FIELDS.NO_OF_LEARNERS.PLACEHOLDER'
            )}
            divProps={{
              className: 'h-10.5',
              'data-testid': 'number-of-learners-input',
            }}
            value={
              programInput.numberOfLearners === null ||
              programInput.numberOfLearners === undefined
                ? ''
                : programInput.numberOfLearners
            }
            data-testid='number-of-learners_numeric-input'
            onChange={changeNumberOfLearners}
            allowNegativeValue={false}
            allowFloatValue={false}
            getIsInputValid={changeIsNumericInputValid}
            min={0}
            max={9999999999}
          />
        </FormItem>
      </div>
    </div>
  );
};

export default LearnerDetailSection;
