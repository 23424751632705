import { Typography, Tab, TabList } from '@getsynapse/design-system';
import { useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import {
  selectResourceUtilizationStatus,
  selectMostUtilizedResourceUtilization,
  selectLeastUtilizedResourceUtilization,
} from 'state/Insights/capacitySlice';
import { SLICE_STATUS } from 'utils/constants';
import ChartSkeletonLoader from './ChartSkeletonLoader';
import NoData from '../components/NoData';
import LoadingError from '../components/LoadingError';
import {
  MOST_UTILIZED_TOP_COLOR,
  MOST_UTILIZED_BOTTOM_COLOR,
  LEAST_UTILIZED_TOP_COLOR,
  LEAST_UTILIZED_BOTTOM_COLOR,
  LEAST_UTILIZED_TOP_COLOR_RGB,
  MOST_UTILIZED_TOP_COLOR_RGB,
} from './constants';
import { TabPanels, TabPanel, Tabs } from '@reach/tabs';
import { ResourceUtilizationType } from 'state/Insights/types';
import classNames from 'classnames';
import styles from '../UtliztionByTeam/UtilizatonByTeamChart.module.css';

const UtilizationByResourcesChart = () => {
  const mostUtilized = useSelector(selectMostUtilizedResourceUtilization);
  const leastUtilized = useSelector(selectLeastUtilizedResourceUtilization);
  const resourceUtilizationStatus = useSelector(
    selectResourceUtilizationStatus
  );

  const [activeIndex, setActiveIndex] = useState(0);

  const renderBars = () => {
    const data = activeIndex ? leastUtilized : mostUtilized;
    const utilization = data?.map((item) => item.utilization);
    const minimumUtilization = Math.min(...utilization!) / 100;
    const maximumUtilization = Math.max(...utilization!) / 100;
    const midPoint = ((maximumUtilization + minimumUtilization) / 2) * 100;
    const colorArray = activeIndex
      ? [
          {
            value: minimumUtilization,
            color: LEAST_UTILIZED_TOP_COLOR,
          },
          {
            value: maximumUtilization,
            color: LEAST_UTILIZED_BOTTOM_COLOR,
          },
        ]
      : [
          {
            value: minimumUtilization,
            color: MOST_UTILIZED_BOTTOM_COLOR,
          },
          {
            value: maximumUtilization,
            color: MOST_UTILIZED_TOP_COLOR,
          },
        ];
    const lower = colorArray[0];
    const upper = colorArray[1];
    const range = upper.value - lower.value;
    let color = activeIndex
      ? LEAST_UTILIZED_TOP_COLOR_RGB
      : MOST_UTILIZED_TOP_COLOR_RGB;

    return (
      <div
        className={classNames(
          'mt-4 h-84.8 overflow-y-auto pr-5',
          styles.scrollbar
        )}
      >
        {data?.map((item: ResourceUtilizationType, index: number) => {
          if (range !== 0) {
            const rangeValue = (item.utilization / 100 - lower.value) / range;
            const lowerValue = 1 - rangeValue;
            const upperValue = rangeValue;
            color = {
              r: Math.floor(
                lower.color.r * lowerValue + upper.color.r * upperValue
              ),
              g: Math.floor(
                lower.color.g * lowerValue + upper.color.g * upperValue
              ),
              b: Math.floor(
                lower.color.b * lowerValue + upper.color.b * upperValue
              ),
            };
          }
          const bgColor = 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
          let textClassName;
          if (activeIndex) {
            textClassName =
              item.utilization > midPoint
                ? 'text-secondary-darkest'
                : 'text-secondary-lighter';
          } else {
            textClassName =
              item.utilization >= midPoint
                ? 'text-error-lightest-two'
                : 'text-tertiary-darker';
          }

          return (
            <div className='flex mb-3.5' key={`resources-utilization-${index}`}>
              <div className='w-half-width-minus-1 text-right mr-4 h-9 pt-1'>
                <p
                  title={item.name}
                  className='text-3 leading-3 text-neutral-black font-semibold truncate cursor-default'
                >
                  {item.name}
                </p>
                <div>
                  <p
                    title={item.jobTitle}
                    className='text-3 text-primary-dark leading-3 truncate cursor-default'
                  >
                    {item.jobTitle}
                  </p>
                </div>
              </div>
              <div
                className='w-1/2 flex items-center justify-end pr-4 rounded'
                style={{
                  backgroundColor: bgColor,
                }}
              >
                <Typography
                  variant='caption'
                  weight='medium'
                  className={textClassName}
                >{`${item.utilization}%`}</Typography>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className='w-full p-6' data-testid='resource-utilization_chart'>
      {resourceUtilizationStatus === SLICE_STATUS.LOADING && (
        <ChartSkeletonLoader />
      )}
      {resourceUtilizationStatus !== SLICE_STATUS.LOADING && (
        <>
          <div className='h-4 mb-7'>
            <p className='text-3.5 leading-2 text-neutral-black font-semibold'>
              {intl.get('CAPACITY_INSIGHTS.RESOURCE_UTILIZATION.TITLE')}
            </p>
            <Typography variant='caption' className='text-neutral-dark'>
              {intl.get('CAPACITY_INSIGHTS.RESOURCE_UTILIZATION.CAPTION')}
            </Typography>
          </div>
          {mostUtilized?.length && (
            <Tabs
              index={activeIndex}
              onChange={(index: number) => setActiveIndex(index)}
            >
              <TabList type='subHeader' className='mb-9'>
                <Tab index={0} type='subHeader'>
                  {intl.get(
                    'CAPACITY_INSIGHTS.RESOURCE_UTILIZATION.MOST_UTILIZED'
                  )}
                </Tab>
                <Tab index={1} type='subHeader'>
                  {intl.get(
                    'CAPACITY_INSIGHTS.RESOURCE_UTILIZATION.LEAST_UTILIZED'
                  )}
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel key={0}>{renderBars()}</TabPanel>
                <TabPanel key={1}>{renderBars()}</TabPanel>
              </TabPanels>
            </Tabs>
          )}
          {mostUtilized && !mostUtilized?.length && <NoData />}

          {!mostUtilized && <LoadingError />}
        </>
      )}
    </div>
  );
};

export default UtilizationByResourcesChart;
