import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from 'state/User/userSlice';
import { useMemo, useEffect } from 'react';
import intl from 'react-intl-universal';
import { Icon } from '@getsynapse/design-system';
import morningIcon from 'assets/icons/morning-icon.svg';
import afternoonIcon from 'assets/icons/afternoon-icon.svg';
import eveningIcon from 'assets/icons/evening-icon.svg';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import MentionsCard from './components/RecentMentions/MentionsCard';
import ProjectsCard from './components/DueProjects/ProjectsCard';
import RequestsCard from './components/InReviewRequests/RequestsCard';
import TasksCard from './components/DueTasks/TasksCard';
import {
  getDueProjects,
  getDueTasks,
  getRecentMentions,
  getRequestsInReview,
  setTablesLimit,
  setMentionsLimit,
} from 'state/MyWorkspace/myWorkspaceSlice';
import {
  LARGE_SCREEN_WIDTH,
  NUMBER_OF_ROWS_IN_SMALL_SCREENS,
  NUMBER_OF_ROWS_IN_LARGE_SCREENS,
  NUMBER_OF_MENTIONS_IN_SMALL_SCREENS,
  NUMBER_OF_MENTIONS_IN_LARGE_SCREENS,
} from 'utils/constants';

const MyWorkspace = () => {
  const currentHour = new Date().getHours();
  const userName = useSelector(selectUser).firstName;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDueTasks());
    dispatch(getRequestsInReview());
    dispatch(getRecentMentions());
    dispatch(getDueProjects());
    dispatch(
      setTablesLimit(
        window.innerWidth <= LARGE_SCREEN_WIDTH
          ? NUMBER_OF_ROWS_IN_SMALL_SCREENS
          : NUMBER_OF_ROWS_IN_LARGE_SCREENS
      )
    );
    dispatch(
      setMentionsLimit(
        window.innerWidth <= LARGE_SCREEN_WIDTH
          ? NUMBER_OF_MENTIONS_IN_SMALL_SCREENS
          : NUMBER_OF_MENTIONS_IN_LARGE_SCREENS
      )
    );
  }, [dispatch]);

  const greetingMessage = useMemo(
    () => (
      <div className='flex items-center'>
        {currentHour >= 0 && currentHour <= 11 ? (
          <>
            {intl.get('MY_WORKSPACE.MORNING_GREETING', { userName })}
            <Icon src={morningIcon} className='ml-1' />
          </>
        ) : currentHour >= 12 && currentHour <= 17 ? (
          <>
            {intl.get('MY_WORKSPACE.AFTERNOON_GREETING', { userName })}
            <Icon src={afternoonIcon} className='ml-1' />
          </>
        ) : (
          <>
            {intl.get('MY_WORKSPACE.EVENING_GREETING', { userName })}
            <Icon src={eveningIcon} className='ml-1' />
          </>
        )}
      </div>
    ),
    [currentHour, userName]
  );

  return (
    <>
      <PageTitle titleComponent={greetingMessage} />
      <div
        data-testid='sammy'
        className='grid grid-cols-2 gap-4 pt-3 px-6 pb-11'
      >
        <TasksCard />
        <ProjectsCard />
        <RequestsCard />
        <MentionsCard />
      </div>
    </>
  );
};

export default MyWorkspace;
