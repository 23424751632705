import { FC } from 'react';
import intl from 'react-intl-universal';
import { Typography, tailwindOverride } from '@getsynapse/design-system';
import {
  File,
  Design,
  WebLink,
  ContentType,
  LinkedTaskContentUploader,
} from 'types/store/linkedTaskContent';
import ContentUploader from './ContentUploader';

interface ContentDescriptionProps {
  contentType: ContentType;
  contentData: File | Design | WebLink;
  displayUploader?: boolean;
  displayType?: boolean;
  truncateName?: boolean;
  className?: string;
  uploader: LinkedTaskContentUploader;
  createdAt: string;
}

const ContentDescription: FC<ContentDescriptionProps> = ({
  contentType,
  contentData,
  displayUploader = false,
  displayType = true,
  truncateName = true,
  className = '',
  uploader,
  createdAt,
}) => {
  let fileName: string = '';
  let typeLabel: string = '';

  switch (contentType) {
    case 'file':
      fileName = (contentData as File).filename;
      typeLabel = intl.get('PROJECT_DETAIL.FILES_TAB.TABLE.CONTENT_TYPE.FILE');
      break;
    case 'web_link':
      fileName = (contentData as WebLink).display;
      typeLabel = intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.WEBLINK');
      break;
    case 'design':
      fileName = (contentData as Design).title;
      typeLabel = intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.DESIGN');
      break;
    case 'design_file':
      fileName = (contentData as File).filename;
      typeLabel = intl.get(
        'PROJECT_DETAIL.FILES_TAB.TABLE.CONTENT_TYPE.DESIGN'
      );
      break;
    default:
      break;
  }

  return (
    <div className={tailwindOverride('w-full flex flex-col gap-y-2')}>
      <div className={tailwindOverride({ truncate: truncateName })}>
        <Typography
          variant='label'
          className={tailwindOverride('cursor-pointer', className, {
            'text-primary font-semibold': truncateName,
          })}
        >
          {fileName}
        </Typography>
      </div>
      {displayType && (
        <Typography
          variant='label'
          className={tailwindOverride('cursor-pointer', className)}
        >
          {typeLabel}
        </Typography>
      )}
      {displayUploader && (
        <ContentUploader
          uploader={uploader}
          createdAt={createdAt}
          className={className}
        />
      )}
    </div>
  );
};

export default ContentDescription;
