import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { ApprovedRequestAnalytic } from 'utils/customTypes';
import { INSIGHTS_PAGE_COLORS } from 'utils/constants';
import CustomTooltip from '../../Components/CustomToolTip';
import intl from 'react-intl-universal';

const BarChartForApprovedRequests = ({
  data,
}: {
  data: ApprovedRequestAnalytic[];
}) => {
  return (
    <ResponsiveContainer width='88%' height='80%'>
      <BarChart data={data} layout='vertical' barGap={8}>
        <XAxis
          type='number'
          domain={[0, 'dataMax']}
          style={INSIGHTS_PAGE_COLORS.APPROVED_REQUESTS.BAR_CHART_AXIES_STYLE}
          tickLine={false}
          padding={{
            left: 1,
            right: 45,
          }}
          label={{
            value: intl.get(
              'INSIGHTS_PAGE_TABS.APPROVED_REQUESTS.NUMBER_OF_REQUESTS'
            ),
            position: 'right',
            offset: -26,
            className: 'fill-primary font-semibold text-xs h-full',
          }}
        />
        <YAxis
          tickLine={false}
          dataKey='businessTeamTitle'
          type='category'
          style={INSIGHTS_PAGE_COLORS.APPROVED_REQUESTS.BAR_CHART_AXIES_STYLE}
          width={145}
          interval={0}
        />
        <Tooltip content={<CustomTooltip chartType='Bar' />} />
        <Bar
          dataKey='requestCount'
          maxBarSize={40}
          name={intl.get('INSIGHTS_PAGE_TABS.NUMBER_OF_REQUESTS')}
          fill={INSIGHTS_PAGE_COLORS.APPROVED_REQUESTS.BAR_CHART_MAIN_COLOR}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartForApprovedRequests;
