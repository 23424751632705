import { FC } from 'react';
import intl from 'react-intl-universal';
import { TaskWithAssignees } from 'types/store/projectTasksList';
import { ProjectTasksTableTab } from 'utils/customTypes';
import RenderNoRecords from 'Pages/RequestsListPage/components/NoRecords';
import TaskRow from './TaskRow';
import LastTaskRow from './LastTaskRow';
import CreateTaskModal from 'Pages/TasksListPage/components/v2/CreateTaskModal/CreateTaskModal';
import EmptyTasks from 'assets/images/empty-tasks.svg';

interface TableBodyProps {
  tableName: ProjectTasksTableTab;
  tasks: TaskWithAssignees[];
  canFetchMoreTasks: boolean;
  fetchMoreTasks: () => void;
  showDisabledColumn?: boolean;
  showReorderColumn?: boolean;
}

const TableBody: FC<TableBodyProps> = ({
  tasks,
  tableName,
  canFetchMoreTasks,
  fetchMoreTasks,
  showDisabledColumn = false,
  showReorderColumn = false,
}) => {
  const isListEmpty = tasks.length === 0;

  return (
    <tbody>
      {tasks.map((task: TaskWithAssignees, index: number) => {
        const isOdd = index % 2 !== 0;
        return (
          <TaskRow
            key={task.id}
            task={task}
            bgColor={isOdd ? 'bg-neutral-lightest-two' : 'bg-neutral-white'}
            showDisabledColumn={showDisabledColumn}
            showReorderColumn={showReorderColumn}
          />
        );
      })}
      {!isListEmpty && canFetchMoreTasks && (
        <LastTaskRow
          isOdd={tasks.length % 2 !== 0}
          tableName={tableName}
          onLastRowInView={fetchMoreTasks}
          showDisabledColumn={showDisabledColumn}
          showReorderColumn={showReorderColumn}
        />
      )}
      {isListEmpty && (
        <tr aria-label={`${tableName}-empty-body`}>
          <td colSpan={10}>
            <RenderNoRecords
              caption={intl.get('TASKS.TABLE.EMPTY')}
              imageSrc={EmptyTasks}
              className='h-empty-table-body w-full'
              labelClassName='mt-0'
              imageClassName='-ml-4'
            >
              <div className='flex-initial self-center mt-2'>
                <CreateTaskModal isCentralizedPage={false} />
              </div>
            </RenderNoRecords>
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default TableBody;
