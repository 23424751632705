import React from 'react';
import { useSelector } from 'react-redux';
import { tailwindOverride } from '@getsynapse/design-system';
import { selectNumberOfDays } from 'state/DailyTeamsCapacity/Days/daysSlice';
import { BaseDaysCarouselItemProps } from 'utils/types/dailyTeamsCapacity';
import { initDateFormatter } from '../../helpers';

interface DaysCarouselProps<T> {
  id: string;
  component: React.ComponentType<T>;
  items: {
    [day: string]: T | null;
  };
}

const DaysCarousel = <T extends BaseDaysCarouselItemProps>({
  id,
  component,
  items,
}: DaysCarouselProps<T>) => {
  const dateFormatter = initDateFormatter();
  const currentDate = dateFormatter.format(new Date());
  const numberOfDays = useSelector(selectNumberOfDays);
  return (
    <div
      aria-label={`${id}__daily-capacity__carousel`}
      className={tailwindOverride('w-full h-full flex')}
    >
      <div className='w-6 h-full' />
      <div className='w-full h-full flex'>
        {Object.keys(items).map((date) => {
          const dateToCompare = dateFormatter.format(
            new Date(date.replace(/-/g, '/'))
          );
          const isCurrentDay = currentDate === dateToCompare;
          const hasCapacity = items[date] !== null;
          return (
            <div
              key={`${id}__daily-capacity__day-${date}`}
              role='group'
              aria-label={`${id}__daily-capacity__day-${date}`}
              className={tailwindOverride(
                'flex-1 h-full',
                'flex-shrink-0 flex-grow',
                {
                  'bg-secondary-lightest border-l border-r border-secondary-lighter':
                    isCurrentDay,
                }
              )}
              style={{ width: `${100 / numberOfDays}%` }}
            >
              {hasCapacity &&
                React.createElement(component, { ...items[date]! })}
              {!hasCapacity && (
                <div className='w-full h-14 flex items-center px-2 animate-pulse'>
                  <div className='w-full h-4 rounded-sm bg-primary-lighter' />
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className='w-6 h-full' />
    </div>
  );
};

export default DaysCarousel;
