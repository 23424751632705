import {
  TaskBundle,
  filter,
  SortingType,
  Status,
  TaskBundleCreationData,
} from 'utils/customTypes';
import TaskBundleAPI from './tasksBundleAPI';
import { RootState } from 'state/store';
import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { SLICE_STATUS, TASK_BUNDLE_SORTING } from 'utils/constants';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import { naturalSort } from '../../utils/naturalSorting';

interface TaskBundleState {
  taskBundles: TaskBundle[];
  filters: filter[];
  pagination: {
    limit: number;
    offset: number;
  };
  sorting: {
    order: SortingType;
    orderBy: string[];
  };
  searchParam: string;
  status: Status;
}

interface TaskSorting {
  order: 'asc' | 'desc';
  orderBy: string[];
}

/* ============================= INITIAL STATE ============================== */
const initialState: TaskBundleState = {
  taskBundles: [],
  filters: [],
  pagination: {
    limit: 15,
    offset: 0,
  },
  sorting: {
    order: 'asc',
    orderBy: [],
  },
  searchParam: '',
  status: SLICE_STATUS.IDLE,
};

const taskBundleAPI = TaskBundleAPI;

/* ============================== REDUX THUNK =============================== */
export const fetchTaskBundles = createAsyncThunk(
  'taskBundles/FETCH_TASK_BUNDLES',
  async () => {
    const response = await taskBundleAPI.fetchTaskBundles();
    return response.data;
  }
);

export const addTaskBundle = createAsyncThunk(
  'taskBundles/ADD_TASK_BUNDLE',
  async (bundleData: TaskBundleCreationData) => {
    const response = await taskBundleAPI.createTaskBundle(bundleData);
    return response.data;
  }
);

export const attachTaskBundleToProject = createAsyncThunk(
  'taskBundles/ATTACH_TASK_BUNDLE_TO_PROJECT',
  async (data: { taskBundleId: string; projectId: string }) => {
    const response = await taskBundleAPI.attachTaskBundleToProject(
      data.taskBundleId,
      data.projectId
    );
    return response.data;
  }
);

export const deleteTaskBundle = createAsyncThunk(
  'taskBundles/DELETE_TASK_BUNDLE',
  async (bundleId: string) => {
    const { code } = await taskBundleAPI.deleteTaskBundle(bundleId);
    if (code !== 200) {
      throw new Error('An error ocurred');
    }

    return { bundleId };
  }
);

/* ============================= ACTIONS ============================== */
export const setTaskBundleTableSearch = createAction(
  'taskBundles/SET_TASK_BUNDLE_SEARCH',
  (search: string) => {
    return { payload: search };
  }
);

export const updateTaskBundlePagination = createAction<{
  limit: number;
  offset: number;
}>('taskBundles/UPDATE_TASK_BUNDLE_TABLE_PAGINATION');

export const setTaskBundleSortingOptions = createAction<{
  order: SortingType;
  orderBy: string[];
}>('taskBundles/SET_TASK_BUNDLE_ORDERS');

/* ================================= REDUCER ================================ */
const bundleSlice = createSlice({
  name: 'taskBundles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskBundles.fulfilled, (state, action) => {
        state.taskBundles = [...get(action, 'payload.taskBundles', [])];
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchTaskBundles.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchTaskBundles.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(setTaskBundleTableSearch, (state, action) => {
        state.searchParam = action.payload;
      })
      .addCase(updateTaskBundlePagination, (state, action) => {
        state.pagination.limit = action.payload.limit;
        state.pagination.offset = action.payload.offset;
      })
      .addCase(setTaskBundleSortingOptions, (state, action) => {
        state.sorting.order = action.payload.order;
        state.sorting.orderBy = action.payload.orderBy;
      })
      .addCase(deleteTaskBundle.fulfilled, (state, action) => {
        state.taskBundles = state.taskBundles.filter(
          (bundle) => bundle.id !== action.payload.bundleId
        );
      });
  },
});

/* =============================== SELECTORS ================================ */

export const selectAllTaskBundles = (state: RootState) =>
  state.taskBundles.taskBundles;

export const selectTaskBundleTableSearch = (state: RootState) =>
  state.taskBundles.searchParam;

export const selectTaskBundleSliceStatus = (state: RootState) =>
  state.taskBundles.status;

export const taskBundleFilters = (state: RootState) =>
  state.taskBundles.filters;

const taskBundlePagination = (state: RootState) => state.taskBundles.pagination;

const taskBundleSorting = (state: RootState) => state.taskBundles.sorting;

const getSortingKey = (
  taskBundle: TaskBundle,
  orderRoute: keyof TaskBundle
) => {
  switch (orderRoute) {
    case TASK_BUNDLE_SORTING.CREATOR[0]:
    case TASK_BUNDLE_SORTING.CREATOR[1]: {
      const label = `${
        get(taskBundle, TASK_BUNDLE_SORTING.CREATOR[0]) || ''
      } ${get(taskBundle, TASK_BUNDLE_SORTING.CREATOR[1])}`.trim();
      return label ? label.toLocaleLowerCase() : '';
    }
    default: {
      const label = `${get(taskBundle, orderRoute) || ''}`.trim();
      return label ? label.toLocaleLowerCase() : '';
    }
  }
};

const sortFormattedTaskBundles = (
  taskBundles: TaskBundle[],
  sorting: TaskSorting
): TaskBundle[] => {
  const { orderBy: orderRoutes, order } = sorting;
  let sortedTaskBundles = taskBundles;

  orderRoutes.forEach((orderRoute) => {
    switch (orderRoute) {
      case TASK_BUNDLE_SORTING.NAME[0]:
      case TASK_BUNDLE_SORTING.DESCRIPTION[0]:
        sortedTaskBundles = naturalSort(
          sortedTaskBundles,
          orderRoute as keyof TaskBundle,
          order
        );
        break;
      case TASK_BUNDLE_SORTING.ESTIMATED_HOURS[0]:
        sortedTaskBundles = naturalSort(
          sortedTaskBundles,
          orderRoute as keyof TaskBundle,
          order
        );
        break;
      default:
        sortedTaskBundles = orderBy(
          sortedTaskBundles,
          (taskBundle) =>
            getSortingKey(taskBundle, orderRoute as keyof TaskBundle),
          [order]
        );
        break;
    }
  });

  return sortedTaskBundles;
};

export { sortFormattedTaskBundles };

export const taskBundlesForTable = createSelector(
  [
    selectAllTaskBundles,
    selectTaskBundleTableSearch,
    taskBundlePagination,
    taskBundleSorting,
  ],
  (taskBundles: TaskBundle[], searchParam, pagination, sorting) => {
    let formattedTaskBundles = taskBundles.filter((taskBundle) => {
      if (searchParam) {
        return (
          taskBundle.name
            ?.toLocaleLowerCase()
            .includes(searchParam.toLocaleLowerCase()) || false
        );
      } else {
        return true;
      }
    }) as TaskBundle[];

    formattedTaskBundles = sortFormattedTaskBundles(
      formattedTaskBundles,
      sorting
    );

    return {
      total: taskBundles.length,
      data: formattedTaskBundles.slice(
        pagination.offset,
        pagination.offset + pagination.limit
      ),
    };
  }
);

export default bundleSlice.reducer;
