import { Tag } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { REQUEST_STATUSES } from 'utils/constants/request';
import { RequestStatus } from 'utils/types/request';

export const mapTagColor: Record<
  RequestStatus,
  { className: string; textClassName: string }
> = {
  [REQUEST_STATUSES.DRAFT]: {
    className: 'bg-purple-lightest group-hover:border-purple-dark',
    textClassName: 'text-purple-darker',
  },
  [REQUEST_STATUSES.SUBMITTED]: {
    className: 'bg-teal-lightest group-hover:border-teal',
    textClassName: 'text-teal-dark',
  },
  [REQUEST_STATUSES.APPROVED]: {
    className: 'bg-success-lightest group-hover:border-success-dark',
    textClassName: 'text-success-darker',
  },
  [REQUEST_STATUSES.REJECTED]: {
    className: 'bg-fire-lighter group-hover:border-fire-dark',
    textClassName: 'text-fire-darker',
  },
  [REQUEST_STATUSES.CANCELED]: {
    className: 'bg-error-lightest group-hover:border-error',
    textClassName: 'text-error-darker',
  },
  [REQUEST_STATUSES.PENDING_CANCELLATION]: {
    className: 'bg-tertiary-lightest group-hover:border-tertiary',
    textClassName: 'text-tertiary-darker',
  },
  [REQUEST_STATUSES.WAITLISTED]: {
    className: 'bg-warning-lightest group-hover:border-warning-light',
    textClassName: 'text-warning-darker',
  },
};

interface RequestStatusTagProps {
  status: RequestStatus;
  isForm?: boolean;
}

const RequestStatusTag: React.FC<RequestStatusTagProps> = ({
  status,
  isForm = false,
}) => {
  return (
    <Tag
      data-testid='request-status-tag'
      label={
        isForm
          ? intl.get('REQUEST_PAGE.TOP_BAR.FORM')
          : intl.get(
              `REQUESTS_LIST_PAGE.TABLE.STATUS_LABEL.${status.toUpperCase()}`
            )
      }
      className={`border border-transparent py-0.5 ${mapTagColor[status].className}`}
      textClassName={mapTagColor[status].textClassName}
    />
  );
};

export default RequestStatusTag;
