import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import has from 'lodash/has';
import moment from 'moment';
import { FlatfileResults } from '@flatfile/react';
import { REQUEST_IMPORT_ATTRIBUTES, REQUEST_STATUS } from 'utils/constants';
import {
  Request,
  RequestQuestion,
  RequestQuestionDataDate,
  RequestQuestionDataDropdown,
  checkboxOption,
} from 'utils/customTypes';

export const defaultRequest: Partial<Request> = {
  request_type: 'OTHERS',
  requestCreator: '',
  data: { baseFormId: '' },
  title: '',
  description: '',
  owners: [],
  businessTeams: [],
  effort: '',
  cost: '',
  ldPriority: 'unassigned',
  organization_id: '',
  status: REQUEST_STATUS.DRAFT,
};

export const cleanImportedRequests = async (
  result: FlatfileResults,
  organizationId: string,
  baseFormId: string,
  businessUnit: { id: string; title: string },
  questions: RequestQuestion[]
) => {
  const requestData = result.validData;

  if (isEmpty(requestData)) {
    return [];
  }

  const cleanRequestData = requestData.map((request: Partial<Request>) => {
    let cleanRequest: Partial<Request> & {
      questions: { [key: string]: RequestQuestion };
    } = { ...defaultRequest, questions: {} };

    for (const key of Object.keys(cleanRequest)) {
      if (key === 'data') {
        cleanRequest = {
          ...cleanRequest,
          data: {
            ...request.data,
            baseFormId: get(request, 'baseFormId') || '',
          },
        };
      } else if (key.startsWith('question_')) {
        continue;
      } else {
        cleanRequest = { ...cleanRequest, [key]: get(request, key) || '' };
      }
    }

    const cleanQuestions = mapRequestQuestions(request, questions);
    cleanRequest = {
      ...cleanRequest,
      questions: cleanQuestions,
    };

    if (!get(cleanRequest, 'organization_id')) {
      cleanRequest = {
        ...cleanRequest,
        organization_id: organizationId,
      };
    }
    if (!get(cleanRequest, 'data.baseFormId')) {
      cleanRequest = {
        ...cleanRequest,
        data: { ...cleanRequest.data, baseFormId },
      };
    }
    if (!get(cleanRequest, 'businessTeams')) {
      cleanRequest = {
        ...cleanRequest,
        businessTeams: [businessUnit],
      };
    }
    if (has(request, REQUEST_IMPORT_ATTRIBUTES.SUBMITTED_AT)) {
      const submittedAt = get(request, REQUEST_IMPORT_ATTRIBUTES.SUBMITTED_AT);
      if (!isEmpty(submittedAt) && moment(submittedAt).isValid()) {
        cleanRequest = {
          ...cleanRequest,
          submittedAt: new Date(submittedAt).toISOString(),
        };
      }
    }
    if (!get(cleanRequest, REQUEST_IMPORT_ATTRIBUTES.SUBMITTED_AT)) {
      cleanRequest = {
        ...cleanRequest,
        submittedAt: new Date().toISOString(),
      };
    }
    cleanRequest = {
      ...cleanRequest,
      type: 'request',
    };
    return cleanRequest;
  });

  return cleanRequestData;
};

const trimQuestionLabel = (value: string, label: string) =>
  value.replace(new RegExp(`^${label}: `), '');

const mapMultiSelectDropdownQuestion = (
  baseQuestionValue: any,
  flatfileQuestion: string
) => {
  const flatfileValues: string[] = flatfileQuestion
    .replace(/"/g, '')
    .split(',')
    .map((value: string) => value.trim());
  const mappedValues = baseQuestionValue.items.filter(
    ({ display }: { display: string }) => flatfileValues.includes(display)
  );
  return mappedValues;
};

const mapDropdownQuestion = (
  baseQuestionValue: any,
  flatfileQuestion: string
) =>
  baseQuestionValue.items.find(
    ({ value }: { value: string }) => value === flatfileQuestion
  );

const mapCheckboxQuestion = (
  baseQuestionValue: any,
  flatfileQuestion: string
) => {
  const flatfileValues: string[] = flatfileQuestion
    .replace(/"/g, '')
    .split(',')
    .map((value: string) => value.trim());

  return baseQuestionValue.items.map(
    (item: checkboxOption) =>
      ({
        ...item,
        checked: flatfileValues.includes(item.item),
      } as checkboxOption)
  );
};

const mapDateRangeQuestion = (flatfileQuestion: string) => {
  const value = flatfileQuestion.replace(/\\"/g, '');
  let range: { startDate: string; endDate: string } | undefined;

  try {
    const o = JSON.parse(value);
    range = {
      startDate: new Date(o.startDate).toISOString(),
      endDate: new Date(o.endDate).toISOString(),
    };
  } catch (e) {
    range = undefined;
  }
  return range;
};

const mapRequestQuestions = (
  request: Partial<Request>,
  questions: RequestQuestion[]
) => {
  let cleanQuestions: { [key: string]: RequestQuestion } = {};

  for (const key of Object.keys(request)) {
    if (key.startsWith('question_')) {
      const questionId = key.split('_')[1];
      const question = questions.find(({ id }) => id === questionId);

      if (question) {
        const { type } = question;
        const baseQuestionValue = question.data.value;

        let flatfileQuestion: string = get(request, key) || '';
        flatfileQuestion = trimQuestionLabel(
          flatfileQuestion,
          question.data.label
        );

        let mapped: any;

        if (
          type === 'dropdown' &&
          (question.data as RequestQuestionDataDropdown).multiselect
        ) {
          mapped = {
            value: mapMultiSelectDropdownQuestion(
              baseQuestionValue,
              flatfileQuestion
            ),
          };
        } else if (type === 'dropdown') {
          mapped = {
            value: mapDropdownQuestion(baseQuestionValue, flatfileQuestion),
          };
        } else if (type === 'checkbox') {
          mapped = {
            items: mapCheckboxQuestion(baseQuestionValue, flatfileQuestion),
          };
        } else if (type === 'radio') {
          mapped = { value: flatfileQuestion };
        } else if (type === 'range') {
          mapped = { value: flatfileQuestion.replace(/"/g, '') };
        } else if (
          type === 'date' &&
          (question.data as RequestQuestionDataDate).range
        ) {
          mapped = { value: mapDateRangeQuestion(flatfileQuestion) };
        } else {
          // Apply simple values
          cleanQuestions[questionId] = {
            ...question,
            data: {
              ...question.data,
              value:
                typeof flatfileQuestion === 'string'
                  ? flatfileQuestion
                      .replace(new RegExp(`^${question.data.label}: `), '')
                      .replace(/^"|"$/g, '')
                      .replace(/\\n/g, '\n')
                  : flatfileQuestion,
            },
          };
          continue;
        }

        // Apply mapped value
        cleanQuestions[questionId] = {
          ...question,
          data: {
            ...question.data,
            value: {
              ...baseQuestionValue,
              ...mapped,
            },
          },
        };
      }
    }
  }

  return cleanQuestions;
};
