import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { tailwindOverride } from '@getsynapse/design-system';
import { CAPACITY_TABLE_NUMBER_OF_WEEKS } from 'utils/constants';
import {
  selectPagination,
  capacityStatus,
} from 'state/TasksAssignmentsResourcesCapacity/tasksAssignmentsResourcesCapacitySlice';

export const Column: React.FC<{
  isCurrentWeek: boolean;
  className?: string;
}> = ({ children, isCurrentWeek = false, className = '', ...otherProps }) => {
  return (
    <div
      className={tailwindOverride(
        'w-1/6 pt-3 px-1',
        'flex-shrink-0 flex-grow flex-col',
        className,
        {
          'bg-secondary-lightest border-l border-r border-secondary-lighter':
            isCurrentWeek,
        }
      )}
      role='group'
      {...otherProps}
    >
      {children}
    </div>
  );
};

const Loader: React.FC<{
  className?: string;
  displayGridLoader?: boolean;
  id?: string;
}> = ({ id, className, displayGridLoader = true }) => {
  return (
    <div
      className={tailwindOverride(
        'w-full h-full flex',
        'absolute top-0 left-0',
        'pl-6 pr-16',
        'z-10',
        className
      )}
    >
      {Array.from(Array(6)).map((_, index: number) => (
        <Column
          key={`${id}__loader-colum-${index}`}
          isCurrentWeek={false}
          className='animate-pulse flex items-center'
        >
          {displayGridLoader && (
            <div className='w-4/5 h-6 bg-primary-lighter rounded-sm' />
          )}
        </Column>
      ))}
    </div>
  );
};

const Timeline: React.FC<{
  id?: string;
  displayActionsMenu?: boolean;
  actionsMenu?: React.ReactNode;
  displayGridLoader?: boolean;
  className?: string;
}> = ({
  id,
  displayActionsMenu = false,
  actionsMenu,
  children,
  displayGridLoader = true,
  className = '',
}) => {
  const { index } = useSelector(selectPagination);
  const capacityTableStatus = useSelector(capacityStatus);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  useEffect(() => {
    if (capacityTableStatus === 'loading' && !isFetching) {
      setIsFetching(true);
    }

    if (capacityTableStatus === 'idle' && isFetching) {
      setTimeout(() => setIsFetching(false), 1000);
    }
  }, [isFetching, capacityTableStatus]);
  return (
    <div className='w-full h-full flex relative'>
      <div className='w-6 h-full' />
      <div className='w-full h-full overflow-hidden'>
        {isFetching && (
          <Loader
            id={id}
            className={className}
            displayGridLoader={displayGridLoader}
          />
        )}
        <div
          className='flex h-full'
          style={{
            transition: 'transform 0.5s ease-in-out',
            transform: `translateX(-${
              index * (100 / CAPACITY_TABLE_NUMBER_OF_WEEKS)
            }%)`,
          }}
        >
          {children}
        </div>
      </div>
      <div className='w-6 h-full' />
      <div className='w-12 h-full shadow-capacity-table-remove-participant px-2 py-3'>
        {displayActionsMenu && actionsMenu}
      </div>
    </div>
  );
};

export default Timeline;
