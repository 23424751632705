import { combineReducers } from '@reduxjs/toolkit';

import allTimeOffEntries from './TimeOffEntries/all/allTimeOffEntriesSlice';
import upcomingTimeOffEntries from './TimeOffEntries/upcoming/upcomingTimeOffEntriesSlice';
import pastTimeOffEntries from './TimeOffEntries/past/pastTimeOffEntriesSlice';
import timeOffTypes from './TimeOffTypes/timeOffTypesSlice';
import timeOffDays from './TimeOffDays/timeOffDaysSlice';
import sidePanel from './SidePanel/sidePanelSlice';

export default combineReducers({
  allTimeOffEntries,
  upcomingTimeOffEntries,
  pastTimeOffEntries,
  timeOffTypes,
  timeOffDays,
  sidePanel,
});
