import { GlobalBanner } from '@getsynapse/design-system';
import classnames from 'classnames';

const UnsavedChangesBanner: React.FC<{
  message: string;
  showEmphasisAnimation?: boolean;
  dataTestId?: string;
}> = ({ message, showEmphasisAnimation = false, dataTestId }) => (
  <GlobalBanner
    variant='warning'
    className={classnames(
      'h-16 items-center mb-4',
      `${showEmphasisAnimation && 'animate-bounceEmphasis'}`
    )}
    iconProps={{
      'data-testid': dataTestId,
    }}
  >
    {() => <div className='pt-1'>{message}</div>}
  </GlobalBanner>
);

export default UnsavedChangesBanner;
