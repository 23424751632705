import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchCurrentBundle,
  fetchTaskTemplatesForBundle,
  resetCurrentBundle,
  selectActiveBundle,
  selectActiveBundleStatus,
  selectActiveBundleTemplates,
} from 'state/ActiveTaskBundle/activeTaskBundleSlice';
import { useAppThunkDispatch } from 'state/store';
import { Status, TaskBundle } from 'utils/customTypes';
import { TaskTemplate } from 'utils/types/taskTemplate';

type UseTaskBundle = (taskBundleId: string) => {
  taskBundle: TaskBundle;
  activeBundleStatus: Status;
  taskTemplates: TaskTemplate[];
};

const useTaskBundle: UseTaskBundle = (taskBundleId) => {
  const dispatch = useAppThunkDispatch();

  const taskBundle = useSelector(selectActiveBundle);
  const activeBundleStatus = useSelector(selectActiveBundleStatus);
  const taskTemplates = useSelector(selectActiveBundleTemplates);

  useEffect(() => {
    const shouldFetchBundle: boolean = Boolean(
      taskBundleId && taskBundleId !== taskBundle.id
    );
    if (shouldFetchBundle) {
      dispatch(fetchCurrentBundle(taskBundleId));
      dispatch(fetchTaskTemplatesForBundle(taskBundleId));
    }

    return () => {
      if (!shouldFetchBundle) {
        dispatch(resetCurrentBundle());
      }
    };
  }, [taskBundle.id, dispatch, taskBundleId]);

  return { taskBundle, activeBundleStatus, taskTemplates };
};

export default useTaskBundle;
