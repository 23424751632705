import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CapacityView } from 'utils/types/capacityView';
import { VIEW_SETTING_VALUES } from 'utils/constants/capacityView';
import { selectDatesRange as selectDailyCapacityDateRange } from 'state/DailyTeamsCapacity/Days/daysSlice';
import { selectWeekItems } from 'state/WeeklyTeamCapacity/Weeks/weeksSlice';

const useCapacityViewState: (view: CapacityView) => string = (view): string => {
  const dailyCapacityDateRange = useSelector(selectDailyCapacityDateRange);
  const weeksTupples = useSelector(selectWeekItems);
  return useMemo(() => {
    if (view !== VIEW_SETTING_VALUES.DAILY) {
      return dailyCapacityDateRange.startDate || '';
    }
    if (weeksTupples.length > 0) {
      return weeksTupples[0][0];
    }
    return '';
  }, [dailyCapacityDateRange, weeksTupples, view]);
};

export default useCapacityViewState;
