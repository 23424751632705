import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import {
  selectBussinessTeams,
  selectProjectCategories,
} from 'state/Organization/organizationSlice';
import { selectLearningTeams } from 'state/LearningTeams/learningTeamsSlice';
import { selectProjectProcesses } from 'state/Processes/processesSlice';
import { selectLDUsersForDropdown } from 'state/UsersManagement/usersManagementSlice';
import {
  PROJECT_BUDGET_SOURCE,
  PROJECT_HEALTH,
  PROJECT_PRIORITY,
  PROJECT_PRIVACY,
  PROJECT_RESOURCING_TYPE,
  PROJECT_STATUS,
} from 'utils/constants';
import { Option, ProjectProcess } from 'utils/customTypes';
import { ProjectFiltersKey, RangeFilter } from 'utils/types/filters';
import moment from 'moment';
import type { LearningTeam } from 'utils/types/learningTeam';

const useProjectFiltersOptions = () => {
  const ldUsers = useSelector(selectLDUsersForDropdown);
  const bussinessTeams = useSelector(selectBussinessTeams);
  const learningTeams = useSelector(selectLearningTeams);
  const projectCategories = useSelector(selectProjectCategories);
  const orgProcesses = useSelector(selectProjectProcesses);

  const statusOptions = useMemo(() => {
    const options = Object.keys(PROJECT_STATUS).map((key) => ({
      label: intl.get(`PROJECT_DETAIL.STATUS_OPTIONS.${key}`),
      value: PROJECT_STATUS[key],
    }));
    return orderBy(options, 'label');
  }, []);

  const priorityOptions = useMemo(() => {
    const options = Object.keys(PROJECT_PRIORITY).map((key) => ({
      label: intl.get(`PROJECT_DETAIL.PRIORITY_OPTIONS.${key}`),
      value: PROJECT_PRIORITY[key],
    }));
    return orderBy(options, 'label');
  }, []);

  const healthOptions = useMemo(() => {
    const options = Object.keys(PROJECT_HEALTH).map((key) => ({
      label: intl.get(`PROJECT_DETAIL.HEALTH_OPTIONS.${key}`),
      value: PROJECT_HEALTH[key],
    }));
    return orderBy(options, 'label');
  }, []);

  const privacyOptions = useMemo(() => {
    const options = Object.keys(PROJECT_PRIVACY).map((key) => ({
      label: intl.get(`PROJECT_DETAIL.PRIVACY_OPTIONS.${key}`),
      value: PROJECT_PRIVACY[key],
    }));
    return orderBy(options, 'label');
  }, []);

  const businessUnitsOptions = useMemo(() => {
    return orderBy(bussinessTeams, (businessUnit) => {
      const businessLabel =
        get(businessUnit, 'label') || get(businessUnit, 'title') || '';
      return businessLabel.toLocaleLowerCase();
    }).map((businessUnit) => ({
      label: get(businessUnit, 'label') || get(businessUnit, 'title'),
      value: get(businessUnit, 'value') || get(businessUnit, 'id'),
    }));
  }, [bussinessTeams]) as Option[];

  const teamOptions = useMemo(
    () =>
      learningTeams.map((team: LearningTeam) => ({
        label: team.name,
        value: team.id,
      })),
    [learningTeams]
  );

  const resourcingTypeOptions = useMemo(() => {
    const options = Object.keys(PROJECT_RESOURCING_TYPE).map((key) => ({
      label: intl.get(`PROJECT_DETAIL.RESOURCING_TYPE_OPTIONS.${key}`),
      value: PROJECT_RESOURCING_TYPE[key],
    }));
    return orderBy(options, 'label');
  }, []);

  const budgetSourceOptions = useMemo(() => {
    const options = Object.keys(PROJECT_BUDGET_SOURCE).map((key) => ({
      label: intl.get(`PROJECT_DETAIL.BUDGET_SOURCE_OPTIONS.${key}`),
      value: PROJECT_BUDGET_SOURCE[key],
    }));
    return orderBy(options, 'label');
  }, []);

  const processOptions = useMemo(() => {
    return orderBy(orgProcesses, (process) => {
      const label = get(process, 'processName') || '';
      return label.toLocaleLowerCase();
    }).map((process) => ({
      label: get(process, 'processName'),
      value: get(process, 'id'),
    }));
  }, [orgProcesses]);

  const stageOptions = useMemo(() => {
    const options = orgProcesses.reduce(
      (stages: Option[], process: ProjectProcess) =>
        stages.concat(
          process.projectStages.map((stage) => ({
            label: stage.stageName,
            value: stage.id,
            process: process.processName,
          }))
        ),
      []
    );
    return orderBy(options, 'label');
  }, [orgProcesses]);

  const projectCategorOptions = useMemo(() => {
    return orderBy(projectCategories, (category) => {
      const label = get(category, 'categoryName') || '';
      return label.toLocaleLowerCase();
    }).map((category) => ({
      label: get(category, 'categoryName'),
      value: get(category, 'id'),
    }));
  }, [projectCategories]);

  const projectFiltersOptions: Record<
    ProjectFiltersKey,
    Option[] | RangeFilter | boolean
  > = useMemo(
    () => ({
      status: statusOptions,
      priority: priorityOptions,
      health: healthOptions,
      privacy: privacyOptions,
      businessTeams: businessUnitsOptions,
      ldteams: teamOptions,
      resourcing_type: resourcingTypeOptions,
      budget_source: budgetSourceOptions,
      process_id: processOptions,
      stage_id: stageOptions,
      category_id: projectCategorOptions,
      owners: ldUsers,
      startDate: { from: '', to: '' },
      targetCompletionDate: { from: '', to: '' },
      actualDate: { from: '', to: '' },
      is_archived: true,
    }),
    [
      statusOptions,
      priorityOptions,
      healthOptions,
      privacyOptions,
      businessUnitsOptions,
      teamOptions,
      resourcingTypeOptions,
      budgetSourceOptions,
      processOptions,
      stageOptions,
      projectCategorOptions,
      ldUsers,
    ]
  );

  const getFilterOptionLabelByKeyAndValue = useCallback(
    (key: ProjectFiltersKey, value: string) => {
      const options = projectFiltersOptions[key] as Option[];
      const option = options.find((option: Option) => option.value === value);
      return option ? option.label : '';
    },
    [projectFiltersOptions]
  );

  const getDateFilterLabelByKey = useCallback(
    (
      key: ProjectFiltersKey,
      value: RangeFilter,
      dateFormat: string,
      customFieldDate?: boolean
    ) => {
      let label = '';
      if (value.from) {
        if (customFieldDate) {
          label = `${moment(value.from).format(dateFormat)}`;
        } else {
          label = `${moment(new Date(value.from)).format(dateFormat)}`;
        }
      }
      if (value.from && value.to) {
        if (customFieldDate) {
          label = `${label} - ${moment(value.to).format(dateFormat)}`;
        } else {
          label = `${label} - ${moment(new Date(value.to)).format(dateFormat)}`;
        }
      } else if (!value.from && value.to) {
        label = `${moment(new Date(value.to)).format(dateFormat)}`;
      }
      return label;
    },
    []
  );

  const getFilterOptionsByKey = useCallback(
    (key: ProjectFiltersKey) => {
      return projectFiltersOptions[key] as Option[];
    },
    [projectFiltersOptions]
  );

  return {
    projectFiltersOptions,
    getFilterOptionLabelByKeyAndValue,
    getFilterOptionsByKey,
    getDateFilterLabelByKey,
  };
};

export default useProjectFiltersOptions;
