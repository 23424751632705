import { Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

const ReceivedRequestsComponent = ({
  receivedRequests,
}: {
  receivedRequests: any;
}) => {
  return (
    <>
      <div className='flex flex-row mt-3'>
        <div className='flex'>
          <p className='flex font-bold text-5xl ml-4 w-1/2'>
            {receivedRequests.requestsRecieved}
          </p>
          <div className='flex flex-col mt-3 ml-1'>
            <Typography variant='label' className='font-semibold'>
              {intl.get('INSIGHTS_PAGE_TABS.RECEIVED_REQUESTS.TITLE')}
            </Typography>
          </div>
        </div>
      </div>
      <div className='flex mt-auto ml-4 pb-2'>
        <Typography
          variant='caption'
          className='font-semibold flex justify-start text-neutral mr-2'
        >
          {Math.round(receivedRequests.mostCommonRequestForm.percent * 100)}%{' '}
          {receivedRequests.mostCommonRequestForm.formTitle}
        </Typography>
        <div className='border-neutral-lighter border-r border-l mr-2' />
        <Typography
          variant='caption'
          className='font-semibold flex justify-start text-neutral ml-2'
        >
          {Math.round(receivedRequests.mostCommonRequestForm.percent * 100)}%{' '}
          {receivedRequests.mostRequestingBusinessUnit.businessTeamTitle}
        </Typography>
      </div>
    </>
  );
};

export default ReceivedRequestsComponent;
