import { Fragment, FC } from 'react';
import intl from 'react-intl-universal';
import useGetUserId from 'Pages/TimeOffListPage/hooks/useGetUserId';
import useAddTimeOff from '../../hooks/useAddTimeOff/useAddTimeOff';
import TimeOffEntryForm from '../TimeOffEntryForm/TimeOffEntryForm';
import PartialHoursAmount from '../PartialHoursAmount/PartialHoursAmount';

const AddTimeOff: FC<{
  shouldSaveChanges: boolean;
  onSaveChanges: (callback: () => void) => void;
  setCanSaveChanges: (canSaveChanges: boolean) => void;
  showNotification: (params: any) => void;
  dailyCapacity: number;
}> = ({
  onSaveChanges,
  shouldSaveChanges,
  setCanSaveChanges,
  showNotification,
  dailyCapacity,
}) => {
  const userId = useGetUserId();

  const {
    timeOffEntry,
    updateTimeOffEntry,
    partialHoursItems,
    totalHours,
    updateTimeOffEntryDays,
    updateValidityForNumericInput,
    updateDateRangeValidity,
  } = useAddTimeOff(
    userId,
    shouldSaveChanges,
    dailyCapacity,
    onSaveChanges,
    setCanSaveChanges,
    showNotification
  );

  return (
    <Fragment>
      <TimeOffEntryForm
        timeOffEntry={timeOffEntry}
        title={intl.get('MANAGE_TIME_OFF.ADD_TIME_OFF')}
        updateTimeOffEntry={updateTimeOffEntry}
        updateDateRangeValidity={updateDateRangeValidity}
      />
      {partialHoursItems.length > 0 && (
        <PartialHoursAmount
          updateValidityForNumericInput={updateValidityForNumericInput}
          partialHoursItems={partialHoursItems}
          totalHours={totalHours}
          updatePartialHours={updateTimeOffEntryDays}
          dailyCapacity={dailyCapacity}
        />
      )}
    </Fragment>
  );
};

export default AddTimeOff;
