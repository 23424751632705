import intl from 'react-intl-universal';
import { Range } from '@getsynapse/design-system';
import get from 'lodash/get';
import { QuestionsProps } from '../Question';
import { useEffect, useState } from 'react';

export const RangeField = ({
  questionData,
  changeQuestionData,
}: QuestionsProps) => {
  const [range, setRange] = useState({
    from: get(questionData, 'data.value.from', 1),
    to: get(questionData, 'data.value.to', 100),
  });
  const changeRange = (value: string, path: string) => {
    const numberValue = Number(value);
    if (Number.isInteger(numberValue)) {
      if (path === 'data.value.from') {
        setRange((prevRange) => ({ ...prevRange, from: numberValue }));
      } else {
        setRange((prevRange) => ({ ...prevRange, to: numberValue }));
      }
      changeQuestionData(path, numberValue, questionData.id);
    }
  };

  useEffect(() => {
    if (get(questionData, 'data.value.from') === undefined) {
      changeQuestionData('data.value.from', 1, questionData.id);
    }
    if (get(questionData, 'data.value.to') === undefined) {
      changeQuestionData('data.value.to', 100, questionData.id);
    }
    if (get(questionData, 'data.value.value') === undefined) {
      changeQuestionData('data.value.value', 1, questionData.id);
    }
  }, [questionData, changeQuestionData]);

  return (
    <div>
      <Range className='mt-10' />
      <div className='flex align-center justify-between relative z-10 h-6'>
        <input
          className='placeholder-neutral-light focus:outline-none text-sm leading-4 text-neutral focus:bg-primary-lighter'
          onChange={(event) =>
            changeRange(event.target.value, 'data.value.from')
          }
          value={range.from}
          placeholder={intl.get(
            'SETTINGS_PAGE.FORMS.DESIGN.CUSTOM_QUESTIONS.RANGE.MIN_VALUE_PLACEHOLDER'
          )}
        />
        <input
          dir='rtl'
          className='placeholder-neutral-light focus:outline-none text-sm leading-4 text-neutral focus:bg-primary-lighter'
          onChange={(event) => changeRange(event.target.value, 'data.value.to')}
          value={range.to}
          placeholder={intl.get(
            'SETTINGS_PAGE.FORMS.DESIGN.CUSTOM_QUESTIONS.RANGE.MAX_VALUE_PLACEHOLDER'
          )}
        />
      </div>
    </div>
  );
};

export default RangeField;
