import React from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { Typography, Button } from '@getsynapse/design-system';
import { selectIsUserLd } from 'state/User/userSlice';
import emptyBoard from 'assets/images/empty-board.svg';
import noSearchResults from 'assets/images/no-projects-found-board.svg';
import noFilterResults from 'assets/images/empty-filtered-projects-board.svg';

const EmptyBoard: React.FC<{
  isSearchEmpty: boolean;
  isFiltersEmpty: boolean;
  handleAddProject: () => void;
}> = ({ isSearchEmpty, isFiltersEmpty, handleAddProject }) => {
  const isLDUser = useSelector(selectIsUserLd);
  let imageSrc = emptyBoard;
  let message = intl.get('PROJECTS_LIST_PAGE.TABLE.EMPTY');
  if (!isSearchEmpty) {
    imageSrc = noSearchResults;
    message = intl.get('PROJECTS_LIST_PAGE.BOARD.NO_RESULTS_FOUND');
  }
  if (!isFiltersEmpty) {
    imageSrc = noFilterResults;
    message = intl.get('PROJECTS_LIST_PAGE.BOARD.NO_RESULTS_FOUND');
  }
  return (
    <div
      data-cy='empty-board'
      className='absolute flex flex-col items-center min-w-50 z-5 top-1/2 left-1/2 -mt-28 -ml-24'
    >
      <img
        src={imageSrc}
        alt={message}
        className={!isSearchEmpty ? '-ml-8' : '-ml-2'}
      />
      <Typography
        variant='body'
        className={classnames('text-neutral-black', {
          'mt-6': !isSearchEmpty || !isFiltersEmpty,
        })}
      >
        {message}
      </Typography>
      {isSearchEmpty && isFiltersEmpty && isLDUser && (
        <Button
          className='mt-2 mx-auto'
          onClick={handleAddProject}
          data-cy='get-started-button'
        >
          {intl.get('PROJECTS_LIST_PAGE.TABLE.GET_STARTED')}
        </Button>
      )}
    </div>
  );
};

export default EmptyBoard;
