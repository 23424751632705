import { FC, useEffect, useCallback, useMemo } from 'react';
import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import useModal from 'Hooks/useModal';
import useTaskForm from './hooks/useTaskForm';
import TaskFormFields from './TaskFormFields/TaskFormFields';
import { NewTask } from 'types/store/newTask';

interface TaskModalFormProps {
  shouldOpenModal: boolean;
  onCloseModal: () => void;
  title: string;
  saveButtonLabel: string;
  formPrompt: string;
  onSaveTask: (newTask: NewTask) => void;
  displayProjectPicker?: boolean;
  orginalTaskData?: NewTask;
}

const TaskModalForm: FC<TaskModalFormProps> = ({
  shouldOpenModal,
  onCloseModal,
  title,
  saveButtonLabel,
  formPrompt,
  onSaveTask = () => {},
  displayProjectPicker = true,
  orginalTaskData = {} as NewTask,
}) => {
  const { Modal, modalProps, openModal, closeModal } = useModal();

  const { taskData, updateTaskData, canSaveTask } = useTaskForm({
    orginalTaskData,
  });

  useEffect(() => {
    if (shouldOpenModal && !modalProps.isOpen) {
      openModal({ ...modalProps });
    }
  }, [shouldOpenModal, modalProps, openModal]);

  const handleCloseModal = useCallback(() => {
    onCloseModal();
    closeModal();
  }, [closeModal, onCloseModal]);

  const handleSaveTask = useCallback(() => {
    onSaveTask(taskData);
    handleCloseModal();
  }, [taskData, handleCloseModal, onSaveTask]);

  const actionButtons = useMemo(
    () => [
      {
        children: saveButtonLabel,
        variant: 'primary',
        'data-cy': 'confirm-button',
        'data-testid': 'confirm-button',
        disabled: !canSaveTask,
        onClick: handleSaveTask,
      },
      {
        children: intl.get('CANCEL'),
        variant: 'tertiary',
        onClick: handleCloseModal,
        'data-cy': 'cancel-button',
        'data-testid': 'cancel-button',
      },
    ],
    [handleCloseModal, saveButtonLabel, handleSaveTask, canSaveTask]
  );

  return (
    <Modal
      {...modalProps}
      aria-label={title}
      closeModal={handleCloseModal}
      title={title}
      size='large'
      className='h-full'
      actionButtons={actionButtons}
    >
      <div className='flex flex-col'>
        <Typography>{formPrompt}</Typography>
        <TaskFormFields
          taskData={taskData}
          updateTaskData={updateTaskData}
          displayProjectPicker={displayProjectPicker}
        />
      </div>
    </Modal>
  );
};

export default TaskModalForm;
