import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { NewBusinessTeamData } from 'utils/customTypes';
import type {
  BusinessTeamResponse,
  BusinessTeamsResponse,
} from 'utils/types/businessTeams';
import type { BusinessTeam } from 'utils/types/businessTeams';
import type { ApiResponse } from 'utils/types/api';

class BusinessTeamsAPI {
  instance: AxiosInstance;

  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchBusinessTeams = async () => {
    const { data } = await this.instance.get<
      ApiResponse<BusinessTeamsResponse>
    >('businessTeam/');
    return data;
  };

  postBusinessTeam = async (newBusinessTeamData: NewBusinessTeamData) => {
    const { data } = await this.instance.post<
      ApiResponse<{
        businessTeam: Exclude<BusinessTeam, 'user' | 'request'>;
      }>
    >('businessTeam/', newBusinessTeamData);
    return data;
  };

  getBusinessTeam = async (id: string) => {
    const { data } = await this.instance.get<ApiResponse<BusinessTeamResponse>>(
      `businessTeam/${id}`
    );
    return data;
  };

  updateTeam = async (teamId: string, updateFields: Partial<BusinessTeam>) => {
    const { data } = await this.instance.put<ApiResponse<BusinessTeamResponse>>(
      `businessTeam/${teamId}`,
      {
        updateFields,
      }
    );
    return data;
  };

  deleteTeam = async (teamId: string) => {
    const { data } = await this.instance.delete<
      ApiResponse<{ businessTeam: number }>
    >(`businessTeam/${teamId}`);
    return data;
  };

  fetchBusinessTeamsForDropdown = async () => {
    const { data } = await this.instance.get('/v2/teams/business?limit=1000');
    return data;
  };
}

const businessTeamsAPI = new BusinessTeamsAPI();

export default businessTeamsAPI;
