import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SLICE_STATUS } from 'utils/constants';
import { Status } from 'utils/customTypes';
import { ObjectiveMeasurementType } from 'utils/types/program';
import objectiveMeasurementAPI, {
  CreateObjectiveMeasurementBody,
  UpdateObjectiveMeasurementBody,
} from './objectiveMeasurementAPI';
import { RootState } from 'state/store';
import { selectMeasurementScalebyId } from 'state/MeasurementScales/measurementScalesSlice';

interface ObjectiveMeasurementState {
  status: Status;
  value?: ObjectiveMeasurementType;
}

/* ============================= INITIAL STATE ============================== */
const initialState: ObjectiveMeasurementState = {
  status: SLICE_STATUS.IDLE,
};

/* ============================== REDUX THUNK =============================== */
export const fetchObjectiveMeasurement = createAsyncThunk(
  'objectiveMeasurement/fetchObjectiveMeasurement',
  async ({
    programId,
    categoryId,
    objectiveId,
  }: {
    programId: string;
    categoryId: string;
    objectiveId: string;
  }) => {
    const response = await objectiveMeasurementAPI.fetchObjectiveMeasurement(
      programId,
      categoryId,
      objectiveId
    );
    return response;
  }
);

interface CreateObjectiveMeasurementArgs {
  programId: string;
  categoryId: string;
  objectiveId: string;
  objectiveMeasurement: {
    objectiveMeasurementScaleId: string;
    score: number | null;
  };
}
export const createObjectiveMeasurement = createAsyncThunk(
  'objectiveMeasurement/createObjectiveMeasurement',
  async (payload: CreateObjectiveMeasurementArgs, { getState }) => {
    const state = getState() as RootState;
    const measurementScale = selectMeasurementScalebyId(
      state,
      payload.objectiveMeasurement.objectiveMeasurementScaleId
    );
    if (!measurementScale) {
      throw new Error('Measurement Scale not found');
    }

    const objectiveMeasurement: CreateObjectiveMeasurementBody = {
      programObjectiveId: payload.objectiveId,
      objectiveMeasurementScaleId:
        payload.objectiveMeasurement.objectiveMeasurementScaleId,
      percentageScore:
        measurementScale.scale === 'PERCENTAGE'
          ? payload.objectiveMeasurement.score
          : undefined,
      fivePointScore:
        measurementScale.scale === 'FIVE_POINT'
          ? payload.objectiveMeasurement.score
          : undefined,
      tenPointScore:
        measurementScale.scale === 'TEN_POINT'
          ? payload.objectiveMeasurement.score
          : undefined,
    };

    const response = await objectiveMeasurementAPI.createObjectiveMeasurement(
      payload.programId,
      payload.categoryId,
      payload.objectiveId,
      objectiveMeasurement
    );

    return response;
  }
);

interface UpdateObjectiveMeasurementArgs {
  programId: string;
  categoryId: string;
  objectiveId: string;
  measurementId: string;
  objectiveMeasurement: {
    objectiveMeasurementScaleId: string;
    score: number | null;
  };
}
export const updateObjectiveMeasurement = createAsyncThunk(
  'objectiveMeasurement/updateObjectiveMeasurement',
  async (payload: UpdateObjectiveMeasurementArgs, { getState }) => {
    const state = getState() as RootState;
    const measurementScale = selectMeasurementScalebyId(
      state,
      payload.objectiveMeasurement.objectiveMeasurementScaleId
    );
    if (!measurementScale) {
      throw new Error('Measurement Scale not found');
    }

    const objectiveMeasurement: UpdateObjectiveMeasurementBody = {
      id: payload.measurementId,
      objectiveMeasurementScaleId:
        payload.objectiveMeasurement.objectiveMeasurementScaleId,
      percentageScore:
        measurementScale.scale === 'PERCENTAGE'
          ? payload.objectiveMeasurement.score
          : undefined,
      fivePointScore:
        measurementScale.scale === 'FIVE_POINT'
          ? payload.objectiveMeasurement.score
          : undefined,
      tenPointScore:
        measurementScale.scale === 'TEN_POINT'
          ? payload.objectiveMeasurement.score
          : undefined,
    };

    const response = await objectiveMeasurementAPI.updateObjectiveMeasurement(
      payload.programId,
      payload.categoryId,
      payload.objectiveId,
      payload.measurementId,
      objectiveMeasurement
    );

    return response;
  }
);

/* ================================= REDUCER ================================ */
const objectiveMeasurementSlice = createSlice({
  name: 'objectiveMeasurement',
  initialState,
  reducers: {
    RESET_OBJECTIVE_MEASUREMENT: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchObjectiveMeasurement.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchObjectiveMeasurement.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        state.value = action.payload;
      })
      .addCase(fetchObjectiveMeasurement.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(createObjectiveMeasurement.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(createObjectiveMeasurement.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        state.value = action.payload;
      })
      .addCase(createObjectiveMeasurement.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
        throw new Error();
      })
      .addCase(updateObjectiveMeasurement.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(updateObjectiveMeasurement.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        state.value = action.payload;
      })
      .addCase(updateObjectiveMeasurement.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
        throw new Error();
      });
  },
});

/* ================================ ACTIONS ================================= */
export const { RESET_OBJECTIVE_MEASUREMENT: resetObjectiveMeasurement } =
  objectiveMeasurementSlice.actions;

/* =============================== SELECTORS ================================ */
export const selectObjectiveMeasurement = (state: RootState) =>
  state.objectiveMeasurement.value;

export const selectObjectiveMeasurementStatus = (state: RootState) =>
  state.objectiveMeasurement.status;

export default objectiveMeasurementSlice.reducer;
