import { useMemo } from 'react';
import { Typography } from '@getsynapse/design-system';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import FieldsTable from './FieldsTable';
import ServerPagination from 'Organisms/Pagination/ServerPagination';

import {
  updatePagination,
  selectTotalCount,
  selectFieldsTablePagination,
} from 'state/CustomFields/customFieldsSlice';

const Fields = () => {
  const dispatch = useDispatch();
  const total = useSelector(selectTotalCount);
  const pagination = useSelector(selectFieldsTablePagination);

  const onPaginationChange = (params: object) => {
    dispatch(updatePagination(params));
  };

  const page = useMemo<number>(
    () => pagination.offset / pagination.limit + 1,
    [pagination]
  );

  return (
    <div>
      <Typography className='text-neutral pb-2'>
        {intl.get('SETTINGS_PAGE.FIELDS_PAGE.PAGE_SUB_TITLE')}
      </Typography>
      <FieldsTable />
      <ServerPagination
        total={total}
        onChange={onPaginationChange}
        page={page}
      />
    </div>
  );
};

export default Fields;
