import { useState, useMemo, useCallback, useEffect } from 'react';
import { Typography, useElevation } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import PageSlider from 'Molecules/PageSlider';
import { DEFAULT_PAGE_SIZE } from 'utils/constants/ui';
import { PageSliderOption } from 'utils/types/pagination';

type PaginationProps = {
  total: number;
  onChange: (params: object) => void;
  className?: string;
  page?: number;
};

const ResultsRange: React.FC<{
  limit: number;
  total: number;
  offset: number;
}> = ({ limit, total, offset }) => {
  const rangeStart = offset + 1;
  const rangeEnd = limit < total ? limit : total;
  return (
    <div className='h-full flex items-center'>
      <Typography
        variant='body'
        data-cy={`total_results-${total}`}
        className='text-neutral-black'
      >
        {intl.get('PAGINATION.RESULTS', {
          range:
            rangeEnd > 0 && rangeEnd > rangeStart
              ? `${rangeStart}-${rangeEnd}`
              : rangeEnd,
          total: total,
        })}
      </Typography>
    </div>
  );
};

const ServerPagination = ({
  total,
  onChange,
  className,
  page,
}: PaginationProps) => {
  const skim = useElevation(1);
  const [currentPage, setCurrentPage] = useState<PageSliderOption>({
    value: 1,
    label: '1',
  });

  useEffect(() => {
    if (page) {
      setCurrentPage({ value: page, label: page.toString() });
    }
  }, [page]);

  const pageCount = useMemo<number>(
    () => Math.ceil(total / DEFAULT_PAGE_SIZE),
    [total]
  );

  const handleChangePage = useCallback(
    (value: number) => {
      setCurrentPage({ value, label: value.toString() });
      onChange({
        limit: DEFAULT_PAGE_SIZE,
        offset: DEFAULT_PAGE_SIZE * (value - 1),
      });
    },
    [onChange]
  );

  return (
    <div
      data-cy='pagination-container'
      className={classnames(
        'absolute bottom-0 left-0 py-3 z-10',
        'px-6 bg-neutral-white w-full',
        'flex items-center justify-between',
        skim,
        className
      )}
    >
      <ResultsRange
        total={total}
        limit={DEFAULT_PAGE_SIZE * currentPage.value}
        offset={DEFAULT_PAGE_SIZE * (currentPage.value - 1)}
      />
      <div className='flex h-full items-center'>
        <PageSlider
          total={total}
          pages={pageCount}
          offset={currentPage.value}
          limit={DEFAULT_PAGE_SIZE}
          setOffset={handleChangePage}
        />
      </div>
    </div>
  );
};

export default ServerPagination;
