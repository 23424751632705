import { Typography } from '@getsynapse/design-system';
import { useState } from 'react';
import { OTHERS_TEXT } from './constants';
import { CHART_COLOR_PALETTE } from 'Pages/InsightsPage/chartConstants';

const TooltipRow = ({ data }: { data: any }) => (
  <>
    {data.map((item: any, index: number) => (
      <div
        key={`${item.declinationReason}-${index}`}
        className='flex flex-col mb-1'
      >
        <Typography variant='label' className='text-neutral-dark'>
          {`${item.declinationReason} : ${item.requestCount}`}
        </Typography>
      </div>
    ))}
  </>
);

const showToolTip = ({
  reasons,
  setVisible,
}: {
  reasons: any;
  setVisible: any;
}) => {
  return reasons && reasons.length > 0 ? (
    <div
      style={{
        overflowY: 'scroll',
        zIndex: 1,
        position: 'relative',
        right: '-10%',
        bottom: '72%',
      }}
      className='h-32 w-5-percent flex flex-col bg-neutral-white px-2 py-2 border border-neutral-lighter-two shadow-allocation-table rounded-sm'
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <div className='flex flex-col mb-1'>
        <Typography
          variant='label'
          className=' text-neutral-dark font-semibold'
        >
          Other Reasons
        </Typography>
      </div>
      <TooltipRow data={reasons} />
    </div>
  ) : null;
};

const Legend = ({
  topThreeReasons,
  otherReasons,
}: {
  topThreeReasons: any;
  otherReasons: any;
}) => {
  const [visible, setVisible] = useState(false);
  const reasonsToDisplay =
    otherReasons.length > 0
      ? [
          ...topThreeReasons,
          { declinationReason: OTHERS_TEXT, requestCount: 0 }, // this requestCOunt is not used anywhere, just required to maintain object structure
        ]
      : topThreeReasons;

  return (
    <div>
      {reasonsToDisplay.map((item: any, index: number) => {
        return (
          <div
            className='flex flex-row'
            key={index}
            onMouseLeave={() => setVisible(false)}
            onMouseEnter={() =>
              item.declinationReason === OTHERS_TEXT && setVisible(true)
            }
          >
            <div
              className='w-2 h-2 rounded-full ml-2 mt-1'
              style={{
                backgroundColor:
                  CHART_COLOR_PALETTE[index % CHART_COLOR_PALETTE.length],
              }}
            ></div>
            <Typography variant='caption' className='ml-2'>
              {item.declinationReason}
            </Typography>
          </div>
        );
      })}
      {visible &&
        showToolTip({ reasons: otherReasons, setVisible: setVisible })}
    </div>
  );
};

export default Legend;
