import { SidePanelFilter } from '@getsynapse/design-system';
import { UserFilters } from 'utils/types/filters';
import FiltersForm from './FiltersForm';
import useSidePanelFilters from 'Hooks/useSidePanelFilters';

interface UsersSidePanelFiltersProps {
  open: boolean;
  onUpdateFilters: (filters: UserFilters) => void;
  onClose: () => void;
  savedFilters: UserFilters;
}

const UsersSidePanelFilters: React.FC<UsersSidePanelFiltersProps> = ({
  open,
  onUpdateFilters,
  onClose,
  savedFilters,
}) => {
  const {
    appliedFilters,
    canApplyFilters,
    handleUpdateFilters,
    handleApplyFilters,
    handleClearAllFilters,
    handleClosePanel,
  } = useSidePanelFilters<UserFilters>(savedFilters, onUpdateFilters, onClose);

  return (
    <SidePanelFilter
      open={open}
      onClose={handleClosePanel}
      appliedFiltersCount={Object.keys(appliedFilters).length}
      canApplyFilters={canApplyFilters}
      onClearAllFilters={handleClearAllFilters}
      onApplyFilters={handleApplyFilters}
      data-testid='users-filters-side-panel'
    >
      <FiltersForm
        appliedFilters={appliedFilters}
        onUpdateFilters={handleUpdateFilters}
      />
    </SidePanelFilter>
  );
};

export default UsersSidePanelFilters;
