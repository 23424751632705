import intl from 'react-intl-universal';
import { CHANGED_TASK_TYPE, TASK_TYPES, TASK_TYPE_CATEGORY } from './constants';

export const getTaskType = (taskType: string, category: string) => {
  const targetType = Object.entries(TASK_TYPES).find(
    (entry) => entry[1] === taskType || entry[0] === taskType
  );

  if (category === TASK_TYPE_CATEGORY.TABLE) {
    if (taskType === CHANGED_TASK_TYPE.LMS) {
      return intl.get(`TASKS.TASK_TYPES.LMS_DEPLOYMENT`);
    }
    if (taskType === CHANGED_TASK_TYPE.DESIGN) {
      return intl.get(`TASKS.TASK_TYPES.INSTRUCTIONAL_DESIGN`);
    }
    if (taskType === CHANGED_TASK_TYPE.OTHER) {
      return intl.get(`TASKS.TASK_TYPES.OTHERS`);
    }
    if (targetType) {
      return intl.get(`TASKS.TASK_TYPES.${targetType[0]}`);
    }
  } else if (category === TASK_TYPE_CATEGORY.DETAIL) {
    if (taskType === CHANGED_TASK_TYPE.LMS) {
      return 'LMS_DEPLOYMENT';
    }
    if (taskType === CHANGED_TASK_TYPE.DESIGN) {
      return 'INSTRUCTIONAL_DESIGN';
    }
    if (taskType === CHANGED_TASK_TYPE.OTHER) {
      return 'OTHERS';
    }
    if (targetType) {
      return targetType[0];
    }
  }
  return '';
};
