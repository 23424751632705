import { ComponentProps, useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import { Dropdown, FormItem } from '@getsynapse/design-system';
import { ProjectProcess, SortingType } from 'utils/customTypes';
import { getInitialValueForDropDown } from 'utils/functions';

type Props = {
  formItemProps: ComponentProps<typeof FormItem>;
  processes: ProjectProcess[];
  sortOrder?: SortingType;
  dropdownProps: Partial<ComponentProps<typeof Dropdown>>;
  currentValue: string;
};

const ProcessPicker = ({
  formItemProps,
  dropdownProps,
  currentValue,
  sortOrder = 'asc',
  processes = [],
}: Props) => {
  const formatOptions = useMemo(() => {
    return orderBy(
      processes,
      (process) => {
        const label = get(process, 'processName') || '';
        return label.toLocaleLowerCase();
      },
      sortOrder
    ).map((process) => ({
      label: get(process, 'processName'),
      value: get(process, 'id'),
    }));
  }, [processes, sortOrder]);

  const {
    onChange = () => {},
    multiple,
    ...otherDropdownProps
  } = dropdownProps;
  return (
    <FormItem {...formItemProps}>
      <Dropdown
        multiple={multiple}
        onChange={onChange}
        options={formatOptions}
        values={getInitialValueForDropDown(formatOptions, currentValue)}
        {...otherDropdownProps}
      />
    </FormItem>
  );
};

export default ProcessPicker;
