import {
  FormItem,
  Icon,
  tailwindOverride,
  TextField,
  Tooltip,
  Typography,
} from '@getsynapse/design-system';
import { TASK_STATUS } from 'utils/constants';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import {
  selectTaskActualHoursSum,
  selectTaskStartDate,
  selectTaskStatus,
} from 'state/TaskDetail/taskSlice';

interface TaskActualHoursFieldProps {
  isOnSidepanel?: boolean;
  setOpenModal: (open: boolean) => void;
}

const TaskActualHoursField = ({
  isOnSidepanel = false,
  setOpenModal,
}: TaskActualHoursFieldProps) => {
  const status = useSelector(selectTaskStatus);

  const canAddActualHours = status !== TASK_STATUS.COMPLETED;
  const taskStatus = useSelector(selectTaskStatus);
  const taskActualHoursSum = useSelector(selectTaskActualHoursSum);
  const startDate = new Date(useSelector(selectTaskStartDate));
  const actualHoursLastUpdate = new Date(); // TODO: This should come from the state fter an API call

  const actualHoursButton = (
    <button
      className={tailwindOverride(
        'text-purple-dark hover:text-purple-darker focus-visible:text-purple-darker active:text-purple-darker py-1 focus:outline-none text-sm leading-6 focus:ring-0',
        'disabled:text-primary-lighter',
        {
          'text-primary-lighter hover:text-primary-lighter focus-visible:text-primary-lighter active:text-primary-lighter':
            taskStatus === TASK_STATUS.NEW,
        }
      )}
      onClick={() => {
        if (taskStatus === TASK_STATUS.NEW) return;
        setOpenModal(true);
        // isOnSidepanel
        //   ? setShowActualHoursLog!(true)
        //   : setIsActualHoursModalOpen(true);
      }}
      data-testid='add-actual-hours-button'
      disabled={startDate > new Date()}
    >
      <div className='flex items-center'>
        <Icon
          name={canAddActualHours ? 'add-circle' : 'eye'}
          className='mr-2 text-xl'
        />
        {canAddActualHours
          ? intl.get('TASKS.TASK_DETAIL_PAGE.ADD_ACTUAL_HOURS')
          : intl.get('TASKS.TASK_DETAIL_PAGE.VIEW_ACTUAL_HOURS')}
      </div>
    </button>
  );

  return (
    <div>
      <FormItem
        component='div'
        label={
          <div className='flex'>
            <Typography className='text-label leading-4 font-semibold mr-1'>
              {intl.get('TASKS.TASK_DETAIL_PAGE.ACTUAL_HOURS')}
            </Typography>
            {
              <Typography className='text-label leading-4 text-neutral-dark'>
                {intl.get('TASKS.TASK_DETAIL_PAGE.ACTUAL_HOURS_LAST_UPDATE', {
                  date: actualHoursLastUpdate,
                })}
              </Typography>
            }
          </div>
        }
        labelProps={{ htmlFor: 'actual-hours-field' }}
        className={tailwindOverride({ 'mt-4': isOnSidepanel })}
      >
        <TextField
          id='actual-hours-field'
          value={`${taskActualHoursSum}h`}
          data-cy='task-actual-hours'
          divProps={{ className: 'flex-1' }}
          readOnly
        />
      </FormItem>
      {taskStatus === TASK_STATUS.NEW ? (
        <Tooltip
          trigger={actualHoursButton}
          openMode='hover1'
          hasMaxWidth={false}
          timeout={0}
          contentProps={{
            'data-testid': 'actual-hours-tooltip',
          }}
        >
          {intl.get('TASKS.TASK_DETAIL_PAGE.ACTUAL_HOURS_TOOLTIP')}
        </Tooltip>
      ) : (
        actualHoursButton
      )}
    </div>
  );
};

export default TaskActualHoursField;
