import axios, { AxiosInstance } from 'axios';
import get from 'lodash/get';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { FetchDailyTeamsCapacityApiResponse } from 'utils/types/dailyTeamsCapacity';

class DailyTeamsCapacityAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchDailyTeamsCapacity: (
    url: string
  ) => Promise<FetchDailyTeamsCapacityApiResponse> = async (url) => {
    const response =
      await this.instance.get<FetchDailyTeamsCapacityApiResponse>(url);
    if (axios.isAxiosError(response)) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
    return response.data;
  };
}

const dailyTeamsCapacityAPI = new DailyTeamsCapacityAPI();
export default dailyTeamsCapacityAPI;
