import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TableOperationHeader } from '@getsynapse/design-system';
import {
  setSearchParam,
  exportUsersStatus,
  setFilters,
} from 'state/UsersManagement/usersManagementSlice';
import { isUserOrganizationAdmin } from 'state/User/userSlice';
import { SLICE_STATUS } from 'utils/constants';
import useImportLDUsers from './hooks/useImportLDUsers';
import useImportFacilitatorUsers from './hooks/useImportFacilitatorUsers';
import useImportBusinessUsers from './hooks/useImportBusinessUsers';
import MoreActionsPopup, {
  OptionProps,
} from 'Organisms/TableHeaderActionButtons/MoreActionsPopup';
import ExportIcon from 'assets/icons/export.svg';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';
import UsersSidePanelFilters from './UsersSidePanelFilters/UsersSidePanelFilters';
import { UserFilters } from 'utils/types/filters';
import AppliedFiltersTags from 'Organisms/AppliedFiltersTags/AppliedFiltersTags';
import useUserFilters from './hooks/useUserFilters';

interface UsersTableHeaderProps {
  isFilterButtonDisabled: boolean;
  isExportButtonDisabled: boolean;
  handleExport: (callback: () => void) => void;
  savedFilters: UserFilters;
  saveFilters: (updatedFilters: UserFilters) => void;
  toggleFilterComponent: () => void;
  showFilterComponent: boolean;
}

const UsersTableHeader: React.FC<UsersTableHeaderProps> = ({
  isFilterButtonDisabled = true,
  isExportButtonDisabled = true,
  handleExport,
  savedFilters,
  saveFilters,
  toggleFilterComponent,
  showFilterComponent,
}) => {
  const dispatch = useDispatch();
  const { getOptionsbyKey } = useUserFilters(savedFilters);
  const isAdmin = useSelector(isUserOrganizationAdmin);
  const exportStatus = useSelector(exportUsersStatus);
  const importLDUsersButtonProps = useImportLDUsers();
  const importFacilitatorUsersButtonProps = useImportFacilitatorUsers();
  const importBusinessUsersButtonProps = useImportBusinessUsers();
  const [searchParamValue, setSearchParamValue] = useState('');
  const { facilitatorManagement = false } = useFlags();

  const handleSearch = (value: string) => {
    setSearchParamValue(value);
    dispatch(setSearchParam(value));
  };

  const handleUpdateFilters = (updatedFilters: UserFilters) => {
    dispatch(setFilters(updatedFilters));
    saveFilters(updatedFilters);
  };

  const moreActionsList = [
    ...(isAdmin
      ? [
          { label: intl.get('IMPORT'), isSectionTitle: true },
          {
            label: intl.get('USERS_PAGE.TABLE.IMPORT.IMPORT_LEARNING_USERS'),
            isImportButton: true,
            disabled:
              isEmpty(importLDUsersButtonProps.currentUser) ||
              isEmpty(importLDUsersButtonProps.settings),
            importButtonProps: importLDUsersButtonProps,
          },
          facilitatorManagement && {
            label: intl.get('USERS_PAGE.TABLE.IMPORT.IMPORT_FACILITATOR_USERS'),
            isImportButton: true,
            disabled:
              isEmpty(importFacilitatorUsersButtonProps.currentUser) ||
              isEmpty(importFacilitatorUsersButtonProps.settings),
            importButtonProps: importFacilitatorUsersButtonProps,
          },
          importBusinessUsersButtonProps.isDataReady && {
            label: intl.get('USERS_PAGE.TABLE.IMPORT.IMPORT_BUSINESS_USERS'),
            isImportButton: true,
            disabled:
              isEmpty(importBusinessUsersButtonProps.currentUser) ||
              isEmpty(importBusinessUsersButtonProps.settings),
            importButtonProps: importBusinessUsersButtonProps,
          },
        ]
      : []),
    { label: intl.get('EXPORT'), isSectionTitle: true },
    {
      label: intl.get('USERS_PAGE.TABLE.EXPORT.EXPORT_USERS'),
      selectOption: handleExport,
      disabled: isExportButtonDisabled,
      tooltip: {
        text: isExportButtonDisabled
          ? intl.get('USERS_PAGE.TABLE.EXPORT.EXPORT_TOOLTIP')
          : '',
      },
      iconSrc: ExportIcon,
      dataCy: 'export-users-button',
      loading: exportStatus === SLICE_STATUS.LOADING,
    },
  ] as OptionProps[];

  return (
    <>
      <UsersSidePanelFilters
        savedFilters={savedFilters}
        onClose={toggleFilterComponent}
        open={showFilterComponent}
        onUpdateFilters={handleUpdateFilters}
      />
      <TableOperationHeader
        className='border-0 rounded-b-none bg-neutral-white'
        inputValue={searchParamValue}
        setInputValue={handleSearch}
        actionButtons={
          <>
            <FilterButton
              aria-pressed={showFilterComponent}
              disabled={isFilterButtonDisabled}
              onToggleFilter={toggleFilterComponent}
            />
            <MoreActionsPopup options={moreActionsList} />
          </>
        }
      />
      <AppliedFiltersTags<UserFilters>
        savedFilters={savedFilters}
        onUpdateFilters={handleUpdateFilters}
        onDisplayAllFilters={toggleFilterComponent}
        getOptionsbyKey={getOptionsbyKey}
        dataTestid='applied-programs-filters-tags'
      />
    </>
  );
};

export default UsersTableHeader;
