import { ChangeEvent } from 'react';
import intl from 'react-intl-universal';
import {
  FormItem,
  RadioGroup,
  LargeLabelInput,
} from '@getsynapse/design-system';

interface Props {
  isObjectiveMet: 'true' | 'false' | '';
  objectiveMetOptions: { label: string; value: 'true' | 'false' }[];
  onSetObjectiveMet: (event: ChangeEvent<HTMLInputElement>) => void;
  notMetReason: string;
  setObjectiveNotMetReason: (reason: string) => void;
}

const ObjectiveMetInput = ({
  isObjectiveMet,
  objectiveMetOptions,
  onSetObjectiveMet,
  notMetReason,
  setObjectiveNotMetReason,
}: Props) => {
  return (
    <>
      <FormItem
        component='fieldset'
        label={intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.WAS_OBJECTIVE_MET'
        )}
        labelProps={{
          className: 'text-base leading-5 tracking-wider pb-2 pt-6',
        }}
      >
        <RadioGroup
          className='w-max'
          checkOption='checked'
          checked={isObjectiveMet}
          name='objective-is-met'
          options={objectiveMetOptions}
          inputProps={{
            onChange: onSetObjectiveMet,
            'data-testid': 'category-objective_is-met-input',
          }}
        />
      </FormItem>
      {isObjectiveMet === 'false' && (
        <LargeLabelInput
          className='mt-6 w-60-percent-plus-64-px'
          value={notMetReason}
          onChange={(event) => setObjectiveNotMetReason(event.target.value)}
          name='objective-not-met-reason'
          label={intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE_NOT_MET_REASON_LABEL'
          )}
          labelProps={{
            className: 'text-base mb-0.5',
          }}
          subtitle={intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE_NOT_MET_REASON_SUBTITLE'
          )}
          subtitleProps={{ className: 'text-neutral-darker' }}
          textAreaProps={{
            placeholder: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE_NOT_MET_REASON_PLACEHOLDER'
            ),
            className: 'mt-0 placeholder-text-neutral',
            'data-testid': 'not-met-reason-input',
          }}
        />
      )}
    </>
  );
};

export default ObjectiveMetInput;
