import {
  Button,
  Checkbox,
  Dropdown,
  FormItem,
  IconButton,
  Typography,
  tailwindOverride,
} from '@getsynapse/design-system';
import { useMemo, useState, Dispatch, SetStateAction } from 'react';
import intl from 'react-intl-universal';
import { useDispatch } from 'react-redux';
import {
  INSIGHT_REQUEST_FILTER_PANEL_DATE_OPTIONS as DATE_OPTIONS,
  SLICE_STATUS,
  INSIGHTS_DATE_FILTER_NAME,
} from 'utils/constants';
import FilterTags from './FilterTags';
import { FiltersPanelButtonsSkeletonLoader } from 'Pages/InsightsPage/Intake/FiltersPanel/FiltersPanelSkeletonLoader';
import filterPanelIcon from 'assets/icons/filter-icon-insight.svg';
import collapseIcon from 'assets/icons/collapse.svg';
import expandIcon from 'assets/icons/expand.svg';
import classnames from 'classnames';
import {
  BusinessUnitFilterData,
  ProcessFilterData,
  InsightsProjectActiveFilter,
} from 'utils/customTypes';
import { get } from 'lodash';
import moment from 'moment';
import { setActiveFilters } from 'state/Insights/ProjectsSlice';

type Option = {
  label: string;
  value: string;
};

const FilterSections = ({
  status,
  setIsExpanded,
  isExpanded,
  filters,
}: {
  status: string | undefined;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
  isExpanded: boolean;
  filters: any;
}) => {
  const processes = filters.processNameFilterValues.filter(
    (process: ProcessFilterData) => {
      return Boolean(process.processName);
    }
  );
  const projectBusinessUnits = filters.businessUnitFilterValues.filter(
    (businessUnit: BusinessUnitFilterData) => {
      return Boolean(businessUnit.businessUnitName);
    }
  );
  const priorityTypesList = useMemo(() => {
    return filters.projectPriorityFilterValues.map((priority: any) => ({
      value: priority.projectPriority,
      label: intl.get(
        `PROJECT_DETAIL.PRIORITY_OPTIONS.${priority.projectPriority}`
      ),
    }));
  }, [filters.projectPriorityFilterValues]);

  const dispatch = useDispatch();

  const [isPrioritySectionOpen, setIsPrioritySectionOpen] = useState(true);
  const [isBusinessUnitSectionOpen, setIsBusinessUnitSectionOpen] =
    useState(true);
  const [isProcessSectionOpen, setIsProcessSectionOpen] = useState(true);

  const [businessUnits, setBusinessUnits] =
    useState<BusinessUnitFilterData[]>(projectBusinessUnits);

  const [processData, setProcessData] =
    useState<ProcessFilterData[]>(processes);

  const defaultPriorityTypes = useMemo(() => {
    return priorityTypesList.map((priority: any) => ({
      value: priority.value,
      label: intl.get(
        `PROJECT_DETAIL.PRIORITY_OPTIONS.${priority.value.toUpperCase()}`
      ),
    }));
  }, [priorityTypesList]);

  const [priorityTypes, setPriorityTypes] = useState<any>(defaultPriorityTypes);

  const dateOptions = useMemo(
    () =>
      Object.values(DATE_OPTIONS).map((option) => ({
        value: option.key,
        label: intl.get(`INSIGHTS_PAGE_TABS.FILTER.DATE.${option.key}`),
      })),
    []
  );

  const defaultDateOption = useMemo(() => {
    const savedDateSelection = window.localStorage.getItem(
      INSIGHTS_DATE_FILTER_NAME.PROJECT
    );
    const fallBackOption = DATE_OPTIONS.ALL;
    const defaultDate = get(
      DATE_OPTIONS,
      savedDateSelection ? savedDateSelection : fallBackOption.key,
      fallBackOption
    );

    return {
      label: intl.get(`INSIGHTS_PAGE_TABS.FILTER.DATE.${defaultDate.key}`),
      value: defaultDate.value,
    };
  }, []);

  const [startFromDateOption, setStartFromDateOption] =
    useState<Option>(defaultDateOption);

  const selectedPriority = useMemo(
    () =>
      priorityTypes
        .filter((priority: any) => priority.selected)
        .map((priority: any) => ({
          value: priority.value,
          label: priority.label,
          id: priority.value,
        })),
    [priorityTypes]
  );

  const selectedBusinessUnits = useMemo(
    () =>
      businessUnits
        .filter((businessUnit: any) => businessUnit.selected)
        .map((businessUnit: any) => ({
          value: businessUnit.businessUnitName,
          label: businessUnit.businessUnitName,
          id: businessUnit.businessUnitId,
        })),
    [businessUnits]
  );

  const selectedProcess = useMemo(
    () =>
      processData
        .filter((process: any) => process.selected)
        .map((process: any) => ({
          value: process.processName,
          label: process.processName,
          id: process.processId,
        })),
    [processData]
  );

  const handleChangePriority = (selectedPriority: any, selected: any) => {
    setPriorityTypes(
      priorityTypes.map((priority: any) =>
        priority.value === selectedPriority
          ? { ...priority, selected: selected }
          : priority
      )
    );
  };

  const handleChangeBusinessUnits = (id: string, selected: boolean) => {
    setBusinessUnits(
      businessUnits.map((businessUnit: any) =>
        businessUnit.businessUnitId === id
          ? { ...businessUnit, selected: selected }
          : businessUnit
      )
    );
  };

  const handleChangeProcess = (id: string, selected: boolean) => {
    setProcessData(
      processData.map((process: any) =>
        process.processId === id ? { ...process, selected: selected } : process
      )
    );
  };

  const resetFilters = () => {
    setPriorityTypes(defaultPriorityTypes);
    setBusinessUnits(projectBusinessUnits);
    setProcessData(processes);
    setStartFromDateOption(defaultDateOption);
  };

  const initialFilters = {
    startDate: '',
    businessUnitIds: [],
    priority: [],
    processes: [],
  };
  const handleChangeDateFilter = (selectedItem: Option) => {
    setStartFromDateOption(selectedItem);
  };

  const handleApplyFilters = () => {
    const appliedFilters = {} as InsightsProjectActiveFilter;
    if (startFromDateOption.value) {
      window.localStorage.setItem(
        INSIGHTS_DATE_FILTER_NAME.PROJECT,
        startFromDateOption.value
      );
    }
    if (startFromDateOption.value && startFromDateOption.value !== 'ALL') {
      const dateValue = get(
        DATE_OPTIONS,
        `[${startFromDateOption.value}].numberOfDays`,
        ''
      );
      if (dateValue) {
        appliedFilters.startDate = moment()
          .subtract(dateValue, 'days')
          .format();
      } else {
        appliedFilters.startDate = '';
      }
    }
    if (selectedPriority.length) {
      appliedFilters.priorities = selectedPriority.map(
        (priority: any) => priority.id
      );
    }
    if (selectedBusinessUnits.length) {
      appliedFilters.businessUnitIds = selectedBusinessUnits.map(
        (businessUnit) => businessUnit.id
      );
    }
    if (selectedProcess.length) {
      appliedFilters.processNames = selectedProcess.map((type) => type.value);
    }
    dispatch(setActiveFilters({ ...initialFilters, ...appliedFilters }));
  };
  return (
    <div className='flex flex-col h-full'>
      <div className='flex justify-between py-4 px-5'>
        <Typography
          variant='h6'
          weight='medium'
          className={classnames(!isExpanded && 'hidden')}
        >
          {intl.get('INSIGHTS_PAGE_TABS.FILTER.TITLE')}
        </Typography>

        <div
          className={classnames(!isExpanded ? '-ml-2' : 'pr-12 flex space-x-4')}
        >
          <IconButton
            src={filterPanelIcon}
            className={classnames(!isExpanded ? 'hidden' : 'text-2xl')}
            onClick={() => resetFilters()}
          />

          {isExpanded ? (
            <IconButton
              className=''
              src={collapseIcon}
              onClick={() => setIsExpanded(false)}
            />
          ) : (
            <IconButton
              className=''
              src={expandIcon}
              onClick={() => setIsExpanded(true)}
            />
          )}
        </div>
      </div>

      <div
        className={classnames(
          !isExpanded ? 'hidden' : 'w-full overflow-y-auto',
          'flex-1'
        )}
      >
        <div className='px-5'>
          <FormItem
            label={intl.get('INSIGHTS_PAGE_TABS.FILTER.DATE_LABEL')}
            component='div'
            className='pt-2 pb-6'
          >
            <Dropdown
              options={dateOptions}
              values={[startFromDateOption]}
              onChange={handleChangeDateFilter}
              data-cy='insights_request-priority-filter'
            />
          </FormItem>
          <div className='pb-6'>
            <div className='flex justify-between items-center'>
              <Typography variant='body2' weight='medium'>
                {intl.get('INSIGHTS_PAGE_TABS.FILTER.PRIORITY')}
              </Typography>

              <IconButton
                name={
                  isPrioritySectionOpen
                    ? 'chevron-up-outline'
                    : 'chevron-down-outline'
                }
                className='text-md text-neutral-dark'
                onClick={() => setIsPrioritySectionOpen(!isPrioritySectionOpen)}
              />
            </div>
            <div className='max-h-32 overflow-y-auto'>
              {selectedPriority.length > 0 && (
                <div className='py-2'>
                  <FilterTags
                    selectedFilterCategoryValue={selectedPriority}
                    removeTag={handleChangePriority}
                  />
                </div>
              )}
              {priorityTypes.map((priority: any) => (
                <FormItem
                  component='div'
                  className={tailwindOverride(
                    'transition-height duration-300 easy',
                    {
                      'h-auto overflow-visible': isPrioritySectionOpen,
                      'h-0 overflow-hidden': !isPrioritySectionOpen,
                    }
                  )}
                >
                  <Checkbox
                    key={priority.value}
                    label={priority.label}
                    className='pr-10 py-1'
                    onChange={(event) =>
                      handleChangePriority(priority.value, event.target.checked)
                    }
                    checked={priority.selected === true}
                  />
                </FormItem>
              ))}
            </div>
          </div>
          <div className='pb-6'>
            <div className='flex justify-between items-center'>
              <Typography variant='body2' weight='medium'>
                {intl.get('INSIGHTS_PAGE_TABS.FILTER.BUSINESS_UNIT_LABEL')}
              </Typography>

              <IconButton
                name={
                  isBusinessUnitSectionOpen
                    ? 'chevron-up-outline'
                    : 'chevron-down-outline'
                }
                className='text-md text-neutral-dark'
                onClick={() =>
                  setIsBusinessUnitSectionOpen(!isBusinessUnitSectionOpen)
                }
              />
            </div>
            <div className='max-h-32 overflow-y-auto'>
              {selectedBusinessUnits.length > 0 && (
                <div className='py-2'>
                  <FilterTags
                    selectedFilterCategoryValue={selectedBusinessUnits}
                    removeTag={handleChangeBusinessUnits}
                  />
                </div>
              )}
              {businessUnits.map((businessUnit: any) => (
                <FormItem
                  component='div'
                  className={tailwindOverride(
                    'transition-height duration-300 easy',
                    {
                      'h-auto overflow-visible': isBusinessUnitSectionOpen,
                      'h-0 overflow-hidden': !isBusinessUnitSectionOpen,
                    }
                  )}
                >
                  <Checkbox
                    key={businessUnit.businessUnitId}
                    label={businessUnit.businessUnitName}
                    className='pr-10 py-1'
                    onChange={(event) =>
                      handleChangeBusinessUnits(
                        businessUnit.businessUnitId,
                        event.target.checked
                      )
                    }
                    checked={businessUnit.selected === true}
                  />
                </FormItem>
              ))}
            </div>
          </div>
          <div className=''>
            <div className='flex justify-between items-center'>
              <Typography variant='body2' weight='medium'>
                {intl.get('INSIGHTS_PAGE_TABS.FILTER.PROCESS_NAME')}
              </Typography>

              <IconButton
                name={
                  isProcessSectionOpen
                    ? 'chevron-up-outline'
                    : 'chevron-down-outline'
                }
                className='text-md text-neutral-dark'
                onClick={() => setIsProcessSectionOpen(!isProcessSectionOpen)}
              />
            </div>
            <div className='max-h-32 overflow-y-auto'>
              {selectedProcess.length > 0 && (
                <div className='py-2'>
                  <FilterTags
                    selectedFilterCategoryValue={selectedProcess}
                    removeTag={handleChangeProcess}
                  />
                </div>
              )}
              {processData.map((process: any) => (
                <FormItem
                  component='div'
                  className={tailwindOverride(
                    'transition-height duration-300 easy',
                    {
                      'h-auto overflow-visible': isProcessSectionOpen,
                      'h-0 overflow-hidden': !isProcessSectionOpen,
                    }
                  )}
                >
                  <Checkbox
                    key={process.processId}
                    label={process.processName}
                    className='pr-10 py-1'
                    onChange={(event) =>
                      handleChangeProcess(
                        process.processId,
                        event.target.checked
                      )
                    }
                    checked={process.selected === true}
                  />
                </FormItem>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className={classnames(
          !isExpanded
            ? 'hidden'
            : 'w-full mt-auto border-t border-primary-lighter-two flex py-2 z-5'
        )}
      >
        {' '}
        {status === SLICE_STATUS.LOADING ? (
          <FiltersPanelButtonsSkeletonLoader />
        ) : (
          <div className='flex mt-auto ml-auto mr-5'>
            <Button
              variant='tertiary'
              className='mr-4'
              onClick={() => resetFilters()}
              data-cy='insight-projects-filter-reset-button'
            >
              {intl.get('INSIGHTS_PAGE_TABS.FILTER.RESET')}
            </Button>
            <Button
              onClick={() => handleApplyFilters()}
              data-cy='insight-projects-filter-apply-button'
            >
              {intl.get('INSIGHTS_PAGE_TABS.FILTER.APPLY')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterSections;
