import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import { get, isEmpty, isEqual } from 'lodash';
import {
  Button,
  Icon,
  Typography,
  useElevation,
} from '@getsynapse/design-system';
import {
  fetchResourcesMetricsForProject,
  fetchTaskMetricsForProject,
  getCurrentProjectData,
  getProjectResourcesInsights,
  getProjectSliceStatus,
  getProjectTaskInsights,
  getProjectUpdateResult,
  resetUpdateStatus,
  updateArchiveStatus,
  updateProject,
} from 'state/Project/projectSlice';
import { deleteProject } from 'state/Projects/projectsSlice';
import { getAllRequests, getUserRequests } from 'state/Requests/requestSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import {
  selectSidePanelUpdatedData,
  setSidePanelUpdatedData,
} from 'state/SidePanel/sidePanelSlice';
import { isUserOrganizationAdmin, selectIsUserLd } from 'state/User/userSlice';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import {
  PATHS,
  PROJECT_STATUS,
  PROJECT_USER_ACTIONS,
  SLICE_STATUS,
} from 'utils/constants';
import { NewProject, objKeyAsString, ProjectStatus } from 'utils/customTypes';
import { ProjectTemplateField } from 'utils/types/templates';
import {
  defaultNewProjectData,
  requiredFieldsErrorsMap,
} from 'Pages/NewProjectPage/helpers/types';
import {
  getUpdatedProjectData,
  removeUnnecessaryStatusData,
} from '../../helpers/updatedProjectData';
import { validateRequiredFields } from '../../helpers/formValidation';
import {
  ClosedStatusBanner,
  OnHoldStatusBanner,
} from '../../components/Banners/Banners';
import { useUserPermissionsContext } from 'Pages/ProjectPage/context/UserPermissionsContext';
import BasicDetails from '../../../NewProjectPage/components/BasicDetails';
import PutProjectOnHoldModal from '../../components/PutProjectOnHoldModal/PutProjectOnHoldModal';
import CloseProject from 'Pages/ProjectPage/components/CloseProject/CloseProject';
import OverviewActions from 'Pages/ProjectPage/components/OverviewActions/OverviewActions';
import CommentsDropdown from 'Pages/ProjectPage/components/CommentsDropdown/CommentsDropdown';
import Loader from 'Molecules/Loader/Loader';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import UnarchiveProjectButton from 'Pages/ProjectPage/components/UnarchiveProject/UnarchiveProjectButton';
import ArchivedBanner from './ArchivedBanner';
import { getStatusColumn } from 'Pages/ProjectsListPage/helpers/tableColumnsValues';
import archiveIcon from 'assets/icons/archive.svg';
import CancelProject from 'Pages/ProjectPage/components/CancelProject/CancelProject';
import ProjectInsights from 'Pages/NewProjectPage/components/ProjectInsights';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CancelledStatusBanner } from '../../components/CancelProject/CancelledStatusBanner';
import ReactivateProjectModal from '../../components/ReactivateProject/ReactivateProjectModal';
import { formatProjectNumber } from 'utils/formatters';

const Overview: React.FC<{
  projectId: string;
  fromSidePanel?: boolean;
  isReadOnly: boolean;
}> = ({ projectId, fromSidePanel, isReadOnly }) => {
  const { operationalInsightsInProjectsOverview = false } = useFlags();
  const history = useHistory();
  const dispatch = useDispatch();
  const { canUser } = useUserPermissionsContext();
  const projectData = useSelector(getCurrentProjectData);
  const sidePanelProjectData = useSelector(selectSidePanelUpdatedData);
  const projectSliceStatus = useSelector(getProjectSliceStatus);
  const updateResult = useSelector(getProjectUpdateResult);
  const isAdmin = useSelector(isUserOrganizationAdmin);
  const [disableSave, setDisableSave] = useState(true);
  const taskMetricsForProjects = useSelector(getProjectTaskInsights);
  const resourcesMetricsForProjects = useSelector(getProjectResourcesInsights);
  const [shouldDisplayPutOnHoldModal, setShouldDisplayPutOnHoldModal] =
    useState({
      isOpen: false,
      readOnly: false,
    });
  const [shouldDisplayCancelModal, setShouldDisplayCancelModal] =
    useState(false);
  const [shouldDisplayReactivateModal, setShouldDisplayReactivateModal] =
    useState(false);
  const [shouldDisplayCloseModal, setShouldDisplayCloseModal] = useState(false);
  const [currentSavedProject, setCurrentSavedProject] = useState<NewProject>(
    fromSidePanel ? (sidePanelProjectData as NewProject) : projectData
  );
  const [requiredFieldsErrors, setRequiredFieldsErrors] =
    useState<objKeyAsString>(requiredFieldsErrorsMap);
  const footerElevation = useElevation(1);
  const toggleCloseModal = () =>
    setShouldDisplayCloseModal((prevState: boolean) => !prevState);
  const toggleCancelModal = () =>
    setShouldDisplayCancelModal((prevState: boolean) => !prevState);
  const isUserLd = useSelector(selectIsUserLd);

  useEffect(() => {
    if (updateResult === 'fail') {
      dispatch(
        showSnackbarNotification({
          notificationVariant: 'error',
          notificationTitle: intl.get('UPDATE_PROJECT_PAGE.UPDATE_FAILED'),
        })
      );
    } else if (updateResult === 'success') {
      dispatch(
        showSnackbarNotification({
          notificationVariant: 'success',
          notificationTitle: intl.get('UPDATE_PROJECT_PAGE.UPDATED'),
        })
      );
    }
    dispatch(resetUpdateStatus());
  }, [updateResult, dispatch]);

  useEffect(() => {
    dispatch(getAllRequests({}));
    dispatch(getUserRequests({}));
  }, [dispatch]);

  const [isMetricsDataReady, setIsMetricsDataReady] = useState(false);
  const getMetricsData = useCallback(async () => {
    if (projectId && operationalInsightsInProjectsOverview && isUserLd) {
      await dispatch(fetchTaskMetricsForProject(projectId));
      await dispatch(fetchResourcesMetricsForProject(projectId));
      setIsMetricsDataReady(true);
    } else if (!operationalInsightsInProjectsOverview) {
      setIsMetricsDataReady(true);
    }
  }, [projectId, operationalInsightsInProjectsOverview, isUserLd, dispatch]);

  useEffect(() => {
    const init = async () => {
      await getMetricsData();
    };
    init();
  }, [getMetricsData]);

  useEffect(() => {
    setCurrentSavedProject(
      fromSidePanel && sidePanelProjectData
        ? (sidePanelProjectData as NewProject)
        : projectData
    );
  }, [projectData, sidePanelProjectData, fromSidePanel]);

  useEffect(() => {
    if (
      !isEqual(currentSavedProject, defaultNewProjectData) &&
      !isEqual(projectData, defaultNewProjectData)
    ) {
      const savedProject = { ...currentSavedProject };
      const originalProjectData = { ...projectData };
      if (fromSidePanel) {
        delete savedProject?.customProperties;
        delete originalProjectData?.customProperties;
      }
      const changesDetected = !isEqual(savedProject, originalProjectData);
      if (changesDetected && disableSave) {
        setDisableSave(false);
      }
      if (!changesDetected && !disableSave) {
        setDisableSave(true);
      }
    }
  }, [currentSavedProject, projectData, disableSave, fromSidePanel]);

  const requiredCustomFieldsErrorsMap = useMemo<{ [key: string]: boolean }>(
    () =>
      projectData.customProperties
        ? projectData.customProperties.reduce(
            (
              errorsMap: { [key: string]: boolean },
              currentField: ProjectTemplateField
            ) => {
              if (currentField.required) {
                errorsMap[currentField.field_template.name] = false;
              }
              return errorsMap;
            },
            {}
          )
        : {},
    [projectData.customProperties]
  );

  const handleSave = async () => {
    const { canSave, errorsMap } = validateRequiredFields(
      currentSavedProject,
      true,
      requiredCustomFieldsErrorsMap
    );
    setRequiredFieldsErrors(errorsMap);
    if (!canSave) {
      setDisableSave(true);
      dispatch(
        showNotificationBanner({
          notificationVariant: 'error',
          notificationText: intl.get('UPDATE_PROJECT_PAGE.NOT_UPDATED'),
        })
      );
    } else {
      await handleUpdateProject();
      setDisableSave(true);
    }
  };

  const handleDeleteProject = async () => {
    await dispatch(deleteProject(projectId));
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get(
          'PROJECT_DETAIL.DELETE_PROJECT.SUCCESS_MESSAGE',
          {
            projectName: get(projectData, 'title'),
          }
        ),
      })
    );
    history.push(PATHS.PROJECTS_LIST_PAGE);
  };

  function saveProject(fieldsToUpdate: { [p: string]: any }, archive: boolean) {
    let updateProjectFieldsToUpdate = { ...fieldsToUpdate };
    if (archive) {
      const { is_archived, status, ...restFieldsToUpdate } =
        updateProjectFieldsToUpdate;
      updateProjectFieldsToUpdate = restFieldsToUpdate;
      // This construct avoids making saveProject async and propagating the async nature to the caller
      (async () => {
        await dispatch(
          updateArchiveStatus({
            projectId,
            updateFields: { is_archived, status },
          })
        );
      })();
    } else {
      const { is_archived, ...restFieldsToUpdate } =
        updateProjectFieldsToUpdate;
      updateProjectFieldsToUpdate = restFieldsToUpdate;
    }
    // This construct avoids making saveProject async and propagating the async nature to the caller
    (async () => {
      await dispatch(
        updateProject({
          projectId,
          data: updateProjectFieldsToUpdate,
        })
      );
    })();
  }

  const handleCancelProject = async (reason: string, archive: boolean) => {
    const updatedProject = {
      cancel_reason: reason,
      status: PROJECT_STATUS.CANCELED,
      cancellation_date: new Date().toISOString(),
      is_archived: archive,
    };
    const fieldsToUpdate = removeUnnecessaryStatusData(
      updatedProject,
      projectData
    );
    setCurrentSavedProject((previousProject) => ({
      ...previousProject,
      ...fieldsToUpdate,
    }));

    saveProject(fieldsToUpdate, archive);

    dispatch(
      showSnackbarNotification({
        notificationVariant: 'success',
        notificationTitle: intl.get(
          'PROJECT_DETAIL.CANCEL_PROJECT.SUCCESS_MESSAGE',
          {
            projectName: get(projectData, 'title'),
            archive: archive ? '1' : '0',
          }
        ),
      })
    );
  };

  function handleReactivateProject(status: ProjectStatus) {
    const updatedProject = {
      cancel_reason: null,
      status: status,
      cancellation_date: null,
    };

    const fieldsToUpdate = removeUnnecessaryStatusData(
      updatedProject,
      projectData
    );

    setCurrentSavedProject((previousProject) => ({
      ...previousProject,
      ...fieldsToUpdate,
    }));

    saveProject(fieldsToUpdate, false);

    dispatch(
      showSnackbarNotification({
        notificationVariant: 'success',
        notificationTitle: intl.get(
          'PROJECT_DETAIL.REACTIVATE_PROJECT.SUCCESS_MESSAGE',
          {
            projectName: get(projectData, 'title'),
          }
        ),
      })
    );
  }

  const handleUpdateProject = async () => {
    const { updatedProjectData } = getUpdatedProjectData(
      currentSavedProject,
      projectData
    );
    if (fromSidePanel) {
      dispatch(setSidePanelUpdatedData(currentSavedProject));
    }
    await dispatch(
      updateProject({
        projectId,
        data: updatedProjectData,
      })
    );
    await getMetricsData();
  };

  const handlePutProjectOnHold = useCallback(
    async (holdReason: string) => {
      const updatedProject = {
        hold_reason: holdReason,
        status: PROJECT_STATUS.ON_HOLD,
      };
      const fieldsToUpdate = removeUnnecessaryStatusData(
        updatedProject,
        projectData
      );
      await dispatch(
        updateProject({
          projectId,
          data: fieldsToUpdate,
        })
      );
    },
    [dispatch, projectData, projectId]
  );

  const handleCancelPutProjectOnHold = useCallback(() => {
    if (projectData.status !== currentSavedProject.status) {
      setCurrentSavedProject((prevData) => ({
        ...prevData,
        status: projectData.status,
      }));
    }
  }, [currentSavedProject, setCurrentSavedProject, projectData]);

  const handleCloseProject = async (
    comments: string,
    closingDate: string,
    archive: boolean
  ) => {
    const updatedProject = {
      status: PROJECT_STATUS.CLOSED,
      finalComments: comments,
      closedDate: closingDate,
      is_archived: archive,
    };
    const fieldsToUpdate = removeUnnecessaryStatusData(
      updatedProject,
      projectData
    );

    saveProject(fieldsToUpdate, archive);

    dispatch(
      showSnackbarNotification({
        notificationVariant: 'success',
        notificationTitle: intl.get(
          'PROJECT_DETAIL.CLOSE_PROJECT.SUCCESS_MESSAGE',
          {
            projectName: get(projectData, 'title'),
            archive: archive ? '1' : '0',
          }
        ),
      })
    );
  };

  const afterArchiveProject = () => {
    history.push(PATHS.PROJECTS_LIST_PAGE);
  };

  useEffect(() => {
    const canPutOnHold: boolean = Boolean(
      !isEmpty(projectData) &&
        !isEmpty(currentSavedProject) &&
        projectData.status &&
        projectData.status !== PROJECT_STATUS.ON_HOLD &&
        currentSavedProject.status &&
        currentSavedProject.status === PROJECT_STATUS.ON_HOLD &&
        canUser(PROJECT_USER_ACTIONS.PUT_PROJECT_ON_HOLD)
    );
    if (canPutOnHold) {
      setShouldDisplayPutOnHoldModal({
        isOpen: true,
        readOnly: false,
      });
    }
  }, [
    projectData,
    canUser,
    setShouldDisplayPutOnHoldModal,
    currentSavedProject,
  ]);

  const canCancelProject: boolean = useMemo<boolean>(
    () =>
      get(currentSavedProject, 'status', '') !== PROJECT_STATUS.CANCELED &&
      canUser(PROJECT_USER_ACTIONS.CANCEL_PROJECTS),
    [currentSavedProject, canUser]
  );
  const canCloseProject: boolean = useMemo<boolean>(
    () =>
      projectData.status === PROJECT_STATUS.COMPLETED &&
      canUser(PROJECT_USER_ACTIONS.CLOSE_PROJECTS),
    [projectData.status, canUser]
  );
  const canArchiveProject: boolean = useMemo<boolean>(
    () =>
      Boolean(
        !projectData.is_archived &&
          (canUser(PROJECT_USER_ACTIONS.ARCHIVE_PROJECTS) ||
            projectData.isProjectPartOfUserPrograms ||
            isAdmin)
      ),
    [
      canUser,
      isAdmin,
      projectData.isProjectPartOfUserPrograms,
      projectData.is_archived,
    ]
  );

  const canUnarchiveProject: boolean = useMemo<boolean>(
    () =>
      Boolean(
        projectData.is_archived &&
          (canUser(PROJECT_USER_ACTIONS.ARCHIVE_PROJECTS) ||
            projectData.isProjectPartOfUserPrograms ||
            isAdmin)
      ),
    [
      canUser,
      isAdmin,
      projectData.isProjectPartOfUserPrograms,
      projectData.is_archived,
    ]
  );

  return (
    <>
      <CancelProject
        shouldDisplayModal={shouldDisplayCancelModal}
        closeModalCallback={() => setShouldDisplayCancelModal(false)}
        cancelReason={projectData.cancel_reason}
        cancellationDate={projectData.cancellation_date}
      />
      <ReactivateProjectModal
        projectData={currentSavedProject}
        handleReactivateProjectClick={handleReactivateProject}
        shouldDisplayModal={shouldDisplayReactivateModal}
        closeModalCallback={() => setShouldDisplayReactivateModal(false)}
        projectId={projectId}
      />
      <PutProjectOnHoldModal
        onConfirm={handlePutProjectOnHold}
        onCancelCallback={handleCancelPutProjectOnHold}
        shouldDisplayModal={shouldDisplayPutOnHoldModal.isOpen}
        isReadOnly={shouldDisplayPutOnHoldModal.readOnly}
        canUpdateHoldReason={canUser(PROJECT_USER_ACTIONS.PUT_PROJECT_ON_HOLD)}
        holdReason={currentSavedProject?.hold_reason}
        onClose={() => {
          if (shouldDisplayPutOnHoldModal) {
            setShouldDisplayPutOnHoldModal({
              isOpen: false,
              readOnly: false,
            });
          }
        }}
      />
      <CloseProject
        shouldDisplayModal={shouldDisplayCloseModal}
        closeModalCallback={toggleCloseModal}
        confirmCloseProject={handleCloseProject}
        project={projectData}
      />

      <DetailsPage
        topBar={
          <div className='z-5 flex justify-between items-center pl-4'>
            <div className='flex items-center space-x-2'>
              <Typography className='flex'>
                <span className='font-semibold'>{`${intl.get(
                  'PROJECT_DETAIL.PROJECT_NUMBER'
                )}: `}</span>
                {projectData.projectNumber &&
                  formatProjectNumber(parseInt(projectData.projectNumber))}
              </Typography>
              {projectData.is_archived && (
                <>
                  {getStatusColumn(projectData.status, false)}
                  <Icon
                    src={archiveIcon}
                    className='text-neutral text-base'
                    data-cy='archived-icon'
                  />
                </>
              )}
            </div>
            <div className='w-full min-h-8 flex justify-end items-center'>
              {canUser(PROJECT_USER_ACTIONS.VIEW_COMMENTS) &&
                !projectData.is_archived && (
                  <CommentsDropdown
                    projectId={projectId}
                    canAddComments={canUser(PROJECT_USER_ACTIONS.ADD_COMMENT)}
                  />
                )}
              {(canCancelProject || canCloseProject || canArchiveProject) &&
                !projectData.is_archived && (
                  <OverviewActions
                    afterArchiveProject={afterArchiveProject}
                    canArchiveProject={canArchiveProject}
                    canCancelProject={canCancelProject}
                    cancelProjectCallback={handleCancelProject}
                    canCloseProject={canCloseProject}
                    deleteProjectCallback={handleDeleteProject}
                    toggleCloseModal={toggleCloseModal}
                  />
                )}
              {canUnarchiveProject && (
                <UnarchiveProjectButton
                  afterUnarchiveProject={afterArchiveProject}
                />
              )}
            </div>
          </div>
        }
        bodyClassName='pt-0 px-0'
        content={
          <div
            className='bg-neutral-white flex-grow overflow-y-auto'
            data-cy='project-form-body'
          >
            {projectData.is_archived && (
              <ArchivedBanner archivedDate={projectData.updatedAt} />
            )}
            <div className='w-full h-full px-6'>
              {projectData?.status === PROJECT_STATUS.ON_HOLD && (
                <OnHoldStatusBanner
                  message={intl.get(
                    'PROJECT_DETAIL.PUT_PROJECT_ON_HOLD_MODAL.PROJECT_ON_HOLD_BANNER'
                  )}
                  handleOnClick={() =>
                    setShouldDisplayPutOnHoldModal({
                      isOpen: true,
                      readOnly: true,
                    })
                  }
                />
              )}
              {projectData.status === PROJECT_STATUS.CANCELED && (
                <CancelledStatusBanner
                  handleOnReactivateClick={() =>
                    setShouldDisplayReactivateModal(
                      (prevState: boolean) => !prevState
                    )
                  }
                  handleOnClick={toggleCancelModal}
                  reason={projectData.cancel_reason}
                />
              )}
              {projectData.status === PROJECT_STATUS.CLOSED && (
                <ClosedStatusBanner
                  handleOnClick={toggleCloseModal}
                  closedDate={projectData.closedDate}
                />
              )}
              {(projectSliceStatus === SLICE_STATUS.LOADING ||
                (isUserLd && !isMetricsDataReady)) && <Loader />}
            </div>
            {projectSliceStatus !== SLICE_STATUS.LOADING &&
              currentSavedProject &&
              operationalInsightsInProjectsOverview &&
              isUserLd && (
                <div
                  data-testid='project_insights_component'
                  className='border-b border-neutral-lighter-two'
                >
                  <ProjectInsights
                    taskDetails={taskMetricsForProjects}
                    resourceDetails={resourcesMetricsForProjects}
                    projectTargetCompletionDate={
                      projectData.targetCompletionDate
                    }
                  />
                </div>
              )}
            <div className='w-full h-full px-6'>
              {projectSliceStatus !== SLICE_STATUS.LOADING &&
                currentSavedProject && (
                  <BasicDetails
                    data={currentSavedProject}
                    setData={setCurrentSavedProject}
                    requiredFieldsErrors={requiredFieldsErrors}
                    isOnUpdatingPage={true}
                    isReadOnly={isReadOnly}
                    originalData={projectData}
                    projectId={projectId}
                  />
                )}
            </div>
          </div>
        }
      />
      <div
        className={classnames(
          'w-full bg-neutral-white flex py-2 z-5',
          footerElevation
        )}
      >
        <div className='flex ml-auto mr-12'>
          <Button
            variant='secondary'
            className='mr-4'
            onClick={() => history.push(PATHS.PROJECTS_LIST_PAGE)}
            data-testid='cancel-button'
          >
            {intl.get('NEW_PROJECT_PAGE.CANCEL_BUTTON')}
          </Button>
          <Button
            onClick={handleSave}
            disabled={disableSave}
            data-testid='update-button'
          >
            {intl.get('UPDATE_PROJECT_PAGE.UPDATE')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Overview;
