import { FormOption } from 'utils/customTypes';
import classnames from 'classnames';
import {
  useElevation,
  FormLabel,
  tailwindOverride,
  Tooltip,
} from '@getsynapse/design-system';

const FormCard = ({
  formOption,
  selected = false,
  onSelect,
  disabled = false,
}: {
  formOption: FormOption;
  selected: boolean;
  onSelect: (form: FormOption) => void;
  disabled?: boolean;
}) => {
  const cardElevation = useElevation(1);
  const handleOnClick = () => {
    if (!disabled && !selected) {
      onSelect(formOption);
    }
  };
  return (
    <div
      className={tailwindOverride(
        'w-11/12 mb-4 min-h-26 py-3 px-4 rounded flex flex-col',
        cardElevation,
        'hover:border-neutral-lighter hover:shadow-tooltip',
        `${
          selected && 'border border-secondary-dark bg-secondary-lightest-two'
        }`,
        `${!disabled && !selected && 'cursor-pointer'}`
      )}
      onClick={handleOnClick}
      data-cy={`form-card-${formOption.value}`}
    >
      <FormLabel>{formOption.label}</FormLabel>
      <Tooltip
        timeout={0}
        position='bottomRight'
        contentProps={{
          className: classnames(
            'z-10 py-1 px-3 bg-neutral-darker min-w-12 absolute',
            'text-center shadow-tooltip'
          ),
        }}
        openMode='hover1'
        showPopper
        trigger={
          <div className='text-neutral line-clamp-3 text-xs max-w-64'>
            {formOption.formDescription}
          </div>
        }
      >
        {formOption.formDescription}
      </Tooltip>
    </div>
  );
};

export default FormCard;
