import { useState } from 'react';
import intl from 'react-intl-universal';
import { Button, Icon, List, Popup } from '@getsynapse/design-system';
import { EditableRequestStatus } from 'utils/types/request';
import { REQUEST_STATUSES } from 'utils/constants/request';
import RequestStatusModals from './RequestStatusModals/RequestStatusModals';
import waitlistIcon from 'assets/icons/waitlist.svg';
import inReviewIcon from 'assets/icons/in-review.svg';

interface StatusOption {
  value: EditableRequestStatus;
  component: () => JSX.Element;
}

const STATUS_OPTIONS: Record<EditableRequestStatus, StatusOption> = {
  [REQUEST_STATUSES.APPROVED]: {
    value: REQUEST_STATUSES.APPROVED,
    component: () => (
      <>
        <Icon name='checkmark-circle' className='pr-2 text-success-darker' />
        {intl.get('REQUEST_PAGE.STATUS.APPROVED')}
      </>
    ),
  },
  [REQUEST_STATUSES.WAITLISTED]: {
    value: REQUEST_STATUSES.WAITLISTED,
    component: () => (
      <>
        <Icon src={waitlistIcon} className='pr-2 text-warning-darker' />
        {intl.get('REQUEST_PAGE.STATUS.WAITLISTED')}
      </>
    ),
  },
  [REQUEST_STATUSES.SUBMITTED]: {
    value: REQUEST_STATUSES.SUBMITTED,
    component: () => (
      <>
        <Icon src={inReviewIcon} className='pr-2 text-teal-dark' />
        {intl.get('REQUEST_PAGE.STATUS.IN_REVIEW')}
      </>
    ),
  },
  [REQUEST_STATUSES.REJECTED]: {
    value: REQUEST_STATUSES.REJECTED,
    component: () => (
      <>
        <Icon name='close-circle' className='pr-2 text-fire-darker' />
        {intl.get('REQUEST_PAGE.STATUS.DECLINED')}
      </>
    ),
  },
};

interface RequestStatusMenuProps {
  currentStatus: EditableRequestStatus;
  approveRequest: ({ comments }: { comments?: string }) => Promise<void>;
  waitlistRequest: ({
    conditions,
    details,
    comments,
  }: {
    conditions: string[];
    details?: string;
    comments?: string;
  }) => Promise<void>;
  moveInReviewRequest: () => Promise<void>;
  declineRequest: ({
    reason,
    message,
    comments,
  }: {
    reason: string;
    message?: string;
    comments?: string;
  }) => Promise<void>;
}

const RequestStatusMenu = ({
  currentStatus,
  approveRequest,
  waitlistRequest,
  moveInReviewRequest,
  declineRequest,
}: RequestStatusMenuProps): JSX.Element => {
  const [activeModal, setActiveModal] = useState<EditableRequestStatus>();
  const modalCallbacks: Record<EditableRequestStatus, Function> = {
    [REQUEST_STATUSES.APPROVED]: approveRequest,
    [REQUEST_STATUSES.WAITLISTED]: waitlistRequest,
    [REQUEST_STATUSES.SUBMITTED]: moveInReviewRequest,
    [REQUEST_STATUSES.REJECTED]: declineRequest,
  };

  return (
    <>
      <RequestStatusModals
        onCancel={() => setActiveModal(undefined)}
        activeModal={activeModal}
        onConfirmAction={(params) => {
          modalCallbacks[activeModal!](params);
          setActiveModal(undefined);
        }}
      />
      <Popup
        position='bottom-end'
        trigger={({ isOpen }) => {
          const Component = STATUS_OPTIONS[currentStatus].component;
          return (
            <Button variant='tertiary' data-cy='request-status-menu__trigger'>
              <Component />
              <Icon
                name={isOpen ? 'caret-up' : 'caret-down'}
                className='text-xs pl-2'
              />
            </Button>
          );
        }}
      >
        {({ close }) => (
          <List
            onSelectOption={() => {}}
            options={Object.values(STATUS_OPTIONS).filter(
              (statusOption) => statusOption.value !== currentStatus
            )}
            renderOption={(option) => (
              <li
                onClick={() => {
                  setActiveModal(option.value);
                  close();
                }}
                className='group py-1 pl-4 pr-6 flex justify-start items-center cursor-pointer hover:bg-primary-lighter space-x-2 text-sm'
              >
                <option.component />
              </li>
            )}
          />
        )}
      </Popup>
    </>
  );
};

export default RequestStatusMenu;
