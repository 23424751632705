import {
  FormItem,
  Icon,
  tailwindOverride,
  useLink,
} from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { Link, useHistory } from 'react-router-dom';
import { PATHS } from 'utils/constants';

interface ProjectLinkProps {
  isOnSidepanel?: boolean;
  projectId: string;
  projectTitle: string;
}

const ProjectLink = ({
  isOnSidepanel = false,
  projectId,
  projectTitle,
}: ProjectLinkProps) => {
  const history = useHistory();
  const linkClassName = useLink();

  return (
    <FormItem
      label={intl.get('ENTITIES.PROJECT', { num: 1 })}
      className={tailwindOverride({ 'mt-2': isOnSidepanel })}
    >
      <Link
        to={{
          pathname: `${PATHS.PROJECT_PAGE}/${projectId}`,
          state: {
            from: history.location.pathname,
          },
        }}
        className={tailwindOverride(
          linkClassName,
          'flex items-center font-semibold'
        )}
      >
        {projectTitle}
        <Icon name='open-outline' className='ml-2 font-semibold' />
      </Link>
    </FormItem>
  );
};

export default ProjectLink;
