import { useEffect, useState, useCallback } from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, get } from 'lodash';
import {
  getUser,
  selectUser,
  setRegistered,
  selectUserSliceStatus,
  updateLoginData,
} from 'state/User/userSlice';
import Loader from 'Molecules/Loader/Loader';
import { PATHS, USER_STATUS, USER_TYPES, USER_ROLES } from 'utils/constants';
import { User } from 'utils/customTypes';
import config from 'config/Config';
import {
  addBusinessUser,
  updateUser,
} from 'state/UsersManagement/usersManagementSlice';

const SSOAuthentication = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userSliceStatus = useSelector(selectUserSliceStatus);
  const [authUser, setAuthUser] = useState();

  const createBusinessUser = useCallback(
    async (email: string, firstName: string, lastName: string) => {
      const organizationId = Cookies.get('ssoAccountId');
      Cookies.remove('ssoAccountId');
      const userInfo = {
        data: {
          firstName: firstName,
          lastName: lastName,
          email: email,
        },
        type: USER_TYPES.BUSINESS as User['type'],
        role: USER_ROLES.USER,
        organization_id: organizationId,
        status: USER_STATUS.REGISTERED,
      };
      await dispatch(addBusinessUser(userInfo));
      await dispatch(getUser(get(authUser, 'attributes.email')!));
    },
    [authUser, dispatch]
  );

  const updateSSOUser = useCallback(() => {
    const userId = get(user, 'id');
    const firstName: string = get(authUser, 'attributes.given_name', '');
    const lastName: string = get(authUser, 'attributes.family_name', '');
    const email: string = get(authUser, 'attributes.email', '');
    const update = async () => {
      await dispatch(
        updateUser({
          userId: userId!,
          updateFields: {
            id: userId,
            role: get(user, 'role'),
            data: {
              email,
              firstName,
              lastName,
              jobTitle: get(user, 'jobTitle'),
            },
          },
        })
      );
      await dispatch(getUser(email!));
    };
    if (userId && firstName && lastName && !isEmpty(user) && email) {
      update();
    }
  }, [authUser, dispatch, user]);

  useEffect(() => {
    if (!isEmpty(authUser)) {
      if (!isEmpty(user)) {
        if (user.status === USER_STATUS.INVITED && user.id) {
          dispatch(setRegistered(user.id));
        }
        if (
          user.status === USER_STATUS.INVITED_DISABLED ||
          user.status === USER_STATUS.REGISTERED_DISABLED
        ) {
          history.push(PATHS.DEACTIVATED_ACCOUNT);
        } else {
          updateSSOUser();
          dispatch(updateLoginData());
          const redirectUrl = Cookies.get('redirectUrl');
          Cookies.remove('redirectUrl');
          if (redirectUrl) {
            if (redirectUrl.includes(PATHS.ELEMENTS)) {
              window.location.href = `${config.get('designURL')}${redirectUrl}`;
            }
            history.replace(redirectUrl);
          } else {
            history.replace(PATHS.ROOT);
          }
        }
      } else {
        if (userSliceStatus === 'failed') {
          createBusinessUser(
            get(authUser, 'attributes.email')!,
            get(authUser, 'attributes.given_name')!,
            get(authUser, 'attributes.family_name')!
          );
        }
      }
    }
  }, [
    history,
    user,
    dispatch,
    userSliceStatus,
    authUser,
    createBusinessUser,
    updateSSOUser,
  ]);

  useEffect(() => {
    const validateUserSession = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        if (get(currentUser, 'attributes.email')) {
          setAuthUser(currentUser);
          await dispatch(getUser(currentUser.attributes.email));
        }
      } catch (error) {
        history.replace(PATHS.LOGIN);
      }
    };

    const connectionUrl = Cookies.get('connectionUrl') || '';
    if (connectionUrl) {
      Cookies.remove('connectionUrl');
      window.location.href = connectionUrl;
    } else {
      validateUserSession();
    }
  }, [history, dispatch]);

  return (
    <div className='flex h-screen px-16'>
      <Loader />
    </div>
  );
};

SSOAuthentication.propTypes = {};

export default SSOAuthentication;
