import intl from 'react-intl-universal';
import { useHistory, Link } from 'react-router-dom';
import { Typography, Icon, tailwindOverride } from '@getsynapse/design-system';
import { PATHS } from 'utils/constants';
import {
  STRATEGY_CATEGORIES,
  STRATEGY_CATEGORY_ICON_MAP,
} from 'utils/constants/program';
import { strategyCategories, ProgramCategory } from 'utils/types/program';
import { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { generateDisallowedCategories } from 'Templates/StrategyCategoryTemplate/components/helpers';

type Props = {
  programId: string;
  category: ProgramCategory;
};

const StrategyCategoryCard = ({ programId, category }: Props) => {
  const history = useHistory();
  const [isComingSoon, setIsComingSoon] = useState<boolean>(false);

  const {
    enableProgramStrategyBeta12 = false,
    enableProgramStrategyBeta14 = false,
    enableProgramStrategy15Roi = false,
  } = useFlags();

  const DISALLOWED_CATEGORIES = generateDisallowedCategories(
    enableProgramStrategyBeta12,
    enableProgramStrategyBeta14,
    enableProgramStrategy15Roi
  );

  useEffect(() => {
    if (DISALLOWED_CATEGORIES.includes(category.name as strategyCategories)) {
      setIsComingSoon(true);
    }
  }, [category.name, DISALLOWED_CATEGORIES]);

  const handleNavigationToCategory = (categoryId: string) => {
    if (!isComingSoon) {
      if (category.name === STRATEGY_CATEGORIES.INPUT) {
        history.push(
          `${PATHS.PROGRAM_PAGE}/${programId}${PATHS.INPUT_CATEGORY_PAGE}/${categoryId}`
        );
      } else if (category.name === STRATEGY_CATEGORIES.ROI) {
        history.push(
          `${PATHS.PROGRAM_PAGE}/${programId}${PATHS.ROI_CATEGORY_PAGE}/${categoryId}`
        );
      } else {
        history.push(
          `${PATHS.PROGRAM_PAGE}/${programId}${PATHS.STRATEGY_PAGE}/${categoryId}`
        );
      }
    }
  };

  return (
    <div
      data-testid={`strategy-category__card-${category.id}`}
      aria-label={`go-to-${category.name}-category-page}`}
      onClick={() => handleNavigationToCategory(category.id || '')}
      className={tailwindOverride(
        'group p-5 h-44 relative',
        'border rounded border-primary-lighter-two',
        'transition duration-100 ease-linear',
        'shadow-header',
        { 'cursor-pointer': !isComingSoon },
        { 'hover:border-secondary-light': !isComingSoon },
        { 'hover:shadow-lifted': !isComingSoon }
      )}
    >
      <div className='flex space-x-2 items-center'>
        <Icon
          src={STRATEGY_CATEGORY_ICON_MAP[category.name]}
          className={tailwindOverride('text-lg text-secondary-dark', {
            'group-hover:text-neutral-black': !isComingSoon,
          })}
        />
        <Typography
          className='text-lg font-semibold'
          data-testid={`strategy-category__card-${category.id}__name`}
        >
          {category.name}
        </Typography>
      </div>
      <Typography
        className='pt-2'
        data-testid={`strategy-category__card-${category.id}__description`}
      >
        {category.description}
      </Typography>
      <div className='absolute bottom-6 left-5 flex space-x-1.5 items-center mt-6'>
        {isComingSoon ? (
          <Typography
            className='text-neutral-darker'
            variant='body2'
            weight='medium'
            data-testid={`strategy-category__card-${category.id}__coming-soon`}
          >
            {intl.get('COMING_SOON')}
          </Typography>
        ) : (
          <>
            <Link
              to={`${PATHS.PROGRAM_PAGE}/${programId}${PATHS.STRATEGY_PAGE}/${category.id}`}
              title={intl.get('PROGRAM_PAGE.STRATEGY_PAGE.GO_CATEGORY_PAGE', {
                category: category.name,
              })}
              data-testid={`strategy-category__card-${category.id}__link`}
              className={tailwindOverride(
                'block text-sm font-medium',
                'text-purple-dark',
                { 'group-hover:text-purple-darker': !isComingSoon }
              )}
            >
              {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.GO_CATEGORY_PAGE', {
                category: category.name,
              })}
            </Link>
            <Icon
              data-testid={`strategy-category__card-${category.id}__arrow`}
              name='arrow-forward'
              className={tailwindOverride(
                'text-xl text-neutral-white',
                { 'group-hover:text-purple-darker': !isComingSoon },
                'transition duration-100 ease-linear'
              )}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default StrategyCategoryCard;
