import { FC } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import {
  IconButton,
  Tooltip,
  tailwindOverride,
} from '@getsynapse/design-system';
import { setTaskIdToEdit } from 'state/ProjectTasksList/actions/actionsSlice';

interface ActionButtonsColumnProps {
  taskId: string;
  canEditTask?: boolean;
  canDuplicateTask?: boolean;
  displayDuplicateTaskModal: (taskId: string) => void;
}

const ActionButtonsColumn: FC<ActionButtonsColumnProps> = ({
  taskId,
  canEditTask = false,
  canDuplicateTask = false,
  displayDuplicateTaskModal,
}) => {
  const dispatch = useDispatch();

  const handleOpenSidePanel = (taskId: string) => {
    dispatch(setTaskIdToEdit(taskId));
  };

  return (
    <div className='flex gap-x-2'>
      <Tooltip
        openMode='hover1'
        timeout={0}
        showPopper
        position='topCenter'
        contentProps={{
          className: 'px-3 py-2 text-sm z-50 absolute',
        }}
        usePortal
        trigger={
          <div>
            <IconButton
              name={canEditTask ? 'pencil-outline' : 'eye'}
              iconClassname='pointer-events-none'
              className={tailwindOverride(
                'text-base text-neutral-dark p-1 rounded',
                'hover:bg-neutral-lightest hover:shadow-allocation-table'
              )}
              onClick={() => handleOpenSidePanel(taskId)}
              aria-label={`task-${taskId}__quick-${
                canEditTask ? 'edit' : 'view'
              }-button`}
            />
          </div>
        }
      >
        {canEditTask
          ? intl.get('SIDE_PANEL.QUICK_EDIT')
          : intl.get('SIDE_PANEL.QUICK_VIEW')}
      </Tooltip>
      {canDuplicateTask && (
        <Tooltip
          openMode='hover1'
          timeout={0}
          showPopper
          position='topCenter'
          contentProps={{
            className: 'px-3 py-2 text-sm z-50',
          }}
          usePortal
          trigger={
            <div>
              <IconButton
                name='copy-outline'
                iconClassname='pointer-events-none'
                aria-label={`task-${taskId}__duplicate-button`}
                className={tailwindOverride(
                  'text-base ml-2 text-neutral-dark p-1 rounded',
                  'hover:bg-neutral-lightest hover:shadow-allocation-table'
                )}
                onClick={() => displayDuplicateTaskModal(taskId)}
              />
            </div>
          }
        >
          {intl.get('SIDE_PANEL.DUPLICATE')}
        </Tooltip>
      )}
    </div>
  );
};

export default ActionButtonsColumn;
