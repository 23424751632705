import { combineReducers } from '@reduxjs/toolkit';

import teamsList from './TeamsList/teamsListSlice';
import teamMembersList from './TeamMembersList/teamMembersListSlice';
import teamMemberProjectsList from './TeamMemberProjectsList/teamMemberProjectsListSlice';
import scrollPosition from './ScrollPosition/scrollPosition';

export default combineReducers({
  teamsList,
  teamMembersList,
  teamMemberProjectsList,
  scrollPosition,
});
