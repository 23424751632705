import { Modal, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import informationIcon from 'assets/icons/information.svg';

const LeavingUnsavedEvaluationModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
}) => {
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      data-testid='leaving-unsaved-evaluation-modal'
      actionButtons={[
        {
          children: intl.get(
            'STRATEGY_DATA_COLLECTION.UNSAVED_CHANGES_MODAL.CONFIRM_MESSAGE'
          ),
          variant: 'primary',
          color: 'warning',
          'data-testid': 'leaving-unsaved-evaluation-modal-confirm-button',
          onClick: handleConfirm,
        },
        {
          children: intl.get(
            'STRATEGY_DATA_COLLECTION.UNSAVED_CHANGES_MODAL.DISCARD_BUTTON'
          ),
          variant: 'tertiary',
          'data-testid': 'leaving-unsaved-evaluation-modal-discard-button',
          onClick: handleClose,
        },
      ]}
      closeModal={handleClose}
      isOpen={isOpen}
      title={intl.get('STRATEGY_DATA_COLLECTION.UNSAVED_CHANGES_MODAL.TITLE')}
      titleIcon={{
        src: informationIcon,
      }}
      size='medium'
      aria-label={intl.get(
        'STRATEGY_DATA_COLLECTION.UNSAVED_CHANGES_MODAL.TITLE'
      )}
    >
      <Typography
        className='mb-5'
        data-testid='leaving-unsaved-evaluation-modal-message'
      >
        {intl.get('STRATEGY_DATA_COLLECTION.UNSAVED_CHANGES_MODAL.MESSAGE')}
      </Typography>
    </Modal>
  );
};

export default LeavingUnsavedEvaluationModal;
