import { Project } from 'utils/customTypes';
import intl from 'react-intl-universal';
import { Button, Table, Typography } from '@getsynapse/design-system';
import { getStatusColumn } from 'Pages/ProjectsListPage/helpers/tableColumnsValues';
import { useState } from 'react';
import LinkedProjectModal from './LinkedProjectModal';
import { useDispatch } from 'react-redux';
import { fetchProject } from 'state/Project/projectSlice';
import { formatProjectNumber } from 'utils/formatters';

type Props = {
  projects: Project[];
  hideViewProject: boolean;
  isLDUser: boolean;
};

const LinkedProjectsTable = ({
  projects,
  hideViewProject,
  isLDUser,
}: Props) => {
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const dispatch = useDispatch();

  const openProjectModal = async (projectId: string) => {
    await dispatch(fetchProject(projectId));
    setIsProjectModalOpen(true);
  };

  return (
    <div className='mt-8'>
      {!hideViewProject && (
        <LinkedProjectModal
          closeModal={() => setIsProjectModalOpen(false)}
          isOpen={isProjectModalOpen}
        />
      )}
      <Typography variant='h5' className='mb-4'>
        {intl.get('REQUEST_PAGE.LINKED_PROJECTS.TITLE')}
      </Typography>
      <Table
        canSelectRows={false}
        data-cy='linked-projects-table'
        className='rounded'
        data={{
          headData: {
            rowProps: { className: 'table w-full table-fixed' },
            className: 'w-full block',
            headCells: [
              ...(isLDUser
                ? [
                    {
                      content: intl.get(
                        'REQUEST_PAGE.LINKED_PROJECTS.TABLE.PROJECT_ID'
                      ),
                      className: 'w-44',
                    },
                  ]
                : []),
              {
                content: intl.get(
                  'REQUEST_PAGE.LINKED_PROJECTS.TABLE.PROJECT_NAME'
                ),
              },
              {
                content: intl.get('REQUEST_PAGE.LINKED_PROJECTS.TABLE.STATUS'),
                className: 'w-56',
              },
              ...(!hideViewProject ? [{ className: 'w-56' }] : []),
            ],
          },
          rows: projects.map((project) => ({
            className: 'table w-full table-fixed',
            cells: [
              ...(isLDUser
                ? [
                    {
                      content: formatProjectNumber(project.projectNumber),
                      className: 'w-44',
                    },
                  ]
                : []),
              {
                content: (
                  <div className='truncate max-w-max	'>{project.title}</div>
                ),
              },
              {
                content: getStatusColumn(project.status),
                className: 'w-56',
              },
              ...(!hideViewProject
                ? [
                    {
                      content: (
                        <Button
                          variant='tertiary'
                          onClick={() => openProjectModal(project.id)}
                          data-cy='view-linked-project-button'
                        >
                          {intl.get('REQUEST_PAGE.LINKED_PROJECTS.TABLE.VIEW')}
                        </Button>
                      ),
                      className: 'w-56',
                    },
                  ]
                : []),
            ],
          })),
        }}
      />
    </div>
  );
};

export default LinkedProjectsTable;
