import { useEffect, useState, useCallback, useMemo } from 'react';
import get from 'lodash/get';
import intl from 'react-intl-universal';
import organizationAPI from 'state/Organization/organizationAPI';
import { PortalSettings } from 'utils/customTypes';

const usePortalSettings: (organizationId: string) => {
  isFetching: boolean;
  portalLogo: string;
  shouldUseDefaultPortalBackground: boolean;
  portalBackground: string;
  intakePortalName: string;
} = (organizationId: string) => {
  const [portalSettings, setPortalSettings] = useState<PortalSettings>({});
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const init = useCallback(async () => {
    const settings = await organizationAPI.fetchPortalSettings(organizationId);
    setPortalSettings(settings);
    setIsFetching(false);
  }, [organizationId]);

  useEffect(() => {
    if (organizationId) {
      init();
    }
  }, [init, organizationId]);

  const resp = useMemo(
    () => ({
      isFetching: isFetching,
      portalLogo: get(portalSettings, 'portalLogo.url', null),
      shouldUseDefaultPortalBackground: get(
        portalSettings,
        'portalBackground.useDefault',
        false
      ),
      portalBackground: get(portalSettings, 'portalBackground.image.url', null),
      intakePortalName: get(portalSettings, 'intakePortalName')
        ? get(portalSettings, 'intakePortalName')
        : intl.get('REQUEST_PORTAL_DEFAULT_NAME'),
    }),
    [portalSettings, isFetching]
  );

  return resp;
};

export default usePortalSettings;
