import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state/store';

type variant = 'information' | 'success' | 'error' | 'warning';

type SnackBarNotification = {
  notificationVariant: variant;
  notificationTitle: string;
  notificationMessage?: string;
  showNotification: boolean;
  timeout?: number;
  autoHide?: boolean;
};

/* ============================= INITIAL STATE ============================== */
const initialState: SnackBarNotification = {
  notificationVariant: 'success',
  notificationTitle: '',
  notificationMessage: '',
  showNotification: false,
  timeout: 0,
  autoHide: true,
};

/* ================================= REDUCER ================================ */
const snackbarNotificationSlice = createSlice({
  name: 'snackbarNotification',
  initialState,
  reducers: {
    hideNotification: (state) => {
      state.showNotification = false;
    },
    showNotification: (
      state,
      action: {
        payload: Omit<SnackBarNotification, 'showNotification'>;
      }
    ) => {
      const {
        notificationMessage,
        notificationTitle,
        notificationVariant,
        autoHide = true,
        timeout,
      } = action.payload;
      state.notificationVariant = notificationVariant;
      state.notificationTitle = notificationTitle;
      state.notificationMessage = notificationMessage;
      state.timeout = autoHide ? timeout || 4000 : 0;
      state.autoHide = autoHide;
      state.showNotification = true;
    },
  },
});

/* ================================ ACTIONS ================================= */
export const { hideNotification, showNotification } =
  snackbarNotificationSlice.actions;

/* =============================== SELECTORS ================================ */
export const getSnackBarNotificationData = (state: RootState) => {
  const {
    notificationTitle,
    notificationMessage,
    notificationVariant,
    timeout,
    autoHide,
  } = state.snackbarNotification;
  return {
    notificationTitle,
    notificationMessage,
    notificationVariant,
    timeout,
    autoHide,
  };
};

export const getSnackBarNotificationState = (state: RootState) =>
  state.snackbarNotification.showNotification;

export default snackbarNotificationSlice.reducer;
