import { Typography } from '@getsynapse/design-system';
import { Icon } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import chartError from 'assets/icons/chartError.svg';

const ErrorScreen = () => {
  return (
    <div className='flex items-center justify-center w-full h-full px-4'>
      <Icon src={chartError} className='text-lg mr-2 min-w-6' />

      <Typography variant='label' className='text-base'>
        {intl.get('INSIGHTS_PAGE.ERROR_MESSAGE')}
      </Typography>
    </div>
  );
};

export default ErrorScreen;
