import React from 'react';
import { tailwindOverride } from '@getsynapse/design-system';

const SkeletonLoader = () => {
  return (
    <div
      className={tailwindOverride(
        'w-full rounded',
        'border border-primary-lighter-two'
      )}
      aria-label='daily-teams-capacity__skeleton-loader'
      data-cy='daily-teams-capacity__skeleton-loader'
    >
      {/* Table Header */}
      <div className='w-full flex bg-neutral-lightest'>
        <div className='w-2/6 h-20 flex'>
          <div className='w-12 h-full px-4 py-3 animate-pulse'>
            <div className='w-4 h-4 rounded-sm bg-neutral-lighter-two' />
          </div>
          <div className='w-3/5 h-full px-4 py-3 animate-pulse'>
            <div className='w-7/12 h-4 rounded-sm bg-neutral-lighter-two' />
          </div>
          <div className='w-2/5 h-full px-4 py-3 animate-pulse'>
            <div className='w-10/12 h-4 rounded-sm bg-neutral-lighter-two' />
          </div>
        </div>
        <div className='w-4/6 h-20 flex'>
          <div className='w-6 h-full' />
          <div className='flex flex-1 h-20'>
            {/* Week 1 */}
            <div className='w-5/12 h-20'>
              <div
                className={tailwindOverride(
                  'w-full h-10 animate-pulse',
                  'flex items-center justify-center'
                )}
              >
                <div className='w-1/4 h-4 rounded-sm bg-neutral-lighter-two' />
              </div>
              <div className='w-full h-10 flex'>
                <div
                  className={tailwindOverride(
                    'w-1/12 h-full bg-secondary-lightest animate-pulse',
                    'flex justify-center items-center flex-shrink-0 flex-grow'
                  )}
                >
                  <div className='w-3/4 h-4 rounded-sm bg-secondary-lighter' />
                </div>
                {Array.from(Array(4)).map((_, index: number) => (
                  <div
                    key={`week-headers__week-1__day-${index}`}
                    className={tailwindOverride(
                      'w-1/12 h-full flex justify-center items-center',
                      'flex-shrink-0 flex-grow animate-pulse'
                    )}
                  >
                    <div className='w-3/4 h-4 rounded-sm bg-neutral-lighter-two' />
                  </div>
                ))}
              </div>
            </div>
            {/* Week 2 */}
            <div className='w-5/12 h-20'>
              <div
                className={tailwindOverride(
                  'w-full h-10 animate-pulse',
                  'flex items-center justify-center'
                )}
              >
                <div className='w-1/4 h-4 rounded-sm bg-neutral-lighter-two' />
              </div>
              <div className='w-full h-10 flex'>
                {Array.from(Array(5)).map((_, index: number) => (
                  <div
                    key={`week-headers__week-2__day-${index}`}
                    className={tailwindOverride(
                      'w-1/12 h-full flex justify-center items-center',
                      'flex-shrink-0 flex-grow animate-pulse'
                    )}
                  >
                    <div className='w-3/4 h-4 rounded-sm bg-neutral-lighter-two' />
                  </div>
                ))}
              </div>
            </div>
            {/* Week 3 */}
            <div className='w-2/12 h-20'>
              <div
                className={tailwindOverride(
                  'w-full h-10 animate-pulse',
                  'flex items-center justify-center'
                )}
              >
                <div className='w-1/2 h-4 rounded-sm bg-neutral-lighter-two' />
              </div>
              <div className='w-full h-10 flex'>
                {Array.from(Array(2)).map((_, index: number) => (
                  <div
                    key={`week-headers__week-3__day-${index}`}
                    className={tailwindOverride(
                      'w-1/12 h-full flex justify-center items-center',
                      'flex-shrink-0 flex-grow animate-pulse'
                    )}
                  >
                    <div className='w-3/4 h-4 rounded-sm bg-neutral-lighter-two' />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='w-6 h-full' />
        </div>
      </div>
      {/* Table Body */}
      <div className='w-full h-14 flex bg-neutral-white'>
        <div className='w-2/6 h-14 flex'>
          <div className='w-12 h-full px-4 flex items-center animate-pulse'>
            <div className='w-4 h-4 rounded-sm bg-neutral-lighter-two' />
          </div>
          <div className='w-3/5 h-full px-4 flex items-center animate-pulse'>
            <div className='w-4/5 h-4 ounded-sm bg-neutral-lighter-two' />
          </div>
          <div className='w-2/5 h-full' />
        </div>
        <div className='w-4/6 h-14 flex'>
          <div className='w-6 h-full' />
          <div className='w-full h-full flex'>
            {Array.from(Array(12)).map((_, index: number) => (
              <div
                key={`team-0__days-carousel__day-${index}`}
                className={tailwindOverride(
                  'w-1/12 h-full flex-shrink-0 flex-grow',
                  'flex px-4 py-3 animate-pulse',
                  { 'bg-secondary-lightest': index === 0 },
                  { 'bg-neutral-white': index !== 0 }
                )}
              />
            ))}
          </div>
          <div className='w-6 h-full' />
        </div>
      </div>
      {/* Team Member */}
      <div className='w-full h-25 flex bg-neutral-lightest-two'>
        <div className='w-2/6 h-14 flex'>
          <div className='w-12 h-full px-4 py-3 flex items-start animate-pulse'>
            <div className='w-4 h-4 rounded-sm bg-neutral-lighter-two' />
          </div>
          <div className='w-3/5 h-full px-4 py-3 flex animate-pulse gap-x-2'>
            <div className='w-6 h-6 rounded-full bg-neutral-lighter-two' />
            <div className='w-full h-full'>
              <div className='w-4/5 h-4 rounded-sm bg-neutral-lighter-two' />
              <div className='w-3/5 h-4 mt-1 rounded-sm bg-neutral-lighter-two' />
              <div className='w-2/5 h-4 mt-3 rounded-sm bg-neutral-lighter-two' />
            </div>
          </div>
          <div className='w-2/5 h-full px-4 py-3 animate-pulse'>
            <div className='w-3/5 h-4 rounded-sm bg-neutral-lighter-two' />
            <div className='w-4/5 h-4 mt-2 rounded-sm bg-neutral-lighter-two' />
          </div>
        </div>
        <div className='w-4/6 h-full flex'>
          <div className='w-6 h-full' />
          <div className='w-full h-full flex'>
            {Array.from(Array(12)).map((_, index: number) => (
              <div
                key={`team-0__days-carousel__day-${index}`}
                className={tailwindOverride(
                  'w-1/12 h-full flex-shrink-0 flex-grow',
                  'flex px-4 py-3 animate-pulse items-center',
                  { 'bg-secondary-lightest': index === 0 },
                  { 'bg-neutral-lightest-two': index !== 0 }
                )}
              >
                <div
                  className={tailwindOverride(
                    'w-full h-6 rounded-sm',
                    { 'bg-primary-lighter': index !== 0 },
                    { 'bg-secondary-lighter': index === 0 }
                  )}
                />
              </div>
            ))}
          </div>
          <div className='w-6 h-full' />
        </div>
      </div>
      <div className='w-full h-14 flex bg-neutral-white'>
        <div className='w-2/6 h-14 flex'>
          <div className='w-12 h-full px-4 flex items-center animate-pulse'>
            <div className='w-4 h-4 rounded-sm bg-neutral-lighter-two' />
          </div>
          <div className='w-3/5 h-full px-4 flex items-center animate-pulse'>
            <div className='w-4/5 h-4 ounded-sm bg-neutral-lighter-two' />
          </div>
          <div className='w-2/5 h-full' />
        </div>
        <div className='w-4/6 h-14 flex'>
          <div className='w-6 h-full' />
          <div className='w-full h-full flex'>
            {Array.from(Array(12)).map((_, index: number) => (
              <div
                key={`team-0__days-carousel__day-${index}`}
                className={tailwindOverride(
                  'w-1/12 h-full flex-shrink-0 flex-grow',
                  'flex px-4 py-3 animate-pulse',
                  { 'bg-secondary-lightest': index === 0 },
                  { 'bg-neutral-white': index !== 0 }
                )}
              />
            ))}
          </div>
          <div className='w-6 h-full' />
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
