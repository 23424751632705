import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { selectLearningTeams } from 'state/LearningTeams/learningTeamsSlice';
import { selectUserById } from 'state/UsersManagement/usersManagementSlice';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {
  Table,
  Tooltip,
  Icon,
  Button,
  OverflowMenu,
  OverflowMenuItem,
  Typography,
  tailwindOverride,
} from '@getsynapse/design-system';
import { Project, SortingType } from 'utils/customTypes';
import {
  DATE,
  PROJECTS_TABLE_TABS,
  PROJECT_STATUS,
  PROJECTS_TABLE_SORTABLE_COLUMNS,
  PROJECT_PARTICIPANT_TYPE,
  PROJECTS_LIST_ACTIONS,
  PATHS,
} from 'utils/constants';
import {
  getStatusColumn,
  getOwnerColumn,
  getResourcingTypeLabel,
  getProjectBusinessUnitColumn,
  getTeamsColumn,
  getPriorityLabel,
} from '../../helpers/tableColumnsValues';
import {
  isProjectDueDatePast,
  isNotStartedProjectStartDatePast,
} from '../../helpers/helpers';
import {
  setSortingOrders,
  teamSearch,
  teamProjectsTableFilters,
} from 'state/Projects/projectsSlice';
import { setProjectId } from 'state/Project/projectSlice';
import { getOrganizationProcesses } from 'state/Processes/processesSlice';
import { isUserOrganizationAdmin, selectIsUserLd } from 'state/User/userSlice';
import emptyProjectsTable from 'assets/icons/empty-projects.svg';
import noProjectsFound from 'assets/images/no-projects-found-table.svg';
import emptyFilteredProjects from 'assets/images/empty-filtered-projects-table.svg';
import informationIcon from 'assets/icons/information.svg';
import HealthLabel from 'Molecules/HealthLabel';
import { getUserAccessOnProject } from '../../helpers/getUserParticipantType';
import RenderNoRecords from 'Pages/RequestsListPage/components/NoRecords';
import UnarchiveIcon from 'assets/icons/unarchive.svg';
import './styles.css';
import { Link, useHistory } from 'react-router-dom';
import ProjectSideDrawerToggle from '../ProjectSideDrawerToggle';
import { formatProjectNumber } from 'utils/formatters';

const TeamProjectsTable: React.FC<{
  projectsList: Project[];
  addNewProject?: () => void;
  onDuplicateProject: (projectId: string) => void;
  onArchiveProject: (projectId: string) => void;
  totalProjects?: number;
}> = ({
  projectsList,
  addNewProject,
  onDuplicateProject,
  onArchiveProject,
  totalProjects = 0,
}) => {
  const isProjectsListEmpty = projectsList.length === 0;
  const searchValue = useSelector(teamSearch);
  const filterValues = useSelector(teamProjectsTableFilters);
  const isLDUser = useSelector(selectIsUserLd);
  const userData = useSelector(selectUserById);
  const isAdmin = useSelector(isUserOrganizationAdmin);
  const ldTeams = useSelector(selectLearningTeams);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getOrganizationProcesses());
  }, [dispatch]);

  const handleSort = (orderByParam: string, order: SortingType) => {
    dispatch(
      setSortingOrders(
        { order, orderBy: orderByParam },
        PROJECTS_TABLE_TABS.TEAM_PROJECTS
      )
    );
  };

  const onOpenProjectPanel = async (projectId: string) => {
    await dispatch(setProjectId(projectId));
  };

  const goToProjectPage = (projectId: string) =>
    history.push(`${PATHS.PROJECT_PAGE}/${projectId}`, {
      from: history.location.pathname,
    });

  return (
    <div
      className={classnames('w-full rounded-b', {
        'overflow-auto border border-neutral-lighter-two': !isProjectsListEmpty,
        'overflow-hidden border-r border-neutral-lighter-two':
          isProjectsListEmpty,
        'max-h-no-filtered-table-body': isEmpty(filterValues),
        'max-h-filtered-table-body': !isEmpty(filterValues),
      })}
    >
      <Table
        className={classnames('relative', {
          'max-w-full border-0': !isProjectsListEmpty,
          'w-full': isProjectsListEmpty,
        })}
        canSelectRows={false}
        data={{
          headData: {
            stickyHeader: true,
            handleSort,
            headCells: [
              {
                'data-cy': 't-header__project-number',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.PROJECT_NUMBER,
                content: intl.get(
                  'PROJECTS_LIST_PAGE.TABLE.HEAD.PROJECT_NUMBER'
                ),
                className: classnames('h-10', {
                  'w-32 left-0 z-2': !isProjectsListEmpty,
                }),
              },
              {
                'data-cy': 't-header__project-name',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.TITLE,
                content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.PROJECT_NAME'),
                className: classnames('h-10', {
                  'w-72 left-40 z-2': !isProjectsListEmpty,
                }),
              },
              {
                'data-cy': 't-header__status',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.STATUS,
                content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.STATUS'),
                className: classnames('h-10', {
                  'w-28 left-120 z-2': !isProjectsListEmpty,
                }),
              },
              {
                'data-cy': 't-header__priority',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.PRIORITY,
                content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.PRIORITY'),
                className: classnames({ 'w-32': !isProjectsListEmpty }),
              },
              {
                'data-cy': 't-header__owner',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.PROJECT_OWNER,
                content: intl.get(
                  'PROJECTS_LIST_PAGE.TABLE.HEAD.PROJECT_OWNER'
                ),
                className: classnames({ 'w-44': !isProjectsListEmpty }),
              },
              {
                'data-cy': 't-header__health',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.HEALTH,
                content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.HEALTH'),
                className: classnames({ 'w-32': !isProjectsListEmpty }),
              },
              {
                'data-cy': 't-header__business-unit',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.BUSINESS_UNIT,
                content: intl.get(
                  'PROJECTS_LIST_PAGE.TABLE.HEAD.BUSINESS_UNIT'
                ),
                className: classnames({ 'w-56': !isProjectsListEmpty }),
              },
              {
                'data-cy': 't-header__teams',
                content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.TEAMS'),
                className: classnames('bg-primary-lightest', {
                  'w-40': !isProjectsListEmpty,
                }),
              },
              {
                'data-cy': 't-header__category',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.PROJECT_CATEGORY,
                content: intl.get(
                  'PROJECTS_LIST_PAGE.TABLE.HEAD.PROJECT_CATEGORY'
                ),
                className: classnames({ 'w-48': !isProjectsListEmpty }),
              },
              {
                'data-cy': 't-header__start-date',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.START_DATE,
                content: intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.START_DATE'),
                className: classnames({ 'w-32': !isProjectsListEmpty }),
              },
              {
                'data-cy': 't-header__target-completion-date',
                columnName:
                  PROJECTS_TABLE_SORTABLE_COLUMNS.TARGET_COMPLETION_DATE,
                content: intl.get(
                  'PROJECTS_LIST_PAGE.TABLE.HEAD.TARGET_COMPLETION_DATE'
                ),
                className: classnames({ 'w-36': !isProjectsListEmpty }),
              },
              {
                'data-cy': 't-header__actual-completion-date',
                columnName:
                  PROJECTS_TABLE_SORTABLE_COLUMNS.ACTUAL_COMPLETION_DATE,
                content: intl.get(
                  'PROJECTS_LIST_PAGE.TABLE.HEAD.ACTUAL_COMPLETION_DATE'
                ),
                className: classnames({ 'w-40': !isProjectsListEmpty }),
              },
              {
                'data-cy': 't-header__resourcing-type',
                content: intl.get(
                  'PROJECTS_LIST_PAGE.TABLE.HEAD.RESOURCING_TYPE'
                ),
                className: classnames('bg-primary-lightest', {
                  'w-36': !isProjectsListEmpty,
                }),
              },
              {
                'data-cy': 't-header__process',
                columnName: PROJECTS_TABLE_SORTABLE_COLUMNS.PROCESS_STAGE,
                content: intl.get(
                  'PROJECTS_LIST_PAGE.TABLE.HEAD.PROCESS_STAGE'
                ),
                className: classnames({ 'w-36': !isProjectsListEmpty }),
              },
              {
                'data-cy': 't-header__stage',
                content: intl.get(
                  'PROJECTS_LIST_PAGE.TABLE.HEAD.PROJECT_STAGE'
                ),
                className: classnames('bg-primary-lightest', {
                  'w-36': !isProjectsListEmpty,
                }),
              },
              {
                content: <div></div>,
                className: classnames('h-10 bg-primary-lightest z-2', {
                  'w-12 right-0': !isProjectsListEmpty,
                }),
              },
            ],
          },
          rows: projectsList.map((project: Project, index: number) => {
            const { permissionLevel } = getUserAccessOnProject(
              project,
              userData,
              ldTeams
            );
            const isOdd = index % 2 !== 0;
            const isInProgressOrCompleted =
              project.status === PROJECT_STATUS.IN_PROGRESS ||
              project.status === PROJECT_STATUS.COMPLETED;

            const projectDueDateIsPast = isProjectDueDatePast(
              project.status,
              project.targetCompletionDate
            );

            const notStartedProjectStartDateIsPast =
              isNotStartedProjectStartDatePast(
                project.status,
                project.startDate
              );

            const stickyCellsStyles = (className: string) =>
              classnames(
                'border-transparent',
                {
                  'sticky z-1': !isProjectsListEmpty,
                  'bg-neutral-white': !isOdd,
                  'bg-neutral-lightest-two': isOdd,
                },
                className
              );

            const overflowMenuItems: {
              label: string;
              iconName?: string;
              iconSrc?: string;
              onSelect: () => void;
              action: typeof PROJECTS_LIST_ACTIONS[keyof typeof PROJECTS_LIST_ACTIONS];
            }[] = [
              {
                label: intl.get('DUPLICATE_PROJECT_MODAL.TITLE'),
                iconName: 'copy-outline',
                onSelect: () => onDuplicateProject(project.id),
                action: PROJECTS_LIST_ACTIONS.DUPLICATE,
              },
            ];

            const canUserArchiveOrUnarchiveProject =
              project.isProjectPartOfUserPrograms ||
              isAdmin ||
              permissionLevel === PROJECT_PARTICIPANT_TYPE.OWNER;

            if (!project.is_archived && canUserArchiveOrUnarchiveProject) {
              overflowMenuItems.push({
                label: intl.get('ARCHIVE_PROJECT_MODAL.TITLE', {
                  action: intl.get('ARCHIVE'),
                }),
                iconName: 'archive',
                onSelect: () => onArchiveProject(project.id),
                action: PROJECTS_LIST_ACTIONS.ARCHIVE,
              });
            }

            if (project.is_archived && canUserArchiveOrUnarchiveProject) {
              overflowMenuItems.push({
                label: intl.get('ARCHIVE_PROJECT_MODAL.TITLE', {
                  action: intl.get('UNARCHIVE'),
                }),
                iconSrc: UnarchiveIcon,
                onSelect: () => onArchiveProject(project.id),
                action: PROJECTS_LIST_ACTIONS.UNARCHIVE,
              });
            }

            return {
              id: project.id,
              'data-cy': `project-${project.id}`,
              className: classnames('group cursor-pointer relative'),
              onClick: () => goToProjectPage(project.id),
              cells: [
                {
                  content: (
                    <div
                      className={classnames({
                        'w-32 h-4': !isProjectsListEmpty,
                      })}
                    >
                      {formatProjectNumber(project.projectNumber)}
                    </div>
                  ),
                  className: stickyCellsStyles(
                    classnames({ 'w-32 left-0': !isProjectsListEmpty })
                  ),
                },
                {
                  content: (
                    <div
                      className={classnames('truncate', {
                        'w-72 h-4': !isProjectsListEmpty,
                        'w-full': isProjectsListEmpty,
                      })}
                    >
                      <Link
                        to={{
                          pathname: `${PATHS.PROJECT_PAGE}/${project.id}`,
                          state: {
                            from: history.location.pathname,
                          },
                        }}
                        title={project.title}
                      >
                        {project.title}
                      </Link>
                    </div>
                  ),
                  className: stickyCellsStyles(
                    classnames({ 'w-72 left-40': !isProjectsListEmpty })
                  ),
                  'data-cy': `project-${project.id}-title`,
                },
                {
                  content: (
                    <div
                      className={classnames({
                        'w-28': !isProjectsListEmpty,
                      })}
                    >
                      {getStatusColumn(project.status)}
                    </div>
                  ),
                  className: stickyCellsStyles(
                    classnames({ 'w-28 left-120': !isProjectsListEmpty })
                  ),
                },
                {
                  content: getPriorityLabel(project.priority),
                  className: classnames({
                    'w-36': !isProjectsListEmpty,
                  }),
                },
                {
                  content: project.owners && getOwnerColumn(project.owners),
                  className: classnames({ 'w-40': !isProjectsListEmpty }),
                },
                {
                  content: isInProgressOrCompleted && (
                    <HealthLabel health={project?.health} />
                  ),
                  className: classnames({ 'w-36': !isProjectsListEmpty }),
                },
                {
                  content: (
                    <div className='w-56 truncate'>
                      {getProjectBusinessUnitColumn(
                        project.businessTeams || []
                      )}
                    </div>
                  ),
                  className: classnames({ 'w-56': !isProjectsListEmpty }),
                },
                {
                  content: (
                    <div className='w-40 truncate'>
                      {getTeamsColumn(project.privacy!, project.ldteams || [])}
                    </div>
                  ),
                  className: classnames({
                    'w-40': !isProjectsListEmpty,
                  }),
                },
                {
                  content: project.category,
                },
                {
                  content: project.startDate && (
                    <div
                      className={
                        notStartedProjectStartDateIsPast
                          ? 'text-warning-darker flex items-center'
                          : ''
                      }
                    >
                      {moment(new Date(project.startDate)).format(
                        DATE.SHORT_FORMAT
                      )}
                      {notStartedProjectStartDateIsPast && (
                        <Tooltip
                          openMode='hover1'
                          position='topCenter'
                          className='ml-auto'
                          timeout={0}
                          showPopper
                          trigger={
                            <span>
                              <Icon src={informationIcon} />
                            </span>
                          }
                          contentProps={{
                            className:
                              'bg-warning-lightest text-warning-darker rounded text-sm w-60 whitespace-normal z-50',
                          }}
                        >
                          {intl.getHTML(`PROJECTS_LIST_PAGE.PAST_START_DATE`)}
                        </Tooltip>
                      )}
                    </div>
                  ),
                  className: classnames({ 'w-32': !isProjectsListEmpty }),
                },
                {
                  content: project.targetCompletionDate && (
                    <span
                      className={
                        projectDueDateIsPast
                          ? 'bg-error-lightest text-error-darker'
                          : ''
                      }
                    >
                      {moment(new Date(project.targetCompletionDate)).format(
                        DATE.SHORT_FORMAT
                      )}
                    </span>
                  ),
                  className: classnames({ 'w-32': !isProjectsListEmpty }),
                },
                {
                  content:
                    project?.actualDate &&
                    moment(new Date(project.actualDate)).format(
                      DATE.SHORT_FORMAT
                    ),
                  className: classnames({ 'w-32': !isProjectsListEmpty }),
                },
                {
                  content: getResourcingTypeLabel(project?.resourcing_type),
                  className: classnames({
                    'w-36': !isProjectsListEmpty,
                  }),
                },
                {
                  content: (
                    <div className='w-36 truncate'>{project.process}</div>
                  ),
                  className: classnames({ 'w-36': !isProjectsListEmpty }),
                },
                {
                  content: project.stage,
                  className: classnames({ 'w-36': !isProjectsListEmpty }),
                },
                {
                  content: (
                    <div
                      className={classnames(
                        'h-full text-center relative flex space-x-2',
                        {
                          'w-12': !isProjectsListEmpty,
                          'w-full': isProjectsListEmpty,
                        }
                      )}
                      onClick={(event: React.MouseEvent<HTMLInputElement>) =>
                        event.stopPropagation()
                      }
                    >
                      <ProjectSideDrawerToggle
                        projectId={project.id}
                        onOpenProjectPanel={onOpenProjectPanel}
                      />
                      {isLDUser && (
                        <OverflowMenu
                          menuListProps={{
                            'data-cy': `project-${project.id}__actions-popover`,
                          }}
                          menuButtonProps={{
                            'data-cy': `project-${project.id}__actions-popover-trigger`,
                          }}
                        >
                          {overflowMenuItems.map((item) => (
                            <OverflowMenuItem
                              key={item.label}
                              className='flex items-center space-x-2'
                              onSelect={item.onSelect}
                              data-cy={`project-${project.id}__actions-popover__${item.action}`}
                            >
                              <Icon
                                name={item.iconName || ''}
                                src={item.iconSrc || ''}
                                className={tailwindOverride(
                                  'fill-current text-primary',
                                  'group-hover:fill-current group-hover:text-secondary-darker',
                                  'text-lg',
                                  {
                                    'text-xl': item.iconSrc,
                                  }
                                )}
                              />
                              <Typography
                                variant='label'
                                className={tailwindOverride(
                                  'group-hover:text-secondary-darker',
                                  'cursor-pointer'
                                )}
                              >
                                {item.label}
                              </Typography>
                            </OverflowMenuItem>
                          ))}
                        </OverflowMenu>
                      )}
                    </div>
                  ),
                  className: stickyCellsStyles(classnames('w-6 right-0')),
                },
              ],
            };
          }),
          total: totalProjects,
        }}
        data-cy='team-projects-table'
        emptyComponent={
          !isEmpty(searchValue) || !isEmpty(filterValues) ? (
            <RenderNoRecords
              imageSrc={
                !isEmpty(searchValue) ? noProjectsFound : emptyFilteredProjects
              }
              className={
                !isEmpty(searchValue)
                  ? 'h-empty-table-body'
                  : 'h-empty-filtered-table-body'
              }
              caption={intl.get('REQUESTS_LIST_PAGE.NO_RECORDS')}
            />
          ) : (
            <RenderNoRecords
              imageSrc={emptyProjectsTable}
              imageClassName='-ml-4'
              className='h-empty-table-body'
              caption={intl.get('PROJECTS_LIST_PAGE.TABLE.EMPTY')}
              labelClassName='mt-0'
            >
              <Button className='mt-2 mx-auto' onClick={addNewProject}>
                {intl.get('PROJECTS_LIST_PAGE.TABLE.GET_STARTED')}
              </Button>
            </RenderNoRecords>
          )
        }
      />
    </div>
  );
};

export default TeamProjectsTable;
