import React, {
  useMemo,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from 'react';
import intl from 'react-intl-universal';
import {
  Typography,
  FormItem,
  Datepicker,
  Dropdown,
} from '@getsynapse/design-system';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { TimeOff, Option, rangeDate } from 'utils/customTypes';
import { TIME_OFF_TYPE } from 'utils/constants';
import UnsavedChangesBanner from 'Atoms/UnsavedChangesBanner/UnsavedChangesBanner';

const TimeOffForm: React.FC<{
  originalTimeOff: TimeOff;
  currentTimeOff: TimeOff;
  setCurrentTimeOff: Dispatch<SetStateAction<TimeOff>>;
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>;
  unsavedChanges?: boolean;
  showUnsavedChangesAnimation: boolean;
  shouldDisableUpdate: boolean;
  setShouldDisableUpdate: Dispatch<SetStateAction<boolean>>;
}> = ({
  originalTimeOff,
  setCurrentTimeOff,
  currentTimeOff,
  unsavedChanges = false,
  showUnsavedChangesAnimation,
  setUnsavedChanges,
  shouldDisableUpdate,
  setShouldDisableUpdate,
}) => {
  const options = useMemo(
    () =>
      Object.keys(TIME_OFF_TYPE).map((type) => ({
        value: type,
        label: intl.get(`TEAMS.TIME_OFF.TIME_OFF_TYPES.${type}`),
      })),
    []
  );

  const selectedOption = currentTimeOff?.timeOffType
    ? [
        {
          value: currentTimeOff.timeOffType,
          label: intl.get(
            `TEAMS.TIME_OFF.TIME_OFF_TYPES.${currentTimeOff.timeOffType}`
          ),
        },
      ]
    : [];

  useEffect(() => {
    if (!isEmpty(originalTimeOff)) {
      setCurrentTimeOff(originalTimeOff);
    }
  }, [originalTimeOff, setCurrentTimeOff]);

  const validateRequiredFields = useCallback((currentTimeOff) => {
    let canSave = true;
    if (!isEmpty(currentTimeOff)) {
      const requiredFields = ['timeOffType', 'startDate', 'endDate'];
      requiredFields.forEach((field) => {
        let value = currentTimeOff[field];
        if (value instanceof Date) {
          value = value.toString();
        }
        canSave = !isEmpty(value) && canSave;
      });
    }
    return canSave;
  }, []);

  useEffect(() => {
    const changesDetected = !isEqual(originalTimeOff, currentTimeOff);
    setUnsavedChanges(changesDetected);
    if (changesDetected) {
      let canSave = false;
      canSave = validateRequiredFields(currentTimeOff);
      if (shouldDisableUpdate && canSave) {
        setShouldDisableUpdate(false);
      }
      if (!canSave && !shouldDisableUpdate) {
        setShouldDisableUpdate(true);
      }
    }
  }, [
    originalTimeOff,
    currentTimeOff,
    setShouldDisableUpdate,
    setUnsavedChanges,
    shouldDisableUpdate,
    validateRequiredFields,
  ]);

  const updateTimeOff = (key: string, value: string | number | rangeDate) => {
    setCurrentTimeOff((prev) => {
      const updatedState = { ...prev } as TimeOff;
      if (key === 'dates') {
        const { startDate = '', endDate = '' } = value as rangeDate;
        updatedState.startDate = startDate
          ? moment(startDate).format('YYYY-MM-DD HH:mm:ss')
          : (startDate as string);
        updatedState.endDate = endDate
          ? moment(endDate).format('YYYY-MM-DD HH:mm:ss')
          : (endDate as string);
      } else {
        updatedState[key] = value;
      }
      return updatedState;
    });
  };

  return (
    <div className='flex w-full h-full flex-col py-6'>
      {unsavedChanges && (
        <UnsavedChangesBanner
          showEmphasisAnimation={showUnsavedChangesAnimation}
          message={intl.get('SIDE_PANEL.UNSAVED_CHANGES')}
        />
      )}
      <Typography
        variant='h4'
        className='text-primary mt-6 mb-8'
        weight='medium'
      >
        {currentTimeOff?.id
          ? intl.get('USER_TIME_OFF_PAGE.FORM.TITLE', {
              action: intl.get('EDIT'),
            })
          : intl.get('USER_TIME_OFF_PAGE.FORM.TITLE', {
              action: intl.get('ADD'),
            })}
      </Typography>
      <FormItem>
        <Datepicker
          className='w-full'
          canSelectRange
          startDate={
            currentTimeOff?.startDate && new Date(currentTimeOff.startDate)
          }
          startDateLabel={intl.get(
            'USER_TIME_OFF_PAGE.TABLE.COLUMNS.START_DATE'
          )}
          startDateLabelProps={{
            required: true,
          }}
          endDate={currentTimeOff?.endDate && new Date(currentTimeOff.endDate)}
          endDateLabel={intl.get('USER_TIME_OFF_PAGE.TABLE.COLUMNS.END_DATE')}
          endDateLabelProps={{
            required: true,
          }}
          size='large'
          onPickDate={(value: rangeDate) => updateTimeOff('dates', value)}
          data-cy='time-off-form__dates-field'
        />
      </FormItem>
      <FormItem
        className='mt-4'
        label={intl.get('USER_TIME_OFF_PAGE.TABLE.COLUMNS.TYPE')}
        labelProps={{ required: true }}
      >
        <Dropdown
          options={options}
          onChange={(option: Option) =>
            updateTimeOff('timeOffType', option.value)
          }
          values={selectedOption}
          placeholder={intl.get('USER_TIME_OFF_PAGE.FORM.TYPE_PLACEHOLDER')}
          triggerProps={{
            'aria-label': intl.get('USER_TIME_OFF_PAGE.TABLE.COLUMNS.TYPE'),
            'data-cy': 'time-off-form__type-field',
          }}
          listProps={{ 'data-cy': 'time-off-form__type-field-list' }}
        />
      </FormItem>
    </div>
  );
};

export default TimeOffForm;
