import { Link } from 'react-router-dom';
import { Typography, tailwindOverride, Icon } from '@getsynapse/design-system';
import PriorityLabel from 'Molecules/PriorityLabel';
import HealthLabel from 'Molecules/HealthLabel';
import { TeamMemberProject as TeamMemberProjectType } from 'utils/customTypes';
import { PATHS, DATE } from 'utils/constants';
import projectStatus from 'assets/icons/project-status.svg';
import calendar from 'assets/icons/calendar.svg';
import moment from 'moment';
import intl from 'react-intl-universal';

type TeamMemberProjectProps = {
  project: TeamMemberProjectType;
};

const TeamMemberProject = ({ project }: TeamMemberProjectProps) => {
  return (
    <div
      className={tailwindOverride(
        'w-37-percent shadow-allocation-table-inner',
        'grid grid-cols-3 grid-rows-1 py-2',
        'border-b border-neutral-lighter'
      )}
    >
      <div className='col-span-2 shadow-table-column px-2 mr-2'>
        <div className='flex justify-between'>
          <Link to={`${PATHS.PROJECT_PAGE}/${project.id}`}>
            <Typography variant='caption' className='text-purple-darker'>
              {project.title}
            </Typography>
          </Link>
        </div>
        <div className='flex space-x-4 mb-2'>
          {project.priority && <PriorityLabel priority={project.priority} />}
          <HealthLabel health={project.health} indicatorClassName='mr-1' />
          {project.status && (
            <div className='flex'>
              <Icon src={projectStatus} className='mr-1' />
              <Typography variant='caption'>
                {intl
                  .get(
                    `PROJECT_DETAIL.STATUS_OPTIONS.${project.status.toUpperCase()}`
                  )
                  .defaultMessage('')}
              </Typography>
            </div>
          )}
        </div>
        <div className='flex'>
          <Icon src={calendar} className='mr-1' />
          <Typography variant='caption'>
            {`${moment(project.startDate).format(DATE.SHORT_FORMAT)} - ${moment(
              project.targetCompletionDate
            ).format(DATE.SHORT_FORMAT)}`}
          </Typography>
        </div>
      </div>
      <div className='row-span-2 flex flex-col'>
        {project.roles.role.split(',').map((role: string) => (
          <Typography variant='caption' className='mb-5'>
            {role}
          </Typography>
        ))}
      </div>
    </div>
  );
};

export default TeamMemberProject;
