import React, { useState, useEffect, useCallback, useContext } from 'react';
import { ProjectPermissionsLevel } from 'utils/customTypes';
import {
  PROJECT_PARTICIPANT_TYPE,
  PROJECT_PERMISSIONS_BY_LEVEL,
} from 'utils/constants';

const UserPermissionsContext = React.createContext<
  | {
      canUser: (action: string) => boolean;
      hasUserPermissionsLevelOf: (
        permissionsLevel: ProjectPermissionsLevel
      ) => boolean;
    }
  | undefined
>(undefined);

export const UserPermissionsProvider: React.FC<{
  children: React.ReactNode;
  userPermissionsLevel: ProjectPermissionsLevel;
}> = ({ children, userPermissionsLevel }) => {
  const [permissionsLevel, setPermissionsLevel] =
    useState<ProjectPermissionsLevel>(PROJECT_PARTICIPANT_TYPE.NOT_PARTICIPANT);

  useEffect(() => {
    if (userPermissionsLevel) {
      setPermissionsLevel(userPermissionsLevel);
    }
  }, [userPermissionsLevel]);

  const canUser = useCallback(
    (action: string) => {
      const availableActions = PROJECT_PERMISSIONS_BY_LEVEL[permissionsLevel];
      return availableActions.includes(action);
    },
    [permissionsLevel]
  );

  const hasUserPermissionsLevelOf = useCallback(
    (permissions: ProjectPermissionsLevel) => permissions === permissionsLevel,
    [permissionsLevel]
  );

  return (
    <UserPermissionsContext.Provider
      value={{ canUser, hasUserPermissionsLevelOf }}
    >
      {children}
    </UserPermissionsContext.Provider>
  );
};

export const useUserPermissionsContext = () => {
  const context = useContext(UserPermissionsContext);
  if (context === undefined) {
    throw new Error('Something wrong with the permissions context');
  }
  return context;
};
