import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { Status } from 'utils/customTypes';
import {
  TeamMemberMap,
  MembersByTeam,
  TeamMemberWithLinks,
} from 'utils/types/dailyTeamsCapacity';
import { SLICE_STATUS } from 'utils/constants';
import {
  addIdsIfNotExistInArray,
  removeTeamFromMembersVisibleTeamsIds,
} from './helpers';
import teamMembersListAPI from './teamMembersListAPI';
import { resetCapacitiesState } from '../TeamsList/teamsListSlice';

interface TeamMemberListState {
  byTeam: MembersByTeam<TeamMemberWithLinks>;
  byId: TeamMemberMap;
  status: Status;
}

/* ============================= INITIAL STATE ============================== */
const initialState: TeamMemberListState = {
  byTeam: {},
  byId: {},
  status: SLICE_STATUS.IDLE,
};

/* =============================== ACTIONS ================================ */
export const removeTeamMembers = createAction<{
  teamId: string;
}>('capacities/teamMemberList/REMOVE_TEAM_MEMBERS');

/* ============================== REDUX THUNK =============================== */
export const fetchTeamMembersList = createAsyncThunk(
  'capacities/teamMemberList/FETCH_TEAM_MEMBERS_LIST',
  async (teamId: string, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      let url = `v2/teams/${teamId}/members?limit=10`;
      if (teamId in state.capacities.teamMembersList.byTeam) {
        url = state.capacities.teamMembersList.byTeam[teamId].links.next || url;
      }
      const response = await teamMembersListAPI.fetchTeamMembersList(url);
      const teamMembersById = response.teamMembers.reduce<TeamMemberMap>(
        (acc: TeamMemberMap, teamMember) => {
          const visibleTeamsIds = [];
          if (teamMember.id in state.capacities.teamMembersList.byId) {
            visibleTeamsIds.push(
              ...(state.capacities.teamMembersList.byId[teamMember.id]
                .visibleTeamsIds || [])
            );
          }
          acc[teamMember.id] = {
            firstName: teamMember.first_name,
            lastName: teamMember.last_name,
            email: teamMember.email,
            roles: teamMember.roles,
            isTeamManager: teamMember.is_team_manager,
            avatarUrl: teamMember.avatar_url,
            visibleTeamsIds: visibleTeamsIds.concat(teamId),
            hasProjects: teamMember.has_projects,
          };
          return acc;
        },
        {}
      );
      const allIds = Object.keys(teamMembersById);
      return {
        teamId,
        allIds,
        teamMembersById,
        links: response.links,
      };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

/* =============================== SELECTORS ================================ */
export const selectMemberIdsByTeam = (state: RootState, teamId: string) => {
  if (teamId in state.capacities.teamMembersList.byTeam) {
    return state.capacities.teamMembersList.byTeam[teamId].allIds;
  }
  return [];
};
export const selectTeamMemberById = (state: RootState, memberId: string) => {
  if (memberId in state.capacities.teamMembersList.byId) {
    return state.capacities.teamMembersList.byId[memberId];
  }
};
export const selectTeamIdFromMember = (state: RootState, memberId: string) => {
  if (memberId in state.capacities.teamMembersList.byId) {
    return state.capacities.teamMembersList.byId[memberId].visibleTeamsIds[0];
  }
};
export const selectTeamMembersListStatus = (state: RootState) =>
  state.capacities.teamMembersList.status;
export const selectNextTeamMembersListLink = (
  state: RootState,
  teamId: string
) => {
  if (teamId in state.capacities.teamMembersList.byTeam) {
    return state.capacities.teamMembersList.byTeam[teamId].links.next;
  }
  return null;
};

/* ================================= REDUCER ================================ */
const teamMembersListSlice = createSlice({
  name: 'teamMembersList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamMembersList.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchTeamMembersList.fulfilled, (state, action) => {
        if (action.payload) {
          const { teamId, allIds, teamMembersById, links } = action.payload;
          state.byTeam = {
            ...state.byTeam,
            [teamId]: {
              ...state.byTeam[teamId],
              allIds: addIdsIfNotExistInArray(
                allIds,
                state.byTeam[teamId]?.allIds || []
              ),
              links,
            },
          };
          state.byId = {
            ...state.byId,
            ...teamMembersById,
          };
        }
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchTeamMembersList.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(removeTeamMembers, (state, action) => {
        const teamId = action.payload.teamId;
        const updatedStateByTeam = { ...state.byTeam };
        const allIds = [];
        if (teamId in state.byTeam) {
          allIds.push(...(state.byTeam[teamId]?.allIds || []));
        }
        const updateStateById = removeTeamFromMembersVisibleTeamsIds(
          teamId,
          allIds,
          { ...state.byId }
        );
        delete updatedStateByTeam[teamId];
        state.byTeam = updatedStateByTeam;
        state.byId = updateStateById;
      })
      .addCase(resetCapacitiesState, (state) => {
        state.byTeam = initialState.byTeam;
        state.byId = initialState.byId;
        state.status = initialState.status;
      });
  },
});

export default teamMembersListSlice.reducer;
