import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { tailwindOverride } from '@getsynapse/design-system';
import {
  ScrollHandlerContext,
  ScrollHandlerContextType,
} from './ScrollHandlerContext';
import {
  selectScrollPosition,
  setScrollPosition,
} from 'state/Capacities/ScrollPosition/scrollPosition';

export type ScrollHandlerProps<T> = React.PropsWithChildren<{
  callbacks: T;
}>;

const ScrollHandler = <T extends ScrollHandlerContextType>({
  callbacks,
  children,
}: ScrollHandlerProps<T>) => {
  const dispatch = useDispatch();
  const scrollPosition = useSelector(selectScrollPosition);
  const previousScrollPosition = useRef<number>(0);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    previousScrollPosition.current = target.scrollTop;
    dispatch(setScrollPosition(target.scrollTop));
  };

  useEffect(() => {
    if (callbacks.rootId) {
      if (previousScrollPosition.current !== scrollPosition) {
        callbacks.rootId.scrollTop = scrollPosition;
      }
    }
  }, [scrollPosition, callbacks.rootId]);

  return (
    <div
      id='teams-capacity-table-scroll-container'
      data-cy='teams-capacity-table-scroll-container'
      ref={callbacks.setRootId}
      className={tailwindOverride(
        'w-full max-h-daily-capacity-table',
        'rounded border border-primary-lighter-two',
        'relative overflow-y-auto',
        'layout-cell layout-scrollbar'
      )}
      onScroll={handleScroll}
    >
      <ScrollHandlerContext.Provider value={callbacks}>
        {children}
      </ScrollHandlerContext.Provider>
    </div>
  );
};

export default ScrollHandler;
