import React from 'react';
import moment from 'moment';
import { Checkbox } from '@getsynapse/design-system';
import { PROJECT_STATUS, NEW_PROJECT_FORM_FIELDS } from 'utils/constants';
import { FormOption, rangeDateType, objKeyAsString } from 'utils/customTypes';

export const MultipleOptionLI: React.FC<{
  label: string;
  isSelected: boolean;
  selectOption: (option: any) => void;
}> = ({ label, isSelected = false, selectOption }) => (
  <Checkbox
    checked={isSelected}
    onChange={selectOption}
    label={label}
    inputProps={{
      className: `${
        isSelected &&
        'group-hover:border-neutral-white group-focus-visible:border-neutral-white'
      }`,
    }}
    className={`${
      isSelected &&
      'group-hover:text-neutral-white group-focus-visible:text-neutral-white'
    }`}
  />
);

export const getNewProjectStageValue = (
  newProjectStatus: string,
  baseProjectStageId: string | undefined,
  stageOptions: FormOption[]
) => {
  let newStageValue: string | null = baseProjectStageId || null;
  const noneStageProjectStatusArray = [
    PROJECT_STATUS.NEW,
    PROJECT_STATUS.IN_PLANNING,
  ];
  if (newProjectStatus === PROJECT_STATUS.IN_PROGRESS && !baseProjectStageId) {
    newStageValue = stageOptions[0].value;
  }
  if (noneStageProjectStatusArray.includes(newProjectStatus)) {
    newStageValue = null;
  }
  const updatedData = {
    [NEW_PROJECT_FORM_FIELDS.STATUS]: newProjectStatus,
    [NEW_PROJECT_FORM_FIELDS.STAGE]: newStageValue,
  };
  return { updatedData };
};

export const resetTargetLauchDateWhenGreaterStartDateIsSelected = (
  dateRange: rangeDateType,
  launchDate: string
) => {
  const { startDate, endDate } = dateRange;
  const shouldResetLaunchDate = moment(startDate).isAfter(launchDate);
  const updatedDates: objKeyAsString = {
    [NEW_PROJECT_FORM_FIELDS.START_DATE]: startDate,
    [NEW_PROJECT_FORM_FIELDS.TARGET_COMPLETION_DATE]: endDate,
    [NEW_PROJECT_FORM_FIELDS.TARGET_LAUNCH_DATE]: launchDate,
  };
  if (shouldResetLaunchDate) {
    updatedDates[NEW_PROJECT_FORM_FIELDS.TARGET_LAUNCH_DATE] = undefined;
  }
  return { shouldResetLaunchDate, updatedDates };
};
