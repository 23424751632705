import React from 'react';
import { tailwindOverride } from '@getsynapse/design-system';
import { DailyCapacityProps } from 'utils/types/dailyTeamsCapacity';
import AllocationSummary from '../../../../CapacityTable/components/AllocationSummary/AllocationSummary';
import AllocationSummaryTooltip from '../../../../CapacityTable/components/AllocationSummaryTooltip/AllocationSummaryTooltip';
import DailyCapacityBreakdown from '../../../../CapacityTable/components/CapacityBreakdown/CapacityBreakdown';
import DailyMemberTimeOff from '../../../../CapacityTable/components/TeamMembers/components/MemberTimeOff/MemberTimeOff';

const DailyMemberCapacity: React.FC<DailyCapacityProps> = ({
  assignedHours,
  dailyCapacity,
  timeOff,
}) => {
  return (
    <div
      className={tailwindOverride(
        'w-full h-full',
        'pt-4 px-2',
        'flex flex-col gap-y-2.5'
      )}
    >
      <AllocationSummaryTooltip
        trigger={
          <div>
            <AllocationSummary
              assignedHours={assignedHours}
              defaultCapacity={dailyCapacity}
              timeOff={timeOff}
              level='member'
            />
          </div>
        }
      >
        <DailyCapacityBreakdown
          assignedHours={assignedHours}
          defaultCapacity={dailyCapacity}
          timeOff={timeOff}
          level='member'
        />
      </AllocationSummaryTooltip>
      {timeOff > 0 && <DailyMemberTimeOff timeOff={timeOff} />}
    </div>
  );
};

export default DailyMemberCapacity;
