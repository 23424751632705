import React from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import get from 'lodash/get';
import { FormItem, Dropdown, Typography, Tag } from '@getsynapse/design-system';
import { getProjectLinkedDesigns } from 'state/Project/projectSlice';
import { selectDesigns } from 'state/Designs/designsSlice';
import { Design } from 'utils/customTypes';

const ReplaceDesignContent: React.FC<{
  replaceDesign: (designId: string) => void;
}> = ({ replaceDesign }) => {
  const projectLinkedDesigns = useSelector(getProjectLinkedDesigns);
  const designs = useSelector(selectDesigns);

  const availableDesigns = designs.filter(
    (design: Design) => !projectLinkedDesigns.includes(design.id)
  );

  return (
    <FormItem
      label={intl.get(
        'PROJECT_DETAIL.FILES_TAB.REPLACE_CONTENT_MODAL.REPLACE_DESIGN_CONTENT'
      )}
    >
      <Dropdown
        options={availableDesigns}
        onChange={(selectedDesign: Design) => replaceDesign(selectedDesign.id)}
        getOptionLabel={(design: Design) => design.title}
        triggerProps={{
          className: 'pb-1',
          'data-cy': 'replace-design-section__trigger',
        }}
        listProps={{ 'data-cy': 'replace-design-section__designs-list' }}
        filterable
        filterOptions={(options: Design[] | any[], filterValue: string) =>
          options.filter((option: Design) => {
            const title = (option as Design).title
              ? (option as Design).title.toLowerCase()
              : '';
            return title.includes(filterValue.toLocaleLowerCase());
          })
        }
        renderOption={(
          option: Design,
          isSelected: boolean,
          selectOption,
          { className, ...otherProps }
        ) => (
          <li
            {...otherProps}
            className={classnames('group', className, {
              'hover:bg-primary focus-visible:bg-primary': isSelected,
            })}
            onClick={selectOption}
          >
            <div className='flex w-full items-center'>
              <Typography
                variant='body'
                className={classnames('text-neutral-black truncate', {
                  'group-hover:text-neutral-white': isSelected,
                  'max-w-3/4': 'ownerData' in option,
                })}
              >
                {option.title}
              </Typography>
              {'ownerData' in option && (
                <Tag
                  label={`${intl.get(
                    'PROJECT_DETAIL.FILES_TAB.DESIGN_OWNER_TAG'
                  )}: ${get(option, 'ownerData.firstName')} ${get(
                    option,
                    'ownerData.lastName'
                  )}`}
                  className='bg-neutral-lighter-two ml-2 group-hover:bg-neutral-dark'
                  textClassName={classnames(
                    'text-xs text-neutral-darker font-semibold',
                    'group-hover:text-neutral-white'
                  )}
                />
              )}
            </div>
          </li>
        )}
      />
    </FormItem>
  );
};

export default ReplaceDesignContent;
