import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { setEntryId } from 'state/ManageTimeOff/TimeOffDays/timeOffDaysSlice';

export interface SidePanelState {
  isOpen: boolean;
}

const initialState: SidePanelState = {
  isOpen: false,
};

const sidePanelSlice = createSlice({
  name: 'manageTimeOff/sidePanel',
  initialState,
  reducers: {
    openSidePanel: (state) => {
      state.isOpen = true;
    },
    closeSidePanel: (state) => {
      state.isOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEntryId, (state) => {
      state.isOpen = true;
    });
  },
});

export const selectIsSidePanelOpen = (state: RootState) =>
  state.manageTimeOff.sidePanel.isOpen;

export const { openSidePanel, closeSidePanel } = sidePanelSlice.actions;

export default sidePanelSlice.reducer;
