import React from 'react';
import { tailwindOverride, Typography } from '@getsynapse/design-system';
import { CapacityView } from 'utils/types/capacityView';
import { VIEW_SETTING_VALUES } from 'utils/constants/capacityView';
import useAllocationSummaryPill from './hooks/useAllocationSummaryPill';

export interface AllocationSummaryProps {
  assignedHours: number;
  defaultCapacity: number;
  timeOff?: number;
  capacityView?: CapacityView;
  level?: 'team' | 'member';
}

const AllocationSummary: React.FC<AllocationSummaryProps> = ({
  timeOff = 0,
  capacityView = VIEW_SETTING_VALUES.DAILY,
  level = 'team',
  ...otherProps
}) => {
  const { styles, label } = useAllocationSummaryPill({
    timeOff,
    capacityView,
    level,
    ...otherProps,
  });

  return (
    <div
      className={tailwindOverride(
        'w-full h-6',
        'flex items-center justify-center',
        'cursor-pointer',
        'rounded',
        'border',
        styles.border,
        styles.bg
      )}
      style={{
        boxShadow: `0px 2px 2px 0px #12151B0D, 2px 0px 0px 0px ${styles.leftBorder} inset`,
      }}
    >
      <Typography
        variant='label'
        weight='medium'
        className={tailwindOverride('cursor-pointer', styles.text)}
      >
        {label}
      </Typography>
    </div>
  );
};

export default AllocationSummary;
