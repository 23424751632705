import { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, tailwindOverride } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { v4 as uuidv4 } from 'uuid';
import addAdditionalBenefitIcon from 'assets/icons/add-objective.svg';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { createAdditionalBenefits } from 'state/StrategyCategory/strategyCategorySlice';
import { trackEvent } from 'Services/pendo';
import { PENDO_EVENTS, ADDITIONAL_BENEFITS_PROMPTS } from 'utils/constants';
import { NewAdditionalBenefit, ProgramCategory } from 'utils/types/program';
import CustomObjective from '../CustomObjective';
import CategoryPrompts from '../CategoryPrompts';

type AddAdditionalBenefitModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  category: ProgramCategory;
  programId: string;
  programDescription: string;
};

const AddAdditionalBenefitModal = ({
  isOpen,
  setIsOpen,
  category,
  programId,
  programDescription,
}: AddAdditionalBenefitModalProps) => {
  const dispatch = useDispatch();
  const modalRef = useRef<null | HTMLDivElement>(null);
  const [createdAdditionalBenefits, setCreatedAdditionalBenefits] = useState<
    Map<string, string>
  >(new Map());

  const hasChanges = useMemo(() => {
    return Array.from(createdAdditionalBenefits.values()).some(
      (objective) => objective !== ''
    );
  }, [createdAdditionalBenefits]);

  const onCloseModal = () => {
    setCreatedAdditionalBenefits(new Map());
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      const key = uuidv4();
      setCreatedAdditionalBenefits((prev) => new Map(prev).set(key, ''));
    }
  }, [isOpen]);

  const onAddAdditionalBenefit = () => {
    const key = uuidv4();
    setCreatedAdditionalBenefits((prev) => new Map(prev).set(key, ''));
    modalRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSave = async () => {
    trackEvent(PENDO_EVENTS.CTA_SAVE_ADDITIONAL_BENEFITS, {
      category: category,
    });

    const newBenefits: NewAdditionalBenefit[] = Array.from(
      createdAdditionalBenefits.values()
    )
      .filter((value) => value.length > 0)
      .map((value) => ({ benefitText: value }));
    if (newBenefits.length > 0) {
      try {
        await dispatch(
          createAdditionalBenefits({
            programId,
            categoryId: category.id,
            newBenefits,
          })
        );
        dispatch(
          showSnackbarNotification({
            autoHide: false,
            notificationVariant: 'success',
            notificationTitle: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.ADD_ADDITIONAL_BENEFIT_MODAL.SUCCESS_MESSAGE'
            ),
          })
        );
        trackEvent(PENDO_EVENTS.CREATE_ADDITIONAL_BENEFITS, {
          newCount: newBenefits.length,
        });
      } catch (error) {
        dispatch(
          showSnackbarNotification({
            autoHide: false,
            notificationVariant: 'error',
            notificationTitle: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.ERROR.TITLE'
            ),
            notificationMessage: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.ERROR.MESSAGE'
            ),
          })
        );
      }
    }
    onCloseModal();
  };

  return (
    <Modal
      data-testid='create-additional-benefit_modal'
      childrenClassName='max-h-140'
      aria-label={intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.ADD_ADDITIONAL_BENEFIT_MODAL.TITLE',
        { category: category?.name }
      )}
      closeModal={onCloseModal}
      title={intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.ADD_ADDITIONAL_BENEFIT_MODAL.TITLE',
        { category: category?.name }
      )}
      titleIcon={{
        src: addAdditionalBenefitIcon,
        className: 'text-fire -mr-1',
      }}
      size='large'
      isOpen={isOpen}
      actionButtons={[
        {
          'data-testid': 'create-additional-benefit-modal_save-button',
          children: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.ADD_ADDITIONAL_BENEFIT_MODAL.CONFIRM_BUTTON'
          ),
          variant: 'primary',
          onClick: handleSave,
          disabled: !hasChanges,
        },
        {
          'data-testid': 'create-additional-benefit-modal_cancel-button',
          children: intl.get('CANCEL'),
          variant: 'tertiary',
          onClick: onCloseModal,
        },
      ]}
    >
      <div className='flex space-x-8'>
        <div
          className={tailwindOverride('w-7/12', {
            'w-full': ADDITIONAL_BENEFITS_PROMPTS.length! <= 0,
          })}
        >
          {createdAdditionalBenefits.size > 0 &&
            Array.from(createdAdditionalBenefits.keys()).map((key, index) => (
              <CustomObjective
                category={category}
                selectedObjectiveTemplates={new Map()}
                key={key}
                objectiveKey={key}
                objectiveValue={createdAdditionalBenefits.get(key)!}
                index={index}
                setCreatedObjectives={setCreatedAdditionalBenefits}
                type='additional_benefit'
              />
            ))}

          <Button
            ref={modalRef}
            iconName='add-circle'
            iconPosition='left'
            variant='tertiary'
            className='mb-2'
            onClick={onAddAdditionalBenefit}
            data-testid='create-additional-benefit-modal_add-benefit'
          >
            {intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.ADD_ADDITIONAL_BENEFIT_MODAL.CREATE_BENEFIT'
            )}
          </Button>
        </div>
        {ADDITIONAL_BENEFITS_PROMPTS.length! > 0 && (
          <CategoryPrompts
            categoryPrompts={ADDITIONAL_BENEFITS_PROMPTS.map((key) =>
              intl.get(
                `PROGRAM_PAGE.STRATEGY_PAGE.ADD_ADDITIONAL_BENEFIT_MODAL.PROMPTS.${key}`
              )
            )}
            programDescription={programDescription}
          />
        )}
      </div>
    </Modal>
  );
};

export default AddAdditionalBenefitModal;
