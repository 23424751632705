import axios, { AxiosInstance } from 'axios';
import get from 'lodash/get';
import config from 'config/Config';
import { initAxios } from 'utils/axios';

export interface FetchTeamMembersListResponse {
  teamMembers: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    roles: string[];
    is_team_manager: boolean;
    avatar_url: string;
    has_projects: boolean;
  }[];
  links: {
    next: string | null;
  };
}

class TeamMembersListAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchTeamMembersList: (url: string) => Promise<FetchTeamMembersListResponse> =
    async (url: string) => {
      const response = await this.instance.get<FetchTeamMembersListResponse>(
        url
      );
      if (axios.isAxiosError(response)) {
        const errorMessage = get(response, 'response.data.message', '');
        throw errorMessage;
      }
      return response.data;
    };
}

const teamMembersListAPI = new TeamMembersListAPI();
export default teamMembersListAPI;
