import { api } from './api';
import axios from 'axios';
import { Hours, Submitter } from 'types/store/actualHours';

interface FetchTaskActualHoursParams {
  taskId: string;
  userId?: string;
}

export interface FetchTaskActualHoursResponse {
  hours: Hours[];
  submitters: Record<string, Submitter>;
}

export const fetchTaskActualHours = async ({
  taskId,
}: FetchTaskActualHoursParams) => {
  const url = `/v2/tasks/${taskId}/submitted-hours`;
  const response = await api.get<FetchTaskActualHoursResponse>(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};
