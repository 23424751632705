import MyWorkspaceTable from '../MyWorkspaceTable';
import { useMemo } from 'react';
import { tablesLimit } from 'state/MyWorkspace/myWorkspaceSlice';
import { useSelector } from 'react-redux';
import Skelton from 'Atoms/Skelton/Skelton';

const RequestsTableLoader = () => {
  const tableLimit = useSelector(tablesLimit);

  const rows = useMemo(() => {
    const requestsRows = [];
    for (let i = 0; i < tableLimit; i++) {
      requestsRows.push({
        className: 'table w-full table-fixed cursor-pointer',
        cells: [
          {
            content: <Skelton className='w-3/4 h-4' />,
          },
          {
            content: (
              <div className='flex items-center'>
                <Skelton className='w-6 h-6 mr-2' isCircle />
                <Skelton className='w-3/4 h-4' />
              </div>
            ),
            className: 'w-48 py-2',
          },
          {
            content: <Skelton className='w-3/4 h-4' />,
            className: 'w-28',
          },
        ],
      });
    }
    return requestsRows;
  }, [tableLimit]);

  return (
    <MyWorkspaceTable
      headCells={[
        {
          content: <Skelton />,
        },
        {
          content: <Skelton />,
          className: 'w-48',
        },
        {
          content: <Skelton />,
          className: 'w-28',
        },
      ]}
      rows={rows}
    />
  );
};

export default RequestsTableLoader;
