import intl from 'react-intl-universal';
import { Button } from '@getsynapse/design-system';
import PageFooter from 'Molecules/PageFooter/PageFooter';

interface Props {
  isLoading: boolean;
  onCancel: () => void;
  onSave: () => void;
  saveDisabled: boolean;
  dataCy?: string;
}

const AccountSettingsFooter = ({
  isLoading,
  saveDisabled,
  onCancel,
  onSave,
  dataCy,
}: Props) => {
  return (
    <PageFooter>
      <Button onClick={onCancel} variant='tertiary'>
        {intl.get('CANCEL')}
      </Button>

      <Button
        disabled={saveDisabled}
        loading={isLoading}
        onClick={onSave}
        variant='primary'
        data-cy={dataCy}
      >
        {intl.get('SAVE')}
      </Button>
    </PageFooter>
  );
};

export default AccountSettingsFooter;
