import React, { useState, memo } from 'react';
import intl from 'react-intl-universal';
import { tailwindOverride, Icon, Typography } from '@getsynapse/design-system';
import { TeamCapacity } from 'utils/types/teamsCapacity';
import TeamCapacityCarousel from './components/TeamCapacityCarousel';
import TeamMembers from '../TeamMembers/TeamMembers';

const TeamRow: React.FC<{
  teamCapacity: TeamCapacity;
  isLastTeam: boolean;
}> = ({ teamCapacity, isLastTeam }) => {
  const [shouldDisplayMembersList, setshouldDisplayMembersList] =
    useState<boolean>(false);

  const toggleMembersList = () => {
    setshouldDisplayMembersList(!shouldDisplayMembersList);
  };

  const hasTeamMembers = Object.keys(teamCapacity.members).length > 0;

  return (
    <>
      <tr>
        <td colSpan={2}>
          <div
            className={tailwindOverride('w-full flex bg-neutral-white', {
              'cursor-pointer': hasTeamMembers,
            })}
            onClick={toggleMembersList}
            aria-label={`team-${teamCapacity.data.id}__toggle`}
            data-cy={`team-${teamCapacity.data.id}`}
          >
            <div
              className={tailwindOverride('w-2/6 h-14 flex relative', {
                'shadow-capacity-table-allocation-status': !isLastTeam,
              })}
            >
              <div
                className={tailwindOverride(
                  'w-10 h-full',
                  'flex items-center justify-end'
                )}
              >
                {hasTeamMembers && (
                  <div
                    className={tailwindOverride(
                      'w-5 h-5',
                      'flex items-center',
                      'duration-300 easy'
                    )}
                    style={{
                      transform: `${
                        shouldDisplayMembersList
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)'
                      }`,
                    }}
                    aria-label={`team-${teamCapacity.data.id}__toggle-icon`}
                  >
                    <Icon
                      name='chevron-down'
                      className={tailwindOverride('text-primary-dark text-lg')}
                    />
                  </div>
                )}
              </div>
              <div
                className={tailwindOverride(
                  'h-full px-4',
                  'flex flex-1 items-center'
                )}
              >
                <Typography
                  variant='caption'
                  weight='medium'
                  className='text-primary-darker'
                >
                  {teamCapacity.data.name}
                </Typography>
                <Typography
                  variant='caption'
                  className='text-primary-darker ml-2'
                >
                  {!hasTeamMembers &&
                    intl.get('TEAMS_CAPACITY.NO_TEAM_MEMBERS')}

                  {hasTeamMembers &&
                    intl.get('TEAMS_CAPACITY.NUMBER_OF_TEAM_MEMBERS', {
                      numberMembers: Object.keys(teamCapacity.members).length,
                    })}
                </Typography>
              </div>
            </div>
            <div className={tailwindOverride('w-4/6 h-14 relative')}>
              <TeamCapacityCarousel
                teamId={teamCapacity.data.id}
                maximumTeamCapacity={teamCapacity.data.maximumTeamCapacity}
                capacity={teamCapacity.allocations}
                displayCapacity={hasTeamMembers}
                isLastTeam={isLastTeam}
              />
            </div>
          </div>
        </td>
      </tr>
      {hasTeamMembers && shouldDisplayMembersList && (
        <tr>
          <td colSpan={2}>
            <TeamMembers
              teamId={teamCapacity.data.id}
              aria-hidden={!shouldDisplayMembersList}
              teamMembers={teamCapacity.members}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default memo(TeamRow);
