import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { Modal } from '@getsynapse/design-system';
import {
  fetchActualHours,
  selectActualHoursAreLoading,
} from 'state/ActualHours/actualHoursSlice';
import TimeEntries from './TimeEntries/TimeEntries';

type TaskActualHoursModalProps = {
  taskId: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const TaskActualHoursModal = ({
  taskId,
  isOpen,
  setIsOpen,
}: TaskActualHoursModalProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectActualHoursAreLoading);

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchActualHours({ taskId }));
    }
  }, [dispatch, taskId, isOpen]);

  const actionButtons = [];

  actionButtons.push({
    children: intl.get('SAVE'),
    variant: 'primary',
    onClick: () => {
      setIsOpen(false);
    },
    disabled: true,
    'data-testid': 'save-actuals-button',
  });
  actionButtons.push({
    children: intl.get('CANCEL'),
    variant: 'tertiary',
    onClick: () => {
      setIsOpen(false);
    },
    'data-testid': 'cancel-actuals-button',
  });

  return (
    <Modal
      aria-label={intl.get('TASK_ACTUAL_HOURS.TITLE')}
      closeModal={() => setIsOpen(false)}
      title={intl.get('TASK_ACTUAL_HOURS.TITLE')}
      size='large'
      isOpen={isOpen}
      actionButtons={actionButtons}
      data-testid='actual-hours-modal'
    >
      {isLoading && <div>Loading...</div>}
      {!isLoading && <TimeEntries />}
    </Modal>
  );
};

export default TaskActualHoursModal;
