import React, { useMemo } from 'react';
import classnames from 'classnames';
import { ProcessStage } from 'utils/customTypes';
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { Typography, Tooltip } from '@getsynapse/design-system';
import StageCardsList from './StageCardsList';

type StageProps = ProcessStage & {
  isBoardEmpty?: boolean;
};

const StageHeader: React.FC<{
  name: string;
  textColor: string;
  bgColor: string;
  numberOfCards: number;
}> = ({ name, textColor, bgColor, numberOfCards }) => {
  return (
    <div className='flex items-center justify-between w-full'>
      <Typography
        variant='body'
        className={classnames('font-semibold truncate', textColor, {
          'cursor-pointer': name.length > 24,
        })}
      >
        {name}
      </Typography>
      <div
        className={classnames(
          'w-6 h-5 flex items-center justify-center rounded-xl ml-2.5',
          bgColor
        )}
      >
        <Typography
          variant='caption'
          className={`px-2 py-0.5 font-semibold ${textColor}`}
        >
          {numberOfCards}
        </Typography>
      </div>
    </div>
  );
};

const Stage: React.FC<StageProps> = ({
  color,
  textColor,
  name,
  isBoardEmpty,
  cards,
  colorOnDrag,
  borderColorOnDrag,
  id,
}) => {
  const element = document.querySelector('#stages-list');
  const customModifier = useMemo(() => {
    return {
      enabled: true,
      name: 'preventOverflow',
      options: {
        boundary: element,
      },
    };
  }, [element]);
  return (
    <Droppable droppableId={id}>
      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
        <div
          className={classnames(
            'flex flex-col flex-shrink-0 w-68 h-full mr-4',
            {
              [`border border-dashed rounded-t-lg ${borderColorOnDrag}`]:
                snapshot.isDraggingOver,
            }
          )}
          data-cy={`stage-${id}`}
        >
          <div className={`w-full py-1 px-3 rounded-t-lg ${color}`}>
            {name.length > 24 ? (
              <Tooltip
                trigger={
                  <div>
                    <StageHeader
                      name={name}
                      bgColor={colorOnDrag}
                      textColor={textColor}
                      numberOfCards={cards.length}
                    />
                  </div>
                }
                showPopper
                popperModifiers={[customModifier]}
                className='w-full'
                ariaId={`stage-${id}-header`}
                openMode='hover2'
                timeout='0'
                contentProps={{
                  className: classnames(
                    'bg-neutral-dark',
                    'rounded px-4 py-2',
                    'w-max absolute',
                    'font-normal',
                    'shadow-tooltip',
                    'z-10'
                  ),
                }}
              >
                <Typography variant='body' className='text-neutral-white'>
                  {name}
                </Typography>
              </Tooltip>
            ) : (
              <StageHeader
                name={name}
                bgColor={colorOnDrag}
                textColor={textColor}
                numberOfCards={cards.length}
              />
            )}
          </div>
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={classnames(
              'flex flex-col h-full p-2 pt-0 border overflow-y-auto',
              {
                'bg-neutral-white border-neutral-lighter-two opacity-30':
                  isBoardEmpty && !snapshot.isDraggingOver,
              },
              {
                'bg-neutral-lightest-two border-neutral-lighter-two':
                  !isBoardEmpty && !snapshot.isDraggingOver,
              },
              {
                [`border-none ${colorOnDrag}`]: snapshot.isDraggingOver,
              }
            )}
          >
            <StageCardsList cards={cards} />
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
};

export default Stage;
