import {
  Typography,
  OverflowMenu,
  OverflowMenuItem,
  Button,
  useLink,
  TextArea,
  Tag,
  tailwindOverride,
} from '@getsynapse/design-system';
import classnames from 'classnames';
import moment from 'moment';
import intl from 'react-intl-universal';
import { Fragment, useMemo, useState, useEffect } from 'react';
import { OnChangeHandlerFunc, MentionItem } from 'react-mentions';
import { selectUserId } from 'state/User/userSlice';
import { useSelector } from 'react-redux';
import {
  QuestionCommentType,
  ProjectComment,
  ProjectCommentMentionsData,
  UserAvatars,
} from 'utils/customTypes';
import {
  parseURL,
  parseMention,
  isUserStatusDisabled,
  extractMentionedId,
} from 'utils/functions';
import get from 'lodash/get';
import MentionsTextField from './Mentions/MentionsTextField';

const CommentBlock = ({
  comment,
  onEdit = () => {},
  onDelete = () => {},
  mentionableUsers = [],
  mentionsFieldClassName,
}: {
  comment: QuestionCommentType | ProjectComment;
  onEdit?: ({
    commentId,
    content,
    mentionsData,
  }: {
    commentId: string;
    content: string;
    mentionsData: ProjectCommentMentionsData;
  }) => void;
  onDelete?: (commentId: string) => void;
  mentionableUsers?: UserAvatars[];
  mentionsFieldClassName?: string;
}) => {
  const defaultMentionsData = useMemo(
    () => ({
      value: '',
      plainTextValue: '',
      mentions: [],
    }),
    []
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [commentContent, setCommentContent] = useState('');
  const [mentionsData, setMentionsData] =
    useState<ProjectCommentMentionsData>(defaultMentionsData);
  const userId = useSelector(selectUserId);
  const isOwner = useMemo(
    () => (comment.author ? comment.author.userId === userId : false),
    [comment?.author, userId]
  );
  const linkClassName = useLink(true);

  useEffect(() => {
    setCommentContent(comment.content);
    setMentionsData(get(comment, 'data.mentions'));
  }, [comment]);

  const handleChange: OnChangeHandlerFunc = (
    _,
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionItem[]
  ) => {
    setCommentContent(newValue);
    setMentionsData({
      value: newValue,
      plainTextValue: newPlainTextValue,
      mentions,
    });
  };

  const formattedComment = useMemo(() => {
    const formattedComment = commentContent.trim();
    const mentionedId = extractMentionedId(formattedComment);
    const mentionedUser = mentionableUsers.find(
      (user) => user.value === mentionedId
    );

    let plainTextAfterParsing = formattedComment;
    plainTextAfterParsing = parseMention(
      plainTextAfterParsing,
      mentionedUser?.disabled ? 'text-neutral-lighter' : 'text-neutral'
    );
    plainTextAfterParsing = parseURL(plainTextAfterParsing, linkClassName);
    return plainTextAfterParsing;
  }, [commentContent, linkClassName, mentionableUsers]);

  const updateComment = () => {
    const formattedComment = commentContent.trim();
    let plainTextAfterParsing = formattedComment;
    plainTextAfterParsing = parseMention(plainTextAfterParsing);
    plainTextAfterParsing = parseURL(plainTextAfterParsing, linkClassName);
    const updatedMentionsData = {
      ...mentionsData,
      plainTextValue: plainTextAfterParsing,
    };
    onEdit({
      commentId: comment.id,
      content: formattedComment,
      mentionsData: updatedMentionsData,
    });
    setEditMode(false);
  };

  const isUserDisabled = useMemo(
    () => isUserStatusDisabled(comment.author?.status),
    [comment.author?.status]
  );

  return (
    <div
      className={classnames('pt-4', 'pb-6', 'px-6')}
      data-cy={`comment-${comment.id}`}
    >
      <div
        className={classnames(
          'flex',
          'items-center',
          'mb-4',
          'justify-between'
        )}
      >
        <div className={classnames('flex', 'items-center gap-1')}>
          <Typography
            variant='h6'
            weight='medium'
            className={tailwindOverride({
              'text-neutral-dark': isUserStatusDisabled(comment.author?.status),
            })}
          >
            {comment.author ? comment.author.name : ''}
          </Typography>
          {isUserDisabled && (
            <Tag
              label={intl.get('DEACTIVATED')}
              className='bg-neutral-lighter-two'
              textClassName='text-neutral-dark'
            />
          )}
          <span className={classnames('text-neutral-light', 'text-xs')}>
            {comment.createdAt === comment.updatedAt
              ? moment(comment.createdAt).fromNow()
              : intl.get('COMMENT.EDITED') +
                ' ' +
                moment(comment.updatedAt).fromNow()}
          </span>
        </div>

        {isOwner && (
          <OverflowMenu
            menuListProps={{
              className: classnames('z-50', 'relative'),
              'data-cy': `${comment.id}-overflow-menu-options-list`,
            }}
            menuButtonProps={{
              'data-cy': 'overflow-button',
            }}
          >
            <OverflowMenuItem
              onSelect={() => {
                setEditMode(true);
              }}
              data-testid='edit-button'
            >
              {intl.get('COMMENT.EDIT')}
            </OverflowMenuItem>
            <OverflowMenuItem
              onSelect={() => onDelete(comment.id)}
              data-testid='delete-button'
            >
              {intl.get('COMMENT.DELETE')}
            </OverflowMenuItem>
          </OverflowMenu>
        )}
      </div>

      {!editMode ? (
        <div
          className='break-words'
          dangerouslySetInnerHTML={{
            __html: formattedComment,
          }}
        />
      ) : (
        <Fragment>
          {mentionableUsers?.length > 0 ? (
            <MentionsTextField
              value={commentContent}
              onChange={handleChange}
              mentionableUsers={mentionableUsers}
              inputClassName={mentionsFieldClassName}
            />
          ) : (
            <TextArea
              value={commentContent}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                setCommentContent(event.target.value);
              }}
              className='px-6 py-4 shadow-form-wrapper'
              textAreaProps={{
                className: 'h-auto min-h-10 max-h-26 px-4 py-3',
                'data-cy': 'add-new-comment',
              }}
            />
          )}
          <div className={classnames('flex', 'space-x-4', 'mt-4')}>
            <Button
              size='small'
              onClick={updateComment}
              data-testid='update-button'
            >
              {intl.get('COMMENT.UPDATE_BUTTON')}
            </Button>

            <Button
              size='small'
              variant='secondary'
              onClick={() => setEditMode(false)}
            >
              {intl.get('COMMENT.CANCEL_BUTTON')}
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default CommentBlock;
