import React, { SetStateAction, Dispatch } from 'react';
import { PickerFileMetadata } from 'filestack-js';
import { Typography, FileUpload } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import { ProjectContent } from 'utils/customTypes';
import { getFileStackConfig } from './projectContentFilestackConfig';
import { PROJECT_CONTENT_TYPE } from 'utils/constants';
import FilesCarousel from './FilesCarousel';

const AddFilesSection = ({
  files,
  setFiles,
}: {
  files: ProjectContent[];
  setFiles: Dispatch<SetStateAction<ProjectContent[]>>;
}) => {
  const fileStackConfig = getFileStackConfig();

  const onRemoveFile = (index: number) => {
    setFiles((prevFiles: ProjectContent[]) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const onUploadFile = (newFiles: PickerFileMetadata[]) => {
    if (!isEmpty(newFiles)) {
      const formattedFiles = newFiles.map((file: PickerFileMetadata) => ({
        data: { ...file },
        content_type: PROJECT_CONTENT_TYPE.FILE,
        linkedTasksIds: [],
      }));
      setFiles((prevFiles: ProjectContent[]) => {
        const updatedFilesArray = prevFiles.concat(formattedFiles);
        return updatedFilesArray;
      });
    }
  };

  return (
    <>
      <Typography
        variant='body2'
        weight='medium'
        className='text-neutral-black mt-8'
      >
        {intl.get('PROJECT_DETAIL.FILES_TAB.UPLOAD_FILE')}
      </Typography>
      {!isEmpty(files) && (
        <FilesCarousel files={files} onRemoveFile={onRemoveFile} />
      )}
      <FileUpload
        className='mt-1 flex-1'
        config={fileStackConfig}
        displayUploadedFiles={false}
        onFileUploadHandle={onUploadFile}
        customErrorMessages={{
          'file-too-large': intl.get('PROJECT_DETAIL.FILES_TAB.FILE_1GB'),
        }}
        uploadInstructions={
          <>
            {intl.get('INTAKE_TAB.DRAG_HERE')}{' '}
            <span className='text-purple-darker'>
              {intl.get('INTAKE_TAB.CHOOSE')}
            </span>{' '}
            {intl.get('PROJECT_DETAIL.FILES_TAB.FILE_LIMIT')}
          </>
        }
        hidePickerWhenUploading
        data-cy='project-content-upload-picker'
      />
    </>
  );
};

export default AddFilesSection;
