import classnames from 'classnames';
import { IconButton } from '@getsynapse/design-system';
import { CAROUSEL_DIRECTION } from 'utils/constants';

const CarouselArrowButton = ({
  direction,
  onClick,
}: {
  direction: string;
  onClick: () => void;
}) => {
  const isNext = direction === CAROUSEL_DIRECTION.NEXT;
  return (
    <div
      className={classnames(
        'w-6 h-6 rounded-sm absolute top-5 shadow-tooltip bg-primary-lighter-two z-10 hover:bg-primary-lighter',
        { 'left-0': !isNext },
        { 'right-0': isNext }
      )}
    >
      <IconButton
        name={isNext ? 'chevron-forward-outline' : 'chevron-back-outline'}
        className={classnames(
          'w-5 text-lg text-primary-dark hover:text-primary mt-3.2px',
          { 'ml-1': isNext },
          { 'ml-2.4px': !isNext }
        )}
        onClick={onClick}
      />
    </div>
  );
};

export default CarouselArrowButton;
