import { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import {
  IconButton,
  tailwindOverride,
  Typography,
} from '@getsynapse/design-system';
import {
  moveToPreviousWeek,
  moveToNextWeek,
  selectDatesRange,
  selectWeeks,
} from 'state/WeeklyTeamCapacity/Weeks/weeksSlice';
import { DateSegment } from 'state/WeeklyTeamCapacity/Weeks/helpers';
import { FetchMoreWeeklyTeamsCapacityParams } from 'state/WeeklyTeamCapacity/TeamsCapacity/teamsCapacitySlice';
import { fetchMoreWeeklyTeamsCapacity } from 'state/WeeklyTeamCapacity/TeamsCapacity/teamsCapacitySlice';
import { fetchMoreWeeklyTeamMembersCapacity } from 'state/WeeklyTeamCapacity/TeamMembersCapacity/teamMembersCapacitySlice';
import { fetchMoreWeeklyTeamMemberProjectsCapacity } from 'state/WeeklyTeamCapacity/TeamMembersProjectCapacity/teamMemberProjectsCapacitySlice';
import { getMondayOfTheWeek } from '../../../DailyCapacity/helpers';

const HeaderWeeksCarousel = () => {
  const dispatch = useDispatch();
  const promises = useRef<Array<FetchMoreWeeklyTeamsCapacityParams>>([]);
  const fetching = useRef<boolean>(false);
  const { startDate, endDate } = useSelector(selectDatesRange);
  const weeks = useSelector(selectWeeks);
  const today = new Date();
  const mondayOfTodaysWeek = getMondayOfTheWeek(today);

  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
  });

  const handleFetchMoreWeeklyCapacity = (
    params: FetchMoreWeeklyTeamsCapacityParams
  ) => {
    dispatch(fetchMoreWeeklyTeamsCapacity(params));
    dispatch(fetchMoreWeeklyTeamMembersCapacity(params));
    dispatch(fetchMoreWeeklyTeamMemberProjectsCapacity(params));
  };

  const fetchMoreWeeklyTeamsCapacityWrapper = async () => {
    if (promises.current.length > 0 && !fetching.current) {
      fetching.current = true;
      for (const promise of promises.current) {
        handleFetchMoreWeeklyCapacity(promise);
      }
      promises.current = [];
      fetching.current = false;
    }
  };

  const debouncedFetchMoreWeeklyCapacity = debounce(
    fetchMoreWeeklyTeamsCapacityWrapper,
    500
  );

  const handlePrevious = () => {
    dispatch(moveToPreviousWeek());
    promises.current.push({ startDate, endDate, link: 'prev' });
    debouncedFetchMoreWeeklyCapacity();
  };

  const handleNext = async () => {
    dispatch(moveToNextWeek());
    promises.current.push({ startDate, endDate, link: 'next' });
    debouncedFetchMoreWeeklyCapacity();
  };

  return (
    <div
      className='w-full max-w-full flex h-10 flex-grow-0'
      aria-label='new-weekly-teams-capacity__weeks-carousel'
    >
      <div
        className={tailwindOverride('w-6 h-full py-3', 'flex justify-center')}
      >
        <IconButton
          name='chevron-back'
          onClick={handlePrevious}
          className='text-primary text-lg'
          aria-label='weekly-teams-capacity__days-carousel__previous-button'
        />
      </div>
      <div
        className='flex w-full h-full overflow-hidden'
        aria-label='weekly-teams-capacity__weeks-carousel'
      >
        {weeks.map((week: DateSegment, index: number) => {
          const [startOfWeek, endOfWeek] = week;

          const startOfWeekDate = new Date(startOfWeek.replace('-', '/'));
          const endOfWeekDate = new Date(endOfWeek.replace('-', '/'));

          const isCurrentWeek =
            startOfWeekDate.getFullYear() ===
              mondayOfTodaysWeek.getFullYear() &&
            startOfWeekDate.getMonth() === mondayOfTodaysWeek.getMonth() &&
            startOfWeekDate.getDate() === mondayOfTodaysWeek.getDate();

          return (
            <div
              key={index}
              className={tailwindOverride(
                'h-full relative',
                'flex items-center justify-center',
                'flex-shrink-0 flex-grow',
                'flex-1',
                {
                  'text-primary bg-secondary-lightest border-l border-r border-secondary-lighter':
                    isCurrentWeek,
                }
              )}
              role='group'
              aria-label={`weekly-teams-capacity__week-${startOfWeek}`}
            >
              {isCurrentWeek && (
                <div
                  className={tailwindOverride(
                    'w-full h-px',
                    'bg-secondary-lighter',
                    'absolute top-0 left-0'
                  )}
                />
              )}
              {week !== null && (
                <Typography
                  variant='caption'
                  className={tailwindOverride('font-semibold', {
                    'text-secondary-darker': isCurrentWeek,
                  })}
                >
                  {dateFormatter.format(startOfWeekDate)} -{' '}
                  {dateFormatter.format(endOfWeekDate)}
                </Typography>
              )}
              {isCurrentWeek && (
                <div
                  className={tailwindOverride(
                    'w-full h-0.5',
                    'bg-secondary-dark',
                    'absolute bottom-0 left-0'
                  )}
                />
              )}
            </div>
          );
        })}
      </div>
      <div
        className={tailwindOverride('w-6 h-full py-2', 'flex justify-center')}
      >
        <IconButton
          name='chevron-forward'
          onClick={handleNext}
          className='text-primary text-lg'
          aria-label='weekly-teams-capacity__days-carousel__next-button'
        />
      </div>
    </div>
  );
};

export default HeaderWeeksCarousel;
