import { NewObjective, Objective } from 'utils/types/program';

export const serializeObjectives = (
  objectives?: NewObjective[]
): Objective[] => {
  if (!objectives) {
    return [];
  }
  return objectives.map((objective) => ({
    id: objective.id,
    name: objective.name,
    isMet: objective.is_met,
    programObjectiveTemplateId: objective.program_objective_template_id,
    position: objective.position,
    notMetReason: objective.not_met_reason,
    annualBenefit: objective.annual_monetary_benefit,
    categoryId: objective.category_id,
  }));
};
