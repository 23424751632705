import {
  createAsyncThunk,
  createSlice,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import {
  DailyCapacityPerWeek,
  DailyTeamMemberCapacity,
  WeeksAndDaysArray,
  FetchMoreDailyCapacityParams,
  MembersByTeam,
  TeamMember,
} from 'utils/types/dailyTeamsCapacity';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { RootState } from 'state/store';
import { selectWeeksAndDays } from '../Days/daysSlice';
import { resetDailyCapacityState } from '../TeamsCapacity/teamsCapacitySlice';
import teamMembersCapacityAPI from './teamMembersCapacityAPI';
import {
  generateDailyCapacityPropsMap,
  generateFetchPrevOrNextDayCapacityURL,
  generateFetchDailyCapacityURLFromDateRange,
  removeDayFromCapacityPerWeek,
} from '../helpers';
import {
  getVisibleMembersFromTeam,
  getMembersIdsFromTeamToFetch,
  getMembersIdsFromTeamToRemove,
  addIdIfNotExists,
  updateDailyMemberCapacity,
  removeTeamFromMembersById,
  removeMembersFromTeam,
} from './helpers';

interface TeamMembersCapacityState {
  visibleMembersIds: string[];
  byTeam: MembersByTeam<TeamMember>;
  byId: DailyTeamMemberCapacity;
  status: Status;
}

/* ============================= INITIAL STATE ============================== */
const initialState: TeamMembersCapacityState = {
  byId: {},
  byTeam: {},
  visibleMembersIds: [],
  status: SLICE_STATUS.IDLE,
};

/* =============================== ACTIONS ================================ */
const setVisibleMembersIds = createAction<string[]>(
  'dailyTeamMembersCapacity/SET_VISIBLE_MEMBERS_IDS'
);
const setDailyMemberCapacity = createAction<{
  teamId: string;
  memberId: string;
  dailyCapacities: DailyCapacityPerWeek;
}>('dailyTeamMembersCapacity/SET_MEMBER_CAPACITY');
const removeDayFromDailyMemberCapacities = createAction<{
  memberId: string;
  day: string;
}>('dailyTeamMembersCapacity/REMOVE_DAY_FROM_MEMBER_CAPACITY');
export const removeTeamMembersCapacity = createAction<{
  teamId: string;
  memberIds: string[];
}>('dailyTeamMembersCapacity/REMOVE_TEAM_MEMBERS_CAPACITY');

/* ============================== REDUX THUNK =============================== */
export const fetchDailyTeamMembersCapacity = createAsyncThunk(
  'dailyTeamMembersCapacity/fetchDailyTeamMembersCapacity',
  async (
    params: { teamId: string; membersIds: string[] },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const { teamId, membersIds } = params;
      const state = getState() as RootState;
      const { startDate, endDate } = state.dailyTeamsCapacity.days;
      dispatch(setVisibleMembersIds(membersIds));
      const visibleMembersIds = getVisibleMembersFromTeam(
        teamId,
        state.dailyTeamsCapacity.teamMembersCapacity.byTeam
      );
      const membersIdsToFetch = getMembersIdsFromTeamToFetch(
        visibleMembersIds,
        membersIds
      );
      for (const memberId of membersIdsToFetch) {
        const url = generateFetchDailyCapacityURLFromDateRange(
          startDate,
          endDate,
          `/v2/teams/${teamId}/members/${memberId}/capacities/daily`
        );
        teamMembersCapacityAPI
          .fetchDailyTeamMembersCapacity(url)
          .then((response) => {
            const dailyCapacities = response.dailyCapacities.weeks;
            dispatch(
              setDailyMemberCapacity({
                teamId,
                memberId,
                dailyCapacities,
              })
            );
          });
      }
      const membersIdsToRemove = getMembersIdsFromTeamToRemove(
        visibleMembersIds,
        membersIds
      );
      dispatch(
        removeTeamMembersCapacity({
          teamId,
          memberIds: membersIdsToRemove,
        })
      );
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const fetchMoreDailyTeamMembersCapacity = createAsyncThunk(
  'dailyTeamMembersCapacity/fetchMoreDailyTeamMembersCapacity',
  async (
    params: FetchMoreDailyCapacityParams,
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const state = getState() as RootState;
      const { link, startDate, endDate } = params;
      const visibleMembersIds =
        state.dailyTeamsCapacity.teamMembersCapacity.visibleMembersIds;
      for (const memberId of visibleMembersIds) {
        const teamId =
          state.dailyTeamsCapacity.teamMembersCapacity.byId[memberId]
            .visibleTeamsIds[0];
        let url = `/v2/teams/${teamId}/members/${memberId}/capacities/daily?startDate=:startDate`;
        if (link === 'nextDay') {
          url = generateFetchPrevOrNextDayCapacityURL(url, endDate, link);
        } else {
          url = generateFetchPrevOrNextDayCapacityURL(url, startDate, link);
        }
        teamMembersCapacityAPI
          .fetchDailyTeamMembersCapacity(url)
          .then((response) => {
            const dailyCapacities = response.dailyCapacities.weeks;
            dispatch(
              setDailyMemberCapacity({
                teamId,
                memberId,
                dailyCapacities,
              })
            );
          })
          .then(() => {
            let day = '';
            if (link === 'prevDay') {
              day = endDate;
            } else {
              day = startDate;
            }
            dispatch(removeDayFromDailyMemberCapacities({ memberId, day }));
          });
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* =============================== SELECTORS ================================ */
const selectDailyMemberCapacitiesPerWeek = (
  state: RootState,
  memberId: string
) => {
  if (memberId in state.dailyTeamsCapacity.teamMembersCapacity.byId) {
    return state.dailyTeamsCapacity.teamMembersCapacity.byId[memberId]
      .dailyCapacities;
  }
  return undefined;
};
const selectVisibleMembersIds = (state: RootState) =>
  state.dailyTeamsCapacity.teamMembersCapacity.visibleMembersIds;
export const isMemberVisible = createSelector(
  [selectVisibleMembersIds, (state: RootState, memberId: string) => memberId],
  (visibleMembersIds: string[], memberId: string) => {
    return visibleMembersIds.includes(memberId);
  }
);

export const selectDailyMemberCapacity = createSelector(
  [
    (state: RootState, memberId: string) =>
      selectDailyMemberCapacitiesPerWeek(state, memberId),
    selectWeeksAndDays,
  ],
  (
    dailyCapacitiesPerWeek: DailyCapacityPerWeek | undefined,
    weeksAndDaysArray: WeeksAndDaysArray
  ) => {
    if (!dailyCapacitiesPerWeek || !weeksAndDaysArray) {
      return {};
    }
    return generateDailyCapacityPropsMap(
      dailyCapacitiesPerWeek,
      weeksAndDaysArray
    );
  }
);

/* ================================= REDUCER ================================ */
const dailyTeamMembersCapacitySlice = createSlice({
  name: 'dailyTeamMembersCapacity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetDailyCapacityState, (state) => {
        state.byId = initialState.byId;
        state.visibleMembersIds = initialState.visibleMembersIds;
        state.byTeam = initialState.byTeam;
      })
      .addCase(setVisibleMembersIds, (state, action) => {
        const visibleMembersIds = action.payload;
        const updatedVisibleMembersIdsState = [];
        for (const memberId of visibleMembersIds) {
          if (!state.visibleMembersIds.includes(memberId)) {
            updatedVisibleMembersIdsState.push(memberId);
          }
        }
        state.visibleMembersIds = state.visibleMembersIds.concat(
          updatedVisibleMembersIdsState
        );
      })
      .addCase(removeTeamMembersCapacity, (state, action) => {
        const { memberIds, teamId } = action.payload;
        const updatedMembersByTeam = removeMembersFromTeam(
          { ...state.byTeam },
          memberIds,
          teamId
        );
        const { updatedMembersById, membersIdsToRemove } =
          removeTeamFromMembersById({ ...state.byId }, memberIds, teamId);
        state.visibleMembersIds = state.visibleMembersIds.filter(
          (id: string) => !membersIdsToRemove.includes(id)
        );
        state.byTeam = updatedMembersByTeam;
        state.byId = updatedMembersById;
      })
      .addCase(removeDayFromDailyMemberCapacities, (state, action) => {
        const { memberId, day } = action.payload;
        const dailyCapacities = { ...state.byId[memberId].dailyCapacities };
        state.byId[memberId].dailyCapacities = removeDayFromCapacityPerWeek(
          dailyCapacities,
          day
        );
      })
      .addCase(setDailyMemberCapacity, (state, action) => {
        const { memberId, dailyCapacities, teamId } = action.payload;
        let currentDailyMemberCapacitiesPerWeek = {};
        let currentMemberVisibleTeamsIds: string[] = [];
        let allMembersIdsByTeam: string[] = [];
        if (memberId in state.byId) {
          currentDailyMemberCapacitiesPerWeek =
            state.byId[memberId].dailyCapacities || {};
          currentMemberVisibleTeamsIds =
            state.byId[memberId].visibleTeamsIds || [];
        }
        if (teamId in state.byTeam) {
          allMembersIdsByTeam = state.byTeam[teamId].allIds || [];
        }
        const updatedDailyMemberCapacitiesPerWeek = updateDailyMemberCapacity(
          currentDailyMemberCapacitiesPerWeek,
          dailyCapacities
        );
        const updatedVisibleTeamsIds = addIdIfNotExists(
          currentMemberVisibleTeamsIds,
          teamId
        );
        const updatedAllMembersIdsByTeam = addIdIfNotExists(
          allMembersIdsByTeam,
          memberId
        );
        state.byTeam = {
          ...state.byTeam,
          [teamId]: {
            ...state.byTeam[teamId],
            allIds: updatedAllMembersIdsByTeam,
          },
        };
        state.byId = {
          ...state.byId,
          [memberId]: {
            ...state.byId[memberId],
            dailyCapacities: updatedDailyMemberCapacitiesPerWeek,
            visibleTeamsIds: updatedVisibleTeamsIds,
          },
        };
      });
  },
});

export default dailyTeamMembersCapacitySlice.reducer;
