import reactionIcon from 'assets/icons/reaction-strategy.svg';
import learningIcon from 'assets/icons/learning-strategy.svg';
import applicationIcon from 'assets/icons/application-strategy.svg';
import impactIcon from 'assets/icons/impact-strategy.svg';
import roiIcon from 'assets/icons/roi-strategy.svg';
import inputCategoryIcon from 'assets/icons/input-category.svg';
import { strategyCategories, AnswerDetail } from 'utils/types/program';

export const PROGRAM_TABS = {
  OVERVIEW: 'overview',
  STRATEGY: 'strategy',
} as const;

export const STRATEGY_CATEGORIES = {
  REACTION: 'Reaction',
  LEARNING: 'Learning',
  APPLICATION: 'Application',
  IMPACT: 'Impact',
  ROI: 'ROI',
  INPUT: 'Input',
} as const;

export const METHOD_QUESTIONS = {
  NUMBER_OF_RESPONDENTS: 'number_of_respondents',
  COLLECTION_TIMING: 'collection_timing',
  DATA_SOURCE: 'data_source',
  DESIRED_BEHAVIOURS: 'desired_behaviours',
  NUMBER_OF_OBSERVERS: 'number_of_observers',
  NUMBER_OBSERVED: 'number_observed',
  METHOD: 'method',
} as const;

export const EVALUATION_COLLECTION_QUESTIONS_ORDER = [
  METHOD_QUESTIONS.DATA_SOURCE,
  METHOD_QUESTIONS.METHOD,
  METHOD_QUESTIONS.NUMBER_OF_OBSERVERS,
  METHOD_QUESTIONS.NUMBER_OBSERVED,
  METHOD_QUESTIONS.NUMBER_OF_RESPONDENTS,
  METHOD_QUESTIONS.COLLECTION_TIMING,
  METHOD_QUESTIONS.DESIRED_BEHAVIOURS,
];

export const STRATEGY_CATEGORY_ICON_MAP: Record<string, string> = {
  [STRATEGY_CATEGORIES.REACTION]: reactionIcon,
  [STRATEGY_CATEGORIES.LEARNING]: learningIcon,
  [STRATEGY_CATEGORIES.APPLICATION]: applicationIcon,
  [STRATEGY_CATEGORIES.IMPACT]: impactIcon,
  [STRATEGY_CATEGORIES.ROI]: roiIcon,
  [STRATEGY_CATEGORIES.INPUT]: inputCategoryIcon,
};

export const CATEGORY_WITH_NO_OBJ_AND_EVALUATION: strategyCategories[] = [
  STRATEGY_CATEGORIES.INPUT,
];

export const DEFAULT_EMPTY_COLLECTION_METHOD_ANSWER: AnswerDetail = {
  answer_name: null,
  number_observed: null,
  answer_preset_id: null,
  desired_behaviours: [],
  number_of_observers: null,
  number_of_respondents: null,
  data_source_other_text: null,
};

export const PROGRAM_TABLE_FILTERS = {
  STATUS: 'status',
  OWNERS: 'programOwners',
  DELIVERY: 'delivery_type',
  CREATION: 'createdAt',
};

export const PROGRAM_INPUT_OBJECTIVES_FIELDS = {
  PAYOFF_OPPORTUNITY: 'payoff_opportunity',
  BUSINESS_NEED: 'business_need',
  JOB_PERFORMANCE_NEED: 'job_performance_need',
  LEARNING_NEED: 'learning_need',
  PREFERENCE_NEED: 'preference_need',
} as const;

export const PROGRAM_INPUT_OBJECTIVES_FIELDS_MAPPING = {
  payoff_opportunity: 'payoffOpportunity',
  business_need: 'businessNeed',
  job_performance_need: 'jobPerformanceNeed',
  learning_need: 'learningNeed',
  preference_need: 'preferenceNeed',
};

export const ROI_COSTS_PRIMARY_FIELDS = [
  'id',
  'description',
  'cost',
  'costStageId',
];
