import React, { useEffect, useMemo, useCallback, useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import useModal from 'Hooks/useModal';
import {
  Table,
  TextField,
  Button,
  Tooltip,
  Icon,
} from '@getsynapse/design-system';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import {
  getUserAllocations,
  bulkUpdateAllocations,
  getCurrentEditingUser,
  setCurrentEditingUser,
} from 'state/ResourceAllocation/resourceAllocation';
import { DATE } from 'utils/constants';
import { AllocationUpdateData } from 'utils/customTypes';
import { getProjectRole } from '../../helpers';

const ActionButtons: React.FC<{
  onSaveCallback: () => void;
  onCancelCallback: () => void;
}> = ({ onSaveCallback, onCancelCallback }) => {
  return (
    <div className='flex justify-between'>
      <Tooltip
        timeout={0}
        position='topCenter'
        contentProps={{
          className: classNames(
            'z-10 py-1 px-3 bg-neutral-darker min-w-12',
            'text-center shadow-tooltip'
          ),
        }}
        openMode='hover2'
        trigger={
          <Button
            onClick={onSaveCallback}
            variant='tertiary'
            className='p-1.5'
            data-cy='confirm-allocation-update'
          >
            <Icon
              name='checkmark-circle'
              className='text-success-darker text-lg'
            />
          </Button>
        }
      >
        {intl.get('SAVE')}
      </Tooltip>

      <Tooltip
        timeout={0}
        position='topLeft'
        contentProps={{
          className: classNames(
            'z-10 py-1 px-3 bg-neutral-darker min-w-12',
            'text-center shadow-tooltip'
          ),
        }}
        openMode='hover2'
        trigger={
          <Button
            onClick={onCancelCallback}
            variant='tertiary'
            className='p-1.5'
            data-cy='cancel-allocation-update'
          >
            <Icon name='close-circle' className='text-neutral-dark text-lg' />
          </Button>
        }
      >
        {intl.get('CANCEL')}
      </Tooltip>
    </div>
  );
};

const AdjustAllocationModal: React.FC = () => {
  const { Modal, modalProps, openModal, closeModal } = useModal();
  const currentEditingWeeklyHoursUser = useSelector(getCurrentEditingUser);
  const allocationsList = useSelector(getUserAllocations);

  const dispatch = useDispatch();
  const [editingAllocation, setEditingAllocation] = useState('');
  const [stagedAllocations, setStagedAllocations] = useState(allocationsList);
  const [shouldModalOpen, setShouldModalOpen] = useState(false);
  const [itemToUpdate, setItemToUpdate] = useState<AllocationUpdateData>({
    allocationId: '',
    allocatedHours: 0,
    actualHours: 0,
  });

  useEffect(() => {
    if (currentEditingWeeklyHoursUser.userId) {
      setShouldModalOpen(true);
    } else {
      setShouldModalOpen(false);
    }
  }, [currentEditingWeeklyHoursUser]);

  const handleCloseModal = useCallback(() => {
    setEditingAllocation('');
    dispatch(setCurrentEditingUser({ userId: '', name: '' }));
    closeModal();
    setShouldModalOpen(false);
  }, [closeModal, setEditingAllocation, dispatch]);

  const [fieldsToUpdate, setFieldsToUpdate] = useState<AllocationUpdateData[]>(
    []
  );

  const [canSaveChanges, setCanSaveChanges] = useState(false);

  useEffect(() => {
    setStagedAllocations(allocationsList);
  }, [allocationsList, dispatch]);

  useEffect(() => {
    if (shouldModalOpen && !modalProps.isOpen) {
      openModal(modalProps);
    }
  }, [openModal, modalProps, shouldModalOpen]);

  useEffect(() => {
    const tempFiledsToUpdate = [];

    if (isEmpty(stagedAllocations) || isEmpty(allocationsList)) {
      return;
    }
    for (let i = 0; i < allocationsList.length; i++) {
      if (
        allocationsList[i].allocationId &&
        (allocationsList[i].allocatedHours !==
          stagedAllocations[i].allocatedHours ||
          allocationsList[i].actualHours !== stagedAllocations[i].actualHours)
      ) {
        tempFiledsToUpdate.push({
          allocationId: stagedAllocations[i].allocationId,
          allocatedHours: stagedAllocations[i].allocatedHours,
          actualHours: stagedAllocations[i].actualHours || 0,
        });
      }
    }
    setFieldsToUpdate(tempFiledsToUpdate);
    if (!isEmpty(tempFiledsToUpdate) && !canSaveChanges) {
      setCanSaveChanges(true);
    }

    if (isEmpty(tempFiledsToUpdate) && canSaveChanges) {
      setCanSaveChanges(false);
    }
  }, [allocationsList, stagedAllocations, canSaveChanges]);

  const isEditing = (id: string) =>
    editingAllocation && editingAllocation === id;

  const onSave = useCallback(() => {
    dispatch(bulkUpdateAllocations(fieldsToUpdate));
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get(
          'PEOPLE.RESOURCE_ALLOCATION.UPDATE_WEEKLY_ALLOCATION_MODAL.SUCCESS_BANNER'
        ),
      })
    );
    handleCloseModal();
  }, [fieldsToUpdate, dispatch, handleCloseModal]);

  const actionButons = useMemo(
    () => [
      {
        children: intl.get('SAVE'),
        variant: 'primary',
        disabled: !canSaveChanges,
        onClick: onSave,
        'data-cy': 'confirm-button',
      },
      {
        children: intl.get('CANCEL'),
        variant: 'tertiary',
        onClick: handleCloseModal,
      },
    ],
    [handleCloseModal, canSaveChanges, onSave]
  );

  const getDatesString = (start: string, end: string) => {
    return (
      <div className='w-[10.75rem]'>{`${moment(new Date(start)).format(
        DATE.TASK_TABLE_FORMAT
      )} - ${moment(new Date(end)).format(DATE.TASK_TABLE_FORMAT)}`}</div>
    );
  };

  const onSaveRowChange = () => {
    setEditingAllocation('');
    if (isEmpty(itemToUpdate)) {
      return;
    }
    const tempList = [...stagedAllocations];
    const result = tempList.map((item: any) => {
      const tempItem = { ...item };
      if (item.allocationId === itemToUpdate.allocationId) {
        tempItem.allocatedHours = itemToUpdate.allocatedHours;
        tempItem.actualHours = itemToUpdate.actualHours;
      }
      return tempItem;
    });
    setStagedAllocations(result);
    setEditingAllocation('');
    setItemToUpdate({ allocationId: '', allocatedHours: 0, actualHours: 0 });
  };

  const getHoursLeftSpan = (value: number) => {
    if (value < 0) {
      return (
        <span className='text-error-dark bg-error-lightest'>{value} h</span>
      );
    }
    return `${value} ${intl.get(
      'PEOPLE.RESOURCE_ALLOCATION.UPDATE_WEEKLY_ALLOCATION_MODAL.HOUR_LABEL'
    )}`;
  };

  const handleHoursChange = (key: string, value: number) => {
    setItemToUpdate((prevState: AllocationUpdateData) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleClickEdit = (allocation: AllocationUpdateData) => {
    setEditingAllocation(allocation.allocationId);
    setItemToUpdate((prevState: AllocationUpdateData) => ({
      ...prevState,
      ...allocation,
    }));
  };

  return (
    <Modal
      {...modalProps}
      closeModal={handleCloseModal}
      title={currentEditingWeeklyHoursUser.name}
      size='large'
      actionButtons={actionButons}
      data-cy='adjust-allocation-modal'
      aria-label={intl.get(
        'PEOPLE.RESOURCE_ALLOCATION.TABLE.ADJUST_ALLOCATION'
      )}
    >
      <div
        className={classNames('max-h-64 overflow-y-auto rounded', {
          'border border-neutral-lighter-two': stagedAllocations.length > 0,
        })}
      >
        <Table
          canSelectRows={false}
          className={classNames('w-full max-h-10 overflow-y-auto', {
            'border-0': stagedAllocations.length > 0,
          })}
          data={{
            headData: {
              stickyHeader: true,
              headCells: [
                {
                  content: intl.get(
                    'PEOPLE.RESOURCE_ALLOCATION.UPDATE_WEEKLY_ALLOCATION_MODAL.TIME_LINE'
                  ),
                  className: 'rounded-tl',
                },
                {
                  content: intl.get(
                    'PEOPLE.RESOURCE_ALLOCATION.UPDATE_WEEKLY_ALLOCATION_MODAL.PROJECT_ROLE'
                  ),
                },
                {
                  content: intl.get(
                    'PEOPLE.RESOURCE_ALLOCATION.UPDATE_WEEKLY_ALLOCATION_MODAL.HOURS_ALLOCATED'
                  ),
                },
                {
                  content: intl.get(
                    'PEOPLE.RESOURCE_ALLOCATION.UPDATE_WEEKLY_ALLOCATION_MODAL.HOURS_LEFT'
                  ),
                },
                {
                  content: intl.get(
                    'PEOPLE.RESOURCE_ALLOCATION.UPDATE_WEEKLY_ALLOCATION_MODAL.ACTUAL_HOURS'
                  ),
                },
                {
                  content: '',
                  className: 'w-24 rounded-tr',
                },
              ],
            },
            rows: stagedAllocations.map((allocation: any) => {
              const isEditingCurrentAllocation = isEditing(
                allocation.allocationId
              );
              const shouldDisableEditButton =
                editingAllocation && !isEditingCurrentAllocation;
              return {
                className: 'text-neutral-black',
                cells: [
                  {
                    content: getDatesString(
                      allocation.weekStart,
                      allocation.weekEnd
                    ),
                  },
                  {
                    content: (
                      <div
                        className='w-30 truncate'
                        title={getProjectRole(allocation.role)}
                      >
                        {getProjectRole(allocation.role)}
                      </div>
                    ),
                  },
                  {
                    content: isEditingCurrentAllocation ? (
                      <div className='flex items-center'>
                        <TextField
                          divProps={{ className: 'w-16' }}
                          type='number'
                          height='small'
                          inputClassName='no-spinner'
                          defaultValue={allocation.allocatedHours}
                          onChange={(e: any) =>
                            handleHoursChange(
                              'allocatedHours',
                              parseFloat(e.target.value)
                            )
                          }
                          className='w-16'
                          data-cy='allocation-hours-field'
                        />
                        <span className='ml-2'>
                          {intl.get(
                            'PEOPLE.RESOURCE_ALLOCATION.UPDATE_WEEKLY_ALLOCATION_MODAL.HOUR_LABEL'
                          )}
                        </span>
                      </div>
                    ) : (
                      `${allocation.allocatedHours} ${intl.get(
                        'PEOPLE.RESOURCE_ALLOCATION.UPDATE_WEEKLY_ALLOCATION_MODAL.HOUR_LABEL'
                      )}`
                    ),
                  },
                  {
                    content: getHoursLeftSpan(
                      allocation.capacity - allocation.allocatedHours
                    ),
                  },
                  {
                    content: isEditingCurrentAllocation ? (
                      <div className='flex items-center'>
                        <TextField
                          divProps={{ className: 'w-16' }}
                          type='number'
                          height='small'
                          inputClassName='no-spinner'
                          defaultValue={allocation.actualHours || 0}
                          onChange={(e: any) =>
                            handleHoursChange(
                              'actualHours',
                              parseFloat(e.target.value)
                            )
                          }
                          className='w-16'
                          data-cy='allocation-actual-hours-field'
                        />
                        <span className='ml-2'>
                          {intl.get(
                            'PEOPLE.RESOURCE_ALLOCATION.UPDATE_WEEKLY_ALLOCATION_MODAL.HOUR_LABEL'
                          )}
                        </span>
                      </div>
                    ) : (
                      `${allocation.actualHours || 0} ${intl.get(
                        'PEOPLE.RESOURCE_ALLOCATION.UPDATE_WEEKLY_ALLOCATION_MODAL.HOUR_LABEL'
                      )}`
                    ),
                  },
                  {
                    content: isEditingCurrentAllocation ? (
                      <ActionButtons
                        onCancelCallback={() => setEditingAllocation('')}
                        onSaveCallback={onSaveRowChange}
                      />
                    ) : (
                      <div className='w-full flex justify-end'>
                        <Tooltip
                          timeout={0}
                          position='topLeft'
                          contentProps={{
                            className: classNames(
                              'min-w-12 py-1 px-3 bg-neutral-darker',
                              'z-10 text-center shadow-tooltip'
                            ),
                          }}
                          openMode='hover2'
                          trigger={
                            <Button
                              onClick={() =>
                                handleClickEdit({
                                  allocationId: allocation.allocationId,
                                  allocatedHours: allocation.allocatedHours,
                                  actualHours: allocation.actualHours,
                                })
                              }
                              variant='tertiary'
                              disabled={shouldDisableEditButton}
                              className={classNames('p-1.5', {
                                'cursor-not-allowed': shouldDisableEditButton,
                              })}
                              data-cy={`edit-allocation-${allocation.allocationId}-button`}
                            >
                              <Icon
                                name='pencil-outline'
                                className={classNames('text-lg', {
                                  'text-neutral-lighter':
                                    shouldDisableEditButton,
                                  'text-neutral-dark': !shouldDisableEditButton,
                                })}
                              />
                            </Button>
                          }
                        >
                          {intl.get('EDIT')}
                        </Tooltip>
                      </div>
                    ),
                    className: 'py-3 px-4 w-24',
                  },
                ],
              };
            }),
          }}
        />
      </div>
    </Modal>
  );
};

export default AdjustAllocationModal;
