import intl from 'react-intl-universal';

import { FlatfileSettings } from '@flatfile/react';
import { IField, IFieldSelect } from '@flatfile/adapter/build/main/interfaces';
import {
  REQUEST_IMPORT_ATTRIBUTES,
  REQUEST_LD_PRIORITY,
  REQUEST_STATUS,
} from 'utils/constants';
import {
  Option,
  RequestQuestion,
  RequestQuestionDataDropdown,
} from 'utils/customTypes';

const questionTypeOptionsMapper = (
  type: string,
  data: any
): { label: string; value: string | boolean }[] | undefined => {
  if (
    type === 'dropdown' &&
    !(data as RequestQuestionDataDropdown).multiselect
  ) {
    return data.value.items.map(
      ({ value, display }: { value: string; display: string }) => ({
        label: display,
        value,
      })
    );
  } else if (type === 'radio') {
    return data.value.options.map(
      ({ text, id }: { text: string; id: string }) => ({
        label: text,
        value: id,
      })
    );
  } else if (type === 'toggle') {
    return [
      { label: intl.get('TOGGLE_OFF'), value: false },
      { label: intl.get('TOGGLE_ON'), value: true },
    ];
  }
  // Type is a simple value, or a multi-select
  return undefined;
};

export const flatfileSettings: (
  forms: Option[],
  owners: Option[],
  users: Option[],
  businessTeams: Option[],
  customQuestions: RequestQuestion[]
) => FlatfileSettings = (
  forms,
  owners,
  users,
  businessTeams,
  customQuestions
) => ({
  type: 'Requests',
  title: intl.get('REQUESTS_LIST_PAGE.TABLE.IMPORT.IMPORT_FROM_FILE'),
  fields: [
    {
      label: intl.get('REQUESTS_LIST_PAGE.TABLE.IMPORT.COLUMN_LABELS.TITLE'),
      key: REQUEST_IMPORT_ATTRIBUTES.TITLE,
      validators: [{ validate: 'required' }],
    },
    {
      label: intl.get(
        'REQUESTS_LIST_PAGE.TABLE.IMPORT.COLUMN_LABELS.DESCRIPTION'
      ),
      key: REQUEST_IMPORT_ATTRIBUTES.DESCRIPTION,
    },
    {
      label: intl.get('REQUEST_PAGE.BASIC_DETAILS.FIELDS.REQUEST_STATUS'),
      key: REQUEST_IMPORT_ATTRIBUTES.STATUS,
      validators: [{ validate: 'required' }],
      type: 'select',
      options: Object.keys(REQUEST_STATUS).map((status) => ({
        value: status.toLocaleLowerCase(),
        label: intl.get(`REQUESTS_LIST_PAGE.TABLE.STATUS_LABEL.${status}`),
      })),
    },
    {
      label: intl.get('REQUESTS_LIST_PAGE.TABLE.IMPORT.COLUMN_LABELS.CREATOR'),
      key: REQUEST_IMPORT_ATTRIBUTES.CREATOR,
      type: 'select',
      options: users,
      validators: [{ validate: 'required' }],
    },
    {
      label: intl.get(
        'REQUESTS_LIST_PAGE.TABLE.IMPORT.COLUMN_LABELS.BASE_FORM'
      ),
      key: REQUEST_IMPORT_ATTRIBUTES.BASE_FORM,
      type: 'select',
      options: forms,
    },
    {
      label: intl.get('REQUESTS_LIST_PAGE.TABLE.IMPORT.COLUMN_LABELS.OWNERS'),
      key: REQUEST_IMPORT_ATTRIBUTES.OWNERS,
      type: 'select',
      options: owners,
    },
    {
      label: intl.get(
        'REQUESTS_LIST_PAGE.TABLE.IMPORT.COLUMN_LABELS.BUSINESS_TEAM'
      ),
      key: REQUEST_IMPORT_ATTRIBUTES.BUSINESS_TEAM,
      type: 'select',
      options: businessTeams,
    },
    {
      label: intl.get(
        'REQUESTS_LIST_PAGE.TABLE.IMPORT.COLUMN_LABELS.LD_PRIORITY'
      ),
      key: REQUEST_IMPORT_ATTRIBUTES.LD_PRIORITY,
      type: 'select',
      options: Object.keys(REQUEST_LD_PRIORITY).map((priority) => ({
        value: priority.toLocaleLowerCase(),
        label: intl.get(
          `REQUEST_PAGE.L_D_SECTION.PRIORITY_OPTIONS.${
            REQUEST_LD_PRIORITY[priority as keyof typeof REQUEST_LD_PRIORITY]
          }`
        ),
      })),
    },
    {
      label: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.SUBMISSION_DATE'),
      key: REQUEST_IMPORT_ATTRIBUTES.SUBMITTED_AT,
      type: 'string',
    },
    {
      label: intl.get('REQUESTS_LIST_PAGE.TABLE.HEAD.DECISION_DATE'),
      key: REQUEST_IMPORT_ATTRIBUTES.DECISION_DATE,
      type: 'string',
    },
    ...customQuestions.map((question) => {
      const options = questionTypeOptionsMapper(question.type, question.data);

      let type = options ? 'select' : 'string';
      return {
        label: question.data.label,
        key: `question_${question.id}`,
        type: type,
        ...(options && type !== 'string' ? { options } : {}),
      } as IField | IFieldSelect;
    }),
  ],
});
