import {
  createAsyncThunk,
  createSlice,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { strategyCategories, Strategy } from 'utils/types/program';
import { RootState } from 'state/store';
import programStrategyAPI from './programStrategyAPI';
import { generateDisallowedCategories } from 'Templates/StrategyCategoryTemplate/components/helpers';

interface programStrategyState {
  status: Status;
  value: Strategy;
}

/* ============================= INITIAL STATE ============================== */

const defaultValues = {
  id: '',
  name: '',
  description: '',
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  deleted_at: '',
  deleted_by: '',
  categories: [],
} as Strategy;

const initialState: programStrategyState = {
  value: defaultValues,
  status: SLICE_STATUS.IDLE,
};

/* ============================= ACTIONS ============================== */
export const resetProgramStrategy = createAction(
  'program/RESET_PROGRAM_STRATEGY'
);

/* ============================== REDUX THUNK =============================== */
export const fetchProgramStrategy = createAsyncThunk(
  'program/FETCH_PROGRAM_STRATEGY',
  async (programId: string) => {
    const response = await programStrategyAPI.fetchProgramStrategy(programId);
    if (response.code !== 200) {
      throw new Error('Error fetching program strategy');
    }
    return response.data;
  }
);

export const getROIFileSignedUrl = createAsyncThunk(
  'program/GET_ROI_FILE_SIGNED_URL',
  async ({ programId, fileName }: { programId: string; fileName: string }) => {
    const response = await programStrategyAPI.fetchSignedROIFileUrl(
      programId,
      fileName
    );
    return response;
  }
);

/* ================================= REDUCER ================================ */
const programSlice = createSlice({
  name: 'programStrategy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProgramStrategy.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchProgramStrategy.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchProgramStrategy.fulfilled, (state, action) => {
        state.value = action.payload || defaultValues;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(resetProgramStrategy, (state) => {
        state.value = defaultValues;
      });
  },
});

/* =============================== SELECTORS ================================ */

export const getProgramStrategyStatus = (state: RootState) =>
  state.programStrategy.status;

export const getProgramStrategy = (state: RootState) =>
  state.programStrategy.value;

export const selectCategoryIds = (
  enableProgramStrategyBeta12: boolean,
  enableProgramStrategyBeta14: boolean,
  enableProgramStrategy15Roi: boolean
) =>
  createSelector([getProgramStrategy], (programStrategy) => {
    const DISALLOWED_CATEGORIES = generateDisallowedCategories(
      enableProgramStrategyBeta12,
      enableProgramStrategyBeta14,
      enableProgramStrategy15Roi
    );
    const categoryIds = programStrategy.categories
      .filter(
        (category) =>
          !DISALLOWED_CATEGORIES.includes(category.name as strategyCategories)
      )
      .map((category) => category.id);
    return categoryIds;
  });

export default programSlice.reducer;
