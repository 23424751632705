import {
  useMemo,
  useState,
  useEffect,
  ChangeEvent,
  KeyboardEvent,
  useCallback,
} from 'react';
import { FieldTemplateType, FieldValueType } from 'utils/types/fields';
import {
  ALLOWED_FIELD_VALUE_SELECTORS,
  ALLOWED_FIELD_TYPES,
  CUSTOM_FIELDS,
} from 'utils/constants';
import intl from 'react-intl-universal';
import {
  Dropdown,
  FormItem,
  IconButton,
  Icon,
  TextField,
  Tooltip,
  FormLabel,
} from '@getsynapse/design-system';
import { Option } from 'utils/customTypes';
import { getInitialValueForDropDown } from 'utils/functions';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

type OptionsWithId = {
  id: string;
  duplicate: boolean;
} & FieldValueType;

const FieldOptions = ({
  data,
  handleFieldChange,
  onUpdatePage,
}: {
  data: FieldTemplateType;
  handleFieldChange: (fieldName: string, fieldValue: FieldValueType[]) => void;
  onUpdatePage: boolean;
}) => {
  const [disableAddOption, setDisableAddOption] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [optionsAndOtherValues, setOptionsAndOtherValues] = useState<
    OptionsWithId[]
  >(
    data?.allowed_values?.map((option: FieldValueType) => ({
      ...option,
      id: uuidv4(),
      duplicate: false,
    }))!
  );
  const [hovering, setHovering] = useState('');

  useEffect(() => {
    if (data.allowed_values?.length === 0) {
      setOptionsAndOtherValues([]);
    }
  }, [data.allowed_values]);

  const numberOption =
    data?.type === ALLOWED_FIELD_TYPES.INT ||
    data?.type === ALLOWED_FIELD_TYPES.FLOAT ||
    data?.type === ALLOWED_FIELD_TYPES.NUMERIC;

  const optionTypes = useMemo(() => {
    const options: Option[] = [
      {
        label: intl.get(
          'SETTINGS_PAGE.FIELDS_PAGE.DETAILS.FIELD_OPTIONS.SINGLE_LINE'
        ),
        value: ALLOWED_FIELD_TYPES.SINGLE_LINE,
      },
      {
        label: intl.get('SETTINGS_PAGE.FIELDS_PAGE.DETAILS.FIELD_OPTIONS.INT'),
        value: ALLOWED_FIELD_TYPES.INT,
      },
      {
        label: intl.get(
          'SETTINGS_PAGE.FIELDS_PAGE.DETAILS.FIELD_OPTIONS.FLOAT'
        ),
        value: ALLOWED_FIELD_TYPES.FLOAT,
      },
    ];
    if (
      (data?.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.DROPDOWN &&
        !data.collection) ||
      data?.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.RADIO
    ) {
      options.push({
        label: intl.get(
          'SETTINGS_PAGE.FIELDS_PAGE.DETAILS.FIELD_OPTIONS.BOOLEAN'
        ),
        value: ALLOWED_FIELD_TYPES.BOOLEAN,
      });
    }
    return options;
  }, [data?.value_selector, data?.collection]);

  const checkIfEmptyOrDuplicated = useCallback(
    () =>
      optionsAndOtherValues?.length === 0 ||
      optionsAndOtherValues?.some(
        (item: OptionsWithId) => !item.val?.toString() || item.duplicate
      ),
    [optionsAndOtherValues]
  );

  useEffect(() => {
    setDisableAddOption(editMode || checkIfEmptyOrDuplicated());
  }, [checkIfEmptyOrDuplicated, editMode]);

  const addOption = () => {
    setOptionsAndOtherValues((prevState: OptionsWithId[]) =>
      prevState.concat([{ val: '', id: uuidv4(), duplicate: false }])
    );
    handleFieldChange(
      CUSTOM_FIELDS.ALLOWED_VALUES,
      data?.allowed_values?.concat([{ val: '' }])!
    );
  };

  const removeOption = (id: string) => {
    let index = optionsAndOtherValues.findIndex(
      (item: OptionsWithId) => item.id === id
    );
    const newOptions = [...optionsAndOtherValues];
    newOptions.splice(index, 1);
    setOptionsAndOtherValues(newOptions);
    const newAllowedValues = [...data?.allowed_values!];
    newAllowedValues.splice(index, 1);
    handleFieldChange(CUSTOM_FIELDS.ALLOWED_VALUES, newAllowedValues);
  };

  const checkDuplicate = (
    array: OptionsWithId[],
    value: string | number,
    id: string
  ) =>
    array.some((item: OptionsWithId) => item.val === value && item.id !== id);

  const editOption = debounce(
    (oldValue: string, updatedValue: string, id: string) => {
      if (oldValue !== updatedValue) {
        let newValue: string | number = updatedValue;
        if (updatedValue && numberOption) {
          newValue = Number(updatedValue);
        }
        const duplicate = checkDuplicate(optionsAndOtherValues, newValue, id);
        let newOptions = optionsAndOtherValues?.map((option: OptionsWithId) =>
          option.id === id
            ? { ...option, val: newValue, duplicate: duplicate }
            : option
        );
        //update the duplicate value of the other options
        newOptions = newOptions.map((option: OptionsWithId) =>
          option.val === oldValue && option.duplicate
            ? {
                ...option,
                duplicate: checkDuplicate(newOptions, option.val, option.id),
              }
            : option
        );
        setOptionsAndOtherValues(newOptions);
        const optionIndex = optionsAndOtherValues.findIndex(
          (item: OptionsWithId) => item.id === id
        );
        handleFieldChange(
          CUSTOM_FIELDS.ALLOWED_VALUES,
          data?.allowed_values?.map((item: FieldValueType, index) =>
            optionIndex === index ? { ...item, val: newValue } : item
          )!
        );
      }
    },
    500
  );

  const addFirstOption = (value: string) => {
    if (value !== '') {
      setOptionsAndOtherValues([
        {
          val: numberOption ? Number(value) : value,
          id: uuidv4(),
          duplicate: false,
        },
      ]);
      handleFieldChange(
        CUSTOM_FIELDS.ALLOWED_VALUES,
        data?.allowed_values?.concat([
          { val: numberOption ? Number(value) : value },
        ])!
      );
      setEditMode(false);
    }
  };

  const handleInputBlur = (
    event: React.ChangeEvent<HTMLInputElement>,
    optionId: string
  ) => {
    setEditMode(false);
    if (event.target.value === '') {
      removeOption(optionId);
    }
    if (!checkIfEmptyOrDuplicated()) {
      setDisableAddOption(false);
    }
  };

  const checkInput = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === '.') {
      event.preventDefault();
    }
  };

  const changeOptionType = (option: Option) => {
    handleFieldChange(CUSTOM_FIELDS.TYPE, option.value);
    if (option.value === ALLOWED_FIELD_TYPES.BOOLEAN) {
      handleFieldChange(CUSTOM_FIELDS.ALLOWED_VALUES, [
        { val: true, display: intl.get('YES') },
        { val: false, display: intl.get('NO') },
      ]);
      setOptionsAndOtherValues([
        { val: true, display: intl.get('YES'), id: uuidv4(), duplicate: false },
        { val: false, display: intl.get('NO'), id: uuidv4(), duplicate: false },
      ]);
    } else {
      handleFieldChange(CUSTOM_FIELDS.ALLOWED_VALUES, []);
      setOptionsAndOtherValues([]);
    }
  };

  return (
    <>
      <div className='mt-6 flex items-center mb-2'>
        <FormLabel required className='mb-0'>
          {intl.get(
            'SETTINGS_PAGE.FIELDS_PAGE.DETAILS.OPTIONS_DATA_TYPE_LABEL'
          )}
        </FormLabel>
        <Tooltip
          openMode='hover2'
          timeout={0}
          contentProps={{
            className:
              'z-5 bg-neutral-white text-purple-darker border border-purple-lighter',
          }}
          position='topRight'
          trigger={
            <IconButton
              name='information-circle'
              className='text-purple hover:text-purple-darker'
              description='more iformation'
              iconClassname='w-4 h-4'
            />
          }
        >
          {intl.get('SETTINGS_PAGE.FIELDS_PAGE.DETAILS.TYPE_TOOLTIP')}
        </Tooltip>
      </div>
      <Dropdown
        options={optionTypes}
        onChange={changeOptionType}
        values={getInitialValueForDropDown(optionTypes, data?.type)}
        disabled={onUpdatePage}
        className='mb-6'
        triggerProps={{ 'data-cy': 'options-data-type' }}
      />
      <FormItem
        label={intl.get('SETTINGS_PAGE.FIELDS_PAGE.DETAILS.OPTIONS_LABEL')}
        labelProps={{ required: data.allowed_values?.length === 0 }}
        className='mb-12'
      >
        {!data?.allowed_values?.length && (
          <TextField
            className='w-full'
            placeholder={intl.get(
              'SETTINGS_PAGE.FORMS.DESIGN.CUSTOM_QUESTIONS.OPTION_PLACEHOLDER'
            )}
            onFocus={() => setEditMode(true)}
            onBlur={(event: ChangeEvent<HTMLInputElement>) =>
              addFirstOption(event.target.value)
            }
            type={numberOption ? 'number' : 'text'}
            noBorder
            inputClassName='bg-transparent no-spinner'
            onKeyDown={(event: KeyboardEvent<HTMLInputElement>) =>
              data?.type === ALLOWED_FIELD_TYPES.INT ? checkInput(event) : {}
            }
          />
        )}
        <ul data-cy='options-list'>
          {optionsAndOtherValues.map((option: OptionsWithId) => (
            <li
              key={option.id}
              className={classNames({
                'border-l-2 border-secondary-dark': hovering === option.id,
              })}
            >
              <div className='shadow-header flex'>
                <TextField
                  className='w-full'
                  defaultValue={option.display ? option.display : option.val}
                  placeholder={intl.get(
                    'SETTINGS_PAGE.FORMS.DESIGN.CUSTOM_QUESTIONS.OPTION_PLACEHOLDER'
                  )}
                  onFocus={() => setEditMode(true)}
                  onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputBlur(event, option.id)
                  }
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    editOption(
                      option.val!.toString(),
                      event.target.value,
                      option.id
                    )
                  }
                  state={option.duplicate ? 'error' : 'default'}
                  helpText={
                    option.duplicate
                      ? intl.get(
                          'SETTINGS_PAGE.FIELDS_PAGE.DETAILS.DUPLICATE_ERROR'
                        )
                      : ''
                  }
                  readOnly={data?.type === ALLOWED_FIELD_TYPES.BOOLEAN}
                  type={numberOption ? 'number' : 'text'}
                  noBorder
                  inputClassName={classNames('bg-transparent no-spinner', {
                    'pointer-events-none':
                      data?.type === ALLOWED_FIELD_TYPES.BOOLEAN,
                  })}
                  onKeyDown={(event: KeyboardEvent<HTMLInputElement>) =>
                    data?.type === ALLOWED_FIELD_TYPES.INT
                      ? checkInput(event)
                      : {}
                  }
                />
                {data?.type !== ALLOWED_FIELD_TYPES.BOOLEAN && (
                  <IconButton
                    name='close'
                    description={intl.get(
                      'SETTINGS_PAGE.FORMS.DESIGN.CUSTOM_QUESTIONS.REMOVE_OPTION'
                    )}
                    onClick={() => {
                      removeOption(option.id);
                    }}
                    className='text-neutral m-3'
                    hasASize={false}
                    onMouseEnter={() => setHovering(option.id)}
                    onMouseLeave={() => setHovering('')}
                    iconClassname='pointer-events-none'
                    title={intl.get('REMOVE')}
                  />
                )}
              </div>
            </li>
          ))}
        </ul>
        {data?.type !== ALLOWED_FIELD_TYPES.BOOLEAN && (
          <button
            className='text-purple-dark hover:text-purple-darker focus-visible:text-purple-darker active:text-purple-darker py-1 focus:outline-none text-sm leading-6 focus:ring-0 disabled:text-primary-lighter disabled:cursor-not-allowed'
            onClick={addOption}
            disabled={disableAddOption}
            data-cy='add-option-button'
          >
            <div className='flex items-center h-8'>
              <Icon name='add-circle' className='mr-2 text-xl' />
              {intl.get(
                'SETTINGS_PAGE.FORMS.DESIGN.CUSTOM_QUESTIONS.ADD_OPTION'
              )}
            </div>
          </button>
        )}
      </FormItem>
    </>
  );
};

export default FieldOptions;
