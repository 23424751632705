import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { getTruncatedFileNameAndExtension } from '../utils';

const TruncatedFileName: React.FC<{ fileName: string }> = ({ fileName }) => {
  const { name, extension } = getTruncatedFileNameAndExtension(fileName);
  const nameRef = useRef<HTMLDivElement>(null);
  const [isFileNameTruncated, setIsFileNameTruncated] = useState(false);

  useEffect(() => {
    if (nameRef.current) {
      const isNameTruncated =
        nameRef.current.offsetWidth < nameRef.current.scrollWidth;
      if (isNameTruncated) {
        setIsFileNameTruncated(isNameTruncated);
      }
    }
  }, [nameRef]);

  return (
    <div className='w-full flex'>
      <div className='truncate' ref={nameRef}>
        {name}
      </div>
      <span
        className={classnames({
          inline: isFileNameTruncated,
          hidden: !isFileNameTruncated,
        })}
      >
        {extension}
      </span>
    </div>
  );
};

export default TruncatedFileName;
