import { Internationalization } from '@syncfusion/ej2-base';
import { Typography } from '@getsynapse/design-system';
import { ProjectResource } from 'utils/types/learningSchedule';
import { Link } from 'react-router-dom';

export const getDateTime = (
  startTime: Date,
  endTime: Date,
  isAllDay: boolean
) => {
  const intl = new Internationalization();
  const isSameDay = isSameDayEvent(
    startTime.toISOString(),
    endTime.toISOString()
  );

  if (isAllDay) {
    if (isSameDay) {
      return (
        intl.formatDate(startTime, { type: 'date', skeleton: 'full' }) +
        ' (All Day)'
      );
    } else {
      return (
        intl.formatDate(startTime, { type: 'date', skeleton: 'full' }) +
        ' - ' +
        intl.formatDate(endTime, { type: 'date', skeleton: 'full' }) +
        ' (All Day)'
      );
    }
  } else {
    if (isSameDay) {
      return (
        intl.formatDate(startTime, { type: 'date', skeleton: 'full' }) +
        ' (' +
        intl.formatDate(startTime, { skeleton: 'hm' }) +
        ' - ' +
        intl.formatDate(endTime, { skeleton: 'hm' }) +
        ')'
      );
    } else {
      return (
        intl.formatDate(startTime, { type: 'date', skeleton: 'full' }) +
        ' (' +
        intl.formatDate(startTime, { skeleton: 'hm' }) +
        ')' +
        ' - ' +
        intl.formatDate(endTime, { type: 'date', skeleton: 'full' }) +
        ' (' +
        intl.formatDate(endTime, { skeleton: 'hm' }) +
        ')'
      );
    }
  }
};

const isSameDayEvent = (startTimeStr: string, endTimeStr: string): boolean => {
  const startTime = new Date(startTimeStr);
  const endTime = new Date(endTimeStr);
  const startDate = new Date(
    startTime.getFullYear(),
    startTime.getMonth(),
    startTime.getDate()
  );
  const endDate = new Date(
    endTime.getFullYear(),
    endTime.getMonth(),
    endTime.getDate()
  );
  // Check if endTime is midnight (00:00:00)
  if (
    endTime.getHours() === 0 &&
    endTime.getMinutes() === 0 &&
    endTime.getSeconds() === 0
  ) {
    endDate.setDate(endDate.getDate() - 1);
  }
  return startDate.getTime() === endDate.getTime();
};

export const renderProjectNameLink = ({
  projectElement,
  index = 0,
  dataTestId,
}: {
  projectElement: ProjectResource;
  index: number;
  dataTestId: string;
}) => {
  return (
    <Link
      rel='noreferrer'
      target='_blank'
      key={index}
      to={`/project/${projectElement.id}`}
      data-testid={`${dataTestId}-${index}`}
    >
      <Typography
        key={index}
        variant='body2'
        className='underline text-secondary line-clamp-1'
      >
        {projectElement.title}
      </Typography>
    </Link>
  );
};
