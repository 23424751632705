import { useSelector } from 'react-redux';
import {
  selectAggregates,
  selectAggregatesStatus,
} from 'state/Insights/capacitySlice';
import intl from 'react-intl-universal';
import { Card } from 'Pages/InsightsPage/Components/Card';
import { Typography } from '@getsynapse/design-system';
import { SLICE_STATUS } from 'utils/constants';
import AggregatesLoader from './AggregatesLoader';

const AggregatesNumbers = () => {
  const aggregates = useSelector(selectAggregates);
  const aggregatesStatus = useSelector(selectAggregatesStatus);

  return aggregatesStatus === SLICE_STATUS.LOADING ? (
    <AggregatesLoader />
  ) : (
    <>
      <div className='flex w-full min-h-26 space-x-2'>
        <Card
          data-testid='total-resources'
          className='flex min-h-26 w-full justify-center items-center'
        >
          <div className='w-full flex items-center'>
            <p className='text-big-cards font-bold mr-4 w-1/2 text-right'>
              {aggregates.numberOfResources}
            </p>
            <Typography variant='body2' weight='medium' className='leading-4'>
              {intl.getHTML('CAPACITY_INSIGHTS.NUMBER_OF_RESOURCES')}
            </Typography>
          </div>
        </Card>
        <Card
          data-testid='total-demand-hours'
          className='flex min-h-26 w-full justify-center items-center'
        >
          <div className='w-full flex items-center'>
            <p className='text-big-cards font-bold mr-4 w-1/2 text-right'>
              {aggregates.totalDemandHours}
            </p>
            <Typography variant='body2' weight='medium' className='leading-4'>
              {intl.getHTML('CAPACITY_INSIGHTS.DEMAND_HOURS')}
            </Typography>
          </div>
        </Card>
        <Card
          data-testid='total-actual-hours'
          className='flex min-h-26 w-full justify-center items-center'
        >
          <div className='w-full flex items-center'>
            <p className='text-big-cards font-bold mr-4 w-1/2 text-right'>
              {aggregates.totalActualHours}
            </p>
            <Typography variant='body2' weight='medium' className='leading-4'>
              {intl.getHTML('CAPACITY_INSIGHTS.ACTUAL_HOURS')}
            </Typography>
          </div>
        </Card>
      </div>
      <div className='flex w-full min-h-26 space-x-2'>
        <Card
          data-testid='total-capacity-hours'
          className='flex min-h-26 w-full justify-center items-center'
        >
          <div className='w-full flex items-center'>
            <p className='text-big-cards font-bold mr-4 w-1/2 text-right'>
              {aggregates.totalCapacityHours}
            </p>
            <Typography variant='body2' weight='medium' className='leading-4'>
              {intl.getHTML('CAPACITY_INSIGHTS.CAPACITY_HOURS')}
            </Typography>
          </div>
        </Card>
        <Card
          data-testid='total-available-hours'
          className='flex min-h-26 w-full justify-center items-center'
        >
          <div className='w-full flex items-center'>
            <p className='text-big-cards font-bold mr-4 w-1/2 text-right'>
              {aggregates.totalAvailableHours}
            </p>
            <Typography variant='body2' weight='medium' className='leading-4'>
              {intl.getHTML('CAPACITY_INSIGHTS.AVAILABLE_HOURS')}
            </Typography>
          </div>
        </Card>
        <Card
          data-testid='total-allocated-hours'
          className='flex min-h-26 w-full justify-center items-center'
        >
          <div className='w-full flex items-center'>
            <p className='text-big-cards font-bold mr-4 w-1/2 text-right'>
              {aggregates.totalAllocatedHours}
            </p>
            <Typography variant='body2' weight='medium' className='leading-4'>
              {intl.getHTML('CAPACITY_INSIGHTS.ALLOCATED_HOURS')}
            </Typography>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AggregatesNumbers;
