import { GlobalBanner, Button, Typography } from '@getsynapse/design-system';
import { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import ConfirmCancellationModal from './ConfirmCancellationModal';
import { Request } from 'utils/customTypes';
import classNames from 'classnames';

type Props = {
  requestData: Request;
  sidePanel?: boolean;
  dataTestId?: string;
};

const PendingCancellationBanner = ({
  requestData,
  sidePanel,
  dataTestId,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Fragment>
      <ConfirmCancellationModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        requestData={requestData}
      />
      <GlobalBanner
        variant='warning'
        hasCloseIcon
        data-cy='pending-cancellation-banner'
        className={classNames(`${sidePanel ? 'mb-4' : 'mt-4'}`)}
        iconProps={{
          'data-testid': dataTestId,
        }}
      >
        {() => {
          return (
            <div className='flex justify-between items-start'>
              <div>
                <Typography variant='body' className='text-warning-darker'>
                  {intl.get('REQUEST_PAGE.PENDING_CANCELLATION_BANNER.TITLE')}
                </Typography>
                <Typography variant='body' className='text-warning-darker'>
                  {intl.get('REQUEST_PAGE.PENDING_CANCELLATION_BANNER.BODY')}
                </Typography>
              </div>
              {!sidePanel && (
                <Button
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                  color='warning'
                  data-cy='pending-cancellation-button'
                >
                  {intl.get('REQUEST_PAGE.PENDING_CANCELLATION_BANNER.BUTTON')}
                </Button>
              )}
            </div>
          );
        }}
      </GlobalBanner>
    </Fragment>
  );
};

export default PendingCancellationBanner;
