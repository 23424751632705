import intl from 'react-intl-universal';
import { kebabCase } from 'lodash';
import { tailwindOverride, Typography, Icon } from '@getsynapse/design-system';
import {
  PROJECT_HEALTH,
  PROJECT_PRIORITY,
  PROJECT_STATUS,
} from 'utils/constants';

import lowPriority from 'assets/icons/low-priority.svg';
import mediumPriority from 'assets/icons/medium-priority.svg';
import highPriority from 'assets/icons/high-priority.svg';

import atRisk from 'assets/icons/project-at-risk-health.svg';
import onTrack from 'assets/icons/project-on-track-health.svg';
import offTrack from 'assets/icons/project-off-track-health.svg';

import newStatus from 'assets/icons/project-new-status.svg';
import inPlanning from 'assets/icons/project-in-queue-status.svg';
import inProgress from 'assets/icons/project-in-progress-status.svg';
import completed from 'assets/icons/project-completed-status.svg';
import onHold from 'assets/icons/project-on-hold-status.svg';
import closed from 'assets/icons/project-closed-status.svg';

type ProjectPriority = typeof PROJECT_PRIORITY[keyof typeof PROJECT_PRIORITY];
type ProjectHealth = typeof PROJECT_HEALTH[keyof typeof PROJECT_HEALTH];
type ProjectStatus = typeof PROJECT_STATUS[keyof typeof PROJECT_STATUS];

const projectPriorityIconMap: Record<ProjectPriority, string> = {
  [PROJECT_PRIORITY.LOW]: lowPriority,
  [PROJECT_PRIORITY.MEDIUM]: mediumPriority,
  [PROJECT_PRIORITY.HIGH]: highPriority,
};

export const projectPriorityLabelMap: Record<ProjectPriority, string> = {
  [PROJECT_PRIORITY.LOW]: 'PROJECT_DETAIL.PRIORITY_OPTIONS.LOW',
  [PROJECT_PRIORITY.MEDIUM]: 'PROJECT_DETAIL.PRIORITY_OPTIONS.MEDIUM',
  [PROJECT_PRIORITY.HIGH]: 'PROJECT_DETAIL.PRIORITY_OPTIONS.HIGH',
};

const projectHealthIconMap: Record<ProjectHealth, string> = {
  [PROJECT_HEALTH.AT_RISK]: atRisk,
  [PROJECT_HEALTH.ON_TRACK]: onTrack,
  [PROJECT_HEALTH.OFF_TRACK]: offTrack,
};

export const projectHealthLabelMap: Record<ProjectHealth, string> = {
  [PROJECT_HEALTH.AT_RISK]: 'PROJECT_DETAIL.HEALTH_OPTIONS.AT_RISK',
  [PROJECT_HEALTH.ON_TRACK]: 'PROJECT_DETAIL.HEALTH_OPTIONS.ON_TRACK',
  [PROJECT_HEALTH.OFF_TRACK]: 'PROJECT_DETAIL.HEALTH_OPTIONS.OFF_TRACK',
};

const projectStatusIconMap: Record<ProjectStatus, string> = {
  [PROJECT_STATUS.NEW]: newStatus,
  [PROJECT_STATUS.IN_PLANNING]: inPlanning,
  [PROJECT_STATUS.IN_PROGRESS]: inProgress,
  [PROJECT_STATUS.COMPLETED]: completed,
  [PROJECT_STATUS.ON_HOLD]: onHold,
  [PROJECT_STATUS.CLOSED]: closed,
};

export const projectStatusLabelMap: Record<ProjectStatus, string> = {
  [PROJECT_STATUS.NEW]: 'PROJECT_DETAIL.STATUS_OPTIONS.NEW',
  [PROJECT_STATUS.IN_PLANNING]: 'PROJECT_DETAIL.STATUS_OPTIONS.IN_PLANNING',
  [PROJECT_STATUS.IN_PROGRESS]: 'PROJECT_DETAIL.STATUS_OPTIONS.IN_PROGRESS',
  [PROJECT_STATUS.COMPLETED]: 'PROJECT_DETAIL.STATUS_OPTIONS.COMPLETED',
  [PROJECT_STATUS.ON_HOLD]: 'PROJECT_DETAIL.STATUS_OPTIONS.ON_HOLD',
  [PROJECT_STATUS.CLOSED]: 'PROJECT_DETAIL.STATUS_OPTIONS.CLOSED',
};

const ProjectAttributeIconAndLabel: React.FC<{
  attribute: 'health' | 'status' | 'priority';
  value: string | null;
  className?: string;
}> = ({ attribute, value, className }) => {
  if (!value) {
    return null;
  }

  let icon, label;

  switch (attribute) {
    case 'health':
      icon = projectHealthIconMap[value];
      label = projectHealthLabelMap[value];
      break;
    case 'status':
      icon = projectStatusIconMap[value];
      label = projectStatusLabelMap[value];
      break;
    case 'priority':
      icon = projectPriorityIconMap[value];
      label = projectPriorityLabelMap[value];
      break;
  }

  if (!icon || !label) {
    return null;
  }

  return (
    <div className={tailwindOverride('flex items-center', className)}>
      <Icon
        src={icon}
        className={tailwindOverride('h-5', 'w-5', 'text-tertiary')}
        aria-label={`project-${kebabCase(intl.get(label))}-${attribute}`}
      />
      <Typography variant='caption'>{label && intl.get(label)}</Typography>
    </div>
  );
};

export default ProjectAttributeIconAndLabel;
