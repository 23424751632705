import React from 'react';
import intl from 'react-intl-universal';
import classNames from 'classnames';
import { getStatusColumn } from 'Pages/ProjectsListPage/helpers/tableColumnsValues';
import { Button, Table } from '@getsynapse/design-system';
import { Project } from 'utils/customTypes';
import { PROJECT_STATUS } from 'utils/constants';

const LinkedRequestProjectsTable: React.FC<{
  projectsList: Project[];
  unLinkProject: (projectId: string) => void;
}> = ({ projectsList, unLinkProject }) => {
  type keys = keyof typeof PROJECT_STATUS;
  type ProjectStatus = typeof PROJECT_STATUS[keys];

  return (
    <div
      className={classNames('w-full rounded max-h-58 overflow-y-auto mb-5', {
        'border border-neutral-lighter-two': projectsList.length > 0,
      })}
    >
      <Table
        canSelectRows={false}
        className={classNames('w-full', {
          'border-0': projectsList.length > 0,
        })}
        bodyProps={{
          'data-cy': 'linked-requests-table-body',
        }}
        data={{
          headData: {
            stickyHeader: true,
            headCells: [
              {
                content: intl.get(
                  'REQUEST_PAGE.TOP_BAR.LINK_REQUEST_MODAL.PROJECT_TITLE'
                ),
                className: 'w-7/12 rounded-tl',
              },
              {
                content: intl.get(
                  'REQUEST_PAGE.TOP_BAR.LINK_REQUEST_MODAL.STATUS'
                ),
              },
              {
                className: 'w-24 rounded-tr',
              },
            ],
          },
          rows: projectsList.map((project) => {
            const status = project.status as ProjectStatus;
            return {
              cells: [
                {
                  content: (
                    <div className='w-104 truncate'>{project.title}</div>
                  ),
                  className: 'w-7/12',
                  'data-cy': `project-${project.id}-title`,
                },
                {
                  content: getStatusColumn(status),
                },
                {
                  content: (
                    <Button
                      className='text-sm text-primary'
                      variant='tertiary'
                      onClick={() => unLinkProject(project.id)}
                      data-cy={`unlink-project-${project.id}-from-table`}
                    >
                      {intl.get(
                        'REQUEST_PAGE.TOP_BAR.LINK_REQUEST_MODAL.UNLINK'
                      )}
                    </Button>
                  ),
                  className: 'w-24',
                },
              ],
            };
          }),
        }}
        emptyComponent={
          <div
            className='py-4 text-neutral text-sm flex flex-1 items-center justify-center'
            data-cy='no-linked-projects'
          >
            {intl.get(
              'REQUEST_PAGE.TOP_BAR.LINK_REQUEST_MODAL.NO_LINKED_PROJECTS'
            )}
          </div>
        }
      />
    </div>
  );
};

export default LinkedRequestProjectsTable;
