import React from 'react';
import { useSelector } from 'react-redux';
import { tailwindOverride } from '@getsynapse/design-system';
import { toSegmentString } from 'state/WeeklyTeamCapacity/Weeks/helpers';
import { selectNumberOfWeeks } from 'state/WeeklyTeamCapacity/Weeks/weeksSlice';
import { DateSegment } from 'state/WeeklyTeamCapacity/Weeks/helpers';
import {
  DateRange,
  getWeekRangeForDate,
} from '../../../WeeklyCapacity/helpers';
import { CapacityPerWeek } from 'types/store/weeklyTeamCapacity';

interface WeeksGridProps<T> {
  id: string;
  component: React.ComponentType<T>;
  items: {
    [week: string]: T | undefined;
  };
}

const WeeksGrid = <T extends CapacityPerWeek>({
  id,
  component,
  items,
}: WeeksGridProps<T>) => {
  const numberOfWeeks: number = useSelector(selectNumberOfWeeks);
  const currenDate = new Date();
  const currentWeekDateRange: DateRange = getWeekRangeForDate(currenDate);
  const currenWeekSegment: DateSegment = toSegmentString(currentWeekDateRange);

  return (
    <div
      aria-label={`${id}__weekly-capacity__grid`}
      className='w-full h-full flex'
    >
      <div className='w-6 h-full' />
      <div className='w-full h-full flex'>
        {Object.keys(items).map((week) => {
          const hasCapacity = items[week] !== undefined;
          const isCurrentWeek = week === currenWeekSegment[0];
          return (
            <div
              key={`${id}__weekly-capacity__week-${week}`}
              role='group'
              aria-label={`${id}__weekly-capacity__week-${week}`}
              className={tailwindOverride(
                'flex-1 h-full',
                'flex-shrink-0 flex-grow',
                {
                  'bg-secondary-lightest border-l border-r border-secondary-lighter':
                    isCurrentWeek,
                }
              )}
              style={{ width: `${100 / numberOfWeeks}%` }}
            >
              {hasCapacity &&
                React.createElement(component, { ...items[week]! })}
              {!hasCapacity && (
                <div className='w-full h-14 flex items-center px-2 animate-pulse'>
                  <div className='w-full h-4 rounded-sm bg-primary-lighter' />
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className='w-6 h-full' />
    </div>
  );
};

export default WeeksGrid;
