import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { CENTRALIZED_TASKS_TABLE_TABS } from 'utils/constants/centralizedTasks';
import { TaskListFilters } from 'types/store/tasksList';
import {
  fetchTeamTasks,
  selectFetchTeamTasksStatus,
  selectTeamTasks,
  setFilters,
  selectTeamTasksTableFilters,
  selectTeamTasksSorting,
  setSorting,
  selectCanFetchMoreTeamTasks,
  selectTeamTasksTotalCount,
} from 'state/TasksList/teamTasks/teamTasksSlice';
import {
  fetchFilterSettingByType,
  selectFiltersSliceStatus,
} from 'state/Settings/Filters/FiltersSlice';
import TasksListTable from 'Pages/TasksListPage/components/v2/TasksListTable/TasksListTable';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';

const TeamTasks = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const fetchTeamTasksStatus = useSelector(selectFetchTeamTasksStatus);
  const fetchFilterSettingByTypeStatus = useSelector(selectFiltersSliceStatus);
  const teamTasks = useSelector(selectTeamTasks);
  const teamTasksSorting = useSelector(selectTeamTasksSorting);
  const teamTasksTableFilters = useSelector(selectTeamTasksTableFilters);
  const canFetchMoreTasks = useSelector(selectCanFetchMoreTeamTasks);
  const totalCount = useSelector(selectTeamTasksTotalCount);
  const prevFetchTeamTasksStatus = useRef<Status>(fetchTeamTasksStatus);
  const prevFetchFilterSettingByTypeStatus = useRef<Status>(
    fetchFilterSettingByTypeStatus
  );

  useEffect(() => {
    dispatch(fetchFilterSettingByType(CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS));
  }, [dispatch]);

  useEffect(() => {
    if (
      fetchFilterSettingByTypeStatus !==
      prevFetchFilterSettingByTypeStatus.current
    ) {
      prevFetchFilterSettingByTypeStatus.current =
        fetchFilterSettingByTypeStatus;
      if (fetchFilterSettingByTypeStatus === SLICE_STATUS.IDLE) {
        dispatch(
          fetchTeamTasks({
            fetchNext: false,
            includeTotalCount: true,
          })
        );
      }
    }
  }, [fetchFilterSettingByTypeStatus, dispatch]);

  useEffect(() => {
    if (fetchTeamTasksStatus !== prevFetchTeamTasksStatus.current) {
      prevFetchTeamTasksStatus.current = fetchTeamTasksStatus;
      if (isLoading && fetchTeamTasksStatus === SLICE_STATUS.IDLE) {
        setIsLoading(false);
      }
    }
  }, [fetchTeamTasksStatus, isLoading]);

  const handleUpdateFilters = useCallback(
    (updatedFilters: TaskListFilters) => {
      dispatch(setFilters(updatedFilters));
      dispatch(
        fetchTeamTasks({
          fetchNext: false,
          includeTotalCount: true,
        })
      );
    },
    [dispatch]
  );

  const handleTableSort = useCallback(
    (sortBy: string, order: string) => {
      dispatch(setSorting({ sortBy, order }));
      dispatch(
        fetchTeamTasks({
          fetchNext: false,
          includeTotalCount: false,
        })
      );
    },
    [dispatch]
  );

  const handleFetchMoreTasks = useCallback(() => {
    dispatch(
      fetchTeamTasks({
        fetchNext: true,
        includeTotalCount: false,
      })
    );
  }, [dispatch]);

  if (isLoading) {
    return (
      <SkeletonLoader
        data-testid={`${CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS}__skeleton-loader`}
      />
    );
  }

  return (
    <TasksListTable
      tableName={CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS}
      tasks={teamTasks}
      filters={teamTasksTableFilters}
      totalCount={totalCount}
      onUpdateFilters={handleUpdateFilters}
      sortBy={teamTasksSorting.sortBy}
      order={teamTasksSorting.order}
      handleSort={handleTableSort}
      canFetchMoreTasks={canFetchMoreTasks}
      fetchMoreTasks={handleFetchMoreTasks}
    />
  );
};

export default TeamTasks;
