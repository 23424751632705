import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Request, TableExportOptions } from 'utils/customTypes';
import { TABLE_EXPORT_OPTIONS, REQUESTS_TABLE } from 'utils/constants';
import { RequestFilters } from 'utils/types/filters';
import {
  selectTableSearchParam,
  setFilters,
  updateMyRequestPagination,
  getUserRequestsFetchStatus,
} from 'state/Requests/requestSlice';
import {
  createFilterSetting,
  selectFiltersSettingsByType,
  updateFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import Pagination from 'Organisms/Pagination/ClientPagination';
import RequestsTable from './RequestsTable';
import RequestsTableHeader from './RequestsTableHeader';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';

const MyRequestsTable = ({
  handleSort,
  setModalOpen,
  setModalProps,
  userRequests,
  onExportRequests,
  onExportRequestsByForm,
}: {
  handleSort: (orderByParam: string, order: 'desc' | 'asc') => void;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setModalProps: React.Dispatch<React.SetStateAction<{}>>;
  userRequests: {
    data: Request[];
    total: number;
    all: Request[];
  };
  onExportRequests: (requestIds: string[], callback: () => void) => void;
  onExportRequestsByForm: (requestIds: string[], callback: () => void) => void;
}) => {
  const dispatch = useDispatch();
  const [selectedRequests, setSelectedRequests] = useState<string[]>([]);
  const fetchUserRequestsStatus = useSelector(getUserRequestsFetchStatus);

  const tableSearchParam = useSelector(
    selectTableSearchParam(REQUESTS_TABLE.MY_REQUESTS)
  );
  const filtersSettings = useSelector(
    selectFiltersSettingsByType(REQUESTS_TABLE.MY_REQUESTS)
  );
  const appliedFilters = useMemo(
    () =>
      filtersSettings
        ? (filtersSettings.settings as RequestFilters)
        : ({} as RequestFilters),
    [filtersSettings]
  );

  useEffect(() => {
    if (appliedFilters) {
      dispatch(setFilters(appliedFilters, REQUESTS_TABLE.MY_REQUESTS));
    }
  }, [appliedFilters, dispatch]);

  const allUserRequestsIds = useMemo(
    () => userRequests.all.map((request: Request) => request.id!),
    [userRequests.all]
  );

  const currentPageUserRequestsIds = useMemo(
    () => userRequests.data.map((request: Request) => request.id!),
    [userRequests.data]
  );

  const handleExportRequests = (callback: () => void) => {
    onExportRequests(selectedRequests, callback);
  };

  const handleExportRequestsByForm = (callback: () => void) => {
    onExportRequestsByForm(selectedRequests, callback);
  };

  const handleSelectExportOption = (
    exportOption: TableExportOptions | null
  ) => {
    if (exportOption === TABLE_EXPORT_OPTIONS.ALL) {
      setSelectedRequests(allUserRequestsIds);
      return;
    }
    if (exportOption === TABLE_EXPORT_OPTIONS.CURRENT_PAGE) {
      setSelectedRequests(currentPageUserRequestsIds);
      return;
    }
    setSelectedRequests([]);
  };

  const fetchUserRequestWithPagination = useCallback(
    (params) => {
      dispatch(updateMyRequestPagination(params));
    },
    [dispatch]
  );

  const handleSaveFiltersSettings = (filters: RequestFilters) => {
    if (filtersSettings && filtersSettings.id) {
      dispatch(
        updateFilterSetting({
          id: filtersSettings.id,
          updateFields: { filter_settings: filters },
        })
      );
    } else {
      dispatch(
        createFilterSetting({
          filter_type: REQUESTS_TABLE.MY_REQUESTS,
          filter_settings: filters,
        })
      );
    }
  };

  if (fetchUserRequestsStatus === 'loading') {
    return <SkeletonLoader data-cy='my-requests-table-skeleton-loader' />;
  }

  return (
    <Fragment>
      <RequestsTableHeader
        requestsTable={REQUESTS_TABLE.MY_REQUESTS}
        exportEnabled={!isEmpty(selectedRequests)}
        onExportHandler={handleExportRequests}
        onExportByFormHandler={handleExportRequestsByForm}
        appliedFilters={appliedFilters}
        tableSearchParam={tableSearchParam}
        saveFiltersSettings={handleSaveFiltersSettings}
      />
      <RequestsTable
        requestsTable={REQUESTS_TABLE.MY_REQUESTS}
        data={userRequests.data}
        totalRequests={userRequests.total}
        setModalOpen={setModalOpen}
        setModalProps={setModalProps}
        setSelectedRequests={setSelectedRequests}
        selectedRequests={selectedRequests}
        handleSort={handleSort}
        isSearch={false}
        areFiltersApplied={false}
        onSelectExportOption={handleSelectExportOption}
        showFilter={false}
      />
      <Pagination
        total={userRequests.total}
        onChange={fetchUserRequestWithPagination}
      />
    </Fragment>
  );
};

export default MyRequestsTable;
