import { tailwindOverride } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { TaskBundleOption } from './types';

interface TaskBundlePickerOptionProps {
  option: TaskBundleOption;
  selected: boolean;
  selectOption: () => void;
  className?: string;
}

const TaskBundlePickerOption: React.FC<TaskBundlePickerOptionProps> = ({
  option,
  selected,
  selectOption,
  className,
  ...otherProps
}) => {
  return (
    <li
      {...otherProps}
      className={tailwindOverride(
        className,
        {
          'text-neutral-white bg-primary hover:text-neutral-white hover:bg-primary focus-visible:bg-primary focus-visible:text-neutral-white':
            selected,
        },
        'flex justify-between max-w-full'
      )}
      onClick={selectOption}
    >
      <span className='max-w-3/4 truncate'>{option.label}</span>

      {isNaN(option.totalEstimatedHours) ? (
        <span
          className={classnames('text-3.5', {
            'text-neutral': !selected,
          })}
        >
          {intl.get('NEW_PROJECT_PAGE.TASK_BUNDLE.ESTIMATED_HOURS_PLACEHOLDER')}
        </span>
      ) : (
        <span>
          {intl.get('HOURS_LABEL', {
            hours: option.totalEstimatedHours,
          })}
        </span>
      )}
    </li>
  );
};

export default TaskBundlePickerOption;
