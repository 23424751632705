import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  fetchAllocationSummary,
  getResourceSummary,
  fetchTasksSummary,
  getTasksSummary,
} from 'state/Project/projectSlice';
import ResourceSummaryTable from './components/ResourceSummaryTable';
import TasksSummaryTable from './components/TasksSummaryTable';

const ResourceSummary: React.FC<{ projectId: string }> = ({ projectId }) => {
  const dispatch = useDispatch();
  const { tasksAssignmentsBasedResourcesCapacity = false } = useFlags();
  const summaryData = useSelector(getResourceSummary);
  const tasksData = useSelector(getTasksSummary);

  useEffect(() => {
    dispatch(fetchAllocationSummary(projectId));
    dispatch(fetchTasksSummary(projectId));
  }, [projectId, dispatch]);

  return (
    <div className='w-full h-projectTabContent'>
      {!tasksAssignmentsBasedResourcesCapacity && (
        <ResourceSummaryTable data={summaryData} />
      )}
      <TasksSummaryTable data={tasksData} />
    </div>
  );
};

export default ResourceSummary;
