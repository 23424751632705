import React from 'react';
import intl from 'react-intl-universal';
import { Tooltip, Button, tailwindOverride } from '@getsynapse/design-system';
import ExportIcon from 'assets/icons/export.svg';

type ExportButtonProps = {
  onExport: () => void;
  exportTooltipText: string;
} & React.ComponentProps<typeof Button>;

const ExportButton: React.FC<ExportButtonProps> = ({
  onExport,
  exportTooltipText,
  className,
  ...buttonProps
}) => {
  return exportTooltipText ? (
    <Tooltip
      position='bottomLeft'
      ariaId='export-tooltip'
      openMode='hover1'
      timeout={0}
      contentProps={{ className: 'z-200000' }}
      trigger={
        <div tabIndex={0} className='focus:outline-none'>
          <Button
            data-testid='table-header__export-button'
            color='positive'
            iconPosition='left'
            size='small'
            variant='tertiary'
            iconSrc={ExportIcon}
            onClick={onExport}
            className={tailwindOverride(className, {
              'cursor-auto': buttonProps.disabled,
            })}
            {...buttonProps}
          >
            {intl.get('TABLE_HEADER_ACTIONS.EXPORT')}
          </Button>
        </div>
      }
    >
      {exportTooltipText}
    </Tooltip>
  ) : (
    <Button
      data-testid='table-header__export-button'
      color='positive'
      iconPosition='left'
      size='small'
      variant='tertiary'
      iconSrc={ExportIcon}
      onClick={onExport}
      className={tailwindOverride(className, {
        'cursor-not-allowed': buttonProps.disabled,
      })}
      {...buttonProps}
    >
      {intl.get('TABLE_HEADER_ACTIONS.EXPORT')}
    </Button>
  );
};

export default ExportButton;
