import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { SLICE_STATUS } from 'utils/constants';
import { Status } from 'utils/customTypes';
import { MonetaryBenefitsType } from 'utils/types/program';
import { RootState } from 'state/store';
import monetaryBenefitsAPI from './monetaryBenefitsAPI';

interface MonetaryBenefitsState {
  status: Status;
  value: MonetaryBenefitsType;
}

/* ============================= INITIAL STATE ============================== */
const initialState: MonetaryBenefitsState = {
  status: SLICE_STATUS.IDLE,
  value: {},
};

/* ============================== REDUX THUNK =============================== */
export const fetchMonetaryBenefits = createAsyncThunk(
  'monetaryBenefits/FETCH_MONETARY_BENEFIT',
  async ({
    programId,
    categoryId,
    objectiveId,
  }: {
    programId: string;
    categoryId: string;
    objectiveId: string;
  }) => {
    const response = await monetaryBenefitsAPI.getMonetaryBenefits(
      programId,
      categoryId,
      objectiveId
    );
    return response;
  }
);

interface CreateMonetaryBenefitArgs {
  programId: string;
  categoryId: string;
  objectiveId: string;
  monetaryBenefits: MonetaryBenefitsType;
}
export const createMonetaryBenefits = createAsyncThunk(
  'monetaryBenefits/CREATE_MONETARY_BENEFITS',
  async (payload: CreateMonetaryBenefitArgs) => {
    const response = await monetaryBenefitsAPI.createMonetaryBenefits(
      payload.programId,
      payload.categoryId,
      payload.objectiveId,
      payload.monetaryBenefits
    );

    return response;
  }
);

interface UpdateMonetaryBenefitsArgs {
  programId: string;
  categoryId: string;
  objectiveId: string;
  monetaryBenefitsId: string;
  monetaryBenefits: MonetaryBenefitsType;
}
export const updateMonetaryBenefits = createAsyncThunk(
  'monetaryBenefits/UPDATE_MONETARY_BENEFITS',
  async (payload: UpdateMonetaryBenefitsArgs) => {
    const response = await monetaryBenefitsAPI.updateMonetaryBenefits(
      payload.programId,
      payload.categoryId,
      payload.objectiveId,
      payload.monetaryBenefitsId,
      payload.monetaryBenefits
    );

    return response;
  }
);

/* ================================ ACTIONS ================================= */
export const resetMonetaryBenefits = createAction(
  'monetaryBenefits/RESET_MONETARY_BENEFITS'
);

/* ================================= REDUCER ================================ */
const monetaryBenefitsSlice = createSlice({
  name: 'monetaryBenefits',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMonetaryBenefits.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchMonetaryBenefits.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        state.value = action.payload;
      })
      .addCase(fetchMonetaryBenefits.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(createMonetaryBenefits.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(createMonetaryBenefits.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        state.value = action.payload;
      })
      .addCase(createMonetaryBenefits.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
        throw new Error();
      })
      .addCase(updateMonetaryBenefits.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(updateMonetaryBenefits.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        state.value = action.payload;
      })
      .addCase(updateMonetaryBenefits.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
        throw new Error();
      })
      .addCase(resetMonetaryBenefits, (state) => {
        state.status = SLICE_STATUS.IDLE;
        state.value = {};
      });
  },
});

/* =============================== SELECTORS ================================ */
export const selectMonetaryBenefits = (state: RootState) =>
  state.monetaryBenefits.value;

export const selectMonetaryBenefitsStatus = (state: RootState) =>
  state.monetaryBenefits.status;

export default monetaryBenefitsSlice.reducer;
