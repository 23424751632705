import React, { useEffect, useState } from 'react';
import { SidePanelFilter } from '@getsynapse/design-system';
import { RequestFilters } from 'utils/types/filters';
import FiltersForm from './FiltersForm';

const RequestsSidePanelFilters: React.FC<{
  open: boolean;
  onUpdateFilters: (filters: RequestFilters) => void;
  onClose: () => void;
  filters: RequestFilters;
}> = ({ onClose, open, filters, onUpdateFilters }) => {
  const [appliedFilters, setAppliedFilters] = useState<RequestFilters>({});
  const [canApplyFilters, setCanApplyFilters] = useState<boolean>(false);

  useEffect(() => {
    if (filters) {
      setAppliedFilters(filters);
    }
  }, [filters]);

  const handleUpdateFilters = (updatedFilters: RequestFilters) => {
    setAppliedFilters(updatedFilters);
    setCanApplyFilters(true);
  };

  const handleApplyFilters = () => {
    onUpdateFilters(appliedFilters);
    setCanApplyFilters(false);
  };

  const handleClearAllFilters = () => {
    onUpdateFilters({});
    setAppliedFilters({});
    setCanApplyFilters(false);
  };

  return (
    <SidePanelFilter
      open={open}
      onClose={onClose}
      appliedFiltersCount={Object.keys(appliedFilters).length}
      canApplyFilters={canApplyFilters}
      onClearAllFilters={handleClearAllFilters}
      onApplyFilters={handleApplyFilters}
      data-testid='requests-filters-side-panel'
    >
      <FiltersForm
        filters={appliedFilters}
        onUpdateFilters={handleUpdateFilters}
      />
    </SidePanelFilter>
  );
};

export default RequestsSidePanelFilters;
