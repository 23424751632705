import { api } from './api';
import axios from 'axios';
import { get } from 'lodash';

interface CapacityPerWeekEntry {
  startDate: string; // Date in str form
  endDate: string; // Date in str form
  assigned: string;
  timeOff: string;
  capacity: string;
}

interface CapacityPerWeek {
  [week: string]: CapacityPerWeekEntry | undefined;
}

export interface WeeklyTeamCapacityApiResponse {
  id: string;
  weeklyCapacities: CapacityPerWeek;
  links: {
    next: string;
    prev: string;
  };
}

export interface FetchWeeklyTeamParams {
  teamId: string;
  startDate: string;
  endDate?: string;
}

export const fetchWeeklyCapacityForTeam = async ({
  teamId,
  startDate,
  endDate,
}: FetchWeeklyTeamParams): Promise<WeeklyTeamCapacityApiResponse> => {
  const url = `/v2/teams/${teamId}/capacities/weekly`;

  const response = await api.get<WeeklyTeamCapacityApiResponse>(url, {
    params: {
      startDate,
      endDate,
    },
  });
  if (axios.isAxiosError(response)) {
    const errorMessage = get(response, 'response.data.message', '');
    throw errorMessage;
  }
  return response.data;
};

export interface FetchWeeklyTeamMemberParams {
  teamId: string;
  teamMemberId: string;
  startDate: string;
  endDate?: string;
}

export const fetchWeeklyCapacityForTeamMember = async ({
  teamId,
  teamMemberId,
  startDate,
  endDate,
}: FetchWeeklyTeamMemberParams): Promise<WeeklyTeamCapacityApiResponse> => {
  const url = `/v2/teams/${teamId}/members/${teamMemberId}/capacities/weekly`;

  const response = await api.get<WeeklyTeamCapacityApiResponse>(url, {
    params: {
      startDate,
      endDate,
    },
  });
  if (axios.isAxiosError(response)) {
    const errorMessage = get(response, 'response.data.message', '');
    throw errorMessage;
  }
  return response.data;
};
