import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'state/store';
import { NewTask } from 'types/store/newTask';
import { createNewTask } from 'api/newTask';
import { fetchTeamTasks } from '../teamTasks/teamTasksSlice';
import { fetchUserTasks } from '../userTasks/userTasksSlice';

interface ProjectTasksListActionsState {
  taskIdToEdit?: string;
  taskIdToDuplicate?: string;
}

interface CreateNewTaskParams {
  newTask: NewTask;
  userId: string;
}

const initialState: ProjectTasksListActionsState = {};

export const createProjectTask = createAsyncThunk(
  'projectTasksList/createProjectTask',
  async (
    { newTask, userId }: CreateNewTaskParams,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await createNewTask(newTask);
      dispatch(fetchTeamTasks({ projectId: newTask.projectId }));
      dispatch(fetchUserTasks({ userId, projectId: newTask.projectId }));
      return response.successes[0];
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue(error);
    }
  }
);

const actionsSlice = createSlice({
  name: 'projectTasksList/actions',
  initialState,
  reducers: {
    setTaskIdToEdit(state, action) {
      state.taskIdToEdit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createProjectTask.rejected, (state, action) => {
      throw new Error(action.payload as string);
    });
  },
});

export const selectTaskIdToEdit = (state: RootState) =>
  state.projectTasksList.actions.taskIdToEdit;

export const { setTaskIdToEdit } = actionsSlice.actions;

export default actionsSlice.reducer;
