import { GlobalBanner, Button, Typography } from '@getsynapse/design-system';
import { useState } from 'react';
import intl from 'react-intl-universal';
import CancelledRequestInfoModal from './CancelledRequestInfoModal';
import { Request } from 'utils/customTypes';
import moment from 'moment';
import { DATE } from 'utils/constants';
import classNames from 'classnames';

type Props = {
  requestData: Request;
  sidePanel?: boolean;
  dataTestId?: string;
};

const CancelledRequestBanner = ({
  requestData,
  sidePanel,
  dataTestId,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const cancellationDate = moment(requestData.updatedAt).format(
    DATE.SHORT_FORMAT
  );
  const reason = intl.get(
    `REQUESTS_LIST_PAGE.MODAL.CANCEL_REQUEST.REASONS.${requestData.data?.cancellation?.reason}`
  );

  return (
    <>
      <CancelledRequestInfoModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        reason={reason}
        details={requestData.data?.cancellation?.details}
        ldComments={requestData.data?.cancellation?.ldRemark}
        cancellationDate={cancellationDate}
      />
      <GlobalBanner
        variant='warning'
        hasCloseIcon
        className={classNames(`${sidePanel ? 'mb-4' : 'mt-2'}`)}
        iconProps={{
          'data-testid': dataTestId,
        }}
      >
        {() => {
          return (
            <div className='flex justify-between items-start'>
              <div>
                <Typography
                  variant='body'
                  className='text-warning-darker leading-6'
                  data-testid='cancelled-request-banner-text-one'
                >
                  {intl.getHTML(
                    'REQUEST_PAGE.CANCELLED_REQUEST_BANNER.LINE_ONE',
                    { cancellationDate: cancellationDate }
                  )}
                </Typography>
                <Typography
                  variant='body'
                  className='text-warning-darker leading-6'
                  data-testid='cancelled-request-banner-text-two'
                >
                  {intl.get('REQUEST_PAGE.CANCELLED_REQUEST_BANNER.LINE_TWO', {
                    reason: reason,
                  })}
                </Typography>
              </div>
              {!sidePanel && (
                <Button
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                  color='warning'
                  data-testid='cancelled-request-banner-button'
                >
                  {intl.get('REQUEST_PAGE.CANCELLED_REQUEST_BANNER.BUTTON')}
                </Button>
              )}
            </div>
          );
        }}
      </GlobalBanner>
    </>
  );
};

export default CancelledRequestBanner;
