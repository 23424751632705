import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import type { ApiResponse } from 'utils/types/api';
import type {
  LearningTeam,
  BaseLearningTeam,
  LearningTeamResponse,
  LearningTeamsResponse,
  LearningTeamForm,
  LearningTeamWithMembers,
} from 'utils/types/learningTeam';

class LearningTeamsAPI {
  instance: AxiosInstance;

  constructor() {
    this.instance = initAxios(`${config.get('backendURL')}learningTeam`);
  }

  fetchLearningTeams = async () => {
    const { data } = await this.instance.get<
      ApiResponse<LearningTeamsResponse>
    >('/organization/all');

    return data;
  };

  createLearningTeam = async (team: LearningTeamForm) => {
    const {
      data: { data: postResponse },
    } = await this.instance.post<
      ApiResponse<{
        learningTeam: BaseLearningTeam;
      }>
    >('/', team);

    const id = postResponse.learningTeam.id;
    const {
      data: { learningTeam },
    } = await this.getLearningTeam(id);

    return learningTeam;
  };

  getLearningTeam = async (id: string) => {
    const { data } = await this.instance.get<
      ApiResponse<LearningTeamResponse<LearningTeamWithMembers>>
    >(`/${id}`);

    return data;
  };

  updateTeam = async (teamId: string, updateFields: Partial<LearningTeam>) => {
    const { data } = await this.instance.put<
      ApiResponse<LearningTeamResponse<LearningTeamWithMembers>>
    >(`/${teamId}`, {
      updateFields,
    });

    return data;
  };

  deleteTeam = async (teamId: string) => {
    const { data } = await this.instance.delete<
      ApiResponse<{ learningTeam: number }>
    >(`/${teamId}`);

    return data;
  };
}

const learningTeamsAPI = new LearningTeamsAPI();

export default learningTeamsAPI;
