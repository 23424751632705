import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import ByPriorityPieChart from 'Pages/InsightsPage/Projects/ByPriority/PieChart';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  fetchProjectsByPriority,
  getprojectsByPriority,
  getActiveFiltersQueryParams,
} from 'state/Insights/ProjectsSlice';
import ByPrioritySkeletonLoader from './ByPrioritySkeletonLoader';
import { SLICE_STATUS } from 'utils/constants';
import ErrorScreen from '../../Components/ErrorScreen';

const ByPriority = () => {
  const dispatch = useDispatch();
  const activeFilters = useSelector(getActiveFiltersQueryParams);

  useEffect(() => {
    dispatch(fetchProjectsByPriority(activeFilters));
  }, [dispatch, activeFilters]);

  const { values, status } = useSelector(getprojectsByPriority);
  let highCount = 0;
  let mediumCount = 0;
  let lowCount = 0;

  values &&
    values.forEach((priority) => {
      if (priority.projectPriority === 'high') {
        highCount += priority.projectCount;
      } else if (priority.projectPriority === 'medium') {
        mediumCount += priority.projectCount;
      } else if (priority.projectPriority === 'low') {
        lowCount += priority.projectCount;
      }
    });

  const totalProjects = highCount + mediumCount + lowCount;
  return status === SLICE_STATUS.LOADING ? (
    <ByPrioritySkeletonLoader />
  ) : status === SLICE_STATUS.FAILED ? (
    <ErrorScreen />
  ) : (
    <>
      <Typography
        variant='label'
        className='text-primary font-semibold leading-1 absolute max-w-47px'
      >
        {intl.get('PROJECTS_INSIGHTS_TABS.BY_PRIORITY.TITLE')}
      </Typography>

      {totalProjects === 0 ? (
        <div className='flex h-full items-center justify-center'>
          <Typography variant='caption' className='text-neutral-lighter'>
            {intl.get('PROJECTS_INSIGHTS_TABS.NO_PROJECTS')}
          </Typography>
        </div>
      ) : (
        <ByPriorityPieChart
          high={highCount}
          medium={mediumCount}
          low={lowCount}
        />
      )}
    </>
  );
};

export default ByPriority;
