import React, { SetStateAction, Dispatch, useState, useEffect } from 'react';
import { ProjectContent, ProjectWeblink } from 'utils/customTypes';
import { TextField } from '@getsynapse/design-system';
import { PROJECT_CONTENT_TYPE } from 'utils/constants';
import intl from 'react-intl-universal';

const AddWeblinkSection = ({
  setContent,
}: {
  setContent: Dispatch<SetStateAction<ProjectContent[]>>;
}) => {
  const [weblinkName, setWeblinkName] = useState('');
  const [weblink, setWeblink] = useState('');

  useEffect(() => {
    if (weblink && weblinkName) {
      const formattedWeblink = {
        data: {
          display: weblinkName,
          url: weblink,
        } as ProjectWeblink,
        content_type: PROJECT_CONTENT_TYPE.WEB_LINK,
      };
      setContent([formattedWeblink]);
    }
  }, [weblinkName, weblink, setContent]);

  return (
    <div className='flex space-x-4 mt-8'>
      <TextField
        label={intl.get('PROJECT_DETAIL.FILES_TAB.TEXT_TO_DISPLAY')}
        labelProps={{
          required: true,
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setWeblinkName(e.target.value)
        }
        value={weblinkName}
        placeholder={intl.get('PROJECT_DETAIL.FILES_TAB.WEBLINK_PLACEHOLDER')}
        className='w-full'
        data-cy='weblink-name'
      />
      <TextField
        label={intl.get('PROJECT_DETAIL.FILES_TAB.WEBLINK')}
        labelProps={{
          required: true,
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setWeblink(e.target.value)
        }
        value={weblink}
        placeholder={intl.get(
          'PROJECT_DETAIL.FILES_TAB.WEBLINK_URL_PLACEHOLDER'
        )}
        className='w-full'
        data-cy='weblink-value'
      />
    </div>
  );
};

export default AddWeblinkSection;
