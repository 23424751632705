import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { tailwindOverride, Button } from '@getsynapse/design-system';
import {
  selectTeamsCapacity,
  fetchTeamsCapacityStatus,
  fetchMoreTeams,
  selectTeamsPagination,
  setUpdatedProject,
} from 'state/TeamsCapacity/teamsCapacitySlice';
import { setProjectId } from 'state/Project/projectSlice';
import { TeamsCapacity, TeamsListPagination } from 'utils/types/teamsCapacity';
import { RootState } from 'state/store';
import { SLICE_STATUS } from 'utils/constants';
import ProjectSidePanel from 'Organisms/ProjectSidePanel/ProjectSidePanel';
import TableHeader from './components/TableHeader';
import TeamRow from '../TeamRow/TeamRow';
import SkeletonLoader from './components/SkeletonLoader';

const CapacityTable = () => {
  const dispatch = useDispatch();
  const [showLoder, setShowLoader] = useState<boolean>(true);
  const teamsCapacity = useSelector<RootState, TeamsCapacity>(
    selectTeamsCapacity
  );
  const teamsPagination = useSelector<RootState, TeamsListPagination>(
    selectTeamsPagination
  );
  const status = useSelector(fetchTeamsCapacityStatus);
  const displayFetchMoreTeamsButton =
    teamsPagination.limit < teamsPagination.total;

  const handleFetchMoreTeams = async () => {
    setShowLoader(true);
    await dispatch(fetchMoreTeams());
  };

  const handleSaveProject = async () => {
    await dispatch(setUpdatedProject());
  };

  const onCloseProjectPanel = async () => {
    await dispatch(setProjectId(''));
  };

  useEffect(() => {
    let isMounted = true;
    setTimeout(() => {
      if (status === SLICE_STATUS.IDLE && isMounted) {
        setShowLoader(false);
      }
    }, 1000);
    return () => {
      isMounted = false;
    };
  }, [status]);

  return showLoder ? (
    <SkeletonLoader />
  ) : (
    <div className={tailwindOverride('w-full h-full')}>
      <ProjectSidePanel
        onClosePanel={onCloseProjectPanel}
        onSave={handleSaveProject}
      />
      <div
        className={tailwindOverride(
          'w-full max-h-teams-capacity-table',
          'rounded border border-primary-lighter-two',
          'relative overflow-y-auto',
          'layout-cell layout-scrollbar'
        )}
      >
        <table
          className='w-full h-full table-fixed'
          cellPadding={0}
          cellSpacing={0}
          data-name='teams-capacity-table'
          data-cy='teams-capacity-table'
        >
          <TableHeader numberOfTeams={Object.keys(teamsCapacity).length} />
          <tbody className='h-full'>
            {Object.entries(teamsCapacity).map(
              ([id, teamCapacity], index: number) => {
                const isLastTeam =
                  index === Object.keys(teamsCapacity).length - 1;
                return (
                  <TeamRow
                    key={id}
                    teamCapacity={teamCapacity}
                    isLastTeam={isLastTeam}
                  />
                );
              }
            )}
          </tbody>
        </table>
      </div>
      {displayFetchMoreTeamsButton && (
        <Button
          variant='tertiary'
          size='small'
          className={tailwindOverride('mt-4')}
          disabled={status === SLICE_STATUS.LOADING}
          onClick={handleFetchMoreTeams}
          data-cy='fetch-more-teams-button'
        >
          {intl.get('TEAMS_CAPACITY.FETCH_MORE_TEAMS')}
        </Button>
      )}
    </div>
  );
};

export default CapacityTable;
