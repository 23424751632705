import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'state/store';
import {
  TaskListElement,
  TaskListFilters,
  TasksListTableSorting,
} from 'types/store/tasksList';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { CENTRALIZED_TASKS_TABLE_TABS } from 'utils/constants/centralizedTasks';
import { fetchFilterSettingByType } from 'state/Settings/Filters/FiltersSlice';
import { FetchTasksListApiParams, fetchTasksList } from 'api/tasksList';
import {
  getAfterQueryParamFromNextLink,
  normalizeFiltersSettingsVersions,
} from '../helpers';
import {
  setTasksAssignees,
  setTasksProjects,
  selectAssigneesListByIds,
  selectTaskProjectById,
} from '../allTasks/allTasksSlice';

interface MyTasksSliceState {
  tasks: TaskListElement[];
  status: Status;
  filters: TaskListFilters;
  sorting: TasksListTableSorting;
  links: {
    next: string | null;
  };
  totalCount?: number;
}

const initialState: MyTasksSliceState = {
  tasks: [],
  status: SLICE_STATUS.IDLE,
  filters: {},
  sorting: {
    sortBy: 'name',
    order: 'desc',
  },
  links: {
    next: null,
  },
};

interface FetchMyTasksParams {
  userId: string;
  fetchNext?: boolean;
  includeTotalCount?: boolean;
}

export const fetchMyTasks = createAsyncThunk(
  'tasksList/teamTasks/fetchMyTasks',
  async (
    {
      userId,
      fetchNext = false,
      includeTotalCount = false,
    }: FetchMyTasksParams,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      let queryParams: FetchTasksListApiParams = {};
      queryParams['user'] = [userId];
      const state = getState() as RootState;
      const filters = state.tasksList.myTasks.filters;
      if (filters) {
        queryParams = { ...queryParams, ...filters };
      }
      const sorting = state.tasksList.myTasks.sorting;
      if (sorting.sortBy && sorting.order) {
        queryParams['sortBy'] = sorting.sortBy;
        queryParams['order'] = sorting.order;
      }
      if (fetchNext) {
        const next = state.tasksList.myTasks.links.next;
        queryParams['after'] = getAfterQueryParamFromNextLink(next);
      }
      queryParams['includeTotalCount'] = includeTotalCount;
      const response = await fetchTasksList(queryParams);
      dispatch(setTasksAssignees(response.assignees));
      dispatch(setTasksProjects(response.projects));
      return {
        tasks: response.tasks,
        links: response.links,
        totalCount: response.totalCount,
        fetchNext,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Failed to fetch user tasks');
    }
  }
);

const myTasksSlice = createSlice({
  name: 'tasksList/myTasks',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyTasks.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchMyTasks.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        if (action.payload) {
          const fetchNext = action.payload.fetchNext;
          let updatedTasks = [];
          if (fetchNext) {
            updatedTasks = state.tasks.concat(action.payload.tasks);
          } else {
            updatedTasks = action.payload.tasks;
          }
          state.tasks = updatedTasks;
          state.links = action.payload.links;
          if (action.payload.totalCount !== undefined) {
            state.totalCount = action.payload.totalCount;
          }
        }
      })
      .addCase(fetchMyTasks.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchFilterSettingByType.fulfilled, (state, action) => {
        if (
          action.payload &&
          action.payload.filter_type === CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS
        ) {
          state.filters = normalizeFiltersSettingsVersions(
            action.payload.filter_settings
          );
        }
      });
  },
});

const selectTasks = (state: RootState) => state.tasksList.myTasks.tasks;
export const selectFetchMyTasksStatus = (state: RootState) =>
  state.tasksList.myTasks.status;
export const selectMyTasks = createSelector(
  [(state: RootState) => state, selectTasks],
  (state: RootState, tasks: MyTasksSliceState['tasks']) => {
    return tasks.map((task) => {
      return {
        ...task,
        assignees: selectAssigneesListByIds(state, task.assignees),
        project: selectTaskProjectById(state, task.projectId),
      };
    });
  }
);
export const selectMyTasksTableSorting = (state: RootState) =>
  state.tasksList.myTasks.sorting;

export const selectMyTasksFilters = (state: RootState) =>
  state.tasksList.myTasks.filters;

export const selectCanFetchMoreMyTasks = (state: RootState) =>
  !!state.tasksList.myTasks.links.next;

export const selectMyTasksTotalCount = (state: RootState) =>
  state.tasksList.myTasks.totalCount;

export const { setFilters, setSorting } = myTasksSlice.actions;

export default myTasksSlice.reducer;
