import { useCallback, useEffect, useState } from 'react';
import { Location } from 'history';
import { Prompt, useHistory } from 'react-router-dom';
import UnsavedChangesModal from 'Organisms/UnsavedChangesModal/UnsavedChangesModal';

const UnsavedChangesPrompt = ({ hasChanges }: { hasChanges: boolean }) => {
  const history = useHistory();
  const [newUrl, setNewUrl] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [canLeave, setCanLeave] = useState<boolean>(false);

  const beforeLeaving = useCallback(
    (location: Location) => {
      if (!canLeave) {
        setIsModalOpen(true);
        setNewUrl(location.pathname.concat(location.search));
      }

      return canLeave;
    },
    [canLeave]
  );

  useEffect(() => {
    if (canLeave) {
      history.push(newUrl);
    }
  }, [canLeave, history, newUrl]);

  return (
    <>
      <Prompt when={hasChanges} message={beforeLeaving} />
      <UnsavedChangesModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onConfirm={() => setCanLeave(true)}
      />
    </>
  );
};

export default UnsavedChangesPrompt;
