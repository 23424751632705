import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { TaskBundle } from 'utils/customTypes';
import { TaskTemplateForCreateOrUpdate } from 'utils/types/taskTemplate';

class ActiveTaskBundleAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchTaskBundle = async (bundleId: string) => {
    const { data } = await this.instance.get(`/taskBundles/${bundleId}`);
    return data;
  };

  updateTaskBundle = async (updatedBundle: TaskBundle) => {
    const { data } = await this.instance.put(
      `/taskBundles/${updatedBundle.id}`,
      {
        ...updatedBundle,
      }
    );
    return data;
  };

  bulkCreateOrUpdateTemplatesForBundle = async (
    templatesToCreateOrUpdate: TaskTemplateForCreateOrUpdate[],
    bundleId: string,
    creatorIsNotified?: boolean
  ) => {
    const { data } = await this.instance.post(
      `/taskTemplates/${bundleId}/bulkCreateOrUpdate`,
      {
        templatesToCreateOrUpdate,
        creatorIsNotified,
      }
    );
    return data;
  };

  fetchTaskTemplatesForBundle = async (bundleId: string) => {
    const { data } = await this.instance.get(`/taskTemplates/${bundleId}/all`);
    return data;
  };

  bulkDeleteTaskTemplatesForBundle = async (
    templatesToDelete: string[],
    bundleId: string
  ) => {
    const { data } = await this.instance.post(
      `/taskTemplates/${bundleId}/bulkDelete`,
      {
        templatesToDelete,
      }
    );
    return data;
  };
}

const activeTaskBundleAPI = new ActiveTaskBundleAPI();

export default activeTaskBundleAPI;
