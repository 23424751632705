import React from 'react';
import { useDispatch } from 'react-redux';
import {
  tailwindOverride,
  Icon,
  Typography,
  useLink,
} from '@getsynapse/design-system';
import { setProjectId } from 'state/Project/projectSlice';
import { PROJECT_HEALTH, PROJECT_STATUS } from 'utils/constants';
import { TeamMemberProject } from 'utils/types/dailyTeamsCapacity';
import ProjectAttributeIconAndLabel from '../../../../../WeeklyCapacity/components/TeamMembers/components/ProjectsList/components/ProjectData/ProjectAttributeIconAndLabel';
import calendar from 'assets/icons/calendar.svg';

const ProjectData: React.FC<{
  customKey: string;
  projectId: string;
  projectData: Omit<TeamMemberProject, 'id'>;
}> = ({ customKey, projectData, projectId }) => {
  const dispatch = useDispatch();
  const linkStyles = useLink();
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  return (
    <div className='w-2/6 h-full flex'>
      <div className='w-10 h-full' />
      <div
        className={tailwindOverride(
          'w-2/3 h-full',
          'py-5 pl-10 pr-2',
          'flex flex-col gap-y-1',
          'overflow-hidden'
        )}
      >
        <div className='w-full truncate'>
          <Typography
            variant='caption'
            weight='medium'
            role='button'
            title={projectData.title}
            className={tailwindOverride(linkStyles)}
            onClick={() => dispatch(setProjectId(projectId))}
          >
            {projectData.title}
          </Typography>
        </div>
        <div
          className={tailwindOverride(
            'w-full gap-x-2 2xl:flex',
            'xl:hidden lg:hidden sm:hidden'
          )}
        >
          <ProjectAttributeIconAndLabel
            attribute='priority'
            value={projectData.priority}
          />
          <ProjectAttributeIconAndLabel
            attribute='health'
            value={projectData.health || PROJECT_HEALTH.ON_TRACK}
          />
          <ProjectAttributeIconAndLabel
            attribute='status'
            value={projectData.status || PROJECT_STATUS.NEW}
          />
        </div>
        <div className='w-full flex gap-x-2'>
          <Icon src={calendar} aria-label='project-date-range' />
          <Typography variant='caption'>
            {`${dateFormatter.format(
              new Date(projectData.startDate)
            )} - ${dateFormatter.format(
              new Date(projectData.targetCompletionDate)
            )}`}
          </Typography>
        </div>
      </div>
      <div
        className={tailwindOverride(
          'w-1/3 h-full',
          'px-2 pt-5 pb-4',
          'flex flex-col gap-y-2'
        )}
      >
        {projectData.participantRoles.slice(0, 3).map((role, index) => (
          <Typography key={`${customKey}__role-${index}`} variant='caption'>
            {role}
          </Typography>
        ))}
        {projectData.participantRoles.length > 3 && (
          <Typography variant='caption'>
            {`+${projectData.participantRoles.length - 3}`}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default ProjectData;
