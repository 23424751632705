import React, { useMemo, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import {
  Radio,
  Typography,
  FormItem,
  Dropdown,
  Tag,
  Icon,
} from '@getsynapse/design-system';
import { ProjectContent } from 'utils/customTypes';
import { MultipleOptionLI } from 'Pages/NewProjectPage/helpers/snippets';
import { useSelector, useDispatch } from 'react-redux';
import { Option } from 'utils/customTypes';
import AddFilesSection from './components/AddFilesSection';
import AddWeblinkSection from './components/AddWeblinkSection';
import AddDesignSection from './components/AddDesignSection';
import { selectTeamTasks } from 'state/ProjectTasks/projectTaskSlice';
import { getAllUsers } from 'state/UsersManagement/usersManagementSlice';
import { getDesigns } from 'state/Designs/designsSlice';
import { PROJECT_CONTENT_MODAL_TAB } from 'utils/constants';
import useModal from 'Hooks/useModal';

const AddContentModal = ({
  isOpen,
  setModalOpen,
  handleUploadOrLinkFiles,
  canLinkTasks = true,
}: {
  isOpen: boolean;
  setModalOpen: any;
  canLinkTasks?: boolean;
  handleUploadOrLinkFiles: (
    files: ProjectContent[],
    successMessage: string
  ) => void;
}) => {
  const dispatch = useDispatch();
  const { Modal, modalProps, openModal, closeModal } = useModal();
  const [currentTab, setCurrentTab] = useState<string>(
    PROJECT_CONTENT_MODAL_TAB.DESIGN
  );
  const [contentList, setContentList] = useState<ProjectContent[]>([]);
  const [tasks, setTasks] = useState<Option[]>([]);
  const teamTasksList = useSelector(selectTeamTasks);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getDesigns());
  }, [dispatch]);

  useEffect(() => {
    if (isOpen && !modalProps.isOpen) {
      openModal(modalProps);
      resetData();
      setCurrentTab(PROJECT_CONTENT_MODAL_TAB.DESIGN);
    }
  }, [isOpen, openModal, modalProps]);

  const handleCloseModal = () => {
    setModalOpen(false);
    closeModal();
  };

  const resetData = () => {
    setContentList([]);
    setTasks([]);
  };

  const onConfirm = () => {
    let successMessage = intl.get('PROJECT_DETAIL.FILES_TAB.FILE_LINK_SUCCESS');
    if (currentTab === PROJECT_CONTENT_MODAL_TAB.FILE) {
      successMessage = intl.get('PROJECT_DETAIL.FILES_TAB.FILE_UPLOAD_SUCCESS');
    }
    if (currentTab === PROJECT_CONTENT_MODAL_TAB.DESIGN) {
      successMessage = intl.get(
        'PROJECT_DETAIL.FILES_TAB.DESIGN_ADDED_SUCCESS'
      );
    }
    handleUploadOrLinkFiles(
      contentList.map((content) => {
        return {
          ...content,
          linkedTasksIds: tasks.map((option) => option.value),
        };
      }),
      successMessage
    );
    handleCloseModal();
  };

  const tasksOptions = useMemo(() => {
    if (!teamTasksList.data) {
      return [];
    }
    return teamTasksList.data.map((task) => {
      return {
        label: task.name,
        value: task.id,
      };
    });
  }, [teamTasksList]);

  const actionButtons = [
    {
      children: intl.get('PROJECT_DETAIL.FILES_TAB.ADD_CONTENT'),
      variant: 'primary',
      disabled: isEmpty(contentList),
      onClick: onConfirm,
      'data-cy': 'add-content-confirm-button',
    },
    {
      children: intl.get('REQUEST_PAGE.TOP_BAR.CREATE_PROJECT_MODAL.CANCEL'),
      variant: 'tertiary',
      onClick: handleCloseModal,
    },
  ];

  return (
    <Modal
      {...modalProps}
      title={intl.get('PROJECT_DETAIL.FILES_TAB.ADD_CONTENT')}
      aria-label={intl.get('PROJECT_DETAIL.FILES_TAB.ADD_CONTENT')}
      childrenClassName='max-h-full'
      size='large'
      closeModal={handleCloseModal}
      actionButtons={actionButtons}
    >
      <div data-cy='add-content-modal'>
        <Typography
          variant='body2'
          weight='medium'
          className='text-neutral-black'
        >
          {intl.get('PROJECT_DETAIL.FILES_TAB.SELECT_CONTENT')}
        </Typography>
        <div className='flex mt-3 pl-3'>
          <Radio
            name={intl.get('PROJECT_DETAIL.FILES_TAB.DESIGN')}
            label={intl.get('PROJECT_DETAIL.FILES_TAB.DESIGN')}
            data-testid='add-content-modal-design-radio'
            value={PROJECT_CONTENT_MODAL_TAB.DESIGN}
            inputProps={{
              checked: currentTab === PROJECT_CONTENT_MODAL_TAB.DESIGN,
              onChange: () => {
                setCurrentTab(PROJECT_CONTENT_MODAL_TAB.DESIGN);
                resetData();
              },
            }}
          />
          <Radio
            name={intl.get('PROJECT_DETAIL.FILES_TAB.FILE')}
            label={intl.get('PROJECT_DETAIL.FILES_TAB.FILE')}
            data-testid='add-content-modal-file-radio'
            value={PROJECT_CONTENT_MODAL_TAB.FILE}
            className='ml-10'
            inputProps={{
              checked: currentTab === PROJECT_CONTENT_MODAL_TAB.FILE,
              onChange: () => {
                setCurrentTab(PROJECT_CONTENT_MODAL_TAB.FILE);
                resetData();
              },
            }}
          />
          <Radio
            name={intl.get('PROJECT_DETAIL.FILES_TAB.WEBLINK')}
            label={intl.get('PROJECT_DETAIL.FILES_TAB.WEBLINK')}
            data-testid='add-content-modal-weblink-radio'
            value={PROJECT_CONTENT_MODAL_TAB.WEB_LINK}
            className='ml-10'
            inputProps={{
              checked: currentTab === PROJECT_CONTENT_MODAL_TAB.WEB_LINK,
              onChange: () => {
                setCurrentTab(PROJECT_CONTENT_MODAL_TAB.WEB_LINK);
                resetData();
              },
            }}
          />
        </div>
        {currentTab === PROJECT_CONTENT_MODAL_TAB.DESIGN && (
          <AddDesignSection setFiles={setContentList} />
        )}
        {currentTab === PROJECT_CONTENT_MODAL_TAB.FILE && (
          <AddFilesSection files={contentList} setFiles={setContentList} />
        )}
        {currentTab === PROJECT_CONTENT_MODAL_TAB.WEB_LINK && (
          <AddWeblinkSection setContent={setContentList} />
        )}
        {canLinkTasks && (
          <>
            <div className='w-full mt-7 border-b border-primary-light'></div>
            <FormItem
              label={intl.get('PROJECT_DETAIL.FILES_TAB.LINK_TO_TASKS')}
              className='mt-7'
            >
              <Dropdown
                placeholder={intl.get('PROJECT_DETAIL.FILES_TAB.SELECTE_TASKS')}
                onChange={(options: Option[]) => setTasks(options)}
                options={tasksOptions}
                values={tasks}
                multiple
                filterable
                renderSelectedOptionTag={({
                  textClassName,
                  ...restOfProps
                }) => (
                  <Tag
                    textClassName={`${textClassName} font-semibold`}
                    {...restOfProps}
                    leadingIcon={{
                      name: 'checkmark-circle-outline',
                    }}
                  />
                )}
                renderTriggerIcon={() => (
                  <Icon name='add-circle-outline' className='text-xl' />
                )}
                triggerProps={{ 'data-cy': 'project-content-tasks-dropdown' }}
                renderOption={(
                  option: Option,
                  isSelected: boolean,
                  selectOption,
                  { className, ...otherProps }
                ) => (
                  <li
                    {...otherProps}
                    className={classnames('group', className, {
                      'hover:bg-primary focus-visible:bg-primary': isSelected,
                    })}
                  >
                    <MultipleOptionLI
                      label={option.label}
                      isSelected={isSelected}
                      selectOption={selectOption}
                    />
                  </li>
                )}
              />
            </FormItem>
          </>
        )}
      </div>
    </Modal>
  );
};
export default AddContentModal;
