const SkeletonLoader = () => {
  return (
    <div
      aria-label='project-progress-skeleton-loader'
      className='h-full flex items-center gap-x-6 animate-pulse'
    >
      <div className='w-80 flex items-center gap-x-2'>
        <div className='flex-1 h-4 bg-neutral-lighter-two rounded' />
        <div className='flex-1 h-4 bg-neutral-lighter-two rounded' />
        <div className='w-10 h-6 bg-neutral-lighter-two rounded' />
      </div>
      <div className='flex items-center gap-x-2'>
        <div className='w-6 h-6 bg-neutral-lighter-two rounded-full' />
        <div className='w-24 h-6 bg-neutral-lighter-two rounded' />
      </div>
      <div className='flex items-center gap-x-2'>
        <div className='w-6 h-6 bg-neutral-lighter-two rounded-full' />
        <div className='w-24 h-6 bg-neutral-lighter-two rounded' />
      </div>
    </div>
  );
};

export default SkeletonLoader;
