import React, { useMemo } from 'react';
import { ProjectTemplate, ProjectTemplateField } from 'utils/types/templates';
import { FieldTemplateType } from 'utils/types/fields';
import AddTemplateFieldsModal from '../../AddTemplateFieldsModal/AddTemplateFieldsModal';
import TemplateFieldsList from './TemplateFieldsList';

const AdditionalInformation: React.FC<{
  templateData: ProjectTemplate;
  updateTemplateData: (key: keyof ProjectTemplate, value: any) => void;
}> = ({ templateData, updateTemplateData }) => {
  const previouslySelectedFieldTemplatesIds = useMemo(
    () =>
      templateData.project_template_fields.map(
        (customProperty: ProjectTemplateField) =>
          customProperty.field_template.id
      ) as string[],
    [templateData.project_template_fields]
  );

  const handleUpdateProjectTemplateFields = (
    fieldTemplatesToAdd: FieldTemplateType[],
    fieldTemplatesIdsToRemove: string[]
  ) => {
    let newProjectTemplateFields: ProjectTemplateField[] = [
      ...templateData.project_template_fields,
    ];
    newProjectTemplateFields = newProjectTemplateFields.filter(
      (projectTemplateField: ProjectTemplateField) =>
        !fieldTemplatesIdsToRemove.includes(
          projectTemplateField.field_template_id
        )
    );
    newProjectTemplateFields = newProjectTemplateFields.map(
      (projectTemplateField: ProjectTemplateField, index: number) => ({
        ...projectTemplateField,
        layout: index + 1,
      })
    );
    const lastProjectTemplateFieldIndex = newProjectTemplateFields.length + 1;
    for (const [index, fieldTemplate] of fieldTemplatesToAdd.entries()) {
      newProjectTemplateFields.push({
        project_template_id: templateData.id || '',
        field_template_id: fieldTemplate.id!,
        field_template: fieldTemplate,
        required: false,
        layout: lastProjectTemplateFieldIndex + index,
      });
    }
    updateTemplateData('project_template_fields', newProjectTemplateFields);
  };

  const handleUpdateTemplateFieldRequiredStatus = (
    fieldTemplateId: string,
    checked: boolean
  ) => {
    const newProjectTemplateFields = templateData.project_template_fields.map(
      (projectTemplateField: ProjectTemplateField) => {
        if (projectTemplateField.field_template_id === fieldTemplateId) {
          return { ...projectTemplateField, required: checked };
        }
        return projectTemplateField;
      }
    );
    updateTemplateData('project_template_fields', newProjectTemplateFields);
  };

  const handleUpdateTemplateFieldsOrder = (
    templateFieldsList: ProjectTemplateField[]
  ) => {
    updateTemplateData('project_template_fields', templateFieldsList);
  };

  return (
    <div className='mt-8' data-testid='additional-information-section'>
      <TemplateFieldsList
        canUpdate={!templateData.system}
        templateFields={templateData.project_template_fields || []}
        onRemoveFieldTemplate={(fieldTemplateId: string) =>
          handleUpdateProjectTemplateFields([], [fieldTemplateId])
        }
        onUpdateRequiredStatus={handleUpdateTemplateFieldRequiredStatus}
        onUpdateTemplatesFieldsOrder={handleUpdateTemplateFieldsOrder}
      />
      {!templateData.system && (
        <AddTemplateFieldsModal
          onUpdateFields={handleUpdateProjectTemplateFields}
          previouslySelectedFieldTemplatesIds={
            previouslySelectedFieldTemplatesIds
          }
        />
      )}
    </div>
  );
};

export default AdditionalInformation;
