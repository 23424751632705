import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'state/store';
import { ActualHoursState, Hours, Submitter } from 'types/store/actualHours';
import { TaskActualHourState } from 'types/store/taskDetail';
import { fetchTaskActualHours } from 'api/taskActualHours';

const normalizeActualHoursState = (
  hours: Hours[]
): ActualHoursState['actualHours'] => {
  return hours.reduce((acc: ActualHoursState['actualHours'], hour: Hours) => {
    if (!acc[hour.submitterId]) {
      acc[hour.submitterId] = [hour];
    } else {
      acc[hour.submitterId].push(hour);
    }
    return acc;
  }, {});
};

export const initialState: ActualHoursState = {
  actualHours: {},
  loading: false,
  submitters: {},
};

export const fetchActualHours = createAsyncThunk(
  'tasksDetail/fetchActualHours',
  async (params: { taskId: string; userId?: string }, { rejectWithValue }) => {
    try {
      const response = await fetchTaskActualHours(params);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error fetching team tasks');
    }
  }
);

const selectSubmitters = (state: RootState) => state.actualHours.submitters;
const selectActualHours = (state: RootState) => state.actualHours.actualHours;

export const selectActualHoursAreLoading = (state: RootState) =>
  state.actualHours.loading;

export const selectCanFetchActualHours = createSelector(
  [
    selectActualHoursAreLoading,
    (state: RootState) => state.taskDetail.taskActualHours,
  ],
  (areActualHoursLoading, actualHours?: TaskActualHourState) => {
    return !actualHours && areActualHoursLoading === false;
  }
);

export const selectSubmittersIds = createSelector(
  [(state: RootState) => state.actualHours.submitters],
  (submitters) => Object.keys(submitters)
);

const selectSubmmiterById = createSelector(
  [selectSubmitters, (_: RootState, submitterId: string) => submitterId],
  (submitters, submitterId) => submitters[submitterId]
);

const selectActualHoursBySubmitterId = createSelector(
  [selectActualHours, (_: RootState, submitterId: string) => submitterId],
  (actualHours, submitterId) => actualHours[submitterId]
);

export const selectSubmitterTimeEntries = createSelector(
  [selectSubmmiterById, selectActualHoursBySubmitterId],
  (submitter: Submitter, actualHours: Hours[]) => {
    const totalHours = actualHours.reduce((acc, hour) => acc + hour.hours, 0);
    return {
      submitter,
      totalHours,
      actualHours,
    };
  }
);

const actualHoursSlice = createSlice({
  name: 'actualHours',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchActualHours.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchActualHours.fulfilled, (state, action) => {
        if (action.payload) {
          const actualHours = normalizeActualHoursState(action.payload.hours);
          state.submitters = action.payload.submitters;
          state.actualHours = actualHours;
        }
        state.loading = false;
      })
      .addCase(fetchActualHours.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default actualHoursSlice.reducer;
