import { useEffect, useMemo, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import { useDispatch } from 'react-redux';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import { createCustomField } from 'state/CustomFields/customFieldsSlice';
import FooterButtons from 'Pages/CustomFieldPage/components/FooterButtons';
import FieldsOverview from 'Pages/CustomFieldPage/components/FieldOverview';
import { FieldTemplateType } from 'utils/types/fields';
import get from 'lodash/get';
import {
  checkIfFieldIsValid,
  removeUnnecessaryData,
} from 'Pages/CustomFieldPage/helpers/helper';
import projectTemplatesAPI from 'state/ProjectTemplates/projectTemplatesAPI';
import UnsavedChangesModal from 'Organisms/UnsavedChangesModal/UnsavedChangesModal';
import { Location } from 'history';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { PATHS } from 'utils/constants';

const NewCustomFieldPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [field, setField] = useState<FieldTemplateType>(
    {} as FieldTemplateType
  );
  const [disableSaveBuuton, setDisableSaveButton] = useState(true);
  const [titleError, setTitleError] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] =
    useState<boolean>(false);
  const [leavingToLocation, setLeavingToLocation] = useState<string>('');
  const [isLeavingWarningModalOpen, setIsLeavingWarningModalOpen] =
    useState<boolean>(false);

  const changesDetected = useMemo(() => {
    const fieldWithoutOwner = { ...field };
    delete fieldWithoutOwner.owner;
    //check if fieldWithoutOwner has any property
    return !!Object.keys(fieldWithoutOwner).length;
  }, [field]);

  useEffect(() => {
    const handleLeavingSiteWithUnsavedChanges = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    if (changesDetected) {
      window.addEventListener(
        'beforeunload',
        handleLeavingSiteWithUnsavedChanges
      );
      return () => {
        window.removeEventListener(
          'beforeunload',
          handleLeavingSiteWithUnsavedChanges
        );
      };
    }
  }, [changesDetected]);

  useEffect(() => {
    if (confirmedNavigation && leavingToLocation) {
      history.push(leavingToLocation);
      setConfirmedNavigation(false);
      setLeavingToLocation('');
    }
  }, [confirmedNavigation, history, leavingToLocation]);

  useEffect(() => {
    if (changesDetected && checkIfFieldIsValid(field)) {
      setDisableSaveButton(false);
    } else {
      setDisableSaveButton(true);
    }
  }, [changesDetected, field]);

  const saveCustomField = async () => {
    const fieldData = removeUnnecessaryData(field);
    const result = await dispatch(createCustomField(fieldData));
    const success = get(result, 'payload.data.newFieldTemplate', false);

    if (success) {
      dispatch(
        showSnackbarNotification({
          notificationVariant: 'success',
          notificationTitle: intl.get('SUCCESS_SNACKBAR_TITLE'),
          notificationMessage: intl.get(
            'SETTINGS_PAGE.FIELDS_PAGE.DETAILS.ADD_SUCCESS',
            { title: field.name }
          ),
        })
      );
      setField({} as FieldTemplateType);
      history.push(
        `${PATHS.SETTINGS}${PATHS.CUSTOM_FIELDS_PAGE}/${success.id}`
      );
    } else {
      dispatch(
        showSnackbarNotification({
          notificationVariant: 'error',
          notificationTitle: '',
          notificationMessage: intl.get(
            'SETTINGS_PAGE.FIELDS_PAGE.DETAILS.UPDATE_ERROR'
          ),
        })
      );
    }
  };

  const onSave = async () => {
    if (field.name) {
      const fields = await projectTemplatesAPI.fetchFieldTemplates();
      const isTitleExist = fields.some(
        (templateField: FieldTemplateType) => templateField.name === field.name
      );
      if (isTitleExist) {
        setDisableSaveButton(true);
        setTitleError(true);
        return;
      }
      saveCustomField();
    } else if (!!Object.keys(field).length) {
      //check if there is other property than name
      saveCustomField();
    }
  };

  const handleBlockedNavigation = (location: Location) => {
    if (!confirmedNavigation) {
      setLeavingToLocation(`${location.pathname}${location.search}`);
      setIsLeavingWarningModalOpen(true);
      return false;
    }
    return true;
  };

  return (
    <div className='h-full flex flex-col'>
      <Prompt when={changesDetected} message={handleBlockedNavigation} />
      <UnsavedChangesModal
        isOpen={isLeavingWarningModalOpen}
        setIsOpen={setIsLeavingWarningModalOpen}
        onConfirm={() => setConfirmedNavigation(true)}
      />
      <PageTitle
        titleComponent={intl.get('SETTINGS_PAGE.FIELDS_PAGE.BUTTON_TITLE')}
        dataCy='custom-field-title'
      />
      <div className='mx-6 rounded-t-lg border-l border-r border-t border-neutral-lighter-two flex-grow'>
        <div className='h-12 border-b p-2 border-neutral-lighter-two flex items-center justify-end'></div>
        <FieldsOverview
          data={field}
          setData={setField}
          onUpdatePage={false}
          titleError={titleError}
          setTitleError={setTitleError}
        />
      </div>
      <FooterButtons
        disableSaveButton={disableSaveBuuton}
        onSave={onSave}
        onUpdatePage={false}
      />
    </div>
  );
};

export default NewCustomFieldPage;
