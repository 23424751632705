import { useState, useEffect } from 'react';
import { Range } from '@getsynapse/design-system';
import { intakeQuestionWrapper } from 'utils/customTypes';
import get from 'lodash/get';

const RangeQuestion = ({
  question,
  handler,
  disabled,
}: intakeQuestionWrapper) => {
  useEffect(() => {
    const newValue = get(question, 'data.value.value');
    setValue((prev) => (prev !== newValue ? newValue : prev));
  }, [question]);

  const from = get(question, 'data.value.from');
  const to = get(question, 'data.value.to');
  const [value, setValue] = useState<number>(
    get(question, 'data.value.value') || from
  );

  return (
    <>
      {from !== '' && to !== '' && (
        <Range
          data-testid='range_questions'
          min={from}
          max={to}
          className='mt-11 mb-11'
          onChange={(event) => {
            setValue(event);
            handler(question, event, 'data.value.value');
          }}
          value={value}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default RangeQuestion;
