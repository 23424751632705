import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { Button } from '@getsynapse/design-system';
import { openSidePanel } from 'state/ManageTimeOff/SidePanel/sidePanelSlice';

const AddTimeOffButton = () => {
  const dispatch = useDispatch();
  return (
    <Button onClick={() => dispatch(openSidePanel())}>
      {intl.get('MANAGE_TIME_OFF.ADD_TIME_OFF')}
    </Button>
  );
};

export default AddTimeOffButton;
