import React from 'react';
import intl from 'react-intl-universal';
import {
  OverflowMenu,
  OverflowMenuItem,
  Icon,
  Typography,
} from '@getsynapse/design-system';
import { ProjectContent } from 'utils/customTypes';
import { PROJECT_CONTENT_TYPE } from 'utils/constants';

const ActionsMenu: React.FC<{
  onReplaceCallback?: () => void;
  onUpdateLinkedTasksCallback?: () => void;
  onRemoveCallback: () => void;
  file: ProjectContent;
  isReadOnly?: boolean;
}> = ({
  onReplaceCallback,
  onUpdateLinkedTasksCallback,
  onRemoveCallback,
  file,
  isReadOnly,
}) => {
  let removeLabel = intl.get('DELETE');

  if (
    file.content_type === PROJECT_CONTENT_TYPE.WEB_LINK ||
    file.content_type === PROJECT_CONTENT_TYPE.DESIGN
  ) {
    removeLabel = intl.get('REMOVE');
  }

  return (
    <div className='w-full h-full flex justify-center items-center'>
      <OverflowMenu
        menuButtonProps={{
          className:
            'text-lg text-neutral focus:outline-none active:outline-none focus-visible:outline-none',
          'data-cy': `content-table__file--${file.id}__actions-menu__trigger-button`,
        }}
        menuListProps={{
          className: 'py-2',
          'data-cy': `content-table__file--${file.id}__actions-menu__options-list`,
        }}
        disabled={isReadOnly}
      >
        {onReplaceCallback && (
          <OverflowMenuItem
            onSelect={onReplaceCallback}
            className='group border-l-2 border-transparent hover:border-neutral-dark first:rounded-t-none last:rounded-b-none'
            data-cy='content-table__actions-menu__replace-option'
          >
            <div className='flex items-center min-h-6 cursor-pointer'>
              <Icon name='sync' className='text-xl mr-2' />
              <Typography
                variant='label'
                className='text-neutral-black cursor-pointer group-active:text-neutral-white'
              >
                {intl.get(
                  'PROJECT_DETAIL.FILES_TAB.TABLE.ACTIONS_MENU.REPLACE'
                )}
              </Typography>
            </div>
          </OverflowMenuItem>
        )}
        {onUpdateLinkedTasksCallback && (
          <OverflowMenuItem
            onSelect={onUpdateLinkedTasksCallback}
            className='group border-l-2 border-transparent hover:border-neutral-dark first:rounded-t-none last:rounded-b-none'
            data-cy='content-table__actions-menu__link-unlink-option'
          >
            <div className='flex items-center min-h-6 cursor-pointer'>
              <Icon name='checkmark-circle-outline' className='text-xl mr-2' />
              <Typography
                variant='label'
                className='text-neutral-black cursor-pointer group-active:text-neutral-white'
              >
                {intl.get(
                  'PROJECT_DETAIL.FILES_TAB.TABLE.ACTIONS_MENU.LINK_UNLINK_TASK'
                )}
              </Typography>
            </div>
          </OverflowMenuItem>
        )}
        <OverflowMenuItem
          onSelect={onRemoveCallback}
          className='group border-l-2 border-transparent hover:border-neutral-dark first:rounded-t-none last:rounded-b-none'
          data-cy='content-table__actions-menu__delete-option'
        >
          <div className='flex items-center min-h-6 cursor-pointer'>
            <Icon name='trash' className='text-xl text-error-darker mr-2' />
            <Typography
              variant='label'
              className='text-neutral-black cursor-pointer group-active:text-neutral-white'
            >
              {intl.get('PROJECT_DETAIL.FILES_TAB.TABLE.ACTIONS_MENU.DELETE', {
                action: removeLabel,
              })}
            </Typography>
          </div>
        </OverflowMenuItem>
      </OverflowMenu>
    </div>
  );
};

export default ActionsMenu;
