import intl from 'react-intl-universal';
import { tailwindOverride, Typography } from '@getsynapse/design-system';

const TableLegends = () => (
  <div className='bg-neutral-white shadow-skim w-full h-8 flex items-center justify-end space-x-6 pr-6'>
    <Typography
      variant='body2'
      className='text-neutral-dark flex items-center space-x-2 cursor-default'
      title={intl.get('TEAMS.CAPACITY_LEGENDS.ALLOCATION')}
    >
      <span
        className={tailwindOverride(
          'inline-block w-2 h-2',
          'rounded-lg bg-success-darker',
          'border border-success-darkest'
        )}
      />
      <span>{intl.get('TEAMS.CAPACITY_LEGENDS.ALLOCATION')}</span>
    </Typography>
    <Typography
      variant='body2'
      className='text-neutral-dark flex items-center space-x-2 cursor-default'
      title={intl.get('TEAMS.CAPACITY_LEGENDS.OVER_ALLOCATION')}
    >
      <span
        className={tailwindOverride(
          'inline-block w-2 h-2',
          'rounded-lg bg-error-dark',
          'border border-error-darker'
        )}
      />
      <span>{intl.get('TEAMS.CAPACITY_LEGENDS.OVER_ALLOCATION')}</span>
    </Typography>
    <Typography
      variant='body2'
      className='text-neutral-dark flex items-center space-x-2 cursor-default'
      title={intl.get('TEAMS.CAPACITY_LEGENDS.TIMEOFF_HOLIDAYS')}
    >
      <span
        className={tailwindOverride(
          'inline-block w-2 h-2',
          'rounded-lg bg-fire',
          'border border-fire-dark'
        )}
      />
      <span>{intl.get('TEAMS.CAPACITY_LEGENDS.TIMEOFF_HOLIDAYS')}</span>
    </Typography>
  </div>
);

export default TableLegends;
