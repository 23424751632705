export const ACTIVE_COLORS = ['#45598C', '#3389D3', '#35510B', '#800020'];
export const INACTIVE_COLORS = ['#E1E9EC', '#E1E9EC', '#E1E9EC', '#E1E9EC'];
export const inActiveColor = '#E1E9EC';
export const SECTOR_INACTIVE_COLOR = '#000000';
export const CENTER_COUNT_COLOR = '#0E1212';
export const CENTER_TEXT_COLOR = '#506268';
export const chartCenterCountStyle = {
  fontWeight: 500,
  fontSize: '1.5em',
  lineHeight: '1.7em',
};

export const chartCenterTextStyle = {
  fontWeight: 500,
  fontSize: '1em',
  lineHeight: '1em',
};

const getStatusOptions = (intl: any) => {
  const statusNew = intl.get(`TASKS.TASK_DETAIL_PAGE.STATUS_OPTIONS.NEW`);
  const statusInProgress = intl.get(
    `TASKS.TASK_DETAIL_PAGE.STATUS_OPTIONS.IN_PROGRESS`
  );
  const statusCompleted = intl.get(
    `TASKS.TASK_DETAIL_PAGE.STATUS_OPTIONS.COMPLETED`
  );
  const statusOnHold = intl.get(
    `TASKS.TASK_DETAIL_PAGE.STATUS_OPTIONS.ON_HOLD`
  );

  return {
    statusNew,
    statusInProgress,
    statusCompleted,
    statusOnHold,
  };
};

export const DATA_STATE_NO_TASKS = (intl: any) => {
  const statusOptions = getStatusOptions(intl);

  return [
    {
      name: statusOptions.statusNew,
      value: 1,
    },
    {
      name: statusOptions.statusInProgress,
      value: 1,
    },
    {
      name: statusOptions.statusCompleted,
      value: 1,
    },
    {
      name: statusOptions.statusOnHold,
      value: 1,
    },
  ];
};
