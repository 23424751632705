import { TASK_STATUS } from 'utils/constants';
import moment from 'moment';
import {
  NewProject,
  AllUsersType,
  UserAvatars,
  TaskActualHours,
} from 'utils/customTypes';
import { isUserStatusDisabled } from 'utils/functions';

export const isTaskDueDatePast = (
  taskStatus: string,
  taskDuedate: string | undefined
) => {
  if (taskStatus === TASK_STATUS.COMPLETED || !taskDuedate) {
    return false;
  }
  if (moment(new Date(taskDuedate)).isBefore(moment(), 'day')) {
    return true;
  }
  return false;
};

export const getAvailableUsersForTaskAssignees = (
  project: NewProject,
  members: string[],
  collaborators: string[],
  assignees: string[],
  users: AllUsersType[]
) => {
  const usersList: string[] = [];
  for (let user of project.owners.concat(members, collaborators)) {
    if (user && !assignees.includes(user)) {
      usersList.push(user);
    }
  }

  return users
    .filter((user: AllUsersType) => usersList.includes(user.id))
    .map((user: AllUsersType) => ({
      label: `${user.data.firstName} ${user.data.lastName}`,
      avatar: {
        imageSrc: user.avatar_url,
        initial: `${user.data?.firstName?.charAt(
          0
        )}${user.data?.lastName?.charAt(0)}`,
        name: `${user.data?.firstName} ${user.data?.lastName}`,
      },
      value: user.id,
    })) as UserAvatars[];
};

export const getFormattedTaskAssignees = (
  assignees: string[],
  users: AllUsersType[]
) => {
  return users
    .filter((user: AllUsersType) => assignees.includes(user.id))
    .map((user: AllUsersType) => ({
      label: `${user.data.firstName} ${user.data.lastName}`,
      avatar: {
        imageSrc: user.avatar_url,
        initial: `${user.data?.firstName?.charAt(
          0
        )}${user.data?.lastName?.charAt(0)}`,
        name: `${user.data?.firstName} ${user.data?.lastName}`,
      },
      disabled: isUserStatusDisabled(user.status),
      value: user.id,
    })) as UserAvatars[];
};

export const getActualHoursData = (
  originalData: TaskActualHours[],
  newData: TaskActualHours[]
) => {
  const newActualHours: TaskActualHours[] = [];
  const removedActualHours: string[] = [];
  newData.forEach((actualHours) => {
    if (!originalData.some((item) => item.id === actualHours.id)) {
      delete actualHours.id;
      delete actualHours.actual_hours_creator;
      newActualHours.push(actualHours);
    }
  });
  originalData.forEach((actualHours) => {
    if (!newData.some((item) => item.id === actualHours.id)) {
      removedActualHours.push(actualHours.id!);
    }
  });
  return { newActualHours, removedActualHours };
};

export const calculateActualHoursTotal = (array: TaskActualHours[]) =>
  array.reduce((accum, item) => accum + Number(item.number_of_hours), 0);
