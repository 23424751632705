import { ProgramLinkedProject } from 'utils/customTypes';
import ProgramProjectsTable from '../ProgramProjectsTable';
import ProgramProjectsTableHeader from '../ProgramProjectsTableHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { ProgramProjectFilters } from 'utils/types/filters';
import { SETTINGS_FILTERS } from 'utils/constants';
import {
  createFilterSetting,
  fetchFilterSettingByType,
  selectFiltersSettingsByType,
  updateFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import { setFilters } from 'state/Program/programSlice';

interface ProgramProjectsProps {
  projects: ProgramLinkedProject[];
  userHasWriteAccess: boolean;
}

const ProgramProjects: React.FC<ProgramProjectsProps> = ({
  projects,
  userHasWriteAccess,
}) => {
  const dispatch = useDispatch();

  const filtersSettings = useSelector(
    selectFiltersSettingsByType(SETTINGS_FILTERS.PROGRAM_PROJECTS_TABLE)
  );
  const savedFilters = useMemo<ProgramProjectFilters>(
    () => (filtersSettings ? filtersSettings.settings : {}),
    [filtersSettings]
  );

  useEffect(() => {
    if (savedFilters) {
      dispatch(setFilters(savedFilters));
    }
  }, [savedFilters, dispatch]);

  useEffect(() => {
    dispatch(fetchFilterSettingByType(SETTINGS_FILTERS.PROGRAM_PROJECTS_TABLE));
  }, [dispatch]);

  const handleSaveFiltersSettings = (updatedFilters: ProgramProjectFilters) => {
    if (filtersSettings && filtersSettings.id) {
      dispatch(
        updateFilterSetting({
          id: filtersSettings.id,
          updateFields: { filter_settings: updatedFilters },
        })
      );
    } else {
      dispatch(
        createFilterSetting({
          filter_type: SETTINGS_FILTERS.PROGRAM_PROJECTS_TABLE,
          filter_settings: updatedFilters,
        })
      );
    }
  };

  return (
    <>
      <ProgramProjectsTableHeader
        savedFilters={savedFilters}
        saveFilters={handleSaveFiltersSettings}
      />
      <ProgramProjectsTable
        projects={projects || []}
        userHasWriteAccess={userHasWriteAccess}
      />
    </>
  );
};

export default ProgramProjects;
