import { useMemo } from 'react';
import intl from 'react-intl-universal';
import {
  tailwindOverride,
  Typography,
  useLink,
} from '@getsynapse/design-system';
import AlmostThereBg from 'assets/images/almost-there-bg.svg';
import VerifyBg from 'assets/images/verify-bg.svg';
import UpgradeBg from 'assets/images/upgrade-bg.svg';
import DeactivatedBg from 'assets/images/deactivated-bg.svg';

type Pages = 'upgrade' | 'verify' | 'almostThere' | 'deactivated';

type Props = {
  page: Pages;
  children: React.ReactNode;
};

const bgMap: Record<
  Pages,
  {
    bgColor: string;
    bgUrl: string;
  }
> = {
  almostThere: {
    bgColor: 'bg-purple-dark',
    bgUrl: AlmostThereBg,
  },
  deactivated: {
    bgColor: 'bg-fire-light',
    bgUrl: DeactivatedBg,
  },
  upgrade: {
    bgColor: 'bg-tertiary-dark',
    bgUrl: UpgradeBg,
  },
  verify: {
    bgColor: 'bg-secondary-dark',
    bgUrl: VerifyBg,
  },
};

const AnonNotice = ({ children, page }: Props) => {
  const externalLink = useLink(true);

  const { bgColor, bgUrl } = useMemo(() => bgMap[page], [page]);

  return (
    <div className='flex h-screen'>
      <div className={tailwindOverride('w-5/12 flex items-center', bgColor)}>
        <div
          className='w-full h-full bg-cover bg-left bg-no-repeat'
          style={{ backgroundImage: `url(${bgUrl})` }}
        />
      </div>

      <div className='relative flex flex-col justify-center items-center flex-grow px-16 pb-6'>
        <main>{children}</main>

        <div className='absolute bottom-0 mb-6'>
          <Typography variant='caption' className='pb-4 text-neutral-black'>
            {intl.getHTML('FOOTER', {
              year: new Date().getFullYear(),
              externalLink: `class='${externalLink}'`,
            })}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default AnonNotice;
