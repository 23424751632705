import { FC } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import {
  Tooltip,
  IconButton,
  tailwindOverride,
  Typography,
} from '@getsynapse/design-system';
import { setEntryId } from 'state/ManageTimeOff/TimeOffDays/timeOffDaysSlice';

const EditButton: FC<{ timeOffEntryId: string }> = ({ timeOffEntryId }) => {
  const dispatch = useDispatch();
  return (
    <Tooltip
      openMode='hover1'
      timeout={0}
      showPopper
      position='topCenter'
      contentProps={{
        className: tailwindOverride(
          'px-3 py-2 z-50 absolute',
          'border border-purple-lighter',
          'bg-neutral-white'
        ),
      }}
      usePortal
      trigger={
        <IconButton
          name='pencil'
          aria-label={intl.get('EDIT')}
          iconClassname='pointer-events-none'
          className='text-base text-neutral-dark p-1 hover:bg-neutral-lightest hover:shadow-allocation-table rounded'
          onClick={() => dispatch(setEntryId(timeOffEntryId))}
        />
      }
    >
      <Typography variant='label' className='text-purple-darker'>
        {intl.get('EDIT')}
      </Typography>
    </Tooltip>
  );
};

export default EditButton;
