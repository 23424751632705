import React, { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Typography, Icon, tailwindOverride } from '@getsynapse/design-system';
import { Week } from '../helpers';
import { RoleAllocation } from './AllocatedWeeksTable';

const SectionToggle: React.FC<{
  id?: string;
  onToggleSection: (event: React.MouseEvent<HTMLDivElement>) => void;
  label: string;
  currentWeeksSlideArray: (Week | null)[];
  numberOfWeeks: number;
  isSectionOpen?: boolean;
  displayRemoveButton?: boolean;
}> = ({
  id,
  label,
  onToggleSection,
  numberOfWeeks,
  currentWeeksSlideArray,
  isSectionOpen = false,
  displayRemoveButton = false,
}) => {
  const { tasksAssignmentsBasedResourcesCapacity = false } = useFlags();

  const allocationsGrid = useMemo(() => {
    return currentWeeksSlideArray.map((week, index: number) =>
      week !== null ? (
        <RoleAllocation
          id={index}
          key={index}
          displayContent={false}
          isCurrentWeek={week.isCurrentWeek || false}
          className={tailwindOverride('flex flex-1', {
            'shadow-table-column': !tasksAssignmentsBasedResourcesCapacity,
          })}
          currentWeekClassName={
            tasksAssignmentsBasedResourcesCapacity
              ? 'bg-secondary-lightest border-l border-r border-secondary-lighter'
              : 'bg-neutral-lighter-two'
          }
        />
      ) : (
        <div key={index} />
      )
    );
  }, [currentWeeksSlideArray, tasksAssignmentsBasedResourcesCapacity]);
  return (
    <div
      data-cy={`${id ? id.toLowerCase() : label.toLowerCase()}-section-toggle`}
      className='h-full flex cursor-pointer bg-neutral-white'
      onClick={onToggleSection}
    >
      <div
        className={tailwindOverride('w-2/5 flex h-full relative', {
          'shadow-allocation-table-inner':
            !tasksAssignmentsBasedResourcesCapacity,
        })}
      >
        <div className='w-12 h-full flex items-center justify-center'>
          <div
            className='flex'
            style={{
              transform: `${isSectionOpen ? 'rotate(180deg)' : 'rotate(0deg)'}`,
              transition: 'all 0.2s linear',
            }}
          >
            <Icon name='chevron-down' className='text-lg text-neutral-dark' />
          </div>
        </div>
        <div className='px-2 flex flex-1 items-center'>
          <Typography
            variant='caption'
            className='text-neutral-darker font-semibold'
          >
            {label}
          </Typography>
        </div>
      </div>
      <div className='w-3/5 flex h-full'>
        <div className='w-6' />
        <div className='flex flex-1 flex-col h-full'>
          <div className={`grid grid-cols-${numberOfWeeks} h-full`}>
            {currentWeeksSlideArray.length > 0 && allocationsGrid}
          </div>
        </div>
        <div className='w-6' />
        {displayRemoveButton && (
          <div className='w-12 h-full shadow-capacity-table-remove-participant' />
        )}
      </div>
    </div>
  );
};

export default SectionToggle;
