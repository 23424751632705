import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import { FormItem, Dropdown, Tag, Icon } from '@getsynapse/design-system';
import { ProjectContent, Task } from 'utils/customTypes';
import useModal from 'Hooks/useModal';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { selectTeamTasks } from 'state/ProjectTasks/projectTaskSlice';
import { updateProjectFileLinkedTasks } from 'state/Project/projectSlice';
import { MultipleOptionLI } from 'Pages/NewProjectPage/helpers/snippets';

const LinkUnlinkToTaskModal: React.FC<{
  projectId: string;
  isModalOpen: boolean;
  onCloseModal: () => void;
  contentFile: ProjectContent | null;
}> = ({ isModalOpen, onCloseModal, contentFile, projectId }) => {
  const dispatch = useDispatch();
  const projectTasks = useSelector(selectTeamTasks);
  const { Modal, modalProps, openModal, closeModal } = useModal();
  const [originalLinkedTasksIds, setOriginalLinkedTasksIds] = useState<
    string[]
  >([]);
  const [linkedTasks, setLinkedTasks] = useState<Task[]>([]);

  const canSubmit = useMemo(() => {
    const currentLinkedTasksIds = linkedTasks?.map(
      (linkedTask: Task) => linkedTask.id
    );
    return !isEqual(currentLinkedTasksIds, originalLinkedTasksIds);
  }, [originalLinkedTasksIds, linkedTasks]);

  useEffect(() => {
    if (isModalOpen && !modalProps.isOpen) {
      openModal(modalProps);
    }
  }, [isModalOpen, modalProps, openModal]);

  useEffect(() => {
    if (contentFile !== null) {
      setLinkedTasks(contentFile.linkedTasks || []);
      setOriginalLinkedTasksIds(contentFile.linkedTasksIds || []);
    }
  }, [contentFile]);

  const handleCloseModal = () => {
    onCloseModal();
    closeModal();
  };

  const handleOnConfirm = async () => {
    await dispatch(
      updateProjectFileLinkedTasks({
        projectId,
        data: { linkedTasks, fileId: contentFile?.id! },
      })
    );
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get(
          'PROJECT_DETAIL.FILES_TAB.LINK_UNLINK_TO_TASK_MODAL.SUBMIT_SUCCESS_MESSAGE'
        ),
      })
    );
    handleCloseModal();
  };

  const actionButtons = [
    {
      children: intl.get('SAVE'),
      variant: 'primary',
      onClick: handleOnConfirm,
      disabled: !canSubmit,
      'data-cy': 'link-unlink-task-modal__submit-button',
    },
    {
      children: intl.get('CANCEL'),
      variant: 'tertiary',
      onClick: handleCloseModal,
      'data-cy': 'link-unlink-task-modal__cancel-button',
    },
  ];

  return (
    <Modal
      {...modalProps}
      title={intl.get(
        'PROJECT_DETAIL.FILES_TAB.LINK_UNLINK_TO_TASK_MODAL.TITLE'
      )}
      aria-label={intl.get(
        'PROJECT_DETAIL.FILES_TAB.LINK_UNLINK_TO_TASK_MODAL.TITLE'
      )}
      closeModal={handleCloseModal}
      actionButtons={actionButtons}
      size='large'
      data-cy='link-unlink-task-modal'
    >
      <FormItem
        label={intl.get(
          'PROJECT_DETAIL.FILES_TAB.LINK_UNLINK_TO_TASK_MODAL.LABEL'
        )}
      >
        <Dropdown
          options={projectTasks.data || []}
          getOptionLabel={(option: Task) => option.name}
          values={linkedTasks}
          onChange={(options: Task[]) => setLinkedTasks(options)}
          placeholder={intl.get(
            'PROJECT_DETAIL.FILES_TAB.LINK_UNLINK_TO_TASK_MODAL.TASKS_PICKER_PLACEHOLDER'
          )}
          multiple
          renderSelectedOptionTag={({ textClassName, ...restOfProps }) => (
            <Tag
              data-cy='link-unlink-task-modal__linked-task'
              textClassName={`${textClassName} font-semibold`}
              {...restOfProps}
              leadingIcon={{
                name: 'checkmark-circle-outline',
              }}
            />
          )}
          renderTriggerIcon={() => (
            <Icon name='add-circle-outline' className='text-xl' />
          )}
          triggerProps={{ 'data-cy': 'link-unlink-task-modal__tasks-dropdown' }}
          listProps={{ 'data-cy': 'link-unlink-task-modal__tasks-list' }}
          renderOption={(
            option: Task,
            isSelected: boolean,
            selectOption,
            { className, ...otherProps }
          ) => (
            <li
              {...otherProps}
              className={classnames('group', className, {
                'hover:bg-primary focus-visible:bg-primary': isSelected,
              })}
            >
              <MultipleOptionLI
                label={option.name}
                isSelected={isSelected}
                selectOption={selectOption}
              />
            </li>
          )}
        />
      </FormItem>
    </Modal>
  );
};

export default LinkUnlinkToTaskModal;
