import intl from 'react-intl-universal';
import { TableExportOptions } from 'utils/customTypes';
import { TEAM_MEMBER_COLUMNS } from 'utils/constants';

const teamHeader = (
  onSelectExportOptionCallback: (
    exportOption: TableExportOptions | null
  ) => void
) => ({
  headData: {
    stickyHeader: true,
    onSelectExportOption: onSelectExportOptionCallback,
    headCells: [
      ...TEAM_MEMBER_COLUMNS.map((column) => ({
        content: intl.get(`TEAMS.TABLE.${column.labelKey}`),
      })),
      { content: <span /> },
    ],
  },
});

export default teamHeader;
