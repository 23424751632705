import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { FieldsFiltersQueryParams } from 'utils/customTypes';
import { initAxios } from 'utils/axios';
import { FieldTemplateType, FieldDefaultValue } from 'utils/types/fields';
import get from 'lodash/get';

class CustomFieldsAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(`${config.get('backendURL')}customFields`);
  }

  fetchCustomFields = async (params: {
    searchText: string;
    limit: number;
    offset: number;
    filters?: FieldsFiltersQueryParams[];
  }) => {
    const { data } = await this.instance.post('/retrieveCustomFields', {
      limit: params.limit.toString(),
      offset: params.offset,
      searchText: params.searchText,
      filters: params.filters,
    });
    return data;
  };

  fetchCustomField = async (fieldId: string) => {
    const { data } = await this.instance.get(
      `/retrieveFieldTemplate/${fieldId}`
    );
    return data;
  };

  deleteCustomField = async (fieldId: string) => {
    const { data } = await this.instance.delete(
      `/deleteFieldTemplate/${fieldId}`
    );
    return data;
  };

  updateCustomField = async (
    fieldId: string,
    updateFields: FieldTemplateType,
    defaultValues: FieldDefaultValue[]
  ) => {
    const response = await this.instance.put(
      `/updateFieldTemplate/${fieldId}`,
      {
        updateFields,
        defaultValues,
      }
    );
    if (response.data) {
      return response.data;
    }
    return get(response, 'response.data');
  };

  createCustomField = async (field: FieldTemplateType) => {
    const response = await this.instance.post('/createFieldTemplate', field);
    if (response.data) {
      return response.data;
    }
    return get(response, 'response.data');
  };

  fetchAllFieldsUsedInProjects = async () => {
    const { data } = await this.instance.get('/retrieveFieldsUsedInProjects');
    return data;
  };
}

const customFieldsAPI = new CustomFieldsAPI();

export default customFieldsAPI;
