import { FC, useEffect, useState, useRef } from 'react';
import { DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';
import { useInView } from 'react-intersection-observer';
import { tailwindOverride } from '@getsynapse/design-system';
import { CentralizedTasksTableTab } from 'utils/types/centralizedTasks';

interface LastTaskRowProps {
  isOdd: boolean;
  tableName: CentralizedTasksTableTab;
  onLastRowInView: (inView: boolean) => void;
}

const LastTaskRow: FC<LastTaskRowProps> = ({
  isOdd,
  tableName,
  onLastRowInView,
}) => {
  const [rootRef, setRootRef] = useState<HTMLElement | null>(null);
  const debouncedOnChangeHandler =
    useRef<DebouncedFunc<(inView: boolean) => void>>();

  useEffect(() => {
    const table = document.querySelector(
      `table[arial-label="${tableName}"]`
    ) as HTMLElement;
    setRootRef(table);
  }, [tableName]);

  useEffect(() => {
    debouncedOnChangeHandler.current = debounce(
      (inView: boolean) => inView && onLastRowInView(inView),
      500
    );
  }, [onLastRowInView]);

  const { ref } = useInView({
    root: rootRef,
    threshold: 0,
    rootMargin: '-80px 0px 0px',
    onChange: debouncedOnChangeHandler.current,
  });

  const columnStyles = tailwindOverride('px-4 py-3', 'animate-pulse');

  const columnChildStyles = tailwindOverride(
    'h-4 rounded',
    'bg-primary-lighter-two'
  );

  return (
    <tr
      ref={ref}
      className={tailwindOverride('h-10', {
        'bg-neutral-lightest-two': isOdd,
        'bg-neutral-white': !isOdd,
      })}
      aria-label={`${tableName}__last-task-row`}
    >
      <td className={tailwindOverride('w-84', columnStyles)}>
        <div className={tailwindOverride('w-3/5', columnChildStyles)} />
      </td>
      <td className={tailwindOverride('w-32', columnStyles)}>
        <div className={tailwindOverride('w-3/5', columnChildStyles)} />
      </td>
      <td className={tailwindOverride('w-72', columnStyles)}>
        <div className={tailwindOverride('w-3/4', columnChildStyles)} />
      </td>
      <td className={tailwindOverride('w-72', columnStyles)}>
        <div className={tailwindOverride('w-1/4', columnChildStyles)} />
      </td>
      <td className={tailwindOverride('w-32', columnStyles)}>
        <div className={tailwindOverride('w-3/5', columnChildStyles)} />
      </td>
      <td className={tailwindOverride('w-32', columnStyles)}>
        <div className={tailwindOverride('w-3/5', columnChildStyles)} />
      </td>
      <td className={tailwindOverride('w-32', columnStyles)}>
        <div className={tailwindOverride('w-1/4', columnChildStyles)} />
      </td>
      <td className={tailwindOverride('w-32', columnStyles)}>
        <div className={tailwindOverride('w-1/4', columnChildStyles)} />
      </td>
      <td className={tailwindOverride('w-6', columnStyles)}></td>
    </tr>
  );
};

export default LastTaskRow;
