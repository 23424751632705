import { FC, useRef, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { DebouncedFunc, debounce } from 'lodash';
import {
  FormItem,
  tailwindOverride,
  TextArea,
} from '@getsynapse/design-system';

interface TaskDescriptionProps {
  isOnSidepanel?: boolean;
  isViewOnly?: boolean;
  isReadOnly?: boolean;
  shouldExpand2Rows?: boolean;
  description: string;
  onDescriptionChange: (description: string) => void;
}

const TaskDescription: FC<TaskDescriptionProps> = ({
  isOnSidepanel = false,
  isViewOnly = false,
  isReadOnly = false,
  shouldExpand2Rows = true,
  description,
  onDescriptionChange,
}: TaskDescriptionProps) => {
  const [newDescription, setNewDescription] = useState<string>(description);
  const debouncedOnChangeHandler =
    useRef<DebouncedFunc<(description: string) => void>>();

  useEffect(() => {
    debouncedOnChangeHandler.current = debounce(
      (description: string) => onDescriptionChange(description),
      500
    );
  }, [onDescriptionChange]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewDescription(event.target.value);
    if (debouncedOnChangeHandler.current) {
      debouncedOnChangeHandler.current(event.target.value);
    }
  };

  return (
    <FormItem
      label={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_DESCRIPTION_LABEL')}
      className={tailwindOverride({
        'mt-2': isOnSidepanel,
        'row-span-2': shouldExpand2Rows,
      })}
    >
      <TextArea
        disabled={isViewOnly || isReadOnly}
        value={newDescription}
        textAreaProps={{
          placeholder: intl.get(
            'TASKS.TASK_DETAIL_PAGE.TASK_DESCRIPTION_PLACEHOLDER'
          ),
          className: tailwindOverride('max-h-32', {}),
          'data-cy': 'task-description-input',
        }}
        onChange={onChangeHandler}
      />
    </FormItem>
  );
};

export default TaskDescription;
