import React, { useMemo, useEffect, useCallback } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@getsynapse/design-system';
import {
  selectParticipantIdToRemove,
  setParticipantIdToRemove,
  removeParticipant,
} from 'state/TasksAssignmentsResourcesCapacity/tasksAssignmentsResourcesCapacitySlice';
import { showNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import useModal from 'Hooks/useModal';

const RemoveParticipantModal: React.FC<{ projectId: string }> = ({
  projectId,
}) => {
  const dispatch = useDispatch();
  const participantIdToRemove = useSelector(selectParticipantIdToRemove);
  const { Modal, modalProps, openModal, closeModal } = useModal();

  const handleCloseModal = useCallback(() => {
    dispatch(setParticipantIdToRemove(null));
    closeModal();
  }, [closeModal, dispatch]);

  const handleRemoveParticipant = useCallback(async () => {
    await dispatch(
      removeParticipant({
        project_id: projectId,
        user_id: participantIdToRemove!,
      })
    );
    dispatch(
      showNotification({
        notificationVariant: 'success',
        notificationTitle: intl.get(
          'PEOPLE.RESOURCE_ALLOCATION.REMOVE_PARTICIPANT_MODAL.PARTICIPANT_REMOVED_SUCCESS_SNACKBAR_MESSAGE'
        ),
      })
    );
    handleCloseModal();
  }, [dispatch, projectId, participantIdToRemove, handleCloseModal]);

  const actionButons = useMemo(
    () => [
      {
        children: intl.get(
          'PEOPLE.RESOURCE_ALLOCATION.REMOVE_PARTICIPANT_MODAL.REMOVE'
        ),
        variant: 'primary',
        color: 'danger',
        'data-cy': 'remove-button',
        onClick: handleRemoveParticipant,
      },
      {
        children: intl.get('CANCEL'),
        variant: 'tertiary',
        onClick: handleCloseModal,
        'data-cy': 'cancel-button',
      },
    ],
    [handleCloseModal, handleRemoveParticipant]
  );

  useEffect(() => {
    if (participantIdToRemove && !modalProps.isOpen) {
      openModal({
        ...modalProps,
        isOpen: true,
      });
    }
  }, [participantIdToRemove, openModal, modalProps]);

  return (
    <Modal
      {...modalProps}
      title={intl.get(
        'PEOPLE.RESOURCE_ALLOCATION.REMOVE_PARTICIPANT_MODAL.TITLE'
      )}
      titleIcon={{
        name: 'trash',
        className: 'text-error-dark',
      }}
      aria-label={intl.get(
        'PEOPLE.RESOURCE_ALLOCATION.REMOVE_PARTICIPANT_MODAL.TITLE'
      )}
      size='medium'
      className='h-full'
      actionButtons={actionButons}
      data-cy='remove-participant-modal'
      closeModal={handleCloseModal}
    >
      <Typography variant='body' className='text-primary'>
        {intl.getHTML(
          'PEOPLE.RESOURCE_ALLOCATION.REMOVE_PARTICIPANT_MODAL.DESCRIPTION'
        )}
      </Typography>
    </Modal>
  );
};

export default RemoveParticipantModal;
