import intl from 'react-intl-universal';
import { LargeLabelInput, Icon, Typography } from '@getsynapse/design-system';
import { AdditionalBenefit } from 'utils/types/program';
import benefitIcon from 'assets/icons/benefit-icon.svg';

type Props = {
  data: AdditionalBenefit;
  updateData: (value: string, key: string) => void;
};

const BenefitManagement = ({ data, updateData }: Props) => (
  <div className='pt-6 pl-8'>
    <Typography variant='h5' className='leading-7 mb-6'>
      {intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.BENEFIT_MANAGEMENT.TITLE'
      )}
    </Typography>
    <div className='flex'>
      <Icon src={benefitIcon} className='w-10 h-10 mr-4' />
      <LargeLabelInput
        className='w-3/5'
        value={data.benefitText}
        onChange={(event) => updateData(event.target.value, 'benefitText')}
        name={intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.BENEFIT_MANAGEMENT.BENEFIT',
          {
            number: data.position,
          }
        )}
        label={intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.BENEFIT_MANAGEMENT.BENEFIT',
          {
            number: data.position,
          }
        )}
        labelProps={{
          className: 'text-base leading-6 mb-0',
        }}
        subtitle={intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.BENEFIT_MANAGEMENT.BENEFIT_SUBTITLE'
        )}
        subtitleProps={{ className: 'text-neutral-darker' }}
        textAreaProps={{
          placeholder: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.BENEFIT_MANAGEMENT.BENEFIT_PLACEHOLDER'
          ),
          className: 'w-full max-h-32 placeholder-text-neutral',
          'data-testid': 'benefit-text_input',
          rows: 1,
        }}
      />
    </div>
  </div>
);

export default BenefitManagement;
