import React from 'react';
import { Table, Typography } from '@getsynapse/design-system';
import { TasksSummaryItem } from 'utils/customTypes';
import intl from 'react-intl-universal';
import classNames from 'classnames';

const TasksSummaryTable: React.FC<{ data: any }> = ({ data }) => (
  <div className='mt-8'>
    <Typography variant='h4'>
      {intl.get('PEOPLE.RESOURCE_SUMMARY.TASKS_SUMMARY_TABLE.TITLE')}
    </Typography>
    <Typography variant='body2' className='text-neutral-dark mt-1'>
      {intl.get('PEOPLE.RESOURCE_SUMMARY.TASKS_SUMMARY_TABLE.SUB_TITLE')}
    </Typography>
    <Table
      data-cy='tasks-summary-table'
      className='mt-4'
      emptyComponent={
        <Typography className='h-12 flex justify-center items-center text-neutral'>
          {intl.get('PEOPLE.RESOURCE_SUMMARY.TASKS_SUMMARY_TABLE.EMPTY_TABLE')}
        </Typography>
      }
      style={{ borderSpacing: 0 }}
      canSelectRows={false}
      bodyProps={{
        className: classNames('max-h-resource-summary overflow-y-auto', {
          block: data.items.length > 0,
        }),
      }}
      data={{
        headData: {
          rowProps: {
            className: 'table w-full table-fixed',
          },
          headCells: [
            {
              content: intl.get(
                'PEOPLE.RESOURCE_SUMMARY.TASKS_SUMMARY_TABLE.TASK_TYPE'
              ),
            },
            {
              content: intl.get(
                'PEOPLE.RESOURCE_SUMMARY.TASKS_SUMMARY_TABLE.NUMBER_OF_TASKS'
              ),
              className: 'text-right',
            },
            {
              content: intl.get(
                'PEOPLE.RESOURCE_SUMMARY.RESOURCE_SUMMARY_TABLE.NO_OF_RESOURCE_COLUMN'
              ),
              className: 'text-right',
            },
            {
              content: intl.get(
                'PEOPLE.RESOURCE_SUMMARY.TASKS_SUMMARY_TABLE.TOTAL_ESTIMATED_HOURS'
              ),
              className: 'text-right',
            },
            {
              content: intl.get(
                'PEOPLE.RESOURCE_SUMMARY.TASKS_SUMMARY_TABLE.TOTAL_ACTUAL_HOURS'
              ),
              className: 'text-right',
            },
          ],
          className: 'sticky top-0 z-1',
        },
        rows:
          data.items.length > 0
            ? [
                ...data.items.map((summaryItem: TasksSummaryItem) => {
                  return {
                    id: summaryItem.taskType,
                    className: 'table w-full table-fixed',
                    cells: [
                      { content: summaryItem.taskType },
                      {
                        content: summaryItem.numberOfTasks,
                        className: 'text-right',
                      },
                      {
                        content: summaryItem.numberOfResources,
                        className: 'text-right',
                      },
                      {
                        content: `${intl.get(
                          'PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION_TIME',
                          { allocation: summaryItem.totalEstimatedHours }
                        )}`,
                        className: 'text-right',
                      },
                      {
                        content: `${intl.get(
                          'PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION_TIME',
                          { allocation: summaryItem.totalActualHours }
                        )}`,
                        className: 'text-right',
                      },
                    ],
                  };
                }),
                {
                  id: 'summary-total',
                  className:
                    'sticky bottom-0 bg-secondary-lightest-two hover:bg-secondary-lightest-two table w-full table-fixed',
                  cells: [
                    {
                      content: intl.get(
                        'PEOPLE.RESOURCE_SUMMARY.RESOURCE_SUMMARY_TABLE.TOTAL'
                      ),
                      className:
                        'font-bold group-hover:border-neutral-lightest group-hover:shadow-cancel',
                    },
                    {
                      content: data.total.totalNumberOfTasks,
                      className:
                        'font-semibold group-hover:border-neutral-lightest text-right group-hover:shadow-cancel',
                    },
                    {
                      content: data.total.totalNumberOfResources,
                      className:
                        'font-semibold group-hover:border-neutral-lightest text-right group-hover:shadow-cancel',
                    },
                    {
                      content: intl.get(
                        'PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION_TIME',
                        { allocation: data.total.totalEstimatedHours }
                      ),
                      className:
                        'font-semibold group-hover:border-neutral-lightest text-right group-hover:shadow-cancel',
                    },
                    {
                      content: intl.get(
                        'PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION_TIME',
                        { allocation: data.total.totalActualHours }
                      ),
                      className:
                        'font-semibold group-hover:border-neutral-lightest text-right group-hover:shadow-cancel',
                    },
                  ],
                },
              ]
            : [],
      }}
    />
  </div>
);

export default TasksSummaryTable;
