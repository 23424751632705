import { tailwindOverride } from '@getsynapse/design-system';
import DefaultFileContent from 'assets/images/default-file-content.png';
import AudioFileContent from 'assets/images/audio-preview.svg';
import VideoFileContent from 'assets/images/play-circle.svg';
import { FILESTACK_FILE_TYPES } from 'utils/constants';

export const getThumbnailBackgroundImage = (
  contentType: string,
  fileUrl: string
) => {
  let backgroundImage = '';
  switch (contentType) {
    case FILESTACK_FILE_TYPES.JPG:
    case FILESTACK_FILE_TYPES.PNG:
      backgroundImage = fileUrl;
      break;
    case FILESTACK_FILE_TYPES.MP4:
    case FILESTACK_FILE_TYPES.MPEG:
      backgroundImage = VideoFileContent;
      break;
    case FILESTACK_FILE_TYPES.MP3:
      backgroundImage = AudioFileContent;
      break;
    default:
      backgroundImage = DefaultFileContent;
      break;
  }
  return backgroundImage;
};

const Thumbnail: React.FC<{
  evidenceId?: string;
  contentType: string;
  fileUrl: string;
}> = ({ contentType, evidenceId, fileUrl }) => {
  const backgroundImageUrl = getThumbnailBackgroundImage(contentType, fileUrl);

  return (
    <div className='w-14 h-10 relative'>
      <div
        data-testid={`thumbnail-hover-shadow-${evidenceId}`}
        className={tailwindOverride(
          'absolute top-0 left-0 w-full h-10 justify-end z-20',
          'hidden bg-neutral-black group-hover:bg-opacity-70'
        )}
      ></div>
      <div
        className='absolute top-0 left-0 w-full h-full'
        data-testid={`thumbnail-image-${evidenceId}`}
        style={{
          backgroundImage: `url("${backgroundImageUrl}")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%',
        }}
      />
    </div>
  );
};

export default Thumbnail;
