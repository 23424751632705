import { useContext } from 'react';
import { ScrollHandlerContext } from './ScrollHandlerContext';

const useScrollHandlerContext = () => {
  const context = useContext(ScrollHandlerContext);
  if (!context) {
    throw new Error(
      'useScrollHandlerContext must be used within a ScrollHandlerContext'
    );
  }
  return context;
};

export default useScrollHandlerContext;
