import { useEffect, useMemo, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { tailwindOverride } from '@getsynapse/design-system';
import {
  getCapacity,
  selectCapacity,
  selectCapacityStatus,
} from 'state/Capacity/capacitySlice';
import { formatResourceAllocationSections } from './helpers';
import { ResourceAllocationSectionsType } from 'utils/customTypes';
import SectionToggle from 'Organisms/CapacityAllocationTable/components/SectionToggle';
import { getWeeksArray, Week } from 'Organisms/CapacityAllocationTable/helpers';
import TeamCapacityHeader from './components/TeamCapacityHeader';
import TeamMemberRow from './components/TeamMemberRow';
import TableLegends from './components/TableLegends';
import { SLICE_STATUS } from 'utils/constants';
import SkeletonLoading from 'Organisms/CapacityAllocationTable/components/SkeletonLoading';
import { selectUserId } from 'state/User/userSlice';

const CapacityPage = () => {
  const dispatch = useDispatch();
  const numberOfWeeks = 7;
  const currentUserId = useSelector(selectUserId);
  const capacity = useSelector(selectCapacity);
  const capacitySliceStatus = useSelector(selectCapacityStatus);
  const {
    allocationData: formattedSections,
    startDate,
    endDate,
  } = useMemo(() => formatResourceAllocationSections(capacity), [capacity]);

  const [currentWeeksSlide, setCurrentWeeksSlide] = useState(1);
  const [openSections, setOpenSections] = useState<string[]>([]);

  const generateCompleteWeekSets = (allWeeks: Week[]) => {
    let firstRemaining, secondRemaining;
    const currentWeekIndex = allWeeks.findIndex((week) => week.isCurrentWeek);
    const firstPart = allWeeks.slice(0, currentWeekIndex);
    const secondPart = allWeeks.slice(
      currentWeekIndex - 1,
      allWeeks.length - 1
    );

    firstRemaining =
      Math.ceil(firstPart.length / numberOfWeeks) * numberOfWeeks;
    firstRemaining = firstRemaining - (firstRemaining % numberOfWeeks);

    secondRemaining =
      Math.ceil(secondPart.length / numberOfWeeks) * numberOfWeeks;
    secondRemaining = secondRemaining - (secondRemaining % numberOfWeeks);

    const firstPartFiller = new Array(firstRemaining - firstPart.length).fill(
      null
    );
    const secondPartFiller = new Array(
      secondRemaining - secondPart.length
    ).fill(null);
    return [firstPartFiller, secondPartFiller];
  };

  const weeksArray = useMemo(() => {
    if (!isEmpty(formattedSections)) {
      const allWeeks = getWeeksArray(startDate, endDate, numberOfWeeks);

      const weekFillers = generateCompleteWeekSets(allWeeks);

      return [...weekFillers[0], ...allWeeks, ...weekFillers[1]];
    } else {
      return [];
    }
  }, [endDate, formattedSections, startDate]);

  const weeksSlidesNumber = Math.ceil(weeksArray.length / numberOfWeeks);

  const currentWeeksSlideArray = useMemo(() => {
    const startIndex = (currentWeeksSlide - 1) * numberOfWeeks;
    const endIndex = startIndex + numberOfWeeks;
    return weeksArray.slice(startIndex, endIndex);
  }, [numberOfWeeks, currentWeeksSlide, weeksArray]);

  const handleToggleSection = (sectionId: string) => {
    let updatedSections = [];
    const sectionIndex = openSections.findIndex((value) => value === sectionId);
    if (sectionIndex !== -1) {
      updatedSections = [
        ...openSections.slice(0, sectionIndex),
        ...openSections.slice(sectionIndex + 1),
      ];
    } else {
      updatedSections = openSections.concat(sectionId);
    }
    setOpenSections(updatedSections);
  };

  useEffect(() => {
    dispatch(getCapacity());
  }, [dispatch]);

  useEffect(() => {
    const currentWeekIndex = weeksArray.findIndex((week) =>
      get(week, 'isCurrentWeek', false)
    );
    if (currentWeekIndex !== -1) {
      setCurrentWeeksSlide(currentWeekIndex / numberOfWeeks + 1);
    }
  }, [weeksArray]);

  useEffect(() => {
    const sections: string[] = [];
    formattedSections.forEach((section) => {
      const managerIds = section.users
        .filter((user) => user.user.isTeamManager)
        .map((user) => user.id);
      if (managerIds.includes(currentUserId!)) {
        sections.push(section.id);
      }
    });

    setOpenSections(sections);
  }, [currentUserId, formattedSections]);

  return (
    <Fragment>
      <TableLegends />
      {capacitySliceStatus === SLICE_STATUS.LOADING && <SkeletonLoading />}
      {capacitySliceStatus === SLICE_STATUS.IDLE && (
        <table
          className='w-full h-full table-fixed shadow-allocation-table'
          cellPadding={0}
          cellSpacing={0}
          data-cy='capacity-table'
        >
          <TeamCapacityHeader
            currentWeeksSlideArray={currentWeeksSlideArray}
            weeksSlidesNumber={weeksSlidesNumber}
            setCurrentWeeksSlide={setCurrentWeeksSlide}
            currentWeeksSlide={currentWeeksSlide}
          />

          <tbody>
            {formattedSections.map(
              (section: ResourceAllocationSectionsType) => {
                const isSectionOpen = openSections.includes(section.id);
                return (
                  <Fragment key={section.id}>
                    <tr className='h-10 shadow-header border-b border-neutral-lighter'>
                      <td colSpan={2} className='h-full'>
                        <SectionToggle
                          label={section.label}
                          onToggleSection={() =>
                            handleToggleSection(section.id)
                          }
                          numberOfWeeks={numberOfWeeks}
                          currentWeeksSlideArray={currentWeeksSlideArray}
                          isSectionOpen={isSectionOpen}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <div
                          className={tailwindOverride(
                            'transition-height duration-300 easy',
                            {
                              'h-auto overflow-visible': isSectionOpen,
                              'h-0 overflow-hidden': !isSectionOpen,
                            }
                          )}
                        >
                          {section.users.map((userData, index: number) => (
                            <TeamMemberRow
                              key={userData.id}
                              userData={userData}
                              index={index}
                              numberOfWeeks={numberOfWeeks}
                              currentWeeksSlideArray={currentWeeksSlideArray}
                            />
                          ))}
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                );
              }
            )}
          </tbody>
        </table>
      )}
    </Fragment>
  );
};

export default CapacityPage;
