import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { RootState } from 'state/store';
import {
  selectTeamMemberProjects,
  selectCanFetchMoreTeamMemberProjects,
  fetchTeamMemberProjectsList,
} from 'state/Capacities/TeamMemberProjectsList/teamMemberProjectsListSlice';
import useScrollHandlerContext from '../../../ScrollHandler/useScrollHandlerContext';
import useRowsInView from '../../../../hooks/useRowsInView/useRowsInView';
import ProjectRow from '../ProjectRow/ProjectRow';
import LastProjectRow from '../LastProjectRow/LastProjectRow';

const ProjectsList: React.FC<{
  teamId: string;
  memberId: string;
}> = ({ teamId, memberId }) => {
  const { fetchTeamMembeProjectsCapacity } = useScrollHandlerContext();
  const dispatch = useDispatch();
  const projectsListIds = useSelector((state: RootState) =>
    selectTeamMemberProjects(state, teamId, memberId)
  );
  const canFetchMoreProjects = useSelector((state: RootState) =>
    selectCanFetchMoreTeamMemberProjects(state, teamId, memberId)
  );
  const onVisibleProjectsChangeHandler = useCallback(
    (projectsIds: string[]) =>
      fetchTeamMembeProjectsCapacity(teamId, memberId, projectsIds),
    [fetchTeamMembeProjectsCapacity, teamId, memberId]
  );
  const handleFetchMoreTeamMemberProjects = () => {
    dispatch(fetchTeamMemberProjectsList({ teamId, memberId }));
  };
  const debouncedFetchMoreTeamsHandler = debounce(
    (shouldFetchMoreProjects: boolean) => {
      if (shouldFetchMoreProjects) {
        handleFetchMoreTeamMemberProjects();
      }
    },
    500
  );
  const { updateRowInView } = useRowsInView(onVisibleProjectsChangeHandler);
  return (
    <React.Fragment>
      {projectsListIds.map((projectId: string) => (
        <ProjectRow
          key={`team-${teamId}__member-${memberId}__project-${projectId}`}
          teamId={teamId}
          memberId={memberId}
          projectId={projectId}
          updateProjectIsInView={updateRowInView}
        />
      ))}
      {canFetchMoreProjects && (
        <LastProjectRow
          teamId={teamId}
          memberId={memberId}
          onLastProjectRowInView={debouncedFetchMoreTeamsHandler}
        />
      )}
    </React.Fragment>
  );
};

export default ProjectsList;
