import React from 'react';
import { useSelector } from 'react-redux';
import { CapacityPerWeek } from 'types/store/weeklyTeamCapacity';
import { RootState } from 'state/store';
import { selectWeeklyProjectCapacityByProjectId } from 'state/WeeklyTeamCapacity/TeamMembersProjectCapacity/teamMemberProjectsCapacitySlice';
import NoWeeklyCapacity from '../../NoWeeklyCapacity/NoWeeklyCapacity';
import WeeksGrid from '../../WeeksGrid/WeeksGrid';
import TeamMemberProjectsWeeklyCapacity from '../TeamMemberProjectsWeeklyCapacity/TeamMemberProjectsWeeklyCapacity';

interface TeamMemberProjectsWeeklyCapacitiesProps {
  teamId: string;
  memberId: string;
  projectId: string;
}

export interface ProjectCapacityPerWeek extends CapacityPerWeek {
  projectId: string;
}

const TeamMemberProjectsWeeklyCapacities: React.FC<
  TeamMemberProjectsWeeklyCapacitiesProps
> = ({ teamId, memberId, projectId }) => {
  const weeklyProjectCapacity = useSelector((state: RootState) =>
    selectWeeklyProjectCapacityByProjectId(state, teamId, memberId, projectId)
  ) as { [week: string]: ProjectCapacityPerWeek | undefined };

  if (Object.keys(weeklyProjectCapacity).length === 0) {
    return (
      <div className='w-4/6 h-full flex'>
        <NoWeeklyCapacity
          id={`team-${teamId}__member-${memberId}__project-${projectId}`}
          isLoading
        />
      </div>
    );
  }

  return (
    <div className='w-4/6 h-full flex'>
      <WeeksGrid<ProjectCapacityPerWeek>
        id={`team-${teamId}__member-${memberId}__project-${projectId}`}
        component={TeamMemberProjectsWeeklyCapacity}
        items={weeklyProjectCapacity}
      />
    </div>
  );
};

export default TeamMemberProjectsWeeklyCapacities;
