import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { tailwindOverride } from '@getsynapse/design-system';
import { DailyProjectCapacityProps } from 'utils/types/dailyTeamsCapacity';
import { RootState } from 'state/store';
import { selectProjectStatus } from 'state/Capacities/TeamMemberProjectsList/teamMemberProjectsListSlice';
import ProjectHours from '../../../../CapacityTable/components/ProjectHours/ProjectHours';

const DailyProjectCapacity: React.FC<DailyProjectCapacityProps> = ({
  projectId,
  assignedHours,
}) => {
  const [displayLoader, setDisplayLoader] = useState<boolean>(true);
  const projectStatus = useSelector((state: RootState) =>
    selectProjectStatus(state, projectId)
  );
  useEffect(() => {
    setTimeout(() => {
      setDisplayLoader(false);
    }, 1000);
  }, []);
  if (!projectStatus) {
    return null;
  }

  return (
    <div className={tailwindOverride('w-full h-full', 'pt-4 px-2')}>
      {assignedHours > 0 && (
        <ProjectHours
          id={projectId}
          status={projectStatus}
          hours={assignedHours}
        />
      )}
      {assignedHours === 0 && displayLoader && (
        <div className='w-full h-4 rounded-sm bg-primary-lighter' />
      )}
    </div>
  );
};

export default DailyProjectCapacity;
