import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { resetProject } from 'state/Project/projectSlice';
import { resetSidePanelUpdatedData } from 'state/SidePanel/sidePanelSlice';

const ProjectDetailsUnloader: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetProject());
      dispatch(resetSidePanelUpdatedData());
    };
  }, [dispatch]);

  return <>{children}</>;
};

export default ProjectDetailsUnloader;
