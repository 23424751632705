import { EvaluationPlanItem } from 'utils/types/program';
import isEqual from 'lodash/isEqual';

export const getItemsToCreateUpdateDelete = (
  evaluationPlanData: EvaluationPlanItem[],
  currentEvaluationPlanData: EvaluationPlanItem[]
) => {
  const originalMethodsIdsList = evaluationPlanData.map(
    (method) => method.data_collection_method_id
  );
  const updatedMethodsIdsList = currentEvaluationPlanData.map(
    (method) => method.data_collection_method_id
  );
  const collectionMethodToBeCreated = updatedMethodsIdsList.filter(
    (methodKey) => !originalMethodsIdsList.includes(methodKey)
  );
  const collectionMethodToBeUpdated = updatedMethodsIdsList.filter(
    (methodKey) => {
      const baseData = evaluationPlanData.find(
        (method) => method.data_collection_method_id === methodKey
      );
      const newData = currentEvaluationPlanData.find(
        (method) => method.data_collection_method_id === methodKey
      );

      if (baseData && newData) {
        return !isEqual(
          baseData.collection_method_selected_options,
          newData.collection_method_selected_options
        );
      }
      return false;
    }
  );
  const collectionMethodsToBeRemoved = originalMethodsIdsList.filter(
    (methodId) => !updatedMethodsIdsList.includes(methodId)
  );

  return {
    collectionMethodToBeCreated,
    collectionMethodToBeUpdated,
    collectionMethodsToBeRemoved,
  };
};
