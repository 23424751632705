import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { tailwindOverride } from '@getsynapse/design-system';
import { selectWeeksCarouselItems } from 'state/TeamsCapacity/teamsCapacitySlice';
import {
  CapacityCarousel,
  CapacityCarouselItem,
} from 'Pages/TeamsPage/views/TeamsCapacity/components/WeeklyCapacity/components/CapacityCarousel/CapacityCarousel';
import WeeklyCapacity from './WeeklyCapacity';
import { Week } from 'Organisms/CapacityAllocationTable/helpers';
import NoTasksAssignedBanner from './NoTasksAssignedBanner';
import { WeeksCarouselLoader } from 'Pages/TeamsPage/views/TeamsCapacity/components/WeeklyCapacity/components/CapacityTable/components/SkeletonLoader';

const ProjectCapacity: React.FC<{
  allocations: Record<string, { allocation: number }>;
  teamId: string;
  memberId: string;
  projectId: string;
  projectStatus: string;
  className?: string;
  hasTasks: boolean;
}> = ({
  teamId,
  memberId,
  allocations,
  projectId,
  projectStatus,
  className,
  hasTasks,
}) => {
  const weeksCaourselItems = useSelector(selectWeeksCarouselItems);
  return (
    <CapacityCarousel
      id={`team-${teamId}__member-${memberId}__project-${projectId}`}
      className={className}
      emptyComponent={!hasTasks ? <NoTasksAssignedBanner /> : null}
      loader={
        <WeeksCarouselLoader
          className={tailwindOverride(
            'w-full',
            'absolute top-0 left-0',
            className,
            'z-10'
          )}
        />
      }
    >
      {weeksCaourselItems.map((week: Week, index: number) => {
        const weekKey = moment(week.startWeekDate).format('YYYY-MM-DD');
        const allocation = allocations[weekKey]?.allocation || 0;
        return (
          <CapacityCarouselItem
            id={`team-${teamId}__member-${memberId}__project-${projectId}__weekly-capacity__week-${weekKey}`}
            key={`team-${teamId}__member-${memberId}__project-${projectId}__weekly-capacity__week-${weekKey}`}
            index={index}
            isCurrentWeek={week.isCurrentWeek || false}
            className='py-3 justify-self-stretch'
          >
            {allocation > 0 && hasTasks && (
              <WeeklyCapacity
                projectStatus={projectStatus}
                allocation={allocation}
              />
            )}
          </CapacityCarouselItem>
        );
      })}
    </CapacityCarousel>
  );
};

export default ProjectCapacity;
