import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { selectWeeks, selectDatesRange } from '../Weeks/weeksSlice';
import { DateSegment, getStartOfTheWeek, toDateString } from '../Weeks/helpers';
import { MembersByTeam } from 'utils/types/dailyTeamsCapacity';
import { fetchWeeklyCapacityForTeamMember } from 'api';
import { generateFetchPrevOrNextWeekCapacityURL } from '../TeamsCapacity/helpers';
import { resetWeeklyCapacityState } from '../TeamsCapacity/teamsCapacitySlice';

// 1:1 mapping but can change later when necessary
export type CapacityPerWeekEntryState = {
  startDate: string; // Date in str form
  endDate: string; // Date in str form
  assigned: string;
  timeOff: string;
  capacity: string;
};

export type CapacityPerWeekState = {
  [week: string]: CapacityPerWeekEntryState | undefined;
};

export type WeeklyTeamMemberCapacityState = {
  [teamId: string]: {
    weeklyCapacities: CapacityPerWeekState;
    teamIds: string[];
  };
};

export interface TeamMembersCapacityState {
  byId: WeeklyTeamMemberCapacityState;
  byTeamMember: MembersByTeam<string[]>;
  visibleMembersIds: string[];
  status: Status;
}

/* ============================= INITIAL STATE ============================== */
const initialState: TeamMembersCapacityState = {
  byId: {},
  byTeamMember: {},
  visibleMembersIds: [],
  status: SLICE_STATUS.IDLE,
};
/* ============================== ACTIONS =============================== */
const setVisibleTeamMembers = createAction<string[]>(
  'weeklyTeamMemberCapacities/SET_VISIBLE_TEAM_MEMBERS'
);

const setWeeklyTeamMemberCapacities = createAction<{
  teamId: string;
  memberId: string;
  weeklyCapacities: CapacityPerWeekState;
}>('weeklyTeamMemberCapacities/SET_TEAM_MEMBER_WEEEKLY_CAPACITIES');

const removeWeekFromWeeklyTeamMemberCapacities = createAction<{
  teamId: string;
  memberId: string;
  week: string;
}>(
  'weeklyTeamMemberCapacities/REMOVE_WEEK_FROM_TEAM_MEMBER_WEEEKLY_CAPACITIES'
);

export const removeTeamMembersCapacity = createAction<{
  teamId: string;
  memberIds: string[];
}>('weeklyTeamMemberCapacities/REMOVE_TEAM_MEMBERS_CAPACITY');

/* ============================== REDUX THUNK =============================== */
export const fetchWeeklyTeamMembersCapacity = createAsyncThunk(
  'weeklyTeamMemberCapacities/fetchWeeklyTeamMembersCapacity',
  async (
    params: { teamId: string; membersIds: string[] },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const { teamId, membersIds } = params;
      const state = getState() as RootState;
      const { startDate, endDate } = selectDatesRange(state);
      dispatch(setVisibleTeamMembers(membersIds));
      const visibleMembersIds =
        state.weeklyTeamsCapacity.teamMembersCapacity.byTeamMember[teamId] ||
        [];

      const membersIdsToFetch = membersIds.filter(
        (m) => !visibleMembersIds.includes(m)
      );

      for (const memberId of membersIdsToFetch) {
        fetchWeeklyCapacityForTeamMember({
          teamId,
          teamMemberId: memberId,
          startDate,
          endDate,
        }).then((response) => {
          const weeklyCapacities = response.weeklyCapacities;
          dispatch(
            setWeeklyTeamMemberCapacities({
              teamId,
              memberId,
              weeklyCapacities,
            })
          );
        });
      }
      const membersIdsToRemove = visibleMembersIds.filter(
        (m) => !membersIds.includes(m)
      );

      dispatch(
        removeTeamMembersCapacity({
          teamId,
          memberIds: membersIdsToRemove,
        })
      );
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const fetchMoreWeeklyTeamMembersCapacity = createAsyncThunk(
  'weeklyTeamMemberCapacities/fetchMoreWeeklyTeamMembersCapacity',
  async (
    params: { startDate: string; endDate: string; link: 'prev' | 'next' },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const state = getState() as RootState;
      const { link, startDate, endDate } = params;
      const visibleMembersIds =
        state.weeklyTeamsCapacity.teamMembersCapacity.visibleMembersIds;

      for (const memberId of visibleMembersIds) {
        const teamId =
          state.weeklyTeamsCapacity.teamMembersCapacity.byId[memberId]
            .teamIds[0];
        let newStartDate;
        if (link === 'next') {
          newStartDate = generateFetchPrevOrNextWeekCapacityURL(endDate, link);
        } else {
          newStartDate = generateFetchPrevOrNextWeekCapacityURL(
            startDate,
            link
          );
        }

        fetchWeeklyCapacityForTeamMember({
          teamId,
          teamMemberId: memberId,
          startDate: newStartDate,
        })
          .then((response) => {
            const weeklyCapacities = response.weeklyCapacities;
            dispatch(
              setWeeklyTeamMemberCapacities({
                teamId,
                memberId,
                weeklyCapacities,
              })
            );
          })
          .then(() => {
            let week = '';
            if (link === 'prev') {
              week = endDate;
            } else {
              week = startDate;
            }
            dispatch(
              removeWeekFromWeeklyTeamMemberCapacities({
                teamId,
                memberId,
                week,
              })
            );
          });
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* =============================== SELECTORS ================================ */
export const selectCapacityByMemberId = (
  state: RootState,
  memberId: string
) => {
  const byId = state.weeklyTeamsCapacity.teamMembersCapacity.byId;
  if (memberId in byId) {
    return byId[memberId]?.weeklyCapacities;
  }
};

const selectVisibleMembersIds = (state: RootState) =>
  state.weeklyTeamsCapacity.teamMembersCapacity.visibleMembersIds;

export const isMemberVisible = createSelector(
  [selectVisibleMembersIds, (state: RootState, memberId: string) => memberId],
  (visibleMembersIds: string[], memberId: string) => {
    return visibleMembersIds.includes(memberId);
  }
);

export const selectWeeklyCapacityByMemberId = createSelector(
  [
    (state: RootState, memberId: string) =>
      selectCapacityByMemberId(state, memberId),
    selectWeeks,
  ],
  (memberCapacity: CapacityPerWeekState | undefined, weeks: DateSegment[]) => {
    if (!memberCapacity || !weeks) {
      return {};
    }
    return weeks.reduce<{
      [week: string]: CapacityPerWeekEntryState | undefined;
    }>((acc, current: DateSegment) => {
      const [startDate] = current;
      const entry = memberCapacity[startDate];
      acc[startDate] = entry;
      return acc;
    }, {});
  }
);

/* ============================== REDUCER =============================== */
const weeklyTeamMembersCapacitySlice = createSlice({
  name: 'weeklyTeamMembersCapacity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setVisibleTeamMembers, (state, action) => {
      const visibleMembersIds = action.payload;
      const updatedVisibleMembersIdsState = [];
      for (const memberId of visibleMembersIds) {
        if (!state.visibleMembersIds.includes(memberId)) {
          updatedVisibleMembersIdsState.push(memberId);
        }
      }
      state.visibleMembersIds = state.visibleMembersIds.concat(
        updatedVisibleMembersIdsState
      );
    });

    builder.addCase(setWeeklyTeamMemberCapacities, (state, action) => {
      const { teamId, memberId, weeklyCapacities } = action.payload;
      let currentTeamWeeklyCapacities = {};
      let currentMemberTeams = new Set<string>();

      if (memberId in state.byId) {
        currentTeamWeeklyCapacities = {
          ...state.byId[memberId].weeklyCapacities,
        };
        currentMemberTeams = new Set(state.byId[memberId].teamIds);
      }
      currentTeamWeeklyCapacities = {
        ...currentTeamWeeklyCapacities,
        ...weeklyCapacities,
      };
      currentMemberTeams.add(teamId);

      state.byTeamMember[teamId] = Array.from(
        new Set(state.byTeamMember[teamId]).add(memberId)
      );

      state.byId = {
        ...state.byId,
        [memberId]: {
          ...state.byId[memberId],
          weeklyCapacities: {
            ...currentTeamWeeklyCapacities,
          },
          teamIds: Array.from(currentMemberTeams),
        },
      };
    });

    builder.addCase(
      removeWeekFromWeeklyTeamMemberCapacities,
      (state, action) => {
        const { memberId, week } = action.payload;
        const weeklyCapacities = { ...state.byId[memberId].weeklyCapacities };
        const startOfWeek = getStartOfTheWeek(new Date(week.replace('-', '/')));
        delete weeklyCapacities[toDateString(startOfWeek)];

        state.byId[memberId].weeklyCapacities = weeklyCapacities;
      }
    );

    builder.addCase(removeTeamMembersCapacity, (state, action) => {
      const { teamId, memberIds } = action.payload;
      const updatedByIdState = { ...state.byId };
      const updateByTeamMemberState = { ...state.byTeamMember };
      const membersToRemove: string[] = [];
      for (const memberId of memberIds) {
        if (memberId in state.byId) {
          const memberById = { ...state.byId[memberId] };
          const teamIds = memberById.teamIds.filter((id) => id !== teamId);
          if (teamIds.length === 0) {
            membersToRemove.push(memberId);
            delete updatedByIdState[memberId];
          } else {
            updatedByIdState[memberId] = {
              ...memberById,
              teamIds,
            };
          }
        }
      }
      updateByTeamMemberState[teamId] = [
        ...(updateByTeamMemberState[teamId] || []),
      ].filter((id) => !memberIds.includes(id));
      if (updateByTeamMemberState[teamId].length === 0) {
        delete updateByTeamMemberState[teamId];
      }
      state.visibleMembersIds = state.visibleMembersIds.filter(
        (id) => !membersToRemove.includes(id)
      );
      state.byId = updatedByIdState;
      state.byTeamMember = updateByTeamMemberState;
    });

    builder.addCase(resetWeeklyCapacityState, (state) => {
      state.byId = initialState.byId;
      state.byTeamMember = initialState.byTeamMember;
      state.visibleMembersIds = initialState.visibleMembersIds;
    });
  },
});

export default weeklyTeamMembersCapacitySlice.reducer;
