import { FC } from 'react';
import intl from 'react-intl-universal';
import {
  tailwindOverride,
  Avatar,
  AvatarGroup,
} from '@getsynapse/design-system';
import { TaskAssignee } from 'types/store/tasks';
import { isUserStatusDisabled } from 'utils/functions';

interface AssigneesColumnProps {
  assignees: TaskAssignee[];
}

const AssigneesColumn: FC<AssigneesColumnProps> = ({ assignees }) => {
  if (assignees.length === 0) {
    return (
      <div className='flex items-center'>
        <span className='text-neutral-dark'>
          {intl.get('TASKS.TABLE.NO_ASSIGNEE')}
        </span>
      </div>
    );
  }

  if (assignees.length === 1) {
    const fullName = assignees[0].name.split(' ');
    const isUserDisabled = isUserStatusDisabled(assignees[0].status);
    return (
      <div
        className={tailwindOverride('flex items-center gap-x-2', {
          'text-neutral-light': isUserDisabled,
        })}
      >
        <Avatar
          imageSrc={assignees[0].avatarUrl}
          initial={`${fullName[0].charAt(0)}${fullName[1].charAt(0)}`}
          name={assignees[0].name}
          disabled={isUserDisabled}
          showTooltip
        />
        {`${assignees[0].name}`}
      </div>
    );
  }

  return (
    <div className='flex items-center'>
      <AvatarGroup
        avatars={assignees.map((assignee: TaskAssignee) => {
          const fullName = assignee.name.split(' ');
          return {
            key: assignee.id,
            imageSrc: assignee.avatarUrl,
            initial: `${fullName[0].charAt(0)}${fullName[0].charAt(0)}`,
            name: assignee.name,
            disabled: isUserStatusDisabled(assignee.status),
          };
        })}
      />
    </div>
  );
};

export default AssigneesColumn;
