import { Modal, Typography } from '@getsynapse/design-system';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { showNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { deleteAdditionalBenefit } from 'state/AdditionalBenefit/additionalBenefitSlice';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'utils/constants';

type DeleteAdditionalBenefitModalProps = {
  isOpen: boolean;
  benefitId: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  programId: string;
  categoryId: string;
};

const DeleteAdditionalBenefitModal = ({
  isOpen,
  benefitId,
  setIsOpen,
  programId,
  categoryId,
}: DeleteAdditionalBenefitModalProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDelete = async () => {
    try {
      await dispatch(
        deleteAdditionalBenefit({ programId, categoryId, benefitId })
      );
      history.replace(
        `${PATHS.PROGRAM_PAGE}/${programId}/strategy/${categoryId}`
      );
      dispatch(
        showNotification({
          notificationVariant: 'success',
          notificationTitle: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.DELETE_BENEFIT_MODAL.SUCCESS_NOTIFICATION'
          ),
          autoHide: false,
        })
      );
      closeModal();
    } catch (error) {
      dispatch(
        showNotification({
          notificationVariant: 'error',
          notificationTitle: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.DELETE_BENEFIT_MODAL.ERROR_NOTIFICATION_TITLE'
          ),
          notificationMessage: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.DELETE_BENEFIT_MODAL.ERROR_NOTIFICATION_MESSAGE'
          ),
        })
      );
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      aria-label='delete-additional-benefit_modal'
      data-testid='delete-additional-benefit_modal'
      title={intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.DELETE_BENEFIT_MODAL.TITLE'
      )}
      titleIcon={{
        name: 'trash',
        className: 'text-error-dark',
      }}
      size='medium'
      actionButtons={[
        {
          children: intl.get('YES_DELETE'),
          variant: 'primary',
          color: 'danger',
          onClick: handleDelete,
          'data-testid': 'delete-additional-benefit_confirm',
        },
        {
          children: intl.get('CANCEL'),
          variant: 'tertiary',
          onClick: closeModal,
          'data-testid': 'delete-additional-benefit_cancel',
        },
      ]}
    >
      <Typography>
        {intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.DELETE_BENEFIT_MODAL.MESSAGE'
        )}
      </Typography>
    </Modal>
  );
};

export default DeleteAdditionalBenefitModal;
