import { useDispatch, useSelector } from 'react-redux';
import Card from '../Card';
import emptyProjectsImg from 'assets/images/empty-due-projects-img.svg';
import dueProjectsIcon from 'assets/icons/due-tasks-icon.svg';
import intl from 'react-intl-universal';
import { useState } from 'react';
import {
  selectDueProjectsForCard,
  selectDueProjectsStatus,
  setDueProjectsOrderForCard,
} from 'state/MyWorkspace/myWorkspaceSlice';
import ProjectsTable from './ProjectsTable';
import ProjectsModal from './ProjectsModal';
import { SLICE_STATUS } from 'utils/constants';
import ProjectsTableLoader from './ProjectsTableLoader';

const ProjectsCard = () => {
  const dispatch = useDispatch();
  const projectsSelector = useSelector(selectDueProjectsForCard);
  const projectsStatus = useSelector(selectDueProjectsStatus);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSort = (orderByParam: string[], order: 'desc' | 'asc') => {
    dispatch(setDueProjectsOrderForCard({ order, orderBy: orderByParam }));
  };

  return (
    <>
      <ProjectsModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        icon={dueProjectsIcon}
      />
      <Card
        title={intl.get('MY_WORKSPACE.DUE_PROJECTS.TITLE')}
        iconSrc={dueProjectsIcon}
        emptyStateImg={emptyProjectsImg}
        emptyStateText={intl.get('MY_WORKSPACE.DUE_PROJECTS.EMPTY_STATE_TEXT')}
        content={
          projectsStatus === SLICE_STATUS.LOADING ? (
            <ProjectsTableLoader />
          ) : projectsSelector.length ||
            projectsStatus === SLICE_STATUS.FAILED ? (
            <ProjectsTable
              data={projectsSelector}
              handleSort={handleSort}
              cardTable
            />
          ) : null
        }
        showViewAllButton={projectsSelector.length ? true : false}
        viewAllTooltipText={intl.get('MY_WORKSPACE.DUE_PROJECTS.TOOLTIP_TEXT')}
        viewAllOnClick={() => setIsModalOpen(true)}
        dataCy='due-projects'
      />
    </>
  );
};

export default ProjectsCard;
