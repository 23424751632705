import React, {
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import {
  FormItem,
  TextField,
  UsersPicker,
  Typography,
  Datepicker,
  Dropdown,
} from '@getsynapse/design-system';
import {
  NewProject,
  Option,
  UserOption,
  objKeyAsString,
} from 'utils/customTypes';
import { getInitialValueForDropDown } from 'utils/functions';
import { ProjectTemplate } from 'utils/types/templates';
import {
  NEW_PROJECT_FORM_FIELDS,
  PROJECT_PRIVACY,
  LONG_INPUTS_LENGTH,
} from 'utils/constants';
import { selectAllTemplates } from 'state/ProjectTemplates/projectTemplatesSlice';
import { selectEnabledProjectProcesses } from 'state/Processes/processesSlice';
import { selectProjectCategories } from 'state/Organization/organizationSlice';
import { selectLearningTeams } from 'state/LearningTeams/learningTeamsSlice';
import { selectLDUsersForDropdown } from 'state/UsersManagement/usersManagementSlice';
import { MultipleOptionLI } from '../../NewProjectPage/helpers/snippets';
import ProjectCategoryPicker from 'Molecules/ProjectCategoryPicker/ProjectCategoryPicker';
import ProcessPicker from 'Molecules/ProcessPicker/ProcessPicker';
import {
  fetchTaskBundles,
  selectAllTaskBundles,
} from 'state/TasksBundle/tasksBundleSlice';

const ProjectCreationForm: React.FC<{
  updateValue: (newValue: objKeyAsString) => void;
  setLearningTeamIds: Dispatch<SetStateAction<string[]>>;
  data: NewProject;
  description: string;
}> = ({ updateValue, setLearningTeamIds, data, description }) => {
  const dispatch = useDispatch();
  const learningTeams = useSelector(selectLearningTeams);
  const projectCategories = useSelector(selectProjectCategories);
  const projectProcesses = useSelector(selectEnabledProjectProcesses);
  const ldUsers = useSelector(selectLDUsersForDropdown);
  const projectTemplates = useSelector(selectAllTemplates);
  const taskBundles = useSelector(selectAllTaskBundles);

  useEffect(() => {
    dispatch(fetchTaskBundles());
  }, [dispatch]);

  const privacyOptions = useMemo(
    () =>
      Object.keys(PROJECT_PRIVACY).map((key) => ({
        label: intl.get(`PROJECT_DETAIL.PRIVACY_OPTIONS.${key}`),
        value: PROJECT_PRIVACY[key],
      })),
    []
  );

  const teamOptions = useMemo(
    () =>
      learningTeams.map((team) => ({
        label: team.name,
        value: team.id,
      })),
    [learningTeams]
  );

  const ownersList = useMemo(
    () => ldUsers.filter((ldUser) => data.owners?.includes(ldUser.value)),
    [ldUsers, data]
  );

  const handlePickOwners = (owners: UserOption[]) => {
    const ownersIds = owners.map((owner) => owner.value);
    updateValue({ owners: ownersIds });
  };

  const [privacyMode, setPrivacyMode] = useState('');
  const getPrivacyHelperText = () => {
    switch (privacyMode) {
      case 'team':
        return intl.get('PROJECT_DETAIL.PRIVACY_TEAM_MESSAGE');
      case 'private':
        return intl.get('PROJECT_DETAIL.PRIVACY_PRIVATE_MESSAGE');
      default:
        return '';
    }
  };

  const toSnakeCase = (inputString: string) => {
    return inputString.replace(/\s+/g, '_').toLowerCase();
  };

  const taskBundleOptions = useMemo(
    () =>
      taskBundles.map((taskBundle) => ({
        label: taskBundle.name,
        value: taskBundle.id,
      })),
    [taskBundles]
  );

  return (
    <div className='flex flex-col'>
      <Typography
        variant='body'
        weight='regular'
        className='text-neutral-black'
      >
        {description}
      </Typography>
      {projectTemplates.length > 1 &&
        description !== intl.get('DUPLICATE_PROJECT_MODAL.DESCRIPTION') && (
          <>
            <FormItem
              label={intl.get('PROJECT_DETAIL.TEMPLATES')}
              labelProps={{
                required: true,
                className: 'pt-4',
              }}
              className='w-3/6'
            >
              <Dropdown
                placeholder={intl.get('PROJECT_DETAIL.TEMPLATES_PLACEHOLDER')}
                onChange={(value) =>
                  updateValue({ [NEW_PROJECT_FORM_FIELDS.TEMPLATE]: value.id })
                }
                options={projectTemplates}
                triggerProps={{
                  'data-cy': 'project-template_dropdown',
                }}
                getOptionLabel={(option: ProjectTemplate) => option.name}
                renderOption={(
                  option: ProjectTemplate,
                  isSelected: boolean,
                  selectOption,
                  { className, ...otherProps }
                ) => (
                  <li
                    {...otherProps}
                    key={option.id}
                    data-cy={`project-template_${toSnakeCase(option.name)}`}
                    className={classnames('group', className, {
                      'hover:bg-primary focus-visible:bg-primary': isSelected,
                    })}
                    onClick={selectOption}
                  >
                    <Typography
                      weight='medium'
                      className={classnames('caption-sm', {
                        'group-hover:text-neutral-white': isSelected,
                        'group-focus-visible:text-neutral-white': isSelected,
                      })}
                    >
                      {option.name}
                    </Typography>
                    {option.description && (
                      <Typography
                        className={classnames('text-caption', {
                          'group-hover:text-neutral-white': isSelected,
                          'group-focus-visible:text-neutral-white': isSelected,
                          'text-neutral': !isSelected,
                        })}
                      >
                        {option.description}
                      </Typography>
                    )}
                  </li>
                )}
              />
            </FormItem>
            <div className='w-full border-t border-solid border-neutral-lighter my-7' />
          </>
        )}
      <div className='grid gap-y-4 gap-x-10% grid-cols-2 mt-4'>
        <FormItem>
          <TextField
            label={intl.get('PROJECT_DETAIL.PROJECT_NAME')}
            labelProps={{
              required: true,
            }}
            displayCharactersCount
            maxLength={LONG_INPUTS_LENGTH}
            value={data.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateValue({ [NEW_PROJECT_FORM_FIELDS.TITLE]: e.target.value })
            }
            placeholder={intl.get('PROJECT_DETAIL.PROJECT_NAME_PLACEHOLDER')}
            className='w-full'
            data-cy='project-input_name'
          />
        </FormItem>
        <FormItem
          label={intl.get('PROJECT_DETAIL.PROJECT_OWNERS')}
          labelProps={{
            required: true,
          }}
          className='text-primary-light font-medium'
        >
          <UsersPicker
            usersList={ldUsers}
            selectedUsersList={ownersList}
            onChange={handlePickOwners}
            maxLimit={6}
            required
          />
        </FormItem>
        <FormItem>
          <Datepicker
            data-cy='project-input_date'
            className='flex justify-evenly w-full'
            canSelectRange={true}
            size='large'
            onPickDate={(dates: any) =>
              updateValue({
                [NEW_PROJECT_FORM_FIELDS.START_DATE]: dates.startDate,
                [NEW_PROJECT_FORM_FIELDS.TARGET_COMPLETION_DATE]: dates.endDate,
              })
            }
            startDate={data.startDate && new Date(data.startDate)}
            startDateLabel={intl.get('PROJECT_DETAIL.START_DATE')}
            startDateLabelProps={{
              required: true,
            }}
            endDate={
              data.targetCompletionDate && new Date(data.targetCompletionDate)
            }
            endDateLabel={intl.get('PROJECT_DETAIL.END_DATE')}
            endDateLabelProps={{
              required: true,
            }}
          />
        </FormItem>
        <ProjectCategoryPicker
          categories={projectCategories}
          currentValue={get(data, `[${NEW_PROJECT_FORM_FIELDS.CATEGORY}]`)}
          formItemProps={{
            label: intl.get('PROJECT_DETAIL.CATEGORY'),
            labelProps: {
              required: true,
            },
          }}
          dropdownProps={{
            placeholder: intl.get('PROJECT_DETAIL.CATEGORY_PLACEHOLDER'),
            onChange: (option: Option) =>
              updateValue({ [NEW_PROJECT_FORM_FIELDS.CATEGORY]: option.value }),
            triggerProps: { 'data-cy': 'project-input_category' },
          }}
        />
        <ProcessPicker
          processes={projectProcesses}
          formItemProps={{
            label: intl.get('PROJECT_DETAIL.PROCESS'),
            labelProps: {
              required: true,
            },
          }}
          currentValue={get(data, `[${NEW_PROJECT_FORM_FIELDS.PROCESS}]`)}
          dropdownProps={{
            placeholder: intl.get('PROJECT_DETAIL.PROCESS_PLACEHOLDER'),
            onChange: (option: Option) =>
              updateValue({ [NEW_PROJECT_FORM_FIELDS.PROCESS]: option.value }),
            triggerProps: { 'data-cy': 'project-input_process' },
          }}
        />
        <FormItem
          label={intl.get('PROJECT_DETAIL.PRIVACY')}
          labelProps={{
            required: true,
          }}
          helpText={getPrivacyHelperText()}
        >
          <Dropdown
            placeholder={intl.get('PROJECT_DETAIL.PRIVACY_PLACEHOLDER')}
            onChange={(option: Option) => {
              updateValue({ [NEW_PROJECT_FORM_FIELDS.PRIVACY]: option.value });
              setPrivacyMode(option.value);
            }}
            options={privacyOptions}
            triggerProps={{ 'data-cy': 'project-input_privacy' }}
            values={getInitialValueForDropDown(
              privacyOptions,
              data[NEW_PROJECT_FORM_FIELDS.PRIVACY]
            )}
          />
        </FormItem>
        {privacyMode === PROJECT_PRIVACY.TEAM && (
          <FormItem
            label={intl.get('PROJECT_DETAIL.TEAM')}
            labelProps={{
              required: true,
            }}
          >
            <Dropdown
              multiple
              placeholder={intl.get('PROJECT_DETAIL.TEAM_PLACEHOLDER')}
              onChange={(options: Option[]) => {
                const ids = options.map((option) => option.value);
                setLearningTeamIds(ids);
              }}
              options={teamOptions}
              renderOption={(
                option: Option,
                isSelected: boolean,
                selectOption,
                { className, ...otherProps }
              ) => (
                <li
                  {...otherProps}
                  className={classnames('group', className, {
                    'hover:bg-primary focus-visible:bg-primary': isSelected,
                  })}
                >
                  <MultipleOptionLI
                    label={option.label}
                    isSelected={isSelected}
                    selectOption={selectOption}
                  />
                </li>
              )}
              triggerProps={{ 'data-cy': 'project-input_detail' }}
            />
          </FormItem>
        )}

        <div>
          <FormItem label={intl.get('NEW_PROJECT_PAGE.TASK_BUNDLE.TITLE')}>
            <Dropdown
              placeholder={intl.get('NEW_PROJECT_PAGE.TASK_BUNDLE.PLACEHOLDER')}
              onChange={(option: Option) =>
                updateValue({
                  [NEW_PROJECT_FORM_FIELDS.TASK_BUNDLE]: option.value,
                })
              }
              options={taskBundleOptions}
              triggerProps={{ 'data-cy': 'intake-task-bundle-dropdown' }}
            />
          </FormItem>
        </div>
      </div>
    </div>
  );
};

export default ProjectCreationForm;
