import React, { useMemo } from 'react';
import intl from 'react-intl-universal';
import { tailwindOverride, Typography } from '@getsynapse/design-system';
import { ProjectLevelAllocation } from 'utils/types/tasksAssignmentsResourcesCapacity';

type AllocatedHoursStyle = {
  text: string;
  background: string;
  boder: string;
  shadow: string;
};

const formatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
});

const AllocatedHours: React.FC<{
  allocation: ProjectLevelAllocation;
}> = ({ allocation }) => {
  const allocatedHoursStyle = useMemo<AllocatedHoursStyle>(() => {
    let style = {
      text: 'text-primary-darker',
      background: 'bg-primary-lighter-two',
      boder: 'border-primary-lighter',
      shadow: 'rgb(var(--color-neutral-default))',
    };
    if (allocation.allocation > 0) {
      style = {
        text: 'text-success-darkest',
        background: 'bg-success-lightest',
        boder: 'border-success-lighter',
        shadow: 'rgb(var(--color-success-dark))',
      };
    }
    return style;
  }, [allocation]);

  return (
    <div
      className={tailwindOverride(
        'w-full h-6 px-2',
        'flex items-center justify-center gap-x-2.5',
        'rounded-sm border',
        allocatedHoursStyle.background,
        allocatedHoursStyle.boder
      )}
      style={{
        boxShadow: `0px 2px 2px 0px #12151B0D, 2px 0px 0px 0px ${allocatedHoursStyle.shadow} inset`,
      }}
    >
      <Typography
        variant='label'
        className={tailwindOverride('font-semibold', allocatedHoursStyle.text)}
      >
        {intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION_TIME', {
          allocation: formatter.format(allocation.allocation),
        })}
      </Typography>
    </div>
  );
};

export default AllocatedHours;
