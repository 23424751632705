import {
  createAction,
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import {
  ProjectsByTeamMember,
  TeamMemberProjects,
} from 'utils/types/dailyTeamsCapacity';
import {
  addProjectToTeamMember,
  removeProjectsFromTeamMember,
  getProjectsIdsFromTeamMember,
  getProjectsIdsFromTeamMemberToFetch,
  getProjectsIdsFromTeamMemberToRemove,
} from 'state/DailyTeamsCapacity/TeamMemberProjectsCapacity/helpers';
import { generateTeamMemberId } from './helpers';
import { addIdsToArrayIfNotExists } from 'state/DailyTeamsCapacity/helpers';
import { fetchWeeklyCapacityForTeamMemberProject } from 'api';
import { RootState } from 'state/store';
import { toggleExpandTeam } from 'state/Capacities/TeamsList/teamsListSlice';
import {
  WeeklyTeamMemberProjectCapacity,
  WeeklyProjectCapacityPerWeekEntryState,
  WeeklyCapacitiesByUserKey,
  UserKey,
} from 'types/store/weeklyTeamMemberProjectCapacity';
import { WeeklyCapacityPerWeek } from 'types/store/weeklyTeamCapacity';
import { selectDatesRange, selectWeeks } from '../Weeks/weeksSlice';
import {
  addWeeklyCapacityToProject,
  removeTeamIdFromProjects,
} from './helpers';
import { FetchMoreWeeklyTeamsCapacityParams } from '../TeamsCapacity/teamsCapacitySlice';
import { generateFetchPrevOrNextWeekCapacityURL } from '../TeamsCapacity/helpers';
import { DateSegment, getStartOfTheWeek, toDateString } from '../Weeks/helpers';
import { resetWeeklyCapacityState } from '../TeamsCapacity/teamsCapacitySlice';

interface TeamMemberProjectsCapacityState {
  byTeamMember: ProjectsByTeamMember<Omit<TeamMemberProjects, 'links'>>;
  byId: WeeklyTeamMemberProjectCapacity;
}

/* ============================= INITIAL STATE ============================== */
const initialState: TeamMemberProjectsCapacityState = {
  byTeamMember: {},
  byId: {},
};

/* =============================== ACTIONS ================================ */

const setWeeklyProjectCapacity = createAction<{
  teamId: string;
  memberId: string;
  projectId: string;
  weeklyCapacities: WeeklyCapacityPerWeek;
}>('weeklyTeamMembersProjectsCapacity/SET_WEEKLY_PROJECT_CAPACITY');

export const removeTeamMemberProjects = createAction<{
  teamId: string;
  memberId: string;
}>('weeklyTeamMembersProjectsCapacity/REMOVE_TEAM_MEMBER_PROJECTS');

const removeWeekFromWeeklyProjectCapacities = createAction<{
  projectId: string;
  weekKey: string;
  userKey: UserKey;
}>(
  'weeklyTeamMembersProjectsCapacity/REMOVE_WEEK_FROM_WEEKLY_PROJECT_CAPACITIES'
);

/* ============================== REDUX THUNK =============================== */
export const fetchWeeklyTeamMemberProjectsCapacity = createAsyncThunk(
  'weeklyTeamMembersProjectsCapacity/fetchWeeklyTeamMemberProjectsCapacity',
  async (
    params: { teamId: string; memberId: string; projectsIds: string[] },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { teamId, memberId, projectsIds } = params;
      const state = getState() as RootState;
      const { startDate, endDate } = selectDatesRange(state);
      const projectsIdsFromTeamMember = getProjectsIdsFromTeamMember(
        teamId,
        memberId,
        state.weeklyTeamsCapacity.teamMemberProjectsCapacity.byTeamMember
      );
      const projectsIdsToFetch = getProjectsIdsFromTeamMemberToFetch(
        projectsIdsFromTeamMember,
        projectsIds
      );
      for (const projectId of projectsIdsToFetch) {
        fetchWeeklyCapacityForTeamMemberProject({
          teamId,
          memberId,
          projectId,
          startDate,
          endDate,
        }).then((response) => {
          const weeklyCapacities = response.weeklyCapacities;
          dispatch(
            setWeeklyProjectCapacity({
              teamId,
              memberId,
              projectId,
              weeklyCapacities,
            })
          );
        });
      }
      const projectsIdsToRemove = getProjectsIdsFromTeamMemberToRemove(
        projectsIdsFromTeamMember,
        projectsIds
      );
      return {
        teamId,
        memberId,
        projectsIdsToRemove,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMoreWeeklyTeamMemberProjectsCapacity = createAsyncThunk(
  'weeklyTeamMembersProjectsCapacity/fetchMoreWeeklyTeamMemberProjectsCapacity',
  async (
    params: FetchMoreWeeklyTeamsCapacityParams,
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const state = getState() as RootState;
      const { link, startDate, endDate } = params;
      const byTeamMember =
        state.weeklyTeamsCapacity.teamMemberProjectsCapacity.byTeamMember;
      Object.keys(byTeamMember).forEach((teamId) => {
        Object.keys(byTeamMember[teamId]).forEach((memberId) => {
          const projectsIds = byTeamMember[teamId][memberId]?.projectsIds || [];
          for (const projectId of projectsIds) {
            let startDateParam = '';
            if (link === 'next') {
              startDateParam = generateFetchPrevOrNextWeekCapacityURL(
                endDate,
                link
              );
            } else {
              startDateParam = generateFetchPrevOrNextWeekCapacityURL(
                startDate,
                link
              );
            }
            fetchWeeklyCapacityForTeamMemberProject({
              teamId,
              memberId,
              projectId,
              startDate: startDateParam,
            })
              .then((response) => {
                const weeklyCapacities = response.weeklyCapacities;
                dispatch(
                  setWeeklyProjectCapacity({
                    teamId,
                    memberId,
                    projectId,
                    weeklyCapacities,
                  })
                );
              })
              .then(() => {
                const userKey = generateTeamMemberId(teamId, memberId);
                let weekKey = '';
                if (link === 'prev') {
                  weekKey = endDate;
                } else {
                  weekKey = startDate;
                }
                dispatch(
                  removeWeekFromWeeklyProjectCapacities({
                    projectId,
                    weekKey,
                    userKey,
                  })
                );
              });
          }
        });
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* =============================== SELECTORS ================================ */
const selectWeeklyProjectCapacitiesPerWeek = (
  state: RootState,
  projectId: string
) => {
  const byId = state.weeklyTeamsCapacity.teamMemberProjectsCapacity.byId;
  if (projectId in byId) {
    return byId[projectId].weeklyCapacities;
  }
  return undefined;
};

const selectProjectId = (
  _: RootState,
  teamId: string,
  memberId: string,
  projectId: string
) => projectId;

const selectUserKey = (
  _: RootState,
  teamId: string,
  memberId: string,
  projectId: string
): UserKey => generateTeamMemberId(teamId, memberId);

export const selectWeeklyProjectCapacityByProjectId = createSelector(
  [
    (state: RootState, teamId: string, memberId: string, projectId: string) =>
      selectWeeklyProjectCapacitiesPerWeek(state, projectId),
    selectWeeks,
    selectProjectId,
    selectUserKey,
  ],
  (
    weeklyCapacitiesPerWeek: WeeklyCapacitiesByUserKey | undefined,
    weeks: DateSegment[],
    projectId: string,
    userKey: UserKey
  ) => {
    if (
      !weeklyCapacitiesPerWeek ||
      !weeklyCapacitiesPerWeek[userKey] ||
      !weeks
    ) {
      return [];
    }

    return weeks.reduce<{
      [week: string]: WeeklyProjectCapacityPerWeekEntryState;
    }>((acc, current: DateSegment) => {
      const [startDate, endDate] = current;
      let entry = {
        startDate,
        endDate,
        assigned: '0.00',
        capacity: '0.00',
        timeOff: '0.00',
        projectId,
      };
      acc[startDate] = {
        ...entry,
        ...weeklyCapacitiesPerWeek[userKey][startDate],
      };
      return acc;
    }, {});
  }
);

/* ================================= REDUCER ================================ */
const teamMemberProjectsCapacitySlice = createSlice({
  name: 'teamMemberProjectsCapacity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setWeeklyProjectCapacity, (state, action) => {
        if (!action.payload) {
          return;
        }
        const { teamId, memberId, projectId, weeklyCapacities } =
          action.payload;
        const updatedWeeklyProjectCapacity = addWeeklyCapacityToProject(
          teamId,
          memberId,
          projectId,
          weeklyCapacities,
          { ...state.byId }
        );
        const updatedProjectsByTeamMember = addProjectToTeamMember(
          teamId,
          memberId,
          projectId,
          { ...state.byTeamMember }
        );
        state.byId = {
          ...state.byId,
          [projectId]: {
            weeklyCapacities: {
              ...state.byId[projectId]?.weeklyCapacities,
              ...updatedWeeklyProjectCapacity.weeklyCapacities,
            },
          },
        };
        state.byTeamMember = {
          ...state.byTeamMember,
          [teamId]: updatedProjectsByTeamMember,
        };
      })
      .addCase(
        fetchWeeklyTeamMemberProjectsCapacity.fulfilled,
        (state, action) => {
          if (!action.payload) {
            return;
          }
          const { teamId, memberId, projectsIdsToRemove } = action.payload;
          if (projectsIdsToRemove.length === 0) {
            return;
          }
          const updatedProjectsByTeamMember = removeProjectsFromTeamMember(
            teamId,
            memberId,
            projectsIdsToRemove,
            { ...state.byTeamMember }
          );
          const {
            projectsIdsToRemove: visibleProjectsIdsToRemove,
            updatedProjectsById,
          } = removeTeamIdFromProjects(teamId, memberId, projectsIdsToRemove, {
            ...state.byId,
          });
          if (teamId in updatedProjectsByTeamMember) {
            const allProjectsIds =
              updatedProjectsByTeamMember[teamId].allProjectsIds;
            updatedProjectsByTeamMember[teamId].allProjectsIds =
              allProjectsIds.filter(
                (id) => !visibleProjectsIdsToRemove.includes(id)
              );
          }
          state.byId = updatedProjectsById;
          state.byTeamMember = updatedProjectsByTeamMember;
        }
      )
      .addCase(removeTeamMemberProjects, (state, action) => {
        if (!action.payload) {
          return;
        }
        const { teamId, memberId } = action.payload;
        if (
          !(teamId in state.byTeamMember) ||
          !(memberId in state.byTeamMember[teamId])
        ) {
          return;
        }
        const projectsIds = state.byTeamMember[teamId][memberId].projectsIds;
        const updatedProjectsByTeamMember = removeProjectsFromTeamMember(
          teamId,
          memberId,
          projectsIds,
          { ...state.byTeamMember }
        );
        const { projectsIdsToRemove, updatedProjectsById } =
          removeTeamIdFromProjects(teamId, memberId, projectsIds, {
            ...state.byId,
          });
        if (teamId in updatedProjectsByTeamMember) {
          const allProjectsIds =
            updatedProjectsByTeamMember[teamId].allProjectsIds;
          updatedProjectsByTeamMember[teamId].allProjectsIds =
            allProjectsIds.filter((id) => !projectsIdsToRemove.includes(id));
        }
        state.byId = updatedProjectsById;
        state.byTeamMember = updatedProjectsByTeamMember;
      })
      .addCase(toggleExpandTeam, (state, action) => {
        if (!action.payload) {
          return;
        }
        const { teamId, expanded } = action.payload;
        if (!expanded && teamId in state.byTeamMember) {
          const { allProjectsIds, ...teamMembers } = state.byTeamMember[teamId];
          const memberIds = Object.keys(teamMembers);
          const { [teamId]: teamToRemove, ...restOfTeams } = state.byTeamMember;
          let projectsIdsToRemove: string[] = [];
          let updatedProjectsById = { ...state.byId };
          for (const memberId of memberIds) {
            const resp = removeTeamIdFromProjects(
              teamId,
              memberId,
              allProjectsIds,
              updatedProjectsById
            );
            projectsIdsToRemove = addIdsToArrayIfNotExists(
              resp.projectsIdsToRemove,
              projectsIdsToRemove
            );
            updatedProjectsById = resp.updatedProjectsById;
          }
          state.byId = updatedProjectsById;
          state.byTeamMember = restOfTeams;
        }
      })
      .addCase(removeWeekFromWeeklyProjectCapacities, (state, action) => {
        if (!action.payload) {
          return;
        }
        const { projectId, weekKey, userKey } = action.payload;
        const updatedWeeklyCapacities = {
          ...state.byId[projectId].weeklyCapacities,
        };
        const startOfWeek = getStartOfTheWeek(
          new Date(weekKey.replace('-', '/'))
        );
        delete updatedWeeklyCapacities[userKey][toDateString(startOfWeek)];
        state.byId[projectId].weeklyCapacities = updatedWeeklyCapacities;
      })
      .addCase(resetWeeklyCapacityState, (state) => {
        state.byId = initialState.byId;
        state.byTeamMember = initialState.byTeamMember;
      });
  },
});

export default teamMemberProjectsCapacitySlice.reducer;
