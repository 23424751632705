import React from 'react';
import { tailwindOverride } from '@getsynapse/design-system';
import { TeamMemberCapacity } from 'utils/types/teamsCapacity';
import TeamMemberData from './components/TeamMemberData/TeamMemberData';
import MemberCapacity from './components/TeamMemberCapacity/TeamMemberCapacity';
import ProjectsList from '../ProjectsList/ProjectsList';

const TeamMember: React.FC<{
  teamId: string;
  teamMemberCapacity: TeamMemberCapacity;
  index: number;
  displayProjectsList: boolean;
  onDisplayProjectsList: (memberId: string, index: number) => void;
}> = ({
  teamId,
  teamMemberCapacity,
  index,
  onDisplayProjectsList,
  displayProjectsList,
}) => {
  const isEven = index % 2 === 0;
  const className = tailwindOverride({
    'bg-neutral-white': !isEven,
    'bg-neutral-lightest-two': isEven,
  });
  return (
    <>
      <div className='flex flex-1'>
        <div className={tailwindOverride('w-2/6 h-26', className)}>
          <TeamMemberData
            teamId={teamId}
            memberData={teamMemberCapacity.data}
            hasProjectsAssigned={
              Object.keys(teamMemberCapacity.projects).length > 0
            }
            isProjectsListDisplayed={displayProjectsList}
            toggleProjectsList={() => {
              onDisplayProjectsList(teamMemberCapacity.data.id, index);
            }}
            className={className}
          />
        </div>
        <div className='w-4/6 h-26 relative'>
          <MemberCapacity
            teamId={teamId}
            memberId={teamMemberCapacity.data.id}
            defaultCapacity={teamMemberCapacity.data.default_capacity}
            capacity={teamMemberCapacity.allocations}
            className={className}
          />
        </div>
      </div>
      {displayProjectsList && (
        <ProjectsList
          teamId={teamId}
          memberId={teamMemberCapacity.data.id}
          projects={teamMemberCapacity.projects}
          aria-hidden={!displayProjectsList}
          className={className}
        />
      )}
    </>
  );
};

export default TeamMember;
