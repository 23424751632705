import {
  useRef,
  useState,
  useEffect,
  useMemo,
  createRef,
  RefObject,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  Dropdown,
  tailwindOverride,
  Typography,
  Tooltip,
  Icon,
} from '@getsynapse/design-system';
import {
  getOrganizationProcesses,
  selectOrderedEnabledProjectProcesses,
} from 'state/Processes/processesSlice';
import { Option } from 'utils/customTypes';
import { CostDataType } from 'utils/types/program';

export type ProcessStageDropdownProps = {
  costData: CostDataType;
  onChange: (value: Partial<CostDataType>) => void;
};

const ProcessStageDropdown = ({
  costData,
  onChange,
}: ProcessStageDropdownProps) => {
  const dispatch = useDispatch();

  const inputRef = useRef<HTMLInputElement>(null);
  const processRefs = useRef<RefObject<HTMLLIElement>[]>([]);

  const processes = useSelector(selectOrderedEnabledProjectProcesses);

  const [filterValue, setFilterValue] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [isTruncated, setIsTruncated] = useState(false);
  const [truncatedItems, setTruncatedItems] = useState<boolean[]>([]);
  const [isDropdownFirstOpen, setIsDropdownFirstOpen] = useState(false);

  const dropdownOptions = useMemo(() => {
    const options: Option[] = processes.flatMap((process) => [
      { value: 'process', label: process.processName },
      ...process.projectStages.map((stage) => ({
        value: stage.id,
        label: stage.stageName,
      })),
    ]);
    options.unshift({ value: null, label: intl.get('NONE') });
    return options;
  }, [processes]);

  useEffect(() => {
    dispatch(getOrganizationProcesses());
  }, [dispatch]);

  useEffect(() => {
    const isOverflowing =
      inputRef?.current?.scrollWidth! > inputRef?.current?.clientWidth! + 1;
    setIsTruncated(isOverflowing);
  }, [
    inputRef?.current?.scrollWidth,
    inputRef?.current?.clientWidth,
    filterValue,
  ]);

  useEffect(() => {
    processRefs.current = dropdownOptions.map(
      (_, i) => processRefs.current[i] ?? createRef()
    );
  }, [dropdownOptions]);

  useEffect(() => {
    if (isDropdownFirstOpen) {
      requestAnimationFrame(() => {
        const truncationStates = processRefs.current.map((ref) => {
          const item = ref.current;
          return item ? item.scrollWidth > item.clientWidth : false;
        });
        setTruncatedItems(truncationStates);
      });
    }
  }, [isDropdownFirstOpen]);

  const getProcessName = useCallback(
    (value: string) => {
      const process = processes.find((process) =>
        process.projectStages.some((stage) => stage.id === value)
      );
      return process?.processName ? process.processName : '';
    },
    [processes]
  );

  const getSatgeName = useCallback(
    (stageId: string) => {
      for (const process of processes) {
        const stage = process.projectStages.find(
          (stage) => stage.id === stageId
        );
        if (stage) {
          return stage.stageName;
        }
      }
      return '';
    },
    [processes]
  );

  useEffect(() => {
    const stageName = costData.costStageName!;
    const processName = costData.processName!;

    let label =
      stageName && processName
        ? `${stageName} (${processName})`
        : intl.get('NONE');
    let selectedOptionLabel = stageName ? stageName : intl.get('NONE');
    let value = costData.costStageId ? costData.costStageId : null;
    if (costData.costStageId && costData?.projectStageIsDeleted === true) {
      value = 'deleted';
      selectedOptionLabel = label;
    } else if (
      costData.costStageId &&
      costData?.projectProcessIsEnabled === false
    ) {
      value = 'disabled';
      selectedOptionLabel = label;
    }
    setFilterValue(label);
    setSelectedOptions([{ value, label: selectedOptionLabel! }]);
  }, [costData]);

  const handleDropdownChange = useCallback(
    (option: Option) => {
      let stageName = null,
        processName = null;
      if (option.value) {
        stageName = getSatgeName(option.value);
        processName = getProcessName(option.value);
      }
      const optionLabel = option.value
        ? `${option.label} (${processName})`
        : option.label;
      setSelectedOptions([option]);
      setFilterValue(optionLabel);
      onChange({
        costStageId: option.value,
        costStageName: stageName,
        processName: processName,
      });
    },
    [getProcessName, getSatgeName, onChange]
  );

  const applyFilter = useCallback(
    (_, filterValue: string) => {
      const normalizedSearchValue = filterValue.toLowerCase();
      let filteredOptions: Option[] = [];

      processes.forEach((process) => {
        let processMatch = false;
        let stages = [];

        if (process.processName.toLowerCase().includes(normalizedSearchValue)) {
          processMatch = true;
          stages = process.projectStages;
        } else {
          stages = process.projectStages.filter((stage) =>
            stage.stageName.toLowerCase().includes(normalizedSearchValue)
          );
        }

        if (processMatch || stages.length > 0) {
          filteredOptions.push({
            value: 'process',
            label: process.processName,
          });
          stages.forEach((stage) => {
            filteredOptions.push({ value: stage.id, label: stage.stageName });
          });
        }
      });

      return filteredOptions;
    },
    [processes]
  );

  const renderTrigger = useCallback(
    ({
      onToggleDropdown,
      isDropdownExpanded,
      onInputChange,
    }: {
      onToggleDropdown: () => void;
      isDropdownExpanded: boolean;
      onInputChange?: (value: string) => void;
    }) => {
      const triggerInput = (
        <div
          onClick={() => {
            setIsDropdownFirstOpen(true);
            onToggleDropdown();
          }}
          className={tailwindOverride(
            'rounded border flex items-center justify-between relative py-2 pl-4 pr-8 h-10 text-base focus-visible:outline-none focus:ring-primary focus-visible:ring-primary bg-neutral-white hover:shadow-skim focus:border-primary',
            {
              'ring-primary border-primary': isDropdownExpanded,
            },
            {
              'border-primary-lighter hover:border-neutral-lighter focus:border-primary active:primary-lighter text-neutral-black':
                !isDropdownExpanded,
            }
          )}
        >
          <div className='w-full inline-flex items-center truncate'>
            <input
              ref={inputRef}
              value={filterValue}
              type='text'
              className={tailwindOverride(
                'form-input appearance-none w-0 min-w-6 flex-grow border-0 focus:ring-0 active:ring-0 p-0 overflow-ellipsis placeholder-neutral bg-transparent text-neutral-black ml-1.5',
                {
                  'text-neutral': filterValue === intl.get('NONE'),
                }
              )}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (!isDropdownExpanded) {
                  onToggleDropdown();
                }
                setFilterValue(event.target.value);
                onInputChange!(event.target.value);
              }}
              placeholder={intl.get('SELECT')}
              data-testid={`process-stage_dropdown_input_${costData.id}`}
              id={`process-stage_dropdown_input_${costData.id}`}
            />
          </div>
          {selectedOptions?.length! > 0 &&
            filterValue === selectedOptions[0].label &&
            (selectedOptions[0].value === 'deleted' ||
              selectedOptions[0].value === 'disabled') && (
              <Typography className='text-neutral ml-1 text-base my-auto'>
                {intl.get(
                  `ROI_CATEGORY.COSTS_PAGE.${selectedOptions[0].value.toUpperCase()}_STAGE`
                )}
              </Typography>
            )}
          <div className='absolute top-0 right-0 flex items-center mt-3 mr-2.5'>
            <Icon
              name={
                isDropdownExpanded ? 'caret-up-outline' : 'caret-down-outline'
              }
              className='text-neutral-dark'
            />
          </div>
        </div>
      );

      return (
        <>
          {isTruncated ? (
            <Tooltip
              className='w-full'
              ariaId='dropdown-trigger-tooltip'
              openMode='hover1'
              timeout={0}
              trigger={triggerInput}
              showPopper={true}
              contentProps={{ className: 'z-20 text-sm' }}
            >
              {selectedOptions?.length &&
              (selectedOptions[0].value === 'deleted' ||
                selectedOptions[0].value === 'disabled')
                ? selectedOptions[0].label
                : selectedOptions?.length
                ? `${selectedOptions[0].label} (${getProcessName(
                    selectedOptions[0].value
                  )})`
                : ''}
            </Tooltip>
          ) : (
            triggerInput
          )}
        </>
      );
    },
    [filterValue, getProcessName, isTruncated, selectedOptions, costData.id]
  );

  const renderOption = useCallback(
    (
      option: Option,
      _,
      selectOption: (option: Option) => void,
      { className, ...otherProps }
    ) => {
      const index = dropdownOptions.findIndex(
        (opt) => opt.value === option.value
      );
      const isSelected =
        selectedOptions.length > 0 && selectedOptions[0].value === option.value;

      const optionItem = (
        <li
          {...otherProps}
          ref={processRefs.current[index]}
          onClick={() => {
            if (option.value !== 'process') {
              selectOption(option);
              setSelectedOptions([option]);
            }
          }}
          className={tailwindOverride(
            `${
              option.value === 'process'
                ? 'text-neutral font-semibold	text-xs pt-2 px-4 pb-1'
                : !isSelected
                ? `${className} hover:text-secondary-darker hover:bg-secondary-lightest hover:shadow-list-item-hover-green focus-visible:bg-secondary-lightest focus-visible:shadow-list-item-hover-green focus-visible::text-secondary-darker`
                : `${className} text-neutral-white bg-primary hover:text-neutral-white hover:bg-primary focus-visible:bg-primary focus-visible:text-neutral-white`
            }`
          )}
        >
          <span
            className={tailwindOverride({
              'pl-4': option.value && option.value !== 'process',
            })}
          >
            {option.label}
          </span>
        </li>
      );

      const isCurrentItemTruncated = truncatedItems[index];

      return isCurrentItemTruncated ? (
        <Tooltip
          className='w-full'
          ariaId='dropdown-tooltip'
          openMode='hover1'
          timeout={0}
          trigger={optionItem}
          usePortal={true}
          showPopper={true}
          contentProps={{ className: 'z-10 text-sm' }}
        >
          {option.label}
        </Tooltip>
      ) : (
        optionItem
      );
    },
    [dropdownOptions, truncatedItems, selectedOptions]
  );

  const resetFilterValue = () => {
    let stageName = costData.costStageName!;
    let processName = costData.processName!;

    const label =
      stageName && processName
        ? `${stageName} (${processName})`
        : intl.get('NONE');
    setFilterValue(label);
  };

  return (
    <Dropdown
      onChange={handleDropdownChange}
      data-testid='process-stage_dropdown'
      onClose={resetFilterValue}
      filterOptions={applyFilter}
      options={dropdownOptions}
      filterable
      listProps={{
        'data-testid': 'process-stage_dropdown_list',
      }}
      renderTrigger={renderTrigger}
      renderOption={renderOption}
    />
  );
};

export default ProcessStageDropdown;
