import { useState, useMemo } from 'react';
import {
  Legend,
  PieChart,
  Cell,
  Pie,
  Sector,
  ResponsiveContainer,
} from 'recharts';
import intl from 'react-intl-universal';
import {
  ACTIVE_COLORS,
  CENTER_COUNT_COLOR,
  CENTER_TEXT_COLOR,
  IN_ACTIVE_COLORS,
  PATH_CIRCLE_COLOR,
  SECTOR_INACTIVE_COLOR,
  inActiveColor,
} from 'Pages/InsightsPage/Projects/constants';

const ByPriorityPieChart = ({
  high,
  medium,
  low,
}: {
  high: number;
  medium: number;
  low: number;
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [sectorClicked, setSectorClicked] = useState(false);
  const [renderBlankState, setBlankState] = useState(false);
  const [sectorColors, setSectorColors] = useState(ACTIVE_COLORS);

  const totalNumberOfProjects: number = high + medium + low;

  useMemo(() => {
    if (totalNumberOfProjects === 0) {
      setBlankState(true);
      setSectorColors(IN_ACTIVE_COLORS);
    } else {
      setBlankState(false);
      setSectorColors(ACTIVE_COLORS);
    }
  }, [totalNumberOfProjects]);

  const data = useMemo(() => {
    return [
      {
        name: intl.get('PROJECTS_INSIGHTS_TABS.BY_PRIORITY.HIGH_PRIORITY'),
        value: high,
      },
      {
        name: intl.get('PROJECTS_INSIGHTS_TABS.BY_PRIORITY.MEDIUM_PRIORITY'),
        value: medium,
      },
      {
        name: intl.get('PROJECTS_INSIGHTS_TABS.BY_PRIORITY.LOW_PRIORITY'),
        value: low,
      },
    ];
  }, [high, medium, low]);

  const renderActiveShape = (props: {
    cx?: any;
    cy?: any;
    midAngle?: any;
    innerRadius?: any;
    outerRadius?: any;
    startAngle?: any;
    endAngle?: any;
    fill?: any;
    percent?: any;
    value?: any;
    showArc?: any;
  }) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 4) * cos;
    const sy = cy + (outerRadius + 4) * sin;
    const mx = cx + (outerRadius + 12) * cos;
    const my = cy + (outerRadius + 12) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    const chartArcElements = !renderBlankState ? (
      <>
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 2}
          outerRadius={outerRadius + 4}
          fill={fill}
          style={{ outline: 'none' }}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}`}
          stroke={PATH_CIRCLE_COLOR}
          fill='none'
        />
        <circle
          cx={mx}
          cy={my}
          r={2}
          fill={PATH_CIRCLE_COLOR}
          stroke={PATH_CIRCLE_COLOR}
        />
        <text
          x={ex - (cos >= 0 ? 1 : -1) * 16}
          y={sin >= 0 ? ey + 1 * 10 : ey - 1 * 8}
          textAnchor={textAnchor}
          fill={CENTER_TEXT_COLOR}
          fontWeight={700}
          fontSize='0.825em'
        >
          {`${value}`}
        </text>
      </>
    ) : null;
    return (
      <g style={{ outline: 'none' }}>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          style={{ outline: 'none' }}
        />
        {chartArcElements}
      </g>
    );
  };

  const handleMouseEnter = (event: any, index: number) => {
    setActiveIndex(index);
  };
  const handleMouseLeave = (event: any, index: number) => {
    setSectorClicked(false);
  };
  const renderColorfulLegendText = (value: string, entry: any) => {
    return (
      <span
        style={{
          color: renderBlankState
            ? SECTOR_INACTIVE_COLOR
            : sectorClicked && data[activeIndex].name !== entry.value
            ? SECTOR_INACTIVE_COLOR
            : CENTER_COUNT_COLOR,
          lineHeight: '1rem',
          fontSize: '0.75rem',
          fontWeight: 400,
          paddingRight: '0.25rem',
        }}
      >
        {value}
      </span>
    );
  };

  return (
    <>
      <ResponsiveContainer width='95%' height='100%'>
        <PieChart>
          <Pie
            data={data}
            activeIndex={activeIndex}
            activeShape={(projectDetails) => renderActiveShape(projectDetails)}
            cx='50%'
            cy='50%'
            innerRadius='35%'
            outerRadius='75%'
            paddingAngle={1}
            dataKey='value'
            onMouseEnter={(_, index) => handleMouseEnter(_, index)}
            onMouseLeave={(_, index) => handleMouseLeave(_, index)}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                style={{
                  outline: 'none',
                }}
                fill={
                  sectorClicked
                    ? activeIndex === index % sectorColors.length
                      ? sectorColors[index % sectorColors.length]
                      : inActiveColor
                    : sectorColors[index % sectorColors.length]
                }
              />
            ))}
          </Pie>
          <Legend
            style={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              right: '0',
              margin: 'auto',
              outline: 'none',
            }}
            align='left'
            iconSize={8}
            iconType='circle'
            layout='vertical'
            verticalAlign='bottom'
            formatter={renderColorfulLegendText}
            height={120}
          />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default ByPriorityPieChart;
