import { NumericInput } from '@getsynapse/design-system';
import { ChangeEvent, useMemo, KeyboardEvent } from 'react';
import debounce from 'lodash/debounce';
import {
  NumberFieldTemplateType,
  NumberFieldType,
  NumberValue,
} from 'utils/types/fields';
import { ALLOWED_FIELD_TYPES } from 'utils/constants';

interface NumberFieldProps {
  field?: NumberFieldType;
  fieldTemplate: NumberFieldTemplateType;
  onChange: (newValue: NumberValue) => void;
  disabled: boolean;
  state?: 'default' | 'error';
}

const NumberField = ({
  field,
  fieldTemplate,
  onChange,
  disabled,
  state = 'default',
}: NumberFieldProps) => {
  const defaultValue = useMemo<number | null>(() => {
    if (field) {
      return field.value.val;
    } else if (fieldTemplate.default_value) {
      return fieldTemplate.default_value.val;
    } else {
      return null;
    }
  }, [field, fieldTemplate.default_value]);

  const onChangeHandler = debounce((value: string) => {
    const newValue = value ? Number(value) : null;
    onChange({ val: newValue });
  }, 500);

  const checkInput = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === '.') {
      event.preventDefault();
    }
  };

  return (
    <NumericInput
      defaultValue={defaultValue}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        onChangeHandler(event.target.value);
      }}
      disabled={disabled}
      state={state}
      onKeyDown={(event: KeyboardEvent<HTMLInputElement>) =>
        fieldTemplate?.type === ALLOWED_FIELD_TYPES.INT ? checkInput(event) : {}
      }
    />
  );
};

export default NumberField;
