import { STRATEGY_CATEGORIES } from 'utils/constants/program';
import { strategyCategories } from 'utils/types/program';

export const generateDisallowedCategories = (
  isProgramStrategyBeta12Enabled: boolean,
  isProgramStrategyBeta14Enabled: boolean,
  isProgramStrategy15RoiEnabled: boolean
) => {
  let disallowedCategories: strategyCategories[] = [];

  if (!isProgramStrategyBeta12Enabled) {
    disallowedCategories = disallowedCategories.concat(
      STRATEGY_CATEGORIES.IMPACT
    );
  }

  if (!isProgramStrategyBeta14Enabled) {
    disallowedCategories = disallowedCategories.concat(
      STRATEGY_CATEGORIES.INPUT
    );
  }

  if (!isProgramStrategy15RoiEnabled) {
    disallowedCategories = disallowedCategories.concat(STRATEGY_CATEGORIES.ROI);
  }

  return disallowedCategories;
};
