import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import {
  NewScheduleCreationParams,
  GetScheduleParams,
  FetchedEvent,
} from 'utils/types/learningSchedule';

class ScheduleAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(
      `${config.get('backendURL')}v2/facilitation-management/`
    );
  }

  createSchedule = async (newScheduleData: NewScheduleCreationParams) => {
    const res = await this.instance.post('event', newScheduleData);
    return res;
  };

  fetchSchedules = async (fetchScheduleParams?: GetScheduleParams) => {
    const urlSearchParams = new URLSearchParams();
    const res = await this.instance.get(
      `schedule?${urlSearchParams.toString()}`
    );
    return res.data.formattedSchedules as FetchedEvent[];
  };
}

export default new ScheduleAPI();
