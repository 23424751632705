import { Typography } from '@getsynapse/design-system';

const CountAndRatio = ({
  count,
  countMessage,
  ratioMessage = '',
}: {
  count: number;
  countMessage: string;
  ratioMessage?: string;
}) => {
  return (
    <div className='flex flex-col'>
      <div className='w-full flex items-center'>
        <label className='text-48 leading-10 font-bold'>{count}</label>
        <span className='font-semibold text-sm leading-4 w-24 mt-1 ml-2'>
          {countMessage}
        </span>
      </div>
      {ratioMessage && (
        <Typography
          variant='caption'
          className='text-neutral text-xs font-semibold mt-2'
        >
          {ratioMessage}
        </Typography>
      )}
    </div>
  );
};

export default CountAndRatio;
