import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { Status } from 'utils/customTypes';
import { fetchTimeOffTypes as fetchTimeOffTypesApi } from 'api/manageTimeOff';
import { SLICE_STATUS } from 'utils/constants';
import { TimeOffType } from 'types/store/manageTimeOff';

interface TimeOffTypesState {
  ids: string[];
  byId: {
    [id: string]: TimeOffType;
  };
  status: Status;
}

const initialState: TimeOffTypesState = {
  ids: [],
  byId: {},
  status: SLICE_STATUS.IDLE,
};

export const fetchTimeOffTypes = createAsyncThunk(
  'manageTimeOff/timeOffTypes/fetchTimeOffTypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchTimeOffTypesApi();
      const allIds: string[] = [];
      const byId = response.types.reduce(
        (acc: TimeOffTypesState['byId'], timeOffType) => {
          allIds.push(timeOffType.id);
          acc[timeOffType.id] = timeOffType;
          return acc;
        },
        {}
      );
      return {
        ids: allIds,
        byId,
      };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const timeOffTypesSlice = createSlice({
  name: 'manageTimeOff/timeOffTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTimeOffTypes.pending, (state) => {
      state.status = SLICE_STATUS.LOADING;
    });
    builder.addCase(fetchTimeOffTypes.fulfilled, (state, action) => {
      if (action.payload) {
        state.status = SLICE_STATUS.IDLE;
        state.ids = action.payload.ids;
        state.byId = action.payload.byId;
      }
    });
    builder.addCase(fetchTimeOffTypes.rejected, (state) => {
      state.status = SLICE_STATUS.FAILED;
    });
  },
});

export const selectTimeOffTypesIds = (state: RootState) =>
  state.manageTimeOff.timeOffTypes.ids;
const selectTimeOffTypesById = (state: RootState) =>
  state.manageTimeOff.timeOffTypes.byId;
export const selectTimeOffTypes = createSelector(
  [selectTimeOffTypesById],
  (timeOffTypesById: TimeOffTypesState['byId']) =>
    Object.values(timeOffTypesById)
);
export const selectTimeOffTypesByCode = createSelector(
  [selectTimeOffTypes],
  (timeOffTypes: TimeOffType[]) =>
    timeOffTypes.reduce((acc: TimeOffTypesState['byId'], cur: TimeOffType) => {
      acc[cur.code] = cur;
      return acc;
    }, {})
);
export const selectTimeOffType = createSelector(
  [selectTimeOffTypesById, (_: RootState, id: string) => id],
  (timeOffTypesById: TimeOffTypesState['byId'], id: string) =>
    timeOffTypesById[id]
);

export default timeOffTypesSlice.reducer;
