import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { TaskActualHours } from 'utils/customTypes';

class SingleTaskAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  updateTask = async (taskId: string, updateData: any) => {
    const { data } = await this.instance.put(`task/${taskId}`, {
      ...updateData,
    });
    return data.data.resultTask;
  };

  updateStatus = async (taskId: string, status: string) => {
    const { data } = await this.instance.put(`task/${taskId}/status`, {
      status,
    });
    return data.data.resultTask;
  };

  updateAssignees = async (taskId: string, assignedUsers: string[]) => {
    const { data } = await this.instance.put(`task/${taskId}/setAssignees`, {
      assignedUsers,
    });
    return data.data.resultTask;
  };

  updateEnablement = async (taskId: string, disabled: boolean) => {
    const { data } = await this.instance.put(`task/${taskId}/disable`, {
      disabled,
    });
    return data.data;
  };

  fetchTask = async (taskId: string) => {
    const { data } = await this.instance.get(`task/${taskId}`);
    return data;
  };

  fetchTaskComments = async (taskId: string) => {
    const { data } = await this.instance.get(`v2/tasks/${taskId}/comments`);
    return data;
  };

  createTaskComment = async (
    taskId: string,
    content: string,
    mentions: string[]
  ) => {
    const { data } = await this.instance.post(`v2/tasks/${taskId}/comments`, {
      content,
      mentions,
    });
    return data;
  };

  updateTaskComment = async (
    commentId: string,
    taskId: string,
    content: string,
    mentions: string[]
  ) => {
    const { data } = await this.instance.put(
      `v2/tasks/${taskId}/comments/${commentId}`,
      {
        content,
        mentions,
      }
    );
    return data;
  };

  deleteTaskComment = async (commentId: string) => {
    await this.instance.delete(`v2/comments/${commentId}`);
    return commentId;
  };

  fetchTaskActualHours = async (taskId: string) => {
    const { data } = await this.instance.get(
      `task/${taskId}/retrieveTaskActualHours`
    );
    return data;
  };

  updateTaskActualHours = async (
    taskId: string,
    newActualHours: TaskActualHours[],
    removedActualHours: string[]
  ) => {
    const { data } = await this.instance.put(
      `task/${taskId}/setTaskActualHours`,
      {
        newActualHours,
        removedActualHours,
      }
    );
    return data;
  };

  updateLinkedContent = async (taskId: string, linkedContentIds: string[]) => {
    await this.instance.put(`task/${taskId}/linkedContent`, {
      linkedContentIds,
    });
  };
}

const singleTaskAPI = new SingleTaskAPI();

export default singleTaskAPI;
