import {
  tailwindOverride,
  TextField,
  Typography,
} from '@getsynapse/design-system';
import { useState, useEffect, useCallback, useRef } from 'react';
import classnames from 'classnames';

const SidePanelTitle = ({
  className,
  placeholder,
  value,
  maxLength,
  disabled,
  onChange,
  containerProps,
}: {
  className?: string;
  placeholder: string;
  value: string;
  maxLength: number;
  disabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const inputField = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: Event) => {
      const target = event.target as Element;
      if (!inputField.current?.contains(target) && editMode) {
        setEditMode(false);
      }
    },
    [editMode]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div
      className={tailwindOverride(
        'sticky top-0 px-1 pt-6 pb-4 bg-neutral-white border-neutral-white z-10',
        containerProps?.className
      )}
    >
      {editMode && !disabled ? (
        <div ref={inputField}>
          <TextField
            placeholder={placeholder}
            value={value}
            maxLength={maxLength}
            disabled={disabled}
            onChange={onChange}
            data-cy='side-panel-title-edit'
            inputClassName={classnames({ 'text-xl font-semibold': value })}
            className={className}
            autoFocus
          />
        </div>
      ) : (
        <div
          className={classnames(
            'text-xl border border-transparent rounded py-2 h-10 truncate flex items-center	',
            { 'hover:border-neutral-lighter hover:px-4': !disabled }
          )}
          onClick={() => {
            if (!editMode) setEditMode(true);
          }}
          title={value}
        >
          <Typography
            weight='medium'
            data-testid='side-panel-title'
            className={tailwindOverride('text-xl truncate', className, {
              'text-neutral font-normal': !value,
            })}
          >
            {value || placeholder}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default SidePanelTitle;
