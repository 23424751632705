import { FC } from 'react';
import { IconButton, tailwindOverride } from '@getsynapse/design-system';
import {
  File,
  Design,
  WebLink,
  ContentType,
} from 'types/store/linkedTaskContent';
import DefaultFileContent from 'assets/images/default-file-content.png';
import WebLinkContent from 'assets/images/web-link-content.png';
import DesignContent from 'assets/images/design-content.png';
import UnlinkContent from 'assets/images/unlink-content.svg';

interface ThumbnailProps {
  contentType: ContentType;
  contentData: File | Design | WebLink;
  canUnlinkContent?: boolean;
  onUnlinkContent: () => void;
}

const Thumbnail: FC<ThumbnailProps> = ({
  contentData,
  contentType,
  canUnlinkContent = true,
  onUnlinkContent,
}) => {
  let backgroundImage = '';
  switch (contentType) {
    case 'file':
    case 'design_file':
      backgroundImage = (contentData as File).mimetype.includes('image/')
        ? (contentData as File).cloudFrontURL || (contentData as File).url
        : DefaultFileContent;
      break;
    case 'web_link':
      backgroundImage = WebLinkContent;
      break;
    case 'design':
      backgroundImage = DesignContent;
      break;
    default:
      break;
  }

  return (
    <div className='w-38 h-18 relative' aria-label='thumbnail'>
      <div
        className={tailwindOverride(
          'absolute top-0 left-0 w-full h-18 justify-end z-20',
          'hidden bg-neutral-black group-hover:bg-opacity-70',
          { 'group-hover:flex': canUnlinkContent }
        )}
      >
        <IconButton
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            onUnlinkContent();
          }}
          className='mt-2 mr-1 fill-current text-neutral-white text-xl hover:text-neutral-lighter-two w-6 h-6'
          src={UnlinkContent}
          aria-label='unlink-content-button'
        />
      </div>
      <div
        aria-label='thumbnail-image'
        className='absolute top-0 left-0 w-full h-full'
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%',
        }}
      />
    </div>
  );
};

export default Thumbnail;
