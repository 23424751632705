import config from 'config/Config';
export const getAfterQueryParamFromNextLink: (nextLink: string) => string = (
  nextLink
) => {
  const fullUrl = new URL(`${config.get('backendURL')}/${nextLink}`);
  const searchParams = new URLSearchParams(new URL(fullUrl).search);
  if (searchParams.has('after')) {
    return searchParams.get('after')!;
  }
  return '';
};
