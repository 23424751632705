import { Toggle, Typography } from '@getsynapse/design-system';
import classnames from 'classnames';
import intl from 'react-intl-universal';

const ReorderToggle = ({
  isChecked,
  onClick,
  disabled,
}: {
  isChecked: boolean;
  onClick: (checked: boolean) => void;
  disabled: string | boolean;
}) => (
  <div className='flex items-center'>
    <Toggle
      isSmall
      className={classnames({
        'cursor-not-allowed bg-neutral-lightest': disabled,
      })}
      checked={isChecked}
      inputProps={{
        'data-testid': 'table-header__reorder-button',
      }}
      disabled={Boolean(disabled)}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        onClick(event.target.checked)
      }
    />
    <Typography
      variant='body2'
      className={classnames(
        'ml-1',
        {
          'cursor-not-allowed text-neutral-light': disabled,
        },
        {
          'text-neutral-black': !disabled,
        }
      )}
    >
      {intl.get('TABLE_HEADER_ACTIONS.REORDER')}
    </Typography>
  </div>
);

export default ReorderToggle;
