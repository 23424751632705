import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { RootState } from 'state/store';
import { SLICE_STATUS } from 'utils/constants';
import { Status } from 'utils/customTypes';
import {
  ProjectsByTeamMember,
  TeamMemberProjects,
  TeamMemberProjectsById,
} from 'utils/types/dailyTeamsCapacity';
import {
  resetCapacitiesState,
  toggleExpandTeam,
} from '../../Capacities/TeamsList/teamsListSlice';
import { updateProject } from '../../Project/projectSlice';
import teamMemberProjectsListAPI from './teamMemberProjectsListAPI';
import {
  addProjectsToTeamMember,
  removeTeamFromProjectsById,
  toggleMemberFromProjectsByTeamMember,
} from './helpers';
import { generateTeamMemberId } from 'state/DailyTeamsCapacity/helpers';
import { addIdToArrayIfNotExists } from '../helpers';

interface TeamMemberProjectsListState {
  byTeamMember: ProjectsByTeamMember<TeamMemberProjects>;
  byId: TeamMemberProjectsById;
  status: Status;
}

/* ============================= INITIAL STATE ============================== */
const initialState: TeamMemberProjectsListState = {
  byTeamMember: {},
  byId: {},
  status: 'idle',
};

/* ============================= SELECTORS ============================== */
export const selectTeamMemberIsExpanded = (
  state: RootState,
  teamId: string,
  memberId: string
) => {
  const byTeamMember = state.capacities.teamMemberProjectsList.byTeamMember;
  return teamId in byTeamMember && memberId in byTeamMember[teamId];
};
export const selectTeamMemberProjects = (
  state: RootState,
  teamId: string,
  memberId: string
) => {
  const byTeamMember = state.capacities.teamMemberProjectsList.byTeamMember;
  if (teamId in byTeamMember && memberId in byTeamMember[teamId]) {
    return byTeamMember[teamId][memberId]?.projectsIds || [];
  }
  return [];
};
export const selectTeamMemberProject = (
  state: RootState,
  projectId: string
) => {
  if (projectId in state.capacities.teamMemberProjectsList.byId) {
    return state.capacities.teamMemberProjectsList.byId[projectId];
  }
};
export const selectCanFetchMoreTeamMemberProjects = (
  state: RootState,
  teamId: string,
  memberId: string
) => {
  const byTeamMember = state.capacities.teamMemberProjectsList.byTeamMember;
  if (teamId in byTeamMember && memberId in byTeamMember[teamId]) {
    return byTeamMember[teamId][memberId]?.links?.next !== null;
  }
  return false;
};

export const selectProjectStatus = (state: RootState, projectId: string) => {
  const byId = state.capacities.teamMemberProjectsList.byId;
  if (projectId in byId) {
    return byId[projectId].status;
  }
};

/* ============================== ACTIONS =============================== */
export const toggleTeamMemberProjectsList = createAction<{
  teamId: string;
  memberId: string;
}>('dailyTeamsCapacity/teamMemberProjectsList/TOGGLE_TEAM_MEMBER');

/* ============================== REDUX THUNK =============================== */
export const fetchTeamMemberProjectsList = createAsyncThunk(
  'dailyTeamsCapacity/teamMemberProjectsList/FETCH_TEAM_MEMBER_PROJECTS_LIST',
  async (
    params: { teamId: string; memberId: string },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const { teamId, memberId } = params;
      let url = `v2/teams/${teamId}/members/${memberId}/projects?limit=5`;
      const byTeamMember = state.capacities.teamMemberProjectsList.byTeamMember;
      if (teamId in byTeamMember && memberId in byTeamMember[teamId]) {
        url = byTeamMember[teamId][memberId].links.next || url;
      }
      const response =
        await teamMemberProjectsListAPI.fetchTeamMemberProjectsList(url);
      const teamMemberProjectsById =
        response.teamMemberProjects.reduce<TeamMemberProjectsById>(
          (acc, project) => {
            let teamMembersIds: string[] = [];
            const { id, ...restOfProjecData } = project;
            const projectsById = state.capacities.teamMemberProjectsList.byId;
            if (id in projectsById) {
              teamMembersIds = projectsById[id]?.teamMembersIds || [];
            }
            const teamMemberId = generateTeamMemberId(teamId, memberId);
            acc[project.id] = {
              ...restOfProjecData,
              teamMembersIds: addIdToArrayIfNotExists(
                teamMembersIds,
                teamMemberId
              ),
            };
            return acc;
          },
          {}
        );
      return {
        teamId,
        memberId,
        teamMemberProjectsById,
        links: response.links,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* ================================= REDUCER ================================ */
const teamMemberProjectsListSlice = createSlice({
  name: 'dailyTeamsCapacity/teamMemberProjectsList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(toggleTeamMemberProjectsList, (state, action) => {
      if (action.payload) {
        const { teamId, memberId } = action.payload;
        const updatedByTeamState = toggleMemberFromProjectsByTeamMember(
          { ...state.byTeamMember },
          teamId,
          memberId
        );
        let updatedProjectsById = { ...state.byId };
        if (
          !(teamId in updatedByTeamState) ||
          !(memberId in updatedByTeamState[teamId])
        ) {
          const projectsIds =
            state.byTeamMember[teamId]?.[memberId]?.projectsIds || [];
          const resp = removeTeamFromProjectsById(
            updatedProjectsById,
            projectsIds,
            generateTeamMemberId(teamId, memberId)
          );
          updatedProjectsById = resp.updatedProjectsById;
          if (teamId in updatedByTeamState) {
            const allProjectsIds = updatedByTeamState[teamId].allProjectsIds;
            updatedByTeamState[teamId].allProjectsIds = allProjectsIds.filter(
              (id) => !resp.projectsIdsToRemove.includes(id)
            );
          }
        }
        state.byTeamMember = updatedByTeamState;
        state.byId = updatedProjectsById;
      }
    });
    builder.addCase(toggleExpandTeam, (state, action) => {
      if (action.payload) {
        const { teamId, expanded } = action.payload;
        if (!expanded && teamId in state.byTeamMember) {
          const { allProjectsIds, ...members } = state.byTeamMember[teamId];
          const membersIds = Object.keys(members);
          let updatedProjectsById = { ...state.byId };
          for (const memberId of membersIds) {
            const teamMemberId = generateTeamMemberId(teamId, memberId);
            const resp = removeTeamFromProjectsById(
              updatedProjectsById,
              members[memberId].projectsIds,
              teamMemberId
            );
            updatedProjectsById = resp.updatedProjectsById;
          }
          const updatedProjectsByTeamMember = { ...state.byTeamMember };
          delete updatedProjectsByTeamMember[teamId];
          state.byTeamMember = updatedProjectsByTeamMember;
          state.byId = updatedProjectsById;
        }
      }
    });
    builder.addCase(fetchTeamMemberProjectsList.pending, (state) => {
      state.status = SLICE_STATUS.LOADING;
    });
    builder.addCase(fetchTeamMemberProjectsList.fulfilled, (state, action) => {
      if (action.payload) {
        const { teamId, memberId, teamMemberProjectsById, links } =
          action.payload;

        state.byTeamMember = addProjectsToTeamMember(
          { ...state.byTeamMember },
          teamId,
          memberId,
          Object.keys(teamMemberProjectsById),
          links
        );

        state.byId = {
          ...state.byId,
          ...teamMemberProjectsById,
        };
      }
      state.status = SLICE_STATUS.IDLE;
    });
    builder.addCase(fetchTeamMemberProjectsList.rejected, (state) => {
      state.status = SLICE_STATUS.FAILED;
    });
    builder.addCase(updateProject.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }
      const { id, ...otherProjectData } = action.payload;
      const updatedById = { ...state.byId };
      if (id in updatedById) {
        updatedById[id].title = get(
          otherProjectData,
          'title',
          updatedById[id].title
        );
        updatedById[id].status = get(
          otherProjectData,
          'status',
          updatedById[id].status
        );
        updatedById[id].priority = get(
          otherProjectData,
          'priority',
          updatedById[id].priority
        );
        updatedById[id].health = get(
          otherProjectData,
          'health',
          updatedById[id].health
        );
        updatedById[id].startDate = get(
          otherProjectData,
          'startDate',
          updatedById[id].startDate
        );
        updatedById[id].targetCompletionDate = get(
          otherProjectData,
          'targetCompletionDate',
          updatedById[id].targetCompletionDate
        );
        state.byId = updatedById;
      }
    });
    builder.addCase(resetCapacitiesState, (state) => {
      state.byTeamMember = initialState.byTeamMember;
      state.byId = initialState.byId;
      state.status = initialState.status;
    });
  },
});

export default teamMemberProjectsListSlice.reducer;
