const EvidenceSkeletonLoader = () => {
  const skeletonClassnames = 'bg-neutral-lighter-two animate-pulse';

  return (
    <div data-testid='evidence-skeleton-loader'>
      <div className={`w-full mt-4 h-12 ${skeletonClassnames}`} />
      <div className={`w-full mt-2 h-12 ${skeletonClassnames}`} />
      <div className={`w-full mt-4 h-4 ${skeletonClassnames}`} />
      <div className={`w-full mt-2 h-4 ${skeletonClassnames}`} />
      <div className={`w-1/4 mt-2 h-4 ${skeletonClassnames}`} />
    </div>
  );
};

export default EvidenceSkeletonLoader;
