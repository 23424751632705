import intl from 'react-intl-universal';
import {
  Dropdown,
  FormItem,
  tailwindOverride,
} from '@getsynapse/design-system';
import { Option, TaskBundle } from 'utils/customTypes';
import { useCallback, useMemo } from 'react';
import { TaskBundleOption } from './types';
import TaskBundlePickerOption from './TaskBundlePickerOption';
import TaskBundlePickerTrigger from './TaskBundlePickerTrigger';

interface TaskBundlePickerProps {
  className?: string;
  required?: boolean;
  taskBundles: TaskBundle[];
  onSelectTaskBundle: (selectedTaskBundle: TaskBundleOption) => void;
  width?: number;
}

const TaskBundlePicker: React.FC<TaskBundlePickerProps> = ({
  className,
  required = false,
  taskBundles,
  onSelectTaskBundle,
  width,
}) => {
  const taskBundleOptions = useMemo<TaskBundleOption[]>(
    () =>
      taskBundles.map((taskBundle) => ({
        label: taskBundle.name,
        value: taskBundle.id,
        totalEstimatedHours: parseFloat(taskBundle.totalEstimatedHours),
      })),
    [taskBundles]
  );

  const selectTaskBundle: (templateId: string) => void = useCallback(
    (templateId) => {
      const selectedTaskBundle = taskBundleOptions.find(
        (taskBundle) => taskBundle.value === templateId
      );

      if (selectedTaskBundle) {
        onSelectTaskBundle(selectedTaskBundle);
      }
    },
    [onSelectTaskBundle, taskBundleOptions]
  );

  return (
    <FormItem
      label={intl.get('NEW_PROJECT_PAGE.TASK_BUNDLE.TITLE')}
      labelProps={{ required }}
      className={tailwindOverride('mt-3', className)}
      style={{ width }}
    >
      <Dropdown
        options={taskBundleOptions}
        style={{ width }}
        renderOption={(
          option: TaskBundleOption,
          selected,
          selectOption,
          { className, ...otherProps }
        ) => (
          <TaskBundlePickerOption
            className={className}
            option={option}
            selected={selected}
            selectOption={selectOption}
            {...otherProps}
          />
        )}
        placeholder={intl.get('NEW_PROJECT_PAGE.TASK_BUNDLE.PLACEHOLDER')}
        onChange={(option: Option) => {
          selectTaskBundle(option.value);
        }}
        renderTrigger={({
          onToggleDropdown,
          selectedOptions,
          isDropdownExpanded,
        }) => (
          <TaskBundlePickerTrigger
            onToggleDropdown={onToggleDropdown}
            selectedOption={selectedOptions[0]}
            isDropdownExpanded={isDropdownExpanded}
          />
        )}
      />
    </FormItem>
  );
};

export default TaskBundlePicker;
