import { MEMBER_VALUES } from 'utils/constants';

export const USER_TABLE_FILTERS = {
  TYPE: MEMBER_VALUES.TYPE,
  TEAM: MEMBER_VALUES.TEAM,
  JOB_TITLE: MEMBER_VALUES.JOB_TITLE,
  EMPLOYMENT_TYPE: MEMBER_VALUES.EMPLOYMENT_TYPE,
  COUNTRY: MEMBER_VALUES.COUNTRY,
  STATUS: MEMBER_VALUES.STATUS,
};
