import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { selectProjectStatus } from 'state/Capacities/TeamMemberProjectsList/teamMemberProjectsListSlice';
import { ProjectCapacityPerWeek } from '../TeamMemberProjectsWeeklyCapacities/TeamMemberProjectsWeeklyCapacities';
import ProjectHours from '../../../../CapacityTable/components/ProjectHours/ProjectHours';

const TeamMemberProjectsWeeklyCapacity: React.FC<ProjectCapacityPerWeek> = ({
  projectId,
  assigned,
}) => {
  const projectStatus = useSelector((state: RootState) =>
    selectProjectStatus(state, projectId)
  );
  const assignedHours = parseFloat(assigned);
  if (!projectStatus) {
    return null;
  }
  return (
    <div
      aria-label={`project-${projectId}-hours`}
      className='w-full h-full pt-4 px-2'
    >
      {assignedHours > 0 && (
        <ProjectHours
          id={projectId}
          status={projectStatus}
          hours={assignedHours}
        />
      )}
    </div>
  );
};

export default TeamMemberProjectsWeeklyCapacity;
