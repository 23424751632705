const ChartSkeletonLoader = () => {
  const topDivs = (index: number) => (
    <div key={`top-div-${index}`}>
      <div className='bg-neutral-lighter animate-pulse w-40 h-4 mb-2'></div>
      <div className='bg-neutral-lighter animate-pulse w-40 h-4'></div>
    </div>
  );

  const renderBottomDivs = (index: number) => (
    <div className='flex mb-3.5 mr-9 items-center' key={`bottom-div-${index}`}>
      <div className='bg-neutral-lighter animate-pulse w-1/3 h-3.5 mb-2 mr-9' />
      <div className='w-full'>
        <div className='bg-neutral-lighter-two animate-pulse h-4 mb-1 w-full' />
        <div className='bg-neutral-lighter animate-pulse h-4 w-3/4' />
      </div>
    </div>
  );

  return (
    <div className='px-4'>
      <div className='flex justify-between mb-14'>
        {Array(2)
          .fill('')
          .map((_, index) => topDivs(index))}
      </div>
      {Array(7)
        .fill('')
        .map((_, index) => renderBottomDivs(index))}
    </div>
  );
};

export default ChartSkeletonLoader;
