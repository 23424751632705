import intl from 'react-intl-universal';
import moment from 'moment';
import { Typography } from '@getsynapse/design-system';

const ResponseRatioComponent = ({ data }: { data: any }) => {
  const duration = moment.duration(data.avgResponseTime || 0, 'seconds');
  const days = duration.days();
  return (
    <>
      <div className='flex flex-row mt-3'>
        <div className='flex'>
          <p className='flex font-bold text-5xl ml-4 w-1/2'>
            {Math.round(data.responseRatio * 100)}%
          </p>
          <div className='flex flex-col mt-3 ml-1'>
            <Typography variant='label' className='ml-1 font-semibold'>
              {intl.get('INSIGHTS_PAGE_TABS.RESPONSE_RATIO.TITLE')}
            </Typography>
          </div>
        </div>
      </div>
      <div className='flex mt-auto ml-4 pb-2'>
        <Typography
          variant='caption'
          className='font-semibold flex justify-start text-neutral mr-2'
        >
          {intl.get('INSIGHTS_PAGE_TABS.RESPONSE_RATIO.AVERAGE_DAYS', {
            responseTime: days,
          })}
        </Typography>
      </div>
    </>
  );
};

export default ResponseRatioComponent;
