import { Fragment, useState, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import { Table, Tag, tailwindOverride } from '@getsynapse/design-system';
import { selectOrganizationCurrency } from 'state/Organization/organizationSlice';
import {
  setUsersOrder,
  exportUsers,
  selectPaginatedUsers,
  selectFilteredSortedUserIds,
  setFilters,
} from 'state/UsersManagement/usersManagementSlice';
import {
  USER_STATUS,
  USER_ROLES,
  PATHS,
  TABLE_EXPORT_OPTIONS,
  SETTINGS_FILTERS,
} from 'utils/constants';
import {
  AllUsersType,
  objKeyAsString,
  TableExportOptions,
} from 'utils/customTypes';
import UserAvatar from 'Atoms/UserAvatar';
import UsersTableHead from './UsersTableHead';
import UsersTableHeader from './UsersTableHeader';
import {
  createFilterSetting,
  selectFiltersSettingsByType,
  updateFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import { UserFilters } from 'utils/types/filters';
import { isUserStatusDisabled } from 'utils/functions';

const UsersTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currency = useSelector(selectOrganizationCurrency);
  const users = useSelector(selectPaginatedUsers);
  const allUsersIds = useSelector(selectFilteredSortedUserIds);
  const filtersSettings = useSelector(
    selectFiltersSettingsByType(SETTINGS_FILTERS.USERS_TABLE)
  );
  const mapTagColor: objKeyAsString = {
    [USER_STATUS.REGISTERED]: {
      className: 'bg-success-lightest group-hover:border-success-dark',
      textClassName: 'text-success-darker',
    },
    [USER_STATUS.INVITED]: {
      className: 'bg-warning-lightest group-hover:border-warning-light',
      textClassName: 'text-warning-darker',
    },
    [USER_STATUS.REGISTERED_DISABLED]: {
      className: 'bg-neutral-lighter-two group-hover:border-neutral',
      textClassName: 'text-neutral-darker',
    },
    [USER_STATUS.INVITED_DISABLED]: {
      className: 'bg-neutral-lighter-two group-hover:border-neutral',
      textClassName: 'text-neutral-darker',
    },
  };
  const [selectedUsersIds, setSelectedUsersIds] = useState<string[]>([]);
  const [showFilterComponent, setShowFilterComponent] = useState(false);
  const currentPageUsersIds = useMemo(
    () => users.map((user: AllUsersType) => user.id),
    [users]
  );
  const savedFilters = useMemo<UserFilters>(
    () => (filtersSettings ? filtersSettings.settings : {}),
    [filtersSettings]
  );

  useEffect(() => {
    if (savedFilters) {
      dispatch(setFilters(savedFilters));
    }
  }, [savedFilters, dispatch]);

  const isTableEmpty = users.length === 0;

  const handleSaveFiltersSettings = (updatedFilters: UserFilters) => {
    if (filtersSettings && filtersSettings.id) {
      dispatch(
        updateFilterSetting({
          id: filtersSettings.id,
          updateFields: { filter_settings: updatedFilters },
        })
      );
    } else {
      dispatch(
        createFilterSetting({
          filter_type: SETTINGS_FILTERS.USERS_TABLE,
          filter_settings: updatedFilters,
        })
      );
    }
  };

  const handleSort = (orderByParam: string[], order: 'desc' | 'asc') => {
    dispatch(setUsersOrder({ order, orderBy: orderByParam }));
  };

  const exportUsersToCsv = async (callback: () => void) => {
    await dispatch(exportUsers(selectedUsersIds));
    callback();
  };

  const handleToggleFilterComponent = () => {
    setShowFilterComponent((prevState) => !prevState);
  };

  const handleSelectExportOption = (
    exportOption: TableExportOptions | null
  ) => {
    if (exportOption === TABLE_EXPORT_OPTIONS.ALL) {
      setSelectedUsersIds(allUsersIds);
      return;
    }

    if (
      exportOption === TABLE_EXPORT_OPTIONS.CURRENT_PAGE &&
      currentPageUsersIds.length > 0
    ) {
      setSelectedUsersIds(currentPageUsersIds);
      return;
    }

    setSelectedUsersIds([]);
  };

  return (
    <Fragment>
      <UsersTableHeader
        savedFilters={savedFilters}
        saveFilters={handleSaveFiltersSettings}
        isFilterButtonDisabled={isTableEmpty}
        isExportButtonDisabled={selectedUsersIds.length === 0}
        handleExport={exportUsersToCsv}
        toggleFilterComponent={handleToggleFilterComponent}
        showFilterComponent={showFilterComponent}
      />
      <div
        data-cy='users-table-scrollable-container'
        className={classnames('w-full rounded-b max-h-request-details', {
          'overflow-auto border-t border-b border-neutral-lighter-two':
            !isTableEmpty,
        })}
      >
        <Table
          className={classnames('w-full', {
            'border-t-0 border-b-0': !isTableEmpty,
          })}
          selectedRowsIds={selectedUsersIds}
          isSelectRowCellSticky={true}
          onSelectRows={(usersIds: string[]) => setSelectedUsersIds(usersIds)}
          data={{
            headData: {
              stickyHeader: true,
              handleSort,
              headCells: UsersTableHead(),
              onSelectExportOption: handleSelectExportOption,
            },
            rows: users.map((user, index) => {
              const tagProps = mapTagColor[user.status];
              const isOdd: boolean = index % 2 !== 0;
              const isRowSelected = selectedUsersIds.includes(user.id);
              return {
                id: user.id,
                className: 'group cursor-pointer',
                onClick: () => history.push(`${PATHS.USER_PAGE}/${user.id}`),
                'data-testid': `table-row-${user.id}`,
                cells: [
                  {
                    content: (
                      <Link
                        to={`${PATHS.USER_PAGE}/${user.id}`}
                        className='flex items-start w-full relative'
                      >
                        <UserAvatar user={user} />
                        <div
                          className={tailwindOverride('ml-2.5', {
                            'text-neutral-dark': isUserStatusDisabled(
                              user.status
                            ),
                          })}
                        >
                          <div className='font-semibold'>
                            {user.data.firstName
                              ? `${user.data.firstName} ${user.data.lastName}`
                              : '-'}
                          </div>
                          <div className='text-neutral'>{user.data.email}</div>
                        </div>
                        {user.role === USER_ROLES.ADMIN && (
                          <Tag
                            label={intl.get(
                              `USERS_PAGE.TABLE.USER_ROLE.${user.role.toUpperCase()}`
                            )}
                            className={classnames(
                              'bg-tertiary-lightest absolute right-0',
                              'border border-transparent group-hover:border-tertiary'
                            )}
                            textClassName='text-tertiary-darker'
                          />
                        )}
                      </Link>
                    ),
                    className: classnames('leading-6 w-72 sticky left-14 z-1', {
                      'bg-neutral-lightest-two': isOdd && !isRowSelected,
                      'bg-neutral-white': !isOdd && !isRowSelected,
                    }),
                  },
                  {
                    content: intl.get(
                      `USERS_PAGE.TABLE.USER_TYPE.${user.type.toUpperCase()}`
                    ),
                    className: 'leading-4',
                  },
                  {
                    content: (
                      <div
                        className='max-w-md truncate'
                        title={get(user, 'team')}
                      >
                        {get(user, 'team')}
                      </div>
                    ),
                    className: 'leading-4',
                  },
                  {
                    content: user.data.jobTitle ? user.data.jobTitle : '-',
                    className: 'leading-4',
                  },
                  {
                    content: (
                      <div className='w-28'>
                        {intl.get(
                          `TEAMS.EMPLOYMENT_TYPE.${get(
                            user,
                            'data.employmentType',
                            'UNDEFINED'
                          ).toUpperCase()}`
                        )}
                      </div>
                    ),
                    className: 'leading-4 w-28',
                  },
                  {
                    content: `${
                      user.data.province ? user.data.province + ',' : ''
                    } ${intl.get(
                      'COUNTRIES.' + user.country_iso_3166_1_alpha_2_code
                    )}`,
                    className: 'leading-4',
                  },
                  {
                    content: (
                      <div className='w-22'>
                        {get(user, 'data.rateHour')
                          ? `${get(user, 'data.rateHour')} ${currency}`
                          : '-'}
                      </div>
                    ),
                    className: 'leading-4 w-22',
                  },
                  {
                    content: (
                      <div className='w-24'>
                        {user.status === USER_STATUS.INVITED &&
                        user.notified_user !== true ? (
                          <Tag
                            label={intl.get(
                              'USERS_PAGE.TABLE.USER_PENDING.PENDING'
                            )}
                            className={classnames(
                              'border border-transparent',
                              'bg-purple-lightest group-hover:border-purple-dark'
                            )}
                            textClassName='text-purple-darker'
                          />
                        ) : (
                          <Tag
                            label={intl.get(
                              `USERS_PAGE.TABLE.USER_STATUS.${user.status.toUpperCase()}`
                            )}
                            className={`border border-transparent ${tagProps.className}`}
                            textClassName={tagProps.textClassName}
                          />
                        )}
                      </div>
                    ),
                    className: 'w-24',
                  },
                ],
              };
            }),
            total: allUsersIds.length,
          }}
        />
      </div>
    </Fragment>
  );
};

export default UsersTable;
