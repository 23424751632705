import { useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ScrollHandlerContextType } from '../../../CapacityTable/components/ScrollHandler/ScrollHandlerContext';
import { VIEW_SETTING_VALUES } from 'utils/constants/capacityView';
import { fetchDailyTeamsCapacity } from 'state/DailyTeamsCapacity/TeamsCapacity/teamsCapacitySlice';
import {
  fetchDailyTeamMembersCapacity,
  removeTeamMembersCapacity,
} from 'state/DailyTeamsCapacity/TeamMembersCapacity/teamMembersCapacitySlice';
import {
  fetchDailyTeamMembeProjectsCapacity,
  removeTeamMemberProjects,
} from 'state/DailyTeamsCapacity/TeamMemberProjectsCapacity/teamMemberProjectsCapacitySlice';

const useDailyCapacity: () => ScrollHandlerContextType = () => {
  const [rootId, setRootId] = useState<Element | null>(null);
  const dispatch = useDispatch();

  const fetchTeamsCapacity = useCallback(
    (teamIds: string[]) => dispatch(fetchDailyTeamsCapacity({ teamIds })),
    [dispatch]
  );

  const fetchTeamMembersCapacity = useCallback(
    (teamId: string, membersIds: string[]) =>
      dispatch(fetchDailyTeamMembersCapacity({ teamId, membersIds })),
    [dispatch]
  );

  const fetchTeamMembeProjectsCapacity = useCallback(
    (teamId: string, memberId: string, projectsIds: string[]) =>
      dispatch(
        fetchDailyTeamMembeProjectsCapacity({ teamId, memberId, projectsIds })
      ),
    [dispatch]
  );

  const removeTeamMembersCapacityHandler = useCallback(
    (teamId: string, memberIds: string[]) =>
      dispatch(removeTeamMembersCapacity({ teamId, memberIds })),
    [dispatch]
  );

  const removeTeamMemberProjectsHandler = useCallback(
    (teamId: string, memberId: string) =>
      dispatch(removeTeamMemberProjects({ teamId, memberId })),
    [dispatch]
  );

  return useMemo(
    () => ({
      rootId,
      setRootId,
      capacityView: VIEW_SETTING_VALUES.DAILY,
      fetchTeamsCapacity,
      fetchTeamMembersCapacity,
      fetchTeamMembeProjectsCapacity,
      removeTeamMembersCapacity: removeTeamMembersCapacityHandler,
      removeTeamMemberProjects: removeTeamMemberProjectsHandler,
    }),
    [
      fetchTeamMembeProjectsCapacity,
      fetchTeamMembersCapacity,
      fetchTeamsCapacity,
      rootId,
      removeTeamMembersCapacityHandler,
      removeTeamMemberProjectsHandler,
    ]
  );
};

export default useDailyCapacity;
