import {
  Datepicker,
  Dropdown,
  FormItem,
  Modal,
  TextArea,
  Typography,
  UsersPicker,
} from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import { selectLDUsersForDropdown } from 'state/UsersManagement/usersManagementSlice';
import { useMemo, useEffect } from 'react';
import {
  resetProject,
  getCurrentProjectData,
} from 'state/Project/projectSlice';
import { getStatusColumn } from 'Pages/ProjectsListPage/helpers/tableColumnsValues';
import {
  selectProjectCategories,
  selectBussinessTeams,
} from 'state/Organization/organizationSlice';
import { PROJECT_STATUS } from 'utils/constants';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

const LinkedProjectModal = ({ isOpen, closeModal }: Props) => {
  const dispatch = useDispatch();
  const ldUsers = useSelector(selectLDUsersForDropdown);
  const project = useSelector(getCurrentProjectData);
  const projectCategories = useSelector(selectProjectCategories);
  const bussinessTeams = useSelector(selectBussinessTeams);

  useEffect(() => {
    return () => {
      dispatch(resetProject());
    };
  }, [dispatch]);

  const owners = useMemo(() => {
    return ldUsers.filter((ldUser) => project.owners?.includes(ldUser.value));
  }, [project, ldUsers]);

  const getCategoryName = (categoryId: string) =>
    projectCategories.find((category) => category.id === categoryId)
      ?.categoryName;

  const getBusinessTeams = (teams: string[]) => {
    const options = bussinessTeams.filter((team) => teams.includes(team.id));
    return options.map((option) => ({
      label: option.title,
      value: option.id,
    }));
  };

  return (
    <Modal
      childrenClassName='max-h-100'
      data-testid='linked-project_modal'
      isOpen={isOpen}
      title={project.title}
      aria-label={intl.get('REQUEST_PAGE.LINKED_PROJECTS.MODAL.SUB_TITLE')}
      closeModal={closeModal}
      size='large'
      actionButtons={[
        {
          children: intl.get('REQUEST_PAGE.LINKED_PROJECTS.MODAL.CLOSE_BUTTON'),
          variant: 'primary',
          onClick: closeModal,
          'data-cy': 'close-button',
        },
      ]}
    >
      <div>
        <Typography variant='h6'>
          {intl.get('REQUEST_PAGE.LINKED_PROJECTS.MODAL.TITLE')}
        </Typography>
        <Typography variant='body2' className='text-neutral-dark mt-1 mb-4'>
          {intl.get('REQUEST_PAGE.LINKED_PROJECTS.MODAL.SUB_TITLE')}
        </Typography>
        <div className='grid grid-cols-2 gap-x-10 gap-y-4'>
          <FormItem label={intl.get('PROJECT_DETAIL.OWNERS')}>
            <UsersPicker
              readOnly
              usersList={[]}
              selectedUsersList={owners}
              onChange={() => {}}
            />
          </FormItem>
          <FormItem label={intl.get('PROJECT_DETAIL.STATUS')}>
            {project.status && getStatusColumn(project.status)}
          </FormItem>
          <FormItem
            className='row-span-2'
            label={intl.get('PROJECT_DETAIL.DESCRIPTION')}
          >
            <TextArea
              value={project.description}
              readOnly
              textAreaProps={{ className: 'max-h-32' }}
            />
          </FormItem>
          <FormItem label={intl.get('PROJECT_DETAIL.BUSINESS_UNIT')}>
            <Dropdown
              readOnly
              className='max-w-94'
              onChange={() => {}}
              options={[]}
              values={
                project.businessTeams!.length
                  ? getBusinessTeams(project.businessTeams!)
                  : []
              }
            />
          </FormItem>
          <FormItem label={intl.get('PROJECT_DETAIL.CATEGORY')}>
            <Dropdown
              readOnly
              onChange={() => {}}
              options={[]}
              values={[
                {
                  label: project.category_id
                    ? getCategoryName(project.category_id)
                    : '',
                  value: project.categoryId,
                },
              ]}
            />
          </FormItem>
          <FormItem label={intl.get('PROJECT_DETAIL.START_DATE')}>
            <Datepicker
              startDate={project.startDate}
              readOnly
              className='w-full'
            />
          </FormItem>
          <FormItem
            label={intl.get(
              `PROJECT_DETAIL.${
                project.status === PROJECT_STATUS.COMPLETED
                  ? 'ACTUAL_COMPLETION_DATE'
                  : 'END_DATE'
              }`
            )}
          >
            <Datepicker
              startDate={
                project.status === PROJECT_STATUS.COMPLETED
                  ? project.actualDate
                  : project.targetCompletionDate
              }
              readOnly
              className='w-full'
              placeholder=''
            />
          </FormItem>
        </div>
      </div>
    </Modal>
  );
};

export default LinkedProjectModal;
