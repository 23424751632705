import { Modal, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

type Props = {
  fileName: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  replaceFile: () => void;
};

const ReplaceEvidenceModal = ({
  fileName,
  isOpen,
  setIsOpen,
  replaceFile,
}: Props) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      data-testid='replcae-evaluation-evidence_modal'
      isOpen={isOpen}
      title={intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.EVIDENCE.REPLACE_EVIDENCE_MODAL.TITLE'
      )}
      titleIcon={{
        name: 'alert-circle',
        className: 'text-warning-darker',
      }}
      aria-label={intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.EVIDENCE.REPLACE_EVIDENCE_MODAL.TITLE'
      )}
      closeModal={closeModal}
      size='medium'
      actionButtons={[
        {
          children: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.EVIDENCE.REPLACE_EVIDENCE_MODAL.REPLACE_BUTTON'
          ),
          variant: 'primary',
          color: 'warning',
          onClick: () => {
            replaceFile();
            closeModal();
          },
          'data-testid': 'replace-evidence-modal-replace_button',
        },
        {
          children: intl.get('CANCEL'),
          variant: 'tertiary',
          onClick: closeModal,
          'data-testid': 'replace-evidence-modal-cancel_button',
        },
      ]}
    >
      <Typography data-testid='replace-evidence-modal_body'>
        {intl.getHTML(
          'PROGRAM_PAGE.STRATEGY_PAGE.EVIDENCE.REPLACE_EVIDENCE_MODAL.BODY_TEXT',
          { fileName }
        )}
      </Typography>
    </Modal>
  );
};

export default ReplaceEvidenceModal;
