import '../node_modules/@syncfusion/ej2-base/styles/tailwind.css';
import '../node_modules/@syncfusion/ej2-buttons/styles/tailwind.css';
import '../node_modules/@syncfusion/ej2-calendars/styles/tailwind.css';
import '../node_modules/@syncfusion/ej2-dropdowns/styles/tailwind.css';
import '../node_modules/@syncfusion/ej2-inputs/styles/tailwind.css';
import '../node_modules/@syncfusion/ej2-lists/styles/tailwind.css';
import '../node_modules/@syncfusion/ej2-navigations/styles/tailwind.css';
import '../node_modules/@syncfusion/ej2-popups/styles/tailwind.css';
import '../node_modules/@syncfusion/ej2-splitbuttons/styles/tailwind.css';
import '../node_modules/@syncfusion/ej2-react-schedule/styles/tailwind.css';
import { registerLicense } from '@syncfusion/ej2-base';

const initSyncfusion = () => {
  registerLicense(process.env.REACT_APP_SYNCFUSION_KEY || '');
};

export default initSyncfusion;
