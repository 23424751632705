import { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { ProjectTemplate } from 'utils/types/templates';
import { selectUserId } from 'state/User/userSlice';
import { allUsers as getAllUsers } from 'state/UsersManagement/usersManagementSlice';
import {
  PROJECT_TEMPLATE_ACCESS_VALUES,
  PROJECT_TEMPLATE_STATUS_VALUES,
  PROJECT_TEMPLATE_RESOURCE_TYPE_VALUES,
  SLICE_STATUS,
} from 'utils/constants';
import { Status } from 'utils/customTypes';

const defaultData: Omit<ProjectTemplate, 'createdAt, updatedAt, deletedAt'> = {
  name: '',
  description: '',
  owner_id: '',
  creator_id: '',
  access: PROJECT_TEMPLATE_ACCESS_VALUES.USER,
  status: PROJECT_TEMPLATE_STATUS_VALUES.DRAFT,
  resource_type: PROJECT_TEMPLATE_RESOURCE_TYPE_VALUES.INTERNAL,
  hidden: false,
  project_template_fields: [],
};

const useTemplateForm = (
  initialData?: ProjectTemplate,
  pageStatus: Status = SLICE_STATUS.IDLE
) => {
  const currentUserId = useSelector(selectUserId);
  const allUsers = useSelector(getAllUsers);
  const [templateData, setTemplateData] = useState<ProjectTemplate>({
    ...defaultData,
  });

  useEffect(() => {
    if (!initialData) {
      if (allUsers.length > 0 && currentUserId) {
        const currentUser = allUsers.find((user) => user.id === currentUserId);
        setTemplateData((prevState) => ({
          ...prevState,
          creator_id: currentUserId,
          creator: currentUser,
          owner_id: currentUserId,
          owner: currentUser,
        }));
      }
    }
  }, [currentUserId, initialData, allUsers]);

  useEffect(() => {
    if (initialData?.id && initialData?.id !== templateData.id) {
      setTemplateData((prevState) => ({
        ...prevState,
        ...initialData,
      }));
    }
  }, [initialData, templateData.id]);

  const handleFieldChange = useCallback(
    (key: keyof ProjectTemplate, value: any) => {
      return setTemplateData((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
    []
  );

  const canSubmitForm = useMemo(() => {
    const isUpdating = templateData.id;
    let response = false;
    if (pageStatus === SLICE_STATUS.LOADING) {
      return false;
    }
    if (!isUpdating) {
      response =
        !isEmpty(templateData.name) && !isEmpty(templateData.description);
    } else {
      if (
        initialData?.name !== templateData.name ||
        initialData?.description !== templateData.description ||
        initialData.owner_id !== templateData.owner_id
      ) {
        response = true;
      } else if (
        initialData.project_template_fields.length !==
        templateData.project_template_fields.length
      ) {
        response = true;
      } else {
        response = initialData.project_template_fields.some((field) => {
          const currentField = templateData.project_template_fields.find(
            (currentField) => currentField.id === field.id
          );
          if (currentField) {
            return (
              currentField.required !== field.required ||
              currentField.layout !== field.layout
            );
          } else {
            return true;
          }
        });
      }
    }
    return response;
  }, [templateData, initialData, pageStatus]);

  return {
    templateData,
    handleFieldChange,
    canSubmitForm,
  };
};

export default useTemplateForm;
