export const REQUEST_STATUSES = {
  SUBMITTED: 'submitted',
  DRAFT: 'draft',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PENDING_CANCELLATION: 'pending_cancellation',
  CANCELED: 'canceled',
  WAITLISTED: 'waitlisted',
} as const;

export const EDITABLE_REQUEST_STATUSES = [
  REQUEST_STATUSES.APPROVED,
  REQUEST_STATUSES.WAITLISTED,
  REQUEST_STATUSES.SUBMITTED,
  REQUEST_STATUSES.REJECTED,
] as const;
