import {
  UpdateReqData,
  Request,
  checkboxOption,
  RequestQuestion,
} from 'utils/customTypes';
import { filter, get } from 'lodash';

export const validateRequestRequiredQuestion = (question: RequestQuestion) =>
  (question.data.isRequired &&
    question.type !== 'toggle' &&
    !question.data.value) ||
  (question.data.isRequired &&
    ['dropdown', 'radio'].includes(question.type) &&
    !question.data.value.value) ||
  (question.data.isRequired &&
    question.type === 'checkbox' &&
    question.data.value.items.every((item: checkboxOption) => !item.checked)) ||
  (question.data.isRequired &&
    question.type === 'file' &&
    !question.data.value.length);

export const validateRequiredFields = (
  title: string,
  requestData: Request,
  updatedReqData: UpdateReqData,
  shouldUseUserBusinessTeamAsDefault: boolean,
  isForm: boolean
) => {
  const isTitleValid = title !== '';
  let areQuestionsValid = true;
  if (updatedReqData && updatedReqData.requestQuestions) {
    const requiredQuestionsWithoutAnswers = filter(
      updatedReqData.requestQuestions,
      validateRequestRequiredQuestion
    );
    if (requiredQuestionsWithoutAnswers.length) {
      areQuestionsValid = false;
    }
  }
  let areBusinessTeamsValid = true;
  if (!shouldUseUserBusinessTeamAsDefault) {
    const updatedBusinessTeams = get(
      updatedReqData,
      'requestAttributes.businessTeams.length'
    );
    const prevBusinessTeams = get(requestData, 'businessTeams.length');
    if (isForm && !updatedBusinessTeams) {
      areBusinessTeamsValid = false;
    } else if (
      (updatedBusinessTeams <= 0 || updatedBusinessTeams === undefined) &&
      (prevBusinessTeams <= 0 || prevBusinessTeams === undefined)
    ) {
      areBusinessTeamsValid = false;
    }
  }
  const isRequesterValid =
    get(updatedReqData, 'requestAttributes.requester_id') !== '';
  return (
    isTitleValid &&
    areQuestionsValid &&
    areBusinessTeamsValid &&
    isRequesterValid
  );
};
