import intl from 'react-intl-universal';
import { Typography, FormItem, RadioGroup } from '@getsynapse/design-system';
import { useMemo, ChangeEvent } from 'react';

type Props = {
  isMonetary: boolean | null;
  updateData: (value: boolean, key: string) => void;
};

const BenefitMeasurement = ({ isMonetary, updateData }: Props) => {
  const isMonetaryValue = useMemo(() => {
    switch (isMonetary) {
      case true:
        return 'true';
      case false:
        return 'false';
      default:
        return '';
    }
  }, [isMonetary]);

  const isBenefitMonetaryOptions = useMemo(
    () => [
      {
        label: intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.BENEFIT_MEASUREMENT.IS_MONETARY_OPTIONS.YES'
        ),
        value: 'true',
      },
      {
        label: intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.BENEFIT_MEASUREMENT.IS_MONETARY_OPTIONS.NO'
        ),
        value: 'false',
      },
    ],
    []
  );

  return (
    <div className='pl-8'>
      <Typography variant='h5' className='mb-1'>
        {intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.BENEFIT_MEASUREMENT.TITLE'
        )}
      </Typography>
      <Typography variant='body2' className='text-neutral-darker mb-5'>
        {intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.BENEFIT_MEASUREMENT.SUBTITLE'
        )}
      </Typography>
      <FormItem
        label={intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.BENEFIT_MEASUREMENT.IS_MONETARY'
        )}
        labelProps={{
          className: 'text-base',
        }}
      >
        <RadioGroup
          className='w-max'
          checkOption='checked'
          checked={isMonetaryValue}
          name='is-benefit-monetary'
          options={isBenefitMonetaryOptions}
          inputProps={{
            onChange: (event: ChangeEvent<HTMLInputElement>) =>
              updateData(event.target.value === 'true', 'isMonetary'),
          }}
          data-testid='is-monetary_radio_group'
        />
      </FormItem>
    </div>
  );
};

export default BenefitMeasurement;
