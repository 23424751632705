import cloneDeep from 'lodash/cloneDeep';
import { debounce, get } from 'lodash';
import set from 'lodash/set';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { PickerFileMetadata } from 'filestack-js';
import classnames from 'classnames';
import RadioQuestion from './Questions/RadioQuestion';
import CheckboxQuestion from './Questions/CheckboxQuestion';
import RangeQuestion from './Questions/RangeQuestion';
import DateQuestion from './Questions/DateQuestion';
import DropdownQuestion from './Questions/DropdownQuestion';
import NumberQuestion from './Questions/NumberQuestion';
import TextFieldQuestion from './Questions/TextFieldQuestion';
import ToggleQuestion from './Questions/ToggleQuestion';
import UrlQuestion from './Questions/UrlQuestion';
import Comments from 'Organisms/Comments/Comments';
import {
  deleteQuestionComment,
  getQuestionComments,
  postQuestionComment,
  selectQuestionComments,
  updateQuestionComment,
} from 'state/RequestComments/requestCommentsSlice';
import { deleteRequestQuestions } from 'state/ActiveRequest/activeRequestSlice';
import {
  FormLabel,
  OverflowMenu,
  OverflowMenuItem,
  useLink,
} from '@getsynapse/design-system';
import FileUploadQuestion from './Questions/FileUploadQuestion';
import {
  dropdownOption,
  intakeQuestionWrapper,
  rangeDate,
  objKeyAsString,
  RequestQuestion,
  ProjectCommentMentionsData,
} from 'utils/customTypes';
import { parseURL, extractContent } from 'utils/functions';
import LabelLinkPrompt from 'Organisms/LabelLinkPrompt/LabelLinkPrompt';

type qnsComponentMap = {
  [key: string]: (props: intakeQuestionWrapper) => JSX.Element;
};

const questionComponent: qnsComponentMap = {
  radio: RadioQuestion,
  checkbox: CheckboxQuestion,
  range: RangeQuestion,
  date: DateQuestion,
  dropdown: DropdownQuestion,
  number: NumberQuestion,
  customTextArea: TextFieldQuestion,
  url: UrlQuestion,
  file: FileUploadQuestion,
  toggle: ToggleQuestion,
};

export const IntakeQuestions = ({
  questions,
  requestId,
  showComment,
  setUpdatedReqData,
  disableFields,
  questionIdParam,
}: {
  questions: RequestQuestion[];
  requestId: string;
  showComment?: boolean;
  setUpdatedReqData: any;
  disableFields: boolean;
  testId?: string;
  questionIdParam?: string;
}) => {
  const dispatch = useDispatch();
  const commentsSelector = useSelector(selectQuestionComments);
  const [qnsInEdit, setQnsInEdit] = useState('');
  const [labels, setLabels] = useState<objKeyAsString>({});
  const [questionPrompt, setQuestionPrompt] = useState<string>('');
  const [promptLink, setPromptLink] = useState<string>('');
  const linkClassName = useLink(true);

  useEffect(() => {
    if (requestId) {
      dispatch(getQuestionComments(requestId));
    }
  }, [dispatch, requestId]);

  const submitChangeHandler = debounce(
    (
      question: any,
      value:
        | string
        | boolean
        | number
        | Array<PickerFileMetadata>
        | dropdownOption
        | dropdownOption[]
        | rangeDate,
      path: string
    ) => {
      setUpdatedReqData((prevState: any) => {
        let updatedQuestion;
        if (question.id in prevState.requestQuestions) {
          updatedQuestion = cloneDeep(prevState.requestQuestions[question.id]);
        } else {
          updatedQuestion = cloneDeep(question);
        }
        set(updatedQuestion, path, value);
        return {
          ...prevState,
          ...{
            requestQuestions: {
              ...prevState.requestQuestions,
              ...{ [updatedQuestion.id]: updatedQuestion },
            },
          },
        };
      });
    },
    500
  );

  const getComment = (questionId: string) => {
    const questionComments = commentsSelector.find(
      (question: any) => question.id === questionId
    );
    return get(questionComments, 'comments', []).map((comment) => ({
      id: comment.id,
      content: comment.content,
      data: comment.data,
      author: comment.author,
      createdAt: comment.createdAt,
      updatedAt: comment.updatedAt,
    }));
  };

  const onEditComment = ({
    commentId,
    content,
    questionId,
    mentionsData,
  }: {
    commentId: string;
    content: string;
    questionId: string;
    mentionsData: ProjectCommentMentionsData;
  }) => {
    dispatch(
      updateQuestionComment({ commentId, content, questionId, mentionsData })
    );
  };

  const onDeleteComment = (commentId: string, questionId: string) => {
    const questionComments = commentsSelector.find(
      (question: any) => question.id === questionId
    );
    dispatch(
      deleteQuestionComment({ commentId, threadId: questionComments!.id })
    );
  };

  const deleteQuestion = async (questionId: string) => {
    await dispatch(deleteRequestQuestions({ questionId, requestId }));
  };

  const onCreateComment = async (
    questionId: string,
    content: string,
    mentionsData: ProjectCommentMentionsData
  ) => {
    dispatch(postQuestionComment({ questionId, content, mentionsData }));
  };

  const onClickLabel = (
    event: React.MouseEvent<HTMLElement>,
    question: RequestQuestion
  ) => {
    if (question.section === 'additionalDetails') {
      if ((event.target as Element).tagName === 'A') {
        event.preventDefault();
        setQuestionPrompt(question.id);
        setPromptLink((event.target as HTMLAnchorElement).href);
      } else {
        setQnsInEdit(question.id);
      }
    }
  };

  return (
    <>
      {questions.map((question: any) => {
        const Question = questionComponent[question.type];
        const isEditing = qnsInEdit === question.id;
        const questionLabel =
          question.id in labels
            ? extractContent(labels[question.id])
            : extractContent(question.data.label);

        return Question ? (
          <div className='mt-4 relative' key={question.id}>
            <div className='mb-2 flex items-center'>
              <LabelLinkPrompt
                isOpen={questionPrompt === question.id}
                link={promptLink}
                onClose={() => setQuestionPrompt('')}
                onEdit={() => setQnsInEdit(question.id)}
              />
              {!isEditing ? (
                <FormLabel
                  component='label'
                  required={question.data.isRequired}
                  className={classnames(
                    'mb-0 flex-grow',
                    {
                      'text-neutral': !questionLabel,
                    },
                    { 'text-neutral-light': disableFields }
                  )}
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    onClickLabel(event, question)
                  }
                  data-testid={`question-${question.id}__label`}
                >
                  {questionLabel ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: parseURL(questionLabel, linkClassName),
                      }}
                    />
                  ) : (
                    intl.get(
                      'SETTINGS_PAGE.FORMS.DESIGN.CUSTOM_QUESTIONS.QUESTION_LABEL'
                    )
                  )}
                </FormLabel>
              ) : (
                <input
                  className={classnames(
                    'bg-primary-lighter focus:outline-none text-sm font-semibold leading-4 w-full'
                  )}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLabels((prev) => ({
                      ...prev,
                      [question.id]: parseURL(
                        event.target.value,
                        linkClassName
                      ),
                    }));
                    submitChangeHandler(
                      question,
                      parseURL(event.target.value, linkClassName),
                      'data.label'
                    );
                  }}
                  defaultValue={questionLabel}
                  id={`input-${question.id}`}
                  autoFocus
                  onBlur={() => setQnsInEdit('')}
                />
              )}
              {showComment && (
                <Comments
                  className='ml-2'
                  comments={getComment(question.id)}
                  onEdit={({ commentId, content, mentionsData }) =>
                    onEditComment({
                      commentId,
                      content,
                      questionId: question.id,
                      mentionsData,
                    })
                  }
                  onDelete={(commentId) =>
                    onDeleteComment(commentId, question.id)
                  }
                  onCreate={(content, mentionsData) =>
                    onCreateComment(question.id, content, mentionsData)
                  }
                  testId={question.id}
                  isPopupOpen={questionIdParam === question.id}
                />
              )}
              {question.section === 'additionalDetails' && (
                <OverflowMenu
                  menuButtonProps={{
                    'data-testid': `${question.id}-menu-ellipsis`,
                    className: 'ml-2',
                  }}
                >
                  <OverflowMenuItem
                    data-testid={`${question.id}-delete-option`}
                    onSelect={() => deleteQuestion(question.id)}
                  >
                    {intl.get('COMMENT.DELETE')}
                  </OverflowMenuItem>
                </OverflowMenu>
              )}
            </div>
            <Question
              question={question}
              handler={submitChangeHandler}
              disabled={disableFields}
            />
          </div>
        ) : null;
      })}
    </>
  );
};

export default IntakeQuestions;
