import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { X_Y_AXIS_STYLE } from './constants';
import { getLineElements } from 'Pages/InsightsPage/Intake/RequestsBy/Elements';
import { IconButton, Typography, Tooltip } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import {
  selectDCATrend,
  selectDCATrendStatus,
} from 'state/Insights/capacitySlice';
import { SLICE_STATUS } from 'utils/constants';
import NoData from '../components/NoData';
import LoadingError from '../components/LoadingError';
import ChartSkeletonLoader from './ChartSkeletonLoader';

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
}) =>
  active &&
  payload &&
  payload.length && (
    <div className='bg-neutral-white rounded border border-purple-lighter p-4 shadow-raised'>
      <Typography
        className='text-neutral-dark text-center'
        variant='body2'
        weight='medium'
      >
        {payload[0].payload.name}
      </Typography>
      <div className='flex items-center'>
        <div className='w-3.5 h-3.5 rounded-sm bg-secondary-dark mr-4'></div>
        <Typography className='text-neutral-dark' variant='body2'>
          {`${intl.get(
            'CAPACITY_INSIGHTS.DEMAND_CAPACITY_AVAILABILITY.AVAILABILITY'
          )} : ${payload[0].payload.Availability}`}
        </Typography>
      </div>
      <div className='flex items-center'>
        <div className='w-3.5 h-3.5 rounded-sm bg-warning-light mr-4'></div>
        <Typography className='text-neutral-dark' variant='body2'>
          {`${intl.get(
            'CAPACITY_INSIGHTS.DEMAND_CAPACITY_AVAILABILITY.CAPACITY'
          )} : ${payload[0].payload.Capacity}`}
        </Typography>
      </div>
      <div className='flex items-center'>
        <div className='w-3.5 h-3.5 rounded-sm bg-tertiary-dark mr-4'></div>
        <Typography className='text-neutral-dark' variant='body2'>
          {`${intl.get(
            'CAPACITY_INSIGHTS.DEMAND_CAPACITY_AVAILABILITY.DEMAND'
          )} : ${payload[0].payload.Demand}`}
        </Typography>
      </div>
    </div>
  );

const DemandCapAvailabilityChart = () => {
  const dcaTrendData = useSelector(selectDCATrend);
  const dcaTrendStatus = useSelector(selectDCATrendStatus);

  const renderColorfulLegendText = (value: string) => {
    return (
      <span style={{ ...X_Y_AXIS_STYLE, ...{ paddingRight: '1rem' } }}>
        {value}
      </span>
    );
  };

  return (
    <div
      data-testid='dem-cap-avail'
      className='flex flex-col w-full pt-6 pl-6 pr-10'
    >
      {dcaTrendStatus === SLICE_STATUS.LOADING ? (
        <ChartSkeletonLoader />
      ) : (
        <>
          <div className='flex'>
            <div>
              <p className='text-3.5 leading-2 text-neutral-black font-semibold'>
                {intl.get(
                  'CAPACITY_INSIGHTS.DEMAND_CAPACITY_AVAILABILITY.TITLE'
                )}
              </p>
              <Typography variant='caption' className='text-neutral-dark'>
                {intl.get(
                  'CAPACITY_INSIGHTS.DEMAND_CAPACITY_AVAILABILITY.CAPTION'
                )}
              </Typography>
            </div>
            <Tooltip
              trigger={
                <IconButton
                  name='information-circle'
                  className='w-5 h-5 ml-4 text-purple-darker hover:text-purple-darker items-center'
                  description={intl.get(
                    'CAPACITY_INSIGHTS.DEMAND_CAPACITY_AVAILABILITY.TOOLTIP_TRIGGER_DESC'
                  )}
                  iconClassname='pointer-events-none'
                />
              }
              openMode='hover1'
              contentProps={{
                className:
                  'bg-neutral-white z-50 text-neutral-black border-purple-lighter border',
              }}
              className='-top-1'
            >
              {intl.get(
                'CAPACITY_INSIGHTS.DEMAND_CAPACITY_AVAILABILITY.TOOLTIP'
              )}
            </Tooltip>
          </div>
          {dcaTrendData?.length ? (
            <ResponsiveContainer
              className='my-6 min-h-10'
              width='100%'
              height='100%'
              id='dca-trend-line-chart'
            >
              <LineChart margin={{ top: 8 }} data={dcaTrendData}>
                <XAxis
                  tickLine={false}
                  dataKey='name'
                  style={X_Y_AXIS_STYLE}
                  padding={{ left: 40, right: 40 }}
                  minTickGap={32}
                />
                <YAxis
                  unit='h'
                  tickLine={false}
                  style={X_Y_AXIS_STYLE}
                  tickCount={4}
                  padding={{ top: 15 }}
                />
                <ChartTooltip content={<CustomTooltip />} />
                <Legend
                  iconSize={8}
                  iconType='circle'
                  layout='horizontal'
                  formatter={renderColorfulLegendText}
                />
                {getLineElements(
                  [
                    intl.get(
                      'CAPACITY_INSIGHTS.DEMAND_CAPACITY_AVAILABILITY.AVAILABILITY'
                    ),
                    intl.get(
                      'CAPACITY_INSIGHTS.DEMAND_CAPACITY_AVAILABILITY.CAPACITY'
                    ),
                    intl.get(
                      'CAPACITY_INSIGHTS.DEMAND_CAPACITY_AVAILABILITY.DEMAND'
                    ),
                  ],
                  false
                )}
              </LineChart>
            </ResponsiveContainer>
          ) : dcaTrendData ? (
            <NoData />
          ) : (
            <LoadingError />
          )}
        </>
      )}
    </div>
  );
};

export default DemandCapAvailabilityChart;
