import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import { trackEvent } from 'Services/pendo';
import {
  FormItem,
  LargeLabel,
  Modal,
  NumericInput,
  Typography,
} from '@getsynapse/design-system';
import { getProgramDescription } from 'state/Program/programSlice';
import addObjectiveIcon from 'assets/icons/add-objective.svg';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { PENDO_EVENTS } from 'utils/constants';
import CategoryPrompts from 'Pages/StrategyCategoryPage/components/CategoryPrompts';
import classNames from 'classnames';
import { createROIObjective } from 'state/ROICategory/ROICategoySlice';
import { STRATEGY_CATEGORIES } from 'utils/constants/program';

type AddCategoryObjectiveModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  prompts: string[];
};

const AddROIObjectiveModal = ({
  isOpen,
  closeModal,
  prompts,
}: AddCategoryObjectiveModalProps) => {
  const dispatch = useDispatch();

  const { programId, categoryId } = useParams<{
    programId: string;
    categoryId: string;
  }>();

  const programDescription = useSelector(getProgramDescription);

  const [roi, setROI] = useState<string>('');
  const [isROINumberValid, setIsROINumberValid] = useState<boolean>(false);

  const onCloseModal = () => {
    setROI('');
    closeModal();
  };
  const showNotification = (
    variant: 'success' | 'error',
    title: string,
    message: string = ''
  ) => {
    dispatch(
      showSnackbarNotification({
        autoHide: false,
        notificationVariant: variant,
        notificationTitle: title,
        notificationMessage: message,
      })
    );
  };

  const handleSave = async () => {
    trackEvent(PENDO_EVENTS.CTA_SAVE_OBJECTIVES, {
      category: STRATEGY_CATEGORIES.ROI,
    });
    try {
      await dispatch(
        createROIObjective({ programId, categoryId, roi: Number(roi) })
      );
      showNotification(
        'success',
        intl.get('ROI_CATEGORY.OBJECTIVES_SETTING.ADD_SUCCESS_NOTIFICATION')
      );
      trackEvent(PENDO_EVENTS.CREATE_OBJECTIVE, {
        newObjectiveCount: 1,
      });
    } catch (error) {
      showNotification(
        'error',
        intl.get('PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.ERROR.TITLE'),
        intl.get('PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.ERROR.MESSAGE')
      );
    }
    onCloseModal();
  };

  return (
    <Modal
      aria-label={intl.get(
        'ROI_CATEGORY.OBJECTIVES_SETTING.ADD_OBJECTIVE_MODAL.TITLE'
      )}
      closeModal={onCloseModal}
      title={intl.get(
        'ROI_CATEGORY.OBJECTIVES_SETTING.ADD_OBJECTIVE_MODAL.TITLE'
      )}
      titleIcon={{
        src: addObjectiveIcon,
        className: 'text-fire -mr-1',
      }}
      size='large'
      isOpen={isOpen}
      actionButtons={[
        {
          children: intl.get(
            'ROI_CATEGORY.OBJECTIVES_SETTING.ADD_OBJECTIVE_MODAL.CONFIRM_BUTTON'
          ),
          variant: 'primary',
          onClick: handleSave,
          disabled: roi === '' || !isROINumberValid,
          'data-testid': 'save-roi-objective-button',
        },
        {
          children: intl.get('CANCEL'),
          variant: 'tertiary',
          onClick: onCloseModal,
          'data-testid': 'cancel-save-roi-objective-button',
        },
      ]}
    >
      <div className='flex'>
        <FormItem
          className={classNames(
            'mr-8',
            `${prompts?.length! === 0 ? 'w-full' : 'w-3/5'}`
          )}
          label={
            <LargeLabel
              label={intl.get(
                'ROI_CATEGORY.OBJECTIVES_SETTING.ADD_OBJECTIVE_MODAL.OBJECTIVE_FIELD_LABEL'
              )}
              subtitle={intl.get(
                'ROI_CATEGORY.OBJECTIVES_SETTING.ADD_OBJECTIVE_MODAL.OBJECTIVE_FIELD_SUBTITLE'
              )}
              subtitleProps={{
                className: 'text-neutral-darker mb-2',
              }}
              name='roi-objective'
            />
          }
        >
          <div className='flex'>
            <NumericInput
              containerClassName='w-full'
              placeholder={intl.get(
                'ROI_CATEGORY.OBJECTIVES_SETTING.ADD_OBJECTIVE_MODAL.OBJECTIVE_FIELD_PLACEHOLDER'
              )}
              id='roi-objective'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setROI(event.target.value)
              }
              allowFloatValue={false}
              allowNegativeValue={false}
              getIsInputValid={(isValid) => setIsROINumberValid(isValid)}
              data-testid='roi-objective-input'
            />
            <Typography className='ml-2 mt-2'>
              {intl.get('PERCENT_SIGN')}
            </Typography>
          </div>
        </FormItem>
        {prompts?.length > 0 && (
          <CategoryPrompts
            categoryPrompts={prompts}
            programDescription={programDescription!}
          />
        )}
      </div>
    </Modal>
  );
};

export default AddROIObjectiveModal;
