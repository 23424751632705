import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { INSIGHTS_PAGE_COLORS } from 'utils/constants';
import { ProjectsCustomTooltip } from 'Pages/InsightsPage/Projects/ProjectsCustomTooltip';
import { formatStatusTitle } from 'Pages/InsightsPage/Projects/ByStatus/ByStatusBarChart';

export const TasksByStatusBarChart = ({
  data,
}: {
  data?: {
    title: string;
    value: number;
  }[];
}) => {
  return (
    <ResponsiveContainer height='100%' width='90%'>
      <BarChart
        data={data}
        layout='vertical'
        barGap={8}
        barSize={30}
        maxBarSize={20}
      >
        <XAxis
          type='number'
          domain={[0, 'dataMax + 2']}
          style={INSIGHTS_PAGE_COLORS.PROJECTS.BY_PROCESS.BAR_CHART_AXIES_STYLE}
          tickLine={false}
          stroke={INSIGHTS_PAGE_COLORS.STROKE}
          padding={{
            left: 1,
          }}
        />
        <YAxis
          style={INSIGHTS_PAGE_COLORS.PROJECTS.BY_PROCESS.BAR_CHART_AXIES_STYLE}
          dataKey='title'
          type='category'
          tickFormatter={formatStatusTitle}
          tickLine={false}
          stroke={INSIGHTS_PAGE_COLORS.STROKE}
          width={95}
          interval={0}
          padding={{
            bottom: 8,
          }}
        />

        <Tooltip content={<ProjectsCustomTooltip chartType='Bar' />} />

        <Bar
          dataKey='value'
          fill={
            INSIGHTS_PAGE_COLORS.PROJECTS.TASKS_BY_STATUS.BAR_CHART_MAIN_COLOR
          }
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
