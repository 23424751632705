import intl from 'react-intl-universal';
import { Button, Typography } from '@getsynapse/design-system';
import { AdditionalBenefit } from 'utils/types/program';
import AdditionalBenefitCard from './components/AdditionalBenefitCard';

const AdditionalBenefits = ({
  handleClick,
  benefits,
}: {
  handleClick: () => void;
  benefits: AdditionalBenefit[];
}) => {
  return (
    <div className='mt-8 flex flex-col'>
      <div>
        <Typography
          data-testid='additional-benefits__title'
          variant='h6'
          className='pb-2 leading-7 font-semibold'
        >
          {intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.ADDITIONAL_BENEFITS.SECTION_TITLE'
          )}
        </Typography>
        <div className='flex justify-between'>
          <Typography
            className='text-neutral-darker w-5/6'
            data-testid='additional-benefits__description'
            variant='body2'
          >
            {intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.ADDITIONAL_BENEFITS.SECTION_DESCRIPTION'
            )}
          </Typography>
          <Button
            variant='secondary'
            className='h-8'
            data-testid='additional-benefits__add-benefit'
            onClick={handleClick}
          >
            {intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.ADDITIONAL_BENEFITS.ADD_BENEFIT'
            )}
          </Button>
        </div>
      </div>
      <div className='mt-4 flex flex-col space-y-2'>
        {benefits.map((benefit, index) => (
          <AdditionalBenefitCard
            key={benefit.id}
            benefit={benefit}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};

export default AdditionalBenefits;
