import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { MentionType } from 'utils/customTypes';
import projectMentionIcon from 'assets/icons/project-mention.svg';
import taskCommentMentionIcon from 'assets/icons/task-comment-mention.svg';
import requestMentionIcon from 'assets/icons/request-mention.svg';
import designMentionIcon from 'assets/icons/design-mention.svg';
import { Icon, Typography } from '@getsynapse/design-system';
import { MENTIONS_TYPES } from 'utils/constants';
import importantIcon from 'assets/icons/important.svg';
import CommentMention from './CommentMention/CommentMention';

type MentionsListProps = {
  data: MentionType[];
  cardList: boolean;
};

const mentionIconMap: Record<MentionType['type'], string> = {
  [MENTIONS_TYPES.DESIGN]: designMentionIcon,
  [MENTIONS_TYPES.PROJECT]: projectMentionIcon,
  [MENTIONS_TYPES.REQUEST_PROPERTY]: requestMentionIcon,
  [MENTIONS_TYPES.RQUEST_QUESTION]: requestMentionIcon,
  [MENTIONS_TYPES.TASK]: taskCommentMentionIcon,
};

const MentionsList = ({ data, cardList }: MentionsListProps) => (
  <>
    {data.length ? (
      <div data-cy='recent-mentions-list'>
        {data.map((mention, index) => (
          <Link
            key={mention.id}
            data-cy={mention.id}
            to={mention.link}
            className={`mb-1 block pb-5 pl-8 pr-4 ${
              index !== 0 ? 'pt-3' : 'pt-1'
            }`}
          >
            <div className='flex mb-1'>
              <Icon
                src={mentionIconMap[mention.type]}
                className='w-6 h-6 mr-2'
              />
              <Typography variant='body2'>
                {`${mention.authorName} ${intl.get(
                  'MY_WORKSPACE.RECENT_MENTIONS.IN'
                )} `}
                <span className='font-semibold'>{`${mention.title}:`}</span>
              </Typography>
            </div>
            <CommentMention cardList={cardList} comment={mention} />
            <Typography className='ml-8' variant='body2'>
              {mention.createdAt}
            </Typography>
          </Link>
        ))}
      </div>
    ) : (
      <div className='h-80 2xl:h-108 w-144 2xl:w-192 flex justify-center items-center'>
        <Icon src={importantIcon} className='h-6 w-6 mr-2' />
        <Typography>{intl.get('MY_WORKSPACE.ERROR_STATE')}</Typography>
      </div>
    )}
  </>
);

export default MentionsList;
