import React from 'react';
import intl from 'react-intl-universal';
import {
  Typography,
  Checkbox,
  tailwindOverride,
  Tooltip,
} from '@getsynapse/design-system';
import truncate from 'lodash/truncate';
import { FieldTemplateType } from 'utils/types/fields';
import {
  ALLOWED_FIELD_TYPES,
  ALLOWED_FIELD_VALUE_SELECTORS,
} from 'utils/constants';

export const getFieldTypeLabel = (fieldTemplate: FieldTemplateType) => {
  if (fieldTemplate.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.DROPDOWN) {
    return intl.get('CUSTOM_QUESTIONS.DROPDOWN');
  }

  if (
    fieldTemplate.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.DATE_PICKER
  ) {
    return intl.get('CUSTOM_QUESTIONS.DATE');
  }

  if (fieldTemplate.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.CHECKBOX) {
    return intl.get('CUSTOM_QUESTIONS.CHECKBOX');
  }

  if (fieldTemplate.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.RADIO) {
    return intl.get('CUSTOM_QUESTIONS.RADIO_BUTTON');
  }

  if (
    fieldTemplate.type === ALLOWED_FIELD_TYPES.SINGLE_LINE ||
    fieldTemplate.type === ALLOWED_FIELD_TYPES.LABEL ||
    fieldTemplate.type === ALLOWED_FIELD_TYPES.MULTILINE
  ) {
    return intl.get('CUSTOM_QUESTIONS.TEXT');
  }

  if (
    fieldTemplate.type === ALLOWED_FIELD_TYPES.NUMERIC ||
    fieldTemplate.type === ALLOWED_FIELD_TYPES.FLOAT ||
    fieldTemplate.type === ALLOWED_FIELD_TYPES.INT
  ) {
    return intl.get('CUSTOM_QUESTIONS.NUMBER');
  }

  return intl.get('CUSTOM_QUESTIONS.TOGGLE');
};

const FieldTemplateCard: React.FC<{
  fieldTemplate: FieldTemplateType;
  isSelected: boolean;
  onSelectFieldTemplate: (fieldTemplateId: string) => void;
}> = ({ fieldTemplate, isSelected = false, onSelectFieldTemplate }) => {
  const maxCharacters = 140;
  const showTooltip = fieldTemplate.description
    ? fieldTemplate.description?.length > maxCharacters
    : false;
  return (
    <div
      className={tailwindOverride(
        'rounded-lg border border-neutral-lighter-two',
        'p-4 h-40',
        { 'bg-secondary-lightest-two border-secondary-lighter': isSelected }
      )}
    >
      <Checkbox
        label={fieldTemplate.name}
        className='text-base font-semibold'
        checked={isSelected}
        value={fieldTemplate.id}
        onChange={() => onSelectFieldTemplate(fieldTemplate.id!)}
        inputProps={{ 'data-cy': `field-template-${fieldTemplate.id!}` }}
      />
      <Typography variant='body2' className='text-neutral'>
        {intl.get(
          'SETTINGS_PAGE.TEMPLATE_PAGE.ADD_MORE_FIELDS_MODAL.FIELD_TYPE',
          { fieldType: getFieldTypeLabel(fieldTemplate) }
        )}
      </Typography>
      <div className='flex flex-1'>
        {showTooltip ? (
          <Tooltip
            timeout={0}
            position='topCenter'
            openMode='hover2'
            showPopper
            trigger={
              <div>
                <Typography variant='body' className='text-primary'>
                  {truncate(fieldTemplate.description, {
                    length: maxCharacters,
                  })}
                </Typography>
              </div>
            }
          >
            {fieldTemplate.description}
          </Tooltip>
        ) : (
          <Typography variant='body' className='text-primary'>
            {fieldTemplate.description}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default FieldTemplateCard;
