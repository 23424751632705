import { FC } from 'react';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'utils/constants';
import { IconButton, Tooltip } from '@getsynapse/design-system';
import { getStatusColumn } from 'Pages/ProjectsListPage/helpers/tableColumnsValues';

interface HeaderElementProps {
  projectId?: string;
  taskId?: string;
  status: string;
  onClose: () => void;
}

const HeaderElement: FC<HeaderElementProps> = ({
  projectId,
  taskId,
  status,
  onClose,
}) => {
  const history = useHistory();
  const openFullView = () => {
    history.push(
      `${PATHS.PROJECT_PAGE}/${projectId}/tasks/${taskId}?fromSidePanel=true`,
      {
        from: history.location.pathname,
      }
    );
    onClose();
  };

  if (!taskId || !projectId) {
    return null;
  }

  return (
    <div className='flex items-center mr-3'>
      <div className='ml-2 flex-grow'>{getStatusColumn(status)}</div>

      <Tooltip
        openMode='hover1'
        timeout={0}
        position='botomCenter'
        contentProps={{ className: 'px-3 py-2 text-sm z-50' }}
        trigger={
          <IconButton
            name='resize'
            iconClassname='pointer-events-none'
            className='text-lg p-1 rounded text-primary-dark hover:bg-neutral-lightest hover:shadow-allocation-table'
            onClick={openFullView}
            aria-label={intl.get('SIDE_PANEL.FULL_PAGE')}
          />
        }
      >
        {intl.get('SIDE_PANEL.FULL_PAGE')}
      </Tooltip>
    </div>
  );
};

export default HeaderElement;
