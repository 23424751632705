import React, { useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { useElevation, Button } from '@getsynapse/design-system';
import { Column, Section, getWeeksArray, AllocatedUser, Week } from './helpers';
import {
  TableHeaders as UsersTableHeaders,
  AllocatedUserDetails,
  AllocatedUserAssignments,
} from './components/AllocatedUsersTable';
import {
  TableHeaders as WeeksTableHeaders,
  RoleAllocations,
  AssignmentsAllocations,
  TargetCompletionDateHeader,
} from './components/AllocatedWeeksTable';
import ExportIcon from 'assets/icons/export.svg';
import EmptySection from './components/EmptySection';
import SectionToggle from './components/SectionToggle';

const CapacityAllocationTable: React.FC<{
  headers: Column[];
  sections: Section[];
  startDate: Date | string;
  endDate: Date | string;
  numberOfWeeks?: number;
  onExportUsers: (selectedUsers: string[], weeksArray: Week[]) => void;
}> = ({
  headers,
  startDate,
  endDate,
  numberOfWeeks = 6,
  sections,
  onExportUsers,
}) => {
  const tableElevation = useElevation(1);
  const weeksArray = useMemo(
    () => getWeeksArray(startDate, endDate, numberOfWeeks),
    [startDate, endDate, numberOfWeeks]
  );
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const weeksSlidesNumber = Math.ceil(weeksArray.length / numberOfWeeks);
  const [currentWeeksSlide, setCurrentWeeksSlide] = useState(1);
  const [openSections, setOpenSections] = useState<string[]>([sections[0].id!]);
  const [openUserAssignmentsList, setOpenUserAssignmentsList] = useState<
    string[]
  >([]);
  const currentWeeksSlideArray = useMemo(() => {
    const startIndex = (currentWeeksSlide - 1) * numberOfWeeks;
    const endIndex = startIndex + numberOfWeeks;
    return weeksArray.slice(startIndex, endIndex);
  }, [numberOfWeeks, currentWeeksSlide, weeksArray]);

  const totalNumberUsers = useMemo(() => {
    let totalUsers = 0;
    for (const section of sections) {
      totalUsers += section.users.length;
    }
    return totalUsers;
  }, [sections]);

  const areAllUsersSelected = useMemo(
    () =>
      totalNumberUsers > 0 ? selectedUsers.length === totalNumberUsers : false,
    [totalNumberUsers, selectedUsers]
  );

  const handleToggleUser = (userId: string) => {
    let updatedSelectedUsers = [];
    const foundUserIndex = selectedUsers.findIndex(
      (id: string) => id === userId
    );
    if (foundUserIndex > -1) {
      updatedSelectedUsers = [
        ...selectedUsers.slice(0, foundUserIndex),
        ...selectedUsers.slice(foundUserIndex + 1),
      ];
    } else {
      updatedSelectedUsers = selectedUsers.concat(userId);
    }
    setSelectedUsers(updatedSelectedUsers);
  };

  const handleSelectAllUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
    const usersArray: string[] = [];
    if (event.target.checked) {
      for (const section of sections) {
        const users = section.users;
        for (const user of users) {
          usersArray.push(user.id);
        }
      }
    }
    setSelectedUsers(usersArray);
  };

  const handleToggleSection = (section: string) => {
    let updatedSections = [];
    const sectionIndex = openSections.findIndex((value) => value === section);
    if (sectionIndex > -1) {
      updatedSections = [
        ...openSections.slice(0, sectionIndex),
        ...openSections.slice(sectionIndex + 1),
      ];
    } else {
      updatedSections = openSections.concat(section);
    }
    setOpenSections(updatedSections);
  };

  const handleToggleUserAssignmentsList = (userId: string) => {
    let updatedUserAssigmentsList = [];
    const userAssigmentListIndex = openUserAssignmentsList.findIndex(
      (value) => value === userId
    );
    if (userAssigmentListIndex > -1) {
      updatedUserAssigmentsList = [
        ...openUserAssignmentsList.slice(0, userAssigmentListIndex),
        ...openUserAssignmentsList.slice(userAssigmentListIndex + 1),
      ];
    } else {
      updatedUserAssigmentsList = openUserAssignmentsList.concat(userId);
    }
    setOpenUserAssignmentsList(updatedUserAssigmentsList);
  };

  const handleExportUsers = () => {
    onExportUsers([...selectedUsers], weeksArray);
    setSelectedUsers([]);
  };

  return (
    <div
      className={classnames(
        'w-full h-auto shadow-allocation-table rounded-t-lg mt-4',
        tableElevation
      )}
    >
      <div className='w-full h-12 bg-neutral-white rounded-t-lg flex border-b border-neutral-lighter px-4 py-2 justify-end'>
        <Button
          color='positive'
          iconPosition='left'
          size='small'
          variant='tertiary'
          iconSrc={ExportIcon}
          disabled={selectedUsers.length === 0}
          onClick={handleExportUsers}
        >
          {intl.get('EXPORT_CSV')}
        </Button>
      </div>
      <div className='w-full h-5 bg-neutral-white flex justify-end'>
        <div className='w-3/5'>
          <TargetCompletionDateHeader
            currentWeeksSlideArray={currentWeeksSlideArray}
            targetCompletionDate={endDate}
          />
        </div>
      </div>
      <table
        className='w-full h-full table-fixed'
        cellPadding={0}
        cellSpacing={0}
      >
        <thead className='h-10 bg-neutral-lightest font-semibold text-primary border-b border-neutral-lighter sticky top-0 z-10'>
          <tr>
            <th className='w-2/5'>
              <UsersTableHeaders
                headers={headers}
                onSelectAllUsers={handleSelectAllUsers}
                areAllUsersSelected={areAllUsersSelected}
              />
            </th>
            <th className='w-3/5'>
              <WeeksTableHeaders
                currentWeeksSlideArray={currentWeeksSlideArray}
                weeksSlidesNumber={weeksSlidesNumber}
                onChangeWeeksSlide={setCurrentWeeksSlide}
                currentWeeksSlide={currentWeeksSlide}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {sections.map((section: Section) => {
            const isSectionOpen = openSections.includes(section.id);
            const isSectionEmpty = section.users.length === 0;
            return (
              <React.Fragment key={section.id}>
                <tr className='h-10 shadow-header border-b border-neutral-lighter'>
                  <td colSpan={2}>
                    <SectionToggle
                      id={section.id}
                      label={section.label}
                      onToggleSection={() => handleToggleSection(section.id)}
                      numberOfWeeks={numberOfWeeks}
                      currentWeeksSlideArray={currentWeeksSlideArray}
                      isSectionOpen={isSectionOpen}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div
                      className={classnames(
                        'overflow-hidden',
                        'transition-height duration-300 easy',
                        { 'h-auto': isSectionOpen, 'h-0': !isSectionOpen }
                      )}
                    >
                      {!isSectionEmpty ? (
                        section.users.map(
                          (user: AllocatedUser, index: number) => {
                            const isOdd = index % 2;
                            const isUserAssigmentListOpen =
                              openUserAssignmentsList.includes(user.id);
                            return (
                              <React.Fragment key={user.id}>
                                <div
                                  className={classnames(
                                    'flex flex-1 h-full border-b border-neutral-lighter',
                                    {
                                      'bg-neutral-white': isOdd,
                                      'bg-neutral-lightest': !isOdd,
                                    }
                                  )}
                                >
                                  <div className='w-2/5'>
                                    <AllocatedUserDetails
                                      user={user}
                                      onToggleSection={
                                        handleToggleUserAssignmentsList
                                      }
                                      onToggleUser={handleToggleUser}
                                      isSectionOpen={isUserAssigmentListOpen}
                                      selectedUsers={selectedUsers}
                                    />
                                  </div>
                                  <div className='w-3/5'>
                                    <RoleAllocations
                                      userId={user.id}
                                      roles={user.roles}
                                      currentWeeksSlideArray={
                                        currentWeeksSlideArray
                                      }
                                      numberOfWeeks={numberOfWeeks}
                                      timeOffs={user.timeOffs}
                                    />
                                  </div>
                                </div>
                                <div
                                  className={`h-${
                                    isUserAssigmentListOpen ? 'auto' : '0'
                                  } overflow-hidden transition-height duration-300 easy`}
                                >
                                  <div
                                    className={classnames(
                                      'flex flex-1 h-full border-b border-neutral-lighter',
                                      {
                                        'bg-neutral-white': isOdd,
                                      }
                                    )}
                                  >
                                    <div className='w-2/5'>
                                      <AllocatedUserAssignments
                                        assignments={user.assignments}
                                      />
                                    </div>
                                    <div className='w-3/5'>
                                      <AssignmentsAllocations
                                        assignments={user.assignments}
                                        currentWeeksSlideArray={
                                          currentWeeksSlideArray
                                        }
                                        numberOfWeeks={numberOfWeeks}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          }
                        )
                      ) : (
                        <EmptySection
                          id={section.id}
                          label={section.emptyMessage}
                          currentWeeksSlideArray={currentWeeksSlideArray}
                          numberOfWeeks={numberOfWeeks}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CapacityAllocationTable;
