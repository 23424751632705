import { Typography } from '@getsynapse/design-system';
import { ByStatusBarChart } from 'Pages/InsightsPage/Projects/ByStatus/ByStatusBarChart';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  fetchProjectsByStatus,
  getProjectsByStatus,
  getActiveFiltersQueryParams,
} from 'state/Insights/ProjectsSlice';
import intl from 'react-intl-universal';
import { DataValueDescription } from 'Pages/InsightsPage/Components/DataValueDescription';
import HorizontalBarChartLoader from 'Pages/InsightsPage/Components/SkeletonLoaders/HorizontalBarChartLoader';
import { SLICE_STATUS } from 'utils/constants';
import ErrorScreen from '../../Components/ErrorScreen';

export const ByStatus = () => {
  const dispatch = useDispatch();
  const activeFilters = useSelector(getActiveFiltersQueryParams);
  useEffect(() => {
    dispatch(fetchProjectsByStatus(activeFilters));
  }, [dispatch, activeFilters]);

  const { data, total, status } = useSelector(getProjectsByStatus);

  return status === SLICE_STATUS.LOADING ? (
    <HorizontalBarChartLoader />
  ) : status === SLICE_STATUS.FAILED ? (
    <ErrorScreen />
  ) : (
    <div className='flex flex-col h-full w-full'>
      {total && (
        <DataValueDescription title='Projects By Status' value={total} />
      )}

      <div className='h-full w-full flex pt-4'>
        {total === 0 ? (
          <div className='flex flex-col h-full justify-center pt-6'>
            <Typography variant='caption' className='text-neutral-lighter'>
              {intl.get('PROJECTS_INSIGHTS_TABS.NO_PROJECTS')}
            </Typography>
          </div>
        ) : (
          <ByStatusBarChart data={data} />
        )}
      </div>
    </div>
  );
};
