import {
  Datepicker,
  FormItem,
  tailwindOverride,
} from '@getsynapse/design-system';
import { rangeDate } from 'utils/customTypes';
import intl from 'react-intl-universal';

interface TaskDatesFieldProps {
  isOnSidepanel?: boolean;
  isViewOnly?: boolean;
  isReadOnly?: boolean;
  startDate?: Date;
  dueDate?: Date;
  hasAssignedUsers: boolean;
  onDateChange: (date: rangeDate) => void;
}
const TaskDatesField = ({
  isOnSidepanel = false,
  isViewOnly,
  isReadOnly,
  startDate,
  dueDate,
  hasAssignedUsers,
  onDateChange,
}: TaskDatesFieldProps) => {
  return (
    <FormItem className={tailwindOverride({ 'mt-4': isOnSidepanel })}>
      <Datepicker
        disabled={isViewOnly || isReadOnly}
        className='w-full'
        canSelectRange
        startDate={startDate}
        startDateLabel={intl.get('TASKS.TASK_DETAIL_PAGE.START_DATE_LABEL')}
        startDateLabelProps={{
          required: hasAssignedUsers,
          state: 'default',
          // state: requiredFieldsErrors?.start_date ? 'error' : 'default',
        }}
        // startDateError={
        //   requiredFieldsErrors?.start_date
        //     ? intl.get('TASKS.TASK_DETAIL_PAGE.MISSING_INFO_ERROR')
        //     : ''
        // }
        endDate={dueDate}
        endDateLabel={intl.get('TASKS.TASK_DETAIL_PAGE.DUE_DATE_LABEL')}
        // endDateError={
        //   requiredFieldsErrors?.due_date
        //     ? intl.get('TASKS.TASK_DETAIL_PAGE.MISSING_INFO_ERROR')
        //     : ''
        // }
        endDateLabelProps={{
          required: hasAssignedUsers,
          state: 'default',
          // state: requiredFieldsErrors?.due_date ? 'error' : 'default',
        }}
        size='large'
        onPickDate={onDateChange}
        data-cy='task-date-input'
      />
    </FormItem>
  );
};

export default TaskDatesField;
