import { Typography, Tooltip, Icon } from '@getsynapse/design-system';
import { ReactElement, useMemo, Dispatch, SetStateAction } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { useEffect } from 'react';

interface Props {
  trigger: ReactElement;
  className: string;
  password: string;
  setIsPasswordValid: Dispatch<SetStateAction<boolean>>;
}

const PasswordTooltip = ({
  trigger,
  className,
  password,
  setIsPasswordValid,
}: Props) => {
  const passwordState = useMemo(
    () => ({
      length: password.length >= 8,
      number: /\d/.test(password),
      uppercase: /[A-Z]/.test(password),
      specialChar: /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password),
    }),
    [password]
  );

  useEffect(() => {
    setIsPasswordValid(
      passwordState.length &&
        passwordState.number &&
        passwordState.uppercase &&
        passwordState.specialChar
    );
  }, [passwordState, setIsPasswordValid]);

  return (
    <Tooltip
      openMode='focus'
      contentProps={{ className: `${className} bg-neutral-white w-48` }}
      trigger={trigger}
      timeout={0}
      className='w-full'
    >
      <Typography weight='medium' variant='caption'>
        {intl.get('TRIAL_REGISTRATION_PAGE.INPUT.PASSWORD.TOOLTIP.TITLE')}
      </Typography>
      <ul>
        <li
          className={classnames(
            `${passwordState.length ? 'text-neutral-black' : 'text-neutral'}`,
            'flex items-center text-xs'
          )}
        >
          <Icon
            name={passwordState.length ? 'checkmark-circle' : 'close-circle'}
            className={classnames(
              'mr-2',
              `${
                passwordState.length
                  ? 'text-success-darker'
                  : 'text-neutral-lighter'
              }`
            )}
          />
          {intl.get('TRIAL_REGISTRATION_PAGE.INPUT.PASSWORD.TOOLTIP.LENGTH')}
        </li>
        <li
          className={classnames(
            `${passwordState.number ? 'text-neutral-black' : 'text-neutral'}`,
            'flex items-center text-xs'
          )}
        >
          <Icon
            name={passwordState.number ? 'checkmark-circle' : 'close-circle'}
            className={classnames(
              'mr-2',
              `${
                passwordState.number
                  ? 'text-success-darker'
                  : 'text-neutral-lighter'
              }`
            )}
          />
          {intl.get('TRIAL_REGISTRATION_PAGE.INPUT.PASSWORD.TOOLTIP.NUMBER')}
        </li>
        <li
          className={classnames(
            `${
              passwordState.uppercase ? 'text-neutral-black' : 'text-neutral'
            }`,
            'flex items-center text-xs'
          )}
        >
          <Icon
            name={passwordState.uppercase ? 'checkmark-circle' : 'close-circle'}
            className={classnames(
              'mr-2',
              `${
                passwordState.uppercase
                  ? 'text-success-darker'
                  : 'text-neutral-lighter'
              }`
            )}
          />
          {intl.get('TRIAL_REGISTRATION_PAGE.INPUT.PASSWORD.TOOLTIP.UPPERCASE')}
        </li>
        <li
          className={classnames(
            `${
              passwordState.specialChar ? 'text-neutral-black' : 'text-neutral'
            }`,
            'flex items-center text-xs'
          )}
        >
          <Icon
            name={
              passwordState.specialChar ? 'checkmark-circle' : 'close-circle'
            }
            className={classnames(
              'mr-2',
              `${
                passwordState.specialChar
                  ? 'text-success-darker'
                  : 'text-neutral-lighter'
              }`
            )}
          />
          {intl.get(
            'TRIAL_REGISTRATION_PAGE.INPUT.PASSWORD.TOOLTIP.SPECIAL_CHARACTERS'
          )}
        </li>
      </ul>
    </Tooltip>
  );
};

export default PasswordTooltip;
