import { useCallback, useMemo } from 'react';
import { FlatfileResults, ISettings } from '@flatfile/adapter';
import { FlatfileImporter } from '@flatfile/react';
import intl from 'react-intl-universal';
import { CurrentUser } from 'utils/customTypes';

export const useImport: (
  onData: (result: FlatfileResults) => Promise<void>,
  currentUser: CurrentUser,
  settings: ISettings,
  afterImport?: () => void
) => { startImport: () => Promise<void> } = (
  onData,
  currentUser,
  settings,
  afterImport
) => {
  const colorPrimary = useMemo(
    () =>
      getComputedStyle(document.body).getPropertyValue(
        '--color-primary-default'
      ),
    []
  );
  const colorPrimaryHover = useMemo(
    () =>
      getComputedStyle(document.body).getPropertyValue(
        '--color-primary-darker'
      ),
    []
  );

  const buttonStyles = useMemo(
    () => ({
      backgroundColor: `rgb(${colorPrimary})`,
      border: `2px solid rgb(${colorPrimary})`,
      ':hover': {
        backgroundColor: `rgb(${colorPrimaryHover})`,
        border: `2px solid rgb(${colorPrimaryHover})`,
      },
    }),
    [colorPrimary, colorPrimaryHover]
  );

  const createImporter: () => FlatfileImporter = useCallback(() => {
    const newImporter: FlatfileImporter = new FlatfileImporter(
      process.env.REACT_APP_FLATFILE_API_KEY || '',
      {
        ...settings,
        disableManualInput: true,
        devMode: process.env.REACT_APP_APP_ENV !== 'prod',
        managed: true,
        theme: {
          buttons: {
            primary: buttonStyles,
            secondary: buttonStyles,
            headerMatchYes: buttonStyles,
            headerMatchNo: {
              border: `2px solid rgb(${colorPrimary})`,
              color: `rgb(${colorPrimary})`,
              ':hover': {
                border: `2px solid rgb(${colorPrimaryHover})`,
                color: `rgb(${colorPrimaryHover})`,
              },
            },
          },
          headerMatch: {
            content: {
              border: `2px solid rgb(${colorPrimary})`,
            },
          },
        },
      }
    );

    newImporter.setCustomer({
      companyId: currentUser.organization_id,
      companyName: currentUser.organization_id,
      email: currentUser.email,
      name: currentUser.full_name,
      userId: currentUser.id || '',
    });

    return newImporter;
  }, [
    buttonStyles,
    colorPrimary,
    colorPrimaryHover,
    currentUser.email,
    currentUser.full_name,
    currentUser.id,
    currentUser.organization_id,
    settings,
  ]);

  const startImport = useCallback(async () => {
    const importer = createImporter();

    const results = await importer.requestDataFromUser();
    await onData(results);
    await importer.displaySuccess(intl.get('SUCCESS'));
    if (afterImport) {
      afterImport();
    }
  }, [afterImport, createImporter, onData]);

  return {
    startImport,
  };
};
