import React from 'react';
import { useSelector } from 'react-redux';
import { tailwindOverride } from '@getsynapse/design-system';
import {
  selectWeekDays,
  selectNumberOfDays,
} from 'state/DailyTeamsCapacity/Days/daysSlice';
import { initDateFormatter } from '../../../../helpers';

const NoDailyCapacity: React.FC<{
  id: string;
  isLoading?: boolean;
}> = ({ id, isLoading = false }) => {
  const dateFormatter = initDateFormatter();
  const weekDays = useSelector(selectWeekDays);
  const currenDate = dateFormatter.format(new Date());
  const numberOfDays = useSelector(selectNumberOfDays);
  let ariaLabel = `${id}__no-daily-capacity`;
  if (isLoading) {
    ariaLabel = `${id}__daily-capacity__skeleton-loader`;
  }
  return (
    <div className='w-full h-full flex' aria-label={ariaLabel}>
      <div className='w-6 h-full' />
      <div className='w-full h-full flex'>
        {weekDays.map((day, index) => {
          const dayDate = dateFormatter.format(
            new Date(day.replace(/-/g, '/'))
          );
          const isCurrentDay = currenDate === dayDate;
          return (
            <div
              key={`${id}__${day}`}
              className={tailwindOverride(
                'flex-1 h-full',
                'flex-shrink-0 flex-grow items-center',
                {
                  'bg-secondary-lightest border-l border-r border-secondary-lighter':
                    isCurrentDay,
                }
              )}
              style={{ width: `${100 / numberOfDays}%` }}
            >
              <div
                key={`${id}__${index}`}
                className={tailwindOverride(
                  'w-full h-full',
                  'flex  items-center',
                  'px-1 pb-2 pt-3',
                  'box-border animate-pulse'
                )}
              >
                {isLoading && (
                  <div className='w-full h-4 rounded-sm bg-primary-lighter' />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className='w-6 h-full' />
    </div>
  );
};

export default NoDailyCapacity;
