import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { UserSettings } from 'utils/customTypes';

export class UserSettingsAPI {
  instance: AxiosInstance;

  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  getUserSettings = async () => {
    const { data } = await this.instance.get(`user/userSettings`);
    return data;
  };

  getUserSettingsV2 = async () => {
    const { data }: { data: UserSettings } = await this.instance.get(
      `/v2/userSettings`
    );
    return data;
  };

  updatePublicDraftSettings = async (requestId?: string) => {
    const { data } = await this.instance.put(
      `user/userSettings/toggleDraftPublicSetting`,
      {
        updateFields: { requestId },
      }
    );
    return data;
  };
}
