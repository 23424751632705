import {
  createSlice,
  createAction,
  createSelector,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import {
  ProgramCategory,
  ObjectiveMutableProps,
  NewObjective,
  EvaluationPlanItem,
  CollectionMethodAndOptions,
  NewCollectionMethod,
  UpdatedQuestion,
  EvaluationEvidenceItem,
  EvaluationEvidenceFilePostData,
  NewAdditionalBenefit,
} from 'utils/types/program';
import { RootState } from 'state/store';
import categoryAPI from './strategyCategoryAPI';
import { serializeObjectives } from './helpers';

interface strategyCategoryState {
  status: Status;
  collectionMethodsAndOptions: CollectionMethodAndOptions[];
  collectionMethodsAndOptionsStatus: Status;
  evaluationPlanData: EvaluationPlanItem[];
  evaluationPlanStatus: Status;
  evaluationEvidences: EvaluationEvidenceItem[];
  evaluationEvidenceStatus: Status;
  deleteEvaluationEvidenceStatus: Status;
  value: ProgramCategory;
}

/* ============================= INITIAL STATE ============================== */

export const defaultValues = {
  id: '',
  name: '',
  description: '',
  strategyId: '',
  orderIndex: 0,
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  deleted_at: '',
  deleted_by: '',
  objectives: [],
  objective_templates: [],
  additionalBenefits: [],
} as ProgramCategory;

const defaultEvaluationPlan = [] as EvaluationPlanItem[];
const defaultCollectionMethodsAndOptions = [] as CollectionMethodAndOptions[];

const defaultEvaluationEvidences = [] as EvaluationEvidenceItem[];

const initialState: strategyCategoryState = {
  value: defaultValues,
  evaluationPlanData: defaultEvaluationPlan,
  collectionMethodsAndOptions: defaultCollectionMethodsAndOptions,
  collectionMethodsAndOptionsStatus: SLICE_STATUS.IDLE,
  evaluationPlanStatus: SLICE_STATUS.IDLE,
  evaluationEvidences: defaultEvaluationEvidences,
  evaluationEvidenceStatus: SLICE_STATUS.IDLE,
  status: SLICE_STATUS.IDLE,
  deleteEvaluationEvidenceStatus: SLICE_STATUS.IDLE,
};

/* ============================= ACTIONS ============================== */
export const resetProgramCategory = createAction(
  'strategyCategory/RESET_PROGRAM_CATEGORY'
);

/* ============================== REDUX THUNK =============================== */
export const getCategory = createAsyncThunk(
  'strategyCategory/GET_PROGRAM_CATEGORY',
  async (
    { programId, categoryId }: { programId: string; categoryId: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await categoryAPI.getCategory(programId, categoryId);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Error in fetching category');
    }
  }
);

export const updateObjective = createAsyncThunk(
  'strategyCategory/UPDATE_OBJECTIVE',
  async (
    {
      name,
      is_met,
      not_met_reason,
      programId,
      objectiveId,
    }: {
      programId: string;
      objectiveId: string;
    } & ObjectiveMutableProps,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await categoryAPI.updateObjective(
        programId,
        objectiveId,
        name,
        is_met !== null ? is_met : undefined,
        not_met_reason
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Error in updating objective');
    }
  }
);

export const deleteObjective = createAsyncThunk(
  'strategyCategory/DELETE_OBJECTIVE',
  async ({
    programId,
    objectiveId,
    categoryId,
  }: {
    programId: string;
    objectiveId: string;
    categoryId: string;
  }) => {
    await categoryAPI.deleteObjective(programId, objectiveId, categoryId);
    return objectiveId;
  }
);

export const getCategoryObjectiveTemplates = createAsyncThunk(
  'strategyCategory/GET_CATEGORY_OBJECTIVE_TEMPLATES',
  async (
    { programId, categoryId }: { programId: string; categoryId: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await categoryAPI.getCategoryObjectiveTemplates(
        programId,
        categoryId
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.message || 'Error in fetching category objective templates'
      );
    }
  }
);

export const createCategoryObjectives = createAsyncThunk(
  'strategyCategory/CREATE_CATEGORY_OBJECTIVES',
  async (
    {
      programId,
      newObjectives,
    }: { programId: string; newObjectives: NewObjective[] },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await categoryAPI.setObjectives(
        programId,
        newObjectives
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.message || 'Error in creating category objectives'
      );
    }
  }
);

export const createAdditionalBenefits = createAsyncThunk(
  'strategyCategory/CREATE_ADDITIONAL_BENEFITS',
  async ({
    programId,
    categoryId,
    newBenefits,
  }: {
    programId: string;
    categoryId: string;
    newBenefits: NewAdditionalBenefit[];
  }) => {
    const response = await categoryAPI.createAdditionalBenefits(
      programId,
      categoryId,
      newBenefits
    );
    return response;
  }
);

export const getEvaluationPlan = createAsyncThunk(
  'strategyCategory/GET_PROGRAM_CATEGORY_EVALUATION_PLAN',
  async (
    { programId, categoryId }: { programId: string; categoryId: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await categoryAPI.getEvaluationPlan(
        programId,
        categoryId
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.message || 'Error in fetching evaluation plan'
      );
    }
  }
);

export const getCategoryEvaluationEvidences = createAsyncThunk(
  'strategyCategory/GET_PROGRAM_CATEGORY_EVALUATION_EVIDENCE',
  async (
    { programId, categoryId }: { programId: string; categoryId: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await categoryAPI.getCategoryEvaluationEvidences(
        programId,
        categoryId
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.message || 'Error in fetching evaluation evidences'
      );
    }
  }
);

export const createCategoryEvaluationEvidence = createAsyncThunk(
  'strategyCategory/CREATE_PROGRAM_CATEGORY_EVALUATION_EVIDENCE',
  async (
    {
      programId,
      categoryId,
      fileData,
    }: {
      programId: string;
      categoryId: string;
      fileData: EvaluationEvidenceFilePostData;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await categoryAPI.createCategoryEvaluationEvidence(
        programId,
        categoryId,
        fileData
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error || 'Error in creating evaluation evidence');
    }
  }
);

export const deleteCategoryEvaluationEvidence = createAsyncThunk(
  'strategyCategory/DELETE_PROGRAM_CATEGORY_EVALUATION_EVIDENCE',
  async ({
    programId,
    categoryId,
    evidenceId,
  }: {
    programId: string;
    categoryId: string;
    evidenceId: string;
  }) => {
    await categoryAPI.deleteCategoryEvaluationEvidences(
      programId,
      categoryId,
      evidenceId
    );
    return evidenceId;
  }
);

export const bulkCreateCollectionMethodsWithAnswers = createAsyncThunk(
  'strategyCategory/CREATE_COLLECTION_METHODS_WITH_ANSWERS',
  async (
    {
      programId,
      categoryId,
      dataCollectionMethods,
    }: {
      programId: string;
      categoryId: string;
      dataCollectionMethods: NewCollectionMethod[];
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await categoryAPI.bulkCreateCollectionMethodsWithAnswers(
        programId,
        categoryId,
        dataCollectionMethods
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.message || 'Error in creating collection methods with answers'
      );
    }
  }
);

export const updateCollectionMethodQuestionsAnswers = createAsyncThunk(
  'strategyCategory/UPDATE_COLLECTION_METHODS_WITH_ANSWERS',
  async (
    {
      programId,
      categoryId,
      collectionMethodAnswerId,
      updatedQuestions,
    }: {
      programId: string;
      categoryId: string;
      collectionMethodAnswerId: string;
      updatedQuestions: UpdatedQuestion[];
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await categoryAPI.updateQuestionsAnswers(
        programId,
        categoryId,
        collectionMethodAnswerId,
        updatedQuestions
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.message ||
          'Error in updating collection method questions and answers'
      );
    }
  }
);

export const deleteCollectionMethodWithAnswers = createAsyncThunk(
  'strategyCategory/DELETE_COLLECTION_METHODS_WITH_ANSWERS',
  async (
    {
      programId,
      categoryId,
      dataCollectionMethodId,
    }: {
      programId: string;
      categoryId: string;
      dataCollectionMethodId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await categoryAPI.deleteCollectionMethod(
        programId,
        categoryId,
        dataCollectionMethodId
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.message || 'Error in deleteing a collection method'
      );
    }
  }
);

export const getCollectionMethodsAndOptions = createAsyncThunk(
  'strategyCategory/GET_PROGRAM_CATEGORY_COLLECTION_METHODS_AND_OPTIONS',
  async ({ categoryId }: { categoryId: string }, { rejectWithValue }) => {
    try {
      const { data } = await categoryAPI.getCollectionMethodAndOptions(
        categoryId
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.message || 'Error in fetching collection mehtods and options'
      );
    }
  }
);

/* ================================= REDUCER ================================ */
const programSlice = createSlice({
  name: 'programCategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetProgramCategory, (state) => {
        state.value = defaultValues;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.value = action.payload;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(getCategory.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getCategory.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(updateObjective.fulfilled, (state, action) => {
        const newObjective = action.payload;
        const objectiveIndex = state.value.objectives.findIndex(
          (objective) => objective.id === newObjective.id
        );
        if (objectiveIndex !== -1) {
          const objective = serializeObjectives([newObjective]);
          objective?.[0] &&
            (state.value.objectives[objectiveIndex] = objective[0]);
        }
      })
      .addCase(updateObjective.rejected, () => {
        throw new Error();
      })
      .addCase(deleteObjective.fulfilled, (state, action) => {
        const objectiveId = action.payload;
        const objectiveIndex = state.value.objectives.findIndex(
          (objective) => objective.id === objectiveId
        );
        if (objectiveIndex !== -1) {
          state.value.objectives = [
            ...state.value.objectives.slice(0, objectiveIndex),
            ...state.value.objectives.slice(objectiveIndex + 1),
          ];
        }
      })
      .addCase(deleteObjective.rejected, () => {
        throw new Error();
      })
      .addCase(getCategoryObjectiveTemplates.fulfilled, (state, action) => {
        state.value.objective_templates = action.payload;
      })
      .addCase(createCategoryObjectives.fulfilled, (state, action) => {
        const objectives = serializeObjectives(action.payload);
        state.value.objectives = [...state.value.objectives, ...objectives];
      })
      .addCase(createAdditionalBenefits.fulfilled, (state, action) => {
        state.value.additionalBenefits = [
          ...(state.value.additionalBenefits ?? []),
          ...action.payload,
        ];
      })
      .addCase(createAdditionalBenefits.rejected, () => {
        throw new Error();
      })
      .addCase(getEvaluationPlan.fulfilled, (state, action) => {
        state.evaluationPlanData = action.payload;
        state.evaluationPlanStatus = SLICE_STATUS.IDLE;
      })
      .addCase(getEvaluationPlan.pending, (state) => {
        state.evaluationPlanStatus = SLICE_STATUS.LOADING;
      })
      .addCase(getEvaluationPlan.rejected, (state) => {
        state.evaluationPlanStatus = SLICE_STATUS.FAILED;
      })
      .addCase(getCategoryEvaluationEvidences.fulfilled, (state, action) => {
        state.evaluationEvidences = action.payload;
        state.evaluationEvidenceStatus = SLICE_STATUS.IDLE;
      })
      .addCase(getCategoryEvaluationEvidences.pending, (state) => {
        state.evaluationEvidenceStatus = SLICE_STATUS.LOADING;
      })
      .addCase(getCategoryEvaluationEvidences.rejected, (state) => {
        state.evaluationEvidenceStatus = SLICE_STATUS.FAILED;
      })
      .addCase(createCategoryEvaluationEvidence.fulfilled, (state, action) => {
        state.evaluationEvidences = [
          action.payload,
          ...state.evaluationEvidences,
        ];
      })
      .addCase(deleteCategoryEvaluationEvidence.rejected, (state) => {
        state.deleteEvaluationEvidenceStatus = SLICE_STATUS.FAILED;
        throw new Error();
      })
      .addCase(deleteCategoryEvaluationEvidence.fulfilled, (state, action) => {
        state.deleteEvaluationEvidenceStatus = SLICE_STATUS.IDLE;
        state.evaluationEvidences = state.evaluationEvidences.filter(
          (evidence) => evidence.id !== action.payload
        );
      })
      .addCase(getCollectionMethodsAndOptions.fulfilled, (state, action) => {
        state.collectionMethodsAndOptions = action.payload;
        state.collectionMethodsAndOptionsStatus = SLICE_STATUS.IDLE;
      })
      .addCase(getCollectionMethodsAndOptions.pending, (state) => {
        state.collectionMethodsAndOptionsStatus = SLICE_STATUS.LOADING;
      })
      .addCase(getCollectionMethodsAndOptions.rejected, (state) => {
        state.collectionMethodsAndOptionsStatus = SLICE_STATUS.FAILED;
      });
  },
});

/* =============================== SELECTORS ================================ */

export const selectCategory = (state: RootState) => state.programCategory.value;
export const selectCategoryStatus = (state: RootState) =>
  state.programCategory.status;

export const selectObjectiveAndIndex = createSelector(
  [
    selectCategory,
    (state: RootState, currentObjectiveId: string) => currentObjectiveId,
  ],
  (category, objectiveId) => {
    const objectiveIndex = category.objectives.findIndex(
      (objective) => objective.id === objectiveId
    );
    if (objectiveIndex !== -1) {
      return {
        objective: category.objectives[objectiveIndex],
        index: objectiveIndex,
      };
    } else {
      return { objective: undefined, index: 0 };
    }
  }
);

export const selectEvaluationPlan = (state: RootState) =>
  state.programCategory.evaluationPlanData;

export const selectEvaluationEvidences = (state: RootState) =>
  state.programCategory.evaluationEvidences;

export const selectEvaluationEvidencesStatus = (state: RootState) =>
  state.programCategory.evaluationEvidenceStatus;

export const selectDeleteEvaluationEvidencesStatus = (state: RootState) =>
  state.programCategory.deleteEvaluationEvidenceStatus;

export const selectCollectionMethodsAndOptions = (state: RootState) =>
  state.programCategory.collectionMethodsAndOptions;

export const selectEvaluationSectionStatus = (state: RootState) =>
  state.programCategory.evaluationPlanStatus;

export const selectCollectionMethodsAndOptionsStatus = (state: RootState) =>
  state.programCategory.collectionMethodsAndOptionsStatus;

export default programSlice.reducer;
