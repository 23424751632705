import {
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';
import { Typography, Tooltip, IconButton } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import {
  selectTaskTypeEstimation,
  selectTaskTypeEstimationStatus,
} from 'state/Insights/capacitySlice';
import { SLICE_STATUS } from 'utils/constants';
import ChartSkeletonLoader from './ChartSkeletonLoader';
import styles from '../UtliztionByTeam/UtilizatonByTeamChart.module.css';
import { useMemo } from 'react';
import legend from 'assets/images/task-type-estimation-legend.svg';
import classNames from 'classnames';
import {
  X_Y_AXIS_STYLE,
  AXIS_COLOR,
  BAR_HOVER_COLOR,
  ACTUAL_HOURS_BAR_COLOR,
  ESTIMATED_HOURS_BAR_COLOR,
  X_AXIS_LINE_COLOR,
  DATA_LABELS_STYLE,
} from './constants';
import NoData from '../components/NoData';
import LoadingError from '../components/LoadingError';
import { ReactElement, JSXElementConstructor } from 'react';

const EstimatedVsActualByTaskTypeChart = () => {
  const chartTitle = (
    <div>
      <p className='text-3.5 leading-2 text-neutral-black font-semibold'>
        {intl.get('CAPACITY_INSIGHTS.ESTIMATION_BY_TASK_TYPE.TITLE')}
      </p>
      <Typography variant='caption' className='text-neutral-dark'>
        {intl.get('CAPACITY_INSIGHTS.ESTIMATION_BY_TASK_TYPE.CAPTION')}
      </Typography>
    </div>
  );

  const taskTypesEstimation = useSelector(selectTaskTypeEstimation);
  const taskTypesEstimationStatus = useSelector(selectTaskTypeEstimationStatus);

  const chartHeight = useMemo(() => {
    let height;
    if (taskTypesEstimation?.length! > 7) {
      height = taskTypesEstimation?.length! * 47;
    } else {
      height = '100%';
    }
    return height;
  }, [taskTypesEstimation]);

  const renderBars = (props: {
    x: number;
    y: number;
    width: number;
    height: number;
    fill: string;
    value: ReactElement<SVGElement, string | JSXElementConstructor<any>>;
  }) => {
    const { x, y, width, height, fill, value } = props;
    const roundedCornersPath = `M${x},${y}L ${x + width - 2},${y}A 2,2,0,0,1,
        ${x + width},${y + 2}L ${x + width},${y + height - 2}A 2,2,0,0,1,
        ${x + width - 2},${y + height}L ${x},${y + height}Z`;

    return (
      value && (
        <g className='recharts-layer recharts-bar-rectangle' role='img'>
          <path
            fill={fill}
            width={width}
            height={height}
            x={x}
            y={y}
            className='recharts-rectangle'
            d={roundedCornersPath}
          />
        </g>
      )
    );
  };

  return (
    <div
      className='flex flex-col w-full p-6'
      data-testid='task-type-estimation_chart'
    >
      {taskTypesEstimationStatus === SLICE_STATUS.LOADING ? (
        <ChartSkeletonLoader />
      ) : (
        <>
          {taskTypesEstimation?.length ? (
            <>
              <div className='flex'>
                {chartTitle}
                <Tooltip
                  trigger={
                    <IconButton
                      name='information-circle'
                      className='w-5 h-5 ml-4 text-purple-darker hover:text-purple-darker items-center'
                      description={intl.get(
                        'CAPACITY_INSIGHTS.DEMAND_CAPACITY_AVAILABILITY.TOOLTIP_TRIGGER_DESC'
                      )}
                      iconClassname='pointer-events-none'
                    />
                  }
                  openMode='hover1'
                  contentProps={{
                    className:
                      'bg-neutral-white z-50 text-neutral-black border-purple-lighter border',
                  }}
                  className='-top-1'
                >
                  {intl.get(
                    'CAPACITY_INSIGHTS.ESTIMATION_BY_TASK_TYPE.TOOLTIP'
                  )}
                </Tooltip>
              </div>
              <div
                className={classNames(
                  'overflow-y-auto overflow-x-hidden h-full flex flex-col mt-18 pr-2',
                  styles.scrollbar
                )}
              >
                <ResponsiveContainer width='100%' height={chartHeight}>
                  <BarChart
                    layout='vertical'
                    data={taskTypesEstimation!}
                    id='task-type-estimation_bar_chart'
                    barGap={2}
                  >
                    <XAxis
                      tickLine={false}
                      style={X_Y_AXIS_STYLE}
                      type='number'
                      tickCount={5}
                      padding={{ right: 34 }}
                      label={{
                        value: intl.get(
                          'CAPACITY_INSIGHTS.ESTIMATION_BY_TASK_TYPE.HOURS'
                        ),
                        position: 'insideBottomRight',
                        offset: -0.1,
                        style: X_Y_AXIS_STYLE,
                        dx: -16,
                        fill: AXIS_COLOR,
                      }}
                      tick={{ stroke: 'none', fill: AXIS_COLOR }}
                      stroke={X_AXIS_LINE_COLOR}
                    />
                    <YAxis
                      tickLine={false}
                      style={X_Y_AXIS_STYLE}
                      dataKey='taskType'
                      type='category'
                      axisLine={false}
                      width={135}
                    />
                    <ChartTooltip
                      content={() => null}
                      cursor={{ fill: BAR_HOVER_COLOR }}
                    />
                    <Bar
                      dataKey='medianEstimatedHours'
                      fill={ESTIMATED_HOURS_BAR_COLOR}
                      barSize={12}
                      shape={renderBars}
                      label={{
                        ...DATA_LABELS_STYLE,
                        position: 'right',
                        formatter: (value: number) => `${value}h`,
                      }}
                    />
                    <Bar
                      dataKey='medianActualHours'
                      fill={ACTUAL_HOURS_BAR_COLOR}
                      barSize={12}
                      shape={renderBars}
                      label={{
                        ...DATA_LABELS_STYLE,
                        position: 'right',
                        formatter: (value: number) => `${value}h`,
                      }}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className='flex justify-center w-full mt-4'>
                <img
                  data-testid='task-type-estimation_legend'
                  src={legend}
                  alt={intl.get(
                    'CAPACITY_INSIGHTS.ESTIMATION_BY_TASK_TYPE.CHART_LEGEND'
                  )}
                />
              </div>
            </>
          ) : taskTypesEstimation ? (
            <>
              {chartTitle}
              <NoData />
            </>
          ) : (
            <>
              {chartTitle}
              <LoadingError />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default EstimatedVsActualByTaskTypeChart;
