import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { selectFormattedRecentMentions } from 'state/MyWorkspace/myWorkspaceSlice';
import MentionsList from './MentionsList';
import MyWorspaceModal from '../MyWorkspaceModal';

type MentionsModalProps = {
  icon: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const MentionsModal = ({ icon, isOpen, setIsOpen }: MentionsModalProps) => {
  const mentionsSelector = useSelector(selectFormattedRecentMentions);

  return (
    <MyWorspaceModal
      title={intl.get('MY_WORKSPACE.RECENT_MENTIONS.TITLE')}
      icon={icon}
      content={<MentionsList data={mentionsSelector} cardList={false} />}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dataCy='recent-mentions-modal'
    />
  );
};

export default MentionsModal;
