import moment from 'moment';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  selectOrganizationLicense,
  selectTrialEndDate,
} from 'state/Organization/organizationSlice';
import { LICENSE_TIER, PATHS } from 'utils/constants';

const useTrialPermission = () => {
  const history = useHistory();
  const { license_tier } = useSelector(selectOrganizationLicense);
  const trialEndDate = useSelector(selectTrialEndDate);

  useEffect(() => {
    if (license_tier === LICENSE_TIER.TRIAL) {
      const trialEnd = moment(trialEndDate).startOf('day');
      const today = moment().startOf('day');
      const isBefore = trialEnd.isBefore(today);

      if (trialEndDate && isBefore) {
        history.push(PATHS.UPGRADE);
      }
    }
  }, [history, license_tier, trialEndDate]);
};

export default useTrialPermission;
