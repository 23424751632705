import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { SortingType, TableExportOptions, Task } from 'utils/customTypes';
import {
  CentralizedTask,
  CentralizedTasksTableTab,
} from 'utils/types/centralizedTasks';
import { TABLE_EXPORT_OPTIONS } from 'utils/constants';
import { RawFilters } from 'utils/filters';
import {
  CENTRALIZED_TASKS_TABLE_TABS,
  CENTRALIZED_TASKS_SORT,
} from 'utils/constants/centralizedTasks';
import {
  selectTasksTablePagination,
  updateMySearchParam,
  updateMyTasksFilters,
  updateMyTasksSort,
  updateTeamSearchParam,
  updateTeamTasksFilters,
  updateTeamTasksSort,
} from 'state/Tasks/taskSlice';
import TasksTableHeader from './TasksTableHeader';
import TasksTable from './TasksTable';
import Pagination from 'Organisms/Pagination/ServerPagination';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';

export interface TasksProps {
  taskTable: CentralizedTasksTableTab;
  tasksList: {
    data: CentralizedTask[];
    total: number;
    allIds: string[];
  };
  tabName: string;
  exportEnabled?: Boolean;
  onUpdatePagination: (pagination: {}) => void;
  onUpdateTask?: () => void;
  isLoading?: boolean;
}

const Tasks: React.FC<TasksProps> = ({
  tasksList,
  onUpdatePagination,
  onUpdateTask,
  taskTable,
  isLoading = false,
  tabName,
  exportEnabled,
}) => {
  const dispatch = useDispatch();
  const [selectedTaskIds, setSelectedTasks] = useState<string[]>([]);
  const teamTasksTablePagination = useSelector(
    selectTasksTablePagination(CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS)
  );
  const myTasksTablePagination = useSelector(
    selectTasksTablePagination(CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS)
  );

  const page = useMemo<number>(() => {
    if (taskTable === CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS) {
      return myTasksTablePagination.offset / myTasksTablePagination.limit + 1;
    } else {
      return (
        teamTasksTablePagination.offset / teamTasksTablePagination.limit + 1
      );
    }
  }, [
    myTasksTablePagination.limit,
    myTasksTablePagination.offset,
    taskTable,
    teamTasksTablePagination.limit,
    teamTasksTablePagination.offset,
  ]);

  const currentPageTasksIds = useMemo(
    () => tasksList.data.map((task: Task) => task.id),
    [tasksList.data]
  );

  const handleSelectedExportOption = (
    exportOption: TableExportOptions | null
  ) => {
    if (exportOption === TABLE_EXPORT_OPTIONS.ALL) {
      setSelectedTasks(tasksList.allIds);
      return;
    }

    if (
      exportOption === TABLE_EXPORT_OPTIONS.CURRENT_PAGE &&
      currentPageTasksIds.length > 0
    ) {
      setSelectedTasks(currentPageTasksIds);
      return;
    }

    setSelectedTasks([]);
  };

  const handleSelectTasks = (tasks: string[]) => {
    setSelectedTasks(tasks);
  };

  const onUpdateFilters: (filters: RawFilters) => void = (filters) => {
    if (taskTable === CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS) {
      dispatch(updateMyTasksFilters(filters));
    } else {
      dispatch(updateTeamTasksFilters(filters));
    }
  };

  const handleSearch: (value: string) => void = (value) => {
    if (taskTable === CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS) {
      dispatch(updateMySearchParam(value));
    } else {
      dispatch(updateTeamSearchParam(value));
    }
  };

  const handleSort = (
    sortBy: keyof typeof CENTRALIZED_TASKS_SORT,
    sortOrder: SortingType
  ) => {
    if (taskTable === CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS) {
      dispatch(updateMyTasksSort([sortBy, sortOrder]));
    } else if (taskTable === CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS) {
      dispatch(updateTeamTasksSort([sortBy, sortOrder]));
    }
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  if (isLoading) {
    return (
      <SkeletonLoader data-testid='centralized-tasks-table-skeleton-loader' />
    );
  }

  return (
    <div className='mt-4'>
      <TasksTableHeader
        taskTable={taskTable}
        onExport={() => {}}
        tabName={tabName}
        onSearch={debouncedHandleSearch}
        exportEnabled={exportEnabled}
        onUpdateFilters={onUpdateFilters}
      />
      <TasksTable
        onSort={handleSort}
        onSelectRows={handleSelectTasks}
        tasksList={tasksList.data}
        totalTasks={tasksList.total}
        selectedTaskIds={selectedTaskIds}
        onSelectExportOption={handleSelectedExportOption}
        onUpdateTask={onUpdateTask}
      />
      <Pagination
        total={tasksList.total}
        onChange={onUpdatePagination}
        className='z-10 max-w-full'
        page={page}
      />
    </div>
  );
};

export default Tasks;
