import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  fetchReceivedRequests,
  getActiveFiltersQueryParams,
} from 'state/Insights/InsightsSlice';
import { getReceivedRequests } from 'state/Insights/InsightsSlice';
import { SLICE_STATUS } from 'utils/constants';
import ErrorScreen from '../../Components/ErrorScreen';
import BasicInfoLoader from '../../Components/SkeletonLoaders/BasicInfoLoader';
import ReceivedRequestsComponent from './ReceivedRequestsComponent';

const ReceivedRequests = () => {
  const dispatch = useDispatch();
  const receivedRequests = useSelector(getReceivedRequests);
  const activeFilters = useSelector(getActiveFiltersQueryParams);

  useEffect(() => {
    dispatch(fetchReceivedRequests(activeFilters));
  }, [dispatch, activeFilters]);

  const status = receivedRequests.status;
  return (
    <div className='flex flex-col border rounded border-neutral-lighter-two rounded-lg shadow-allocation-table w-1/2'>
      {status === SLICE_STATUS.LOADING ? (
        <BasicInfoLoader />
      ) : status === SLICE_STATUS.FAILED ? (
        <ErrorScreen />
      ) : (
        <ReceivedRequestsComponent receivedRequests={receivedRequests} />
      )}
    </div>
  );
};

export default ReceivedRequests;
