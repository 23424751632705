import { Line, Bar } from 'recharts';
import { CHART_COLOR_PALETTE } from 'Pages/InsightsPage/chartConstants';
import { Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

export const generateLineElement = ({
  dataKey,
  strokeWidth,
  strokeFill,
  index,
  isAnimationActive = true,
  showDots = true,
}: {
  dataKey: string;
  strokeWidth: number;
  strokeFill: string;
  index: number;
  isAnimationActive?: boolean;
  showDots?: boolean;
}) => {
  return (
    <Line
      key={index}
      strokeWidth={strokeWidth}
      type='linear'
      isAnimationActive={isAnimationActive}
      dataKey={dataKey}
      stroke={strokeFill}
      dot={showDots ? { fill: strokeFill, r: 2 } : false}
    />
  );
};

export const generateBarChartElement = ({
  dataKey,
  fill,
  stackId,
  index,
}: {
  dataKey: string;
  fill: string;
  stackId: string;
  index: number;
}) => {
  return <Bar key={index} dataKey={dataKey} fill={fill} stackId={stackId} />;
};

export const getBarElements = (seriesNames: string[]) => {
  return seriesNames.map((seriesName, index) => {
    return generateBarChartElement({
      dataKey: seriesName,
      stackId: 'a',
      fill: CHART_COLOR_PALETTE[index % CHART_COLOR_PALETTE.length],
      index,
    });
  });
};

export const getLineElements = (
  seriesNames: string[],
  showDots?: boolean,
  loader?: boolean
) => {
  return seriesNames.map((seriesName, index) => {
    return generateLineElement({
      dataKey: seriesName,
      strokeWidth: 2,
      strokeFill: loader
        ? '#C1CFD6'
        : CHART_COLOR_PALETTE[index % CHART_COLOR_PALETTE.length],
      index: index,
      showDots,
    });
  });
};

export const getNoDataMessage = () => (
  <div className='h-full flex flex-col items-center justify-center'>
    <Typography variant='caption' className='text-neutral-lighter'>
      {intl.get('INSIGHTS_PAGE_TABS.NO_REQUESTS')}
    </Typography>
  </div>
);
