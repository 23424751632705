import { useCallback, Dispatch, SetStateAction, useEffect } from 'react';
import {
  NewProject,
  Request,
  UpdateReqData,
  TaskDetailType,
  TimeOff,
} from 'utils/customTypes';
import { SIDE_PANEL_TYPES } from 'utils/constants';
import RequestSidePanel from 'Pages/RequestPage/RequestSidePanel';
import TimeOffForm from 'Pages/UserTimeOff/TimeOffForm';
import { RequestProvider } from 'Pages/RequestPage/context/RequestContext';
import { useSelector } from 'react-redux';
import { selectActiveRequestQuestions } from 'state/ActiveRequest/activeRequestSlice';

const PanelContent = ({
  type,
  data,
  shouldDisableUpdate,
  setShouldDisableUpdate,
  unsavedChanges,
  updatedData,
  setUpdatedData,
  showUnsavedChangesAnimation,
  setUnsavedChanges,
  setShouldDisableSubmitRequest,
  setIsRequestDetailFieldsValid,
  isDataReady,
}: {
  type: string;
  data: NewProject | Request | TaskDetailType;
  shouldDisableUpdate: boolean;
  setShouldDisableUpdate: Dispatch<SetStateAction<boolean>>;
  unsavedChanges: boolean;
  updatedData: NewProject | UpdateReqData | TaskDetailType | TimeOff;
  setUpdatedData: Dispatch<
    SetStateAction<NewProject | UpdateReqData | TaskDetailType | TimeOff | null>
  >;
  showUnsavedChangesAnimation: boolean;
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>;
  setShouldDisableSubmitRequest: Dispatch<SetStateAction<boolean>>;
  setIsRequestDetailFieldsValid: Dispatch<SetStateAction<boolean>>;
  isViewOnly?: boolean;
  isDataReady: boolean;
}) => {
  const requestQuestionsData = useSelector(selectActiveRequestQuestions);

  const getContent = useCallback(() => {
    switch (type) {
      case SIDE_PANEL_TYPES.REQUEST:
        return (
          data && (
            <RequestProvider
              requestData={data as Request}
              updatedReqData={updatedData as UpdateReqData}
              requestQuestionsData={requestQuestionsData}
            >
              <RequestSidePanel
                requestData={data as Request}
                updatedReqData={updatedData as UpdateReqData}
                setUpdatedReqData={
                  setUpdatedData as Dispatch<SetStateAction<UpdateReqData>>
                }
                requestQuestionsData={requestQuestionsData}
                setShouldDisableUpdate={setShouldDisableUpdate}
                unsavedChanges={unsavedChanges}
                setUnsavedChanges={setUnsavedChanges}
                showUnsavedChangesAnimation={showUnsavedChangesAnimation}
                setShouldDisableSubmitRequest={setShouldDisableSubmitRequest}
                setIsRequestDetailFieldsValid={setIsRequestDetailFieldsValid}
                isDataReady={isDataReady}
              />
            </RequestProvider>
          )
        );
      case SIDE_PANEL_TYPES.TIME_OFF:
        return (
          <TimeOffForm
            originalTimeOff={(data || {}) as TimeOff}
            currentTimeOff={(updatedData || {}) as TimeOff}
            setCurrentTimeOff={
              setUpdatedData as Dispatch<SetStateAction<TimeOff>>
            }
            unsavedChanges={unsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            showUnsavedChangesAnimation={showUnsavedChangesAnimation}
            shouldDisableUpdate={shouldDisableUpdate}
            setShouldDisableUpdate={setShouldDisableUpdate}
          />
        );
    }
  }, [
    type,
    data,
    setShouldDisableUpdate,
    shouldDisableUpdate,
    unsavedChanges,
    showUnsavedChangesAnimation,
    setUpdatedData,
    updatedData,
    setUnsavedChanges,
    setIsRequestDetailFieldsValid,
    requestQuestionsData,
    isDataReady,
    setShouldDisableSubmitRequest,
  ]);

  useEffect(() => {
    if (showUnsavedChangesAnimation) {
      scrollToTop();
    }
  }, [showUnsavedChangesAnimation]);

  const scrollToTop = () => {
    const panelContentContainer = document.getElementById(
      'panel-content-container'
    );
    if (panelContentContainer) {
      panelContentContainer.scrollTop = 0;
    }
  };

  return (
    <div
      className='px-6 pb-8 max-h-side-panel-content overflow-y-auto'
      id='panel-content-container'
    >
      {getContent()}
    </div>
  );
};

export default PanelContent;
