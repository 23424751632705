import React from 'react';
import intl from 'react-intl-universal';
import { tailwindOverride } from '@getsynapse/design-system';
import {
  Button,
  Icon,
  List,
  Popup,
  Typography,
  Tooltip,
} from '@getsynapse/design-system';
import loadingIcon from 'assets/icons/loading.svg';
import ImportButton, { ImportButtonProps } from 'Molecules/ImportButton';
import { MoreActionsOption } from 'utils/customTypes';

export type OptionProps = Omit<Partial<MoreActionsOption>, 'selectOption'> & {
  isSectionTitle?: boolean;
  isImportButton?: boolean;
  loading?: boolean;
  tabName?: string;
  renderLink?: string;
  selectOption?: (callback: () => void) => void;
  importButtonProps?: Omit<
    ImportButtonProps,
    'disabled' | 'isListItemButton' | ' onLaunchCallback' | 'buttonText'
  >;
};

const ExportComponent: React.FC<{ option: OptionProps; link: string }> = ({
  option,
  link,
}) => {
  return (
    <a
      href={link}
      target='_blank'
      rel='noreferrer'
      className={tailwindOverride(
        'text-sm',
        { 'text-neutral-light cursor-not-allowed': option.disabled },
        {
          'text-primary group-hover:text-secondary-darker cursor-pointer':
            !option.disabled,
        }
      )}
    >
      {option.label}
    </a>
  );
};
const ListItemContent: React.FC<{ option: OptionProps }> = ({ option }) => {
  return (
    <div className='py-1 px-2 flex items-center'>
      {(option.iconSrc || option.iconName || option.loading) && (
        <Icon
          className={tailwindOverride(
            'fill-current text-neutral-black mr-2 text-xl',
            {
              'group-hover:fill-current group-hover:text-neutral-dark':
                !option.disabled,
              'fill-current text-neutral-light cursor-not-allowed':
                option.disabled,
              'animate-spin': option.loading,
            }
          )}
          name={option.loading ? '' : option.iconName || ''}
          src={option.loading ? loadingIcon : option.iconSrc || ''}
          aria-hidden={option.loading ? false : true}
          aria-label={option.loading ? intl.get('LOADING') : option.label}
        />
      )}
      {option.renderLink ? (
        <ExportComponent option={option} link={option.renderLink} />
      ) : (
        <Typography
          className={tailwindOverride(
            { 'text-neutral-light cursor-not-allowed': option.disabled },
            {
              'text-primary group-hover:text-secondary-darker cursor-pointer':
                !option.disabled,
            }
          )}
          variant='label'
        >
          {option.label}
        </Typography>
      )}
    </div>
  );
};

const ListItem: React.FC<{
  option: OptionProps;
  close: () => void;
}> = ({ option, close }) => {
  return option.isImportButton && option.importButtonProps ? (
    <ImportButton
      {...option.importButtonProps}
      isListItemButton
      onLaunchCallback={() => close()}
      buttonText={option.label}
      disabled={option.disabled}
    />
  ) : (
    <li
      onClick={() => {
        if (option.disabled !== true && option.selectOption) {
          option.selectOption(close);
        }
      }}
      className={tailwindOverride(
        'font-body text-base truncate cursor-pointer group px-4 py-1 text-neutral-black',
        'focus-visible:border-0 focus-visible:ring-0 focus-visible:outline-none',
        {
          'hover:text-neutral-dark hover:shadow-list-item-hover hover:bg-neutral-lighter-two':
            !option.disabled,
          'focus-visible:bg-neutral-lighter-two focus-visible:text-neutral-dark focus-visible:shadow-list-item-hover':
            !option.disabled,
          'cursor-not-allowed': option.disabled,
        }
      )}
      data-cy={option.dataCy}
      role='option'
      tabIndex={-1}
      aria-selected={false}
    >
      <ListItemContent option={option} />
    </li>
  );
};

const SectionTitle: React.FC<{ option: OptionProps }> = ({ option }) => {
  return (
    <li className='py-1 px-4'>
      <Typography variant='caption' weight='medium' className='text-neutral'>
        {option.label}
      </Typography>
    </li>
  );
};

const ActionItem: React.FC<{
  option: OptionProps;
  close: () => void;
}> = ({ option, close }) => {
  if (option.isSectionTitle) {
    return <SectionTitle option={option} />;
  }

  if (option.tooltip?.text) {
    return (
      <Tooltip
        position={
          option.tooltip?.position ? option.tooltip?.position : 'bottomLeft'
        }
        contentProps={{
          className: 'z-10',
        }}
        openMode='hover2'
        timeout={0}
        showPopper
        trigger={
          <div>
            <ListItem option={option} close={close} />
          </div>
        }
      >
        {option.tooltip?.text}
      </Tooltip>
    );
  }

  return <ListItem option={option} close={close} />;
};

const MoreActionsPopup: React.FC<{
  options: OptionProps[];
  buttonVariant?: 'tertiary' | 'primary';
  buttonText?: string;
}> = ({
  options,
  buttonVariant = 'tertiary',
  buttonText = intl.get('MORE_ACTIONS'),
}) => {
  return (
    <Popup
      position='bottom-end'
      popperProps={{ className: 'z-5' }}
      trigger={({ isOpen }) => (
        <Button
          variant={buttonVariant}
          size='small'
          data-cy='more-actions-button'
          className={tailwindOverride({
            'bg-neutral-lightest': isOpen,
            'bg-primary-darker': buttonVariant === 'primary' && isOpen,
            'h-8': buttonVariant === 'primary',
          })}
        >
          <Typography
            className={tailwindOverride('text-primary mr-2 text-sm', {
              'text-neutral-white': buttonVariant === 'primary',
            })}
          >
            {buttonText}
          </Typography>
          <Icon name={isOpen ? 'caret-up' : 'caret-down'} className='text-xs' />
        </Button>
      )}
    >
      {({ close }) => (
        <List
          options={options}
          data-cy='more-actions-list'
          renderOption={(
            option: MoreActionsOption,
            isSelected,
            selectOption: () => void
          ) => <ActionItem close={close} option={option} />}
          onSelectOption={() => {}}
        />
      )}
    </Popup>
  );
};

export default MoreActionsPopup;
