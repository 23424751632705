import { useEffect, useMemo } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs } from '@getsynapse/design-system';
import {
  setBoardProcess,
  projectsBoard,
  setProjectsBoard,
  teamBoardProcess,
  myBoardProcess,
  selectAssociatedProcessesAndStages,
} from 'state/Projects/projectsSlice';
import {
  getOrganizationProcesses,
  selectProjectProcesses,
} from 'state/Processes/processesSlice';
import { selectIsUserLd } from 'state/User/userSlice';
import { fetchFilterSettingByType } from 'state/Settings/Filters/FiltersSlice';
import { PROJECTS_BOARD_TABS, SETTINGS_FILTERS } from 'utils/constants';
import TeamBoard from '../components/TeamBoard';
import MyBoard from '../components/MyBoard';
import { selectCurrentViewSettingValue } from 'state/ViewSettings/viewSettingsSlice';
import { ProjectsBoardTabs } from 'utils/customTypes';

const BoardView: React.FC<{ handleAddProject: () => void }> = ({
  handleAddProject,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const activeTab = queryParams.get('activeTab');
  const tabMapping: { [key: string]: number } = useMemo(
    () => ({
      [PROJECTS_BOARD_TABS.TEAM_BOARD]: 0,
      [PROJECTS_BOARD_TABS.MY_BOARD]: 1,
    }),
    []
  );
  const dispatch = useDispatch();
  const organizationProjectProcesses = useSelector(selectProjectProcesses);
  const processesAssociatedToProjects = useSelector(
    selectAssociatedProcessesAndStages
  );
  const isLDUser = useSelector(selectIsUserLd);
  const selectedProjectsBoard = useSelector(projectsBoard);
  const selectedTeamBoardProcess = useSelector(teamBoardProcess);
  const selectedMyBoardProcess = useSelector(myBoardProcess);
  const savedTeamBoardProcess = useSelector(
    selectCurrentViewSettingValue('PROJECT_VIEW_BY_TEAM_BOARD')
  );
  const savedMyBoardProcess = useSelector(
    selectCurrentViewSettingValue('PROJECT_VIEW_BY_MY_BOARD')
  );

  const availableProcesses = useMemo(() => {
    const processes = [];
    for (const process of organizationProjectProcesses) {
      const isProcessDisabled = process.enabled !== null && !process.enabled;
      const isProcessAssociated = process.id in processesAssociatedToProjects;
      if (!isProcessDisabled || isProcessAssociated) {
        processes.push(process);
      }
    }
    return processes;
  }, [organizationProjectProcesses, processesAssociatedToProjects]);

  const currentTabIndex = useMemo(() => {
    const tab = activeTab ? activeTab : selectedProjectsBoard;
    return tab ? tabMapping[tab] : 0;
  }, [activeTab, selectedProjectsBoard, tabMapping]);

  const defaultProcess = useMemo(() => {
    return (
      availableProcesses.find((process) => {
        return (
          process.id ===
          (currentTabIndex === 0
            ? savedTeamBoardProcess?.value
            : savedMyBoardProcess?.value)
        );
      }) || availableProcesses[availableProcesses.length - 1]
    );
  }, [
    savedMyBoardProcess,
    savedTeamBoardProcess,
    availableProcesses,
    currentTabIndex,
  ]);

  useEffect(() => {
    if (defaultProcess && defaultProcess.id) {
      dispatch(
        setBoardProcess(
          selectedTeamBoardProcess || defaultProcess.id,
          PROJECTS_BOARD_TABS.TEAM_BOARD
        )
      );
      dispatch(
        setBoardProcess(
          selectedMyBoardProcess || defaultProcess.id,
          PROJECTS_BOARD_TABS.MY_BOARD
        )
      );
    }
  }, [
    dispatch,
    defaultProcess,
    selectedTeamBoardProcess,
    selectedMyBoardProcess,
  ]);

  useEffect(() => {
    dispatch(getOrganizationProcesses());
    dispatch(fetchFilterSettingByType(SETTINGS_FILTERS.TEAM_PROJECTS_BOARD));
    dispatch(fetchFilterSettingByType(SETTINGS_FILTERS.MY_PROJECTS_BOARD));
  }, [dispatch]);

  const handleChangeTab = (index: number) => {
    const selectedTab = Object.keys(tabMapping)[index];
    dispatch(setProjectsBoard(selectedTab as ProjectsBoardTabs));
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?activeTab=${Object.keys(tabMapping)[index]}`
    );
  };

  return (
    <div className='pb-4 px-6 min-h-full'>
      {isLDUser ? (
        <Tabs
          index={currentTabIndex}
          onChange={handleChangeTab}
          className='min-h-board'
          tabListProps={{ className: 'mb-4 w-58' }}
          type='tab'
          data={[
            {
              label: intl.get('PROJECTS_LIST_PAGE.BOARD.TEAM_BOARD'),
              content: <TeamBoard handleAddProject={handleAddProject} />,
            },
            {
              label: intl.get('PROJECTS_LIST_PAGE.BOARD.MY_BOARD'),
              content: <MyBoard handleAddProject={handleAddProject} />,
            },
          ]}
        />
      ) : (
        <div>
          <span className='text-neutral-dark font-semibold text-base'>
            {intl.get('PROJECTS_LIST_PAGE.BOARD.MY_BOARD')}
          </span>
          <MyBoard handleAddProject={handleAddProject} />
        </div>
      )}
    </div>
  );
};

export default BoardView;
