import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import { PROGRAM_PROJECT_FIELDS } from 'utils/constants';
import { ProgramLinkedProject, SortingType } from 'utils/customTypes';
import {
  ProgramProjectFilters,
  ProgramProjectFiltersKey,
} from 'utils/types/filters';
import { PROGRAM_PROJECT_TABLE_FILTERS } from 'utils/constants/programProject';
import { isDateRangeFilter } from 'utils/typeGuards';
import { checkEntityMatchesDateFilter } from 'utils/filters';
import { naturalSort } from '../../utils/naturalSorting';

export const orderProjectsBy = (
  projects: ProgramLinkedProject[],
  column: string,
  order: SortingType
) => {
  switch (column) {
    case PROGRAM_PROJECT_FIELDS.PROGRESS:
      return orderBy(
        projects,
        [
          (project: ProgramLinkedProject) => {
            const {
              number_of_completed_tasks: completedTasksCount,
              number_of_not_completed_tasks: incompletedTasksCount,
            } = project;

            const percentage =
              completedTasksCount > 0
                ? completedTasksCount /
                  (completedTasksCount + incompletedTasksCount)
                : 0;
            return percentage;
          },
        ],
        [order]
      );
    case PROGRAM_PROJECT_FIELDS.TITLE:
      const test = naturalSort(
        [...projects],
        column as keyof ProgramLinkedProject,
        order
      );
      return test;
    default:
      return orderBy(projects, [column], [order]);
  }
};

const checkProgressFilter: (
  project: ProgramLinkedProject,
  filterValue: string[]
) => boolean = (project, filterValue) => {
  const progressFilter: number = Number(filterValue[0]?.trim());
  if (isNaN(progressFilter)) {
    return false;
  }
  const {
    number_of_completed_tasks: completedTasksCount,
    number_of_not_completed_tasks: incompletedTasksCount,
  } = project;

  let realValue: number =
    completedTasksCount > 0
      ? completedTasksCount / (completedTasksCount + incompletedTasksCount)
      : 0;

  const formattedFilter = Intl.NumberFormat('en-US', {
    style: 'percent',
  }).format(progressFilter / 100);
  const formattedValue = Intl.NumberFormat('en-US', {
    style: 'percent',
  }).format(realValue);

  return formattedFilter === formattedValue;
};

const projectMatchAllFilters: (
  project: ProgramLinkedProject,
  filters: ProgramProjectFilters
) => boolean = (project, filters) => {
  let allFiltersMatched = true;
  for (const filter of Object.keys(filters)) {
    const filterValue = filters[filter as ProgramProjectFiltersKey];
    const projectValue = project[filter as keyof ProgramLinkedProject];

    if (!isEmpty(filterValue)) {
      let projectMatchesCurrentFilter = false;

      switch (filter) {
        case PROGRAM_PROJECT_TABLE_FILTERS.START_DATE:
        case PROGRAM_PROJECT_TABLE_FILTERS.TARGET_COMPLETION_DATE:
        case PROGRAM_PROJECT_TABLE_FILTERS.ACTUAL_COMPLETION_DATE:
          if (isDateRangeFilter(filterValue)) {
            projectMatchesCurrentFilter = checkEntityMatchesDateFilter(
              projectValue as string,
              filterValue
            );
          }
          break;
        case PROGRAM_PROJECT_TABLE_FILTERS.PROGRESS:
          projectMatchesCurrentFilter = checkProgressFilter(
            project,
            filterValue as string[]
          );
          break;
        default:
          projectMatchesCurrentFilter = (filterValue as string[]).includes(
            projectValue as string
          );
          break;
      }

      allFiltersMatched = allFiltersMatched && projectMatchesCurrentFilter;
      if (!allFiltersMatched) {
        return allFiltersMatched;
      }
    } else {
      allFiltersMatched = false;
      return allFiltersMatched;
    }
  }
  return allFiltersMatched;
};

export const filterProgramProjects: (
  projects: ProgramLinkedProject[],
  search: string,
  filters: ProgramProjectFilters
) => ProgramLinkedProject[] = (projects, search, filters) => {
  let filteredProjects = projects;
  const filtersActive = Object.keys(filters).length > 0;
  const searchActive = search;
  if (filtersActive || searchActive) {
    filteredProjects = projects.filter((project: ProgramLinkedProject) => {
      let projectMatchSearch = false;
      let projectMatchFilters = false;
      if (filtersActive) {
        projectMatchFilters = projectMatchAllFilters(project, filters);
      }
      if (searchActive) {
        projectMatchSearch = project.title
          ?.toLocaleLowerCase()
          .includes(search.toLocaleLowerCase());
      }

      if (searchActive && filtersActive) {
        return projectMatchSearch && projectMatchFilters;
      } else if (searchActive && !filtersActive) {
        return projectMatchSearch;
      } else if (!searchActive && filtersActive) {
        return projectMatchFilters;
      } else {
        return false;
      }
    });
  }
  return filteredProjects;
};
