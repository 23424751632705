import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import axios from 'axios';
import {
  LinkedTaskContentState,
  LinkedTaskContent,
  LinkedTaskContentUploader,
} from 'types/store/linkedTaskContent';
import { fetchLinkedTaskContent } from 'api/linkedTaskContent';
import { normalizeState } from 'state/utils/normalizeState';
import { RootState } from 'state/store';

const initialState: LinkedTaskContentState = {
  linkedContentIds: [],
  linkedContentById: {},
  uploadersById: {},
  uploadersIds: [],
  loading: false,
};

export const fetchTaskContent = createAsyncThunk(
  'linkedTaskContent/fetchTaskContent',
  async (taskId: string, { rejectWithValue }) => {
    try {
      const response = await fetchLinkedTaskContent({ taskId });
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error fetching linked task content');
    }
  }
);

const linkedTaskContentSlice = createSlice({
  name: 'linkedTaskContent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskContent.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTaskContent.fulfilled, (state, action) => {
        if (action.payload) {
          const linkedContent = action.payload.linkedContent;
          const uploaders = action.payload.uploaders;
          const normalizedLinkedContentState =
            normalizeState<LinkedTaskContent>(linkedContent);
          const normalizedUploadersState =
            normalizeState<LinkedTaskContentUploader>(uploaders);
          state.linkedContentIds = normalizedLinkedContentState.ids;
          state.linkedContentById = normalizedLinkedContentState.entities;
          state.uploadersIds = normalizedUploadersState.ids;
          state.uploadersById = normalizedUploadersState.entities;
        }
        state.loading = false;
      })
      .addCase(fetchTaskContent.rejected, (state) => {
        state.loading = false;
      });
  },
});

const selectLinkedTaskContentById = (state: RootState) =>
  state.linkedTaskContent.linkedContentById;
const selectLinkedTaskContentUploadersById = (state: RootState) =>
  state.linkedTaskContent.uploadersById;

export const selectLinkedTaskContent = createSelector(
  [selectLinkedTaskContentById, selectLinkedTaskContentUploadersById],
  (
    linkedContentById: LinkedTaskContentState['linkedContentById'],
    uploadersById: LinkedTaskContentState['uploadersById']
  ) => {
    return Object.values(linkedContentById).map((linkedContent) => {
      const uploader = uploadersById[linkedContent.uploadedBy];
      return {
        ...linkedContent,
        uploader,
      };
    });
  }
);

export const selectIsFetchingLinkedTaskContent = (state: RootState) =>
  state.linkedTaskContent.loading;

export default linkedTaskContentSlice.reducer;
