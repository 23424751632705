import { ReactNode } from 'react';
import { tailwindOverride } from '@getsynapse/design-system';

type DetailsPagePropsBase = {
  topBar?: ReactNode;
  comments?: ReactNode;
  className?: string;
  bodyClassName?: string;
  topBarClassName?: string;
  dataCy?: string;
};

type DetailsPagePropsWithContent = DetailsPagePropsBase & {
  content: ReactNode;
  children?: never;
};

type DetailsPagePropsWithChildren = DetailsPagePropsBase & {
  children: ReactNode;
  content?: never;
};

type DetailsPageProps =
  | DetailsPagePropsWithContent
  | DetailsPagePropsWithChildren;

const DetailsPage = (detailsPageProps: DetailsPageProps) => {
  const {
    topBar,
    comments,
    content,
    className,
    bodyClassName,
    topBarClassName,
    dataCy,
    children,
  } = detailsPageProps;

  return (
    <div
      className={tailwindOverride(
        'mx-6 rounded-t-lg border-l border-r border-t border-neutral-lighter-two flex-grow flex flex-row',
        className
      )}
      data-cy={dataCy}
    >
      <div className='flex flex-col flex-grow'>
        {topBar && (
          <div
            className={tailwindOverride(
              'h-12 border-b pr-2 pl-2 pt-2 pb-2 border-neutral-lighter-two',
              topBarClassName
            )}
          >
            {topBar}
          </div>
        )}

        <main
          className={tailwindOverride(
            'px-6 pt-4 overflow-y-auto pb-8 flex-grow',
            `${
              topBar ? 'max-h-details-with-bar' : 'max-h-details-without-bar'
            }`,
            bodyClassName
          )}
        >
          {children ?? content}
        </main>
      </div>

      {comments && (
        <div className='flex flex-col max-h-comment-panel-content'>
          {comments}
        </div>
      )}
    </div>
  );
};

export default DetailsPage;
