import React from 'react';
import { tailwindOverride, Tooltip } from '@getsynapse/design-system';

const AllocationSummaryTooltip: React.FC<{
  trigger: React.ReactNode;
}> = ({ trigger, children }) => {
  const element = document.querySelector(
    '#teams-capacity-table-scroll-container'
  );
  return (
    <Tooltip
      trigger={trigger}
      openMode='click'
      timeout={0}
      position='bottomLeft'
      contentProps={{
        className: tailwindOverride(
          'p-4 z-50',
          'border border-purple-lighter',
          'bg-neutral-white'
        ),
      }}
      showPopper
      className='w-full'
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
      popperModifiers={[
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['bottom-start', 'top-end', 'top-start'],
            boundary: element,
          },
        },
      ]}
    >
      {children}
    </Tooltip>
  );
};

export default AllocationSummaryTooltip;
