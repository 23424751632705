import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import useModal from 'Hooks/useModal';

const DeleteTemplateModal: React.FC<{
  onConfirm: () => void;
  onCancel: () => void;
  shouldOpenModal?: boolean;
}> = ({ shouldOpenModal = false, onConfirm, onCancel }) => {
  const { Modal, modalProps, openModal, closeModal } = useModal();

  useEffect(() => {
    if (shouldOpenModal && !modalProps.isOpen) {
      openModal(modalProps);
    }
  }, [shouldOpenModal, modalProps, openModal]);

  const handleCloseModal = () => {
    onCancel();
    closeModal();
  };

  return (
    <Modal
      {...modalProps}
      title={intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.DELETE_TEMPLATE')}
      titleIcon={{
        name: 'trash',
        className: 'text-error-dark',
      }}
      aria-label={intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.DELETE_TEMPLATE')}
      size='medium'
      closeModal={handleCloseModal}
      data-cy='delete-template__confirmation-modal'
      actionButtons={[
        {
          children: intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.DELETE_TEMPLATE'),
          variant: 'primary',
          onClick: () => {
            handleCloseModal();
            onConfirm();
          },
          color: 'danger',
          'data-cy': 'delete-template__confirmation-modal__confirm-button',
        },
        {
          children: intl.get('CANCEL'),
          variant: 'tertiary',
          onClick: handleCloseModal,
        },
      ]}
    >
      <Typography variant='body' className='text-primary'>
        {intl.get(
          'SETTINGS_PAGE.TEMPLATE_PAGE.DELETE_TEMPLATE_WARNING_MENSSAGE'
        )}
      </Typography>
    </Modal>
  );
};

export default DeleteTemplateModal;
