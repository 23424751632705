import { FC, useRef, useEffect, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import useGetUserId from '../../hooks/useGetUserId';
import useEditTimeOff from '../../hooks/useEditTimeOff/useEditTimeOff';
import { selectFetchTimeOffEntryDaysStatus } from 'state/ManageTimeOff/TimeOffDays/timeOffDaysSlice';
import { SLICE_STATUS } from 'utils/constants';
import { Status } from 'utils/customTypes';
import SkeletonLoader from 'Pages/TimeOffListPage/components/TimeOffEntrySidePanel/components/SkeletonLoader/SkeletonLoader';
import TimeOffEntryForm from '../TimeOffEntryForm/TimeOffEntryForm';
import PartialHoursAmount from '../PartialHoursAmount/PartialHoursAmount';

export interface EditTimeOffProps {
  shouldSaveChanges: boolean;
  timeOffEntryId: string;
  dailyCapacity: number;
  setCanSaveChanges: (canSaveChanges: boolean) => void;
  onSaveChanges: (callback: () => void) => void;
  showNotification: (params: any) => void;
}

const EditTimeOff: FC<EditTimeOffProps> = ({
  shouldSaveChanges,
  timeOffEntryId,
  dailyCapacity,
  setCanSaveChanges,
  onSaveChanges,
  showNotification,
}) => {
  const userId = useGetUserId();
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const status = useRef<Status>(SLICE_STATUS.IDLE);
  const fetchTimeOffEntryDaysStatus = useSelector(
    selectFetchTimeOffEntryDaysStatus
  );

  const {
    updatedTimeOffEntry,
    updateTimeOffEntry,
    partialHoursItems,
    totalHours,
    updateTimeOffEntryDays,
    updateValidityForNumericInput,
    updateDateRangeValidity,
  } = useEditTimeOff({
    userId,
    timeOffEntryId,
    dailyCapacity,
    setCanSaveChanges,
    shouldSaveChanges,
    onSaveChanges,
    showNotification,
  });

  useEffect(() => {
    if (fetchTimeOffEntryDaysStatus !== status.current) {
      status.current = fetchTimeOffEntryDaysStatus;
      if (fetchTimeOffEntryDaysStatus !== SLICE_STATUS.LOADING) {
        setIsFetching(false);
      }
    }
  }, [fetchTimeOffEntryDaysStatus]);

  if (isFetching) {
    return (
      <SkeletonLoader
        id='edit-time-off__skeleton-loader'
        title={intl.get('MANAGE_TIME_OFF.EDIT_TIME_OFF')}
      />
    );
  }

  return (
    <Fragment>
      <TimeOffEntryForm
        title={intl.get('MANAGE_TIME_OFF.EDIT_TIME_OFF')}
        timeOffEntry={updatedTimeOffEntry}
        updateTimeOffEntry={updateTimeOffEntry}
        updateDateRangeValidity={updateDateRangeValidity}
      />
      <PartialHoursAmount
        updateValidityForNumericInput={updateValidityForNumericInput}
        partialHoursItems={partialHoursItems}
        totalHours={totalHours}
        updatePartialHours={updateTimeOffEntryDays}
        dailyCapacity={dailyCapacity}
      />
    </Fragment>
  );
};

export default EditTimeOff;
