import React, { useMemo } from 'react';
import moment from 'moment';
import { Datepicker } from '@getsynapse/design-system';
import {
  ALLOWED_DATE_FIELD_DISPLAY_VALUES,
  ALLOWED_FIELD_TYPES,
  DATE,
} from 'utils/constants';
import {
  DateValue,
  DateFieldType,
  DateFieldTemplateType,
} from 'utils/types/fields';
import { rangeDateType } from 'utils/customTypes';

interface DateFieldProps {
  field?: DateFieldType;
  fieldTemplate: DateFieldTemplateType;
  onChange: (newValue: DateValue | { val: DateValue[] }) => void;
  disabled?: boolean;
  errorMessage?: string;
}

const getStartAndEndDates: (
  isRange: boolean,
  value: DateValue | { val: DateValue[] }
) => (Date | null)[] = (isRange, value) => {
  const getDate = (date?: string | null) =>
    date ? moment(date).toDate() : null;

  if (!value.val) {
    return [null, null];
  } else if (isRange) {
    const startDate = (value.val as DateValue[]).find(
      (date) => date.display === ALLOWED_DATE_FIELD_DISPLAY_VALUES.START_DATE
    );
    const endDate = (value.val as DateValue[]).find(
      (date) => date.display === ALLOWED_DATE_FIELD_DISPLAY_VALUES.END_DATE
    );
    return [getDate(startDate?.val), getDate(endDate?.val)];
  } else {
    return [getDate((value as DateValue).val), null];
  }
};

const formatNewValue: (dateString?: string) => string | null = (dateString) =>
  dateString ? moment(dateString).format(DATE.DATE_STAMP) : null;

const DateField: React.FC<DateFieldProps> = ({
  field,
  fieldTemplate,
  onChange,
  disabled = false,
  errorMessage = '',
}) => {
  const canSelectRange = fieldTemplate.type === ALLOWED_FIELD_TYPES.DATERANGE;

  const [defaultStartDate, defaultEndDate] = useMemo<(Date | null)[]>(() => {
    if (field && field.value) {
      return getStartAndEndDates(canSelectRange, field.value);
    } else if (fieldTemplate.default_value && fieldTemplate.default_value) {
      return getStartAndEndDates(canSelectRange, fieldTemplate.default_value);
    } else {
      return [null, null];
    }
  }, [canSelectRange, field, fieldTemplate.default_value]);

  const [minDate, maxDate] = useMemo<(Date | null)[]>(() => {
    if (
      fieldTemplate.allowed_values &&
      fieldTemplate.allowed_values.length > 0
    ) {
      return getStartAndEndDates(true, { val: fieldTemplate.allowed_values });
    }

    return [null, null];
  }, [fieldTemplate.allowed_values]);

  const handlePickDate = (rangeDate: rangeDateType) => {
    let newValue: DateValue | { val: DateValue[] } = {
      val: formatNewValue(rangeDate.startDate),
      display: ALLOWED_DATE_FIELD_DISPLAY_VALUES.START_DATE,
    };
    if (canSelectRange) {
      newValue = {
        val: [
          {
            display: ALLOWED_DATE_FIELD_DISPLAY_VALUES.START_DATE,
            val: formatNewValue(rangeDate.startDate),
          },
          {
            display: ALLOWED_DATE_FIELD_DISPLAY_VALUES.END_DATE,
            val: formatNewValue(rangeDate.endDate),
          },
        ],
      };
    }
    onChange(newValue);
  };

  return (
    <Datepicker
      className='w-full'
      canSelectRange={canSelectRange}
      startDate={defaultStartDate}
      endDate={defaultEndDate}
      minDate={minDate}
      maxDate={maxDate}
      onPickDate={handlePickDate}
      disabled={disabled}
      startDateError={errorMessage}
      data-cy={`date-field-${fieldTemplate.id}__trigger-button`}
    />
  );
};

export default DateField;
