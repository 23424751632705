import {
  CapacityPerWeek,
  WeeklyCapacityPerWeek,
} from 'types/store/weeklyTeamCapacity';
import {
  UserKey,
  WeeklyTeamMemberProjectCapacity,
} from 'types/store/weeklyTeamMemberProjectCapacity';

const formatter = new Intl.DateTimeFormat('us-EN');

export const generateTeamMemberId: (
  teamId: string,
  memberId: string
) => UserKey = (teamId, memberId) => `team-${teamId}__member-${memberId}`;

export const generateFetchWeeklyCapacityURLFromDateRange: (
  rangeStart: string,
  rangeEnd: string,
  baseUrl: string
) => string = (rangeStart, rangeEnd, baseUrl) => {
  const url = `${baseUrl}?startDate=:startDate&endDate=:endDate`;
  const startDate = new Date(rangeStart.replace(/-/g, '/'));
  const endDate = new Date(rangeEnd.replace(/-/g, '/'));

  return url
    .replace(':startDate', formatter.format(startDate))
    .replace(':endDate', formatter.format(endDate));
};

export const removeTeamIdFromProjects: (
  teamId: string,
  memberId: string,
  projectsIds: string[],
  projectsById: WeeklyTeamMemberProjectCapacity
) => {
  projectsIdsToRemove: string[];
  updatedProjectsById: WeeklyTeamMemberProjectCapacity;
} = (teamId, memberId, projectsIds, projectsById) => {
  const projectsIdsToRemove = [];
  const updatedProjectsById = { ...projectsById };
  const teamMemberKey = generateTeamMemberId(teamId, memberId);

  for (const projectId of projectsIds) {
    if (projectId in updatedProjectsById) {
      const weeklyCapacities = {
        ...updatedProjectsById[projectId].weeklyCapacities,
      };

      if (teamMemberKey in weeklyCapacities) {
        delete weeklyCapacities[teamMemberKey];
        updatedProjectsById[projectId].weeklyCapacities = weeklyCapacities;
      }

      if (Object.keys(weeklyCapacities).length === 0) {
        projectsIdsToRemove.push(projectId);
        delete updatedProjectsById[projectId];
      }
    }
  }

  return {
    projectsIdsToRemove,
    updatedProjectsById,
  };
};

type AddWeeklyCapacityToProjectResponseType =
  WeeklyTeamMemberProjectCapacity[keyof WeeklyTeamMemberProjectCapacity];

export const addWeeklyCapacityToProject: (
  teamId: string,
  memberId: string,
  projectId: string,
  weeklyCapacity: WeeklyCapacityPerWeek,
  projectsById: WeeklyTeamMemberProjectCapacity
) => AddWeeklyCapacityToProjectResponseType = (
  teamId,
  memberId,
  projectId,
  weeklyCapacity,
  projectsById
) => {
  let currentWeeklyProjectCapacitiesPerWeek = {} as WeeklyCapacityPerWeek;
  const teamMemberKey = generateTeamMemberId(teamId, memberId);

  if (projectId in projectsById) {
    const weeklyProjectCapacitiesByTeamMember = {
      ...projectsById[projectId].weeklyCapacities,
    };
    currentWeeklyProjectCapacitiesPerWeek = {
      ...weeklyProjectCapacitiesByTeamMember[teamMemberKey],
    };
  }

  const updatedWeeklyProjectCapacitiesPerWeek = updateWeeklyCapacityPerWeek(
    { ...currentWeeklyProjectCapacitiesPerWeek },
    weeklyCapacity
  );

  return {
    weeklyCapacities: {
      [teamMemberKey]: updatedWeeklyProjectCapacitiesPerWeek,
    },
  };
};

export const updateWeeklyCapacityPerWeek: (
  currentCapacity: WeeklyCapacityPerWeek,

  newCapacity: WeeklyCapacityPerWeek
) => WeeklyCapacityPerWeek = (currentCapacity, newCapacity) => {
  const updatedWeeklyTeamCapacity = { ...currentCapacity };
  const weeks = Object.keys(newCapacity);
  for (const week of weeks) {
    if (week in currentCapacity) {
      updatedWeeklyTeamCapacity[week] = {
        ...(currentCapacity[week] as CapacityPerWeek),
        ...(newCapacity[week] as CapacityPerWeek),
      };
    } else {
      updatedWeeklyTeamCapacity[week] = newCapacity[week] as CapacityPerWeek;
    }
  }
  return updatedWeeklyTeamCapacity;
};
