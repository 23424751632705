import { Button } from '@getsynapse/design-system';
import PageFooter from 'Molecules/PageFooter/PageFooter';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router';
import { PATHS, SETTINGS_TABS } from 'utils/constants';

const UserPageFooter = ({
  disableSave,
  onUpdate,
  newUser,
}: {
  disableSave: boolean;
  onUpdate: () => void;
  newUser: boolean;
}) => {
  const history = useHistory();

  const onCancel = () => {
    history.push(`${PATHS.SETTINGS}/${SETTINGS_TABS.USERS}`);
  };

  return (
    <PageFooter>
      <Button variant='tertiary' className='mr-4' onClick={onCancel}>
        {intl.get('CANCEL')}
      </Button>
      <Button
        disabled={disableSave}
        onClick={onUpdate}
        data-cy='update-user_button'
      >
        {newUser
          ? intl.get('SAVE')
          : intl.get('SETTINGS_PAGE.USER_PAGE.FOOTER.UPDATE_BUTTON')}
      </Button>
    </PageFooter>
  );
};

export default UserPageFooter;
