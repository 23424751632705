import React from 'react';
import MemberRow from '../MemberRow/MemberRow';
import TeamMemberProjects from '../../../TeamMemberProjects/TeamMemberProjects';

const MemberRowAccordion: React.FC<{
  teamId: string;
  memberId: string;
  bgColor: string;
  updateRowInView: (rowId: string, inView: boolean) => void;
}> = ({ teamId, memberId, bgColor, updateRowInView }) => {
  return (
    <React.Fragment>
      <MemberRow
        teamId={teamId}
        memberId={memberId}
        bgColor={bgColor}
        updateMemberIsInView={updateRowInView}
      />
      <TeamMemberProjects
        teamId={teamId}
        memberId={memberId}
        bgColor={bgColor}
      />
    </React.Fragment>
  );
};

export default MemberRowAccordion;
