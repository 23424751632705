import React from 'react';
import { Typography, tailwindOverride } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { Task, Task_Status } from 'utils/customTypes';
import { TaskLevelAllocation } from 'utils/types/tasksAssignmentsResourcesCapacity';
import { Column } from '../../../Timeline/Timeline';

type Style = {
  boxShadow: string;
  textColor: string;
  borderColor: string;
};

const taskStatusToStyle: Record<Task_Status, Style> = {
  new: {
    boxShadow: 'rgb(var(--color-purple-dark))',
    textColor: 'text-purple-darker',
    borderColor: 'border-purple-light',
  },
  in_progress: {
    boxShadow: 'rgb(var(--color-teal-default))',
    textColor: 'text-teal-dark',
    borderColor: 'border-teal-lighter',
  },
  completed: {
    boxShadow: 'rgb(var(--color-success-dark))',
    textColor: 'text-success-darker',
    borderColor: 'border-success-lighter',
  },
  on_hold: {
    boxShadow: 'rgb(var(--color-tertiary-default))',
    textColor: 'text-tertiary-dark',
    borderColor: 'border-tertiary-lighter',
  },
};

const formatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
});

const Estimation: React.FC<{
  task: Task;
  isCurrentWeek: boolean;
  estimation: TaskLevelAllocation;
}> = ({ isCurrentWeek, task, estimation, ...otherProps }) => {
  const className = taskStatusToStyle[task.status];
  return (
    <Column isCurrentWeek={isCurrentWeek} className='pb-3' {...otherProps}>
      <div
        className={tailwindOverride(
          'w-full h-6 py-1 px-1.5',
          'flex justify-center items-center',
          'border rounded-sm bg-neutral-white',
          className.borderColor
        )}
        style={{
          boxShadow: `0px 2px 2px 0px #12151B0D, 2px 0px 0px 0px ${className.boxShadow} inset`,
        }}
      >
        <Typography
          variant='caption'
          weight='medium'
          className={className.textColor}
        >
          {intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION_TIME', {
            allocation: `${formatter.format(estimation.allocation)} `,
          })}
        </Typography>
      </div>
    </Column>
  );
};

export default Estimation;
