import { useState, ChangeEvent } from 'react';
import intl from 'react-intl-universal';
import {
  FormItem,
  RadioGroup,
  LargeLabelInput,
  Icon,
} from '@getsynapse/design-system';
import { Objective } from 'utils/types/program';
import tagetIcon from 'assets/icons/target-icon-green.svg';

type Props = {
  objective?: Objective;
  setObjectiveData: React.Dispatch<React.SetStateAction<Objective | undefined>>;
};

const ObjectiveData = ({ objective, setObjectiveData }: Props) => {
  const objectiveMetOptions = [
    {
      label: intl.get('PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.YES_MET'),
      value: 'true',
    },
    {
      label: intl.get('PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.NOT_MET'),
      value: 'false',
    },
  ];

  const [selectedOption, setSelectedOption] = useState('');

  const onSetObjectiveMet = (event: ChangeEvent<HTMLInputElement>) => {
    const optionId = objectiveMetOptions.findIndex(
      (option) => option.value === event.target.value
    );
    if (optionId !== -1) {
      const isTrue = objectiveMetOptions[optionId].value === 'true';
      setSelectedOption(objectiveMetOptions[optionId].value);
      setObjectiveData((prev) => ({ ...prev, isMet: isTrue } as Objective));
    }
  };

  const onSetObjectiveName = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setObjectiveData(
      (prev) => ({ ...prev, name: event.target.value } as Objective)
    );
  };

  return (
    <div className='flex flex-grow px-8 pt-6 pb-10 items-start border-b border-primary-lighter-two'>
      <Icon src={tagetIcon} className='text-5xl pr-4 self-start' />
      <LargeLabelInput
        className='w-3/5'
        value={objective?.name}
        onChange={onSetObjectiveName}
        name={intl.get('PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE', {
          number: objective?.position,
        })}
        state='default'
        label={intl.get('PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE', {
          number: objective?.position,
        })}
        labelProps={{
          className: 'text-base leading-6 mb-0',
          'data-testid': 'category-objective_number',
        }}
        subtitle={intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.AT_END_OF_PROGRAM_PARTICIPANTS'
        )}
        subtitleProps={{ className: 'text-neutral-darker' }}
        textAreaProps={{
          placeholder: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.EDIT_OBJECTIVE_PLACEHOLDER'
          ),
          className: 'w-full max-h-32 placeholder-text-neutral',
          'data-testid': 'category-objective_name-input',
          rows: 1,
        }}
        readOnly={objective?.programObjectiveTemplateId !== null}
      />
      <FormItem
        form='form-id'
        className='ml-16'
        component='fieldset'
        label={intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.WAS_OBJECTIVE_MET'
        )}
        labelProps={{
          className: 'text-base leading-5 tracking-wider mb-7',
        }}
      >
        <RadioGroup
          checkOption='checked'
          checked={selectedOption || objective?.isMet?.toString()}
          name='option'
          options={objectiveMetOptions}
          inputProps={{
            onChange: onSetObjectiveMet,
            'data-testid': 'category-objective_is-met-input',
          }}
        />
      </FormItem>
    </div>
  );
};

export default ObjectiveData;
