import moment from 'moment';
import { DATE } from './constants';
import { RangeFilter } from './types/filters';
import { Option } from './customTypes';

export type RawFilters = Record<
  string,
  string[] | { from?: string; to?: string }
>;

type BaseFilters = Record<string, string[] | string>;

export const parseFiltersForBackend: (
  rawFilters?: RawFilters
) => BaseFilters = (rawFilters) => {
  const filters: BaseFilters = {};

  if (rawFilters) {
    for (const property in rawFilters) {
      const rawFilterValue = rawFilters[property];
      if (Array.isArray(rawFilterValue)) {
        filters[property] = (rawFilterValue as string[]).join(',');
      } else if (rawFilterValue.from || rawFilterValue.to) {
        if (rawFilterValue.from) {
          const propertyName = `${property}_from`;
          if (isNaN(rawFilterValue.from as any)) {
            const parsedDate = moment(new Date(rawFilterValue.from));
            filters[propertyName] = parsedDate.format(DATE.DATE_STAMP);
          } else {
            filters[propertyName] = rawFilterValue.from;
          }
        }
        if (rawFilterValue.to) {
          const propertyName = `${property}_to`;
          if (isNaN(rawFilterValue.to as any)) {
            const parsedDate = moment(new Date(rawFilterValue.to));
            filters[propertyName] = parsedDate.format(DATE.DATE_STAMP);
          } else {
            filters[propertyName] = rawFilterValue.to;
          }
        }
      }
    }
  }

  return filters;
};

export const getDateFilterLabel = (value: RangeFilter, dateFormat: string) => {
  let label = '';
  if (value.from) {
    label = moment(new Date(value.from)).format(dateFormat);
  }

  if (value.from && value.to) {
    label = `${label} - ${moment(new Date(value.to)).format(dateFormat)}`;
  } else if (!value.from && value.to) {
    label = moment(new Date(value.to)).format(dateFormat);
  }
  return label;
};

export const getFilterOptionLabelByKeyAndValue = (
  options: Option[],
  value: string
) => {
  const option = options.find((option) => option.value === value);
  return option ? option.label : '';
};

export const checkEntityMatchesDateFilter: (
  entityValue: string,
  filterValue: RangeFilter
) => boolean = (entityValue, filterValue) => {
  let entityMatchesFilter = false;

  const entityDate = moment(new Date(entityValue));
  const fromRange = filterValue.from && new Date(filterValue.from);
  const toRange = filterValue.to && new Date(filterValue.to);

  if (fromRange && toRange) {
    entityMatchesFilter = entityDate.isBetween(
      fromRange,
      toRange,
      'days',
      '[]'
    );
  } else if (fromRange) {
    entityMatchesFilter = entityDate.isSameOrAfter(fromRange, 'days');
  } else if (toRange) {
    entityMatchesFilter = entityDate.isSameOrBefore(toRange, 'days');
  }

  return entityMatchesFilter;
};
