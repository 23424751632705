import React from 'react';
import { CapacityTask } from 'utils/types/tasksAssignmentsResourcesCapacity';
import {
  Allocation,
  TaskLevelAllocation,
} from 'utils/types/tasksAssignmentsResourcesCapacity';
import TaskDetails from './TasksDetails/TasksDetails';
import EstimationsTimeline from './EstimationsTimeline/EstimationsTimeline';
import { tailwindOverride } from '@getsynapse/design-system';

const AssignedTasks: React.FC<{
  userId: string;
  tasks: {
    data: CapacityTask;
    allocations: Allocation<TaskLevelAllocation>;
  }[];
  weeksArray: string[];
  className?: string;
}> = ({ userId, tasks, weeksArray, className }) => {
  return (
    <div className={tailwindOverride('flex flex-1 flex-col h-full', className)}>
      {tasks.map(({ data, allocations }) => (
        <div
          className='flex flex-1 h-full'
          key={`participant-${userId}__task-${data.id}`}
          data-cy={`participant-${userId}__task-${data.id}`}
        >
          <div className='w-2/5 relative'>
            <TaskDetails key={data.id} task={data} />
          </div>
          <div className='w-3/5 relative'>
            <EstimationsTimeline
              key={`participant-${userId}__task-${data.id}-estimation`}
              task={data}
              estimations={allocations}
              userId={userId}
              weeksArray={weeksArray}
              className={className}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default AssignedTasks;
