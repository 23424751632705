import { SidePanelFilter } from '@getsynapse/design-system';
import { ProgramFilters } from 'utils/types/filters';
import FiltersForm from './FiltersForm';
import useSidePanelFilters from 'Hooks/useSidePanelFilters';

interface ProgramsSidePanelFiltersProps {
  open: boolean;
  onUpdateFilters: (filters: ProgramFilters) => void;
  onClose: () => void;
  savedFilters: ProgramFilters;
}

const ProgramsSidePanelFilters: React.FC<ProgramsSidePanelFiltersProps> = ({
  open,
  onUpdateFilters,
  onClose,
  savedFilters,
}) => {
  const {
    appliedFilters,
    canApplyFilters,
    handleUpdateFilters,
    handleApplyFilters,
    handleClearAllFilters,
    handleClosePanel,
  } = useSidePanelFilters<ProgramFilters>(
    savedFilters,
    onUpdateFilters,
    onClose
  );

  return (
    <SidePanelFilter
      open={open}
      onClose={handleClosePanel}
      appliedFiltersCount={Object.keys(appliedFilters).length}
      canApplyFilters={canApplyFilters}
      onClearAllFilters={handleClearAllFilters}
      onApplyFilters={handleApplyFilters}
      data-testid='programs-filters-side-panel'
    >
      <FiltersForm
        appliedFilters={appliedFilters}
        onUpdateFilters={handleUpdateFilters}
      />
    </SidePanelFilter>
  );
};

export default ProgramsSidePanelFilters;
