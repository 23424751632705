import React from 'react';
import { tailwindOverride, useElevation } from '@getsynapse/design-system';

const SkeletonLoader: React.FC<{
  'data-testid'?: string;
  'data-cy'?: string;
}> = (props) => {
  const skim = useElevation(1);

  const BodyRow: React.FC<{ isOdd: boolean }> = ({ isOdd }) => (
    <div
      className={tailwindOverride('w-full h-12', 'flex items-center', {
        'bg-neutral-lightest-two': isOdd,
        'bg-neutral-white': !isOdd,
      })}
    >
      <div className='w-12 h-12 pr-2 pl-4 flex items-center animate-pulse'>
        <div className='w-4 h-4 rounded bg-primary-lighter-two' />
      </div>
      <div className='flex flex-1'>
        <div className='w-1/4 h-10 flex items-center px-2 animate-pulse'>
          <div className='w-5/6 h-4 rounded bg-primary-lighter-two' />
        </div>
        <div className='w-1/4 h-10 flex items-center px-2 animate-pulse'>
          <div className='w-5/6 h-4 rounded bg-primary-lighter-two' />
        </div>
        <div className='w-1/4 h-10 flex items-center px-2 animate-pulse'>
          <div className='w-5/6 h-4 rounded bg-primary-lighter-two' />
        </div>
        <div className='w-1/6 h-10 flex items-center px-2 animate-pulse'>
          <div className='w-3/4 h-4 rounded bg-primary-lighter-two' />
        </div>
        <div className='w-1/6 h-10 flex items-center px-2 animate-pulse'>
          <div className='w-3/4 h-4 rounded bg-primary-lighter-two' />
        </div>
      </div>
    </div>
  );

  const Filters = () => (
    <div
      className={tailwindOverride(
        'w-full px-4 py-2 rounded-t',
        'flex justify-between items-center',
        'bg-neutral-white'
      )}
      style={{
        border: '0.4px solid #DADDE2',
      }}
    >
      <div className='w-1/4 py-1 px-4 animate-pulse'>
        <div className='bg-neutral-lighter-two w-full h-6 rounded' />
      </div>
      <div className='h-8 flex items-center space-x-4 animate-pulse'>
        <div className='px-1 py-1.5'>
          <div className='w-5 h-5 bg-neutral-lighter-two' />
        </div>
        <div className='h-6 flex items-center px-2 space-x-2'>
          <div className='w-4 h-4 bg-neutral-lighter-two' />
          <div className='w-13 h-3.5 bg-neutral-lighter-two' />
        </div>
        <div className='h-6 flex items-center px-2 space-x-2'>
          <div className='w-13 h-3.5 bg-neutral-lighter-two' />
          <div className='w-4 h-4 bg-neutral-lighter-two' />
        </div>
      </div>
    </div>
  );

  const Pagination = () => (
    <div
      className={tailwindOverride(
        'w-full',
        'absolute bottom-0 left-0',
        'bg-neutral-white px-8 py-4',
        'flex items-center justify-between',
        'animate-pulse',
        skim
      )}
    >
      <div className='w-1/4 h-6 rounded bg-neutral-lighter-two' />
      <div className='w-1/4 h-6 rounded bg-neutral-lighter-two' />
    </div>
  );

  return (
    <div className='w-full h-full'>
      <div
        className={tailwindOverride(
          'w-full h-table-skeleton-loader',
          'overflow-hidden'
        )}
        {...props}
      >
        <Filters />
        <table
          className={tailwindOverride(
            'w-full',
            'border border-neutral-lighter-two border-t-0'
          )}
        >
          <thead className='w-full flex bg-primary-lightest'>
            <div className='w-12 flex items-center pr-2 pl-4 h-10 justify-end animate-pulse'>
              <div className='w-4 h-4 rounded bg-primary-lighter-two' />
            </div>
            <div className='w-1/4 h-10 flex items-center px-2 animate-pulse'>
              <div className='w-3/4 h-4 rounded bg-primary-lighter-two' />
            </div>
            <div className='w-1/4 h-10 flex items-center px-2 animate-pulse'>
              <div className='w-3/4 h-4 rounded bg-primary-lighter-two' />
            </div>
            <div className='w-1/4 h-10 flex items-center px-2 animate-pulse'>
              <div className='w-3/4 h-4 rounded bg-primary-lighter-two' />
            </div>
            <div className='w-1/6 h-10 flex items-center px-2 animate-pulse'>
              <div className='w-3/5 h-4 rounded bg-primary-lighter-two' />
            </div>
            <div className='w-1/6 h-10 flex items-center px-2 animate-pulse'>
              <div className='w-3/5 h-4 rounded bg-primary-lighter-two' />
            </div>
          </thead>
          <tbody>
            {[...Array(13)].map((_, index) => (
              <BodyRow key={index} isOdd={index % 2 !== 0} />
            ))}
          </tbody>
        </table>
      </div>
      <Pagination />
    </div>
  );
};

export default SkeletonLoader;
