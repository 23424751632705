import { MentionsInput, Mention } from 'react-mentions';
import { MentionComment } from '@getsynapse/design-system';
import classnames from 'classnames';
import { MENTIONS_TYPES } from 'utils/constants';
import { MentionType } from 'utils/customTypes';
import styles from './CommentMention.module.css';

interface CommentMentionProps {
  cardList: boolean;
  comment: MentionType;
}

const renderTaskMention = (cardList: boolean, comment: MentionType) => (
  <MentionComment
    className={classnames('ml-8 mb-4 max-w-full text-sm', {
      truncate: cardList,
    })}
    content={`“${comment.content}”`}
    mentions={comment.mentions || {}}
    mentionClassName='text-purple-darker font-normal'
  />
);

const renderMentionsInput = (cardList: boolean, comment: MentionType) => (
  <MentionsInput
    value={`“${comment.content}”`}
    className={`${
      cardList ? styles.cardMention : styles.modalMention
    } ml-8 mb-4 flex max-w-full text-sm`}
    disabled
  >
    <Mention
      trigger='@'
      data={[]}
      className='text-purple-darker'
      displayTransform={(_, display) => `@${display}`}
    />
  </MentionsInput>
);

const CommentMention = ({ cardList, comment }: CommentMentionProps) =>
  comment.type === MENTIONS_TYPES.TASK
    ? renderTaskMention(cardList, comment)
    : renderMentionsInput(cardList, comment);

export default CommentMention;
