import { useMemo } from 'react';
import { FormItem } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';
import { FieldsFilter } from 'utils/types/filters';
import { Option, FieldsFiltersKey } from 'utils/customTypes';
import { getInitialValueForDropDown } from 'utils/functions';
import {
  FIELD_CONSOLIDATED_TYPES,
  CUSTOM_FIELDS_FILTERS,
} from 'utils/constants';

const FieldsFiltersForms = ({
  filters,
  onUpdateFilters,
}: {
  filters?: FieldsFilter;
  onUpdateFilters: (newFilters: FieldsFilter) => void;
}) => {
  const statusOptions = useMemo(() => {
    return Object.values(FIELD_CONSOLIDATED_TYPES).map((type) => ({
      label: intl.get(
        `SETTINGS_PAGE.FIELDS_PAGE.FIELD_NAMES.${type.toUpperCase()}`
      ),
      value: type,
    }));
  }, []);

  const updateFilters = (filterKey: FieldsFiltersKey, value: Option[]) => {
    let filtersCopy = { ...filters } as FieldsFilter;

    if (filterKey === CUSTOM_FIELDS_FILTERS.TYPE) {
      const optionValue = value as Option[];
      if (optionValue.length === 0) {
        delete filtersCopy[filterKey];
      } else {
        filtersCopy[filterKey] = optionValue.map(
          (option) => option.value
        ) as string[];
      }
    }
    onUpdateFilters(filtersCopy);
  };

  return (
    <div className='mt-8 flex flex-col space-y-5'>
      <FormItem
        label={intl.get('SETTINGS_PAGE.FIELDS_PAGE.TABLE_COLUMNS.TYPE')}
      >
        <MultiSelectDropdown
          options={statusOptions}
          values={getInitialValueForDropDown(
            statusOptions,
            filters && (filters[CUSTOM_FIELDS_FILTERS.TYPE] as string[])
          )}
          placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
          onChange={(option: Option[]) => updateFilters('type', option)}
          triggerProps={{
            'data-testid': 'fields-filters-form-type-trigger',
          }}
        />
      </FormItem>
    </div>
  );
};

export default FieldsFiltersForms;
