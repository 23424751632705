import { CurrentUser, OrganizationData } from 'utils/customTypes';

declare const window: any;

export const init = (user: CurrentUser, organization: OrganizationData) => {
  window.Intercom('boot', {
    app_id: 'qh3p0q61',
    user_id: user.id,
    email: user.email,
    name: user.full_name,
    company: organization.company_name,
    'synapse-version': 'v2',
    created_at: new Date().getTime(),
    hide_default_launcher: true,
  });
  window.Intercom('onHide', () => {
    window.Intercom('update', {
      hide_default_launcher: true,
    });
  });
};

export const showMessenger = () => {
  window.Intercom('update', {
    hide_default_launcher: false,
  });
  window.Intercom('show');
};

export const shutdown = () => {
  window.Intercom('shutdown');
};
