import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import { selectSubmittersIds } from 'state/ActualHours/actualHoursSlice';
import SubmitterAccordion from '../SubmitterAccordion/SubmitterAccordion';

const TimeEntries = () => {
  const submittersIds = useSelector(selectSubmittersIds);
  return (
    <div className='flex flex-col gap-y-4'>
      <Typography variant='body' weight='medium'>
        {intl.get('TASK_ACTUAL_HOURS_MODAL.TIME_ENTRIES')}
      </Typography>
      {submittersIds.map((submitterId) => {
        return (
          <SubmitterAccordion key={submitterId} submitterId={submitterId} />
        );
      })}
    </div>
  );
};

export default TimeEntries;
