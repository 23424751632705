import { Card } from 'Pages/InsightsPage/Components/Card';

const AggregatesLoader = () => {
  const divClasses = 'flex w-full min-h-26 space-x-2';
  const cardClasses = 'flex min-h-26 w-full items-center';
  const numberClasses = 'animate-pulse w-10 m-4 h-10 bg-neutral-lighter';
  const topTextClasses = 'animate-pulse w-24 h-4 bg-neutral-lighter mb-2';
  const bottomTextClasses = 'animate-pulse w-24 h-4 bg-neutral-lighter';

  return (
    <div data-testid='aggregates-loader'>
      <div className={divClasses}>
        <Card className={cardClasses}>
          <div className={numberClasses}></div>
          <div>
            <div className={topTextClasses}></div>
            <div className={bottomTextClasses}></div>
          </div>
        </Card>
        <Card className={cardClasses}>
          <div className={numberClasses}></div>
          <div>
            <div className={topTextClasses}></div>
            <div className={bottomTextClasses}></div>
          </div>
        </Card>
        <Card className={cardClasses}>
          <div className={numberClasses}></div>
          <div>
            <div className={topTextClasses}></div>
            <div className={bottomTextClasses}></div>
          </div>
        </Card>
      </div>
      <div className={divClasses}>
        <Card className={cardClasses}>
          <div className={numberClasses}></div>
          <div>
            <div className={topTextClasses}></div>
            <div className={bottomTextClasses}></div>
          </div>
        </Card>
        <Card className={cardClasses}>
          <div className={numberClasses}></div>
          <div>
            <div className={topTextClasses}></div>
            <div className={bottomTextClasses}></div>
          </div>
        </Card>
        <Card className={cardClasses}>
          <div className={numberClasses}></div>
          <div>
            <div className={topTextClasses}></div>
            <div className={bottomTextClasses}></div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AggregatesLoader;
