import { useMemo } from 'react';
import {
  LargeLabelInput,
  IconButton,
  tailwindOverride,
} from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { debounce } from 'lodash';
import { StringOption } from 'utils/customTypes';
import { ProgramCategory } from 'utils/types/program';

type CustomObjectiveProps = {
  category: ProgramCategory;
  selectedObjectiveTemplates: Map<
    string,
    StringOption & {
      checked: boolean;
      templateId: string;
    }
  >;
  objectiveKey: string;
  objectiveValue: string;
  index: number;
  setCreatedObjectives: (
    value: React.SetStateAction<Map<string, string>>
  ) => void;
  type?: string;
};

const CustomObjective = ({
  category,
  selectedObjectiveTemplates,
  objectiveKey,
  objectiveValue,
  index,
  setCreatedObjectives,
  type = 'objective',
}: CustomObjectiveProps) => {
  const numberOfItems = useMemo(() => {
    if (type === 'objective') {
      const selectedTemplates = category?.objective_templates!.filter(
        (template) =>
          category.objectives.findIndex(
            (objective) => objective.name === template.name
          ) !== -1
      );
      let templatesNoLongerSelected = 0;
      let newObjectiveTemplates = 0;

      let totalObjectives = category.objectives.length;
      let customObjectives = totalObjectives - selectedTemplates.length;

      selectedObjectiveTemplates.forEach((value) => {
        const wasThere = category.objectives.findIndex(
          (objective) => objective.name === value.label
        );

        if (value.checked && wasThere === -1) {
          newObjectiveTemplates++;
        }
        if (!value.checked && wasThere !== -1) {
          templatesNoLongerSelected++;
        }
      });

      return (
        customObjectives +
        (selectedTemplates.length +
          newObjectiveTemplates -
          templatesNoLongerSelected)
      );
    } else {
      return category.additionalBenefits?.length || 0;
    }
  }, [category, selectedObjectiveTemplates, type]);

  const updateCustomObjective = debounce(
    (event: React.ChangeEvent<HTMLTextAreaElement>, key: string) => {
      setCreatedObjectives((prev) =>
        new Map(prev).set(key, event.target.value)
      );
    },
    500
  );

  const removeCustomObjective = (key: string) => {
    setCreatedObjectives((prev) => {
      const newMap = new Map(prev);
      newMap.delete(key);
      return newMap;
    });
  };

  return (
    <div className='flex items-end pb-6' key={objectiveKey}>
      <LargeLabelInput
        className={tailwindOverride('w-full', {
          'w-2/4': category?.prompts?.length! <= 0,
        })}
        label={
          type === 'objective'
            ? intl.get('PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE', {
                number: numberOfItems! + (index + 1),
              })
            : intl.get(
                'PROGRAM_PAGE.STRATEGY_PAGE.ADD_ADDITIONAL_BENEFIT_MODAL.BENEFIT',
                {
                  number: numberOfItems! + (index + 1),
                }
              )
        }
        labelProps={{
          'data-testid': `custom-${type}-${index + 1}_input-label`,
        }}
        name={`new-${type}-${category.name}`}
        onChange={(event) => updateCustomObjective(event, objectiveKey)}
        state='default'
        subtitle={
          type === 'objective'
            ? intl.get(
                'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.ADD_OBJECTIVE.OBJECTIVES_SHOULD_BE'
              )
            : ''
        }
        subtitleProps={{
          'data-testid': `custom-${type}-${index + 1}_input-sublabel`,
          className: 'text-neutral-darker',
        }}
        textAreaProps={{
          'data-testid': `custom-${type}-${index + 1}_input`,
          placeholder:
            type === 'objective'
              ? intl.get(
                  'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.ADD_OBJECTIVE.ADD_OBJECTIVE_FOR'
                )
              : intl.get(
                  'PROGRAM_PAGE.STRATEGY_PAGE.ADD_ADDITIONAL_BENEFIT_MODAL.BENEFIT_INPUT_PLACEHOLDER'
                ),
          rows: 1,
          className: 'w-full placeholder-text-neutral',
          defaultValue: objectiveValue,
        }}
      />
      <IconButton
        onClick={() => removeCustomObjective(objectiveKey)}
        name='trash'
        className={tailwindOverride(
          'text-base text-neutral-dark',
          'hover:bg-neutral-lightest hover:shadow-allocation-table',
          'ml-2 mb-4 p-1',
          'rounded'
        )}
        data-testid={`custom-${type}-${index + 1}_remove-${type}`}
      />
    </div>
  );
};

export default CustomObjective;
