import { AllocationSummaryStyles } from 'utils/types/dailyTeamsCapacity';
import { ProjectStatus } from 'utils/customTypes';
import { PROJECT_STATUS } from 'utils/constants';

export const numberFormatter = new Intl.NumberFormat('en', {
  maximumFractionDigits: 2,
});

export const calculateCapacityPercentage: (
  assignedHours: number,
  dailyCapacity: number,
  timeOff: number
) => number = (assignedHours, dailyCapacity, timeOff) => {
  if (dailyCapacity === 0) {
    return 0;
  }
  const totalHours = assignedHours + timeOff;
  const percentage = (totalHours / dailyCapacity) * 100;
  return Math.round(percentage);
};

export const getAllocationSummaryStyles: (
  percentage: number
) => AllocationSummaryStyles = (percentage) => {
  const style = {
    bg: 'bg-success-lightest',
    text: 'text-success-darkest',
    border: 'border-success-lighter',
    leftBorder: 'rgb(var(--color-success-dark))',
  };
  if (percentage >= 60 && percentage < 80) {
    style.bg = 'bg-warning-lightest-two';
    style.text = 'text-warning-darker';
    style.border = 'border-warning-lighter';
    style.leftBorder = 'rgb(var(--color-warning-darker))';
  } else if (percentage >= 80 && percentage < 100) {
    style.bg = 'bg-fire-lightest';
    style.text = 'text-fire-darker';
    style.border = 'border-fire-light';
    style.leftBorder = 'rgb(var(--color-fire-dark))';
  } else if (percentage >= 100) {
    style.bg = 'bg-error-lightest-two';
    style.text = 'text-error-darker';
    style.border = 'border-error-lightest';
    style.leftBorder = 'rgb(var(--color-error-dark))';
  }
  return style;
};

export const noCapacityAndAssignedHoursAllocationSummaryStyle: AllocationSummaryStyles =
  {
    bg: 'bg-primary-lighter-two',
    text: 'text-primary-darker',
    border: 'border-primary-lighter',
    leftBorder: 'rgb(var(--color-primary-dark))',
  };

export const noCapacityWithAssignedHoursAllocationSummaryStyle: AllocationSummaryStyles =
  {
    bg: 'bg-error-lightest-two',
    text: 'text-error-darker',
    border: 'border-error-lightest',
    leftBorder: 'rgb(var(--color-error-dark))',
  };

export const getDailyProjectCapacityStylesByStatus: Record<
  ProjectStatus,
  {
    border: string;
    text: string;
    boxShadow: string;
  }
> = {
  [PROJECT_STATUS.NEW]: {
    border: 'border-purple-light',
    text: 'text-purple-darker',
    boxShadow: 'rgb(var(--color-purple-dark))',
  },
  [PROJECT_STATUS.IN_PLANNING]: {
    border: 'border-warning-light',
    text: 'text-warning-darker',
    boxShadow: 'rgb(var(--color-warning-darker))',
  },
  [PROJECT_STATUS.IN_PROGRESS]: {
    border: 'border-teal-lighter',
    text: 'text-teal-dark',
    boxShadow: 'rgb(var(--color-teal-default))',
  },
  [PROJECT_STATUS.COMPLETED]: {
    border: 'border-success-light',
    text: 'text-success-darker',
    boxShadow: 'rgb(var(--color-success-dark))',
  },
  [PROJECT_STATUS.ON_HOLD]: {
    border: 'border-tertiary-lighter',
    text: 'text-tertiary-dark',
    boxShadow: 'rgb(var(--color-tertiary-default))',
  },
  [PROJECT_STATUS.CLOSED]: {
    border: 'border-secondary-light',
    text: 'text-secondary-darker',
    boxShadow: 'rgb(var(--color-secondary-dark))',
  },
  [PROJECT_STATUS.CANCELED]: {
    border: 'border-error-lighter',
    text: 'text-error-darker',
    boxShadow: 'rgb(var(--color-error-dark))',
  },
};
