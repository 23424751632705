import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchUserTasks,
  selectUserTasksLoading,
  selectUserTasks,
  selectCanFetchtMoreTasks,
  selectTotalCount,
} from 'state/ProjectTasksList/userTasks/userTasksSlice';
import { fetchProjectProgress } from 'state/Project/progress/progressSlice';
import { selectUserId } from 'state/User/userSlice';
import { TASKS_TABLE_TABS } from 'utils/constants';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';
import TasksTable from '../TasksTable/TasksTable';

const UserTasks = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectUserTasksLoading);
  const userTasks = useSelector(selectUserTasks);
  const userId = useSelector(selectUserId);
  const totalCount = useSelector(selectTotalCount);
  const canFetchMoreTasks = useSelector(selectCanFetchtMoreTasks);
  const { projectId } = useParams<{ projectId: string }>();

  useEffect(() => {
    if (projectId && userId) {
      dispatch(
        fetchUserTasks({
          projectId,
          userId,
          includeTotalCount: true,
        })
      );
      dispatch(fetchProjectProgress({ projectId, userId }));
    }
  }, [userId, projectId, dispatch]);

  const handleFetchMoreTasks = useCallback(() => {
    dispatch(
      fetchUserTasks({
        projectId,
        userId: userId!,
        fetchNext: true,
      })
    );
  }, [dispatch, projectId, userId]);

  if (isLoading) {
    return (
      <SkeletonLoader
        data-testid={`${TASKS_TABLE_TABS.MY_TASKS}__skeleton-loader`}
      />
    );
  }

  return (
    <TasksTable
      tableName={TASKS_TABLE_TABS.MY_TASKS}
      tasks={userTasks}
      canFetchMoreTasks={canFetchMoreTasks}
      fetchMoreTasks={handleFetchMoreTasks}
      totalCount={totalCount}
    />
  );
};

export default UserTasks;
