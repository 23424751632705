import { Modal, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { useDispatch } from 'react-redux';
import { deleteCustomField } from 'state/CustomFields/customFieldsSlice';
import { useHistory } from 'react-router-dom';
import { PATHS, SETTINGS_TABS, SETTINGS_SECTIONS } from 'utils/constants';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fieldId: string;
};

const DeleteCustomFieldModal = ({ isOpen, setIsOpen, fieldId }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDeleteConfirmation = async () => {
    await dispatch(deleteCustomField(fieldId));
    setIsOpen(false);
    history.push(
      `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.FIELDS}`
    );
    dispatch(
      showSnackbarNotification({
        notificationVariant: 'success',
        notificationTitle: intl.get('SUCCESS_SNACKBAR_TITLE'),
        notificationMessage: intl.get(
          'SETTINGS_PAGE.FIELDS_PAGE.DELETE_FIELD_MODAL.SUCCESS_NOTIFICATION'
        ),
      })
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      data-cy='delete-custom-field_modal'
      title={intl.get('SETTINGS_PAGE.FIELDS_PAGE.DELETE_FIELD_MODAL.TITLE')}
      titleIcon={{ name: 'trash' }}
      aria-label={intl.get(
        'SETTINGS_PAGE.FIELDS_PAGE.DELETE_FIELD_MODAL.TITLE'
      )}
      closeModal={() => setIsOpen(false)}
      size='medium'
      actionButtons={[
        {
          children: intl.get(
            'SETTINGS_PAGE.FIELDS_PAGE.DELETE_FIELD_MODAL.DELETE_BUTTON'
          ),
          variant: 'primary',
          onClick: handleDeleteConfirmation,
          'data-cy': 'delete-custom-field_button',
        },
        {
          children: intl.get('CANCEL'),
          variant: 'tertiary',
          onClick: () => setIsOpen(false),
          'data-cy': 'cancel-delete-custom-field_button',
        },
      ]}
    >
      <Typography>
        {intl.get('SETTINGS_PAGE.FIELDS_PAGE.DELETE_FIELD_MODAL.BODY')}
      </Typography>
    </Modal>
  );
};

export default DeleteCustomFieldModal;
