import { useEffect, useState, useCallback, useMemo } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Typography,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Button,
} from '@getsynapse/design-system';
import { Tabs } from '@reach/tabs';
import { SETTINGS_ATTRIBUTES, PATHS } from 'utils/constants';
import ProcessesTable from './components/ProcessesTable';
import TemplatesTable from './components/TemplatesTable';
import { getOrganizationProcesses } from 'state/Processes/processesSlice';
import {
  fetchProjectTemplates,
  updateTemplatePagination,
} from 'state/ProjectTemplates/projectTemplatesSlice';
import { fetchProjects } from 'state/Projects/projectsSlice';
import { projectTemplatesForTable } from 'state/ProjectTemplates/projectTemplatesSlice';
import TaskBundlesTable from './components/TaskBundlesTable/TaskBundlesList';
import Pagination from 'Organisms/Pagination/ClientPagination';

const ProjectSettings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const templatesTable = useSelector(projectTemplatesForTable);
  const searchParams = new URLSearchParams(window.location.search);
  const indexParam = searchParams.get('attribute');
  const attrMapping = {
    [SETTINGS_ATTRIBUTES.PROCESS]: 0,
    [SETTINGS_ATTRIBUTES.TEMPLATES]: 1,
    [SETTINGS_ATTRIBUTES.TASK_BUNDLES]: 2,
  };
  const [activeIndex, setActiveIndex] = useState(
    indexParam ? attrMapping[indexParam] : 0
  );

  const isOnTaskBundlesTab = useMemo(() => activeIndex === 2, [activeIndex]);

  useEffect(() => {
    dispatch(getOrganizationProcesses());
    dispatch(fetchProjects());
    dispatch(fetchProjectTemplates());
  }, [dispatch]);

  const fetchTemplatePagination = useCallback(
    (params) => {
      dispatch(updateTemplatePagination(params));
    },
    [dispatch]
  );

  const changeTab = (index: number) => {
    setActiveIndex(index);
    const section = searchParams.get('section');
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?section=${section}&attribute=${
        Object.keys(attrMapping)[index]
      }`
    );
  };

  return (
    <Tabs index={activeIndex} onChange={changeTab}>
      <div>
        <Typography className='text-neutral pb-2'>
          {intl.get('SETTINGS_PAGE.PROJECTS_PAGE.PAGE_SUB_TITLE')}
        </Typography>
        <div className='flex justify-between'>
          <TabList
            type='subHeader'
            className='mb-0 flex-1 mr-5 border-b border-primary-lighter-two'
          >
            <Tab index={0} type='subHeader'>
              {intl.get('PROJECTS_LIST_PAGE.TABLE.HEAD.PROCESS_STAGE')}
            </Tab>
            <Tab index={1} type='subHeader'>
              {intl.get('SETTINGS_PAGE.PROJECTS_PAGE.TEMPLATES')}
            </Tab>
            <Tab index={2} type='subHeader'>
              {intl.get('ENTITIES.TASK_BUNDLE', { num: 2 })}
            </Tab>
          </TabList>
          {activeIndex === 1 && (
            <Button
              onClick={() => history.push(PATHS.NEW_TEMPLATE_PAGE)}
              variant='primary'
              data-cy='add-template-button'
            >
              {intl.get('SETTINGS_PAGE.PROJECTS_PAGE.ADD_TEMPLATES')}
            </Button>
          )}
          {activeIndex === 2 && (
            <Button
              onClick={() => history.push(PATHS.NEW_TASK_BUNDLE_PAGE)}
              data-cy='add-task-bundle_button'
            >
              {intl.get('SETTINGS_PAGE.PROJECTS_PAGE.ADD_TASK_BUNDLE')}
            </Button>
          )}
        </div>
      </div>

      <TabPanels>
        <TabPanel key={0}>
          <ProcessesTable />
        </TabPanel>
        <TabPanel key={1}>
          <>
            <TemplatesTable data={templatesTable.data} />
            <Pagination
              total={templatesTable.total}
              onChange={fetchTemplatePagination}
            />
          </>
        </TabPanel>
        <TabPanel key={2}>
          {isOnTaskBundlesTab && <TaskBundlesTable />}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ProjectSettings;
