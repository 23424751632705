import React from 'react';
import intl from 'react-intl-universal';
import {
  tailwindOverride,
  Typography,
  Tooltip,
} from '@getsynapse/design-system';
import { formatter } from 'utils/functions';

const CapacityBreakdownTooltip: React.FC<{
  weeklyCapacity: number;
  assignedHours: number;
  availableHours: number;
  trigger: React.ReactNode;
}> = ({ weeklyCapacity, assignedHours, availableHours, trigger }) => {
  return (
    <Tooltip
      trigger={trigger}
      openMode='click'
      timeout={0}
      position='bottomRight'
      contentProps={{
        className: tailwindOverride(
          'p-4 z-50',
          'border border-purple-lighter',
          'bg-neutral-white'
        ),
      }}
      showPopper
      usePortal
      className='w-full'
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <div className={tailwindOverride('w-full h-full', 'flex flex-col')}>
        <div className={tailwindOverride('flex justify-between gap-x-4 mb-px')}>
          <Typography variant='caption' className='text-purple-darker'>
            {intl.get('TEAMS_CAPACITY.WEEKLY_CAPACITY')}
          </Typography>
          <Typography
            variant='label'
            weight='medium'
            className='text-purple-darker'
          >
            {`${formatter.format(weeklyCapacity)}h`}
          </Typography>
        </div>
        <div className={tailwindOverride('flex justify-between gap-x-4 mb-px')}>
          <Typography variant='caption' className='text-purple-darker'>
            {intl.get('TEAMS_CAPACITY.ASSIGNED_HOURS')}
          </Typography>
          <Typography
            variant='label'
            weight='medium'
            className='text-purple-darker'
          >
            {`${formatter.format(assignedHours)}h`}
          </Typography>
        </div>
        <div className={tailwindOverride('flex justify-between gap-x-4')}>
          <Typography variant='caption' className='text-purple-darker'>
            {intl.get('TEAMS_CAPACITY.AVAILABLE_HOURS')}
          </Typography>
          <Typography
            variant='label'
            weight='medium'
            className='text-purple-darker'
          >
            {`${formatter.format(availableHours)}h`}
          </Typography>
        </div>
      </div>
    </Tooltip>
  );
};

export default CapacityBreakdownTooltip;
