import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';

class MyWorkspaceAPI {
  instance: AxiosInstance;

  constructor() {
    this.instance = initAxios(`${config.get('backendURL')}workspace`);
  }

  fetchDueTasks = async () => {
    const { data } = await this.instance.get('/tasks');
    return data;
  };

  fetchInReviewRequests = async () => {
    const { data } = await this.instance.get('/requests');
    return data;
  };

  fetchRecentMentions = async () => {
    const { data } = await this.instance.get('/mentions');
    return data;
  };

  fetchDueProjects = async () => {
    const { data } = await this.instance.get('/projects');
    return data;
  };
}

const myWorkspaceAPI = new MyWorkspaceAPI();

export default myWorkspaceAPI;
