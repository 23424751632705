import { useDispatch, useSelector } from 'react-redux';
import Card from '../Card';
import emptyRequestsImg from 'assets/images/empty-requests-in-review-img.svg';
import requestsInReviewIcon from 'assets/icons/in-review-requests-icon.svg';
import intl from 'react-intl-universal';
import { useState } from 'react';
import {
  selectRequestsInReviewForCard,
  setRequestsInReviewOrderForCard,
  selecRequestsInReviewStatus,
} from 'state/MyWorkspace/myWorkspaceSlice';
import RequestsTable from './RequestsTable';
import RequestsModal from './RequestsModal';
import { SLICE_STATUS } from 'utils/constants';
import RequestsTableLoader from './RequestsTableLoader';

const RequestsCard = () => {
  const dispatch = useDispatch();
  const requestsSelector = useSelector(selectRequestsInReviewForCard);
  const requestsStatus = useSelector(selecRequestsInReviewStatus);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSort = (orderByParam: string[], order: 'desc' | 'asc') => {
    dispatch(setRequestsInReviewOrderForCard({ order, orderBy: orderByParam }));
  };

  return (
    <>
      <RequestsModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        icon={requestsInReviewIcon}
      />
      <Card
        title={intl.get('MY_WORKSPACE.IN_REVIEW_REQUESTS.TITLE')}
        iconSrc={requestsInReviewIcon}
        emptyStateImg={emptyRequestsImg}
        emptyStateText={intl.get(
          'MY_WORKSPACE.IN_REVIEW_REQUESTS.EMPTY_STATE_TEXT'
        )}
        content={
          requestsStatus === SLICE_STATUS.LOADING ? (
            <RequestsTableLoader />
          ) : requestsSelector.length ||
            requestsStatus === SLICE_STATUS.FAILED ? (
            <RequestsTable
              data={requestsSelector}
              handleSort={handleSort}
              cardTable
            />
          ) : null
        }
        showViewAllButton={requestsSelector.length ? true : false}
        viewAllTooltipText={intl.get(
          'MY_WORKSPACE.IN_REVIEW_REQUESTS.TOOLTIP_TEXT'
        )}
        viewAllOnClick={() => setIsModalOpen(true)}
        dataCy='requests-in-review'
      />
    </>
  );
};

export default RequestsCard;
