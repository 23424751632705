import {
  createAsyncThunk,
  createSlice,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import {
  SLICE_STATUS,
  MENTIONS_TYPES,
  DATE,
  PROJECT_PRIORITY,
} from 'utils/constants';
import {
  Task,
  Status,
  SortingType,
  Request,
  MentionType,
  Project,
} from 'utils/customTypes';
import { orderBy, get } from 'lodash';
import MyWorkspaceAPI from './myWorkspaceAPI';
import moment from 'moment';
import config from 'config/Config';

interface MyWorkspace {
  dueTasks: {
    userTasks: Task[];
    status: Status;
    cardSorting: {
      orderBy: string[];
      order: SortingType;
    };
    modalSorting: {
      orderBy: string[];
      order: SortingType;
    };
  };
  requestsInReview: {
    userRequests: Request[];
    status: Status;
    cardSorting: {
      orderBy: string[];
      order: SortingType;
    };
    modalSorting: {
      orderBy: string[];
      order: SortingType;
    };
  };
  recentMentions: {
    userMentions: MentionType[];
    status: Status;
    limit: number;
  };
  dueProjects: {
    userProjects: Project[];
    status: Status;
    cardSorting: {
      orderBy: string[];
      order: SortingType;
    };
    modalSorting: {
      orderBy: string[];
      order: SortingType;
    };
  };
  tablesLimit: number;
}

/* ============================= INITIAL STATE ============================== */
const initialState: MyWorkspace = {
  dueTasks: {
    userTasks: [],
    status: SLICE_STATUS.IDLE,
    cardSorting: {
      order: 'asc',
      orderBy: [],
    },
    modalSorting: {
      order: 'asc',
      orderBy: [],
    },
  },
  requestsInReview: {
    userRequests: [],
    status: SLICE_STATUS.IDLE,
    cardSorting: {
      order: 'asc',
      orderBy: [],
    },
    modalSorting: {
      order: 'asc',
      orderBy: [],
    },
  },
  dueProjects: {
    userProjects: [],
    status: SLICE_STATUS.IDLE,
    cardSorting: {
      order: 'asc',
      orderBy: [],
    },
    modalSorting: {
      order: 'asc',
      orderBy: [],
    },
  },
  recentMentions: {
    userMentions: [],
    status: SLICE_STATUS.IDLE,
    limit: 3,
  },
  tablesLimit: 6,
};

/* ============================== REDUX THUNK =============================== */
export const getDueTasks = createAsyncThunk(
  'myWorkspace/GET_DUE_TASKS',
  async () => {
    const { data } = await MyWorkspaceAPI.fetchDueTasks();
    if (!data.userTasks) {
      throw new Error('An error ocurred');
    }

    return data.userTasks;
  }
);

export const getRequestsInReview = createAsyncThunk(
  'myWorkspace/GET_REQUESTS_IN_REVIEW',
  async () => {
    const { data } = await MyWorkspaceAPI.fetchInReviewRequests();
    if (!data.userRequests) {
      throw new Error('An error ocurred');
    }

    return data.userRequests;
  }
);

export const getRecentMentions = createAsyncThunk(
  'myWorkspace/GET_RECENT_MENTIONS',
  async () => {
    const { data } = await MyWorkspaceAPI.fetchRecentMentions();
    if (!data.userMentions) {
      throw new Error('An error ocurred');
    }

    return data.userMentions;
  }
);

export const getDueProjects = createAsyncThunk(
  'myWorkspace/GET_DUE_PROJECTS',
  async () => {
    const { data } = await MyWorkspaceAPI.fetchDueProjects();
    if (!data.userProjects) {
      throw new Error('An error ocurred');
    }

    return data.userProjects;
  }
);
/* ================================ ACTIONS ================================= */
export const setTablesLimit = createAction<number>(
  'myWorkspace/SET_TABLES_LIMIT'
);

export const setDueTasksOrderForCard = createAction<{
  order: SortingType;
  orderBy: string[];
}>('myWorkspace/SET_DUE_TASKS_ORDER_FOR_CARD');

export const setDueTasksOrderForModal = createAction<{
  order: SortingType;
  orderBy: string[];
}>('myWorkspace/SET_DUE_TASKS_ORDER_FOR_MODAL');

export const setRequestsInReviewOrderForCard = createAction<{
  order: SortingType;
  orderBy: string[];
}>('myWorkspace/SET_IN_REVIEW_REQUESTS_ORDER_FOR_CARD');

export const setRequestsInReviewOrderForModal = createAction<{
  order: SortingType;
  orderBy: string[];
}>('myWorkspace/SET_IN_REVIEW_REQUESTS_ORDER_FOR_MODAL');

export const setMentionsLimit = createAction<number>(
  'myWorkspace/SET_MENTIONS_LIMIT'
);

export const setDueProjectsOrderForCard = createAction<{
  order: SortingType;
  orderBy: string[];
}>('myWorkspace/SET_DUE_PROJECTS_ORDER_FOR_CARD');

export const setDueProjectsOrderForModal = createAction<{
  order: SortingType;
  orderBy: string[];
}>('myWorkspace/SET_DUE_PROJECTS_ORDER_FOR_MODAL');
/* ================================= REDUCER ================================ */
const myWorkspaceSlice = createSlice({
  name: 'myWorkspace',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setTablesLimit, (state, action) => {
        state.tablesLimit = action.payload;
      })
      .addCase(getDueTasks.pending, (state) => {
        state.dueTasks.status = SLICE_STATUS.LOADING;
      })
      .addCase(getDueTasks.rejected, (state) => {
        state.dueTasks.status = SLICE_STATUS.FAILED;
      })
      .addCase(getDueTasks.fulfilled, (state, action) => {
        state.dueTasks.userTasks = action.payload;
        state.dueTasks.status = SLICE_STATUS.IDLE;
      })
      .addCase(setDueTasksOrderForCard, (state, action) => {
        state.dueTasks.cardSorting.order = action.payload.order;
        state.dueTasks.cardSorting.orderBy = action.payload.orderBy;
      })
      .addCase(setDueTasksOrderForModal, (state, action) => {
        state.dueTasks.modalSorting.order = action.payload.order;
        state.dueTasks.modalSorting.orderBy = action.payload.orderBy;
      })
      .addCase(getRequestsInReview.pending, (state) => {
        state.requestsInReview.status = SLICE_STATUS.LOADING;
      })
      .addCase(getRequestsInReview.rejected, (state) => {
        state.requestsInReview.status = SLICE_STATUS.FAILED;
      })
      .addCase(getRequestsInReview.fulfilled, (state, action) => {
        state.requestsInReview.userRequests = action.payload;
        state.requestsInReview.status = SLICE_STATUS.IDLE;
      })
      .addCase(setRequestsInReviewOrderForCard, (state, action) => {
        state.requestsInReview.cardSorting.order = action.payload.order;
        state.requestsInReview.cardSorting.orderBy = action.payload.orderBy;
      })
      .addCase(setRequestsInReviewOrderForModal, (state, action) => {
        state.requestsInReview.modalSorting.order = action.payload.order;
        state.requestsInReview.modalSorting.orderBy = action.payload.orderBy;
      })
      .addCase(setMentionsLimit, (state, action) => {
        state.recentMentions.limit = action.payload;
      })
      .addCase(getRecentMentions.pending, (state) => {
        state.recentMentions.status = SLICE_STATUS.LOADING;
      })
      .addCase(getRecentMentions.rejected, (state) => {
        state.recentMentions.status = SLICE_STATUS.FAILED;
      })
      .addCase(getRecentMentions.fulfilled, (state, action) => {
        state.recentMentions.userMentions = action.payload;
        state.recentMentions.status = SLICE_STATUS.IDLE;
      })
      .addCase(getDueProjects.pending, (state) => {
        state.dueProjects.status = SLICE_STATUS.LOADING;
      })
      .addCase(getDueProjects.rejected, (state) => {
        state.dueProjects.status = SLICE_STATUS.FAILED;
      })
      .addCase(getDueProjects.fulfilled, (state, action) => {
        state.dueProjects.userProjects = action.payload;
        state.dueProjects.status = SLICE_STATUS.IDLE;
      })
      .addCase(setDueProjectsOrderForCard, (state, action) => {
        state.dueProjects.cardSorting.order = action.payload.order;
        state.dueProjects.cardSorting.orderBy = action.payload.orderBy;
      })
      .addCase(setDueProjectsOrderForModal, (state, action) => {
        state.dueProjects.modalSorting.order = action.payload.order;
        state.dueProjects.modalSorting.orderBy = action.payload.orderBy;
      });
  },
});
/* =============================== SELECTORS ================================ */
export const tablesLimit = (state: RootState) => state.myWorkspace.tablesLimit;

export const selectDueTasks = (state: RootState) =>
  state.myWorkspace.dueTasks.userTasks;

export const selectDueTasksStatus = (state: RootState) =>
  state.myWorkspace.dueTasks.status;

export const dueTasksCardSorting = (state: RootState) =>
  state.myWorkspace.dueTasks.cardSorting;

export const dueTasksModalSorting = (state: RootState) =>
  state.myWorkspace.dueTasks.modalSorting;

export const selectOrderedDueTasksForCard = createSelector(
  [selectDueTasks, dueTasksCardSorting, tablesLimit],
  (tasks, sorting, limit) => {
    const tasksBasedOnLimit = tasks.slice(0, limit);
    const orderedTasks = orderBy(tasksBasedOnLimit, sorting.orderBy, [
      sorting.order,
      sorting.order,
    ]);
    return orderedTasks;
  }
);

export const selectOrderedDueTasksForModal = createSelector(
  [selectDueTasks, dueTasksModalSorting],
  (tasks, sorting) => {
    const orderedTasks = orderBy(tasks, sorting.orderBy, [
      sorting.order,
      sorting.order,
    ]);
    return orderedTasks;
  }
);

export const selecRequestsInReview = (state: RootState) =>
  state.myWorkspace.requestsInReview.userRequests.map((request) => ({
    ...request,
    requesterName: `${request.requester.data.firstName} ${request.requester.data.lastName}`,
    daysInReview: moment().diff(moment(request.submittedAt), 'days') + 1,
  }));

export const selecRequestsInReviewStatus = (state: RootState) =>
  state.myWorkspace.requestsInReview.status;

export const inReviewRequestsCardSorting = (state: RootState) =>
  state.myWorkspace.requestsInReview.cardSorting;

export const inReviewRequestsModalSorting = (state: RootState) =>
  state.myWorkspace.requestsInReview.modalSorting;

export const selectRequestsInReviewForCard = createSelector(
  [selecRequestsInReview, inReviewRequestsCardSorting, tablesLimit],
  (requests, sorting, limit) => {
    const requestsBasedOnLimit = requests.slice(0, limit);
    const orderedRequests = orderBy(requestsBasedOnLimit, sorting.orderBy, [
      sorting.order,
      sorting.order,
    ]);
    return orderedRequests;
  }
);

export const selectInReviewRequestsForModal = createSelector(
  [selecRequestsInReview, inReviewRequestsModalSorting],
  (requests, sorting) => {
    const orderedRequests = orderBy(requests, sorting.orderBy, [
      sorting.order,
      sorting.order,
    ]);
    return orderedRequests;
  }
);

export const mentionsLimit = (state: RootState) =>
  state.myWorkspace.recentMentions.limit;

export const selectRecentMentions = (state: RootState): MentionType[] =>
  state.myWorkspace.recentMentions.userMentions!;

export const selectRecentMentionsStatus = (state: RootState) =>
  state.myWorkspace.recentMentions.status;

export const selectFormattedRecentMentions = createSelector(
  [selectRecentMentions],
  (mentions) =>
    mentions.map((mention) => {
      const momentCreationDate = moment(mention.createdAt);
      const formattedMention = {
        id: mention.id,
        type: mention.type,
        content: mention.content,
        authorName: `${get(mention, 'commentCreator.data.firstName')} ${get(
          mention,
          'commentCreator.data.lastName'
        )}`,
        link: mention.link,
        title: mention.title,
        createdAt: `${momentCreationDate.format(
          DATE.SHORT_FORMAT
        )} - ${momentCreationDate.fromNow()}`,
      };
      switch (mention.type) {
        case MENTIONS_TYPES.PROJECT:
          return {
            ...formattedMention,
            title: get(mention, 'project.title'),
          };
        case MENTIONS_TYPES.REQUEST_PROPERTY:
          return {
            ...formattedMention,
            content: get(mention, 'message'),
            title: get(mention, 'parentRequest.title'),
          };
        case MENTIONS_TYPES.RQUEST_QUESTION:
          return {
            ...formattedMention,
            authorName: get(mention, 'author.name'),
            title: mention.title,
          };
        case MENTIONS_TYPES.DESIGN:
          return {
            ...formattedMention,
            link: `${config.get('designURL')}${mention.link}`,
          };
        case MENTIONS_TYPES.TASK:
          return {
            ...formattedMention,
            authorName: get(mention, 'author'),
            mentions: mention.mentions!,
          };
        default:
          return formattedMention;
      }
    }) as MentionType[]
);

export const selectRecentMentionsForCard = createSelector(
  [selectFormattedRecentMentions, mentionsLimit],
  (mentions, limit) => mentions.slice(0, limit) as MentionType[]
);

export const selectDueProjects = (state: RootState) =>
  state.myWorkspace.dueProjects.userProjects;

export const selectDueProjectsStatus = (state: RootState) =>
  state.myWorkspace.dueProjects.status;

export const dueProjectsCardSorting = (state: RootState) =>
  state.myWorkspace.dueProjects.cardSorting;

export const dueProjectsModalSorting = (state: RootState) =>
  state.myWorkspace.dueProjects.modalSorting;

export const selectDueProjectsWithPriorityRank = createSelector(
  [selectDueProjects],
  (projects) =>
    projects.map((project) => ({
      ...project,
      priorityRank:
        project.priority === PROJECT_PRIORITY.HIGH
          ? 0
          : project.priority === PROJECT_PRIORITY.MEDIUM
          ? 1
          : project.priority === PROJECT_PRIORITY.LOW
          ? 2
          : 3,
    }))
);

export const selectDueProjectsForCard = createSelector(
  [selectDueProjectsWithPriorityRank, dueProjectsCardSorting, tablesLimit],
  (projects, sorting, limit) => {
    const projectsBasedOnLimit = projects.slice(0, limit);
    const orderedProjects = sorting.orderBy.length
      ? orderBy(projectsBasedOnLimit, sorting.orderBy, [
          sorting.order,
          sorting.order,
        ])
      : orderBy(
          projectsBasedOnLimit,
          ['targetCompletionDate', 'priorityRank'],
          ['asc', 'asc']
        );
    return orderedProjects;
  }
);

export const selectDueProjectsForModal = createSelector(
  [selectDueProjectsWithPriorityRank, dueProjectsModalSorting],
  (projects, sorting) => {
    const orderedProjects = sorting.orderBy.length
      ? orderBy(projects, sorting.orderBy, [sorting.order, sorting.order])
      : orderBy(
          projects,
          ['targetCompletionDate', 'priorityRank'],
          ['asc', 'asc']
        );
    return orderedProjects;
  }
);
/* ================================= EXPORT ================================= */
export default myWorkspaceSlice.reducer;
