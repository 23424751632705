import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { fetchProjectsList, Project } from 'api/projectsList';
import { fetchFacilitatorsList } from 'api/facilitatorsList';
import {
  EventData,
  ScheduleProjectResource,
  NewScheduleCreationParams,
  GetScheduleParams,
  ScheduleProjectOption,
  Facilitator,
} from 'utils/types/learningSchedule';
import { serializeEvents, serializeCreatedEvent } from './helpers';
import { RootState } from 'state/store';
import ScheduleAPI from './ScheduleAPI';

interface ScheduleState {
  status: Status;
  value: EventsValue;
  projects: Project[];
  facilitators: Facilitator[];
}

type EventsValue = {
  events: EventData[];
};

/* ============================= INITIAL STATE ============================== */

const initialState: ScheduleState = {
  value: {
    events: [],
  },
  status: SLICE_STATUS.IDLE,
  projects: [],
  facilitators: [],
};

/* ============================= ACTIONS ============================== */

/* ============================== REDUX THUNK =============================== */
export const createEvent = createAsyncThunk(
  'SCHEDULE/CREATE_EVENT',
  async (params: NewScheduleCreationParams) => {
    const response = await ScheduleAPI.createSchedule(params);
    return response.data;
  }
);

export const fetchProjects = createAsyncThunk(
  'SCHEDULE/FETCH_PROJECTS',
  async () => {
    const response = await fetchProjectsList();
    return response;
  }
);

export const fetchFacilitators = createAsyncThunk(
  'SCHEDULE/FETCH_FACILITATORS',
  async (params?: {
    search?: string;
    filters?: {
      languages: string[];
      skills: string[];
      countries: string[];
    };
  }) => {
    const response = await fetchFacilitatorsList(
      params?.search,
      params?.filters
    );
    return response;
  }
);

export const fetchEventsForProjects = createAsyncThunk(
  'SCHEDULE/FETCH_EVENTS_FOR_PROJECTS',
  async (params?: GetScheduleParams) => {
    const response = await ScheduleAPI.fetchSchedules(params);
    const serializedEvents = serializeEvents(response);
    return serializedEvents;
  }
);

/* ================================= REDUCER ================================ */
const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createEvent.fulfilled, (state, action) => {
        if (action.payload) {
          const serializedEvent = serializeCreatedEvent(action.payload);
          state.value.events.push(serializedEvent);
          state.status = SLICE_STATUS.IDLE;
        }
      })
      .addCase(createEvent.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(createEvent.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.projects = action.payload.projects;
      })
      .addCase(fetchEventsForProjects.fulfilled, (state, action) => {
        state.value.events = action.payload;
      })
      .addCase(fetchFacilitators.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchFacilitators.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchFacilitators.fulfilled, (state, action) => {
        state.facilitators = action.payload.map((facilitator: Facilitator) => ({
          id: facilitator.id,
          name: facilitator.name,
          jobTitle: facilitator.jobTitle,
          country: facilitator.country,
          province: facilitator.province,
          matchedSkills: facilitator.matchedSkills,
          matchedLanguages: facilitator.matchedLanguages,
        }));
      });
  },
});

/* =============================== SELECTORS ================================ */

export const selectScheduleEvents = (state: RootState) =>
  state.schedule.value.events;

export const selectScheduleResources = (
  state: RootState
): ScheduleProjectResource[] =>
  state.schedule.projects.map((project) => ({
    ProjectId: project.id,
    Title: project.title,
  }));

export const selectProjectsForDropdown = (
  state: RootState
): ScheduleProjectOption[] =>
  state.schedule.projects.map((project) => ({
    id: project.id,
    title: project.title,
    status: project.status,
  }));

export const selectFacilitatorsForDropdown = (
  state: RootState
): Facilitator[] => {
  return state.schedule.facilitators.map((facilitator) => ({
    id: facilitator.id,
    name: facilitator.name,
    jobTitle: facilitator.jobTitle,
    country: facilitator.country,
    province: facilitator.province,
    matchedSkills: facilitator.matchedSkills,
    matchedLanguages: facilitator.matchedLanguages,
  }));
};
export default scheduleSlice.reducer;
