import React from 'react';
import intl from 'react-intl-universal';
import {
  FormItem,
  TextField,
  TextArea,
  Dropdown,
  Datepicker,
  Typography,
  GlobalBanner,
} from '@getsynapse/design-system';
import { ProjectTemplate } from 'utils/types/templates';
import Divider from 'Atoms/Divider';
import AdditionalInformation from './AdditionalInformation/AdditionalInformation';

const Build: React.FC<{
  templateData: ProjectTemplate;
  updateTemplateData: (key: keyof ProjectTemplate, value: any) => void;
}> = ({ templateData, updateTemplateData }) => {
  const isUpdating = templateData.id ? true : false;
  const displayBanner = isUpdating && !templateData.system;

  return (
    <div className='mt-6 mb-12'>
      <Typography variant='h5' className='text-primary'>
        {intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.BUILD_SECTION.SECTION_TITLE')}
      </Typography>
      <Typography variant='body2' className='text-neutral-dark'>
        {intl.get(
          'SETTINGS_PAGE.TEMPLATE_PAGE.BUILD_SECTION.SECTION_DESCRIPTION'
        )}
      </Typography>
      {displayBanner && (
        <GlobalBanner
          variant='warning'
          className='mt-2.5'
          data-cy='build-template__update-warning'
        >
          {() => (
            <Typography variant='body2' className='text-neutral-darker mt-2'>
              {intl.getHTML('SETTINGS_PAGE.TEMPLATE_PAGE.UPDATE_WARNING')}
            </Typography>
          )}
        </GlobalBanner>
      )}
      <div className='grid grid-cols-2 gap-y-6 gap-x-10% mt-6'>
        <FormItem
          label={intl.get('PROJECT_DETAIL.PROJECT_NAME')}
          labelProps={{ required: true }}
        >
          <TextField
            readOnly
            aria-label={intl.get('PROJECT_DETAIL.PROJECT_NAME')}
          />
        </FormItem>
        <FormItem label={intl.get('PROJECT_DETAIL.BUSINESS_UNIT')}>
          <Dropdown
            options={[]}
            onChange={() => {}}
            readOnly
            triggerProps={{
              'aria-label': intl.get('PROJECT_DETAIL.BUSINESS_UNIT'),
            }}
          />
        </FormItem>
        <FormItem label={intl.get('PROJECT_DETAIL.DESCRIPTION')}>
          <TextArea
            readOnly
            textAreaProps={{
              'aria-label': intl.get('PROJECT_DETAIL.DESCRIPTION'),
            }}
          />
        </FormItem>
        <FormItem label={intl.get('PROJECT_DETAIL.CATEGORY')}>
          <Dropdown
            options={[]}
            onChange={() => {}}
            readOnly
            triggerProps={{ 'aria-label': intl.get('PROJECT_DETAIL.CATEGORY') }}
          />
        </FormItem>
        <FormItem>
          <Datepicker
            className='w-full'
            canSelectRange
            startDateLabel={intl.get('PROJECT_DETAIL.START_DATE')}
            startDateLabelProps={{
              required: true,
            }}
            endDateLabel={intl.get('PROJECT_DETAIL.END_DATE')}
            endDateLabelProps={{
              required: true,
              className: 'text-neutral-black',
            }}
            size='large'
            readOnly
            inputProps={{ 'aria-label': intl.get('PROJECT_DETAIL.START_DATE') }}
          />
        </FormItem>
        <FormItem>
          <Datepicker
            className='w-full'
            startDateLabel={intl.get('PROJECT_DETAIL.TARGET_LAUNCH_DATE')}
            size='large'
            readOnly
            inputProps={{
              'aria-label': intl.get('PROJECT_DETAIL.TARGET_LAUNCH_DATE'),
            }}
          />
        </FormItem>
        <FormItem label={intl.get('PROJECT_DETAIL.STATUS')}>
          <Dropdown
            options={[]}
            onChange={() => {}}
            readOnly
            triggerProps={{ 'aria-label': intl.get('PROJECT_DETAIL.STATUS') }}
          />
        </FormItem>
        <FormItem label={intl.get('PROJECT_DETAIL.PRIORITY')}>
          <Dropdown
            options={[]}
            onChange={() => {}}
            readOnly
            triggerProps={{ 'aria-label': intl.get('PROJECT_DETAIL.PRIORITY') }}
          />
        </FormItem>
        <FormItem label={intl.get('PROJECT_DETAIL.HEALTH')}>
          <Dropdown
            options={[]}
            onChange={() => {}}
            readOnly
            triggerProps={{ 'aria-label': intl.get('PROJECT_DETAIL.HEALTH') }}
          />
        </FormItem>
      </div>
      <Divider />
      <AdditionalInformation
        templateData={templateData}
        updateTemplateData={updateTemplateData}
      />
    </div>
  );
};

export default Build;
