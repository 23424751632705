import { useCallback, useEffect, useState } from 'react';
import { EntityFilteredType } from 'utils/types/filters';

type HookType<T> = {
  appliedFilters: EntityFilteredType<T> | {};
  canApplyFilters: boolean;
  handleUpdateFilters: (updatedFilters: EntityFilteredType<T>) => void;
  handleApplyFilters: () => void;
  handleClearAllFilters: () => void;
  handleClosePanel: () => void;
};

function useSidePanelFilters<T>(
  savedFilters: EntityFilteredType<T>,
  onUpdateFilters: (filters: EntityFilteredType<T> | {}) => void,
  onClosePanel: () => void
): HookType<T> {
  const [appliedFilters, setAppliedFilters] = useState<
    EntityFilteredType<T> | {}
  >({});
  const [canApplyFilters, setCanApplyFilters] = useState<boolean>(false);

  const setInitialFilters = useCallback(() => {
    if (savedFilters) {
      setAppliedFilters(savedFilters);
    }
  }, [savedFilters]);

  useEffect(() => {
    setInitialFilters();
  }, [setInitialFilters]);

  const handleUpdateFilters = (updatedFilters: EntityFilteredType<T>) => {
    setAppliedFilters(updatedFilters);
    setCanApplyFilters(true);
  };

  const handleApplyFilters = () => {
    onUpdateFilters(appliedFilters);
    setCanApplyFilters(false);
  };

  const handleClearAllFilters = () => {
    onUpdateFilters({});
    setAppliedFilters({});
    setCanApplyFilters(false);
  };

  const handleClosePanel = useCallback(() => {
    setInitialFilters();
    setCanApplyFilters(false);
    onClosePanel();
  }, [onClosePanel, setInitialFilters]);

  return {
    appliedFilters,
    canApplyFilters,
    handleUpdateFilters,
    handleApplyFilters,
    handleClearAllFilters,
    handleClosePanel,
  };
}

export default useSidePanelFilters;
