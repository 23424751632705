import React from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import { GlobalBanner, Typography } from '@getsynapse/design-system';
import { DATE } from 'utils/constants';

const AccountDeactivationBanner: React.FC<{
  closedDate?: string | null;
}> = ({ closedDate }) => {
  return (
    <GlobalBanner variant='warning' className='mt-4 mx-4' hasCloseIcon>
      {() => (
        <div className='flex justify-between'>
          <Typography variant='body' className='text-warning-darker'>
            {intl.get('SETTINGS_PAGE.USER_PAGE.ERRORS.DISABLE_ACCOUNT')}
          </Typography>
          {closedDate && (
            <Typography variant='body' className='text-warning-darker'>
              {moment(closedDate).utc().format(DATE.SHORT_FORMAT)}
            </Typography>
          )}
        </div>
      )}
    </GlobalBanner>
  );
};

export default AccountDeactivationBanner;
