import config from 'config/Config';
import { TaskFilters } from 'utils/types/filters';
import { RangeFilter } from 'utils/types/filters';
import { TaskListFilters } from 'types/store/tasksList';

export const getAfterQueryParamFromNextLink: (
  nextLink: string | null
) => string = (nextLink) => {
  if (nextLink) {
    const fullUrl = new URL(`${config.get('backendURL')}/${nextLink}`);
    const searchParams = new URLSearchParams(new URL(fullUrl).search);
    if (searchParams.has('after')) {
      return searchParams.get('after')!;
    }
  }
  return '';
};

export const normalizeFiltersSettingsVersions: (
  oldFilters: TaskFilters
) => TaskListFilters = (oldFilters) => {
  const filters: TaskListFilters = {};
  const oldFiltersCopy = { ...oldFilters };
  if (oldFiltersCopy.additionalFlags) {
    filters.disabled = true;
    delete oldFiltersCopy.additionalFlags;
  }

  if (
    oldFiltersCopy.assignedUsers &&
    Array.isArray(oldFiltersCopy.assignedUsers)
  ) {
    filters.user = oldFiltersCopy.assignedUsers;
    delete oldFiltersCopy.assignedUsers;
  }

  if (oldFiltersCopy.start_date) {
    const startDate = { ...oldFiltersCopy.start_date } as RangeFilter;
    delete oldFiltersCopy.start_date;
    if (startDate.from) {
      filters.startDateFrom = new Date(startDate.from).toLocaleDateString();
    }

    if (startDate.to) {
      filters.startDateTo = new Date(startDate.to).toLocaleDateString();
    }
  }

  if (oldFiltersCopy.due_date) {
    const dueDate = { ...oldFiltersCopy.due_date } as RangeFilter;
    delete oldFiltersCopy.due_date;
    if (dueDate.from) {
      filters.dueDateFrom = new Date(dueDate.from).toLocaleDateString();
    }

    if (dueDate.to) {
      filters.dueDateTo = new Date(dueDate.to).toLocaleDateString();
    }
  }

  if (oldFiltersCopy.completion_date) {
    const completionDate = { ...oldFiltersCopy.completion_date } as RangeFilter;
    delete oldFiltersCopy.completion_date;
    if (completionDate.from) {
      filters.completionDateFrom = new Date(
        completionDate.from
      ).toLocaleDateString();
    }

    if (completionDate.to) {
      filters.completionDateTo = new Date(
        completionDate.to
      ).toLocaleDateString();
    }
  }

  if (oldFiltersCopy.estimate_hours) {
    const estimateHours = { ...oldFiltersCopy.estimate_hours } as RangeFilter;
    delete oldFiltersCopy.estimate_hours;
    if (estimateHours.from) {
      filters.estimateHoursFrom = estimateHours.from;
    }

    if (estimateHours.to) {
      filters.estimateHoursTo = estimateHours.to;
    }
  }

  if (oldFiltersCopy.actual_hours) {
    const actualHours = { ...oldFiltersCopy.actual_hours } as RangeFilter;
    delete oldFiltersCopy.actual_hours;
    if (actualHours.from) {
      filters.actualHoursFrom = actualHours.from;
    }

    if (actualHours.to) {
      filters.actualHoursTo = actualHours.to;
    }
  }

  return { ...filters, ...oldFiltersCopy } as TaskListFilters;
};
