import { useState } from 'react';
import intl from 'react-intl-universal';
import { TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { Tab, TabList, Button } from '@getsynapse/design-system';
import { SETTINGS_SECTIONS, PATHS } from 'utils/constants';
import ProjectSettings from './ProjectSettings/ProjectSettings';
import IntakeSettings from './IntakeSettings/IntakeSettings';
import Fields from './Fields/Fields';
import { useHistory } from 'react-router-dom';

const Configurations = () => {
  const history = useHistory();
  const indexParam = new URLSearchParams(window.location.search).get('section');
  const sectionMapping = {
    [SETTINGS_SECTIONS.INTAKE]: 0,
    [SETTINGS_SECTIONS.PROJECTS]: 1,
    [SETTINGS_SECTIONS.FIELDS]: 2,
  };

  const [activeIndex, setActiveIndex] = useState<number>(
    indexParam ? sectionMapping[indexParam] : 0
  );

  return (
    <Tabs
      className='min-h-full flex flex-col'
      index={activeIndex}
      onChange={(index: number) => {
        setActiveIndex(index);
        window.history.replaceState(
          {},
          '',
          `${window.location.pathname}?section=${
            Object.keys(sectionMapping)[index]
          }`
        );
      }}
      data-cy='platform-settings-tabs'
    >
      <div className='flex justify-between'>
        <TabList className='max-w-xs mb-4 flex-1'>
          <Tab index={0} data-cy='platform-request'>
            {intl.get('INTAKE')}
          </Tab>

          <Tab index={1} data-cy='platform-settings__projects-tab'>
            {intl.get('ENTITIES.PROJECT', { num: 2 })}
          </Tab>

          <Tab index={2} data-cy='platform-settings__fields-tab'>
            {intl.get('ENTITIES.FIELDS', { num: 2 })}
          </Tab>
        </TabList>
        {activeIndex === 2 && (
          <Button
            onClick={() => history.push(PATHS.NEW_CUSTOM_FIELD_PAGE)}
            data-cy='add-fields-button'
            className='h-8'
          >
            {intl.get('SETTINGS_PAGE.FIELDS_PAGE.BUTTON_TITLE')}
          </Button>
        )}
      </div>
      <TabPanels className='flex-grow flex flex-col pb-4 max-h-details-without-bar'>
        <TabPanel key={0} className='flex-grow bg-neutral-white rounded pt-6'>
          <IntakeSettings />
        </TabPanel>

        <TabPanel key={1} className='flex-grow bg-neutral-white rounded'>
          <ProjectSettings />
        </TabPanel>

        <TabPanel key={2} className='flex-grow bg-neutral-white rounded'>
          <Fields />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default Configurations;
