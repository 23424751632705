import { NewProject, objKeyAsString } from 'utils/customTypes';

export const defaultNewProjectData: NewProject = {
  title: '',
  category_id: '',
  businessTeams: [],
  description: '',
  startDate: null,
  actualDate: null,
  targetCompletionDate: null,
  targetLaunchDate: null,
  process_id: '',
  status: '',
  stage_id: '',
  privacy: '',
  learningTeams: [],
  priority: null,
  owners: [],
  resourcing_type: null,
  estimatedEffort: '',
  budget_source: null,
  estimated_cost: '',
  allocated_budget: 0,
  health: '',
  projectRequests: [],
  hold_reason: '',
  vendors: [],
  actual_cost: 0,
  actual_time_spent: 0,
  finalComments: '',
  closedDate: null,
  tasksOrdering: [],
  projectNumber: undefined,
  project_template_id: '',
  programs: [],
  is_archived: false,
  isProjectPartOfUserPrograms: false,
  task_bundle_id: '',
  cancel_reason: '',
  cancellation_date: null,
};

export const projectUpdatedAt = {
  updatedAt: '',
};

export const requiredFields = [
  'title',
  'category_id',
  'businessTeams',
  'owners',
  'startDate',
  'targetCompletionDate',
  'privacy',
  'process_id',
];

export const requiredFieldsErrorsMap: objKeyAsString = {
  title: false,
  businessTeams: false,
  owners: false,
  startDate: false,
  targetCompletionDate: false,
  privacy: false,
  process_id: false,
  learningTeams: false,
};
