import { useEffect, useState } from 'react';

export const useElementWidth = (
  ref: React.RefObject<HTMLElement>
): number | undefined => {
  const element: HTMLElement | null = ref.current;
  const [elementWidth, setElementWidth] = useState<number>();

  useEffect(() => {
    if (!element) return;
    const observer = new ResizeObserver(() => {
      setElementWidth(element.offsetWidth);
    });

    observer.observe(element);

    return () => observer.disconnect();
  }, [element]);

  if (elementWidth) return elementWidth;
};
