import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import get from 'lodash/get';
import intl from 'react-intl-universal';
import { Button, Typography } from '@getsynapse/design-system';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';

const AlmostThere = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { email } = useParams<{ email: string }>();

  const resendVerification = async () => {
    try {
      await Auth.resendSignUp(email);
    } catch (error: any) {
      dispatch(
        showNotificationBanner({
          notificationVariant: 'error',
          notificationText: get(error, 'message') || '',
          timeout: 7000,
        })
      );
      history.goBack();
    }
  };

  return (
    <div className='flex flex-col items-center'>
      <Typography variant='h2' className='mb-6'>
        {intl.get('PASSWORD_SETUP_PAGE.SUCCESS_MESSAGE.TITLE')}
      </Typography>
      <Typography className='text-center mb-2'>
        {intl.get('PASSWORD_SETUP_PAGE.SUCCESS_MESSAGE.LINE_ONE')}
      </Typography>
      <div className='flex justify-center items-center'>
        <Typography>
          {intl.get('PASSWORD_SETUP_PAGE.SUCCESS_MESSAGE.LINE_TWO')}
        </Typography>
        <Button variant='tertiary' onClick={resendVerification}>
          {intl.get('PASSWORD_SETUP_PAGE.SUCCESS_MESSAGE.LINK')}
        </Button>
      </div>
    </div>
  );
};

export default AlmostThere;
