import FiltersPanel from 'Pages/InsightsPage/Intake/FiltersPanel/FiltersPanel';
import DeclinedRequests from 'Pages/InsightsPage/Intake/DeclinedRequests/DeclinedRequests';
import ResponseRatio from 'Pages/InsightsPage/Intake/ResponseRatio/ResponseRatio';
import ReceivedRequests from 'Pages/InsightsPage/Intake/ReceivedRequests/ReceivedRequests';
import RequestsByComp from 'Pages/InsightsPage/Intake/RequestsBy/RequestsByComp';
import ApprovedRequests from 'Pages/InsightsPage/Intake/ApprovedRequests/ApprovedRequests';
import RequestsBy from 'Pages/InsightsPage/Intake/InReviewRequests/InReviewRequests';

const Intake = () => {
  return (
    <div className='flex h-insights w-full relative space-x-2'>
      <FiltersPanel />

      <div className='h-full w-full flex flex-col space-y-2 pb-4 overflow-y-auto'>
        <div className='flex w-full h-2/4 min-h-100 space-x-2'>
          <div className='flex w-70-percent h-full flex-col space-y-2'>
            <div className='flex w-full min-h-26 space-x-2'>
              <ReceivedRequests />
              <ResponseRatio />
            </div>

            <div className='flex w-full h-full'>
              <RequestsByComp />
            </div>
          </div>

          <div className='flex w-30-percent h-full'>
            <RequestsBy />
          </div>
        </div>

        <div className='flex w-full h-2/4 min-h-96 space-x-2'>
          <ApprovedRequests />
          <DeclinedRequests />
        </div>
      </div>
    </div>
  );
};

export default Intake;
