import { tailwindOverride, useElevation } from '@getsynapse/design-system';
import { HTMLAttributes } from 'react';

interface PageFooterProps extends HTMLAttributes<HTMLElement> {}

const PageFooter = ({ className, children }: PageFooterProps) => {
  const footerElevation = useElevation(2);

  return (
    <footer
      className={tailwindOverride(
        'bg-neutral-white',
        'absolute bottom-0 left-0 right-0',
        'flex justify-end items-center gap-x-4',
        'h-12 w-full py-2 px-8',
        footerElevation,
        className
      )}
    >
      {children}
    </footer>
  );
};

export default PageFooter;
