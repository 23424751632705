import { Icon, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

const NoData = () => (
  <div
    className='flex items-center justify-center h-full'
    data-testid='no-data'
  >
    <div className='flex'>
      <div className='w-6 mr-2'>
        <Icon
          name='alert-circle'
          className='mr-2 text-warning-darker w-5 h-5 mt-1'
        />
      </div>
      <Typography>
        {intl.get('CAPACITY_INSIGHTS.DEMAND_CAPACITY_AVAILABILITY.NO_DATA')}
      </Typography>
    </div>
  </div>
);

export default NoData;
