import BasicInfoLoader from '../../Components/SkeletonLoaders/BasicInfoLoader';
import PieChartLoader from '../../Components/SkeletonLoaders/PieChartLoader';

const DeclinedRequestsSkeletonLoader = () => {
  return (
    <div className='w-full h-full flex'>
      <div className='w-1/2'>
        <BasicInfoLoader />
      </div>
      <PieChartLoader />
    </div>
  );
};

export default DeclinedRequestsSkeletonLoader;
