import React from 'react';
import moment from 'moment';
import { tailwindOverride, Typography, Icon } from '@getsynapse/design-system';
import { useDispatch } from 'react-redux';
import { ProjectCapacity } from 'utils/types/teamsCapacity';
import { DATE } from 'utils/constants';
import { setProjectId } from 'state/Project/projectSlice';
import ProjectAttributeIconAndLabel from './ProjectAttributeIconAndLabel';
import calendar from 'assets/icons/calendar.svg';

const ProjectData: React.FC<{
  teamId: string;
  memberId: string;
  data: ProjectCapacity['data'];
}> = ({ teamId, memberId, data }) => {
  const dispatch = useDispatch();
  return (
    <div className='flex flex-1 relative'>
      <div className='w-10' />
      <div
        className={tailwindOverride(
          'w-3/5 h-full',
          'flex flex-col',
          'pl-8 py-3.5 pr-2'
        )}
      >
        <Typography
          variant='caption'
          role='button'
          onClick={() => dispatch(setProjectId(data.id))}
          className={tailwindOverride(
            'text-purple-dark cursor-pointer font-semibold',
            'focus:underline hover:underline focus:outline-none',
            'hover:text-purple-darker focus:text-purple-darker',
            'active:text-purple-darker visited:text-purple-darker',
            'truncate'
          )}
          title={data.title}
        >
          {data.title}
        </Typography>
        <div className='flex flex-1 mt-3'>
          <ProjectAttributeIconAndLabel
            attribute='priority'
            value={data.priority}
            className='mr-2'
          />
          <ProjectAttributeIconAndLabel
            attribute='health'
            value={data.health}
            className='mr-2'
          />
          <ProjectAttributeIconAndLabel
            attribute='status'
            value={data.status}
          />
        </div>
        <div className='flex flex-1 mt-3'>
          <Icon src={calendar} aria-label='project-date-range' />
          <Typography variant='caption' className='text-primary ml-2'>
            {`${moment(new Date(data.startDate)).format(
              DATE.TASK_TABLE_FORMAT
            )} - ${moment(new Date(data.targetCompletionDate)).format(
              DATE.TASK_TABLE_FORMAT
            )}`}
          </Typography>
        </div>
      </div>
      <div
        className={tailwindOverride('w-2/5 h-full flex flex-col', 'px-2 py-4')}
      >
        {data.userRoles.slice(0, 3).map((role, index) => (
          <Typography
            key={`team-${teamId}__member-${memberId}__role-${index}`}
            variant='caption'
            className='text-primary'
          >
            {role}
          </Typography>
        ))}
        {data.userRoles.length > 3 && (
          <Typography variant='caption' className='text-primary'>{`+${
            data.userRoles.length - 3
          }`}</Typography>
        )}
      </div>
    </div>
  );
};

export default ProjectData;
