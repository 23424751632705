import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Typography, Button } from '@getsynapse/design-system';
import RequestsDropdown from './RequestsDropdown';
import LinkedRequestsTable from './LinkedRequestsTable';
import RequestsSectionAdditionalFields from './RequestsSectionAdditionalFields';
import intl from 'react-intl-universal';
import { DropdownRequest } from 'utils/customTypes';
import { ProgramInputCategoryData } from 'utils/types/program';

export interface BusinessRequestSectionRef {
  showRequestsDropdownAndHideTable: () => void;
}

type BusinessRequestSectionProps = {
  linkedRequests: DropdownRequest[];
  linkedBusinessUnits: string[];
  completionDate: string;
  updateLinkedBusinessUnits: (businessUnits: string[]) => void;
  updateLinkedRequests: (
    requests: DropdownRequest[],
    isAdding: boolean
  ) => void;
  updateInputField: (
    value: string,
    property: keyof ProgramInputCategoryData
  ) => void;
};

const BusinessRequestSection = React.forwardRef(
  (
    {
      linkedRequests,
      linkedBusinessUnits,
      updateLinkedRequests,
      completionDate,
      updateLinkedBusinessUnits,
      updateInputField,
    }: BusinessRequestSectionProps,
    ref
  ) => {
    const [showRequestsDropdown, setShowRequestsDropdown] =
      useState<boolean>(true);
    const [showLinkedRequestsTable, setShowLinkedRequestsTable] =
      useState<boolean>(false);

    useEffect(() => {
      if (linkedRequests?.length > 0) {
        setShowRequestsDropdown(false);
        setShowLinkedRequestsTable(true);
      }
    }, [linkedRequests]);

    useImperativeHandle(ref, () => ({
      showRequestsDropdownAndHideTable() {
        setShowRequestsDropdown(true);
        setShowLinkedRequestsTable(false);
      },
    }));

    return (
      <>
        <Typography variant='h5' className='mb-1'>
          {intl.get('INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.TITLE')}
        </Typography>
        <Typography variant='body2' className='text-neutral-darker mb-6'>
          {intl.get('INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.SUBTITLE')}
        </Typography>
        {showLinkedRequestsTable && (
          <LinkedRequestsTable
            requests={linkedRequests}
            updateLinkedRequests={updateLinkedRequests}
          />
        )}
        {showRequestsDropdown ? (
          <RequestsDropdown
            onFinish={() => setShowRequestsDropdown(false)}
            updateLinkedRequests={updateLinkedRequests}
            linkedRequests={linkedRequests}
          />
        ) : (
          <Button
            onClick={() => setShowRequestsDropdown(true)}
            iconName='add-circle'
            variant='tertiary'
            data-cy='request-search-projects-to-link'
          >
            {intl.get('INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.LINK_REQUEST')}
          </Button>
        )}
        <RequestsSectionAdditionalFields
          completionDate={completionDate}
          linkedBusinessUnits={linkedBusinessUnits}
          updateLinkedBusinessUnits={updateLinkedBusinessUnits}
          updateInputField={updateInputField}
        />
      </>
    );
  }
);

export default BusinessRequestSection;
