export const SCHEDULE_REQUIRED_FIELDS = [
  'scheduleProjectIds',
  'startDateTime',
  'endDateTime',
  'recurrencePattern',
];

export const RECURRENCE_PATTERN_REQUIRED_FIELDS = [
  'name',
  'interval',
  'dayOfWeek',
  'dayOfMonth',
  'monthOfYear',
  'startDateTime',
  'endDateTime',
  'recurrenceRule',
];

export const ADD_FACILITATORS_FILTERS = {
  SKILLS: 'skills',
  LANGUAGES: 'languages',
  COUNTRIES: 'countries',
} as const;
