import { Typography } from '@getsynapse/design-system';

interface TooltipRowMap {
  [key: string]: string;
}

const TOOLTIP_ROW_MAP: TooltipRowMap = {
  Bar: 'fill',
  Line: 'stroke',
};

const TooltipRow = ({ data, chartType }: { data: any; chartType: string }) => (
  <>
    {data.map((item: any, index: number) => (
      <div key={`${item.name}-${index}`} className='flex flex-row mb-2'>
        <div
          className='border border-transparent mr-4 rounded-sm w-4 h-4'
          style={{ background: item[TOOLTIP_ROW_MAP[chartType]] }}
        />
        <Typography variant='label' className='text-neutral-dark'>
          {`${item.name} : ${item.value}`}
        </Typography>
      </div>
    ))}
  </>
);

const CustomTooltip = ({
  active,
  payload,
  label,
  chartType,
}: {
  active?: any;
  payload?: any;
  label?: string;
  chartType: string;
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        className='flex flex-col bg-neutral-white px-2 py-2 border border-neutral-lighter-two shadow-allocation-table rounded-sm'
        onMouseMove={(e) => e.stopPropagation()}
      >
        <div className='flex flex-row mb-2'>
          <Typography
            variant='label'
            className='text-neutral-dark font-semibold'
          >
            {label}
          </Typography>
        </div>
        <TooltipRow data={payload} chartType={chartType} />
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
