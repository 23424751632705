import { ChangeEvent, useState, useEffect } from 'react';
import { Input } from '@getsynapse/design-system';
import { intakeQuestionWrapper } from 'utils/customTypes';
import get from 'lodash/get';

const UrlQuestion = ({
  question,
  handler,
  disabled,
}: intakeQuestionWrapper) => {
  useEffect(() => {
    setUrl((prev) =>
      prev !== question.data.value ? question.data.value : prev
    );
  }, [question]);

  const [url, setUrl] = useState<string>(question.data.value);

  return (
    <Input
      type='url'
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setUrl(event.target.value);
        handler(question, event.target.value, 'data.value');
      }}
      value={url}
      disabled={disabled}
      placeholder={get(question, 'data.placeholder')}
    />
  );
};

export default UrlQuestion;
