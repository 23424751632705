import { useMemo, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import intl from 'react-intl-universal';
import { useDispatch } from 'react-redux';
import Auth from '@aws-amplify/auth';
import get from 'lodash/get';
import { Button, Typography } from '@getsynapse/design-system';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { PATHS, SLICE_STATUS } from 'utils/constants';
import { Status } from 'utils/customTypes';
import Loader from 'Molecules/Loader/Loader';

const VerifyUser = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [status, setStatus] = useState<Status>('loading');

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const username = query.get('username');
  const code = query.get('code');

  useEffect(() => {
    const verifyUser = async () => {
      try {
        await Auth.confirmSignUp(username!, code!);
        setStatus('idle');
        setTimeout(() => {
          history.push(PATHS.LOGIN);
        }, 5000);
      } catch (error: any) {
        setStatus('failed');
        return;
      }
    };
    if (query && (!username || !code)) {
      history.push(PATHS.LOGIN);
    } else {
      verifyUser();
    }
  }, [query, username, code, history]);

  const resendVerification = async () => {
    try {
      await Auth.resendSignUp(username!);
    } catch (error: any) {
      dispatch(
        showNotificationBanner({
          notificationVariant: 'error',
          notificationText: get(error, 'message') || '',
          timeout: 7000,
        })
      );
      history.goBack();
    }
  };

  return (
    <div className='flex flex-col items-center'>
      {status === SLICE_STATUS.LOADING && <Loader />}
      {status === SLICE_STATUS.FAILED && (
        <>
          <Typography variant='h2' className='mb-6'>
            {intl.get('VERIFY_PAGE.ERROR.TITLE')}
          </Typography>
          <Typography className='text-center mb-4'>
            {intl.get('VERIFY_PAGE.ERROR.BODY')}
          </Typography>
          <Button onClick={resendVerification}>
            {intl.get('VERIFY_PAGE.ERROR.LINK')}
          </Button>
        </>
      )}
      {status === SLICE_STATUS.IDLE && (
        <>
          <Typography variant='h2' className='mb-6 text-center'>
            {intl.get('VERIFY_PAGE.TITLE')}
          </Typography>
          <Typography className='text-center mb-4'>
            {intl.get('VERIFY_PAGE.BODY.LINE_ONE')}
          </Typography>
          <div className='flex justify-center items-center'>
            <Typography>{intl.get('VERIFY_PAGE.BODY.LINE_TWO')}</Typography>
            <Button
              variant='tertiary'
              onClick={() => history.push(PATHS.LOGIN)}
            >
              {intl.get('VERIFY_PAGE.LINK')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default VerifyUser;
