import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { generateLineElement } from './Elements';
import { INSIGHTS_PAGE_COLORS } from 'utils/constants';

const RequestsBySkeletonLoader = () => {
  const mockUnits = [
    'Waitlisted',
    'Approved',
    'Declined',
    'Cancelled',
    'In Review',
  ];
  const axiesStyle = {
    fontSize: '0.75em',
    fontWeight: '400',
    lineHeight: '1em',
  };
  const skeletonColor = INSIGHTS_PAGE_COLORS.SKELETON_LOADER_COLOR;

  const mockDataForSkeleton = [
    {
      month: 'Mar',
      Approved: 20,
      Declined: 28,
      Waitlisted: 15,
      Cancelled: 25,
      'In Review': 9,
      totalRequests: 30,
    },
    {
      month: 'Apr',
      Approved: 18,
      Declined: 10,
      Waitlisted: 10,
      Cancelled: 20,
      'In Review': 5,
      totalRequests: 40,
    },
    {
      month: 'May',
      Approved: 24,
      Declined: 15,
      Waitlisted: 22,
      Cancelled: 12,
      'In Review': 13,
      totalRequests: 30,
    },
    {
      month: 'June',
      Approved: 25,
      Declined: 10,
      Waitlisted: 14,
      Cancelled: 18,
      'In Review': 9,
      totalRequests: 25,
    },
    {
      month: 'July',
      Approved: 7,
      Declined: 9,
      Waitlisted: 18,
      Cancelled: 11,
      'In Review': 12,
      totalRequests: 25,
    },
  ];
  return (
    <div className='animate-pulse w-full h-full'>
      <ResponsiveContainer width='90%' height='90%'>
        <LineChart data={mockDataForSkeleton}>
          <CartesianGrid horizontal={false} vertical={false} />
          <XAxis
            tickLine={false}
            dataKey='month'
            style={axiesStyle}
            stroke={skeletonColor}
            tick={false}
            padding={{ left: 30, right: 30 }}
          />
          <YAxis
            tickLine={false}
            tick={false}
            stroke={skeletonColor}
            dataKey='totalRequests'
            style={axiesStyle}
          />
          {mockUnits.map((unit, index) =>
            generateLineElement({
              dataKey: unit,
              strokeWidth: 2,
              strokeFill: skeletonColor,
              isAnimationActive: false,
              index,
            })
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RequestsBySkeletonLoader;
