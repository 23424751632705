import { UpdatedQuestion, EvaluationPlanItem } from 'utils/types/program';
import isEqual from 'lodash/isEqual';

export const formatCollectionsToBeUpdated = (
  data: EvaluationPlanItem[],
  collectionMethodToBeUpdated: string[],
  evaluationPlanData: EvaluationPlanItem[]
) => {
  return data
    .filter((method) =>
      collectionMethodToBeUpdated.includes(method.data_collection_method_id)
    )
    .map((method) => {
      const updatedQuestions = [] as UpdatedQuestion[];
      method.collection_method_selected_options.forEach((answers) => {
        const answerInBaseData = evaluationPlanData
          .find(
            (methodInBase) =>
              method.data_collection_method_id ===
              methodInBase.data_collection_method_id
          )
          ?.collection_method_selected_options.find(
            (answersInBase) => answersInBase.question_id === answers.question_id
          );
        const hasAnswersChanged = Boolean(
          !answerInBaseData ||
            (answerInBaseData && !isEqual(answerInBaseData, answers))
        );

        if (hasAnswersChanged) {
          updatedQuestions.push({
            question_id: answers.question_id,
            questions_answers: answers.questions_answers.map((answer) => {
              return {
                answer_id: answer.answer_id || null,
                number_of_respondents: answer.number_of_respondents,
                answer_preset_id: answer.answer_preset_id,
                data_source_other_text: answer.data_source_other_text,
                number_of_observers: answer.number_of_observers,
                number_observed: answer.number_observed,
                desired_behaviours: answer.desired_behaviours
                  ? [...answer.desired_behaviours]
                  : [],
              };
            }),
          });
        }
      });

      return {
        data_collection_method_answer_id: method.id!,
        data: updatedQuestions,
      };
    });
};
