import React, { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Typography, tailwindOverride } from '@getsynapse/design-system';
import { Week } from '../helpers';
import { RoleAllocation } from './AllocatedWeeksTable';

const EmptySection: React.FC<{
  id?: string;
  label: string;
  currentWeeksSlideArray: Week[];
  numberOfWeeks: number;
  displayRemoveButton?: boolean;
}> = ({
  id,
  label,
  numberOfWeeks,
  currentWeeksSlideArray,
  displayRemoveButton = false,
}) => {
  const { tasksAssignmentsBasedResourcesCapacity = false } = useFlags();

  const allocationsGrid = useMemo(() => {
    return currentWeeksSlideArray.map((week: Week, index: number) => (
      <RoleAllocation
        id={index}
        key={index}
        displayContent={false}
        isCurrentWeek={week.isCurrentWeek || false}
        className={tailwindOverride('flex flex-1', {
          'shadow-table-column': !tasksAssignmentsBasedResourcesCapacity,
        })}
        currentWeekClassName={
          tasksAssignmentsBasedResourcesCapacity
            ? 'bg-secondary-lightest border-l border-r border-secondary-lighter'
            : 'bg-neutral-lighter-two'
        }
      />
    ));
  }, [currentWeeksSlideArray, tasksAssignmentsBasedResourcesCapacity]);

  return (
    <div
      className='h-10 flex flex-1'
      data-cy={`empty-${id ? id.toLowerCase() : label.toLowerCase()}-section`}
    >
      <div
        className={tailwindOverride('w-2/5 flex', {
          'shadow-allocation-table-inner':
            !tasksAssignmentsBasedResourcesCapacity,
        })}
      >
        <div className='w-12' />
        <div
          className={tailwindOverride('h-full flex flex-1 items-center', {
            'shadow-table-column': !tasksAssignmentsBasedResourcesCapacity,
          })}
        >
          <Typography className='text-neutral-darker ml-2' variant='caption'>
            {label}
          </Typography>
        </div>
      </div>
      <div className='w-3/5 flex h-full'>
        <div className='w-6' />
        <div className='flex flex-1 flex-col h-full'>
          <div className={`grid grid-cols-${numberOfWeeks} h-full`}>
            {currentWeeksSlideArray.length > 0 && allocationsGrid}
          </div>
        </div>
        <div className='w-6' />
        {displayRemoveButton && (
          <div className='w-12 shadow-capacity-table-remove-participant' />
        )}
      </div>
    </div>
  );
};

export default EmptySection;
