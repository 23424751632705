import React from 'react';
import intl from 'react-intl-universal';
import {
  tailwindOverride,
  Button,
  useElevation,
} from '@getsynapse/design-system';

const PageFooter: React.FC<{
  canSave?: boolean;
  onCancel: () => void;
  onSave: () => void;
  isUpdating?: boolean;
  isLoading?: boolean;
}> = ({ onCancel, canSave, onSave, isUpdating = false, isLoading = false }) => {
  const footerElevation = useElevation(1);
  return (
    <div
      className={tailwindOverride(
        'w-full flex justify-end py-2 px-12',
        footerElevation
      )}
    >
      <Button
        variant='tertiary'
        className='mr-4'
        onClick={onCancel}
        data-cy='template-page__cancel-button'
      >
        {intl.get('CANCEL')}
      </Button>
      <Button
        disabled={!canSave || isLoading}
        className={tailwindOverride({ 'cursor-not-allowed': !canSave })}
        onClick={onSave}
        loading={isLoading}
        data-cy='template-page__save-button'
      >
        {isUpdating
          ? intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.UPDATE_TEMPLATE')
          : intl.get('SAVE')}
      </Button>
    </div>
  );
};

export default PageFooter;
