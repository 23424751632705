import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import {
  DailyTeamCapacity,
  WeeksAndDaysArray,
  DailyCapacityPerWeek,
} from 'utils/types/dailyTeamsCapacity';
import { RootState } from 'state/store';
import dailyTeamsCapacityAPI from './teamsCapacityAPI';
import { selectWeeksAndDays } from '../Days/daysSlice';
import {
  updateDailyCapacityPerWeek,
  removeTeamsFromDailyCapacityState,
  removeDayFromCapacityPerWeek,
  generateFetchPrevOrNextDayCapacityURL,
  generateFetchDailyCapacityURLFromDateRange,
  generateDailyCapacityPropsMap,
} from '../helpers';

interface TeamsCapacityState {
  byId: DailyTeamCapacity;
  visibleTeams: string[];
  status: Status;
}

export interface FetchMoreDailyTeamsCapacityParams {
  startDate: string;
  endDate: string;
  link: 'prevDay' | 'nextDay';
}

/* ============================= INITIAL STATE ============================== */
const initialState: TeamsCapacityState = {
  byId: {},
  visibleTeams: [],
  status: SLICE_STATUS.IDLE,
};

/* ============================== ACTIONS =============================== */
const setVisibleTeams = createAction<string[]>(
  'dailyTeamsCapacity/SET_VISIBLE_TEAMS'
);
export const setTeamCapacityStatus = createAction<Status>(
  'dailyTeamsCapacity/SET_TEAM_CAPACITY_STATUS'
);
const setDailyTeamCapacities = createAction<{
  teamId: string;
  dailyCapacities: DailyCapacityPerWeek;
}>('dailyTeamsCapacity/SET_TEAM_DAILY_CAPACITIES');
const removeDayFromDailyTeamCapacities = createAction<{
  teamId: string;
  day: string;
}>('dailyTeamsCapacity/REMOVE_DAY_FROM_TEAM_DAILY_CAPACITIES');
export const resetDailyCapacityState = createAction(
  'dailyTeamsCapacity/RESET_STATE'
);

/* ============================== REDUX THUNK =============================== */
export const fetchDailyTeamsCapacity = createAsyncThunk(
  'dailyTeamsCapacity/FETCH_DAILY_TEAMS_CAPACITY',
  async (
    params: { teamIds: string[] },
    { rejectWithValue, getState, dispatch }
  ) => {
    const state = getState() as RootState;
    const { startDate, endDate } = state.dailyTeamsCapacity.days;
    const currentTeamIds = state.dailyTeamsCapacity.teamsCapacity.visibleTeams;
    try {
      const teamsToFetch = params.teamIds.filter(
        (teamId) => !currentTeamIds.includes(teamId)
      );
      dispatch(setVisibleTeams(params.teamIds));
      for (const team of teamsToFetch) {
        const url = generateFetchDailyCapacityURLFromDateRange(
          startDate,
          endDate,
          `/v2/teams/${team}/capacities/daily`
        );
        dailyTeamsCapacityAPI.fetchDailyTeamsCapacity(url).then((res) => {
          const dailyTeamCapacitiesPerWeek = res.dailyCapacities.weeks;
          dispatch(
            setDailyTeamCapacities({
              teamId: team,
              dailyCapacities: dailyTeamCapacitiesPerWeek,
            })
          );
          return res.links;
        });
      }
      const teamIdsToRemove = currentTeamIds.filter(
        (teamId) => !params.teamIds.includes(teamId)
      );
      return teamIdsToRemove;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const fetchMoreDailyTeamsCapacity = createAsyncThunk(
  'dailyTeamsCapacity/FETCH_MORE_DAILY_TEAMS_CAPACITY',
  async (
    params: FetchMoreDailyTeamsCapacityParams,
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const state = getState() as RootState;
      const { link, startDate, endDate } = params;
      const visibleTeams = state.dailyTeamsCapacity.teamsCapacity.visibleTeams;
      for (const teamId of visibleTeams) {
        let url = `/v2/teams/${teamId}/capacities/daily?startDate=:startDate`;
        if (link === 'nextDay') {
          url = generateFetchPrevOrNextDayCapacityURL(url, endDate, link);
        } else {
          url = generateFetchPrevOrNextDayCapacityURL(url, startDate, link);
        }
        dailyTeamsCapacityAPI
          .fetchDailyTeamsCapacity(url)
          .then((res) => {
            const dailyTeamCapacitiesPerWeek = res.dailyCapacities.weeks;
            dispatch(
              setDailyTeamCapacities({
                teamId,
                dailyCapacities: dailyTeamCapacitiesPerWeek,
              })
            );
            return res;
          })
          .then(() => {
            let day = '';
            if (link === 'prevDay') {
              day = endDate;
            } else {
              day = startDate;
            }
            dispatch(
              removeDayFromDailyTeamCapacities({
                teamId,
                day,
              })
            );
          });
      }
      return link;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

/* ================================= REDUCER ================================ */
const dailyTeamsCapacitySlice = createSlice({
  name: 'dailyTeamsCapacity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDailyTeamsCapacity.pending, (state) => {
      state.status = SLICE_STATUS.LOADING;
    });
    builder.addCase(fetchDailyTeamsCapacity.fulfilled, (state, action) => {
      state.status = SLICE_STATUS.IDLE;
      const teamIdsToRemove = action.payload;
      if (teamIdsToRemove && teamIdsToRemove.length > 0) {
        state.byId = removeTeamsFromDailyCapacityState(
          state.byId,
          teamIdsToRemove
        );
      }
    });
    builder.addCase(fetchDailyTeamsCapacity.rejected, (state) => {
      state.status = SLICE_STATUS.IDLE;
    });
    builder.addCase(setVisibleTeams, (state, action) => {
      state.visibleTeams = action.payload;
    });
    builder.addCase(setTeamCapacityStatus, (state, action) => {
      state.status = action.payload;
    });
    builder.addCase(setDailyTeamCapacities, (state, action) => {
      const { teamId, dailyCapacities } = action.payload;
      let currentTeamDailyCapacities = {};
      if (state.byId[teamId] && state.byId[teamId].dailyCapacities) {
        currentTeamDailyCapacities = { ...state.byId[teamId].dailyCapacities };
      }
      currentTeamDailyCapacities = updateDailyCapacityPerWeek(
        currentTeamDailyCapacities,
        dailyCapacities
      );
      state.byId = {
        ...state.byId,
        [teamId]: {
          ...state.byId[teamId],
          dailyCapacities: {
            ...currentTeamDailyCapacities,
          },
        },
      };
    });
    builder.addCase(removeDayFromDailyTeamCapacities, (state, action) => {
      const { teamId, day } = action.payload;
      const dailyCapacities = { ...state.byId[teamId].dailyCapacities };
      state.byId[teamId].dailyCapacities = removeDayFromCapacityPerWeek(
        dailyCapacities,
        day
      );
    });
    builder.addCase(resetDailyCapacityState, (state) => {
      state.byId = initialState.byId;
      state.visibleTeams = initialState.visibleTeams;
    });
  },
});

/* =============================== SELECTORS ================================ */
export const selectTeamsCapacityStatus = (state: RootState) =>
  state.dailyTeamsCapacity.teamsCapacity.status;
export const selectCapacityByTeamId = (state: RootState, teamId: string) =>
  state.dailyTeamsCapacity.teamsCapacity.byId[teamId]?.dailyCapacities;

export const selectDailyCapacityByTeamId = createSelector(
  [
    (state: RootState, teamId: string) => selectCapacityByTeamId(state, teamId),
    selectWeeksAndDays,
  ],
  (
    teamCapacity: DailyCapacityPerWeek,
    weeksAndDaysArray: WeeksAndDaysArray
  ) => {
    if (!teamCapacity || !weeksAndDaysArray) {
      return {};
    }
    return generateDailyCapacityPropsMap(teamCapacity, weeksAndDaysArray);
  }
);

export const selectVisibleTeams = (state: RootState) =>
  state.dailyTeamsCapacity.teamsCapacity.visibleTeams;

export default dailyTeamsCapacitySlice.reducer;
