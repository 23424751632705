import { FieldTemplateType } from 'utils/types/fields';
import {
  ALLOWED_FIELD_TYPES,
  ALLOWED_FIELD_VALUE_SELECTORS,
} from 'utils/constants';
import { isEqual } from 'lodash';

const fixedProperties = [
  'deletedAt',
  'createdAt',
  'updatedAt',
  'id',
  'organization_id',
  'owner',
  'owner_id',
  'project_Fields',
  'system',
  'type',
  'value_selector',
  'disabled',
];

export const checkIfFieldIsValid = (field: FieldTemplateType) => {
  if (!field.type || !field.name || !field.value_selector) {
    return false;
  }
  if (
    field.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.FREEFORM &&
    field.type === ALLOWED_FIELD_TYPES.BOOLEAN
  ) {
    return !field.allowed_values?.some((option) => !option.display?.toString());
  } else if (
    field.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.DROPDOWN ||
    field.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.RADIO ||
    field.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.CHECKBOX
  ) {
    if (field.allowed_values?.length === 0) {
      return false;
    }
    const hasEmptyValues = field.allowed_values?.some(
      (option) => !option.val?.toString()
    );
    if (hasEmptyValues) {
      return false;
    }
    const uniqueValues = field.allowed_values?.filter(
      (option, index) =>
        field.allowed_values?.findIndex((item) => item.val === option.val) ===
        index
    );
    if (uniqueValues?.length !== field.allowed_values?.length) {
      return false;
    }
  }
  return true;
};

export const removeUnchangedData = (
  field: FieldTemplateType,
  updatedData: FieldTemplateType
) => {
  const oldKeys = Object.keys(field);
  const cleanData = { ...updatedData };
  for (const key of oldKeys) {
    if (
      key in fixedProperties ||
      isEqual(
        field[key as keyof typeof field],
        cleanData[key as keyof typeof field]
      )
    ) {
      delete cleanData[key as keyof typeof field];
    }
  }
  return cleanData;
};

export const removeUnnecessaryData = (field: FieldTemplateType) => {
  const cleanData = { ...field };
  if (
    field.value_selector !== ALLOWED_FIELD_VALUE_SELECTORS.DROPDOWN &&
    field.value_selector !== ALLOWED_FIELD_VALUE_SELECTORS.CHECKBOX
  ) {
    delete cleanData.collection;
  }
  if (
    !(
      field.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.FREEFORM &&
      field.type === ALLOWED_FIELD_TYPES.BOOLEAN
    )
  ) {
    delete cleanData.default_value;
  }
  if (
    field.value_selector !== ALLOWED_FIELD_VALUE_SELECTORS.DROPDOWN &&
    field.value_selector !== ALLOWED_FIELD_VALUE_SELECTORS.RADIO &&
    field.value_selector !== ALLOWED_FIELD_VALUE_SELECTORS.CHECKBOX &&
    !(
      field.value_selector === ALLOWED_FIELD_VALUE_SELECTORS.FREEFORM &&
      field.type === ALLOWED_FIELD_TYPES.BOOLEAN
    )
  ) {
    delete cleanData.allowed_values;
  }
  return cleanData;
};
