import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import { useSelector } from 'react-redux';
import {
  selectAllocationByResourceType,
  selectAllocationByResourceTypeStatus,
} from 'state/Insights/capacitySlice';
import { SLICE_STATUS } from 'utils/constants';
import ChartSkeletonLoader from './ChartSkeletonLoader';
import NoData from 'Pages/InsightsPage/Capacity/components/NoData';
import LoadingError from 'Pages/InsightsPage/Capacity/components/LoadingError';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Legend,
  Cell,
  Sector,
  Tooltip,
  TooltipProps,
} from 'recharts';
import {
  TEXT_STYLE,
  SECTOR_COLOR,
  LABEL_STROKE_COLOR,
  LABEL_TEXT_COLOR,
  PRECENTAGE_LABEL_STYLE,
} from './constants';
import { useMemo, useRef, useState, useEffect } from 'react';
import styles from './AllocationByResourceTypeChart.module.css';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';

const AllocationByResourceTypeChart = () => {
  const resourceTypeAllocation = useSelector(selectAllocationByResourceType);
  const resourceTypeAllocationStatus = useSelector(
    selectAllocationByResourceTypeStatus
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const chartRadius: { innerRadius: string; outerRadius: string } =
    useMemo(() => {
      if (containerWidth >= 474) {
        return {
          innerRadius: '30%',
          outerRadius: '',
        };
      } else {
        let inner = 30;
        let outer = 70;
        for (let i = containerWidth; i < 474; i = i + 2) {
          inner = inner - 0.2;
          outer = outer - 0.01;
        }
        return {
          innerRadius: inner.toString() + '%',
          outerRadius: outer.toString() + '%',
        };
      }
    }, [containerWidth]);

  useEffect(() => {
    if (!containerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setContainerWidth(containerRef.current?.offsetWidth!);
    });
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const renderColorfulLegendText = (value: string) => (
    <span style={TEXT_STYLE}>{value}</span>
  );

  const renderActiveShape = (props: any) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
    } = props;

    return (
      <g className={styles.noOutline}>
        <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };

  const renderLabel = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, outerRadius, percent } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g className={styles.noOutline}>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          dx={cos >= 0 ? -50 : 40}
          y={ey}
          dy={4}
          textAnchor={textAnchor}
          fill={LABEL_TEXT_COLOR}
          style={PRECENTAGE_LABEL_STYLE}
        >
          {`${(percent * 100).toFixed(1)}%`}
        </text>
      </g>
    );
  };

  const renderTooltip = (props: TooltipProps<ValueType, NameType>) => {
    const { active, payload } = props;

    return (
      active &&
      payload?.length && (
        <div className='bg-neutral-white rounded border border-purple-lighter p-2 shadow-raised'>
          <Typography className='text-purple-darker' variant='body2'>
            {`${intl.get(
              `CAPACITY_INSIGHTS.ALLOCATION_BY_RESOURCE_TYPE.${payload[0].payload.payload.resourceType.toUpperCase()}`
            )} ${intl.get(
              'CAPACITY_INSIGHTS.ALLOCATION_BY_RESOURCE_TYPE.ALLOCATION'
            )} : ${payload[0].value}h`}
          </Typography>
        </div>
      )
    );
  };

  return (
    <div
      className='flex flex-col w-full p-6'
      ref={containerRef}
      data-testid='allocation-by-resource-type_chart'
    >
      {resourceTypeAllocationStatus === SLICE_STATUS.LOADING ? (
        <ChartSkeletonLoader />
      ) : (
        <>
          <div>
            <p className='text-3.5 leading-2 text-neutral-black font-semibold'>
              {intl.get('CAPACITY_INSIGHTS.ALLOCATION_BY_RESOURCE_TYPE.TITLE')}
            </p>
            <Typography variant='caption' className='text-neutral-dark'>
              {intl.get(
                'CAPACITY_INSIGHTS.ALLOCATION_BY_RESOURCE_TYPE.CAPTION'
              )}
            </Typography>
          </div>
          {resourceTypeAllocation?.length ? (
            <ResponsiveContainer
              width='100%'
              height='100%'
              id='recource-type-allocation_pie_chart'
            >
              <PieChart>
                <Pie
                  dataKey='allocation'
                  startAngle={180}
                  endAngle={0}
                  cy='65%'
                  data={resourceTypeAllocation!}
                  innerRadius={chartRadius.innerRadius}
                  outerRadius={chartRadius.outerRadius}
                  paddingAngle={0.5}
                  label={renderLabel}
                  labelLine={{ stroke: LABEL_STROKE_COLOR }}
                  activeIndex={activeIndex}
                  activeShape={(projectDetails) =>
                    renderActiveShape(projectDetails)
                  }
                  onMouseEnter={(_, index) => setActiveIndex(index)}
                  onMouseLeave={() => setActiveIndex(-1)}
                >
                  {resourceTypeAllocation?.map((entry, index) => (
                    <Cell
                      key={`allocation-by-resource-cell-${index}`}
                      fill={SECTOR_COLOR[entry.resourceType]}
                      className={styles.noOutline}
                    />
                  ))}
                </Pie>
                <Tooltip content={renderTooltip} />
                <Legend
                  iconType='circle'
                  payload={[
                    {
                      value: intl.get(
                        'CAPACITY_INSIGHTS.ALLOCATION_BY_RESOURCE_TYPE.BUSINESS'
                      ),
                      color: SECTOR_COLOR.business,
                    },
                    {
                      value: intl.get(
                        'CAPACITY_INSIGHTS.ALLOCATION_BY_RESOURCE_TYPE.EXTERNAL'
                      ),
                      color: SECTOR_COLOR.external,
                    },
                    {
                      value: intl.get(
                        'CAPACITY_INSIGHTS.ALLOCATION_BY_RESOURCE_TYPE.LD'
                      ),
                      color: SECTOR_COLOR.ld,
                    },
                  ]}
                  iconSize={8}
                  formatter={renderColorfulLegendText}
                />
              </PieChart>
            </ResponsiveContainer>
          ) : resourceTypeAllocation ? (
            <NoData />
          ) : (
            <>
              <LoadingError />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AllocationByResourceTypeChart;
