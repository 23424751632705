import intl from 'react-intl-universal';
import { Button, Typography } from '@getsynapse/design-system';

const UpgradePage = () => {
  return (
    <div className='flex flex-col items-center'>
      <Typography variant='h2' className='pb-4'>
        {intl.get('UPGRADE_PAGE.TITLE')}
      </Typography>

      <Typography variant='body' className='w-97 pb-8 text-center'>
        {intl.get('UPGRADE_PAGE.MESSAGE')}
      </Typography>

      <a
        href='https://cognota.com/upgrade-free-trial'
        target='_blank'
        rel='noreferrer'
      >
        <Button>{intl.get('UPGRADE_PAGE.CTA')}</Button>
      </a>
    </div>
  );
};

export default UpgradePage;
