import { Typography, Icon } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

const NoObjetivesEvaluationState = () => (
  <div
    className='flex items-center mt-6'
    data-testid='evaluation-plan__empty-objectives-message'
  >
    <Icon name='alert-circle' className='mr-2 text-purple-dark w-5 h-5' />
    <Typography variant='h6' className='leading-6 font-normal'>
      {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.EVALUATION.WARNING')}
    </Typography>
  </div>
);

export default NoObjetivesEvaluationState;
