import React, { useState } from 'react';
import intl from 'react-intl-universal';
import {
  Toggle,
  Button,
  Icon,
  Typography,
  Tooltip,
  tailwindOverride,
} from '@getsynapse/design-system';
import { PROJECT_TEMPLATE_STATUS_VALUES } from 'utils/constants';
import { ProjectTemplateStatus } from 'utils/types/templates';
import DeleteTemplateModal from '../../DeleteTemplate/DeleteTemplateModal';

const TriggerButton: React.FC<{
  handleClick: () => void;
  disabled?: boolean;
}> = ({ handleClick = () => {}, disabled = false }) => (
  <Button
    onClick={handleClick}
    className={tailwindOverride('ml-2.5 py-0.5', {
      'cursor-not-allowed': disabled,
    })}
    size='small'
    variant='tertiary'
    disabled={disabled}
    data-cy='delete-template__button'
  >
    <Icon
      name='trash'
      className={tailwindOverride('text-error-dark mr-2 text-xl', {
        'text-neutral-light cursor-not-allowed': disabled,
      })}
    />
    <Typography
      variant='body2'
      className={tailwindOverride('text-primary', {
        'text-neutral-light cursor-not-allowed': disabled,
      })}
    >
      {intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.DELETE_TEMPLATE')}
    </Typography>
  </Button>
);

const DeleteTemplateButton: React.FC<{
  onClickCallback: () => void;
  isTemplatePublished?: boolean;
}> = ({ onClickCallback, isTemplatePublished = false }) => {
  return isTemplatePublished ? (
    <Tooltip
      timeout={0}
      openMode='hover2'
      showPopper
      position='bottomLeft'
      hasMaxWidth={false}
      trigger={
        <div>
          <TriggerButton
            handleClick={onClickCallback}
            disabled={isTemplatePublished}
          />
        </div>
      }
    >
      {intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.DELETE_TEMPLATE_TOOLTIP')}
    </Tooltip>
  ) : (
    <TriggerButton handleClick={onClickCallback} />
  );
};

const SkeletonLoader = () => (
  <div className='flex'>
    <div className='bg-neutral-lighter rounded-sm animate-pulse w-28 h-4' />
    <div className='bg-neutral-lighter rounded-sm animate-pulse w-28 h-4 ml-8' />
  </div>
);

const TopBar: React.FC<{
  canUpdate?: boolean;
  canRemove?: boolean;
  status?: ProjectTemplateStatus;
  onUpdateStatus: (status: ProjectTemplateStatus) => void;
  onRemove?: () => void;
  isLoading?: boolean;
}> = ({
  canUpdate = true,
  canRemove = false,
  status = PROJECT_TEMPLATE_STATUS_VALUES.DRAFT,
  onUpdateStatus,
  onRemove,
  isLoading = false,
}) => {
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    let newStatus = status;
    if (isChecked) {
      newStatus = PROJECT_TEMPLATE_STATUS_VALUES.PUBLISHED;
    } else {
      newStatus = PROJECT_TEMPLATE_STATUS_VALUES.DRAFT;
    }
    onUpdateStatus(newStatus);
  };

  return (
    <div className='flex items-center h-full justify-end'>
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          <DeleteTemplateModal
            shouldOpenModal={displayModal}
            onConfirm={() => (onRemove ? onRemove() : () => {})}
            onCancel={() => setDisplayModal(false)}
          />
          <Toggle
            label={intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.PUBLISH')}
            isSmall
            className={'flex items-center'}
            labelProps={{
              className: tailwindOverride('mb-0 mr-2', {
                'cursor-not-allowed': !canUpdate,
              }),
            }}
            onChange={handleChangeStatus}
            checked={status === PROJECT_TEMPLATE_STATUS_VALUES.PUBLISHED}
            disabled={!canUpdate}
            inputProps={{
              'data-cy': 'template-status-toggle-field',
              className: tailwindOverride({ 'cursor-not-allowed': !canUpdate }),
            }}
          />
          {canRemove && (
            <DeleteTemplateButton
              onClickCallback={() => setDisplayModal(true)}
              isTemplatePublished={
                status === PROJECT_TEMPLATE_STATUS_VALUES.PUBLISHED
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default TopBar;
