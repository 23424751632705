import intl from 'react-intl-universal';
import { Option, rangeDateType } from 'utils/customTypes';
import { isArrayOfOptions, isDateRangeObject } from 'utils/typeGuards';
import { ProgramProjectFilters, RangeFilter } from 'utils/types/filters';
import useProgramProjectFilters from '../hooks/useProgramProjectFilters';
import { Datepicker, FormItem, NumericInput } from '@getsynapse/design-system';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';
import {
  getInitialDatePickerValue,
  getInitialValueForDropDown,
} from 'utils/functions';
import { PROGRAM_PROJECT_TABLE_FILTERS } from 'utils/constants/programProject';

interface FiltersFormProps {
  appliedFilters: ProgramProjectFilters;
  onUpdateFilters: (newFilters: ProgramProjectFilters) => void;
}

type UpdateFiltersCallback = (
  key: string,
  value: Option[] | rangeDateType | string,
  rangeFilterKey?: 'from' | 'to'
) => void;

const FiltersForm: React.FC<FiltersFormProps> = ({
  appliedFilters,
  onUpdateFilters,
}) => {
  const { statusOptions, priorityOptions } =
    useProgramProjectFilters(appliedFilters);

  const updateFilters: UpdateFiltersCallback = (key, value, rangeFilterKey) => {
    const updatedFilters: ProgramProjectFilters = { ...appliedFilters };
    if (isDateRangeObject(value) && rangeFilterKey) {
      updatedFilters[key] = {
        ...(updatedFilters[key] as RangeFilter),
        [rangeFilterKey]: value.startDate ? value.startDate.toString() : null,
      };
      if (
        (updatedFilters[key] as RangeFilter).from === null &&
        (updatedFilters[key] as RangeFilter).to === null
      ) {
        delete updatedFilters[key];
      }
    } else if (isArrayOfOptions(value) || typeof value === 'string') {
      if (value.length === 0) {
        delete updatedFilters[key];
      } else if (typeof value === 'string') {
        updatedFilters[key] = [value];
      } else {
        updatedFilters[key] = value.map((option) => option.value);
      }
    }
    onUpdateFilters(updatedFilters);
  };

  return (
    <div className='mt-8 mb-12 flex flex-col space-y-5'>
      <FormItem label={intl.get('PROGRAM_PAGE.TABLE.COLUMNS.STATUS')}>
        <MultiSelectDropdown
          options={statusOptions}
          values={getInitialValueForDropDown(
            statusOptions,
            appliedFilters[PROGRAM_PROJECT_TABLE_FILTERS.STATUS] as string[]
          )}
          onChange={(options: Option[]) =>
            updateFilters(PROGRAM_PROJECT_TABLE_FILTERS.STATUS, options)
          }
          triggerProps={{
            'aria-label': intl.get('PROGRAM_PAGE.TABLE.COLUMNS.STATUS'),
            'data-cy': 'program-projects-filters__status-picker',
          }}
          listProps={{
            'data-cy': 'program-projects-filters__status__options-list',
          }}
        />
      </FormItem>

      <FormItem label={intl.get('PROGRAM_PAGE.TABLE.COLUMNS.PRIORITY')}>
        <MultiSelectDropdown
          options={priorityOptions}
          values={getInitialValueForDropDown(
            priorityOptions,
            appliedFilters[PROGRAM_PROJECT_TABLE_FILTERS.PRIORITY] as string[]
          )}
          onChange={(options: Option[]) =>
            updateFilters(PROGRAM_PROJECT_TABLE_FILTERS.PRIORITY, options)
          }
          triggerProps={{
            'aria-label': intl.get('PROGRAM_PAGE.TABLE.COLUMNS.PRIORITY'),
            'data-cy': 'program-projects-filters__priority-picker',
          }}
          listProps={{
            'data-cy': 'program-projects-filters__priority__options-list',
          }}
        />
      </FormItem>

      <FormItem label={intl.get('PROGRAM_PAGE.TABLE.COLUMNS.PROGRESS')}>
        <NumericInput
          placeholder={intl.get('FILTER_GENERAL.TEXT_VALUE_PLACEHOLDER')}
          value={appliedFilters[PROGRAM_PROJECT_TABLE_FILTERS.PROGRESS] || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            updateFilters(
              PROGRAM_PROJECT_TABLE_FILTERS.PROGRESS,
              event.target.value
            )
          }
          data-testid='program-projects-filters__progress-input'
          max='100'
          min='0'
        />
      </FormItem>

      <FormItem label={intl.get('PROGRAM_PAGE.TABLE.COLUMNS.START_DATE')}>
        <div className='w-full flex space-x-4'>
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.FROM')}
            startDate={getInitialDatePickerValue(
              'from',
              appliedFilters[
                PROGRAM_PROJECT_TABLE_FILTERS.START_DATE
              ] as RangeFilter
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(
                PROGRAM_PROJECT_TABLE_FILTERS.START_DATE,
                date,
                'from'
              )
            }
            inputProps={{
              'aria-label': `${intl.get(
                'PROGRAM_PAGE.TABLE.COLUMNS.START_DATE'
              )}__from-range`,
              'data-cy': 'program-projects-filters__start-date__from-range',
            }}
          />
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.TO')}
            startDate={getInitialDatePickerValue(
              'to',
              appliedFilters[
                PROGRAM_PROJECT_TABLE_FILTERS.START_DATE
              ] as RangeFilter
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(
                PROGRAM_PROJECT_TABLE_FILTERS.START_DATE,
                date,
                'to'
              )
            }
            inputProps={{
              'aria-label': `${intl.get(
                'PROGRAM_PAGE.TABLE.COLUMNS.START_DATE'
              )}__to-range`,
              'data-cy': 'program-projects-filters__start-date__to-range',
            }}
          />
        </div>
      </FormItem>

      <FormItem
        label={intl.get('PROGRAM_PAGE.TABLE.COLUMNS.TARGET_COMPLETION_DATE')}
      >
        <div className='w-full flex space-x-4'>
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.FROM')}
            startDate={getInitialDatePickerValue(
              'from',
              appliedFilters[
                PROGRAM_PROJECT_TABLE_FILTERS.TARGET_COMPLETION_DATE
              ] as RangeFilter
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(
                PROGRAM_PROJECT_TABLE_FILTERS.TARGET_COMPLETION_DATE,
                date,
                'from'
              )
            }
            inputProps={{
              'aria-label': `${intl.get(
                'PROGRAM_PAGE.TABLE.COLUMNS.TARGET_COMPLETION_DATE'
              )}__from-range`,
              'data-cy':
                'program-projects-filters__target-completion-date__from-range',
            }}
          />
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.TO')}
            startDate={getInitialDatePickerValue(
              'to',
              appliedFilters[
                PROGRAM_PROJECT_TABLE_FILTERS.TARGET_COMPLETION_DATE
              ] as RangeFilter
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(
                PROGRAM_PROJECT_TABLE_FILTERS.TARGET_COMPLETION_DATE,
                date,
                'to'
              )
            }
            inputProps={{
              'aria-label': `${intl.get(
                'PROGRAM_PAGE.TABLE.COLUMNS.TARGET_COMPLETION_DATE'
              )}__to-range`,
              'data-cy':
                'program-projects-filters__target-completion-date__to-range',
            }}
          />
        </div>
      </FormItem>

      <FormItem
        label={intl.get('PROGRAM_PAGE.TABLE.COLUMNS.ACTUAL_COMPLETION_DATE')}
      >
        <div className='w-full flex space-x-4'>
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.FROM')}
            startDate={getInitialDatePickerValue(
              'from',
              appliedFilters[
                PROGRAM_PROJECT_TABLE_FILTERS.ACTUAL_COMPLETION_DATE
              ] as RangeFilter
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(
                PROGRAM_PROJECT_TABLE_FILTERS.ACTUAL_COMPLETION_DATE,
                date,
                'from'
              )
            }
            inputProps={{
              'aria-label': `${intl.get(
                'PROGRAM_PAGE.TABLE.COLUMNS.ACTUAL_COMPLETION_DATE'
              )}__from-range`,
              'data-cy':
                'program-projects-filters__actual-completion-date__from-range',
            }}
          />
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.TO')}
            startDate={getInitialDatePickerValue(
              'to',
              appliedFilters[
                PROGRAM_PROJECT_TABLE_FILTERS.ACTUAL_COMPLETION_DATE
              ] as RangeFilter
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(
                PROGRAM_PROJECT_TABLE_FILTERS.ACTUAL_COMPLETION_DATE,
                date,
                'to'
              )
            }
            inputProps={{
              'aria-label': `${intl.get(
                'PROGRAM_PAGE.TABLE.COLUMNS.ACTUAL_COMPLETION_DATE'
              )}__to-range`,
              'data-cy':
                'program-projects-filters__actual-completion-date__to-range',
            }}
          />
        </div>
      </FormItem>
    </div>
  );
};

export default FiltersForm;
