import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  ProjectTasksTableTab,
  Task,
  Pagination as PaginationType,
  TableExportOptions,
} from 'utils/customTypes';
import { TASKS_TABLE_TABS, TABLE_EXPORT_OPTIONS } from 'utils/constants';
import { selectTeamTasks } from 'state/ProjectTasks/projectTaskSlice';
import TasksTableFilters from './TasksTableFilters';
import TaskTable from './TasksTable';
import Pagination from 'Organisms/Pagination/ClientPagination';

const TeamTasks: React.FC<{
  projectId: string;
  onExportTasksToCsv: (taskIds: string[], callback: () => void) => void;
  onUpdatePagination: (
    pagination: PaginationType,
    table: ProjectTasksTableTab
  ) => void;
  openTaskPanel: (taskId: string) => void;
  hasActiveTask?: boolean;
}> = ({
  projectId,
  onExportTasksToCsv,
  onUpdatePagination,
  openTaskPanel,
  hasActiveTask,
}) => {
  const [selectedTasks, setSelectedTasks] = useState<string[]>([]);
  const teamTasksList = useSelector(selectTeamTasks);

  const allTasksIds = useMemo(
    () => teamTasksList.all.map((task: Task) => task.id),
    [teamTasksList.all]
  );

  const currentPageTasksIds = useMemo(
    () => teamTasksList.data.map((task: Task) => task.id),
    [teamTasksList.data]
  );

  const handleUpdatePagination = useCallback(
    (pagination) => {
      const table: ProjectTasksTableTab = TASKS_TABLE_TABS.TEAM_TASKS;
      onUpdatePagination(pagination, table);
    },
    [onUpdatePagination]
  );

  const handleExportTasks = (callback: () => void) => {
    onExportTasksToCsv(selectedTasks, callback);
  };

  const handleSelectedExportOption = (
    exportOption: TableExportOptions | null
  ) => {
    if (exportOption === TABLE_EXPORT_OPTIONS.ALL) {
      setSelectedTasks(allTasksIds);
      return;
    }

    if (
      exportOption === TABLE_EXPORT_OPTIONS.CURRENT_PAGE &&
      currentPageTasksIds.length > 0
    ) {
      setSelectedTasks(currentPageTasksIds);
      return;
    }

    setSelectedTasks([]);
  };

  return (
    <div>
      <TasksTableFilters
        taskTable={TASKS_TABLE_TABS.TEAM_TASKS}
        onExport={handleExportTasks}
        exportEnabled={!isEmpty(selectedTasks)}
        filterButtonEnabled={!isEmpty(teamTasksList.data)}
      />
      <TaskTable
        tasksList={teamTasksList.data}
        projectId={projectId}
        taskTable={TASKS_TABLE_TABS.TEAM_TASKS}
        onSelectTasks={setSelectedTasks}
        onSelectExportOption={handleSelectedExportOption}
        selectedTasksIds={selectedTasks}
        totalTasks={teamTasksList.total}
        openTaskPanel={openTaskPanel}
        hasActiveTask={hasActiveTask}
      />
      <Pagination
        total={teamTasksList.total}
        onChange={handleUpdatePagination}
        className='z-10 max-w-full'
      />
    </div>
  );
};

export default TeamTasks;
