import { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography } from '@getsynapse/design-system';
import FileUploadComponent from './FileUploadComponent';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import {
  selectEvaluationEvidences,
  selectEvaluationEvidencesStatus,
  getCategoryEvaluationEvidences,
  deleteCategoryEvaluationEvidence,
} from 'state/StrategyCategory/strategyCategorySlice';
import DeleteFileConfirmationModal from 'Organisms/DeleteFileConfirmationModal/DeleteFileConfirmationModal';
import Card from './Card';
import { SLICE_STATUS } from 'utils/constants';
import EvidenceSkeletonLoader from './EvidenceSkeletonLoader';

const Evidence = ({ readOnly }: { readOnly: boolean }) => {
  const dispatch = useDispatch();
  const evaluationEvidences = useSelector(selectEvaluationEvidences);
  const evaluationEvidenceStatus = useSelector(selectEvaluationEvidencesStatus);
  const { programId, categoryId } = useParams<{
    programId: string;
    categoryId: string;
  }>();
  const [fileToBeDeleted, setFileToBeDeleted] = useState<string | null>(null);

  useEffect(() => {
    if (programId && categoryId) {
      dispatch(getCategoryEvaluationEvidences({ programId, categoryId }));
    }
  }, [dispatch, programId, categoryId]);

  const beforeFileRemoveHandle = (evidenceId: string) => {
    setFileToBeDeleted(evidenceId);
  };

  const removeFile = async (evidenceId: string) => {
    try {
      if (programId && categoryId && evidenceId) {
        await dispatch(
          deleteCategoryEvaluationEvidence({
            programId,
            categoryId,
            evidenceId,
          })
        );
        dispatch(
          showSnackbarNotification({
            autoHide: false,
            notificationVariant: 'success',
            notificationTitle: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.EVIDENCE.DELETE_SUCCESS'
            ),
          })
        );
      }
    } catch (error) {
      dispatch(
        showSnackbarNotification({
          autoHide: false,
          notificationVariant: 'error',
          notificationTitle: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.EVIDENCE.DELETE_ERROR'
          ),
          notificationMessage: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.EVIDENCE.DELETE_ERROR_MESSAGE'
          ),
        })
      );
    }
  };
  if (readOnly && (!evaluationEvidences || evaluationEvidences.length === 0)) {
    return null;
  }

  return (
    <div
      className='flex flex-col w-84 h-full mt-20 ml-10'
      data-testid='evaluation__evidence_comp'
    >
      <DeleteFileConfirmationModal
        isOpen={Boolean(fileToBeDeleted)}
        onConfirm={() => {
          fileToBeDeleted && removeFile(fileToBeDeleted);
          setFileToBeDeleted(null);
        }}
        onCancel={() => {
          setFileToBeDeleted(null);
        }}
      />
      <Typography variant='h6' className='pb-2 leading-7'>
        {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.EVIDENCE.TITLE')}
      </Typography>
      <Typography className='text-neutral-darker' variant='body2'>
        {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.EVIDENCE.SECTION_DESCRIPTION')}
      </Typography>
      {evaluationEvidenceStatus === SLICE_STATUS.LOADING && (
        <EvidenceSkeletonLoader />
      )}
      {evaluationEvidenceStatus === SLICE_STATUS.IDLE && (
        <>
          {!readOnly && (
            <>
              <FileUploadComponent />
              <Typography className='text-neutral-darker' variant='body2'>
                {intl.get(
                  'PROGRAM_PAGE.STRATEGY_PAGE.EVIDENCE.SUPPORTED_FILE_TYPES'
                )}
              </Typography>
            </>
          )}
          <div className='flex flex-col mt-4 py-1 w-full overflow-y-auto max-h-116'>
            {evaluationEvidences &&
              evaluationEvidences.map((evidence) => (
                <Card
                  file={evidence}
                  removeFile={beforeFileRemoveHandle}
                  key={evidence.id}
                  readOnly={readOnly}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Evidence;
