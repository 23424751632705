import {
  createAction,
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import {
  ProjectsByTeamMember,
  TeamMemberProjects,
  DailyTeamMemberProjectCapacity,
  DailyCapacityPerWeek,
  DailyProjectCapacityPropsMap,
  FetchMoreDailyCapacityParams,
} from 'utils/types/dailyTeamsCapacity';
import {
  addDailyCapacityToProject,
  addProjectToTeamMember,
  removeProjectsFromTeamMember,
  getProjectsIdsFromTeamMember,
  getProjectsIdsFromTeamMemberToFetch,
  getProjectsIdsFromTeamMemberToRemove,
  removeTeamIdFromProjects,
  generateTeamMemberKey,
} from './helpers';
import {
  addIdsToArrayIfNotExists,
  generateFetchDailyCapacityURLFromDateRange,
  generateFetchPrevOrNextDayCapacityURL,
  removeDayFromCapacityPerWeek,
} from '../helpers';
import teamMemberProjectsCapacityAPI from './teamMemberProjectsCapacityAPI';
import { RootState } from 'state/store';
import { toggleExpandTeam } from 'state/Capacities/TeamsList/teamsListSlice';
import { resetDailyCapacityState } from '../TeamsCapacity/teamsCapacitySlice';
import { selectWeeksAndDays } from '../Days/daysSlice';

interface FetchDailyTeamMembeProjectsCapacityParams {
  teamId: string;
  memberId: string;
  projectsIds: string[];
}

interface TeamMemberProjectsCapacityState {
  byTeamMember: ProjectsByTeamMember<Omit<TeamMemberProjects, 'links'>>;
  byId: DailyTeamMemberProjectCapacity;
}

/* ============================= INITIAL STATE ============================== */
const initialState: TeamMemberProjectsCapacityState = {
  byTeamMember: {},
  byId: {},
};

/* =============================== ACTIONS ================================ */
const setDailyProjectCapacity = createAction<{
  teamId: string;
  memberId: string;
  projectId: string;
  dailyCapacities: DailyCapacityPerWeek;
}>('dailyTeamMembersProjectsCapacity/SET_DAILY_PROJECT_CAPACITY');
export const removeTeamMemberProjects = createAction<{
  teamId: string;
  memberId: string;
}>('dailyTeamMembersProjectsCapacity/REMOVE_TEAM_MEMBER_PROJECTS');
const removeDayFromDailyProjectCapacities = createAction<{
  teamId: string;
  memberId: string;
  projectId: string;
  day: string;
}>('dailyTeamMembersProjectsCapacity/REMOVE_DAY_FROM_DAILY_PROJECT_CAPACITIES');

/* ============================== REDUX THUNK =============================== */
export const fetchDailyTeamMembeProjectsCapacity = createAsyncThunk(
  'dailyTeamMembersProjectsCapacity/fetchDailyTeamMemberProjectsCapacity',
  async (
    params: FetchDailyTeamMembeProjectsCapacityParams,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { teamId, memberId, projectsIds } = params;
      const state = getState() as RootState;
      const { startDate, endDate } = state.dailyTeamsCapacity.days;
      const projectsIdsFromTeamMember = getProjectsIdsFromTeamMember(
        teamId,
        memberId,
        state.dailyTeamsCapacity.teamMemberProjectsCapacity.byTeamMember
      );
      const projectsIdsToFetch = getProjectsIdsFromTeamMemberToFetch(
        projectsIdsFromTeamMember,
        projectsIds
      );
      for (const projectId of projectsIdsToFetch) {
        const url = generateFetchDailyCapacityURLFromDateRange(
          startDate,
          endDate,
          `/v2/teams/${teamId}/members/${memberId}/projects/${projectId}/capacities/daily`
        );
        teamMemberProjectsCapacityAPI
          .fetchDailyTeamMemberProjectCapacity(url)
          .then((response) => {
            const dailyCapacities = response.dailyCapacities.weeks;
            dispatch(
              setDailyProjectCapacity({
                teamId,
                memberId,
                projectId,
                dailyCapacities,
              })
            );
          });
      }
      const projectsIdsToRemove = getProjectsIdsFromTeamMemberToRemove(
        projectsIdsFromTeamMember,
        projectsIds
      );
      return {
        teamId,
        memberId,
        projectsIdsToRemove,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMoreDailyTeamMembeProjectsCapacity = createAsyncThunk(
  'dailyTeamMembersProjectsCapacity/fetchMoreDailyTeamMemberProjectsCapacity',
  async (
    params: FetchMoreDailyCapacityParams,
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const state = getState() as RootState;
      const { link, startDate, endDate } = params;
      const byTeamMember =
        state.dailyTeamsCapacity.teamMemberProjectsCapacity.byTeamMember;
      Object.keys(byTeamMember).forEach((teamId) => {
        Object.keys(byTeamMember[teamId]).forEach((memberId) => {
          const projectsIds = byTeamMember[teamId][memberId]?.projectsIds || [];
          for (const projectId of projectsIds) {
            let url = `/v2/teams/${teamId}/members/${memberId}/projects/${projectId}/capacities/daily?startDate=:startDate`;
            if (link === 'nextDay') {
              url = generateFetchPrevOrNextDayCapacityURL(url, endDate, link);
            } else {
              url = generateFetchPrevOrNextDayCapacityURL(url, startDate, link);
            }
            teamMemberProjectsCapacityAPI
              .fetchDailyTeamMemberProjectCapacity(url)
              .then((response) => {
                const dailyCapacities = response.dailyCapacities.weeks;
                dispatch(
                  setDailyProjectCapacity({
                    teamId,
                    memberId,
                    projectId,
                    dailyCapacities,
                  })
                );
              })
              .then(() => {
                let day = '';
                if (link === 'prevDay') {
                  day = endDate;
                } else {
                  day = startDate;
                }
                dispatch(
                  removeDayFromDailyProjectCapacities({
                    teamId,
                    memberId,
                    projectId,
                    day,
                  })
                );
              });
          }
        });
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* =============================== SELECTORS ================================ */
const selectDailyProjectCapacitiesPerTeamMember = (
  state: RootState,
  projectId: string
) => {
  const byId = state.dailyTeamsCapacity.teamMemberProjectsCapacity.byId;
  if (projectId in byId) {
    return byId[projectId].dailyCapacities;
  }
  return undefined;
};

const selectProjectId = (
  _: RootState,
  teamId: string,
  memberId: string,
  projectId: string
) => projectId;
const selectTeamMemberKey = (
  _: RootState,
  teamId: string,
  memberId: string,
  projectId: string
) => generateTeamMemberKey(teamId, memberId);

export const selectDailyProjectCapacity = createSelector(
  [
    (state: RootState, teamId: string, memberId: string, projectId: string) =>
      selectDailyProjectCapacitiesPerTeamMember(state, projectId),
    selectTeamMemberKey,
    selectProjectId,
    selectWeeksAndDays,
  ],
  (
    dailyCapacitiesPerTeamMember,
    teamMemberKey,
    projectId,
    weeksAndDaysArray
  ) => {
    if (
      !dailyCapacitiesPerTeamMember ||
      !dailyCapacitiesPerTeamMember[teamMemberKey] ||
      !weeksAndDaysArray
    ) {
      return {};
    }
    const emptyDay = {
      assignedHours: 0,
      projectId: projectId,
    };
    return Object.keys(weeksAndDaysArray).reduce<DailyProjectCapacityPropsMap>(
      (acc: DailyProjectCapacityPropsMap, week: string) => {
        const days = weeksAndDaysArray[week];
        const dailyCapacitiesPerWeek =
          dailyCapacitiesPerTeamMember[teamMemberKey];
        if (week in dailyCapacitiesPerWeek) {
          const capacitiesPerWeek = dailyCapacitiesPerWeek[week];
          for (const day of days) {
            if (day in capacitiesPerWeek.days) {
              acc[day] = {
                projectId: projectId,
                assignedHours: parseFloat(
                  capacitiesPerWeek.days[day].assigned as string
                ),
              };
            } else {
              acc[day] = emptyDay;
            }
          }
        } else {
          for (const day of days) {
            acc[day] = emptyDay;
          }
        }
        return acc;
      },
      {}
    );
  }
);

/* ================================= REDUCER ================================ */
const teamMemberProjectsCapacitySlice = createSlice({
  name: 'teamMemberProjectsCapacity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetDailyCapacityState, (state) => {
        state.byId = initialState.byId;
        state.byTeamMember = initialState.byTeamMember;
      })
      .addCase(setDailyProjectCapacity, (state, action) => {
        if (!action.payload) {
          return;
        }
        const { teamId, memberId, projectId, dailyCapacities } = action.payload;
        const updatedDailyProjectCapacity = addDailyCapacityToProject(
          teamId,
          memberId,
          projectId,
          dailyCapacities,
          { ...state.byId }
        );
        const updatedProjectsByTeamMember = addProjectToTeamMember(
          teamId,
          memberId,
          projectId,
          { ...state.byTeamMember }
        );
        state.byId = {
          ...state.byId,
          [projectId]: {
            dailyCapacities: {
              ...state.byId[projectId]?.dailyCapacities,
              ...updatedDailyProjectCapacity.dailyCapacities,
            },
          },
        };
        state.byTeamMember = {
          ...state.byTeamMember,
          [teamId]: updatedProjectsByTeamMember,
        };
      })
      .addCase(
        fetchDailyTeamMembeProjectsCapacity.fulfilled,
        (state, action) => {
          if (!action.payload) {
            return;
          }
          const { teamId, memberId, projectsIdsToRemove } = action.payload;
          if (projectsIdsToRemove.length === 0) {
            return;
          }
          const updatedProjectsByTeamMember = removeProjectsFromTeamMember(
            teamId,
            memberId,
            projectsIdsToRemove,
            { ...state.byTeamMember }
          );
          const {
            projectsIdsToRemove: visibleProjectsIdsToRemove,
            updatedProjectsById,
          } = removeTeamIdFromProjects(teamId, memberId, projectsIdsToRemove, {
            ...state.byId,
          });
          if (teamId in updatedProjectsByTeamMember) {
            const allProjectsIds =
              updatedProjectsByTeamMember[teamId].allProjectsIds;
            updatedProjectsByTeamMember[teamId].allProjectsIds =
              allProjectsIds.filter(
                (id) => !visibleProjectsIdsToRemove.includes(id)
              );
          }
          state.byId = updatedProjectsById;
          state.byTeamMember = updatedProjectsByTeamMember;
        }
      )
      .addCase(removeTeamMemberProjects, (state, action) => {
        if (!action.payload) {
          return;
        }
        const { teamId, memberId } = action.payload;
        if (
          !(teamId in state.byTeamMember) ||
          !(memberId in state.byTeamMember[teamId])
        ) {
          return;
        }
        const projectsIds = state.byTeamMember[teamId][memberId].projectsIds;
        const updatedProjectsByTeamMember = removeProjectsFromTeamMember(
          teamId,
          memberId,
          projectsIds,
          { ...state.byTeamMember }
        );
        const { projectsIdsToRemove, updatedProjectsById } =
          removeTeamIdFromProjects(teamId, memberId, projectsIds, {
            ...state.byId,
          });
        if (teamId in updatedProjectsByTeamMember) {
          const allProjectsIds =
            updatedProjectsByTeamMember[teamId].allProjectsIds;
          updatedProjectsByTeamMember[teamId].allProjectsIds =
            allProjectsIds.filter((id) => !projectsIdsToRemove.includes(id));
        }
        state.byId = updatedProjectsById;
        state.byTeamMember = updatedProjectsByTeamMember;
      })
      .addCase(toggleExpandTeam, (state, action) => {
        if (!action.payload) {
          return;
        }
        const { teamId, expanded } = action.payload;
        if (!expanded && teamId in state.byTeamMember) {
          const { allProjectsIds, ...teamMembers } = state.byTeamMember[teamId];
          const memberIds = Object.keys(teamMembers);
          const { [teamId]: teamToRemove, ...restOfTeams } = state.byTeamMember;
          let projectsIdsToRemove: string[] = [];
          let updatedProjectsById = { ...state.byId };
          for (const memberId of memberIds) {
            const resp = removeTeamIdFromProjects(
              teamId,
              memberId,
              allProjectsIds,
              updatedProjectsById
            );
            projectsIdsToRemove = addIdsToArrayIfNotExists(
              resp.projectsIdsToRemove,
              projectsIdsToRemove
            );
            updatedProjectsById = resp.updatedProjectsById;
          }
          state.byId = updatedProjectsById;
          state.byTeamMember = restOfTeams;
        }
      })
      .addCase(removeDayFromDailyProjectCapacities, (state, action) => {
        if (!action.payload) {
          return;
        }
        const { teamId, memberId, projectId, day } = action.payload;
        const teamMemberKey = generateTeamMemberKey(teamId, memberId);
        const updatedDailyProjectCapacitiesPerTeamMember =
          removeDayFromCapacityPerWeek(
            { ...state.byId[projectId].dailyCapacities[teamMemberKey] },
            day
          );
        state.byId[projectId] = {
          dailyCapacities: {
            ...state.byId[projectId].dailyCapacities,
            [teamMemberKey]: updatedDailyProjectCapacitiesPerTeamMember,
          },
        };
      });
  },
});

export default teamMemberProjectsCapacitySlice.reducer;
