import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import {
  FormItem,
  NumericInput,
  RadioGroup,
  Typography,
} from '@getsynapse/design-system';
import { MeasurementScale, MeasurementScaleType } from 'utils/types/program';
import { useSelector } from 'react-redux';
import { selectMeasurementScales } from 'state/MeasurementScales/measurementScalesSlice';

interface MeasurementScaleOption {
  label: string;
  value: MeasurementScaleType;
}

interface Props {
  storedScaleId?: string;
  storedScore?: number;
  onChangeMeasurement: (scale: MeasurementScale, score: number | null) => void;
  setIsMeasurementScaleValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const ObjectiveMeasurementScale = ({
  storedScaleId,
  storedScore,
  onChangeMeasurement,
  setIsMeasurementScaleValid,
}: Props) => {
  const measurementScales = useSelector(selectMeasurementScales);

  const measurementScaleOptions: MeasurementScaleOption[] = useMemo<
    MeasurementScaleOption[]
  >(
    () =>
      measurementScales.map((scale) => ({
        label: intl.get(
          `PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.MEASUREMENT_TYPES.${scale.scale}`
        ),
        value: scale.scale,
      })),
    [measurementScales]
  );

  const [selectedScale, setSelectedScale] = useState<MeasurementScale>();
  const [selectedScore, setSelectedScore] = useState<number>();

  useEffect(() => {
    if (storedScaleId) {
      const storedScale = measurementScales.find(
        (scale) => scale.id === storedScaleId
      );
      setSelectedScale(storedScale);
    }
  }, [measurementScales, storedScaleId]);
  useEffect(() => {
    if (storedScore) {
      setSelectedScore(storedScore);
    }
  }, [storedScore]);

  const onSelectScale = (event: ChangeEvent<HTMLInputElement>) => {
    const scaleOptionValue = event.target.value as MeasurementScaleType;
    const newScale = measurementScales.find(
      (measurementScale) => measurementScale.scale === scaleOptionValue
    );
    if (!newScale) return;
    setIsMeasurementScaleValid(true);
    setSelectedScale(newScale);
    setSelectedScore(undefined);
    onChangeMeasurement(newScale, null);
  };
  const onChangeScore = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setSelectedScore(value);
    if (selectedScale) {
      onChangeMeasurement(selectedScale, value);
    }
  };

  return (
    <>
      <FormItem
        component='fieldset'
        label={intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.MEASURED_OTHER_SCALE'
        )}
        labelProps={{
          className: 'text-base leading-5 tracking-wider pb-2 pt-6',
        }}
      >
        <RadioGroup
          horizontal
          checkOption='checked'
          checked={selectedScale?.scale}
          name='objective-measurement-scale'
          options={measurementScaleOptions}
          inputProps={{
            onChange: onSelectScale,
            'data-testid': 'objective-measurement_scale-input',
          }}
        />
      </FormItem>

      {selectedScale && (
        <div
          key={selectedScale.scale}
          className='flex items-start pt-6 space-x-2'
        >
          {selectedScale.scale === 'PERCENTAGE' && (
            <>
              <NumericInput
                defaultValue={selectedScore}
                type='number'
                height='small'
                divProps={{ className: 'max-w-22' }}
                onChange={onChangeScore}
                getIsInputValid={(isValid) => {
                  setIsMeasurementScaleValid(isValid);
                }}
                min={selectedScale.minValue}
                max={selectedScale.maxValue}
                allowNegativeValue={selectedScale.minValue < 0}
              />
              <Typography className='mt-1'>
                {intl.get(
                  `PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.MEASUREMENT_INPUT.${selectedScale.scale}`
                )}
              </Typography>
            </>
          )}

          {(selectedScale.scale === 'FIVE_POINT' ||
            selectedScale.scale === 'TEN_POINT') && (
            <>
              <Typography className='mt-1'>
                {intl.get(
                  `PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.MEASUREMENT_INPUT.${selectedScale.scale}`
                )}
              </Typography>
              <NumericInput
                defaultValue={selectedScore}
                type='number'
                height='small'
                divProps={{ className: 'max-w-22' }}
                onChange={onChangeScore}
                getIsInputValid={(isValid) => {
                  setIsMeasurementScaleValid(isValid);
                }}
                min={selectedScale.minValue}
                max={selectedScale.maxValue}
                step='.1'
                allowNegativeValue={selectedScale.minValue < 0}
              />
              <Typography className='mt-1'>
                / {selectedScale.maxValue}
              </Typography>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ObjectiveMeasurementScale;
