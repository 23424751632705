import { PartialHours, PartialHoursItem } from 'types/store/manageTimeOff';

const isDateWeekend = (date: Date): boolean =>
  date.getDay() === 0 || date.getDay() === 6;

export const dateFormatter = new Intl.DateTimeFormat('fr-CA');

export const generatePartialHoursForDateRange = (
  startDate: Date,
  endDate: Date,
  defaultCapacity: number,
  oldPartialHours?: PartialHours
): PartialHours => {
  const partialHours: PartialHours = {};
  let pivotDate = new Date(startDate);
  while (pivotDate <= endDate) {
    const isWeekDay = !isDateWeekend(pivotDate);
    const day = dateFormatter.format(pivotDate);
    partialHours[day] = isWeekDay ? defaultCapacity : 0;
    if (oldPartialHours && oldPartialHours[day]) {
      partialHours[day] = oldPartialHours[day];
    }
    pivotDate = new Date(pivotDate.setDate(pivotDate.getDate() + 1));
  }
  return partialHours;
};

export const generatePartialHoursItemsArray = (
  partialHours: PartialHours
): PartialHoursItem[] =>
  Object.keys(partialHours).map((day: string) => {
    const date = new Date(day.replace(/-/g, '/'));
    const isWeekDay = !isDateWeekend(date);
    return {
      day,
      hours: partialHours[day].toString(),
      isWeekDay,
    };
  });

export const convertPartialsHoursToMinutes = (
  partialHours: PartialHours
): PartialHours =>
  Object.keys(partialHours).reduce((acc: PartialHours, day: string) => {
    acc[day] = partialHours[day] * 60;
    return acc;
  }, {});

export const calculatePartialHoursTotalSum = (
  partialHours: PartialHours
): number =>
  Object.values(partialHours).reduce(
    (acc: number, hours: number) => acc + hours,
    0
  );

export const getPartialHoursDiff = (
  prevPartialHours: PartialHours,
  newPartialHours: PartialHours
): PartialHours => {
  let diffDays: PartialHours = {};
  for (const [day, hours] of Object.entries(newPartialHours)) {
    if (day in prevPartialHours) {
      if (prevPartialHours[day] !== hours) {
        diffDays[day] = newPartialHours[day];
      }
    } else {
      diffDays[day] = hours;
    }
  }
  return diffDays;
};
