import * as Sentry from '@sentry/react';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from 'state/store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';

const tracePropagationTargets: string[] = [
  'localhost',
  process.env.backendURL || '',
].filter((target) => target.length > 0);

Sentry.init({
  dsn: 'https://10d3169705f23cb802e821d7180f2104@o4507889185718272.ingest.us.sentry.io/4507890082381824',
  environment: process.env.REACT_APP_APP_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: tracePropagationTargets,
  // Session Replay
  replaysSessionSampleRate: 0.0, // This sets the sample rate at 0%, as for Sentry at the moment we are only interested in errors.
  replaysOnErrorSampleRate: 1.0, // 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
