const InputFieldsPageSkeleton = () => (
  <div
    className='flex flex-row w-2/3 pl-8'
    data-testid='input-page-skeleton-loader'
  >
    <div className='h-insights w-full'>
      <div className='animate-pulse'>
        <div className='bg-neutral-lighter-two w-1/6 h-4 mt-1.5 rounded' />
        <div className='bg-neutral-lighter-two w-10/12 h-4 mt-4 rounded' />
        <div className='bg-neutral-lighter-two w-6/6 h-28 mt-6 rounded' />
        <div className='bg-neutral-lighter-two w-1/6 h-4 mt-4 rounded' />
        <div className='bg-neutral-lighter-two w-10/12 h-4 mt-16 rounded' />
        <div className='flex flex-row'>
          <div className='flex flex-col w-1/2 mt-8 pr-4'>
            <div className='bg-neutral-lighter-two w-1/6 h-4 mt-4 rounded' />
            <div className='bg-neutral-lighter-two w-3/6 h-4 mt-2 rounded' />
            <div className='bg-neutral-lighter-two w-full h-12 mt-4 rounded' />
          </div>
          <div className='flex flex-col w-1/2 mt-8 pl-4'>
            <div className='bg-neutral-lighter-two w-1/6 h-4 mt-4 rounded' />
            <div className='bg-neutral-lighter-two w-3/6 h-4 mt-2 rounded' />
            <div className='bg-neutral-lighter-two w-full h-12 mt-4 rounded' />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default InputFieldsPageSkeleton;
