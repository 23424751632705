import { Modal, Typography } from '@getsynapse/design-system';
import { ModalProps } from '@getsynapse/design-system/dist/Organisms/Modal/Modal';
import intl from 'react-intl-universal';

export type DeleteTaskConfirmationModalProps = ModalProps & {
  onDeleteTask: () => void;
  onCancel: () => void;
};

export const DeleteTaskConfirmationModal = ({
  onDeleteTask,
  onCancel,
  ...props
}: DeleteTaskConfirmationModalProps) => {
  return (
    <Modal
      {...props}
      aria-label={intl.get(
        'SETTINGS_PAGE.TASK_BUNDLE_PAGE.BUILD_SECTION.DELETE_TASK'
      )}
      titleIcon={{
        name: 'trash',
      }}
      title={intl.get(
        'SETTINGS_PAGE.TASK_BUNDLE_PAGE.BUILD_SECTION.DELETE_TASK'
      )}
      size='medium'
      actionButtons={[
        {
          children: intl.get(
            'SETTINGS_PAGE.TASK_BUNDLE_PAGE.BUILD_SECTION.DELETE_TASK_CONFIRM'
          ),
          onClick: onDeleteTask,
          'data-cy': 'remove-task_button',
          'data-testid': 'remove-task',
          variant: 'primary',
          color: 'danger',
        },
        {
          children: intl.get('CANCEL'),
          variant: 'tertiary',
          onClick: onCancel,
          'data-cy': 'cancel-task_button',
          'data-testid': 'cancel',
        },
      ]}
    >
      <Typography className='mb-6'>
        {intl.get(
          'SETTINGS_PAGE.TASK_BUNDLE_PAGE.BUILD_SECTION.TASK_WILL_BE_REMOVED'
        )}
      </Typography>
      <Typography>
        {intl.get('SETTINGS_PAGE.TASK_BUNDLE_PAGE.BUILD_SECTION.ARE_YOU_SURE')}
      </Typography>
    </Modal>
  );
};
