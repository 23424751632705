import React, { useEffect, useMemo, useState } from 'react';
import { AppliedFilters, tailwindOverride } from '@getsynapse/design-system';
import {
  AppliedFilter,
  RangeFilter,
  TaskFilters,
  CentralizedTasksFiltersKey,
} from 'utils/types/filters';
import { isDateRangeFilter } from 'utils/typeGuards';
import { DATE, TASKS_TABLE_FILTERS } from 'utils/constants';
import useTaskFilters from './hooks/useTasksFilters';
import { CENTRALIZED_TASKS_FILTERS } from 'utils/constants/centralizedTasks';

const DATE_FILTERS = [
  TASKS_TABLE_FILTERS.START_DATE,
  TASKS_TABLE_FILTERS.DUE_DATE,
  TASKS_TABLE_FILTERS.COMPLETION_DATE,
];

const HOURS_FILTERS = [
  TASKS_TABLE_FILTERS.ESTIMATED_HOURS,
  TASKS_TABLE_FILTERS.ACTUAL_HOURS,
];

export type TasksAppliedFiltersTagsProps = {
  filters: TaskFilters;
  onUpdateFilters: (filters: TaskFilters) => void;
  onDisplayAllFilters: () => void;
};

const TasksAppliedFiltersTags: React.FC<TasksAppliedFiltersTagsProps> = ({
  filters,
  onUpdateFilters,
  onDisplayAllFilters,
}) => {
  const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);

  useEffect(() => {
    const filteredPrograms = filters[
      CENTRALIZED_TASKS_FILTERS.PROGRAM
    ] as string[];
    setSelectedPrograms(filteredPrograms || []);
  }, [filters]);

  const {
    getFilterOptionLabelByKeyAndValue,
    getDateFilterLabel,
    getHoursRangeFilterLabel,
  } = useTaskFilters(selectedPrograms);

  const appliedFilters = useMemo<AppliedFilter[]>(() => {
    const tags: AppliedFilter[] = [];
    for (const [key, value] of Object.entries(filters)) {
      const filterKey = key as CentralizedTasksFiltersKey;
      if (isDateRangeFilter(value)) {
        const filterValue = value as RangeFilter;
        let label = '';
        if (DATE_FILTERS.includes(filterKey)) {
          label = getDateFilterLabel(filterValue, DATE.TASK_TABLE_FORMAT);
        }
        if (HOURS_FILTERS.includes(filterKey)) {
          label = getHoursRangeFilterLabel(filterValue);
        }
        tags.push({ label, value: '', key });
      } else {
        const filterValues = value as string[];
        filterValues.forEach((value: string) => {
          const label = getFilterOptionLabelByKeyAndValue(filterKey, value);
          tags.push({ label, value, key });
        });
      }
    }
    return tags;
  }, [
    filters,
    getDateFilterLabel,
    getFilterOptionLabelByKeyAndValue,
    getHoursRangeFilterLabel,
  ]);

  const handleRemoveFilter = (filter: AppliedFilter) => {
    const { key, value } = filter as {
      key: CentralizedTasksFiltersKey;
      value: string;
    };
    const updatedFilters = { ...filters };
    if (isDateRangeFilter(filters[key])) {
      delete updatedFilters[key];
    } else {
      const filterValues = updatedFilters[key] as string[];
      const updatedFilterValues = filterValues.filter(
        (filterValue) => filterValue !== value
      );
      if (updatedFilterValues.length === 0) {
        delete updatedFilters[key];
      } else {
        updatedFilters[key] = updatedFilterValues;
      }
    }
    onUpdateFilters(updatedFilters);
  };

  return (
    <div data-testid='centralized-tasks-applied-tasks-filters-tags'>
      <AppliedFilters<AppliedFilter>
        filters={appliedFilters}
        onRemoveFilter={handleRemoveFilter}
        onClearAll={() => onUpdateFilters({})}
        onDisplayAllFilters={onDisplayAllFilters}
        className={tailwindOverride('border-primary-lighter-two z-20')}
      />
    </div>
  );
};

export default TasksAppliedFiltersTags;
