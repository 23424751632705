import React, { useState, useRef, memo, useMemo } from 'react';
import { VariableSizeList as List, areEqual } from 'react-window';
import { tailwindOverride } from '@getsynapse/design-system';
import { TeamMemberCapacity } from 'utils/types/teamsCapacity';
import TeamMember from './components/TeamMember/TeamMember';

const TeamMembers: React.FC<{
  teamId: string;
  teamMembers: Record<string, TeamMemberCapacity>;
}> = ({ teamId, teamMembers, ...props }) => {
  const listRef = useRef<any>(null);
  const [openMemberProjectsList, setOpenMemberProjectsList] = useState<
    string[]
  >([]);
  const itemCount = Object.keys(teamMembers).length;
  const itemSize = 104;
  const maxItems = 100;
  const members = Object.values(teamMembers);
  const listHeight = useMemo(() => {
    let height = 0;
    const maxHeight = itemSize * maxItems;
    if (itemCount > maxItems) {
      return maxHeight;
    }
    height = itemSize * itemCount;
    if (openMemberProjectsList.length === 0) {
      return height;
    }
    let pivot = itemCount;
    openMemberProjectsList.forEach((memberId) => {
      const memberProjectsCount = Object.keys(
        teamMembers[memberId].projects
      ).length;
      if (pivot + memberProjectsCount > maxItems) {
        height = maxHeight;
        return;
      }
      height += itemSize * memberProjectsCount;
      pivot = pivot += memberProjectsCount;
    });
    return height;
  }, [openMemberProjectsList, itemCount, itemSize, teamMembers, maxItems]);

  const toggleOpenMemberProjectsList = (memberId: string, index: number) => {
    if (openMemberProjectsList.includes(memberId)) {
      setOpenMemberProjectsList(
        openMemberProjectsList.filter((id) => id !== memberId)
      );
    } else {
      setOpenMemberProjectsList([...openMemberProjectsList, memberId]);
    }
    listRef.current?.resetAfterIndex(index);
  };

  const getItemSize = (index: number) => {
    const member = members[index];
    if (openMemberProjectsList.includes(member.data.id)) {
      const memberProjectsCount = Object.keys(member.projects).length;
      return memberProjectsCount > maxItems
        ? itemSize + itemSize * maxItems
        : itemSize + itemSize * memberProjectsCount;
    }
    return itemSize;
  };

  const MemberRow: React.FC<{ index: number; style: React.CSSProperties }> =
    memo(({ index, style }) => {
      const teamMemberCapacity = members[index];
      return (
        <div
          aria-label={`team-${teamId}__member-${teamMemberCapacity.data.id}`}
          data-cy={`team-${teamId}__member-${teamMemberCapacity.data.id}`}
          style={style}
        >
          <TeamMember
            teamId={teamId}
            teamMemberCapacity={teamMemberCapacity}
            index={index}
            onDisplayProjectsList={toggleOpenMemberProjectsList}
            displayProjectsList={openMemberProjectsList.includes(
              members[index].data.id
            )}
          />
        </div>
      );
    }, areEqual);

  return (
    <List
      ref={listRef}
      itemKey={(index: number) =>
        `team-${teamId}__member-${members[index].data.id}`
      }
      itemCount={itemCount}
      estimatedItemSize={itemSize}
      itemSize={getItemSize}
      height={listHeight}
      width='100%'
      className={tailwindOverride(
        'layout-cell layout-scrollbar',
        `team-${teamId}__members-list`
      )}
      {...props}
    >
      {MemberRow}
    </List>
  );
};

export default TeamMembers;
