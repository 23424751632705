import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { ProjectProcess, MoreActionsOption } from 'utils/customTypes';
import { PROCESS_ACTIONS } from 'utils/constants';
import MoreActions from 'Organisms/MoreActions/MoreActions';
import RemoveProcessConfirmationModal from './RemoveProcessConfirmationModal';

const ProcessActions: React.FC<{
  process: ProjectProcess | null;
  removeProcessCallback: () => void;
  canRemoveProcess?: boolean;
  removeProcessTooltip?: string;
}> = ({
  process,
  removeProcessCallback,
  canRemoveProcess = false,
  removeProcessTooltip = undefined,
}) => {
  const [shouldDisplayRemoveProcessModal, setShouldDisplayRemoveProcessModal] =
    useState(false);

  const handleSelectOption = (option: MoreActionsOption) => {
    if (option.selectOption) {
      option.selectOption();
    }

    if (option.value === PROCESS_ACTIONS.DELETE) {
      setShouldDisplayRemoveProcessModal(true);
    }
  };

  const handleCloseModa = () => {
    setShouldDisplayRemoveProcessModal(false);
    removeProcessCallback();
  };

  return (
    <>
      <RemoveProcessConfirmationModal
        isModalOpen={shouldDisplayRemoveProcessModal}
        process={process}
        onCloseModalHandle={handleCloseModa}
      />
      <MoreActions
        options={[
          {
            value: PROCESS_ACTIONS.DELETE,
            label: intl.get('SETTINGS_PAGE.PROJECTS_PAGE.DELETE_PROCESS'),
            iconName: 'trash',
            dataCy: 'delete-task-button',
            tooltip: { text: removeProcessTooltip },
            disabled: !canRemoveProcess,
          },
        ]}
        onSelectOption={handleSelectOption}
      />
    </>
  );
};

export default ProcessActions;
