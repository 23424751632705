import { Icon, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

const LoadingError = () => (
  <div className='flex items-center justify-center h-full'>
    <div className='flex'>
      <div className='w-6 mr-2'>
        <Icon
          name='alert-circle'
          className='mr-2 text-warning-darker w-5 h-5 mt-1'
        />
      </div>
      <Typography>{intl.get('INSIGHTS_PAGE.ERROR_MESSAGE')}</Typography>
    </div>
  </div>
);

export default LoadingError;
