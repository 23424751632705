import { useMemo } from 'react';
import { EMPLOYMENT_TYPE } from 'utils/constants';
import { FormOption } from 'utils/customTypes';
import intl from 'react-intl-universal';
import { Dropdown, FormItem } from '@getsynapse/design-system';

type EmploymentTypePickerProps = {
  defaultValues?: string[];
  onChange: (option: FormOption) => void;
  required?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const EmploymentTypePicker = ({
  defaultValue,
  onChange,
  className,
  required,
}: EmploymentTypePickerProps) => {
  const employmentTypeOptions: FormOption[] = useMemo(() => {
    return EMPLOYMENT_TYPE.map((employmentType) => ({
      value: employmentType,
      label: intl.get(`EMPLOYMENT_TYPE.${employmentType}`),
    }));
  }, []);

  const defaultOption: FormOption | undefined = useMemo(() => {
    return employmentTypeOptions.find((option) => {
      return defaultValue === option.value;
    });
  }, [employmentTypeOptions, defaultValue]);

  return (
    <>
      <FormItem
        className={className}
        component='div'
        label={intl.get('EMPLOYMENT_TYPE.TITLE')}
        labelProps={{ required }}
      >
        <Dropdown
          onChange={onChange}
          options={employmentTypeOptions}
          values={defaultOption ? [defaultOption] : []}
        />
      </FormItem>
    </>
  );
};

export default EmploymentTypePicker;
