import { FC } from 'react';
import { tailwindOverride, Typography } from '@getsynapse/design-system';
import { Hours } from 'types/store/actualHours';

interface TimeEntryProps {
  hours: Hours;
  onDeleteHours: (hoursId: string) => void;
}

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});

const TimeEntry: FC<TimeEntryProps> = ({ hours }) => {
  return (
    <div
      className={tailwindOverride('flex justify-between', 'py-1 px-2 pl-14')}
      aria-label={`time-entry-${hours.id}`}
    >
      <Typography variant='body'>
        {dateFormatter.format(new Date(hours.date))}
      </Typography>
      <Typography variant='body'>{`${hours.hours}h`}</Typography>
    </div>
  );
};

export default TimeEntry;
