import { useState, useEffect } from 'react';
import { NumericInput } from '@getsynapse/design-system';
import { intakeQuestionWrapper } from 'utils/customTypes';
import get from 'lodash/get';

const NumberQuestion = ({
  question,
  handler,
  disabled,
}: intakeQuestionWrapper) => {
  useEffect(() => {
    setValue((prev) =>
      prev !== question.data.value ? question.data.value : prev
    );
  }, [question]);

  const [value, setValue] = useState<number>(question.data.value);

  return (
    <NumericInput
      onChange={(event: { target: { value: any } }) => {
        setValue(event.target.value);
        handler(question, event.target.value, 'data.value');
      }}
      value={value}
      placeholder={get(question, 'data.placeholder')}
      disabled={disabled}
    />
  );
};

export default NumberQuestion;
