import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Cell,
} from 'recharts';
import CustomTooltip from '../../Components/CustomToolTip';
import { CHART_COLOR_PALETTE } from 'Pages/InsightsPage/chartConstants';

const BAR_CHART_AXIES_STYLE = {
  fontSize: '0.75em',
  fontWeight: '400',
  fill: '#000',
  lineHeight: '1em',
};
const BarChartInProgressProjects = ({ data }: { data: any }) => {
  return (
    <ResponsiveContainer width='85%' height='95%'>
      <BarChart
        data={data}
        layout='vertical'
        margin={{
          right: 60,
        }}
        barSize={30}
        maxBarSize={20}
      >
        <XAxis
          tickLine={false}
          type='number'
          domain={[0, 'dataMax + 10']}
          style={BAR_CHART_AXIES_STYLE}
        />
        <YAxis
          tickLine={false}
          style={BAR_CHART_AXIES_STYLE}
          dataKey='name'
          type='category'
        />
        <Tooltip content={<CustomTooltip chartType='Bar' />} />

        <Bar dataKey='count' maxBarSize={40}>
          {data.map((elem: { name: string; count: number }, index: number) => (
            <Cell
              key={`cell-${index}`}
              fill={CHART_COLOR_PALETTE[index % CHART_COLOR_PALETTE.length]}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartInProgressProjects;
