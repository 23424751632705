import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'state/store';
import {
  selectAssigneesListByIds,
  setTasksAssignees,
} from 'state/TasksList/allTasks/allTasksSlice';
import { fetchTasksList } from 'api/tasksList';
import { ProjectTasksSliceState } from 'types/store/projectTasksList';
import { getFetchProjectsTasksListQueryParams } from '../helpers';

const initialState: ProjectTasksSliceState = {
  tasks: [],
  links: {
    next: null,
  },
  loading: false,
};

interface FetchTasksListParams {
  projectId: string;
  userId: string;
  fetchNext?: boolean;
  includeTotalCount?: boolean;
}

export const fetchUserTasks = createAsyncThunk(
  'projectTasksList/userTasks/fetchMyTasks',
  async (
    params: FetchTasksListParams,
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const state = getState() as RootState;
      const userTasksState = state.projectTasksList.userTasks;
      const queryParams = getFetchProjectsTasksListQueryParams(
        params,
        userTasksState
      );
      const response = await fetchTasksList(queryParams);
      dispatch(setTasksAssignees(response.assignees));
      return {
        tasks: response.tasks,
        links: response.links,
        fetchNext: params.fetchNext,
        totalCount: response.totalCount,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error fetching user tasks');
    }
  }
);

const userTasksSlice = createSlice({
  name: 'projectTasksList/userTasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserTasks.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserTasks.fulfilled, (state, action) => {
        if (action.payload.fetchNext) {
          state.tasks = state.tasks.concat(action.payload.tasks);
        } else {
          state.tasks = action.payload.tasks;
        }
        state.links = action.payload.links;
        if (action.payload.totalCount !== undefined) {
          state.totalCount = action.payload.totalCount;
        }
        state.loading = false;
      })
      .addCase(fetchUserTasks.rejected, (state) => {
        state.loading = false;
      });
  },
});

const selectTasks = (state: RootState) =>
  state.projectTasksList.userTasks.tasks;
export const selectUserTasksLoading = (state: RootState) =>
  state.projectTasksList.userTasks.loading;

export const selectUserTasks = createSelector(
  [(state: RootState) => state, selectTasks],
  (state: RootState, tasks: ProjectTasksSliceState['tasks']) => {
    return tasks.map((task) => {
      return {
        ...task,
        assignees: selectAssigneesListByIds(state, task.assignees),
      };
    });
  }
);

export const selectTotalCount = (state: RootState) =>
  state.projectTasksList.userTasks.totalCount;

export const selectCanFetchtMoreTasks = (state: RootState) =>
  state.projectTasksList.userTasks.links.next !== null;

export default userTasksSlice.reducer;
