import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { Status } from 'utils/customTypes';
import { ParticipantsCapacityByType } from 'utils/types/tasksAssignmentsResourcesCapacity';
import {
  AddParticipantApiBody,
  UpdateParticipantApiBody,
  UpdateParticipantsApiBody,
  Pagination,
  ParticipantToUpdate,
} from './types';
import { SLICE_STATUS, ParticipantTypes } from 'utils/constants';
import {
  mapApiResponseToParticipantsCapacity,
  userTypeToParticipantType,
  calculateNewPagination,
  addCapacityToParticipants,
  mapSingleParticipantApiResponseToParticipantCapacity,
} from './helpers';
import tasksAssignmentsResourcesCapacityApi from './tasksAssignmentsResourcesCapacityAPI';

export interface state {
  value: ParticipantsCapacityByType;
  pagination: Pagination;
  participantToUpdate: ParticipantToUpdate | null;
  participantIdToRemove: string | null;
  status: Status;
}

const API = tasksAssignmentsResourcesCapacityApi;

/* ============================= INITIAL STATE ============================== */
const initialState: state = {
  value: {} as ParticipantsCapacityByType,
  pagination: {
    from: null,
    to: null,
    total: 0,
    index: 0,
  },
  participantToUpdate: null,
  participantIdToRemove: null,
  status: SLICE_STATUS.IDLE,
};

/* ============================== ACTIONS ============================= */
export const updatePaginationIndex = createAction<number>(
  'TASKS_ASSIGNMENTS_RESOURCES_CAPACITY/UPDATE_PAGINATION_INDEX'
);

export const setParticipantToUpdate = createAction<ParticipantToUpdate | null>(
  'TASKS_ASSIGNMENTS_RESOURCES_CAPACITY/SET_PARTICIPANT_TO_UPDATE'
);

export const setParticipantIdToRemove = createAction<string | null>(
  'TASKS_ASSIGNMENTS_RESOURCES_CAPACITY/SET_PARTICIPANT_ID_TO_REMOVE'
);

export const resetCapacityTable = createAction(
  'TASKS_ASSIGNMENTS_RESOURCES_CAPACITY/RESET_CAPACITY_TABLE'
);

/* ============================== REDUX THUNK =============================== */
export const fetchCapacity = createAsyncThunk(
  'TASKS_ASSIGNMENTS_RESOURCES_CAPACITY/FETCH_CAPACITY',
  async (
    params: {
      projectId: string;
      query: { tableStartDate: string; tableEndDate: string };
      fromSidePanel?: boolean;
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const response = await API.fetchCapacity(params.projectId, params.query);
      const state = getState() as RootState;
      const currentPagination = {
        ...state.tasksAssignmentsResourcesCapacity.pagination,
      };
      const newPagination = calculateNewPagination(
        currentPagination,
        params.query.tableStartDate,
        params.query.tableEndDate,
        params.fromSidePanel || false
      );
      let newParticipantsCapacity = {} as ParticipantsCapacityByType;
      if (currentPagination.total === 0 || params.fromSidePanel) {
        newParticipantsCapacity =
          mapApiResponseToParticipantsCapacity(response);
      } else {
        newParticipantsCapacity = addCapacityToParticipants(
          response,
          state.tasksAssignmentsResourcesCapacity.value,
          { from: currentPagination.from!, to: currentPagination.to! },
          params.query.tableStartDate
        );
      }
      return {
        value: newParticipantsCapacity,
        pagination: newPagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addParticipant = createAsyncThunk(
  'TASKS_ASSIGNMENTS_RESOURCES_CAPACITY/ADD_PARTICIPANT',
  async (participant: AddParticipantApiBody, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const pagination = state.tasksAssignmentsResourcesCapacity.pagination;
      await API.addParticipant(participant);
      const response = await API.fetchParticipantCapacity(
        participant.project_id,
        participant.user_id,
        {
          tableStartDate: pagination.from!,
          tableEndDate: pagination.to!,
        }
      );
      const capacity =
        mapSingleParticipantApiResponseToParticipantCapacity(response);
      let participantType =
        userTypeToParticipantType[capacity[participant.user_id].data.type];
      return { participantType, capacity };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateParticipant = createAsyncThunk(
  'TASKS_ASSIGNMENTS_RESOURCES_CAPACITY/UPDATE_PARTICIPANT',
  async (
    participant: UpdateParticipantApiBody,
    { rejectWithValue, getState }
  ) => {
    try {
      await API.updateParticipant(participant);
      const state = getState() as RootState;
      const participantsCapacity =
        state.tasksAssignmentsResourcesCapacity.value;
      let participantType = '';
      for (const type of Object.keys(participantsCapacity)) {
        if (
          participantsCapacity[type as ParticipantTypes][participant.user_id]
        ) {
          participantType = type;
          break;
        }
      }
      return {
        participantType,
        capacity: participant,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeParticipant = createAsyncThunk(
  'TASKS_ASSIGNMENTS_RESOURCES_CAPACITY/REMOVE_PARTICIPANT',
  async (
    participant: UpdateParticipantsApiBody,
    { rejectWithValue, getState }
  ) => {
    try {
      await API.removeParticipant(participant);
      const state = getState() as RootState;
      const participantsCapacity =
        state.tasksAssignmentsResourcesCapacity.value;
      let participantType = '';
      for (const type of Object.keys(participantsCapacity)) {
        if (
          participantsCapacity[type as ParticipantTypes][participant.user_id]
        ) {
          participantType = type;
          break;
        }
      }
      return {
        participantType,
        user_id: participant.user_id,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* =============================== SELECTORS ================================ */
export const selectCapacity = (state: RootState) =>
  state.tasksAssignmentsResourcesCapacity.value;

export const capacityStatus = (state: RootState) =>
  state.tasksAssignmentsResourcesCapacity.status;

export const selectParticipantIds = (state: RootState) => {
  const participantsCapacity = state.tasksAssignmentsResourcesCapacity.value;
  const participantIds: string[] = [];
  for (const participantType of Object.keys(participantsCapacity)) {
    const participants = Object.keys(
      participantsCapacity[participantType as ParticipantTypes]
    );
    for (const participantId of participants) {
      if (!participantIds.includes(participantId)) {
        participantIds.push(participantId);
      }
    }
  }
  return participantIds;
};

export const selectPagination = (state: RootState) =>
  state.tasksAssignmentsResourcesCapacity.pagination;

export const selectParticipantToUpdate = (state: RootState) =>
  state.tasksAssignmentsResourcesCapacity.participantToUpdate;

export const selectParticipantIdToRemove = (state: RootState) =>
  state.tasksAssignmentsResourcesCapacity.participantIdToRemove;

/* ================================= REDUCER ================================ */
const tasksAssignmentsResourcesCapacitySlice = createSlice({
  name: 'tasksAssignmentsResourcesCapacity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCapacity.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchCapacity.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        state.value = action.payload.value;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchCapacity.rejected, (state) => {
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(addParticipant.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(addParticipant.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        const { participantType, capacity } = action.payload;
        state.value = {
          ...state.value,
          [participantType]: {
            ...state.value[participantType],
            ...capacity,
          },
        };
      })
      .addCase(addParticipant.rejected, (state) => {
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(updateParticipant.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(updateParticipant.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        const { participantType, capacity } = action.payload;
        state.value = {
          ...state.value,
          [participantType]: {
            ...state.value[participantType as ParticipantTypes],
            [capacity.user_id]: {
              ...state.value[participantType as ParticipantTypes][
                capacity.user_id
              ],
              data: {
                ...state.value[participantType as ParticipantTypes][
                  capacity.user_id
                ].data,
                job_roles: capacity.updateFields.job_roles || [],
              },
            },
          },
        };
      })
      .addCase(updateParticipant.rejected, (state) => {
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(removeParticipant.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(removeParticipant.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        const { participantType, user_id } = action.payload;
        const updatedState = { ...state.value };
        delete updatedState[participantType as ParticipantTypes][user_id];
        state.value = updatedState;
      })
      .addCase(removeParticipant.rejected, (state) => {
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(updatePaginationIndex, (state, action) => {
        state.pagination = {
          ...state.pagination,
          index: action.payload,
        };
      })
      .addCase(setParticipantToUpdate, (state, action) => {
        state.participantToUpdate = action.payload;
      })
      .addCase(setParticipantIdToRemove, (state, action) => {
        state.participantIdToRemove = action.payload;
      })
      .addCase(resetCapacityTable, (state) => {
        state.value = {} as ParticipantsCapacityByType;
        state.pagination = {
          from: null,
          to: null,
          total: 0,
          index: 0,
        };
        state.participantToUpdate = null;
        state.participantIdToRemove = null;
      });
  },
});

export default tasksAssignmentsResourcesCapacitySlice.reducer;
