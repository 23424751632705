import intl from 'react-intl-universal';
import { useState } from 'react';
import { Button, Typography } from '@getsynapse/design-system';
import { STRATEGY_CATEGORIES } from 'utils/constants/program';
import CategoryEmptyState from 'Pages/StrategyCategoryPage/components/CategoryEmptyState';
import AddROIObjectiveModal from './AddROIObjectiveModal/AddROIObjectiveModal';
import ROIObjectiveCard from './ROIObjectiveCard';
import { ROICategoryType } from 'utils/types/program';

const ObjectiveSetting = ({ roiData }: { roiData: ROICategoryType }) => {
  const [isAddObjectiveModalOpen, setIsAddObjectiveModalOpen] = useState(false);

  const hasROIObjective = roiData.roi.id !== undefined;

  return (
    <>
      <AddROIObjectiveModal
        isOpen={isAddObjectiveModalOpen}
        closeModal={() => setIsAddObjectiveModalOpen(false)}
        prompts={roiData.prompts}
      />
      <Typography variant='h5' className='pb-1'>
        {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.OBJECTIVES_SETTING')}
      </Typography>
      <div className='flex justify-between'>
        <Typography className='text-neutral-darker' variant='body2'>
          {roiData.explanation ||
            intl.get(
              `PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.CATEGORY_INSTRUCTIONS.${STRATEGY_CATEGORIES.ROI.toLocaleUpperCase()}`
            )}
        </Typography>
        {!hasROIObjective && (
          <Button
            data-testid='add-objective_button'
            onClick={() => setIsAddObjectiveModalOpen(true)}
          >
            {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.ADD_OBJECTIVE')}
          </Button>
        )}
      </div>
      {hasROIObjective && (
        <ROIObjectiveCard
          roi={roiData.roi.roi!}
          objectiveId={roiData.roi.id!}
        />
      )}
      {!hasROIObjective && (
        <CategoryEmptyState
          setIsOpen={setIsAddObjectiveModalOpen}
          caption={intl.get(
            'ROI_CATEGORY.OBJECTIVES_SETTING.EMPTY_STATE_CAPTION'
          )}
        />
      )}
    </>
  );
};

export default ObjectiveSetting;
