import { Owner } from 'utils/customTypes';
import UserAvatar from 'Atoms/UserAvatar';
import { PROGRAMS_STATUS } from 'utils/constants';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import { AvatarGroup, tailwindOverride, Tag } from '@getsynapse/design-system';
import { isUserStatusDisabled } from 'utils/functions';

export const getOwnersCell = (owners: Owner[]) => {
  if (!owners) {
    return '';
  }
  if (owners.length === 1) {
    return (
      <div className='flex items-center'>
        <UserAvatar
          user={{
            avatar_url: owners[0].avatar_url,
            data: {
              firstName: get(owners[0], 'data.firstName'),
              lastName: get(owners[0], 'data.lastName'),
            },
            status: owners[0].status,
          }}
          className='mr-2.5'
        />
        {`${get(owners, '0.data.firstName')} ${get(owners, '0.data.lastName')}`}
      </div>
    );
  } else {
    return (
      <AvatarGroup
        avatars={owners.map((owner) => {
          const firstName = get(owner, 'data.firstName');
          const lastName = get(owner, 'data.lastName');
          return {
            size: 'medium',
            imageSrc: owner.avatar_url,
            initial: get(firstName, '[0]'),
            name: `${firstName} ${lastName}`,
            disabled: isUserStatusDisabled(owner.status),
            deactivatedText: intl.get('DEACTIVATED'),
          };
        })}
      />
    );
  }
};

export const getProgramStatusPill = (status: string) => {
  const statusMapping = {
    [PROGRAMS_STATUS.NOT_STARTED]: {
      bgColor: 'bg-purple-lightest',
      textColor: 'text-purple-darker',
    },
    [PROGRAMS_STATUS.IN_PROGRESS]: {
      bgColor: 'bg-teal-lightest',
      textColor: 'text-teal-dark',
    },
    [PROGRAMS_STATUS.CLOSED]: {
      bgColor: 'bg-warning-lightest',
      textColor: 'text-warning-darker',
    },
  };

  const colorAndLabel = statusMapping[status];

  return (
    <Tag
      className={tailwindOverride(
        `text-xs border border-transparent py-0.5 ${get(
          colorAndLabel,
          'bgColor',
          ''
        )}`
      )}
      textClassName={get(colorAndLabel, 'textColor', '')}
      label={intl
        .get(`PROGRAMS_LIST_PAGE.STATUS.${status.toUpperCase()}`)
        .defaultMessage('')}
    />
  );
};
