import intl from 'react-intl-universal';
import { USER_TYPES } from 'utils/constants';
export const getFile = () => {
  return intl.get('USERS_PAGE.TABLE.USER_TYPE.LD');
};

export const getUserTypeOptionsForDropdown = (
  facilitatorManagementEnabled: boolean
) => {
  const baseTypes = [
    {
      value: USER_TYPES.L_D,
      label: intl.get('USERS_PAGE.TABLE.USER_TYPE.LD') || 'Learning Team User',
    },
    {
      value: USER_TYPES.FACILITATOR,
      label:
        intl.get('USERS_PAGE.TABLE.USER_TYPE.FACILITATOR') ||
        'Facilitator User',
    },
    {
      value: USER_TYPES.BUSINESS,
      label: intl.get('USERS_PAGE.TABLE.USER_TYPE.BUSINESS') || 'Business User',
    },
    {
      value: USER_TYPES.EXTERNAL,
      label: intl.get('USERS_PAGE.TABLE.USER_TYPE.EXTERNAL') || 'External User',
    },
  ];

  return facilitatorManagementEnabled
    ? baseTypes
    : baseTypes.filter((type) => type.value !== USER_TYPES.FACILITATOR);
};
