import { Auth } from 'aws-amplify';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUser,
  selectUser,
  selectUserSliceStatus,
} from 'state/User/userSlice';
import { getConnectionId } from 'state/Organization/organizationSlice';
import { getUserSettings } from 'state/UserSetting/userSettingsSlice';
import { PATHS, USER_STATUS } from 'utils/constants';

const useAuthentication = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const dispatch = useDispatch();
  const userSliceStatus = useSelector(selectUserSliceStatus);
  const user = useSelector(selectUser);
  const history = useHistory();

  useEffect(() => {
    const initSession = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (get(user, 'attributes.email')) {
          dispatch(getUser(user.attributes.email));
          dispatch(getUserSettings());
        }
      } catch (error) {
        const redirectUrl = !window.location.hash
          ? window.location.pathname
          : window.location.hash;
        history.push(
          `${PATHS.LOGIN}?redirect=${redirectUrl}${window.location.search}`
        );
      }
    };

    initSession();
  }, [dispatch, history]);

  const checkIfShouldCompleteRegistration = useCallback(async () => {
    const organizationId = get(user, 'organization_id');
    if (organizationId) {
      const response = await dispatch(getConnectionId(organizationId));
      const connection = get(response, 'payload') || '';
      if (!connection && !user.firstName) {
        history.push(
          `${PATHS.COMPLETE_REGISTRATION}?redirect=${window.location.pathname}${window.location.search}`
        );
      } else {
        setIsAuthenticated(true);
      }
    }
  }, [dispatch, history, user]);

  useEffect(() => {
    if (userSliceStatus === 'idle' && !isEmpty(user)) {
      if (
        user.status === USER_STATUS.INVITED_DISABLED ||
        user.status === USER_STATUS.REGISTERED_DISABLED
      ) {
        history.push(PATHS.DEACTIVATED_ACCOUNT);
      } else {
        checkIfShouldCompleteRegistration();
      }
    } else if (userSliceStatus === 'failed') {
      history.push(
        `${PATHS.LOGIN}?redirect=${window.location.pathname}${window.location.search}`
      );
    }
  }, [userSliceStatus, user, history, checkIfShouldCompleteRegistration]);

  return isAuthenticated;
};

export default useAuthentication;
