import { api } from './api';
import axios from 'axios';

export interface SearchProgramParams {
  name?: string;
  programIds?: string[];
}

export interface AvailableProgram {
  id: string;
  name: string;
  displayId: string;
}

export interface FetchAvailableProgramResponse {
  availablePrograms: AvailableProgram[];
}

export const searchPrograms = async ({
  name,
  programIds,
}: SearchProgramParams) => {
  let url = `/v2/available-programs/`;
  const urlParams = new URLSearchParams();
  if (name) {
    urlParams.append('name', name);
  }

  if (programIds) {
    programIds.forEach((id) => urlParams.append('programIds[]', id));
  }

  url = `${url}?${urlParams.toString()}`;
  const response = await api.get<FetchAvailableProgramResponse>(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};
