import { Link, useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import ROIObjectiveTag from './ROIObjectiveTag';
import { PATHS } from 'utils/constants';

type Props = {
  roi: number | null;
  objectiveId: string;
};

const ROIObjectiveCard = ({ roi, objectiveId }: Props) => {
  const { categoryId, programId } = useParams<{
    categoryId: string;
    programId: string;
  }>();

  return (
    <Link
      to={`${PATHS.PROGRAM_PAGE}/${programId}${PATHS.ROI_CATEGORY_PAGE}/${categoryId}${PATHS.ROI_OBJECTIVE_PAGE}/${objectiveId}`}
      className='group mt-6 py-5 px-6 border rounded border-primary-lighter-two h-31 block transition duration-100 ease-linear shadow-header hover:border-secondary-light hover:shadow-lifted'
    >
      <Typography weight='medium' className='mb-0.5'>
        {intl.get('ROI_CATEGORY.OBJECTIVES_SETTING.OBJECTIVE_CARD.TITLE')}
      </Typography>
      <Typography variant='body2'>
        {intl.get('ROI_CATEGORY.OBJECTIVES_SETTING.OBJECTIVE_CARD.BODY', {
          roi,
        })}
      </Typography>
      <div className='flex mt-3 justify-between'>
        <ROIObjectiveTag />
        <Typography
          variant='body2'
          title={intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.MANAGE_OBJECTIVE'
          )}
          className='text-purple-dark group-hover:text-purple-darker font-medium'
        >
          {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.MANAGE_OBJECTIVE')}
        </Typography>
      </div>
    </Link>
  );
};

export default ROIObjectiveCard;
