import { TableOperationHeader } from '@getsynapse/design-system';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';

const EventsHeader = () => {
  return (
    <TableOperationHeader
      className='border-b-0'
      actionButtons={<FilterButton data-testid='events-filter_button' />}
    />
  );
};

export default EventsHeader;
