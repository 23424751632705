import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  selectOrderedDueTasksForModal,
  setDueTasksOrderForModal,
} from 'state/MyWorkspace/myWorkspaceSlice';
import TasksTable from './TasksTable';
import MyWorspaceModal from '../MyWorkspaceModal';

type TasksModalProps = {
  icon: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const TasksModal = ({ icon, isOpen, setIsOpen }: TasksModalProps) => {
  const dispatch = useDispatch();
  const tasksSelector = useSelector(selectOrderedDueTasksForModal);

  const handleSort = (orderByParam: string[], order: 'desc' | 'asc') => {
    dispatch(setDueTasksOrderForModal({ order, orderBy: orderByParam }));
  };

  return (
    <MyWorspaceModal
      title={intl.get('MY_WORKSPACE.DUE_TASKS.MODAL_TITLE')}
      icon={icon}
      content={
        <TasksTable
          data={tasksSelector}
          handleSort={handleSort}
          cardTable={false}
        />
      }
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dataCy='due-tasks-modal'
    />
  );
};

export default TasksModal;
