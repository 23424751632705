import intl from 'react-intl-universal';
import classNames from 'classnames';
import {
  Button,
  Icon,
  List,
  Popup,
  tailwindOverride,
  Tooltip,
  Typography,
} from '@getsynapse/design-system';
import { MoreActionsOption } from 'utils/customTypes';

interface ContentProps {
  option: MoreActionsOption;
}

interface SingleOptionProps extends ContentProps {
  selectOption: () => void;
}

interface OptionProps extends SingleOptionProps {
  close: () => void;
}

interface ActionProps extends SingleOptionProps {
  close?: () => void;
  isSingle?: boolean;
}

type MoreActionsProps = {
  options: MoreActionsOption[];
  onSelectOption?: (option: MoreActionsOption) => void;
};

const Content: React.FC<ContentProps> = ({ option }) => {
  return (
    <>
      <Icon
        className={tailwindOverride(
          'mr-2 text-base',
          { 'group-hover:text-primary': !option.disabled },
          classNames(option.iconClassName, {
            'text-neutral-light group-hover:text-neutral-light':
              option.disabled,
          })
        )}
        name={option.iconName}
        src={option.iconSrc}
      />
      <Typography
        className={tailwindOverride(
          { 'text-neutral-light': option.disabled },
          {
            'group-hover:text-primary': !option.disabled,
          }
        )}
        variant='body'
      >
        {option.label}
      </Typography>
    </>
  );
};

const Option: React.FC<OptionProps> = ({ close, option, selectOption }) => {
  return (
    <li
      onClick={() => {
        if (option.disabled !== true) {
          selectOption();
          close();
        }
      }}
      className={tailwindOverride(
        'group py-2 px-6 flex justify-start items-center cursor-pointer',
        { 'hover:bg-primary-lighter': !option.disabled },
        {
          'text-neutral-light cursor-not-allowed': option.disabled,
        }
      )}
      data-cy={option.dataCy}
    >
      <Content option={option} />
    </li>
  );
};

const SingleOption: React.FC<SingleOptionProps> = ({
  option,
  selectOption = () => {},
}) => {
  return (
    <Button
      data-cy={option.dataCy}
      disabled={option.disabled}
      onClick={selectOption}
      size='small'
      variant='tertiary'
    >
      <Content option={option} />
    </Button>
  );
};

const Action: React.FC<ActionProps> = ({
  isSingle = false,
  option,
  close = () => {},
  selectOption,
}) => {
  return option.tooltip?.text ? (
    <Tooltip
      position={
        option.tooltip?.position ? option.tooltip?.position : 'bottomLeft'
      }
      contentProps={{
        className: 'z-10',
        'data-cy': `more-actions-tooltip-${option.value}`,
      }}
      openMode='hover2'
      timeout={0}
      trigger={
        <div>
          {isSingle ? (
            <SingleOption option={option} selectOption={selectOption} />
          ) : (
            <Option close={close} option={option} selectOption={selectOption} />
          )}
        </div>
      }
    >
      {option.tooltip?.text}
    </Tooltip>
  ) : isSingle ? (
    <SingleOption option={option} selectOption={selectOption} />
  ) : (
    <Option close={close} option={option} selectOption={selectOption} />
  );
};

function MoreActions(props: MoreActionsProps) {
  const { options, onSelectOption = () => {} } = props;

  if (options.length === 1) {
    return (
      <Action
        isSingle
        option={options[0]}
        selectOption={() => onSelectOption(options[0])}
      />
    );
  }

  return (
    <Popup
      position='bottom-end'
      popperProps={{ className: 'z-5' }}
      trigger={({ isOpen }) => (
        <Button
          variant='tertiary'
          data-cy='more-actions-button'
          className={classNames({ 'bg-neutral-lightest': isOpen })}
        >
          {intl.get('MORE_ACTIONS')}
          <Icon
            name={isOpen ? 'caret-up' : 'caret-down'}
            className='text-xs pl-2'
          />
        </Button>
      )}
    >
      {({ close }) => (
        <List
          options={options}
          data-cy='more-actions-list'
          renderOption={(
            option: MoreActionsOption,
            isSelected,
            selectOption: () => void
          ) => (
            <Action close={close} option={option} selectOption={selectOption} />
          )}
          onSelectOption={onSelectOption}
        />
      )}
    </Popup>
  );
}

export default MoreActions;
