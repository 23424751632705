import { ComponentProps, useMemo } from 'react';
import intl from 'react-intl-universal';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import { Dropdown, FormItem } from '@getsynapse/design-system';
import { SortingType } from 'utils/customTypes';
import { getInitialValueForDropDown } from 'utils/functions';

type Props = {
  formItemProps: ComponentProps<typeof FormItem>;
  sortOrder?: SortingType;
  dropdownProps: Partial<ComponentProps<typeof Dropdown>>;
  currentValue: string;
  options: readonly string[];
  labelRoute: string;
};

const TypePicker = ({
  formItemProps,
  dropdownProps,
  currentValue,
  options,
  labelRoute,
  sortOrder = 'asc',
}: Props) => {
  const formatOptions = useMemo(() => {
    const requestTypes = options.map((type) => ({
      value: type,
      label: intl.get(`${labelRoute}.${type}`),
    }));

    return orderBy(
      requestTypes,
      (type) => {
        const label = get(type, 'label') || '';
        return label.toLocaleLowerCase();
      },
      sortOrder
    );
  }, [labelRoute, options, sortOrder]);

  const { onChange = () => {}, ...otherDropdownProps } = dropdownProps;
  return (
    <FormItem {...formItemProps}>
      <Dropdown
        onChange={onChange}
        options={formatOptions}
        values={getInitialValueForDropDown(formatOptions, currentValue)}
        {...otherDropdownProps}
      />
    </FormItem>
  );
};

export default TypePicker;
