import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import OrganizationAPI from './organizationAPI';
import AccountsAPI from 'Services/accountsAPI';
import { Organization, OrganizationSettings } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
/* ============================= INITIAL STATE ============================== */
const initialState: Organization = {
  organizationData: {
    businessTeams: [],
    learningTeams: [],
    projectCategories: [],
    projectVendors: [],
    users: [],
    license_tier: '',
    license_number: 0,
    id: '',
    settings: {},
    company_name: '',
    trial_end_date: '',
    hasConnectionId: false,
  },
  status: SLICE_STATUS.IDLE,
};

/* ============================== REDUX THUNK =============================== */
export const getOrganization = createAsyncThunk(
  'organization/GET_ORGANIZATION',
  async (organizationId: string) => {
    const response = await OrganizationAPI.fetchOrganization(organizationId);
    return response;
  }
);

export const updateOrganizationSettings = createAsyncThunk(
  'organization/UPDATE_ORGANIZATION',
  async (settings: OrganizationSettings, { getState }) => {
    const state = getState() as RootState;
    const organizationId = state.organization.organizationData.id;
    const response = await OrganizationAPI.updateOrganization(organizationId, {
      settings,
    });
    return response;
  }
);

export const createOrganization = createAsyncThunk(
  'organization/CREATE_ORGANIZATION',
  async ({ userEmail, company }: { userEmail: string; company: string }) => {
    const response = await OrganizationAPI.createOrganization(
      userEmail,
      company
    );
    if (response.code !== 201) {
      throw new Error('Creation of organization failed');
    }
    return response.data.organization;
  }
);

export const getConnectionId = createAsyncThunk(
  'organization/GET_CONNECTION_ID',
  async (accountId: string) => {
    const connectionId = await AccountsAPI.getEnterpriseConnection(accountId);
    return connectionId;
  }
);

/* ================================= REDUCER ================================ */
const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrganization.fulfilled, (state, action) => {
        state.organizationData = {
          ...state.organizationData,
          ...action.payload,
        };
      })
      .addCase(updateOrganizationSettings.pending, (state) => {
        state.status = SLICE_STATUS.UPDATING;
      })
      .addCase(updateOrganizationSettings.fulfilled, (state, action) => {
        state.organizationData.settings = action.payload.organization.settings;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(updateOrganizationSettings.rejected, (state) => {
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.organizationData = action.payload;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(getConnectionId.fulfilled, (state, action) => {
        state.organizationData.hasConnectionId = !isEmpty(action.payload);
        state.status = SLICE_STATUS.IDLE;
      });
  },
});

/* ================================ ACTIONS ================================= */

/* =============================== SELECTORS ================================ */
export const selectOrganization = (state: RootState) =>
  state.organization.organizationData;
export const selectBussinessTeams = (state: RootState) =>
  state.organization.organizationData.businessTeams;
export const selectProjectCategories = (state: RootState) =>
  state.organization.organizationData.projectCategories;
export const selectOrganizationLicense = (state: RootState) => ({
  license_tier: state.organization.organizationData.license_tier,
  license_number: state.organization.organizationData.license_number,
});
export const selectHasConnectionId = (state: RootState) =>
  state.organization.organizationData.hasConnectionId;

export const SelectOrganisationId = (state: RootState) =>
  state.organization.organizationData.id;

export const selectOrganizationSettings = (state: RootState) =>
  state.organization.organizationData.settings;

export const selectOrganizationCurrency = (state: RootState) =>
  get(
    state.organization.organizationData.settings,
    'platformCurrency',
    intl.get('CURRENCY')
  );

export const selectRequestsPortalName = (state: RootState) =>
  get(
    state.organization.organizationData.settings,
    'intakePortalName',
    intl.get('REQUEST_PORTAL_DEFAULT_NAME')
  );

export const SelectComapnyName = (state: RootState) =>
  state.organization.organizationData.company_name;

export const selectOrganizationSliceStatus = (state: RootState) =>
  state.organization.status;

export const selectTrialEndDate = (state: RootState) =>
  state.organization.organizationData.trial_end_date;

export default organizationSlice.reducer;
