import {
  Project,
  ProjectParticipant,
  ProjectCollaborator,
  AllUsersType,
  LDTeam,
  LDUser,
} from 'utils/customTypes';
import {
  PROJECT_PARTICIPANT_TYPE,
  USER_TYPES,
  USER_ROLES,
  PROJECT_PRIVACY,
  PROJECT_USER_ACTIONS,
  PROJECT_PERMISSIONS_BY_LEVEL,
  PROJECT_STATUS,
} from 'utils/constants';
import type { LearningTeam } from 'utils/types/learningTeam';

export const getUserParticipantType = (
  project: Project,
  currentUserId: string | undefined
) => {
  if (!currentUserId) {
    return PROJECT_PARTICIPANT_TYPE.NOT_PARTICIPANT;
  }
  const owners = project.owners.map(
    (owner) => owner.project_owners.userId ?? ''
  );

  const members =
    project.participants.map(
      (participant: ProjectParticipant) =>
        participant.project_participants?.userId ?? ''
    ) || [];

  const collaborators =
    project.collaborators.map(
      (collaborator: ProjectCollaborator) =>
        collaborator.project_collaborators?.userId ?? ''
    ) || [];

  const isCurrentUserOwner = owners.find(
    (ownerId: string) => ownerId === currentUserId
  );
  if (isCurrentUserOwner) {
    return PROJECT_PARTICIPANT_TYPE.OWNER;
  }

  const isCurrentUserMember = members.find(
    (memberId: string) => memberId === currentUserId
  );
  if (isCurrentUserMember) {
    return PROJECT_PARTICIPANT_TYPE.MEMBER;
  }

  const isCurrentUserCollaborator = collaborators.find(
    (collaboratorId: string) => collaboratorId === currentUserId
  );
  if (isCurrentUserCollaborator) {
    return PROJECT_PARTICIPANT_TYPE.COLLABORATOR;
  }

  return PROJECT_PARTICIPANT_TYPE.NOT_PARTICIPANT;
};

const getSubTeamIds = (ldTeams: LearningTeam[], ldTeamId?: string) => {
  if (!ldTeamId) {
    return [];
  }
  return ldTeams
    .filter((team: LearningTeam) => {
      return team.parent_id === ldTeamId;
    })
    .map((team: LearningTeam) => team.id);
};

const checkSubTeamsAccess = (
  ldTeams: LearningTeam[],
  userLearningTeamsForProject: string[],
  baseTeamIds: string[]
) => {
  const exploredLearningTeamIds = new Set();
  while (baseTeamIds.length > 0) {
    const learningTeamId = baseTeamIds.shift();

    exploredLearningTeamIds.add(learningTeamId);

    if (
      learningTeamId &&
      userLearningTeamsForProject.includes(learningTeamId)
    ) {
      return true;
    }

    const subTeamIds = getSubTeamIds(ldTeams, learningTeamId);

    for (const childTeam of subTeamIds) {
      if (!exploredLearningTeamIds.has(childTeam)) {
        baseTeamIds.push(childTeam);
      }
    }
  }
  return false;
};

const getUserPermissionTypeByProject = (
  userRole: typeof USER_ROLES[keyof typeof USER_ROLES],
  projectParticipantType: typeof PROJECT_PARTICIPANT_TYPE[keyof typeof PROJECT_PARTICIPANT_TYPE],
  userManagesAllowedTeam: boolean,
  userType?: typeof USER_TYPES[keyof typeof USER_TYPES]
) => {
  if (userType === USER_TYPES.L_D) {
    if (userRole !== USER_ROLES.ADMIN) {
      if (userManagesAllowedTeam) {
        return PROJECT_PARTICIPANT_TYPE.OWNER;
      }
    }

    return PROJECT_PARTICIPANT_TYPE.OWNER;
  }

  return projectParticipantType;
};

const getUserAccessByProject = (
  userRole: typeof USER_ROLES[keyof typeof USER_ROLES],
  projectPrivacyMode: typeof PROJECT_PRIVACY[keyof typeof PROJECT_PRIVACY],
  isUserProjectParticipant: boolean,
  userBelongsToAllowedTeam: boolean,
  userManagesAllowedTeam: boolean,
  userRegisteredInAllowedOrParentTeam: boolean,
  userType?: typeof USER_TYPES[keyof typeof USER_TYPES]
) => {
  if (
    (userType === USER_TYPES.BUSINESS || userType === USER_TYPES.EXTERNAL) &&
    !isUserProjectParticipant
  ) {
    return false;
  }

  if (userType === USER_TYPES.L_D && userRole !== USER_ROLES.ADMIN) {
    switch (projectPrivacyMode) {
      case PROJECT_PRIVACY.PRIVATE:
        return isUserProjectParticipant;

      case PROJECT_PRIVACY.TEAM:
        return (
          userBelongsToAllowedTeam ||
          isUserProjectParticipant ||
          userManagesAllowedTeam ||
          userRegisteredInAllowedOrParentTeam
        );

      case PROJECT_PRIVACY.PUBLIC:
        return true;
    }
  }

  return true;
};

export const getUserAccessOnProject = (
  projectData: Project,
  userData: AllUsersType | undefined,
  ldTeams: LearningTeam[]
) => {
  const userType = userData?.type;
  const userRole = userData?.role ?? USER_ROLES.USER;
  const currentUserId = userData?.id;

  const projectPrivacyMode = projectData.privacy;

  let teamsManaged: LearningTeam[] = [];
  let userBelongsToAllowedTeam: boolean = false;
  let userManagesAllowedTeam: boolean = false;
  let userRegisteredInAllowedOrParentTeam: boolean = false;

  const projectParticipantType = getUserParticipantType(
    projectData,
    currentUserId
  );

  const isUserProjectParticipant =
    projectParticipantType !== PROJECT_PARTICIPANT_TYPE.NOT_PARTICIPANT;

  if (
    userType === USER_TYPES.L_D &&
    userRole !== USER_ROLES.ADMIN &&
    projectPrivacyMode === PROJECT_PRIVACY.TEAM
  ) {
    const userLearningTeamsForProject =
      projectData.ldteams?.map((team: LDTeam) => team.id) ?? [];

    const userLearningTeams =
      (userData as LDUser)?.registeredLearningTeams ?? [];

    teamsManaged = (userData as LDUser)?.teamsManaged ?? [];

    userBelongsToAllowedTeam = userLearningTeams.some((team: LDTeam) =>
      userLearningTeamsForProject.includes(team.id)
    );

    const userRegisteredTeamIds = userLearningTeams.map(
      (team: LearningTeam) => team.id
    );

    userRegisteredInAllowedOrParentTeam = checkSubTeamsAccess(
      ldTeams,
      userLearningTeamsForProject,
      userRegisteredTeamIds
    );

    const userManagedTeamIds = teamsManaged.map(
      (team: LearningTeam) => team.id
    );

    userManagesAllowedTeam = checkSubTeamsAccess(
      ldTeams,
      userLearningTeamsForProject,
      userManagedTeamIds
    );
  }

  const permissionLevel = getUserPermissionTypeByProject(
    userRole,
    projectParticipantType,
    userManagesAllowedTeam,
    userType
  );

  const hasAccess = getUserAccessByProject(
    userRole,
    projectPrivacyMode,
    isUserProjectParticipant,
    userBelongsToAllowedTeam,
    userManagesAllowedTeam,
    userRegisteredInAllowedOrParentTeam,
    userType
  );

  const availableActions = PROJECT_PERMISSIONS_BY_LEVEL[permissionLevel];

  let canUpdateProject = false;
  if (availableActions.length > 0) {
    canUpdateProject = availableActions.includes(
      PROJECT_USER_ACTIONS.UPDATE_PROJECT
    );
  }

  const isReadOnly =
    [PROJECT_STATUS.CLOSED, PROJECT_STATUS.CANCELED].includes(
      projectData.status
    ) || !canUpdateProject;

  return { hasAccess, isReadOnly, permissionLevel };
};
