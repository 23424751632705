import {
  Button,
  Checkbox,
  Dropdown,
  FormItem,
  IconButton,
  Typography,
  tailwindOverride,
} from '@getsynapse/design-system';
import moment from 'moment';
import { useMemo, useState, Dispatch, SetStateAction } from 'react';
import intl from 'react-intl-universal';
import { setActiveFilters } from 'state/Insights/InsightsSlice';
import {
  INSIGHT_REQUEST_FILTER_PANEL_DATE_OPTIONS as DATE_OPTIONS,
  SLICE_STATUS,
  INSIGHTS_DATE_FILTER_NAME,
} from 'utils/constants';
import FilterTags from 'Pages/InsightsPage/Intake/FiltersPanel/components/FilterTags';
import filterPanelIcon from 'assets/icons/filter-icon-insight.svg';
import collapseIcon from 'assets/icons/collapse.svg';
import expandIcon from 'assets/icons/expand.svg';
import classnames from 'classnames';
import get from 'lodash/get';
import { orderBy } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  BusinessUnitFilterData,
  FormFilterData,
  OwnerFilterData,
  InsightsIntakeActiveFilter,
} from 'utils/customTypes';
import { FiltersPanelButtonsSkeletonLoader } from '../FiltersPanelSkeletonLoader';

type Option = {
  label: string;
  value: string;
};
const FilterSections = ({
  status,
  setIsExpanded,
  isExpanded,
  filters,
}: {
  status: string | undefined;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
  isExpanded: boolean;
  filters: any;
}) => {
  const requestOwnersList = filters.ownerFilterValues.filter(
    (owner: OwnerFilterData) => Boolean(owner.ownerName)
  );

  const requestBusinessUnits = filters.businessUnitFilterValues.filter(
    (businessUnit: BusinessUnitFilterData) => {
      return Boolean(businessUnit.businessUnitName);
    }
  );

  const requestForms = useMemo(
    () =>
      filters.formFilterValues.filter((form: FormFilterData) => {
        return Boolean(form.formTitle);
      }),
    [filters.formFilterValues]
  );

  const dispatch = useDispatch();
  const labelRoute = 'REQUEST_PAGE.LEFT_PANEL.REQUEST_TYPE';
  const sortOrder = 'asc';

  const requestTypesList: Option[] = useMemo(() => {
    const requestTypes = filters.typeFilterValues.map((type: any) => ({
      value: type.requestType,
      label: intl.get(`${labelRoute}.${type.requestType}`) || type.requestType,
    }));

    return orderBy(
      requestTypes,
      (type) => {
        return type.label.toLocaleLowerCase();
      },
      sortOrder
    );
  }, [labelRoute, filters.typeFilterValues, sortOrder]);

  const [isOwnerSectionOpen, setIsOwnerSectionOpen] = useState(true);
  const [isBusinessUnitSectionOpen, setIsBusinessUnitSectionOpen] =
    useState(true);
  const [isFormSectionOpen, setIsFormSectionOpen] = useState(true);
  const [isRequestTypeSectionOpen, setIsRequestTypeSectionOpen] =
    useState(true);

  const [requestOwners, setRequestOwners] =
    useState<OwnerFilterData[]>(requestOwnersList);
  const [businessUnits, setBusinessUnits] =
    useState<BusinessUnitFilterData[]>(requestBusinessUnits);
  const [forms, setForms] = useState<FormFilterData[]>(requestForms);
  const [requestTypes, setRequestTypes] = useState<Option[]>(
    requestTypesList.map((elem) => ({
      value: elem.value,
      label: elem.label,
    }))
  );

  const dateOptions = useMemo(
    () =>
      Object.values(DATE_OPTIONS).map((option) => ({
        value: option.key,
        label: intl.get(`INSIGHTS_PAGE_TABS.FILTER.DATE.${option.key}`),
      })),
    []
  );

  const defaultDateOption = useMemo(() => {
    const savedDateSelection = window.localStorage.getItem(
      INSIGHTS_DATE_FILTER_NAME.INTAKE
    );
    const fallBackOption = DATE_OPTIONS.ALL;
    const defaultDate = get(
      DATE_OPTIONS,
      savedDateSelection ? savedDateSelection : fallBackOption.key,
      fallBackOption
    );

    return {
      label: intl.get(`INSIGHTS_PAGE_TABS.FILTER.DATE.${defaultDate.key}`),
      value: defaultDate.value,
    };
  }, []);

  const [startFromDateOption, setStartFromDateOption] =
    useState<Option>(defaultDateOption);

  const selectedUsers = useMemo(
    () =>
      requestOwners
        .filter((user: any) => user.selected)
        .map((user: any) => ({
          value: `${user.ownerName}`,
          label: `${user.ownerName}`,
          id: user.ownerId,
        })),
    [requestOwners]
  );

  const selectedBusinessUnits = useMemo(
    () =>
      businessUnits
        .filter((businessUnit: any) => businessUnit.selected)
        .map((businessUnit) => ({
          value: businessUnit.businessUnitName,
          label: businessUnit.businessUnitName,
          id: businessUnit.businessUnitId,
        })),
    [businessUnits]
  );

  const selectedForms = useMemo(
    () =>
      forms
        .filter((form: any) => form.selected)
        .map((form) => ({
          value: form.formId,
          label: form.formTitle,
          id: form.formId,
        })),
    [forms]
  );

  const selectedRequestTypes = useMemo(
    () =>
      requestTypes
        .filter((type: any) => type.selected)
        .map((type) => ({
          value: type.value,
          label: type.label,
          id: type.value,
        })),
    [requestTypes]
  );

  const handleChangeUsers = (id: any, selected: any) => {
    setRequestOwners(
      requestOwners.map((owner: any) =>
        owner.ownerId === id ? { ...owner, selected } : owner
      )
    );
  };

  const handleChangeDateFilter = (selectedItem: Option) => {
    setStartFromDateOption(selectedItem);
  };

  const handleChangeBusinessUnits = (id: any, selected: any) => {
    setBusinessUnits(
      businessUnits.map((businessUnit: any) =>
        businessUnit.businessUnitId === id
          ? { ...businessUnit, selected: selected }
          : businessUnit
      )
    );
  };

  const handleChangeForms = (id: any, selected: any) => {
    setForms(
      forms.map((form: any) =>
        form.formId === id ? { ...form, selected: selected } : form
      )
    );
  };

  const handleChangeRequestType = (selectedType: any, selected: any) => {
    setRequestTypes(
      requestTypes.map((type: any) =>
        type.value === selectedType ? { ...type, selected: selected } : type
      )
    );
  };

  const resetFilters = () => {
    setRequestOwners(requestOwnersList);
    setBusinessUnits(requestBusinessUnits);
    setForms(requestForms);
    setRequestTypes(requestTypesList);
    setStartFromDateOption(defaultDateOption);
  };

  const initialFilters = {
    startDate: '',
    ownerIds: [],
    formIds: [],
    businessUnitIds: [],
    requestTypes: [],
  };

  const handleApplyFilters = () => {
    const appliedFilters = {} as InsightsIntakeActiveFilter;
    if (startFromDateOption.value) {
      window.localStorage.setItem(
        INSIGHTS_DATE_FILTER_NAME.INTAKE,
        startFromDateOption.value
      );
    }
    if (startFromDateOption.value && startFromDateOption.value !== 'ALL') {
      const dateValue = get(
        DATE_OPTIONS,
        `[${startFromDateOption.value}].numberOfDays`,
        ''
      );
      if (dateValue) {
        appliedFilters.startDate = moment()
          .subtract(dateValue, 'days')
          .format();
      } else {
        appliedFilters.startDate = '';
      }
    }
    if (selectedUsers.length) {
      appliedFilters.ownerIds = selectedUsers.map((user) => user.id);
    }
    if (selectedBusinessUnits.length) {
      appliedFilters.businessUnitIds = selectedBusinessUnits.map(
        (businessUnit) => businessUnit.id
      );
    }
    if (selectedRequestTypes.length) {
      appliedFilters.requestTypes = selectedRequestTypes.map(
        (type) => type.value
      );
    }
    if (selectedForms.length) {
      appliedFilters.formIds = selectedForms.map((form) => form.value);
    }
    dispatch(setActiveFilters({ ...initialFilters, ...appliedFilters }));
  };

  return (
    <div className='flex flex-col h-full'>
      <div className='flex justify-between py-4 px-5'>
        <Typography
          variant='h6'
          weight='medium'
          className={classnames(!isExpanded && 'hidden')}
        >
          {intl.get('INSIGHTS_PAGE_TABS.FILTER.TITLE')}
        </Typography>

        <div
          className={classnames(!isExpanded ? '-ml-2' : 'pr-12 flex space-x-4')}
        >
          <IconButton
            src={filterPanelIcon}
            className={classnames(!isExpanded ? 'hidden' : 'text-2xl')}
            onClick={() => resetFilters()}
          />

          {isExpanded ? (
            <IconButton
              className=''
              src={collapseIcon}
              onClick={() => setIsExpanded(false)}
            />
          ) : (
            <IconButton
              className=''
              src={expandIcon}
              onClick={() => setIsExpanded(true)}
            />
          )}
        </div>
      </div>

      <div
        className={classnames(
          !isExpanded ? 'hidden' : 'w-full overflow-y-auto',
          'flex-1'
        )}
      >
        <div className='px-5'>
          <FormItem
            label={intl.get('INSIGHTS_PAGE_TABS.FILTER.DATE_LABEL')}
            component='div'
            className='pt-2 pb-6'
          >
            <Dropdown
              options={dateOptions}
              values={[startFromDateOption]}
              onChange={handleChangeDateFilter}
              data-cy='insights_request-owner-filter'
            />
          </FormItem>

          <div className='pb-6'>
            <div className='flex justify-between items-center'>
              <Typography variant='body2' weight='medium'>
                {intl.get('INSIGHTS_PAGE_TABS.FILTER.OWNER')}
              </Typography>

              <IconButton
                name={
                  isOwnerSectionOpen
                    ? 'chevron-up-outline'
                    : 'chevron-down-outline'
                }
                className='text-md text-neutral-dark'
                onClick={() => setIsOwnerSectionOpen(!isOwnerSectionOpen)}
              />
            </div>
            <div className='max-h-32 overflow-y-auto'>
              {selectedUsers.length > 0 && (
                <div className='py-2'>
                  <FilterTags
                    selectedFilterCategoryValue={selectedUsers}
                    removeTag={handleChangeUsers}
                  />
                </div>
              )}
              {requestOwners.map((user: any) => (
                <FormItem
                  component='div'
                  className={tailwindOverride(
                    'transition-height duration-300 easy',
                    {
                      'h-auto overflow-visible': isOwnerSectionOpen,
                      'h-0 overflow-hidden': !isOwnerSectionOpen,
                    }
                  )}
                >
                  <Checkbox
                    key={user.ownerId}
                    label={`${user.ownerName}`}
                    className='pr-10 py-1'
                    onChange={(event) =>
                      handleChangeUsers(user.ownerId, event.target.checked)
                    }
                    checked={user.selected === true}
                  />
                </FormItem>
              ))}
            </div>
          </div>
          <div className='pb-6'>
            <div className='flex justify-between items-center'>
              <Typography variant='body2' weight='medium'>
                {intl.get('INSIGHTS_PAGE_TABS.FILTER.BUSINESS_UNIT_LABEL')}
              </Typography>

              <IconButton
                name={
                  isBusinessUnitSectionOpen
                    ? 'chevron-up-outline'
                    : 'chevron-down-outline'
                }
                className='text-md text-neutral-dark'
                onClick={() =>
                  setIsBusinessUnitSectionOpen(!isBusinessUnitSectionOpen)
                }
              />
            </div>
            <div className='max-h-32 overflow-y-auto'>
              {selectedBusinessUnits.length > 0 && (
                <div className='py-2'>
                  <FilterTags
                    selectedFilterCategoryValue={selectedBusinessUnits}
                    removeTag={handleChangeBusinessUnits}
                  />
                </div>
              )}
              {businessUnits.map((businessUnit: any) => (
                <FormItem
                  component='div'
                  className={tailwindOverride(
                    'transition-height duration-300 easy',
                    {
                      'h-auto overflow-visible': isBusinessUnitSectionOpen,
                      'h-0 overflow-hidden': !isBusinessUnitSectionOpen,
                    }
                  )}
                >
                  <Checkbox
                    key={businessUnit.businessUnitId}
                    label={businessUnit.businessUnitName}
                    className='pr-10 py-1'
                    onChange={(event) =>
                      handleChangeBusinessUnits(
                        businessUnit.businessUnitId,
                        event.target.checked
                      )
                    }
                    checked={businessUnit.selected === true}
                  />
                </FormItem>
              ))}
            </div>
          </div>
          <div className='pb-6'>
            <div className='flex justify-between items-center'>
              <Typography variant='body2' weight='medium'>
                {intl.get('INSIGHTS_PAGE_TABS.FILTER.REQUEST_TYPE_LABEL')}
              </Typography>

              <IconButton
                name={
                  isRequestTypeSectionOpen
                    ? 'chevron-up-outline'
                    : 'chevron-down-outline'
                }
                className='text-md text-neutral-dark'
                onClick={() =>
                  setIsRequestTypeSectionOpen(!isRequestTypeSectionOpen)
                }
              />
            </div>
            <div className='max-h-32 overflow-y-auto'>
              {selectedRequestTypes.length > 0 && (
                <div className='py-2'>
                  <FilterTags
                    selectedFilterCategoryValue={selectedRequestTypes}
                    removeTag={handleChangeRequestType}
                  />
                </div>
              )}
              {requestTypes.map((type: any, index: number) => (
                <FormItem
                  component='div'
                  className={tailwindOverride(
                    'transition-height duration-300 easy',
                    {
                      'h-auto overflow-visible': isRequestTypeSectionOpen,
                      'h-0 overflow-hidden': !isRequestTypeSectionOpen,
                    }
                  )}
                >
                  <Checkbox
                    key={`${index}-${type.label}`}
                    label={type.label}
                    className='pr-10 py-1'
                    onChange={(event) =>
                      handleChangeRequestType(type.value, event.target.checked)
                    }
                    checked={type.selected === true}
                  />
                </FormItem>
              ))}
            </div>
          </div>
          <div className='pb-6'>
            <div className='flex justify-between items-center'>
              <Typography variant='body2' weight='medium'>
                {intl.get('INSIGHTS_PAGE_TABS.FILTER.FORM_LABEL')}
              </Typography>
              <IconButton
                name={
                  isFormSectionOpen
                    ? 'chevron-up-outline'
                    : 'chevron-down-outline'
                }
                className='text-md text-neutral-dark'
                onClick={() => setIsFormSectionOpen(!isFormSectionOpen)}
              />
            </div>
            <div className='max-h-32 overflow-y-auto'>
              {selectedForms.length > 0 && (
                <div className='py-2'>
                  <FilterTags
                    selectedFilterCategoryValue={selectedForms}
                    removeTag={handleChangeForms}
                  />
                </div>
              )}
              {forms.map((form: any) => (
                <FormItem
                  component='div'
                  className={tailwindOverride(
                    'transition-height duration-300 easy',
                    {
                      'h-auto overflow-visible': isFormSectionOpen,
                      'h-0 overflow-hidden': !isFormSectionOpen,
                    }
                  )}
                >
                  <Checkbox
                    key={form.formId}
                    label={form.formTitle}
                    className='pr-10 py-1'
                    onChange={(event) =>
                      handleChangeForms(form.formId, event.target.checked)
                    }
                    checked={form.selected === true}
                  />
                </FormItem>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className={classnames(
          !isExpanded
            ? 'hidden'
            : 'w-full mt-auto border-t border-primary-lighter-two flex py-2 z-5'
        )}
      >
        {status === SLICE_STATUS.LOADING ? (
          <FiltersPanelButtonsSkeletonLoader />
        ) : (
          <div className='flex mt-auto ml-auto mr-5'>
            <Button
              variant='tertiary'
              className='mr-4'
              onClick={() => resetFilters()}
              data-cy='insight-intake-reset-button'
            >
              {intl.get('INSIGHTS_PAGE_TABS.FILTER.RESET')}
            </Button>
            <Button
              onClick={() => handleApplyFilters()}
              data-cy='insight-intake-apply-button'
            >
              {intl.get('INSIGHTS_PAGE_TABS.FILTER.APPLY')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterSections;
