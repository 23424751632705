import {
  useCallback,
  useMemo,
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from 'react';
import ReactDOM from 'react-dom';
import { store } from 'state/store';
import { useSelector, useDispatch } from 'react-redux';
import {
  ScheduleComponent,
  Inject,
  ResourcesDirective,
  ResourceDirective,
  ViewsDirective,
  ViewDirective,
  TimelineViews,
  PopupCloseEventArgs,
  ActionEventArgs,
  CellClickEventArgs,
  PopupOpenEventArgs,
} from '@syncfusion/ej2-react-schedule';
import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import {
  L10n,
  Internationalization,
  createElement,
} from '@syncfusion/ej2-base';
import { EventData } from 'utils/types/learningSchedule';
import {
  selectScheduleEvents,
  selectScheduleResources,
  createEvent,
  fetchProjects,
  fetchFacilitators,
  fetchEventsForProjects,
  selectProjectsForDropdown,
} from 'state/Schedule/ScheduleSlice';
import { serializeCreateEvent } from 'Pages/LearningSchedule/components/helpers/helpers';
import DetailsSection from './components/DetailsSection/DetailsSection';
import AddFacilitatorsSection from './components/AddFacilitatorsSection/AddFacilitatorsSection';
import {
  headerTemplate,
  ContentTemplate,
} from '../EventQuickView/EventQuickView';
import '../styles.css';
import { Provider } from 'react-redux';
import '../styles.css';

export interface EventsScheduleRef {
  onAddingEvent: () => void;
}

const EventsSchedule = forwardRef<EventsScheduleRef>((_, ref) => {
  L10n.load({
    'en-US': {
      schedule: {
        newEvent: intl.get('SCHEDULE.ADD_NEW_EVENT'),
      },
    },
  });
  const dispatch = useDispatch() as typeof store.dispatch;
  const [projectIds, setProjectIds] = useState<string[]>([]);
  const [eventTitle, setEventTitle] = useState<string>('');
  const [eventType, setEventType] = useState<string>('');
  const events = useSelector(selectScheduleEvents);
  const defaultResources = useSelector(selectScheduleResources);
  const projects = useSelector(selectProjectsForDropdown);
  const saveButtonRef = useRef<HTMLButtonElement | null>(null);
  const scheduleObj = useRef<any>(null);

  const [selectedFacilitatorsIds, setSelectedFacilitatorsIds] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (projectIds.length && eventTitle && eventType) {
      if (saveButtonRef && saveButtonRef.current) {
        saveButtonRef.current.disabled = false;
      }
    } else {
      if (saveButtonRef && saveButtonRef.current) {
        saveButtonRef.current.disabled = true;
      }
    }
  }, [projectIds, eventTitle, eventType, saveButtonRef]);

  const intlInstance = useMemo(() => {
    return new Internationalization();
  }, []);

  const closeQuickInfo = useCallback(() => {
    if (
      scheduleObj &&
      scheduleObj.current &&
      scheduleObj.current.closeQuickInfoPopup
    ) {
      scheduleObj.current.closeQuickInfoPopup();
    }
  }, [scheduleObj]);

  const formatHeader = useCallback(
    (args: { date: Date }) => {
      const formattedDate = intlInstance.formatDate(args?.date, {
        skeleton: 'Ed',
      });
      return <span>{formattedDate}</span>;
    },
    [intlInstance]
  );

  const onAddingEvent = (args?: CellClickEventArgs) => {
    let props;
    if (!args) {
      props = {
        startTime: new Date(),
        endTime: new Date(new Date().setHours(new Date().getHours() + 1)),
        isAllDay: true,
      };
    } else {
      props = args;
    }

    scheduleObj?.current?.openEditor(props, 'Add');
  };

  const onCellClick = (args: CellClickEventArgs) => {
    args.cancel = true;
  };

  useImperativeHandle(ref, () => ({
    onAddingEvent,
  }));

  const oncancel = (args: PopupCloseEventArgs) => {
    if (args.type === 'Editor') {
      if (args.element.querySelector('.details-section')) {
        const detailsSection = args.element.querySelector('.details-section');
        detailsSection?.remove();
        const facilitatorsSection = args.element.querySelector(
          '.facilitators-section'
        );
        facilitatorsSection?.remove();
      }
    }
  };

  const resetState = () => {
    setProjectIds([]);
    setEventTitle('');
    setEventType('');
    setSelectedFacilitatorsIds([]);
  };

  useEffect(() => {
    dispatch(fetchEventsForProjects());
    dispatch(fetchFacilitators());
    dispatch(fetchProjects());
  }, [dispatch]);

  const actionBegin = (args: ActionEventArgs) => {
    if (args.requestType === 'eventCreate') {
      if (args.data && args.data.length) {
        const data = args.data as EventData[];
        const newEvent = {
          StartTime: data[0].StartTime,
          EndTime: data[0].EndTime,
          RecurrenceRule: data[0].RecurrenceRule,
          Id: data[0].Id,
          ProjectId: projectIds,
          Facilitators: selectedFacilitatorsIds,
          Subject: eventTitle,
          EventType: eventType,
          IsAllDay: data[0].IsAllDay,
        } as EventData;
        args.cancel = true;
        const createEventParams = serializeCreateEvent(newEvent);
        dispatch(createEvent(createEventParams));
      }
    }
    if (args.requestType === 'eventRemove') {
      const data = args.data as Record<string, any>[][0];
      if (data.parent) {
        // dispatch deleteing an event of a series
      } else {
        // dispatch deleting an event
      }
    }
  };

  const onPopupOpen = (args: PopupOpenEventArgs) => {
    if (args.type === 'Editor') {
      resetState();

      const initialProjects =
        args.data?.ProjectId && args.target ? [args.data?.ProjectId] : [];

      setProjectIds(initialProjects);

      if (!args.element.querySelector('.details-section')) {
        if (scheduleObj) {
          if (scheduleObj?.current?.eventWindow?.recurrenceEditor) {
            scheduleObj.current.eventWindow.recurrenceEditor.frequencies = [
              'none',
              'daily',
              'weekly',
              'monthly',
            ];
          }
        }
        const row = createElement('div', { className: 'details-section' });
        const fRow = createElement('div', {
          className: 'facilitators-section',
        });

        const saveButton = args.element.querySelector('.e-event-save');
        if (saveButton instanceof HTMLButtonElement) {
          saveButtonRef.current = saveButton;
        }

        const formElement = args.element.querySelector('.e-schedule-form');

        formElement?.firstChild?.insertBefore(
          row,
          formElement.firstChild.firstChild
        );

        formElement?.firstChild?.insertBefore(
          fRow,
          formElement.firstChild.lastChild
        );

        const detailsContainer = createElement('div', {
          className: 'custom-field-container',
        });
        const facilitatorsContainer = createElement('div', {
          className: 'custom-field-facilitators-container',
        });

        row.appendChild(detailsContainer);
        fRow.appendChild(facilitatorsContainer);

        ReactDOM.render(
          <DetailsSection
            changeEventData={(value, name) => {
              switch (name) {
                case 'eventTitle':
                  setEventTitle(value);
                  break;
                case 'projectIds':
                  setProjectIds(value);
                  break;
                case 'learningEventTypeId':
                  setEventType(value);
                  break;
                default:
                  break;
              }
            }}
            projectIds={initialProjects}
            projects={projects}
          />,
          detailsContainer
        );

        ReactDOM.render(
          <Provider store={store}>
            <Typography variant='body2' className='mt-4 mb-4'>
              {intl.get('SCHEDULE.EVENTS.FACILITATORS_SECTION.TITLE')}
            </Typography>
            <AddFacilitatorsSection
              updateFacilitatorsIds={(selectedIds) =>
                setSelectedFacilitatorsIds(selectedIds)
              }
            />
          </Provider>,
          facilitatorsContainer
        );
      }
    } else if (args.type === 'RecurrenceAlert') {
      const dialogHeader = args.element.querySelector('#QuickDialog_title');
      const dialogContent = args.element.querySelector(
        '#QuickDialog_dialog-content'
      );
      const seriesButton = args.element.querySelector(
        'button.e-quick-dialog-series-event'
      );
      if (dialogContent && seriesButton) {
        if (dialogHeader?.textContent === 'Delete Event') {
          dialogContent.textContent = intl.get(
            'SCHEDULE.EVENTS.DELETE_RECURRING_EVENT_MESSAGE'
          );
          seriesButton.textContent = intl.get(
            'SCHEDULE.EVENTS.DELETE_SERIES_BUTTON'
          );
        } else if (dialogHeader?.textContent === 'Edit Event') {
          dialogContent.textContent = intl.get(
            'SCHEDULE.EVENTS.EDIT_RECURRING_EVENT_MESSAGE'
          );
          seriesButton.textContent = intl.get(
            'SCHEDULE.EVENTS.EDIT_SERIES_BUTTON'
          );
        }
      }
    }
  };

  return (
    <div className='h-schedule overflow-y-auto' data-testid='schedule_div'>
      <ScheduleComponent
        height='100%'
        ref={scheduleObj}
        eventSettings={{ dataSource: events }}
        actionBegin={actionBegin}
        popupClose={oncancel}
        currentView='WorkWeek'
        showTimeIndicator={false}
        rowAutoHeight={true}
        timeScale={{
          enable: false,
        }}
        group={{ resources: ['projects'] }}
        dateHeaderTemplate={formatHeader}
        popupOpen={onPopupOpen}
        cellClick={(args) => onCellClick(args)}
        quickInfoTemplates={{
          header: headerTemplate(closeQuickInfo),
          content: ContentTemplate,
        }}
      >
        <ViewsDirective>
          <ViewDirective option='TimelineWorkWeek' />
        </ViewsDirective>
        <Inject services={[TimelineViews]} />
        <ResourcesDirective>
          <ResourceDirective
            name='projects'
            dataSource={defaultResources}
            textField='Title'
            field='ProjectId'
            idField='ProjectId'
          />
        </ResourcesDirective>
      </ScheduleComponent>
    </div>
  );
});

export default EventsSchedule;
