import intl from 'react-intl-universal';
import { Button, Typography } from '@getsynapse/design-system';
import AdditionalBenefitTag from './AdditionalBenefitTag';
import { useState } from 'react';
import DeleteAdditionalBenefitModal from '../DeleteAdditionalBenefitModal/DeleteAdditionalBenefitModal';

type Props = {
  categoryName: string;
  isBenefitMonetary: boolean | null;
  benefitId: string;
  programId: string;
  categoryId: string;
};

const TopBar = ({
  categoryName,
  isBenefitMonetary,
  benefitId,
  programId,
  categoryId,
}: Props) => {
  const [isDeleteBenefitModalOpen, setIsDeleteBenefitModalOpen] =
    useState(false);

  return (
    <div className='flex items-center pl-6 py-2 pr-2 border-b border-neutral-lighter-two justify-between'>
      <DeleteAdditionalBenefitModal
        setIsOpen={setIsDeleteBenefitModalOpen}
        isOpen={isDeleteBenefitModalOpen}
        benefitId={benefitId}
        programId={programId}
        categoryId={categoryId}
      />
      <div className='flex items-center h-8'>
        <Typography variant='body2' weight='medium' className='mr-2'>
          {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.TITLE', {
            categoryName,
          })}
        </Typography>
        <AdditionalBenefitTag isBenefitMonetary={isBenefitMonetary} />
      </div>
      <Button
        variant='tertiary'
        iconName='trash'
        onClick={() => setIsDeleteBenefitModalOpen(true)}
      >
        {intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.DELETE_BENEFIT_BUTTON'
        )}
      </Button>
    </div>
  );
};

export default TopBar;
