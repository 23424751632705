import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { selectCanEdit, selectTaskDetail } from 'state/TaskDetail/taskSlice';
import { LONG_INPUTS_LENGTH } from 'utils/constants';
import SidePanelTitle from 'Atoms/SidePanelTitle/SidePanelTitle';
import ProjectLink from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskProjectLink';
import TaskAssigneesField from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskAssignee';
import TaskDescription from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskDescription';
import TaskDatesField from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskDates';
import TaskTypeField from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskType';
import TaskStatusField from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskStatus';
import TaskEstimatedTimeField from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskEstimateHours';
import TaskActualHoursField from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskActualHours';

const SidePanelContent = () => {
  const task = useSelector(selectTaskDetail);
  const canEditTask = useSelector(selectCanEdit);
  return (
    <div className='w-full bg-neutral-white flex-grow'>
      <SidePanelTitle
        placeholder={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_TITLE_PLACEHOLDER')}
        value={task?.name ?? ''}
        maxLength={LONG_INPUTS_LENGTH}
        disabled={!canEditTask}
        onChange={() => {}}
        containerProps={{ className: 'pt-0' }}
      />
      <div className='flex flex-col gap-y-3'>
        <ProjectLink
          projectId={task?.project.id ?? ''}
          projectTitle={task?.project.title ?? ''}
        />
        <TaskAssigneesField
          isReadOnly={false}
          isViewOnly={false}
          taskId={task?.id ?? ''}
        />
        <TaskDescription
          description={task?.description ?? ''}
          isReadOnly={false}
          isViewOnly={false}
          onDescriptionChange={() => {}}
        />
        <TaskDatesField
          isReadOnly={false}
          isViewOnly={false}
          hasAssignedUsers={task?.assignees ? task.assignees.length > 0 : false}
          startDate={new Date(task?.startDate ?? '')}
          dueDate={new Date(task?.endDate ?? '')}
          onDateChange={() => {}}
        />
        <TaskTypeField onChangeType={() => {}} />
        <TaskStatusField />
        <TaskEstimatedTimeField
          estimateHours={task?.estimateHours ?? ''}
          onEstimateHoursChange={() => {}}
        />
        <TaskActualHoursField setOpenModal={() => {}} />
      </div>
    </div>
  );
};

export default SidePanelContent;
