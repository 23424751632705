import React, { useState, useRef, useEffect } from 'react';
import get from 'lodash/get';
import CarouselArrowButton from './CarouselArrowButton';
import CarouselContentItem from './CarouselContentItem';
import { CAROUSEL_DIRECTION } from 'utils/constants';
import { ProjectContent } from 'utils/customTypes';

const FilesCarousel = ({
  files,
  onRemoveFile,
}: {
  files: ProjectContent[];
  onRemoveFile: (index: number) => void;
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const carousel = useRef<HTMLHeadingElement>(null);
  const [maxScrollWidth, setMaxScrollWidth] = useState<number>(0);
  const [itemsCount, setItemsCount] = useState<number>(files.length);

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const moveNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currentIndex <= maxScrollWidth
    ) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const shouldHidePrev = currentIndex <= 0;
  const shouldHideNext = () => {
    if (carousel.current !== null) {
      return carousel.current.offsetWidth * currentIndex >= maxScrollWidth;
    }
    return true;
  };

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      if (
        carousel.current.offsetWidth * currentIndex >
        carousel.current?.scrollWidth
      ) {
        carousel.current.scrollLeft = carousel.current.scrollWidth;
      } else {
        carousel.current.scrollLeft =
          carousel.current.offsetWidth * currentIndex;
      }
    }
  }, [
    files,
    currentIndex,
    carousel.current?.scrollWidth,
    carousel.current?.offsetWidth,
  ]);

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      if (carousel.current.scrollWidth <= carousel.current.offsetWidth) {
        setCurrentIndex(0);
      }
      if (files.length > itemsCount) {
        setCurrentIndex(
          Math.floor(
            (get(carousel, 'current.scrollWidth', 0) -
              get(carousel, 'current.offsetWidth', 0)) /
              carousel.current.offsetWidth
          ) + 1
        );
      }
      if (files.length !== itemsCount) {
        setItemsCount(files.length);
      }
    }
    setMaxScrollWidth(
      get(carousel, 'current.scrollWidth', 0) -
        get(carousel, 'current.offsetWidth', 0)
    );
  }, [
    files,
    carousel.current?.scrollWidth,
    carousel.current?.offsetWidth,
    itemsCount,
  ]);

  return (
    <div className='mx-auto'>
      <div className='relative overflow-hidden'>
        <div className='flex justify-between absolute top left w-full h-full'>
          {!shouldHidePrev && (
            <CarouselArrowButton
              direction={CAROUSEL_DIRECTION.PREV}
              onClick={movePrev}
            />
          )}
          {!shouldHideNext() && (
            <CarouselArrowButton
              direction={CAROUSEL_DIRECTION.NEXT}
              onClick={moveNext}
            />
          )}
        </div>
        <div
          ref={carousel}
          style={{ scrollBehavior: 'smooth' }}
          className='relative flex gap-1.5 snap-x snap-mandatory overflow-hidden touch-pan-x z-0 overflow-visible py-2 px-0.5 z-1'
        >
          {files.map((content: Partial<ProjectContent>, index) => (
            <CarouselContentItem
              content={content}
              index={index}
              onRemoveFile={onRemoveFile}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilesCarousel;
