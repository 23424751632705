import { useEffect, Dispatch, SetStateAction, useState } from 'react';
import TaskDetails from 'Pages/ProjectPage/tabs/Tasks/components/TaskDetails';
import { TaskActualHours, TaskDetailType } from 'utils/customTypes';
import SidePanelTitle from 'Atoms/SidePanelTitle/SidePanelTitle';
import intl from 'react-intl-universal';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { Banner } from '../../Pages/ProjectPage/tabs/Tasks/TaskPage';
import { requiredFields } from '../../Pages/ProjectPage/tabs/Tasks/helpers/constants';
import UnsavedChangesBanner from 'Atoms/UnsavedChangesBanner/UnsavedChangesBanner';
import {
  LONG_INPUTS_LENGTH,
  TASK_FIELDS,
  PROJECT_PARTICIPANT_TYPE,
  TASK_STATUS,
} from 'utils/constants';
import { isUserOrganizationAdmin } from 'state/User/userSlice';
import { useSelector } from 'react-redux';
import useHasUserAccess from 'Pages/ProjectPage/hooks/useHasUserAccess';
import { Icon, Typography } from '@getsynapse/design-system';

type TaskPanelProps = {
  taskData: TaskDetailType;
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>;
  unsavedChanges: boolean;
  showUnsavedChangesAnimation: boolean;
  currentSavedTask: TaskDetailType;
  setCurrentSavedTask: Dispatch<SetStateAction<TaskDetailType>>;
  setShouldDisableUpdate: Dispatch<SetStateAction<boolean>>;
  shouldDisableUpdate: boolean;
  isViewOnly?: boolean;
  canUpdateTask?: boolean;
  isOnSidepanel?: boolean;
};

const TaskSidePanelContent = ({
  taskData,
  setUnsavedChanges,
  unsavedChanges,
  showUnsavedChangesAnimation,
  currentSavedTask,
  setCurrentSavedTask,
  setShouldDisableUpdate,
  shouldDisableUpdate,
  canUpdateTask = false,
  isOnSidepanel = false,
  isViewOnly = false,
}: TaskPanelProps) => {
  const isUserAdmin = useSelector(isUserOrganizationAdmin);

  const [showActualHoursLog, setShowActualHoursLog] = useState<boolean>(false);

  const { permissionsLevel } = useHasUserAccess();

  const setData = (
    fieldName: string,
    fieldValue: string | string[] | TaskActualHours[]
  ) => {
    setCurrentSavedTask((prevData) => ({
      ...prevData,
      [fieldName]: fieldValue,
    }));
  };

  useEffect(() => {
    setCurrentSavedTask(taskData);
  }, [taskData, setCurrentSavedTask]);

  useEffect(() => {
    const changesDetected = !isEqual(currentSavedTask, taskData);
    setUnsavedChanges(changesDetected);

    let canSave = true;
    const dateFields = ['start_date', 'due_date'];
    if (currentSavedTask.assignedUsers.length > 0) {
      requiredFields.push('start_date', 'due_date');
    }
    requiredFields.forEach((field) => {
      if (!dateFields.includes(field) && isEmpty(currentSavedTask[field])) {
        canSave = false;
      } else {
        if (
          !(currentSavedTask[field] instanceof Date) &&
          isEmpty(currentSavedTask[field])
        ) {
          canSave = false;
        }
      }
    });

    if (changesDetected && shouldDisableUpdate && canSave) {
      setShouldDisableUpdate(false);
    }
    if ((!changesDetected || !canSave) && !shouldDisableUpdate) {
      setShouldDisableUpdate(true);
    }
  }, [
    currentSavedTask,
    shouldDisableUpdate,
    taskData,
    setShouldDisableUpdate,
    setUnsavedChanges,
    unsavedChanges,
  ]);

  return (
    <div className='bg-neutral-white flex-grow'>
      <div className='w-full h-full'>
        {showActualHoursLog ? (
          <>
            <div
              className='flex items-center mt-2.5 cursor-pointer'
              onClick={() => setShowActualHoursLog(false)}
              data-cy='back-to-task-details'
            >
              <Icon
                name='arrow-back-outline'
                className='mr-2 text-neutral font-semibold'
              />
              <Typography
                variant='body2'
                className='text-neutral'
                weight='medium'
              >
                {intl.get('BACK')}
              </Typography>
            </div>
            <Typography variant='h4' className='mb-9 mt-6' weight='medium'>
              {intl.get('TASK_ACTUAL_HOURS.TITLE')}
            </Typography>
            {unsavedChanges && (
              <UnsavedChangesBanner
                showEmphasisAnimation={showUnsavedChangesAnimation}
                message={intl.get('SIDE_PANEL.UNSAVED_CHANGES')}
              />
            )}
          </>
        ) : (
          <>
            <SidePanelTitle
              placeholder={intl.get(
                'TASKS.TASK_DETAIL_PAGE.TASK_TITLE_PLACEHOLDER'
              )}
              value={currentSavedTask.name}
              maxLength={LONG_INPUTS_LENGTH}
              disabled={isViewOnly}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setData(TASK_FIELDS.NAME, event.target.value)
              }
              containerProps={{ className: 'pt-0' }}
            />
            {unsavedChanges && (
              <UnsavedChangesBanner
                showEmphasisAnimation={showUnsavedChangesAnimation}
                message={intl.get('SIDE_PANEL.UNSAVED_CHANGES')}
              />
            )}
            <Banner
              originalTaskData={taskData}
              currentTaskData={currentSavedTask}
              sidePanel={true}
            />
          </>
        )}
        <TaskDetails
          setData={setData}
          data={currentSavedTask as TaskDetailType}
          canUpdateTask={canUpdateTask}
          isOnSidepanel={isOnSidepanel}
          isViewOnly={isViewOnly}
          canDeleteActualHours={
            (permissionsLevel === PROJECT_PARTICIPANT_TYPE.OWNER ||
              isUserAdmin) &&
            !(
              taskData.status === TASK_STATUS.COMPLETED &&
              currentSavedTask.status === TASK_STATUS.COMPLETED
            ) &&
            currentSavedTask.status !== TASK_STATUS.ON_HOLD &&
            !currentSavedTask.disabled
          }
          setShowActualHoursLog={setShowActualHoursLog}
          showActualHoursLog={showActualHoursLog}
        />
      </div>
    </div>
  );
};

export default TaskSidePanelContent;
