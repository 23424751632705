import { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, tailwindOverride } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { v4 as uuidv4 } from 'uuid';
import addObjectiveIcon from 'assets/icons/add-objective.svg';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { createCategoryObjectives } from 'state/StrategyCategory/strategyCategorySlice';
import { trackEvent } from 'Services/pendo';
import { PENDO_EVENTS } from 'utils/constants';
import { NewObjective, ProgramCategory } from 'utils/types/program';
import { StringOption } from 'utils/customTypes';
import TemplateObjectiveCheckboxGroup from './TemplateObjectiveCheckboxGroup';
import CustomObjective from './CustomObjective';
import CategoryPrompts from './CategoryPrompts';

type AddCategoryObjectiveModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  category: ProgramCategory;
  programId: string;
  programDescription: string;
};

const AddCategoryObjectiveModal = ({
  isOpen,
  setIsOpen,
  category,
  programId,
  programDescription,
}: AddCategoryObjectiveModalProps) => {
  const dispatch = useDispatch();
  const modalRef = useRef<null | HTMLDivElement>(null);
  const [createdObjectives, setCreatedObjectives] = useState<
    Map<string, string>
  >(new Map());
  const [selectedObjectiveTemplates, setSelectedObjectiveTemplates] = useState<
    Map<string, StringOption & { checked: boolean; templateId: string }>
  >(new Map());

  const currentCategory = useMemo(
    () => ({
      ...category,
      objective_templates: category?.objective_templates || [],
      prompts: category?.prompts || [],
    }),
    [category]
  );

  const hasChanges = useMemo(() => {
    const hasNewObjectives = Array.from(
      selectedObjectiveTemplates.values()
    ).some((template) => template.checked);

    if (!hasNewObjectives) {
      return Array.from(createdObjectives.values()).some(
        (objective) => objective !== ''
      );
    }

    return true;
  }, [createdObjectives, selectedObjectiveTemplates]);

  useEffect(() => {
    if (currentCategory?.objective_templates!.length > 0) {
      const objectiveTemplatesMap = new Map();
      currentCategory?.objective_templates!.forEach((objectiveTemplate) => {
        const isThere =
          currentCategory?.objectives.findIndex(
            (objective) => objective.name === objectiveTemplate.name
          ) !== -1;
        if (!isThere) {
          objectiveTemplatesMap.set(objectiveTemplate.name, {
            label: objectiveTemplate.name,
            value: objectiveTemplate.name,
            templateId: objectiveTemplate.id,
            checked: isThere,
          });
        }
      });

      setSelectedObjectiveTemplates(objectiveTemplatesMap);
    }
  }, [currentCategory, isOpen]);

  const onCloseModal = () => {
    setCreatedObjectives(new Map());
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen && currentCategory?.objective_templates.length === 0) {
      const key = uuidv4();
      setCreatedObjectives((prev) => new Map(prev).set(key, ''));
    }
  }, [isOpen, currentCategory?.objective_templates.length]);

  const onAddCustomObjective = async () => {
    const key = uuidv4();
    await setCreatedObjectives((prev) => new Map(prev).set(key, ''));
    modalRef &&
      modalRef.current &&
      modalRef.current!.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSave = async () => {
    trackEvent(PENDO_EVENTS.CTA_SAVE_OBJECTIVES, {
      category: currentCategory?.name,
    });

    const newObjectives: NewObjective[] = [];

    selectedObjectiveTemplates.forEach((value) => {
      if (value.checked) {
        newObjectives.push({
          name: value.label,
          category_id: currentCategory?.id,
          program_objective_template_id: value.templateId,
        } as NewObjective);
      }
    });

    createdObjectives.forEach((value) => {
      if (value.length > 0) {
        newObjectives.push({
          name: value,
          category_id: currentCategory.id,
        } as NewObjective);
      }
    });
    if (newObjectives.length > 0) {
      try {
        await dispatch(createCategoryObjectives({ programId, newObjectives }));
        dispatch(
          showSnackbarNotification({
            autoHide: false,
            notificationVariant: 'success',
            notificationTitle: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.ADD_OBJECTIVE.SUCCESS'
            ),
          })
        );
        trackEvent(PENDO_EVENTS.CREATE_OBJECTIVE, {
          newObjectiveCount: newObjectives.length,
        });
      } catch (error) {
        dispatch(
          showSnackbarNotification({
            autoHide: false,
            notificationVariant: 'error',
            notificationTitle: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.ERROR.TITLE'
            ),
            notificationMessage: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.ERROR.MESSAGE'
            ),
          })
        );
      }
    }
    onCloseModal();
  };

  return (
    <Modal
      data-testid={`create-objective_modal-${currentCategory?.name?.toLocaleLowerCase()}`}
      childrenClassName='max-h-140'
      aria-label={intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.ADD_OBJECTIVE.TITLE',
        { category: currentCategory?.name }
      )}
      closeModal={onCloseModal}
      title={intl.get(
        'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.ADD_OBJECTIVE.TITLE',
        { category: currentCategory?.name }
      )}
      titleIcon={{
        src: addObjectiveIcon,
        className: 'text-fire -mr-1',
      }}
      size='large'
      isOpen={isOpen}
      actionButtons={[
        {
          'data-testid': 'create-objective-modal_save-button',
          children: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.ADD_OBJECTIVE.ADD_OBJECTIVES'
          ),
          variant: 'primary',
          onClick: handleSave,
          disabled: !hasChanges,
        },
        {
          'data-testid': 'create-objective-modal_cancel-button',
          children: intl.get('CANCEL'),
          variant: 'tertiary',
          onClick: onCloseModal,
        },
      ]}
    >
      <div className='flex space-x-8'>
        <div
          className={tailwindOverride({
            'w-7/12': currentCategory.objective_templates.length <= 0,
            'w-full':
              currentCategory.objective_templates.length > 0 ||
              currentCategory?.prompts!.length <= 0,
          })}
        >
          {currentCategory?.objective_templates.length > 0 && (
            <TemplateObjectiveCheckboxGroup
              categoryName={currentCategory?.name}
              selectedObjectiveTemplates={selectedObjectiveTemplates}
              setSelectedObjectiveTemplates={setSelectedObjectiveTemplates}
            />
          )}

          {createdObjectives.size > 0 &&
            Array.from(createdObjectives.keys()).map((key, index) => (
              <CustomObjective
                category={currentCategory}
                selectedObjectiveTemplates={selectedObjectiveTemplates}
                key={key}
                objectiveKey={key}
                objectiveValue={createdObjectives.get(key)!}
                index={index}
                setCreatedObjectives={setCreatedObjectives}
              />
            ))}

          <Button
            ref={modalRef}
            iconName='add-circle'
            iconPosition='left'
            variant='tertiary'
            className='mb-2'
            onClick={onAddCustomObjective}
            data-testid='create-objective-modal_add-objective'
          >
            {intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.ADD_OBJECTIVE.CREATE_OBJECTIVE'
            )}
          </Button>
        </div>
        {currentCategory?.prompts!.length > 0 && (
          <CategoryPrompts
            categoryPrompts={currentCategory.prompts}
            programDescription={programDescription}
          />
        )}
      </div>
    </Modal>
  );
};

export default AddCategoryObjectiveModal;
