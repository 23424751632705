import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { tailwindOverride } from '@getsynapse/design-system';

const TableCell: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  const { tasksAssignmentsBasedResourcesCapacity = false } = useFlags();
  return (
    <div
      className={tailwindOverride(
        'py-3 px-2 flex box-border animate-pulse',
        className,
        { 'shadow-table-column': !tasksAssignmentsBasedResourcesCapacity }
      )}
    >
      {children}
    </div>
  );
};

const TableCellContent: React.FC<{ className?: string }> = ({ className }) => {
  return <div className={tailwindOverride('h-4 rounded-sm', className)}></div>;
};

const TableTrigger = () => {
  const { tasksAssignmentsBasedResourcesCapacity = false } = useFlags();
  return (
    <tr
      className={tailwindOverride('bg-neutral-white', {
        'border-b border-neutral-lighter shadow-header':
          !tasksAssignmentsBasedResourcesCapacity,
      })}
    >
      <td
        className={tailwindOverride('w-2/5 h-10', {
          'shadow-allocation-table-inner':
            !tasksAssignmentsBasedResourcesCapacity,
        })}
      >
        <div className='flex w-full h-full'>
          <TableCell className='w-12 justify-end'>
            <TableCellContent className='w-4 bg-neutral-lighter' />
          </TableCell>
          <TableCell className='w-2/5'>
            <TableCellContent className='w-2/5 bg-neutral-lighter' />
          </TableCell>
          <TableCell className='flex-1' />
          <TableCell className='flex-1' />
        </div>
      </td>
      <td className='w-3/5 h-10'>
        <div className='flex w-full h-full'>
          <TableCell className='w-5' />
          {Array.from(Array(6)).map((_, index: number) => (
            <TableCell
              key={`table-trigger__column-${index}__loader`}
              className={tailwindOverride('flex-1', {
                'bg-primary-lightest': index === 0,
              })}
            />
          ))}
          <TableCell className='w-5' />
        </div>
      </td>
    </tr>
  );
};

const SkeletonLoading = () => {
  const { tasksAssignmentsBasedResourcesCapacity = false } = useFlags();
  return (
    <div
      className={tailwindOverride('w-full h-auto rounded-t-lg', {
        'mt-4': !tasksAssignmentsBasedResourcesCapacity,
      })}
      data-testid='skeleton-loader'
    >
      <table
        className={tailwindOverride('w-full h-full table-fixed', {
          'shadow-allocation-table': !tasksAssignmentsBasedResourcesCapacity,
        })}
        cellPadding={0}
        cellSpacing={0}
      >
        <thead
          className={tailwindOverride('bg-primary-lightest', {
            'border-b border-neutral-lighter':
              !tasksAssignmentsBasedResourcesCapacity,
          })}
        >
          <tr>
            <th className='w-2/5 h-10'>
              <div
                className={tailwindOverride('flex w-full h-full', {
                  'shadow-allocation-table-inner':
                    !tasksAssignmentsBasedResourcesCapacity,
                })}
              >
                <TableCell className='w-12 justify-end'>
                  <TableCellContent className='w-4 bg-primary-lighter' />
                </TableCell>
                <TableCell className='w-2/5'>
                  <TableCellContent className='w-3/5 bg-primary-lighter' />
                </TableCell>
                <TableCell className='flex-1'>
                  <TableCellContent className='w-full bg-primary-lighter' />
                </TableCell>
                <TableCell className='flex-1'>
                  <TableCellContent className='w-full bg-primary-lighter' />
                </TableCell>
              </div>
            </th>
            <th className='w-3/5 h-10'>
              <div className='flex w-full h-full'>
                <TableCell className='w-5' />
                {Array.from(Array(6)).map((_, index: number) => (
                  <TableCell
                    key={`table-head__column-${index}__loader`}
                    className='flex-1'
                  >
                    <TableCellContent className='w-full bg-primary-lighter' />
                  </TableCell>
                ))}
                <TableCell className='w-5' />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <TableTrigger />
          <tr
            className={tailwindOverride({
              'border-b border-neutral-lighter bg-neutral-lightest-2':
                !tasksAssignmentsBasedResourcesCapacity,
              'bg-neutral-lightest-two': tasksAssignmentsBasedResourcesCapacity,
            })}
          >
            <td className='w-2/5 h-22'>
              <div
                className={tailwindOverride('flex w-full h-full', {
                  'shadow-allocation-table-inner':
                    !tasksAssignmentsBasedResourcesCapacity,
                })}
              >
                <TableCell className='w-12 justify-end'>
                  <TableCellContent className='w-4 bg-neutral-lighter' />
                </TableCell>
                <TableCell className='w-2/5'>
                  <TableCellContent className='w-5 h-5 rounded-full bg-neutral-lighter' />
                  <div className='flex-1 ml-2'>
                    <TableCellContent className='w-3/5 bg-neutral-lighter mb-1' />
                    <TableCellContent className='w-2/5 bg-neutral-lighter mb-3' />
                    <TableCellContent className='w-1/5 bg-neutral-lighter' />
                  </div>
                </TableCell>
                <TableCell className='flex-1 flex-col'>
                  <TableCellContent className='w-2/5 bg-neutral-lighter mb-2' />
                  <TableCellContent className='w-3/5 bg-neutral-lighter' />
                </TableCell>
                <TableCell className='flex-1 flex-col'>
                  <TableCellContent className='w-3/5 bg-neutral-lighter mb-2' />
                  <TableCellContent className='w-1/3 bg-neutral-lighter' />
                </TableCell>
              </div>
            </td>
            <td className='w-3/5 h-22'>
              <div className='flex w-full h-full'>
                <TableCell className='w-5' />
                {Array.from(Array(6)).map((_, index: number) => (
                  <TableCell
                    key={`table-body__column-${index}__loader`}
                    className={tailwindOverride('flex-1', {
                      'bg-primary-lightest': index === 0,
                    })}
                  >
                    <TableCellContent className='w-full h-6 bg-neutral-lighter' />
                  </TableCell>
                ))}
                <TableCell className='w-5' />
              </div>
            </td>
          </tr>
          <TableTrigger />
          <TableTrigger />
        </tbody>
      </table>
    </div>
  );
};

export default SkeletonLoading;
