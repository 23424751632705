import React from 'react';
import { tailwindOverride, Typography } from '@getsynapse/design-system';
import { ProjectStatus } from 'utils/customTypes';
import { PROJECT_STATUS } from 'utils/constants';
import {
  getDailyProjectCapacityStylesByStatus,
  numberFormatter,
} from '../../../DailyCapacity/helpers';

export interface ProjectHoursProps {
  id: string;
  status: ProjectStatus;
  hours: number;
}

const ProjectHours: React.FC<ProjectHoursProps> = ({ status, hours }) => {
  let styles = getDailyProjectCapacityStylesByStatus[PROJECT_STATUS.NEW];
  if (status && status !== PROJECT_STATUS.NEW) {
    styles = getDailyProjectCapacityStylesByStatus[status];
  }
  return (
    <div
      className={tailwindOverride(
        'flex flex-1 items-center justify-center',
        'h-6 border rounded-sm',
        'bg-neutral-white',
        styles.border
      )}
      style={{
        boxShadow: `0px 2px 2px 0px #12151B0D, 2px 0px 0px 0px ${styles.boxShadow} inset`,
      }}
    >
      <Typography
        variant='caption'
        weight='medium'
        className={styles.text}
      >{`${numberFormatter.format(hours)}h`}</Typography>
    </div>
  );
};

export default ProjectHours;
