import { useEffect, useState, useMemo } from 'react';
import intl from 'react-intl-universal';
import { useParams, Prompt, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@getsynapse/design-system';
import { AdditionalBenefit } from 'utils/types/program';
import {
  getProgramData,
  fetchProgram,
  selectProgramStatus,
} from 'state/Program/programSlice';
import {
  getCategory,
  selectCategory,
  selectCategoryStatus,
} from 'state/StrategyCategory/strategyCategorySlice';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import TopBar from './components/TopBar';
import PageFooter from 'Molecules/PageFooter/PageFooter';
import Divider from 'Atoms/Divider';
import {
  fetchAdditionalBenefit,
  selectAdditionalBenefit,
  resetAdditionalBenefit,
  selectAdditionalBenefitStatus,
  updateAdditionalBenefit,
} from 'state/AdditionalBenefit/additionalBenefitSlice';
import BenefitManagement from './components/BenefitManagement';
import BenefitMeasurement from './components/BenefitMeasurement';
import MonetaryBenefits from 'Pages/CategoryObjectivePage/components/MonetaryBenefits/MonetaryBenefits';
import { SLICE_STATUS } from 'utils/constants';
import UnsavedChangesModal from 'Organisms/UnsavedChangesModal/UnsavedChangesModal';
import { Location } from 'history';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { trackEvent } from 'Services/pendo';
import { PENDO_EVENTS } from 'utils/constants';
import ObjectiveSkeletonLoader from 'Pages/CategoryObjectivePage/components/ObjectiveSkeletonLoader';

const AdditionalBenefitPage = () => {
  const { programId, categoryId, benefitId } = useParams<{
    programId: string;
    categoryId: string;
    benefitId: string;
  }>();
  const dispatch = useDispatch();
  const history = useHistory();

  const program = useSelector(getProgramData);
  const programStatus = useSelector(selectProgramStatus);
  const category = useSelector(selectCategory);
  const categoryStatus = useSelector(selectCategoryStatus);
  const additionalBenefitSelector = useSelector(selectAdditionalBenefit);
  const additionalBenefitStatus = useSelector(selectAdditionalBenefitStatus);

  const [additionalBenefitData, setAdditionalBenefitData] =
    useState<AdditionalBenefit>({});
  const [confirmedNavigation, setConfirmedNavigation] =
    useState<boolean>(false);
  const [isLeavingWarningModalOpen, setIsLeavingWarningModalOpen] =
    useState<boolean>(false);
  const [leavingToLocation, setLeavingToLocation] = useState<string>('');

  const isDataLoading =
    additionalBenefitStatus === SLICE_STATUS.LOADING ||
    categoryStatus === SLICE_STATUS.LOADING ||
    programStatus === SLICE_STATUS.LOADING;

  const [areNumericFieldsValid, setAreNumericFieldsValid] =
    useState<boolean>(true);

  const hasChanges = useMemo(() => {
    const data = { ...additionalBenefitData };
    delete data.position;
    const additionalBenefitKeys = Object.keys(data) as Array<
      keyof AdditionalBenefit
    >;
    const changes = additionalBenefitKeys.some(
      (key) => data[key] !== additionalBenefitSelector[key]
    );
    return changes;
  }, [additionalBenefitData, additionalBenefitSelector]);

  useEffect(() => {
    if (confirmedNavigation && leavingToLocation) {
      history.push(leavingToLocation);
      setConfirmedNavigation(false);
      setLeavingToLocation('');
    }
  }, [confirmedNavigation, history, leavingToLocation]);

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        dispatch(fetchProgram(programId)),
        dispatch(getCategory({ programId, categoryId })),
        dispatch(fetchAdditionalBenefit({ programId, categoryId, benefitId })),
      ]);
    };
    init();
    return () => {
      dispatch(resetAdditionalBenefit());
    };
  }, [dispatch, categoryId, programId, benefitId]);

  useEffect(() => {
    if (additionalBenefitSelector.id) {
      const position = category.additionalBenefits?.length
        ? category.additionalBenefits?.findIndex(
            (additionalBenefit) => additionalBenefit.id === benefitId
          )
        : 1;
      const data = { ...additionalBenefitSelector, position: position! + 1 };
      setAdditionalBenefitData(data);
    }
  }, [additionalBenefitSelector, benefitId, category.additionalBenefits]);

  const canSave = additionalBenefitData?.benefitText !== '' && hasChanges;

  const changeAdditionalBenefitData = (
    value: string | boolean,
    key: string
  ) => {
    setAdditionalBenefitData((prev) => ({ ...prev, [key]: value }));
  };

  const handleBlockedNavigation = (location: Location) => {
    if (!confirmedNavigation && additionalBenefitSelector.id) {
      setLeavingToLocation(`${location.pathname}${location.search}`);
      setIsLeavingWarningModalOpen(true);
      return false;
    }
    return true;
  };

  const onSaveAdditionalBenefit = async () => {
    if (hasChanges) {
      const data = { ...additionalBenefitData };
      delete data.position;
      delete data.id;
      delete data.annualBenefit;
      if (!data.isMonetary) {
        data.unitOfMeasure = null;
        data.changeInPerformancePerMonth = null;
        data.valueOfOneUnitOfMeasure = null;
      }
      try {
        await dispatch(
          updateAdditionalBenefit({
            programId,
            categoryId,
            benefitId,
            data,
          })
        );
        dispatch(
          showSnackbarNotification({
            autoHide: false,
            notificationVariant: 'success',
            notificationTitle: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.ADDITIONAL_BENEFIT.SUCCESS_MESSAGE'
            ),
          })
        );
        trackEvent(PENDO_EVENTS.SAVE_ADDITIONAL_BENEFIT, {
          id: additionalBenefitData?.id,
          name: additionalBenefitData?.benefitText,
          is_monetary: additionalBenefitData?.isMonetary,
        });
        setAdditionalBenefitData({});
        history.goBack();
      } catch (error) {
        dispatch(
          showSnackbarNotification({
            autoHide: false,
            notificationVariant: 'error',
            notificationTitle: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.UPDATE_ERROR_TITLE'
            ),
            notificationMessage: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.ERROR.MESSAGE'
            ),
          })
        );
      }
    }
  };

  return (
    <div className='h-full flex flex-col'>
      <Prompt when={canSave} message={handleBlockedNavigation} />
      <UnsavedChangesModal
        isOpen={isLeavingWarningModalOpen}
        setIsOpen={setIsLeavingWarningModalOpen}
        onConfirm={() => setConfirmedNavigation(true)}
      />
      {isDataLoading && <ObjectiveSkeletonLoader />}
      {!isDataLoading && additionalBenefitData.id && program.id && category.id && (
        <>
          <PageTitle
            className='w-full'
            titleComponent={intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.CATEGORY_TITLE',
              {
                program: program.title,
                category: category.name,
              }
            )}
          />
          <main
            id='additional-benefit-container'
            data-testid='additional-benefit-container'
            className='mb-6 mx-6 rounded-lg border border-neutral-lighter-two flex-grow'
          >
            <TopBar
              categoryName={category.name}
              isBenefitMonetary={additionalBenefitData.isMonetary!}
              benefitId={additionalBenefitData.id!}
              programId={programId}
              categoryId={categoryId}
            />
            <div className='overflow-y-auto max-h-details-with-bar pb-10'>
              <BenefitManagement
                data={additionalBenefitData}
                updateData={changeAdditionalBenefitData}
              />
              <Divider className='text-neutral-lighter-two mb-8' />
              <BenefitMeasurement
                isMonetary={additionalBenefitData.isMonetary!}
                updateData={changeAdditionalBenefitData}
              />
              {additionalBenefitData.isMonetary && (
                <>
                  <Divider className='text-neutral-lighter-two mb-8' />
                  <MonetaryBenefits
                    monetaryBenefitsData={
                      additionalBenefitData as AdditionalBenefit
                    }
                    setMonetaryBenefitsData={setAdditionalBenefitData}
                    setAreNumericFieldsValid={setAreNumericFieldsValid}
                  />
                </>
              )}
            </div>
          </main>
        </>
      )}
      <PageFooter>
        <Button
          variant='tertiary'
          onClick={() => history.goBack()}
          data-testid='additional-benefit_cancel-button'
        >
          {intl.get('CANCEL')}
        </Button>
        <Button
          onClick={onSaveAdditionalBenefit}
          data-testid='additional-benefit_save-button'
          disabled={
            !canSave ||
            (additionalBenefitData.isMonetary && !areNumericFieldsValid)
          }
        >
          {intl.get('SAVE')}
        </Button>
      </PageFooter>
    </div>
  );
};

export default AdditionalBenefitPage;
