import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { tailwindOverride } from '@getsynapse/design-system';
import useCapacityTable from './hooks/useCapacityTable';
import { ParticipantTypes, SLICE_STATUS } from 'utils/constants';
import {
  selectCapacity,
  capacityStatus,
} from 'state/TasksAssignmentsResourcesCapacity/tasksAssignmentsResourcesCapacitySlice';
import { generateTableHeaders } from 'Pages/ProjectPage/tabs/People/tabs/ResourceAllocation/helpers';
import { TableHeaders as UsersSectionHeaders } from 'Organisms/CapacityAllocationTable/components/AllocatedUsersTable';
import WeekHeaders from './components/WeekHeaders/WeekHeaders';
import Section from './components/Section/Section';
import Loader from './components/Loader/Loader';
import TargetCompletion from './components/TargetCompletion/TargetCompletion';
import RemoveParticipantModal from './components/RemoveParticipantModal/RemoveParticipantModal';

const CapacityTable: React.FC<{
  projectId: string;
  startDate: Date | string;
  endDate: Date | string;
}> = ({ projectId, endDate }) => {
  const { isSectionOpen, toggleSection } = useCapacityTable();
  const [showLoder, setShowLoader] = useState<boolean>(true);
  const isFetching = useSelector(capacityStatus);
  const participantsCapacity = useSelector(selectCapacity);
  const headers = useMemo(() => generateTableHeaders(), []);

  useEffect(() => {
    let isMounted = true;
    setTimeout(() => {
      if (isFetching === SLICE_STATUS.IDLE && isMounted) {
        setShowLoader(false);
      }
    }, 1000);
    return () => {
      isMounted = false;
    };
  }, [isFetching]);

  return showLoder ? (
    <Loader />
  ) : (
    <>
      <RemoveParticipantModal projectId={projectId} />
      <div
        className={tailwindOverride(
          'w-full max-h-programs-table',
          'rounded border border-neutral-lighter-two',
          'relative'
        )}
      >
        <div
          className={tailwindOverride(
            'w-2/5 h-full shadow-allocation-table',
            'absolute top-0 left-0',
            'bg-transparent'
          )}
        />
        <TargetCompletion targetCompletionDate={endDate} />
        <div className='max-h-request-details overflow-y-auto'>
          <table
            className='w-full h-full table-fixed'
            cellPadding={0}
            cellSpacing={0}
            data-cy='tasks-based-capacity-table'
          >
            <thead
              className={tailwindOverride(
                'h-10 bg-neutral-lightest font-semibold text-primary',
                'sticky top-0 z-10'
              )}
            >
              <tr>
                <th className='w-2/5 relative'>
                  <UsersSectionHeaders
                    headers={headers}
                    onSelectAllUsers={() => {}}
                    canSelectUsers={false}
                  />
                </th>
                <th className='w-3/5 h-full'>
                  <WeekHeaders projectId={projectId} />
                </th>
              </tr>
            </thead>
            <tbody className='h-full'>
              {Object.keys(participantsCapacity).map((participantType) => {
                return (
                  <Section
                    key={participantType}
                    participantType={participantType as ParticipantTypes}
                    participantCapacity={
                      participantsCapacity[participantType as ParticipantTypes]
                    }
                    isSectionOpen={isSectionOpen(participantType)}
                    toggleSection={toggleSection}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CapacityTable;
