import atRiskIcon from 'assets/icons/at_risk.svg';
import kanbanCardIcon from 'assets/icons/kanban_card.svg';
import dueDateIcon from 'assets/icons/due_date.svg';
import taskCompletionIcon from 'assets/icons/checkbox.svg';
import PieChartComponent from '../charts/PieChart';
import { Icon, Typography } from '@getsynapse/design-system';
import Rocket from 'assets/icons/Rocket.svg';
import { useEffect } from 'react';
import intl from 'react-intl-universal';
import party from 'party-js';

const ProgramInsights = ({
  projectDetails,
  allProjectsCompleted,
}: {
  projectDetails: any;
  allProjectsCompleted: any;
}) => {
  const insightsBox =
    'h-44 flex flex-row w-full border-b border-t border-neutral-lighter-two';
  const insightsColumn1Classes =
    'h-1/2 pl-8 pr-4 border-l border-b border-neutral-lighter-two';
  const insightsColumn2Classes =
    'h-1/2 pl-8 pr-4 border-l border-neutral-lighter-two';
  const insightsValueClasses = 'flex flex-col font-semibold pl-9';
  const insightsTextClasses = 'text-neutral-darker pt-1 pl-2.5';

  const element =
    allProjectsCompleted && document.querySelector('#part-element');

  useEffect(() => {
    if (element) {
      party.confetti(element as HTMLElement);
    }
  }, [element]);

  return (
    <div className={insightsBox}>
      {!allProjectsCompleted ? (
        <>
          <div className='flex flex-col w-1/12 pl-5 pt-6'>
            <Typography variant='label' className='text-primary'>
              {intl.get('PROGRAM_PAGE.PROJECTS')}
            </Typography>
            <Typography variant='caption' className='text-neutral'>
              {intl.get('PROGRAM_PAGE.INSIGHTS.CAPTION')}
            </Typography>
          </div>
          <div data-testid='prg_insights_pie_chart' className='w-2/5'>
            <PieChartComponent projectDetails={projectDetails} />
          </div>
        </>
      ) : (
        <>
          <span id='part-element' className='flex flex-col w-1/4'>
            <Icon src={Rocket} className='w-full h-full' />
          </span>
          <div className='flex flex-col pt-5'>
            <Typography variant='h4' className='flex flex-row pb-2'>
              {intl.get('PROGRAM_PAGE.INSIGHTS.CONGRATULATIONS')}
            </Typography>
            <Typography variant='body2' className='flex flex-row'>
              {intl.get('PROGRAM_PAGE.INSIGHTS.CONGRATULATIONS_MESSAGE_1')}
            </Typography>
            <Typography variant='body2' className='flex flex-row'>
              {intl.get('PROGRAM_PAGE.INSIGHTS.CONGRATULATIONS_MESSAGE_2')}
            </Typography>
          </div>
        </>
      )}
      <div className='w-3/5 flex flex-row'>
        <div className='w-1/2 flex-col'>
          <div className={insightsColumn1Classes} data-cy='projects-completed'>
            <div className='flex flex-row pt-4'>
              <Icon src={kanbanCardIcon} className='text-2xl' />
              <Typography variant='label' className={insightsTextClasses}>
                {intl.get('PROGRAM_PAGE.INSIGHTS.PROJECTS_COMPLETED')}
              </Typography>
            </div>
            <Typography variant='body' className={insightsValueClasses}>
              {projectDetails.totalNumberOfProjectsCompleted}/
              {projectDetails.totalNumberOfProjects}
            </Typography>
          </div>
          <div
            className={insightsColumn2Classes}
            data-cy='average-task-completion'
          >
            <div className='flex flex-row pt-4'>
              <Icon src={taskCompletionIcon} className='text-2xl' />
              <Typography variant='label' className={insightsTextClasses}>
                {intl.get('PROGRAM_PAGE.INSIGHTS.AVERAGE_TASK_COMPLETION')}
              </Typography>
            </div>
            <Typography variant='body' className={insightsValueClasses}>
              {projectDetails.averageTaskCompletionPercentage}%
            </Typography>
          </div>
        </div>
        <div className='w-1/2 flex-col '>
          <div className={insightsColumn1Classes} data-cy='projects-at-risk'>
            <div className='flex flex-row pt-4'>
              <Icon src={atRiskIcon} className='text-2xl' />
              <Typography variant='label' className={insightsTextClasses}>
                {intl.get('PROGRAM_PAGE.INSIGHTS.PROJECTS_AT_RISK')}
              </Typography>
            </div>
            <Typography variant='body' className={insightsValueClasses}>
              {projectDetails.totalNumberOfProjectsAtRisk}
            </Typography>
          </div>
          <div className={insightsColumn2Classes} data-cy='projects-overdue'>
            <div className='flex flex-row pt-4'>
              <Icon src={dueDateIcon} className='text-2xl' />
              <Typography variant='label' className={insightsTextClasses}>
                {intl.get('PROGRAM_PAGE.INSIGHTS.PROJECTS_OVERDUE')}
              </Typography>
            </div>
            <Typography variant='body' className={insightsValueClasses}>
              {projectDetails.totalNumberOfProjectsOverdue}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramInsights;
