import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import {
  FormItem,
  UsersPicker,
  Datepicker,
  tailwindOverride,
  Typography,
  Tag,
  Checkbox,
  Icon,
} from '@getsynapse/design-system';
import { selectLDUsersForDropdown } from 'state/UsersManagement/usersManagementSlice';
import { selectProjectProcesses } from 'state/Processes/processesSlice';
import { PROJECT_PRIVACY, PROJECTS_TABLE_FILTERS } from 'utils/constants';
import { getInitialValueForDropDown } from 'utils/functions';
import { Option, rangeDateType, UserOption } from 'utils/customTypes';
import {
  ProjectFiltersKey,
  ProjectFilters,
  RangeFilter,
} from 'utils/types/filters';
import { isDateRangeFilterEmpty, getUpdatedProcessesFilters } from './helpers';
import useProjectFiltersOptions from './hooks/useProjectFiltersOptions';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';
import MultipleOptionListItem from 'Molecules/MultipleOptionsListItem/MultipleOptionsListItem';
import { selectFieldsUsedInProjects } from 'state/CustomFields/customFieldsSlice';
import classNames from 'classnames';

type Props = {
  filters?: ProjectFilters;
  onUpdateFilters: (newFilters: ProjectFilters) => void;
  isBoardView?: boolean;
  setShowCustomFilters: React.Dispatch<React.SetStateAction<boolean>>;
  showAnimation: boolean;
};

export interface StageOption extends Option {
  process: string;
}

const FiltersForm: React.FC<Props> = ({
  filters = {},
  onUpdateFilters,
  isBoardView,
  setShowCustomFilters,
  showAnimation,
}) => {
  const fields = useSelector(selectFieldsUsedInProjects);
  const ldUsers = useSelector(selectLDUsersForDropdown);
  const orgProcesses = useSelector(selectProjectProcesses);
  const { getFilterOptionsByKey } = useProjectFiltersOptions();

  const numberOfCustomFilters = useMemo(
    () =>
      Object.keys(filters).filter(
        (key) =>
          !Object.values(PROJECTS_TABLE_FILTERS).includes(
            key as ProjectFiltersKey
          )
      ).length,
    [filters]
  );

  const getDateFilterInitialValue = (
    filterKey: ProjectFiltersKey,
    dateFilterRange: 'from' | 'to'
  ) => {
    const dateFilter = filters[filterKey] as RangeFilter;
    return dateFilter?.[dateFilterRange]
      ? new Date(dateFilter?.[dateFilterRange])
      : null;
  };

  const getOwnersFilterInitialValue = (filterKey: ProjectFiltersKey) => {
    const owners = filters[filterKey] as string[];
    if (owners && owners.length > 0) {
      return ldUsers.filter((user) => owners.includes(user.value));
    }
    return [];
  };

  const selectDependentFilters = (
    filterKey: ProjectFiltersKey,
    value: Option[] | rangeDateType,
    selectedFilters: ProjectFilters
  ) => {
    let updatedFilters = { ...selectedFilters };
    const selectedValue = value as Option[];
    if (filterKey === PROJECTS_TABLE_FILTERS.STAGE) {
      updatedFilters = getUpdatedProcessesFilters(
        selectedValue,
        orgProcesses,
        updatedFilters
      );
    }

    if (filterKey === PROJECTS_TABLE_FILTERS.TEAMS) {
      const currentPrivacyFilters = selectedFilters[
        PROJECTS_TABLE_FILTERS.PRIVACY
      ] as string[];
      if (
        !currentPrivacyFilters ||
        !currentPrivacyFilters.includes(PROJECT_PRIVACY.TEAM)
      ) {
        updatedFilters[PROJECTS_TABLE_FILTERS.PRIVACY] = currentPrivacyFilters
          ? currentPrivacyFilters.concat(PROJECT_PRIVACY.TEAM)
          : [PROJECT_PRIVACY.TEAM];
      }
    }
    return updatedFilters;
  };

  const updateFilters = (
    filterKey: ProjectFiltersKey,
    value: Option[] | rangeDateType | boolean,
    dateFilterKey: 'from' | 'to' | null = null
  ) => {
    let filtersCopy = { ...filters } as ProjectFilters;

    if (filterKey === PROJECTS_TABLE_FILTERS.ARCHIVED) {
      const booleanValue = value as boolean;
      value
        ? (filtersCopy[filterKey] = booleanValue)
        : delete filtersCopy[filterKey];
    } else if (
      filterKey === PROJECTS_TABLE_FILTERS.START_DATE ||
      filterKey === PROJECTS_TABLE_FILTERS.TARGET_COMPLETION_DATE ||
      filterKey === PROJECTS_TABLE_FILTERS.ACTUAL_COMPLETION_DATE
    ) {
      const filterValue = value as rangeDateType;
      filtersCopy[filterKey] = {
        ...filtersCopy[filterKey],
        [dateFilterKey as string]: filterValue.startDate
          ? filterValue.startDate.toString()
          : null,
      };
      if (isDateRangeFilterEmpty(filtersCopy[filterKey] as RangeFilter)) {
        delete filtersCopy[filterKey];
      }
    } else {
      const optionValue = value as Option[];
      if (optionValue.length === 0) {
        delete filtersCopy[filterKey];
      } else {
        filtersCopy[filterKey] = optionValue.map(
          (option) => option.value
        ) as string[];
        filtersCopy = selectDependentFilters(
          filterKey,
          optionValue,
          filtersCopy
        );
      }
    }
    onUpdateFilters(filtersCopy);
  };

  const statusOptions = getFilterOptionsByKey(PROJECTS_TABLE_FILTERS.STATUS);
  const priorityOptions = getFilterOptionsByKey(
    PROJECTS_TABLE_FILTERS.PRIORITY
  );
  const healthOptions = getFilterOptionsByKey(PROJECTS_TABLE_FILTERS.HEALTH);
  const businessUnitsOptions = getFilterOptionsByKey(
    PROJECTS_TABLE_FILTERS.BUSINESS_TEAMS
  );
  const privacyOptions = getFilterOptionsByKey(PROJECTS_TABLE_FILTERS.PRIVACY);
  const teamOptions = getFilterOptionsByKey(PROJECTS_TABLE_FILTERS.TEAMS);
  const projectCategorOptions = getFilterOptionsByKey(
    PROJECTS_TABLE_FILTERS.CATEGORY
  );
  const resourcingTypeOptions = getFilterOptionsByKey(
    PROJECTS_TABLE_FILTERS.RESOURCING_TYPE
  );
  const budgetSourceOptions = getFilterOptionsByKey(
    PROJECTS_TABLE_FILTERS.BUDGET_SOURCE
  );
  const processOptions = getFilterOptionsByKey(PROJECTS_TABLE_FILTERS.PROCESS);
  const stageOptions = getFilterOptionsByKey(PROJECTS_TABLE_FILTERS.STAGE);

  const customStageOptionRender = (
    option: StageOption,
    isSelected: boolean,
    selectOption: () => void,
    { className, ...otherProps }: { className: string }
  ) => {
    const selectedStages = filters[PROJECTS_TABLE_FILTERS.STAGE] as string[];
    const isStageSelected = selectedStages?.includes(option.value) || false;
    return (
      <li
        {...otherProps}
        key={option.value}
        className={tailwindOverride('group flex items-center', className, {
          'hover:bg-primary focus-visible:bg-primary': isStageSelected,
        })}
      >
        <MultipleOptionListItem
          label={option.label}
          isSelected={isStageSelected}
          selectOption={selectOption}
          value={option.value}
          className='truncate'
        />
        <Typography
          variant='label'
          className={tailwindOverride('text-primary-dark truncate ml-2', {
            'text-neutral-white': isStageSelected,
          })}
        >
          {`(${option.process})`}
        </Typography>
      </li>
    );
  };

  return (
    <div
      className={classNames('mt-8 flex flex-col space-y-5', {
        'animate-snackarNotification': showAnimation,
      })}
    >
      <button
        className='disabled:text-primary-lighter text-purple-dark hover:text-purple-darker focus-visible:text-purple-darker active:text-purple-darker py-1 focus:outline-none text-sm leading-6 focus:ring-0'
        onClick={() => setShowCustomFilters(true)}
        data-testid='custom-filters-button'
        disabled={!fields?.length}
      >
        <div className='flex items-center'>
          {`${intl.get('FILTER_GENERAL.FIELDS_FILTERS')} ${
            numberOfCustomFilters ? `(${numberOfCustomFilters})` : ''
          }`}
          <Icon name='arrow-forward' className='ml-2 text-xl' />
        </div>
      </button>
      <Checkbox
        label={intl.get('PROJECT_DETAIL.ARCHIVED')}
        onChange={(event) =>
          updateFilters(PROJECTS_TABLE_FILTERS.ARCHIVED, event.target.checked)
        }
        checked={get(filters, PROJECTS_TABLE_FILTERS.ARCHIVED) === true}
        inputProps={{ 'data-cy': 'projects-filters__archived' }}
      />
      <FormItem label={intl.get('PROJECT_DETAIL.STATUS')}>
        <MultiSelectDropdown
          options={statusOptions}
          values={getInitialValueForDropDown(
            statusOptions,
            filters && (filters[PROJECTS_TABLE_FILTERS.STATUS] as string[])
          )}
          placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
          onChange={(option: Option[]) =>
            updateFilters(PROJECTS_TABLE_FILTERS.STATUS, option)
          }
          triggerProps={{
            'aria-label': intl.get('PROJECT_DETAIL.STATUS'),
            'data-cy': 'projects-filters__status',
          }}
          listProps={{ 'data-cy': 'projects-filters__status__options-list' }}
        />
      </FormItem>
      <FormItem label={intl.get('PROJECT_DETAIL.PRIORITY')}>
        <MultiSelectDropdown
          onChange={(option: Option[]) =>
            updateFilters(PROJECTS_TABLE_FILTERS.PRIORITY, option)
          }
          values={getInitialValueForDropDown(
            priorityOptions,
            filters && (filters[PROJECTS_TABLE_FILTERS.PRIORITY] as string[])
          )}
          options={priorityOptions}
          placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
          triggerProps={{
            'aria-label': intl.get('PROJECT_DETAIL.PRIORITY'),
            'data-cy': 'projects-filters__priority',
          }}
          listProps={{ 'data-cy': 'projects-filters__priority__options-list' }}
        />
      </FormItem>
      <FormItem label={intl.get('PROJECT_DETAIL.PROJECT_OWNERS')}>
        <UsersPicker
          usersList={ldUsers}
          selectedUsersList={getOwnersFilterInitialValue(
            PROJECTS_TABLE_FILTERS.OWNERS
          )}
          onChange={(users: UserOption[]) =>
            updateFilters(PROJECTS_TABLE_FILTERS.OWNERS, users)
          }
          triggerText={intl.get('FILTER_GENERAL.PLACEHOLDER')}
          triggerProps={{
            'aria-label': intl.get('PROJECT_DETAIL.PROJECT_OWNERS'),
            'data-cy': 'projects-filters__owners',
          }}
          popperProps={{ 'data-cy': 'projects-filters__owners__options-list' }}
          allowSelectDeactivated
        />
      </FormItem>
      <FormItem label={intl.get('PROJECT_DETAIL.HEALTH')}>
        <MultiSelectDropdown
          options={healthOptions}
          values={getInitialValueForDropDown(
            healthOptions,
            filters && (filters[PROJECTS_TABLE_FILTERS.HEALTH] as string[])
          )}
          onChange={(option: Option[]) =>
            updateFilters(PROJECTS_TABLE_FILTERS.HEALTH, option)
          }
          placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
          triggerProps={{
            'aria-label': intl.get('PROJECT_DETAIL.HEALTH'),
            'data-cy': 'projects-filters__health',
          }}
          listProps={{
            'data-cy': 'projects-filters__health__options-list',
          }}
        />
      </FormItem>
      <FormItem label={intl.get('PROJECT_DETAIL.BUSINESS_UNIT')}>
        <MultiSelectDropdown
          options={businessUnitsOptions}
          values={getInitialValueForDropDown(
            businessUnitsOptions,
            filters &&
              (filters[PROJECTS_TABLE_FILTERS.BUSINESS_TEAMS] as string[])
          )}
          onChange={(option: Option[]) =>
            updateFilters(PROJECTS_TABLE_FILTERS.BUSINESS_TEAMS, option)
          }
          placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
          triggerProps={{
            'aria-label': intl.get('PROJECT_DETAIL.BUSINESS_UNIT'),
            'data-cy': 'projects-filters__business-teams',
          }}
          listProps={{
            'data-cy': 'projects-filters__business-teams__options-list',
          }}
        />
      </FormItem>
      <FormItem label={intl.get('PROJECT_DETAIL.PRIVACY')}>
        <MultiSelectDropdown
          values={getInitialValueForDropDown(
            privacyOptions,
            filters && (filters[PROJECTS_TABLE_FILTERS.PRIVACY] as string[])
          )}
          onChange={(option: Option[]) =>
            updateFilters(PROJECTS_TABLE_FILTERS.PRIVACY, option)
          }
          options={privacyOptions}
          placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
          triggerProps={{
            'aria-label': intl.get('PROJECT_DETAIL.PRIVACY'),
            'data-cy': 'projects-filters__privacy',
          }}
          listProps={{
            'data-cy': 'projects-filters__privacy__options-list',
          }}
        />
      </FormItem>
      <FormItem label={intl.get('PROJECT_DETAIL.TEAM')}>
        <MultiSelectDropdown
          options={teamOptions}
          values={getInitialValueForDropDown(
            teamOptions,
            filters && (filters[PROJECTS_TABLE_FILTERS.TEAMS] as string[])
          )}
          onChange={(option: Option[]) =>
            updateFilters(PROJECTS_TABLE_FILTERS.TEAMS, option)
          }
          placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
          triggerProps={{
            'aria-label': intl.get('PROJECT_DETAIL.TEAM'),
            'data-cy': 'projects-filters__teams',
          }}
          listProps={{
            'data-cy': 'projects-filters__teams__options-list',
          }}
        />
      </FormItem>
      <FormItem label={intl.get('PROJECT_DETAIL.CATEGORY')}>
        <MultiSelectDropdown
          options={projectCategorOptions}
          values={getInitialValueForDropDown(
            projectCategorOptions,
            filters && (filters[PROJECTS_TABLE_FILTERS.CATEGORY] as string[])
          )}
          onChange={(option: Option[]) =>
            updateFilters(PROJECTS_TABLE_FILTERS.CATEGORY, option)
          }
          placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
          triggerProps={{
            'aria-label': intl.get('PROJECT_DETAIL.CATEGORY'),
            'data-cy': 'projects-filters__categories',
          }}
          listProps={{
            'data-cy': 'projects-filters__categories__options-list',
          }}
        />
      </FormItem>
      <FormItem label={intl.get('PROJECT_DETAIL.START_DATE')}>
        <div className='w-full flex space-x-4'>
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.FROM')}
            startDate={getDateFilterInitialValue(
              PROJECTS_TABLE_FILTERS.START_DATE,
              'from'
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(PROJECTS_TABLE_FILTERS.START_DATE, date, 'from')
            }
            inputProps={{
              'aria-label': `${intl.get(
                'PROJECT_DETAIL.START_DATE'
              )}__from-range`,
              'data-cy': 'projects-filters__start-date__from-range',
            }}
          />
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.TO')}
            startDate={getDateFilterInitialValue(
              PROJECTS_TABLE_FILTERS.START_DATE,
              'to'
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(PROJECTS_TABLE_FILTERS.START_DATE, date, 'to')
            }
            minDate={getDateFilterInitialValue(
              PROJECTS_TABLE_FILTERS.START_DATE,
              'from'
            )}
            inputProps={{
              'aria-label': `${intl.get(
                'PROJECT_DETAIL.START_DATE'
              )}__to-range`,
              'data-cy': 'projects-filters__start-date__to-range',
            }}
          />
        </div>
      </FormItem>
      <FormItem label={intl.get('PROJECT_DETAIL.END_DATE')}>
        <div className='w-full flex space-x-4'>
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.FROM')}
            startDate={getDateFilterInitialValue(
              PROJECTS_TABLE_FILTERS.TARGET_COMPLETION_DATE,
              'from'
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(
                PROJECTS_TABLE_FILTERS.TARGET_COMPLETION_DATE,
                date,
                'from'
              )
            }
            inputProps={{
              'aria-label': `${intl.get(
                'PROJECT_DETAIL.END_DATE'
              )}__from-range`,
              'data-cy': 'projects-filters__end-date__from-range',
            }}
          />
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.TO')}
            startDate={getDateFilterInitialValue(
              PROJECTS_TABLE_FILTERS.TARGET_COMPLETION_DATE,
              'to'
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(
                PROJECTS_TABLE_FILTERS.TARGET_COMPLETION_DATE,
                date,
                'to'
              )
            }
            minDate={getDateFilterInitialValue(
              PROJECTS_TABLE_FILTERS.TARGET_COMPLETION_DATE,
              'from'
            )}
            inputProps={{
              'aria-label': `${intl.get('PROJECT_DETAIL.END_DATE')}__to-range`,
              'data-cy': 'projects-filters__end-date__to-range',
            }}
          />
        </div>
      </FormItem>
      <FormItem label={intl.get('PROJECT_DETAIL.ACTUAL_COMPLETION_DATE')}>
        <div className='w-full flex space-x-4'>
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.FROM')}
            startDate={getDateFilterInitialValue(
              PROJECTS_TABLE_FILTERS.ACTUAL_COMPLETION_DATE,
              'from'
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(
                PROJECTS_TABLE_FILTERS.ACTUAL_COMPLETION_DATE,
                date,
                'from'
              )
            }
            inputProps={{
              'aria-label': `${intl.get(
                'PROJECT_DETAIL.ACTUAL_COMPLETION_DATE'
              )}__from-range`,
              'data-cy': 'projects-filters__actual-date__from-range',
            }}
          />
          <Datepicker
            className='w-full'
            startPlaceHolder={intl.get('FILTER_GENERAL.TO')}
            startDate={getDateFilterInitialValue(
              PROJECTS_TABLE_FILTERS.ACTUAL_COMPLETION_DATE,
              'to'
            )}
            onPickDate={(date: rangeDateType) =>
              updateFilters(
                PROJECTS_TABLE_FILTERS.ACTUAL_COMPLETION_DATE,
                date,
                'to'
              )
            }
            inputProps={{
              'aria-label': `${intl.get(
                'PROJECT_DETAIL.ACTUAL_COMPLETION_DATE'
              )}__to-range`,
              'data-cy': 'projects-filters__actual-date__to-range',
            }}
          />
        </div>
      </FormItem>
      <FormItem label={intl.get('PROJECT_DETAIL.RESOURCING_TYPE')}>
        <MultiSelectDropdown
          onChange={(option: Option[]) =>
            updateFilters(PROJECTS_TABLE_FILTERS.RESOURCING_TYPE, option)
          }
          options={resourcingTypeOptions}
          placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
          values={getInitialValueForDropDown(
            resourcingTypeOptions,
            filters &&
              (filters[PROJECTS_TABLE_FILTERS.RESOURCING_TYPE] as string[])
          )}
          triggerProps={{
            'aria-label': intl.get('PROJECT_DETAIL.RESOURCING_TYPE'),
            'data-cy': 'projects-filters__resourcing-type',
          }}
          listProps={{
            'data-cy': 'projects-filters__resourcing-type__options-list',
          }}
        />
      </FormItem>
      <FormItem label={intl.get('PROJECT_DETAIL.BUDGET_SOURCE')}>
        <MultiSelectDropdown
          onChange={(option: Option[]) =>
            updateFilters(PROJECTS_TABLE_FILTERS.BUDGET_SOURCE, option)
          }
          options={budgetSourceOptions}
          placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
          values={getInitialValueForDropDown(
            budgetSourceOptions,
            filters &&
              (filters[PROJECTS_TABLE_FILTERS.BUDGET_SOURCE] as string[])
          )}
          triggerProps={{
            'aria-label': intl.get('PROJECT_DETAIL.BUDGET_SOURCE'),
            'data-cy': 'projects-filters__budget-source',
          }}
          listProps={{
            'data-cy': 'projects-filters__budget-source__options-list',
          }}
        />
      </FormItem>
      {!isBoardView && (
        <>
          <FormItem label={intl.get('PROJECT_DETAIL.PROCESS')}>
            <MultiSelectDropdown
              options={processOptions}
              values={getInitialValueForDropDown(
                processOptions,
                filters && (filters[PROJECTS_TABLE_FILTERS.PROCESS] as string[])
              )}
              onChange={(option: Option[]) =>
                updateFilters(PROJECTS_TABLE_FILTERS.PROCESS, option)
              }
              placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
              triggerProps={{
                'aria-label': intl.get('PROJECT_DETAIL.PROCESS'),
                'data-cy': 'projects-filters__process',
              }}
              listProps={{
                'data-cy': 'projects-filters__process__options-list',
              }}
            />
          </FormItem>
          <FormItem label={intl.get('PROJECT_DETAIL.STAGE')}>
            <MultiSelectDropdown
              values={getInitialValueForDropDown(
                stageOptions,
                filters && (filters[PROJECTS_TABLE_FILTERS.STAGE] as string[])
              )}
              onChange={(option: Option[]) =>
                updateFilters(PROJECTS_TABLE_FILTERS.STAGE, option)
              }
              options={stageOptions}
              placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
              triggerProps={{
                'aria-label': intl.get('PROJECT_DETAIL.STAGE'),
                'data-cy': 'projects-filters__stage',
              }}
              listProps={{
                'data-cy': 'projects-filters__stage__options-list',
              }}
              renderOption={customStageOptionRender}
              getOptionLabel={(option: StageOption) =>
                `${option.label}-${option.value}`
              }
              renderSelectedOptionTag={(tagProps, selectedOption) => (
                <Tag
                  {...tagProps}
                  key={(selectedOption as Option).value}
                  label={(selectedOption as Option).label}
                />
              )}
            />
          </FormItem>
        </>
      )}
    </div>
  );
};

export default FiltersForm;
