import React, { useMemo } from 'react';
import get from 'lodash/get';

import intl from 'react-intl-universal';
import {
  MentionsInput,
  Mention,
  SuggestionDataItem,
  OnChangeHandlerFunc,
} from 'react-mentions';
import {
  Avatar,
  Tag,
  Typography,
  tailwindOverride,
} from '@getsynapse/design-system';
import { UserAvatars } from 'utils/customTypes';

type UserDataSuggestion = {
  avatar?: { imageSrc: string; initial: string; name: string };
  disabled?: boolean;
} & SuggestionDataItem;

const CustomSuggestion: React.FC<{
  suggestion: UserDataSuggestion;
  search: string;
  highlightedDisplay: React.ReactNode;
  index: number;
  focused: boolean;
}> = ({ suggestion, focused }) => {
  return (
    <div
      className={tailwindOverride(
        'w-full flex items-center justify-between px-4 py-2',
        {
          'bg-neutral-black': focused,
        }
      )}
    >
      <div className='flex items-center'>
        <Avatar
          imageSrc={get(suggestion, 'avatar.imageSrc')}
          initial={get(suggestion, 'avatar.initial')}
          size='small'
          disabled={suggestion.disabled}
        />
        <Typography
          variant='body2'
          className={tailwindOverride('ml-2', {
            'text-neutral-black': !focused,
            'text-neutral-white': focused,
            'text-neutral-light': suggestion.disabled,
          })}
        >
          {suggestion.display}
        </Typography>
      </div>
      {suggestion.disabled && (
        <Tag
          label={intl.get('DEACTIVATED')}
          className='bg-neutral-lighter-two ml-2'
          textClassName='text-neutral-dark'
        />
      )}
    </div>
  );
};

const MentionsTextField: React.FC<{
  placeholder?: string;
  value: string;
  onChange: OnChangeHandlerFunc;
  mentionableUsers: UserAvatars[];
  inputClassName?: string;
  disabled?: boolean;
}> = ({
  placeholder,
  value,
  onChange,
  mentionableUsers,
  inputClassName,
  disabled = false,
}) => {
  const userOptions = useMemo(
    () =>
      mentionableUsers.map((user) => ({
        id: user.value,
        display: user.label,
        avatar: user.avatar,
        disabled: user.disabled,
      })),
    [mentionableUsers]
  );

  const handleDisplayTransform = (id: string, display: string) => `@${display}`;

  return (
    <MentionsInput
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={inputClassName}
      forceSuggestionsAboveCursor={true}
      data-cy='add-new-comment'
      disabled={disabled}
    >
      <Mention
        appendSpaceOnAdd
        trigger='@'
        data={userOptions}
        displayTransform={handleDisplayTransform}
        className={`${inputClassName}--mention`}
        renderSuggestion={(
          suggestion: UserDataSuggestion,
          search: string,
          highlightedDisplay: React.ReactNode,
          index: number,
          focused: boolean
        ) => (
          <CustomSuggestion
            suggestion={suggestion}
            search={search}
            highlightedDisplay={highlightedDisplay}
            index={index}
            focused={focused}
          />
        )}
      />
    </MentionsInput>
  );
};

export default MentionsTextField;
