import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  Button,
  Dropdown,
  FormItem,
  Modal,
  tailwindOverride,
  Typography,
} from '@getsynapse/design-system';
import { getForms, selectForms } from 'state/Forms/formSlice';
import AddRequestIcon from 'assets/icons/add-project-icon.svg';
import { useImport } from 'Hooks/useImport';
import importIcon from 'assets/icons/import.svg';
import { Form } from 'utils/customTypes';

import useImportRequests from 'Pages/RequestsListPage/hooks/useImportRequests';

interface ImportRequestModalProps {
  showModal: boolean;
  onClose: () => void;
}

const ImportRequestModal: React.FC<ImportRequestModalProps> = ({
  showModal,
  onClose,
}) => {
  const dispatch = useDispatch();
  const requestForms = useSelector(selectForms);
  const [selectedForm, setSelectedForm] = useState<Form>();
  const [isLoading, setIsLoading] = useState(false);
  const onCloseModal = () => {
    setSelectedForm(undefined);
    onClose();
  };

  const { onData, currentUser, settings } = useImportRequests(selectedForm);
  const { startImport } = useImport(
    onData,
    currentUser,
    settings,
    onCloseModal
  );

  const onStartImport = () => {
    setIsLoading(true);
    startImport();
    setTimeout(() => setIsLoading(false), 1500);
  };

  useEffect(() => {
    dispatch(
      getForms({
        organizationId: currentUser.organization_id!,
        published: true,
      })
    );
  }, [dispatch, currentUser.organization_id]);

  const selectForm: (formId: string) => void = useCallback(
    (formId) => {
      const selectedValue = requestForms.find(({ id }) => id === formId);
      if (selectedValue) {
        setSelectedForm(selectedValue);
      }
    },
    [requestForms]
  );

  return (
    <Modal
      title={intl.get('IMPORT_REQUEST_MODAL.TITLE')}
      titleIcon={{ src: AddRequestIcon }}
      isOpen={showModal}
      size='large'
      closeModal={onCloseModal}
      aria-label={intl.get('IMPORT_REQUEST_MODAL.TITLE')}
      data-testid='import-request-select-form-modal'
    >
      <Typography variant='body' className='mb-4 text-primary'>
        {intl.get('IMPORT_REQUEST_MODAL.DESCRIPTION')}
      </Typography>
      <FormItem
        label={intl.get('IMPORT_REQUEST_MODAL.LABEL')}
        labelProps={{ required: true }}
        className='w-88'
      >
        <Dropdown
          options={requestForms
            .slice()
            .sort((a, b) => a.title!.localeCompare(b.title!))}
          getOptionLabel={(option: Form) => option.title!}
          onChange={(option: Form) => selectForm(option.id!)}
          triggerProps={{ 'data-testid': 'select-form' }}
          renderOption={(
            option: Form,
            isSelected: boolean,
            selectOption,
            { className, ...otherProps }
          ) => (
            <li
              {...otherProps}
              data-value={option.id}
              data-testid={option.id}
              key={option.id}
              className={tailwindOverride('group', className, {
                'hover:bg-primary focus-visible:bg-primary': isSelected,
              })}
              onClick={selectOption}
            >
              <div className='flex w-100 flex-col space-y-1 '>
                <Typography
                  variant='label'
                  weight='medium'
                  className='text-primary'
                >
                  {option.title}
                </Typography>
                {option.description && (
                  <Typography variant='caption' className='text-neutral'>
                    {option.description}
                  </Typography>
                )}
              </div>
            </li>
          )}
        />
      </FormItem>

      <div className='flex mt-8 space-x-3'>
        <Button
          key={selectedForm?.id}
          color='default'
          iconPosition='left'
          size='small'
          variant='primary'
          iconSrc={importIcon}
          onClick={onStartImport}
          disabled={!selectedForm}
          loading={isLoading}
          className='mr-2'
        >
          {intl.get('IMPORT')}
        </Button>
      </div>
    </Modal>
  );
};

export default ImportRequestModal;
