import { Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import ErrorScreen from '../../Components/ErrorScreen';
import StatusCardSkeletonLoader from './StatusCardSkeletonLoader';
import { SLICE_STATUS } from 'utils/constants';
import { GeneralInsights } from 'utils/customTypes';

const ProjectStatusCard = ({
  generalProjectsInsightsData,
}: {
  generalProjectsInsightsData: GeneralInsights;
}) => {
  const {
    new: newProjects,
    canceled: cancelledProjects,
    completed,
    status,
    completedWithinTimeline,
  } = generalProjectsInsightsData;

  return status === SLICE_STATUS.LOADING ? (
    <StatusCardSkeletonLoader />
  ) : status === SLICE_STATUS.FAILED ? (
    <ErrorScreen />
  ) : (
    <div className='flex h-full justify-between items-center m-auto px-6'>
      <div className='flex space-x-2'>
        <span className='text-5xl font-bold'>{newProjects}</span>
        <Typography variant='caption' weight='medium' className='py-2 w-4'>
          {intl.get('INSIGHTS_PAGE.PROJECTS.NEW_PROJECTS')}
        </Typography>
      </div>

      <div className='flex space-x-2'>
        <span className='text-5xl font-bold'>
          {cancelledProjects.toString().padStart(2, '0')}
        </span>
        <Typography variant='caption' weight='medium' className='py-2 w-4'>
          {intl.get('INSIGHTS_PAGE.PROJECTS.CANCELLED_PROJECTS')}
        </Typography>
      </div>

      <div className='flex space-x-2'>
        <span className='text-5xl font-bold'>
          {completed.toString().padStart(2, '0')}
        </span>
        <Typography variant='caption' weight='medium' className='py-2 w-4'>
          {intl.get('INSIGHTS_PAGE.PROJECTS.COMPLETED_PROJECTS')}
        </Typography>
      </div>

      <div className='pt-2'>
        <div className='flex'>
          <Typography variant='caption' className='text-neutral'>
            {completedWithinTimeline.toString().padStart(2, '0')}{' '}
            {intl.get('INSIGHTS_PAGE.PROJECTS.COMPLETED_WITHIN_TIMELINE')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ProjectStatusCard;
