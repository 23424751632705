import { useHistory, Link } from 'react-router-dom';
import MyWorkspaceTable from '../MyWorkspaceTable';
import intl from 'react-intl-universal';
import { PATHS } from 'utils/constants';
import get from 'lodash/get';
import { Request, AvatarUser } from 'utils/customTypes';
import UserAvatar from 'Atoms/UserAvatar';
import { tailwindOverride } from '@getsynapse/design-system';
import { isUserStatusDisabled } from 'utils/functions';

type RequestsTableProps = {
  data: Request[];
  handleSort: (orderByParam: string[], order: 'desc' | 'asc') => void;
  cardTable: boolean;
};

const RequestsTable = ({ data, handleSort, cardTable }: RequestsTableProps) => {
  const history = useHistory();
  return (
    <MyWorkspaceTable
      cardTable={cardTable}
      dataCy='requests-in-review-table'
      headCells={[
        {
          content: intl.get(
            'MY_WORKSPACE.IN_REVIEW_REQUESTS.TABLE.HEAD_CELLS.TITLE'
          ),
          columnName: 'title',
        },
        ...(!cardTable
          ? [
              {
                content: intl.get(
                  'MY_WORKSPACE.IN_REVIEW_REQUESTS.TABLE.HEAD_CELLS.DAYS_IN_REVIEW'
                ),
                columnName: 'daysInReview',
                className: 'w-32',
              },
            ]
          : []),
        {
          content: intl.get(
            'MY_WORKSPACE.IN_REVIEW_REQUESTS.TABLE.HEAD_CELLS.REQUESTER'
          ),
          columnName: 'requesterName',
          className: 'w-48',
        },
        {
          content: intl.get(
            'MY_WORKSPACE.IN_REVIEW_REQUESTS.TABLE.HEAD_CELLS.PRIORITY'
          ),
          columnName: 'ldPriority',
          className: 'w-28',
        },
      ]}
      rows={data.map((request) => ({
        id: request.id,
        className: 'table w-full table-fixed cursor-pointer',
        onClick: () => history.push(`${PATHS.REQUEST_PAGE}/${request.id}`),
        cells: [
          {
            content: (
              <Link
                to={`${PATHS.REQUEST_PAGE}/${request.id}`}
                title={request.title}
              >
                {request.title}
              </Link>
            ),
            className: 'truncate',
          },
          ...(!cardTable
            ? [
                {
                  content: intl.get(
                    'MY_WORKSPACE.IN_REVIEW_REQUESTS.TABLE.DAYS',
                    { days: get(request, 'daysInReview') }
                  ),
                  className: 'w-32',
                },
              ]
            : []),
          {
            content: (
              <div className='flex items-center'>
                <UserAvatar
                  user={request.requester as AvatarUser}
                  size='small'
                />
                <span
                  className={tailwindOverride('ml-2.5', {
                    'text-neutral-light': isUserStatusDisabled(
                      request.requester.status
                    ),
                  })}
                >
                  {get(request, 'requesterName')}
                </span>
              </div>
            ),
            className: 'w-48 py-1',
          },
          {
            content: request.ldPriority,
            className: 'capitalize w-28',
          },
        ],
      }))}
      handleSort={handleSort}
    />
  );
};

export default RequestsTable;
