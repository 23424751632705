import { useState, useMemo } from 'react';
import { Typography, Button, Tooltip } from '@getsynapse/design-system';
import classnames from 'classnames';
import { DeleteProgramFlag, Program } from 'utils/customTypes';
import {
  PROJECT_STATUS,
  PROJECT_HEALTH,
  DELETE_PROGRAM_OPTIONS,
  PATHS,
} from 'utils/constants';
import intl from 'react-intl-universal';
import LinkProjectsModal from './LinkProjectsModal';
import ProgramInsights from './ProgramInsights';
import moment from 'moment';

import DeleteProgramModal from './DeleteProgramModal';
import { useDispatch } from 'react-redux';
import { deleteProgramAction } from 'state/Programs/programsSlice';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import ProgramProjects from './ProgramProjects/ProgramProjects';
import { formatProgramNumber } from 'utils/formatters';

const ProgramContent = ({
  program,
  userHasWriteAccess,
  changesDetected,
}: {
  program: Program;
  userHasWriteAccess: boolean;
  changesDetected: boolean;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [allProjectsCompleted, setAllProjectsCompleted] = useState(false);

  useMemo(() => {
    if (program.programProjects && program.programProjects.length > 0) {
      const result = program.programProjects.every(
        (project) => project.status === PROJECT_STATUS.COMPLETED
      );
      setAllProjectsCompleted(result);
    }
  }, [program.programProjects]);

  const projectDetails = useMemo(() => {
    let totalTaskCompleted = 0;
    let totalTaskNotCompleted = 0;
    let projectDataToWorkWith = program.updatedProjects || [];
    const excludedProjectStatuses = [
      PROJECT_STATUS.CANCELED,
      PROJECT_STATUS.COMPLETED,
      PROJECT_STATUS.CLOSED,
    ];

    let projectDetails = {
      totalNumberOfProjects: 0,
      totalNumberOfNewProjects: 0,
      totalNumberOfProjectsInQueue: 0,
      totalNumberOfProjectsOnHold: 0,
      totalNumberOfProjectsCompleted: 0,
      totalNumberOfProjectsInProgress: 0,
      averageTaskCompletionPercentage: 0,
      totalNumberOfProjectsAtRisk: 0,
      totalNumberOfProjectsOverdue: 0,
    };
    projectDetails.totalNumberOfProjects =
      (projectDataToWorkWith && projectDataToWorkWith.length) || 0;
    projectDataToWorkWith &&
      projectDataToWorkWith.forEach((project) => {
        project.status === PROJECT_STATUS.NEW &&
          projectDetails.totalNumberOfNewProjects++;
        project.status === PROJECT_STATUS.IN_PLANNING &&
          projectDetails.totalNumberOfProjectsInQueue++;
        project.status === PROJECT_STATUS.ON_HOLD &&
          projectDetails.totalNumberOfProjectsOnHold++;
        project.status === PROJECT_STATUS.COMPLETED &&
          projectDetails.totalNumberOfProjectsCompleted++;
        project.status === PROJECT_STATUS.IN_PROGRESS &&
          projectDetails.totalNumberOfProjectsInProgress++;
        project.health === PROJECT_HEALTH.AT_RISK &&
          projectDetails.totalNumberOfProjectsAtRisk++;
        totalTaskCompleted += project.number_of_completed_tasks;
        totalTaskNotCompleted += project.number_of_not_completed_tasks;
        if (!excludedProjectStatuses.includes(project.status)) {
          moment(new Date(project.targetCompletionDate)).isBefore(moment()) &&
            projectDetails.totalNumberOfProjectsOverdue++;
        }
      });
    const taskCompletionPercentage =
      program.updatedProjects && program.updatedProjects.length === 0
        ? 0
        : Math.round(
            (totalTaskCompleted /
              (totalTaskCompleted + totalTaskNotCompleted)) *
              100
          );
    projectDetails.averageTaskCompletionPercentage = Number.isFinite(
      taskCompletionPercentage
    )
      ? taskCompletionPercentage
      : 0;
    return projectDetails;
  }, [program.updatedProjects]);

  const deleteProgram = async (
    flag: DeleteProgramFlag,
    newProgramId?: string
  ) => {
    const removedProgram = await dispatch(
      deleteProgramAction({
        programId: program.id,
        flag,
        newProgramId: newProgramId || '',
      })
    );
    if (get(removedProgram, 'payload.id')) {
      history.push(PATHS.PROGRAMS_LIST_PAGE);
      dispatch(
        showSnackbarNotification({
          notificationVariant: 'success',
          notificationTitle: intl.get(
            `PROGRAM_PAGE.DELETE_PROGRAM_SUCCESS.${flag}`
          ),
          autoHide: true,
        })
      );
    } else {
      dispatch(
        showSnackbarNotification({
          notificationVariant: 'error',
          notificationTitle: intl.get('PROGRAM_PAGE.DELETE_PROGRAM_ERROR'),
          autoHide: true,
        })
      );
    }
  };

  const handleDeleteProgram = async () => {
    if (changesDetected) return;
    if (program.programProjects.length) {
      setIsDeleteModalOpen(true);
    } else {
      await deleteProgram(DELETE_PROGRAM_OPTIONS.NO_PROJRCTS);
    }
  };

  const deleteButton = (
    <Button
      variant='tertiary'
      iconName='trash'
      onClick={handleDeleteProgram}
      data-cy='delete-program-button'
      disabled={!userHasWriteAccess}
      className={classnames({
        'text-primary-light cursor-not-allowed hover:text-primary-light active:text-primary-light':
          changesDetected,
      })}
    >
      {intl.get('PROGRAM_PAGE.DELETE_PROGRAM')}
    </Button>
  );

  return (
    <>
      <LinkProjectsModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      <DeleteProgramModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        program={program}
        deleteProgram={deleteProgram}
      />
      <div
        className={classnames(
          'bg-neutral-white w-full ml-4 border border-neutral-lighter-two relative',
          'border-b-0 rounded-t-lg rounded-b-none overflow-x-auto'
        )}
      >
        <div className='py-2 flex justify-between	items-center pl-6 pr-2'>
          <Typography variant='body2'>
            <span className='font-semibold'>{`${intl.get(
              'PROGRAM_PAGE.ID'
            )} : `}</span>
            {formatProgramNumber(program.program_number)}
          </Typography>
          {changesDetected ? (
            <Tooltip
              openMode='hover1'
              trigger={deleteButton}
              showPopper
              timeout={0}
            >
              There are unsaved changes.
            </Tooltip>
          ) : (
            deleteButton
          )}
        </div>
        <ProgramInsights
          projectDetails={projectDetails}
          allProjectsCompleted={allProjectsCompleted}
        />
        <div className='w-full flex py-6 px-6'>
          <div className='flex flex-col pb-4'>
            <Typography variant='h5'>
              {intl.get('PROGRAM_PAGE.PROJECTS')}
            </Typography>
            <Typography variant='body2' className='text-neutral'>
              {intl.get('PROGRAM_PAGE.PROJECTS_SUB')}
            </Typography>
          </div>
          {userHasWriteAccess && (
            <Button
              variant='secondary'
              data-cy='link-projects-button'
              className='h-8 ml-auto'
              onClick={() => setIsModalOpen(true)}
            >
              {intl.get('PROGRAM_PAGE.LINK_PROJECTS')}
            </Button>
          )}
        </div>
        <div className='px-6'>
          <ProgramProjects
            projects={program.updatedProjects || []}
            userHasWriteAccess={userHasWriteAccess}
          />
        </div>
      </div>
    </>
  );
};

export default ProgramContent;
