type StartDate = string;
type EndDate = string;
export type DateSegment = [StartDate, EndDate];

export const formatter = new Intl.DateTimeFormat('fr-CA');

export const getStartOfTheWeek = (d: Date): Date => {
  const date = new Date(d);
  const day = date.getDay();
  if (day === 1) {
    return date;
  }
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
};

export function toDateString(segment: Date) {
  return formatter.format(segment);
}

export function toSegmentString(segments: [Date, Date]): [string, string] {
  return segments.map(toDateString) as [string, string];
}

/**
 * Reusable function to avoid needlessly duplicating the logic
 * to get the state/end dates
 * @param items
 * @param numberOfWeeks
 * @returns
 */
export function getDateRange(items: DateSegment[], numberOfWeeks: number) {
  const startDate = items[0][0];
  const endDate = items[numberOfWeeks - 1][1];

  return { startDate, endDate };
}
