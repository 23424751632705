import { Typography } from '@getsynapse/design-system';
import { useEffect, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import {
  selectProjectCategoriesDemand,
  selectProjectCategoriesDemandStatus,
} from 'state/Insights/capacitySlice';
import { SLICE_STATUS } from 'utils/constants';
import ChartSkeletonLoader from './ChartSkeletonLoader';
import NoData from '../components/NoData';
import LoadingError from '../components/LoadingError';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { SVGRenderer } from 'echarts/renderers';
import { BarChart } from 'echarts/charts';
import { PolarComponent, TooltipComponent } from 'echarts/components';
import {
  AXIS_LABELS_STYLE,
  AXIS_NAME_STYLE,
  DEMAND_BY_CATEGORY_COLOR_PALATE,
  GRID_COLOR,
  DATA_LABELS_STYLE,
  HOURS_STYLE,
  TOOLTIP_STYLE,
} from './constants';
import { truncate } from 'lodash';

const DemandByProjectChart = () => {
  echarts.use([BarChart, PolarComponent, SVGRenderer, TooltipComponent]);

  const projectCategoriesDemand = useSelector(selectProjectCategoriesDemand);
  const projectCategoriesDemandStatus = useSelector(
    selectProjectCategoriesDemandStatus
  );

  const [dataStatus, setDataStatus] = useState('');

  const demandData = useMemo(() => {
    let demandValues: Array<string | number> = Array(11).fill('');
    for (let i = 0; i < projectCategoriesDemand?.length!; i++) {
      demandValues[i] = projectCategoriesDemand![i].totalDemand;
    }
    return demandValues!;
  }, [projectCategoriesDemand]);

  const projectCategoriesData = useMemo(() => {
    let categories = Array(11).fill('');
    for (let i = 0; i < projectCategoriesDemand?.length!; i++) {
      categories[i] = projectCategoriesDemand![i].projectCategory;
    }

    return categories!;
  }, [projectCategoriesDemand]);

  useEffect(() => {
    if (projectCategoriesDemandStatus === SLICE_STATUS.LOADING) {
      setDataStatus(projectCategoriesDemandStatus);
    }
    if (
      projectCategoriesDemandStatus === SLICE_STATUS.IDLE &&
      dataStatus === SLICE_STATUS.LOADING
    ) {
      setTimeout(() => {
        setDataStatus(SLICE_STATUS.IDLE);
      }, 500);
    }
  }, [projectCategoriesDemandStatus, dataStatus]);

  const chartOptions = {
    polar: {
      radius: [50, '67%'],
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}',
      ...TOOLTIP_STYLE,
    },
    colorBy: 'data',
    color: DEMAND_BY_CATEGORY_COLOR_PALATE,
    series: {
      type: 'bar',
      backgroundStyle: {
        opacity: 0,
      },
      barCategoryGap: 0,
      data: demandData,
      coordinateSystem: 'polar',
    },
    radiusAxis: {
      axisLine: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: GRID_COLOR,
        },
      },
      z: 10,
      axisTick: {
        show: false,
      },
      axisLabel: AXIS_LABELS_STYLE,
      name: intl.get('CAPACITY_INSIGHTS.DEMAND_BY_PROJECT_CATEGORIES.HOURS'),
      nameTextStyle: AXIS_NAME_STYLE,
    },
    angleAxis: {
      type: 'category',
      data: projectCategoriesData,
      startAngle: 90,
      z: 10,
      splitLine: {
        show: true,
        lineStyle: {
          color: GRID_COLOR,
        },
      },
      axisLine: {
        lineStyle: {
          color: GRID_COLOR,
        },
      },
      axisLabel: {
        ...DATA_LABELS_STYLE,
        overflow: 'break',
        width: 65,
        formatter: (d: string, index: number) =>
          [
            `{hoursStyle|${demandData[index]}${demandData[index] ? 'h' : ''}}`,
            truncate(d, {
              length: 21,
            }),
          ].join('\n'),
        rich: {
          hoursStyle: HOURS_STYLE,
        },
      },
    },
  };

  return (
    <div className='w-full p-6' data-testid='demand-by-project-category_chart'>
      {dataStatus === SLICE_STATUS.LOADING ? (
        <ChartSkeletonLoader />
      ) : (
        <>
          <div className='h-4 mb-8'>
            <p className='text-3.5 leading-2 text-neutral-black font-semibold'>
              {intl.get('CAPACITY_INSIGHTS.DEMAND_BY_PROJECT_CATEGORIES.TITLE')}
            </p>
            <Typography variant='caption' className='text-neutral-dark'>
              {intl.get(
                'CAPACITY_INSIGHTS.DEMAND_BY_PROJECT_CATEGORIES.CAPTION'
              )}
            </Typography>
          </div>
          {projectCategoriesDemand?.length ? (
            <ReactEChartsCore
              echarts={echarts}
              option={chartOptions}
              style={{
                width: '100%',
                height: '95%',
                minHeight: '424px',
                minWidth: '344px',
              }}
            />
          ) : projectCategoriesDemand ? (
            <NoData />
          ) : (
            <LoadingError />
          )}
        </>
      )}
    </div>
  );
};

export default DemandByProjectChart;
