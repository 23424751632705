import { useState } from 'react';
import { Tabs } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import BudgetPlan from './BudgetPlan';
import BudgetDetails from './BudgetDetails';

const Budget = ({
  fromSidePanel,
  templateId,
  isReadOnly,
}: {
  fromSidePanel: boolean;
  templateId?: string;
  isReadOnly: boolean;
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const subTab = queryParams.get('subTab');
  const tabMapping: { [key: string]: number } = {
    budgetPlan: 0,
    budgetDetails: 1,
  };
  const [currentTabIndex, setCurrentTabIndex] = useState(
    subTab ? tabMapping[subTab] : 0
  );

  const changeCurrentTabIndex = (index: number) => {
    setCurrentTabIndex(index);
    let url = `${window.location.pathname}?subTab=${
      Object.keys(tabMapping)[index]
    }`;
    if (fromSidePanel) {
      url += `&fromSidePanel=true`;
    }
    const parentTab = queryParams.get('tab');
    if (parentTab) {
      url = `${url}&tab=${parentTab}`;
    }
    window.history.replaceState({}, '', url);
  };

  if (!templateId) {
    return (
      <div className='px-6'>
        <Tabs
          index={currentTabIndex}
          onChange={changeCurrentTabIndex}
          tabListProps={{
            className: 'w-68',
          }}
          type='tab'
          data={[
            {
              label: intl.get('BUDGET.BUDGET_PLAN'),
              content: <BudgetPlan fromSidePanel={fromSidePanel} />,
            },
            {
              label: intl.get('BUDGET.BUDGET_DETAILS'),
              content: <BudgetDetails isReadOnly={isReadOnly} />,
            },
          ]}
        />
      </div>
    );
  }

  return <BudgetDetails isReadOnly={isReadOnly} />;
};

export default Budget;
