import React, { useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import { AppliedFilters, tailwindOverride } from '@getsynapse/design-system';
import {
  ProjectFiltersKey,
  AppliedFilter,
  ProjectFilters,
  RangeFilter,
  CustomFieldsFilters,
} from 'utils/types/filters';
import { isDateRangeFilter } from 'utils/typeGuards';
import {
  DATE,
  PROJECTS_TABLE_FILTERS,
  CUSTOM_FIELDS_FILTERS_TYPES,
} from 'utils/constants';
import useProjectFiltersOptions from './hooks/useProjectFiltersOptions';
import { v4 as uuidv4 } from 'uuid';
import { Option } from 'utils/customTypes';

export type Props = {
  filters: ProjectFilters;
  onUpdateFilters: (filters: ProjectFilters) => void;
  onDisplayAllFilters: () => void;
  customFieldsFilters?: boolean;
  className?: string;
};

const AppliedFiltersTags: React.FC<Props> = ({
  filters,
  onUpdateFilters,
  onDisplayAllFilters,
  customFieldsFilters = false,
  className = '',
}) => {
  const { getDateFilterLabelByKey, getFilterOptionLabelByKeyAndValue } =
    useProjectFiltersOptions();

  const [maxNumberOfTags, setMaxNumberOfTags] = useState<number>(0);

  const customFieldsFiltersProps = customFieldsFilters
    ? {
        tagsDivClassname: 'flex-wrap w-76%',
        tagClassname: 'mt-3',
        clearAllButtonClassname: 'mt-3',
        maxNumberOfTags,
        title: '',
        className: 'px-0 py-0 pb-3 rounded mt-7 items-start',
      }
    : {};

  const appliedFilters = useMemo<AppliedFilter[]>(() => {
    const tags: AppliedFilter[] = [];
    for (const key of Object.keys(filters)) {
      if (
        !Object.values(PROJECTS_TABLE_FILTERS).includes(
          key as ProjectFiltersKey
        )
      ) {
        const fieldFilter = (filters[key] as CustomFieldsFilters).value;
        const filterType = (filters[key] as CustomFieldsFilters).type;
        if (filterType === CUSTOM_FIELDS_FILTERS_TYPES.MULTI_OPTIONS) {
          (fieldFilter as Option[]).forEach((f: Option) => {
            const filter: AppliedFilter = {
              label: f.label,
              value: f.value,
              key,
              id: uuidv4(),
            };
            tags.push(filter);
          });
        } else if (isDateRangeFilter(filters[key].value)) {
          const filter = filters[key].value;
          const label = getDateFilterLabelByKey(
            key as ProjectFiltersKey,
            filter as RangeFilter,
            DATE.TASK_TABLE_FORMAT,
            true
          );
          tags.push({
            label,
            value: '',
            key,
            id: uuidv4(),
          });
        } else {
          tags.push({
            label: fieldFilter as string,
            value: fieldFilter as string | number | boolean,
            key,
            id: uuidv4(),
          });
        }
      } else if (!customFieldsFilters) {
        if (isDateRangeFilter(filters[key])) {
          const filter = filters[key as ProjectFiltersKey] as RangeFilter;
          const label = getDateFilterLabelByKey(
            key as ProjectFiltersKey,
            filter,
            DATE.TASK_TABLE_FORMAT
          );
          tags.push({ label, value: '', key });
        } else if (key === PROJECTS_TABLE_FILTERS.ARCHIVED) {
          tags.push({
            label: intl.get('PROJECT_DETAIL.ARCHIVED'),
            value: 'true',
            key,
          });
        } else {
          const filter = filters[key as ProjectFiltersKey] as string[];
          filter.forEach((value: string) => {
            const label = getFilterOptionLabelByKeyAndValue(
              key as ProjectFiltersKey,
              value
            );
            tags.push({ label, value, key });
          });
        }
      }
    }
    return tags;
  }, [
    filters,
    getDateFilterLabelByKey,
    getFilterOptionLabelByKeyAndValue,
    customFieldsFilters,
  ]);

  const handleRemoveFilter = (filter: AppliedFilter) => {
    const { key, value, id } = filter;
    const filterKey = key as ProjectFiltersKey;
    const updatedFilters = { ...filters };
    if (
      filterKey === PROJECTS_TABLE_FILTERS.START_DATE ||
      filterKey === PROJECTS_TABLE_FILTERS.TARGET_COMPLETION_DATE ||
      filterKey === PROJECTS_TABLE_FILTERS.ACTUAL_COMPLETION_DATE
    ) {
      delete updatedFilters[filterKey];
    } else if (filterKey === PROJECTS_TABLE_FILTERS.ARCHIVED) {
      delete updatedFilters[filterKey];
    } else if (id) {
      const filterValues = updatedFilters[filterKey as string];
      if (filterValues.type === CUSTOM_FIELDS_FILTERS_TYPES.MULTI_OPTIONS) {
        const updatedFilterValues = (filterValues.value as Option[]).filter(
          (filterValue) => filterValue.value !== value
        );
        if (updatedFilterValues.length === 0) {
          delete updatedFilters[filterKey];
        } else {
          updatedFilters[filterKey as string] = {
            ...filterValues,
            value: updatedFilterValues,
          };
        }
      } else {
        delete updatedFilters[filterKey];
      }
    } else {
      const filterValues = updatedFilters[filterKey] as string[];
      const updatedFilterValues = filterValues.filter(
        (filterValue) => filterValue !== value
      );
      if (updatedFilterValues.length === 0) {
        delete updatedFilters[filterKey];
      } else {
        updatedFilters[filterKey] = updatedFilterValues;
      }
    }
    onUpdateFilters(updatedFilters);
  };

  const clearAllFilters = () => {
    if (!customFieldsFilters) {
      onUpdateFilters({} as ProjectFilters);
    } else {
      const updatedFilters = { ...filters };
      for (const key of Object.keys(filters)) {
        if (
          !Object.values(PROJECTS_TABLE_FILTERS).includes(
            key as ProjectFiltersKey
          )
        ) {
          delete updatedFilters[key];
        }
      }
      onUpdateFilters(updatedFilters);
      setMaxNumberOfTags(window.outerWidth >= 1440 ? 8 : 6);
    }
  };

  return (
    <AppliedFilters<AppliedFilter>
      filters={appliedFilters}
      onRemoveFilter={handleRemoveFilter}
      onClearAll={clearAllFilters}
      onDisplayAllFilters={
        customFieldsFilters
          ? () => setMaxNumberOfTags(appliedFilters.length + 1)
          : onDisplayAllFilters
      }
      className={tailwindOverride('border-primary-lighter-two z-20', className)}
      data-testid='applied-projects-filters'
      {...customFieldsFiltersProps}
    />
  );
};

export default AppliedFiltersTags;
