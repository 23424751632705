/**
 * The tabs available in the centralized tasks page
 * @constant
 */
export const CENTRALIZED_TASKS_TABLE_TABS = {
  TEAM_TASKS: 'centralizedTeamTasksTable',
  MY_TASKS: 'myCentralizedTasksTable',
} as const;

/**
 * The allowed fields to filter in the centralized tasks table
 * @constant
 */
export const CENTRALIZED_TASKS_FILTERS = {
  ADDITIONAL_FLAGS: 'additionalFlags',
  STATUS: 'status',
  TYPE: 'type',
  ASSIGNEE_USERS: 'assignedUsers',
  START_DATE: 'start_date',
  DUE_DATE: 'due_date',
  COMPLETION_DATE: 'completion_date',
  ESTIMATED_HOURS: 'estimate_hours',
  ACTUAL_HOURS: 'actual_hours',
  PROJECT: 'project',
  PROGRAM: 'program',
};

export const CENTRALIZED_TASKS_SORT = {
  NAME: 'name',
  STATUS: 'status',
  START_DATE: 'start_date',
  DUE_DATE: 'due_date',
  ESTIMATED_HOURS: 'estimate_hours',
  ACTUAL_HOURS: 'actual_hours',
  PROJECT: 'project',
  ASSIGNEE: 'assignee',
};
