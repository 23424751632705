import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { resetCapacitiesState } from '../TeamsList/teamsListSlice';

interface ScrollPositionState {
  scrollPosition: number;
}

/* ============================= INITIAL STATE ============================== */
const initialState: ScrollPositionState = {
  scrollPosition: 0,
};

/* =============================== SELECTORS ================================ */
export const selectScrollPosition = (state: RootState) =>
  state.capacities.scrollPosition.scrollPosition;

/* ================================= REDUCER ================================ */
const scrollPosition = createSlice({
  name: 'scrollPosition',
  initialState,
  reducers: {
    setScrollPosition: (state, action: PayloadAction<number>) => {
      state.scrollPosition = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetCapacitiesState, (state) => {
      state.scrollPosition = initialState.scrollPosition;
    });
  },
});

/* ============================== ACTIONS =============================== */
export const { setScrollPosition } = scrollPosition.actions;

export default scrollPosition.reducer;
