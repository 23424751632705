import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Icon, ProgressBar, Typography } from '@getsynapse/design-system';
import {
  selectProjectProgress,
  selectIsProjectProgressLoading,
} from 'state/Project/progress/progressSlice';
import SkeletonLoader from './SkeletonLoader';
import estimatedHoursIcon from 'assets/icons/estimated-hours.svg';
import actualHoursIcon from 'assets/icons/actual-hours.svg';

const ProjectProgress = () => {
  const projectProgress = useSelector(selectProjectProgress);
  const isLoading = useSelector(selectIsProjectProgressLoading);

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <div
      className='h-full flex items-center gap-x-6'
      aria-label='project-progress'
    >
      <ProgressBar
        maxValue={projectProgress.totalTasks}
        currentValue={projectProgress.completedTasks}
        label={intl.get('TASKS.COMPLETED_PROGRESS_BAR_LABEL')}
        barClassName='max-w-35 shadow-inner'
        containerClassName='w-80'
        progressClassName='bg-success-darker'
      />
      <div className='flex items-center gap-x-2'>
        <Icon
          src={estimatedHoursIcon}
          className='text-xl'
          aria-label='estimated-hours-icon'
        />
        <Typography
          variant='body'
          className='text-neutral-darker font-semibold'
        >
          {projectProgress.estimateHours}{' '}
          {intl.get('TASKS.TABLE.HEAD.ESTIMATED_HOURS').toLowerCase()}
        </Typography>
      </div>
      <div className='flex items-center gap-x-2'>
        <Icon
          src={actualHoursIcon}
          className='text-xl'
          aria-label='actual-hours-icon'
        />
        <Typography
          variant='body'
          className='text-neutral-darker font-semibold'
        >
          {projectProgress.actualHours}{' '}
          {intl.get('TASKS.TABLE.HEAD.ACTUAL_HOURS').toLowerCase()}
        </Typography>
      </div>
    </div>
  );
};

export default ProjectProgress;
