import React, { useMemo } from 'react';
import { AppliedFilters, tailwindOverride } from '@getsynapse/design-system';
import {
  AppliedFilter,
  RangeFilter,
  TaskFilters,
  TaskFiltersKey,
} from 'utils/types/filters';
import { isDateRangeFilter } from 'utils/typeGuards';
import { DATE, TASKS_TABLE_FILTERS } from 'utils/constants';
import { ProjectTasksTableTab } from 'utils/customTypes';
import useTaskFilters from './hooks/useTasksFilters';

const DATE_FILTERS = [
  TASKS_TABLE_FILTERS.START_DATE,
  TASKS_TABLE_FILTERS.DUE_DATE,
  TASKS_TABLE_FILTERS.COMPLETION_DATE,
];

const HOURS_FILTERS = [
  TASKS_TABLE_FILTERS.ESTIMATED_HOURS,
  TASKS_TABLE_FILTERS.ACTUAL_HOURS,
];

const AppliedFiltersTags: React.FC<{
  taskTable: ProjectTasksTableTab;
  filters: TaskFilters;
  onUpdateFilters: (filters: TaskFilters) => void;
  onDisplayAllFilters: () => void;
}> = ({ taskTable, filters, onUpdateFilters, onDisplayAllFilters }) => {
  const {
    getFilterOptionLabelByKeyAndValue,
    getDateFilterLabel,
    getHoursRangeFilterLabel,
  } = useTaskFilters(taskTable);
  const appliedFilters = useMemo<AppliedFilter[]>(() => {
    const tags: AppliedFilter[] = [];
    for (const key of Object.keys(filters)) {
      const filterKey = key as TaskFiltersKey;
      if (isDateRangeFilter(filters[filterKey])) {
        const filterValue = filters[filterKey] as RangeFilter;
        let label = '';
        if (DATE_FILTERS.includes(filterKey)) {
          label = getDateFilterLabel(filterValue, DATE.TASK_TABLE_FORMAT);
        }
        if (HOURS_FILTERS.includes(filterKey)) {
          label = getHoursRangeFilterLabel(filterValue);
        }
        tags.push({ label, value: '', key });
      } else {
        const filterValues = filters[filterKey] as string[];
        filterValues.forEach((value: string) => {
          const label = getFilterOptionLabelByKeyAndValue(filterKey, value);
          tags.push({ label, value, key });
        });
      }
    }
    return tags;
  }, [
    filters,
    getDateFilterLabel,
    getFilterOptionLabelByKeyAndValue,
    getHoursRangeFilterLabel,
  ]);

  const handleRemoveFilter = (filter: AppliedFilter) => {
    const { key, value } = filter;
    const filterKey = key as TaskFiltersKey;
    const updatedFilters = { ...filters };
    if (isDateRangeFilter(filters[filterKey])) {
      delete updatedFilters[filterKey];
    } else {
      const filterValues = updatedFilters[filterKey] as string[];
      const updatedFilterValues = filterValues.filter(
        (filterValue) => filterValue !== value
      );
      if (updatedFilterValues.length === 0) {
        delete updatedFilters[filterKey];
      } else {
        updatedFilters[filterKey] = updatedFilterValues;
      }
    }
    onUpdateFilters(updatedFilters);
  };

  return (
    <AppliedFilters<AppliedFilter>
      filters={appliedFilters}
      onRemoveFilter={handleRemoveFilter}
      onClearAll={() => onUpdateFilters({})}
      onDisplayAllFilters={onDisplayAllFilters}
      className={tailwindOverride('border-primary-lighter-two z-20')}
      data-testid='applied-tasks-filters-tags'
    />
  );
};

export default AppliedFiltersTags;
