import { INSIGHT_REQUEST_FILTER_PANEL_DATE_OPTIONS as DATE_OPTIONS } from 'utils/constants';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { isNumber } from 'lodash';

interface RequestsByItem {
  monthShortLabel: string;
  requestCount: string;
  seriesName: string;
  year: number;
}

export interface RequestsByDataType {
  by: string;
  requestsBy: RequestsByItem[];
  businessteamid?: string;
}

const requestsStatusMap = {
  submitted: 'In Review',
  canceled: 'Canceled',
  rejected: 'Declined',
  approved: 'Approved',
  waitlisted: 'Waitlisted',
} as { [name: string]: string | number };

const formatRequestStatus = (status: string) => {
  if (status in requestsStatusMap) {
    return requestsStatusMap[status];
  }
  return status;
};

export const requestsByDataFormatter = (
  data: RequestsByDataType,
  shouldFormatStatus?: boolean
) => {
  const { requestsBy = [] } = data;
  const monthMap = new Map();
  let seriesNamesSet = new Set();
  for (let i = 0; i < requestsBy.length; i++) {
    const item = requestsBy[i];
    const formattedSeriesName = shouldFormatStatus
      ? formatRequestStatus(item.seriesName)
      : item.seriesName;
    seriesNamesSet.add(formattedSeriesName);
    if (!monthMap.has(item.monthShortLabel)) {
      monthMap.set(item.monthShortLabel, {
        monthShortLabel: item.monthShortLabel,
      });
    }
    const monthData = monthMap.get(item.monthShortLabel);
    if (!monthData[formattedSeriesName]) {
      monthData[formattedSeriesName] = parseInt(item.requestCount);
    } else {
      monthData[formattedSeriesName] += parseInt(item.requestCount);
    }
  }
  const seriesNames = Array.from(seriesNamesSet) as string[];
  Array.from(monthMap.values()).forEach(
    (element: { [name: string]: string | number }) => {
      seriesNames.forEach((seriesName: string) => {
        if (!element[seriesName]) {
          element[seriesName] = 0;
        }
      });
    }
  );

  return {
    seriesNames: seriesNames,
    requestsBy: Array.from(monthMap.values()),
  };
};

export const getInsightsDefaultDateFilter = (key: string) => {
  const savedDateOption = window.localStorage.getItem(key);
  if (!savedDateOption) {
    return '';
  } else {
    const matchedOption = get(DATE_OPTIONS, savedDateOption, {});
    if (isEmpty(matchedOption)) {
      return '';
    }
    const daysToConsider = get(
      matchedOption,
      'numberOfDays',
      DATE_OPTIONS.ALL.numberOfDays
    );
    return daysToConsider && isNumber(daysToConsider)
      ? moment().subtract(daysToConsider, 'days').format()
      : '';
  }
};
