import intl from 'react-intl-universal';
import classnames from 'classnames';

const UsersTableHead = () => {
  const headCellsClassNames = 'py-3 font-semibold';

  return [
    {
      columnName: ['data.firstName', 'data.lastName'],
      content: intl.get('USERS_PAGE.TABLE.HEAD.USER_NAME'),
      className: classnames(headCellsClassNames, 'w-72 left-14 z-2'),
    },
    {
      columnName: ['type'],
      content: intl.get('USERS_PAGE.TABLE.HEAD.USER_TYPE'),
      className: headCellsClassNames,
    },
    {
      columnName: ['team'],
      content: intl.get('USERS_PAGE.TABLE.HEAD.TEAM'),
      className: headCellsClassNames,
    },
    {
      columnName: ['data.jobTitle'],
      content: intl.get('USERS_PAGE.TABLE.HEAD.JOB_TITLE'),
      className: headCellsClassNames,
    },
    {
      columnName: ['data.employmentType'],
      content: intl.get('USERS_PAGE.TABLE.HEAD.EMPLOYMENT_TYPE'),
      className: classnames(headCellsClassNames, 'w-28'),
    },
    {
      columnName: ['country_iso_3166_1_alpha_2_code', 'data.province'],
      content: intl.get('USERS_PAGE.TABLE.HEAD.LOCATION'),
      className: headCellsClassNames,
    },
    {
      columnName: ['data.rateHour'],
      content: intl.get('USERS_PAGE.TABLE.HEAD.HOURLY_RATE'),
      className: classnames(headCellsClassNames, 'w-22'),
    },
    {
      columnName: ['status'],
      content: intl.get('USERS_PAGE.TABLE.HEAD.ACCOUNT_STATUS'),
      className: classnames(headCellsClassNames, 'w-24'),
    },
  ];
};

export default UsersTableHead;
