export const generateNativeTextElement = (
  xCordStartingPoint: number,
  yCordStartingPoint: number,
  textAnchor: string,
  color: string,
  value: string,
  style: any,
  verticalShiftPosition?: number
) => {
  return (
    <text
      x={xCordStartingPoint}
      y={yCordStartingPoint}
      dy={verticalShiftPosition}
      textAnchor={textAnchor}
      fill={color}
      style={style}
    >
      {value}
    </text>
  );
};
