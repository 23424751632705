import { useEffect, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import classNames from 'classnames';
import { Typography } from '@getsynapse/design-system';
import { PATHS, PENDO_EVENTS } from 'utils/constants';
import Divider from 'Atoms/Divider';
import { trackEvent } from 'Services/pendo';
import {
  getProgramStrategy,
  fetchProgramStrategy,
} from 'state/ProgramStrategy/programStrategySlice';
import { selectROICalculations } from 'state/ROICategory/ROICategoySlice';

const ROICalculator = () => {
  const dispatch = useDispatch();

  const { programId } = useParams<{
    programId: string;
  }>();

  const programStrategySelector = useSelector(getProgramStrategy);
  const roiCalculatorSelector = useSelector(selectROICalculations);

  const impactCategoryId = useMemo(
    () =>
      programStrategySelector.categories.find(
        (category) => category.name === 'Impact'
      )?.id,
    [programStrategySelector.categories]
  );

  useEffect(() => {
    if (!programStrategySelector) {
      dispatch(fetchProgramStrategy(programId));
    }
  });

  return (
    <>
      <Typography variant='h5' className='mt-12 mb-1'>
        {intl.get('ROI_CATEGORY.ROI_CALCULATOR.TITLE')}
      </Typography>
      <Typography className='text-primary-darker' variant='body2'>
        {intl.get('ROI_CATEGORY.ROI_CALCULATOR.SUBTITLE')}
      </Typography>
      <div className='mt-6 rounded border-secondary-light border py-8 px-6'>
        <div className='flex justify-between'>
          <div className='flex items-center'>
            <Typography weight='medium' className='mr-2'>
              {intl.get(
                'ROI_CATEGORY.ROI_CALCULATOR.ANNUAL_MONETARY_BENEFITS_TOTAL'
              )}
            </Typography>
            <Link
              to={`${PATHS.PROGRAM_PAGE}/${programId}${PATHS.STRATEGY_PAGE}/${impactCategoryId}`}
              className='text-sm underline text-primary-darker'
              onClick={() => {
                trackEvent(PENDO_EVENTS.ROI_CALCULATOR_VIEW_CALCULATION, {
                  programId,
                });
              }}
            >
              {intl.get('ROI_CATEGORY.ROI_CALCULATOR.VIEW_CALCULATION')}
            </Link>
          </div>
          <Typography
            className={classNames({
              'text-neutral':
                roiCalculatorSelector.totalAnnualMonetaryBenefit === null,
            })}
          >
            {roiCalculatorSelector.totalAnnualMonetaryBenefit !== null
              ? `${intl.get(
                  'DOLLAR_SIGN'
                )}${roiCalculatorSelector.totalAnnualMonetaryBenefit?.toLocaleString(
                  'en-US'
                )}`
              : intl.get('ROI_CATEGORY.ROI_CALCULATOR.NO_VALUE_PROVIDED')}
          </Typography>
        </div>
        <div className='flex justify-between mt-4'>
          <Typography weight='medium'>
            {intl.get('ROI_CATEGORY.ROI_CALCULATOR.PROGRAM_COST_TOTAL')}
          </Typography>
          <Typography
            className={classNames({
              'text-neutral': roiCalculatorSelector.totalProgramCost === null,
            })}
          >
            {roiCalculatorSelector.totalProgramCost !== null
              ? `${intl.get(
                  'DOLLAR_SIGN'
                )}${roiCalculatorSelector.totalProgramCost?.toLocaleString(
                  'en-US'
                )}`
              : intl.get('ROI_CATEGORY.ROI_CALCULATOR.NO_VALUE_PROVIDED')}
          </Typography>
        </div>
        <Divider className='mt-6' />
        <div className='flex justify-between mt-6'>
          <Typography weight='medium'>
            {intl.get('ROI_CATEGORY.ROI_CALCULATOR.RETURN_ON_INVESTMENT')}
          </Typography>
          <Typography
            className={classNames({
              'text-neutral':
                roiCalculatorSelector.returnOnInvestmentPercentage === null,
            })}
          >
            {roiCalculatorSelector.returnOnInvestmentPercentage !== null
              ? `${
                  roiCalculatorSelector.returnOnInvestmentPercentage
                }${intl.get('PERCENT_SIGN')}`
              : intl.get('ROI_CATEGORY.ROI_CALCULATOR.NO_CALCULATION_YET')}
          </Typography>
        </div>
        <div className='flex justify-between mt-5'>
          <Typography variant='body2' weight='medium'>
            {intl.get('ROI_CATEGORY.ROI_CALCULATOR.BENEFIT_COST_RATIO')}
          </Typography>
          <Typography
            variant='body2'
            className={classNames({
              'text-neutral': roiCalculatorSelector.benefitCostRatio === null,
            })}
          >
            {roiCalculatorSelector.benefitCostRatio ??
              intl.get('ROI_CATEGORY.ROI_CALCULATOR.NO_CALCULATION_YET')}
          </Typography>
        </div>
        <div className='flex justify-between mt-2'>
          <Typography variant='body2' weight='medium'>
            {intl.get('ROI_CATEGORY.ROI_CALCULATOR.ROI_OBJECTIVE')}
          </Typography>
          <Typography
            variant='body2'
            className={classNames({
              'text-neutral':
                roiCalculatorSelector.returnOnInvestmentObjectivePercentage ===
                null,
            })}
          >
            {roiCalculatorSelector.returnOnInvestmentObjectivePercentage !==
            null
              ? `${
                  roiCalculatorSelector.returnOnInvestmentObjectivePercentage
                }${intl.get('PERCENT_SIGN')}`
              : intl.get('ROI_CATEGORY.ROI_CALCULATOR.NO_OBJECTIVE')}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default ROICalculator;
