import {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useLayoutEffect,
} from 'react';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import Alert from '@reach/alert';
import intl from 'react-intl-universal';
import { List, useElevation, Icon, Button } from '@getsynapse/design-system';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';
import { ADD_FACILITATORS_FILTERS } from 'utils/constants/learningSchedule';
import FilterOptionPopup from './FilterOptionPopup';
import { Option } from 'utils/customTypes';

const FilterFacilitatorsPopup = ({
  appliedFilters,
  updateAppliedFilters,
  disabled,
}: {
  appliedFilters: {
    skills: Option[];
    languages: Option[];
    countries: Option[];
  };
  updateAppliedFilters: (
    selectedOptions: Option[] | null,
    type?: 'skills' | 'languages' | 'countries'
  ) => void;
  disabled: boolean;
}) => {
  const skillCount = appliedFilters[ADD_FACILITATORS_FILTERS.SKILLS].length;
  const languageCount =
    appliedFilters[ADD_FACILITATORS_FILTERS.LANGUAGES].length;
  const countryCount =
    appliedFilters[ADD_FACILITATORS_FILTERS.COUNTRIES].length;
  const totalFilters = skillCount + languageCount + countryCount;

  const popperElevation = useElevation(3);

  const containerRef = useRef<HTMLDivElement>(null);
  const popupRefs = useRef<{ [key: string]: { update: () => void } | null }>({
    [ADD_FACILITATORS_FILTERS.SKILLS]: null,
    [ADD_FACILITATORS_FILTERS.LANGUAGES]: null,
    [ADD_FACILITATORS_FILTERS.COUNTRIES]: null,
  });

  const [filterButton, setFilterButton] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [isSkillsPopupOpen, setIsSkillsPopupOpen] = useState<boolean>(false);
  const [isLanguagesPopupOpen, setIsLanguagesPopupOpen] =
    useState<boolean>(false);
  const [isCountriesPopupOpen, setIsCountriesPopupOpen] =
    useState<boolean>(false);

  const { styles, attributes, update } = usePopper(
    filterButton,
    popperElement,
    {
      placement: 'bottom-end',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 4],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            boundary: containerRef.current!,
          },
        },
      ],
    }
  );

  const hasFilters = useMemo(
    () => Object.values(appliedFilters).some((filter) => filter.length > 0),
    [appliedFilters]
  );

  const options = useMemo(
    () =>
      Object.values(ADD_FACILITATORS_FILTERS).map((item) => ({
        value: item,
        label: intl.get(
          `SCHEDULE.EVENTS.FACILITATORS_SECTION.FILTERS_OPTIONS.${item.toUpperCase()}`
        ),
      })),
    []
  );

  const closeAllPopups = () => {
    setIsSkillsPopupOpen(false);
    setIsLanguagesPopupOpen(false);
    setIsCountriesPopupOpen(false);
  };

  const handleClickOutside = useCallback(
    (event: Event) => {
      const target = event.target as Element;
      if (!containerRef.current?.contains(target) && isOpen) {
        setIsOpen(false);
        setSelectedOption('');
        closeAllPopups();
      }
    },
    [isOpen]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (!isOpen) setSelectedOption('');
  }, [isOpen]);

  useLayoutEffect(() => {
    if (update && isOpen) {
      update().then(() => {
        if (isSkillsPopupOpen) {
          popupRefs.current[ADD_FACILITATORS_FILTERS.SKILLS]?.update();
        } else if (isLanguagesPopupOpen) {
          popupRefs.current[ADD_FACILITATORS_FILTERS.LANGUAGES]?.update();
        } else if (isCountriesPopupOpen) {
          popupRefs.current[ADD_FACILITATORS_FILTERS.COUNTRIES]?.update();
        }
      });
    }
  }, [
    isOpen,
    update,
    isSkillsPopupOpen,
    isLanguagesPopupOpen,
    isCountriesPopupOpen,
    appliedFilters,
  ]);

  const selectOption = useCallback(
    (option: string) => {
      if (isOpen) {
        setSelectedOption(option);
        setIsSkillsPopupOpen(option === ADD_FACILITATORS_FILTERS.SKILLS);
        setIsLanguagesPopupOpen(option === ADD_FACILITATORS_FILTERS.LANGUAGES);
        setIsCountriesPopupOpen(option === ADD_FACILITATORS_FILTERS.COUNTRIES);
      }
    },
    [isOpen]
  );

  const clearAllFilters = useCallback(() => {
    updateAppliedFilters(null);
    closeAllPopups();
    setIsOpen(false);
  }, [updateAppliedFilters]);

  const handleFilterToggle = () => {
    setIsOpen((prevState) => !prevState);
    closeAllPopups();
  };

  return (
    <div className='relative' ref={containerRef}>
      <div className='flex items-center'>
        <div ref={setFilterButton}>
          <FilterButton
            onToggleFilter={handleFilterToggle}
            disabled={disabled && !hasFilters}
            data-testid='add-facilitators-filter_button'
          />
        </div>
        {hasFilters && (
          <>
            <div className='rounded-full w-6 h-6 bg-secondary flex items-center justify-center text-neutral-white font-semibold mr-2'>
              {totalFilters}
            </div>
            <Button variant='tertiary' onClick={clearAllFilters} size='small'>
              {intl.get('SCHEDULE.EVENTS.FACILITATORS_SECTION.CLEAR_FILTERS')}
            </Button>
          </>
        )}
      </div>
      <Alert
        ref={setPopperElement}
        style={{ ...styles.popper, zIndex: isOpen ? 10 : -1 }}
        className={classNames('w-44', popperElevation, {
          'opacity-0': !isOpen,
        })}
        {...attributes.popper}
        data-testid='filter-facilitators_popup'
      >
        <List
          options={options}
          renderOption={(option) => (
            <li
              className={classNames(
                'py-2 px-4 h-10 group hover:bg-neutral-black hover:text-neutral-white flex items-center justify-between text-base',
                {
                  'bg-neutral-black text-neutral-white':
                    selectedOption === option.value,
                }
              )}
              onMouseOver={() => selectOption(option.value)}
              onClick={() => selectOption(option.value)}
            >
              <div> {option.label}</div>
              <div className='flex items-center'>
                {((option.value === ADD_FACILITATORS_FILTERS.SKILLS &&
                  skillCount > 0) ||
                  (option.value === ADD_FACILITATORS_FILTERS.LANGUAGES &&
                    languageCount > 0) ||
                  (option.value === ADD_FACILITATORS_FILTERS.COUNTRIES &&
                    countryCount > 0)) && (
                  <div className='rounded-full w-6 h-6 bg-secondary flex items-center justify-center text-neutral-white font-semibold mr-2'>
                    {option.value === ADD_FACILITATORS_FILTERS.SKILLS &&
                      skillCount}
                    {option.value === ADD_FACILITATORS_FILTERS.LANGUAGES &&
                      languageCount}
                    {option.value === ADD_FACILITATORS_FILTERS.COUNTRIES &&
                      countryCount}
                  </div>
                )}
                <Icon
                  name='caret-forward'
                  className={classNames(
                    'text-neutral group-hover:text-neutral-white',
                    {
                      'text-neutral-white': selectedOption === option.value,
                    }
                  )}
                />
              </div>
            </li>
          )}
          onSelectOption={() => {}}
        />
      </Alert>
      {Object.values(ADD_FACILITATORS_FILTERS).map((filterType) => (
        <FilterOptionPopup
          key={filterType}
          isOpen={selectedOption === filterType}
          selectedOption={filterType}
          itemRef={popperElement}
          selectedOptions={appliedFilters[filterType]}
          updateSelectedOptions={(options) =>
            updateAppliedFilters(options, filterType)
          }
          ref={(el) => {
            popupRefs.current[filterType] = el as { update: () => void } | null;
          }}
        />
      ))}
    </div>
  );
};

export default FilterFacilitatorsPopup;
