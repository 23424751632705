import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import {
  Typography,
  tailwindOverride,
  Tooltip,
} from '@getsynapse/design-system';
import { PATHS, TEAMS_PAGE_TABS } from 'utils/constants';
import { RoleAllocation } from 'Organisms/CapacityAllocationTable/components/AllocatedWeeksTable';
import { Week } from 'Organisms/CapacityAllocationTable/helpers';
import { TeamMemberAllocation } from 'utils/customTypes';
import { Fragment } from 'react';

type TeamMemberAllocationsProps = {
  numberOfWeeks: number;
  currentWeeksSlideArray: (Week | null)[];
  currentAllocations: Array<TeamMemberAllocation | null>;
  userId: string;
};

const TeamMemberAllocations = ({
  numberOfWeeks,
  currentWeeksSlideArray,
  userId,
  currentAllocations,
}: TeamMemberAllocationsProps) => {
  return (
    <div className={`mx-6 grid grid-cols-${numberOfWeeks}`}>
      {currentAllocations.map((allocation, i: number) => {
        return allocation !== null ? (
          <RoleAllocation
            key={`${userId}-${i}`}
            id={`${userId}-${i}`}
            displayContent
            content={
              allocation.defaultCapacity > 0 ? (
                <Allocation
                  allocation={allocation.allocation}
                  capacity={allocation.capacity}
                  defaultCapacity={allocation.defaultCapacity}
                />
              ) : (
                <NoDefaultCapacity displayMessage={i === 0} userId={userId} />
              )
            }
            isCurrentWeek={get(
              currentWeeksSlideArray,
              `[${i}].isCurrentWeek`,
              false
            )}
            className='flex flex-1 py-2 z-10'
          />
        ) : (
          <div key={i} />
        );
      })}
    </div>
  );
};

export default TeamMemberAllocations;

const HoursLegend = ({
  allocation,
  capacity,
}: {
  allocation: number;
  capacity: number;
}) => (
  <Fragment>
    <Typography
      className='text-neutral-white flex items-center space-x-2'
      variant='caption'
    >
      <span className='leading-4'>
        {intl.get('TEAMS.CAPACITY_LEGENDS.WEEK_CAPACITY')}
      </span>
      <span className='text-body2 leading-4'>
        {intl.get('ORG_SETTINGS_PAGE.LEARNING_TEAMS.HOURS', {
          hours: capacity,
        })}
      </span>
    </Typography>
    <Typography
      className='text-neutral-white flex items-center space-x-2'
      variant='caption'
    >
      <span className='leading-4'>
        {intl.get('TEAMS.CAPACITY_LEGENDS.HOURS_OCCUPIED')}
      </span>
      <span className='text-body2 leading-4'>
        {intl.get('ORG_SETTINGS_PAGE.LEARNING_TEAMS.HOURS', {
          hours: allocation,
        })}
      </span>
    </Typography>
    <Typography
      className='text-neutral-white flex items-center space-x-2'
      variant='caption'
    >
      <span className='leading-4'>
        {intl.get('TEAMS.CAPACITY_LEGENDS.AVAILABLE_HOURS')}
      </span>
      <span className='text-body2 leading-4'>
        {intl.get('ORG_SETTINGS_PAGE.LEARNING_TEAMS.HOURS', {
          hours: capacity - allocation,
        })}
      </span>
    </Typography>
  </Fragment>
);

const NoDefaultCapacity = ({
  displayMessage,
  userId,
}: {
  displayMessage: boolean;
  userId: string;
}) => {
  return (
    <div className='w-full h-6 bg-warning-lightest flex items-center justify-center'>
      {displayMessage && (
        <Link
          to={`${PATHS.TEAMS}/${TEAMS_PAGE_TABS.TEAMS}?user=${userId}`}
          className='text-warning-darker text-sm font-semibold'
        >
          {intl.get('TEAMS.CAPACITY_LEGENDS.NO_DEFAULT_CAPACITY')}
        </Link>
      )}
    </div>
  );
};

const Allocation = ({
  allocation,
  capacity,
  defaultCapacity,
}: {
  allocation: number;
  capacity: number;
  defaultCapacity: number;
}) => {
  const shownCapacity = capacity <= 0 ? 0 : capacity;
  const isOverAllocated = allocation >= shownCapacity;
  return (
    <Tooltip
      timeout={0}
      className='w-full'
      openMode='hover2'
      position='topCenter'
      trigger={
        <div
          className={tailwindOverride('h-6 flex justify-center items-center', {
            'bg-success-lighter': !isOverAllocated,
            'bg-error-lightest': isOverAllocated,
          })}
        >
          <Typography
            variant='body'
            className={tailwindOverride('font-semibold mr-1.5', {
              'text-success-darkest': !isOverAllocated,
              'text-error-darker': isOverAllocated,
            })}
          >
            {Intl.NumberFormat('en-US', { style: 'percent' }).format(
              shownCapacity === 0
                ? (allocation + defaultCapacity) / defaultCapacity
                : allocation / shownCapacity
            )}
          </Typography>
          <Typography
            variant='body2'
            className={tailwindOverride({
              'text-success-darkest': !isOverAllocated,
              'text-error-darker': isOverAllocated,
            })}
          >
            {`(${allocation}/${shownCapacity})`}
          </Typography>
        </div>
      }
    >
      <HoursLegend allocation={allocation} capacity={shownCapacity} />
    </Tooltip>
  );
};
