import { FlatfileSettings } from '@flatfile/react';
import intl from 'react-intl-universal';
import { PROJECT_IMPORT_ATTRIBUTES } from 'utils/constants';
import { Option, StringOption } from 'utils/customTypes';
import { ProjectTemplateField } from 'utils/types/templates';

const parseOptionLabel: (
  defaultLabel: string | number | boolean | null
) => string = (defaultLabel) => {
  let newLabel: string;
  switch (typeof defaultLabel) {
    case 'string':
      newLabel = defaultLabel;
      break;
    case 'number':
    case 'boolean':
      newLabel = defaultLabel.toString();
      break;
    default:
      newLabel = '';
      break;
  }

  return newLabel;
};

export const flatfileSettings: (
  ownerOptions: StringOption[],
  statusOptions: Option[],
  businessUnitOptions: StringOption[],
  categoryOptions: StringOption[],
  priorityOptions: Option[],
  privacyOptions: Option[],
  ldTeamOptions: StringOption[],
  processOptions: Option[],
  stageOptions: Option[],
  healthOptions: Option[],
  templateFields: ProjectTemplateField[]
) => FlatfileSettings = (
  ownerOptions,
  statusOptions,
  businessUnitOptions,
  categoryOptions,
  priorityOptions,
  privacyOptions,
  ldTeamOptions,
  processOptions,
  stageOptions,
  healthOptions,
  templateFields
) => ({
  type: 'Project',
  title: intl.get('IMPORT_PROJECTS.IMPORT_FROM_FILE'),
  fields: [
    {
      label: intl.get('IMPORT_PROJECTS.COLUMN_LABELS.TITLE'),
      key: PROJECT_IMPORT_ATTRIBUTES.TITLE,
      validators: [{ validate: 'required' }],
    },
    {
      label: intl.get('IMPORT_PROJECTS.COLUMN_LABELS.DESCRIPTION'),
      key: PROJECT_IMPORT_ATTRIBUTES.DESCRIPTION,
    },
    {
      label: intl.get('IMPORT_PROJECTS.COLUMN_LABELS.OWNERS'),
      key: PROJECT_IMPORT_ATTRIBUTES.OWNERS,
      type: 'select',
      options: ownerOptions,
    },
    {
      label: intl.get('IMPORT_PROJECTS.COLUMN_LABELS.STATUS'),
      key: PROJECT_IMPORT_ATTRIBUTES.STATUS,
      type: 'select',
      options: statusOptions,
    },
    {
      label: intl.get('IMPORT_PROJECTS.COLUMN_LABELS.BUSINESS_UNIT'),
      key: PROJECT_IMPORT_ATTRIBUTES.BUSINESS_TEAMS,
      type: 'select',
      options: businessUnitOptions,
    },
    {
      label: intl.get('IMPORT_PROJECTS.COLUMN_LABELS.CATEGORY'),
      key: PROJECT_IMPORT_ATTRIBUTES.CATEGORY,
      type: 'select',
      options: categoryOptions,
    },
    {
      label: intl.get('IMPORT_PROJECTS.COLUMN_LABELS.START_DATE'),
      key: PROJECT_IMPORT_ATTRIBUTES.START_DATE,
    },
    {
      label: intl.get('IMPORT_PROJECTS.COLUMN_LABELS.TARGET_COMPLETION_DATE'),
      key: PROJECT_IMPORT_ATTRIBUTES.TARGET_COMPLETION_DATE,
    },
    {
      label: intl.get('IMPORT_PROJECTS.COLUMN_LABELS.PRIORITY'),
      key: PROJECT_IMPORT_ATTRIBUTES.PRIORITY,
      type: 'select',
      options: priorityOptions,
    },
    {
      label: intl.get('IMPORT_PROJECTS.COLUMN_LABELS.PRIVACY'),
      key: PROJECT_IMPORT_ATTRIBUTES.PRIVACY,
      type: 'select',
      options: privacyOptions,
    },
    {
      label: intl.get('IMPORT_PROJECTS.COLUMN_LABELS.LEARNING_TEAMS'),
      key: PROJECT_IMPORT_ATTRIBUTES.LEARNING_TEAMS,
      type: 'select',
      options: ldTeamOptions,
    },
    {
      label: intl.get('IMPORT_PROJECTS.COLUMN_LABELS.PROCESS'),
      key: PROJECT_IMPORT_ATTRIBUTES.PROCESS,
      type: 'select',
      options: processOptions,
    },
    {
      label: intl.get('IMPORT_PROJECTS.COLUMN_LABELS.STAGE'),
      key: PROJECT_IMPORT_ATTRIBUTES.STAGE,
      type: 'select',
      options: stageOptions,
    },
    {
      label: intl.get('IMPORT_PROJECTS.COLUMN_LABELS.HEALTH'),
      key: PROJECT_IMPORT_ATTRIBUTES.HEALTH,
      type: 'select',
      options: healthOptions,
    },
    ...templateFields.map((field) => {
      if (field.field_template.allowed_values) {
        const option = {
          label: field.field_template.name,
          key: field.field_template.name,
          type: 'select',
          options: field.field_template.allowed_values.map((value) => ({
            value: value.val,
            label: parseOptionLabel(value.display || value.val),
          })),
          ...(field.required ? { validators: [{ validate: 'required' }] } : {}),
        };
        return option;
      } else {
        const option = {
          label: field.field_template.name,
          key: field.field_template.name,
        };

        return option;
      }
    }),
  ],
});
