import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { SearchProgramParams, searchPrograms } from 'api/searchPrograms';
import { SearchProjectParams, searchProjects } from 'api/searchProject';
import {
  fetchAvailableTaskAssignees,
  FetchAvailableTaskAssigneesParams,
} from 'api/taskAvailableAssignees';
import axios from 'axios';
import { RootState } from 'state/store';
import { normalizeState } from 'state/utils/normalizeState';
import { TaskAssignee } from 'types/store/tasks';

export interface AvailableProject {
  id: string;
  name: string;
  displayId: string;
}

export interface AvailableProgram {
  id: string;
  name: string;
  displayId: string;
}

export interface SearchState {
  projects?: Record<string, AvailableProject>;
  projectIds?: string[];
  selectedProject?: string[];
  projectsLoading: boolean;
  programs?: Record<string, AvailableProgram>;
  programIds?: string[];
  selectPrograms?: string[];
  programsLoading: boolean;
  assigneesLoading: boolean;
  assignees?: Record<string, TaskAssignee>;
  assigneeIds?: string[];
}

const initialState: SearchState = {
  projectsLoading: false,
  programsLoading: false,
  assigneesLoading: false,
};

export const fetchAvailableProjects = createAsyncThunk(
  'taskListFilter/searchProjects',
  async (params: SearchProjectParams, { rejectWithValue }) => {
    try {
      const response = await searchProjects(params);
      return response.availableProjects;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error fetching team tasks');
    }
  }
);

export const fetchAvailablePrograms = createAsyncThunk(
  'taskListFilter/searchPrograms',
  async (params: SearchProgramParams, { rejectWithValue }) => {
    try {
      const response = await searchPrograms(params);
      return response.availablePrograms;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error fetching team tasks');
    }
  }
);

export const searchUsers = createAsyncThunk(
  'taskListFilter/searchAssignees',
  async (params: FetchAvailableTaskAssigneesParams, { rejectWithValue }) => {
    try {
      const response = await fetchAvailableTaskAssignees(params);
      return response.availableAssignees;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error fetching team tasks');
    }
  }
);

const filterSlice = createSlice({
  name: 'allTasks',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAvailableProjects.pending, (state) => {
        state.projectsLoading = true;
      })
      .addCase(fetchAvailableProjects.rejected, (state) => {
        state.projectsLoading = false;
      })
      .addCase(fetchAvailableProjects.fulfilled, (state, action) => {
        const normalized = normalizeState(action.payload);
        state.projects = {
          ...state.projects,
          ...normalized.entities,
        };
        state.projectIds = normalized.ids;
        state.projectsLoading = false;
      })
      .addCase(fetchAvailablePrograms.pending, (state) => {
        state.programsLoading = true;
      })
      .addCase(fetchAvailablePrograms.rejected, (state) => {
        state.programsLoading = false;
      })
      .addCase(fetchAvailablePrograms.fulfilled, (state, action) => {
        const normalized = normalizeState(action.payload);
        state.programIds = normalized.ids;
        state.programs = {
          ...state.programs,
          ...normalized.entities,
        };
        state.assigneesLoading = false;
      })
      .addCase(searchUsers.pending, (state) => {
        state.assigneesLoading = true;
      })
      .addCase(searchUsers.rejected, (state) => {
        state.assigneesLoading = false;
      })
      .addCase(searchUsers.fulfilled, (state, action) => {
        const normalized = normalizeState(action.payload);
        state.assigneeIds = normalized.ids;
        state.assignees = {
          ...state.assignees,
          ...normalized.entities,
        };
        state.assigneesLoading = false;
      });
  },
});

export const selectSearchProjectResult = (state: RootState) => {
  return state.tasksList.search.projects || {};
};

export const selectSearcProjectIds = (state: RootState) => {
  return state.tasksList.search.projectIds || [];
};

export const selectSearchProjects = createSelector(
  [selectSearchProjectResult, selectSearcProjectIds],
  (projects, ids) => {
    if (!projects) {
      return [];
    }
    return ids.map((id) => projects[id]);
  }
);

export const selectCanFetchAvailablePrograms = (state: RootState) => {
  return (
    !state.tasksList.search.programs &&
    state.tasksList.search.programsLoading === false
  );
};

export const selectSearchProgramResult = (state: RootState) => {
  return state.tasksList.search.programs || {};
};

export const selectSearchProgramIdsResult = (state: RootState) => {
  return state.tasksList.search.programIds || [];
};

export const selectSearchPrograms = createSelector(
  [selectSearchProgramResult, selectSearchProgramIdsResult],
  (programs, ids) => {
    if (!programs) {
      return [];
    }
    return ids.map((id) => programs[id]);
  }
);

export const selectSearchAssigneesResult = (state: RootState) => {
  return state.tasksList.search.assignees || {};
};

export const selectSearchAssigneeResultIds = (state: RootState) => {
  return state.tasksList.search.assigneeIds || [];
};

export const selectSearchAssignees = createSelector(
  [selectSearchAssigneesResult, selectSearchAssigneeResultIds],
  (assignees, ids) => {
    if (!assignees) {
      return [];
    }
    return ids.map((id) => assignees[id]);
  }
);

export default filterSlice.reducer;
