import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import Pusher from 'pusher-js';
import CryptoJS from 'crypto-js';
import get from 'lodash/get';
import { selectUserId, selectUser } from 'state/User/userSlice';
import config from 'config/Config';
import { FormattedNotification, NotificationType } from 'utils/customTypes';
import {
  DELETE_PROGRAM_OPTIONS,
  NOTIFICATION_TYPE,
  PATHS,
  REQUEST_STATUS,
  SETTINGS_SECTIONS,
  SETTINGS_ATTRIBUTES,
} from 'utils/constants';
import { formatNotifications } from 'utils/notificationsHelper';
import {
  getPropertiesComments,
  getQuestionComments,
} from 'state/RequestComments/requestCommentsSlice';
import {
  getRequest,
  getRequestQuestions,
} from 'state/ActiveRequest/activeRequestSlice';
import { getUserRequests } from 'state/Requests/requestSlice';
import { getNotifications } from 'state/Notifications/notificationsSlice';
import {
  fetchTask,
  fetchTaskAndActualHours,
} from 'state/SingleTask/singleTaskSlice';
import { fetchTeamTasks } from 'state/ProjectTasks/projectTaskSlice';
import { fetchProject } from 'state/Project/projectSlice';
import { fetchProjects } from 'state/Projects/projectsSlice';
import { getAllUsers } from 'state/UsersManagement/usersManagementSlice';
import { fetchProgram } from 'state/Program/programSlice';
import {
  fetchCurrentBundle,
  fetchTaskTemplatesForBundle,
} from 'state/ActiveTaskBundle/activeTaskBundleSlice';
import { fetchTaskBundles } from 'state/TasksBundle/tasksBundleSlice';

const useNotifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector(selectUserId);
  const currentUser = useSelector(selectUser);
  const location = useLocation();
  const [list, setList] = useState<FormattedNotification[]>([]);

  const showToast = useCallback(
    (data: {}, eventType: NotificationType) => {
      dispatch(getNotifications());
      const toastProperties = formatNotifications(
        [
          {
            id: Date.now().toString(),
            message: eventType,
            data: data,
          },
        ],
        currentUser
      );

      setList((prevState) => {
        if (prevState.length === 4) {
          prevState.shift();
        }
        return [...prevState, ...toastProperties];
      });
    },
    [dispatch, currentUser]
  );

  const channelName = useMemo(() => {
    if (userId) {
      return CryptoJS.MD5(userId).toString(CryptoJS.enc.Hex);
    }
  }, [userId]);

  const pusher = useMemo(
    () =>
      new Pusher(process.env.REACT_APP_PUSHER_KEY || '', {
        cluster: config.get('pusher.cluster'),
      }),
    []
  );

  const handleFetchProject = useCallback(
    async (updatedProjectId: string) => {
      if (location.pathname === `${PATHS.PROJECT_PAGE}/${updatedProjectId}`) {
        await dispatch(fetchProject(updatedProjectId));
      }
    },
    [location, dispatch]
  );

  const handleFetchTask = useCallback(
    async (updatedTaskId: string) => {
      if (location.pathname.includes(`${PATHS.TASKS}/${updatedTaskId}`)) {
        await dispatch(fetchTask(updatedTaskId));
      }
    },
    [location, dispatch]
  );

  const handleFetchTaskAndActualHours = useCallback(
    async (updatedTaskId: string) => {
      if (location.pathname.includes(`${PATHS.TASKS}/${updatedTaskId}`)) {
        await dispatch(fetchTaskAndActualHours(updatedTaskId));
      }
    },
    [location, dispatch]
  );

  const handleFetchForRequests = useCallback(
    async (requestId: string, callback: (requestId: string) => void) => {
      if (location.pathname.includes(`${PATHS.REQUEST_PAGE}/${requestId}`)) {
        await dispatch(callback(requestId));
      }
    },
    [location, dispatch]
  );

  const handleFetchProgram = useCallback(
    async (programId: string) => {
      if (location.pathname.includes(`${PATHS.PROGRAM_PAGE}/${programId}`)) {
        await dispatch(fetchProgram(programId));
      }
    },
    [location, dispatch]
  );

  const handleFetchTaskBundle = useCallback(
    async (bundleId: string) => {
      if (
        location.pathname.includes(
          PATHS.EDIT_TASK_BUNDLE_PAGE.replace(':taskBundleId', bundleId)
        )
      ) {
        await dispatch(fetchCurrentBundle(bundleId));
        await dispatch(fetchTaskTemplatesForBundle(bundleId));
      } else if (location.pathname.includes(PATHS.CONFIGURATIONS_PAGE)) {
        await dispatch(fetchTaskBundles());
      }
    },
    [location, dispatch]
  );

  const handleFetchTaskBundles = useCallback(
    async (bundleId: string) => {
      if (
        location.pathname.includes(
          PATHS.EDIT_TASK_BUNDLE_PAGE.replace(':taskBundleId', bundleId)
        )
      ) {
        history.push(
          `${PATHS.CONFIGURATIONS_PAGE}?section=${SETTINGS_SECTIONS.PROJECTS}&attribute=${SETTINGS_ATTRIBUTES.TASK_BUNDLES}`
        );
      } else if (location.pathname.includes(PATHS.CONFIGURATIONS_PAGE)) {
        await dispatch(fetchTaskBundles());
      }
    },
    [location, dispatch, history]
  );

  useEffect(() => {
    if (channelName && pusher) {
      const channel = pusher.subscribe(channelName);
      channel.bind(NOTIFICATION_TYPE.ASSIGN_NEW_OWNER, async (data: {}) => {
        handleFetchForRequests(get(data, 'requestId'), getRequest);
        showToast(data, NOTIFICATION_TYPE.ASSIGN_NEW_OWNER);
      });
      channel.bind(NOTIFICATION_TYPE.UNASSIGN_OWNER, async (data: {}) => {
        handleFetchForRequests(get(data, 'requestId'), getRequest);
        showToast(data, NOTIFICATION_TYPE.UNASSIGN_OWNER);
      });
      channel.bind(
        NOTIFICATION_TYPE.REQUEST_STATUS_UPDATED,
        async (data: {}) => {
          if (
            get(data, 'previousStatus') === REQUEST_STATUS.DRAFT &&
            get(data, 'currentStatus') === REQUEST_STATUS.SUBMITTED
          ) {
            await dispatch(getUserRequests());
          } else {
            handleFetchForRequests(get(data, 'requestId'), getRequest);
          }
          showToast(data, NOTIFICATION_TYPE.REQUEST_STATUS_UPDATED);
        }
      );
      channel.bind(
        NOTIFICATION_TYPE.REQUEST_QUESTIONS_UPDATED,
        async (data: {}) => {
          handleFetchForRequests(get(data, 'requestId'), getRequestQuestions);
          showToast(data, NOTIFICATION_TYPE.REQUEST_QUESTIONS_UPDATED);
        }
      );
      channel.bind(
        NOTIFICATION_TYPE.REQUEST_ATTRIBUTES_UPDATED,
        async (data: {}) => {
          handleFetchForRequests(get(data, 'requestId'), getRequest);
          showToast(data, NOTIFICATION_TYPE.REQUEST_ATTRIBUTES_UPDATED);
        }
      );
      channel.bind(NOTIFICATION_TYPE.QUESTION_COMMENTED, async (data: {}) => {
        handleFetchForRequests(get(data, 'requestId'), getQuestionComments);
        showToast(data, NOTIFICATION_TYPE.QUESTION_COMMENTED);
      });
      channel.bind(
        NOTIFICATION_TYPE.QUESTION_COMMENT_MENTION,
        async (data: {}) => {
          handleFetchForRequests(get(data, 'requestId'), getQuestionComments);
          showToast(data, NOTIFICATION_TYPE.QUESTION_COMMENT_MENTION);
        }
      );
      channel.bind(
        NOTIFICATION_TYPE.REQUEST_PROPERTY_COMMENTED,
        async (data: {}) => {
          handleFetchForRequests(get(data, 'requestId'), getPropertiesComments);
          showToast(data, NOTIFICATION_TYPE.REQUEST_PROPERTY_COMMENTED);
        }
      );
      channel.bind(
        NOTIFICATION_TYPE.REQUEST_PROPERTY_COMMENT_MENTION,
        async (data: {}) => {
          handleFetchForRequests(get(data, 'requestId'), getPropertiesComments);
          showToast(data, NOTIFICATION_TYPE.REQUEST_PROPERTY_COMMENT_MENTION);
        }
      );
      channel.bind(NOTIFICATION_TYPE.TASK_USER_ASSIGNED, async (data: {}) => {
        handleFetchTask(get(data, 'taskId'));
        showToast(data, NOTIFICATION_TYPE.TASK_USER_ASSIGNED);
      });
      channel.bind(
        NOTIFICATION_TYPE.TASK_USER_ASSIGNED_OWNER_VIEW,
        async (data: {}) => {
          handleFetchTask(get(data, 'taskId'));
          showToast(data, NOTIFICATION_TYPE.TASK_USER_ASSIGNED_OWNER_VIEW);
        }
      );
      channel.bind(NOTIFICATION_TYPE.TASK_USER_ASSIGNED, async (data: {}) => {
        handleFetchTask(get(data, 'taskId'));
        showToast(data, NOTIFICATION_TYPE.TASK_USER_ASSIGNED);
      });
      channel.bind(
        NOTIFICATION_TYPE.TASK_USER_UNASSIGNED_OWNER_VIEW,
        async (data: {}) => {
          handleFetchTask(get(data, 'taskId'));
          showToast(data, NOTIFICATION_TYPE.TASK_USER_UNASSIGNED_OWNER_VIEW);
        }
      );
      channel.bind(NOTIFICATION_TYPE.TASK_UPDATED, async (data: {}) => {
        handleFetchTask(get(data, 'taskId'));
        showToast(data, NOTIFICATION_TYPE.TASK_UPDATED);
      });
      channel.bind(
        NOTIFICATION_TYPE.TASK_ACTUAL_HOURS_UPDATED,
        async (data: {}) => {
          handleFetchTaskAndActualHours(get(data, 'taskId'));
          showToast(data, NOTIFICATION_TYPE.TASK_ACTUAL_HOURS_UPDATED);
        }
      );
      channel.bind(NOTIFICATION_TYPE.TASK_STATUS_UPDATED, async (data: {}) => {
        handleFetchTask(get(data, 'taskId'));
        showToast(data, NOTIFICATION_TYPE.TASK_STATUS_UPDATED);
      });
      channel.bind(NOTIFICATION_TYPE.TASK_DISABLED, async (data: {}) => {
        handleFetchTask(get(data, 'taskId'));
        showToast(data, NOTIFICATION_TYPE.TASK_DISABLED);
      });
      channel.bind(NOTIFICATION_TYPE.TASK_DELETED, async (data: {}) => {
        await dispatch(fetchTeamTasks(get(data, 'projectId')));
        showToast(data, NOTIFICATION_TYPE.TASK_DELETED);
      });
      channel.bind(NOTIFICATION_TYPE.TASK_COMMENT_MENTION, async (data: {}) => {
        handleFetchTask(get(data, 'taskId'));
        showToast(data, NOTIFICATION_TYPE.TASK_COMMENT_MENTION);
      });
      channel.bind(
        NOTIFICATION_TYPE.PROJECT_OWNER_ASSIGNED,
        async (data: {}) => {
          await dispatch(fetchProjects());
          handleFetchProject(get(data, 'projectId'));
          showToast(data, NOTIFICATION_TYPE.PROJECT_OWNER_ASSIGNED);
        }
      );
      channel.bind(
        NOTIFICATION_TYPE.PROJECT_OWNER_UNASSIGNED,
        async (data: {}) => {
          handleFetchProject(get(data, 'projectId'));
          showToast(data, NOTIFICATION_TYPE.PROJECT_OWNER_UNASSIGNED);
        }
      );
      channel.bind(
        NOTIFICATION_TYPE.PROJECT_STATUS_UPDATED,
        async (data: {}) => {
          handleFetchProject(get(data, 'projectId'));
          showToast(data, NOTIFICATION_TYPE.PROJECT_STATUS_UPDATED);
        }
      );
      channel.bind(NOTIFICATION_TYPE.PROJECT_UPDATED, async (data: {}) => {
        handleFetchProject(get(data, 'projectId'));
        showToast(data, NOTIFICATION_TYPE.PROJECT_UPDATED);
      });
      channel.bind(NOTIFICATION_TYPE.PROJECT_COMMENTED, async (data: {}) => {
        handleFetchProject(get(data, 'projectId'));
        showToast(data, NOTIFICATION_TYPE.PROJECT_COMMENTED);
      });
      channel.bind(
        NOTIFICATION_TYPE.PROJECT_COMMENT_MENTION,
        async (data: {}) => {
          showToast(data, NOTIFICATION_TYPE.PROJECT_COMMENT_MENTION);
        }
      );
      channel.bind(
        NOTIFICATION_TYPE.NEW_PROJECT_PARTICIPANT,
        async (data: {}) => {
          handleFetchProject(get(data, 'projectId'));
          showToast(data, NOTIFICATION_TYPE.NEW_PROJECT_PARTICIPANT);
        }
      );
      channel.bind(
        NOTIFICATION_TYPE.PROJECT_PARTICIPANT_REMOVED,
        async (data: {}) => {
          await dispatch(fetchProjects());
          showToast(data, NOTIFICATION_TYPE.PROJECT_PARTICIPANT_REMOVED);
        }
      );
      channel.bind(
        NOTIFICATION_TYPE.PROJECT_ASSIGNMENT_UPDATED,
        async (data: {}) => {
          handleFetchProject(get(data, 'projectId'));
          showToast(data, NOTIFICATION_TYPE.PROJECT_ASSIGNMENT_UPDATED);
        }
      );
      channel.bind(
        NOTIFICATION_TYPE.PROJECT_ASSIGNMENT_REMOVED,
        async (data: {}) => {
          await dispatch(fetchProjects());
          showToast(data, NOTIFICATION_TYPE.PROJECT_ASSIGNMENT_REMOVED);
        }
      );
      channel.bind(
        NOTIFICATION_TYPE.PROJECT_IS_ARCHIVED_UPDATED,
        async (data: {}) => {
          handleFetchProject(get(data, 'projectId'));
          showToast(data, NOTIFICATION_TYPE.PROJECT_IS_ARCHIVED_UPDATED);
        }
      );
      channel.bind(
        NOTIFICATION_TYPE.BUSINESS_USER_NEW_REGISTRATION,
        async (data: {}) => {
          await dispatch(getAllUsers());
          showToast(data, NOTIFICATION_TYPE.BUSINESS_USER_NEW_REGISTRATION);
        }
      );
      channel.bind(
        NOTIFICATION_TYPE.REQUEST_BUSINESS_REVIEWER,
        async (data: {}) => {
          await dispatch(getRequest(get(data, 'requestId')));
          showToast(data, NOTIFICATION_TYPE.REQUEST_BUSINESS_REVIEWER);
        }
      );
      channel.bind(NOTIFICATION_TYPE.TIME_OFF_REMOVED, async (data: {}) => {
        showToast(data, NOTIFICATION_TYPE.TIME_OFF_REMOVED);
      });
      channel.bind(NOTIFICATION_TYPE.TIME_OFF_CREATED, async (data: {}) => {
        showToast(data, NOTIFICATION_TYPE.TIME_OFF_CREATED);
      });
      channel.bind(NOTIFICATION_TYPE.TIME_OFF_UPDATED, async (data: {}) => {
        showToast(data, NOTIFICATION_TYPE.TIME_OFF_UPDATED);
      });
      channel.bind(NOTIFICATION_TYPE.PROGRAM_UPDATED, async (data: {}) => {
        await handleFetchProgram(get(data, 'programId'));
        showToast(data, NOTIFICATION_TYPE.PROGRAM_UPDATED);
      });
      channel.bind(
        NOTIFICATION_TYPE.PROJECT_LINKED_TO_PROGRAM,
        async (data: {}) => {
          if (get(data, 'project')) {
            await handleFetchProject(get(data, 'projectId'));
          } else {
            await handleFetchProgram(get(data, 'programId'));
          }
          showToast(data, NOTIFICATION_TYPE.PROJECT_LINKED_TO_PROGRAM);
        }
      );
      channel.bind(
        NOTIFICATION_TYPE.PROJECT_UNLINKED_FROM_PROGRAM,
        async (data: {}) => {
          if (get(data, 'project')) {
            await handleFetchProject(get(data, 'projectId'));
          } else {
            await handleFetchProgram(get(data, 'programId'));
          }
          showToast(data, NOTIFICATION_TYPE.PROJECT_UNLINKED_FROM_PROGRAM);
        }
      );
      channel.bind(NOTIFICATION_TYPE.PROGRAM_CLOSED, async (data: {}) => {
        await handleFetchProgram(get(data, 'programId'));
        showToast(data, NOTIFICATION_TYPE.PROGRAM_CLOSED);
      });
      channel.bind(NOTIFICATION_TYPE.PROGRAM_DELETED, async (data: {}) => {
        if (get(data, 'flag') === DELETE_PROGRAM_OPTIONS.MOVE_PROJECTS) {
          await handleFetchProgram(get(data, 'newProgramId'));
        }
        showToast(data, NOTIFICATION_TYPE.PROGRAM_DELETED);
      });
      channel.bind(NOTIFICATION_TYPE.TASK_BUNDLE_UPDATED, async (data: {}) => {
        await handleFetchTaskBundle(get(data, 'bundleId'));
        showToast(data, NOTIFICATION_TYPE.TASK_BUNDLE_UPDATED);
      });
      channel.bind(NOTIFICATION_TYPE.TASK_BUNDLE_DELETED, async (data: {}) => {
        await handleFetchTaskBundles(get(data, 'bundleId'));
        showToast(data, NOTIFICATION_TYPE.TASK_BUNDLE_DELETED);
      });
      channel.bind(
        NOTIFICATION_TYPE.V2_PROJECT_PARTICIPANT_ADDED,
        async (data: {}) => {
          handleFetchProject(get(data, 'projectId'));
          showToast(data, NOTIFICATION_TYPE.V2_PROJECT_PARTICIPANT_ADDED);
        }
      );
      channel.bind(
        NOTIFICATION_TYPE.V2_PROJECT_PARTICIPANT_REMOVED,
        async (data: {}) => {
          handleFetchProject(get(data, 'projectId'));
          showToast(data, NOTIFICATION_TYPE.V2_PROJECT_PARTICIPANT_REMOVED);
        }
      );
    }

    return () => {
      if (channelName) {
        pusher.unsubscribe(channelName);
      }
    };
  }, [
    channelName,
    dispatch,
    pusher,
    showToast,
    handleFetchProject,
    handleFetchProgram,
    handleFetchTask,
    handleFetchTaskAndActualHours,
    handleFetchForRequests,
    handleFetchTaskBundle,
    handleFetchTaskBundles,
  ]);

  return { list, setList };
};

export default useNotifications;
