import React from 'react';
import intl from 'react-intl-universal';
import { ParticipantTypes } from 'utils/constants';
import { Typography } from '@getsynapse/design-system';
import useWeeksSlider from '../../../hooks/useWeeksSlider';
import Timeline, { Column } from '../../Timeline/Timeline';

const EmptySection: React.FC<{ participantType: ParticipantTypes }> = ({
  participantType,
}) => {
  const { weeksArray } = useWeeksSlider();
  const emptySectionLabel: Record<ParticipantTypes, string> = {
    [ParticipantTypes.OWNERS]: intl.get(
      'PEOPLE.RESOURCE_ALLOCATION.TABLE.SECTIONS.EMPTY_OWNER'
    ),
    [ParticipantTypes.LEARNING]: intl.get(
      'PEOPLE.RESOURCE_ALLOCATION.TABLE.SECTIONS.EMPTY_LD'
    ),
    [ParticipantTypes.BUSINESS]: intl.get(
      'PEOPLE.RESOURCE_ALLOCATION.TABLE.SECTIONS.EMPTY_BUSINESS'
    ),
    [ParticipantTypes.EXTERNAL]: intl.get(
      'PEOPLE.RESOURCE_ALLOCATION.TABLE.SECTIONS.EMPTY_EXTERNAL'
    ),
    [ParticipantTypes.FACILITATOR]: intl.get(
      'PEOPLE.RESOURCE_ALLOCATION.TABLE.SECTIONS.EMPTY_FACILITATOR'
    ),
  };

  return (
    <div
      className='h-10 flex flex-1'
      data-cy={`${participantType}-section-empty`}
    >
      <div className='w-2/5 flex'>
        <div className='w-12' />
        <div className='h-full flex flex-1 items-center'>
          <Typography className='text-neutral-darker ml-2' variant='caption'>
            {emptySectionLabel[participantType]}
          </Typography>
        </div>
      </div>
      <div className='w-3/5'>
        <Timeline className='bg-neutral-white' displayGridLoader={false}>
          {weeksArray.map((week, index) => (
            <Column
              key={week.startWeekDate.valueOf() + index}
              isCurrentWeek={week.isCurrentWeek}
            />
          ))}
        </Timeline>
      </div>
    </div>
  );
};

export default EmptySection;
