import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import {
  Dropdown,
  FormItem,
  Tag,
  Checkbox,
  Typography,
} from '@getsynapse/design-system';
import { getProjectFiles } from 'state/Project/projectSlice';
import {
  getLinkedTaskContentFilesIds,
  updateLinkedContent,
} from 'state/SingleTask/singleTaskSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { getContentTypeLabel } from 'Pages/ProjectPage/tabs/Content/components/ContentTable/utils';
import { ProjectContent } from 'utils/customTypes';
import { PROJECT_CONTENT_TYPE } from 'utils/constants';
import useModal from 'Hooks/useModal';
import designIcon from 'assets/icons/design-icon.svg';

const LinkContentModal: React.FC<{
  isModalOpen: boolean;
  onModalClose: () => void;
  taskId: string;
}> = ({ isModalOpen, onModalClose, taskId }) => {
  const dispatch = useDispatch();
  const { Modal, modalProps, openModal, closeModal } = useModal();
  const projectContentFiles = useSelector(getProjectFiles);
  const originalLinkedTaskContentFilesIds = useSelector(
    getLinkedTaskContentFilesIds
  );
  const [linkedContentFiles, setLinkedContentFiles] = useState<
    ProjectContent[]
  >([]);

  const initCurrentLinkedFilesArray = useCallback(() => {
    const currentLinkedFiles = [];
    for (const contentFile of projectContentFiles) {
      if (originalLinkedTaskContentFilesIds?.includes(contentFile.id!)) {
        currentLinkedFiles.push(contentFile);
      }
    }
    setLinkedContentFiles(currentLinkedFiles);
  }, [projectContentFiles, originalLinkedTaskContentFilesIds]);

  useEffect(() => {
    if (isModalOpen && !modalProps.isOpen) {
      openModal(modalProps);
    }
  }, [isModalOpen, modalProps, openModal]);

  useEffect(() => initCurrentLinkedFilesArray(), [initCurrentLinkedFilesArray]);

  const canSubmit = useMemo(() => {
    const currentLinkedContentFilesIds = linkedContentFiles.map(
      (contentFile: ProjectContent) => contentFile.id
    );
    return !isEqual(
      originalLinkedTaskContentFilesIds?.sort(),
      currentLinkedContentFilesIds.sort()
    );
  }, [originalLinkedTaskContentFilesIds, linkedContentFiles]);

  const handleCloseModal = () => {
    onModalClose();
    closeModal();
  };

  const onConfirm = async () => {
    await dispatch(updateLinkedContent({ taskId, linkedContentFiles }));
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get(
          'TASKS.TASK_DETAIL_PAGE.CONTENT.CONTENT_LINKED_MESSAGE'
        ),
      })
    );
    handleCloseModal();
  };

  const actionButtons = [
    {
      children: intl.get('SAVE'),
      variant: 'primary',
      onClick: onConfirm,
      'data-cy': 'confirm-button',
      disabled: !canSubmit,
    },
    {
      children: intl.get('CANCEL'),
      variant: 'tertiary',
      onClick: () => {
        initCurrentLinkedFilesArray();
        handleCloseModal();
      },
    },
  ];

  return (
    <Modal
      {...modalProps}
      title={intl.get(
        'TASKS.TASK_DETAIL_PAGE.CONTENT.LINK_CONTENT_MODAL_TITLE'
      )}
      size='large'
      actionButtons={actionButtons}
      closeModal={handleCloseModal}
      data-cy='link-content-modal'
      aria-label={intl.get(
        'TASKS.TASK_DETAIL_PAGE.CONTENT.LINK_CONTENT_MODAL_TITLE'
      )}
    >
      <FormItem
        label={intl.get(
          'TASKS.TASK_DETAIL_PAGE.CONTENT.LINK_CONTENT_MODAL_BODY'
        )}
      >
        <Dropdown
          multiple
          onChange={(options: ProjectContent[]) =>
            setLinkedContentFiles(options)
          }
          options={projectContentFiles}
          values={linkedContentFiles}
          getOptionLabel={(option: ProjectContent) => option.name!}
          triggerProps={{
            'data-cy': 'link-content-dropdown',
            className: 'pb-1',
          }}
          renderOption={(
            option: ProjectContent,
            isSelected,
            selectOption: () => void,
            { className, ...restProps }
          ) => {
            return (
              <li
                key={option.id}
                {...restProps}
                className={classnames('group', className, {
                  'hover:bg-primary focus-visible:bg-primary': isSelected,
                })}
                data-cy={`content-file-option--${option.id}`}
              >
                <Checkbox
                  checked={isSelected}
                  onChange={selectOption}
                  label={
                    <div className='flex'>
                      <Typography
                        className={classnames({
                          'group-hover:text-neutral-white': isSelected,
                        })}
                      >
                        {option.name}
                      </Typography>
                      <Tag
                        label={getContentTypeLabel(option.content_type)}
                        className='bg-neutral-lighter-two ml-2 group-hover:bg-neutral-dark'
                        textClassName='text-neutral-darker font-semibold group-hover:text-neutral-white'
                      />
                    </div>
                  }
                  inputProps={{
                    className: classnames({
                      'group-hover:border-neutral-white': isSelected,
                      'group-focus-visible:border-neutral-white': isSelected,
                    }),
                  }}
                  className={classnames({
                    'group-hover:text-neutral-white': isSelected,
                    'group-focus-visible:text-neutral-white': isSelected,
                  })}
                />
              </li>
            );
          }}
          renderSelectedOptionTag={(
            { textClassName, label, ...restOfProps },
            selectedOption: any
          ) => {
            const projectContentFile = selectedOption as ProjectContent;
            const leadingIconProps: { name?: string; src?: string } = {};
            switch (projectContentFile.content_type) {
              case PROJECT_CONTENT_TYPE.FILE:
              case PROJECT_CONTENT_TYPE.DESIGN_FILE:
                leadingIconProps['name'] = 'document-outline';
                break;
              case PROJECT_CONTENT_TYPE.WEB_LINK:
                leadingIconProps['name'] = 'link-outline';
                break;
              case PROJECT_CONTENT_TYPE.DESIGN:
                leadingIconProps['src'] = designIcon;
                break;
              default:
                leadingIconProps['name'] = 'checkmark-circle-outline';
                break;
            }

            return (
              <Tag
                {...restOfProps}
                textClassName={classnames(
                  'text-xs text-secondary-darker font-semibold',
                  'group-hover:text-secondary-lighter',
                  textClassName
                )}
                leadingIcon={{
                  ...leadingIconProps,
                  className: 'text-base',
                }}
                className='group bg-secondary-lightest hover:bg-secondary-darker mr-1 mb-1 last:mr-0'
                label={projectContentFile.name}
                tailingIcon={{ className: 'text-base' }}
              />
            );
          }}
        />
      </FormItem>
    </Modal>
  );
};

export default LinkContentModal;
