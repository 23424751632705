import {
  useRef,
  useState,
  useEffect,
  useMemo,
  createRef,
  RefObject,
  useCallback,
} from 'react';
import intl from 'react-intl-universal';
import {
  Dropdown,
  tailwindOverride,
  Tooltip,
  Checkbox,
} from '@getsynapse/design-system';
import { ScheduleProjectOption } from 'utils/types/learningSchedule';
import { Option } from 'utils/customTypes';
import { getStatusColumn } from 'Pages/ProjectsListPage/helpers/tableColumnsValues';

type ProjectsDropdownProps = {
  onChange: (options: Option[]) => void;
  value: string[];
  projects: ScheduleProjectOption[];
};

const ProjectsDropdown = ({
  onChange,
  value,
  projects,
}: ProjectsDropdownProps) => {
  const processRefs = useRef<RefObject<HTMLLIElement>[]>([]);

  const [truncatedItems, setTruncatedItems] = useState<boolean[]>([]);
  const [isDropdownFirstOpen, setIsDropdownFirstOpen] = useState(false);

  const selectedValues = useMemo(
    () =>
      value.length
        ? projects
            .filter((project) => value.includes(project.id))
            .map((project) => ({
              label: project.title,
              value: project.id,
            }))
        : [],
    [value, projects]
  );

  const dropdownOptions = useMemo(
    () =>
      projects.map((project) => ({
        label: project.title,
        value: project.id,
      })),
    [projects]
  );

  useEffect(() => {
    processRefs.current = dropdownOptions!.map(
      (_, i) => processRefs.current[i] ?? createRef()
    );
  }, [dropdownOptions]);

  useEffect(() => {
    if (isDropdownFirstOpen) {
      requestAnimationFrame(() => {
        const truncationStates = processRefs.current.map((ref) => {
          const item = ref.current;
          return item ? item.scrollWidth > item.clientWidth : false;
        });
        setTruncatedItems(truncationStates);
      });
    }
  }, [isDropdownFirstOpen]);

  const getProjectStatus = useCallback(
    (value: string) => {
      const project = projects.find((project) => project.id === value);
      return project?.status;
    },
    [projects]
  );

  const renderOption = useCallback(
    (
      option: Option,
      selected: boolean,
      selectOption: (option: Option) => void,
      { className, ...otherProps }
    ) => {
      const index = dropdownOptions.findIndex(
        (opt) => opt.value === option.value
      );
      const isCurrentItemTruncated = truncatedItems[index];

      const optionItem = (
        <li
          {...otherProps}
          ref={processRefs.current[index]}
          className={tailwindOverride('flex justify-between	group', className, {
            'hover:bg-secondary-lightest hover:shadow-list-item-hover-green':
              !selected,
            'hover:bg-primary focus-visible:bg-primary': selected,
          })}
        >
          <Checkbox
            checked={selected}
            onChange={() => selectOption(option)}
            label={option.label}
            inputProps={{
              className: tailwindOverride('mt-0', {
                'group-hover:border-neutral-white group-focus-visible:border-neutral-white':
                  selected,
                'group-hover:border-secondary-dark group-focus-visible:border-secondary-dark':
                  !selected,
              }),
            }}
            className={tailwindOverride('inline-block', {
              'group-hover:text-secondary-darker group-focus-visible:text-secondary-darker':
                !selected,
              'group-hover:text-neutral-white group-focus-visible:text-neutral-white':
                selected,
              truncate: isCurrentItemTruncated,
            })}
          />
          {getStatusColumn(getProjectStatus(option.value))}
        </li>
      );

      return isCurrentItemTruncated ? (
        <Tooltip
          className='w-full'
          ariaId='dropdown-tooltip'
          openMode='hover1'
          timeout={0}
          trigger={optionItem}
          usePortal={true}
          showPopper={true}
          contentProps={{ className: 'z-10 text-sm' }}
        >
          {option.label}
        </Tooltip>
      ) : (
        optionItem
      );
    },
    [dropdownOptions, truncatedItems, getProjectStatus]
  );

  return (
    <Dropdown
      onClick={() => {
        if (!isDropdownFirstOpen) setIsDropdownFirstOpen(true);
      }}
      values={selectedValues}
      onChange={onChange}
      options={dropdownOptions}
      filterable
      multiple
      triggerProps={{
        'data-testid': 'projects-dropdown-trigger',
      }}
      listProps={{
        'data-testid': 'projects-dropdown-list',
      }}
      renderOption={renderOption}
      placeholder={intl.get(
        'SCHEDULE.EVENTS.DETAILS_SECTION.PROJECT_PLACEHOLDER'
      )}
    />
  );
};

export default ProjectsDropdown;
