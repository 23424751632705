import { Input, TextArea } from '@getsynapse/design-system';
import { ChangeEvent, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { ALLOWED_FIELD_TYPES } from 'utils/constants';
import {
  TextFieldTemplateType,
  TextFieldType,
  TextValue,
} from 'utils/types/fields';

interface TextFieldProps {
  field?: TextFieldType;
  fieldTemplate: TextFieldTemplateType;
  onChange: (newValue: TextValue) => void;
  disabled: boolean;
  state?: 'default' | 'error';
}

const TextField = ({
  field,
  fieldTemplate,
  onChange,
  disabled,
  state = 'default',
}: TextFieldProps) => {
  const defaultValue = useMemo<string | null>(() => {
    if (field) {
      return field.value.val;
    } else if (fieldTemplate.default_value) {
      return fieldTemplate.default_value.val;
    } else {
      return null;
    }
  }, [field, fieldTemplate.default_value]);

  const onChangeHandler = debounce((value: string) => {
    onChange({ val: value || null });
  }, 500);

  return fieldTemplate.type === ALLOWED_FIELD_TYPES.MULTILINE ? (
    <TextArea
      textAreaProps={{
        defaultValue,
        'data-cy': `multiline-text__custom-field-${fieldTemplate.id}`,
      }}
      onChange={(event) => {
        onChangeHandler(event.target.value);
      }}
      disabled={disabled}
      state={state}
    />
  ) : (
    <Input
      defaultValue={defaultValue}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        onChangeHandler(event.target.value);
      }}
      disabled={disabled}
      state={state}
      data-cy={`single-text__custom-field-${fieldTemplate.id}`}
    />
  );
};

export default TextField;
