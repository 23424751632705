import { useEffect, useState, useCallback } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import intl from 'react-intl-universal';

import { useAppThunkDispatch } from 'state/store';
import { showNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { addTaskBundle } from 'state/TasksBundle/tasksBundleSlice';
import {
  bulkCreateOrUpdateTemplates,
  bulkDeleteTaskTemplatesForBundle,
  fetchTaskTemplatesForBundle,
  updateBundle,
} from 'state/ActiveTaskBundle/activeTaskBundleSlice';

import {
  getTemplatesToBeCreatedOrUpdated,
  getTemplatesToBeDeleted,
} from './helpers';
import useTaskBundle from './useTaskBundle';
import useTaskBundleEdit from './useTaskBundleEdit';

import useNavigation from 'Hooks/useNavigation';
import { PATHS } from 'utils/constants';
import { TASK_BUNDLE_OPERATION_MODES } from 'utils/constants/taskBundles';
import { TaskBundle } from 'utils/customTypes';

const mapPathToOperationMode = new Map<string, TASK_BUNDLE_OPERATION_MODES>([
  [PATHS.NEW_TASK_BUNDLE_PAGE, TASK_BUNDLE_OPERATION_MODES.CREATE],
  [PATHS.CLONE_TASK_BUNDLE_PAGE, TASK_BUNDLE_OPERATION_MODES.CLONE],
  [PATHS.EDIT_TASK_BUNDLE_PAGE, TASK_BUNDLE_OPERATION_MODES.EDIT],
]);

export const useTaskBundleOperations = () => {
  const dispatch = useAppThunkDispatch();
  const { goToBackPage } = useNavigation();
  const match = useRouteMatch();
  const taskBundleOperationMode: TASK_BUNDLE_OPERATION_MODES =
    mapPathToOperationMode.get(match.path) ??
    TASK_BUNDLE_OPERATION_MODES.CREATE;
  const { taskBundleId } = useParams<{ taskBundleId: string }>();
  const { taskBundle, activeBundleStatus, taskTemplates } =
    useTaskBundle(taskBundleId);
  const {
    localTaskBundle,
    localTaskTemplates,
    updateLocalTaskBundle,
    setLocalTaskBundle,
    setLocalTaskTemplates,
    isBundleOverviewValid,
    overViewChanged,
    templatesChanged,
  } = useTaskBundleEdit(taskBundle, taskTemplates);

  const isEditing: boolean =
    taskBundleOperationMode === TASK_BUNDLE_OPERATION_MODES.EDIT;
  const isCreating: boolean =
    taskBundleOperationMode === TASK_BUNDLE_OPERATION_MODES.CREATE;
  const isCloning: boolean =
    taskBundleOperationMode === TASK_BUNDLE_OPERATION_MODES.CLONE;

  const [hasChanges, setHasChanges] = useState<boolean>(true);
  const [canSave, setCanSave] = useState(false);
  const [modifiedTemplatesIds, setModifiedTemplatesIds] = useState<Set<string>>(
    new Set([])
  );

  useEffect(() => {
    if (isCloning && taskBundle.id) {
      setLocalTaskBundle({
        name: intl.get(
          'SETTINGS_PAGE.TASK_BUNDLE_PAGE.OVERVIEW_SECTION.TITLE_CLONE',
          {
            taskBundleName: taskBundle.name,
          }
        ),
        description: taskBundle.description,
      } as TaskBundle);
      setLocalTaskTemplates(
        taskTemplates.map((taskTemplate) => ({
          id: uuidv4(),
          name: taskTemplate.name,
          description: taskTemplate.description,
          estimated_hours: taskTemplate.estimated_hours,
          type: taskTemplate.type,
        }))
      );
    }
  }, [
    isCloning,
    setLocalTaskTemplates,
    taskBundle.description,
    taskBundle.id,
    taskBundle.name,
    taskTemplates,
    setLocalTaskBundle,
  ]);

  useEffect(() => {
    if (isEditing) {
      const changedDetected: boolean = overViewChanged || templatesChanged;
      setHasChanges(changedDetected);
      setCanSave(changedDetected && isBundleOverviewValid);
    } else {
      const changedDetected: boolean =
        localTaskTemplates.length > 0 ||
        Boolean(localTaskBundle.name) ||
        Boolean(localTaskBundle.description);
      setHasChanges(isCloning || changedDetected);
      setCanSave(isBundleOverviewValid);
    }
  }, [
    isBundleOverviewValid,
    isCloning,
    isEditing,
    localTaskBundle.description,
    localTaskBundle.name,
    localTaskTemplates.length,
    overViewChanged,
    templatesChanged,
  ]);

  const onCreateBundle = useCallback(async () => {
    const { taskBundle: createdTaskBundle } = await dispatch(
      addTaskBundle(localTaskBundle)
    ).unwrap();
    if (
      localTaskTemplates.length > 0 &&
      createdTaskBundle &&
      createdTaskBundle.id
    ) {
      const templatesToCreate = localTaskTemplates.map((template, index) => ({
        ...template,
        action_type: 'create',
        index,
      }));

      dispatch(
        bulkCreateOrUpdateTemplates({
          templates: templatesToCreate,
          bundleId: createdTaskBundle.id,
        })
      );
    }
    dispatch(
      showNotification({
        autoHide: true,
        notificationVariant: 'success',
        notificationTitle: intl.get(
          'SETTINGS_PAGE.TASK_BUNDLE_PAGE.CREATED_SUCCESSFULLY'
        ),
      })
    );
    setHasChanges(false);
    goToBackPage();
  }, [dispatch, goToBackPage, localTaskBundle, localTaskTemplates]);

  const onUpdateBundle = useCallback(async () => {
    const bundleId = taskBundle?.id;

    if (overViewChanged) {
      await dispatch(updateBundle(localTaskBundle));
    }
    const originalTemplates = [...taskTemplates];

    const templatesToDelete = getTemplatesToBeDeleted({
      originalTemplateData: originalTemplates,
      updatedTemplateData: localTaskTemplates,
    });

    const templatesToCreateOrUpdate = getTemplatesToBeCreatedOrUpdated({
      originalTemplateData: originalTemplates,
      updatedTemplateData: localTaskTemplates,
      newAndUpdatedTemplatesIds: modifiedTemplatesIds,
    });

    let creatorIsNotified = false;

    if (templatesToDelete.length > 0) {
      await dispatch(
        bulkDeleteTaskTemplatesForBundle({
          bundleId,
          templatesToDelete,
        })
      );
      creatorIsNotified = true;
    }

    if (templatesToCreateOrUpdate.length > 0) {
      await dispatch(
        bulkCreateOrUpdateTemplates({
          templates: templatesToCreateOrUpdate,
          bundleId,
          creatorIsNotified,
        })
      );
    }
    setModifiedTemplatesIds(new Set([]));

    dispatch(
      showNotification({
        autoHide: true,
        notificationVariant: 'success',
        notificationTitle: intl.get(
          'SETTINGS_PAGE.TASK_BUNDLE_PAGE.UPDATED_SUCCESSFULLY'
        ),
      })
    );

    dispatch(fetchTaskTemplatesForBundle(taskBundleId));
  }, [
    dispatch,
    localTaskBundle,
    localTaskTemplates,
    modifiedTemplatesIds,
    overViewChanged,
    taskBundle?.id,
    taskBundleId,
    taskTemplates,
  ]);

  return {
    activeBundleStatus,
    localTaskBundle,
    localTaskTemplates,
    updateLocalTaskBundle,
    setLocalTaskTemplates,
    canSave,
    hasChanges,
    onSaveBundle: isEditing ? onUpdateBundle : onCreateBundle,
    setModifiedTemplatesIds,
    isEditing,
    isCreating,
    isCloning,
  };
};
