import { createSlice, createAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { getWeeksAndDays } from 'Pages/TeamsPage/views/TeamsCapacity/components/DailyCapacity/helpers';
import { resetCapacitiesState } from '../../Capacities/TeamsList/teamsListSlice';
import {
  addItemToTheEnd,
  addItemToTheStart,
  removeItemFromTheEnd,
  removeItemFromTheStart,
} from './helpers';
import { WeeksAndDaysArray } from 'utils/types/dailyTeamsCapacity';

interface Days {
  startDate: string;
  endDate: string;
  items: WeeksAndDaysArray;
  numberOfDays: number;
}

/* ============================= INITIAL STATE ============================== */
const initialState: Days = {
  startDate: '',
  endDate: '',
  items: {},
  numberOfDays: 12,
};

/* ============================== ACTIONS =============================== */
export const initWeeksAndDaysArray = createAction<{
  startDate: string;
  endDate: string;
}>('days/INIT_DAYS_ARRAY');
export const moveToPreviousDay = createAction('days/MOVE_TO_PREVIOUS_DAY');
export const moveToNextDay = createAction('days/MOVE_TO_NEXT_DAY');
export const setNumberOfDays = createAction<number>('days/SET_NUMBER_OF_DAYS');

/* =============================== SELECTORS ================================ */
const selectDaysItems = (state: RootState) =>
  state.dailyTeamsCapacity.days.items;
export const selectNumberOfDays = (state: RootState) =>
  state.dailyTeamsCapacity.days.numberOfDays;
export const selectWeeksAndDays = createSelector(
  [selectDaysItems, selectNumberOfDays],
  (items: WeeksAndDaysArray, numberOfDays: number) => {
    let pivot = numberOfDays;
    const weeksAndDays: WeeksAndDaysArray = {};
    for (const [week, days] of Object.entries(items)) {
      if (pivot <= 0) {
        break;
      }
      if (pivot > days.length) {
        weeksAndDays[week] = days;
      }
      if (pivot <= days.length) {
        weeksAndDays[week] = days.slice(0, pivot);
      }
      pivot -= days.length;
    }
    return weeksAndDays;
  }
);
export const selectWeekDays = createSelector(
  [selectWeeksAndDays],
  (weeksAndDaysArray: WeeksAndDaysArray) => {
    return Object.values(weeksAndDaysArray).reduce<string[]>(
      (acc, current) => [...acc, ...current],
      []
    );
  }
);

export const selectDatesRange = (state: RootState) => {
  const { startDate, endDate } = state.dailyTeamsCapacity.days;
  return { startDate, endDate };
};

/* ================================= REDUCER ================================ */
const daysSlice = createSlice({
  name: 'days',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initWeeksAndDaysArray, (state, action) => {
        if (!action.payload) {
          return;
        }
        state.startDate = action.payload.startDate;
        state.endDate = action.payload.endDate;
        state.items = getWeeksAndDays(
          action.payload.startDate,
          action.payload.endDate
        );
      })
      .addCase(setNumberOfDays, (state, action) => {
        if (!action.payload) {
          return;
        }
        state.numberOfDays = action.payload;
      })
      .addCase(moveToPreviousDay, (state) => {
        const startDate = new Date(state.startDate.replace(/-/g, '/'));
        const endDate = new Date(state.endDate.replace(/-/g, '/'));
        const {
          newStartDate,
          updatedWeeksAndDaysArray: updatedWeeksAndDaysArrayAfterAddItem,
        } = addItemToTheStart(startDate, { ...state.items });
        const {
          newEndDate,
          updatedWeeksAndDaysArray: updatedWeeksAndDaysArrayAfterRemoveItem,
        } = removeItemFromTheEnd(endDate, {
          ...updatedWeeksAndDaysArrayAfterAddItem,
        });
        state.startDate = newStartDate;
        state.endDate = newEndDate;
        state.items = updatedWeeksAndDaysArrayAfterRemoveItem;
      })
      .addCase(moveToNextDay, (state) => {
        const startDate = new Date(state.startDate.replace(/-/g, '/'));
        const endDate = new Date(state.endDate.replace(/-/g, '/'));
        const {
          newStartDate,
          updatedWeeksAndDaysArray: updatedWeeksAndDaysArrayAfterRemoveItem,
        } = removeItemFromTheStart(startDate, { ...state.items });
        const {
          newEndDate,
          updatedWeeksAndDaysArray: updatedWeeksAndDaysArrayAfterAddItem,
        } = addItemToTheEnd(endDate, {
          ...updatedWeeksAndDaysArrayAfterRemoveItem,
        });
        state.endDate = newEndDate;
        state.items = updatedWeeksAndDaysArrayAfterAddItem;
        state.startDate = newStartDate;
      })
      .addCase(resetCapacitiesState, (state) => {
        state.startDate = initialState.startDate;
        state.endDate = initialState.endDate;
        state.items = initialState.items;
        state.numberOfDays = initialState.numberOfDays;
      });
  },
});

export default daysSlice.reducer;
