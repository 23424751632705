import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import {
  selectDailyCapacityByTeamId,
  selectVisibleTeams,
} from 'state/DailyTeamsCapacity/TeamsCapacity/teamsCapacitySlice';
import { DailyCapacityProps } from 'utils/types/dailyTeamsCapacity';
import NoDailyCapacity from '../../DaysCarousel/components/NoDailyCapacity/NoDailyCapacity';
import DaysCarousel from '../../DaysCarousel/DaysCarousel';
import TeamDailyCapacity from '../TeamDailyCapacity/TeamDailyCapacity';

export type TeamDailyCapacitiesProps = {
  id: string;
};

const TeamDailyCapacities: React.FC<TeamDailyCapacitiesProps> = ({ id }) => {
  const dailyCapacity = useSelector((state: RootState) =>
    selectDailyCapacityByTeamId(state, id)
  );
  const visibleTeams = useSelector(selectVisibleTeams);
  const isTeamVisible = visibleTeams.includes(id);

  if (Object.keys(dailyCapacity).length === 0) {
    return (
      <div className='w-4/6 h-full flex'>
        <NoDailyCapacity id={`team-${id}`} isLoading={isTeamVisible} />
      </div>
    );
  }
  return (
    <div className='w-4/6 h-full flex'>
      <DaysCarousel<DailyCapacityProps>
        id={`team-${id}`}
        component={TeamDailyCapacity}
        items={dailyCapacity}
      />
    </div>
  );
};

export default React.memo(TeamDailyCapacities);
