import { useEffect, useState } from 'react';
import { useParams, useHistory, Prompt } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Location } from 'history';
import intl from 'react-intl-universal';
import { trackEvent } from 'Services/pendo';
import {
  Button,
  Typography,
  FormItem,
  LargeLabel,
  NumericInput,
  Icon,
} from '@getsynapse/design-system';
import { PATHS, PENDO_EVENTS, SLICE_STATUS } from 'utils/constants';
import removeIcon from 'assets/icons/trash-bin-small.svg';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { getProgramData, fetchProgram } from 'state/Program/programSlice';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import PageFooter from 'Molecules/PageFooter/PageFooter';
import UnsavedChangesModal from 'Organisms/UnsavedChangesModal/UnsavedChangesModal';
import ROIObjectiveSkeletonLoader from './components/ROIObjectiveSkeletonLoader';
import {
  fetchROICategory,
  resetROICategory,
  selectROICategoryStatus,
  updateROIObjective,
  selectROIObjective,
} from 'state/ROICategory/ROICategoySlice';
import ROIObjectiveTag from 'Pages/ROICategoryPage/components/ObjectiveSetting/ROIObjectiveTag';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import targetIcon from 'assets/icons/target-icon-green.svg';
import { STRATEGY_CATEGORIES } from 'utils/constants/program';
import DeleteROIObjectiveModal from './components/DeleteROIObjectiveModal/DeleteROIObjectiveModal';

const ROIObjectivePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { programId, categoryId, objectiveId } = useParams<{
    programId: string;
    categoryId: string;
    objectiveId: string;
  }>();

  const programSelector = useSelector(getProgramData);
  const roiObjectiveSelector = useSelector(selectROIObjective);
  const roiStatusSelector = useSelector(selectROICategoryStatus);

  const [roi, setROI] = useState<number | null>();
  const [confirmedNavigation, setConfirmedNavigation] =
    useState<boolean>(false);
  const [isLeavingWarningModalOpen, setIsLeavingWarningModalOpen] =
    useState<boolean>(false);
  const [leavingToLocation, setLeavingToLocation] = useState<string>('');
  const [isROINumberValid, setIsROINumberValid] = useState<boolean>(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const isDataLoading =
    roiStatusSelector === SLICE_STATUS.LOADING || !roiObjectiveSelector?.id;

  const canSave =
    roiObjectiveSelector?.roi !== roi && isROINumberValid && roi !== null;

  useEffect(() => {
    if (confirmedNavigation && leavingToLocation) {
      history.push(leavingToLocation);
      setConfirmedNavigation(false);
      setLeavingToLocation('');
    }
  }, [confirmedNavigation, history, leavingToLocation]);

  useEffect(() => {
    if (programId && categoryId) {
      dispatch(fetchProgram(programId));
      dispatch(fetchROICategory({ programId, categoryId }));
    }

    return () => {
      resetROICategory();
    };
  }, [dispatch, categoryId, programId]);

  useEffect(() => {
    setROI(roiObjectiveSelector?.roi);
  }, [roiObjectiveSelector?.roi]);

  const onCancel = () => {
    history.push(
      `${PATHS.PROGRAM_PAGE}/${programId}${PATHS.ROI_CATEGORY_PAGE}/${categoryId}`
    );
  };

  const onSave = async () => {
    if (canSave) {
      try {
        await dispatch(
          updateROIObjective({
            programId,
            categoryId,
            roiId: objectiveId,
            roi: roi!,
          })
        );
        dispatch(
          showSnackbarNotification({
            autoHide: false,
            notificationVariant: 'success',
            notificationTitle: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.SUCCESS_TITLE'
            ),
          })
        );
        trackEvent(PENDO_EVENTS.SAVE_OBJECTIVE, {
          id: roiObjectiveSelector?.id,
          roi,
        });
        setConfirmedNavigation(true);
        history.push(
          `${PATHS.PROGRAM_PAGE}/${programId}${PATHS.ROI_CATEGORY_PAGE}/${categoryId}`
        );
      } catch (err) {
        dispatch(
          showSnackbarNotification({
            autoHide: false,
            notificationVariant: 'error',
            notificationTitle: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.UPDATE_ERROR_TITLE'
            ),
            notificationMessage: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.ERROR.MESSAGE'
            ),
          })
        );
      }
    }
  };

  const handleBlockedNavigation = (location: Location) => {
    if (!confirmedNavigation && roiObjectiveSelector.id) {
      setLeavingToLocation(`${location.pathname}${location.search}`);
      setIsLeavingWarningModalOpen(true);
      return false;
    }
    return true;
  };

  return (
    <div className='h-full flex flex-col'>
      <DeleteROIObjectiveModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      />
      <Prompt when={canSave} message={handleBlockedNavigation} />
      <UnsavedChangesModal
        isOpen={isLeavingWarningModalOpen}
        setIsOpen={setIsLeavingWarningModalOpen}
        onConfirm={() => setConfirmedNavigation(true)}
      />
      {isDataLoading && <ROIObjectiveSkeletonLoader />}
      {!isDataLoading && (
        <>
          <PageTitle
            className='w-full'
            titleComponent={intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.CATEGORY_TITLE',
              {
                program: programSelector.title,
                category: STRATEGY_CATEGORIES.ROI,
              }
            )}
          />
          <DetailsPage
            topBar={
              <div className='flex items-center pl-4 justify-between'>
                <div className='flex space-x-2'>
                  <Typography variant='body2' weight='medium'>
                    {intl.get(
                      'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE_PAGE_SUBTITLE',
                      {
                        category: STRATEGY_CATEGORIES.ROI,
                      }
                    )}
                  </Typography>
                  <ROIObjectiveTag />
                </div>
                <Button
                  variant='tertiary'
                  onClick={() => setIsDeleteModalOpen(true)}
                  iconSrc={removeIcon}
                  className='h-8'
                  data-testid='delete-objective-button'
                >
                  {intl.get(
                    'ROI_CATEGORY.OBJECTIVE_PAGE.DELETE_OBJECTIVE_BUTTON'
                  )}
                </Button>
              </div>
            }
            content={
              <>
                <Typography variant='h5' className='mb-6'>
                  {intl.get('ROI_CATEGORY.OBJECTIVE_PAGE.TITLE')}
                </Typography>
                <div className='flex'>
                  <Icon src={targetIcon} className='pr-4 w-10 h-10' />
                  <FormItem
                    className='w-full'
                    label={
                      <LargeLabel
                        label={intl.get(
                          'ROI_CATEGORY.OBJECTIVES_SETTING.ADD_OBJECTIVE_MODAL.OBJECTIVE_FIELD_LABEL'
                        )}
                        labelProps={{
                          className: 'text-base mb-0.5',
                        }}
                        subtitle={intl.get(
                          'ROI_CATEGORY.OBJECTIVE_PAGE.OBJECTIVE_FIELD_SUBTITLE'
                        )}
                        subtitleProps={{
                          className: 'text-neutral-darker mb-2',
                        }}
                        name='roi-objective'
                      />
                    }
                  >
                    <div className='flex'>
                      <NumericInput
                        containerClassName='w-56'
                        placeholder={intl.get(
                          'ROI_CATEGORY.OBJECTIVES_SETTING.ADD_OBJECTIVE_MODAL.OBJECTIVE_FIELD_PLACEHOLDER'
                        )}
                        id='roi-objective'
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (event.target.value !== '') {
                            setROI(Number(event.target.value));
                          } else {
                            setROI(null);
                          }
                        }}
                        allowFloatValue={false}
                        allowNegativeValue={false}
                        getIsInputValid={(isValid) =>
                          setIsROINumberValid(isValid)
                        }
                        value={roi ?? ''}
                        data-testid='roi-objective-input'
                      />
                      <Typography className='ml-2 mt-2'>
                        {intl.get('PERCENT_SIGN')}
                      </Typography>
                    </div>
                  </FormItem>
                </div>
              </>
            }
          />
          <PageFooter>
            <Button
              variant='tertiary'
              onClick={onCancel}
              data-testid='roi-objective-cancel_button'
            >
              {intl.get('CANCEL')}
            </Button>
            <Button
              onClick={onSave}
              disabled={!canSave}
              data-testid='roi-objective-save_button'
            >
              {intl.get('SAVE')}
            </Button>
          </PageFooter>
        </>
      )}
    </div>
  );
};

export default ROIObjectivePage;
