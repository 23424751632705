import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { RootState } from 'state/store';

interface sidekickData {
  isOpen: boolean;
}

/* ============================= INITIAL STATE ============================== */
const initialState: sidekickData = {
  isOpen: false,
};

/* ============================== REDUX THUNK =============================== */
export const openSidekick = createAsyncThunk('sidekick/OPEN_SIDEKICK', () => {
  return {};
});

/* ================================= ACTIONS ================================ */
export const closeSidekick = createAction('sidekick/CLOSE_SIDEKICK');

/* ================================= REDUCER ================================ */
const sidekickSlice = createSlice({
  name: 'sidekick',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(openSidekick.fulfilled, (state, action) => {
        state.isOpen = true;
      })
      .addCase(closeSidekick, (state, action) => {
        state.isOpen = false;
      });
  },
});

/* =============================== SELECTORS ================================ */
export const selectSidekickData = (state: RootState) => {
  return {
    isOpen: state.sidekick.isOpen,
  };
};

export default sidekickSlice.reducer;
