import { PROGRAM_PROJECT_FIELDS } from 'utils/constants';

export const PROGRAM_PROJECT_TABLE_FILTERS = {
  STATUS: PROGRAM_PROJECT_FIELDS.STATUS,
  PRIORITY: PROGRAM_PROJECT_FIELDS.PRIORITY,
  PROGRESS: PROGRAM_PROJECT_FIELDS.PROGRESS,
  START_DATE: PROGRAM_PROJECT_FIELDS.START_DATE,
  TARGET_COMPLETION_DATE: PROGRAM_PROJECT_FIELDS.TARGET_COMPLETION_DATE,
  ACTUAL_COMPLETION_DATE: PROGRAM_PROJECT_FIELDS.ACTUAL_COMPLETION_DATE,
};
