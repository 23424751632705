import {
  createAsyncThunk,
  createSlice,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { selectUser } from 'state/User/userSlice';
import { SLICE_STATUS } from 'utils/constants';
import { Status, FormOption, SortingType } from 'utils/customTypes';
import learningTeamsAPI from './learningTeamsAPI';
import { get, isEmpty, orderBy } from 'lodash';
import type {
  LearningTeam,
  LearningTeamForm,
  LearningTeamWithMembers,
} from 'utils/types/learningTeam';

interface LearningTeams {
  searchParam: string;
  value: LearningTeamWithMembers[];
  status: Status;
  fetched: boolean;
  currentLearningTeam: LearningTeamWithMembers | null;
  learningTeamsSorting: {
    orderBy: string[];
    order: SortingType;
  };
}

/* ============================= INITIAL STATE ============================== */
const initialState: LearningTeams = {
  searchParam: '',
  value: [],
  currentLearningTeam: null,
  status: SLICE_STATUS.IDLE,
  fetched: false,
  learningTeamsSorting: {
    order: 'asc',
    orderBy: [''],
  },
};

/* ============================== REDUX THUNK =============================== */
export const getLearningTeams = createAsyncThunk(
  'learningTeams/GET_LEARNING_TEAMS',
  async () => {
    const { data } = await learningTeamsAPI.fetchLearningTeams();
    if (!data.learningTeams) {
      throw new Error('An error ocurred');
    }

    return data.learningTeams;
  }
);

export const createLearningTeam = createAsyncThunk(
  'learningTeams/CREATE_LD_TEAM',
  async (ldTeam: LearningTeamForm) => {
    const { data } = await learningTeamsAPI.createLearningTeam(ldTeam);
    return data;
  }
);

export const getCurrentLearningTeam = createAsyncThunk(
  'learningTeams/GET_CURRENT_LD_TEAM',
  async (id: string) => {
    const { data } = await learningTeamsAPI.getLearningTeam(id);
    if (!data.learningTeam) {
      throw new Error('An error ocurred');
    }

    return data.learningTeam;
  }
);

export const updateLearningTeam = createAsyncThunk(
  'learningTeams/UPDATE_TEAM',
  async ({
    teamId,
    updateFields,
  }: {
    teamId: string;
    updateFields: Partial<LearningTeam>;
  }) => {
    const { data } = await learningTeamsAPI.updateTeam(teamId, updateFields);
    if (!data.learningTeam) {
      throw new Error('An error ocurred');
    }

    return data.learningTeam;
  }
);

export const deleteLearningTeam = createAsyncThunk(
  'learningTeams/DELETE_TEAM',
  async (teamId: string) => {
    const { code } = await learningTeamsAPI.deleteTeam(teamId);
    if (code !== 200) {
      throw new Error('An error ocurred');
    }

    return { teamId };
  }
);
/* ================================ ACTIONS ================================= */

export const resetCurrentLearningTeam = createAction(
  'learningTeams/RESET_CURRENT_TEAM'
);

export const setLearningTeamsOrder = createAction<{
  order: SortingType;
  orderBy: string[];
}>('learningTeams/SET_LEARNING_TEAMS_ORDER');

export const setSearchParam = createAction<{ searchParam: string }>(
  'learningTeams/SET_SEARCH_PARAM'
);

/* ================================= REDUCER ================================ */
const slice = createSlice({
  name: 'learningTeams',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLearningTeams.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getLearningTeams.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(getLearningTeams.fulfilled, (state, action) => {
        state.value = action.payload;
        state.fetched = true;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(createLearningTeam.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(createLearningTeam.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(createLearningTeam.fulfilled, (state, action) => {
        const newTeam = get(action, 'payload.learningTeam');
        if (!isEmpty(newTeam)) {
          state.value = [...state.value, newTeam];
        }
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(getCurrentLearningTeam.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getCurrentLearningTeam.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(getCurrentLearningTeam.fulfilled, (state, action) => {
        state.currentLearningTeam = action.payload;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(updateLearningTeam.fulfilled, (state, action) => {
        state.currentLearningTeam = action.payload;
      })
      .addCase(resetCurrentLearningTeam, (state) => {
        state.currentLearningTeam = initialState.currentLearningTeam;
      })
      .addCase(deleteLearningTeam.fulfilled, (state, action) => {
        state.value = state.value.filter(
          (team) => team.id !== action.payload.teamId
        );
      })
      .addCase(setLearningTeamsOrder, (state, action) => {
        state.learningTeamsSorting.order = action.payload.order;
        state.learningTeamsSorting.orderBy = action.payload.orderBy;
      })
      .addCase(setSearchParam, (state, action) => {
        state.searchParam = action.payload.searchParam;
      });
  },
});

/* =============================== SELECTORS ================================ */
export const selectLearningTeams = (state: RootState) =>
  state.learningTeams.value;

export const selectLdTeamAndSubTeams = createSelector(
  [selectLearningTeams, selectUser],
  (allTeams, user): LearningTeamWithMembers[] => {
    const userTeamIdsSet = new Set(
      (user.teamsManaged || []).map((team) => team.id)
    );

    return allTeams.filter(
      (team) =>
        userTeamIdsSet.has(team.id) || userTeamIdsSet.has(team.parent_id || '')
    );
  }
);

export const selectLdTeamMemberIds = createSelector(
  [selectLdTeamAndSubTeams],
  (userManagedTeams): string[] => {
    const teamMembersSet: Set<string> = new Set();

    userManagedTeams.forEach((team) => {
      team.ldTeamMembers.forEach((member) => {
        teamMembersSet.add(member.id);
      });
    });

    return Array.from(teamMembersSet);
  }
);

export const selectLearningTeamsForDropdown = createSelector(
  [selectLearningTeams],
  (learningTeams) => {
    return learningTeams.map((team) => ({
      label: team.name,
      value: team.id,
    })) as FormOption[];
  }
);

export const selectLearningTeamStatus = (state: RootState) =>
  state.learningTeams.status;

export const selectLearningTeamIsFetched = (state: RootState) =>
  state.learningTeams.fetched;

export const selectCurrentLearningTeam = (state: RootState) =>
  state.learningTeams.currentLearningTeam;

export const learningTeamsSorting = (state: RootState) =>
  state.learningTeams.learningTeamsSorting;

const learningTeamsSearchParam = (state: RootState) =>
  state.learningTeams.searchParam;

export const selectOrderedLearningTeams = createSelector(
  [selectLearningTeams, learningTeamsSorting, learningTeamsSearchParam],
  (learningTeams, sorting, searchParam: string) => {
    let filteredLearningTeams = learningTeams;
    if (searchParam) {
      filteredLearningTeams = learningTeams.filter(
        (learningTeam: LearningTeamWithMembers) => {
          const clearedLearningTeamName = learningTeam.name
            .trim()
            .toLowerCase();
          const clearedSearchParam = searchParam.trim().toLowerCase();
          return clearedLearningTeamName.includes(clearedSearchParam);
        }
      );
    }
    return orderBy(filteredLearningTeams, sorting.orderBy, [
      sorting.order,
      sorting.order,
    ]);
  }
);
/* ================================= EXPORT ================================= */
export default slice.reducer;
