import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { SVGRenderer } from 'echarts/renderers';
import { BarChart } from 'echarts/charts';
import { PolarComponent, TooltipComponent } from 'echarts/components';
import { DEMAND_BY_CATEGORY_COLOR_SKELTON, GRID_COLOR } from './constants';

const ChartSkeletonLoader = () => {
  echarts.use([BarChart, PolarComponent, SVGRenderer, TooltipComponent]);
  const chartOptions = {
    polar: {
      radius: [50, '70%'],
    },
    colorBy: 'data',
    color: DEMAND_BY_CATEGORY_COLOR_SKELTON,
    series: {
      type: 'bar',
      backgroundStyle: {
        opacity: 0,
      },
      barCategoryGap: 0,
      data: [360, 330, 320, 310, 280, 260, 250, 220, 200, 180],
      coordinateSystem: 'polar',
    },
    radiusAxis: {
      axisLine: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: GRID_COLOR,
        },
      },
      z: 10,
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
    },
    angleAxis: {
      type: 'category',
      data: new Array(11).fill(''),
      startAngle: 90,
      z: 10,
      splitLine: {
        show: true,
        lineStyle: {
          color: GRID_COLOR,
        },
      },
      axisLine: {
        lineStyle: {
          color: GRID_COLOR,
        },
      },
    },
  };

  return (
    <>
      <div className='bg-neutral-lighter animate-pulse w-40 h-4 mb-2'></div>
      <div className='bg-neutral-lighter animate-pulse w-40 h-4 mb-8'></div>
      <ReactEChartsCore
        echarts={echarts}
        option={chartOptions}
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    </>
  );
};
export default ChartSkeletonLoader;
