import { Modal, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

const CancelCreateRequestModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
}) => (
  <Modal
    actionButtons={[
      {
        children: intl.get(
          'REQUESTS_LIST_PAGE.MODAL.CANCEL_CREATE_NEW_REQUEST.SUBMIT_BUTTON'
        ),
        variant: 'primary',
        color: 'danger',
        onClick: onConfirm,
        'data-cy': 'cancel-create-request-submit-button',
      },
      {
        children: intl.get(
          'REQUESTS_LIST_PAGE.MODAL.DELETE_REQUEST.CANCEL_BUTTON'
        ),
        variant: 'secondary',
        onClick: () => setIsOpen(false),
        'data-cy': 'cancel-create-request-cancel-button',
      },
    ]}
    closeModal={() => setIsOpen(false)}
    isOpen={isOpen}
    title={intl.get('REQUESTS_LIST_PAGE.MODAL.CANCEL_CREATE_NEW_REQUEST.TITLE')}
    titleIcon={{
      name: 'remove-circle',
      className: 'text-error-dark',
    }}
    size='medium'
    aria-label={intl.get(
      'REQUESTS_LIST_PAGE.MODAL.CANCEL_CREATE_NEW_REQUEST.TITLE'
    )}
    data-testid='cancel-create-request-modal'
  >
    <Typography
      className='mb-5'
      data-testid='cancel-create-request-call-action'
    >
      {intl.get(
        'REQUESTS_LIST_PAGE.MODAL.CANCEL_CREATE_NEW_REQUEST.CALL_TO_ACTION'
      )}
    </Typography>
  </Modal>
);

export default CancelCreateRequestModal;
