import { dtApi as api } from './api';
import axios from 'axios';

import { NewTask, NewTaskDetail } from 'types/store/newTask';

export interface CreateNewTaskResponse {
  successes: NewTaskDetail[];
  failures: {
    task: NewTaskDetail;
    error: string;
  }[];
}

export const createNewTask = async (newTask: NewTask) => {
  const url = `/v2/tasks`;
  const response = await api.post<CreateNewTaskResponse>(url, {
    newTasks: [newTask],
  });
  if (axios.isAxiosError(response)) {
    throw response;
  }
  if (response.data.failures.length > 0) {
    throw new Error(response.data.failures[0].error);
  }
  return response.data;
};
