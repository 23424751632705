export const TIME_OFF_PAGE_TABS = {
  UPCOMING_TIME_OFF: 'upcoming-time-off',
  HISTORY: 'history',
};

export const TIME_OFF_TABLES = {
  UPCOMING: 'upcoming-time-off-table',
  HISTORY: 'past-time-off-table',
};

export const TIME_OFFS_TABLE_HEADERS = {
  DATES: 'dateRange',
  TIME_OFF_TYPE: 'typeId',
  HOURS: 'hours',
} as const;
