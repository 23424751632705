import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { PROJECT_PRIVACY, PROJECT_STATUS, USER_ROLES } from './constants';
import { AllUsersType, CurrentUser, Project, Task } from './customTypes';
import type {
  LearningTeam,
  LearningTeamWithMembers,
} from './types/learningTeam';

export const canUpdateTask: (
  task: Task,
  project: Project,
  user: AllUsersType,
  ldTeams: LearningTeam[],
  tasksAssignmentsBasedResourcesCapacity?: boolean
) => boolean = (
  task,
  project,
  user,
  ldTeams,
  tasksAssignmentsBasedResourcesCapacity
) => {
  const isAdmin: boolean = user.role === USER_ROLES.ADMIN;
  if (isAdmin) return true;

  const isOwner: boolean = Boolean(
    project.owners.find((owner) => owner.project_owners.userId === user.id)
  );
  if (isOwner) return true;

  const isMember: boolean = Boolean(
    project.participants.find((participant) =>
      tasksAssignmentsBasedResourcesCapacity
        ? get(participant, 'v2_project_participant.user_id') === user.id
        : get(participant, 'project_participants.userId') === user.id
    )
  );
  const isCollaborator: boolean = Boolean(
    project.collaborators.find(
      (collaborator) => collaborator.project_collaborators.userId === user.id
    )
  );
  const isAssignee: boolean = Boolean(
    task.assignedUsers?.find((assignedUser) => assignedUser.id === user.id)
  );
  const isCreator: boolean = Boolean(task.created_by === user.id);
  if ((isMember || isCollaborator) && (isAssignee || isCreator)) return true;

  const isTeamPrivate: boolean = project.privacy === PROJECT_PRIVACY.TEAM;
  if (isTeamPrivate) {
    const allowedProjectTeams = get(project, 'ldteams', []).map(
      (team: LearningTeam) => team.id
    );
    const teamsManaged = get(user, 'teamsManaged', []) as LearningTeam[];

    const getSubTeamIds = (ldTeamId?: string) => {
      if (!ldTeamId) {
        return [];
      }
      return ldTeams
        .filter((team: LearningTeam) => {
          return team.parent_id === ldTeamId;
        })
        .map((team: LearningTeam) => team.id);
    };

    const checkSubTeamsAccess = (baseTeamIds: string[]) => {
      const exploredLearningTeamIds = new Set();
      while (!isEmpty(baseTeamIds)) {
        const learningTeamId = baseTeamIds.shift();
        exploredLearningTeamIds.add(learningTeamId);
        if (learningTeamId && allowedProjectTeams.includes(learningTeamId)) {
          return true;
        }
        const subTeamIds = getSubTeamIds(learningTeamId);
        for (const childTeam of subTeamIds) {
          if (!exploredLearningTeamIds.has(childTeam)) {
            baseTeamIds.push(childTeam);
          }
        }
      }
      return false;
    };

    const userManagedTeamIds = teamsManaged.map(
      (team: LearningTeam) => team.id
    );
    const userManagesAllowedTeam = checkSubTeamsAccess(userManagedTeamIds);

    if (userManagesAllowedTeam) return true;
  }

  return false;
};

export const canAddTaskToProject = (
  project: Project,
  user: CurrentUser,
  userManagedTeams: LearningTeamWithMembers[],
  teamMemberIds: string[],
  tasksAssignmentsBasedResourcesCapacity?: boolean
): boolean => {
  if (
    project.status === PROJECT_STATUS.CLOSED ||
    project.status === PROJECT_STATUS.CANCELED ||
    project.is_archived
  ) {
    return false;
  }

  const userId = user.id;
  const isAdmin: boolean = user.role === USER_ROLES.ADMIN;
  if (isAdmin) {
    return true;
  }

  const isOwner: boolean = project.owners.some(
    (owner) => owner.project_owners.userId === userId
  );
  if (isOwner) {
    return true;
  }

  const isMember: boolean = project.participants.some((participant) =>
    tasksAssignmentsBasedResourcesCapacity
      ? get(participant, 'v2_project_participant.user_id') === userId
      : get(participant, 'project_participants.userId') === userId
  );
  if (isMember) {
    return true;
  }

  if (project.privacy === PROJECT_PRIVACY.TEAM) {
    const projectTeams = project.ldteams || [];
    const set = new Set(userManagedTeams.map((team) => team.id));
    const hasManagedTeams = projectTeams
      .map((team) => team.id)
      .some((teamId) => set.has(teamId));

    return hasManagedTeams;
  } else if (project.privacy === PROJECT_PRIVACY.PUBLIC) {
    return project.owners.some((owner) =>
      teamMemberIds.includes(owner.project_owners.userId || '')
    );
  }

  return false;
};
