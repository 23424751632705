import { useMemo } from 'react';
import { selectUser } from 'state/User/userSlice';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { bulkImportProjects } from 'state/Projects/projectsSlice';
import {
  selectBussinessTeams,
  selectProjectCategories,
} from 'state/Organization/organizationSlice';
import {
  PROJECT_PRIORITY,
  PROJECT_PRIVACY,
  PROJECT_STATUS,
  PROJECT_HEALTH,
} from 'utils/constants';
import { selectActiveLDUsers } from 'state/UsersManagement/usersManagementSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { cleanImportedProjects } from '../helpers/import';
import { selectEnabledProjectProcesses } from 'state/Processes/processesSlice';
import { selectLearningTeams } from 'state/LearningTeams/learningTeamsSlice';
import { ProjectCategory, Option } from 'utils/customTypes';
import { FlatfileResults } from '@flatfile/react';
import { flatfileSettings } from '../helpers/flatfileHelper';
import { ProjectTemplate } from 'utils/types/templates';
import type { BusinessTeam } from 'utils/types/businessTeams';
import type { LearningTeam } from 'utils/types/learningTeam';

const useImportProjects = (
  template?: ProjectTemplate,
  onImport?: () => void
) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  const projectCategories = useSelector(selectProjectCategories);
  const projectProcesses = useSelector(selectEnabledProjectProcesses);
  const businessTeams = useSelector(selectBussinessTeams);
  const ldUsers = useSelector(selectActiveLDUsers);
  const learningTeams = useSelector(selectLearningTeams);

  const statusOptions = Object.keys(PROJECT_STATUS).map((key) => ({
    label: intl.get(`PROJECT_DETAIL.STATUS_OPTIONS.${key}`),
    value: PROJECT_STATUS[key],
  }));

  const categoryOptions = useMemo(
    () =>
      orderBy(
        projectCategories,
        (category) => {
          const label = get(category, 'categoryName') || '';
          return label.toLocaleLowerCase();
        },
        'asc'
      ).map((category: ProjectCategory) => ({
        label: category.categoryName,
        value: category.id,
      })),
    [projectCategories]
  );

  const priorityOptions = Object.keys(PROJECT_PRIORITY).map((key) => ({
    label: intl.get(`PROJECT_DETAIL.PRIORITY_OPTIONS.${key}`),
    value: PROJECT_PRIORITY[key],
  }));

  const privacyOptions = Object.keys(PROJECT_PRIVACY).map((key) => ({
    label: intl.get(`PROJECT_DETAIL.PRIVACY_OPTIONS.${key}`),
    value: PROJECT_PRIVACY[key],
  }));

  const healthOptions = Object.keys(PROJECT_HEALTH).map((key) => ({
    label: intl.get(`PROJECT_DETAIL.HEALTH_OPTIONS.${key}`),
    value: PROJECT_HEALTH[key],
  }));

  const businessUnitOptions = useMemo(
    () =>
      businessTeams.map((businessUnit: BusinessTeam) => ({
        label: businessUnit.title,
        value: businessUnit.id,
      })),
    [businessTeams]
  );

  const processOptions = useMemo(() => {
    const options: Option[] = [];
    for (const process of projectProcesses) {
      if (process.enabled === null || process.enabled) {
        options.push({
          label: process.processName,
          value: process.id,
        });
      }
    }
    return options;
  }, [projectProcesses]);

  const ldTeamOptions = useMemo(
    () =>
      learningTeams.map((team: LearningTeam) => ({
        label: team.name,
        value: team.id,
      })),
    [learningTeams]
  );

  const ownerOptions = useMemo(
    () =>
      ldUsers.map((user) => ({
        label: user.data.firstName + ' ' + user.data.lastName,
        value: user.id,
      })),
    [ldUsers]
  );

  const stageOptions = useMemo(() => {
    const options: Option[] = [];
    for (const process of projectProcesses) {
      if (process.enabled === null || process.enabled) {
        process.projectStages.forEach((stage) => {
          options.push({
            label: process.processName + ' - ' + stage.stageName,
            value: stage.id,
          });
        });
      }
    }
    return options;
  }, [projectProcesses]);

  const templateFields = useMemo(
    () => template?.project_template_fields || [],
    [template?.project_template_fields]
  );

  const settings = useMemo(
    () =>
      flatfileSettings(
        ownerOptions,
        statusOptions,
        businessUnitOptions,
        categoryOptions,
        priorityOptions,
        privacyOptions,
        ldTeamOptions,
        processOptions,
        stageOptions,
        healthOptions,
        templateFields
      ),
    [
      businessUnitOptions,
      categoryOptions,
      healthOptions,
      ldTeamOptions,
      ownerOptions,
      priorityOptions,
      privacyOptions,
      processOptions,
      stageOptions,
      statusOptions,
      templateFields,
    ]
  );

  const cleanUploadedDataAndCreate = async (result: FlatfileResults) => {
    const cleanProjectsData = await cleanImportedProjects(
      result,
      businessTeams,
      projectCategories,
      currentUser,
      projectProcesses,
      learningTeams,
      templateFields,
      template?.id
    );
    await dispatch(bulkImportProjects(cleanProjectsData));
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get('IMPORT_PROJECTS.IMPORT_SUCCESS'),
        timeout: 7000,
      })
    );
    if (onImport) {
      onImport();
    }
  };

  return {
    onData: cleanUploadedDataAndCreate,
    currentUser,
    settings,
  };
};

export default useImportProjects;
