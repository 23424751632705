import { Table, tailwindOverride } from '@getsynapse/design-system';
import Skelton from 'Atoms/Skelton/Skelton';

type loaderColumnConfig = {
  className: string;
  type?: string;
};

const defaultItems = [
  {
    className: 'w-1/4',
  },
  {
    className: 'w-1/4',
  },
  {
    className: 'w-1/4',
    type: 'avatar',
  },
  {
    className: 'w-1/4',
  },
] as loaderColumnConfig[];

const TableSkeletonLoader = ({
  bodyClassName,
  columnSettings = defaultItems,
}: {
  bodyClassName?: string;
  columnSettings?: loaderColumnConfig[];
}) => {
  const formatHeaderCells = (items: loaderColumnConfig[]) => {
    return items.map((item: loaderColumnConfig) => {
      return {
        content: <Skelton className='w-28 h-4 ml-2' />,
        className: item.className,
      };
    });
  };

  const formatRowCells = (columnsCount: number) => {
    return Array.from(Array(15).keys()).map((index: number) => {
      return {
        id: index,
        onClick: () => {},
        className: '',
        cells: Array.from(Array(columnsCount).keys()).map((index: number) => {
          return {
            content: getCellSkeleton(columnSettings[index].type),
            className: columnSettings[index].className,
          };
        }),
      };
    });
  };

  const getCellSkeleton = (type?: string) => {
    if (type === 'avatar') {
      return (
        <div className='flex items-center justify-start'>
          <Skelton className='w-6 h-6 rounded-full' />
          <Skelton className='w-5/6 h-4 ml-4' />
        </div>
      );
    }
    return <Skelton className='w-5/6 h-4' />;
  };

  return (
    <div
      className={tailwindOverride(
        'overflow-hidden w-full max-h-no-filtered-table-body',
        bodyClassName
      )}
    >
      <Table
        className='w-full mb-5 relative'
        canSelectRows={false}
        data={{
          headData: {
            stickyHeader: true,

            headCells: formatHeaderCells(columnSettings),
          },
          rows: formatRowCells(columnSettings.length),
        }}
      />
    </div>
  );
};

export default TableSkeletonLoader;
