import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { FlatfileResults } from '@flatfile/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectUser, selectOrganizationId } from 'state/User/userSlice';
import { getAvailableUsersForTaskAssignees } from 'state/SingleTask/singleTaskSlice';
import { fetchProject, getCurrentProjectId } from 'state/Project/projectSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { importTasks } from 'state/ProjectTasks/projectTaskSlice';
import { getImportSettings, cleanUploadedData } from './helpers';
import { getCurrentProjectData } from 'state/Project/projectSlice';
import { selectAllUsersForDropdown } from 'state/UsersManagement/usersManagementSlice';
import useGetProjectReadOnly from 'Pages/ProjectPage/hooks/useGetProjectReadOnly';

const useImportTasks = () => {
  const { tasksAssignmentsBasedResourcesCapacity = false } = useFlags();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  const possibleAssignees = useSelector(
    tasksAssignmentsBasedResourcesCapacity
      ? selectAllUsersForDropdown
      : getAvailableUsersForTaskAssignees
  );
  const organizationId = useSelector(selectOrganizationId);
  const projectId = useSelector(getCurrentProjectId);

  const settings = useMemo(
    () => getImportSettings(possibleAssignees),
    [possibleAssignees]
  );

  const handleUploadedData = async (result: FlatfileResults) => {
    const cleanData = cleanUploadedData(organizationId!, projectId, result);
    await dispatch(importTasks({ newTasks: cleanData }));
    dispatch(fetchProject(projectId));
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get('TASKS.IMPORT_SUCCESS'),
        timeout: 7000,
      })
    );
  };

  const projectData = useSelector(getCurrentProjectData);

  const isReadOnly = useGetProjectReadOnly(
    projectData.status,
    projectData.is_archived
  );

  return {
    currentUser,
    settings,
    onData: handleUploadedData,
    disabled: isReadOnly,
  };
};

export default useImportTasks;
