import intl from 'react-intl-universal';
import {
  FormItem,
  Typography,
  TextField,
  Dropdown,
  Modal,
  Checkbox,
} from '@getsynapse/design-system';
import { useHistory } from 'react-router-dom';
import {
  LICENSE_TIER,
  PATHS,
  USER_TYPES,
  USER_STATUS,
  USER_ROLES,
} from 'utils/constants';
import { useState, useMemo, Fragment, useEffect } from 'react';
import { Option, License, SystemUserTypes } from 'utils/customTypes';
import LicenseBanner from './LicenseBanner';
import { useSelector, useDispatch } from 'react-redux';
import { selectOrganizationLicense } from 'state/Organization/organizationSlice';
import {
  selectAvailableLicenses,
  addUser as addNewUser,
  inviteUser,
  getAllUsers,
  allUsers,
} from 'state/UsersManagement/usersManagementSlice';
import {
  selectBussinessTeams,
  getBusinessTeams,
} from 'state/BusinessTeams/businessTeamsSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { selectOrganizationId } from 'state/User/userSlice';
import get from 'lodash/get';
import {
  displayNotification,
  setNotificationText,
  setNotificationVariant,
  setNotificationTimeout,
} from 'state/InlineNotification/inlineNotificationSlice';
import { getUserTypeOptionsForDropdown } from './AddUserModalHelper';

const AddUserModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [userType, setUserType] = useState<SystemUserTypes | undefined>();
  const [selfRegistration, setSelfRegistration] = useState(false);
  const [businessUnit, setBusinessUnit] = useState('');
  const organizationId = useSelector(selectOrganizationId);
  const businessTeams = useSelector(selectBussinessTeams);
  const licenseData: License = useSelector(selectOrganizationLicense);
  const availableLicenses = useSelector(selectAvailableLicenses);

  const { facilitatorManagement = false } = useFlags();
  const userTypeOptions = getUserTypeOptionsForDropdown(facilitatorManagement);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBusinessTeams());
  }, [dispatch]);

  const orgUsers = useSelector(allUsers);

  const isEmailRepeated = useMemo<boolean>(
    () => orgUsers.some((orgUser) => orgUser.data.email === email),
    [orgUsers, email]
  );

  const businessTeamsOptions = useMemo(() => {
    return businessTeams.map((team) => ({
      value: team.id,
      label: team.title,
    }));
  }, [businessTeams]);

  const addUser = async () => {
    if (selfRegistration) {
      const userData = {
        type: userType,
        businessTeam_id: businessUnit,
        data: {
          email: email,
          firstName: '',
          lastName: '',
        },
        status: USER_STATUS.INVITED,
        role: USER_ROLES.USER,
        organization_id: organizationId,
      };
      const user = await dispatch(addNewUser(userData));
      await dispatch(
        inviteUser({
          userId: get(user, 'payload.id'),
          email: email,
          firstName: '',
          selfRegistration: true,
        })
      );
      closeModal();
      dispatch(setNotificationTimeout(4000));
      dispatch(setNotificationVariant('success'));
      dispatch(
        setNotificationText(
          intl.get('ADD_USER.ADD_USER_MODAL.SELF_REGISTRATION_BANNER')
        )
      );
      dispatch(displayNotification());
    } else {
      history.push(`${PATHS.USER_PAGE}/${email}/${userType}`);
    }
  };
  const closeModal = () => {
    setIsOpen(false);
    setUserType(undefined);
    setSelfRegistration(false);
    setBusinessUnit('');
  };

  return (
    <Modal
      title={intl.get('ADD_USER.ADD_USER_MODAL.TITLE', {
        op: 1,
        userType: 'User',
      })}
      closeModal={closeModal}
      isOpen={isOpen}
      aria-label={intl.get('ADD_USER.ADD_USER_MODAL.TITLE', {
        op: 1,
        userType: 'User',
      })}
      size='large'
      actionButtons={[
        {
          children: selfRegistration
            ? intl.get('ADD_USER.ADD_USER_MODAL.SEND_LINK')
            : intl.get('ADD_USER.ADD_USER_MODAL.CONTINUE_BUTTON'),
          variant: 'primary',
          onClick: addUser,
          disabled:
            !email ||
            !userType ||
            (selfRegistration && !businessUnit) ||
            isEmailRepeated,
          'data-cy': 'continue-add-user_button',
        },
        {
          children: intl.get('CANCEL'),
          variant: 'tertiary',
          onClick: closeModal,
        },
      ]}
    >
      <Fragment>
        <Typography variant='body' className='mb-4 text-neutral-black'>
          {intl.get('ADD_USER.ADD_USER_MODAL.BODY')}
        </Typography>
        <div className='grid grid-cols-2 gap-x-10'>
          <FormItem
            label={intl.get('ADD_USER.ADD_USER_MODAL.EMAIL_LABEL')}
            labelProps={{
              required: true,
            }}
          >
            <TextField
              variant='text'
              length='medium'
              placeholder={intl.get(
                'ADD_USER.ADD_USER_MODAL.EMAIL_PLACEHOLDER'
              )}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              data-cy='user-email_input'
            />
          </FormItem>
          <FormItem
            label={intl.get('ADD_USER.ADD_USER_MODAL.USER_TYPE_LABEL')}
            labelProps={{
              required: true,
            }}
            helpText={
              userType &&
              intl.get(
                `ADD_USER.ADD_USER_MODAL.USER_TYPE_HELP_TEXT.${userType.toUpperCase()}`
              )
            }
            helpTextProps={{
              className: 'text-neutral-black',
            }}
          >
            <Dropdown
              options={userTypeOptions}
              triggerProps={{
                className: 'h-6',
                placeholder: intl.get(
                  'ADD_USER.ADD_USER_MODAL.USER_TYPE_PLACEHOLDER'
                ),
                'data-testid': 'user_type_dropdown',
              }}
              listProps={{
                'data-testid': 'user_type_dropdown_list',
              }}
              onChange={(option: Option) => {
                setUserType(option.value);
                setSelfRegistration(false);
              }}
            />
          </FormItem>
          {userType === USER_TYPES.BUSINESS && (
            <Checkbox
              className='mt-3 col-span-2'
              label={intl.get('ADD_USER.ADD_USER_MODAL.SELF_REGISTRATION')}
              onChange={(e) => setSelfRegistration(e.target.checked)}
              inputProps={{ 'data-testid': 'self_registration_check' }}
            />
          )}
          {selfRegistration && (
            <FormItem
              label={intl.get('ADD_USER.ADD_USER_MODAL.BUSINESS_UNIT')}
              labelProps={{
                required: true,
              }}
              className='mt-4'
            >
              <Dropdown
                options={businessTeamsOptions}
                triggerProps={{
                  placeholder: intl.get(
                    'ADD_USER.ADD_USER_MODAL.BUSINESS_UNIT_PLACEHOLDER'
                  ),
                }}
                listProps={{
                  'data-testid': 'businessTeams_dropdown_list',
                }}
                onChange={(option: Option) => {
                  setBusinessUnit(option.value);
                }}
              />
            </FormItem>
          )}
        </div>
      </Fragment>
      {userType === USER_TYPES.L_D &&
        licenseData.license_tier !== LICENSE_TIER.TRIAL &&
        availableLicenses < 1 && <LicenseBanner />}
    </Modal>
  );
};

export default AddUserModal;
