import React, { useMemo } from 'react';
import intl from 'react-intl-universal';
import { tailwindOverride, Typography } from '@getsynapse/design-system';
import { ProjectLevelAllocation } from 'utils/types/tasksAssignmentsResourcesCapacity';

type CapacityStatus = 'default' | 'green' | 'yellow' | 'orange' | 'red';
type CapacityStatusStyle = {
  text: string;
  background: string;
  boder: string;
  shadow: string;
};

const formatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
});

const AllocatedHoursAndAvailability: React.FC<{
  allocation: ProjectLevelAllocation;
}> = ({ allocation }) => {
  const capacityStatusStyleMap = useMemo<
    Record<CapacityStatus, CapacityStatusStyle>
  >(() => {
    return {
      default: {
        text: 'text-primary-darker',
        background: 'bg-primary-lighter-two',
        boder: 'border-primary-lighter',
        shadow: 'rgb(var(--color-neutral-default))',
      },
      green: {
        text: 'text-success-darkest',
        background: 'bg-success-lightest',
        boder: 'border-success-lighter',
        shadow: 'rgb(var(--color-success-dark))',
      },
      yellow: {
        text: 'text-warning-darker',
        background: 'bg-warning-lightest-two',
        boder: 'border-warning-lighter',
        shadow: 'rgb(var(--color-warning-darker))',
      },
      orange: {
        text: 'text-fire-darker',
        background: 'bg-fire-lightest',
        boder: 'border-fire-light',
        shadow: 'rgb(var(--color-fire-dark))',
      },
      red: {
        text: 'text-error-darker',
        background: 'bg-error-lightest-two',
        boder: 'border-error-lightest',
        shadow: 'rgb(var(--color-error-dark))',
      },
    };
  }, []);

  const style = useMemo<CapacityStatusStyle>(() => {
    const percentage = allocation.capacityPercentage;
    let status: CapacityStatus = 'default';
    if (percentage > 0 && percentage < 60) {
      status = 'green';
    } else if (percentage >= 60 && percentage < 80) {
      status = 'yellow';
    } else if (percentage >= 80 && percentage < 100) {
      status = 'orange';
    } else if (percentage >= 100) {
      status = 'red';
    }
    return capacityStatusStyleMap[status];
  }, [allocation, capacityStatusStyleMap]);

  return (
    <div
      className={tailwindOverride(
        'w-full h-6 px-1',
        'flex items-center justify-center gap-x-1',
        'rounded-sm border',
        style.background,
        style.boder
      )}
      style={{
        boxShadow: `0px 2px 2px 0px #12151B0D, 2px 0px 0px 0px ${style.shadow} inset`,
      }}
    >
      <Typography variant='label' weight='medium' className={style.text}>
        {intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION_TIME', {
          allocation: formatter.format(allocation.allocation),
        })}
      </Typography>
      <Typography variant='caption' className={style.text}>
        {intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION_AVAILABILITY', {
          availability: `${formatter.format(allocation.availability)}`,
        })}
      </Typography>
    </div>
  );
};

export default AllocatedHoursAndAvailability;
