import React from 'react';
import { useInView } from 'react-intersection-observer';
import debounce from 'lodash/debounce';
import { tailwindOverride } from '@getsynapse/design-system';
import { VIEW_SETTING_VALUES } from 'utils/constants/capacityView';
import useScrollHandlerContext from 'Pages/TeamsPage/views/TeamsCapacity/components/CapacityTable/components/ScrollHandler/useScrollHandlerContext';
import NoDailyCapacity from 'Pages/TeamsPage/views/TeamsCapacity/components/DailyCapacity/components/DaysCarousel/components/NoDailyCapacity/NoDailyCapacity';
import NoWeeklyCapacity from 'Pages/TeamsPage/views/TeamsCapacity/components/BetaWeeklyCapacity/components/NoWeeklyCapacity/NoWeeklyCapacity';

const LastTeamRow: React.FC<{
  onLastTeamRowInView: (inView: boolean) => void;
}> = ({ onLastTeamRowInView }) => {
  const { rootId, capacityView } = useScrollHandlerContext();
  const debouncenOnChangeHandler = debounce(
    (inView: boolean) => onLastTeamRowInView(inView),
    500
  );
  const { ref: elementRef } = useInView({
    root: rootId,
    threshold: 0.5,
    rootMargin: '-80px 0px 0px',
    onChange: debouncenOnChangeHandler,
  });
  return (
    <tr>
      <td colSpan={2}>
        <div
          ref={elementRef}
          className={tailwindOverride(
            'w-full h-14 flex',
            'bg-neutral-white cursor-pointer',
            'team-row last-team-row'
          )}
        >
          <div className='w-2/6 h-full flex'>
            <div
              className={tailwindOverride(
                'w-10 h-full px-2',
                'flex items-center justify-end',
                'animate-pulse'
              )}
            >
              <div className='w-full h-4 rounded-sm bg-primary-lighter' />
            </div>
            <div
              className={tailwindOverride(
                'h-full flex flex-1 items-center justify-start',
                'px-4'
              )}
            >
              <div className='w-2/4 h-4 rounded-sm bg-primary-lighter' />
            </div>
          </div>
          <div className='w-4/6 h-full flex'>
            {capacityView === VIEW_SETTING_VALUES.DAILY && (
              <NoDailyCapacity id='last-team-row' isLoading />
            )}
            {(capacityView === VIEW_SETTING_VALUES.BETA_WEEKLY ||
              capacityView === VIEW_SETTING_VALUES.WEEKLY) && (
              <NoWeeklyCapacity id='last-team-row' isLoading />
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default LastTeamRow;
