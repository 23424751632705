import { Button } from '@getsynapse/design-system';
import PageFooter from 'Molecules/PageFooter/PageFooter';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router';
import { PATHS, SETTINGS_TABS, SETTINGS_SECTIONS } from 'utils/constants';

const EditTeamFooter = ({
  disableSave,
  isBusinessTeam,
  onUpdate,
}: {
  disableSave: boolean;
  isBusinessTeam: boolean;
  onUpdate: () => void;
}) => {
  const history = useHistory();

  const onCancel = () => {
    const redirectPath = isBusinessTeam
      ? `${PATHS.SETTINGS}/${SETTINGS_TABS.TEAMS}?section=${SETTINGS_SECTIONS.BUSINESS_TEAM}`
      : `${PATHS.SETTINGS}/${SETTINGS_TABS.TEAMS}?section=${SETTINGS_SECTIONS.LD_TEAM}`;
    history.push(redirectPath);
  };

  return (
    <PageFooter>
      <Button variant='tertiary' className='mr-4' onClick={onCancel}>
        {intl.get('CANCEL')}
      </Button>
      <Button
        disabled={disableSave}
        onClick={onUpdate}
        data-cy='update-team_button'
      >
        {intl.get('UPDATE')}
      </Button>
    </PageFooter>
  );
};

export default EditTeamFooter;
