import { api } from './api';
import axios from 'axios';

export interface TaskStatus {
  id: string;
  name: string;
  code: string;
}

export interface TaskStatuesResponse {
  taskStatuses: TaskStatus[];
}

export const fetchTaskStatusList = async () => {
  const url = `/v2/task-statuses`;
  const response = await api.get<TaskStatuesResponse>(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};
