import { useState, useMemo } from 'react';
import { tailwindOverride } from '@getsynapse/design-system';
import moment from 'moment';
import get from 'lodash/get';
import {
  SectionUserType,
  TeamMemberProject as ProjectType,
} from 'utils/customTypes';
import TeamMemberCell from './TeamMemberCell';
import TeamMemberAllocations from './TeamMemberAllocations';
import TeamMemberProject from './TeamMemberProject';
import TeamProjectAllocation from './TeamProjectAllocation';
import TeamTimeOffAllocations from './TeamTimeOffAllocations';
import { RoleAllocation } from 'Organisms/CapacityAllocationTable/components/AllocatedWeeksTable';
import { Week } from 'Organisms/CapacityAllocationTable/helpers';

type TeamMemberRowProps = {
  userData: SectionUserType;
  index: number;
  numberOfWeeks: number;
  currentWeeksSlideArray: (Week | null)[];
};

const TeamMemberRow = ({
  userData,
  index,
  numberOfWeeks,
  currentWeeksSlideArray,
}: TeamMemberRowProps) => {
  const isOdd = index % 2;
  const [showProjects, setShowProjects] = useState(false);

  const currentAllocations = useMemo(() => {
    return currentWeeksSlideArray.map((week) => {
      if (week !== null) {
        const allocationId = userData.allocations.findIndex((item) => {
          return moment(week.startWeekDate).isBetween(
            item.weekStart,
            item.weekEnd,
            undefined,
            '[]'
          );
        });
        if (allocationId !== -1) {
          return userData.allocations[allocationId];
        } else {
          return null;
        }
      } else return null;
    });
  }, [userData.allocations, currentWeeksSlideArray]);

  const timeOffAllocation = useMemo(() => {
    return currentAllocations.map((week) => {
      if (week !== null) {
        const allocationId = userData.timeOffs.findIndex((item) => {
          return moment(week.weekStart).isSame(item.weekStart);
        });
        if (allocationId !== -1) {
          return userData.timeOffs[allocationId];
        } else {
          return null;
        }
      } else return null;
    });
  }, [currentAllocations, userData.timeOffs]);

  return (
    <div
      className={tailwindOverride(
        'flex flex-row h-full border-b border-neutral-lighter w-full',
        {
          'bg-neutral-white': isOdd,
          'bg-neutral-lightest': !isOdd,
        }
      )}
    >
      <span className='w-4-percent' />
      <div className='flex flex-col w-full'>
        <div className='flex w-full'>
          <div className='w-37-percent shadow-allocation-table-inner'>
            <TeamMemberCell
              user={userData.user}
              projects={userData.projects}
              setShowProjects={setShowProjects}
              showProjects={showProjects}
            />
          </div>
          <div className='relative w-62-percent border-b border-neutral-lighter'>
            <div
              className={`absolute inset-0 mx-6 grid grid-cols-${numberOfWeeks} h-full`}
            >
              {currentAllocations.map((_, i: number) => {
                return (
                  <div
                    key={i}
                    className={tailwindOverride('z-0', {
                      'bg-neutral-lighter-two': get(
                        currentWeeksSlideArray,
                        `[${i}].isCurrentWeek`,
                        false
                      ),
                    })}
                  />
                );
              })}
            </div>
            <TeamMemberAllocations
              numberOfWeeks={numberOfWeeks}
              currentWeeksSlideArray={currentWeeksSlideArray}
              currentAllocations={currentAllocations}
              userId={userData.id}
            />
            <TeamTimeOffAllocations
              numberOfWeeks={numberOfWeeks}
              currentWeeksSlideArray={currentWeeksSlideArray}
              timeOffs={timeOffAllocation}
            />
          </div>
        </div>

        {showProjects &&
          userData.projects.map((project) => {
            return (
              <div className='flex flex-row' key={project.id}>
                <TeamMemberProject project={project} />
                <div className='w-62-percent relative'>
                  {project.currentParticipantRoles.map(
                    (
                      role: ProjectType['currentParticipantRoles'][0],
                      index
                    ) => {
                      return (
                        <div
                          className={tailwindOverride(
                            'w-full px-6 grid',
                            `grid-cols-${numberOfWeeks}`,
                            {
                              'h-full':
                                index ===
                                project.currentParticipantRoles.length - 1,
                            }
                          )}
                        >
                          {currentWeeksSlideArray.map((week, i) => {
                            let hours = 0;
                            return role.weeks?.some((roleWeek: any) => {
                              hours = roleWeek.hours;
                              return (
                                week?.startWeekDate &&
                                moment(week?.startWeekDate).isBetween(
                                  roleWeek.startDate,
                                  roleWeek.endDate,
                                  undefined,
                                  '[]'
                                )
                              );
                            }) ? (
                              <RoleAllocation
                                key={`${userData.id}-${i}-${role}`}
                                id={`${userData.id}-${i}-${role}`}
                                displayContent
                                content={
                                  <TeamProjectAllocation
                                    status={project.status}
                                    hours={hours}
                                  />
                                }
                                isCurrentWeek={get(
                                  currentWeeksSlideArray,
                                  `[${i}].isCurrentWeek`,
                                  false
                                )}
                                className='flex flex-1 py-2 z-10'
                              />
                            ) : (
                              <div
                                key={`${userData.id}-${i}-${project.roles.role}`}
                                className={tailwindOverride({
                                  'bg-neutral-lighter-two': get(
                                    currentWeeksSlideArray,
                                    `[${i}].isCurrentWeek`,
                                    false
                                  ),
                                })}
                              />
                            );
                          })}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TeamMemberRow;
