import {
  createAsyncThunk,
  createSelector,
  createSlice,
  createAction,
} from '@reduxjs/toolkit';
import intl from 'react-intl-universal';
import { RootState } from 'state/store';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import { ProjectTemplate } from 'utils/types/templates';
import { SortingType } from 'utils/customTypes';
import { TEMPLATE_SORTING } from 'utils/constants';
import ProjectTemplatesAPI, {
  fetchTemplatesParams,
} from './projectTemplatesAPI';
import ProjectTemplateAPI from 'state/ProjectTemplate/ProjectTemplateAPI';

interface ProjectTemplatesState {
  value: ProjectTemplate[];
  pagination: {
    limit: number;
    offset: number;
  };
  sorting: {
    orderBy: string[];
    order: SortingType;
  };
  searchParam: string;
}

/* ============================= INITIAL STATE ============================== */
const initialState: ProjectTemplatesState = {
  value: [],
  pagination: {
    limit: 15,
    offset: 0,
  },
  sorting: {
    order: 'asc',
    orderBy: [],
  },
  searchParam: '',
};

const projectTemplatesAPI = ProjectTemplatesAPI;
const projectTemplateAPI = ProjectTemplateAPI;

/* ============================== REDUX THUNK =============================== */
export const fetchProjectTemplates = createAsyncThunk(
  'projectTemplates/FETCH_TEMPLATES',
  async (params?: fetchTemplatesParams) => {
    const templates = await projectTemplatesAPI.fetchProjectTemplates(
      params || {}
    );
    return templates as ProjectTemplate[];
  }
);

export const addProjectTemplate = createAsyncThunk(
  'projectTemplates/ADD_TEMPLATE',
  async (params: { template: ProjectTemplate }) => {
    const {
      project_template_fields,
      creator_id,
      owner,
      creator,
      ...otherData
    } = params.template;
    const newTemplate = await projectTemplatesAPI.addNewProjectTemplate({
      ...otherData,
    });
    if (newTemplate.id && project_template_fields.length > 0) {
      const newProjectTemplateFields = project_template_fields.map(
        ({ field_template, ...templateField }) => ({
          ...templateField,
          project_template_id: newTemplate.id,
          currentTemplateVersion: newTemplate.version,
        })
      );
      const projectTemplateFields =
        await projectTemplateAPI.bulkCreateProjectTemplateFields(
          newProjectTemplateFields
        );
      newTemplate.project_template_fields = projectTemplateFields;
    }
    return newTemplate;
  }
);

export const deleteProjectTemplate = createAsyncThunk(
  'projectTemplate/DELETE_TEMPLATE',
  async (params: { templateId: string }) => {
    const response = await projectTemplatesAPI.deleteProjectTemplate(
      params.templateId
    );
    return response.deletedRowId;
  }
);

/* ================================= ACTIONS ================================ */
export const setSearchParam = createAction<string>(
  'projectTemplates/SET_SEARCH_PARAMS'
);

export const updateTemplatePagination = createAction<{
  limit: number;
  offset: number;
}>('projectTemplates/UPDATE_TEMPLATES_PAGINATION');

export const setTemplatesOrder = createAction<{
  order: SortingType;
  orderBy: string[];
}>('projectTemplates/SET_TEMPLATES_ORDER');

/* ================================= REDUCER ================================ */
const projectTemplatesSlice = createSlice({
  name: 'projectTemplates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectTemplates.fulfilled, (state, action) => {
        state.value = action.payload;
      })
      .addCase(updateTemplatePagination, (state, action) => {
        state.pagination.limit = action.payload.limit;
        state.pagination.offset = action.payload.offset;
      })
      .addCase(setSearchParam, (state, action) => {
        state.searchParam = action.payload.toLocaleLowerCase().trim();
      })
      .addCase(setTemplatesOrder, (state, action) => {
        state.sorting.order = action.payload.order;
        state.sorting.orderBy = action.payload.orderBy;
      })
      .addCase(addProjectTemplate.fulfilled, (state, action) => {
        state.value = state.value.concat(action.payload);
      })
      .addCase(deleteProjectTemplate.fulfilled, (state, action) => {
        state.value = state.value.filter(
          (projectTemplate: ProjectTemplate) =>
            projectTemplate.id !== action.payload
        );
      });
  },
});

/* =============================== SELECTORS ================================ */
export const templateSearch = (state: RootState) =>
  state.projectTemplates.searchParam;
export const selectAllTemplates = (state: RootState) =>
  state.projectTemplates.value;
export const templateSearchParams = (state: RootState) =>
  state.projectTemplates.searchParam;
const templatesPagination = (state: RootState) =>
  state.projectTemplates.pagination;
const templatesSorting = (state: RootState) => state.projectTemplates.sorting;

export const projectTemplatesForTable = createSelector(
  [
    selectAllTemplates,
    templateSearchParams,
    templatesPagination,
    templatesSorting,
  ],
  (templates: ProjectTemplate[], searchParam, pagination, sorting) => {
    let formattedTemplates = templates.filter((template) => {
      if (searchParam) {
        return (
          template.name
            ?.toLocaleLowerCase()
            .includes(searchParam.toLocaleLowerCase()) || false
        );
      } else {
        return true;
      }
    }) as ProjectTemplate[];

    formattedTemplates = orderBy(
      formattedTemplates,
      (template) => {
        return sorting.orderBy.map((orderRoute) => {
          switch (orderRoute) {
            case TEMPLATE_SORTING.UPDATED_AT[0]: {
              return get(template, orderRoute);
            }
            case TEMPLATE_SORTING.OWNER[0]:
            case TEMPLATE_SORTING.OWNER[1]: {
              const label = `${
                get(template, TEMPLATE_SORTING.OWNER[0]) || ''
              } ${get(template, TEMPLATE_SORTING.OWNER[1])}`.trim();
              return label ? label.toLocaleLowerCase() : '';
            }
            case TEMPLATE_SORTING.STATUS[0]: {
              return intl.get(
                `SETTINGS_PAGE.PROJECTS_PAGE.${get(template, orderRoute)}`
              );
            }
            default: {
              const label = `${get(template, orderRoute) || ''}`.trim();
              return label ? label.toLocaleLowerCase() : '';
            }
          }
        });
      },
      [sorting.order, sorting.order]
    );

    return {
      total: templates.length,
      data: formattedTemplates.slice(pagination.offset, pagination.limit),
    };
  }
);

export default projectTemplatesSlice.reducer;
