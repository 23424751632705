import { tailwindOverride, Typography, Icon } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { store } from 'state/store';
import roiLogo from 'assets/images/roi-banner.svg';
import { useDispatch } from 'react-redux';
import { getROIFileSignedUrl } from 'state/ProgramStrategy/programStrategySlice';
import bottomIllustration from 'assets/images/examples-Illustration.svg';
import { showMessenger } from 'Services/intercom';
import { ROI_INSTITUTE_BOOTCAMP_LINK, PENDO_EVENTS } from 'utils/constants';
import OpenLink from 'assets/images/opne-link.svg';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { trackEvent } from 'Services/pendo';

const ExternalFileLink = ({
  name,
  onClick,
}: {
  name: string;
  onClick: () => void;
}) => (
  <div
    onClick={onClick}
    className={tailwindOverride(
      'border border-secondary-lighter cursor-pointer rounded items-center justify-between shadow-header bg-neutral-white',
      'flex py-1 h-10 px-4 mt-2 w-full hover:border-secondary-light hover:shadow-allocation-table group'
    )}
  >
    <span
      className='text-sm truncate'
      title={intl.get(
        `PROGRAM_PAGE.STRATEGY_PAGE.EXAMPLE_PROMPTS.${name?.toUpperCase()}`
      )}
    >
      {intl.get(
        `PROGRAM_PAGE.STRATEGY_PAGE.EXAMPLE_PROMPTS.${name?.toUpperCase()}`
      )}
    </span>
    <Icon
      src={OpenLink}
      className='text-3xl text-neutral group-hover:text-neutral-darker'
    />
  </div>
);

const StrategyExamplesCard = ({
  links,
  programId,
  className = '',
  showROIBootcamp = false,
}: {
  links: string[];
  programId: string;
  className?: string;
  showROIBootcamp?: boolean;
}) => {
  const dispatch = useDispatch() as typeof store.dispatch;

  const onGetFileLink = async (fileName: string) => {
    try {
      const { signedUrl } = await dispatch(
        getROIFileSignedUrl({ programId, fileName })
      ).unwrap();

      window.open(signedUrl, '_blank');
    } catch (error) {
      dispatch(
        showSnackbarNotification({
          autoHide: false,
          notificationVariant: 'error',
          notificationTitle: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.TIPS.ERROR_MESSAGE'
          ),
          notificationMessage: intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.TIPS.ERROR_SUB'
          ),
        })
      );
    }
  };

  const openROIBootcamp = () => {
    trackEvent(PENDO_EVENTS.ROI_BOOTCAMP_LINK_CLICKED, { programId });
    window.open(ROI_INSTITUTE_BOOTCAMP_LINK, '_blank', 'noopener,noreferrer');
  };

  return (
    <div
      className={tailwindOverride(
        'pt-4 mb-6 border rounded-lg border-primary-lighter-two',
        'flex flex-col relative overflow-hidden',
        className
      )}
    >
      <div>
        <img src={roiLogo} alt='roi trademark' />
      </div>
      <div className='px-6'>
        {showROIBootcamp && (
          <div className='rounded bg-secondary-lightest-two mt-5 px-4 pt-4 pb-6'>
            <Typography variant='h6' data-testid='bootcamp_title'>
              {intl.getHTML('PROGRAM_PAGE.STRATEGY_PAGE.ROI_BOOTCAMP.TITLE')}
            </Typography>
            <Typography
              variant='caption'
              className='mt-2 text-primary-darker leading-6'
            >
              {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.ROI_BOOTCAMP.DESCRIPTION')}
            </Typography>
            <ul className='list-disc list-inside pl-2 text-primary-darker font-bold text-xs leading-6 mb-3'>
              <li>
                {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.ROI_BOOTCAMP.POINT_ONE')}
              </li>
              <li>
                {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.ROI_BOOTCAMP.POINT_TWO')}
              </li>
              <li>
                {intl.get(
                  'PROGRAM_PAGE.STRATEGY_PAGE.ROI_BOOTCAMP.POINT_THREE'
                )}
              </li>
              <li>
                {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.ROI_BOOTCAMP.POINT_FOUR')}
              </li>
            </ul>
            <ExternalFileLink name='bootcamp' onClick={openROIBootcamp} />
          </div>
        )}

        <Typography
          data-testid='roi_examples_title'
          weight='medium'
          className={tailwindOverride(
            'leading-6 pb-1 w-full',
            `${showROIBootcamp ? 'mt-6' : 'mt-4'}`
          )}
        >
          {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.TIPS.TITLE')}
        </Typography>
        <Typography
          variant='body2'
          title='test'
          data-testid='roi_examples_description'
          className='w-full leading-6 text-neutral-darker mb-4'
        >
          {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.TIPS.DESCRIPTION')}
        </Typography>

        {links.map((link, index) => (
          <ExternalFileLink
            key={index}
            name={link}
            onClick={() => onGetFileLink(link)}
          />
        ))}

        <div
          className='flex gap-1 mt-6 items-center text-sm'
          data-testid='roi_institue-link'
        >
          <span className='text-base font-semibold'>👉</span>
          <span>
            {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.TIPS.HAVE_QUESTIONS')}
          </span>
          <span
            onClick={() => showMessenger()}
            className='text-purple-dark font-medium cursor-pointer hover:text-purple-darker'
          >
            {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.TIPS.WE_CAN_HELP')}
          </span>
        </div>
      </div>
      <img
        src={bottomIllustration}
        alt='roi examples illustration'
        className={tailwindOverride({
          'absolute bottom-0 left-0': !showROIBootcamp,
        })}
      />
    </div>
  );
};

export default StrategyExamplesCard;
