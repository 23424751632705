import { rangeDateType, Option } from 'utils/customTypes';
import { RangeFilter } from './types/filters';

export function isDateRangeFilter(value: any): value is RangeFilter {
  return typeof value === 'object' && ('from' in value || 'to' in value);
}

export function isArrayOfOptions(value: any): value is Option[] {
  return (
    Array.isArray(value) &&
    value.every((item) => 'label' in item && 'value' in item)
  );
}

export function isDateRangeObject(value: any): value is rangeDateType {
  return (
    typeof value === 'object' && ('startDate' in value || 'endDate' in value)
  );
}
