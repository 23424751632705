import { useMemo, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { has, get, isEmpty } from 'lodash';
import { selectUserName, selectOrganizationId } from 'state/User/userSlice';
import { selectAssociatedProcessesAndStages } from 'state/Projects/projectsSlice';
import {
  NewProjectProcess,
  NewProjectProcessStage,
  ProjectProcess,
} from 'utils/customTypes';
import { PROJECT_STAGE_FIELDS, PROJECT_PROCESS_FIELDS } from 'utils/constants';

const useProjectProcessForm = (originalProcess: ProjectProcess | null) => {
  const currentUserName = useSelector(selectUserName);
  const organizationId = useSelector(selectOrganizationId);
  const associatedProjectProcesses = useSelector(
    selectAssociatedProcessesAndStages
  );

  const defaultStageState: NewProjectProcessStage = useMemo(
    () => ({
      stageName: '',
      description: '',
      data: {
        [PROJECT_STAGE_FIELDS.MIN_COMPLETION_TIME]: '',
        [PROJECT_STAGE_FIELDS.MAX_COMPLETON_TIME]: '',
      },
    }),
    []
  );

  const defaultProcessState: NewProjectProcess = useMemo(
    () => ({
      processName: '',
      description: '',
      projectStages: [defaultStageState],
      organization_id: organizationId!,
      data: {
        [PROJECT_PROCESS_FIELDS.CREATED_BY]: { name: currentUserName },
      },
    }),
    [defaultStageState, organizationId, currentUserName]
  );

  const [projectProcess, setProjectProcess] = useState<
    NewProjectProcess | ProjectProcess
  >(defaultProcessState);
  const [canSubmitForm, setCanSubmitForm] = useState(false);
  const [unremovableStages, setUnremovableStages] = useState([]);
  const [processStagesWithRepeatedNames, setProcessStagesWithRepeatedNames] =
    useState<number[]>();

  useEffect(() => {
    setProjectProcess(
      originalProcess !== null ? originalProcess : defaultProcessState
    );
  }, [originalProcess, defaultProcessState]);

  useEffect(() => {
    let canSubmit = true;
    const processStagesNames: string[] = [];
    const processStagesIndexes: number[] = [];
    if (
      isEmpty(projectProcess.processName) ||
      isEmpty(projectProcess.description)
    ) {
      canSubmit = false;
    }

    if (canSubmit) {
      let index = 0;
      for (const stage of projectProcess.projectStages) {
        if (isEmpty(stage.stageName)) {
          canSubmit = false;
          break;
        }

        if (processStagesNames.includes(stage.stageName)) {
          processStagesIndexes.push(index);
          canSubmit = false;
          break;
        }

        processStagesNames.push(stage.stageName);
        index++;
      }
    }

    setCanSubmitForm(canSubmit);
    setProcessStagesWithRepeatedNames(processStagesIndexes);
  }, [projectProcess]);

  useEffect(() => {
    const stagesList =
      originalProcess !== null &&
      has(associatedProjectProcesses, get(originalProcess, 'id'))
        ? associatedProjectProcesses[get(originalProcess, 'id')]
        : [];
    setUnremovableStages(stagesList);
  }, [associatedProjectProcesses, originalProcess]);

  const handleUpdateProcess = useCallback((key: string, value: string) => {
    setProjectProcess((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const handleUpdateStage = useCallback(
    (index: number, key: string, value: string) => {
      setProjectProcess((prevState) => {
        const updateData =
          key === PROJECT_STAGE_FIELDS.MIN_COMPLETION_TIME ||
          key === PROJECT_STAGE_FIELDS.MAX_COMPLETON_TIME;
        const updatedProcess = {
          ...prevState,
          [PROJECT_PROCESS_FIELDS.STAGES]: [
            ...prevState.projectStages.slice(0, index),
            {
              ...prevState.projectStages[index],
              ...(updateData && {
                [PROJECT_STAGE_FIELDS.DATA]: {
                  ...prevState.projectStages[index].data,
                  [key]: value,
                },
              }),
              ...(!updateData && { [key]: value }),
            },
            ...prevState.projectStages.slice(index + 1),
          ],
        };
        return updatedProcess;
      });
    },
    []
  );

  const handleAddStage = useCallback(() => {
    setProjectProcess((prevState: NewProjectProcess) => {
      const updatedStages = prevState.projectStages.concat(defaultStageState);
      return {
        ...prevState,
        [PROJECT_PROCESS_FIELDS.STAGES]: updatedStages,
      };
    });
  }, [defaultStageState]);

  const handleRemoveStage = useCallback((index: number) => {
    setProjectProcess((prevState: NewProjectProcess) => {
      const updatedStages = [
        ...prevState.projectStages.slice(0, index),
        ...prevState.projectStages.slice(index + 1),
      ];
      return {
        ...prevState,
        [PROJECT_PROCESS_FIELDS.STAGES]: updatedStages,
      };
    });
  }, []);

  return {
    projectProcess,
    updateProjectProcess: handleUpdateProcess,
    updateProjectProcessStage: handleUpdateStage,
    addNewProjectProcessStage: handleAddStage,
    removeProjectProcessStage: handleRemoveStage,
    unremovableProjectProcessStages: unremovableStages,
    canSubmitForm,
    processStagesWithRepeatedNames,
    defaultProcessState,
    setProjectProcess,
  };
};

export default useProjectProcessForm;
