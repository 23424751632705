import { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import { selectUserId } from 'state/User/userSlice';
import userAPI from 'state/User/userAPI';
import useGetUserId from 'Pages/TimeOffListPage/hooks/useGetUserId';
import PageTitle from 'Molecules/PageTitle/PageTitle';

const TimeOffPageTitle = () => {
  const [pageTitle, setPageTitle] = useState<string>();
  const mounted = useRef(false);
  const targetUserId = useGetUserId();
  const currentUserId = useSelector(selectUserId);

  const fetchUserBasicInfo = useCallback(async (userId: string) => {
    const user = await userAPI.getUserBasicInfo(userId);
    const basicInfo = get(user, 'data.basicInfo');
    const firstName = get(basicInfo, 'firstName');
    const lastName = get(basicInfo, 'lastName');
    if (mounted.current) {
      setPageTitle(
        intl.get('MANAGE_TIME_OFF.MANAGE_USER_TIME_OFF_TITLE', {
          userName: `${capitalize(firstName)} ${capitalize(lastName)}`,
        })
      );
    }
  }, []);

  useEffect(() => {
    mounted.current = true;
    if (targetUserId && currentUserId && targetUserId !== currentUserId) {
      fetchUserBasicInfo(targetUserId!);
    } else {
      setPageTitle(intl.get('SIDEBAR.MY_TIME_OFF'));
    }
    return () => {
      mounted.current = false;
    };
  }, [targetUserId, currentUserId, fetchUserBasicInfo]);

  return <PageTitle titleComponent={pageTitle} />;
};

export default TimeOffPageTitle;
