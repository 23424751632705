import {
  Icon,
  LargeLabelInput,
  Tooltip,
  Typography,
} from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { ProgramInputCategoryData } from 'utils/types/program';
import get from 'lodash/get';
import {
  PROGRAM_INPUT_OBJECTIVES_FIELDS,
  PROGRAM_INPUT_OBJECTIVES_FIELDS_MAPPING,
} from 'utils/constants/program';

const ObjectivesDetailsSection = ({
  programInput,
  updateInputField,
}: {
  programInput: ProgramInputCategoryData;
  updateInputField: (
    value: string,
    property: keyof ProgramInputCategoryData
  ) => void;
}) => {
  return (
    <div className='border-t w-full border-neutral-lighter-two mt-10'>
      <Typography variant='h5' className='mt-8'>
        {intl.get('INPUT_CATEGORY.OBJECTIVES_DETAIL_SECTION.SECTION_TITLE')}
      </Typography>
      <Typography variant='body2' className='text-neutral-darker mb-4 mt-1'>
        {intl.get('INPUT_CATEGORY.OBJECTIVES_DETAIL_SECTION.SECTION_SUBTITLE')}
      </Typography>
      {Object.values(PROGRAM_INPUT_OBJECTIVES_FIELDS).map((fieldName) => (
        <LargeLabelInput
          className='w-full mt-6 text-base'
          labelProps={{ className: 'text-base flex mb-0 leading-6' }}
          key={fieldName}
          name={fieldName}
          label={intl.get(
            `INPUT_CATEGORY.OBJECTIVES_DETAIL_SECTION.FIELDS.${fieldName.toUpperCase()}.TITLE`
          )}
          subtitleProps={{ className: 'text-neutral-darker leading-5 mt-0.5' }}
          labelTrailingIcon={
            <Tooltip
              trigger={
                <Icon
                  name='help-circle'
                  className='text-lg text-neutral hover:text-neutral-black cursor-pointer mt-1'
                />
              }
              timeout={0}
              openMode='hover1'
              className='ml-1'
              position='topRight'
              contentProps={{ className: 'p-3 max-w-40' }}
            >
              <Typography className='text-neutral-white text-sm'>
                {intl.get(
                  `INPUT_CATEGORY.OBJECTIVES_DETAIL_SECTION.FIELDS.${fieldName.toUpperCase()}.TOOLTIP`
                )}
              </Typography>
            </Tooltip>
          }
          onChange={(event) =>
            updateInputField(
              event.target.value,
              PROGRAM_INPUT_OBJECTIVES_FIELDS_MAPPING?.[
                fieldName
              ] as keyof ProgramInputCategoryData
            )
          }
          value={get(
            programInput,
            `${PROGRAM_INPUT_OBJECTIVES_FIELDS_MAPPING?.[fieldName]}`,
            ''
          )}
          state='default'
          subtitle={intl.get(
            `INPUT_CATEGORY.OBJECTIVES_DETAIL_SECTION.FIELDS.${fieldName.toUpperCase()}.SUBTITLE`
          )}
          textAreaProps={{
            placeholder: intl.get(
              `INPUT_CATEGORY.OBJECTIVES_DETAIL_SECTION.FIELDS.${fieldName.toUpperCase()}.PLACEHOLDER`
            ),
            rows: 1,
            className: 'w-full placeholder-text-neutral',
            'data-testid': `${fieldName}-input`,
          }}
        />
      ))}
    </div>
  );
};

export default ObjectivesDetailsSection;
