import { ChangeEvent } from 'react';
import intl from 'react-intl-universal';
import { LargeLabelInput, Icon, Typography } from '@getsynapse/design-system';
import { Objective } from 'utils/types/program';
import tagetIcon from 'assets/icons/target-icon-green.svg';

type Props = {
  objective: Objective;
  setObjectiveData: React.Dispatch<React.SetStateAction<Objective | undefined>>;
};

const ObjectiveData = ({ objective, setObjectiveData }: Props) => {
  const onSetObjectiveName = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setObjectiveData(
      (prev) => ({ ...prev, name: event.target.value } as Objective)
    );
  };

  return (
    <div className='px-8 pt-6'>
      <Typography variant='h5' className='leading-7 pb-5'>
        {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE_MANAGEMENT')}
      </Typography>

      <div className='flex flex-grow items-start pb-10'>
        <Icon src={tagetIcon} className='text-5xl pr-4 self-start' />

        <LargeLabelInput
          className='w-3/5'
          value={objective.name}
          onChange={onSetObjectiveName}
          name={intl.get('PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE', {
            number: objective.position,
          })}
          state='default'
          label={intl.get('PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE', {
            number: objective.position,
          })}
          labelProps={{
            className: 'text-base leading-6 mb-0',
            'data-testid': 'category-objective_number',
          }}
          subtitle={intl.get(
            'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.AT_END_OF_PROGRAM_PARTICIPANTS'
          )}
          subtitleProps={{ className: 'text-neutral-darker' }}
          textAreaProps={{
            placeholder: intl.get(
              'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.EDIT_OBJECTIVE_PLACEHOLDER'
            ),
            className: 'w-full max-h-32 placeholder-text-neutral',
            'data-testid': 'category-objective_name-input',
            rows: 1,
          }}
          readOnly={objective.programObjectiveTemplateId !== null}
        />
      </div>
    </div>
  );
};

export default ObjectiveData;
