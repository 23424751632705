import React from 'react';
import moment from 'moment';
import { Task } from 'utils/customTypes';
import {
  Allocation,
  TaskLevelAllocation,
} from 'utils/types/tasksAssignmentsResourcesCapacity';
import Timeline, { Column } from '../../Timeline/Timeline';
import Estimation from './Estimation/Estimation';
import { useSelector } from 'react-redux';
import { selectPagination } from 'state/TasksAssignmentsResourcesCapacity/tasksAssignmentsResourcesCapacitySlice';
import { CAPACITY_TABLE_NUMBER_OF_WEEKS } from 'utils/constants';

const EstimationsTimeline: React.FC<{
  userId: string;
  task: Task;
  weeksArray: string[];
  estimations: Allocation<TaskLevelAllocation>;
  className?: string;
}> = ({ userId, task, estimations, weeksArray, className }) => {
  const { index: firstVisibleAllocationIndex } = useSelector(selectPagination);
  const currentWeekStart = moment().startOf('isoWeek');
  const lastVisibleAllocationIndex =
    firstVisibleAllocationIndex + CAPACITY_TABLE_NUMBER_OF_WEEKS - 1;

  return (
    <Timeline className={className} id={`participant-${userId}__tasks`}>
      {weeksArray.map((week, index) => {
        const iscurrentWeek = currentWeekStart.isSame(
          new Date(week.replace(/-/g, '/'))
        );
        const key = `participant-${userId}__task-${
          task.id
        }__estimation-${new Date(week).valueOf()}`;
        if (estimations[week]) {
          const estimation = estimations[week];
          return (
            <Estimation
              task={task}
              isCurrentWeek={iscurrentWeek}
              estimation={estimation}
              key={key}
              aria-hidden={
                index < firstVisibleAllocationIndex ||
                index > lastVisibleAllocationIndex
              }
              data-cy={key}
            />
          );
        } else {
          return (
            <Column
              isCurrentWeek={iscurrentWeek}
              key={key}
              aria-hidden={
                index < firstVisibleAllocationIndex ||
                index > lastVisibleAllocationIndex
              }
              className='pb-3'
            />
          );
        }
      })}
    </Timeline>
  );
};

export default EstimationsTimeline;
