import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchAvailableTaskAssignees,
  FetchAvailableTaskAssigneesParams,
} from 'api/taskAvailableAssignees';
import { FetchTaskDetailParams, fetchTask } from 'api/taskDetail';
import axios from 'axios';
import { RootState } from 'state/store';
import { normalizeState } from 'state/utils/normalizeState';
import { TaskDetailState } from 'types/store/taskDetail';
import { TaskAssignee } from 'types/store/tasks';
import { SLICE_STATUS } from 'utils/constants';

export const initialState: TaskDetailState = {
  status: SLICE_STATUS.IDLE,
  permissions: {
    canDelete: false,
    canEdit: false,
  },
  availableAssigneesStatus: SLICE_STATUS.IDLE,
  availableAssigneeIds: [],
  availableAssignees: {},
  taskActualHoursLoading: false,
};

/* =============================== ACTIONS ================================ */

/* =============================== SELECTORS ================================ */
export const selectFetchTaskDetailStatus = (state: RootState) =>
  state.taskDetail.status;
export const selectTaskDetail = (state: RootState) =>
  state.taskDetail.currentTask;
export const selectCanDelete = (state: RootState) =>
  state.taskDetail.permissions?.canDelete || false;
export const selectCanEdit = (state: RootState) =>
  state.taskDetail.permissions?.canEdit || false;

export const selectAssignees = (state: RootState) =>
  state.taskDetail.currentTask?.assignees || [];

export const selectTaskType = (state: RootState) =>
  state.taskDetail.currentTask?.type || '';
export const selectTaskStatus = (state: RootState) =>
  state.taskDetail.currentTask?.status || '';

export const selectTaskProjectId = (state: RootState) =>
  state.taskDetail.currentTask?.project?.id || '';

export const selectTaskCompletionDate = (state: RootState) => {
  return (
    state.taskDetail.currentTask?.completedDate ||
    new Date().toLocaleDateString('en-US')
  );
};
export const selectTaskStartDate = (state: RootState) => {
  return (
    state.taskDetail.currentTask?.startDate ||
    new Date().toLocaleDateString('en-US')
  );
};

export const selectTaskEstimateHours = (state: RootState) =>
  parseFloat(state.taskDetail.currentTask?.estimateHours || '00');
export const selectTaskActualHoursSum = (state: RootState) =>
  parseFloat(state.taskDetail.currentTask?.actualHours || '00');

export const selectTaskDisplayId = (state: RootState) =>
  state.taskDetail.currentTask?.displayId || '';

export const selectTaskName = (state: RootState) => {
  return state.taskDetail.currentTask?.name || '';
};

export const selectAvailableAssignees = (state: RootState) => {
  return state.taskDetail.availableAssigneeIds.map((id) => {
    return state.taskDetail.availableAssignees[id];
  });
};

export const selectActualHourEntries = (state: RootState) => {
  return Object.values(state.taskDetail.taskActualHours || {});
};

/* =============================== THUNK ================================ */

export const fetchTaskDetail = createAsyncThunk(
  'tasksDetail/fetchTask',
  async (params: FetchTaskDetailParams, { rejectWithValue }) => {
    try {
      const response = await fetchTask(params);

      return {
        taskDetail: response.task,
        permissions: response.permissions,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error fetching team tasks');
    }
  }
);

export const fetchAvailableAssignees = createAsyncThunk(
  'tasksDetail/fetchAvailableAssignees',
  async (
    params: FetchAvailableTaskAssigneesParams,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;

      if (state.taskDetail.availableAssigneesStatus === SLICE_STATUS.UPDATING) {
        return;
      }

      const response = await fetchAvailableTaskAssignees(params);

      return response.availableAssignees;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error fetching team tasks');
    }
  }
);

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskDetail.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchTaskDetail.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        state.currentTask = action.payload.taskDetail;
        if (action.payload.permissions) {
          if (action.payload.permissions.canEdit) {
            state.permissions.canEdit = action.payload.permissions.canEdit;
          }

          if (action.payload.permissions.canDelete) {
            state.permissions.canDelete = action.payload.permissions.canDelete;
          }
        }
      })
      .addCase(fetchTaskDetail.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchAvailableAssignees.pending, (state) => {
        state.availableAssigneesStatus = SLICE_STATUS.LOADING;
      })
      .addCase(fetchAvailableAssignees.fulfilled, (state, action) => {
        if (action.payload) {
          const normalizedState = normalizeState<TaskAssignee>(action.payload);

          state.availableAssigneeIds = normalizedState.ids;
          state.availableAssignees = normalizedState.entities;
        }
      });
  },
});

export default tasksSlice.reducer;
