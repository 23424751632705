export type DateRange = [Date, Date];

/**
 *
 * @param date The date to determine which week segment it falls into
 * @returns Returns a tuple of the monday to friday of a week segment for  date
 */
export function getWeekRangeForDate(date: Date | string | number): DateRange {
  const currentDate = new Date(date);
  const startDate = new Date(
    currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1)
  );

  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 4);

  return [startDate, endDate];
}

export function getWeekRangesForDate(
  date: Date | string | number,
  segmentCount: number
): DateRange[] {
  const result: DateRange[] = new Array<DateRange>();

  let startDate = new Date(date);

  for (let i = 0; i < segmentCount; i++) {
    const segment = getWeekRangeForDate(startDate);
    result.push(segment);
    startDate.setDate(startDate.getDate() + 7);
  }

  return result;
}
