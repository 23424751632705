import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  ProjectComment,
  QuestionCommentType,
  ProjectCommentMentionsData,
  UserAvatars,
} from 'utils/customTypes';
import { Typography } from '@getsynapse/design-system';
import CommentBlock from './CommentBlock';
import HiddenComments from './HiddenComments';
import { State } from '@popperjs/core';

const CommentsList: React.FC<{
  comments: ProjectComment[] | QuestionCommentType[];
  onEdit?: (params: {
    commentId: string;
    content: string;
    mentionsData: ProjectCommentMentionsData;
  }) => void;
  onDelete: (commentId: string) => void;
  testId?: string;
  emptyListMessage: string;
  mentionableUsers?: UserAvatars[];
  mentionsFieldClassName?: string;
  updateCommentsPopup?: (() => Promise<Partial<State>>) | null;
}> = ({
  comments,
  onEdit,
  onDelete,
  testId,
  emptyListMessage,
  mentionableUsers = [],
  mentionsFieldClassName,
  updateCommentsPopup,
}) => {
  return (
    <div data-cy={testId} className='max-h-comments-list overflow-y-auto'>
      {!isEmpty(comments) ? (
        comments.length <= 2 ? (
          comments.map(
            (comment: QuestionCommentType | ProjectComment, index: number) => (
              <CommentBlock
                key={index}
                comment={comment}
                onEdit={onEdit}
                onDelete={onDelete}
                mentionableUsers={mentionableUsers}
                mentionsFieldClassName={mentionsFieldClassName}
              />
            )
          )
        ) : (
          <HiddenComments
            comments={comments}
            onEdit={onEdit}
            onDelete={onDelete}
            testId={testId!}
            mentionableUsers={mentionableUsers}
            mentionsFieldClassName={mentionsFieldClassName}
            updateCommentsPopup={updateCommentsPopup}
          />
        )
      ) : (
        <div className='w-full h-20 py-4 px-6 flex items-center justify-start'>
          <Typography variant='body' className='text-neutral'>
            {emptyListMessage}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default CommentsList;
