import { useState, useMemo, useEffect } from 'react';
import {
  useParams,
  useHistory,
  generatePath,
  matchPath,
} from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import intl from 'react-intl-universal';
import { Location } from 'history';
import { Tabs } from '@reach/tabs';
import { useSelector, useDispatch } from 'react-redux';
import {
  getProgramData,
  fetchProgram,
  fetchProjectsForProgram,
  resetProgram,
} from 'state/Program/programSlice';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import { Program } from 'utils/customTypes';
import { PATHS } from 'utils/constants';
import { PROGRAM_TABS } from 'utils/constants/program';
import { programTabs } from 'utils/types/program';
import { Tab, TabList, TabPanels, TabPanel } from '@getsynapse/design-system';
import Overview from './components/Overview';
import ProgramStrategyPage from 'Pages/ProgramStrategyPage/ProgramStrategyPage';
import { getAllUsers } from 'state/UsersManagement/usersManagementSlice';
import {
  isUserOrganizationAdmin,
  selectUserId,
  selectIsUserLd,
} from 'state/User/userSlice';

const ProgramPage = () => {
  const { enableProgramStrategy = false } = useFlags();
  const history = useHistory();
  const dispatch = useDispatch();
  const { programId, activeTab = 0 } = useParams<{
    programId: string;
    activeTab?: string;
  }>();
  const tabToString: Record<number, programTabs> = {
    0: PROGRAM_TABS.OVERVIEW,
    1: PROGRAM_TABS.STRATEGY,
  };
  const tabMapping: Record<programTabs, number> = {
    [PROGRAM_TABS.OVERVIEW]: 0,
    [PROGRAM_TABS.STRATEGY]: 1,
  };
  const [leavingToLocation, setLeavingToLocation] = useState<string>('');
  const [isLeavingWarningModalOpen, setIsLeavingWarningModalOpen] =
    useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState(
    activeTab ? tabMapping[activeTab] : 0
  );
  const [confirmedNavigation, setConfirmedNavigation] =
    useState<boolean>(false);
  const program: Program = useSelector(getProgramData);
  const currentUserId: string | undefined = useSelector(selectUserId);
  const isUserOrgAdmin: boolean = useSelector(isUserOrganizationAdmin);
  const isUserLd = useSelector(selectIsUserLd);

  const handleTabChange = (index: number) => {
    setActiveIndex(index);
    const tabKey = tabToString[index];
    const path = generatePath(`${PATHS.PROGRAM_PAGE}/:programId/:activeTab?`, {
      programId,
      activeTab: tabKey,
    });
    history.push(path);
  };

  const isUserProgramOwner = useMemo(() => {
    return program.programOwners.some((owner) => owner.id === currentUserId);
  }, [program.programOwners, currentUserId]);

  useEffect(() => {
    if (isUserOrgAdmin || isUserProgramOwner) {
      dispatch(fetchProjectsForProgram(programId));
    }
  }, [dispatch, programId, isUserOrgAdmin, isUserProgramOwner]);

  useEffect(() => {
    if (!isUserOrgAdmin && !isUserLd) {
      history.push(PATHS.ROOT);
    } else if (programId) {
      dispatch(fetchProgram(programId));
      dispatch(getAllUsers());
    }
    return () => {
      dispatch(resetProgram());
    };
  }, [dispatch, programId, history, isUserOrgAdmin, isUserLd]);

  const canUserSeeProgramStrategy = useMemo(() => {
    return enableProgramStrategy && (isUserOrgAdmin || isUserProgramOwner);
  }, [enableProgramStrategy, isUserOrgAdmin, isUserProgramOwner]);

  const handleBlockedNavigation = (location: Location) => {
    const match = matchPath(location.pathname, {
      path: [
        `${PATHS.PROGRAM_PAGE}/:programId`,
        `${PATHS.PROGRAM_PAGE}/:programId/${PROGRAM_TABS.OVERVIEW}`,
        `${PATHS.PROGRAM_PAGE}/:programId/${PROGRAM_TABS.STRATEGY}`,
      ],
      exact: true,
      strict: false,
    });

    if (!confirmedNavigation) {
      if (!match) {
        setLeavingToLocation(location.pathname);
        setIsLeavingWarningModalOpen(true);
        return false;
      }
    }
    return true;
  };

  return canUserSeeProgramStrategy ? (
    <Tabs
      className='min-h-full flex flex-col'
      index={activeIndex}
      onChange={handleTabChange}
    >
      <PageTitle
        titleComponent={program.title}
        headerChildren={
          <div className='flex'>
            <TabList type='subHeader'>
              <Tab index={0} type='subHeader' data-testid='overview-tab_button'>
                {intl.get('PROGRAM_PAGE.OVERVIEW')}
              </Tab>
              <Tab
                index={1}
                type='subHeader'
                data-testid='strategy-tab__button'
              >
                {intl.get('PROGRAM_PAGE.STRATEGY_PAGE.STRATEGY')}
              </Tab>
            </TabList>
          </div>
        }
      />

      <TabPanels className='flex-grow flex flex-col px-6 max-h-details-with-bar'>
        <TabPanel
          data-cy='program-overview-tab__panel'
          className='flex-grow bg-neutral-white rounded'
          key={0}
        >
          <Overview
            leavingToLocation={leavingToLocation}
            setLeavingToLocation={setLeavingToLocation}
            isLeavingWarningModalOpen={isLeavingWarningModalOpen}
            setIsLeavingWarningModalOpen={setIsLeavingWarningModalOpen}
            handleBlockedNavigation={handleBlockedNavigation}
            confirmedNavigation={confirmedNavigation}
            setConfirmedNavigation={setConfirmedNavigation}
          />
        </TabPanel>

        <TabPanel
          data-cy='program-strategy-tab__panel'
          className='flex-grow bg-neutral-white rounded'
          key={1}
        >
          <ProgramStrategyPage programId={programId} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  ) : (
    <div className='min-h-full flex flex-col'>
      <PageTitle titleComponent={program.title} />

      <div className='flex-grow flex flex-col px-6 max-h-details-with-bar'>
        <div
          data-cy='program-overview-tab__panel'
          className='flex-grow bg-neutral-white rounded'
        >
          <Overview
            leavingToLocation={leavingToLocation}
            setLeavingToLocation={setLeavingToLocation}
            isLeavingWarningModalOpen={isLeavingWarningModalOpen}
            setIsLeavingWarningModalOpen={setIsLeavingWarningModalOpen}
            handleBlockedNavigation={handleBlockedNavigation}
            confirmedNavigation={confirmedNavigation}
            setConfirmedNavigation={setConfirmedNavigation}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgramPage;
