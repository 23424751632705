import get from 'lodash/get';
import {
  Program,
  Project,
  ProjectOwner,
  LDUser,
  User,
} from 'utils/customTypes';
/**
 * Sorts an array of objects naturally based on the specified object key and direction.
 * This function uses the `localeCompare` method to perform a natural sort, taking into account
 * numeric values and ignoring punctuation, which is useful for sorting strings that contain numbers
 * in a way that appears natural to users.
 *
 * @template T The type of the objects in the array.
 * @param {T[]} array The array of objects to be sorted.
 * @param {keyof T} key The key of the objects by which the array should be sorted.
 * @param {'asc' | 'desc'} direction The direction of the sort: 'asc' for ascending, 'desc' for descending.
 * @returns {T[]} The sorted array. If an error occurs during sorting, the original array is returned.
 */
export const naturalSort = <T>(
  array: T[],
  key: keyof T,
  direction: 'asc' | 'desc'
): T[] => {
  try {
    const sorted = array.sort((a, b) => {
      const valueA = a[key] != null ? String(a[key]) : '';
      const valueB = b[key] != null ? String(b[key]) : '';

      if (!valueA && !valueB) return 0;
      if (!valueA) return direction === 'asc' ? -1 : 1;
      if (!valueB) return direction === 'asc' ? 1 : -1;

      const comparison = valueA.localeCompare(
        valueB,
        navigator.languages[0] || navigator.language || 'en-US',
        {
          numeric: true,
          ignorePunctuation: true,
        }
      );

      return direction === 'asc' ? comparison : -comparison;
    });
    return sorted;
  } catch (error) {
    return array;
  }
};

export const uploadedBySortKeyGenerator = <T extends { uploadedBy?: User }>(
  object: T
): string => {
  if (object.uploadedBy && object.uploadedBy.data) {
    return `${get(object, 'uploadedBy.data.firstName', '')} ${get(
      object,
      'uploadedBy.data.lastName',
      ''
    )}`;
  }
  return '';
};

export const programOwnerSortKeyGenerator = (
  object: Pick<Program, 'programOwners'>
): string => {
  if (object.programOwners.length > 0) {
    return `${get(object, 'programOwners.0.data.firstName', '')} ${get(
      object,
      'programOwners.0.data.lastName',
      ''
    )}`;
  }
  return '';
};

export const businessTeamSortKeyGenerator = (
  project: Pick<Project, 'businessTeams'>
): string => {
  const businessTeams = get(project, 'businessTeams', []);
  if (businessTeams.length > 0) {
    return businessTeams[0].title;
  }
  return '';
};

type OwnerUnion = ProjectOwner | LDUser;
export const ownerSortKeyGenerator = <T extends { owners?: OwnerUnion[] }>(
  object: T
): string => {
  if (object.owners && object.owners.length > 0) {
    return `${get(object, 'owners.0.data.firstName', '')} ${get(
      object,
      'owners.0.data.lastName',
      ''
    )}`;
  }
  return '';
};

const generateSortKey = <T>(
  item: T,
  sortKeyGenerator: (item: T) => string
): string => {
  return sortKeyGenerator(item);
};

export const sortItemsByKey = <T>(
  items: T[],
  sortKeyGenerator: (item: T) => string,
  order: 'asc' | 'desc'
): T[] => {
  const itemsWithSortKey = items.map((item) => ({
    ...item,
    sortKey: generateSortKey(item, sortKeyGenerator),
  }));

  const sortedItems = naturalSort(itemsWithSortKey, 'sortKey', order);

  return sortedItems.map(({ sortKey, ...rest }) => rest as unknown as T);
};
