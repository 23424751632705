import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { SLICE_STATUS } from 'utils/constants';
import capacityAPI from './capacityAPI';
import { Status, InsightsCapacityActiveFilter } from 'utils/customTypes';
import moment from 'moment';
import { DATE } from 'utils/constants';
import {
  AggregatesNumbersType,
  DCATrendValue,
  TeamUtilizationType,
  ProjectCategoriesDemandType,
  ResourceTypeAllocationType,
  BusinessTeamDemandType,
  TaskTypeEstimationType,
  ResourceUtilizationType,
} from './types.d';

interface CapacityInsights {
  aggregates: {
    value: AggregatesNumbersType;
    status: Status;
  };
  dcaTrend: {
    value: Array<DCATrendValue> | null;
    status: Status;
  };
  teamsUtilization: {
    value: Array<TeamUtilizationType> | null;
    status: Status;
  };
  projectCategoriesDemand: {
    value: Array<ProjectCategoriesDemandType> | null;
    status: Status;
  };
  resourceTypeAllocation: {
    value: Array<ResourceTypeAllocationType> | null;
    status: Status;
  };
  businessTeamsDemand: {
    value: Array<BusinessTeamDemandType> | null;
    status: Status;
  };
  resourceUtilization: {
    mostUtilized: Array<ResourceUtilizationType> | null;
    leastUtilized: Array<ResourceUtilizationType> | null;
    status: Status;
  };
  activeFilters: InsightsCapacityActiveFilter;
  taskTypeEstimation: {
    value: Array<TaskTypeEstimationType> | null;
    status: Status;
  };
}

/* ============================= INITIAL STATE ============================== */
const initialState: CapacityInsights = {
  aggregates: {
    status: SLICE_STATUS.IDLE,
    value: {
      numberOfResources: '0',
      totalDemandHours: '0',
      totalActualHours: '0',
      totalCapacityHours: '0',
      totalAvailableHours: '0',
      totalAllocatedHours: '0',
    },
  },
  dcaTrend: {
    value: null,
    status: SLICE_STATUS.IDLE,
  },
  teamsUtilization: {
    value: null,
    status: SLICE_STATUS.IDLE,
  },
  projectCategoriesDemand: {
    value: null,
    status: SLICE_STATUS.IDLE,
  },
  resourceTypeAllocation: {
    value: null,
    status: SLICE_STATUS.IDLE,
  },
  businessTeamsDemand: {
    value: null,
    status: SLICE_STATUS.IDLE,
  },
  activeFilters: {
    startDate: '',
    endDate: '',
    businessUnitIds: [],
  },
  taskTypeEstimation: {
    value: null,
    status: SLICE_STATUS.IDLE,
  },
  resourceUtilization: {
    mostUtilized: null,
    leastUtilized: null,
    status: SLICE_STATUS.IDLE,
  },
};

/* ============================== REDUX THUNK =============================== */
export const fetchAggregates = createAsyncThunk(
  'capacityInsights/FETCH_AGGREGATES',
  async (filters: InsightsCapacityActiveFilter) => {
    const { data } = await capacityAPI.getCapacityAggregates(filters);
    const formatter = Intl.NumberFormat('en', { notation: 'compact' });
    const value = {
      numberOfResources: formatter.format(
        data.capacityAggregates.resource_count
      ),
      totalDemandHours: formatter.format(data.taskAggregates.total_demand),
      totalActualHours: formatter.format(
        data.taskAggregates.total_actual_hours
      ),
      totalCapacityHours: formatter.format(
        data.capacityAggregates.total_capacity
      ),
      totalAvailableHours: formatter.format(
        data.capacityAggregates.total_available_hours
      ),
      totalAllocatedHours: formatter.format(
        data.taskAggregates.total_allocated_hours
      ),
    };

    return value;
  }
);

export const fetchDCATrend = createAsyncThunk(
  'capacityInsights/FETCH_DCA_TREND',
  async (filters: InsightsCapacityActiveFilter) => {
    const { data } = await capacityAPI.getDCATrends(filters);
    if (!data.dcaTrend) {
      return null;
    }
    const trendData = data.dcaTrend.map(
      (item: {
        dd: string;
        total_availability: number;
        total_capacity: number;
        total_demand: number;
      }) => ({
        name: moment(item.dd).format(DATE.SHORT_FORMAT),
        Availability: item.total_availability,
        Capacity: item.total_capacity,
        Demand: item.total_demand,
      })
    );
    return trendData;
  }
);

export const fetchTeamsUtilization = createAsyncThunk(
  'capacityInsights/FETCH_TEAMS_UTILIZATION',
  async (filters: InsightsCapacityActiveFilter) => {
    const { data } = await capacityAPI.getTeamsUtilization(filters);
    if (!data.teamsUtilization) {
      return null;
    }
    const utilizationData = data.teamsUtilization.reduce(
      (
        teamsWithAllocationsOrCapacity: Array<TeamUtilizationType>,
        item: {
          team_name: string;
          total_capacity: number;
          total_allocation: number;
          percentage_utilization: number;
        }
      ) => {
        if (item.total_allocation !== 0 || item.total_capacity !== 0) {
          teamsWithAllocationsOrCapacity.push({
            teamName: item.team_name,
            allocation: item.total_allocation,
            capacity: item.total_capacity,
            utilization: item.percentage_utilization,
          });
        }
        return teamsWithAllocationsOrCapacity;
      },
      []
    );

    return utilizationData;
  }
);

export const fetchDemandByProject = createAsyncThunk(
  'capacityInsights/FETCH_DEMAND_BY_PROJECT',
  async (filters: InsightsCapacityActiveFilter) => {
    const { data } = await capacityAPI.getDemandByProject(filters);
    if (!data.projectCategoryDemand) {
      return null;
    }

    const demandByProjectData = data.projectCategoryDemand.reduce(
      (
        teamsWithDemand: Array<ProjectCategoriesDemandType>,
        item: { project_category: string; total_demand: number }
      ) => {
        if (item.total_demand) {
          teamsWithDemand.push({
            projectCategory: item.project_category,
            totalDemand: item.total_demand,
          });
        }
        return teamsWithDemand;
      },
      []
    );

    return demandByProjectData;
  }
);

export const fetchResourceTypeAllocation = createAsyncThunk(
  'capacityInsights/FETCH_RESOURCE_TYPE_ALLOCATION',
  async (filters: InsightsCapacityActiveFilter) => {
    const { data } = await capacityAPI.getAllocationByResourceType(filters);
    if (!data.resourceTypeAllocation) {
      return null;
    }

    const resourceTypeAllocationData = data.resourceTypeAllocation.reduce(
      (
        resourcesWithAllocation: Array<ResourceTypeAllocationType>,
        item: { resource_type: string; total_allocation: number }
      ) => {
        if (item.total_allocation) {
          resourcesWithAllocation.push({
            resourceType: item.resource_type,
            allocation: item.total_allocation,
          });
        }
        return resourcesWithAllocation;
      },
      []
    );
    return resourceTypeAllocationData;
  }
);

export const fetchBusinessTeamsDemand = createAsyncThunk(
  'capacityInsights/FETCH_BUSINESS_TEAMS_DEMAND',
  async (filters: InsightsCapacityActiveFilter) => {
    const { data } = await capacityAPI.getBusinessTeamsDemand(filters);
    if (!data.businessTeamDemand) {
      return null;
    }

    const demandData = data.businessTeamDemand.reduce(
      (
        teamsWithAllocationRate: Array<BusinessTeamDemandType>,
        item: {
          business_team_name: string;
          total_demand: number;
          total_allocation: number;
          allocation_rate: number;
        }
      ) => {
        if (item.allocation_rate !== 0) {
          teamsWithAllocationRate.push({
            teamName: item.business_team_name,
            demand: item.total_demand,
            allocation: item.total_allocation,
            allocationRate: item.allocation_rate,
          });
        }
        return teamsWithAllocationRate;
      },
      []
    );

    return demandData;
  }
);

export const fetchResourceUtilization = createAsyncThunk(
  'capacityInsights/FETCH_RESOURCE_UTILIZATION',
  async ({
    filters,
    sort,
  }: {
    filters: InsightsCapacityActiveFilter;
    sort: string;
  }) => {
    const { data } = await capacityAPI.getUtilizationByResources(filters, sort);
    if (!data.resourceUtilization) {
      return null;
    }

    const resourceUtilization = data.resourceUtilization.map(
      (item: {
        first_name: string;
        last_name: string;
        job_title: string;
        percentage_utilization: number;
      }) => ({
        name: `${item.first_name} ${item.last_name}`,
        jobTitle: item.job_title,
        utilization: item.percentage_utilization,
      })
    );
    return { resourceUtilization, sort };
  }
);

export const setActiveFilters = createAction<InsightsCapacityActiveFilter>(
  'capacity/SET_ACTIVE_FILTERS'
);

export const fetchTaskTypeEstimation = createAsyncThunk(
  'capacityInsights/FETCH_TASK_TYPE_ESTIMATION',
  async (filters: InsightsCapacityActiveFilter) => {
    const { data } = await capacityAPI.getTaskTypeEstimation(filters);
    if (!data.taskTypeEstimation) {
      return null;
    }

    const estimationData = data.taskTypeEstimation.reduce(
      (
        tasksWithEstimation: Array<TaskTypeEstimationType>,
        item: {
          task_type: string;
          median_estimated_hours: number;
          median_actual_hours: number;
        }
      ) => {
        if (
          item.median_estimated_hours !== 0 &&
          item.median_actual_hours !== 0
        ) {
          tasksWithEstimation.push({
            taskType: item.task_type,
            medianEstimatedHours: item.median_estimated_hours,
            medianActualHours: item.median_actual_hours,
          });
        }
        return tasksWithEstimation;
      },
      []
    );

    return estimationData;
  }
);

/* ================================= REDUCER ================================ */
const capacitySlice = createSlice({
  name: 'capacityInsights',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAggregates.pending, (state) => {
        state.aggregates.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchAggregates.rejected, (state) => {
        state.aggregates.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchAggregates.fulfilled, (state, action) => {
        state.aggregates.status = SLICE_STATUS.IDLE;
        state.aggregates.value = action.payload;
      })
      .addCase(fetchDCATrend.pending, (state) => {
        state.dcaTrend.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchDCATrend.rejected, (state) => {
        state.dcaTrend.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchDCATrend.fulfilled, (state, action) => {
        state.dcaTrend.value = action.payload;
        state.dcaTrend.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchTeamsUtilization.pending, (state) => {
        state.teamsUtilization.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchTeamsUtilization.rejected, (state) => {
        state.teamsUtilization.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchTeamsUtilization.fulfilled, (state, action) => {
        state.teamsUtilization.value = action.payload;
        state.teamsUtilization.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchDemandByProject.pending, (state) => {
        state.projectCategoriesDemand.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchDemandByProject.rejected, (state) => {
        state.projectCategoriesDemand.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchDemandByProject.fulfilled, (state, action) => {
        state.projectCategoriesDemand.value = action.payload;
        state.projectCategoriesDemand.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchResourceTypeAllocation.pending, (state) => {
        state.resourceTypeAllocation.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchResourceTypeAllocation.rejected, (state) => {
        state.resourceTypeAllocation.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchResourceTypeAllocation.fulfilled, (state, action) => {
        state.resourceTypeAllocation.value = action.payload;
        state.resourceTypeAllocation.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchBusinessTeamsDemand.pending, (state) => {
        state.businessTeamsDemand.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchBusinessTeamsDemand.rejected, (state) => {
        state.businessTeamsDemand.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchBusinessTeamsDemand.fulfilled, (state, action) => {
        state.businessTeamsDemand.value = action.payload;
        state.businessTeamsDemand.status = SLICE_STATUS.IDLE;
      })
      .addCase(setActiveFilters, (state, action) => {
        state.activeFilters = action.payload;
      })
      .addCase(fetchTaskTypeEstimation.pending, (state) => {
        state.taskTypeEstimation.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchTaskTypeEstimation.rejected, (state) => {
        state.taskTypeEstimation.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchTaskTypeEstimation.fulfilled, (state, action) => {
        state.taskTypeEstimation.value = action.payload;
        state.taskTypeEstimation.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchResourceUtilization.pending, (state) => {
        state.resourceUtilization.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchResourceUtilization.rejected, (state) => {
        state.resourceUtilization.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchResourceUtilization.fulfilled, (state, action) => {
        if (action.payload?.sort === 'desc') {
          state.resourceUtilization.mostUtilized =
            action.payload?.resourceUtilization;
        } else {
          state.resourceUtilization.leastUtilized =
            action.payload?.resourceUtilization;
        }
        state.resourceUtilization.status = SLICE_STATUS.IDLE;
      });
  },
});

/* =============================== SELECTORS ================================ */
export const selectAggregates = (state: RootState) =>
  state.capacityInsights.aggregates.value;

export const selectDCATrend = (state: RootState) =>
  state.capacityInsights.dcaTrend.value;

export const selectTeamsUtilization = (state: RootState) =>
  state.capacityInsights.teamsUtilization.value;

export const selectProjectCategoriesDemand = (state: RootState) =>
  state.capacityInsights.projectCategoriesDemand.value;

export const selectAggregatesStatus = (state: RootState) =>
  state.capacityInsights.aggregates.status;

export const selectDCATrendStatus = (state: RootState) =>
  state.capacityInsights.dcaTrend.status;

export const selectTeamUtilizationStatus = (state: RootState) =>
  state.capacityInsights.teamsUtilization.status;

export const selectProjectCategoriesDemandStatus = (state: RootState) =>
  state.capacityInsights.projectCategoriesDemand.status;

export const selectOverallUtilization = (state: RootState) => {
  const teamsAllocationTotal =
    state.capacityInsights.teamsUtilization.value?.reduce(
      (acc, item) => acc + item.allocation,
      0
    );
  const teamsCapacityTotal =
    state.capacityInsights.teamsUtilization.value?.reduce(
      (acc, item) => acc + item.capacity,
      0
    );

  const overallUtilization = teamsCapacityTotal
    ? (teamsAllocationTotal! / teamsCapacityTotal!) * 100
    : 0;

  return overallUtilization ? overallUtilization.toFixed(2) : 0;
};

export const selectAllocationByResourceType = (state: RootState) =>
  state.capacityInsights.resourceTypeAllocation.value;

export const selectAllocationByResourceTypeStatus = (state: RootState) =>
  state.capacityInsights.resourceTypeAllocation.status;

export const selectBusinessTeamsDemand = (state: RootState) =>
  state.capacityInsights.businessTeamsDemand.value;

export const selectBusinessTeamsDemandStatus = (state: RootState) =>
  state.capacityInsights.businessTeamsDemand.status;

export const selectOverallAllocationRate = (state: RootState) => {
  const teamsAllocationTotal =
    state.capacityInsights.businessTeamsDemand.value?.reduce(
      (acc, item) => acc + item.allocation,
      0
    );
  const teamsDemandTotal =
    state.capacityInsights.businessTeamsDemand.value?.reduce(
      (acc, item) => acc + item.demand,
      0
    );

  const overallAllocation = teamsDemandTotal
    ? (teamsAllocationTotal! / teamsDemandTotal!) * 100
    : 0;

  return overallAllocation ? overallAllocation.toFixed(2) : 0;
};

export const getFilters = (state: RootState) =>
  state.capacityInsights.activeFilters;

export const selectTaskTypeEstimation = (state: RootState) =>
  state.capacityInsights.taskTypeEstimation.value;

export const selectTaskTypeEstimationStatus = (state: RootState) =>
  state.capacityInsights.taskTypeEstimation.status;

export const selectMostUtilizedResourceUtilization = (state: RootState) =>
  state.capacityInsights.resourceUtilization.mostUtilized;

export const selectLeastUtilizedResourceUtilization = (state: RootState) =>
  state.capacityInsights.resourceUtilization.leastUtilized;

export const selectResourceUtilizationStatus = (state: RootState) =>
  state.capacityInsights.resourceUtilization.status;

export default capacitySlice.reducer;
