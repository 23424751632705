interface BaseEntity {
  id: string;
}

export const normalizeState = <T extends BaseEntity>(
  entities: T[]
): { ids: string[]; entities: { [id: string]: T } } => {
  const ids = entities.map((entity) => entity.id);
  const normalizedEntities = entities.reduce(
    (acc, entity) => ({ ...acc, [entity.id]: entity }),
    {}
  );

  return { ids, entities: normalizedEntities };
};
