import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import {
  tailwindOverride,
  Typography,
  Avatar,
  Button,
} from '@getsynapse/design-system';
import { RootState } from 'state/store';
import { selectTeamMemberById } from 'state/Capacities/TeamMembersList/teamMembersListSlice';
import { toggleTeamMemberProjectsList } from 'state/Capacities/TeamMemberProjectsList/teamMemberProjectsListSlice';
import { getInitials } from 'utils/functions';
import TeamManagerPill from './TeamManagerPill';

const MemberData: React.FC<{
  teamId: string;
  memberId: string;
  isExpanded: boolean;
}> = ({ teamId, memberId, isExpanded = false }) => {
  const previousExpandedStatus = useRef<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const dispatch = useDispatch();
  const teamMember = useSelector((state: RootState) =>
    selectTeamMemberById(state, memberId)
  );
  useEffect(() => {
    if (isExpanded !== previousExpandedStatus.current) {
      previousExpandedStatus.current = isExpanded;
      setExpanded(isExpanded);
    }
  }, [isExpanded]);
  if (!teamMember) {
    return null;
  }
  const toggleExpandedMemberStatus = () => {
    dispatch(
      toggleTeamMemberProjectsList({
        teamId,
        memberId,
      })
    );
    previousExpandedStatus.current = !expanded;
    setExpanded((prev) => !prev);
  };
  const maxRoles = teamMember.isTeamManager ? 2 : 3;

  return (
    <div className='w-2/6 h-full flex'>
      <div className='w-10 h-full' />
      <div
        className={tailwindOverride('w-2/3 h-full flex', 'px-2 py-4 gap-x-2')}
      >
        <div className='sm:hidden md:hidden lg:hidden xl:block'>
          <Avatar
            aria-label={`member-${memberId}__avatar`}
            imageSrc={teamMember.avatarUrl}
            initial={getInitials({
              firstName: teamMember.firstName,
              lastName: teamMember.lastName,
            })}
          />
        </div>
        <div className={tailwindOverride('flex flex-1 flex-col', 'truncate')}>
          <Typography
            variant='label'
            weight='medium'
            className='text-primary truncate'
            title={`${teamMember.firstName} ${teamMember.lastName}`}
          >
            {`${teamMember.firstName} ${teamMember.lastName}`}
          </Typography>
          <Typography
            variant='label'
            className='text-neutral truncate'
            title={teamMember.email}
          >
            {teamMember.email}
          </Typography>
          {teamMember.hasProjects && (
            <Button
              variant='tertiary'
              className='text-sm leading-6 text-primary mt-2 self-start px-2'
              size='small'
              onClick={toggleExpandedMemberStatus}
            >
              {expanded && intl.get('TEAMS_CAPACITY.HIDE_PROJECTS')}
              {!expanded && intl.get('TEAMS_CAPACITY.VIEW_PROJECTS')}
            </Button>
          )}
        </div>
      </div>
      <div
        className={tailwindOverride(
          'w-1/3 h-full',
          'flex-col items-start',
          'px-2 pt-5 pb-4',
          'overflow-hidden'
        )}
      >
        {teamMember.isTeamManager && <TeamManagerPill />}
        {teamMember.roles.slice(0, maxRoles).map((role, index) => (
          <Typography
            key={`team-${teamId}__member-${memberId}__role-${index}`}
            variant='caption'
            className='text-primary break-all'
          >
            {role}
          </Typography>
        ))}
        {teamMember.roles.length > maxRoles && (
          <Typography variant='caption' className='text-primary'>
            {`+${teamMember.roles.length - maxRoles}`}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default MemberData;
