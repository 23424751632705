import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { selectPagination } from 'state/TasksAssignmentsResourcesCapacity/tasksAssignmentsResourcesCapacitySlice';
import { getWeek } from 'Organisms/CapacityAllocationTable/helpers';

const useWeeksSlider = () => {
  const { from: startDate, to: endDate } = useSelector(selectPagination);

  const getWeeksArray = (startDate: string, endDate: string) => {
    const weeksArray = [];
    const start = moment(new Date(startDate.replace(/-/g, '/')));
    const end = moment(new Date(endDate.replace(/-/g, '/')));
    let pivotWeek = start;
    while (moment(pivotWeek).isSameOrBefore(end)) {
      const week = getWeek(pivotWeek);
      weeksArray.push(week);
      pivotWeek = pivotWeek.add(7, 'days');
    }
    return weeksArray;
  };

  const weeksArray = useMemo(() => {
    const weeks = [];
    if (startDate && endDate) {
      weeks.push(...getWeeksArray(startDate, endDate));
    }
    return weeks;
  }, [startDate, endDate]);

  return { weeksArray };
};

export default useWeeksSlider;
