import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Allocation as AllocationType,
  ProjectLevelAllocation,
} from 'utils/types/tasksAssignmentsResourcesCapacity';
import {
  CAPACITY_TABLE_NUMBER_OF_WEEKS,
  PROJECT_PARTICIPANT_TYPE,
  ParticipantTypes,
} from 'utils/constants';
import { selectPagination } from 'state/TasksAssignmentsResourcesCapacity/tasksAssignmentsResourcesCapacitySlice';
import { selectUserId } from 'state/User/userSlice';
import { useUserPermissionsContext } from 'Pages/ProjectPage/context/UserPermissionsContext';
import Allocation from './Allocation/Allocation';
import Timeline from '../../Timeline/Timeline';
import ActionsMenu from './ActionsMenu/ActionsMenu';

const AllocationsTimeline: React.FC<{
  userId: string;
  allocations: AllocationType<ProjectLevelAllocation>;
  participantType: ParticipantTypes;
  className?: string;
}> = ({ userId, allocations, participantType, className = '' }) => {
  const { index: firstVisibleAllocationIndex } = useSelector(selectPagination);
  const { hasUserPermissionsLevelOf } = useUserPermissionsContext();
  const currentUserId = useSelector(selectUserId);
  const isCurrentUserOwner = hasUserPermissionsLevelOf(
    PROJECT_PARTICIPANT_TYPE.OWNER
  );
  const isCurrentUserMember = hasUserPermissionsLevelOf(
    PROJECT_PARTICIPANT_TYPE.MEMBER
  );
  const canCurrentUserRemoveParticipants =
    isCurrentUserOwner || (isCurrentUserMember && currentUserId === userId);

  const currentWeekStart = moment().startOf('isoWeek');
  const lastVisibleAllocationIndex =
    firstVisibleAllocationIndex + CAPACITY_TABLE_NUMBER_OF_WEEKS - 1;
  return (
    <Timeline
      id={`participant-${userId}`}
      displayActionsMenu={
        participantType !== ParticipantTypes.OWNERS &&
        canCurrentUserRemoveParticipants
      }
      actionsMenu={<ActionsMenu userId={userId} />}
      className={className}
    >
      {Object.keys(allocations).map((week, index) => {
        const allocation = allocations[week];
        const iscurrentWeek = currentWeekStart.isSame(
          new Date(week.replace(/-/g, '/'))
        );
        const key = `participant-${userId}__allocation-${new Date(
          week
        ).valueOf()}`;
        return (
          <Allocation
            key={key}
            allocation={allocation}
            isCurrentWeek={iscurrentWeek}
            participantType={participantType}
            aria-hidden={
              index < firstVisibleAllocationIndex ||
              index > lastVisibleAllocationIndex
            }
            data-cy={key}
          />
        );
      })}
    </Timeline>
  );
};

export default AllocationsTimeline;
