import { RadioGroup } from '@getsynapse/design-system';
import { ChangeEvent, useMemo } from 'react';
import { ALLOWED_FIELD_TYPES } from 'utils/constants';
import { RadioFieldTemplateType, RadioFieldType } from 'utils/types/fields';

interface RadioFieldProps {
  field?: RadioFieldType;
  fieldTemplate: RadioFieldTemplateType;
  onChange: (newValue: RadioFieldType['value']) => void;
  disabled: boolean;
}

const RadioField = ({
  field,
  fieldTemplate,
  onChange,
  disabled,
}: RadioFieldProps) => {
  const isBoolean: boolean = fieldTemplate.type === ALLOWED_FIELD_TYPES.BOOLEAN;

  const options = useMemo(
    () =>
      fieldTemplate.allowed_values.map((option) => {
        let value: string | number = option.val! as string | number;
        if (isBoolean) {
          value = option.val!.toString();
        }

        return { label: option.display || option.val!.toString(), value };
      }),
    [fieldTemplate.allowed_values, isBoolean]
  );

  const defaultValue: string | number | undefined = useMemo(() => {
    let value: string | number | undefined;
    if (field) {
      if (isBoolean) {
        value = field.value.val?.toString();
      } else {
        value = (field.value.val as string | number) || undefined;
      }
    } else if (fieldTemplate.default_value) {
      if (isBoolean) {
        value = fieldTemplate.default_value.val?.toString();
      } else {
        value =
          (fieldTemplate.default_value.val as string | number) || undefined;
      }
    }
    value = value?.toString();

    return value;
  }, [field, fieldTemplate.default_value, isBoolean]);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    let value: string | number | boolean = event.target.value;
    if (isBoolean) {
      value = Boolean(value === 'true');
    } else if (!isNaN(Number(value))) {
      value = Number(value);
    }

    const newValue = fieldTemplate.allowed_values.find((option) => {
      let compareTo = option.val;
      if (!isBoolean && !isNaN(Number(compareTo))) {
        compareTo = Number(compareTo);
      }
      return compareTo === value;
    })!;
    onChange(newValue);
  };

  return (
    <RadioGroup
      checked={defaultValue}
      checkOption='defaultChecked'
      disabled={disabled}
      inputProps={{ onChange: onChangeHandler }}
      name={`radio-field-${fieldTemplate.id}`}
      options={options}
    />
  );
};

export default RadioField;
