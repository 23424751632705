import { TaskState } from 'types/store/taskDetail';
import TaskName from './fields/TaskName';
import TaskDescription from './fields/TaskDescription';
import ProjectLink from './fields/TaskProjectLink';
import TaskAssigneesField from './fields/TaskAssignee';
import TaskDatesField from './fields/TaskDates';
import { rangeDate } from 'utils/customTypes';
import TaskTypeField from './fields/TaskType';
import TaskStatusField from './fields/TaskStatus';
import TaskActualCompletionDateField from './fields/TaskActualHourCompletionDate';
import TaskEstimatedTimeField from './fields/TaskEstimateHours';
import TaskActualHoursField from './fields/TaskActualHours';
import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import TaskActualHoursModal from '../ActualHoursModal/ActualHoursModal';
import { useState } from 'react';

interface TaskDetailFormProps {
  task: TaskState;
}

const TaskDetailForm = ({ task }: TaskDetailFormProps) => {
  const handleDescriptionChange = (newDesc: string) => {};
  const handleDateChange = (range: rangeDate) => {};
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <TaskActualHoursModal
        taskId={task.id}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
      <div className='mt-4 border-b border-neutral-lighter pb-10'>
        <Typography variant='h5'>
          {intl.get('TASKS.TASK_DETAIL_PAGE.TITLE')}
        </Typography>
        <div className='grid-cols-2 w-full grid gap-x-10% gap-y-4 px-px mt-8'>
          <TaskName name={task.name} onNameChange={() => {}} />
          <ProjectLink
            projectId={task.project.id}
            projectTitle={task.project.title}
          />
          <TaskDescription
            description={task.description}
            isReadOnly={false}
            isViewOnly={false}
            onDescriptionChange={handleDescriptionChange}
          />
          <TaskAssigneesField
            isReadOnly={false}
            isViewOnly={false}
            taskId={task.id}
          />
          <TaskDatesField
            isReadOnly={false}
            isViewOnly={false}
            hasAssignedUsers={task.assignees.length > 0}
            startDate={new Date(task.startDate)}
            dueDate={new Date(task.endDate)}
            onDateChange={handleDateChange}
          />
          <TaskTypeField onChangeType={() => {}} />
          <div className='flex'>
            <TaskStatusField />
            <TaskActualCompletionDateField />
          </div>
          <TaskEstimatedTimeField
            estimateHours='0'
            onEstimateHoursChange={() => {}}
          />
          <TaskActualHoursField setOpenModal={setIsModalOpen} />
        </div>
      </div>
    </>
  );
};

export default TaskDetailForm;
