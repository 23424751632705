import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
  getWeekRangesForDate,
  getWeekRangeForDate,
} from 'Pages/TeamsPage/views/TeamsCapacity/components/WeeklyCapacity/helpers';
import { resetCapacitiesState } from '../../Capacities/TeamsList/teamsListSlice';
import { DateSegment, getDateRange, toSegmentString } from './helpers';

import { createAction } from '@reduxjs/toolkit';
import { RootState } from 'state/store';

/* ============================= ACTIONS ============================== */
export const initWeeksArray = createAction<{
  startDate: string;
}>('weeks/INIT_WEEKS_ARRAY');
export const moveToPreviousWeek = createAction('weeks/MOVE_TO_PREVIOUS_WEEK');
export const moveToNextWeek = createAction('weeks/MOVE_TO_NEXT_WEEK');
export const setNumberOfWeeks = createAction<number>(
  'weeks/SET_NUMBER_OF_WEEKS'
);

/* ================================= SELECTOR ================================ */
export const selectWeekItems = (state: RootState) =>
  state.weeklyTeamsCapacity.weeks.items;

export const selectNumberOfWeeks = (state: RootState) =>
  state.weeklyTeamsCapacity.weeks.numberOfWeeks;

export const selectWeeks = createSelector(
  [selectWeekItems, selectNumberOfWeeks],
  (items: DateSegment[], numberOfWeeks: number) => {
    if (!numberOfWeeks) {
      numberOfWeeks = items.length;
    }
    return items.slice(0, numberOfWeeks);
  }
);

export const selectDatesRange = createSelector(
  [selectWeekItems, selectNumberOfWeeks],
  (items: DateSegment[], numberOfWeeks: number) => {
    return getDateRange(items, numberOfWeeks);
  }
);

/* ============================= INITIAL STATE ============================== */
interface Weeks {
  startDate: string;
  items: DateSegment[];
  numberOfWeeks: number;
}

const initialState: Weeks = {
  startDate: '',
  items: [],
  numberOfWeeks: 6,
};

/* ================================= REDUCER ================================ */
const weeksSlice = createSlice({
  name: 'weeks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initWeeksArray, (state, action) => {
        if (!action.payload) {
          return;
        }

        state.startDate = action.payload.startDate.replaceAll('-', '/');
        state.items = getWeekRangesForDate(
          state.startDate,
          state.numberOfWeeks
        ).map(toSegmentString);
      })
      .addCase(setNumberOfWeeks, (state, action) => {
        if (!action.payload) {
          return;
        }
        state.numberOfWeeks = action.payload;
      })
      .addCase(moveToPreviousWeek, (state) => {
        const { startDate } = getDateRange(state.items, state.numberOfWeeks);

        const newStartDate = new Date(startDate.replaceAll('-', '/'));
        newStartDate.setDate(newStartDate.getDate() - 7);

        const segment = getWeekRangeForDate(newStartDate);

        const newItems = [...state.items];
        // Add the segmenet to the beginning
        newItems.unshift(toSegmentString(segment));
        newItems.pop();

        state.items = newItems;
      })
      .addCase(moveToNextWeek, (state) => {
        const { endDate } = getDateRange(state.items, state.numberOfWeeks);

        const newStartDate = new Date(endDate.replaceAll('-', '/'));
        newStartDate.setDate(newStartDate.getDate() + 3);

        const segment = getWeekRangeForDate(newStartDate);

        let newItems = [...state.items];
        // Add the segmenet to the beginning
        newItems.push(toSegmentString(segment));
        newItems.shift();

        state.items = newItems;
      })
      .addCase(resetCapacitiesState, (state) => {
        state.startDate = initialState.startDate;
        state.items = initialState.items;
        state.numberOfWeeks = initialState.numberOfWeeks;
      });
  },
});

export default weeksSlice.reducer;
