import Skelton from 'Atoms/Skelton/Skelton';

export const FiltersPanelSkeletonLoader = () => (
  <div className='h-full w-full flex-col relative p-4'>
    <Skelton className='h-4 w-1/3' />
    <Skelton className='h-4 w-1/4 mt-12' />
    <Skelton className='h-4 w-2/3 mt-4' />
    <Skelton className='h-4 w-1/4 mt-12' />
    <Skelton className='h-4 w-full mt-4' />
    <Skelton className='h-4 w-full mt-4' />
    <Skelton className='h-4 w-full mt-4' />
    <Skelton className='h-4 w-full mt-4' />
    <Skelton className='h-4 w-1/4 mt-12' />
    <Skelton className='h-4 w-full mt-4' />
    <Skelton className='h-4 w-full mt-4' />
    <Skelton className='h-4 w-1/4 mt-12' />
    <Skelton className='h-4 w-full mt-4' />
    <Skelton className='h-4 w-full mt-4' />
  </div>
);

export const FiltersPanelButtonsSkeletonLoader = () => (
  <div className='w-full h-full flex items-center justify-end py-2'>
    <Skelton className='h-4 w-20' />
    <Skelton className='h-4 w-20 mx-4' />
  </div>
);
