import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { X_Y_AXIS_STYLE } from './constants';
import { getLineElements, getNoDataMessage } from './Elements';
import CustomTooltip from '../../Components/CustomToolTip';
import { SLICE_STATUS } from 'utils/constants';
import ErrorScreen from '../../Components/ErrorScreen';
import { RequestsBy } from 'utils/customTypes';
import RequestsBySkeletonLoading from './RequestsBySkeletonLoader';

const TypeLineChart = ({
  status,
  state,
}: {
  status: string;
  state: RequestsBy;
}) => {
  const renderColorfulLegendText = (value: string, entry: any) => {
    return (
      <span style={{ ...X_Y_AXIS_STYLE, ...{ paddingRight: '1rem' } }}>
        {value}
      </span>
    );
  };

  return status === SLICE_STATUS.LOADING ? (
    <RequestsBySkeletonLoading />
  ) : status === SLICE_STATUS.FAILED ? (
    <ErrorScreen />
  ) : state.values.length === 0 ? (
    getNoDataMessage()
  ) : (
    <ResponsiveContainer width='90%' height='90%'>
      <LineChart data={state.values || []}>
        <CartesianGrid horizontal={false} vertical={false} />
        <XAxis
          tickLine={false}
          dataKey='monthShortLabel'
          style={X_Y_AXIS_STYLE}
          padding={{ left: 30, right: 30 }}
        />
        <YAxis tickLine={false} style={X_Y_AXIS_STYLE} />
        <Tooltip content={<CustomTooltip chartType='Line' />} />
        <Legend
          iconSize={7}
          iconType='circle'
          layout='horizontal'
          align='left'
          wrapperStyle={{ marginLeft: '3rem' }}
          formatter={renderColorfulLegendText}
        />
        {getLineElements(state.seriesNames)}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TypeLineChart;
