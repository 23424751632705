import React, { ReactNode } from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import classnames from 'classnames';
import { IconButton, Typography, Tooltip } from '@getsynapse/design-system';
import get from 'lodash/get';
import { DATE } from 'utils/constants';
import {
  Allocation,
  Assignment,
  AllocatedUserRole,
  Estimation,
  generateGridColumnsArray,
} from '../helpers';
import {
  Week,
  confirmAllocationBetweenWeek,
  confirmAllocationBetweenWeeks,
  confirmAllocationOutOfWeeksRange,
} from '../helpers';

export const TableHeaders: React.FC<{
  currentWeeksSlideArray: (Week | null)[];
  currentWeeksSlide: number;
  onChangeWeeksSlide: (slide: number) => void;
  weeksSlidesNumber: number;
  className?: string;
  displayRemoveButton?: boolean;
}> = ({
  currentWeeksSlideArray,
  currentWeeksSlide,
  onChangeWeeksSlide,
  weeksSlidesNumber,
  className = 'bg-neutral-lighter-two border-b-2 boder-neutral-darker',
  displayRemoveButton = false,
}) => {
  const handleChangeSlide = (direction: 'prev' | 'next') => {
    let nextSlide = currentWeeksSlide;
    if (direction === 'prev' && currentWeeksSlide > 0) {
      nextSlide = currentWeeksSlide - 1;
    }

    if (direction === 'next' && currentWeeksSlide < weeksSlidesNumber) {
      nextSlide = currentWeeksSlide + 1;
    }
    onChangeWeeksSlide(nextSlide);
  };
  return (
    <div className='flex flex-1 h-10'>
      <div className='w-6 h-full flex justify-center items-center'>
        <IconButton
          name='chevron-back'
          className='text-primary text-lg'
          onClick={() => handleChangeSlide('prev')}
          disabled={currentWeeksSlide === 1}
        />
      </div>
      <div className='h-full flex flex-1'>
        {currentWeeksSlideArray.map((week, index: number) => (
          <div
            key={index}
            className={classnames('flex flex-1 items-center justify-center', {
              [className]: get(week, 'isCurrentWeek', false),
            })}
          >
            {week !== null && (
              <Typography
                variant='caption'
                className='font-semibold text-primary'
              >
                {week.label}
              </Typography>
            )}
          </div>
        ))}
      </div>
      <div className='w-6 h-full flex justify-center items-center'>
        <IconButton
          name='chevron-forward'
          className='text-primary text-lg'
          onClick={() => handleChangeSlide('next')}
          disabled={currentWeeksSlide === weeksSlidesNumber}
        />
      </div>
      {displayRemoveButton && <div className='w-12' />}
    </div>
  );
};

const AssignmentAllocation: React.FC<{
  bgColor: string;
  borderColor: string;
  textColor: string;
  name: string;
  time?: string;
  className?: string;
}> = ({ bgColor, borderColor, textColor, name, time, className }) => (
  <div
    className={classnames(
      'h-6 flex items-center border-b-2 justify-between',
      bgColor,
      borderColor,
      className
    )}
  >
    <Typography variant='caption' className={textColor}>
      {name}
    </Typography>
    {time && (
      <Typography variant='caption' className={textColor}>
        {intl.get('PEOPLE.RESOURCE_ALLOCATION.TABLE.ALLOCATION_TIME', {
          allocation: time,
        })}
      </Typography>
    )}
  </div>
);

const EstimationAllocation: React.FC<{
  bgColor: string;
  textColor: string;
  onClickCallback: (id: string) => void;
  label?: string;
  userId: string;
  className?: string;
}> = ({ bgColor, textColor, className, label, userId, onClickCallback }) => {
  return (
    <div
      className={classnames('h-6 flex items-center', bgColor, className)}
      data-cy={`collaborator-${userId}-estimation`}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        onClickCallback(userId);
      }}
    >
      {label && (
        <Typography
          variant='label'
          className={classnames('font-semibold ml-2', textColor)}
        >
          {label}
        </Typography>
      )}
    </div>
  );
};

export const RoleAllocation: React.FC<{
  id: number | string;
  isCurrentWeek: boolean;
  displayContent: boolean;
  content?: ReactNode;
  className?: string;
  currentWeekClassName?: string;
}> = ({
  isCurrentWeek,
  displayContent,
  content,
  className,
  id,
  currentWeekClassName = 'bg-neutral-lighter-two',
  ...otherProps
}) => (
  <div
    key={id}
    className={classnames(className, {
      [currentWeekClassName]: isCurrentWeek,
    })}
    {...otherProps}
  >
    {displayContent ? content : <div className='h-6' />}
  </div>
);

export const completeRoleAllocationsGrid = (
  start: number,
  currentWeeksSlideArray: Week[],
  className?: string,
  id?: string,
  currentWeekClassName?: string
) => {
  const allocationsGrid: ReactNode[] = [];
  for (let i = start; i < currentWeeksSlideArray.length; i++) {
    allocationsGrid.push(
      <RoleAllocation
        key={`${id}-${new Date(
          currentWeeksSlideArray[i].startWeekDate
        ).valueOf()}`}
        id={`${id}-${new Date(
          currentWeeksSlideArray[i].startWeekDate
        ).valueOf()}`}
        displayContent={false}
        isCurrentWeek={currentWeeksSlideArray[i].isCurrentWeek}
        className={className}
        currentWeekClassName={currentWeekClassName}
      />
    );
  }
  return allocationsGrid;
};

export const RoleAllocations: React.FC<{
  userId: string;
  roles: AllocatedUserRole[];
  currentWeeksSlideArray: Week[];
  numberOfWeeks: number;
  timeOffs?: Allocation[];
}> = ({
  userId,
  roles,
  currentWeeksSlideArray,
  numberOfWeeks,
  timeOffs = [],
}) => {
  const getRoleEstimationGrid = (estimation: Estimation, id: string) => {
    const allocationsGrid: ReactNode[] = [];
    const { startDate, endDate } = estimation;
    const gridsColumnsArray = generateGridColumnsArray(
      currentWeeksSlideArray,
      startDate,
      endDate
    );
    let labelDisplayed = false;
    for (let i = 0; i < gridsColumnsArray.length; i++) {
      const shouldDisplayContent = gridsColumnsArray[i];
      const { label, ...otherContentProps } = estimation.content;
      const currentWeek = currentWeeksSlideArray[i];
      const key = currentWeek
        ? `${id}-${new Date(currentWeek.startWeekDate).valueOf()}`
        : `${id}-${new Date().valueOf()}`;
      allocationsGrid.push(
        <RoleAllocation
          key={key}
          id={key}
          displayContent={shouldDisplayContent}
          isCurrentWeek={currentWeek ? currentWeek.isCurrentWeek : false}
          className='col-span-1 py-2'
          data-week-start={
            currentWeek.startWeekDate &&
            new Date(currentWeek.startWeekDate).valueOf()
          }
          data-week-end={
            currentWeek.endWeekDate &&
            new Date(currentWeek.endWeekDate).valueOf()
          }
          content={
            <EstimationAllocation
              {...otherContentProps}
              label={!labelDisplayed ? label : ''}
            />
          }
        />
      );
      if (shouldDisplayContent && !labelDisplayed) {
        labelDisplayed = true;
      }
    }
    if (gridsColumnsArray.length < currentWeeksSlideArray.length) {
      const gridRemain = completeRoleAllocationsGrid(
        gridsColumnsArray.length,
        currentWeeksSlideArray,
        'col-span-1 py-2',
        `estimation-`
      );
      return allocationsGrid.concat(gridRemain);
    }
    return allocationsGrid;
  };

  const getRoleAllocationsGrid = (allocations: Allocation[], id?: string) => {
    let start = 0;
    const allocationsGrid: ReactNode[] = [];
    const isAllocationsOutOfCurrentWeeksRange =
      confirmAllocationOutOfWeeksRange(currentWeeksSlideArray, {
        weekEnd: allocations[0].weekEnd,
        weekStart: allocations[0].weekStart,
      });
    if (isAllocationsOutOfCurrentWeeksRange) {
      return completeRoleAllocationsGrid(
        start,
        currentWeeksSlideArray,
        'flex flex-1 py-2',
        id
      );
    }
    for (let allocation of allocations) {
      const isAllocationBetweenWeeks = confirmAllocationBetweenWeeks(
        currentWeeksSlideArray,
        { weekEnd: allocation.weekEnd, weekStart: allocation.weekStart }
      );
      if (!isAllocationBetweenWeeks) {
        continue;
      }
      if (start === currentWeeksSlideArray.length) {
        break;
      }
      for (let i = start; i < currentWeeksSlideArray.length; i++) {
        const isAllocationBetweenCurrentWeek = confirmAllocationBetweenWeek(
          currentWeeksSlideArray[i],
          allocation
        );
        const currentWeek = currentWeeksSlideArray[i];
        const key = currentWeek
          ? `${id}-${new Date(currentWeek.startWeekDate).valueOf()}`
          : `${id}-${new Date().valueOf()}`;
        if (isAllocationBetweenCurrentWeek) {
          start = i + 1;
          allocationsGrid.push(
            <RoleAllocation
              key={key}
              id={key}
              displayContent
              content={allocation.content}
              isCurrentWeek={currentWeek ? currentWeek.isCurrentWeek : false}
              className='flex flex-1 py-2'
              data-week-start={
                allocation.weekStart && new Date(allocation.weekStart).valueOf()
              }
              data-week-end={
                allocation.weekEnd && new Date(allocation.weekEnd).valueOf()
              }
            />
          );
          break;
        } else {
          allocationsGrid.push(
            <RoleAllocation
              key={key}
              id={key}
              displayContent={false}
              isCurrentWeek={currentWeek ? currentWeek.isCurrentWeek : false}
              className='flex flex-1 py-2'
            />
          );
        }
      }
    }
    if (start < currentWeeksSlideArray.length) {
      const gridRemain = completeRoleAllocationsGrid(
        start,
        currentWeeksSlideArray,
        'flex flex-1 py-2',
        id
      );
      return allocationsGrid.concat(gridRemain);
    }
    return allocationsGrid;
  };

  return (
    <div className='flex h-full'>
      <div className='w-6' />
      <div className='flex flex-1 flex-col h-full'>
        {roles.map(
          (
            { allocations = [], estimation, participationId = '' },
            index: number
          ) => {
            const isUserParticipant =
              allocations && allocations.length > 0 && !estimation;
            return (
              <div
                data-cy={
                  isUserParticipant
                    ? `participant-role-${participationId}`
                    : `collaborator-role-${estimation?.content.userId}`
                }
                key={`role-${index}`}
                className={`grid grid-cols-${numberOfWeeks} h-full`}
              >
                {isUserParticipant
                  ? getRoleAllocationsGrid(allocations, `role-${index}`)
                  : estimation &&
                    getRoleEstimationGrid(estimation, `role-${index}`)}
              </div>
            );
          }
        )}
        {timeOffs.length > 0 && (
          <div
            key={`${userId}-time-offs`}
            className={`grid grid-cols-${numberOfWeeks} h-full`}
          >
            {getRoleAllocationsGrid(timeOffs, 'time-offs')}
          </div>
        )}
      </div>
      <div className='w-6' />
    </div>
  );
};

export const AssignmentsAllocations: React.FC<{
  assignments: Assignment[];
  numberOfWeeks: number;
  currentWeeksSlideArray: Week[];
}> = ({ assignments, numberOfWeeks, currentWeeksSlideArray }) => {
  const getAssigmentsAllocationGrid = (
    assigment: Assignment,
    index: number
  ) => {
    const allocationsGrid: ReactNode[] = [];
    const { startDate, endDate } = assigment;
    const gridsColumnsArray = generateGridColumnsArray(
      currentWeeksSlideArray,
      startDate,
      endDate
    );

    let labelDisplayed = false;
    for (let i = 0; i < gridsColumnsArray.length; i++) {
      const key = currentWeeksSlideArray[i]
        ? `assignment-${index}-${new Date(
            currentWeeksSlideArray[i].startWeekDate
          ).valueOf()}`
        : `assignment-${index}-${new Date().valueOf()}`;

      const shouldDisplayContent = gridsColumnsArray[i];
      const isLastGridItem = i === gridsColumnsArray.length - 1;
      allocationsGrid.push(
        <RoleAllocation
          key={key}
          id={key}
          className='col-span-1 py-2'
          displayContent={shouldDisplayContent}
          isCurrentWeek={currentWeeksSlideArray[i].isCurrentWeek}
          content={
            <AssignmentAllocation
              bgColor={assigment.content.bgColor}
              borderColor={assigment.content.borderColor}
              textColor={assigment.content.textColor}
              name={!labelDisplayed ? assigment.content.name : ''}
              time={
                isLastGridItem && assigment.content.time
                  ? assigment.content.time
                  : ''
              }
              className='px-2'
            />
          }
        />
      );
      if (shouldDisplayContent && !labelDisplayed) {
        labelDisplayed = true;
      }
    }
    if (gridsColumnsArray.length < currentWeeksSlideArray.length) {
      const gridRemain = completeRoleAllocationsGrid(
        gridsColumnsArray.length,
        currentWeeksSlideArray,
        'col-span-1 py-2',
        `assignment-${index}`
      );
      return allocationsGrid.concat(gridRemain);
    }

    return allocationsGrid;
  };
  return (
    <div className='h-full flex flex-1'>
      <div className='w-6' />
      <div className='h-full flex flex-1 flex-col'>
        {assignments.map((assignment: Assignment, index: number) => {
          return (
            <div
              key={`assignment-${index}`}
              className={`grid grid-cols-${numberOfWeeks} h-full relative`}
            >
              {getAssigmentsAllocationGrid(assignment, index)}
            </div>
          );
        })}
      </div>
      <div className='w-6'></div>
    </div>
  );
};

export const TargetCompletionDateHeader: React.FC<{
  currentWeeksSlideArray: Week[];
  targetCompletionDate: Date | string | undefined;
}> = ({ currentWeeksSlideArray, targetCompletionDate }) => {
  const startDate =
    currentWeeksSlideArray[0] && currentWeeksSlideArray[0].startWeekDate;
  const endDate =
    currentWeeksSlideArray[currentWeeksSlideArray.length - 1] &&
    currentWeeksSlideArray[currentWeeksSlideArray.length - 1].endWeekDate;
  if (!targetCompletionDate) {
    return <div></div>;
  }
  if (
    moment(targetCompletionDate).isAfter(endDate) ||
    moment(targetCompletionDate).isBefore(startDate)
  ) {
    return <div></div>;
  }

  return (
    <div className='w-full flex'>
      <div className='w-6' />
      {currentWeeksSlideArray.map((week: Week, index: number) => {
        const displayTooltip =
          moment(targetCompletionDate).isSameOrAfter(week.startWeekDate) &&
          moment(targetCompletionDate).isSameOrBefore(week.endWeekDate);
        return (
          <div className='flex-1 h-5' key={index}>
            {displayTooltip && (
              <Tooltip
                className='w-full'
                trigger={
                  <div
                    className={classnames(
                      'w-full flex h-5 cursor-pointer',
                      'bg-success-lightest items-center justify-center'
                    )}
                  >
                    <Typography
                      variant='caption'
                      className='text-success-darker font-semibold'
                    >
                      {intl.get(
                        'PEOPLE.RESOURCE_ALLOCATION.TABLE.TARGET_COMPLETION'
                      )}
                    </Typography>
                  </div>
                }
                openMode='hover2'
                timeout={0}
                ariaId='budget-notes-info'
                position='topCenter'
                contentProps={{
                  className: classnames(
                    'bg-neutral-darker shadow-tooltip',
                    'rounded-lg px-4 py-3',
                    'w-max absolute',
                    'text-body'
                  ),
                }}
              >
                <div className='w-36 flex flex-col'>
                  <Typography
                    variant='caption'
                    className='text-neutral-white font-semibold mb-1'
                  >
                    {intl.get(
                      'PROJECTS_LIST_PAGE.TABLE.HEAD.TARGET_COMPLETION_DATE'
                    )}
                  </Typography>
                  <Typography variant='label' className='text-neutral-white'>
                    {moment(targetCompletionDate).format(DATE.SHORT_FORMAT)}
                  </Typography>
                </div>
              </Tooltip>
            )}
          </div>
        );
      })}
      <div className='w-6' />
    </div>
  );
};
