import {
  Dropdown,
  FormItem,
  tailwindOverride,
  Typography,
} from '@getsynapse/design-system';
import { useEffect } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { selectTaskStatus } from 'state/TaskDetail/taskSlice';
import {
  fetchTaskStatuses,
  selectCanFetchTaskStatues,
  selectTaskStatuses,
} from 'state/TasksList/allTasks/allTasksSlice';
import { TASK_STATUS } from 'utils/constants';
import { Option } from 'utils/customTypes';

interface TaskStatusFieldProps {
  isOnSidepanel?: boolean;
  hasAssignedUsers?: boolean;
}

const TaskStatusField = ({
  isOnSidepanel = false,
  hasAssignedUsers,
}: TaskStatusFieldProps) => {
  const taskStatus = useSelector(selectTaskStatus);
  const statuses = useSelector(selectTaskStatuses);
  const handleOnStatusChange = (option: Option) => {};

  const statusOptions = Object.entries(statuses).map(([value, label]) => {
    return {
      value,
      label,
    };
  });

  const dispatch = useDispatch();
  const canFetchTaskStatuses = useSelector(selectCanFetchTaskStatues);
  useEffect(() => {
    if (canFetchTaskStatuses) {
      dispatch(fetchTaskStatuses());
    }
  }, [canFetchTaskStatuses, dispatch]);

  const statusName = statuses[taskStatus];

  return (
    <FormItem
      label={intl.get('TASKS.TASK_DETAIL_PAGE.STATUS')}
      component='div'
      className={tailwindOverride({ 'mt-4': isOnSidepanel }, 'w-full')}
    >
      <Dropdown
        placeholder={intl.get('TASKS.TASK_DETAIL_PAGE.STATUS_PLACEHOLDER')}
        options={statusOptions}
        disabled={taskStatus === 'on_hold'}
        values={[
          {
            label: statusName,
            value: taskStatus,
          },
        ]}
        triggerProps={{
          'data-cy': 'task-status-input',
          'aria-label': intl.get('TASKS.TASK_DETAIL_PAGE.STATUS'),
        }}
        onChange={handleOnStatusChange}
        renderOption={(
          option: Option,
          isSelected: boolean,
          selectOption,
          { className, ...otherProps }
        ) => {
          const isOptionDisabled =
            option.value === TASK_STATUS.COMPLETED && hasAssignedUsers;

          return (
            <li
              {...otherProps}
              onClick={selectOption}
              tabIndex={isSelected ? 0 : -1}
              className={tailwindOverride(
                'font-body text-base truncate cursor-pointer group px-4 py-2 text-neutral-black',
                'focus-visible:border-0 focus-visible:ring-0 focus-visible:outline-none',
                {
                  'hover:text-neutral-dark hover:shadow-list-item-hover hover:bg-neutral-lighter-two':
                    !isSelected && !isOptionDisabled,
                  'focus-visible:bg-neutral-lighter-two focus-visible:text-neutral-dark focus-visible:shadow-list-item-hover':
                    !isSelected && !isOptionDisabled,
                  'text-neutral-light cursor-not-allowed hover:text-neutral-light hover:shadow-none hover:bg-transparent':
                    isOptionDisabled,
                  'bg-primary focus-visible:bg-primary text-neutral-white':
                    isSelected,
                }
              )}
            >
              <div className='flex justify-between items-center'>
                <span>{option.label}</span>
                {isOptionDisabled && (
                  <Typography variant='caption' className='text-neutral'>
                    {intl.get('TASKS.TASK_DETAIL_PAGE.ASSIGNEES_REQUIRED')}
                  </Typography>
                )}
              </div>
            </li>
          );
        }}
      />
    </FormItem>
  );
};

export default TaskStatusField;
