import { EditableRequestStatus } from 'utils/types/request';
import { REQUEST_STATUSES } from 'utils/constants/request';
import ApproveRequestModal from './ApproveRequestModal/ApproveRequestModal';
import DeclineRequestModal from './DeclineRequestModal/DeclineRequestModal';
import InReviewModal from './InReviewModal/InReviewModal';
import WaitlistModal from './WaitlistModal/WaitlistModal';

interface RequestStatusModalsProps {
  activeModal?: EditableRequestStatus;
  onConfirmAction: (params?: {
    comments?: string;
    conditions?: string[];
    details?: string;
    reason?: string;
    message?: string;
  }) => void;
  onCancel: () => void;
}

export default function RequestStatusModals({
  onCancel,
  activeModal,
  onConfirmAction,
}: RequestStatusModalsProps): JSX.Element {
  return (
    <>
      <ApproveRequestModal
        isOpen={activeModal === REQUEST_STATUSES.APPROVED}
        onCancel={onCancel}
        onApproveRequest={onConfirmAction}
      />
      <WaitlistModal
        isOpen={activeModal === REQUEST_STATUSES.WAITLISTED}
        onCancel={onCancel}
        onWaitlist={onConfirmAction}
      />
      <InReviewModal
        isOpen={activeModal === REQUEST_STATUSES.SUBMITTED}
        onCancel={onCancel}
        onMoveInReview={onConfirmAction}
      />
      <DeclineRequestModal
        isOpen={activeModal === REQUEST_STATUSES.REJECTED}
        onCancel={onCancel}
        onDeclineRequest={onConfirmAction}
      />
    </>
  );
}
