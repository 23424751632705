import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import useRowsInView from '../../hooks/useRowsInView/useRowsInView';
import { fetchTeamsList } from 'state/Capacities/TeamsList/teamsListSlice';
import useScrollHandlerContext from '../ScrollHandler/useScrollHandlerContext';
import TeamRowAccordion from './components/TeamRowAccordion/TeamRowAccordion';
import LastTeamRow from './components/LastTeamRow/LastTeamRow';

export type TeamsListProps = {
  teamsIds: string[];
  canFetchMoreTeams: boolean;
};

const TeamsList: React.FC<TeamsListProps> = ({
  teamsIds,
  canFetchMoreTeams,
}) => {
  const { fetchTeamsCapacity } = useScrollHandlerContext();
  const dispatch = useDispatch();
  const onVisibleTeamsChangeHandler = useCallback(
    (teamIds: string[]) => fetchTeamsCapacity(teamIds),
    [fetchTeamsCapacity]
  );
  const handleFetchMoreTeams = useCallback(
    () => dispatch(fetchTeamsList()),
    [dispatch]
  );
  const { updateRowInView } = useRowsInView(onVisibleTeamsChangeHandler);

  const debouncedFetchMoreTeamsHandler = debounce(
    (shouldFetchMoreTeams: boolean) => {
      if (shouldFetchMoreTeams) {
        handleFetchMoreTeams();
      }
    },
    500
  );

  return (
    <tbody>
      {teamsIds.map((teamId) => (
        <tr key={teamId}>
          <td colSpan={2}>
            <TeamRowAccordion
              teamId={teamId}
              updateTeamRowInView={updateRowInView}
            />
          </td>
        </tr>
      ))}
      {canFetchMoreTeams && (
        <LastTeamRow onLastTeamRowInView={debouncedFetchMoreTeamsHandler} />
      )}
    </tbody>
  );
};

export default TeamsList;
