import {
  CollectionMethodAndOptions,
  MethodQuestionWithPresetValues,
} from 'utils/types/program';
import intl from 'react-intl-universal';
import { OptionWithKey } from 'utils/customTypes';

export const formatCollectionMethodsOptions = (
  collectionMethods: CollectionMethodAndOptions[]
) => {
  let methodsOptions = [] as OptionWithKey[];

  let methodQuestionsAndOptions = {} as {
    [key: string]: MethodQuestionWithPresetValues[];
  };

  collectionMethods.forEach((method) => {
    methodsOptions.push({
      key: method.data_collection_method_id,
      value: method.collection_method_name,
      label: intl.get(
        `STRATEGY_DATA_COLLECTION.METHODS.${method.collection_method_name.toUpperCase()}`
      ),
    });
    methodQuestionsAndOptions[method.data_collection_method_id] =
      method.collectionMethodQuestionsData.map((question) => {
        return {
          question_id: question.question_id,
          question_name: question.question_name,
          options: question.preset_values?.map((option) => {
            return {
              key: option.answer_id,
              value: option.answer_name,
              label: intl.get(
                `STRATEGY_DATA_COLLECTION.QUESTION_ANSWERS.${question.question_name.toUpperCase()}.${option.answer_name.toUpperCase()}`
              ),
            };
          }),
        };
      });
  });

  return { methodsOptions, methodQuestionsAndOptions };
};
