import { useMemo } from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  selectCollaboratedProjects,
  selectParticipatedProjects,
  selectAssociatedTeamsCount,
  selectUserRole,
  selectUserType,
  selectUserEmail,
} from 'state/User/userSlice';
import config from 'config/Config';
import { PATHS, USER_ROLES, USER_TYPES } from 'utils/constants';
import RenderLinks, { LinkWrapperType } from 'Wrappers/LinkWrapper';
import { selectDesigns } from 'state/Designs/designsSlice';
import homeIcon from 'assets/icons/home-icon.svg';
import insightIcon from 'assets/icons/insight-icon.svg';
import intakeIcon from 'assets/icons/intake-icon.svg';
import projectsIcon from 'assets/icons/projects-icon.svg';
import tasksIcon from 'assets/icons/tasks-icon.svg';
import designIcon from 'assets/icons/design-icon.svg';
import teamsIcon from 'assets/icons/teams-icon.svg';
import settingsIcon from 'assets/icons/settings-icon.svg';
import get from 'lodash/get';
import programsIcon from 'assets/icons/programs-icon.svg';
import schedulerIcon from 'assets/icons/schedule-icon.svg';

type NavLinkType = Omit<LinkWrapperType, 'isExpanded'>;

const SideNav = ({ isExpanded }: { isExpanded: boolean }) => {
  const userType = useSelector(selectUserType);
  const userRole = useSelector(selectUserRole);
  const associatedTeamsCount = useSelector(selectAssociatedTeamsCount);
  const collaboratedProjects = useSelector(selectCollaboratedProjects);
  const participatedProjects = useSelector(selectParticipatedProjects);
  const designs = useSelector(selectDesigns);
  const userEmail = useSelector(selectUserEmail);
  const {
    tasksAssignmentsBasedResourcesCapacity = false,
    facilitatorManagement = false,
  } = useFlags();
  const projectsCurrentUserIsTakingPartIn =
    tasksAssignmentsBasedResourcesCapacity
      ? participatedProjects
      : collaboratedProjects;

  const collaboratedDesigns = useMemo(
    () =>
      designs.filter((design: any) => {
        const collaborators = get(design, 'collaborators', []);
        return (
          collaborators.length > 0 &&
          collaborators.find(({ email }: any) => email === userEmail)
        );
      }),
    [designs, userEmail]
  );

  const navLinks = useMemo<NavLinkType[]>(() => {
    const links: NavLinkType[] = [];

    if (userType === USER_TYPES.L_D || userType === USER_TYPES.EXTERNAL) {
      links.push({
        linkPath: PATHS.ROOT,
        iconSrc: homeIcon,
        linkText: intl.get('SIDEBAR.HOME'),
        ariaLabel: intl.get('SIDEBAR.HOME'),
        linkBorderColor: 'border-purple',
      });
    }

    if (userType === USER_TYPES.L_D && userRole === USER_ROLES.ADMIN) {
      links.push({
        linkPath: PATHS.INSIGHTS,
        iconSrc: insightIcon,
        linkText: intl.get('SIDEBAR.INSIGHTS'),
        ariaLabel: intl.get('SIDEBAR.INSIGHTS'),
        linkBorderColor: 'border-secondary-light',
      });
    }

    if (userType === USER_TYPES.L_D || userType === USER_TYPES.BUSINESS) {
      links.push({
        linkPath: PATHS.REQUESTS_LIST_PAGE,
        iconSrc: intakeIcon,
        linkText: intl.get('SIDEBAR.INTAKE'),
        ariaLabel: intl.get('SIDEBAR.INTAKE'),
        linkBorderColor: 'border-tertiary-light',
      });
    }

    if (userType === USER_TYPES.L_D) {
      links.push({
        linkPath: PATHS.PROGRAMS_LIST_PAGE,
        iconSrc: programsIcon,
        linkText: intl.get('SIDEBAR.PROGRAMS'),
        ariaLabel: intl.get('SIDEBAR.PROGRAMS'),
        linkBorderColor: 'border-fire',
      });
    }

    if (
      userType === USER_TYPES.L_D ||
      (projectsCurrentUserIsTakingPartIn &&
        projectsCurrentUserIsTakingPartIn.length > 0)
    ) {
      links.push({
        linkPath: PATHS.PROJECTS_LIST_PAGE,
        iconSrc: projectsIcon,
        linkText: intl.get('SIDEBAR.PROJECTS'),
        ariaLabel: intl.get('SIDEBAR.PROJECTS'),
        linkBorderColor: 'border-purple-dark',
      });
    }

    if (
      userType === USER_TYPES.L_D ||
      (projectsCurrentUserIsTakingPartIn &&
        projectsCurrentUserIsTakingPartIn.length > 0)
    ) {
      links.push({
        linkPath: PATHS.TASKS_LIST_PAGE,
        iconSrc: tasksIcon,
        linkText: intl.get('SIDEBAR.TASKS'),
        ariaLabel: intl.get('SIDEBAR.TASKS'),
        linkBorderColor: 'border-secondary-light',
      });
    }

    if (
      facilitatorManagement &&
      userType === USER_TYPES.L_D &&
      userRole === USER_ROLES.ADMIN
    ) {
      links.push({
        linkPath: PATHS.LEARNING_SCHEDULE_PAGE,
        iconSrc: schedulerIcon,
        linkText: intl.get('SIDEBAR.SCHEDULE'),
        ariaLabel: intl.get('SIDEBAR.SCHEDULE'),
        linkBorderColor: 'border-neutral-white',
      });
    }

    if (
      userType === USER_TYPES.L_D ||
      (collaboratedDesigns && collaboratedDesigns.length > 0)
    ) {
      links.push({
        linkPath: config.get('designURL'),
        iconSrc: designIcon,
        linkText: intl.get('SIDEBAR.DESIGN'),
        ariaLabel: intl.get('SIDEBAR.DESIGN'),
        external: true,
        linkBorderColor: 'border-tertiary-light',
      });
    }

    if (
      (userType === USER_TYPES.L_D && userRole === USER_ROLES.ADMIN) ||
      (userType === USER_TYPES.L_D && associatedTeamsCount)
    ) {
      links.push({
        linkPath: PATHS.TEAMS,
        iconSrc: teamsIcon,
        linkText: intl.get('SIDEBAR.TEAMS'),
        ariaLabel: intl.get('SIDEBAR.TEAMS'),
        linkBorderColor: 'border-fire',
      });
    }

    if (userType === USER_TYPES.L_D && userRole === USER_ROLES.ADMIN) {
      links.push({
        linkPath: PATHS.SETTINGS,
        iconSrc: settingsIcon,
        linkText: intl.get('SIDEBAR.SETTINGS'),
        ariaLabel: intl.get('SIDEBAR.SETTINGS'),
        linkBorderColor: 'border-purple',
      });
    }

    return links;
  }, [
    userType,
    projectsCurrentUserIsTakingPartIn,
    collaboratedDesigns,
    associatedTeamsCount,
    userRole,
    facilitatorManagement,
  ]);

  return (
    <nav className='space-y-2 flex-grow'>
      {navLinks.map((obj, index) => {
        return (
          <RenderLinks
            linkPath={obj.linkPath}
            iconSrc={obj.iconSrc}
            iconName={obj.iconName}
            linkText={obj.linkText}
            key={index}
            isExpanded={isExpanded}
            ariaLabel={obj.ariaLabel}
            external={obj.external}
            linkBorderColor={obj.linkBorderColor}
          />
        );
      })}
    </nav>
  );
};

export default SideNav;
