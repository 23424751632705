import React from 'react';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import {
  tailwindOverride,
  Typography,
  Button,
} from '@getsynapse/design-system';
import { PATHS } from 'utils/constants';
import emptyList from 'assets/icons/empty-daily-teams-capacity-list.svg';

const EmptyTeamsList: React.FC<{
  isUserAdmin: boolean;
}> = ({ isUserAdmin }) => {
  let label = intl.get('TEAMS_CAPACITY.NO_TEAMS_ASSIGNED');
  if (isUserAdmin) {
    label = intl.get('TEAMS_CAPACITY.GET_STARTED_ADD_TEAMS');
  }
  const history = useHistory();
  return (
    <tbody>
      <tr>
        <td colSpan={2}>
          <div
            className={tailwindOverride(
              'w-full h-empty-daily-teams-capacity-table',
              'flex justify-center items-center'
            )}
            aria-label='empty-teams-capacity-table'
          >
            <div
              className={tailwindOverride(
                'flex flex-col',
                'justify-center items-center'
              )}
            >
              <img
                src={emptyList}
                alt={label}
                className='m-auto self-center -ml-5'
              />
              <Typography variant='body' className='text-primary mt-4'>
                {label}
              </Typography>
              {isUserAdmin && (
                <Button
                  className='mt-4'
                  onClick={() => history.push(PATHS.TEAMS_SETTINGS)}
                  aria-label={intl.get('TEAMS_CAPACITY.ADD_TEAM')}
                >
                  {intl.get('TEAMS_CAPACITY.ADD_TEAM')}
                </Button>
              )}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  );
};

export default EmptyTeamsList;
