import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { NotificationSetting } from 'utils/types/notification-settings';

class NotificationSettingsAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchNotificationSettings = async (): Promise<NotificationSetting[]> => {
    const { data } = await this.instance.get(`v2/notification-settings/`);
    return data as NotificationSetting[];
  };

  updateNotificationSettings = async (
    userId: string,
    trigger: string,
    groupId: string,
    value: boolean
  ): Promise<void> => {
    await this.instance.put(`v2/notification-settings/${userId}`, {
      settings: [
        {
          name: trigger,
          groupId,
          emailEnabled: value,
        },
      ],
    });
  };
}

const notificationSettingsAPI = new NotificationSettingsAPI();

export default notificationSettingsAPI;
