import { FC } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import {
  Tooltip,
  IconButton,
  tailwindOverride,
  Typography,
} from '@getsynapse/design-system';
import { setTimeOffEntryIdToDelete } from 'state/ManageTimeOff/TimeOffEntries/all/allTimeOffEntriesSlice';

interface DeleteButtonProps {
  timeOffEntryId: string;
}

const DeleteButton: FC<DeleteButtonProps> = ({ timeOffEntryId }) => {
  const dispatch = useDispatch();
  return (
    <Tooltip
      openMode='hover1'
      timeout={0}
      showPopper
      position='topCenter'
      contentProps={{
        className: tailwindOverride(
          'px-3 py-2 z-50 absolute',
          'border border-purple-lighter',
          'bg-neutral-white'
        ),
      }}
      usePortal
      trigger={
        <IconButton
          name='trash'
          aria-label={intl.get('DELETE')}
          iconClassname='pointer-events-none'
          className='text-base text-neutral-dark p-1 hover:bg-neutral-lightest hover:shadow-allocation-table rounded'
          onClick={() => dispatch(setTimeOffEntryIdToDelete(timeOffEntryId))}
        />
      }
    >
      <Typography variant='label' className='text-purple-darker'>
        {intl.get('DELETE')}
      </Typography>
    </Tooltip>
  );
};

export default DeleteButton;
