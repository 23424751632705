import {
  DailyCapacityPerWeek,
  DailyTeamMemberCapacity,
  MembersByTeam,
  TeamMember,
} from 'utils/types/dailyTeamsCapacity';
import { updateDailyCapacityPerWeek } from '../helpers';

export const getVisibleMembersFromTeam: (
  teamId: string,
  membersByTeam: {
    [teamId: string]: {
      allIds: string[];
    };
  }
) => string[] = (teamId, membersByTeam) => {
  if (teamId in membersByTeam) {
    return membersByTeam[teamId].allIds;
  }
  return [];
};

export const getMembersIdsFromTeamToFetch: (
  teamMembersIds: string[],
  memberIds: string[]
) => string[] = (teamMembersIds, memberIds) => {
  if (teamMembersIds.length === 0) {
    return memberIds;
  }
  return memberIds.filter((memberId) => !teamMembersIds.includes(memberId));
};

export const getMembersIdsFromTeamToRemove: (
  teamMembersIds: string[],
  memberIds: string[]
) => string[] = (teamMembersIds, memberIds) => {
  return teamMembersIds.filter((memberId) => !memberIds.includes(memberId));
};

export const updateDailyMemberCapacity: (
  currentDailyMemberCapacity: DailyCapacityPerWeek,
  newDailyMemberCapacity: DailyCapacityPerWeek
) => DailyCapacityPerWeek = (
  currentDailyMemberCapacity,
  newDailyMemberCapacity
) => {
  return updateDailyCapacityPerWeek(
    currentDailyMemberCapacity,
    newDailyMemberCapacity
  );
};

export const addIdIfNotExists: (
  idsArray: string[],
  newId: string
) => string[] = (idsArray, newId) => {
  if (idsArray.includes(newId)) {
    return idsArray;
  }
  return idsArray.concat(newId);
};

export const removeTeamFromMembersById: (
  membersById: DailyTeamMemberCapacity,
  membersIds: string[],
  teamId: string
) => {
  updatedMembersById: DailyTeamMemberCapacity;
  membersIdsToRemove: string[];
} = (membersById, memberIds, teamId) => {
  const updatedMembersById = { ...membersById };
  const membersIdsToRemove: string[] = [];
  for (const memberId of memberIds) {
    let visibleMembersIds = updatedMembersById[memberId]?.visibleTeamsIds || [];
    visibleMembersIds = visibleMembersIds.filter((id) => id !== teamId);
    if (visibleMembersIds.length === 0) {
      membersIdsToRemove.push(memberId);
      delete updatedMembersById[memberId];
    } else {
      updatedMembersById[memberId] = {
        ...updatedMembersById[memberId],
        visibleTeamsIds: visibleMembersIds,
      };
    }
  }
  return { updatedMembersById, membersIdsToRemove };
};

export const removeMembersFromTeam: (
  membersByTeam: MembersByTeam<TeamMember>,
  memberIds: string[],
  teamId: string
) => MembersByTeam<TeamMember> = (membersByTeam, memberIds, teamId) => {
  const updatedMembersByTeam = { ...membersByTeam };
  let allIds = updatedMembersByTeam[teamId]?.allIds || [];
  allIds = allIds.filter((id) => !memberIds.includes(id));
  if (allIds.length === 0) {
    delete updatedMembersByTeam[teamId];
  } else {
    updatedMembersByTeam[teamId] = {
      ...updatedMembersByTeam[teamId],
      allIds,
    };
  }
  return updatedMembersByTeam;
};
