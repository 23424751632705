import axios, { AxiosInstance } from 'axios';
import get from 'lodash/get';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { User, Project } from 'utils/customTypes';
import type { LearningTeamWithMembers } from 'utils/types/learningTeam';

type ApiResponse = {
  code: number;
  data: ApiResponseData;
  success: boolean;
};

export type RawTeamCapacity = {
  learningTeam: LearningTeamWithMembers & { maximumTeamCapacity: number };
  teamCapacity: Record<
    string,
    { allocation: number; totalWeeklyTimeOffsTotal?: number }
  >;
  teamMembersAndManagersCapacity: Record<
    string,
    TeamMembersAndManagersCapacity
  >;
};

export type ApiResponseData = {
  teamCapacityTables: RawTeamCapacity[];
  viewableTeamsCount: number;
};

export type TeamMembersAndManagersCapacity = {
  user: User & { isTeamManager: boolean; default_capacity: number };
  allocations: Record<
    string,
    { allocation: number; totalWeeklyTimeOff?: number }
  >;
  projectLevelAllocations: Record<string, ProjectLevelAllocations>;
};

export type ProjectLevelAllocations = {
  project: Project & { userProjectRoles: string[]; has_tasks: boolean };
  projectAllocations: Record<string, { allocation: number }>;
};

class TeamsCapacityAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchTeamsCapacity: (query: {
    tableStartDate: string;
    tableEndDate: string;
    offset: number;
    limit: number;
  }) => Promise<ApiResponseData> = async (query) => {
    const response = await this.instance.get<ApiResponse>(
      '/capacity/learningTeam/retrieveMultipleLearningTeamCapacities',
      {
        params: { ...query },
      }
    );
    if (response.data && response.status === 200) {
      return get(response, 'data.data', []);
    }
    if (axios.isAxiosError(response)) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
  };
}

const teamsCapacityAPI = new TeamsCapacityAPI();

export default teamsCapacityAPI;
