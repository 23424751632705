import { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  getUserSettingValues,
  getViewSettingValues,
  selectCurrentViewSettingValue,
} from 'state/ViewSettings/viewSettingsSlice';
import { resetCapacitiesState } from 'state/Capacities/TeamsList/teamsListSlice';
import {
  VIEW_SETTING,
  VIEW_SETTING_VALUES,
} from 'utils/constants/capacityView';
import { CapacityView } from 'utils/types/capacityView';
import DailyCapacity from './components/DailyCapacity/DailyCapacity';
import WeeklyCapacity from './components/WeeklyCapacity/WeeklyCapacity';
import CapacityViewPicker from 'Pages/TeamsPage/views/TeamsCapacity/components/CapacityViewPicker/CapacityViewPicker';
import BetaWeeklyCapacity from './components/BetaWeeklyCapacity/BetaWeeklyCapacity';

const TeamsCapacity = () => {
  const dispatch = useDispatch();
  const { teamsCapacityViewByDay = false, teamsCapacityViewByWeek = false } =
    useFlags();
  const savedTeamsCapacityView = useSelector(
    selectCurrentViewSettingValue(VIEW_SETTING)
  );
  const teamsCapacityView = useMemo<CapacityView | null>(() => {
    if (!teamsCapacityViewByDay && !teamsCapacityViewByWeek) {
      return VIEW_SETTING_VALUES.WEEKLY;
    }
    if (savedTeamsCapacityView) {
      return savedTeamsCapacityView.value as CapacityView;
    }
    return null;
  }, [savedTeamsCapacityView, teamsCapacityViewByDay, teamsCapacityViewByWeek]);

  useEffect(() => {
    dispatch(getUserSettingValues());
    dispatch(getViewSettingValues([VIEW_SETTING]));
    return () => {
      dispatch(resetCapacitiesState());
    };
  }, [dispatch]);

  return (
    <Fragment>
      {teamsCapacityViewByDay && (
        <CapacityViewPicker
          capacityView={teamsCapacityView || VIEW_SETTING_VALUES.WEEKLY}
        />
      )}
      {!teamsCapacityViewByWeek &&
        teamsCapacityView === VIEW_SETTING_VALUES.WEEKLY && <WeeklyCapacity />}
      {teamsCapacityViewByDay &&
        teamsCapacityView === VIEW_SETTING_VALUES.DAILY && <DailyCapacity />}
      {teamsCapacityViewByWeek &&
        (teamsCapacityView === VIEW_SETTING_VALUES.BETA_WEEKLY ||
          teamsCapacityView === VIEW_SETTING_VALUES.WEEKLY) && (
          <BetaWeeklyCapacity />
        )}
    </Fragment>
  );
};

export default TeamsCapacity;
