import {
  FormEvent,
  ChangeEvent,
  useState,
  useCallback,
  useEffect,
} from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import Auth from '@aws-amplify/auth';
import {
  Button,
  Dropdown,
  FormItem,
  TextField,
  Typography,
} from '@getsynapse/design-system';
import { getUser, selectUser } from 'state/User/userSlice';
import { COUNTRIES } from 'utils/constants';
import { useMemo } from 'react';
import { Option } from 'utils/customTypes';
import {
  selectBussinessTeams,
  getBusinessTeams,
} from 'state/BusinessTeams/businessTeamsSlice';
import { updateUser } from 'state/UsersManagement/usersManagementSlice';
import { isEmpty, get } from 'lodash';

const CompleteRegistration = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const redirectURL = query.get('redirect');
  const history = useHistory();
  const [authnticatedUser, setAuthnticatedUser] = useState();
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    businessUnit: '',
    country: '',
  });

  const countryOptions = useMemo(
    () =>
      COUNTRIES.map((country) => ({
        value: country,
        label: intl.get(`COUNTRIES.${country}`),
      })),
    []
  );

  useEffect(() => {
    if (get(user, 'businessTeam')) {
      history.push('/');
    }
    if (user.firstName) {
      setUserData((prevState) => ({
        ...prevState,
        firstName: user.firstName!,
        lastName: user.lastName!,
      }));
    }
  }, [user, history]);

  useEffect(() => {
    const getAuthUser = async () => {
      const authUser = await Auth.currentAuthenticatedUser();
      await dispatch(getUser(authUser.attributes.email));
      setAuthnticatedUser(authUser);
    };
    getAuthUser();
    dispatch(getBusinessTeams());
  }, [dispatch, history]);

  const businessTeams = useSelector(selectBussinessTeams);

  const businessTeamsOptions = useMemo(() => {
    return businessTeams.map((team) => ({
      value: team.id,
      label: team.title,
    }));
  }, [businessTeams]);

  const isDisabled = useMemo(() => {
    if (
      userData.firstName.trim() &&
      userData.lastName.trim() &&
      userData.jobTitle.trim() &&
      userData.businessUnit.trim() &&
      userData.country.trim()
    ) {
      return false;
    } else {
      return true;
    }
  }, [userData]);

  const updateUserInDBAndInCognito = async () => {
    await updateUserInDB();
    await Auth.updateUserAttributes(authnticatedUser, {
      given_name: userData.firstName,
      family_name: userData.lastName,
    });
  };

  const updateUserInDB = async () => {
    const updateFields = {
      data: {
        firstName: userData.firstName,
        lastName: userData.lastName,
        jobTitle: userData.jobTitle,
        email: user.email!,
      },
      country_iso_3166_1_alpha_2_code: userData.country,
      businessTeam_id: userData.businessUnit,
    };
    await dispatch(updateUser({ userId: user.id!, updateFields }));
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!user.firstName) {
      await updateUserInDBAndInCognito();
    } else {
      await updateUserInDB();
    }
    await dispatch(getUser(user.email!));
    history.push(redirectURL!);
  };

  const onUserDataChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setUserData((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    },
    []
  );

  return (
    <div className='mx-8'>
      <Typography variant='h2' className='text-center mb-7.5'>
        {intl.get('COMPLETE_REGISTRATION.HEADING')}
      </Typography>
      <Typography>
        {intl.getHTML('COMPLETE_REGISTRATION.CAPTION', {
          email: user.email,
        })}
      </Typography>
      <form className='mt-6 flex flex-col items-center' onSubmit={handleSubmit}>
        <div className='grid grid-cols-2 gap-4 w-full mb-8'>
          <FormItem
            label={intl.get('TRIAL_REGISTRATION_PAGE.INPUT.FIRST_NAME.LABEL')}
            labelProps={{ required: true }}
            component='div'
          >
            <TextField
              variant='text'
              placeholder={intl.get(
                'TRIAL_REGISTRATION_PAGE.INPUT.FIRST_NAME.PLACEHOLDER'
              )}
              name='firstName'
              value={userData.firstName}
              onChange={onUserDataChange}
              disabled={!isEmpty(user.firstName)}
            />
          </FormItem>

          <FormItem
            label={intl.get('TRIAL_REGISTRATION_PAGE.INPUT.LAST_NAME.LABEL')}
            labelProps={{ required: true }}
            component='div'
          >
            <TextField
              variant='text'
              placeholder={intl.get(
                'TRIAL_REGISTRATION_PAGE.INPUT.LAST_NAME.PLACEHOLDER'
              )}
              name='lastName'
              value={userData.lastName}
              onChange={onUserDataChange}
              disabled={!isEmpty(user.lastName)}
            />
          </FormItem>

          <FormItem
            label={intl.get('TRIAL_REGISTRATION_PAGE.INPUT.JOB_TITLE.LABEL')}
            labelProps={{ required: true }}
            component='div'
          >
            <TextField
              variant='text'
              placeholder={intl.get(
                'TRIAL_REGISTRATION_PAGE.INPUT.JOB_TITLE.PLACEHOLDER'
              )}
              name='jobTitle'
              value={userData.jobTitle}
              onChange={onUserDataChange}
            />
          </FormItem>
          <FormItem
            label={intl.get('COMPLETE_REGISTRATION.BUSINESS_UNIT_LABEL')}
            labelProps={{
              required: true,
            }}
            component='div'
          >
            <Dropdown
              options={businessTeamsOptions}
              triggerProps={{
                placeholder: intl.get(
                  'COMPLETE_REGISTRATION.BUSINESS_UNIT_PLACEHOLDER'
                ),
              }}
              onChange={(option: Option) => {
                setUserData((prev) => ({
                  ...prev,
                  businessUnit: option.value,
                }));
              }}
            />
          </FormItem>
          <FormItem
            label={intl.get('TRIAL_REGISTRATION_PAGE.INPUT.COUNTRY.LABEL')}
            labelProps={{ required: true }}
            className='col-span-2'
            component='div'
          >
            <Dropdown
              options={countryOptions}
              placeholder={intl.get(
                'TRIAL_REGISTRATION_PAGE.INPUT.COUNTRY.PLACEHOLDER'
              )}
              filterable
              triggerProps={{
                className: 'h-6',
              }}
              onChange={(option: Option) =>
                setUserData((prev) => ({ ...prev, country: option.value }))
              }
            />
          </FormItem>
        </div>

        <Button
          variant='primary'
          type='submit'
          className={classnames('w-74 justify-center', {
            'cursor-not-allowed': isDisabled,
          })}
          disabled={isDisabled}
        >
          {intl.get('COMPLETE_REGISTRATION.BUTTON')}
        </Button>
      </form>
    </div>
  );
};

export default CompleteRegistration;
