import { objKeyAsString } from 'utils/customTypes';

export const SKELETON_COLOR = '#E1E9EC';

export const SECTOR_COLOR: objKeyAsString = {
  business: '#45598C',
  ld: '#8B2C56',
  external: '#55AC9B',
};

export const TEXT_STYLE = {
  color: '#0E1212',
  fontSize: '0.75rem',
  lineHeight: '1rem',
};

export const LABEL_STROKE_COLOR = '#A0B0B5';

export const LABEL_TEXT_COLOR = '#0E1212';

export const PRECENTAGE_LABEL_STYLE = {
  fontSize: '1rem',
  lineHeight: '1.5rem',
  color: LABEL_TEXT_COLOR,
  fontWeight: 700,
};
