import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';

class ViewSettingsAPI {
  instance: AxiosInstance;

  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  getUserViewSettings = async () => {
    const { data } = await this.instance.get(`v2/view-settings/`);
    return data;
  };

  getViewSettingValues = async (key: string) =>
    await this.instance.get(`v2/view-settings/${key}`);

  createUpdateUserViewSetting = async (
    view_settings_id: string,
    view_settings_values_id?: string,
    value?: string
  ) => {
    const { data } = await this.instance.put(
      `v2/view-settings/${view_settings_id}`,
      {
        viewSettingValue: view_settings_values_id,
        value,
      }
    );
    return data;
  };
}

const viewSettingsAPI = new ViewSettingsAPI();

export default viewSettingsAPI;
