import { DataValueDescription } from 'Pages/InsightsPage/Components/DataValueDescription';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTasksByStatus,
  getTasksByStatus,
  getActiveFiltersQueryParams,
} from 'state/Insights/ProjectsSlice';
import { TasksByStatusBarChart } from 'Pages/InsightsPage/Projects/TasksByStatus/TasksByStatusBarChart';
import { Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import HorizontalBarChartLoader from 'Pages/InsightsPage/Components/SkeletonLoaders/HorizontalBarChartLoader';
import { SLICE_STATUS } from 'utils/constants';
import ErrorScreen from '../../Components/ErrorScreen';

export const TasksByStatus = () => {
  const dispatch = useDispatch();
  const activeFilters = useSelector(getActiveFiltersQueryParams);
  useEffect(() => {
    dispatch(fetchTasksByStatus(activeFilters));
  }, [dispatch, activeFilters]);

  const data = useSelector(getTasksByStatus);

  return data.status === SLICE_STATUS.LOADING ? (
    <HorizontalBarChartLoader />
  ) : data.status === SLICE_STATUS.FAILED ? (
    <ErrorScreen />
  ) : (
    <div className='flex flex-col h-full w-full'>
      {data.total && (
        <DataValueDescription title='Tasks By Status' value={data.total} />
      )}

      <div className='w-full h-full pt-4 flex'>
        {data.total === 0 ? (
          <div className='flex flex-col h-full px-6 justify-center items-center'>
            <Typography variant='caption' className='text-neutral-lighter'>
              {intl.get('PROJECTS_INSIGHTS_TABS.NO_TASKS')}
            </Typography>
          </div>
        ) : (
          <TasksByStatusBarChart data={data.data} />
        )}
      </div>
    </div>
  );
};
