import React from 'react';
import { tailwindOverride } from '@getsynapse/design-system';
import { TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS } from 'utils/constants';

const TeamRow = () => {
  return (
    <div className='flex flex-1 animate-pulse'>
      <div className={'w-2/6 flex h-full'}>
        <div
          className={tailwindOverride(
            'w-1/12 px-2',
            'flex items-center justify-end'
          )}
        >
          <div className='w-4 h-4 rounded-sm bg-neutral-lighter' />
        </div>
        <div className={tailwindOverride('w-11/12', 'py-5 px-2')}>
          <div className='w-1/4 h-4 rounded-sm bg-neutral-lighter' />
        </div>
      </div>
      <div className='w-4/6 h-14 flex'>
        <div className='w-6 h-full' />
        <div className='flex flex-1 h-full'>
          {Array.from(Array(TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS)).map(
            (_, index) => (
              <div
                key={`skeleton-loader__table-header__column-${index}`}
                className={tailwindOverride('h-full', 'px-2 py-3', {
                  'bg-secondary-lightest': index === 0,
                })}
                style={{
                  width: `calc(100% / ${TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS})`,
                }}
              />
            )
          )}
        </div>
        <div className='w-6 h-full' />
      </div>
    </div>
  );
};

const MemberRow = () => {
  return (
    <div className='flex flex-1 h-25 animate-pulse'>
      <div
        className={tailwindOverride(
          'w-2/6 flex h-full',
          'bg-neutral-lightest-two'
        )}
      >
        <div
          className={tailwindOverride(
            'w-1/12 h-full',
            'py-3 px-2',
            'flex justify-end'
          )}
        >
          <div className='w-4 h-4 rounded-sm bg-neutral-lighter' />
        </div>
        <div className={tailwindOverride('w-7/12 h-full', 'py-3 px-4 flex')}>
          <div className='w-6 h-6 rounded-full bg-neutral-lighter' />
          <div className='flex flex-1 flex-col ml-2'>
            <div className='w-3/5 h-4 rounded-sm bg-neutral-lighter mb-1' />
            <div className='w-2/5 h-4 rounded-sm bg-neutral-lighter mb-2' />
            <div className='w-1/5 h-4 rounded-sm bg-neutral-lighter' />
          </div>
        </div>
        <div className={tailwindOverride('w-4/12 h-full', 'py-3 px-2')}>
          <div className='w-3/5 h-4 rounded-sm bg-neutral-lighter mb-2' />
          <div className='w-4/5 h-4 rounded-sm bg-neutral-lighter mb-1' />
        </div>
      </div>
      <div className='w-4/6 flex h-full'>
        <div className='w-6 h-full bg-neutral-lightest-two' />
        <div className='flex flex-1 h-full bg-neutral-white'>
          {Array.from(Array(7)).map((_, index) => (
            <div
              key={`skeleton-loader__table-header__column-${index}`}
              className={tailwindOverride('h-full', 'px-2 py-3', {
                'bg-secondary-lightest': index === 0,
                'bg-neutral-lightest-two': index !== 0,
              })}
              style={{ width: 'calc(100% / 7)' }}
            >
              <div className='w-full h-8 bg-neutral-lighter rounded-sm' />
            </div>
          ))}
        </div>
        <div className='w-6 h-full bg-neutral-lightest-two' />
      </div>
    </div>
  );
};

export const WeeksCarouselLoader: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <div
      className={tailwindOverride('flex flex-1 h-full', className)}
      aria-label='weekly-capacity-carousel-loader'
    >
      <div className='w-6 h-10' />
      <div className='flex flex-1 h-full'>
        {Array.from(Array(TEAMS_CAPACITY_TABLE_NUMBER_OF_WEEKS)).map(
          (_, index) => (
            <div
              key={`skeleton-loader__table-header__column-${index}`}
              className={tailwindOverride('h-full', 'px-2 py-3', {
                'bg-secondary-lightest': index === 0,
              })}
              style={{ width: 'calc(100% / 7)' }}
            >
              <div
                className={tailwindOverride(
                  'w-full h-4 rounded-sm',
                  'bg-neutral-lighter',
                  { 'bg-secondary-lighter': index === 0 }
                )}
              />
            </div>
          )
        )}
      </div>
      <div className='w-6 h-10' />
    </div>
  );
};

const SkeletonLoader = () => {
  return (
    <div
      className={tailwindOverride(
        'w-full h-77 rounded',
        'border border-primary-lighter-two'
      )}
      aria-label='teams-capacity-table-loader'
      data-cy='teams-capacity-table-loader'
    >
      {/* Table Header */}
      <div className={tailwindOverride('flex flex-1', 'animate-pulse')}>
        <div
          className={tailwindOverride(
            'w-2/6 flex h-full',
            'bg-neutral-lightest'
          )}
        >
          <div className='w-1/12 ' />
          <div className={tailwindOverride('w-7/12 px-2 py-3')}>
            <div
              className={tailwindOverride(
                'w-7/12 h-4 rounded-sm',
                'bg-neutral-lighter'
              )}
            />
          </div>
          <div className={tailwindOverride('w-4/12 px-2 py-3')}>
            <div
              className={tailwindOverride(
                'w-10/12 h-4 rounded-sm',
                'bg-neutral-lighter'
              )}
            />
          </div>
        </div>
        <div className={tailwindOverride('w-4/6 h-full')}>
          <WeeksCarouselLoader className='bg-neutral-lightest' />
        </div>
      </div>
      {/* Table Body */}
      <div className='w-full h-full'>
        <TeamRow />
        <MemberRow />
        <TeamRow />
        <TeamRow />
      </div>
    </div>
  );
};

export default SkeletonLoader;
