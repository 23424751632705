import { api } from './api';
import axios from 'axios';
import { get } from 'lodash';

interface FetchTimeOffEntriesParams {
  userId: string;
  includeTotalCount: boolean;
  startDate?: string;
  endDate?: string;
  timeZone?: string;
  timeOffType?: string;
  sortBy?: string;
  order?: string;
  after?: string;
}

interface TimeOffEntry {
  id: string;
  timeOffTypeId: string;
  startDate: string;
  endDate: string;
  hours: string;
}

interface FetchTimeOffEntriesResponse {
  entries: TimeOffEntry[];
  totalCount?: number;
  links: {
    next: string | null;
  };
}

interface FetchTimeOffTypesResponse {
  types: {
    id: string;
    name: string;
    code: string;
  }[];
}

interface FetchTimeOffDaysParams {
  userId: string;
  entryId: string;
  timezone?: string;
  query?: string;
}

interface TimeOffDay {
  id: string;
  hours: string;
}

export interface FetchTimeOffDaysResponse {
  timeOff: {
    [day: string]: TimeOffDay;
  };
  links: {
    next: string | null;
  };
}

export interface AddTimeOffEntryParams {
  userId: string;
  timeOffTypeId: string;
  startDate: string;
  endDate: string;
  days: {
    [day: string]: number;
  };
}

export interface EditTimeOffEntryParams {
  userId: string;
  id: string;
  timeOffEntry: {
    timeOffTypeId?: string;
    startDate?: string;
    endDate?: string;
    days?: {
      [day: string]: number;
    };
  };
}

export interface DeleteTimeOffEntryParams {
  userId: string;
  timeOffEntryId: string;
}

export interface EditTimeOffEntryResponse {
  timeOffEntry: {
    id: string;
    timeOffTypeId?: string;
    startDate?: string;
    endDate?: string;
    days?: {
      [day: string]: {
        id: string;
        entryId: string;
        day: string;
        hours: number;
      };
    };
  };
}

interface NewTimeOffEntry {
  id: string;
  timeOffTypeId: string;
  startDate: string;
  endDate: string;
  days: {
    [day: string]: {
      id: string;
      entryId: string;
      day: string;
      hours: number;
    };
  };
}
interface AddTimeOffEntryResponse {
  timeOffEntry: NewTimeOffEntry;
}

const getFetchTimeOffEntriesUrlSearchParams = ({
  startDate,
  endDate,
  timeOffType,
  sortBy,
  order,
  after,
}: Omit<FetchTimeOffEntriesParams, 'userId' | 'includeTotalCount'>): string => {
  const urlSearchParams = new URLSearchParams();

  if (startDate) {
    urlSearchParams.append('startDate', startDate);
  }

  if (endDate) {
    urlSearchParams.append('endDate', endDate);
  }

  if (timeOffType) {
    urlSearchParams.append('timeOffType', timeOffType);
  }

  if (sortBy) {
    urlSearchParams.append('sortBy', sortBy);
  }

  if (order) {
    urlSearchParams.append('order', order);
  }

  if (after) {
    urlSearchParams.append('after', after);
  }

  urlSearchParams.append('limit', '100');

  return urlSearchParams.toString();
};

export const fetchUpcomingTimeOffEntries = async ({
  userId,
  includeTotalCount,
  startDate,
  endDate,
  timeOffType,
  sortBy,
  order,
  after,
}: FetchTimeOffEntriesParams) => {
  const searchParams = getFetchTimeOffEntriesUrlSearchParams({
    startDate,
    endDate,
    timeOffType,
    sortBy,
    order,
    after,
  } as FetchTimeOffEntriesParams);

  const url = `/v2/users/${userId}/time-off-entries/upcoming?${searchParams}`;
  const config = {
    headers: {
      'include-total-count': includeTotalCount ? 'true' : 'false',
    },
  };

  const response = await api.get<FetchTimeOffEntriesResponse>(url, config);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};

export const fetchPastTimeOffEntries = async ({
  userId,
  includeTotalCount,
  startDate,
  endDate,
  timeOffType,
  sortBy,
  order,
  after,
}: FetchTimeOffEntriesParams) => {
  const searchParams = getFetchTimeOffEntriesUrlSearchParams({
    startDate,
    endDate,
    timeOffType,
    sortBy,
    order,
    after,
  } as FetchTimeOffEntriesParams);

  const url = `/v2/users/${userId}/time-off-entries/past?${searchParams}`;
  const config = {
    headers: {
      'include-total-count': includeTotalCount ? 'true' : 'false',
    },
  };

  const response = await api.get<FetchTimeOffEntriesResponse>(url, config);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};

export const fetchTimeOffTypes = async () => {
  const url = '/v2/time-off-types';
  const response = await api.get<FetchTimeOffTypesResponse>(url);
  if (axios.isAxiosError(response)) {
    const errorMessage = get(response, 'response.data.message', '');
    throw errorMessage;
  }
  return response.data;
};

export const fetchTimeOffDays = async ({
  entryId,
  userId,
  query,
}: FetchTimeOffDaysParams) => {
  let url = `/v2/users/${userId}/time-off-entries/${entryId}/days`;
  if (query) {
    url += `?${query}`;
  }
  const response = await api.get<FetchTimeOffDaysResponse>(url);
  if (axios.isAxiosError(response)) {
    const errorMessage = get(response, 'response.data.message', '');
    throw errorMessage;
  }
  return response.data;
};

export const addTimeOffEntry = async ({
  userId,
  timeOffTypeId,
  startDate,
  endDate,
  days,
}: AddTimeOffEntryParams) => {
  const url = `/v2/users/${userId}/time-off-entries`;
  const response = await api.post<AddTimeOffEntryResponse>(url, {
    timeOffTypeId,
    startDate,
    endDate,
    days,
  });
  if (axios.isAxiosError(response)) {
    const errorMessage = get(response, 'response.data.message', '');
    throw errorMessage;
  }
  return response.data;
};

export const editTimeOffEntry = async ({
  userId,
  id,
  timeOffEntry,
}: EditTimeOffEntryParams) => {
  const url = `/v2/users/${userId}/time-off-entries/${id}`;
  const response = await api.patch<EditTimeOffEntryResponse>(url, timeOffEntry);
  if (axios.isAxiosError(response)) {
    const errorMessage = get(response, 'response.data.message', '');
    throw errorMessage;
  }
  return response.data;
};

export const deleteTimeOffEntry = async ({
  userId,
  timeOffEntryId,
}: DeleteTimeOffEntryParams) => {
  const url = `/v2/users/${userId}/time-off-entries/${timeOffEntryId}`;
  const response = await api.delete(url);
  if (axios.isAxiosError(response)) {
    const errorMessage = get(response, 'response.data.message', '');
    throw errorMessage;
  }
};
