import {
  EventData,
  FetchedEvent,
  CreatedEvent,
} from 'utils/types/learningSchedule';

export const serializeEvents = (events: FetchedEvent[]): EventData[] => {
  return events.map((event) => ({
    Id: event.id,
    Subject: event.subject,
    StartTime: event.startTime,
    EndTime: event.endTime,
    ProjectId: event.projectIds,
    IsAllDay: event.isAllDay,
    EventType: event.learningEventType,
    RecurrenceRule: event.recurrenceRule,
    RecurrenceException: event.recurrenceException,
    projects: event.projects,
    facilitators: event.facilitators,
  }));
};

export const serializeCreatedEvent = (event: CreatedEvent): EventData => {
  return {
    Id: event.id,
    Subject: event.title,
    StartTime: event.startDateTime,
    EndTime: event.endDateTime,
    ProjectId: event.projects.map((project) => project.id),
    projects: event.projects.map((project) => ({
      id: project.id,
      title: project.title,
    })),
    facilitators: event.facilitators.map((facilitator) => ({
      id: facilitator.id,
      name: facilitator.name,
    })),
    IsAllDay: event.isAllDay,
    EventType: event.learningEventType,
    RecurrenceRule: event.recurrencePattern,
    RecurrenceException: event.recurrenceException,
  };
};
