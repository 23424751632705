import {
  Dropdown,
  FormLabel,
  Modal,
  Typography,
} from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import updateModalIcon from 'assets/icons/update-field-modal.svg';
import { FieldDefaultValue, FieldTemplateType } from 'utils/types/fields';
import { Option } from 'utils/customTypes';
import { useMemo } from 'react';
import { ALLOWED_FIELD_TYPES } from 'utils/constants';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateField: () => void;
  data: FieldTemplateType;
  defaultValues: FieldDefaultValue[];
  setDefaultValues: React.Dispatch<React.SetStateAction<FieldDefaultValue[]>>;
};

const DefaultValuesModal = ({
  isOpen,
  setIsOpen,
  updateField,
  data,
  defaultValues,
  setDefaultValues,
}: Props) => {
  const options = data?.allowed_values?.map((value) => ({
    label: value.display || value.val,
    value: value.val,
  }));

  const numberOption =
    data?.type === ALLOWED_FIELD_TYPES.INT ||
    data?.type === ALLOWED_FIELD_TYPES.FLOAT ||
    data?.type === ALLOWED_FIELD_TYPES.NUMERIC;

  const disableUpdateButton = useMemo(
    () =>
      defaultValues.some((defaultValue) => !defaultValue.newValue?.toString()),
    [defaultValues]
  );

  const updateDefaultValues = (option: Option, defValue: FieldDefaultValue) => {
    let selectedOption = option.value;
    if (numberOption) {
      selectedOption = Number(selectedOption);
    }
    setDefaultValues((prev) =>
      prev.map((defaultValue) => {
        if (defaultValue.oldValue === defValue.oldValue) {
          return {
            ...defaultValue,
            newValue: selectedOption,
          };
        }
        return defaultValue;
      })
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      title={intl.get('SETTINGS_PAGE.FIELDS_PAGE.DEFAULT_VALUES_MODAL.TITLE')}
      titleIcon={{ src: updateModalIcon }}
      aria-label={intl.get(
        'SETTINGS_PAGE.FIELDS_PAGE.DEFAULT_VALUES_MODAL.TITLE'
      )}
      closeModal={() => setIsOpen(false)}
      size='medium'
      actionButtons={[
        {
          children: intl.get(
            'SETTINGS_PAGE.FIELDS_PAGE.DEFAULT_VALUES_MODAL.ADD_VALUES_BUTTON'
          ),
          variant: 'primary',
          onClick: updateField,
          disabled: disableUpdateButton,
        },
        {
          children: intl.get('CANCEL'),
          variant: 'tertiary',
          onClick: () => setIsOpen(false),
        },
      ]}
      childrenClassName='max-h-76'
    >
      <Typography>
        {intl.getHTML('SETTINGS_PAGE.FIELDS_PAGE.DEFAULT_VALUES_MODAL.BODY')}
      </Typography>
      {defaultValues.map((defValue) => (
        <>
          <div className='flex items-center mt-4 mb-2'>
            <FormLabel required className='mb-0'>
              {data?.name}
            </FormLabel>
            <div className='text-neutral ml-1'>{` (For ${defValue.oldValue})`}</div>
          </div>
          <Dropdown
            options={options!}
            onChange={(option: Option) => updateDefaultValues(option, defValue)}
          />
        </>
      ))}
    </Modal>
  );
};

export default DefaultValuesModal;
