import intl from 'react-intl-universal';
import { useEffect, useRef, useCallback, ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import {
  IconButton,
  NumericInput,
  TextArea,
  Typography,
} from '@getsynapse/design-system';
import { CostDataType } from 'utils/types/program';
import { PENDO_EVENTS } from 'utils/constants';
import { trackEvent } from 'Services/pendo';
import ProcessStageDropdown from './ProcessStageDropdown/ProcessStageDropdown';

type CostComponentProps = {
  costData: CostDataType;
  handleCostChange: (
    id: string,
    value: string | number | Partial<CostDataType> | null,
    prop: keyof CostDataType
  ) => void;
  changeAreOtherCostsValid: (id: string, value: boolean) => void;
  showDeleteModal: (id: string) => void;
};

const CostComponent = ({
  costData,
  handleCostChange,
  changeAreOtherCostsValid,
  showDeleteModal,
}: CostComponentProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { programId } = useParams<{
    programId: string;
  }>();

  const [isInDeleteState, setIsInDeleteState] = useState(false);

  const handleAutoResize = useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      const remHeight =
        textarea.scrollHeight /
        parseFloat(getComputedStyle(document.documentElement).fontSize);
      const newHeight = remHeight > 2.5 ? `${remHeight}rem` : '2.5rem';
      textarea.style.height = newHeight;
    }
  }, []);

  useEffect(() => {
    handleAutoResize();
  }, [handleAutoResize]);

  const inErrorState =
    !costData.description &&
    (costData.cost !== null || costData.costStageId !== null);

  const removeCost = () => {
    trackEvent(PENDO_EVENTS.PROGRAM_COST_REMOVE_ICON_CLICK, {
      programId,
    });
    showDeleteModal(costData.id!);
  };

  return (
    <div
      className={classNames(
        'flex mt-3.5 w-full px-1 pt-1 rounded transition-colors transition-100',
        {
          'bg-primary-lightest': isInDeleteState,
        }
      )}
      data-testid={`cost-component-${costData.id}`}
      onMouseEnter={() => setIsInDeleteState(true)}
      onMouseLeave={() => setIsInDeleteState(false)}
    >
      <div className='w-cost-description mr-5'>
        <TextArea
          className='-mb-0.5'
          ref={textareaRef}
          value={costData.description}
          textAreaProps={{
            placeholder: intl.get(
              'ROI_CATEGORY.COSTS_PAGE.COST_DESCRIPTION_PLACEHOLDER'
            ),
            rows: 1,
            className: 'overflow-hidden',
            'data-testid': `cost-description_input_${costData.id}`,
          }}
          name={`cost-description_input_${costData.id}`}
          onChange={(event) => {
            handleAutoResize();
            handleCostChange(costData.id!, event.target.value, 'description');
          }}
          state={inErrorState ? 'error' : 'default'}
          helpText={inErrorState ? intl.get('REQUIRED_INFORMATION') : ''}
        />
      </div>
      <div className='flex w-cost mr-5'>
        <Typography className='mr-1 mt-2'>{intl.get('DOLLAR_SIGN')}</Typography>
        <NumericInput
          containerClassName='w-full'
          placeholder={intl.get('ROI_CATEGORY.COSTS_PAGE.COST_PLACEHOLDER')}
          value={costData.cost ?? ''}
          allowNegativeValue={false}
          maxAllowedDecimalPlaces={2}
          max={99999999.99}
          data-testid={`cost-numeric_input_${costData.id}`}
          id={`cost-numeric_input_${costData.id}`}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const cost =
              event.target.value === '' ? null : Number(event.target.value);
            handleCostChange(costData.id!, cost, 'cost');
          }}
          getIsInputValid={(value) => {
            changeAreOtherCostsValid(costData.id!, value);
          }}
        />
      </div>
      <div className='w-stage mr-4'>
        <ProcessStageDropdown
          costData={costData}
          onChange={(value) =>
            handleCostChange(costData.id!, value, 'costStageId')
          }
        />
      </div>
      <div className='h-10 flex items-center'>
        <IconButton
          onClick={removeCost}
          name='trash'
          data-testid={`delete-cost_button_${costData.id}`}
          description={intl.get('DELETE')}
          className={classNames(
            'text-primary-darker p-1 transition-opacity transition-100',
            {
              'opacity-0': !isInDeleteState,
            }
          )}
        />
      </div>
    </div>
  );
};

export default CostComponent;
