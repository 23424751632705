import intl from 'react-intl-universal';
import classnames from 'classnames';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { Typography, Tooltip, Icon } from '@getsynapse/design-system';
import {
  PROJECT_CONTENT_TYPE,
  PROJECT_CONTENT_TABLE_SORTING,
} from 'utils/constants';
import {
  ProjectContentType,
  Task,
  ProjectFile,
  SortingType,
  ProjectContent,
  ProjectWeblink,
  User,
} from 'utils/customTypes';
import config from 'config/Config';
import UserAvatar from 'Atoms/UserAvatar';
import TruncatedFileName from './components/TruncatedFileName';
import { OrderByValues } from './ContentTable';
import WebLinkContent from 'assets/images/web-link-content.png';
import DefaultFileContent from 'assets/images/default-file-content.png';
import DesignContent from 'assets/images/design-content.png';
import DefaultDesignFileContent from 'assets/images/default-design-file-content.png';
import {
  naturalSort,
  uploadedBySortKeyGenerator,
  sortItemsByKey,
} from '../../../../../../utils/naturalSorting';

export const getLinkedTasksList = (linkedTasks: Task[] = []) => {
  if (linkedTasks.length === 0) {
    return (
      <Typography variant='label' className='text-neutral'>
        {intl.get('PROJECT_DETAIL.FILES_TAB.TABLE.NO_TASKS')}
      </Typography>
    );
  }
  const taskNamesList = [];
  for (const task of linkedTasks) {
    taskNamesList.push(
      <div className='flex items-center mb-2 last:mb-0'>
        <Icon name='checkmark-circle-outline' className='text-lg mr-2' />
        <Typography
          variant='label'
          className='text-neutral-white truncate w-72'
        >
          {intl.get('PROJECT_DETAIL.FILES_TAB.TABLE.LINKED_TASK', {
            name: task.name,
          })}
        </Typography>
      </div>
    );
  }
  return (
    <Tooltip
      trigger={
        <div>
          {intl.get('PROJECT_DETAIL.FILES_TAB.TABLE.LINKED_TASKS_NUMBER', {
            number: linkedTasks.length,
            num: linkedTasks.length > 1 ? 1 : 0,
          })}
        </div>
      }
      openMode='hover2'
      timeout={0}
      ariaId='budget-notes-info'
      position='topCenter'
      showPopper
      contentProps={{
        className: classnames(
          'bg-neutral-darker shadow-tooltip',
          'rounded-lg p-4',
          'w-max absolute',
          'text-body',
          'z-10'
        ),
      }}
    >
      <div className='flex flex-col'>{taskNamesList}</div>
    </Tooltip>
  );
};

export const getContentFileName = (contentFile: ProjectContent) => {
  if (contentFile.name) {
    return contentFile.name;
  }
  switch (contentFile.content_type) {
    case PROJECT_CONTENT_TYPE.FILE:
    case PROJECT_CONTENT_TYPE.DESIGN_FILE:
      return get(contentFile, 'data.filename');
    case PROJECT_CONTENT_TYPE.WEB_LINK:
      return get(contentFile, 'data.display');
    case PROJECT_CONTENT_TYPE.DESIGN:
      return get(contentFile, 'data.title');
    default:
      return '';
  }
};

export const getContentTypeLabel = (contentType: ProjectContentType) => {
  switch (contentType) {
    case PROJECT_CONTENT_TYPE.FILE:
      return intl.get('PROJECT_DETAIL.FILES_TAB.TABLE.CONTENT_TYPE.FILE');
    case PROJECT_CONTENT_TYPE.WEB_LINK:
      return intl.get('PROJECT_DETAIL.FILES_TAB.TABLE.CONTENT_TYPE.WEB_LINK');
    case PROJECT_CONTENT_TYPE.DESIGN:
    case PROJECT_CONTENT_TYPE.DESIGN_FILE:
      return intl.get('PROJECT_DETAIL.FILES_TAB.TABLE.CONTENT_TYPE.DESIGN');
    default:
      return '';
  }
};

export const getUserAvatar = (file: ProjectContent) => {
  return 'uploadedBy' in file ? (
    <div className='flex items-center truncate'>
      <UserAvatar size='small' user={file.uploadedBy as User} />
      <Typography variant='label' weight='medium' className='ml-2'>
        {`${get(file, 'uploadedBy.data.firstName')} ${get(
          file,
          'uploadedBy.data.lastName'
        )}`}
      </Typography>
    </div>
  ) : null;
};

export const getTruncatedFileNameAndExtension = (
  fileName: string,
  maxCharactersNumber = 0
) => {
  if (!fileName) {
    return { name: '', extension: '' };
  }
  let fileExtension = fileName.split('.').pop();
  let truncatedFileName = fileName;
  if (maxCharactersNumber > 0 && fileName.length > maxCharactersNumber) {
    truncatedFileName = `${fileName.substring(0, maxCharactersNumber)}...`;
  }
  return {
    name: truncatedFileName,
    extension: fileExtension,
  };
};

export const getClickableFileName = (file: ProjectContent) => {
  switch (file.content_type) {
    case PROJECT_CONTENT_TYPE.DESIGN_FILE:
    case PROJECT_CONTENT_TYPE.FILE:
      return {
        children: <TruncatedFileName fileName={file.name!} />,
        href:
          (file.data as ProjectFile).cloudFrontURL ||
          (file.data as ProjectFile).url,
      };
    case PROJECT_CONTENT_TYPE.WEB_LINK:
      return {
        children: (
          <Typography
            variant='label'
            className='text-purple-darker cursor-pointer'
          >
            {file.name}
          </Typography>
        ),
        href: (file.data as ProjectWeblink).url,
      };
    case PROJECT_CONTENT_TYPE.DESIGN:
      return {
        children: (
          <Typography
            variant='label'
            className='text-purple-darker cursor-pointer'
          >
            {file.name}
          </Typography>
        ),
        href: `${config.get('designURL')}#/elements/${get(
          file,
          'data.id'
        )}/briefElements`,
      };
    default:
      return { children: null, href: '' };
  }
};

export const getThumbnailBackgroundImage = (
  contentType: string,
  mimeType: string,
  cloudFrontUrl: string,
  backupUrl: string
) => {
  let backgroundImage = '';
  switch (contentType) {
    case PROJECT_CONTENT_TYPE.FILE:
      backgroundImage = mimeType.includes('image/')
        ? cloudFrontUrl || backupUrl
        : DefaultFileContent;
      break;
    case PROJECT_CONTENT_TYPE.DESIGN_FILE:
      backgroundImage = mimeType.includes('image/')
        ? cloudFrontUrl || backupUrl
        : DefaultDesignFileContent;
      break;
    case PROJECT_CONTENT_TYPE.WEB_LINK:
      backgroundImage = WebLinkContent;
      break;
    case PROJECT_CONTENT_TYPE.DESIGN:
      backgroundImage = DesignContent;
      break;
    default:
      break;
  }
  return backgroundImage;
};

export const sortFiles = (
  files: ProjectContent[],
  sorting: { orderBy: OrderByValues | null; order: SortingType }
) => {
  switch (sorting.orderBy) {
    case PROJECT_CONTENT_TABLE_SORTING.NAME:
      return naturalSort(
        [...files],
        sorting.orderBy as keyof ProjectContent,
        sorting.order
      );
    case PROJECT_CONTENT_TABLE_SORTING.ADDED_BY:
      const sortedByUploadedBy = sortItemsByKey(
        files,
        uploadedBySortKeyGenerator,
        sorting.order
      );

      return sortedByUploadedBy;
    case PROJECT_CONTENT_TABLE_SORTING.ADDED_ON:
      return orderBy(files, ['createdAt'], [sorting.order]);
    default:
      return files;
  }
};
