import { useMemo } from 'react';
import intl from 'react-intl-universal';
import { PROJECT_PRIORITY, PROJECT_STATUS } from 'utils/constants';
import { PROGRAM_PROJECT_TABLE_FILTERS } from 'utils/constants/programProject';
import { Option } from 'utils/customTypes';
import {
  ProgramProjectFilters,
  ProgramProjectFiltersKey,
} from 'utils/types/filters';

const useProgramProjectFilters = (appliedFilters: ProgramProjectFilters) => {
  const statusOptions = useMemo<Option[]>(() => {
    const options = Object.keys(PROJECT_STATUS).map((key) => ({
      value: PROJECT_STATUS[key],
      label: intl
        .get(`PROJECT_DETAIL.STATUS_OPTIONS.${key.toUpperCase()}`)
        .defaultMessage(''),
    }));

    return options;
  }, []);

  const priorityOptions = useMemo<Option[]>(() => {
    const options = Object.keys(PROJECT_PRIORITY).map((key) => ({
      value: PROJECT_PRIORITY[key],
      label: intl
        .get(`PROJECT_DETAIL.PRIORITY_OPTIONS.${key.toUpperCase()}`)
        .defaultMessage(''),
    }));

    return options;
  }, []);

  const progressOptions = useMemo<Option[]>(() => {
    const progressFilter = appliedFilters[
      PROGRAM_PROJECT_TABLE_FILTERS.PROGRESS
    ] as string[];
    if (progressFilter && progressFilter.length > 0) {
      return progressFilter.map((progress) => {
        const parsedValue = isNaN(Number(progress)) ? 0 : Number(progress);
        return {
          value: progress,
          label: Intl.NumberFormat('en-US', {
            style: 'percent',
          }).format(parsedValue / 100),
        };
      });
    } else {
      return [];
    }
  }, [appliedFilters]);

  const getOptionsbyKey = (key: ProgramProjectFiltersKey) => {
    switch (key) {
      case PROGRAM_PROJECT_TABLE_FILTERS.STATUS:
        return statusOptions;
      case PROGRAM_PROJECT_TABLE_FILTERS.PRIORITY:
        return priorityOptions;
      case PROGRAM_PROJECT_TABLE_FILTERS.PROGRESS:
        return progressOptions;
      default:
        return [];
    }
  };

  return {
    statusOptions,
    priorityOptions,
    getOptionsbyKey,
  };
};

export default useProgramProjectFilters;
