import { Button, Icon, Popup } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

type Props = {
  isOpen?: boolean;
  link?: string;
  onClose: () => void;
  onEdit: () => void;
};

const LabelLinkPrompt = ({ isOpen, link, onClose, onEdit }: Props) => {
  return (
    <Popup
      trigger={() => null}
      open={isOpen}
      onClose={onClose}
      popperProps={{
        className: 'z-5 rounded',
      }}
      popperModifiers={[
        {
          name: 'offset',
          enabled: true,
          options: { offset: [0, 12] },
        },
      ]}
      position='top-start'
    >
      {() => (
        <div className='flex'>
          <Button
            onClick={() => {
              onClose();
              onEdit();
            }}
            variant='tertiary'
          >
            {intl.get(
              'SETTINGS_PAGE.FORMS.DESIGN.CUSTOM_QUESTIONS.LABEL_PROMPT.EDIT'
            )}
            <Icon name='pencil' className='ml-2' />
          </Button>
          <Button onClick={onClose} variant='tertiary'>
            <a
              href={link}
              target='_blank'
              rel='noreferrer'
              className='flex items-center'
            >
              {intl.get(
                'SETTINGS_PAGE.FORMS.DESIGN.CUSTOM_QUESTIONS.LABEL_PROMPT.OPEN'
              )}
              <Icon name='open-outline' className='ml-2' />
            </a>
          </Button>
        </div>
      )}
    </Popup>
  );
};

export default LabelLinkPrompt;
