import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Tag } from '@getsynapse/design-system';
import { selectROICalculations } from 'state/ROICategory/ROICategoySlice';
import partyingFace from 'assets/icons/partying-face.svg';
import confusedFace from 'assets/icons/confused-face.svg';

const ROIObjectiveTag = () => {
  const roiCalculatorSelector = useSelector(selectROICalculations);

  const tagProps = useMemo(() => {
    if (
      roiCalculatorSelector.returnOnInvestmentPercentage === null ||
      roiCalculatorSelector.returnOnInvestmentObjectivePercentage === null
    ) {
      return {
        label: intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE_RESULT.NO_RESULTS'
        ),
        className: 'bg-neutral-lighter-two py-1 px-2',
        textClassName: 'text-neutral-dark font-semibold',
        leadingIcon: undefined,
      };
    } else if (
      roiCalculatorSelector.returnOnInvestmentPercentage >=
      roiCalculatorSelector.returnOnInvestmentObjectivePercentage
    ) {
      return {
        label: intl.getHTML(
          'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE_RESULT.MET'
        ),
        className: 'bg-secondary-lightest py-1 px-2',
        textClassName: 'text-secondary-darker font-semibold',
        leadingIcon: { src: partyingFace },
      };
    } else {
      return {
        label: intl.getHTML(
          'PROGRAM_PAGE.STRATEGY_PAGE.OBJECTIVE.OBJECTIVE_RESULT.NOT_MET'
        ),
        className: 'bg-tertiary-lightest py-1 px-2',
        textClassName: 'text-tertiary-dark font-semibold',
        leadingIcon: {
          src: confusedFace,
        },
      };
    }
  }, [
    roiCalculatorSelector.returnOnInvestmentObjectivePercentage,
    roiCalculatorSelector.returnOnInvestmentPercentage,
  ]);

  return <Tag {...tagProps} data-testid='roi-objective-tag' />;
};

export default ROIObjectiveTag;
