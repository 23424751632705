import { useState } from 'react';
import { Modal, Typography, TextArea } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

type ApproveRequestModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onApproveRequest: ({ comments }: { comments?: string | undefined }) => void;
};

const ApproveRequestModal = ({
  isOpen,
  onCancel,
  onApproveRequest,
}: ApproveRequestModalProps) => {
  const [comments, setComments] = useState<string>();

  const handleClose = () => {
    onCancel();
    setComments('');
  };

  const handleApproveRequest = () => {
    onApproveRequest({ comments });
  };

  return (
    <Modal
      aria-label='approve-request-modal'
      actionButtons={[
        {
          children: intl.get(
            'REQUESTS_LIST_PAGE.MODAL.APPROVE_REQUEST.APPROVE_REQUEST'
          ),
          variant: 'primary',
          onClick: handleApproveRequest,
          'data-cy': 'request-approval-button',
        },
        {
          children: intl.get(
            'REQUESTS_LIST_PAGE.MODAL.DELETE_REQUEST.CANCEL_BUTTON'
          ),
          variant: 'secondary',
          onClick: handleClose,
          'data-cy': 'request-approval-cancel-button',
        },
      ]}
      data-testid='approve-request-modal'
      closeModal={handleClose}
      isOpen={isOpen}
      title={intl.get(
        'REQUESTS_LIST_PAGE.MODAL.APPROVE_REQUEST.APPROVE_REQUEST'
      )}
      size='medium'
    >
      <Typography className='mb-5' data-testid='approve-request-call-action'>
        {intl.get('REQUESTS_LIST_PAGE.MODAL.APPROVE_REQUEST.CALL_TO_ACTION')}
      </Typography>

      <TextArea
        label={intl.get('REQUESTS_LIST_PAGE.MODAL.APPROVE_REQUEST.COMMENTS')}
        textAreaProps={{
          placeholder: intl.get(
            'REQUESTS_LIST_PAGE.MODAL.APPROVE_REQUEST.DETAILS'
          ),
          'data-cy': 'field_approve-request-comments',
        }}
        value={comments}
        onChange={(event) => setComments(event.target.value)}
      />
    </Modal>
  );
};

export default ApproveRequestModal;
