import { FC } from 'react';
import { tailwindOverride, useElevation } from '@getsynapse/design-system';
import {
  LinkedTaskContent,
  LinkedTaskContentUploader,
} from 'types/store/linkedTaskContent';
import DownloadableWrapper from './DownloadableWrapper';
import Thumbnail from './Thumbnail';
import ContentDescription from './ContentDescription';

interface LinkedContentCardProps extends LinkedTaskContent {
  uploader: LinkedTaskContentUploader;
}

const LinkedContentCard: FC<LinkedContentCardProps> = ({
  id,
  contentData,
  contentType,
  uploader,
  createdAt,
}) => {
  const shadowElevation = useElevation(2);
  return (
    <DownloadableWrapper
      name={`linked-content-card-${id}`}
      contentData={contentData}
      contentTye={contentType}
    >
      <div
        className={tailwindOverride(
          'w-42 h-36 bg-neutral-white',
          'flex flex-col gap-y-2',
          'px-2 py-2',
          'rounded-sm group cursor-pointer',
          shadowElevation
        )}
      >
        <Thumbnail
          contentData={contentData}
          contentType={contentType}
          canUnlinkContent={true}
          onUnlinkContent={() => {}}
        />
        <ContentDescription
          contentData={contentData}
          contentType={contentType}
          uploader={uploader}
          createdAt={createdAt}
          truncateName={true}
          displayType={contentType !== 'file'}
          className='text-neutral'
        />
      </div>
    </DownloadableWrapper>
  );
};

export default LinkedContentCard;
