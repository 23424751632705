import React, { useMemo } from 'react';
import intl from 'react-intl-universal';
import { Dropdown, FormItem } from '@getsynapse/design-system';
import { Option } from 'utils/customTypes';
import { NewParticipant } from 'utils/types/tasksAssignmentsResourcesCapacity';
import { PROJECT_PARTICIPANT_ROLE } from 'utils/constants';

const ProjectRolePicker: React.FC<{
  currentParticipant?: NewParticipant;
  handleChange: (key: keyof NewParticipant, value: string | string[]) => void;
}> = ({ currentParticipant, handleChange }) => {
  const participantRolesOptions = useMemo(
    () =>
      Object.entries(PROJECT_PARTICIPANT_ROLE).map(([key, value]) => ({
        label: intl.get(
          `PEOPLE.RESOURCE_ALLOCATION.PARTICIPANT_PROJECT_ROLE.${key}`
        ),
        value: value,
      })),
    []
  );

  const selectedRoles = useMemo(
    () =>
      participantRolesOptions.filter((roleOption: Option) =>
        currentParticipant?.job_roles?.includes(roleOption.value)
      ),
    [participantRolesOptions, currentParticipant?.job_roles]
  );

  return (
    <FormItem
      label={intl.get(
        'PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.PARTICIPANT_ROLE_FIELD_LABEL'
      )}
      className='flex-1'
    >
      <Dropdown
        options={participantRolesOptions}
        onChange={(options: Option[]) => {
          const roles = options.map((option) => option.value);
          handleChange('job_roles', roles);
        }}
        placeholder={intl.get(
          'PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.SELECT_ROLE'
        )}
        multiple
        values={selectedRoles}
        triggerProps={{
          'aria-label': intl.get(
            'PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.PARTICIPANT_ROLE_FIELD_LABEL'
          ),
          'data-cy': 'participant-roles-picker',
        }}
      />
    </FormItem>
  );
};

export default ProjectRolePicker;
