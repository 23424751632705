import { combineReducers } from '@reduxjs/toolkit';

import allTasksSlice from './allTasks/allTasksSlice';
import teamTasksSlice from './teamTasks/teamTasksSlice';
import myTasksSlice from './myTasks/myTasksSlice';
import searchSlice from './Search/searchSlice';

export default combineReducers({
  allTasks: allTasksSlice,
  teamTasks: teamTasksSlice,
  myTasks: myTasksSlice,
  search: searchSlice,
});
