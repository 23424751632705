import { FC } from 'react';
import TableTabs from './TableTabs/TableTabs';
import ProjectProgress from './ProjectProgress/ProjectProgress';
import TaskDetailSidePanel from './TaskDetailSidePanel/TaskDetailSidePanel';
import CreateTaskModal from 'Pages/TasksListPage/components/v2/CreateTaskModal/CreateTaskModal';

interface ProjectTasksListProps {
  projectId: string;
}

const ProjectTasksList: FC<ProjectTasksListProps> = ({ projectId }) => {
  return (
    <div className='w-full h-full relative'>
      <TaskDetailSidePanel />
      <div className='absolute left-64 top-0 ml-4 h-8'>
        <ProjectProgress />
      </div>
      <div className='absolute top-0 right-0 w-28'>
        <CreateTaskModal isCentralizedPage={false} projectId={projectId} />
      </div>
      <TableTabs />
    </div>
  );
};

export default ProjectTasksList;
