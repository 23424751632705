import { AxiosInstance } from 'axios';
import get from 'lodash/get';
import { initAxios } from 'utils/axios';
// import { initAxios, getUserToken } from 'utils/axios';
import config from 'config/Config';
import { objKeyAsString } from 'utils/customTypes';

class ExportAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  exportCsv = async (
    csvHeaders: string[],
    csvData: objKeyAsString,
    fileName: string
  ) => {
    const { data } = await this.instance.post('export/', {
      exportData: { csvHeaders, csvData, fileName },
    });
    return data;
  };

  exportRequestPdf = async (requestId: string, fileName: string) => {
    const { data } = await this.instance.post('export/downloadPdf', {
      exportData: { requestId, fileName },
    });
    return data;
  };

  exportRequests = async (requestIds: string[]) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await this.instance.post('export/exportRequestsAsCsv', {
      requestIds,
      timeZone,
    });
    if (response.data && response.status === 200) {
      return response.data;
    }
    const isAxiosError = get(response, 'isAxiosError', false);
    if (isAxiosError) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
  };

  exportRequestsByForm = async (requestIds: string[]) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await this.instance.post(
      'export/exportRequestsByFormAsCsv',
      {
        requestIds,
        timeZone,
      }
    );
    if (response.data && response.status === 200) {
      return response.data;
    }
    const isAxiosError = get(response, 'isAxiosError', false);
    if (isAxiosError) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
  };

  exportUsers = async (userIds: string[]) => {
    const response = await this.instance.post('export/exportUsersAsCsv', {
      userIds,
    });
    if (response.data && response.status === 200) {
      return response.data;
    }
    const isAxiosError = get(response, 'isAxiosError', false);
    if (isAxiosError) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
  };

  exportTasks = async (taskIds: string[]) => {
    const response = await this.instance.post('export/exportTasksAsCsv', {
      taskIds,
    });
    if (response.data && response.status === 200) {
      return response.data;
    }
    const isAxiosError = get(response, 'isAxiosError', false);
    if (isAxiosError) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
  };

  exportOrgTasks = async (tabName?: string) => {
    const response = await this.instance.get(`export/exportOrgTasksAsCsv`, {
      params: {
        tabName,
      },
    });
    if (response.data && response.status === 200) {
      return response.data;
    }
    const isAxiosError = get(response, 'isAxiosError', false);
    if (isAxiosError) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
  };

  exportProjectTasks = async (projectId: string, tabName: string) => {
    const response = await this.instance.post(
      'export/exportProjectTasksAsCsv',
      {
        projectId,
        tabName,
      }
    );
    if (response.data && response.status === 200) {
      return response.data;
    }
    const isAxiosError = get(response, 'isAxiosError', false);
    if (isAxiosError) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
  };

  exportTeamMembers = async (userIds: string[]) => {
    const response = await this.instance.post('export/exportTeamMembersAsCsv', {
      userIds,
    });
    if (response.data && response.status === 200) {
      return response.data;
    }
    const isAxiosError = get(response, 'isAxiosError', false);
    if (isAxiosError) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
  };
}

const exportApi = new ExportAPI();

export default exportApi;
