import { useMemo, ChangeEvent, Fragment } from 'react';
import {
  Typography,
  FormItem,
  TextField,
  UsersPicker,
  TextArea,
} from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import { SHORT_INPUTS_LENGTH, REQUEST_TYPE } from 'utils/constants';
import {
  Form,
  UserAvatars,
  FormOption,
  Option,
  UserOption,
} from 'utils/customTypes';
import BusinessUnitPicker from 'Molecules/BusinessUnitPicker/BusinessUnitPicker';
import TypePicker from 'Molecules/TypePicker/TypePicker';

type FormSummaryProps = {
  formObj: Form;
  handleChangeField: (
    value: string | string[] | boolean,
    pathToUpdate: string
  ) => void;
  ldUsers: UserAvatars[];
  errors?: {
    title: boolean;
    description: boolean;
    requestType: boolean;
    businessTeams: boolean;
    owners: boolean;
  };
  businessTeamsOptions: FormOption[];
};

const FormSummary = ({
  formObj,
  handleChangeField,
  ldUsers,
  errors = {
    title: false,
    description: false,
    requestType: false,
    businessTeams: false,
    owners: false,
  },
  businessTeamsOptions,
}: FormSummaryProps) => {
  const selectedOwners = useMemo(() => {
    const owners = get(formObj, 'owners', []);
    if (!isEmpty(owners)) {
      const options = ldUsers.filter((option) =>
        owners.find((owner) => (get(owner, 'id') || owner) === option.value)
      );
      return options || [];
    } else {
      return [];
    }
  }, [formObj, ldUsers]);

  const changeOwners = (owners: UserOption[]) => {
    const ownersIds = owners.map((owner) => owner.value);
    handleChangeField(ownersIds, 'owners');
  };

  const selectedBusinessTeam = useMemo(() => {
    const businessTeams = get(formObj, 'businessTeams', []);
    if (!isEmpty(businessTeams)) {
      const options = businessTeamsOptions.filter((option) =>
        businessTeams.find((team) => (get(team, 'id') || team) === option.value)
      );
      return options || [];
    } else {
      return [];
    }
  }, [formObj, businessTeamsOptions]);

  const changeBusinessTeams = (businessTeams: FormOption[]) => {
    const businessTeamsIds = businessTeams.map((team) => team.value);
    handleChangeField(businessTeamsIds, 'businessTeams');
  };

  return (
    <Fragment>
      <Typography variant='h5' className='mt-8 text-neutral-black'>
        {intl.get('SETTINGS_PAGE.FORMS.SUMMARY.TITLE')}
      </Typography>
      <Typography variant='caption' className='mb-4 text-neutral'>
        {intl.get('SETTINGS_PAGE.FORMS.SUMMARY.SUBTITLE')}
      </Typography>
      <form className='grid grid-cols-2 gap-x-20 gap-y-6 pt-6'>
        <FormItem
          helpText={
            errors.title &&
            intl.get('SETTINGS_PAGE.USER_PAGE.ERRORS.MISSING_INFORMATION')
          }
          helpTextProps={{
            state: errors.title ? 'error' : 'default',
          }}
        >
          <TextField
            label={intl.get('SETTINGS_PAGE.FORMS.SUMMARY.FORM_TITLE')}
            labelProps={{
              required: true,
              state: errors.title ? 'error' : 'default',
            }}
            displayCharactersCount
            maxLength={SHORT_INPUTS_LENGTH}
            placeholder={intl.get('SETTINGS_PAGE.FORMS.SUMMARY.ENTER', {
              field: intl.get('SETTINGS_PAGE.FORMS.SUMMARY.FORM_TITLE'),
            })}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeField(e.target.value, 'title')
            }
            value={get(formObj, 'title')}
            state={errors.title ? 'error' : 'default'}
            data-cy='form-title_input'
          />
        </FormItem>

        <FormItem
          label={intl.get('SETTINGS_PAGE.FORMS.SUMMARY.DEFAULT_OWNERS')}
          labelProps={{
            required: true,
            state: errors.owners ? 'error' : 'default',
          }}
          helpText={
            errors.owners &&
            intl.get('SETTINGS_PAGE.USER_PAGE.ERRORS.MISSING_INFORMATION')
          }
          helpTextProps={{
            state: errors.owners ? 'error' : 'default',
          }}
        >
          <UsersPicker
            usersList={ldUsers}
            onChange={changeOwners}
            selectedUsersList={selectedOwners}
            triggerText={intl.get('SETTINGS_PAGE.FORMS.SUMMARY.REQUEST_OWNER')}
            maxLimit={6}
            triggerProps={{
              type: 'button',
              'data-cy': 'form-owner_selector',
              className: classnames({ 'border-error-dark': errors.owners }),
            }}
          />
        </FormItem>

        <TypePicker
          formItemProps={{
            label: intl.get('SETTINGS_PAGE.FORMS.REQUEST_TYPE'),
            labelProps: {
              required: true,
              state: errors.requestType ? 'error' : 'default',
            },
            helpText:
              errors.requestType &&
              intl.get('SETTINGS_PAGE.USER_PAGE.ERRORS.MISSING_INFORMATION'),
            helpTextProps: {
              state: errors.requestType ? 'error' : 'default',
            },
          }}
          dropdownProps={{
            onChange: (option: Option) =>
              handleChangeField(option.value, 'request_type'),
            triggerProps: {
              className: 'h-10',
              placeholder: intl.get(
                'SETTINGS_PAGE.FORMS.SUMMARY.SELECT_REQUEST_TYPE'
              ),
              state: errors.requestType ? 'error' : 'default',
              'data-cy': 'form-request_type',
            },
            listProps: {
              'data-cy': 'form-request_type-options_list',
            },
          }}
          currentValue={get(formObj, 'request_type') || ''}
          options={REQUEST_TYPE}
          labelRoute='REQUEST_PAGE.LEFT_PANEL.REQUEST_TYPE'
        />

        <FormItem
          className='row-span-2'
          label={intl.get('SETTINGS_PAGE.FORMS.SUMMARY.DESCRIPTION')}
          labelProps={{
            required: true,
            state: errors.description ? 'error' : 'default',
          }}
          helpText={
            errors.description &&
            intl.get('SETTINGS_PAGE.USER_PAGE.ERRORS.MISSING_INFORMATION')
          }
          helpTextProps={{
            state: errors.description ? 'error' : 'default',
          }}
        >
          <TextArea
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              handleChangeField(e.target.value, 'form_description')
            }
            value={get(formObj, 'form_description') || ''}
            textAreaProps={{
              className: 'h-30',
              placeholder: intl.get(
                'SETTINGS_PAGE.FORMS.SUMMARY.ENTER_DESCRIPTION'
              ),
              'data-cy': 'form-description_input',
            }}
            state={errors.description ? 'error' : 'default'}
          />
        </FormItem>

        <BusinessUnitPicker
          businessUnits={businessTeamsOptions}
          formItemProps={{
            label: intl.get('REQUEST_PAGE.BASIC_DETAILS.FIELDS.BUSINESS_UNIT'),
            labelProps: {
              required: true,
              state: errors.businessTeams ? 'error' : 'default',
            },
            helpText:
              errors.businessTeams &&
              intl.get('SETTINGS_PAGE.USER_PAGE.ERRORS.MISSING_INFORMATION'),
            helpTextProps: {
              state: errors.businessTeams ? 'error' : 'default',
            },
          }}
          dropdownProps={{
            values: selectedBusinessTeam,
            onChange: changeBusinessTeams,
            multiple: true,
            placeholder: intl.get(
              'SETTINGS_PAGE.FORMS.DESIGN.SELECT_BUSINESS_UNIT'
            ),
            triggerProps: { 'data-cy': 'dropdown' },
            listProps: { 'data-cy': 'multiple-dropdown__options-list' },
          }}
        />
      </form>
    </Fragment>
  );
};

export default FormSummary;
