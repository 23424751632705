import {
  MethodQuestionWithPresetValues,
  EvaluationPlanItem,
} from 'utils/types/program';
import {
  METHOD_QUESTIONS,
  DEFAULT_EMPTY_COLLECTION_METHOD_ANSWER,
} from 'utils/constants/program';
import { OptionWithKey } from 'utils/customTypes';

export const getDefaultDataForNewMethod = (
  methodsOptions: OptionWithKey[],
  methodId: string,
  methodQuestionsAndOptions: {
    [key: string]: MethodQuestionWithPresetValues[];
  },
  evaluationPlanData: EvaluationPlanItem[]
) => {
  const methodExists = evaluationPlanData.find(
    (method) => method.data_collection_method_id === methodId
  );

  if (methodExists) {
    return methodExists;
  }

  const desiredBehavioursQuestion = methodQuestionsAndOptions[methodId]?.find(
    (question) => question.question_name === METHOD_QUESTIONS.DESIRED_BEHAVIOURS
  );

  const newMethod = {
    collection_method_name:
      methodsOptions.find((option) => option.key === methodId)?.label || '',
    data_collection_method_id: methodId,
    collection_method_selected_options: desiredBehavioursQuestion
      ? [
          {
            question_id: desiredBehavioursQuestion.question_id,
            question_name: desiredBehavioursQuestion.question_name,
            questions_answers: [
              {
                ...DEFAULT_EMPTY_COLLECTION_METHOD_ANSWER,
                desired_behaviours: [
                  {
                    behaviour_text: '',
                  },
                ],
              },
            ],
          },
        ]
      : [],
  };

  return newMethod as EvaluationPlanItem;
};
