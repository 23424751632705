import { Icon, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import monetaryBenefitsIcon from 'assets/icons/total-monetary-benefits.svg';

type Props = {
  annualTotal: number;
};

const MonetaryBenefitsTotalCard = ({ annualTotal }: Props) => (
  <div
    className='rounded border border-secondary-light mt-8 py-4 px-6 flex justify-between items-center'
    data-testid='annual-total_card'
  >
    <div className='flex items-center'>
      <Icon src={monetaryBenefitsIcon} className='h-10 w-10' />
      <Typography weight='medium' className='mx-4'>
        {intl.get(
          'PROGRAM_PAGE.STRATEGY_PAGE.CATEGORY.TOTAL_MONETARY_BENEFITS'
        )}
      </Typography>
    </div>
    <Typography data-testid='annual-total-value'>
      {intl.get('DOLLAR_SIGN')}
      {annualTotal?.toLocaleString('en-US')}
    </Typography>
  </div>
);

export default MonetaryBenefitsTotalCard;
