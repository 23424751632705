const ROIObjectiveSkeletonLoader = () => (
  <div
    className='flex-grow overflow-y-auto bg-neutral-white'
    data-testid='roi-objevtive_skeleton_loader'
  >
    <div className='h-full flex flex-col'>
      <div className='h-14 flex items-center px-6'>
        <div className='h-6 w-1/2 bg-neutral-lighter-two animate-pulse' />
      </div>
      <div className='mb-6 mx-6 rounded-lg border border-neutral-lighter-two flex-grow'>
        <div className='border-b border-neutral-lighter-two h-12 px-6 flex items-center'>
          <div className='h-4 w-2/12 bg-neutral-lighter-two animate-pulse' />
        </div>
        <div className='p-8'>
          <div className='h-4 w-3/12 bg-neutral-lighter-two animate-pulse' />
          <div className='flex mt-7'>
            <div className='rounded-circle w-10 h-10 bg-neutral-lighter-two animate-pulse mr-4' />
            <div className='w-full'>
              <div className='h-4 w-3/12 bg-neutral-lighter-two animate-pulse mb-2' />
              <div className='h-4 w-1/2 bg-neutral-lighter-two animate-pulse mb-5' />
              <div className='h-10 w-1/2 bg-neutral-lighter-two animate-pulse' />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ROIObjectiveSkeletonLoader;
