import React, { useState, useEffect } from 'react';
import { SidePanelFilter } from '@getsynapse/design-system';
import { FieldsFilter } from 'utils/types/filters';
import FieldsFiltersForms from './FieldsFiltersForms';

export type Props = {
  open: boolean;
  onUpdateFilters: (filters: FieldsFilter) => void;
  onClose: () => void;
  filters?: FieldsFilter;
};

const FieldsFiltersSidePanel: React.FC<Props> = ({
  open,
  filters = {},
  onUpdateFilters,
  onClose,
}) => {
  const [appliedFilters, setAppliedFilters] = useState<FieldsFilter>(
    {} as FieldsFilter
  );
  const [canApplyFilters, setCanApplyFilters] = useState<boolean>(false);

  useEffect(() => {
    if (filters) {
      setAppliedFilters(filters as FieldsFilter);
    }
  }, [filters]);

  const handleUpdateFilters = (updatedFilters: FieldsFilter) => {
    setAppliedFilters(updatedFilters);
    setCanApplyFilters(true);
  };

  const handleApplyFilters = () => {
    onUpdateFilters(appliedFilters);
    setCanApplyFilters(false);
  };

  const handleClearAllFilters = () => {
    onUpdateFilters({} as FieldsFilter);
    setAppliedFilters({} as FieldsFilter);
    setCanApplyFilters(false);
  };

  return (
    <SidePanelFilter
      open={open}
      onClose={onClose}
      appliedFiltersCount={Object.keys(appliedFilters).length}
      canApplyFilters={canApplyFilters}
      onClearAllFilters={handleClearAllFilters}
      onApplyFilters={handleApplyFilters}
      data-testid='fields-filters-side-panel'
    >
      <FieldsFiltersForms
        filters={appliedFilters as FieldsFilter}
        onUpdateFilters={handleUpdateFilters}
      />
    </SidePanelFilter>
  );
};

export default FieldsFiltersSidePanel;
