import React, { useEffect, useMemo, useRef, useState } from 'react';

import intl from 'react-intl-universal';
import { MentionsInput, Mention, MentionItem, DataFunc } from 'react-mentions';
import { Button, tailwindOverride } from '@getsynapse/design-system';
import { CustomSuggestion, UserDataSuggestion } from './CustomSuggestion';
import { useSelector } from 'react-redux';
import { getMentionableProjectParticipants } from 'state/Project/projectSlice';
import { Mentions } from 'utils/types/comments';
import { EmptySuggestion } from './EmptySuggestion';

export type MentionableCommentFormProps = {
  placeholder?: string;
  onSubmitComment: (content: string, mentions: MentionItem[]) => Promise<void>;
  onCancel?: () => void;
  inputClassName?: string;
  disabled?: boolean;
  defaultContent?: string;
  defaultMentions?: Mentions;
};

const formatContent = (content: string) => {
  const normalizedContent = content.replace(/@\[[^\]]+\]\(([^)]+)\)/g, '@($1)');
  return normalizedContent;
};

const formatContentForEdit = (content: string, mentions: Mentions) => {
  return content.replace(/@\(([^)]+)\)/g, (match, p1) => {
    const user = mentions[p1];
    if (user) {
      return `@[${user.username}](${user.id})`;
    } else {
      return match;
    }
  });
};

const MentionableCommentForm = ({
  placeholder,
  onSubmitComment,
  onCancel = () => {},
  inputClassName,
  disabled = false,
  defaultContent,
  defaultMentions,
}: MentionableCommentFormProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const formattedContent = useMemo(
    () => formatContentForEdit(defaultContent || '', defaultMentions || {}),
    [defaultContent, defaultMentions]
  );

  const defaultMentionsTransformed = useMemo(() => {
    if (!defaultMentions) {
      return [];
    }
    const mentions: MentionItem[] = [];
    Object.keys(defaultMentions || {}).forEach((key) => {
      mentions.push({
        id: key,
        display: defaultMentions[key].username,
        type: null,
      });
    });
    return mentions;
  }, [defaultMentions]);

  const [commentContent, setCommentContent] = useState(formattedContent || '');
  const [mentionsData, setMentionsData] = useState<MentionItem[]>(
    defaultMentionsTransformed || []
  );
  const mentionableUsers = useSelector(getMentionableProjectParticipants);
  const userOptions = useMemo(
    () =>
      mentionableUsers.map((user) => ({
        id: user.value,
        display: user.label,
        avatar: user.avatar,
        disabled: user.disabled,
      })),
    [mentionableUsers]
  );

  const isMounted = useRef(false);
  // Custom suggestion filter
  const filterSuggestions: DataFunc = (query, callback) => {
    const filteredSuggestions = userOptions.filter((item) =>
      item.display.toLowerCase().includes(query.toLowerCase())
    );

    if (filteredSuggestions.length === 0) {
      callback([{ id: 'no-results' }]);
    } else {
      callback(filteredSuggestions);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleDisplayTransform = (_: string, display: string) => `@${display}`;

  const handleSaveCommentClick = async () => {
    if (!commentContent || commentContent === '') {
      return;
    }

    await onSubmitComment(formatContent(commentContent), mentionsData);
    if (isMounted.current) {
      setCommentContent(defaultContent || '');
      setMentionsData([]);
    }
  };

  const handleCancelClick = () => {
    // Remove focus from the input and the container
    inputRef.current?.blur();
    containerRef.current?.blur();
    buttonRef.current?.blur();
    setCommentContent(defaultContent || '');
    setMentionsData([]);
    onCancel();
  };

  return (
    <div
      className={tailwindOverride(
        'w-full flex flex-col bg-neutral-white p-2 border border-neutral-lighter-two rounded',
        `${inputClassName || 'mention-comment-v2'}--container`
      )}
      ref={containerRef}
    >
      <MentionsInput
        value={commentContent}
        onChange={(_, newValue, __, mentions) => {
          setCommentContent(newValue);
          setMentionsData(
            mentions.filter((mention) => mention.id !== 'no-results')
          );
        }}
        placeholder={placeholder}
        className={inputClassName || 'mention-comment-v2'}
        data-cy='add-new-comment'
        disabled={disabled}
        inputRef={inputRef}
        suggestionsPortalHost={containerRef.current || undefined}
      >
        <Mention
          appendSpaceOnAdd
          trigger='@'
          data={filterSuggestions}
          displayTransform={handleDisplayTransform}
          className={`${inputClassName || 'mention-comment-v2'}--mention`}
          renderSuggestion={(
            suggestion: UserDataSuggestion,
            search: string,
            highlightedDisplay: React.ReactNode,
            index: number,
            focused: boolean
          ) => {
            return (
              <>
                {suggestion.id === 'no-results' ? (
                  <EmptySuggestion />
                ) : (
                  <CustomSuggestion
                    suggestion={suggestion}
                    search={search}
                    highlightedDisplay={highlightedDisplay}
                    index={index}
                    focused={focused}
                  />
                )}
              </>
            );
          }}
        />
      </MentionsInput>

      <div
        className={tailwindOverride(
          `${inputClassName || 'mention-comment-v2'}--buttons`
        )}
      >
        <Button variant='secondary' ref={buttonRef} onClick={handleCancelClick}>
          {intl.get('CANCEL')}
        </Button>
        <Button
          variant='primary'
          onClick={handleSaveCommentClick}
          className={tailwindOverride({
            'mention-comment-v2-focusable-disabled':
              !commentContent || commentContent === '',
          })}
        >
          {intl.get(defaultContent ? 'UPDATE' : 'ADD')}
        </Button>
      </div>
    </div>
  );
};

export default MentionableCommentForm;
