import taskCompletedIcon from 'assets/icons/task_completed.svg';
import taskOverdueIcon from 'assets/icons/task_overdue.svg';
import taskActualTimeIcon from 'assets/icons/task_actual_time.svg';
import taskDueAgeIcon from 'assets/icons/task_due_age.svg';
import unassignedIcon from 'assets/icons/remaining.svg';
import availabilityIcon from 'assets/icons/availability.svg';
import numberOFResourcesIcon from 'assets/icons/numOfResource.svg';
import utilizationIcon from 'assets/icons/utilization.svg';
import { Icon, Typography, Tooltip } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

import PieChartComponent from '../charts/PieChart';
import type { TaskMetrics } from 'utils/types/taskMetrics';
import type { ProjectResourceMetrics } from 'utils/types/projectResourceMetrics';

const ProjectInsights = ({
  taskDetails,
  resourceDetails,
  projectTargetCompletionDate,
}: {
  taskDetails: TaskMetrics;
  resourceDetails: ProjectResourceMetrics;
  projectTargetCompletionDate: string | null;
}) => {
  const taskCompletedClasses =
    'flex h-1/2 pl-4 pt-7.5 pb-4 border-r border-b border-neutral-lighter-two';
  const estActualHoursClasses =
    'flex h-1/2 pl-4 pt-7.5 pb-4 border-b border-neutral-lighter-two';
  const overdueTasksClasses =
    'flex h-1/2 pl-4 pt-4 pb-7.5 border-r border-neutral-lighter-two';
  const avgOverdueAgeClasses =
    'flex h-1/2 pl-4 pt-4 pb-7.5 border-neutral-lighter-two';
  const leftSectionDivClasses = 'h-full border-r border-neutral-lighter-two';
  const insightsValueClasses = 'flex flex-col semi-bold pl-1';
  const insightsTextClasses = 'text-neutral-darker pl-1';
  const insightsIconClasses = 'text-2xl z-0';

  const padNumberWithLeadingZeroes = (number: number) => {
    return String(number).padStart(2, '0');
  };

  const getAverageOverdueAge = (overdueAgeInDays: number) => {
    const overdueAge = overdueAgeInDays ? overdueAgeInDays : 0;
    const decimalPart = overdueAge - Math.floor(overdueAge);

    if (decimalPart < 0.25) {
      return Math.floor(overdueAge);
    } else if (decimalPart >= 0.25 && decimalPart <= 0.75) {
      return Math.floor(overdueAge) + 0.5;
    } else {
      return Math.ceil(overdueAge);
    }
  };

  const isDueDatePassed = () => {
    if (projectTargetCompletionDate === null) return true;

    const dueDateObject = new Date(projectTargetCompletionDate);
    dueDateObject.setHours(0, 0, 0, 0);

    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return dueDateObject < now;
  };

  return (
    <div id='project-insights' className='mt-4'>
      <Typography
        variant='h5'
        className='px-6 text-primary pb-2 border-b border-neutral-lighter-two'
      >
        {intl.get('PROJECT_DETAIL.INSIGHTS.SUMMARY')}
      </Typography>
      <div className='grid grid-cols-2 w-full h-56'>
        <div className={leftSectionDivClasses}>
          <div className='flex'>
            <div className='flex-row w-2/5 h-40'>
              <Typography variant='h5' className='pt-2 px-6 text-primary'>
                {intl.get('PROJECT_DETAIL.INSIGHTS.BY_TASKS.TASKS')}
              </Typography>
              <Typography className=' px-6 text-neutral text-base'>
                {intl.get('PROJECT_DETAIL.INSIGHTS.BY_TASKS.BY_STATUS')}
              </Typography>
              <PieChartComponent taskDetails={taskDetails} showLegend={false} />
            </div>
            <div className='w-3/5 flex flex-row h-full pt-6'>
              <div className='w-1/2 flex-col'>
                <div className={taskCompletedClasses}>
                  <Icon
                    data-testid='tasks_completed_icon'
                    src={taskCompletedIcon}
                    className={insightsIconClasses}
                  />
                  <div>
                    <Typography variant='body2' className={insightsTextClasses}>
                      {intl.get(
                        'PROJECT_DETAIL.INSIGHTS.BY_TASKS.TASKS_COMPLETED'
                      )}
                    </Typography>
                    <Typography
                      data-testid='tasks_completed_value'
                      variant='body'
                      className={insightsValueClasses}
                    >
                      {padNumberWithLeadingZeroes(taskDetails.completed)}/
                      {padNumberWithLeadingZeroes(taskDetails.total)}
                    </Typography>
                  </div>
                </div>
                <div className={overdueTasksClasses}>
                  <Icon
                    data-testid='tasks_overdue_icon'
                    src={taskOverdueIcon}
                    className={insightsIconClasses}
                  />
                  <div>
                    <Typography variant='body2' className={insightsTextClasses}>
                      {intl.get(
                        'PROJECT_DETAIL.INSIGHTS.BY_TASKS.OVERDUE_TASKS'
                      )}
                    </Typography>
                    <Typography
                      data-testid='tasks_overdue_value'
                      variant='body'
                      className={insightsValueClasses}
                    >
                      {padNumberWithLeadingZeroes(taskDetails.overdue)}/
                      {padNumberWithLeadingZeroes(taskDetails.total)}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className='w-1/2 flex-col '>
                <div className={estActualHoursClasses}>
                  <Icon
                    data-testid='est_actual_hours_icon'
                    src={taskActualTimeIcon}
                    className={insightsIconClasses}
                  />
                  <div>
                    <Typography variant='body2' className={insightsTextClasses}>
                      {intl.get(
                        'PROJECT_DETAIL.INSIGHTS.BY_TASKS.ESTIMATED_VS_ACTUAL_HOURS'
                      )}
                    </Typography>
                    <Typography
                      data-testid='est_actual_hours_value'
                      variant='body'
                      className={insightsValueClasses}
                    >
                      {padNumberWithLeadingZeroes(taskDetails.estimateHours)}
                      {intl.get(
                        'PROJECT_DETAIL.INSIGHTS.BY_TASKS.HOURS'
                      )} | {padNumberWithLeadingZeroes(taskDetails.actualHours)}
                      {intl.get('PROJECT_DETAIL.INSIGHTS.BY_TASKS.HOURS')}
                    </Typography>
                  </div>
                </div>
                <div className={avgOverdueAgeClasses}>
                  <Icon
                    data-testid='overdue_age_icon'
                    src={taskDueAgeIcon}
                    className={insightsIconClasses}
                  />
                  <div>
                    <Typography variant='body2' className={insightsTextClasses}>
                      {intl.get(
                        'PROJECT_DETAIL.INSIGHTS.BY_TASKS.AVERAGE_OVERDUE_AGE'
                      )}
                    </Typography>
                    <Typography
                      data-testid='overdue_age_value'
                      variant='body'
                      className={insightsValueClasses}
                    >
                      {getAverageOverdueAge(taskDetails.overdueAgeInDays)}{' '}
                      {!taskDetails.overdueAgeInDays ||
                      taskDetails.overdueAgeInDays !== 1
                        ? intl.get('PROJECT_DETAIL.INSIGHTS.BY_TASKS.DAYS')
                        : intl.get('PROJECT_DETAIL.INSIGHTS.BY_TASKS.DAY')}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-testid='resources-insights'
          className='flex h-full flex-row w-full h-40'
        >
          <Typography variant='h5' className='pt-2 px-6 text-primary'>
            {intl.get('PROJECT_DETAIL.INSIGHTS.RESOURCES.TITLE')}
          </Typography>
          <div className='flex w-full h-full flex-row pt-6'>
            <div className='w-1/2 flex-col'>
              <div className='flex h-1/2 pl-4 pt-7.5 pb-4 border-r border-b border-neutral-lighter-two'>
                <Icon
                  data-testid='number_of_resources_icon'
                  src={numberOFResourcesIcon}
                  className={insightsIconClasses}
                />
                <div>
                  <Tooltip
                    trigger={
                      <Typography
                        variant='body2'
                        className={insightsTextClasses}
                      >
                        {intl.get(
                          'PROJECT_DETAIL.INSIGHTS.RESOURCES.NUMBER_OF_RESOURCES'
                        )}
                      </Typography>
                    }
                    position='bottomRight'
                    timeout={0}
                    contentProps={{
                      className:
                        'z-50 bg-neutral-white border border-purple-lighter',
                    }}
                    openMode='hover1'
                  >
                    <Typography
                      variant='body2'
                      weight='medium'
                      className='text-purple-darker'
                    >
                      {intl.get(
                        'PROJECT_DETAIL.INSIGHTS.RESOURCES.NUMBER_OF_RESOURCES'
                      )}
                    </Typography>
                    <Typography variant='body2' className='text-purple-darker'>
                      {intl.get(
                        'PROJECT_DETAIL.INSIGHTS.RESOURCES.NUMBER_OF_RESOURCES_TOOLTIP'
                      )}
                    </Typography>
                  </Tooltip>
                  <Typography
                    data-testid='number_of_resources'
                    variant='body'
                    className='flex flex-col semi-bold pl-1'
                  >
                    {padNumberWithLeadingZeroes(
                      resourceDetails.uniqueProjectParticipants
                    )}
                  </Typography>
                </div>
              </div>
              <div className={overdueTasksClasses}>
                <Icon
                  data-testid='unassigned_icon'
                  src={unassignedIcon}
                  className={insightsIconClasses}
                />
                <div>
                  <Tooltip
                    trigger={
                      <Typography
                        variant='body2'
                        className={insightsTextClasses}
                      >
                        {intl.get(
                          'PROJECT_DETAIL.INSIGHTS.RESOURCES.UNASSIGNED_HOURS'
                        )}
                        {' | '}
                        {intl.get(
                          'PROJECT_DETAIL.INSIGHTS.RESOURCES.REMAINING_HOURS'
                        )}
                      </Typography>
                    }
                    position='bottomLeft'
                    timeout={0}
                    contentProps={{
                      className:
                        'z-50 bg-neutral-white border border-purple-lighter',
                    }}
                    openMode='hover1'
                  >
                    <Typography
                      variant='body2'
                      weight='medium'
                      className='text-purple-darker'
                    >
                      {intl.get(
                        'PROJECT_DETAIL.INSIGHTS.RESOURCES.UNASSIGNED_HOURS'
                      )}{' '}
                      {intl.get('PROJECT_DETAIL.INSIGHTS.RESOURCES.HOURS')}
                    </Typography>
                    <Typography variant='body2' className='text-purple-darker'>
                      {intl.get(
                        'PROJECT_DETAIL.INSIGHTS.RESOURCES.UNASSIGNED_HOURS_TOOLTIP'
                      )}
                    </Typography>
                    <Typography
                      variant='body2'
                      weight='medium'
                      className='text-purple-darker'
                    >
                      {intl.get(
                        'PROJECT_DETAIL.INSIGHTS.RESOURCES.REMAINING_HOURS'
                      )}{' '}
                      {intl.get('PROJECT_DETAIL.INSIGHTS.RESOURCES.HOURS')}
                    </Typography>
                    <Typography variant='body2' className='text-purple-darker'>
                      {intl.get(
                        'PROJECT_DETAIL.INSIGHTS.RESOURCES.REMAINING_HOURS_TOOLTIP'
                      )}
                    </Typography>
                  </Tooltip>

                  <Typography
                    data-testid='unassigned_remaining_value'
                    variant='body'
                    className={insightsValueClasses}
                  >
                    {isDueDatePassed() ? (
                      'NA'
                    ) : (
                      <>
                        {padNumberWithLeadingZeroes(
                          resourceDetails.estimatedHoursUnassigned
                        )}
                        {intl.get('PROJECT_DETAIL.INSIGHTS.BY_TASKS.HOURS')} |{' '}
                        {padNumberWithLeadingZeroes(
                          resourceDetails.estimatedHoursRemaining
                        )}
                        {intl.get('PROJECT_DETAIL.INSIGHTS.BY_TASKS.HOURS')}
                      </>
                    )}
                  </Typography>
                </div>
              </div>
            </div>
            <div className='w-1/2 flex-col '>
              <div className={estActualHoursClasses}>
                <Icon
                  data-testid='total_availability_icon'
                  src={availabilityIcon}
                  className={insightsIconClasses}
                />
                <div>
                  <Tooltip
                    trigger={
                      <Typography
                        variant='body2'
                        className={insightsTextClasses}
                      >
                        {intl.get(
                          'PROJECT_DETAIL.INSIGHTS.RESOURCES.TOTAL_AVAILABILITY'
                        )}
                      </Typography>
                    }
                    position='bottomLeft'
                    timeout={0}
                    contentProps={{
                      className:
                        'z-50 bg-neutral-white border border-purple-lighter',
                    }}
                    openMode='hover1'
                  >
                    <Typography
                      variant='body2'
                      weight='medium'
                      className='text-purple-darker'
                    >
                      {intl.get(
                        'PROJECT_DETAIL.INSIGHTS.RESOURCES.TOTAL_AVAILABILITY'
                      )}
                    </Typography>
                    <Typography variant='body2' className='text-purple-darker'>
                      {intl.get(
                        'PROJECT_DETAIL.INSIGHTS.RESOURCES.TOTAL_AVAILABILITY_TOOLTIP'
                      )}
                    </Typography>
                  </Tooltip>
                  <Typography
                    data-testid='total_availability_value'
                    variant='body'
                    className={insightsValueClasses}
                  >
                    {isDueDatePassed() ? (
                      intl.get(
                        'PROJECT_DETAIL.INSIGHTS.RESOURCES.NOT_AVAILABLE'
                      )
                    ) : (
                      <>
                        {padNumberWithLeadingZeroes(
                          resourceDetails.totalAvailability
                        )}
                        {intl.get('PROJECT_DETAIL.INSIGHTS.BY_TASKS.HOURS')}
                      </>
                    )}
                  </Typography>
                </div>
              </div>
              <div className={avgOverdueAgeClasses}>
                <Icon
                  data-testid='avg_utlization_icon'
                  src={utilizationIcon}
                  className={insightsIconClasses}
                />
                <div>
                  <Tooltip
                    trigger={
                      <Typography
                        variant='body2'
                        className={insightsTextClasses}
                      >
                        {intl.get(
                          'PROJECT_DETAIL.INSIGHTS.RESOURCES.AVERAGE_UTILIZATION'
                        )}
                      </Typography>
                    }
                    position='bottomLeft'
                    timeout={0}
                    contentProps={{
                      className:
                        'z-50 bg-neutral-white border border-purple-lighter',
                    }}
                    openMode='hover1'
                  >
                    <Typography
                      variant='body2'
                      weight='medium'
                      className='text-purple-darker'
                    >
                      {intl.get(
                        'PROJECT_DETAIL.INSIGHTS.RESOURCES.AVERAGE_UTILIZATION'
                      )}
                    </Typography>
                    <Typography variant='body2' className='text-purple-darker'>
                      {intl.get(
                        'PROJECT_DETAIL.INSIGHTS.RESOURCES.AVERAGE_UTILIZATION_TOOLTIP'
                      )}
                    </Typography>
                  </Tooltip>
                  <Typography
                    data-testid='avg_utlization_value'
                    variant='body'
                    className={insightsValueClasses}
                  >
                    {isDueDatePassed() ? (
                      intl.get(
                        'PROJECT_DETAIL.INSIGHTS.RESOURCES.NOT_AVAILABLE'
                      )
                    ) : (
                      <>
                        {padNumberWithLeadingZeroes(
                          getAverageOverdueAge(
                            resourceDetails.averageUtilization
                          )
                        )}
                        {'%'}
                      </>
                    )}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectInsights;
