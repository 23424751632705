import { FlatfileSettings, FlatfileResults } from '@flatfile/react';
import intl from 'react-intl-universal';
import moment from 'moment';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import { TASK_FIELDS, TASK_TYPES, TASK_STATUS } from 'utils/constants';
import { UserAvatars, NewTask } from 'utils/customTypes';

export const getImportSettings: (
  assignees: UserAvatars[]
) => FlatfileSettings = (assignees) => ({
  type: 'Tasks',
  title: intl.get('IMPORT_FROM_FILE', {
    element: intl.get('UPDATE_PROJECT_PAGE_TABS.TASKS').toLowerCase(),
  }),
  fields: [
    {
      label: intl.get('TASKS.TASK_DETAIL_PAGE.TASK_TITLE_LABEL'),
      key: TASK_FIELDS.NAME,
      validators: [{ validate: 'required' }],
    },
    {
      label: intl.get('TASKS.ADD_TASK_MODAL.TASK_ASSIGNEE_PLACEHOLDER'),
      key: TASK_FIELDS.ASSIGNEE_ADD,
      type: 'select',
      options:
        assignees.length > 0
          ? assignees.map((assignee: UserAvatars) => ({
              label: assignee.label,
              value: assignee.value,
            }))
          : [],
    },
    {
      label: intl.get('TASKS.TASK_DETAIL_PAGE.START_DATE_LABEL'),
      key: TASK_FIELDS.START_DATE,
    },
    {
      label: intl.get('TASKS.TASK_DETAIL_PAGE.DUE_DATE_LABEL'),
      key: TASK_FIELDS.DUE_DATE,
    },
    {
      label: intl.get('TASKS.TASK_DETAIL_PAGE.TASK_DESCRIPTION_LABEL'),
      key: TASK_FIELDS.DESCRIPTION,
    },
    {
      label: intl.get('TASKS.TASK_DETAIL_PAGE.TASK_TYPE'),
      key: TASK_FIELDS.TASK_TYPE,
      type: 'select',
      options: orderBy(
        Object.keys(TASK_TYPES).map((key) => ({
          value: key,
          label: intl.get(`TASKS.TASK_TYPES.${key}`),
        })),
        (type) => {
          const label = get(type, 'label') || '';
          return label.toLocaleLowerCase();
        },
        'asc'
      ),
    },
    {
      label: intl.get('TASKS.TASK_DETAIL_PAGE.ESTIMATED_TIME_LABEL'),
      key: TASK_FIELDS.ESTIMATED_HOURS,
    },
    {
      label: intl.get('TASKS.TASK_DETAIL_PAGE.STATUS'),
      key: TASK_FIELDS.STATUS,
      type: 'select',
      options: Object.keys(TASK_STATUS).map((key) => ({
        label: intl.get(`TASKS.TASK_DETAIL_PAGE.STATUS_OPTIONS.${key}`),
        value: TASK_STATUS[key],
      })),
    },
    {
      label: intl.get('TASKS.TABLE.HEAD.ACTUAL_HOURS'),
      key: TASK_FIELDS.ACTUAL_HOURS,
    },
    {
      label: intl.get('TASKS.TABLE.HEAD.ACTUAL_DATE'),
      key: TASK_FIELDS.COMPLETION_DATE,
    },
  ],
});

const validateDate = (value: string) => {
  if (isEmpty(value)) {
    return false;
  }
  return moment(value).isValid();
};

export const cleanUploadedData: (
  organizationId: string,
  projectId: string,
  data: FlatfileResults
) => NewTask[] = (organizationId, projectId, data) => {
  const newTaskDefaultValues: NewTask = {
    name: '',
    type: '',
    description: '',
    start_date: null,
    due_date: null,
    assignedUserIds: [],
    estimate_hours: '0',
    project_id: projectId,
    organization_id: organizationId,
    status: TASK_STATUS.NEW,
  };
  return data.validData.map((uploadedTask: NewTask) => {
    const cleanTask = {
      ...newTaskDefaultValues,
      [TASK_FIELDS.NAME]: get(uploadedTask, TASK_FIELDS.NAME),
      [TASK_FIELDS.DESCRIPTION]: get(uploadedTask, TASK_FIELDS.DESCRIPTION),
    };
    if (has(uploadedTask, TASK_FIELDS.ASSIGNEE_ADD)) {
      const assignee = uploadedTask[TASK_FIELDS.ASSIGNEE_ADD];
      if (assignee) {
        cleanTask[TASK_FIELDS.START_DATE] = moment().format(
          'YYYY-MM-DD HH:mm:ss'
        );
        cleanTask[TASK_FIELDS.DUE_DATE] = moment().format(
          'YYYY-MM-DD HH:mm:ss'
        );
      }
    }
    if (has(uploadedTask, TASK_FIELDS.STATUS)) {
      const newTaskStatus = uploadedTask[TASK_FIELDS.STATUS];
      cleanTask[TASK_FIELDS.STATUS] = newTaskStatus;
      if (newTaskStatus === TASK_STATUS.COMPLETED) {
        cleanTask[TASK_FIELDS.COMPLETION_DATE] = moment().format(
          'YYYY-MM-DD HH:mm:ss'
        );
        if (
          has(uploadedTask, TASK_FIELDS.ACTUAL_HOURS) &&
          !isEmpty(uploadedTask[TASK_FIELDS.ACTUAL_HOURS]) &&
          !isNaN(uploadedTask[TASK_FIELDS.ACTUAL_HOURS])
        ) {
          cleanTask[TASK_FIELDS.ACTUAL_HOURS] =
            uploadedTask[TASK_FIELDS.ACTUAL_HOURS];
        }
        if (
          has(uploadedTask, TASK_FIELDS.COMPLETION_DATE) &&
          validateDate(uploadedTask[TASK_FIELDS.COMPLETION_DATE])
        ) {
          cleanTask[TASK_FIELDS.COMPLETION_DATE] = new Date(
            uploadedTask[TASK_FIELDS.COMPLETION_DATE]
          ).toISOString();
        }
      }
    }
    if (
      has(uploadedTask, TASK_FIELDS.ASSIGNEE_ADD) &&
      uploadedTask[TASK_FIELDS.ASSIGNEE_ADD] !== null
    ) {
      cleanTask[TASK_FIELDS.ASSIGNEE_ADD] = [
        uploadedTask[TASK_FIELDS.ASSIGNEE_ADD],
      ];
    }
    if (
      has(uploadedTask, TASK_FIELDS.TASK_TYPE) &&
      uploadedTask[TASK_FIELDS.TASK_TYPE] !== null
    ) {
      cleanTask[TASK_FIELDS.TASK_TYPE] = uploadedTask[TASK_FIELDS.TASK_TYPE];
    }
    if (
      has(uploadedTask, TASK_FIELDS.START_DATE) &&
      validateDate(uploadedTask[TASK_FIELDS.START_DATE])
    ) {
      cleanTask[TASK_FIELDS.START_DATE] = new Date(
        uploadedTask[TASK_FIELDS.START_DATE]
      ).toISOString();
    }
    if (
      has(uploadedTask, TASK_FIELDS.DUE_DATE) &&
      validateDate(uploadedTask[TASK_FIELDS.DUE_DATE]) &&
      moment(uploadedTask[TASK_FIELDS.DUE_DATE]).isSameOrAfter(
        cleanTask[TASK_FIELDS.START_DATE]
      )
    ) {
      cleanTask[TASK_FIELDS.DUE_DATE] = new Date(
        uploadedTask[TASK_FIELDS.DUE_DATE]
      ).toISOString();
    } else {
      cleanTask[TASK_FIELDS.DUE_DATE] = moment(
        cleanTask[TASK_FIELDS.START_DATE]
      ).add(30, 'days');
    }
    if (
      has(uploadedTask, TASK_FIELDS.ESTIMATED_HOURS) &&
      !isEmpty(uploadedTask[TASK_FIELDS.ESTIMATED_HOURS]) &&
      !isNaN(uploadedTask[TASK_FIELDS.ESTIMATED_HOURS])
    ) {
      cleanTask[TASK_FIELDS.ESTIMATED_HOURS] =
        uploadedTask[TASK_FIELDS.ESTIMATED_HOURS];
    }
    return cleanTask;
  });
};
