import React, { useRef, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { OnChangeHandlerFunc, MentionItem } from 'react-mentions';
import { Button, useLink, TextArea } from '@getsynapse/design-system';
import { parseURL, parseMention } from 'utils/functions';
import { ProjectCommentMentionsData, UserAvatars } from 'utils/customTypes';
import MentionsTextField from '../Mentions/MentionsTextField';
import { State } from '@popperjs/core';

type NewCommentProps = {
  onCreate?: (
    content: string,
    mentionsData: ProjectCommentMentionsData
  ) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  placeholder: string;
  submitButtonLabel: string;
  mentionableUsers?: UserAvatars[];
  mentionsFieldClassName?: string;
  updateCommentsPopup?: (() => Promise<Partial<State>>) | null;
};

const NewComment = ({
  onCreate = () => {},
  setOpen,
  isOpen,
  placeholder,
  submitButtonLabel,
  mentionableUsers = [],
  mentionsFieldClassName,
  updateCommentsPopup,
}: NewCommentProps) => {
  const [value, setValue] = useState('');
  const defaultMentionsData = {
    value: '',
    plainTextValue: '',
    mentions: [],
  };
  const [mentionsData, setMentionsData] =
    useState<ProjectCommentMentionsData>(defaultMentionsData);
  const formRef = useRef<HTMLFormElement>(null);
  const linkClassName = useLink(true);

  const handleChange: OnChangeHandlerFunc = (
    _,
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionItem[]
  ) => {
    setValue(newValue);
    setMentionsData({
      value: newValue,
      plainTextValue: newPlainTextValue,
      mentions,
    });
    if (updateCommentsPopup) {
      updateCommentsPopup();
    }
  };

  const handleClear = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setValue('');
    }
  }, [isOpen]);

  const handleCreateComment = (event: React.FormEvent) => {
    event && event.preventDefault();
    const formattedValue = value.trim();
    let plainTextAfterParsing = formattedValue;
    if (formattedValue.length > 0) {
      plainTextAfterParsing = parseMention(plainTextAfterParsing);
      plainTextAfterParsing = parseURL(plainTextAfterParsing, linkClassName);
      onCreate(formattedValue, {
        ...mentionsData,
        plainTextValue: plainTextAfterParsing,
      });
      setValue('');
      setMentionsData(defaultMentionsData);
    }
  };

  return (
    <form onSubmit={handleCreateComment} ref={formRef}>
      {mentionableUsers.length > 0 ? (
        <div className='px-6 py-4 shadow-form-wrapper'>
          <MentionsTextField
            mentionableUsers={mentionableUsers}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            inputClassName={mentionsFieldClassName}
          />
        </div>
      ) : (
        <TextArea
          value={value}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setValue(event.target.value);
          }}
          className='px-6 py-4 shadow-form-wrapper'
          textAreaProps={{
            className: 'h-auto min-h-10 max-h-26 px-4 py-3',
            placeholder: placeholder,
            'data-cy': 'add-new-comment',
          }}
        />
      )}
      {value.length > 0 && (
        <div className='flex space-x-4 px-6 pb-4'>
          <Button size='small' type='submit' data-cy='post-comment-button'>
            {submitButtonLabel}
          </Button>
          <Button
            variant='secondary'
            size='small'
            type='reset'
            onClick={handleClear}
            data-cy='cancel-comment-button'
          >
            {intl.get('COMMENT.CANCEL_BUTTON')}
          </Button>
        </div>
      )}
    </form>
  );
};

export default NewComment;
