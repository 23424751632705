import React, { useMemo } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { ProjectTemplateField } from 'utils/types/templates';
import CustomField from 'Molecules/CustomField/CustomField';
import FieldTemplateCard from './FieldTemplateCard';

interface Props {
  canUpdate: boolean;
  templateFields: ProjectTemplateField[];
  onRemoveFieldTemplate: (fieldTemplateId: string) => void;
  onUpdateRequiredStatus: (templateFieldId: string, checked: boolean) => void;
}

const FieldsList: React.FC<Props & { isSortEnabled?: boolean }> = ({
  templateFields,
  onRemoveFieldTemplate,
  onUpdateRequiredStatus,
  canUpdate,
  isSortEnabled = false,
}) => {
  return (
    <>
      {templateFields.map((customField: ProjectTemplateField) => (
        <FieldTemplateCard
          key={customField.field_template_id}
          required={customField.required}
          fieldTemplateId={customField.field_template_id}
          canUpdate={canUpdate}
          onRemoveFieldTemplate={onRemoveFieldTemplate}
          onUpdateRequiredStatus={(checked: boolean) =>
            onUpdateRequiredStatus(customField.field_template_id, checked)
          }
          isSortEnabled={isSortEnabled}
        >
          <CustomField
            field={customField.field}
            fieldTemplate={customField.field_template}
            required={customField.required}
          />
        </FieldTemplateCard>
      ))}
    </>
  );
};

const SortableFieldsList: React.FC<{
  templateFields: ProjectTemplateField[];
  onUpdateTemplatesFieldsOrder: (
    templateFieldsList: ProjectTemplateField[]
  ) => void;
}> = ({ templateFields, onUpdateTemplatesFieldsOrder, children }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const templateFieldsMap = useMemo(
    () =>
      templateFields.reduce(
        (
          fieldsMap: { [key: string]: ProjectTemplateField },
          currentVale: ProjectTemplateField
        ) => {
          return { ...fieldsMap, [currentVale.field_template_id]: currentVale };
        },
        {}
      ),
    [templateFields]
  );
  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const fieldTemplatesIdsArray = Object.keys(templateFieldsMap);
      const oldTemplateFieldsList = Object.values(templateFieldsMap);
      const oldIndex = fieldTemplatesIdsArray.indexOf(active.id);
      const newIndex = fieldTemplatesIdsArray.indexOf(over.id);
      const newTemplateFieldsList = arrayMove(
        oldTemplateFieldsList,
        oldIndex,
        newIndex
      );
      const orderedTemplateFieldsList = newTemplateFieldsList.map(
        (templateField: ProjectTemplateField, index: number) => ({
          ...templateField,
          layout: index + 1,
        })
      );
      onUpdateTemplatesFieldsOrder(orderedTemplateFieldsList);
    }
  };
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={Object.keys(templateFieldsMap)}
        strategy={rectSortingStrategy}
      >
        {children}
      </SortableContext>
    </DndContext>
  );
};

const TemplateFieldsList: React.FC<
  Props & {
    onUpdateTemplatesFieldsOrder: (
      templateFieldsList: ProjectTemplateField[]
    ) => void;
  }
> = ({
  templateFields,
  canUpdate,
  onRemoveFieldTemplate,
  onUpdateRequiredStatus,
  onUpdateTemplatesFieldsOrder,
}) => {
  const isSortEnabled = canUpdate && templateFields.length > 1;
  return (
    <div className='grid grid-cols-2 gap-y-6 gap-x-10%'>
      {isSortEnabled ? (
        <SortableFieldsList
          templateFields={templateFields}
          onUpdateTemplatesFieldsOrder={onUpdateTemplatesFieldsOrder}
        >
          <FieldsList
            templateFields={templateFields}
            canUpdate={canUpdate}
            onRemoveFieldTemplate={onRemoveFieldTemplate}
            onUpdateRequiredStatus={onUpdateRequiredStatus}
            isSortEnabled
          />
        </SortableFieldsList>
      ) : (
        <FieldsList
          templateFields={templateFields}
          canUpdate={canUpdate}
          onRemoveFieldTemplate={onRemoveFieldTemplate}
          onUpdateRequiredStatus={onUpdateRequiredStatus}
        />
      )}
    </div>
  );
};

export default TemplateFieldsList;
