import React from 'react';
import intl from 'react-intl-universal';
import { tailwindOverride, Typography } from '@getsynapse/design-system';
import { CapacityView } from 'utils/types/capacityView';
import { VIEW_SETTING_VALUES } from 'utils/constants/capacityView';
import useAllocationSummaryPill from '../AllocationSummary/hooks/useAllocationSummaryPill';
import { numberFormatter } from 'Pages/TeamsPage/views/TeamsCapacity/components/DailyCapacity/helpers';

interface DailyCapacityBreakdownProps {
  assignedHours: number;
  defaultCapacity: number;
  timeOff: number;
  capacityView?: CapacityView;
  level?: 'team' | 'member';
}

const DailyCapacityBreakdown: React.FC<DailyCapacityBreakdownProps> = ({
  assignedHours,
  defaultCapacity,
  timeOff,
  capacityView = VIEW_SETTING_VALUES.DAILY,
  level = 'team',
}) => {
  const { styles } = useAllocationSummaryPill({
    assignedHours,
    defaultCapacity,
    timeOff,
    capacityView,
    level,
  });
  const view = intl.get(`TEAMS_CAPACITY.VIEW_PICKER.${capacityView}`);
  const availableHours = defaultCapacity - assignedHours - timeOff;
  return (
    <React.Fragment>
      <div className={tailwindOverride('w-full flex justify-between gap-x-4')}>
        <Typography variant='caption' className='text-purple-darker'>
          {intl.get(
            'TEAMS_CAPACITY.ALLOCATION_SUMMARY_TOOLTIP.DEFAULT_CAPACITY',
            { teamLevel: level === 'team' ? 1 : 0, view }
          )}
        </Typography>
        <Typography
          variant='label'
          weight='medium'
          className='text-purple-darker'
        >
          {intl.get('TEAMS_CAPACITY.ALLOCATION_SUMMARY_HOURS', {
            hours: numberFormatter.format(defaultCapacity),
          })}
        </Typography>
      </div>
      <div className={tailwindOverride('w-full flex justify-between gap-x-4')}>
        <Typography variant='caption' className='text-purple-darker'>
          {intl.get(
            'TEAMS_CAPACITY.ALLOCATION_SUMMARY_TOOLTIP.ASSIGNED_HOURS',
            { teamLevel: level === 'team' ? 1 : 0 }
          )}
        </Typography>
        <Typography
          variant='label'
          weight='medium'
          className='text-purple-darker'
        >
          {intl.get('TEAMS_CAPACITY.ALLOCATION_SUMMARY_HOURS', {
            hours: numberFormatter.format(assignedHours),
          })}
        </Typography>
      </div>
      {timeOff > 0 && (
        <div
          className={tailwindOverride('w-full flex justify-between gap-x-4')}
        >
          <Typography variant='caption' className='text-purple-darker'>
            {intl.get('TEAMS_CAPACITY.ALLOCATION_SUMMARY_TOOLTIP.TIME_OFF', {
              teamLevel: level === 'team' ? 1 : 0,
            })}
          </Typography>
          <Typography
            variant='label'
            weight='medium'
            className='text-purple-darker'
          >
            {intl.get('TEAMS_CAPACITY.ALLOCATION_SUMMARY_HOURS', {
              hours: numberFormatter.format(timeOff),
            })}
          </Typography>
        </div>
      )}
      <div
        className={tailwindOverride(
          'w-full flex justify-between items-center mt-4 gap-x-4'
        )}
        aria-label='available-hours'
      >
        <Typography variant='caption' className='text-purple-darker'>
          {intl.get(
            'TEAMS_CAPACITY.ALLOCATION_SUMMARY_TOOLTIP.AVAILABLE_HOURS',
            { teamLevel: level === 'team' ? 1 : 0 }
          )}
        </Typography>
        <div
          className={tailwindOverride(
            'h-6 px-2',
            'flex items-center justify-center',
            'rounded border',
            styles.bg,
            styles.border
          )}
        >
          <Typography variant='label' weight='medium' className={styles.text}>
            {intl.get('TEAMS_CAPACITY.ALLOCATION_SUMMARY_HOURS', {
              hours: numberFormatter.format(availableHours),
            })}
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DailyCapacityBreakdown;
