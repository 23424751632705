export const DEMAND_BY_CATEGORY_COLOR_PALATE = [
  '#475C8E',
  '#48668F',
  '#496D90',
  '#4B7792',
  '#4D8193',
  '#4F8D96',
  '#529A98',
  '#529D99',
  '#5DB0AA',
  '#6EC4B6',
];

export const DEMAND_BY_CATEGORY_COLOR_SKELTON = ['#E1E9EC'];

export const GRID_COLOR = '#C1CFD6';

export const FONT_FAMILY = 'proxima-nova';

export const AXIS_LABELS_STYLE = {
  fontFamily: FONT_FAMILY,
  fontSize: 10,
};

export const AXIS_NAME_STYLE = {
  align: 'right',
  fontFamily: FONT_FAMILY,
};

export const DATA_LABELS_STYLE = {
  color: '#0E1212',
  fontSize: 12,
  fontFamily: FONT_FAMILY,
  lineHeight: 16,
};

export const HOURS_STYLE = {
  fontSize: 14,
  fontWeight: 700,
  fontFamily: FONT_FAMILY,
};

export const TOOLTIP_STYLE = {
  borderColor: '#CBDAFE',
  textStyle: {
    color: '#45598C',
    fontFamily: FONT_FAMILY,
  },
};
