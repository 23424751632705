import stgConfig from './stg.json';
import prodConfig from './prod.json';
import devcsi1Config from './devcsi1.json';
import devcsi2Config from './devcsi2.json';
import devbfs1Config from './devbfs1.json';
import devbfs2Config from './devbfs2.json';
import stgpreviewConfig from './stgpreview.json';
import demoConfig from './demo.json';
import trial1Config from './trial1.json';
import trial2Config from './trial2.json';
import caprod from './caprod.json';

const envFiles = new Map([
  ['stg', stgConfig],
  ['prod', prodConfig],
  ['devcsi1', devcsi1Config],
  ['devcsi2', devcsi2Config],
  ['devbfs1', devbfs1Config],
  ['devbfs2', devbfs2Config],
  ['stgpreview', stgpreviewConfig],
  ['demo', demoConfig],
  ['trial1', trial1Config],
  ['trial2', trial2Config],
  ['caprod', caprod],
]);

class EnvConfig {
  _env: string;

  constructor(env: string) {
    this._env = env;
  }

  getConfig() {
    return envFiles.get(this._env) || {};
  }
}

const envConfig = new EnvConfig(process.env.REACT_APP_APP_ENV || 'local');

export default envConfig;
