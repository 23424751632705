import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { tailwindOverride } from '@getsynapse/design-system';
import {
  fetchTeamsCapacityStatus,
  selectWeeksCarouselPagination,
} from 'state/TeamsCapacity/teamsCapacitySlice';
import { RootState } from 'state/store';
import { WeeksCarouselPagination } from 'utils/types/teamsCapacity';

export const CapacityCarouselItem: React.FC<{
  id: string;
  index: number;
  isCurrentWeek: boolean;
  className?: string;
}> = memo(({ className, isCurrentWeek, index, children, id }) => {
  const { index: firstVisibleItemIndex } = useSelector<
    RootState,
    WeeksCarouselPagination
  >(selectWeeksCarouselPagination);
  const lastVisibleItemIndex = firstVisibleItemIndex + 6;
  return (
    <div
      className={tailwindOverride(
        'h-full px-2',
        'flex-shrink-0 flex-grow flex-col',
        className,
        {
          'bg-secondary-lightest border-l border-r border-secondary-lighter':
            isCurrentWeek,
        }
      )}
      style={{ width: 'calc(100% / 7)' }}
      role='group'
      data-cy={id}
      aria-label={id}
      aria-hidden={
        index < firstVisibleItemIndex || index > lastVisibleItemIndex
      }
    >
      {children}
    </div>
  );
});

export const CapacityCarousel: React.FC<{
  id: string;
  className?: string;
  emptyComponent?: React.ReactNode | null;
  loader?: React.ReactNode | null;
}> = memo(
  ({ id, children, className = '', emptyComponent = null, loader = null }) => {
    const { index = 7 } = useSelector<RootState, WeeksCarouselPagination>(
      selectWeeksCarouselPagination
    );
    const capacityTableStatus = useSelector(fetchTeamsCapacityStatus);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    useEffect(() => {
      let isMounted = true;
      if (capacityTableStatus === 'loading' && !isFetching && isMounted) {
        setIsFetching(true);
      }

      if (capacityTableStatus === 'idle' && isFetching) {
        setTimeout(() => {
          if (isMounted) {
            setIsFetching(false);
          }
        }, 1000);
      }

      return () => {
        isMounted = false;
      };
    }, [isFetching, capacityTableStatus]);
    return (
      <>
        {isFetching && loader}
        <div className={tailwindOverride('flex flex-1 h-full', className)}>
          <div className='w-6 h-full' />
          <div className='w-full h-full overflow-hidden relative'>
            {emptyComponent}
            <div
              data-cy={`${id}__weekly-capacity`}
              aria-label={`${id}__weekly-capacity`}
              className='flex h-full relative'
              style={{
                transition: 'transform 0.5s ease-in-out',
                transform: `translateX(-${index * (100 / 7)}%)`,
              }}
            >
              {children}
            </div>
          </div>
          <div className='w-6 h-full' />
        </div>
      </>
    );
  }
);

export default CapacityCarousel;
