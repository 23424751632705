import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo, useEffect, useState, FC } from 'react';
import {
  Icon,
  useElevation,
  Typography,
  NotificationPopup,
  StaticNotification,
  Button,
  Tag,
} from '@getsynapse/design-system';
import classnames from 'classnames';
import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { NOTIFICATION_STATUS, LICENSE_TIER } from 'utils/constants';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from 'state/User/userSlice';
import { openSidekick } from 'state/Sidekick/sidekickSlice';
import {
  getNotifications,
  selectNotifications,
  markAllAsRead,
  updateNotification,
  markAllAsSeen,
} from 'state/Notifications/notificationsSlice';
import { Notification, FormattedNotification } from 'utils/customTypes';
import { formatNotifications } from 'utils/notificationsHelper';
import {
  selectOrganizationLicense,
  selectTrialEndDate,
} from 'state/Organization/organizationSlice';
import sparkles from 'assets/icons/sparkles.svg';
import ProfileDropdown from './ProfileDropdown';
import useNavigation from 'Hooks/useNavigation';

const SidekickButton: FC<{
  tagLabel: string;
}> = ({ tagLabel }) => {
  const dispatch = useDispatch();

  return (
    <Button
      iconName='planet-outline'
      variant='tertiary'
      onClick={() => {
        dispatch(openSidekick());
      }}
      size='small'
    >
      {intl.get('SIDEKICK')}
      <Tag
        label={tagLabel}
        className='bg-tertiary-lightest group-hover:border-tertiary py-px px-1.5 ml-2'
        textClassName='text-tertiary-dark text-xs font-semibold'
      />
    </Button>
  );
};

const Header = () => {
  const dispatch = useDispatch();
  const { sidekickGa, sidekickEarlyAccess } = useFlags();

  const user = useSelector(selectUser);
  const skimClass = useElevation(1);
  const [notificationsList, setNotificationsList] = useState<
    FormattedNotification[]
  >([]);
  const { license_tier } = useSelector(selectOrganizationLicense);
  const trialEndDate = useSelector(selectTrialEndDate);

  const trialEndDays = useMemo<number>(() => {
    const timeToEnd =
      moment(trialEndDate)
        .startOf('day')
        .diff(moment().startOf('day'), 'days') || 0;

    return timeToEnd < 0 ? 0 : timeToEnd;
  }, [trialEndDate]);

  const { backPage, goToBackPage } = useNavigation();

  const notificationsListSelector = useSelector(selectNotifications);

  const hasNewNotifications = useMemo(
    () =>
      notificationsListSelector.some(
        (notification: Notification) =>
          notification.status === NOTIFICATION_STATUS.NEW
      ),
    [notificationsListSelector]
  );

  const hasUnreadNotications = useMemo(
    () =>
      notificationsListSelector.some(
        (notification: Notification) =>
          notification.status !== NOTIFICATION_STATUS.READ
      ),
    [notificationsListSelector]
  );

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  useEffect(() => {
    if (notificationsListSelector.length > 0) {
      setNotificationsList(
        formatNotifications(notificationsListSelector, user)
      );
    }
  }, [notificationsListSelector, user]);

  const markAllAsReadHandle = () => {
    dispatch(markAllAsRead());
  };

  const markAsRead = (id: string) => {
    dispatch(
      updateNotification({ id, newData: { status: NOTIFICATION_STATUS.READ } })
    );
  };

  const markAllAsSeenHandle = () => {
    if (hasNewNotifications) {
      dispatch(markAllAsSeen());
    }
  };

  return (
    <header
      className={classnames(
        skimClass,
        'h-10',
        'flex-shrink-0',
        'flex',
        'w-full',
        'justify-between',
        'items-center',
        'px-6'
      )}
    >
      <div className='flex items-center space-x-2'>
        {backPage && (
          <span
            onClick={goToBackPage}
            className='flex font-body text-body2-sm lg:text-body2 font-normal text-neutral-dark cursor-pointer'
            data-testid='back-link__button'
          >
            <Icon
              name='arrow-back-outline'
              className='py-1.5 mr-2 font-body font-normal lg:text-body2 text-body2-sm'
            />
            {backPage.name}
          </span>
        )}
      </div>

      <div className='flex space-x-4 items-center' data-cy='notifications-list'>
        {license_tier === LICENSE_TIER.TRIAL && (
          <div className='flex space-x-3 items-center'>
            <Typography variant='label' className='text-neutral'>
              {intl.get('DAYS_LEFT', { days: trialEndDays })}
            </Typography>
            <a
              href='https://cognota.com/upgrade-free-trial'
              target='_blank'
              rel='noreferrer'
            >
              <Button
                iconSrc={sparkles}
                color='tertiary'
                className='py-0.5 px-2'
              >
                {intl.get('UPGRADE')}
              </Button>
            </a>
          </div>
        )}

        {(sidekickGa || sidekickEarlyAccess) && (
          <SidekickButton tagLabel={intl.get('BETA')} />
        )}

        <NotificationPopup
          hasNewNotifications={hasNewNotifications}
          hasUnreadNotications={hasUnreadNotications}
          markAllHandle={markAllAsReadHandle}
          openNotificationHandle={markAllAsSeenHandle}
          triggerProps={{
            'data-cy': 'notification_bell-button',
            'aria-label': intl.get('NOTIFICATIONS'),
          }}
        >
          {!isEmpty(notificationsList) &&
            notificationsList.map((notification) => (
              <Link to={notification.link} key={notification.id}>
                <StaticNotification
                  avatar={notification.avatar}
                  time={notification.time}
                  unread={notification.status !== 'read'}
                  data-cy={`notification-${notification.id}`}
                  onClick={() => markAsRead(notification.id || '')}
                >
                  {notification.content}
                </StaticNotification>
              </Link>
            ))}
        </NotificationPopup>
        <ProfileDropdown user={user} />
      </div>
    </header>
  );
};

export default Header;
