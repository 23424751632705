import {
  Table,
  Button,
  Avatar,
  Typography,
  Tooltip,
  IconButton,
} from '@getsynapse/design-system';
import moment from 'moment';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SortingType, TaskBundle } from 'utils/customTypes';
import { DATE, PATHS, TASK_BUNDLE_SORTING } from 'utils/constants';
import duplicateSVG from 'assets/icons/duplicate.svg';
import emptyTaskBundleTable from 'assets/images/task-bundles-table-empty.svg';
import noSearchResults from 'assets/images/no-projects-found-table.svg';
import noFilterResults from 'assets/images/empty-filtered-projects-table.svg';
import isEmpty from 'lodash/isEmpty';
import RenderNoRecords from 'Pages/RequestsListPage/components/NoRecords';
import { get } from 'lodash';
import {
  selectTaskBundleTableSearch,
  setTaskBundleSortingOptions,
  taskBundleFilters,
} from 'state/TasksBundle/tasksBundleSlice';

const TaskBundlesTable = ({ taskBundles }: { taskBundles: TaskBundle[] }) => {
  const dispatch = useDispatch();
  const searchValue = useSelector(selectTaskBundleTableSearch);
  const filterValues = useSelector(taskBundleFilters);

  const handleSort = (orderByParam: string[], order: SortingType) => {
    dispatch(setTaskBundleSortingOptions({ order, orderBy: orderByParam }));
  };
  const history = useHistory();

  return (
    <div className='max-h-table-body'>
      <Table
        className='w-full mb-5'
        canSelectRows={false}
        isSelectRowCellSticky
        data-cy='task-bundle-table-body'
        data={{
          headData: {
            stickyHeader: true,
            handleSort,
            headCells: [
              {
                columnName: TASK_BUNDLE_SORTING.NAME,
                content: intl.get(
                  'SETTINGS_PAGE.TASK_BUNDLE_PAGE.TABLE.HEAD.TITLE'
                ),
                className: 'font-semibold py-3 w-2/5',
                'data-cy': 'header__bundle-title',
              },
              {
                columnName: TASK_BUNDLE_SORTING.DESCRIPTION,
                content: intl.get(
                  'SETTINGS_PAGE.TASK_BUNDLE_PAGE.TABLE.HEAD.DESCRIPTION'
                ),
                className: 'font-semibold py-3 w-2/5',
                'data-cy': 'header__bundle-description',
              },
              {
                columnName: TASK_BUNDLE_SORTING.ESTIMATED_HOURS,
                content: intl.get(
                  'SETTINGS_PAGE.TASK_BUNDLE_PAGE.TABLE.HEAD.ESTIMATED_HOURS'
                ),
                className: 'font-semibold py-3 w-1/5',
                'data-cy': 'header__bundle-estimated-hours',
              },
              {
                columnName: TASK_BUNDLE_SORTING.CREATOR,
                content: intl.get(
                  'SETTINGS_PAGE.TASK_BUNDLE_PAGE.TABLE.HEAD.CREATOR'
                ),
                className: 'font-semibold py-3 w-1/5',
                'data-cy': 'header__bundle-creator',
              },
              {
                columnName: TASK_BUNDLE_SORTING.CREATED_AT,
                content: intl.get(
                  'SETTINGS_PAGE.TASK_BUNDLE_PAGE.TABLE.HEAD.CREATION_DATE'
                ),
                className: 'font-semibold py-3 w-1/5',
                'data-cy': 'header__bundle-creation-date',
              },
              {
                content: <div />,
              },
            ],
          },
          rows: taskBundles.map((taskBundle: TaskBundle) => {
            const managerAvatars = {
              imageSrc: get(taskBundle, 'avatar_url'),
              initial: `${get(
                taskBundle,
                'taskBundleCreator.data.firstName[0]',
                ''
              )} ${get(taskBundle, 'taskBundleCreator.data.lastName[0]', '')}`,
              name: `${get(
                taskBundle,
                'taskBundleCreator.data.firstName',
                ''
              )} ${get(taskBundle, 'taskBundleCreator.data.lastName', '')}`,
            };

            return {
              id: taskBundle.id,
              onClick: () =>
                history.push(
                  PATHS.EDIT_TASK_BUNDLE_PAGE.replace(
                    ':taskBundleId',
                    taskBundle.id
                  )
                ),
              className: 'cursor-pointer',
              cells: [
                {
                  content: (
                    <Typography
                      title={get(taskBundle, 'name') || '-'}
                      variant='body2'
                      className='leading-4 truncate'
                    >
                      {get(taskBundle, 'name') || '-'}
                    </Typography>
                  ),
                  className: 'truncate w-2/5',
                },

                {
                  content: (
                    <Typography
                      title={get(taskBundle, 'description') || '-'}
                      variant='body2'
                      className='leading-4 truncate'
                    >
                      {get(taskBundle, 'description') || '-'}
                    </Typography>
                  ),
                  className: 'truncate w-2/5',
                },
                {
                  content: (
                    <Typography
                      title={get(taskBundle, 'totalEstimatedHours') || '-'}
                      variant='body2'
                      className='leading-4 truncate'
                    >
                      {get(taskBundle, 'totalEstimatedHours') || '-'}
                    </Typography>
                  ),
                  className: 'truncate w-1/5',
                },
                {
                  content: (
                    <div className='flex items-center'>
                      <Avatar
                        imageSrc={managerAvatars.imageSrc}
                        initial={managerAvatars.initial}
                      />
                      <span className='ml-2.5'>{managerAvatars.name}</span>
                    </div>
                  ),
                  className: 'truncate w-1/5',
                },
                {
                  content: moment(new Date(taskBundle.createdAt)).format(
                    DATE.SHORT_FORMAT
                  ),
                  className: 'truncate w-1/5',
                },
                {
                  className: 'w-12',
                  content: (
                    <div
                      className='h-full text-center w-12'
                      onClick={(e) => e.stopPropagation()}
                    >
                      {
                        <div className='ml-2 hidden group-hover:flex'>
                          <Tooltip
                            openMode='hover1'
                            timeout={0}
                            showPopper
                            position='topCenter'
                            contentProps={{
                              className: 'px-3 py-2 text-sm z-50 absolute',
                            }}
                            usePortal
                            trigger={
                              <IconButton
                                src={duplicateSVG}
                                description={intl.get('DUPLICATE')}
                                data-cy='duplicate-task-bundle'
                                onClick={() =>
                                  history.push(
                                    PATHS.CLONE_TASK_BUNDLE_PAGE.replace(
                                      ':taskBundleId',
                                      taskBundle.id
                                    )
                                  )
                                }
                              />
                            }
                          >
                            {intl.get('DUPLICATE')}
                          </Tooltip>
                        </div>
                      }
                    </div>
                  ),
                },
              ],
            };
          }),
        }}
        emptyComponent={
          !isEmpty(searchValue) || !isEmpty(filterValues) ? (
            <RenderNoRecords
              imageSrc={
                !isEmpty(searchValue) ? noSearchResults : noFilterResults
              }
              className='h-empty-table-body'
              caption={intl.get(
                'SETTINGS_PAGE.TASK_BUNDLE_PAGE.TABLE.NO_RECORDS'
              )}
              dataCy='no-task-bundle-found'
            />
          ) : (
            <RenderNoRecords
              imageSrc={emptyTaskBundleTable}
              className='h-empty-table-body'
              caption={intl.get('SETTINGS_PAGE.TASK_BUNDLE_PAGE.TABLE.EMPTY')}
              labelClassName='mt-0'
              imageClassName='-ml-0'
              dataCy='no-task-bundle-found'
            >
              <Button className='mt-2 mx-auto' onClick={() => {}}>
                {intl.get('SETTINGS_PAGE.TASK_BUNDLE_PAGE.TABLE.GET_STARTED')}
              </Button>
            </RenderNoRecords>
          )
        }
      />
    </div>
  );
};

export default TaskBundlesTable;
