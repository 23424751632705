import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import {
  Dropdown,
  Typography,
  useElevation,
  Icon,
  IconButton,
  Tooltip,
} from '@getsynapse/design-system';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { PATHS, USER_TYPES, SLICE_STATUS } from 'utils/constants';
import { filteredFormsOptions, getForms } from 'state/Forms/formSlice';
import { FormOption } from 'utils/customTypes';
import ChangeFormModal from 'Pages/RequestPage/components/ChangeFormModal';
import FormCard from './FormCard';
import {
  newRequest,
  selectActiveRequestForm,
  selectActiveRequestId,
  selectActiveRequestType,
  selectIsActiveRequestAForm,
  selectActiveRequestSliceStatus,
} from 'state/ActiveRequest/activeRequestSlice';
import collapseIcon from 'assets/icons/collapse.svg';
import expandIcon from 'assets/icons/expand.svg';
import { useHistory } from 'react-router-dom';
import {
  selectOrganizationId,
  selectUserType,
  selectUser,
} from 'state/User/userSlice';

type RequestTypeOption = { value: string; label: string; count: number };

const RequestPageSidebar = ({
  requestId,
  shouldResetForm,
  setShouldResetForm,
  handleLeavingPageWithUnsavedChanges,
  hasUpdate,
}: {
  requestId: string;
  shouldResetForm: boolean;
  setShouldResetForm: React.Dispatch<React.SetStateAction<boolean>>;
  handleLeavingPageWithUnsavedChanges: (path: string) => void;
  hasUpdate: boolean;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState({
    requestTypeId: '',
    formId: '',
    selectedRequestType: '',
  });
  const requestSliceStatus = useSelector(selectActiveRequestSliceStatus);
  const disableSwitchForm = requestSliceStatus === SLICE_STATUS.LOADING;
  const formOptions: FormOption[] = useSelector(filteredFormsOptions);
  const currentRequestBaseForm = useSelector(selectActiveRequestForm);
  const currentRequestType = useSelector(selectActiveRequestType);
  const currentRequestId = useSelector(selectActiveRequestId);
  const isForm = useSelector(selectIsActiveRequestAForm);
  const organizationId = useSelector(selectOrganizationId);
  const userType = useSelector(selectUserType);
  const currentUser = useSelector(selectUser);
  const [isExpanded, setIsExpanded] = useState(true);
  const [requestTypeDropdownSelection, setRequestTypeDropdownSelection] =
    useState<string>(intl.get('REQUEST_PAGE.LEFT_PANEL.REQUEST_TYPE.ALL'));

  useEffect(() => {
    if (organizationId) {
      dispatch(getForms({ organizationId, published: true }));
    }
  }, [organizationId, dispatch]);

  useEffect(() => {
    if (
      currentRequestType &&
      !data.requestTypeId &&
      !data.selectedRequestType &&
      currentRequestBaseForm &&
      !data.formId
    ) {
      setData({
        ...data,
        formId: currentRequestBaseForm.value,
        requestTypeId: currentRequestType,
        selectedRequestType: currentRequestType,
      });
    }
  }, [currentRequestType, currentRequestBaseForm, data]);

  useEffect(() => {
    if (requestId && isForm) {
      setData((prevData) => ({
        ...prevData,
        formId: requestId,
      }));
    }
  }, [requestId, isForm]);

  useEffect(() => {
    if (
      !data.formId &&
      !data.selectedRequestType &&
      !isEmpty(formOptions) &&
      !requestId
    ) {
      if (formOptions[0].value) {
        history.replace(`${PATHS.REQUEST_PAGE}/${formOptions[0].value}`);
      }

      setData({
        ...data,
        selectedRequestType: 'All',
        formId: formOptions[0].value,
      });
    }
  }, [data, formOptions, history, requestId]);

  useEffect(() => {
    if (currentRequestId && currentRequestId !== requestId && !isForm) {
      handleLeavingPageWithUnsavedChanges(
        `${PATHS.REQUEST_PAGE}/${currentRequestId}`
      );
    }
  }, [
    currentRequestId,
    history,
    requestId,
    isForm,
    handleLeavingPageWithUnsavedChanges,
  ]);

  const setForm = useCallback(
    (form: FormOption) => {
      if (requestId && currentRequestBaseForm) {
        if (!isForm || (isForm && hasUpdate)) {
          data.formId !== form.value && setModalOpen(true);
        } else {
          handleLeavingPageWithUnsavedChanges(
            `${PATHS.REQUEST_PAGE}/${form.value}`
          );
        }
      }
      setData({
        ...data,
        formId: form.value,
        selectedRequestType: form.requestType!,
        requestTypeId: form.requestType!,
      });
    },
    [
      data,
      requestId,
      currentRequestBaseForm,
      isForm,
      hasUpdate,
      handleLeavingPageWithUnsavedChanges,
    ]
  );

  const getRequestTypeFormsCount = useCallback(
    (type: string | null | undefined) => {
      if (!type) return '';

      const optionsWithValues = formOptions.filter(
        (option) => option.requestType
      );

      if (type === intl.get('REQUEST_PAGE.LEFT_PANEL.REQUEST_TYPE.ALL')) {
        return optionsWithValues.length;
      }
      let count = 0;
      for (let i = 0; i < optionsWithValues.length; ++i) {
        if (optionsWithValues[i].requestType === type) count++;
      }
      return count;
    },
    [formOptions]
  );

  const requestTypeOptions = useMemo(() => {
    const optionsWithValues = formOptions.filter(
      (option) => option.requestType
    );
    const uniqueTypes = [
      ...new Set(optionsWithValues.map((option) => option.requestType)),
    ];
    const requestTypes = uniqueTypes.map((type) => ({
      value: type,
      label: intl.get(`REQUEST_PAGE.LEFT_PANEL.REQUEST_TYPE.${type}`),
      count: getRequestTypeFormsCount(type),
    }));

    requestTypes.unshift({
      value: intl.get('REQUEST_PAGE.LEFT_PANEL.REQUEST_TYPE.ALL'),
      label: intl.get('REQUEST_PAGE.LEFT_PANEL.REQUEST_TYPE.ALL'),
      count: getRequestTypeFormsCount(
        intl.get('REQUEST_PAGE.LEFT_PANEL.REQUEST_TYPE.ALL')
      ),
    });
    return requestTypes;
  }, [formOptions, getRequestTypeFormsCount]);

  const filteredFormOptions = useMemo(() => {
    if (
      requestTypeDropdownSelection ===
      intl.get('REQUEST_PAGE.LEFT_PANEL.REQUEST_TYPE.ALL')
    ) {
      return formOptions;
    }
    return formOptions.filter(
      (form) => form.requestType === requestTypeDropdownSelection
    );
  }, [formOptions, requestTypeDropdownSelection]);

  const selectedRequestType: any = useMemo(
    () => ({
      value: requestTypeDropdownSelection,
      label: intl.get(
        `REQUEST_PAGE.LEFT_PANEL.REQUEST_TYPE.${requestTypeDropdownSelection.toUpperCase()}`
      ),
      count: getRequestTypeFormsCount(requestTypeDropdownSelection),
    }),
    [requestTypeDropdownSelection, getRequestTypeFormsCount]
  );

  const selectedForm = useMemo(() => {
    const form = formOptions.find((form) => form.value === data.formId);
    if (form !== undefined) {
      return { value: form.value, label: form.label };
    }
    return { value: '', label: '' };
  }, [formOptions, data.formId]);

  const setRequest = useCallback(
    (requestType: RequestTypeOption) => {
      setData({
        ...data,
        requestTypeId:
          requestType.value !==
          intl.get('REQUEST_PAGE.LEFT_PANEL.REQUEST_TYPE.ALL')
            ? requestType.value
            : '',
        selectedRequestType: requestType.value,
      });
      setRequestTypeDropdownSelection(requestType.value);
    },
    [data]
  );

  const createRequest = () => {
    if (data.formId && data.requestTypeId) {
      if (userType === USER_TYPES.BUSINESS) {
        dispatch(
          newRequest({
            ...data,
            businessTeams: [get(currentUser, 'businessTeam', '')],
          })
        );
      } else {
        dispatch(newRequest(data));
      }
    }
  };

  const resetForm = useCallback(() => {
    if (currentRequestBaseForm) {
      setData({
        ...data,
        formId: currentRequestBaseForm.value,
        requestTypeId: currentRequestType!,
        selectedRequestType: currentRequestType!,
      });
    }
  }, [currentRequestBaseForm, setData, data, currentRequestType]);

  useEffect(() => {
    if (shouldResetForm) {
      resetForm();
      setShouldResetForm(false);
    }
  }, [shouldResetForm, setShouldResetForm, resetForm]);

  const RequestTypeSelect: React.FC<{
    onToggleDropdown: () => void;
    selectedOptions: RequestTypeOption[] | any[];
    disabled: boolean;
    isDropdownExpanded: boolean;
  }> = ({
    onToggleDropdown,
    selectedOptions,
    disabled,
    isDropdownExpanded,
  }) => {
    const hoverElevation = useElevation(1);

    return (
      <div
        onClick={!disabled ? onToggleDropdown : () => {}}
        data-cy='training-type'
        className={classnames(
          'min-h-10 h-10 rounded border text-base py-1 px-2 flex justify-between items-center',
          {
            'border-neutral-lighter text-neutral-light': disabled,
            'bg-neutral-lightest hover:border-neutral-lighter': disabled,
            'focus:ring-0 active:ring-0 focus-visible:ring-0': disabled,
            'focus-visible:outline-none cursor-not-allowed': disabled,
            'ring-primary border-primary': isDropdownExpanded,
          },
          {
            'border-neutral-light hover:border-neutral-dark focus:border-primary active:ring-primary text-neutral-black':
              !disabled && !isDropdownExpanded,
            [`hover:${hoverElevation} focus:border-primary`]: !disabled,
            'focus-visible:outline-none focus:ring-primary': !disabled,
            'focus-visible:ring-primary bg-neutral-white': !disabled,
          }
        )}
      >
        <div className='flex items-center'>
          <div
            className={classnames(
              'rounded-full h-2 w-2 flex items-center justify-center'
            )}
          />
          {selectedOptions[0] && (
            <div className=''>{selectedOptions[0]?.label}</div>
          )}
          {selectedOptions[0] && (
            <span className='text-primary-light ml-1'>
              {`(${selectedOptions[0]?.count})`}
            </span>
          )}
        </div>
        <Icon
          name={isDropdownExpanded ? 'caret-up-outline' : 'caret-down-outline'}
          className='text-neutral-dark'
        />
      </div>
    );
  };

  const CollapseExpandButtons = () => (
    <Tooltip
      timeout={0}
      position={isExpanded ? 'bottomLeft' : 'bottomRight'}
      contentProps={{
        className: classnames(
          'z-10 py-1 px-3 bg-neutral-darker min-w-12 absolute',
          'text-center shadow-tooltip'
        ),
      }}
      openMode='hover2'
      trigger={
        isExpanded ? (
          <IconButton
            className=''
            src={collapseIcon}
            onClick={() => setIsExpanded(false)}
          />
        ) : (
          <IconButton
            className=''
            src={expandIcon}
            onClick={() => setIsExpanded(true)}
          />
        )
      }
    >
      {isExpanded
        ? intl.get('REQUEST_PAGE.LEFT_PANEL.COLLAPSE')
        : intl.get('REQUEST_PAGE.LEFT_PANEL.EXPAND')}
    </Tooltip>
  );

  return (
    <div
      className={classnames(
        'bg-neutral-white pt-4 ml-4 border border-neutral-lighter-two relative',
        'transition-width',
        'duration-150 ease-in',
        'motion-safe',
        'border-b-0 rounded-t-lg rounded-b-none',
        `${isExpanded ? 'w-80' : 'w-12'}`
      )}
    >
      <ChangeFormModal
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        requestId={requestId}
        onCreateRequest={createRequest}
        onLoadNewForm={() =>
          handleLeavingPageWithUnsavedChanges(
            `${PATHS.REQUEST_PAGE}/${data.formId}`
          )
        }
        isForm={isForm}
        onClose={resetForm}
      />
      <div className='px-3 flex'>
        <div className={!isExpanded ? 'hidden' : ''}>
          <Typography variant='h5'>
            {intl.get('REQUEST_PAGE.LEFT_PANEL.TITLE')}
          </Typography>

          <Typography
            variant='label'
            className='text-neutral-dark block w-full mb-4 mt-2'
          >
            {intl.get('REQUEST_PAGE.LEFT_PANEL.PROMPT')}
          </Typography>
        </div>
        <CollapseExpandButtons />
      </div>
      <div className={!isExpanded ? 'hidden' : ''}>
        <div className='px-3'>
          <Dropdown
            options={requestTypeOptions}
            onChange={setRequest}
            values={!isEmpty(formOptions) ? [selectedRequestType] : []}
            renderTrigger={({
              onToggleDropdown,
              selectedOptions,
              disabled,
              isDropdownExpanded,
            }) => (
              <RequestTypeSelect
                onToggleDropdown={onToggleDropdown}
                selectedOptions={selectedOptions}
                disabled={disabled}
                isDropdownExpanded={isDropdownExpanded}
              />
            )}
            renderOption={(
              option: RequestTypeOption,
              isSelected: boolean,
              selectOption,
              { className, ...otherProps }
            ) => (
              <li
                {...otherProps}
                key={option.value}
                onClick={selectOption}
                className={classnames(className, {
                  'bg-primary focus-visible:bg-primary text-neutral-white':
                    isSelected,
                })}
              >
                <span>{option.label}</span>
                <span className='text-primary-light ml-1'>{`(${option.count})`}</span>
              </li>
            )}
          />
        </div>
        <div className='h-request-side-bar-forms overflow-auto'>
          <div className='flex w-full flex-col items-center pb-2 pt-2 mt-2'>
            {filteredFormOptions.map((formOption) => (
              <FormCard
                key={formOption.value}
                formOption={formOption}
                selected={formOption.value === selectedForm.value}
                onSelect={setForm}
                disabled={disableSwitchForm}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestPageSidebar;
