import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { RootState } from 'state/store';
import { ProjectTemplate, ProjectTemplateField } from 'utils/types/templates';
import { Status } from 'utils/customTypes';
import {
  SLICE_STATUS,
  PROJECT_TEMPLATE_ACCESS_VALUES,
  PROJECT_TEMPLATE_STATUS_VALUES,
  PROJECT_TEMPLATE_RESOURCE_TYPE_VALUES,
} from 'utils/constants';
import ProjectTemplateAPI, {
  ProjectTemplateFieldUpdate,
} from './ProjectTemplateAPI';
import {
  formatPayloadForUpdateTemplateEndpoint,
  formatPayloadForBulkCreateTemplateFieldsEndpoint,
  formatPayloadForBulkUpdateTemplateFieldsEndpoint,
} from './helpers';

interface ProjectTemplateState {
  value: ProjectTemplate;
  fetchStatus: Status;
  updateStatus: Status;
}

const defaultTemplate = {
  name: '',
  owner_id: '',
  creator_id: '',
  access: PROJECT_TEMPLATE_ACCESS_VALUES.USER,
  status: PROJECT_TEMPLATE_STATUS_VALUES.DRAFT,
  resource_type: PROJECT_TEMPLATE_RESOURCE_TYPE_VALUES.INTERNAL,
  hidden: false,
  project_template_fields: [],
};

/* ============================= INITIAL STATE ============================== */
const initialState: ProjectTemplateState = {
  value: defaultTemplate,
  fetchStatus: SLICE_STATUS.IDLE,
  updateStatus: SLICE_STATUS.IDLE,
};

const projectTemplateAPI = ProjectTemplateAPI;

/* ============================== REDUX THUNK =============================== */
export const fetchProjectTemplateById = createAsyncThunk(
  'projectTemplate/FETCH_TEMPLATE_BY_ID',
  async (params: { templateId: string }, { rejectWithValue }) => {
    try {
      const template = await projectTemplateAPI.fetchProjectTemplateById(
        params.templateId
      );
      return template;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const updateProjectTemplate = createAsyncThunk(
  'projectTemplate/UPDATE_TEMPLATE',
  async (
    params: {
      templateId: string;
      templateData: Omit<ProjectTemplate, 'project_template_fields' | 'id'>;
      templateFieldsToAdd: ProjectTemplateField[];
      templateFieldsToUpdate: ProjectTemplateFieldUpdate[];
      templateFieldsToDelete: string[];
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const {
        templateId,
        templateData,
        templateFieldsToAdd,
        templateFieldsToUpdate,
        templateFieldsToDelete,
      } = params;
      const state = getState() as RootState;
      const currentTemplateVersion = state.projectTemplate.value
        .version as number;
      const updatedTemplate =
        await projectTemplateAPI.increaseProjectTemplateVersion({
          projectTemplateId: templateId,
          currentTemplateVersion,
        });
      if (!isEmpty(templateData)) {
        const updateTemplatePayload =
          formatPayloadForUpdateTemplateEndpoint(templateData);
        await projectTemplateAPI.updateProjectTemplate(templateId, {
          ...updateTemplatePayload,
          currentTemplateVersion: updatedTemplate.version,
        });
      }
      if (templateFieldsToDelete.length > 0) {
        await projectTemplateAPI.bulkDeleteProjectTemplateFields(
          templateFieldsToDelete,
          updatedTemplate.version
        );
      }
      if (templateFieldsToUpdate.length > 0) {
        const updateTemplateFieldsPayload =
          formatPayloadForBulkUpdateTemplateFieldsEndpoint(
            templateFieldsToUpdate
          );
        await projectTemplateAPI.bulkUpdateProjectTemplateFields(
          updateTemplateFieldsPayload,
          updatedTemplate.version
        );
      }
      if (templateFieldsToAdd.length > 0) {
        const createTemplateFieldsPayload =
          formatPayloadForBulkCreateTemplateFieldsEndpoint(
            templateFieldsToAdd,
            updatedTemplate.version
          );

        await projectTemplateAPI.bulkCreateProjectTemplateFields(
          createTemplateFieldsPayload
        );
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProjectTemplateStatus = createAsyncThunk(
  'projectTemplate/UPDATE_TEMPLATE_STATUS',
  async (
    params: {
      templateId: string;
      status: typeof PROJECT_TEMPLATE_STATUS_VALUES[keyof typeof PROJECT_TEMPLATE_STATUS_VALUES];
    },
    { getState }
  ) => {
    const { templateId, status } = params;
    const state = getState() as RootState;
    const currentTemplateVersion = state.projectTemplate.value
      .version as number;
    await projectTemplateAPI.updateProjectTemplate(templateId, {
      status,
      currentTemplateVersion,
    });
    return status;
  }
);

/* ============================== ACTIONS =============================== */
export const resetTemplate = createAction('projectTemplate/RESET_TEMPLATE');

/* ================================= REDUCER ================================ */
const projectTemplateSlice = createSlice({
  name: 'projectTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectTemplateById.pending, (state) => {
        state.fetchStatus = SLICE_STATUS.LOADING;
      })
      .addCase(fetchProjectTemplateById.fulfilled, (state, action) => {
        state.fetchStatus = SLICE_STATUS.IDLE;
        state.value = action.payload;
      })
      .addCase(fetchProjectTemplateById.rejected, (state) => {
        state.fetchStatus = SLICE_STATUS.IDLE;
      })
      .addCase(updateProjectTemplateStatus.fulfilled, (state, action) => {
        state.value = {
          ...state.value,
          status: action.payload,
        };
      })
      .addCase(updateProjectTemplate.pending, (state) => {
        state.updateStatus = SLICE_STATUS.LOADING;
      })
      .addCase(updateProjectTemplate.fulfilled, (state) => {
        state.updateStatus = SLICE_STATUS.IDLE;
      })
      .addCase(updateProjectTemplate.rejected, (state) => {
        state.updateStatus = SLICE_STATUS.IDLE;
      })
      .addCase(resetTemplate, (state) => {
        state.value = defaultTemplate;
      });
  },
});

/* =============================== SELECTORS ================================ */
export const getUpdateTemplateStatus = (state: RootState) =>
  state.projectTemplate.updateStatus;
export const getFetchTemplateStatus = (state: RootState) =>
  state.projectTemplate.fetchStatus;
export const getProjectTemplateData = (state: RootState) =>
  state.projectTemplate.value;

export default projectTemplateSlice.reducer;
