import { FC } from 'react';
import { tailwindOverride, useElevation } from '@getsynapse/design-system';

interface SkeletonLoaderProps {
  id: string;
}

const SkeletonLoader: FC<SkeletonLoaderProps> = ({ id }) => {
  const skim = useElevation(1);
  return (
    <div
      className={tailwindOverride(
        'w-full h-table-skeleton-loader',
        'rounded border border-neutral-lighter-two',
        'bg-neutral-white overflow-y-hidden'
      )}
      role='status'
      aria-live='polite'
      aria-busy='true'
      aria-label={id}
    >
      {/* FILTER SECTION */}
      <div className={tailwindOverride('flex flex-1 justify-end', 'px-4 py-2')}>
        <div className='w-20 h-4 rounded bg-primary-lighter-two' />
      </div>
      {/* TABLE HEADER */}
      <div className={tailwindOverride('flex flex-1 h-10 bg-neutral-lightest')}>
        <div className='flex flex-1 h-fulll animate-pulse'>
          <div
            className={tailwindOverride('w-2/5 h-full px-4 py-3 items-center')}
          >
            <div className='w-5/12 h-4 rounded bg-neutral-lighter-two' />
          </div>
          <div
            className={tailwindOverride('w-2/5 h-full px-4 py-3 items-center')}
          >
            <div className='w-5/12 h-4 rounded bg-neutral-lighter-two' />
          </div>
          <div
            className={tailwindOverride('w-1/5 h-full px-4 py-3 items-center')}
          >
            <div className='w-5/12 h-4 rounded bg-neutral-lighter-two' />
          </div>
        </div>
        <div className={tailwindOverride('w-20 h-full px-3 py-3')}></div>
      </div>
      {/* TABLE BODY */}
      <div>
        {Array.from({ length: 20 }).map((_, index) => (
          <div
            key={`${id}__row-${new Date().getTime() * index}`}
            className={tailwindOverride('flex flex-1 h-12 animate-pulse', {
              'bg-neutral-lightest-two': index % 2 > 0,
            })}
          >
            <div className='flex flex-1 h-fulll'>
              <div
                className={tailwindOverride(
                  'w-2/5 h-full px-4 py-3 items-center'
                )}
              >
                <div className='w-3/5 h-4 rounded bg-neutral-lighter-two' />
              </div>
              <div
                className={tailwindOverride(
                  'w-2/5 h-full px-4 py-3 items-center'
                )}
              >
                <div className='w-3/5 h-4 rounded bg-neutral-lighter-two' />
              </div>
              <div
                className={tailwindOverride(
                  'w-1/5 h-full px-4 py-3 items-center'
                )}
              >
                <div className='w-1/5 h-4 rounded bg-neutral-lighter-two' />
              </div>
            </div>
            <div
              className={tailwindOverride(
                'w-20 flex justify-between h-full px-3 py-3 items-center'
              )}
            >
              <div className='w-4 h-4 rounded bg-neutral-lighter-two' />
              <div className='w-4 h-4 rounded bg-neutral-lighter-two' />
            </div>
          </div>
        ))}
      </div>
      {/* TABLE FOOTER */}
      <div
        className={tailwindOverride(
          'w-full px-6 py-4',
          'flex flex-1 items-center',
          'absolute bottom-0 left-0 z-10',
          'bg-neutral-white animate-pulse',
          skim
        )}
      >
        <div className='w-1/5 h-4 rounded bg-neutral-lighter-two' />
      </div>
    </div>
  );
};

export default SkeletonLoader;
