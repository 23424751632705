import {
  ALLOWED_FIELD_TYPES,
  ALLOWED_FIELD_VALUE_SELECTORS,
  FIELD_CONSOLIDATED_TYPES,
} from 'utils/constants';
import { FieldsFilter } from 'utils/types/filters';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { FieldsFiltersQueryParams } from 'utils/customTypes';

export const getQueryParamsFromFilters = (filters: FieldsFilter) => {
  const typeFilters = get(filters, 'type', []);
  const queryParams = [] as FieldsFiltersQueryParams[];
  if (!isEmpty(typeFilters)) {
    typeFilters.forEach((type) => {
      switch (type) {
        case FIELD_CONSOLIDATED_TYPES.CHECKBOX:
          queryParams.push({
            value_selector: ALLOWED_FIELD_VALUE_SELECTORS.CHECKBOX,
          });
          break;
        case FIELD_CONSOLIDATED_TYPES.DATE_PICKER:
          queryParams.push({
            value_selector: ALLOWED_FIELD_VALUE_SELECTORS.DATE_PICKER,
          });
          break;
        case FIELD_CONSOLIDATED_TYPES.DROPDOWN:
          queryParams.push({
            value_selector: ALLOWED_FIELD_VALUE_SELECTORS.DROPDOWN,
          });
          break;
        case FIELD_CONSOLIDATED_TYPES.RADIO:
          queryParams.push({
            value_selector: ALLOWED_FIELD_VALUE_SELECTORS.RADIO,
          });
          break;
        case FIELD_CONSOLIDATED_TYPES.TOGGLE:
          queryParams.push({
            value_selector: ALLOWED_FIELD_VALUE_SELECTORS.FREEFORM,
            type: [ALLOWED_FIELD_TYPES.BOOLEAN],
          });
          break;
        case FIELD_CONSOLIDATED_TYPES.TEXT_FIELD:
          queryParams.push({
            value_selector: ALLOWED_FIELD_VALUE_SELECTORS.FREEFORM,
            type: [
              ALLOWED_FIELD_TYPES.SINGLE_LINE,
              ALLOWED_FIELD_TYPES.MULTILINE,
            ],
          });
          break;
        case FIELD_CONSOLIDATED_TYPES.NUMERICAL_FIELD:
          queryParams.push({
            value_selector: ALLOWED_FIELD_VALUE_SELECTORS.FREEFORM,
            type: [
              ALLOWED_FIELD_TYPES.NUMERIC,
              ALLOWED_FIELD_TYPES.INT,
              ALLOWED_FIELD_TYPES.FLOAT,
            ],
          });
          break;
        default:
          break;
      }
    });
  }

  return queryParams;
};
