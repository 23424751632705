import { useDispatch, useSelector } from 'react-redux';
import Card from '../Card';
import emptyDueTasksImg from 'assets/images/empty-due-tasks.svg';
import dueTasksIcon from 'assets/icons/due-tasks-icon.svg';
import intl from 'react-intl-universal';
import { useState } from 'react';
import {
  selectDueTasksStatus,
  selectOrderedDueTasksForCard,
  setDueTasksOrderForCard,
} from 'state/MyWorkspace/myWorkspaceSlice';
import TasksTable from './TasksTable';
import TasksModal from './TasksModal';
import { SLICE_STATUS } from 'utils/constants';
import TasksTableLoader from './TasksTableLoader';

const TasksCard = () => {
  const dispatch = useDispatch();
  const tasksSelector = useSelector(selectOrderedDueTasksForCard);
  const tasksStatus = useSelector(selectDueTasksStatus);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSort = (orderByParam: string[], order: 'desc' | 'asc') => {
    dispatch(setDueTasksOrderForCard({ order, orderBy: orderByParam }));
  };

  return (
    <>
      <TasksModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        icon={dueTasksIcon}
      />
      <Card
        title={intl.get('MY_WORKSPACE.DUE_TASKS.TITLE')}
        iconSrc={dueTasksIcon}
        emptyStateImg={emptyDueTasksImg}
        emptyStateText={intl.get('MY_WORKSPACE.DUE_TASKS.EMPTY_STATE_TEXT')}
        content={
          tasksStatus === SLICE_STATUS.LOADING ? (
            <TasksTableLoader />
          ) : tasksSelector.length || tasksStatus === SLICE_STATUS.FAILED ? (
            <TasksTable
              data={tasksSelector}
              handleSort={handleSort}
              cardTable
            />
          ) : null
        }
        showViewAllButton={tasksSelector.length ? true : false}
        viewAllTooltipText={intl.get('MY_WORKSPACE.DUE_TASKS.TOOLTIP_TEXT')}
        viewAllOnClick={() => setIsModalOpen(true)}
        dataCy='due-tasks'
      />
    </>
  );
};

export default TasksCard;
