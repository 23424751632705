import has from 'lodash/has';
import { ProjectTemplate, ProjectTemplateField } from 'utils/types/templates';
import {
  ProjectTemplateFieldUpdate,
  ProjectTemplateToUpdate,
} from './ProjectTemplateAPI';

export const formatPayloadForUpdateTemplateEndpoint = (
  template: Omit<ProjectTemplate, 'id' | 'project_template_fields'>
) => {
  const allowedValues = [
    'name',
    'description',
    'owner_id',
    'access',
    'status',
    'resource_type',
    'hidden',
  ];
  const payload: any = {};
  for (const value of allowedValues) {
    const key = value as keyof ProjectTemplateToUpdate;
    if (has(template, key)) {
      payload[key] = template[key];
    }
  }
  return payload;
};

export const formatPayloadForBulkCreateTemplateFieldsEndpoint = (
  templateFields: ProjectTemplateField[],
  currentTemplateVersion: number
) => {
  return templateFields.map((field: ProjectTemplateField) => ({
    project_template_id: field.project_template_id,
    field_template_id: field.field_template_id,
    required: field.required,
    layout: field.layout,
    defaultValue: field.defaultValue,
    currentTemplateVersion,
  }));
};

export const formatPayloadForBulkUpdateTemplateFieldsEndpoint = (
  templateFields: ProjectTemplateFieldUpdate[]
) => {
  return templateFields.map((field: ProjectTemplateFieldUpdate) => {
    const response: ProjectTemplateFieldUpdate = {
      id: field.id,
      layout: field.layout,
      defaultValue: field.defaultValue,
    };
    if (field.required !== undefined) {
      response.required = field.required;
    }
    return response;
  });
};
