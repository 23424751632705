import { PROJECTS_TABLE_FILTERS } from 'utils/constants';
import { Option, ProjectProcess, rangeDateType } from 'utils/customTypes';
import { ProjectFilters, RangeFilter } from 'utils/types/filters';

export function isArrayOfOptions(value: any): value is Option[] {
  return (
    Array.isArray(value) &&
    value.every((item) => 'label' in item && 'value' in item)
  );
}

export function isDateRange(value: any): value is rangeDateType {
  return (
    typeof value === 'object' && ('startDate' in value || 'endDate' in value)
  );
}

export const isDateRangeFilterEmpty = (filter: RangeFilter) =>
  filter.from === null && filter.to === null;

export const getUpdatedProcessesFilters = (
  selectedStages: Option[],
  organizationProcesses: ProjectProcess[],
  selectedFilters: ProjectFilters
) => {
  const updatedFilters = { ...selectedFilters };
  const selectedStagesProcesses: string[] = [];
  for (const stage of selectedStages) {
    const stageProcess = organizationProcesses.find((process: ProjectProcess) =>
      process.stagesOrdering.includes(stage.value)
    );
    if (stageProcess && !selectedStagesProcesses.includes(stageProcess.id)) {
      selectedStagesProcesses.push(stageProcess.id);
    }
  }
  const currentProcessesFilters = selectedFilters[
    PROJECTS_TABLE_FILTERS.PROCESS
  ] as string[];
  const newProcessesFilters = currentProcessesFilters
    ? selectedStagesProcesses.filter(
        (process: string) => !currentProcessesFilters.includes(process)
      )
    : selectedStagesProcesses;
  updatedFilters[PROJECTS_TABLE_FILTERS.PROCESS] = currentProcessesFilters
    ? currentProcessesFilters.concat(newProcessesFilters)
    : newProcessesFilters;
  return updatedFilters;
};
