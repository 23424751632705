import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { CENTRALIZED_TASKS_TABLE_TABS } from 'utils/constants/centralizedTasks';
import { TaskListFilters } from 'types/store/tasksList';
import {
  fetchMyTasks,
  selectFetchMyTasksStatus,
  selectMyTasks,
  selectMyTasksFilters,
  setFilters,
  setSorting,
  selectMyTasksTableSorting,
  selectCanFetchMoreMyTasks,
  selectMyTasksTotalCount,
} from 'state/TasksList/myTasks/myTasksSlice';
import { selectUserId } from 'state/User/userSlice';
import {
  fetchFilterSettingByType,
  selectFiltersSliceStatus,
} from 'state/Settings/Filters/FiltersSlice';
import TasksListTable from 'Pages/TasksListPage/components/v2/TasksListTable/TasksListTable';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';

const MyTasks = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const myTasks = useSelector(selectMyTasks);
  const currentUserId = useSelector(selectUserId);
  const fetchMyTasksStatus = useSelector(selectFetchMyTasksStatus);
  const fetchFilterSettingByTypeStatus = useSelector(selectFiltersSliceStatus);
  const myTasksFilters = useSelector(selectMyTasksFilters);
  const myTasksSorting = useSelector(selectMyTasksTableSorting);
  const canFetchMoreTasks = useSelector(selectCanFetchMoreMyTasks);
  const totalCount = useSelector(selectMyTasksTotalCount);
  const prevFetchMyTasksStatus = useRef<Status>(fetchMyTasksStatus);
  const prevFetchFilterSettingByTypeStatus = useRef<Status>(
    fetchFilterSettingByTypeStatus
  );

  useEffect(() => {
    dispatch(fetchFilterSettingByType(CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS));
  }, [dispatch]);

  useEffect(() => {
    if (
      fetchFilterSettingByTypeStatus !==
      prevFetchFilterSettingByTypeStatus.current
    ) {
      prevFetchFilterSettingByTypeStatus.current =
        fetchFilterSettingByTypeStatus;
      if (
        fetchFilterSettingByTypeStatus === SLICE_STATUS.IDLE &&
        currentUserId
      ) {
        dispatch(
          fetchMyTasks({
            userId: currentUserId,
            fetchNext: false,
            includeTotalCount: true,
          })
        );
      }
    }
  }, [dispatch, currentUserId, fetchFilterSettingByTypeStatus]);

  useEffect(() => {
    if (fetchMyTasksStatus !== prevFetchMyTasksStatus.current) {
      prevFetchMyTasksStatus.current = fetchMyTasksStatus;
      if (isLoading && fetchMyTasksStatus === SLICE_STATUS.IDLE) {
        setIsLoading(false);
      }
    }
  }, [fetchMyTasksStatus, isLoading]);

  const handleUpdateFilters = useCallback(
    (updatedFilters: TaskListFilters) => {
      dispatch(setFilters(updatedFilters));
      dispatch(
        fetchMyTasks({
          fetchNext: false,
          includeTotalCount: true,
          userId: currentUserId!,
        })
      );
    },
    [dispatch, currentUserId]
  );

  const handleTableSort = useCallback(
    (sortBy: string, order: string) => {
      dispatch(setSorting({ sortBy, order }));
      dispatch(
        fetchMyTasks({
          fetchNext: false,
          includeTotalCount: false,
          userId: currentUserId!,
        })
      );
    },
    [dispatch, currentUserId]
  );

  const handleFetchMoreTasks = useCallback(() => {
    dispatch(
      fetchMyTasks({
        fetchNext: true,
        includeTotalCount: false,
        userId: currentUserId!,
      })
    );
  }, [dispatch, currentUserId]);

  if (isLoading) {
    return (
      <SkeletonLoader
        data-testid={`${CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS}__skeleton-loader`}
      />
    );
  }

  return (
    <TasksListTable
      tableName={CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS}
      tasks={myTasks}
      totalCount={totalCount}
      filters={myTasksFilters}
      onUpdateFilters={handleUpdateFilters}
      sortBy={myTasksSorting.sortBy}
      order={myTasksSorting.order}
      handleSort={handleTableSort}
      canFetchMoreTasks={canFetchMoreTasks}
      fetchMoreTasks={handleFetchMoreTasks}
    />
  );
};

export default MyTasks;
