import intl from 'react-intl-universal';
import {
  IconButton,
  Tooltip,
  Typography,
  Icon,
} from '@getsynapse/design-system';
import { getStatusColumn } from 'Pages/ProjectsListPage/helpers/tableColumnsValues';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'utils/constants';
import { setSidePanelUpdatedData } from 'state/SidePanel/sidePanelSlice';
import { useDispatch } from 'react-redux';
import { NewProject } from 'utils/customTypes';
import archiveIcon from 'assets/icons/archive.svg';
import { formatProjectNumber } from 'utils/formatters';

interface ProjectSidePanelHeaderProps {
  onClose: () => void;
  projectId?: string;
  projectNumber: number;
  projectStatus: string;
  updatedData: NewProject;
}

const ProjectSidePanelHeader: React.FC<ProjectSidePanelHeaderProps> = ({
  onClose,
  projectId,
  projectNumber,
  projectStatus,
  updatedData,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div className='flex mr-3'>
      <div className='flex items-center flex-grow'>
        <Typography variant='label' className='pr-1'>
          <span className='font-semibold pr-1'>
            {intl.get('SIDE_PANEL.ID')}
          </span>
          :
        </Typography>
        <Typography variant='label'>
          {formatProjectNumber(projectNumber)}
        </Typography>
        <div className='ml-2'>{getStatusColumn(projectStatus)}</div>
        {updatedData.is_archived && (
          <Icon
            src={archiveIcon}
            className='text-neutral text-base pl-1'
            data-cy='archived-icon'
          />
        )}
      </div>

      <Tooltip
        openMode='hover1'
        timeout={0}
        position='botomCenter'
        contentProps={{ className: 'px-3 py-2 text-sm z-50' }}
        trigger={
          <IconButton
            data-cy='full-page__button'
            name='resize'
            iconClassname='pointer-events-none'
            className='text-lg p-1 rounded text-primary-dark hover:bg-neutral-lightest hover:shadow-allocation-table'
            onClick={() => {
              dispatch(setSidePanelUpdatedData(updatedData));
              history.push(
                `${PATHS.PROJECT_PAGE}/${projectId}?fromSidePanel=true`,
                {
                  from: history.location.pathname,
                }
              );
              onClose();
            }}
          />
        }
      >
        {intl.get('SIDE_PANEL.FULL_PAGE')}
      </Tooltip>
    </div>
  );
};

export default ProjectSidePanelHeader;
