import {
  createAsyncThunk,
  createAction,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { NewTask, Status } from 'utils/customTypes';
import { PageSizeType } from 'utils/types/pagination';
import { RootState } from 'state/store';
import TaskAPI from './taskAPI';
import projectTaskAPI from 'state/ProjectTasks/projectTaskAPI';
import { SLICE_STATUS } from 'utils/constants';
import { CENTRALIZED_TASKS_TABLE_TABS } from 'utils/constants/centralizedTasks';
import exportAPI from 'Services/exportAPI';
import { getTasksAssigneesList } from 'state/ProjectTasks/helpers';
import { selectFiltersSettingsByType } from 'state/Settings/Filters/FiltersSlice';
import { RawFilters, parseFiltersForBackend } from 'utils/filters';
import { DEFAULT_OFFSET, DEFAULT_PAGE_SIZE } from 'utils/constants/ui';
import {
  CentralizedTask,
  CentralizedTasksTableTab,
} from 'utils/types/centralizedTasks';

export interface Pagination {
  limit: PageSizeType;
  offset: number;
}

interface TasksState {
  teamTasks: CentralizedTask[];
  myTasks: CentralizedTask[];
  allTeamTasks: string[];
  allMyTasks: string[];
  [CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS]: {
    searchParam?: string;
    pagination: Pagination;
    sortBy?: string;
    sortOrder?: string;
  };
  [CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS]: {
    searchParam?: string;
    pagination: Pagination;
    sortBy?: string;
    sortOrder?: string;
  };
  fetchTasksStatus: Status;
  exportStatus: Status;
}

/* ============================= INITIAL STATE ============================== */
const initialState: TasksState = {
  teamTasks: [],
  myTasks: [],
  allTeamTasks: [],
  allMyTasks: [],
  [CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS]: {
    pagination: {
      limit: DEFAULT_PAGE_SIZE,
      offset: DEFAULT_OFFSET,
    },
  },
  [CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS]: {
    pagination: {
      limit: DEFAULT_PAGE_SIZE,
      offset: DEFAULT_OFFSET,
    },
  },
  fetchTasksStatus: SLICE_STATUS.IDLE,
  exportStatus: SLICE_STATUS.IDLE,
};

const tasksAPI = TaskAPI;
const projectTasksAPI = projectTaskAPI;

/* ============================== ACTIONS =============================== */
export const setFetchTasksStatus = createAction<Status>(
  'tasks/UPDATE_FETCH_TASKS_STATUS'
);

/* ============================== REDUX THUNK =============================== */
export const createTask = createAsyncThunk(
  'tasks/CREATE_TASK',
  async (taskData: NewTask) => {
    const response = await projectTasksAPI.createTask(taskData);
    return response.data.task;
  }
);

export const exportTasks = createAsyncThunk(
  'tasks/EXPORT_LIST',
  async (
    {
      taskIds,
    }: {
      taskIds: string[];
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await exportAPI.exportTasks(taskIds);
      return response.data.fileUrl;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTeamTasksPagination = createAsyncThunk(
  'tasks/UPDATE_TEAM_TABLE_PAGINATION',
  async (
    pagination: Pagination = {
      limit: DEFAULT_PAGE_SIZE,
      offset: DEFAULT_OFFSET,
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    const taskApiParams = getTaskApiParams(
      state,
      CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS,
      'pagination',
      pagination
    );

    const {
      data: { tasks, all_ids },
    } = await tasksAPI.fetchTeamTasks(taskApiParams);

    return { pagination, tasks, allIds: all_ids };
  }
);

export const updateMyTasksPagination = createAsyncThunk(
  'tasks/UPDATE_MY_TABLE_PAGINATION',
  async (
    pagination: Pagination = {
      limit: DEFAULT_PAGE_SIZE,
      offset: DEFAULT_OFFSET,
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    const taskApiParams = getTaskApiParams(
      state,
      CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS,
      'pagination',
      pagination
    );

    const {
      data: { tasks, all_ids },
    } = await tasksAPI.fetchUserTasks(taskApiParams);

    return { pagination, tasks, allIds: all_ids };
  }
);

export const updateTeamTasksFilters = createAsyncThunk(
  'tasks/UPDATE_TEAM_TABLE_FILTERS',
  async (rawFilters: RawFilters, { getState }) => {
    const state = getState() as RootState;
    const taskApiParams = getTaskApiParams(
      state,
      CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS,
      'filters',
      rawFilters
    );

    const {
      data: { tasks, all_ids },
    } = await tasksAPI.fetchTeamTasks(taskApiParams);

    return {
      pagination: {
        limit: taskApiParams.limit,
        offset: taskApiParams.offset,
      },
      tasks,
      allIds: all_ids,
    };
  }
);

export const updateMyTasksFilters = createAsyncThunk(
  'tasks/UPDATE_MY_TABLE_FILTERS',
  async (rawFilters: RawFilters, { getState }) => {
    const state = getState() as RootState;
    const taskApiParams = getTaskApiParams(
      state,
      CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS,
      'filters',
      rawFilters
    );

    const {
      data: { tasks, all_ids },
    } = await tasksAPI.fetchUserTasks(taskApiParams);

    return {
      pagination: {
        limit: taskApiParams.limit,
        offset: taskApiParams.offset,
      },
      tasks,
      allIds: all_ids,
    };
  }
);

export const updateTeamTasksSort = createAsyncThunk(
  'tasks/UPDATE_TEAM_TABLE_SORT',
  async ([sortBy, sortOrder]: [string, string], { getState }) => {
    const state = getState() as RootState;
    const taskApiParams = getTaskApiParams(
      state,
      CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS,
      'sort',
      [sortBy, sortOrder]
    );

    const {
      data: { tasks, all_ids },
    } = await tasksAPI.fetchTeamTasks(taskApiParams);

    return {
      sortBy,
      sortOrder,
      tasks,
      allIds: all_ids,
    };
  }
);

export const updateMyTasksSort = createAsyncThunk(
  'tasks/UPDATE_MY_TABLE_SORT',
  async ([sortBy, sortOrder]: [string, string], { getState }) => {
    const state = getState() as RootState;
    const taskApiParams = getTaskApiParams(
      state,
      CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS,
      'sort',
      [sortBy, sortOrder]
    );

    const {
      data: { tasks, all_ids },
    } = await tasksAPI.fetchUserTasks(taskApiParams);

    return {
      sortBy,
      sortOrder,
      tasks,
      allIds: all_ids,
    };
  }
);

export const updateTeamSearchParam = createAsyncThunk(
  'tasks/UPDATE_TEAM_SEARCH_PARAM',
  async (searchParam: string, { getState }) => {
    const state = getState() as RootState;
    const taskApiParams = getTaskApiParams(
      state,
      CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS,
      'search',
      searchParam
    );

    const {
      data: { tasks, all_ids },
    } = await tasksAPI.fetchTeamTasks(taskApiParams);

    return {
      pagination: {
        limit: taskApiParams.limit,
        offset: taskApiParams.offset,
      },
      searchParam,
      tasks,
      allIds: all_ids,
    };
  }
);

export const updateMySearchParam = createAsyncThunk(
  'tasks/UPDATE_MY_SEARCH_PARAM',
  async (searchParam: string, { getState }) => {
    const state = getState() as RootState;
    const taskApiParams = getTaskApiParams(
      state,
      CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS,
      'search',
      searchParam
    );

    const {
      data: { tasks, all_ids },
    } = await tasksAPI.fetchUserTasks(taskApiParams);

    return {
      pagination: {
        limit: taskApiParams.limit,
        offset: taskApiParams.offset,
      },
      searchParam,
      tasks,
      allIds: all_ids,
    };
  }
);

/* ================================= REDUCER ================================ */
const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setFetchTasksStatus, (state, action) => {
        state.fetchTasksStatus = action.payload;
      })
      .addCase(updateTeamTasksPagination.fulfilled, (state, action) => {
        state.fetchTasksStatus = SLICE_STATUS.IDLE;
        state.teamTasks = [...action.payload.tasks];
        state.allTeamTasks = [...action.payload.allIds];
        state[CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS].pagination =
          action.payload.pagination;
      })
      .addCase(updateMyTasksPagination.fulfilled, (state, action) => {
        state.fetchTasksStatus = SLICE_STATUS.IDLE;
        state.myTasks = [...action.payload.tasks];
        state.allMyTasks = [...action.payload.allIds];
        state[CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS].pagination =
          action.payload.pagination;
      })
      .addCase(updateTeamTasksFilters.fulfilled, (state, action) => {
        state.teamTasks = [...action.payload.tasks];
        state.allTeamTasks = [...action.payload.allIds];
        state[CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS].pagination =
          action.payload.pagination;
      })
      .addCase(updateMyTasksFilters.fulfilled, (state, action) => {
        state.myTasks = [...action.payload.tasks];
        state.allMyTasks = [...action.payload.allIds];
        state[CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS].pagination =
          action.payload.pagination;
      })
      .addCase(updateTeamTasksSort.fulfilled, (state, action) => {
        state.teamTasks = [...action.payload.tasks];
        state.allTeamTasks = [...action.payload.allIds];
        state[CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS].sortBy =
          action.payload.sortBy;
        state[CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS].sortOrder =
          action.payload.sortOrder;
      })
      .addCase(updateMyTasksSort.fulfilled, (state, action) => {
        state.myTasks = [...action.payload.tasks];
        state.allMyTasks = [...action.payload.allIds];
        state[CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS].sortBy =
          action.payload.sortBy;
        state[CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS].sortOrder =
          action.payload.sortOrder;
      })
      .addCase(updateTeamSearchParam.fulfilled, (state, action) => {
        state.teamTasks = [...action.payload.tasks];
        state.allTeamTasks = [...action.payload.allIds];
        state[CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS].pagination =
          action.payload.pagination;
        state[CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS].searchParam =
          action.payload.searchParam;
      })
      .addCase(updateMySearchParam.fulfilled, (state, action) => {
        state.myTasks = [...action.payload.tasks];
        state.allMyTasks = [...action.payload.allIds];
        state[CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS].pagination =
          action.payload.pagination;
        state[CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS].searchParam =
          action.payload.searchParam;
      })
      .addCase(exportTasks.pending, (state) => {
        state.exportStatus = SLICE_STATUS.LOADING;
      })
      .addCase(exportTasks.fulfilled, (state, action) => {
        state.exportStatus = SLICE_STATUS.IDLE;
        window.location.href = action.payload;
      })
      .addCase(exportTasks.rejected, (state) => {
        state.exportStatus = SLICE_STATUS.FAILED;
      });
  },
});

/* ================================= HELPERS ================================ */
const getTaskApiParams = (
  state: RootState,
  taskTable: CentralizedTasksTableTab,
  changedParam: 'pagination' | 'filters' | 'search' | 'sort',
  param: Pagination | RawFilters | string | [string, string]
) => {
  if (changedParam === 'pagination') {
    const searchParam = selectTasksSearch(taskTable)(state);
    const rawFilters = selectFiltersSettingsByType(taskTable)(state);
    const filters = parseFiltersForBackend(rawFilters?.settings);
    const [sortBy, sortOrder] = selectTasksSort(taskTable)(state);

    return {
      ...(param as Pagination),
      ...filters,
      sortBy,
      sortOrder,
      search: searchParam ? searchParam : undefined,
    };
  } else if (changedParam === 'filters') {
    const searchParam = selectTasksSearch(taskTable)(state);
    const pagination = selectTasksTablePagination(taskTable)(state);
    const newPagination: Pagination = {
      limit: pagination.limit,
      offset: DEFAULT_OFFSET,
    };
    const filters = parseFiltersForBackend(param as RawFilters);
    const [sortBy, sortOrder] = selectTasksSort(taskTable)(state);

    return {
      ...newPagination,
      ...filters,
      sortBy,
      sortOrder,
      search: searchParam ? searchParam : undefined,
    };
  } else if (changedParam === 'sort') {
    const searchParam = selectTasksSearch(taskTable)(state);
    const rawFilters = selectFiltersSettingsByType(taskTable)(state);
    const pagination = selectTasksTablePagination(taskTable)(state);
    const filters = parseFiltersForBackend(rawFilters?.settings);
    const [sortBy, sortOrder] = param as [string, string];

    return {
      ...pagination,
      ...filters,
      sortBy,
      sortOrder,
      search: searchParam ? searchParam : undefined,
    };
  } else {
    const rawFilters = selectFiltersSettingsByType(taskTable)(state);
    const filters = parseFiltersForBackend(rawFilters?.settings);
    const pagination = selectTasksTablePagination(taskTable)(state);
    const [sortBy, sortOrder] = selectTasksSort(taskTable)(state);
    const newPagination: Pagination = {
      limit: pagination.limit,
      offset: DEFAULT_OFFSET,
    };

    return {
      ...newPagination,
      ...filters,
      sortBy,
      sortOrder,
      search: param ? (param as string) : undefined,
    };
  }
};

/* =============================== SELECTORS ================================ */
export const selectAllTeamTasksIds = (state: RootState) =>
  state.tasks.allTeamTasks;
export const selectAllMyTasksIds = (state: RootState) => state.tasks.allMyTasks;

export const selectTeamTasks = (state: RootState) => state.tasks.teamTasks;

export const selectMyTasks = (state: RootState) => state.tasks.myTasks;

export const tasksFetchStatus = (state: RootState) =>
  state.tasks.fetchTasksStatus;

export const exportTasksStatus = (state: RootState) => state.tasks.exportStatus;

export const selectMyTasksList = createSelector(
  [
    selectMyTasks,
    (state: RootState) =>
      state.tasks[CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS].pagination,
  ],
  (tasks: CentralizedTask[]) => {
    return {
      data: tasks,
      total: tasks.length,
    };
  }
);

export const selectTeamTasksList = createSelector(
  [
    selectTeamTasks,
    (state: RootState) =>
      state.tasks[CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS].pagination,
  ],
  (tasks: CentralizedTask[]) => {
    return {
      data: tasks,
      total: tasks.length,
    };
  }
);

export const selectTasksTablePagination =
  (table: CentralizedTasksTableTab) => (state: RootState) =>
    state.tasks[table].pagination;

export const selectTasksSearch =
  (table: CentralizedTasksTableTab) => (state: RootState) =>
    state.tasks[table].searchParam;

export const selectTasksSort =
  (table: CentralizedTasksTableTab) => (state: RootState) =>
    [state.tasks[table].sortBy, state.tasks[table].sortOrder];

export const getTeamTasksAssigneesList = createSelector(
  [selectTeamTasks],
  (tasks: CentralizedTask[]) => {
    const assignees = getTasksAssigneesList(tasks);
    return assignees;
  }
);

export const getUserTasksAssigneesList = createSelector(
  [selectMyTasks],
  (tasks: CentralizedTask[]) => {
    const assignees = getTasksAssigneesList(tasks);
    return assignees;
  }
);

export default tasksSlice.reducer;
