import { Avatar } from '@getsynapse/design-system';
import get from 'lodash/get';
import { useMemo } from 'react';
import { AvatarUser } from 'utils/customTypes';
import { isUserStatusDisabled } from 'utils/functions';

interface Props {
  className?: string;
  size?: 'small' | 'medium' | 'large';
  user?: AvatarUser;
  showTooltip?: boolean;
}

const UserAvatar = ({ className, size, user, showTooltip = true }: Props) => {
  const getInitials = () => {
    const displayInitial = `${get(user, 'data.firstName[0]', '')}${get(
      user,
      'data.lastName[0]',
      ''
    )}`;
    if (!displayInitial) {
      return get(user, 'data.email[0]', '');
    }
    return displayInitial;
  };

  const fullName = useMemo(
    () => `${user?.data.firstName} ${user?.data.lastName}`,
    [user]
  );

  const isDisabled = useMemo(() => isUserStatusDisabled(user?.status), [user]);

  return (
    <Avatar
      className={className}
      imageSrc={get(user, 'avatar_url')}
      initial={getInitials()}
      name={fullName}
      size={size}
      disabled={isDisabled}
      showTooltip={showTooltip}
    />
  );
};

export default UserAvatar;
