import { useState, useMemo, SetStateAction, Dispatch, useEffect } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import BasicDetails from './BasicDetailsPage';
import LDOnlyPage from './LDOnlyPage';
import { selectUserId, selectUserType } from 'state/User/userSlice';
import {
  REQUEST_STATUS,
  USER_TYPES,
  LONG_INPUTS_LENGTH,
  REQUEST_SECTIONS,
} from 'utils/constants';
import {
  Request,
  RequestQuestion,
  UpdateReqData,
  User,
} from 'utils/customTypes';
import PendingCancellationBanner from './components/PendingCancellationBanner';
import { useRequestContext } from './context/RequestContext';
import ApprovedRequestBanner from './components/ApprovedRequest/ApprovedRequestBanner';
import CancelledRequestBanner from './components/CancelledRequest/CancelledRequestBanner';
import DeclinedRequestBanner from './components/DeclinedRequest/DeclinedRequestBanner';
import WaitlistedRequestBanner from './components/WaitlistedRequest/WaitlistedRequestBanner';
import SidePanelTitle from 'Atoms/SidePanelTitle/SidePanelTitle';
import {
  validateRequiredFields,
  validateRequestRequiredQuestion,
} from './helpers/validateFields';
import { isEmpty, isEqual, debounce, filter } from 'lodash';
import UnsavedChangesBanner from 'Atoms/UnsavedChangesBanner/UnsavedChangesBanner';

type Props = {
  requestData: Request;
  updatedReqData?: UpdateReqData;
  requestQuestionsData: RequestQuestion[];
  setUpdatedReqData?: Dispatch<React.SetStateAction<UpdateReqData>>;
  setShouldDisableUpdate: Dispatch<SetStateAction<boolean>>;
  unsavedChanges: boolean;
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>;
  showUnsavedChangesAnimation: boolean;
  setShouldDisableSubmitRequest: Dispatch<SetStateAction<boolean>>;
  setIsRequestDetailFieldsValid: Dispatch<SetStateAction<boolean>>;
  isDataReady: boolean;
};

const RequestSidePanel = ({
  requestData,
  updatedReqData,
  setUpdatedReqData = () => {},
  requestQuestionsData,
  setShouldDisableUpdate,
  unsavedChanges,
  setUnsavedChanges,
  showUnsavedChangesAnimation,
  setShouldDisableSubmitRequest,
  setIsRequestDetailFieldsValid,
  isDataReady,
}: Props) => {
  const [owners, setOwners] = useState<string[] | null>(null);
  const [businessReviewers, setBusinessReviewers] = useState<string[] | null>(
    null
  );
  const [ldReviewers, setLDReviewers] = useState<string[] | null>(null);
  const [currentTitle, setCurrentTitle] = useState(requestData.title);
  const userId = useSelector(selectUserId);
  const userType = useSelector(selectUserType);
  const originalBusinessReviewerIds = useMemo(() => {
    return requestData.reviewers
      ?.filter((reviewer: User) => reviewer.type === USER_TYPES.BUSINESS)
      .map((reviewer: User) => reviewer.id);
  }, [requestData.reviewers]);
  const originalLDReviewerIds = useMemo(() => {
    return requestData.reviewers
      ?.filter((reviewer: User) => reviewer.type === USER_TYPES.L_D)
      .map((reviewer: User) => reviewer.id);
  }, [requestData.reviewers]);
  const isLDReviewer = useMemo<boolean>(
    () => originalLDReviewerIds?.includes(userId),
    [originalLDReviewerIds, userId]
  );

  const showPendingCancellationBanner =
    requestData.status === REQUEST_STATUS.PENDING_CANCELLATION &&
    requestData.owners?.some((owner) => owner.id === userId);

  const {
    areBasicAndRequestDisabled,
    isLDDisabled,
    isBusinessUnitDisabled,
    shouldUseUserBusinessTeamAsDefault,
    requestHasUpdate,
  } = useRequestContext();

  const basicQuestions = useMemo(
    () =>
      requestQuestionsData.filter(
        (que) => que.section === REQUEST_SECTIONS.BASIC_DETAILS
      ),
    [requestQuestionsData]
  );

  const questionsWithLD = useMemo(
    () =>
      requestQuestionsData.filter(
        (que) =>
          que.section === REQUEST_SECTIONS.LD_DETAILS ||
          que.section === REQUEST_SECTIONS.BASIC_DETAILS
      ),
    [requestQuestionsData]
  );

  const areRequestDetailQuestionsValid = useMemo(() => {
    if (!isDataReady) return false;
    const requiredQuestionsWithoutAnswers = filter(
      requestQuestionsData.filter(
        (que) => que.section === REQUEST_SECTIONS.REQUEST_DETAILS
      ),
      validateRequestRequiredQuestion
    );
    if (requiredQuestionsWithoutAnswers.length) {
      return false;
    }
    return true;
  }, [requestQuestionsData, isDataReady]);

  useEffect(() => {
    setIsRequestDetailFieldsValid(areRequestDetailQuestionsValid);
  }, [areRequestDetailQuestionsValid, setIsRequestDetailFieldsValid]);

  useEffect(() => {
    const filteredRequestQuestions =
      userType === USER_TYPES.L_D && requestData.status !== REQUEST_STATUS.DRAFT
        ? questionsWithLD
        : basicQuestions;
    setUpdatedReqData((prev) => ({
      ...prev,
      requestQuestions: filteredRequestQuestions.reduce(
        (reqQuestions, questionData) => ({
          ...reqQuestions,
          [questionData.id]: questionData,
        }),
        {}
      ),
    }));
  }, [
    basicQuestions,
    questionsWithLD,
    requestData.status,
    setUpdatedReqData,
    userType,
  ]);

  useEffect(() => {
    return () => {
      setOwners(null);
      setLDReviewers(null);
      setBusinessReviewers(null);
    };
  }, []);

  useEffect(() => {
    const areFieldsValid = validateRequiredFields(
      currentTitle!,
      requestData,
      updatedReqData!,
      shouldUseUserBusinessTeamAsDefault,
      false
    );
    const ownerChange =
      !isEmpty(owners) && !isEqual(requestData.owners, owners);
    const ldReviewersChange =
      !isEmpty(ldReviewers) && !isEqual(originalLDReviewerIds, ldReviewers);
    const businessReviewerChange =
      !isEmpty(businessReviewers) &&
      !isEqual(originalBusinessReviewerIds, businessReviewers);
    const detectChangeInUsers =
      ownerChange || ldReviewersChange || businessReviewerChange;
    const detectChanges = detectChangeInUsers || requestHasUpdate;
    setUnsavedChanges(detectChanges);
    if (requestData.status === REQUEST_STATUS.DRAFT) {
      setShouldDisableUpdate(!detectChanges);
    } else {
      setShouldDisableUpdate(!areFieldsValid || !detectChanges);
    }
    setShouldDisableSubmitRequest(!areFieldsValid);
  }, [
    businessReviewers,
    currentTitle,
    ldReviewers,
    originalBusinessReviewerIds,
    originalLDReviewerIds,
    owners,
    requestData,
    requestHasUpdate,
    setShouldDisableUpdate,
    shouldUseUserBusinessTeamAsDefault,
    updatedReqData,
    setUnsavedChanges,
    setShouldDisableSubmitRequest,
  ]);

  const onChange = debounce(
    (path: string, value: string | boolean | { id: string }[]) =>
      setUpdatedReqData((prevState: UpdateReqData) => ({
        ...prevState,
        requestAttributes: {
          ...prevState.requestAttributes,
          [path]: value,
        },
      })),
    500
  );

  return (
    <>
      <SidePanelTitle
        placeholder={intl.get('REQUEST_PAGE.BASIC_DETAILS.REQUEST_TITLE')}
        maxLength={LONG_INPUTS_LENGTH}
        onChange={(event) => {
          setCurrentTitle(event.target.value);
          onChange('title', event.target.value);
        }}
        disabled={areBasicAndRequestDisabled}
        value={currentTitle!}
      />
      {unsavedChanges && (
        <UnsavedChangesBanner
          showEmphasisAnimation={showUnsavedChangesAnimation}
          message={intl.get('SIDE_PANEL.UNSAVED_CHANGES')}
          dataTestId='request-unsaved-changes-banner'
        />
      )}
      {showPendingCancellationBanner && (
        <PendingCancellationBanner
          requestData={requestData}
          sidePanel
          dataTestId='request-pending-cancellation-banner'
        />
      )}
      {requestData.status === REQUEST_STATUS.APPROVED && (
        <ApprovedRequestBanner
          requestData={requestData}
          sidePanel
          dataTestId='request-approved-banner'
        />
      )}
      {requestData.status === REQUEST_STATUS.CANCELED && (
        <CancelledRequestBanner
          requestData={requestData}
          sidePanel
          dataTestId='request-cancelled-banner'
        />
      )}
      {requestData.status === REQUEST_STATUS.REJECTED && (
        <DeclinedRequestBanner
          requestData={requestData}
          sidePanel
          dataTestId='request-declined-banner'
        />
      )}
      {requestData.status === REQUEST_STATUS.WAITLISTED && (
        <WaitlistedRequestBanner
          requestData={requestData}
          sidePanel
          dataTestId='request-waitlisted-banner'
        />
      )}
      <BasicDetails
        requestData={requestData}
        requestQuestionsData={requestQuestionsData}
        showComment={false}
        setUpdatedReqData={setUpdatedReqData}
        updatedReqData={updatedReqData}
        disableFields={areBasicAndRequestDisabled}
        disabledBusinessUnits={isBusinessUnitDisabled}
        setOwners={setOwners}
        setBusinessReviewers={setBusinessReviewers}
        setLDReviewers={setLDReviewers}
        isLDReviewer={isLDReviewer}
        sidePanel
      />
      {userType === USER_TYPES.L_D &&
        requestData.status !== REQUEST_STATUS.DRAFT && (
          <LDOnlyPage
            requestData={requestData}
            requestQuestionsData={requestQuestionsData}
            setUpdatedReqData={setUpdatedReqData}
            showComment={false}
            disableFields={isLDDisabled}
            sidePanel
          />
        )}
    </>
  );
};

export default RequestSidePanel;
