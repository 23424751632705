import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { SLICE_STATUS } from 'utils/constants';
import { Status, Design, AllUsersType } from 'utils/customTypes';
import { allActiveUsers } from 'state/UsersManagement/usersManagementSlice';
import DesignsAPI from './designsAPI';

interface DesignSlice {
  designs: any;
  status: Status;
}

/* ============================= INITIAL STATE ============================== */
const initialState: DesignSlice = {
  designs: [],
  status: SLICE_STATUS.IDLE,
};

/* ============================== REDUX THUNK =============================== */
export const getDesigns = createAsyncThunk('design/GET_DESIGNS', async () => {
  const { projects } = await DesignsAPI.fetchDesigns();
  return projects.rows;
});

/* ================================= REDUCER ================================ */
const designsSlice = createSlice({
  name: 'designs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDesigns.fulfilled, (state, action) => {
      state.designs = action.payload;
    });
  },
});

/* =============================== SELECTORS ================================ */
export const selectDesigns = createSelector(
  [(state: RootState) => state.designs.designs, allActiveUsers],
  (designs: Design[], users: AllUsersType[]) => {
    return designs.map((design: Design) => {
      const foundOwner = users.find(
        (user: AllUsersType) => user.data.email === design.owner
      );
      if (foundOwner) {
        return { ...design, ownerData: foundOwner.data };
      } else {
        return design;
      }
    });
  }
);

export default designsSlice.reducer;
