import { FC, useMemo } from 'react';
import {
  Table,
  tailwindOverride,
  TableOperationHeader,
} from '@getsynapse/design-system';
import { TaskWithAssignees } from 'types/store/projectTasksList';
import { ProjectTasksTableTab } from 'utils/customTypes';
import { TASK_STATUS } from 'utils/constants';
import TableHeader from './TableHeader/TableHeader';
import TableBody from './TableBody/TableBody';
import TableFooter from 'Pages/TasksListPage/components/v2/TasksListTable/TableFooter/TableFooter';

interface TasksTableProps {
  tableName: ProjectTasksTableTab;
  tasks: TaskWithAssignees[];
  totalCount?: number;
  canFetchMoreTasks: boolean;
  fetchMoreTasks: () => void;
}

const TasksTable: FC<TasksTableProps> = ({
  tableName,
  tasks,
  totalCount,
  canFetchMoreTasks,
  fetchMoreTasks,
}) => {
  const showDisabledColumn = useMemo(
    () =>
      tasks.some(
        (task) => task.disabled || task.status === TASK_STATUS.COMPLETED
      ),
    [tasks]
  );

  const isListEmpty = tasks.length === 0;

  return (
    <>
      <TableOperationHeader />
      <div
        className={tailwindOverride(
          'w-full max-h-centralized-tasks-table',
          'rounded-b border-neutral-lighter-two border border-t-0',
          'overflow-auto'
        )}
      >
        <Table
          className={tailwindOverride('relative', {
            'max-w-full border-0': !isListEmpty,
            'w-full': isListEmpty,
          })}
          canSelectRows
          isSelectRowCellSticky={true}
          aria-label={tableName}
          data={{
            headData: {
              headCells: [],
            },
            rows: [],
          }}
        >
          <TableHeader
            showDisabledColumn={showDisabledColumn}
            isListEmpty={tasks.length === 0}
          />
          <TableBody
            tableName={tableName}
            tasks={tasks}
            showDisabledColumn={showDisabledColumn}
            canFetchMoreTasks={canFetchMoreTasks}
            fetchMoreTasks={fetchMoreTasks}
          />
        </Table>
      </div>
      <TableFooter total={totalCount} />
    </>
  );
};

export default TasksTable;
