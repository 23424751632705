import React, { useMemo } from 'react';
import { get } from 'lodash';
import intl from 'react-intl-universal';
import {
  Button,
  Typography,
  tailwindOverride,
  Tag,
} from '@getsynapse/design-system';
import { TeamMemberCapacity } from 'utils/types/teamsCapacity';
import UserAvatar from 'Atoms/UserAvatar';
import { isUserStatusDisabled } from 'utils/functions';

const TeamMemberData: React.FC<{
  teamId: string;
  memberData: TeamMemberCapacity['data'];
  hasProjectsAssigned: boolean;
  isProjectsListDisplayed: boolean;
  toggleProjectsList: () => void;
  className?: string;
}> = ({
  teamId,
  memberData,
  hasProjectsAssigned,
  isProjectsListDisplayed,
  toggleProjectsList,
  className,
}) => {
  const maxRoles = memberData.isTeamManager ? 2 : 3;

  const isMemberDisabled = useMemo(
    () => isUserStatusDisabled(memberData.status),
    [memberData.status]
  );
  return (
    <div className={tailwindOverride('flex flex-1 relative', className)}>
      <div className='w-10' />
      <div className={tailwindOverride('w-3/5 h-full', 'flex', 'px-2 py-3.5')}>
        <div className={tailwindOverride('flex flex-row gap-2 max-w-full')}>
          <UserAvatar
            user={{
              avatar_url: get(memberData, 'avatar_url'),
              data: {
                firstName: get(memberData, 'firstName'),
                lastName: get(memberData, 'lastName'),
                email: get(memberData, 'email'),
              },
              status: memberData.status,
            }}
            showTooltip={false}
          />

          <div className='flex flex-col min-w-0'>
            <div className='flex flex-row justify-between items-center gap-2.5'>
              <Typography
                variant='label'
                className={tailwindOverride(
                  'text-primary font-semibold mb-1 whitespace-nowrap overflow-hidden overflow-ellipsis flex-1',
                  {
                    'text-neutral-dark': isMemberDisabled,
                  }
                )}
              >
                {`${get(memberData, 'firstName')} ${get(
                  memberData,
                  'lastName'
                )}`}
              </Typography>

              {isMemberDisabled && (
                <Tag
                  label={intl.get('DEACTIVATED')}
                  className='bg-neutral-lighter-two'
                  textClassName='text-neutral-dark'
                />
              )}
            </div>
            <Typography variant='label' className='text-neutral mb-2 break-all'>
              {get(memberData, 'email')}
            </Typography>
            {!hasProjectsAssigned && (
              <Typography variant='caption' className='text-primary-dark'>
                {intl.get('TEAMS_CAPACITY.NO_PROJECTS_ASSIGNED')}
              </Typography>
            )}
            {hasProjectsAssigned && (
              <Button
                variant='tertiary'
                className='text-sm self-start px-1 text-primary'
                onClick={toggleProjectsList}
                size='small'
                aria-label={`team-${teamId}__member-${memberData.id}__toggle-projects-button`}
                data-cy={`team-${teamId}__member-${memberData.id}__toggle-projects-button`}
              >
                {isProjectsListDisplayed
                  ? intl.get('TEAMS_CAPACITY.HIDE_PROJECTS')
                  : intl.get('TEAMS_CAPACITY.VIEW_PROJECTS')}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div
        className={tailwindOverride('w-2/5 h-full flex flex-col', 'px-2 py-4')}
      >
        {memberData.isTeamManager && (
          <Tag
            label={intl.get('TEAMS_CAPACITY.TEAM_MANAGER')}
            textClassName='text-secondary-darker text-xs'
            className={tailwindOverride(
              'bg-secondary-lighter mb-2',
              'py-0.5 px-2'
            )}
          />
        )}
        {memberData.roles.slice(0, maxRoles).map((role, index) => (
          <Typography
            key={`team-${teamId}__member-${memberData.id}__role-${index}`}
            variant='caption'
            className='text-primary break-all'
          >
            {`${role}`}
          </Typography>
        ))}
        {memberData.roles.length > maxRoles && (
          <Typography variant='caption' className='text-primary'>{`+${
            memberData.roles.length - maxRoles
          }`}</Typography>
        )}
      </div>
    </div>
  );
};

export default TeamMemberData;
