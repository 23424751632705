import { useState, useMemo } from 'react';
import { PieChart, Cell, Pie, Sector, ResponsiveContainer } from 'recharts';
import { CHART_COLOR_PALETTE } from 'Pages/InsightsPage/chartConstants';
import {
  IN_COLOR_MAP,
  inActiveColor,
  CENTER_TEXT_COLOR,
  PATH_CIRCLE_COLOR,
  DATA_STATE_NO_PROJECTS_LINKED,
} from './constants';

const PieChartComponent = ({
  requestDetails,
  reasons,
}: {
  requestDetails: any;
  reasons: any;
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [sectorClicked, setSectorClicked] = useState(false);
  const [renderBlankState, setBlankState] = useState(false);
  const [sectorColors, setSectorColors] = useState(CHART_COLOR_PALETTE);

  const totalRequests = requestDetails.declinedRequests;

  useMemo(() => {
    if (totalRequests === 0) {
      setBlankState(true);
      setSectorColors(IN_COLOR_MAP);
    } else {
      setBlankState(false);
      setSectorColors(CHART_COLOR_PALETTE);
    }
  }, [totalRequests]);

  const data = useMemo(() => {
    return totalRequests === 0 ? DATA_STATE_NO_PROJECTS_LINKED : reasons;
  }, [reasons, totalRequests]);

  const renderActiveShape = (props: {
    cx?: any;
    cy?: any;
    midAngle?: any;
    innerRadius?: any;
    outerRadius?: any;
    startAngle?: any;
    endAngle?: any;
    fill?: any;
    percent?: any;
    value?: any;
    showArc?: any;
  }) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 4) * cos;
    const sy = cy + (outerRadius + 4) * sin;
    const mx = cx + (outerRadius + 12) * cos;
    const my = cy + (outerRadius + 12) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    const chartArcElements = !renderBlankState ? (
      <>
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 2}
          outerRadius={outerRadius + 4}
          fill={fill}
          style={{ outline: 'none' }}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}`}
          stroke={PATH_CIRCLE_COLOR}
          fill='none'
        />
        <circle
          cx={mx}
          cy={my}
          r={1.5}
          fill={PATH_CIRCLE_COLOR}
          stroke={PATH_CIRCLE_COLOR}
        />
        <text
          x={ex - (cos >= 0 ? 1 : -1) * 18}
          y={sin >= 0 ? ey + 1 * 2 : ey - 1}
          textAnchor={textAnchor}
          fill={CENTER_TEXT_COLOR}
          fontWeight={600}
          fontSize='0.875em'
        >
          {`${value}`}
        </text>
      </>
    ) : null;
    return (
      <g style={{ outline: 'none' }}>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          style={{ outline: 'none' }}
        />
        {chartArcElements}
      </g>
    );
  };

  const handleMouseEnter = (event: any, index: number) => {
    setActiveIndex(index);
  };
  const handleMouseLeave = (event: any, index: number) => {
    setSectorClicked(false);
  };

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <PieChart>
        <Pie
          data={data}
          activeIndex={activeIndex}
          activeShape={(requestDetails) => renderActiveShape(requestDetails)}
          innerRadius='38%'
          outerRadius='85%'
          cx='48%'
          paddingAngle={1}
          dataKey='requestCount'
          onMouseEnter={(_, index) => handleMouseEnter(_, index)}
          onMouseLeave={(_, index) => handleMouseLeave(_, index)}
        >
          {data.map((entry: any, index: number) => (
            <Cell
              key={`cell-${index}`}
              style={{
                outline: 'none',
              }}
              fill={
                sectorClicked
                  ? activeIndex === index % sectorColors.length
                    ? sectorColors[index % sectorColors.length]
                    : inActiveColor
                  : sectorColors[index % sectorColors.length]
              }
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
