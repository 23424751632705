import { useEffect } from 'react';
import {
  Typography,
  Datepicker,
  FormItem,
  LargeLabel,
} from '@getsynapse/design-system';
import { Option } from 'utils/customTypes';
import {
  selectBusinessTeamsForDropdownV2,
  getBusinessTeamsForDropdown,
} from 'state/BusinessTeams/businessTeamsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getInitialValueForDropDown } from 'utils/functions';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';
import intl from 'react-intl-universal';
import { rangeDateType } from 'utils/customTypes';
import { ProgramInputCategoryData } from 'utils/types/program';

const RequestsSectionAdditionalFields = ({
  linkedBusinessUnits,
  completionDate,
  updateLinkedBusinessUnits,
  updateInputField,
}: {
  linkedBusinessUnits: string[];
  completionDate: string;
  updateLinkedBusinessUnits: (businessUnits: string[]) => void;
  updateInputField: (
    value: string,
    property: keyof ProgramInputCategoryData
  ) => void;
}) => {
  const dispatch = useDispatch();
  const businessUnitsOptions = useSelector(selectBusinessTeamsForDropdownV2);

  useEffect(() => {
    dispatch(getBusinessTeamsForDropdown());
  }, [dispatch]);

  return (
    <div className='mt-6'>
      <Typography variant='body2' className='text-neutral-darker mb-6'>
        {intl.get(
          'INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.ADDITIONAL_FIELDS_PROMPT'
        )}
      </Typography>
      <div className='flex w-full gap-10'>
        <FormItem
          className='w-1/2'
          label={
            <LargeLabel
              label={intl.get(
                'INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.BUSINESS_UNIT_TITLE'
              )}
              subtitle={intl.get(
                'INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.BUSINESS_UNIT_SUBTITLE'
              )}
              subtitleProps={{ className: 'text-neutral-darker' }}
              labelProps={{ className: 'text-base mb-0.5' }}
            />
          }
          labelProps={{ className: 'mb-0' }}
        >
          <MultiSelectDropdown
            triggerProps={{
              className: 'py-0 items-center',
              'data-testid': 'business_units_dropdown_trigger',
            }}
            listProps={{
              'data-testid': 'business_units_dropdown_list',
            }}
            options={businessUnitsOptions}
            values={getInitialValueForDropDown(
              businessUnitsOptions,
              linkedBusinessUnits.map((businessUnitId) => businessUnitId)
            )}
            filterable
            placeholder={intl.get(
              'INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.BUSINESS_UNIT_PLACEHOLDER'
            )}
            onChange={(selectedBUs: Option[]) => {
              updateLinkedBusinessUnits(selectedBUs.map((bu) => bu.value));
            }}
            multiple
          />
        </FormItem>
        <FormItem
          className='w-1/2'
          label={
            <LargeLabel
              label={intl.get(
                'INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.COMPLETION_DATE_TITLE'
              )}
              subtitle={intl.get(
                'INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.COMPLETION_DATE_SUBTITLE'
              )}
              subtitleProps={{ className: 'text-neutral-darker' }}
              labelProps={{ className: 'text-base mb-0.5' }}
            />
          }
          labelProps={{ className: 'mb-0' }}
        >
          <Datepicker
            placeholder={intl.get(
              'INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.COMPLETION_DATE_PLACEHOLDER'
            )}
            inputProps={{
              'data-testid': 'completion-date_input',
            }}
            startDate={completionDate && new Date(completionDate)}
            className='w-full'
            onPickDate={(date: rangeDateType) =>
              updateInputField(date.startDate, 'completionDate')
            }
          />
        </FormItem>
      </div>
    </div>
  );
};

export default RequestsSectionAdditionalFields;
