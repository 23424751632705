import intl from 'react-intl-universal';
import { Button } from '@getsynapse/design-system';
import { PATHS, SETTINGS_TABS, SETTINGS_SECTIONS } from 'utils/constants';
import { useHistory } from 'react-router-dom';

const FooterButtons = ({
  disableSaveButton,
  onSave,
  onUpdatePage,
}: {
  disableSaveButton: boolean;
  onSave: () => void;
  onUpdatePage: boolean;
}) => {
  const history = useHistory();

  const onCancel = () => {
    const redirectPath = `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.FIELDS}`;
    history.push(redirectPath);
  };

  return (
    <div className='h-12 px-12 w-full flex-shrink-0 space-x-4 bg-neutral-white flex items-center shadow-lifted absolute bottom-0 z-10 justify-end'>
      <Button
        variant='tertiary'
        onClick={onCancel}
        data-cy='cancel-field-button'
      >
        {intl.get('CANCEL')}
      </Button>
      <Button
        onClick={onSave}
        disabled={disableSaveButton}
        data-cy='update-field-button'
      >
        {onUpdatePage ? intl.get('UPDATE') : intl.get('SAVE')}
      </Button>
    </div>
  );
};

export default FooterButtons;
