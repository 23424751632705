import intl from 'react-intl-universal';
import UserAvatar from 'Atoms/UserAvatar';
import UnlinkContent from 'assets/images/unlink-content.svg';
import OpenLink from 'assets/images/opne-link.svg';
import { formatRequestIdentifier } from 'Pages/helpers';
import { ProgramRequest } from 'utils/types/program';
import { IconButton, Table, Typography } from '@getsynapse/design-system';
import { PATHS } from 'utils/constants';
import { DropdownRequest } from 'utils/customTypes';
import classNames from 'classnames';

type LinkedRequestsTableProps = {
  requests: ProgramRequest[];
  updateLinkedRequests: (
    requests: DropdownRequest[],
    isAdding: boolean
  ) => void;
};

const LinkedRequestsTable = ({
  requests,
  updateLinkedRequests,
}: LinkedRequestsTableProps) => {
  type requesterInfo = {
    firstName: string;
    lastName: string;
    avatrURL?: string;
  };
  const getUserCell = (requester: requesterInfo) => {
    return (
      <div className='flex items-center'>
        <UserAvatar
          user={{
            avatar_url: requester.avatrURL,
            data: {
              firstName: requester.firstName,
              lastName: requester.lastName,
            },
          }}
          className='mr-2.5'
        />
        <span
          className='truncate'
          title={`${requester.firstName} ${requester.lastName}`}
        >{`${requester.firstName} ${requester.lastName}`}</span>
      </div>
    );
  };

  return (
    <div className='w-full rounded-t'>
      <Table
        className='w-full mb-5 rounded relative'
        data-testid='linked-requests-table'
        bodyProps={{
          className: classNames('max-h-64 overflow-y-auto', {
            block: requests?.length > 0,
          }),
        }}
        canSelectRows={false}
        data={{
          headData: {
            className: 'w-full block h-auto min-h-10',
            rowProps: {
              className: 'table w-full table-fixed min-h-10',
            },
            stickyHeader: true,
            headCells: [
              {
                content: intl.get(
                  'INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.TABLE.REQUEST_NUMBER'
                ),
                className: 'rounded-tl w-20',
              },
              {
                content: intl.get(
                  'INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.TABLE.REQUESTER_NAME'
                ),
                className: 'w-32 min-w-32',
              },
              {
                content: intl.get(
                  'INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.TABLE.REQUEST_TITLE'
                ),
                className: 'w-60 min-w-28',
              },
              {
                content: intl.get(
                  'INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.TABLE.BUSINESS_UNIT'
                ),
                className: 'w-32 min-w-32',
              },
              {
                content: '',
                className: 'w-20',
              },
            ],
          },
          rows: requests?.map((request) => ({
            className: 'table w-full table-fixed',
            'data-testid': `linked-request-${request.id}`,
            cells: [
              {
                content: formatRequestIdentifier(request.requestIdentifier),
                className: 'w-20',
              },
              {
                content: getUserCell(request.requester),
                className: 'w-32 min-w-32 truncate',
              },
              {
                content: request.title,
                className: 'truncate w-60 min-w-40',
              },
              {
                content: (
                  <span title={request.businessTeam?.title}>
                    {request.businessTeam?.title}
                  </span>
                ),
                className: 'w-32 truncate min-w-32',
              },
              {
                content: (
                  <div className='flex items-center justify-around px-2'>
                    <IconButton
                      src={OpenLink}
                      className='text-neutral-dark text-3xl'
                      description='open link'
                      onClick={() =>
                        window.open(
                          `${window.location.origin}${PATHS.REQUEST_PAGE}/${request.id}`,
                          '_blank'
                        )
                      }
                    />
                    <IconButton
                      src={UnlinkContent}
                      className='text-2xl text-neutral-dark'
                      description='unlink'
                      data-testid={`unlink-request-${request.id}`}
                      onClick={() => updateLinkedRequests([request], false)}
                    />
                  </div>
                ),
                className: 'w-20 p-0 px-2',
              },
            ],
          })),
        }}
        emptyComponent={
          <div className='flex justify-center items-center m-4'>
            <Typography variant='body2'>
              {intl.get(
                'INPUT_CATEGORY.BUSINESS_REQUEST_SECTION.TABLE.EMPTY_MESSAGE'
              )}
            </Typography>
          </div>
        }
      />
    </div>
  );
};

export default LinkedRequestsTable;
