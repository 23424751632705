import { ChangeEvent, useMemo } from 'react';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import { Toggle, tailwindOverride } from '@getsynapse/design-system';
import { Form, MoreActionsOption } from 'utils/customTypes';
import { FORM_OPTIONS, PATHS } from 'utils/constants';
import MoreActions from 'Organisms/MoreActions/MoreActions';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrganizationId } from 'state/User/userSlice';

type FormTopBarProps = {
  handlePublishUnpublish: (e: ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
  formObj: Form;
  disablePublishButton: boolean;
};
const FormTopBar = ({
  formObj,
  handlePublishUnpublish,
  onDelete,
  disablePublishButton,
}: FormTopBarProps) => {
  const dispatch = useDispatch();
  const isPublished = useMemo(
    () => get(formObj, 'data.published', false),
    [formObj]
  );

  const moreOptions = useMemo<MoreActionsOption[]>(() => {
    const options: MoreActionsOption[] = [];
    if (isPublished) {
      options.push({
        value: FORM_OPTIONS.LINK,
        label: intl.get('SETTINGS_PAGE.FORMS.COPY_LINK.COPY_FORM_LINK'),
        iconName: 'link',
        tooltip: {
          position: 'topLeft',
          text: intl.get('SETTINGS_PAGE.FORMS.COPY_LINK.TOOLTIP'),
        },
      });
    }
    options.push({
      value: FORM_OPTIONS.DELETE,
      label: intl.get('SETTINGS_PAGE.FORMS.DELETE.DELETE_FORM'),
      iconClassName: 'text-error-darker group-hover:text-error-dark',
      iconName: 'trash',
      dataCy: 'delete-option-button',
      disabled: isPublished,
      tooltip: isPublished && {
        position: 'bottomLeft',
        text: intl.get('SETTINGS_PAGE.FORMS.DELETE_TOOLTIP'),
      },
    });

    return options;
  }, [isPublished]);
  const organizationId = useSelector(selectOrganizationId);

  const handleActions = async (option: MoreActionsOption) => {
    switch (option.value) {
      case FORM_OPTIONS.LINK:
        await navigator.clipboard.writeText(
          `${window.location.origin}${PATHS.REQUEST_PAGE}/${formObj.id}?accountId=${organizationId}`
        );
        dispatch(
          showNotificationBanner({
            notificationVariant: 'success',
            notificationText: intl.get(
              'SETTINGS_PAGE.FORMS.COPY_LINK.SUCCESS_MESSAGE'
            ),
          })
        );
        break;
      case FORM_OPTIONS.DELETE:
        onDelete();
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={tailwindOverride(
        'relative',
        'z-5',
        'flex items-center justify-end'
      )}
    >
      <Toggle
        label={intl.get('SETTINGS_PAGE.FORMS.PUBLISH')}
        labelProps={{ className: 'mb-0 mr-2 font-normal' }}
        inputProps={{ 'data-cy': 'form_publish-unpublish' }}
        className='flex items-center mr-4'
        isSmall
        checked={isPublished}
        onChange={handlePublishUnpublish}
        disabled={disablePublishButton}
      />

      <MoreActions options={moreOptions} onSelectOption={handleActions} />
    </div>
  );
};

export default FormTopBar;
