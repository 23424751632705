import intl from 'react-intl-universal';
import {
  Button,
  tailwindOverride,
  Icon,
  Typography,
} from '@getsynapse/design-system';
import {
  FlatfileButton,
  FlatfileSettings,
  FlatfileResults,
  FieldHookCallback,
} from '@flatfile/react';
import { IDictionary } from '@flatfile/adapter';
import importIcon from 'assets/icons/import.svg';
import { CurrentUser } from 'utils/customTypes';

export type ImportButtonProps = {
  currentUser: CurrentUser;
  settings: FlatfileSettings;
  onData?: (results: FlatfileResults) => Promise<string | void | null>;
  fieldHooks?: IDictionary<FieldHookCallback>;
  disabled?: boolean;
  isListItemButton?: boolean;
  onLaunchCallback?: () => void;
  buttonText?: string;
};

const ListItem: React.FC<{
  disabled?: boolean;
  onLauch: () => void;
  onLaunchCallback?: () => void;
  buttonText?: string;
}> = ({ disabled = false, onLauch, onLaunchCallback, buttonText }) => {
  return (
    <li
      onClick={() => {
        if (disabled !== true) {
          onLauch();
          if (onLaunchCallback) {
            onLaunchCallback();
          }
        }
      }}
      className={tailwindOverride(
        'font-body text-base truncate cursor-pointer group px-4 py-1 text-neutral-black',
        'focus-visible:border-0 focus-visible:ring-0 focus-visible:outline-none',
        {
          'hover:text-neutral-dark hover:shadow-list-item-hover hover:bg-neutral-lighter-two':
            !disabled,
          'focus-visible:bg-neutral-lighter-two focus-visible:text-neutral-dark focus-visible:shadow-list-item-hover':
            !disabled,
          'cursor-not-allowed': disabled,
        }
      )}
    >
      <div className='py-1 px-2 flex items-center'>
        <Icon
          className={tailwindOverride(
            'fill-current text-neutral-black mr-2 text-xl',
            {
              'group-hover:fill-current group-hover:text-neutral-dark':
                !disabled,
            },
            {
              'fill-current text-neutral-light cursor-not-allowed': disabled,
            }
          )}
          src={importIcon}
        />
        <Typography
          className={tailwindOverride(
            { 'text-neutral-light cursor-not-allowed': disabled },
            {
              'text-primary group-hover:text-secondary-darker cursor-pointer':
                !disabled,
            }
          )}
          variant='label'
        >
          {buttonText || intl.get('REQUESTS_LIST_PAGE.TABLE.IMPORT.IMPORT')}
        </Typography>
      </div>
    </li>
  );
};

const ImportButton = ({
  currentUser,
  settings,
  onData,
  fieldHooks,
  disabled = false,
  isListItemButton = false,
  onLaunchCallback,
  buttonText,
}: ImportButtonProps) => {
  const colorPrimary = getComputedStyle(document.body).getPropertyValue(
    '--color-primary-default'
  );
  const colorPrimaryHover = getComputedStyle(document.body).getPropertyValue(
    '--color-primary-darker'
  );
  const buttonStyles = {
    backgroundColor: `rgb(${colorPrimary})`,
    border: `2px solid rgb(${colorPrimary})`,
    ':hover': {
      backgroundColor: `rgb(${colorPrimaryHover})`,
      border: `2px solid rgb(${colorPrimaryHover})`,
    },
  };
  return (
    <FlatfileButton
      licenseKey={process.env.REACT_APP_FLATFILE_API_KEY || ''}
      customer={{
        companyId: currentUser.organization_id,
        companyName: currentUser.organization_id,
        email: currentUser.email,
        name: currentUser.full_name,
        userId: currentUser.id || '',
      }}
      render={(_, launch) => {
        return isListItemButton ? (
          <ListItem
            onLauch={launch}
            onLaunchCallback={onLaunchCallback}
            disabled={disabled}
            buttonText={buttonText}
          />
        ) : (
          <Button
            color='default'
            iconPosition='left'
            size='small'
            variant='primary'
            iconSrc={importIcon}
            onClick={launch}
            disabled={disabled}
            className='mr-2'
          >
            {intl.get('IMPORT')}
          </Button>
        );
      }}
      settings={{
        ...settings,
        disableManualInput: true,
        devMode: process.env.REACT_APP_APP_ENV !== 'prod',
        managed: true,
        theme: {
          buttons: {
            primary: buttonStyles,
            secondary: buttonStyles,
            headerMatchYes: buttonStyles,
            headerMatchNo: {
              border: `2px solid rgb(${colorPrimary})`,
              color: `rgb(${colorPrimary})`,
              ':hover': {
                border: `2px solid rgb(${colorPrimaryHover})`,
                color: `rgb(${colorPrimaryHover})`,
              },
            },
          },
          headerMatch: {
            content: {
              border: `2px solid rgb(${colorPrimary})`,
            },
          },
        },
      }}
      onData={onData}
      fieldHooks={fieldHooks}
    />
  );
};

export default ImportButton;
