import React from 'react';
import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import debounce from 'lodash/debounce';
import { tailwindOverride } from '@getsynapse/design-system';
import { RootState } from 'state/store';
import { selectTeamMemberIsExpanded } from 'state/Capacities/TeamMemberProjectsList/teamMemberProjectsListSlice';
import { selectCurrentViewSettingValue } from 'state/ViewSettings/viewSettingsSlice';
import {
  VIEW_SETTING,
  VIEW_SETTING_VALUES,
} from 'utils/constants/capacityView';
import useScrollHandlerContext from 'Pages/TeamsPage/views/TeamsCapacity/components/CapacityTable/components/ScrollHandler/useScrollHandlerContext';
import MemberData from '../MemberData/MemberData';
import DailyMemberCapacities from 'Pages/TeamsPage/views/TeamsCapacity/components/DailyCapacity/components/TeamMembers/DailyMemberCapacities/DailyMemberCapacities';
import TeamMembersWeeklyCapacities from 'Pages/TeamsPage/views/TeamsCapacity/components/BetaWeeklyCapacity/components/TeamMembers/TeamMembersWeeklyCapacities/TeamMembersWeeklyCapacities';

const MemberRow: React.FC<{
  teamId: string;
  memberId: string;
  bgColor?: string;
  updateMemberIsInView: (memberId: string, inView: boolean) => void;
}> = ({
  teamId,
  memberId,
  updateMemberIsInView,
  bgColor = 'bg-neutral-white',
}) => {
  const isTeamMemberExpanded = useSelector((state: RootState) =>
    selectTeamMemberIsExpanded(state, teamId, memberId)
  );
  const savedTeamsCapacityView = useSelector(
    selectCurrentViewSettingValue(VIEW_SETTING)
  );
  const { rootId } = useScrollHandlerContext();
  const debouncedOnChangeHandler = debounce(
    (inView: boolean) => updateMemberIsInView(memberId, inView),
    500
  );
  const { ref: elementRef } = useInView({
    root: rootId,
    threshold: 0.5,
    rootMargin: '-80px 0px 0px',
    onChange: debouncedOnChangeHandler,
  });
  return (
    <div
      ref={elementRef}
      className={tailwindOverride('w-full h-26 flex', bgColor, 'member-row')}
      data-member-id={memberId}
      id={`team-${teamId}__member-${memberId}__row`}
      aria-expanded={isTeamMemberExpanded}
      aria-controls={`team-${teamId}__member-${memberId}__projects-list`}
    >
      <MemberData
        teamId={teamId}
        memberId={memberId}
        isExpanded={isTeamMemberExpanded}
      />
      {savedTeamsCapacityView.value === VIEW_SETTING_VALUES.DAILY && (
        <DailyMemberCapacities memberId={memberId} />
      )}
      {(savedTeamsCapacityView.value === VIEW_SETTING_VALUES.BETA_WEEKLY ||
        savedTeamsCapacityView.value === VIEW_SETTING_VALUES.WEEKLY) && (
        <TeamMembersWeeklyCapacities teamId={teamId} memberId={memberId} />
      )}
    </div>
  );
};

export default MemberRow;
