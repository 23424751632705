import { Tooltip, Dropdown, tailwindOverride } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { useMemo } from 'react';
import { PROGRAMS_STATUS } from 'utils/constants';
import { Program, Option } from 'utils/customTypes';

const ProgramStatusPicker = ({
  program,
  handleFieldChange,
  getSelectedValue,
}: {
  program: Program;
  handleFieldChange: (path: string, value: any) => void;
  getSelectedValue: (options: Option[], value: string | null) => void;
}) => {
  const statusOptions = useMemo(() => {
    return Object.values(PROGRAMS_STATUS).map((status) => ({
      label: intl.get(`PROGRAMS_LIST_PAGE.STATUS.${status.toUpperCase()}`),
      value: status,
    }));
  }, []);

  const cannotCloseProgram = useMemo(
    () =>
      program.updatedProjects?.some(
        (project) =>
          project.status !== 'completed' &&
          project.status !== 'canceled' &&
          project.status !== 'closed'
      ),
    [program.updatedProjects]
  );

  return (
    <Dropdown
      options={statusOptions}
      triggerProps={{ 'data-cy': 'program-status-field' }}
      onChange={(option: Option) => {
        handleFieldChange('status', option.value);
      }}
      values={[getSelectedValue(statusOptions, program.status)]}
      listProps={{
        'data-cy': 'status_list',
      }}
      renderOption={(
        option: Option,
        selected: boolean,
        selectOption,
        { className, ...otherProps }
      ) => {
        const isClosedOption = option.value === PROGRAMS_STATUS.CLOSED;
        const disabledClass =
          cannotCloseProgram && isClosedOption
            ? 'text-neutral-lighter cursor-not-allowed'
            : '';
        const listItem = (
          <li
            {...otherProps}
            onClick={() => {
              if (!disabledClass) {
                selectOption();
              }
            }}
            tabIndex={selected ? 0 : -1}
            className={tailwindOverride(
              'font-body text-base truncate group px-4 py-2 focus-visible:border-0 focus-visible:ring-0 focus-visible:outline-none',
              {
                'cursor-pointer text-neutral-black hover:text-neutral-dark hover:shadow-list-item-hover hover:bg-neutral-lighter-two focus-visible:bg-neutral-lighter-two focus-visible:text-neutral-dark focus-visible:shadow-list-item-hover':
                  !disabledClass,
                'text-neutral-white bg-primary hover:text-neutral-white hover:bg-primary focus-visible:bg-primary focus-visible:text-neutral-white':
                  selected && !disabledClass,
              },
              disabledClass
            )}
          >
            {option.label}
          </li>
        );

        return disabledClass ? (
          <Tooltip
            className='w-full'
            showPopper
            usePortal
            openMode='hover1'
            timeout={0}
            trigger={listItem}
            position='bottomRight'
            contentProps={{ className: 'z-200000' }}
          >
            {intl.get('PROGRAM_PAGE.CLOSED_TOOLTIP')}
          </Tooltip>
        ) : (
          listItem
        );
      }}
    />
  );
};
export default ProgramStatusPicker;
