import { ReactNode } from 'react';
import classnames from 'classnames';
import { tailwindOverride } from '@getsynapse/design-system';
import emptyTimeOffFilterResult from 'assets/icons/no-time-off-filter-results.svg';

const TimeOffFilterNoResultIndicator = ({
  imageSrc,
  caption,
  children,
  dataCy,
  className,
  imageClassName,
  labelClassName,
}: {
  imageSrc?: any;
  caption?: string;
  children?: ReactNode;
  dataCy?: string;
  className?: string;
  imageClassName?: string;
  labelClassName?: string;
}) => {
  let defaultIsmageToShow = emptyTimeOffFilterResult;
  let captionToUse: string | undefined = caption;

  return (
    <div
      data-cy={dataCy}
      className={classnames(
        'bg-neutral-white flex justify-center items-center border-t-0',
        { 'h-99': !className },
        className
      )}
    >
      <div className='flex flex-col'>
        <img
          className={tailwindOverride('m-auto self-center', imageClassName)}
          src={imageSrc || defaultIsmageToShow}
          alt='React Logo'
        />

        <span
          className={tailwindOverride(
            'm-auto mt-6 self-center text-base text-neutral-black',
            labelClassName
          )}
        >
          {captionToUse}
        </span>

        {children && children}
      </div>
    </div>
  );
};

export default TimeOffFilterNoResultIndicator;
