import React, { memo } from 'react';
import { FixedSizeList as List, areEqual } from 'react-window';
import { tailwindOverride } from '@getsynapse/design-system';
import { ProjectCapacity } from 'utils/types/teamsCapacity';
import ProjectRow from './components/ProjectRow';

const ProjectsList: React.FC<{
  teamId: string;
  memberId: string;
  projects: Record<string, ProjectCapacity>;
  className?: string;
}> = ({ teamId, memberId, projects, className, ...props }) => {
  const itemCount = Object.keys(projects).length;
  const maxItems = 100;
  const itemSize = 104;
  const projectsList = Object.values(projects);
  const listHeight =
    itemCount > maxItems ? itemSize * maxItems : itemSize * itemCount;

  const Row: React.FC<{ index: number; style: React.CSSProperties }> = memo(
    ({ index, style }) => {
      const projectCapacity = projectsList[index];
      return (
        <div
          style={style}
          aria-label={`team-${teamId}__member-${memberId}__project-${projectCapacity.data.id}`}
          data-cy={`team-${teamId}__member-${memberId}__project-${projectCapacity.data.id}`}
        >
          <ProjectRow
            projectCapacity={projectCapacity}
            teamId={teamId}
            memberId={memberId}
            className={className}
          />
        </div>
      );
    },
    areEqual
  );

  return (
    <List
      itemCount={itemCount}
      itemSize={itemSize}
      itemKey={(index) =>
        `team-${teamId}__member-${memberId}__project-${projectsList[index].data.id}`
      }
      height={listHeight}
      width='100%'
      className={tailwindOverride(
        'layout-cell layout-scrollbar',
        `team-${teamId}__member-${memberId}__projects-list`
      )}
      {...props}
    >
      {Row}
    </List>
  );
};

export default ProjectsList;
