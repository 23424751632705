import {
  createAsyncThunk,
  createSlice,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import orderBy from 'lodash/orderBy';
import { selectOrganizationId, selectUserId } from 'state/User/userSlice';
import { fetchProject } from 'state/Project/projectSlice';
import { Task, NewTask, ProjectTasksTableTab, Status } from 'utils/customTypes';
import { TaskFilters } from 'utils/types/filters';
import { RootState } from 'state/store';
import {
  filterTasks,
  getTasksAssigneesList,
  getTasksCompletionProgress,
  getTasksEstimatedAndActualHours,
  sortTasks,
} from './helpers';
import ProjectTaskAPI from './projectTaskAPI';
import { TASKS_TABLE_TABS, TASK_STATUS, SLICE_STATUS } from 'utils/constants';
import exportAPI from 'Services/exportAPI';
interface ProjectTasksState {
  projectTasks: Task[];
  userProjectTasks: Task[];
  teamTasksTable: {
    searchParam: string;
    filters: TaskFilters;
    showReorder: boolean;
    pagination: {
      limit: number;
      offset: number;
    };
  };
  myTasksTable: {
    searchParam: string;
    filters: TaskFilters;
    pagination: {
      limit: number;
      offset: number;
    };
  };
  exportStatus: Status;
}

/* ============================= INITIAL STATE ============================== */
const initialState: ProjectTasksState = {
  projectTasks: [],
  userProjectTasks: [],
  teamTasksTable: {
    searchParam: '',
    filters: {},
    showReorder: false,
    pagination: {
      limit: 15,
      offset: 0,
    },
  },
  myTasksTable: {
    searchParam: '',
    filters: {},
    pagination: {
      limit: 15,
      offset: 0,
    },
  },
  exportStatus: SLICE_STATUS.IDLE,
};

const projectTasksAPI = ProjectTaskAPI;

/* ============================== REDUX THUNK =============================== */
export const fetchTeamTasks = createAsyncThunk(
  'projectTasks/FETCH_TEAMS_TASKS',
  async (projectId: string) => {
    const response = await projectTasksAPI.fetchTeamsTasks(projectId, {
      includeDisabled: true,
    });
    return response.data.orderedTasks;
  }
);

export const fetchUserTasks = createAsyncThunk(
  'projectTasks/FETCH_MY_TASKS',
  async (projectId: string) => {
    const response = await projectTasksAPI.fetchUserTasks({
      projectId,
      includeDisabled: true,
    });
    return response.data.myTasks;
  }
);

export const createNewTask = createAsyncThunk(
  'projectTasks/CREATE_TASK',
  async (newTaskData: NewTask, { getState, dispatch }) => {
    const state = getState() as RootState;
    const organizationId = selectOrganizationId(state);
    const currentUserId = selectUserId(state);
    const response = await projectTasksAPI.createTask({
      ...newTaskData,
      organization_id: organizationId,
    });
    dispatch(fetchProject(response.data.task.project_id));
    if (newTaskData.assignedUserIds.includes(currentUserId)) {
      dispatch(addUserTask(response.data.task));
    }
    return response.data.task;
  }
);

export const deleteTask = createAsyncThunk(
  'projectTasks/DELETE_TASK',
  async (taskId: string) => {
    await projectTasksAPI.deleteTask(taskId);
    return { taskId };
  }
);

export const duplicateTask = createAsyncThunk(
  'projectTasks/DUPLICATE_TASK',
  async (
    params: { taskId: string; newTaskData: NewTask },
    { getState, dispatch }
  ) => {
    const state = getState() as RootState;
    const currentUserId = selectUserId(state);
    const response = await projectTasksAPI.duplicateTask(
      params.taskId,
      params.newTaskData
    );
    const newTask = response.data.result;
    dispatch(fetchProject(newTask.project_id));
    if (params.newTaskData.assignedUserIds.includes(currentUserId)) {
      dispatch(addUserTask(newTask));
    }
    return newTask;
  }
);

export const importTasks = createAsyncThunk(
  'projectTasks/IMPORT_TASKS',
  async (params: { newTasks: NewTask[] }) => {
    const response = await projectTasksAPI.bulkCreateTasks(params.newTasks);
    return response.data;
  }
);

export const exportTasks = createAsyncThunk(
  'projectTasks/EXPORT_TASKS',
  async (taskIds: string[], { rejectWithValue }) => {
    try {
      const response = await exportAPI.exportTasks(taskIds);
      return response.data.fileUrl;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* ================================ ACTIONS ================================= */
const addUserTask = createAction<Task>('projectTasks/ADD_USER_TASK');

export const resetTasksTable = createAction('projectTasks/RESET_TASKS_TABLE');

export const setFilters = createAction(
  'projectTasks/SET_FILTERS',
  (filters: TaskFilters, table: ProjectTasksTableTab) => {
    return { payload: { filters, table } };
  }
);

export const updatePagination = createAction(
  'projectTasks/UPDATE_PAGINATION',
  (pagination, table: ProjectTasksTableTab) => {
    return { payload: { pagination, table } };
  }
);

export const setSearchParam = createAction(
  'projectTasks/SET_SEARCH',
  (searchParam: string, table: ProjectTasksTableTab) => {
    return { payload: { searchParam, table } };
  }
);

export const setShowReorder = createAction(
  'projectTasks/SET_SHOW_REORDER',
  (showReorder: boolean, table: ProjectTasksTableTab) => {
    return { payload: { showReorder, table } };
  }
);

export const reorderRow = createAction(
  'projectTasks/REORDER_ROW',
  (newTasksOrderingList: string[]) => {
    return { payload: { newTasksOrderingList } };
  }
);

/* ================================= REDUCER ================================ */
const tasksSlice = createSlice({
  name: 'projectTasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createNewTask.fulfilled, (state, action) => {
        state.projectTasks = [...state.projectTasks, action.payload];
      })
      .addCase(fetchTeamTasks.fulfilled, (state, action) => {
        state.projectTasks = [...action.payload];
      })
      .addCase(fetchUserTasks.fulfilled, (state, action) => {
        state.userProjectTasks = [...action.payload];
      })
      .addCase(addUserTask, (state, action) => {
        state.userProjectTasks = [...state.userProjectTasks, action.payload];
      })
      .addCase(setFilters, (state, action) => {
        state[action.payload.table] = {
          ...state[action.payload.table],
          filters: action.payload.filters,
          showReorder: false,
        };
      })
      .addCase(updatePagination, (state, action) => {
        state[action.payload.table] = {
          ...state[action.payload.table],
          showReorder: state.teamTasksTable.showReorder,
          pagination: action.payload.pagination,
        };
      })
      .addCase(setSearchParam, (state, action) => {
        if (action.payload.table === TASKS_TABLE_TABS.MY_TASKS) {
          state.myTasksTable.searchParam = action.payload.searchParam;
        } else {
          state.teamTasksTable.searchParam = action.payload.searchParam;
        }
      })
      .addCase(deleteTask.fulfilled, (state, action) => {
        state.projectTasks = state.projectTasks.filter(
          (task) => task.id !== action.payload.taskId
        );
      })
      .addCase(setShowReorder, (state, action) => {
        state.teamTasksTable.showReorder = action.payload.showReorder;
      })
      .addCase(reorderRow, (state, action) => {
        const updatedTasksOrdering = action.payload.newTasksOrderingList;
        const newTeamTasks = state.projectTasks;
        state.projectTasks = sortTasks(newTeamTasks, updatedTasksOrdering);
      })
      .addCase(duplicateTask.fulfilled, (state, action) => {
        state.projectTasks = [...state.projectTasks, action.payload];
      })
      .addCase(resetTasksTable, (state) => {
        state.projectTasks = [];
        state.userProjectTasks = [];
      })
      .addCase(importTasks.fulfilled, (state, action) => {
        state.projectTasks = [...state.projectTasks, ...action.payload];
      })
      .addCase(exportTasks.pending, (state) => {
        state.exportStatus = SLICE_STATUS.LOADING;
      })
      .addCase(exportTasks.fulfilled, (state, action) => {
        state.exportStatus = SLICE_STATUS.IDLE;
        window.location.href = action.payload;
      })
      .addCase(exportTasks.rejected, (state) => {
        state.exportStatus = SLICE_STATUS.FAILED;
      });
  },
});

/* =============================== SELECTORS ================================ */
export const selectAllTasks = (state: RootState) =>
  state.projectTasks.projectTasks;
export const selectUserTasks = (state: RootState) =>
  state.projectTasks.userProjectTasks;

export const teamSearchParam = (state: RootState) =>
  state.projectTasks.teamTasksTable.searchParam;
export const mySearchParam = (state: RootState) =>
  state.projectTasks.myTasksTable.searchParam;

export const selectTableSearchParam =
  (table: ProjectTasksTableTab) => (state: RootState) =>
    state.projectTasks[table].searchParam;

export const teamTasksTableFilters = (state: RootState) =>
  state.projectTasks.teamTasksTable.filters;

export const myTasksTableFilters = (state: RootState) =>
  state.projectTasks.myTasksTable.filters;

export const teamTasksTableReorder = (state: RootState) =>
  state.projectTasks.teamTasksTable.showReorder;
export const exportTasksStatus = (state: RootState) =>
  state.projectTasks.exportStatus;

export const selectAreAllTasksClosed = createSelector(
  [selectAllTasks],
  (tasks: Task[]) =>
    !tasks.some(
      (task: Task) => task.status !== TASK_STATUS.COMPLETED && !task.disabled
    )
);

export const selectMyTasks = createSelector(
  [
    selectUserTasks,
    mySearchParam,
    myTasksTableFilters,
    (state: RootState) => state.projectTasks.myTasksTable.pagination,
  ],
  (tasks: Task[], searchParam, filters: TaskFilters, pagination) => {
    const filteredTasksList = filterTasks(tasks, filters, searchParam);
    return {
      data: orderBy(
        filteredTasksList.slice(pagination.offset, pagination.limit)
      ),
      total: filteredTasksList.length,
      all: filteredTasksList,
    };
  }
);

export const selectTeamTasks = createSelector(
  [
    selectAllTasks,
    teamSearchParam,
    teamTasksTableFilters,
    (state: RootState) => state.projectTasks.teamTasksTable.pagination,
  ],
  (tasks: Task[], searchParam, filters: TaskFilters, pagination) => {
    const filteredTasksList = filterTasks(tasks, filters, searchParam);
    return {
      data: orderBy(
        filteredTasksList.slice(pagination.offset, pagination.limit)
      ),
      total: filteredTasksList.length,
      all: filteredTasksList,
    };
  }
);

export const getTeamTasksAssigneesList = createSelector(
  [selectAllTasks],
  (tasks: Task[]) => {
    const assignees = getTasksAssigneesList(tasks);
    return assignees;
  }
);

export const getUserTasksAssigneesList = createSelector(
  [selectUserTasks],
  (tasks: Task[]) => {
    const assignees = getTasksAssigneesList(tasks);
    return assignees;
  }
);

export const getTeamTasksCompletionProgress = createSelector(
  [selectAllTasks],
  (tasks: Task[]) => getTasksCompletionProgress(tasks)
);

export const getUserTasksCompletionProgress = createSelector(
  [selectUserTasks],
  (tasks: Task[]) => getTasksCompletionProgress(tasks)
);

export const getTeamTasksEstimatedAndActualHours = createSelector(
  [selectAllTasks],
  (tasks: Task[]) => getTasksEstimatedAndActualHours(tasks)
);

export const getUserTasksEstimatedAndActualHours = createSelector(
  [selectUserTasks],
  (tasks: Task[]) => getTasksEstimatedAndActualHours(tasks)
);

export default tasksSlice.reducer;
