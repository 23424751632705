import React from 'react';
import intl from 'react-intl-universal';
import { tailwindOverride, Icon, Typography } from '@getsynapse/design-system';
import { numberFormatter } from '../../../../../DailyCapacity/helpers';
import dayOffIcon from 'assets/icons/day-off.svg';

const DailyMemberTimeOff: React.FC<{
  timeOff: number;
}> = ({ timeOff }) => {
  return (
    <div
      className={tailwindOverride(
        'w-full h-6 rounded-sm',
        'flex items-center justify-center gap-x-1',
        'bg-neutral-lighter-two',
        'border border-neutral-lighter'
      )}
      style={{
        boxShadow: `0px 2px 2px 0px #12151B0D, 2px 0px 0px 0px rgb(var(--color-neutral-dark)) inset`,
      }}
    >
      <Icon
        src={dayOffIcon}
        className='text-neutral-darker text-lg'
        aria-label='time-off-icon'
      />
      <Typography
        variant='label'
        weight='strong'
        className='text-neutral-darker'
      >
        {intl.get('TEAMS_CAPACITY.ALLOCATION_SUMMARY_HOURS', {
          hours: numberFormatter.format(timeOff),
        })}
      </Typography>
    </div>
  );
};

export default DailyMemberTimeOff;
