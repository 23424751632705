import intl from 'react-intl-universal';
import classnames from 'classnames';
import { Icon, useElevation } from '@getsynapse/design-system';
import { TaskBundleOption } from './types';
import { useMemo } from 'react';

interface TaskBundlePickerTriggerProps {
  onToggleDropdown: () => void;
  selectedOption?: TaskBundleOption;
  isDropdownExpanded: boolean;
}

const TaskBundlePickerTrigger: React.FC<TaskBundlePickerTriggerProps> = ({
  onToggleDropdown,
  selectedOption,
  isDropdownExpanded,
}) => {
  const hoverElevation = useElevation(1);
  const hasEsimatedHours = useMemo<boolean>(
    () => !!selectedOption && !isNaN(selectedOption.totalEstimatedHours),
    [selectedOption]
  );
  return (
    <div
      onClick={onToggleDropdown}
      className={classnames(
        'h-auto rounded border flex items-center relative',
        'py-2 px-4 min-h-10 text-base',
        `hover:${hoverElevation} focus:border-primary`,
        'focus-visible:outline-none focus:ring-primary',
        'focus-visible:ring-primary bg-neutral-white',
        'cursor-default max-w-full space-x-4',
        {
          'ring-primary border-primary': isDropdownExpanded,
          'border-primary-lighter hover:border-neutral-lighter focus:border-primary active:primary-lighter text-neutral-black':
            !isDropdownExpanded,
        }
      )}
      data-cy='project-task-bundle-input'
    >
      <div
        className={classnames('flex-1 items-center truncate', {
          'text-neutral': !selectedOption,
        })}
      >
        {selectedOption
          ? selectedOption.label
          : intl.get('NEW_PROJECT_PAGE.TASK_BUNDLE.PLACEHOLDER')}
      </div>

      {selectedOption && (
        <div
          className={classnames('whitespace-nowrap', {
            'text-neutral text-3.5': !hasEsimatedHours,
          })}
        >
          {!hasEsimatedHours
            ? intl.get(
                'NEW_PROJECT_PAGE.TASK_BUNDLE.ESTIMATED_HOURS_PLACEHOLDER'
              )
            : intl.get('HOURS_LABEL', {
                hours: selectedOption.totalEstimatedHours,
              })}
        </div>
      )}

      <div className='flex w-4'>
        <Icon
          name={isDropdownExpanded ? 'caret-up-outline' : 'caret-down-outline'}
          className='text-neutral-dark'
        />
      </div>
    </div>
  );
};

export default TaskBundlePickerTrigger;
