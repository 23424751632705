import { TeamMemberMap } from 'utils/types/dailyTeamsCapacity';

export const addIdsIfNotExistInArray: (
  idsToAdd: string[],
  idsArray: string[]
) => string[] = (idsToAdd, idsArray) => {
  if (idsArray.length === 0) {
    return idsToAdd;
  }
  const idsSet = new Set(idsArray);
  idsToAdd.forEach((id) => idsSet.add(id));
  return Array.from(idsSet);
};

export const removeTeamFromMembersVisibleTeamsIds: (
  teamId: string,
  membersIds: string[],
  membersById: TeamMemberMap
) => TeamMemberMap = (teamId, membersIds, membersById) => {
  const updateStateById = { ...membersById };
  for (const memberId of membersIds) {
    const visibleTeamsIds = [];
    if (memberId in membersById) {
      visibleTeamsIds.push(...(membersById[memberId]?.visibleTeamsIds || []));
    }
    updateStateById[memberId] = {
      ...updateStateById[memberId],
      visibleTeamsIds: visibleTeamsIds.filter((id) => id !== teamId),
    };
    if (updateStateById[memberId].visibleTeamsIds.length === 0) {
      delete updateStateById[memberId];
    }
  }
  return updateStateById;
};
