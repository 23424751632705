import { combineReducers } from '@reduxjs/toolkit';

import teamsCapacity from './TeamsCapacity/teamsCapacitySlice';
import teamMembersCapacity from './TeamMembersCapacity/teamMembersCapacitySlice';
import teamMemberProjectsCapacity from './TeamMembersProjectCapacity/teamMemberProjectsCapacitySlice';
import weeks from './Weeks/weeksSlice';

export default combineReducers({
  teamsCapacity,
  teamMembersCapacity,
  teamMemberProjectsCapacity,
  weeks,
});
