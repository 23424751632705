import { FC, useRef, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import debounce from 'lodash/debounce';
import { DebouncedFunc } from 'lodash';
import { NumericInput } from '@getsynapse/design-system';

interface HoursRangeFilterFieldProps {
  fromHoursValue: string;
  toHoursValue: string;
  onFromHoursChange: (hours: string) => void;
  onToHoursChange: (hours: string) => void;
  label: string;
}

const HoursRangeFilterField: FC<HoursRangeFilterFieldProps> = ({
  fromHoursValue,
  onFromHoursChange,
  toHoursValue,
  onToHoursChange,
  label,
}) => {
  const [fromValue, setFromValue] = useState<String>(fromHoursValue || '');
  const [toValue, setToValue] = useState<String>(toHoursValue || '');
  const debouncedFromHoursChange = useRef<DebouncedFunc<
    (hours: string) => void
  > | null>(null);
  const debouncedToHoursChange = useRef<DebouncedFunc<
    (hours: string) => void
  > | null>(null);

  useEffect(() => {
    debouncedFromHoursChange.current = debounce(
      (hours: string) => onFromHoursChange(hours),
      500
    );

    debouncedToHoursChange.current = debounce(
      (hours: string) => onToHoursChange(hours),
      500
    );
  }, [onFromHoursChange, onToHoursChange]);

  const hanldedFromHoursChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFromValue(event.target.value);
    if (debouncedFromHoursChange.current) {
      debouncedFromHoursChange.current(event.target.value);
    }
  };

  const handleToHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToValue(event.target.value);
    if (debouncedToHoursChange.current) {
      debouncedToHoursChange.current(event.target.value);
    }
  };

  useEffect(() => {
    setFromValue(fromHoursValue);
    setToValue(toHoursValue);
  }, [fromHoursValue, toHoursValue]);

  return (
    <div className='w-full flex space-x-4'>
      <NumericInput
        placeholder={intl.get('FILTER_GENERAL.FROM')}
        containerClassName='w-full'
        value={fromValue}
        onChange={hanldedFromHoursChange}
        aria-label={`${label}__from-range`}
        allowFloatValue
        step='any'
        maxAllowedDecimalPlaces={2}
      />
      <NumericInput
        placeholder={intl.get('FILTER_GENERAL.TO')}
        containerClassName='w-full'
        value={toValue || ''}
        onChange={handleToHoursChange}
        aria-label={`${label}__to-range`}
        allowFloatValue
        step='any'
        maxAllowedDecimalPlaces={2}
      />
    </div>
  );
};

export default HoursRangeFilterField;
