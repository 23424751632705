import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { InReviewRequestBarChartData } from 'utils/customTypes';
import { INSIGHTS_PAGE_COLORS } from 'utils/constants';
import intl from 'react-intl-universal';
import CustomTooltip from '../../Components/CustomToolTip';

const BarChartForInReviewRequests = ({
  data,
}: {
  data: InReviewRequestBarChartData[];
}) => {
  return (
    <ResponsiveContainer width='85%' height='80%'>
      <BarChart
        data={data}
        layout='vertical'
        margin={{
          left: 5,
        }}
      >
        <XAxis
          tickLine={false}
          type='number'
          style={INSIGHTS_PAGE_COLORS.IN_REVIEW_REQUESTS.BAR_CHART_AXIES_STYLE}
        />
        <YAxis
          tickLine={false}
          style={INSIGHTS_PAGE_COLORS.IN_REVIEW_REQUESTS.BAR_CHART_AXIES_STYLE}
          dataKey='name'
          type='category'
        />
        <Tooltip content={<CustomTooltip chartType='Bar' />} />
        <Bar
          dataKey='count'
          name={intl.get('INSIGHTS_PAGE_TABS.NUMBER_OF_REQUESTS')}
          maxBarSize={40}
          fill={INSIGHTS_PAGE_COLORS.IN_REVIEW_REQUESTS.BAR_CHART_MAIN_COLOR}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartForInReviewRequests;
