import { FC } from 'react';
import intl from 'react-intl-universal';
import { TableHead, tailwindOverride } from '@getsynapse/design-system';

interface TableHeaderProps {
  isListEmpty?: boolean;
  showDisabledColumn?: boolean;
  showReorderColumn?: boolean;
}

const TableHeader: FC<TableHeaderProps> = ({
  isListEmpty = false,
  showDisabledColumn = false,
  showReorderColumn = false,
}) => {
  return (
    <TableHead
      canSelectRows={!isListEmpty}
      stickyHeader={!isListEmpty}
      isSelectRowCellSticky={true}
      headCells={[
        {
          content: '',
          'aria-label': 'reorder-tasks',
          className: tailwindOverride('h-full', {
            'w-14 left-14 z-2': !isListEmpty,
            hidden: !showReorderColumn,
          }),
        },
        {
          content: '',
          'aria-label': 'disabled-completed-tasks',
          className: tailwindOverride('h-full', {
            'w-12 z-2': !isListEmpty,
            hidden: !showDisabledColumn,
            'left-36': showReorderColumn,
            'left-14': !showReorderColumn,
          }),
        },
        {
          content: intl.get('TASKS_LIST_PAGE.TABLE.HEAD.ID'),
          className: tailwindOverride('w-32 h-full', {
            'z-2': !isListEmpty,
            'left-26': !showReorderColumn && showDisabledColumn,
            'left-48': showReorderColumn && showDisabledColumn,
            'left-14': !showReorderColumn && !showDisabledColumn,
            'left-36': showReorderColumn && !showDisabledColumn,
            'left-0': isListEmpty,
          }),
        },
        {
          content: intl.get('TASKS.TABLE.HEAD.NAME'),
          className: tailwindOverride('w-1/3 h-full', {
            'z-2': !isListEmpty,
            'left-66': !showReorderColumn && showDisabledColumn,
            'left-88': showReorderColumn && showDisabledColumn,
            'left-54': !showReorderColumn && !showDisabledColumn,
            'left-76': showReorderColumn && !showDisabledColumn,
            'left-0': isListEmpty,
          }),
        },
        {
          content: intl.get('TASKS.TABLE.HEAD.STATUS'),
          className: tailwindOverride('h-full', { 'w-20': !isListEmpty }),
        },
        {
          content: intl.get('TASKS.TABLE.HEAD.ASSIGNEE_UPDATE'),
          className: tailwindOverride('h-full', { 'w-44': !isListEmpty }),
        },
        {
          content: intl.get('TASKS.TABLE.HEAD.TASK_TYPE'),
          className: tailwindOverride('h-full', { 'w-31': !isListEmpty }),
        },
        {
          content: intl.get('TASKS.TABLE.HEAD.START_DATE'),
          className: tailwindOverride('h-full', { 'w-38': !isListEmpty }),
        },
        {
          content: intl.get('TASKS.TABLE.HEAD.DUE_ACTUAL_DATE'),
          className: tailwindOverride('h-full', { 'w-60': !isListEmpty }),
        },
        {
          content: intl.get('TASKS.TABLE.HEAD.ESTIMATED_ACTUAL_HOURS'),
          className: tailwindOverride('h-full', { 'w-34': !isListEmpty }),
        },
        {
          content: <div></div>,
          className: tailwindOverride('w-12 h-full z-2', {
            'right-0': !isListEmpty,
          }),
        },
      ]}
    />
  );
};

export default TableHeader;
