import React, { useState, useEffect, useMemo } from 'react';
import intl from 'react-intl-universal';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { ProjectContent, ProjectFile, ProjectWeblink } from 'utils/customTypes';
import { PROJECT_CONTENT_TYPE } from 'utils/constants';
import {
  getContentFileName,
  getTruncatedFileNameAndExtension,
} from '../../utils';
import { updateProjectFile } from 'state/Project/projectSlice';
import { getAllUsers } from 'state/UsersManagement/usersManagementSlice';
import { getDesigns } from 'state/Designs/designsSlice';
import useModal from 'Hooks/useModal';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import ReplaceFileContent from './components/ReplaceFileContent';
import ReplaceWebLinkContent from './components/ReplaceWebLinkContent';
import ReplaceDesignContent from './components/ReplaceDesignContent';

const ReplaceContentModal: React.FC<{
  projectId: string;
  contentFile: ProjectContent | null;
  onCloseModal: () => void;
  isModalOpen: boolean;
}> = ({ contentFile, isModalOpen, onCloseModal, projectId }) => {
  const dispatch = useDispatch();
  const { Modal, modalProps, openModal, closeModal } = useModal();
  const isContentFileType =
    contentFile?.content_type === PROJECT_CONTENT_TYPE.FILE ||
    contentFile?.content_type === PROJECT_CONTENT_TYPE.DESIGN_FILE;
  const isContentWeblinkType =
    contentFile?.content_type === PROJECT_CONTENT_TYPE.WEB_LINK;
  const isContentDesignType =
    contentFile?.content_type === PROJECT_CONTENT_TYPE.DESIGN;
  const contentFileName = contentFile ? getContentFileName(contentFile) : '';
  let truncatedFileName = contentFileName;
  const [updatedContentFile, setUpdatedContentFile] =
    useState<ProjectContent | null>(contentFile);

  if (isContentFileType && contentFileName.length > 80) {
    const { name, extension } = getTruncatedFileNameAndExtension(
      contentFileName,
      80
    );
    truncatedFileName = `${name}${extension}`;
  }

  if (
    (isContentWeblinkType || isContentDesignType) &&
    contentFileName.length > 80
  ) {
    truncatedFileName = `${contentFileName.substring(0, 80)}...`;
  }

  useEffect(() => {
    if (isModalOpen && !modalProps.isOpen) {
      openModal(modalProps);
    }
  }, [isModalOpen, modalProps, openModal]);

  useEffect(() => {
    if (contentFile !== null) {
      setUpdatedContentFile(contentFile);
    }
  }, [contentFile]);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getDesigns());
  }, [dispatch]);

  const canSubmit = useMemo(() => {
    switch (contentFile?.content_type) {
      case PROJECT_CONTENT_TYPE.FILE:
      case PROJECT_CONTENT_TYPE.DESIGN_FILE:
        return (
          get(contentFile, 'data.handle') !==
          get(updatedContentFile, 'data.handle')
        );
      case PROJECT_CONTENT_TYPE.DESIGN:
        return (
          contentFile.linkedDesignId !== updatedContentFile?.linkedDesignId
        );
      default:
        return !isEqual(contentFile?.data, updatedContentFile?.data);
    }
  }, [contentFile, updatedContentFile]);

  const handleCloseModal = () => {
    onCloseModal();
    closeModal();
  };

  const handleConfirm = async () => {
    await dispatch(updateProjectFile({ projectId, data: updatedContentFile! }));

    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get(
          'PROJECT_DETAIL.FILES_TAB.REPLACE_CONTENT_MODAL.SUCCESS_MESSAGE'
        ),
      })
    );
    handleCloseModal();
  };

  const handleUpdateContentData = (
    newContentFileData: ProjectFile | Partial<ProjectWeblink>
  ) => {
    const updatedFile = {
      ...contentFile,
      data: newContentFileData,
    } as ProjectContent;
    setUpdatedContentFile(updatedFile);
  };

  const handleUpdateLinkedDesign = (designId: string) => {
    const updatedFile = {
      ...contentFile,
      linkedDesignId: designId,
    } as ProjectContent;
    setUpdatedContentFile(updatedFile);
  };

  const actionButtons = [
    {
      children: intl.get(
        'PROJECT_DETAIL.FILES_TAB.REPLACE_CONTENT_MODAL.SUBMIT_BUTTON'
      ),
      variant: 'primary',
      disabled: !canSubmit,
      onClick: handleConfirm,
      'data-cy': 'replace-content-modal__submit-button',
    },
    {
      children: intl.get('CANCEL'),
      variant: 'tertiary',
      onClick: handleCloseModal,
    },
  ];

  return (
    <Modal
      {...modalProps}
      title={intl.get('PROJECT_DETAIL.FILES_TAB.REPLACE_CONTENT_MODAL.TITLE', {
        name: truncatedFileName,
      })}
      aria-label={intl.get(
        'PROJECT_DETAIL.FILES_TAB.REPLACE_CONTENT_MODAL.ARIA_LABEL'
      )}
      size='large'
      closeModal={handleCloseModal}
      actionButtons={actionButtons}
      data-cy='replace-content-modal__container'
    >
      {isContentDesignType && (
        <ReplaceDesignContent replaceDesign={handleUpdateLinkedDesign} />
      )}
      {isContentFileType && (
        <ReplaceFileContent
          originalFile={contentFile.data as ProjectFile}
          replaceFile={handleUpdateContentData}
          contentType={contentFile.content_type}
        />
      )}
      {isContentWeblinkType && (
        <ReplaceWebLinkContent
          replaceContenData={handleUpdateContentData}
          contentData={updatedContentFile?.data as Partial<ProjectWeblink>}
        />
      )}
    </Modal>
  );
};

export default ReplaceContentModal;
