import axios, { AxiosInstance } from 'axios';
import get from 'lodash/get';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { TeamMemberProject } from 'utils/types/dailyTeamsCapacity';

export interface FetchTeamMembersListResponse {
  teamMemberProjects: Omit<TeamMemberProject, 'teamMembersIds'>[];
  links: {
    next: string | null;
  };
}

class TeamMemberProjectsListAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(config.get('backendURL'));
  }

  fetchTeamMemberProjectsList: (
    url: string
  ) => Promise<FetchTeamMembersListResponse> = async (url: string) => {
    const response = await this.instance.get<FetchTeamMembersListResponse>(url);
    if (axios.isAxiosError(response)) {
      const errorMessage = get(response, 'response.data.message', '');
      throw errorMessage;
    }
    return response.data;
  };
}

const teamMemberProjectsListAPI = new TeamMemberProjectsListAPI();
export default teamMemberProjectsListAPI;
