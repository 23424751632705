import React, { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { UsersPicker } from '@getsynapse/design-system';
import { UserAvatars, UserOption } from 'utils/customTypes';
import { PROJECT_PARTICIPANT_TYPE } from 'utils/constants';
import { getCurrentUserParticipantType } from 'state/Project/projectSlice';
import useHasUserAccess from 'Pages/ProjectPage/hooks/useHasUserAccess';
import AssignToMeButton from './AssignToMeButton';

const OwnersPicker: React.FC<{
  availableUsers: UserAvatars[];
  selectedUsers: UserAvatars[];
  currentUser: UserAvatars;
  onChangeOwners: (owners: UserOption[]) => void;
  isReadOnly?: boolean;
}> = ({
  availableUsers,
  selectedUsers,
  currentUser,
  onChangeOwners,
  isReadOnly = false,
}) => {
  const { permissionsLevel } = useHasUserAccess();
  const participantType = useSelector(getCurrentUserParticipantType);
  const [assignToMeButtonMargin, setAssignToMeButtonMargin] = useState(0);
  const isCurrentUserNotProjectParticipant =
    participantType === PROJECT_PARTICIPANT_TYPE.NOT_PARTICIPANT;
  const isCurrentUserAdminOrManager =
    permissionsLevel === PROJECT_PARTICIPANT_TYPE.OWNER;

  const usersList = useMemo(() => {
    if (isCurrentUserAdminOrManager) {
      const currentUserIndex = availableUsers.findIndex(
        (user: UserAvatars) => user.value === currentUser.value
      );
      if (currentUserIndex > -1) {
        return [
          {
            ...currentUser,
            label: `${currentUser.label} (${intl.get(
              'PROJECT_DETAIL.ASSIGN_TO_ME'
            )})`,
          },
          ...availableUsers.slice(0, currentUserIndex),
          ...availableUsers.slice(currentUserIndex + 1),
        ];
      }
    }
    return availableUsers;
  }, [isCurrentUserAdminOrManager, availableUsers, currentUser]);

  const isCurrentAmongSelectedUsers = selectedUsers.find(
    (user: UserAvatars) => user.value === currentUser.value
  );

  useEffect(() => {
    const triggerElement = document.querySelector(
      '[data-testid="owners-picker-trigger"]'
    ) as HTMLButtonElement;
    const buttonMargin = triggerElement.offsetWidth + 8;
    setAssignToMeButtonMargin(buttonMargin);
  }, [selectedUsers]);

  return (
    <div className='relative'>
      <UsersPicker
        disabled={isReadOnly}
        usersList={usersList}
        selectedUsersList={selectedUsers}
        onChange={onChangeOwners}
        maxLimit={6}
        required
        triggerProps={{ 'data-testid': 'owners-picker-trigger' }}
      />
      {isCurrentUserNotProjectParticipant && !isCurrentAmongSelectedUsers && (
        <AssignToMeButton
          currentUser={currentUser}
          assignToMeCallback={() => onChangeOwners([currentUser])}
          disabled={selectedUsers.length === 2}
          isUserAdminOrManager={isCurrentUserAdminOrManager}
          dynamicButtonMargin={assignToMeButtonMargin}
        />
      )}
    </div>
  );
};

export default OwnersPicker;
