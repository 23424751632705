import { TextArea, TextField, Checkbox } from '@getsynapse/design-system';
import { intakeQuestionWrapper } from 'utils/customTypes';
import get from 'lodash/get';
import intl from 'react-intl-universal';
import { useState, useEffect } from 'react';

const TextFieldQuestion = ({
  question,
  handler,
  disabled,
}: intakeQuestionWrapper) => {
  useEffect(() => {
    const value = get(question, 'data.multiline');
    setText((prev) =>
      prev !== question.data.value ? question.data.value : prev
    );
    setMultiline((prev) => (prev !== value ? value : prev));
  }, [question]);
  const [multiline, setMultiline] = useState<boolean>(
    get(question, 'data.multiline')
  );
  const [text, setText] = useState<string>(question.data.value);

  const updateMultiline = (checked: boolean) => {
    setMultiline(checked);
    handler(question, checked, 'data.multiline');
  };

  const updateText = (value: string) => {
    setText(value);
    handler(question, value, 'data.value');
  };

  return (
    <div>
      {multiline ? (
        <TextArea
          onChange={(event) => updateText(event.target.value)}
          textAreaProps={{
            placeholder: get(question, 'data.placeholder'),
          }}
          value={text}
          disabled={disabled}
        />
      ) : (
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            updateText(event.target.value)
          }
          placeholder={get(question, 'data.placeholder')}
          value={text}
          disabled={disabled}
          data-cy='text_question-button'
        />
      )}
      {question.section === 'additionalDetails' && (
        <Checkbox
          className='mt-2'
          checked={multiline}
          label={intl.get(
            'SETTINGS_PAGE.FORMS.DESIGN.CUSTOM_QUESTIONS.TEXT.MULTILINE'
          )}
          onChange={(event) => updateMultiline(event.target.checked)}
        />
      )}
    </div>
  );
};
export default TextFieldQuestion;
