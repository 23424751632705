import {
  createAsyncThunk,
  createSlice,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { SLICE_STATUS } from 'utils/constants';
import {
  NewBusinessTeamData,
  Status,
  FormOption,
  Option,
  SortingType,
  DropdownBusinessTeam,
} from 'utils/customTypes';
import businessTeamsAPI from './businessTeamsAPI';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import type { BusinessTeam } from 'utils/types/businessTeams';

interface BusinessTeams {
  searchParam: string;
  value: Exclude<BusinessTeam, 'user'>[];
  status: Status;
  currentBusinessTeam: BusinessTeam | null;
  businessTeamsSorting: {
    orderBy: string;
    order: SortingType;
  };
  businessTeamsForDropdown: DropdownBusinessTeam[];
}

/* ============================= INITIAL STATE ============================== */
const initialState: BusinessTeams = {
  searchParam: '',
  value: [],
  status: SLICE_STATUS.IDLE,
  currentBusinessTeam: null,
  businessTeamsSorting: {
    order: 'asc',
    orderBy: '',
  },
  businessTeamsForDropdown: [],
};

/* ============================== REDUX THUNK =============================== */
export const getBusinessTeams = createAsyncThunk(
  'businessTeams/GET_BUSINESS_TEAMS',
  async () => {
    const { data } = await businessTeamsAPI.fetchBusinessTeams();

    if (!data.businessTeams) {
      throw new Error('An error ocurred');
    }

    return data.businessTeams;
  }
);

export const getBusinessTeamsForDropdown = createAsyncThunk(
  'businessTeams/GET_BUSINESS_TEAMS_FOR_DROPDOWN',
  async () => {
    const response = await businessTeamsAPI.fetchBusinessTeamsForDropdown();
    return response;
  }
);

export const createBusinessTeam = createAsyncThunk(
  'businessTeams/CREATE_BUSINESS_TEAM',
  async (newBusinessTeamData: NewBusinessTeamData) => {
    const { data } = await businessTeamsAPI.postBusinessTeam(
      newBusinessTeamData
    );

    if (!data.businessTeam) {
      throw new Error('An error ocurred');
    }

    return data.businessTeam;
  }
);

export const getCurrentBusinessTeam = createAsyncThunk(
  'businessTeams/GET_CURRENT_BUSINESS_TEAM',
  async (id: string) => {
    const { data } = await businessTeamsAPI.getBusinessTeam(id);
    if (!data.businessTeam) {
      throw new Error('An error ocurred');
    }

    return data.businessTeam;
  }
);

export const updateBusinessTeam = createAsyncThunk(
  'businessTeams/UPDATE_TEAM',
  async ({
    teamId,
    updateFields,
  }: {
    teamId: string;
    updateFields: Partial<BusinessTeam>;
  }) => {
    const { data } = await businessTeamsAPI.updateTeam(teamId, updateFields);
    if (!data.businessTeam) {
      throw new Error('An error ocurred');
    }

    return data.businessTeam;
  }
);

export const deleteBusinessTeam = createAsyncThunk(
  'businessTeams/DELETE_TEAM',
  async (teamId: string) => {
    const { code } = await businessTeamsAPI.deleteTeam(teamId);
    if (code !== 200) {
      throw new Error('An error ocurred');
    }

    return { teamId };
  }
);
/* ================================ ACTIONS ================================= */

export const resetCurrentBusinessTeam = createAction(
  'businessTeams/RESET_CURRENT_TEAM'
);

export const setBusinessTeamsOrder = createAction<{
  order: SortingType;
  orderBy: string;
}>('businessTeams/SET_BUSINESS_TEAMS_ORDER');

export const setSearchParam = createAction<{ searchParam: string }>(
  'businessTeams/SET_SEARCH_PARAM'
);
/* ================================= REDUCER ================================ */
const slice = createSlice({
  name: 'businessTeams',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBusinessTeams.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getBusinessTeams.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(getBusinessTeams.fulfilled, (state, action) => {
        state.value = action.payload;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(createBusinessTeam.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(createBusinessTeam.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(createBusinessTeam.fulfilled, (state, action) => {
        state.value = [action.payload].concat(state.value);
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(getCurrentBusinessTeam.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getCurrentBusinessTeam.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(getCurrentBusinessTeam.fulfilled, (state, action) => {
        state.currentBusinessTeam = action.payload;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(updateBusinessTeam.fulfilled, (state, action) => {
        state.currentBusinessTeam = action.payload;
      })
      .addCase(resetCurrentBusinessTeam, (state) => {
        state.currentBusinessTeam = initialState.currentBusinessTeam;
      })
      .addCase(deleteBusinessTeam.fulfilled, (state, action) => {
        state.value = state.value.filter(
          (team) => team.id !== action.payload.teamId
        );
      })
      .addCase(setBusinessTeamsOrder, (state, action) => {
        state.businessTeamsSorting.order = action.payload.order;
        state.businessTeamsSorting.orderBy = action.payload.orderBy;
      })
      .addCase(setSearchParam, (state, action) => {
        state.searchParam = action.payload.searchParam;
      })
      .addCase(getBusinessTeamsForDropdown.fulfilled, (state, action) => {
        state.businessTeamsForDropdown = action.payload?.teams ?? [];
      });
  },
});
/* =============================== HELPERS ================================== */

export const sortBusinessTeams = (teams: BusinessTeam[]) =>
  sortBy(teams, function (team: BusinessTeam) {
    return team.title.toLowerCase();
  });

/* =============================== SELECTORS ================================ */
export const selectBussinessTeams = (state: RootState) =>
  state.businessTeams.value;

export const businessTeamsSorting = (state: RootState) =>
  state.businessTeams.businessTeamsSorting;

export const selectBusinessTeamsForDropdown = createSelector(
  [selectBussinessTeams],
  (businessTeams) => {
    const sortedBusinessTeams = sortBusinessTeams(businessTeams);
    return sortedBusinessTeams.map((team) => ({
      label: team.title,
      value: team.id,
    })) as FormOption[];
  }
);
export const selectBussinessTeamStatus = (state: RootState) =>
  state.businessTeams.status;

export const selectCurrentBusinessTeam = (state: RootState) =>
  state.businessTeams.currentBusinessTeam;

export const selectBusinessTeamsForDropdownV2 = (state: RootState) =>
  state.businessTeams.businessTeamsForDropdown.map((team) => ({
    label: team.name,
    value: team.id,
  })) as Option[];

const businessTeamsSearchParam = (state: RootState) =>
  state.businessTeams.searchParam;

export const selectOrderedBusinessTeams = createSelector(
  [selectBussinessTeams, businessTeamsSorting, businessTeamsSearchParam],
  (businessTeams, sorting, searchParam: string) => {
    let filteredBussinessTeams = businessTeams;
    if (searchParam) {
      filteredBussinessTeams = businessTeams.filter(
        (businessTeam: BusinessTeam) => {
          const clearedBusinessTeamName = businessTeam.title
            .trim()
            .toLowerCase();
          const clearedSearchParam = searchParam.trim().toLowerCase();
          return clearedBusinessTeamName.includes(clearedSearchParam);
        }
      );
    }
    return orderBy(filteredBussinessTeams, sorting.orderBy, sorting.order);
  }
);
/* ================================= EXPORT ================================= */
export default slice.reducer;
