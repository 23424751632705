import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import { store } from 'state/store';
import { deleteProjectTemplate } from 'state/ProjectTemplates/projectTemplatesSlice';
import {
  fetchProjectTemplateById,
  getProjectTemplateData,
  updateProjectTemplateStatus,
  updateProjectTemplate,
  getUpdateTemplateStatus,
  getFetchTemplateStatus,
  resetTemplate,
} from 'state/ProjectTemplate/ProjectTemplateSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import {
  PATHS,
  SETTINGS_TABS,
  SETTINGS_SECTIONS,
  SETTINGS_ATTRIBUTES,
  PROJECT_TEMPLATE_STATUS_VALUES,
  SLICE_STATUS,
  PROJECT_TEMPLATE_ERRORS,
} from 'utils/constants';
import {
  ProjectTemplate,
  ProjectTemplateField,
  ProjectTemplateStatus,
} from 'utils/types/templates';
import useTemplateForm from '../../hooks/useTemplateForm';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import TemplateContentTabs from './Form/TemplateContentTabs';
import TopBar from './Form/TopBar';
import PageFooter from './Form/PageFooter';
import UpdateTemplateConfirmationModal from './UpdateTemplateConfirmationModal';
import DefaultValuesModal from './DefaultValuesModal/DefaultValuesModal';
import { FieldType } from 'utils/types/fields';

type ProjectTemplateFieldToAddOrUpdate = Omit<
  ProjectTemplateField,
  'required' | 'defaultValue'
> & {
  required?: boolean;
  defaultValue?: FieldType['value'];
};

type ProjectTemplateFieldUpdate = Pick<
  ProjectTemplateField,
  'id' | 'layout'
> & { required?: boolean; defaultValue?: FieldType['value'] };

type ProjectTemplateDataToUpdate = Omit<
  ProjectTemplate,
  'project_template_fields' | 'id'
>;

const TemplatePage = () => {
  const dispatch = useDispatch() as typeof store.dispatch;
  const history = useHistory();
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState<boolean>(false);
  const [displayDefaultValuesModal, setDisplayDefaultValuesModal] =
    useState<boolean>(false);
  const [templateFieldsToAddOrUpdate, setTemplateFieldsToAddOrUpdate] =
    useState<ProjectTemplateFieldToAddOrUpdate[]>([]);
  const [templateFieldsToRemove, setTemplateFieldsToRemove] = useState<
    string[]
  >([]);
  const [
    requiredTemplateFieldsWithoutDefaultValues,
    setRequiredTemplateFieldsWithoutDefaultValues,
  ] = useState<ProjectTemplateField[]>([]);
  const { templateId } = useParams<{ templateId: string }>();
  const projectTemplateData = useSelector(getProjectTemplateData);
  const updateStatus = useSelector(getUpdateTemplateStatus);
  const fetchTemplateStatus = useSelector(getFetchTemplateStatus);
  const { templateData, handleFieldChange, canSubmitForm } = useTemplateForm(
    {
      ...projectTemplateData,
    },
    updateStatus
  );

  useEffect(() => {
    const init = async () => {
      await dispatch(fetchProjectTemplateById({ templateId }));
    };
    init();
    return () => {
      dispatch(resetTemplate());
    };
  }, [dispatch, templateId]);

  const handleUpdateStatus = async (status: ProjectTemplateStatus) => {
    let action = intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.ACTIONS.PUBLISH');
    if (status === PROJECT_TEMPLATE_STATUS_VALUES.DRAFT) {
      action = intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.ACTIONS.UNPUBLISH');
    }
    await dispatch(updateProjectTemplateStatus({ templateId, status }));
    handleFieldChange('status', status);
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get(
          'SETTINGS_PAGE.TEMPLATE_PAGE.SUCCESS_MESSAGE',
          { action }
        ),
      })
    );
  };

  const returnToTablePage = () =>
    history.push(
      `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.PROJECTS}&attribute=${SETTINGS_ATTRIBUTES.TEMPLATES}`
    );

  const handleRemove = async () => {
    await dispatch(deleteProjectTemplate({ templateId }));
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get(
          'SETTINGS_PAGE.TEMPLATE_PAGE.SUCCESS_MESSAGE',
          { action: intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.ACTIONS.DELETE') }
        ),
      })
    );
    returnToTablePage();
  };

  const getTemplateFieldsMap = (
    templateFields: ProjectTemplateFieldToAddOrUpdate[]
  ) =>
    templateFields.reduce(
      (
        fieldsMap: { [key: string]: ProjectTemplateFieldToAddOrUpdate },
        currentVale: ProjectTemplateFieldToAddOrUpdate
      ) => {
        fieldsMap[currentVale.id || currentVale!.field_template!.name] =
          currentVale;
        return fieldsMap;
      },
      {}
    );

  const resetTemplateFieldsArrays = () => {
    setTemplateFieldsToAddOrUpdate([]);
    setTemplateFieldsToRemove([]);
    setRequiredTemplateFieldsWithoutDefaultValues([]);
  };

  const compareCurrentTemplateDataWithTheOriginalOne = (
    currentTemplateData: ProjectTemplateDataToUpdate
  ) => {
    const { id, project_template_fields, ...restOfTemplateData } =
      projectTemplateData;
    const originalTemplateData = {
      ...restOfTemplateData,
    } as ProjectTemplateDataToUpdate;
    const updatedData = currentTemplateData;
    for (const [key, value] of Object.entries(currentTemplateData)) {
      const prop = key as keyof ProjectTemplateDataToUpdate;
      if (originalTemplateData[prop] === value) {
        delete updatedData[prop];
      }
    }
    return updatedData;
  };

  const handleUpdate = async (
    templateFieldsToAddOrUpdate: ProjectTemplateFieldToAddOrUpdate[],
    templateFieldsToDelete: string[]
  ) => {
    const { id, project_template_fields, ...restOfTemplateData } = templateData;
    const templateFieldsToAdd = [];
    const templateFieldsToUpdate = [];
    const updatedData =
      compareCurrentTemplateDataWithTheOriginalOne(restOfTemplateData);
    for (const field of templateFieldsToAddOrUpdate) {
      if (field.id) {
        templateFieldsToUpdate.push(field);
      } else {
        templateFieldsToAdd.push(field);
      }
    }
    try {
      await dispatch(
        updateProjectTemplate({
          templateId: id!,
          templateData: updatedData,
          templateFieldsToAdd: templateFieldsToAdd as ProjectTemplateField[],
          templateFieldsToUpdate:
            templateFieldsToUpdate as ProjectTemplateFieldUpdate[],
          templateFieldsToDelete,
        })
      ).unwrap();
      dispatch(
        showSnackbarNotification({
          notificationVariant: 'success',
          notificationTitle: intl.get(
            'SETTINGS_PAGE.TEMPLATE_PAGE.SUCCESS_MESSAGE',
            { action: intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.ACTIONS.UPDATE') }
          ),
          autoHide: false,
        })
      );
      returnToTablePage();
    } catch (error) {
      const message = get(error, 'message', '');
      if (message === PROJECT_TEMPLATE_ERRORS.VERSION_MISMATCH) {
        dispatch(
          showSnackbarNotification({
            notificationVariant: 'error',
            notificationTitle: intl.get(
              'SETTINGS_PAGE.TEMPLATE_PAGE.VERSION_MISMATCH_ERROR.TITLE'
            ),
            notificationMessage: intl.get(
              'SETTINGS_PAGE.TEMPLATE_PAGE.VERSION_MISMATCH_ERROR.MESSAGE'
            ),
          })
        );
      }
    }
  };

  const setDefaultValuesToNewAndUpdatedFields = (
    updatedFields: ProjectTemplateField[]
  ) => {
    const newAndUpdatedFieldsMap = getTemplateFieldsMap([
      ...templateFieldsToAddOrUpdate,
    ]);
    for (const field of updatedFields) {
      if (newAndUpdatedFieldsMap[field.id || field.field_template.name]) {
        newAndUpdatedFieldsMap[field.id || field.field_template.name] = field;
      }
    }
    setDisplayDefaultValuesModal(false);
    handleUpdate(Object.values(newAndUpdatedFieldsMap), templateFieldsToRemove);
  };

  const findNewAndUpdatedFieldsWithoutDefaultValues = () => {
    const originalTemplateFieldsMap = getTemplateFieldsMap([
      ...projectTemplateData.project_template_fields,
    ]);
    const updatedTemplateFields = [...templateData.project_template_fields];
    const customFieldsToAddOrUpdate = [];
    const requiredFieldsWithoutDefaultValues = [];

    for (const field of updatedTemplateFields) {
      if (field.id && field.id in originalTemplateFieldsMap) {
        const originalField = originalTemplateFieldsMap[field.id!];
        delete originalTemplateFieldsMap[field.id!];
        if (originalField.required !== field.required) {
          if (!originalField.required && field.required) {
            requiredFieldsWithoutDefaultValues.push(field);
          }
          customFieldsToAddOrUpdate.push(field);
        } else if (originalField.layout !== field.layout) {
          const { required, ...restOfFieldData } = field;
          customFieldsToAddOrUpdate.push({ ...restOfFieldData });
        }
      } else {
        customFieldsToAddOrUpdate.push(field);
        if (field.required) {
          requiredFieldsWithoutDefaultValues.push(field);
        }
      }
    }
    const customFieldsToDelete = Object.keys(originalTemplateFieldsMap);
    if (requiredFieldsWithoutDefaultValues.length > 0) {
      setRequiredTemplateFieldsWithoutDefaultValues(
        requiredFieldsWithoutDefaultValues
      );
      setTemplateFieldsToAddOrUpdate(customFieldsToAddOrUpdate);
      if (customFieldsToDelete.length > 0) {
        setTemplateFieldsToRemove(customFieldsToDelete);
      }
      setDisplayDefaultValuesModal(true);
    } else {
      handleUpdate(customFieldsToAddOrUpdate, customFieldsToDelete);
    }
  };

  const confirmUpdate = () => {
    setDisplayConfirmationModal(true);
  };

  return (
    <div className='h-full flex flex-col shadow-skim'>
      <UpdateTemplateConfirmationModal
        shouldDisplayModal={displayConfirmationModal}
        onConfirm={() => {
          setDisplayConfirmationModal(false);
          findNewAndUpdatedFieldsWithoutDefaultValues();
        }}
        onClose={() => setDisplayConfirmationModal(false)}
      />
      <DefaultValuesModal
        shouldDisplayModal={displayDefaultValuesModal}
        templateFields={requiredTemplateFieldsWithoutDefaultValues}
        onUpdate={setDefaultValuesToNewAndUpdatedFields}
        onCancel={() => {
          setDisplayDefaultValuesModal(false);
          resetTemplateFieldsArrays();
        }}
      />
      <PageTitle titleComponent={projectTemplateData.name} />
      <DetailsPage
        bodyClassName='overflow-y-hidden'
        topBar={
          <TopBar
            status={templateData.status}
            onUpdateStatus={handleUpdateStatus}
            canUpdate={!templateData.system}
            canRemove={!templateData.system}
            onRemove={handleRemove}
            isLoading={fetchTemplateStatus === SLICE_STATUS.LOADING}
          />
        }
        content={
          <TemplateContentTabs
            templateData={templateData}
            updateTemplateData={handleFieldChange}
            isLoading={fetchTemplateStatus === SLICE_STATUS.LOADING}
          />
        }
      />
      <PageFooter
        canSave={canSubmitForm}
        onCancel={returnToTablePage}
        onSave={confirmUpdate}
        isUpdating
        isLoading={updateStatus === SLICE_STATUS.LOADING}
      />
    </div>
  );
};

export default TemplatePage;
