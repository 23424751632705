import React from 'react';
import { useSelector } from 'react-redux';
import { tailwindOverride } from '@getsynapse/design-system';
import {
  selectWeeks,
  selectNumberOfWeeks,
} from 'state/WeeklyTeamCapacity/Weeks/weeksSlice';
import {
  toSegmentString,
  DateSegment,
} from 'state/WeeklyTeamCapacity/Weeks/helpers';
import {
  DateRange,
  getWeekRangeForDate,
} from '../../../WeeklyCapacity/helpers';

const NoWeeklyCapacity: React.FC<{
  id: string;
  isLoading?: boolean;
}> = ({ id, isLoading = false }) => {
  const weeksTuplesArray: DateSegment[] = useSelector(selectWeeks);
  const numberOfWeeks: number = useSelector(selectNumberOfWeeks);
  const currenDate = new Date();
  const currentWeekDateRange: DateRange = getWeekRangeForDate(currenDate);
  const currenWeekSegment: DateSegment = toSegmentString(currentWeekDateRange);

  return (
    <div
      className='w-full h-full flex'
      aria-label={`${id}__no-weekly-capacity`}
    >
      <div className='w-6 h-full' />
      <div className='flex w-full h-full'>
        {weeksTuplesArray.map((weekTuple: DateSegment, index) => {
          const isCurrentWeek = weekTuple[0] === currenWeekSegment[0];
          return (
            <div
              key={`${id}_${weekTuple[0]}`}
              className={tailwindOverride(
                'flex-1 h-full',
                'flex-shrink-0 flex-grow items-center',
                {
                  'bg-secondary-lightest border-l border-r border-secondary-lighter':
                    isCurrentWeek,
                }
              )}
              style={{ width: `${100 / numberOfWeeks}%` }}
            >
              <div
                key={`${id}__${index}`}
                className={tailwindOverride(
                  'w-full h-full flex-1',
                  'flex  items-center',
                  'px-1 pb-2 pt-3',
                  'box-border animate-pulse'
                )}
              >
                {isLoading && (
                  <div className='w-full h-4 rounded-sm bg-primary-lighter' />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className='w-6 h-full' />
    </div>
  );
};

export default NoWeeklyCapacity;
