import { L10n } from '@syncfusion/ej2-base';
import { generateSummary } from '@syncfusion/ej2-schedule';

export const generateReadableRecurrancePattern = (
  recurrancePattern?: string
): string => {
  if (!recurrancePattern) {
    return '';
  }
  const l10n = new L10n('recurrenceeditor', {
    none: 'None',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    month: 'Month',
    yearly: 'Yearly',
    never: 'Never',
    until: 'Until',
    count: 'Count',
    first: 'First',
    second: 'Second',
    third: 'Third',
    fourth: 'Fourth',
    last: 'Last',
    repeat: 'Repeat',
    repeatEvery: 'Repeat every',
    on: 'Repeat On',
    end: 'End',
    onDay: 'Day',
    days: 'Day(s)',
    weeks: 'Week(s)',
    months: 'Month(s)',
    years: 'Year(s)',
    every: 'every',
    summaryTimes: 'time(s)',
    summaryOn: 'on',
    summaryUntil: 'until',
    summaryRepeat: 'Repeats',
    summaryDay: 'day(s)',
    summaryWeek: 'week(s)',
    summaryMonth: 'month(s)',
    summaryYear: 'year(s)',
    monthWeek: 'Month Week',
    monthPosition: 'Month Position',
    monthExpander: 'Month Expander',
    yearExpander: 'Year Expander',
    repeatInterval: 'Repeat Interval',
  });

  return generateSummary(recurrancePattern, l10n, 'en');
};

// TO DO: remove mock data once wired up with backend
export const mockData = {
  dataSource: [
    {
      Id: 1,
      Subject: 'Get Together Day',
      StartTime: '2024-08-15T11:30:00',
      EndTime: '2024-08-15T12:00:00',
      ProjectId: 1,
      IsAllDay: true,
      ProjectTitle: 'Project 1',
    },
    {
      EndTime: '2024-08-05T11:30:00',
      EndTimezone: null,
      Id: 6,
      ProjectId: 6,
      ProjectTitle: 'Project 6',
      IsAllDay: false,
      RecurrenceRule: 'FREQ=DAILY;INTERVAL=1;',
      StartTime: '2024-08-05T11:30:00',
      StartTimezone: null,
      Subject: 'Daily Standup',
      EventType: 'Virtual Instrcution led Jewdre',
      Facilitators: [
        {
          Id: 1,
          Name: 'Bernard Schultz',
        },
        {
          Id: 2,
          Name: 'Karen Kelly',
        },
        // {
        //   Id: 2,
        //   Name: 'Joe Doen',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 1,
        //   Name: 'Bernard Schultz',
        // },
        // {
        //   Id: 2,
        //   Name: 'Karen Kelly',
        // },
        // {
        //   Id: 2,
        //   Name: 'Joe Doen',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
      ],
      Projects: [
        {
          Id: '1',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
      ],
    },
    {
      EndTime: '2024-08-05T11:30:00',
      EndTimezone: null,
      Id: 1,
      ProjectId: 1,
      ProjectTitle: 'Project 6',
      IsAllDay: false,
      RecurrenceRule: 'FREQ=DAILY;INTERVAL=1;',
      StartTime: '2024-08-05T11:30:00',
      StartTimezone: null,
      Subject: 'Daily Standup',
      EventType: 'Virtual Instrcution led Jewdre',
      Facilitators: [
        {
          Id: 1,
          Name: 'Bernard Schultz',
        },
        {
          Id: 2,
          Name: 'Karen Kelly',
        },
        // {
        //   Id: 2,
        //   Name: 'Joe Doen',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 1,
        //   Name: 'Bernard Schultz',
        // },
        // {
        //   Id: 2,
        //   Name: 'Karen Kelly',
        // },
        // {
        //   Id: 2,
        //   Name: 'Joe Doen',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
      ],
      Projects: [
        {
          Id: '1',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
      ],
    },
    {
      EndTime: '2024-08-05T11:30:00',
      EndTimezone: null,
      Id: 3,
      ProjectId: 3,
      ProjectTitle: 'Project 6',
      IsAllDay: false,
      RecurrenceRule: 'FREQ=DAILY;INTERVAL=1;',
      StartTime: '2024-08-05T11:30:00',
      StartTimezone: null,
      Subject: 'Daily Standup',
      EventType: 'Virtual Instrcution led Jewdre',
      Facilitators: [
        {
          Id: 1,
          Name: 'Bernard Schultz',
        },
        {
          Id: 2,
          Name: 'Karen Kelly',
        },
        // {
        //   Id: 2,
        //   Name: 'Joe Doen',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 1,
        //   Name: 'Bernard Schultz',
        // },
        // {
        //   Id: 2,
        //   Name: 'Karen Kelly',
        // },
        // {
        //   Id: 2,
        //   Name: 'Joe Doen',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
        // {
        //   Id: 2,
        //   Name: 'Steve Iper',
        // },
      ],
      Projects: [
        {
          Id: '1',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
        {
          Id: '22d63e8a-e546-4b44-9224-3b6064710f3c',
          Name: '2024 New Hire Refresh',
        },
      ],
    },
  ],
  projects: [
    { ProjectId: 1, title: 'Project 1' },
    { ProjectId: 2, title: 'Project 2' },
    { ProjectId: 3, title: 'Project 3' },
    { ProjectId: 4, title: 'Project 4' },
    { ProjectId: 5, title: 'Project 5' },
    { ProjectId: 6, title: 'Project 6' },
  ],
};
