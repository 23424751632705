import { ChangeEvent, useMemo, useState, useEffect } from 'react';
import { RadioGroup } from '@getsynapse/design-system';
import { intakeQuestionWrapper, radioOption } from 'utils/customTypes';

const RadioQuestion = ({
  question,
  handler,
  disabled,
}: intakeQuestionWrapper) => {
  useEffect(() => {
    setOptionChecked((prev) =>
      prev !== question.data.value.value ? question.data.value.value : prev
    );
  }, [question]);

  const [optionChecked, setOptionChecked] = useState<string | number>(
    question.data.value.value
  );

  const optionsData = useMemo(
    () =>
      question.data.value.options.map((data: radioOption) => {
        if (data.text) {
          return { label: data.text, value: data.id };
        }
        return null;
      }),
    [question.data.value.options]
  );

  return (
    <>
      {question.data.label?.trim() && optionsData.length && (
        <RadioGroup
          checkOption='checked'
          name={question.data.label}
          options={optionsData}
          inputProps={{
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
              setOptionChecked(event.target.value);
              handler(question, event.target.value, 'data.value.value');
            },
          }}
          checked={optionChecked}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default RadioQuestion;
