import React from 'react';
import { CapacityPerWeek } from 'types/store/weeklyTeamCapacity';
import AllocationSummary from '../../../../CapacityTable/components/AllocationSummary/AllocationSummary';
import AllocationSummaryTooltip from '../../../../CapacityTable/components/AllocationSummaryTooltip/AllocationSummaryTooltip';
import CapacityBreakdown from '../../../../CapacityTable/components/CapacityBreakdown/CapacityBreakdown';

const TeamWeeklyCapacity: React.FC<CapacityPerWeek> = ({
  assigned,
  timeOff,
  capacity,
}) => {
  return (
    <div className='w-full h-14 flex items-center px-2'>
      <AllocationSummaryTooltip
        trigger={
          <div>
            <AllocationSummary
              assignedHours={parseFloat(assigned)}
              defaultCapacity={parseFloat(capacity)}
              timeOff={parseFloat(timeOff)}
              capacityView='WEEKLY'
            />
          </div>
        }
      >
        <CapacityBreakdown
          assignedHours={parseFloat(assigned)}
          defaultCapacity={parseFloat(capacity)}
          timeOff={parseFloat(timeOff)}
          capacityView='WEEKLY'
        />
      </AllocationSummaryTooltip>
    </div>
  );
};

export default TeamWeeklyCapacity;
