import { FC } from 'react';
import intl from 'react-intl-universal';
import { TableHeadCell, tailwindOverride } from '@getsynapse/design-system';

const CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES = {
  ID: 'id',
  NAME: 'name',
  STATUS: 'status',
  PROJECT: 'project',
  ASSIGNEE: 'user',
  START_DATE: 'startDate',
  DUE_DATE: 'dueDate',
  ESTIMATED_HOURS: 'estimateHours',
  ACTUAL_HOURS: 'actualHours',
};

interface TableHeaderProps {
  sortBy: string;
  order: string;
  handleSort: (sortBy: string, order: string) => void;
}

const TableHeader: FC<TableHeaderProps> = ({ sortBy, order, handleSort }) => {
  const stickyHeaderStyles = tailwindOverride(
    'sticky top-0 z-1 bg-primary-lightest h-10'
  );
  return (
    <thead
      className={tailwindOverride(
        'h-10 bg-primary-lightest',
        'text-primary text-caption font-semibold'
      )}
    >
      <tr>
        <TableHeadCell
          columnName={CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.ID}
          content={intl.get('TASKS_LIST_PAGE.TABLE.HEAD.ID')}
          className={tailwindOverride(
            stickyHeaderStyles,
            'w-32 left-0 z-2',
            {}
          )}
        />
        <TableHeadCell
          columnName={CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.NAME}
          content={intl.get('TASKS_LIST_PAGE.TABLE.HEAD.TASK_NAME')}
          className={tailwindOverride(stickyHeaderStyles, 'w-84 left-40 z-2', {
            'bg-secondary-lightest':
              sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.NAME,
          })}
          isActive={sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.NAME}
          isDesc={
            sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.NAME &&
            order === 'desc'
          }
          isAsc={
            sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.NAME &&
            order === 'asc'
          }
          handleSort={() =>
            handleSort(
              CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.NAME,
              order === 'asc' ? 'desc' : 'asc'
            )
          }
          aria-label={intl.get('TASKS_LIST_PAGE.TABLE.HEAD.TASK_NAME')}
        />
        <TableHeadCell
          columnName={CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.STATUS}
          content={intl.get('TASKS_LIST_PAGE.TABLE.HEAD.STATUS')}
          className={tailwindOverride(stickyHeaderStyles, 'w-32 left-132 z-2', {
            'bg-secondary-lightest':
              sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.STATUS,
          })}
          isActive={sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.STATUS}
          isDesc={
            sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.STATUS &&
            order === 'desc'
          }
          isAsc={
            sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.STATUS &&
            order === 'asc'
          }
          handleSort={() =>
            handleSort(
              CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.STATUS,
              order === 'asc' ? 'desc' : 'asc'
            )
          }
          aria-label={intl.get('TASKS_LIST_PAGE.TABLE.HEAD.STATUS')}
        />
        <TableHeadCell
          columnName={CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.PROJECT}
          content={intl.get('TASKS_LIST_PAGE.TABLE.HEAD.PROJECT')}
          className={tailwindOverride(stickyHeaderStyles, {
            'bg-secondary-lightest':
              sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.PROJECT,
          })}
          isActive={sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.PROJECT}
          isDesc={
            sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.PROJECT &&
            order === 'desc'
          }
          isAsc={
            sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.PROJECT &&
            order === 'asc'
          }
          handleSort={() =>
            handleSort(
              CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.PROJECT,
              order === 'asc' ? 'desc' : 'asc'
            )
          }
          aria-label={intl.get('TASKS_LIST_PAGE.TABLE.HEAD.PROJECT')}
        />
        <TableHeadCell
          columnName={CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.ASSIGNEE}
          content={intl.get('TASKS_LIST_PAGE.TABLE.HEAD.ASSIGNEE')}
          className={stickyHeaderStyles}
        />
        <TableHeadCell
          columnName={CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.START_DATE}
          content={intl.get('TASKS_LIST_PAGE.TABLE.HEAD.START_DATE')}
          className={tailwindOverride(stickyHeaderStyles, {
            'bg-secondary-lightest':
              sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.START_DATE,
          })}
          isActive={
            sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.START_DATE
          }
          isDesc={
            sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.START_DATE &&
            order === 'desc'
          }
          isAsc={
            sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.START_DATE &&
            order === 'asc'
          }
          handleSort={() =>
            handleSort(
              CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.START_DATE,
              order === 'asc' ? 'desc' : 'asc'
            )
          }
          aria-label={intl.get('TASKS_LIST_PAGE.TABLE.HEAD.START_DATE')}
        />
        <TableHeadCell
          columnName={CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.DUE_DATE}
          content={intl.get('TASKS_LIST_PAGE.TABLE.HEAD.DUE_DATE')}
          className={tailwindOverride(stickyHeaderStyles, {
            'bg-secondary-lightest':
              sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.DUE_DATE,
          })}
          isActive={sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.DUE_DATE}
          isDesc={
            sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.DUE_DATE &&
            order === 'desc'
          }
          isAsc={
            sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.DUE_DATE &&
            order === 'asc'
          }
          handleSort={() =>
            handleSort(
              CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.DUE_DATE,
              order === 'asc' ? 'desc' : 'asc'
            )
          }
          aria-label={intl.get('TASKS_LIST_PAGE.TABLE.HEAD.DUE_DATE')}
        />
        <TableHeadCell
          columnName={CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.ESTIMATED_HOURS}
          content={intl.get('TASKS_LIST_PAGE.TABLE.HEAD.ESTIMATED_HOURS')}
          className={tailwindOverride(stickyHeaderStyles, {
            'bg-secondary-lightest':
              sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.ESTIMATED_HOURS,
          })}
          isActive={
            sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.ESTIMATED_HOURS
          }
          isDesc={
            sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.ESTIMATED_HOURS &&
            order === 'desc'
          }
          isAsc={
            sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.ESTIMATED_HOURS &&
            order === 'asc'
          }
          handleSort={() =>
            handleSort(
              CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.ESTIMATED_HOURS,
              order === 'asc' ? 'desc' : 'asc'
            )
          }
          aria-label={intl.get('TASKS_LIST_PAGE.TABLE.HEAD.ESTIMATED_HOURS')}
        />
        <TableHeadCell
          columnName={CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.ACTUAL_HOURS}
          content={intl.get('TASKS_LIST_PAGE.TABLE.HEAD.ACTUAL_HOURS')}
          className={tailwindOverride(stickyHeaderStyles, {
            'bg-secondary-lightest':
              sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.ACTUAL_HOURS,
          })}
          isActive={
            sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.ACTUAL_HOURS
          }
          isDesc={
            sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.ACTUAL_HOURS &&
            order === 'desc'
          }
          isAsc={
            sortBy === CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.ACTUAL_HOURS &&
            order === 'asc'
          }
          handleSort={() =>
            handleSort(
              CENTRALIZED_TASKS_TABLE_SORT_BY_VALUES.ACTUAL_HOURS,
              order === 'asc' ? 'desc' : 'asc'
            )
          }
          aria-label={intl.get('TASKS_LIST_PAGE.TABLE.HEAD.ACTUAL_HOURS')}
        />
        <TableHeadCell
          content=''
          className={tailwindOverride(stickyHeaderStyles, 'w-6 right-0 z-2')}
        />
      </tr>
    </thead>
  );
};

export default TableHeader;
