import { TaskFormFields } from 'utils/types/task';

export const taskModalFields: TaskFormFields = {
  assignedUserIds: [],
  description: '',
  due_date: '',
  estimate_hours: '0',
  name: '',
  project_id: '',
  start_date: '',
  type: '',
};
