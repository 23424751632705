import intl from 'react-intl-universal';
import { Icon, Typography, tailwindOverride } from '@getsynapse/design-system';

const NoTasksAssignedBanner = () => {
  return (
    <div
      className={tailwindOverride(
        'w-full h-6',
        'px-2 mt-4',
        'absolute top-0 left-0 z-10',
        'bg-transparent'
      )}
    >
      <div
        className={tailwindOverride(
          'w-full h-6',
          'flex flex-grow flex-shrink-0 flex items-center',
          'rounded-sm px-2',
          'bg-primary-lighter-two',
          'border border-primary-lighter'
        )}
        style={{
          boxShadow: `0px 2px 2px 0px #12151B0D, 2px 0px 0px 0px rgb(var(--color-primary-dark)) inset`,
        }}
      >
        <Icon
          name='information-circle'
          className='text-primary-dark text-lg mr-2'
        />
        <Typography
          variant='caption'
          weight='medium'
          className='text-primary-darker'
        >
          {intl.get('TEAMS_CAPACITY.NO_TASKS_ASSIGNED')}
        </Typography>
      </div>
    </div>
  );
};

export default NoTasksAssignedBanner;
