import { tailwindOverride, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { PROJECT_STATUS } from 'utils/constants';

type TeamProjectAllocationProps = {
  status: string;
  hours: number;
};

const TeamProjectAllocation = ({
  status,
  hours,
}: TeamProjectAllocationProps) => {
  const statusMapping: { [key: string]: { container: string; text: string } } =
    {
      [PROJECT_STATUS.NEW]: {
        container: 'bg-purple-lighter border-purple-darker',
        text: 'text-purple-darker',
      },
      [PROJECT_STATUS.IN_PLANNING]: {
        container: 'bg-warning-lightest border-warning-darker',
        text: 'text-warning-darker',
      },
      [PROJECT_STATUS.IN_PROGRESS]: {
        container: 'bg-teal-lightest border-teal-dark',
        text: 'text-teal-dark',
      },
      [PROJECT_STATUS.COMPLETED]: {
        container: 'bg-success-lighter border-success-darkest',
        text: 'text-success-darkest',
      },
      [PROJECT_STATUS.CANCELED]: {
        container: 'bg-error-lightest border-error-darker',
        text: 'text-error-darker',
      },
      [PROJECT_STATUS.ON_HOLD]: {
        container: 'bg-tertiary-lightest border-tertiary-darker',
        text: 'text-tertiary-darker',
      },
      [PROJECT_STATUS.CLOSED]: {
        container: 'bg-secondary-lighter border-secondary-darker',
        text: 'text-secondary-darker',
      },
    };

  return (
    <div
      className={tailwindOverride(
        'w-full h-6 flex items-center',
        'border-b-2 justify-center	',
        statusMapping[status].container
      )}
    >
      <Typography
        variant='caption'
        className={tailwindOverride(
          'pr-2 font-semibold',
          statusMapping[status].text
        )}
      >
        {intl.get('ORG_SETTINGS_PAGE.LEARNING_TEAMS.HOURS', { hours })}
      </Typography>
    </div>
  );
};
export default TeamProjectAllocation;
